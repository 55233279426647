import { includes } from 'lodash';
import { alert, confirm, toast } from '../../../../utils/ModalService';
import DriveInfoRest from '../../../../apis/DriveInfoRest';
import FoldersRest from '../../../../apis/FoldersRest';

const chkValidFolder = objtId => {
    if (includes(['all', 'shared', 'workgroup'], objtId)) {
        return alert('drive.alert.invalid.selectfolder').then(Promise.reject);
    }
    return Promise.resolve(true);
};

const checkBeforeFavorite = objtId => {
    if (includes(['all', 'shared', 'sharing', 'workgroup', 'own'], objtId)) {
        return alert('drive.alert.invalid.selectfolder').then(Promise.reject);
    }
    return Promise.resolve(true);
};

const beforeCopy = ({ objtId, objtNm }) => {
    const msg = { id: 'drive.confirm.copy', values: objtNm };
    return chkValidFolder(objtId).then(() => confirm(msg), Promise.reject);
};

const copyComplete = result => {
    const { resultCode, data } = result;
    if (data && data.success > 0) {
        toast('drive.toast.copy.success');
        return Promise.resolve(true);
    }
    if (resultCode === 403) alert('drive.alert.incomLink.noAuthFolder');
    else if (includes([20911, 20912], resultCode)) alert(data);
    else if (resultCode === 20110) toast('com.alert.invalid.fullPath.tooLong');
    else toast('com.alert.failCopy');
    return Promise.reject();
};

const copyApi = (destFolder, files) => {
    const list = files.map(item => item.drive);
    const objtId = destFolder.objtId === 'own' ? '111100000000000000' : destFolder.objtId;
    return DriveInfoRest.copyFolderFile({ ...destFolder, objtId }, list).then(result => copyComplete(result), copyComplete);
};

const beforeMove = ({ objtId, objtNm }) => {
    const msg = { id: 'drive.confirm.move', values: objtNm };
    return chkValidFolder(objtId).then(() => confirm(msg), Promise.reject);
};

const beforeWorkgroup = destFolder => {
    const { objtId } = destFolder;
    return chkValidFolder(objtId).then(() => Promise.resolve(destFolder), Promise.reject);
};

const moveComplete = result => {
    const { resultCode, data } = result;
    if (data && data.success > 0) {
        toast('drive.toast.move.success');
        return Promise.resolve(true);
    }
    if (resultCode === 403) alert('drive.alert.incomLink.noAuthFolder');
    else if (includes([20911, 20912, 20031], resultCode)) alert(data);
    else if (resultCode === 20110) toast('com.alert.invalid.fullPath.tooLong');
    else toast('drive.alert.move.fail');
    return Promise.reject();
};

const moveApi = (destFolder, files) => {
    const list = files.map(item => item.drive);
    const objtId = destFolder.objtId === 'own' ? '111100000000000000' : destFolder.objtId;
    return DriveInfoRest.moveFolderFile({ ...destFolder, objtId }, list).then(result => moveComplete(result), moveComplete);
};

const beforeFavorite = file => {
    return checkBeforeFavorite(file.objtId).then(() => confirm('drive.confirm.addFavorite'), Promise.reject);
};

const favoriteApi = file => {
    return FoldersRest.addPreferFolders([{ drive: file }], true).then(result => ({ added: [file], result }));
};

function getCopyConfig() {
    return {
        beforeSave: beforeCopy,
        api: copyApi,
        newFolder: true
    };
}

function getMoveConfig() {
    return {
        beforeSave: beforeMove,
        api: moveApi,
        newFolder: true
    };
}

function getDefaultConfig(addedConfig = {}) {
    return {
        beforeSave: () => Promise.resolve(true),
        api: () => Promise.resolve(true),
        newFolder: false,
        ...addedConfig
    };
}

function getFavoriteConfig({ newFolder = false }) {
    return {
        beforeSave: beforeFavorite,
        api: favoriteApi,
        newFolder
    };
}

function getSearchConfig() {
    return {
        beforeSave: () => Promise.resolve(true),
        api: (destFolder = {}) => Promise.resolve(destFolder),
        newFolder: false
    };
}

function FolderSelectConfig() {
    const getConfig = mode => {
        const config = {
            copy: getCopyConfig(),
            move: getMoveConfig(),
            ownOnly: getDefaultConfig({ newFolder: true, beforeSave: data => Promise.resolve(data), api: data => Promise.resolve(data) }),
            sharedOnly: getDefaultConfig({ newFolder: true }),
            workgroupOnly: getDefaultConfig({ newFolder: true, beforeSave: beforeWorkgroup, api: data => Promise.resolve(data) }),
            default: getDefaultConfig({}),
            favorite: getFavoriteConfig({}),
            search: getSearchConfig()
        };
        return config[mode] || config.default;
    };
    return getConfig;
}

export default new FolderSelectConfig();
