import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import 'react-quill/dist/quill.snow.css'; // 어드민에서 사용하고있는 에디터사용 컨텐츠 표기위한 css (like 공지사항/FAQ)

import FAQConstants from 'constants/FAQ';
import FAQRest from 'apis/FAQRest';
import CodesRest from 'apis/CodesRest';
import SelectBox from 'presentationals/SelectBox';

import Editor from './editor/Editor';

class FAQ extends Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;
        this.state = {
            page: 1,
            faqs: [],
            totalCount: 0,
            faqCatCd: '',
            keyword: '',
            preKeyword: '',
            search: false,
            searchInputFocus: false
        };
        this.categories = [{ value: '', message: intl.formatMessage({ id: 'user.helpcenter.allTypes' }) }];
        this.searchedKeyword = '';
    }

    componentDidMount() {
        CodesRest.getCode(FAQConstants.CATEGORY_CDE_ID).then(response => {
            const { resultCode, data } = response;
            if (resultCode === 200) {
                const categories = _.map(data, category => ({ value: category.codeValidVal, message: category.thCdValidValNm }));
                this.categories = this.categories.concat(categories);
            }
            this.getFAQs();
        });
    }

    scrollToTop = () => window.scrollTo(0, 0);

    getFAQs = isMore => {
        const { faqCatCd, page, faqs, keyword, search } = this.state;
        const params = { page };
        if (faqCatCd && !search) params.faqCatCd = faqCatCd;
        if (search && keyword) {
            params.keyword = keyword.trim();
            this.searchedKeyword = keyword.trim();
            this.setState({
                keyword: keyword.trim()
            });
        }
        params.limit = FAQConstants.LIMIT_PER_PAGE;
        FAQRest.getFAQs(params).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { list, total: totalCount } = data;
            const convertedFaqs = _.map(list, faq => {
                const changedFaq = faq;
                changedFaq.showAnswer = false;
                changedFaq.answer = '';
                return changedFaq;
            });
            const finalFaqs = isMore ? faqs.concat(convertedFaqs) : convertedFaqs;
            this.setState(
                {
                    faqs: finalFaqs,
                    totalCount
                },
                () => !isMore && this.scrollToTop()
            );
        });
    };

    getMoreFAQs = () => {
        this.setState(
            prevState => {
                const { page: prevPage } = prevState;
                return { page: prevPage + 1 };
            },
            () => this.getFAQs(true)
        );
    };

    getFAQContent = async faqId => {
        const { faqs } = this.state;
        const faq = _.find(faqs, ['faqId', faqId]);
        const { showAnswer } = faq;
        if (!showAnswer) {
            const response = await FAQRest.getFAQContent(faqId);
            const { resultCode, data } = response;
            if (resultCode === 200) {
                const { faqDtlContent } = data;
                faq.answer = faqDtlContent;
            }
        }
        faq.showAnswer = !showAnswer;
        this.setState({ faqs: _.map(faqs, item => (item.faqId === faqId ? Object.assign(item, faq) : { ...item, showAnswer: false })) });
    };

    createFAQs = () => {
        const { faqs, search } = this.state;
        return _.map(faqs, faq => {
            const { faqId, faqCatCd, faqTitle, showAnswer, answer } = faq;
            const faqCategory = _.find(this.categories, ['value', faqCatCd]);
            const message = (faqCategory && faqCategory.message) || faqCatCd;
            return (
                <li key={faqId} id={faqId}>
                    <div className="faq-header">
                        <em className="category">{message}</em>
                        <div className="faq-q">
                            <a role="button" onClick={() => this.getFAQContent(faqId)}>
                                {search && this.searchedKeyword ? <Highlighter searchWords={[this.searchedKeyword]} autoEscape={true} textToHighlight={faqTitle} /> : <>{faqTitle}</>}
                            </a>
                        </div>
                    </div>
                    {/* react-quill editor content 표기를 위한 ql-editor class 추가 */}

                    <div
                        className="faq-a ql-editor"
                        style={{ display: showAnswer ? 'block' : 'none', visibility: showAnswer ? 'visible' : 'hidden', opacity: showAnswer ? '1' : '', height: showAnswer ? 'auto' : '0' }}>
                        <Editor html={answer} />
                    </div>
                </li>
            );
        });
    };

    onCategoryChange = faqCatCd => {
        this.setState({ faqCatCd, page: 1 }, this.getFAQs);
    };

    onKeywordChange = ({ target }) => {
        const { value: keyword } = target;
        const { page, preKeyword } = this.state;

        let tmpPage = page;
        if (keyword !== preKeyword) {
            tmpPage = 1;
        }
        this.setState({ keyword, page: tmpPage, preKeyword: keyword });
    };

    inputKeyUpEvent = ({ key }) => {
        if (key === 'Enter') this.onSearch();
    };

    onSearch = () => {
        const { keyword } = this.state;
        if (!keyword || keyword.trim().length === 0) return;
        this.setState({ search: true }, this.getFAQs);
    };

    cancelSearch = () => {
        this.setState({ keyword: '', search: false }, this.getFAQs);
    };

    clearKeyword = () => {
        this.setState({ keyword: '' });
    };

    render() {
        const { intl } = this.props;
        const { totalCount, faqCatCd, keyword, faqs, search, searchInputFocus } = this.state;
        const faqList = this.createFAQs();
        return (
            <>
                <div className="comm-title-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            <div className="section-srch-result" style={{ display: search ? '' : 'none' }}>
                                <a className="btn-ic-nor btn-func-back" role="button" title={intl.formatMessage({ id: 'com.tooltip.go.back' })} onClick={this.cancelSearch}>
                                    <i className="ic-20-arrow-back" />
                                </a>
                                <div className="total-count">
                                    <span className="count">
                                        <FormattedMessage id="user.helpcenter.resultCount" values={{ n: <i className="num">{totalCount}</i> }} />
                                    </span>
                                </div>
                            </div>
                            <div className="section-filter" style={{ display: search ? 'none' : '' }}>
                                <SelectBox options={this.categories} onChange={this.onCategoryChange} value={faqCatCd} />
                            </div>
                            <div className="section-srch">
                                <div className={`box-input-group ${searchInputFocus ? 'is-focused' : ''}`}>
                                    <a
                                        className={_.isEmpty(keyword) ? 'btn-ic-nor btn-func-search' : 'btn-ic-nor btn-func-search on'}
                                        role="button"
                                        title={intl.formatMessage({ id: 'com.search' })}
                                        onClick={this.onSearch}>
                                        <i className="ic-16-search" />
                                    </a>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'user.helpcenter.searchFaq' })}
                                        maxLength={100}
                                        value={keyword}
                                        onChange={this.onKeywordChange}
                                        onKeyUp={this.inputKeyUpEvent}
                                        onFocus={() => this.setState({ searchInputFocus: true })}
                                        onBlur={() => this.setState({ searchInputFocus: false })}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={intl.formatMessage({ id: 'com.text.clear' })}
                                        style={{ display: keyword.length > 0 ? 'inline-flex' : 'none' }}
                                        onClick={this.clearKeyword}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="help-view-conts">
                    <ul className="faq-list" style={{ display: faqs && faqs.length > 0 ? '' : 'none' }}>
                        {faqList}
                    </ul>
                    <div className="box-empty" style={{ display: !faqs || faqs.length === 0 ? 'block' : 'none' }}>
                        <div className="message" style={{ display: search ? 'none' : 'block' }}>
                            <span className="ic-empty-faq" />
                            <p>{intl.formatMessage({ id: 'user.helpcenter.noFaqs' })}</p>
                        </div>
                        <div className="message" style={{ display: search ? 'block' : 'none' }}>
                            <span className="ic-empty-search" />
                            <p>{intl.formatMessage({ id: 'com.no-search-results' })}</p>
                        </div>
                    </div>
                    <a className="btn btn-secondary btn-block" role="button" onClick={this.getMoreFAQs} style={{ display: faqs && faqs.length > 0 && totalCount > faqs.length ? '' : 'none' }}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.viewmore' })}</span>
                    </a>
                </div>
            </>
        );
    }
}

FAQ.propTypes = {
    intl: PropTypes.object.isRequired
};

export default injectIntl(FAQ);
