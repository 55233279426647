import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

class UploadInput extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidUpdate() {
        const { folder = {}, time = -1 } = this.props;
        const { objtId } = folder || {};
        if (!!this.ref && !_.isNil(folder) && !_.isEmpty(folder) && !!objtId && time > 0) {
            this.ref.current.click();
        }
    }

    render() {
        const { executor } = this.props;
        return (
            <span className="file-upload">
                <input ref={this.ref} type="file" title="" id="uploadInput" className="upload" multiple={true} onChange={executor} />
            </span>
        );
    }
}

UploadInput.propTypes = {
    folder: PropTypes.object,
    executor: PropTypes.func,
    time: PropTypes.number
};

UploadInput.defaultProps = {
    folder: null,
    executor: _.noop,
    time: -1
};

const mapStateToProps = state => ({
    folder: state.upload.folder,
    executor: state.upload.executor,
    time: state.upload.time
});

export default connect(
    mapStateToProps,
    null
)(UploadInput);
