import React from 'react';
import PropTypes from 'prop-types';
import DateConverter from '../../../../utils/DateConverter';

const PermanentDeleteDueDt = props => {
    const {
        file: { drive },
        cellStyle
    } = props;
    const value = drive.permDeleteDueDt ? DateConverter.convert(drive.permDeleteDueDt) : '-';
    return (
        <div className="grid-cell files-delDueDt-cell ellipsis" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

PermanentDeleteDueDt.defaultProps = {
    cellStyle: null
};

PermanentDeleteDueDt.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default PermanentDeleteDueDt;
