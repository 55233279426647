/* eslint-disable */
import React, { Component } from 'react';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import SelectBox from '../SelectBox';
import SettingsRest from '../../../apis/SettingsRest';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DriveWebSocket from '../../../utils/DriveWebSocket';
import HelpCenterPopup from '../../containers/Popup/HelpCenterPopup';
import DriveConfigs from 'constants/DriveConfigs';
import DriveConfigRest from '../../../apis/DriveConfigRest';
import LoadingAction from '../../../redux/actions/Loading';
import PropTypes from 'prop-types';
import PcclientRest from '../../../apis/PcclientRest';

class AgentDownload extends Component {
    constructor(props) {
        super(props);

        const { user } = props;
        const { languageCode, timeZone } = user;
        this.state = {
            basicData: [],
            languageCode,
            timeZone,
            agentUrl: '',
            uploaderUrl: '',
            agentInstalled: null,
            helpCenterShow: false,
            helpCenterTel: ''
        };
    }

    componentDidMount() {
        this.getBasicData();
    }

    getBasicData = async () => {
        const { user, openLoading, closeLoading } = this.props;
        // const { timeZone } = this.state;
        const { locale } = user;
        const { timezoneDiff } = locale;

        openLoading();

        const orgData = await SettingsRest.getSettingsBasicData(user.id, timezoneDiff);
        const basicData = orgData.languageOptions;

        const domainData = await PcclientRest.getDomainUrl();
        const domainUrl = domainData.data;

        const helpCenterTel = await PcclientRest.getHelpCenterTel();

        console.log('domainUrl, ', domainUrl, 'helpCenterTel, ', helpCenterTel);

        const agentUrls = await DriveConfigRest.getConfig(DriveConfigs.AGENT_DOWNLOAD_INFO);
        const agentList = agentUrls.split(';');
        let agentUrl = '';
        const agentInstalled = await this.chkAgentInstall();

        for (const list of agentList) {
            const temp = list.split(',');
            agentUrl = temp[1];
            console.log('agent temp[1] ', temp[1]);
            if (temp[1].indexOf('[domain]') !== -1) {
                agentUrl = temp[1].replace('[domain]', domainUrl);
            }
            console.log('agentUrl ', agentUrl);
        }

        const uploaderUrls = await DriveConfigRest.getConfig(DriveConfigs.UPLOADER_DOWNLOAD_INFO);
        let uploaderUrl = '';
        if ('NONE' === uploaderUrls || _.isEmpty(uploaderUrls)) {
            console.log('uploaderUrls ', uploaderUrls);
        } else {
            const uploaderList = uploaderUrls.split(';');
            if (uploaderList.length > 0) {
                for (const list of uploaderList) {
                    const temp = list.split(',');
                    uploaderUrl = temp[1];
                    console.log('uploader temp[1] ', temp[1]);
                    if (temp[1].indexOf('[domain]') !== -1) {
                        uploaderUrl = temp[1].replace('[domain]', domainUrl);
                    }
                }
            }
        }
        console.log('uploaderUrl ', uploaderUrl);

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        console.log('agentType ', agentType);

        this.setState({ basicData, agentUrl, uploaderUrl, agentInstalled, helpCenterTel, agentType }, closeLoading);
    };

    chkAgentInstall = async () => {
        // Agent 설치 여부 체크
        // Agent 로그인 여부 Websocket을 날려서 오류가 발생하면(Login_1과 같은 설치는 되어있으나 오류가 발생하는 경우 말고) 미설치로 판단
        const { user } = this.props;
        let loginParam = [];

        loginParam.push(user.tenantId);
        loginParam.push(user.loginId); // 로그인 유저의 로그인 아이디 (ex: khw.kwon@samsung.com)
        loginParam.push('Login');
        loginParam.push('NONE');

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        const result = await DriveWebSocket(loginParam, agentType, 'Login');

        if (!result) {
            return false;
        } else {
            return true;
        }
    };

    onChangeLanguage = async languageCode => {
        const response = await SettingsRest.putLanguage(languageCode);
        const { resultCode } = response;

        if (resultCode === 200) {
            window.location.reload(true);
        }
    };

    close() {
        const { close } = this.props;
        // console.log("isClose >> ", isClose);
        close(true);

        /* if (isClose) {
            close(true);
            // window.open(window.location.origin + window.location.pathname, '_self').close();
        } else {
            close(true);
            // window.open('about:blank', '_self').close();
        }*/
    }

    downloadClientFile(url) {
        let element = document.createElement('a');
        element.setAttribute('href', url);
        document.body.appendChild(element); //<- append to body
        element.click();
        //element.remove();
    }

    helpCenter() {
        this.setState({ helpCenterShow: true });
    }

    closeHelpCenter = () => {
        this.setState({ helpCenterShow: false });
    };

    render() {
        const { intl, isClose, configs } = this.props;
        const { basicData, languageCode, agentUrl, uploaderUrl, agentInstalled, helpCenterShow, helpCenterTel } = this.state;

        const infoYnConfig = _.find(configs, ['id', 'EFL_COM_INFO_YN']);
        const infoYn = infoYnConfig ? infoYnConfig.value : '';

        let subProgramTable;

        if (uploaderUrl !== '') {
            subProgramTable = (
                <React.Fragment>
                    <div className="comm-title-bar">
                        <h5 className="article-title">
                            <FormattedMessage id="drive.title.pcClient.auxiliaryProgram" />
                        </h5>
                    </div>

                    <table className="data-table client">
                        <caption>보조 프로그램</caption>
                        <colgroup>
                            <col style={{ width: '150px' }} />
                            <col style={{ width: 'auto' }} />
                            <col style={{ width: '94px' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.name" />
                                </th>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.fnc" />
                                </th>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.file" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Drive Uploader</th>
                                <td>
                                    <div className="td-text">
                                        <FormattedMessage id="drive.text.pcClient.uploaderDesc" />
                                    </div>
                                </td>
                                <td>
                                    <a className="btn btn-sm btn-secondary" role="button" onClick={() => this.downloadClientFile(uploaderUrl)}>
                                        <span className="btn-text">
                                            <FormattedMessage id="com.download" />
                                        </span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.pcClient.title" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => this.close(isClose)}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <If condition={!isClose}>
                        <span className="select-type-box" style={{ width: '120px' }}>
                            {basicData.length > 0 && <SelectBox className="selectpicker" type="box" options={basicData} value={languageCode} onChange={this.onChangeLanguage} dataWidth={120} />}
                        </span>
                    </If>
                    <div className="comm-title-bar" style={isClose ? { marginTop: '0px' } : {}}>
                        <h5 className="article-title">
                            <FormattedMessage id="drive.title.pcClient.requiredProgram" />
                        </h5>
                    </div>
                    <div className="box-text">
                        <ul className="hyphen-list">
                            <li>
                                <FormattedMessage id="drive.text.pcClient.detail1" />
                            </li>
                            <li>
                                <FormattedMessage id="drive.text.pcClient.detail2" values={{ n: helpCenterTel }} />
                            </li>
                            <li>
                                <FormattedMessage id="drive.text.pcClient.detail3" />
                            </li>
                            <li>
                                <FormattedMessage id="drive.text.pcClient.detail4" />
                            </li>
                        </ul>
                    </div>

                    <table className="data-table client">
                        <caption>필수 프로그램</caption>
                        <colgroup>
                            <col style={{ width: '150px' }} />
                            <col style={{ width: 'auto' }} />
                            <col style={{ width: '98px' }} />
                            <col style={{ width: '94px' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.name" />
                                </th>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.fnc" />
                                </th>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.status" />
                                </th>
                                <th>
                                    <FormattedMessage id="drive.title.pcClient.file" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Drive Agent</th>
                                <td>
                                    <div className="td-text">
                                        <FormattedMessage id="drive.text.pcClient.desc" />
                                    </div>
                                </td>
                                <td>
                                    {_.isNull(agentInstalled) ? (
                                        <></>
                                    ) : !agentInstalled ? (
                                        <FormattedMessage id="drive.text.pcClient.notInstalled" />
                                    ) : (
                                        <FormattedMessage id="drive.text.pcClient.installed" />
                                    )}
                                </td>
                                <td>
                                    <a className="btn btn-sm btn-secondary" role="button" onClick={() => this.downloadClientFile(agentUrl)}>
                                                <span className="btn-text">
                                                    <FormattedMessage id="com.download" />
                                                </span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {subProgramTable}
                </div>

                <div className="modal-footer">
                    <If condition={infoYn === 'Y'}>
                        <div className="modal-footer-option">
                            <a className="btn btn-lg btn-secondary" role="button" onClick={() => this.helpCenter()}>
                                <span className="btn-text">
                                    <FormattedMessage id="user.gnb.helpCenter" />
                                </span>
                            </a>
                        </div>
                    </If>
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => this.close(isClose)}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </div>
                </div>
                {helpCenterShow && <HelpCenterPopup show={helpCenterShow} handleClose={this.closeHelpCenter} />}
            </React.Fragment>
        );
    }
}

AgentDownload.propTypes = {
    openLoading: PropTypes.func.isRequired,
    closeLoading: PropTypes.func.isRequired,
    isClose: PropTypes.bool,
    close: PropTypes.func
};

AgentDownload.defaultProps = {
    isClose: false,
    close: _.noop
};

const mapStateToProps = state => ({
    user: state.auth.user,
    configs: state.config.configs
});

export default connect(
    mapStateToProps,
    {
        openLoading: () => ({ type: LoadingAction.OPEN, key: 'agentDownload' }),
        closeLoading: () => ({ type: LoadingAction.CLOSE, key: 'agentDownload' })
    }
)(injectIntl(AgentDownload));
