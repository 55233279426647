import React from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Tab = props => {
    const { changeTab, mode } = props;
    return (
        <nav>
            <ul className="nav nav-tabs nav-fill" role="tablist">
                <li className="nav-item">
                    <a
                        className={`nav-link ${mode === 'info' ? 'active' : ''}`}
                        role="button"
                        onClick={() => {
                            changeTab('info');
                        }}>
                        <FormattedMessage id="drive.tab.info" />
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${mode === 'history' ? 'active' : ''}`}
                        role="button"
                        onClick={() => {
                            changeTab('history');
                        }}>
                        <FormattedMessage id="drive.tab.history" />
                    </a>
                </li>
            </ul>
        </nav>
    );
};

Tab.propTypes = {
    changeTab: func.isRequired,
    mode: string.isRequired
};

export default Tab;
