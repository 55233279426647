class DragDrop {
    isIE() {
        return typeof document.createElement('span').dragDrop === 'function';
    }

    isDropZone(node) {
        return node && node.classList && node.classList.contains('dzdzdz');
    }
}

export default new DragDrop();
