import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FileViewerRest from '../../../apis/FileViewerRest';
import FileViewerContent from '../../presentationals/FileViewerContent';
import FileIconStyle from '../../../utils/FileIconStyle';
import { alert, popup } from '../../../utils/ModalService';
import FileOpen from '../../presentationals/FileOpen/FileOpen';

/*
 - import
1. import FileViewerPopup from '......./containers/Popup/FileViewerPopup';
2. import { viewerPopup } from ''......./utils/ModalService';

  sample
  viewerPopup(<FileViewerPopup type="NORMAL" objtId="보고자하는 파일의 objtId" fileVerSno="보고자하는 파일의 fileVerSno" onpstId="보고자 하는 파일의 onpstId" incomLinkUrl="내부링크인 경우 내부링크url"/>)
        .then(
            aa => {
            //뷰어에서 실행 클릭시 이쪽으로 리턴. 편집자가 업데이트 되어야할듯?
                return true;
            },
            bb => {
            //실행안누르고 닫은경우. 아무 변경이 없는루트.
                return false;
            }
        );

	타입코드
    일반	    NORMAL              링크가 아닌 호출
    링크일반	LINK_NORMAL         기존링크, 링크복사를 통해 만들어진 링크에서 호출시
    링크(타입)	LINK_TYPE_NORMAL    요청시 생성된 타입이 있는 링크에서 호출시

 */
class FileViewerPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewerInfo: {},
            openFile: false
        };
        this.timerId = 0;
        this.timerCount = 0;
    }

    setTimer() {
        this.timerCount += 1;
        this.timerId = setInterval(() => {
            this.viewerInfoSet();
        }, 3000);
    }

    clearTimer() {
        clearInterval(this.timerId);
        this.timerId = 0;
    }

    componentDidMount() {
        this.viewerInfoSet();
    }

    componentDidUpdate(prevProps, prevState) {
        const { viewerInfo } = this.state;
        const updatedType = viewerInfo.viewerType;
        const prevType = prevState.viewerInfo.viewerType;
        if (updatedType === 'CONVERTING' && this.timerId === 0) {
            this.setTimer();
        }

        if (updatedType !== 'CONVERTING' && prevType === updatedType) {
            this.clearTimer();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    viewerInfoSet() {
        const { objtId, fileVerSno, onpstId, complianceYn, incomLinkUrl, type } = this.props;
        const { viewerInfo } = this.state;
        this.clearTimer();

        // 10 분 초과하면 에러
        if (this.timerCount === 200) {
            viewerInfo.viewerType = 'ERROR';
            this.setState({ viewerInfo });
            return;
        }

        if (type === 'LINK_NORMAL') {
            FileViewerRest.getViewerInfoIncomLink(objtId, incomLinkUrl, complianceYn).then(
                result => {
                    this.postProcessGetViewerInfoIncomLink(result, type);
                },
                () => {
                    this.postProcessGetViewerInfoIncomLinkError();
                }
            );
        } else if (type === 'LINK_TYPE_NORMAL') {
            FileViewerRest.getViewerInfoIncomTypeLink(objtId, incomLinkUrl, complianceYn).then(
                result => {
                    this.postProcessGetViewerInfoIncomLink(result, type);
                },
                () => {
                    this.postProcessGetViewerInfoIncomLinkError();
                }
            );
        } else {
            FileViewerRest.getViewerInfoNormal(objtId, fileVerSno, onpstId, complianceYn).then(
                result => {
                    this.postProcessGetViewerInfoIncomLink(result, type);
                },
                () => {
                    this.postProcessGetViewerInfoIncomLinkError();
                }
            );
        }
    }

    postProcessGetViewerInfoIncomLink(result, type) {
        const { intl } = this.props;
        const viewerErrorMessageKey = 'drive.alert.viewer.cannotOpen';
        this.clearTimer();
        if (result.resultCode !== 200) {
            alert(intl.formatMessage({ id: viewerErrorMessageKey })).then(() => {
                this.closeViewer();
            });
            this.closeViewer();
        } else {
            const { sharePolicy } = result.data;
            const { openFile } = sharePolicy;

            const viewerInfo = result.data;
            viewerInfo.type = type;

            this.setState({ viewerInfo, openFile });
        }
    }

    postProcessGetViewerInfoIncomLinkError() {
        const { intl } = this.props;
        const viewerErrorMessageKey = 'drive.alert.viewer.cannotOpen';
        this.clearTimer();
        alert(intl.formatMessage({ id: viewerErrorMessageKey })).then(() => {
            this.closeViewer();
        });
    }

    closeViewer(rtnVal) {
        const { viewerInfo } = this.state;
        const { viewerType } = viewerInfo;
        if (viewerType === 'PDF' && window.$('#ifrPdfium') != null) {
            window.$('#ifrPdfium')[0].contentWindow.location.reload();
            setTimeout(function() {
                window.$('#ifrPdfium').remove();
            }, 200);
        }
        const { isDirect, close } = this.props;
        if (isDirect) {
            close(true);
        } else {
            close(rtnVal);
        }
    }

    executeFile() {
        const { viewerInfo } = this.state;
        popup(<FileOpen file={viewerInfo} />).then(
            () => {
                this.closeViewer(true);
            },
            () => {
                this.closeViewer(true);
            }
        );
    }

    getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    getViewOrEditStyle = (sharePolicy = {}) => {
        const { versionup: policyVersionUp = false } = sharePolicy;
        if (policyVersionUp) {
            return 'ic-24-modify';
        }
        return 'ic-24-open';
    };

    getViewOrEditTitle = (sharePolicy = {}) => {
        const { versionup: policyVersionUp = false } = sharePolicy;
        if (policyVersionUp) {
            return 'drive.button.viewer.openEdit';
        }
        return 'drive.button.viewer.openView';
    };

    render() {
        const { viewerInfo, openFile } = this.state;
        const { intl } = this.props;
        const isEmpty = _.isEmpty(viewerInfo);

        return (
            <React.Fragment>
                <div className="viewer-header" id="viewer-header">
                    <div className="viewer-title">
                        <div className="g-inline-flex">
                            <div className="item-file">
                                <i className={FileIconStyle.getStyle('ic-file-sm', viewerInfo.objtNm)} />
                                <div className="file" title={viewerInfo.objtNm}>
                                    <span className="file-name">{this.getFileName(viewerInfo.objtNm)}</span>
                                    {this.getExtension(viewerInfo.objtNm) !== '' && <span className="file-extension">{this.getExtension(viewerInfo.objtNm)}</span>}
                                </div>
                            </div>
                            <div className="next divider">
                                <span className="version">
                                    <FormattedMessage id="drive.text.viewer.version" /> {viewerInfo.fileVerSno}
                                </span>
                            </div>
                            <div className="next divider">
                                <span className="authority">{viewerInfo.authCdNm}</span>
                            </div>
                        </div>
                    </div>
                    <ul className="viewer-menu">
                        {openFile && (
                            <li>
                                <a
                                    className="btn-ic-nor"
                                    role="button"
                                    title={intl.formatMessage({ id: this.getViewOrEditTitle(viewerInfo.sharePolicy) })}
                                    onClick={() => {
                                        this.executeFile();
                                    }}>
                                    <i className={this.getViewOrEditStyle(viewerInfo.sharePolicy)} />
                                </a>
                            </li>
                        )}
                        <li>
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={intl.formatMessage({ id: 'com.close' })}
                                onClick={() => {
                                    // close();
                                    this.closeViewer();
                                }}>
                                <i className="ic-24-close" />
                            </a>
                        </li>
                    </ul>
                </div>
                {!isEmpty && <FileViewerContent viewerInfo={viewerInfo} />}
            </React.Fragment>
        );
    }
}

FileViewerPopup.propTypes = {
    objtId: PropTypes.string,
    fileVerSno: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onpstId: PropTypes.string,
    complianceYn: PropTypes.string,
    incomLinkUrl: PropTypes.string,
    close: PropTypes.func,
    isDirect: PropTypes.bool,
    intl: PropTypes.object,
    type: PropTypes.string
};
FileViewerPopup.defaultProps = {
    objtId: '',
    fileVerSno: '',
    onpstId: '',
    complianceYn: '',
    incomLinkUrl: '',
    close: () => {},
    isDirect: false,
    intl: {},
    type: ''
};

export default injectIntl(FileViewerPopup);
