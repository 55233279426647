/* eslint-disable */
import React, {Component} from 'react';
import FileIcon from '../FileMenu/FileIcon';
import { viewerPopup } from '../../../utils/ModalService'
import FileIconStyle from '../../../utils/FileIconStyle';
import Tooltips from '../../presentationals/Tooltips.jsx';
import FileViewerPopup from '../../containers/Popup/FileViewerPopup';
import DateConverter from '../../../utils/DateConverter';
import { Choose, Otherwise, When, If } from 'jsx-control-statements';
import NameCard from '../../containers/NameCard/NameCard';
import { injectIntl } from 'react-intl';
import PropTypes from "prop-types";

class VersionItem extends Component{

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    openFileVersionViewPopup = (version) => {
        viewerPopup(<FileViewerPopup type="NORMAL" objtId={version.objtId} fileVerSno={version.fileVerSno} onpstId={version.onpstId} />).then(
            () => {
                // 뷰어에서 실행 클릭시 이쪽으로 리턴. 편집자가 업데이트 되어야할듯?
                return true;
            },
            () => {
                // 실행안누르고 닫은경우. 아무 변경이 없는루트.
                return false;
            }
        );
    };

    fileIconCustom = {
        // statusCallback: isOpen => {
        //     this.setState({ hover: isOpen });
        // },
        openType: 'left',
        moreGroupType: 'version'
    };

    render() {

        const { version , versionLoad , uploading  , changeRevertStatus , actionPolicy , intl , listSize } = this.props;
        const iconSize = 'ic-file-sm';
        const iconClass = FileIconStyle.getStyle(iconSize, version.upldPhyFileExtNm);
        const versionMsg = intl.formatMessage({ id: 'drive.text.version' });
        const versionTitle = versionMsg + version.fileVerSno;
        const fileVerAttrVal = version.fileVerAttrVal;
        const versionCpgsId = 'CpgsToolTip' + version.fileVerSno;
        version.actionPolicy = actionPolicy;
        version.uploading = uploading;
        version.listSize = listSize;

        this.fileIconCustom.changeRevertStatus = changeRevertStatus;

        return (
            <div className="grid-row" style={this.props.style} key={version.fileVerSno}>
                <div className="grid-cell version-cell">
                    <strong title={versionTitle}>{versionMsg} {version.fileVerSno}</strong>
                    <If condition={fileVerAttrVal.length > 0}>
                        <i className="ic-20-warning" id={versionCpgsId}/>
                        <Tooltips targetId={versionCpgsId} placement="top">
                            <div className='guide'>{fileVerAttrVal}</div>
                        </Tooltips>
                    </If>
                </div>
                <div className="grid-cell link-cell">
                    <div className="item-file">
                        <i className={iconClass}></i>
                        <div className='file file-link' title="" onClick ={(e)=>{
                                this.openFileVersionViewPopup(version);
                                e.stopPropagation();
                        }}>
                            <span className="file-name" title={version.upldPhyFileNm}>{version.upldPhyFileNm}</span>
                            <If condition={!!version.upldPhyFileExtNm}>
                                <span className="file-extension">{version.upldPhyFileExtNm}</span>
                            </If>
                        </div>
                    </div>
                    <div className="g-inline-flex">
                        <div className="previous">{DateConverter.convert(version.fileVerStatChgDt)}</div>
                        <div className="ellipsis comma"><span id={`version-${version.fileVerSno}-div`} className="name has-name-card" title={version.fileVerStatChgUserNm}>{version.fileVerStatChgUserNm}</span>
                        </div>
                    </div>

                </div>
                <div className="grid-cell option-cell">
                    <FileIcon groupType="version" file={version} callback={versionLoad} custom={this.fileIconCustom} />
                </div>
                <NameCard target={`version-${version.fileVerSno}-div`} type={'user'} id={version.fileVerStatChgUserId} />
            </div>
        );
    }
}

VersionItem.propTypes = {
    version: PropTypes.object,
    uploading: PropTypes.bool,
    versionLoad: PropTypes.func,
    actionPolicy: PropTypes.object,
    listSize : PropTypes.number
};

VersionItem.defaultProps = {
    version: {},
    uploading: false,
    versionLoad: () => {},
    actionPolicy: {},
    listSize: 0
};

export default injectIntl(VersionItem);
