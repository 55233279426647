import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserCard from './UserCard';
import DeptCard from './DeptCard';

const NameCard = props => {
    const { type } = props;
    const omitted = _.omit(props, ['type']);
    if (type === 'dept') {
        return <DeptCard {...omitted} />;
    }

    return <UserCard {...omitted} />;
};

NameCard.propTypes = {
    id: PropTypes.string.isRequired,
    target: PropTypes.string,
    placement: PropTypes.string,
    type: PropTypes.string
};

NameCard.defaultProps = {
    target: 'popoverLegacy',
    placement: 'auto',
    type: 'user'
};

export default NameCard;
