import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DriveDetail from './DriveDetail';
import DriveFiles from './DriveFiles/DriveFiles';
import LNBFolderTree from './LNBFolderTree';
import TreeNode from '../presentationals/TreeNode';

class DriveBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailObject: {},
            detailOpen: true
        };
    }

    setDetailFile = (file, open, refreshTargets) => {
        const { detailOpen, detailObject } = this.state;
        const { drive: { objtId: openedObjtId } = {} } = detailObject;
        // 열려있는 상태거나, 정보/이력등 여는 액션으로 접근한 경우만 open함
        if (detailOpen || open) {
            // 해당 파일이 갱신되었을때도 변경해줌
            if (refreshTargets && refreshTargets.length) {
                refreshTargets.some(target => {
                    const { drive: { objtId } = {} } = target;
                    if (openedObjtId === objtId) {
                        this.setState({
                            detailObject: target,
                            detailOpen: true
                        });
                        return true;
                    }
                    return false;
                });
            } else {
                this.setState({
                    detailObject: file,
                    detailOpen: true
                });
            }
        }
    };

    closeDetail = () => {
        this.setState({ detailOpen: false });
    };

    render() {
        const { detailObject, detailOpen } = this.state;
        const {
            pageClass,
            match: {
                params: { folderId }
            },
            location: { state: { onpstId, maxAuthCd } = {}, search },
            history,
            setTotal,
            location,
            match
        } = this.props;
        const { pageId } = pageClass;
        return (
            <div className="drv-list">
                {pageId === 'own' ? (
                    <LNBFolderTree type={pageId} folderId={folderId} location={location} history={history} onpstId={onpstId} pageClass={pageClass} match={match} treeNodeRenderer={TreeNode} />
                ) : (
                    <div />
                )}
                <DriveFiles
                    setDetailFile={this.setDetailFile}
                    closeDetailFile={this.closeDetail}
                    setTotal={setTotal}
                    folderId={folderId}
                    maxAuthCd={maxAuthCd}
                    onpstId={onpstId}
                    search={search}
                    pageClass={pageClass}
                    history={history}
                    match={match}
                />
                <DriveDetail detailObject={detailObject} isOpen={detailOpen} history={history} close={this.closeDetail} />
            </div>
        );
    }
}

DriveBody.propTypes = {
    pageClass: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setTotal: PropTypes.func
};

DriveBody.defaultProps = {
    setTotal: () => {}
};

export default DriveBody;
