import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NameCard from '../../../../containers/NameCard/NameCard';
import CharacterImageContainer from '../../../../containers/CharacterImage/CharacterImageContainer';

const Owner = ({ owner, style, intl }) => {
    if (_.isEmpty(owner)) return '';

    return (
        <div className="grid-row" style={style}>
            <div className="grid-cell user-cell">
                <div className="item-user">
                    <CharacterImageContainer
                        objtId={_.isEmpty(owner.objtShareTgtPhotoFilePathVal) ? '' : owner.objtShareTgtPhotoFilePathVal}
                        userId={owner.objtShareTgtId}
                        text={owner.objtShareTgtNm}
                        size="md"
                        userType={owner.objtShareTgtUserSectCd}
                        characterType="user"
                    />
                    <div className="user-profile">
                        <div title={`${owner.objtShareTgtNm} ${_.toString(owner.objtShareTgtCopNm)}`}>
                            <span id="owner-div" className="name has-name-card">
                                {owner.objtShareTgtNm}
                            </span>
                            <span className="position">{owner.objtShareTgtCopNm}</span>
                        </div>
                        <span className="partname">{owner.objtShareTgtDeptNm}</span>
                    </div>
                </div>
            </div>
            <div className="grid-cell inclusion-cell" />
            <div className="grid-cell authority-cell">
                <span className="authority">{intl.formatMessage({ id: 'drive.text.owner' })}</span>
            </div>
            <div className="grid-cell del-cell" />
            <NameCard target="owner-div" type="user" id={owner.objtShareTgtId} />
        </div>
    );
};

Owner.propTypes = {
    owner: PropTypes.object,
    style: PropTypes.object,
    intl: PropTypes.object
};

Owner.defaultProps = {
    owner: {},
    style: {},
    intl: {}
};

export default injectIntl(Owner);
