import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import _ from 'lodash';
import Base from './Base';
import FoldersRest from '../../apis/FoldersRest';
import { LOW_HEIGHT } from '../../components/containers/DriveFiles/HeaderRow/SettingHeader';
import GroupRow from '../../components/containers/DriveFiles/Row/GroupRow';
import DuplicatedTab from '../../components/presentationals/Duplicated/DuplicatedTab';
import DuplicatedBody from '../../components/presentationals/Duplicated/DuplicatedBody';

class Duplicated extends Base {
    pageId = 'duplicated';

    trackTotal = true;

    menu = {
        header: {
            iconGroupType: null, // 진입 시 icon 영역, 선택 안했을때 나오는 버튼 없음
            iconGroupTypeMulti: 'duplicatedMulti' // 멀티선택 시 icon 영역
        },
        row: {
            iconGroupType: 'duplicated', // icon 영역
            menuGroupType: 'duplicated', // 우클릭 영역 및 헤더 더보기 icon 영역
            floatingIconGroupType: null, // 파일명 옆 floating icon 영역
            moreGroupType: 'duplicated' // 각 행 icon 더보기 버튼 영역
        }
    };

    getDefaultSortColumn() {
        return { sortField: 'objtNm', sortOrder: 'ASC' };
    }

    sortPossibleColumns() {
        return ['objtNm'];
    }

    searchFunction(params) {
        return FoldersRest.getFolders(this.pageId, params);
    }

    showHeaderCheckBox() {
        return true;
    }

    isHideHeaderMenu() {
        return true;
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        let groupIdx = 0;
        const groups = _.groupBy(list, 'drive.objtNm');
        const groupInfo = _.reduce(
            groups,
            (result, value, key) => {
                const {
                    drive: { objtId: startRowId }
                } = _.head(value);
                const {
                    drive: { objtId: endRowId }
                } = _.last(value);
                result.groupTypes.push({ groupId: key, groupType: groupIdx % 2 === 0 ? 'even' : 'odd' });
                groupIdx += 1;
                result.startRows.push(startRowId);
                result.endRows.push(endRowId);
                return result;
            },
            { groupTypes: [], startRows: [], endRows: [] }
        );
        const rowData = list[index];
        const {
            drive: { objtNm, objtId }
        } = rowData;
        const { groupTypes, startRows, endRows } = groupInfo;
        const { groupType } = groupTypes.find(({ groupId }) => groupId === objtNm);
        const isStartRow = startRows.some(row => row === objtId);
        const isEndRow = endRows.some(row => row === objtId);
        return <GroupRow file={rowData} index={index} groupType={groupType} isStartRow={isStartRow} isEndRow={isEndRow} style={style} />;
    }

    pageBuilder({ state: { firstApproach, list, viewHeight } }) {
        return (
            <section className="drv-file-panel">
                <DuplicatedTab />
                <DuplicatedBody
                    className={`comm-grid-list react-list ${LOW_HEIGHT === viewHeight ? 'list-sm' : ''}`}
                    style={{ display: firstApproach ? '' : 'none' }}
                    isHidden={list && !list.length}
                />
            </section>
        );
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-dupfiles" />
                    <FormattedHTMLMessage id="drive.text.list.empty.duplicated" tagName="p" />
                </div>
            </div>
        );
    }
}

export default new Duplicated();
