import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import CustomList from '../../../presentationals/List/CustomList';
import RequestFileItem from './RequestFileItem';

class RequestFileListPopup extends Component {
    closePopup = () => {
        const { close } = this.props;
        close();
    };

    rowBuilder = ({ style, index }) => {
        const { files } = this.props;
        return <RequestFileItem file={files[index]} style={style} />;
    };

    render() {
        const { intl, files } = this.props;
        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'drive.button.share.viewAllItems' })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={this.closePopup}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className="pop-grid-list view-mode-type">
                        <div className="comm-grid-list react-list">
                            <div>
                                <CustomList className="grid-row-group" customScroll={true} virtualized={true} height={46 * 8} rowCount={files.length} rowHeight={46} rowBuilder={this.rowBuilder} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" onClick={this.closePopup}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

RequestFileListPopup.propTypes = {
    close: PropTypes.func,
    files: PropTypes.array.isRequired,
    intl: PropTypes.object
};

RequestFileListPopup.defaultProps = {
    close: () => {},
    intl: {}
};

export default injectIntl(RequestFileListPopup);
