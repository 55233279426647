import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withFilesContext } from '../DriveFilesContext';
import FileReloadAction from '../../../../redux/actions/FileReload';
import FileCheckAction from '../../../../redux/actions/FileCheck';
import { getMaxCount } from '../../../../redux/reducers/FileCheck';
import { alert as modalAlert } from '../../../../utils/ModalService';

class CheckBoxHeader extends React.PureComponent {
    onChange = checked => {
        const {
            removeAll,
            sendCheck,
            context: {
                state: { list },
                actions: { setGridSettings },
                props: { pageClass }
            }
        } = this.props;

        const maxCount = getMaxCount();

        if (checked) {
            removeAll();
            setGridSettings({});
        } else {
            if (pageClass.isCheckableOverMax(list, maxCount)) {
                modalAlert({ id: 'drive.alert.list.select.max.all', values: maxCount });
            }

            list.forEach(file => pageClass.isCheckableFile(file) && sendCheck(file));
            setGridSettings({});
        }
    };

    render() {
        const {
            context: {
                state: { list = [] },
                props: { pageClass, folderId }
            },
            files = []
        } = this.props;
        const show = files.length > 0;
        const checkableFiles = pageClass.getCheckableFiles(list) || [];
        let checked = true;
        if (!show || (checkableFiles.length !== files.length && files.length !== getMaxCount())) {
            checked = false;
        }

        return (
            <div className="grid-cell files-check-cell">
                <div className="form-check" style={{ display: show ? 'block' : '' }}>
                    <label className="form-check-label">
                        {pageClass.showHeaderCheckBox(folderId, list, files) && (
                            <>
                                <input className="form-check-input" type="checkbox" checked={checked} onChange={() => this.onChange(checked)} />
                                <span className="label-text" role="button" />
                            </>
                        )}
                    </label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    files: state.fileCheck.checkedFiles
});

CheckBoxHeader.defaultProps = {
    files: undefined
};

CheckBoxHeader.propTypes = {
    files: PropTypes.array,
    removeAll: PropTypes.func.isRequired,
    context: PropTypes.object.isRequired,
    sendCheck: PropTypes.func.isRequired
};

CheckBoxHeader.displayName = 'CheckBoxHeader';
export default connect(
    mapStateToProps,
    {
        removeAll: () => ({ type: FileReloadAction.EXECUTE, data: { reloadType: 'uncheckedAll' } }),
        sendCheck: file => ({ type: FileCheckAction.ADD, file })
    }
)(withFilesContext(CheckBoxHeader, ['state.list']));
