import 'react-dates/initialize';
import _ from 'lodash';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import FocusedInputShape from 'src/constants/FocusedInputShape';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import isInclusivelyAfterDay from '../../utils/isInclusivelyAfterDay';
import { DateRangePickerPhrases } from '../../constants/defaultPhrases';
import CommonDatePickerWrapper from './CommonDatePickerWrapper';

const propTypes = {
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    stateDateWrapper: PropTypes.func,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
    onDatesChange: PropTypes.func,
    focusedInput: FocusedInputShape,
    onFocusChange: PropTypes.func
};

const defaultProps = {
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,

    // input related props
    startDateId: 'startDate',
    startDatePlaceholderText: 'Start Date',
    endDateId: 'endDate',
    endDatePlaceholderText: 'End Date',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDates: false,
    customArrowIcon: null,
    customCloseIcon: null,
    regular: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: 'horizontal',
    anchorDirection: 'left',
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 1,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,

    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    isDayBlocked: () => false,
    isDayHighlighted: () => false,

    // internationalization
    monthFormat: 'YYYY.MM',
    phrases: DateRangePickerPhrases,

    stateDateWrapper: date => date,

    small: true,
    enableOutsideDays: false,
    showDefaultInputIcon: true,
    transitionDuration: 0,
    inputIconPosition: 'after',
    hideKeyboardShortcutsPanel: true,
    block: false,
    onDatesChange: _.noop,
    customInputIcon: <i className="ic-20-calendar" />,
    minDate: moment(),
    maxDate: null
};

class DateRangePickerWrapper extends CommonDatePickerWrapper {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.intl = intl;

        let focusedInput;
        if (props.autoFocus) {
            focusedInput = 'startDate';
        } else if (props.autoFocusEndDate) {
            focusedInput = 'endDate';
        }

        let startDate = props.initialStartDate ? props.initialStartDate : null;
        if (startDate === null) {
            startDate = props.startDate ? props.startDate : null;
        }
        const selDate = !!startDate && moment.isMoment(startDate) ? startDate : moment();

        this.state = {
            startDate: props.initialStartDate ? props.initialStartDate : null,
            endDate: props.initialEndDate ? props.initialEndDate : null,
            focusedInput,
            selYear: Number(selDate.format('YYYY')),
            selMonth: Number(selDate.format('MM'))
        };
    }

    onDatesChange = ({ startDate, endDate }) => {
        const { onDatesChange } = this.props;
        onDatesChange({ startDate, endDate });
        this.setState({ startDate, endDate });
    };

    onFocusedInputChange = focusedInput => {
        const { startDate, endDate } = this.state;

        let selDate = moment();
        if (focusedInput === 'endDate' && !!endDate && moment.isMoment(endDate)) {
            selDate = endDate;
        } else if (focusedInput === 'startDate' && !!startDate && moment.isMoment(startDate)) {
            selDate = startDate;
        }

        this.setState({
            focusedInput,
            direction: 'UNKNOWN',
            selYear: Number(selDate.format('YYYY')),
            selMonth: Number(selDate.format('MM'))
        });
    };

    getInitialVisibleMonth = () => {
        const { selYear, selMonth } = this.state;
        return moment(`${selYear}-${_.padStart(selMonth, 2, '0')}-01`, 'YYYY-MM-DD');
    };

    getOutsideRange = day => {
        const { isOutsideRange, minDate, maxDate } = this.props;
        if (!_.isEmpty(isOutsideRange)) {
            return isOutsideRange;
        }

        if (!_.isEmpty(minDate) && !_.isEmpty(maxDate)) {
            return !isInclusivelyAfterDay(day, minDate) || isInclusivelyAfterDay(day, maxDate);
        }

        if (_.isEmpty(minDate) && !_.isEmpty(maxDate)) {
            return isInclusivelyAfterDay(day, maxDate);
        }

        if (!_.isEmpty(minDate) && _.isEmpty(maxDate)) {
            return !isInclusivelyAfterDay(day, minDate);
        }

        return !isInclusivelyAfterDay(day, moment());
    };

    render() {
        const { focusedInput, selYear, selMonth } = this.state;
        let { startDate, endDate } = this.state;
        const { minDate, maxDate } = this.props;
        const props = _.omit(this.props, ['autoFocus', 'autoFocusEndDate', 'initialStartDate', 'initialEndDate', 'stateDateWrapper', 'minDate', 'maxDate']);

        const propsStartDate = this.props.startDate;
        const propsEndDate = this.props.endDate;

        if ((startDate != null && propsStartDate === undefined) || (startDate != null && propsStartDate != null)) startDate = propsStartDate;
        if ((endDate != null && propsEndDate === undefined) || (endDate != null && propsEndDate != null)) endDate = propsEndDate;

        return (
            <DateRangePicker
                {...props}
                startDate={startDate} // momentPropTypes.momentObj or null,
                endDate={endDate} // momentPropTypes.momentObj or null,
                onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                focusedInput={focusedInput}
                onFocusChange={this.onFocusedInputChange} // PropTypes.func.isRequired,
                initialVisibleMonth={this.getInitialVisibleMonth}
                displayFormat="YYYY-MM-DD"
                isOutsideRange={this.getOutsideRange}
                renderMonthElement={this.renderMonthElement}
                startDatePlaceholderText={this.intl.formatMessage({ id: `user.placeHolder.approval.exceptPcControl.startDate` })}
                endDatePlaceholderText={this.intl.formatMessage({ id: `user.placeHolder.approval.exceptPcControl.endDate` })}
                minDate={minDate}
                maxDate={maxDate}
                navPrev={this.renderPrevArrow(selYear, selMonth)}
                navNext={this.renderNextArrow(selYear, selMonth)}
            />
        );
    }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default injectIntl(DateRangePickerWrapper);
