import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TermsRest from 'apis/TermsRest';
import SelectBox from 'presentationals/SelectBox';
import CustomModal from 'containers/CustomModal';

class ServiceTermsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { serviceTerms: [] };
    }

    componentDidMount() {
        const { show } = this.props;
        if (show) this.getServiceTerms();
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show !== show && show) {
            this.getServiceTerms();
        }
    }

    getServiceTerms = () => {
        const params = { limit: 100 };
        TermsRest.getServiceTerms(params).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { list } = data;
            if (!list || list.length === 0) return;
            TermsRest.getTerm(list[0].termsId).then(detailsResponse => {
                const { resultCode: detailsResultCode, data: detailsData } = detailsResponse;
                if (detailsResultCode !== 200) {
                    this.setState({ serviceTerms: list });
                    return;
                }
                const { termsId, termsContent } = detailsData;
                this.setState({ serviceTerms: list, termsId, termsContent });
            });
        });
    };

    onServiceTermsChange = value => {
        const { termsId } = this.state;
        if (termsId === value) return;
        TermsRest.getTerm(value).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { termsId: changed, termsContent } = data;
            this.setState({ termsId: changed, termsContent });
        });
    };

    render() {
        const { show, handleClose, intl } = this.props;
        const { serviceTerms, termsId, termsContent } = this.state;
        const serviceTermsList = _.map(serviceTerms, item => {
            const { termsId: value, termsRegDt } = item;
            return { value, message: intl.formatMessage({ id: 'com.text.serviceTerms.policy.history' }, { date: moment(termsRegDt).format('YYYY-MM-DD') }) };
        });
        return (
            <CustomModal isOpen={show} fade={true} size="lg" backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'user.text.serviceTermsPolicy' })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={handleClose}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <span className="select-type-box">
                        <SelectBox options={serviceTermsList} onChange={this.onServiceTermsChange} value={termsId} type="box" style={{ width: 254 }} />
                    </span>
                    <div className="form-group">
                        <textarea className="form-control textarea-lg document" readOnly={true} value={termsContent} />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={handleClose}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

ServiceTermsPopup.propTypes = {
    intl: PropTypes.object.isRequired,
    show: PropTypes.bool,
    handleClose: PropTypes.func
};

ServiceTermsPopup.defaultProps = {
    show: false,
    handleClose: _.noop
};

export default injectIntl(ServiceTermsPopup);
