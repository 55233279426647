import React, { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Choose, If, Otherwise, When } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { LANGUAGE_CODE, SUMMARY_TOOLTIP_DIRECTION } from '../../Common/SummaryConstant';
import SummaryTooltipIcon from '../../Common/Tooltip/SummaryTooltipIcon';

const LLMSelectDialog = memo(({ llmSelectDialogRef, intl }) => {
    const [isGuideShow, setIsGuideShow] = useState(false);
    const [guideTargetLLM, setGuideTargetLLM] = useState({});

    const languageCode = useSelector(({ auth }) => auth.user.locale.languageCode);
    const isOpen = useSelector(({ summary }) => summary.llmSelectDialog.isOpen);
    const { llmList, selectedLLM } = useSelector(({ summary }) => summary.global, shallowEqual);
    const { llmType: selectedLLMType } = selectedLLM;

    const dispatch = useDispatch();

    const llmDescription = useMemo(() => {
        const { extra: { description, descriptionEn, descriptionZh } = {} } = guideTargetLLM;

        switch (languageCode) {
            case LANGUAGE_CODE.korean:
                return description;
            case LANGUAGE_CODE.english:
            case LANGUAGE_CODE.deutsch:
                return descriptionEn;
            case LANGUAGE_CODE.chinese:
                return descriptionZh;
            default:
                return description;
        }
    }, [guideTargetLLM, languageCode]);

    const handleClickLLM = llm => {
        dispatch(summaryActions.global.selectLLM({ llm }));
        dispatch(summaryActions.llmSelectDialog.close());
    };

    const handleMouseEnterLLM = llm => {
        setIsGuideShow(true);
        setGuideTargetLLM(llm);
    };

    const handleClickCloseButton = () => {
        dispatch(summaryActions.llmSelectDialog.close());
    };

    return (
        <div className={classnames('overlay-layer-wrapper', isOpen && 'show')} ref={llmSelectDialogRef}>
            <div className="overlay-layer">
                <div className="layer-content layer-sm">
                    <div className="layer-header hd-gray">
                        <div className="layer-header-lt">
                            <h1 className="layer-title">
                                <FormattedMessage id="drive.text.copilot.summary.llmSelect" />
                            </h1>
                            <SummaryTooltipIcon direction={SUMMARY_TOOLTIP_DIRECTION.right} content={{ messageId: 'drive.tooltip.copilot.summary.llmSelect' }} />
                        </div>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close" />
                        </a>
                    </div>
                    <div className="layer-body">
                        <div className="llm-model">
                            <nav className="llm-lst">
                                <ul>
                                    {llmList.map(llm => {
                                        const {
                                            llmType,
                                            llmName,
                                            extra: { llmDefaultYn }
                                        } = llm;

                                        return (
                                            <li className={classnames(llmType === selectedLLMType && 'active')} key={llmType}>
                                                <a className="llm-lst-btn" role="button" onClick={() => handleClickLLM(llm)} onMouseEnter={() => handleMouseEnterLLM(llm)}>
                                                    <span className="btn-text">{llmName}</span>
                                                    <If condition={llmDefaultYn === 'Y'}>
                                                        <em className="badge">
                                                            <FormattedMessage id="drive.text.copilot.summary.defalutLLM" />
                                                        </em>
                                                    </If>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                            <div className="llm-view">
                                <div className="scroll-bar">
                                    <Choose>
                                        <When condition={isGuideShow}>
                                            <div className="llm-guide" style={{ display: 'block' }}>
                                                <strong>{guideTargetLLM.llmName}</strong>
                                                <pre>{llmDescription}</pre>
                                            </div>
                                        </When>
                                        <Otherwise>
                                            <div className="box-empty">
                                                <div className="message">
                                                    <p>
                                                        <FormattedHTMLMessage tagName="span" id="drive.guideText.copilot.summary.showLLMInfo" />
                                                    </p>
                                                </div>
                                            </div>
                                        </Otherwise>
                                    </Choose>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

LLMSelectDialog.propTypes = {
    llmSelectDialogRef: PropTypes.object.isRequired
};

LLMSelectDialog.defaultProps = {};

export default injectIntl(LLMSelectDialog);
