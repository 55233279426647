/* eslint-disable */
import React from 'react';
import { array } from 'prop-types';
import { injectIntl } from 'react-intl';
import { If } from 'jsx-control-statements';
import { getAuthMap } from '../../../../utils/AuthCd';

const ChangeAuth = props => {
    const { shareInfo , intl } = props;

    const authCd = getAuthMap();

    const lwrDeptIcluCd = {
        'Y' : intl.formatMessage({ id: 'com.text.include'}) ,
        'N' : intl.formatMessage({ id: 'com.text.notInclude' })
    };

    let topShareInfo = shareInfo[0];

    let changeAuthCd  = authCd[topShareInfo.prextAuthCd] + ' → ' + authCd[topShareInfo.authCd];
    let changeLwrDeptIcluYn  = intl.formatMessage({ id: 'drive.text.lwrDeptLcluYn' }) + ' : ' + lwrDeptIcluCd[topShareInfo.prextLwrDeptIcluYn] + ' → ' + lwrDeptIcluCd[topShareInfo.lwrDeptIcluYn];

    return (
            <>
                <If condition={topShareInfo.prextAuthCd !== topShareInfo.authCd}>
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={changeAuthCd}>{changeAuthCd}</div>
                    </div>
                </If>

                <If condition={topShareInfo.prextLwrDeptIcluYn !== topShareInfo.lwrDeptIcluYn}>
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={changeLwrDeptIcluYn}>{changeLwrDeptIcluYn}</div>
                    </div>
                </If>
            </>
    );
};

ChangeAuth.propTypes = {
    shareInfo: array
};

ChangeAuth.defaultProps = {
    shareInfo:[]
};

export default injectIntl(ChangeAuth);
