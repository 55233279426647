import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, isNil } from 'lodash';
import { alert, largePopup } from 'src/utils/ModalService';
import AgentDownload from './Download/AgentDownload';

const linkChatbot = (e, link) => {
    // toast('com.toast.chatbotLink');
    window.open(`knoxim://ids=${link}`, '_self');
    e.preventDefault();
};

const openAgentDownloadPopup = infoYn => {
    if (window.navigator.platform.indexOf('Win') === -1) {
        alert({ id: 'user.text.helpcenter.manualDownload.unsupportedOS' });
    } else {
        largePopup(<AgentDownload isClose={true} infoYn={infoYn} />);
    }
};

const MainFloatButton = props => {
    const { configs, intl } = props;
    const EFL_COM_CHATBOT_YN = find(configs, ['id', 'EFL_COM_CHATBOT_YN']);
    const chatbotUseYn = isNil(EFL_COM_CHATBOT_YN) ? '' : EFL_COM_CHATBOT_YN.value;
    let chatbot;

    if (!chatbotUseYn || chatbotUseYn === 'N') {
        chatbot = <div />;
    } else {
        const link = find(configs, ['id', 'EFL_COM_CHATBOT_ID']).value;
        chatbot = (
            <>
                <dd>
                    <a
                        className="btn-fab-chatbot"
                        role="button"
                        onClick={e => linkChatbot(e, link)}
                        data-toggle="tooltip"
                        data-placement="left"
                        data-trigger="manual"
                        data-html="true"
                        title={intl.formatMessage({ id: 'com.tooltip.chatbotLink' })}>
                        {}
                    </a>
                </dd>
            </>
        );
    }

    const downloadButton = () => {
        return (
            <a
                className="btn-download"
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                data-trigger="manual"
                data-html="true"
                title={intl.formatMessage({ id: 'user.button.helpcenter.pcClientDownload' })}
                onClick={() => openAgentDownloadPopup()}>
                {' '}
            </a>
        );
    };

    const withChatbot = () => {
        return (
            <dl>
                <dt>
                    <a className="quick-help" role="button">
                        {' '}
                        {}
                    </a>
                </dt>
                <dd>
                    <a
                        className="btn-fab-download"
                        role="button"
                        data-toggle="tooltip"
                        data-placement="left"
                        data-trigger="manual"
                        data-html="true"
                        title={intl.formatMessage({ id: 'user.button.helpcenter.pcClientDownload' })}
                        onClick={openAgentDownloadPopup}>
                        {}
                    </a>
                </dd>
                {chatbot}
                <dd />
            </dl>
        );
    };

    return (
        <div className="fixed-bottom-wrap">
            {' '}
            <div className="float-menu">{chatbotUseYn === 'N' ? downloadButton() : withChatbot()}</div>
        </div>
    );
};

MainFloatButton.propTypes = {
    configs: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
    configs: state.config.configs
});

export default injectIntl(connect(mapStateToProps)(MainFloatButton));
