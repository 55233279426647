import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Request from '../../constants/Request';
import { alert as modalAlert, largePopup, toast } from '../../utils/ModalService';
import ReqSecureModePopup from '../presentationals/ReqMng/Popup/ReqSecureModePopup';
import ReqComplianceExceptionPop from '../presentationals/ReqMng/Popup/ReqComplianceExceptionPop';
import RequestComplianceExceptionPopup from '../containers/Popup/RequestException/RequestComplianceExceptionPopup';
import ReqExtendExpiredDtPop from '../presentationals/ReqMng/Popup/ReqExtendExpiredDtPop';
import RequestExceptionPopup from '../containers/Popup/RequestException/RequestExceptionPopup';
import ReqWorkGroupFolderPop from '../containers/Popup/WorkgroupFolderRequest/ManagementPopup';
import CreateRequestPopup from '../containers/Popup/WorkgroupFolderRequest/RequestCreatePopup';
import ReqWorkgroupChangeDatePopup from '../containers/Popup/WorkgroupFolderRequest/RequestChangeDatePopup';
import ReqRest from '../../apis/ReqRest';
import ApprovalPopup from '../containers/Popup/Approval/ApprovalPopup';
import { globalPopup } from '../../redux/actions';
import FoldersRest from '../../apis/FoldersRest';

const correctTypes = [
    Request.TYPE.EXCEPT_NON_COMPLIANCE,
    Request.TYPE.EXTENDS_EXPIRATION,
    Request.TYPE.USE_SECURE_FOLDER,
    Request.TYPE.CREATE_WORKGROUP,
    Request.TYPE.WORKGROUP_RETENTION_DATE,
    Request.TYPE.EXCEPT_PC_CONTROL
];

const isCorrectType = type => {
    return correctTypes.indexOf(type) !== -1;
};

const correctSystems = [Request.SYSTEM_TYPE.KNOX_DRIVE, Request.SYSTEM_TYPE.FLCM, Request.SYSTEM_TYPE.KNOX_APPROVAL];

const isCorrectSystem = system => {
    return correctSystems.indexOf(system) !== -1;
};

const getReq = async reqId => {
    const response = await ReqRest.getReq(reqId);
    const { resultCode, data, message } = response;

    if (resultCode === 200 && !_.isNil(data) && data !== '') {
        return data;
    }

    if (resultCode === 500 && message === 'IOFFICE_INTERNAL_SERVER_EXCEPTION') {
        modalAlert('com.alert.internalServerError');
    } else {
        toast(message);
    }
    return null;
};

const getFiles = async (type = '', size = 2) => {
    let pageId = '';
    if (type === Request.TYPE.EXTENDS_EXPIRATION) {
        pageId = 'expiration';
    } else if (type === Request.TYPE.EXCEPT_NON_COMPLIANCE) {
        pageId = 'compliance';
    } else {
        return [];
    }

    const result = await FoldersRest.getFolders(pageId, { size: 100 });
    const { list = [] } = result;

    if (list.length > 0) {
        return _.chain(list)
            .filter(file => {
                const { extra: { req = {} } = {} } = file;
                return _.isEmpty(req);
            })
            .take(size)
            .value();
    }
    return [
        {
            maxAuthCd: 'U80OWN',
            maxAuth: '소유자',
            drive: {
                objtId: '162124287506000401',
                objtNm: '기존만료일_내림차순.PNG',
                shareRootMrkNm: null,
                objtSectCd: 'FILE',
                onpstFolderId: '161909349352900256',
                objtDelCd: 'NORMAL',
                pathVal: '/161909349352900256/162124287506000401',
                pathValNm: '/.kyt/기존만료일_내림차순.PNG',
                objtRegDt: '2021-05-17T09:14:35+00:00',
                objtStatChgDt: '2021-03-12T09:27:23+00:00',
                objtStatChgUserId: '156868362012600356',
                objtDelDt: '0001-01-01T00:00:00+00:00',
                onpstId: '156868362012600356',
                onpstSectCd: 'INDVFOLDER',
                tagInfoVal: null,
                filePOwnerId: '156868362012600356',
                publcLinkCretYn: null,
                lastModDt: '2021-05-17T09:14:35+00:00'
            },
            version: {
                fileVerSno: 1,
                fileSize: 244843,
                lastModDt: '2021-05-17T09:14:35+00:00'
            },
            users: {
                objtStatChgUser: {
                    userNm: '백승민'
                },
                onpst: {
                    userNm: '백승민'
                }
            },
            personalization: {
                preferred: null
            },
            mine: true,
            extra: {
                sortValues: '0',
                authorizedPath: {
                    pathValNm: '/My files/.kyt/기존만료일_내림차순.PNG',
                    pathVal: '/111100000000000000/161909349352900256/162124287506000401'
                }
            },
            sharedCount: 0,
            actionPolicy: {
                releaseTag: true,
                openFile: true,
                upload: false,
                viewFileWithViewer: false,
                delete: true,
                createPublicLink: true,
                setSecureMode: false,
                manageShare: true,
                createInLinkUrl: true,
                download: true,
                versionup: true,
                create: false,
                setTag: true,
                copy: true,
                downloadInLink: true,
                viewList: true,
                viewInLinkUrl: true,
                changeOwner: true,
                move: true,
                restore: false,
                setInLink: true,
                changeWorkgroupDate: true,
                setTargetFolder: false,
                rename: true,
                viewShareList: true
            },
            config: {
                drive: {
                    expirationFuncLimitYn: 'N',
                    complianceFuncLimitYn: 'N',
                    complianceRequestStateCode: 'CANCEL',
                    complianceResult: 'MONITORING',
                    expiredDate: '20210522'
                },
                version: {
                    complianceDetectedDate: '2021-04-23T08:00:05+00:00',
                    complianceDetectedKeywords: '파워,키보드'
                }
            }
        }
    ];
};

const getApprovalPopup = (config = {}) => {
    const { type, mode, reqId, req, target } = config;
    return <ApprovalPopup type={type} mode={mode} target={target} reqId={reqId} req={req} />;
};

const getRequestPopup = (config = {}) => {
    const { type, mode, reqId, req, target } = config;

    switch (type) {
        case Request.TYPE.USE_SECURE_FOLDER:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam } = config;
                return <ReqSecureModePopup reqId={reqId} reqInfo={req} mode={reqMngListParam.reqType} />;
            }
            return <></>;
        case Request.TYPE.EXCEPT_NON_COMPLIANCE:
            if (mode === Request.MODE.VIEW) {
                const { setReqMngList, setReqMngListSelected, setReqMngListParam } = config;
                return <ReqComplianceExceptionPop reqId={reqId} reqInfo={req} setReqMngList={setReqMngList} setReqMngListSelected={setReqMngListSelected} setReqMngListParam={setReqMngListParam} />;
            }
            return <RequestComplianceExceptionPopup files={target} headerName="drive.title.exceptComlianceFile" />;
        case Request.TYPE.EXTENDS_EXPIRATION:
            if (mode === Request.MODE.VIEW) {
                const { setReqMngList, setReqMngListSelected, setReqMngListParam } = config;
                return <ReqExtendExpiredDtPop reqId={reqId} reqInfo={req} setReqMngList={setReqMngList} setReqMngListSelected={setReqMngListSelected} setReqMngListParam={setReqMngListParam} />;
            }
            return <RequestExceptionPopup files={target} headerName="drive.title.requestExtension" />;
        case Request.TYPE.CREATE_WORKGROUP:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, reload, index } = config;
                return <ReqWorkGroupFolderPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} />;
            }
            return <CreateRequestPopup />;
        case Request.TYPE.WORKGROUP_RETENTION_DATE:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, reload, index } = config;
                return <ReqWorkGroupFolderPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} />;
            }
            return <ReqWorkgroupChangeDatePopup workgroupFolder={target} />;
        default:
            return <></>;
    }
};

function fail() {
    return new Promise(() => false);
}

const open = async (config = {}) => {
    const { type = Request.TYPE.CREATE_WORKGROUP, mode = Request.MODE.REQUEST, reqId = '', system = Request.SYSTEM_TYPE.KNOX_DRIVE } = config;

    if (mode === Request.MODE.VIEW) {
        const req = await getReq(reqId);
        if (_.isNil(req)) return fail();

        const reqConfig = _.extend({ req }, config);

        if (system === Request.SYSTEM_TYPE.KNOX_APPROVAL) {
            return largePopup(getApprovalPopup(reqConfig));
        }
        return largePopup(getRequestPopup(reqConfig));
    }
    if (mode === Request.MODE.REQUEST) {
        const reqConfig = _.extend({}, config);

        if (type === Request.TYPE.EXCEPT_NON_COMPLIANCE || type === Request.TYPE.EXTENDS_EXPIRATION) {
            reqConfig.target = await getFiles(type);
        }

        // 신규 요청이면 시스템 타입 조회 후 처리
        if (system === Request.SYSTEM_TYPE.KNOX_APPROVAL) {
            return largePopup(getApprovalPopup(reqConfig));
        }
        if (type === Request.TYPE.USE_SECURE_FOLDER) {
            return globalPopup.openSecureModeRequestPopup();
        }
        return largePopup(getRequestPopup(reqConfig));
    }

    return fail();
};

const RequestTest = ({ match }) => {
    const { params: { type = '', reqId = '', system = '' } = {} } = match;
    const mode = _.isEmpty(reqId) ? Request.MODE.REQUEST : Request.MODE.VIEW;

    console.log('type', type, 'reqId', reqId, 'mode', mode, 'system', system);

    if (!isCorrectType(type) || !isCorrectSystem(system)) {
        return modalAlert('com.alert.reqInvalidParam');
    }

    const reqConfig = { type, mode, system };
    if (!_.isEmpty(reqId)) {
        reqConfig.reqId = reqId;
    }

    open(reqConfig).then();

    return <></>;
};

RequestTest.propTypes = {
    match: PropTypes.object.isRequired
};

export default RequestTest;
