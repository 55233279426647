import React from 'react';
import PropTypes from 'prop-types';

import CoachMarkConstants from 'constants/CoachMark';

import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import _ from 'lodash';
import { connect } from 'react-redux';
import $ from 'jquery';
import { renderToString } from 'react-dom/server';
import Filter from './Filter';
import Instant from './Instant';
import ClickOutside from '../ClickOutside';
import Url from '../../../utils/Url';
import { alert } from '../../../utils/ModalService';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.queryInput = React.createRef();
        const { location, loginUser } = props;
        const { pathname, search } = location;
        if (pathname.indexOf(Url.search) === 0) {
            this.filterParams = queryString.parse(search);
        } else {
            this.filterParams = {};
        }
        const { q = '' } = this.filterParams;
        this.isFilterInnerPopupOpen = false;
        this.state = {
            query: q,
            isInstantOpen: false,
            isFilterOpen: false
        };

        const { id } = loginUser;
        this.coachMark = localStorage.getItem(`${CoachMarkConstants.COACHMARK_SEARCH}${id}`);

        this.wrapToggleSearchInput = isOpen => {
            const { toggleSearchInput } = props;
            toggleSearchInput(isOpen);
            if (isOpen) {
                this.focusQueryInput();
                if (this.coachMark !== 'N') {
                    setTimeout(this.showCoachMark, 500);
                }
            } else {
                // this.setState({ query: '' });
                this.onClickOutside();
            }
            // else this.setState({ query: '', isInstantOpen: false });
        };
        this.onClickOutside = this.onClickOutside.bind(this);
        this.goSearchPage = this.goSearchPage.bind(this);
        this.onClickFilter = this.onClickFilter.bind(this);

        this.clickOutSideRef = React.createRef();
    }

    componentDidMount() {
        const { toggleSearchInput, intl } = this.props;
        if (Object.keys(this.filterParams).length > 0) {
            toggleSearchInput(true);
            this.onClickOutside();
        }

        if (this.coachMark !== 'N') {
            const dataContent = (
                <div className="coachmark-conts">
                    <p>{intl.formatMessage({ id: 'com.text.coachMark.search' })}</p>
                    <div className="btns">
                        <a className="coachmark-end" id="stop-search" onClick={this.stop} role="button">
                            {intl.formatMessage({ id: 'com.text.coachMark.stop' })}
                        </a>
                    </div>
                </div>
            );
            this.dataRendered = renderToString(dataContent);
        }
    }

    onClickOutside() {
        if (this.isFilterInnerPopupOpen) return;
        const { isFilterOpen, isInstantOpen } = this.state;
        if (isFilterOpen || isInstantOpen) {
            this.setState({
                isFilterOpen: false,
                isInstantOpen: false
            });
        }
    }

    openInnerPopup = isOpen => {
        this.isFilterInnerPopupOpen = isOpen;
    };

    focusQueryInput() {
        setTimeout(() => {
            this.queryInput.focus();
        });
    }

    onChangeQuery(query) {
        this.filterParams = {};
        if (!query || !query.trim()) {
            this.setState({ query: '' });
        } else {
            this.setState({ query });
        }
    }

    toggleQueryInput(isInstantOpen) {
        let { isFilterOpen } = this.state;
        if (isInstantOpen) isFilterOpen = false;
        this.setState({ isFilterOpen, isInstantOpen });
    }

    onClickFilter(isFilterOpen) {
        let { isInstantOpen } = this.state;
        if (isFilterOpen) isInstantOpen = false;
        this.setState({ isFilterOpen, isInstantOpen });
    }

    goSearchPage(params = {}) {
        if (this.isEmptyQuery(params)) {
            alert('com.alert.search.min-query');
            return;
        }

        const newParams = {};
        const { history, location } = this.props;
        Object.keys(params)
            .filter(key => params[key])
            .forEach(key => {
                newParams[key] = params[key];
            });
        if (location.pathname.indexOf(Url.search) >= 0) {
            newParams.time = Date.now();
            history.replace(`${Url.search}?${queryString.stringify(newParams)}`);
        } else {
            history.push(`${Url.search}?${queryString.stringify(newParams)}`);
        }
    }

    isEmptyQuery(params = {}) {
        const queries = _.chain(params)
            .omit(['time', 'utcOffset'])
            .values()
            .join('')
            .value();

        if (!queries || queries.trim() === '') {
            return true;
        }

        return false;
    }

    stop = () => {
        this.hide();
        this.setNLocalStorage();
    };

    hide = () => {
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.animate({ opacity: 0 }, 'fast', 'swing');
        $('.coachmark-conts').popover('hide');
    };

    setNLocalStorage = () => {
        const { loginUser } = this.props;
        const { id } = loginUser;
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_SEARCH}${id}`, 'N');
    };

    showCoachMark = () => {
        this.coachmarkOwner = $('#quick-search-filter');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: true
        });

        // $('body').append('<div class="coachmark-backdrop"></div>');
        this.popover.addClass('coachmark').popover('show');

        $('[class*="bs-popover-bottom"]').addClass('coachmark');

        window.addEventListener('click', function(e) {
            $('[class*="bs-popover-bottom"]').each(function() {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });

        // document.getElementById('stop-search').addEventListener('click', this.stop);
        if (document.getElementById('stop-search') !== null) {
            document.getElementById('stop-search').addEventListener('click', this.stop);
        }
    };

    initParams = () => {
        this.filterParams = {};
        this.setState({});
    };

    render() {
        const { query, isInstantOpen, isFilterOpen } = this.state;

        const { history, intl } = this.props;
        const MAX_LENGTH = 100;
        return (
            <>
                <ClickOutside onClickOutside={this.onClickOutside} childrenRefs={[this.clickOutSideRef]}>
                    <div className="header-search" ref={this.clickOutSideRef}>
                        <fieldset className="search-input-container" role="search">
                            <legend className="sr-only">Search</legend>
                            <div className="quick-search box-input-group has-overlay-layer">
                                <a
                                    className={`btn-ic-nor btn-func-search ${query ? 'on' : ''}`}
                                    role="button"
                                    title={intl.formatMessage({ id: 'drive.placeHolder.search.query' })}
                                    onClick={() => this.goSearchPage({ q: query.trim() })}>
                                    <i className="ic-24-search" />
                                </a>
                                <input
                                    ref={ref => {
                                        this.queryInput = ref;
                                    }}
                                    className="form-control"
                                    type="text"
                                    placeholder={intl.formatMessage({ id: 'drive.placeHolder.search' })}
                                    onChange={e => this.onChangeQuery(e.target.value)}
                                    onFocus={() => this.toggleQueryInput(true)}
                                    onKeyDown={e => e.keyCode === 13 && e.target.value && this.goSearchPage({ q: query.trim() })}
                                    value={query}
                                    maxLength={MAX_LENGTH}
                                />
                                <a
                                    className="btn-ic-nor btn-func-clear"
                                    role="button"
                                    title={intl.formatMessage({ id: 'com.text.clear' })}
                                    style={query ? { display: 'inline-flex' } : { display: 'none' }}
                                    onClick={() => {
                                        this.onChangeQuery('');
                                        this.focusQueryInput();
                                    }}>
                                    <i className="ic-20-clear" />
                                </a>
                                <span className="divider" />
                                <div title={intl.formatMessage({ id: 'drive.tooltip.search.filter' })}>
                                    <a
                                        id="quick-search-filter"
                                        className={`btn-ic-nor btn-func-filter ${Object.keys(this.filterParams).length > 0 ? 'on' : ''} has-coachmark search`}
                                        role="button"
                                        data-placement="bottom"
                                        onClick={() => this.onClickFilter(!isFilterOpen)}
                                        data-content={this.dataRendered}>
                                        <i className="ic-24-filter " />
                                    </a>
                                </div>
                                <a
                                    className="btn-ic-nor btn-func-close"
                                    role="button"
                                    title={intl.formatMessage({ id: 'drive.tooltip.search.close' })}
                                    onClick={() => this.wrapToggleSearchInput(false)}>
                                    <i className="ic-24-close" />
                                </a>
                                <Instant isInstantOpen={isInstantOpen} query={query} goSearchPage={this.goSearchPage} history={history} />
                            </div>
                            {isFilterOpen && (
                                <Filter
                                    openInnerPopup={this.openInnerPopup}
                                    query={query}
                                    params={this.filterParams}
                                    initParams={this.initParams}
                                    goSearchPage={this.goSearchPage}
                                    close={() => this.onClickFilter(false)}
                                />
                            )}
                        </fieldset>
                    </div>
                </ClickOutside>
                <div className="header-search-toggle">
                    <a className="btn-ic-nor btn-func-search" role="button" title={intl.formatMessage({ id: 'drive.tooltip.search.open' })} onClick={() => this.wrapToggleSearchInput(true)}>
                        <i className="ic-24-search" />
                    </a>
                </div>
            </>
        );
    }
}

Search.propTypes = {
    location: PropTypes.object.isRequired,
    toggleSearchInput: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default withRouter(injectIntl(connect(mapStateToProps)(Search)));
