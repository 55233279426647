import React from 'react';
import { string } from 'prop-types';
import { injectIntl } from 'react-intl';

const fileTypes = {
    ALL: 'drive.filter.fileType.all',
    CREATE: 'drive.filter.fileType.create',
    MOVE: 'drive.filter.fileType.move',
    COPY: 'drive.filter.fileType.copy',
    RENAME_FILE: 'drive.filter.fileType.renameFile',
    SHAREADD: 'drive.filter.fileType.shareAdd',
    SHAREDEL: 'drive.filter.fileType.shareDel',
    SHAREMOD: 'drive.filter.fileType.shareMod',
    VERSIONUP: 'drive.filter.fileType.versionUp',
    VERSIONRVT: 'drive.filter.fileType.versionUp',
    DOWNLOAD: 'drive.filter.fileType.download',
    CREATE_FILE_LINK: 'drive.filter.fileType.createFileLink',
    DELETE_FILE_LINK: 'drive.filter.fileType.deleteFileLink',
    OPEN_VIEWER: 'drive.filter.fileType.openViewer',
    SUMMARY: 'drive.filter.fileType.summary'
};

const folderTypes = {
    ALL: 'drive.filter.folderType.all',
    CREATE: 'drive.filter.folderType.create',
    MOVE: 'drive.filter.folderType.move',
    SHAREADD: 'drive.filter.folderType.shareAdd',
    SHAREDEL: 'drive.filter.folderType.shareDel',
    SHAREMOD: 'drive.filter.folderType.shareMod',
    RENAME_FOLDER: 'drive.filter.fileType.renameFile'
};

const TypeName = props => {
    const { type, objtSectCd, intl } = props;

    if (objtSectCd === 'FILE') {
        return (
            <>
                <div className="dd-header">{intl.formatMessage({ id: fileTypes[type] })}</div>
            </>
        );
    }
    if (objtSectCd === 'FOLDER') {
        return (
            <>
                <div className="dd-header">{intl.formatMessage({ id: folderTypes[type] })}</div>
            </>
        );
    }

    return null;
};

TypeName.propTypes = {
    type: string,
    objtSectCd: string
};

TypeName.defaultProps = {
    type: '',
    objtSectCd: ''
};

export default injectIntl(TypeName);
export { fileTypes };
