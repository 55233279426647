import { object } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const FileViewerContent = props => {
    const { viewerInfo } = props;
    const { sharePolicy } = viewerInfo;
    function getExtension(fileName) {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    }
    const fileExtNm = getExtension(viewerInfo.objtNm);
    let url = '/pdfium/webViewer.html?objtId='
        .concat(viewerInfo.objtId)
        .concat('&fileVerSno=')
        .concat(viewerInfo.fileVerSno)
        .concat('&onpstId=')
        .concat(viewerInfo.onpstId)
        .concat('&type=')
        .concat(viewerInfo.type);
    if (viewerInfo.fileVerAttrVal !== null && viewerInfo.fileVerAttrVal !== undefined && viewerInfo.fileVerAttrVal !== '') {
        url = url.concat('&fileVerAttrVal=').concat(viewerInfo.fileVerAttrVal);
    }
    if (viewerInfo.incomLinkUrl !== null && viewerInfo.incomLinkUrl !== undefined && viewerInfo.incomLinkUrl !== '') {
        url = url.concat('&incomLinkUrl=').concat(viewerInfo.incomLinkUrl);
    }
    return (
        <React.Fragment>
            <div className="viewer-body">
                {viewerInfo.viewerType === 'PDF' && <iframe title="pdfium" id="ifrPdfium" src={url} />}

                {viewerInfo.viewerType === 'ETC' && (
                    <div className="box-empty">
                        <div className="message">
                            <strong className="file-extension">{fileExtNm}</strong>
                            <p>
                                <FormattedMessage id="drive.text.viewerError.notSupportExtNm" />
                            </p>
                        </div>
                    </div>
                )}

                {viewerInfo.viewerType === 'ERROR' && (
                    <div className="box-empty">
                        <div className="message">
                            <p>
                                <strong>
                                    {sharePolicy.openFile && <FormattedMessage id="drive.text.viewerThumbnailfail.openFile" values={{ br: <br /> }} />}
                                    {!sharePolicy.openFile && <FormattedMessage id="drive.text.viewerThumbnailfail.readOnly" values={{ br: <br /> }} />}
                                </strong>
                            </p>
                            <p>
                                <FormattedMessage id="drive.text.viewerThumbnailfail.checkFile" />
                            </p>
                        </div>
                    </div>
                )}

                {viewerInfo.viewerType === 'CONVERTING' && (
                    <div className="box-empty">
                        <div className="message">
                            <div className="inn-loading">
                                <div className="dy-progress-page">
                                    <div className="spinner" />
                                </div>
                                <p>
                                    <strong>
                                        <FormattedMessage id="drive.text.viewerConverting.plzWait" values={{ br: <br /> }} />
                                    </strong>
                                </p>
                            </div>
                            <p>
                                <FormattedMessage id="drive.text.viewerConverting.weNeedTime" values={{ br: <br /> }} />
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};
FileViewerContent.propTypes = {
    viewerInfo: object.isRequired
};

export default FileViewerContent;
