import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import 'react-quill/dist/quill.snow.css'; // 어드민에서 사용하고있는 에디터사용 컨텐츠 표기위한 css (like 공지사항/FAQ)

import { largePopup } from 'utils/ModalService';
import CustomModal from 'containers/CustomModal';
import AgentDownload from '../presentationals/Download/AgentDownload';

class NoticeAgentInstallPopup extends PureComponent {
    agentDownload = () => {
        const { handleClose } = this.props;
        handleClose(false);
        // window.open(Url.agentDown, '_blank');
        largePopup(<AgentDownload isClose={true} />);
    };

    render() {
        const { show, handleClose, intl } = this.props;
        return (
            <CustomModal isOpen={show} fade={true} size="lg" backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'user.title.notice.agentInstall' })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.title.ReqRejectPop.close' })} data-dismiss="modal" onClick={() => handleClose(false)}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className="alert-message">{intl.formatMessage({ id: 'user.text.notice.agentInstall' })}</div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option">
                        <div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" onChange={() => handleClose(true)} />
                                <span className="label-text">{intl.formatMessage({ id: 'user.gnb.doNotSeeAgain' })}</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" onClick={this.agentDownload}>
                            <span className="btn-text">{intl.formatMessage({ id: 'user.button.notice.agentInstall' })}</span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => handleClose(false)}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

NoticeAgentInstallPopup.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
};

NoticeAgentInstallPopup.defaultProps = {
    show: false,
    handleClose: undefined
};

export default injectIntl(NoticeAgentInstallPopup);
