import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import RecycleBinRest from '../../../../apis/RecycleBinRest';
import { alert, popup, toast } from '../../../../utils/ModalService';
import ModalAlert from '../../ModalAlert';
import FolderSelectPopup from '../../../containers/Popup/FolderSelect/FolderSelectPopup';
import NumberUtil from '../../../../utils/NumberUtil';

const executor = async (props, files) => {
    const NOT_SELECTED = 'NOT_SELECTED';
    const refresh = () => props.callback({ reloadType: 'refresh' });

    async function callRestoreObjtListToOriginFolder() {
        const restoredObjtInfos = files.map(({ drive: { objtId, onpstId } }) => ({
            objtId,
            onpstId
        }));

        const data = await RecycleBinRest.restoreObjtListToOriginFolder(restoredObjtInfos);
        return data;
    }

    async function confirmForRetryFailList(failCount, isAllFailed) {
        try {
            // 모두 실패한 경우와 일부 실패한 경우 컨펌 메시지 분기
            const alertMessage = isAllFailed ? (
                <FormattedMessage id="drive.confirm.restore.folderSelect" />
            ) : (
                <FormattedMessage id="drive.confirm.restore.folderSelectWithCount" values={{ n: NumberUtil.getNumberWithCommas(failCount) }} />
            );

            const alertProps = {
                btnMessage: { okay: { id: 'drive.button.restore.folderSelect' }, cancel: { id: 'com.cancel' } },
                message: alertMessage,
                useCancel: true
            };

            await popup(<ModalAlert {...alertProps} />);
            return true;
        } catch (e) {
            console.debug('실패한 목록에 대해서 재시도 하지 않음');
            return false;
        }
    }

    async function openFolderSelectPopup() {
        try {
            const [
                {
                    drive: { onpstSectCd, onpstId }
                }
            ] = files;
            const options = {
                mode: onpstSectCd === 'INDVFOLDER' ? 'ownOnly' : 'workgroupOnly',
                preSelectedFolder: {
                    drive: {
                        onpstId
                    },
                    actionPolicy: { create: true }
                }
            };
            const { objtId: selectedObjtId } = await popup(<FolderSelectPopup {...options} />);
            return selectedObjtId;
        } catch (e) {
            console.debug('폴더를 선택하지 않고 팝업을 닫음');
            return NOT_SELECTED;
        }
    }

    async function restoreForFailList(failList, selectedFolderObjtId) {
        return RecycleBinRest.restoreObjtList(
            failList.map(file => {
                return {
                    ...file,
                    onpstFolderId: selectedFolderObjtId === 'own' ? '111100000000000000' : selectedFolderObjtId
                };
            })
        );
    }

    async function process() {
        try {
            const data = await callRestoreObjtListToOriginFolder();

            if (_.isEmpty(data)) {
                alert('com.alert.internalServerError');
                return;
            }

            const { successList, failList } = data;

            const successCount = successList.length;
            const failCount = failList.length;

            if (!failCount) {
                toast('drive.toast.restore.complete');
                refresh();
                return;
            }

            // 실패 목록 재시도를 위해 폴더 선택 여부를 확인
            if (!(await confirmForRetryFailList(failCount, successCount === 0))) {
                refresh();
                return;
            }

            const selectedFolderObjtId = await openFolderSelectPopup();

            // 실패 목록 재시도를 위한 폴더 선택 팝업에서 취소하는 경우
            if (selectedFolderObjtId === NOT_SELECTED) {
                refresh();
                return;
            }

            await restoreForFailList(failList, selectedFolderObjtId);
            refresh();
            toast('drive.toast.restore.complete');
        } catch (e) {
            console.error(e);
            toast('drive.toast.restore.fail');
        }
    }

    await process();
};

class RestoreToOriginFolder extends BaseMenu {
    getName() {
        return 'restoreToOriginFolder';
    }

    getLabel() {
        return 'drive.button.list.menu.restoreToOriginFolder';
    }

    isVisible() {
        return true;
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-restore', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-view', 'ic-20-restore', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default RestoreToOriginFolder;
