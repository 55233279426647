import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

const ModalAlert = props => {
    const { useCancel, useChkBox, close, message, btnMessage } = props;
    const { okay, cancel } = btnMessage;

    return (
        <>
            <div className="modal-body">
                <div className="alert-message">{message}</div>
            </div>
            <div className="modal-footer">
                {useChkBox && <div className="modal-footer-option" />}
                <div className="modal-footer-btns">
                    {useCancel && (
                        <a className="btn btn-lg btn-secondary" role="button" onClick={() => close(false)}>
                            <span className="btn-text">{_.isObject(cancel) ? <FormattedMessage id={cancel.id} values={cancel.values} /> : cancel}</span>
                        </a>
                    )}
                    <a className="btn btn-lg btn-primary" role="button" onClick={() => close(true)}>
                        <span className="btn-text">{_.isObject(okay) ? <FormattedMessage id={okay.id} values={okay.values} /> : okay}</span>
                    </a>
                </div>
            </div>
        </>
    );
};

ModalAlert.propTypes = {
    close: PropTypes.func,
    message: PropTypes.any.isRequired,
    btnMessage: PropTypes.shape({
        okay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        cancel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    }),
    useCancel: PropTypes.bool,
    useChkBox: PropTypes.bool
};

ModalAlert.defaultProps = {
    close: () => {},
    btnMessage: {
        okay: { id: 'com.check' },
        cancel: { id: 'com.cancel' }
    },
    useCancel: false,
    useChkBox: false
};

export default ModalAlert;
