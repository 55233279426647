import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { If } from 'jsx-control-statements';
import ClickOutside from './ClickOutside';
import SidePanel from '../containers/Summary/SidePanel/SidePanel';
import OptionSelectDialog from '../containers/Summary/OptionSelectDialog/OptionSelectDialog';
import SummaryTooltip from '../containers/Summary/Common/Tooltip/SummaryTooltip';
import WrongKeywordDialog from '../containers/Summary/SidePanel/Popup/WrongKeywordDialog';
import { summaryActions } from '../../redux/actions/Summary';

const SummaryContent = memo(({ enableSummary, copilotGnbButtonRef }) => {
    const summarySidePanelRef = useRef();
    const summaryOptionSelectDialogRef = useRef();
    const wrongKeywordDialogRef = useRef();

    const dispatch = useDispatch();

    // TODO clear 해야하는 action 모아서 dispatch
    // 프롬프트 드랍다운, 기타 다이얼로그, 모달
    const handleClickOutsideSummaryComp = () => {
        dispatch(summaryActions.sidePanel.close());
        dispatch(summaryActions.optionSelectDialog.close());
    };

    return (
        <>
            <If condition={enableSummary}>
                <ClickOutside
                    onClickOutside={handleClickOutsideSummaryComp}
                    childrenRefs={[summarySidePanelRef, summaryOptionSelectDialogRef, copilotGnbButtonRef, wrongKeywordDialogRef]}
                    childrenClassNames={['loadingSpinner']}>
                    <SidePanel sidePanelRef={summarySidePanelRef} />
                    <OptionSelectDialog optionSelectDialogRef={summaryOptionSelectDialogRef} />
                </ClickOutside>
                <SummaryTooltip />
            </If>
            <WrongKeywordDialog wrongKeywordDialogRef={wrongKeywordDialogRef} />
        </>
    );
});

SummaryContent.propTypes = {
    enableSummary: PropTypes.bool,
    copilotGnbButtonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

SummaryContent.defaultProps = {
    enableSummary: false
};

export default SummaryContent;
