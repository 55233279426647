import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InLinkRest from '../../../apis/InLinkRest';

class LinkDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.linkDownload();
    }

    linkDownload() {
        const { close, folderFileList } = this.props;
        for (let i = 0; i < folderFileList.length; i += 1) {
            const objtNmArr = folderFileList[i].objtNm.split('.');
            this.download(folderFileList[i].objtId, folderFileList[i].onpstId, objtNmArr[0]);
        }
        close();
    }

    async download(objtId, onpstId, fileNm) {
        const res = await InLinkRest.getLinkDownload(objtId, onpstId);
        if (navigator.userAgent.indexOf('Chrome') === -1) {
            // IE <= 10 Download
            const blob = new Blob([res]);
            window.navigator.msSaveOrOpenBlob(blob, `[LINK] ${fileNm}.html`);
        } else {
            const a = document.createElement('a');
            a.href = `data:text/plain,${res}`;
            a.download = `[LINK] ${fileNm}.html`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div />
            </React.Fragment>
        );
    }
}
LinkDownload.propTypes = {
    close: PropTypes.func,
    folderFileList: PropTypes.array
};

LinkDownload.defaultProps = {
    close: () => {},
    folderFileList: []
};

export default LinkDownload;
