import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { confirm } from '../../utils/ModalService';
import FilterDate from '../presentationals/Search/FilterDate';
import FilterKeyword from '../presentationals/Search/FilterKeyword';
import FilterCheckboxGroup from './Filter/FilterCheckboxGroup';
import { ALL } from './Filter/FilterConstants';
import RequiredLabel from './Filter/RequiredLabel';

class DeletedFilter extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props;
        const { objtNm = '', objtDelDtFrom, objtDelDtTo, objtDelCds = ALL, objtSectCd = ALL } = params;

        this.state = {
            objtNm,
            objtDelDtFrom,
            objtDelDtTo,
            objtDelCds,
            objtSectCd
        };
    }

    componentWillUnmount() {
        this.onCancel();
    }

    onKeywordChange = objtNm => {
        this.setState(objtNm);
    };

    onDateChange = tgtDt => {
        this.setState({
            objtDelDtFrom: tgtDt.objtDelDtFrom,
            objtDelDtTo: tgtDt.objtDelDtTo
        });
    };

    onCancel = () => {
        const { close } = this.props;
        close();
    };

    onInit = () => {
        const { openInnerPopup, initParams } = this.props;
        // 검색 필터를 초기화 하시겠습니까?
        confirm(<FormattedMessage id="drive.confirm.search.filter.init" />).then(
            result => {
                if (result) {
                    this.setState(initParams);
                }
                openInnerPopup(false);
            },
            () => {
                openInnerPopup(false);
            }
        );
        openInnerPopup(true);
    };

    onSearch = () => {
        const { search } = this.props;
        const stateParam = Object.assign({}, this.state);
        const stateKeys = _.keys(stateParam);
        const searchParam = {};

        stateKeys
            .filter(key => !_.isNil(stateParam[key]) && _.trim(stateParam[key]).length)
            .forEach(key => {
                searchParam[key] = _.trim(stateParam[key]);
            });

        search(searchParam);
    };

    handleChangeObjtSectCds = selectedCodes => {
        this.setState({
            objtSectCd: selectedCodes
        });
    };

    handleChangeObjtDelCds = selectedCodes => {
        this.setState({
            objtDelCds: selectedCodes
        });
    };

    render() {
        const { useFlcmYn, intl } = this.props;
        const { objtNm, objtDelDtFrom, objtDelDtTo, objtDelCds, objtSectCd } = this.state;

        const cannotSearch = _.isEmpty(objtDelCds) || _.isEmpty(objtSectCd);

        return (
            <div className="overlay-layer-wrapper slide show">
                <div className="overlay-layer">
                    <div className="layer-content layer-sm">
                        <div className="layer-header hd-gray">
                            <h1 className="layer-title">
                                <FormattedMessage id="drive.text.search.filter.title" />
                            </h1>
                            <a id="quick-search-filter-close" className="btn-ic-nor" role="button" title="닫기" onClick={this.onCancel}>
                                <i className="ic-20-close" />
                            </a>
                        </div>
                        <div className="layer-body">
                            <div className="dt-width-120">
                                <FilterKeyword id="objtNm" nameKey="drive.text.list.columns.objtNm" onChange={this.onKeywordChange} value={objtNm} placeholderKey="drive.placeHolder.search" />
                                <dl className="row-list">
                                    <dt>
                                        <RequiredLabel messageId="drive.text.search.filter.type" />
                                    </dt>
                                    <dd>
                                        <FilterCheckboxGroup
                                            groupInfo={[
                                                { label: intl.formatMessage({ id: 'drive.filter.search.extension.folder' }), value: 'FOLDER' },
                                                { label: intl.formatMessage({ id: 'drive.filter.search.extension.file' }), value: 'FILE' }
                                            ]}
                                            rawSelectedValues={objtSectCd}
                                            onChangeRawSelectedValues={this.handleChangeObjtSectCds}
                                        />
                                    </dd>
                                </dl>
                                {useFlcmYn === 'Y' && (
                                    <dl className="row-list">
                                        <dt>
                                            <RequiredLabel messageId="drive.text.list.columns.deletereason" />
                                        </dt>
                                        <dd>
                                            <FilterCheckboxGroup
                                                groupInfo={[
                                                    { label: intl.formatMessage({ id: 'drive.text.deleted' }), value: 'DTEMPORARY' },
                                                    { label: intl.formatMessage({ id: 'drive.text.expired' }), value: 'DPRSRVEXP' },
                                                    { label: intl.formatMessage({ id: 'drive.text.non-compliance' }), value: 'DCPGS' }
                                                ]}
                                                rawSelectedValues={objtDelCds}
                                                onChangeRawSelectedValues={this.handleChangeObjtDelCds}
                                            />
                                        </dd>
                                    </dl>
                                )}
                                <dl className="row-list">
                                    <dt>
                                        <FormattedMessage id="drive.text.list.columns.permanentDeleteDueDt" />
                                    </dt>
                                    <dd>
                                        <FilterDate
                                            startDate={objtDelDtFrom !== undefined ? moment(objtDelDtFrom) : objtDelDtFrom}
                                            endDate={objtDelDtTo !== undefined ? moment(objtDelDtTo) : objtDelDtTo}
                                            onDatesChange={({ startDate, endDate }) => {
                                                const f = 'YYYYMMDD';
                                                let vStartDate = startDate;
                                                if (vStartDate) {
                                                    vStartDate = vStartDate.format(f);
                                                }
                                                let vEndDate = endDate;
                                                if (vEndDate) {
                                                    vEndDate = vEndDate.format(f);
                                                }
                                                this.onDateChange({ objtDelDtFrom: vStartDate, objtDelDtTo: vEndDate });
                                            }}
                                            minDate={moment().add(-10, 'years')}
                                            maxDate={moment().add(10, 'years')}
                                        />
                                    </dd>
                                </dl>
                            </div>
                            <div className="info-msg">
                                <i className="ic-16-info" />
                                <em className="critical-item" title={intl.formatMessage({ id: 'drive.text.search.filter.requiredItem' })} />{' '}
                                <FormattedMessage id="drive.guideText.search.filter.requiredItem" />
                            </div>
                        </div>
                        <div className="layer-footer">
                            <div className="layer-footer-option">
                                <a className="btn-ic-line btn-lg" role="button" onClick={this.onInit}>
                                    <i className="ic-20-reset" />
                                    <span className="btn-text">
                                        <FormattedMessage id="com.reset" />
                                    </span>
                                </a>
                            </div>
                            <div className="layer-footer-btns">
                                <a className="btn btn-lg btn-secondary" role="button" onClick={this.onCancel}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.cancel" />
                                    </span>
                                </a>
                                <a className={classnames('btn', 'btn-lg', 'btn-primary', cannotSearch && 'disabled')} role="button" onClick={this.onSearch}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.search" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DeletedFilter.defaultProps = {
    params: {},
    initParams: {}
};

DeletedFilter.propTypes = {
    params: PropTypes.object,
    initParams: PropTypes.object,
    intl: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    openInnerPopup: PropTypes.func.isRequired,
    useFlcmYn: PropTypes.string.isRequired
};

export default withRouter(injectIntl(DeletedFilter));
