import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push as routerPush } from 'connected-react-router';
import Request from 'constants/Request';
import RequestPopup from 'containers/Popup/RequestPopup';
// import { renderToString } from 'react-dom/server';
import ConfigsRest from 'apis/ConfigsRest';
import { reqMng as reqMngActions } from '../../../../redux/actions';
import SelectBox from '../../SelectBox';
import ReqRest from '../../../../apis/ReqRest';
import DateConverter from '../../../../utils/DateConverter';
import UserCard from '../../../containers/NameCard/UserCard';
import ReqMngBodyReqStat from './ReqMngBodyReqStat';
import { alert as modalAlert } from '../../../../utils/ModalService';
import CustomList from '../../List/CustomList';
import ApprovalUtil from '../../../containers/Popup/Approval/ApprovalUtil';

const CFG_IDS_FOR_REQ_LST_ID = {
    chgWGDt: ['EFL_COM_USE_FLCM_YN'],
    creWG: ['EFL_COM_WG_CREATE_USE_YN', 'EFL_COM_WG_CREATE_APPROVE_USE_YN'],
    excPcCtrl: ['EFL_DRV_AGENT_PC_CTRL_EXCEPT_USE_YN'],
    excProhWrd: ['EFL_COM_USE_FLCM_YN'],
    extExptDt: ['EFL_COM_USE_FLCM_YN'],
    prtAccResi: ['EFL_AUT_REG_PARTNER_ACCOUNT_BY_USER_YN'],
    prtExpiExt: ['EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION'],
    reqOffMd: ['EFL_DRV_SECURE_FOLDER_USE_REQUEST_YN']
};

class ReqMngBody extends Component {
    constructor(props) {
        super(props);

        const { intl } = this.props;

        this.state = {
            height: this.calcHeight(),
            reqList: [{ value: '', message: intl.formatMessage({ id: 'com.text.ReqMngBody.allType' }) }],
            moreYn: true,
            allCheckedYn: true,
            changing: false,
            receivedReqsCount: 0
        };

        this.tooltipRendered = intl.formatMessage({ id: 'com.tooltip.ReqMngBodyReqStat.explain' });
    }

    componentDidMount() {
        const { setReqMngListParam } = this.props;
        const { pathname } = window.location;
        const pathArray = _.split(pathname, '/');
        const reqType = pathArray[2] === 'received' ? 'received' : 'send';
        this.setState({ changing: true });
        setReqMngListParam({
            reqType,
            reqListType: '',
            page: 1,
            limit: 100,
            sortBy: 'regDt',
            sortOrder: 'desc',
            reloadYn: true,
            newlyLoaded: true
        });
        this.getReqList(reqType);
    }

    componentDidUpdate(prevProps) {
        const { reqMngListParam } = this.props;
        const { pathname } = prevProps;
        const currentPathname = window.location.pathname;
        const previousPathArray = _.split(pathname, '/');
        const currntPathArray = _.split(currentPathname, '/');
        const previousType = previousPathArray[2] === 'received' ? 'received' : 'send';
        const currentType = currntPathArray[2] === 'received' ? 'received' : 'send';
        const reloadYn = previousType !== currentType;
        if (reqMngListParam.reloadYn || reloadYn) {
            this.getReqs(currentType, reloadYn);
        }
    }

    componentWillUnmount() {
        const { setReqMngListParam, setReqMngList } = this.props;
        setReqMngListParam({
            reqType: '',
            reqListType: '',
            page: 1,
            limit: 100,
            sortBy: 'regDt',
            sortOrder: 'desc',
            reloadYn: true
        });
        setReqMngList({ reqMngList: [] });
    }

    getReqListAlertMessage(response) {
        const { resultCode, message } = response;
        if (resultCode === 500) {
            return 'com.alert.internalServerError';
        }
        if (resultCode === 400) {
            return 'com.alert.reqInvalidParam';
        }
        if (resultCode !== 200) {
            return message;
        }
        return undefined;
    }

    isAllowedReqLstId(req, cfgs) {
        const cfgIds = CFG_IDS_FOR_REQ_LST_ID[req.reqLstId];
        if (!cfgIds || !cfgIds.length) return false;
        return _.every(cfgIds, cfgId => _.get(_.find(cfgs, cfg => cfg.cfgId === cfgId), 'cfgVal', '') === 'Y');
    }

    async getReqList(reqType) {
        const { intl } = this.props;

        const { setReqMngListParam } = this.props;

        const param = {
            reqConfmTpCd: reqType === 'received' ? 'INTERNAL' : ''
        };
        const tmpReqList = await ReqRest.getReqList(param);

        const alertMessage = this.getReqListAlertMessage(tmpReqList);
        if (alertMessage) {
            modalAlert(alertMessage);
            return;
        }

        // /**
        //  *1) 보존기한 일괄 변경 요청  - EFL_COM_USE_FLCM_YN
        //  2) Workgroup 생성 요청 - EFL_COM_WG_CREATE_USE_YN
        // >> 하위 옵션 EFL_COM_WG_CREATE_APPROVE_USE_YN 추가 필요. (김용태 프로가 작업 예정)
        //  3) PC통제 예외 요청 - EFL_DRV_AGENT_PC_CTRL_EXCEPT_USE_YN
        //
        //  이 두개 옵션을 같이 보고 둘 다 Y 인 경우를 요청관리 필요로 판단
        //  4) Non-compliance 파일 예외 요청 - EFL_COM_USE_FLCM_YN
        //  5) 파일 보존기한 변경 요청 - EFL_COM_USE_FLCM_YN
        //  6) 협력사 계정 등록 -  EFL_AUT_REG_PARTNER_ACCOUNT_BY_USER_YN
        //  7) 협력사 계정 만료일 변경 요청 - EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION
        //  8) Secure folder 사용 요청 - EFL_DRV_SECURE_FOLDER_USE_REQUEST_YN
        //

        ConfigsRest.getConfigs().then(response => {
            const reqList = [{ value: '', message: intl.formatMessage({ id: 'com.text.ReqMngBody.allType' }) }];
            _.forEach(tmpReqList.data, item => {
                if (this.isAllowedReqLstId(item, response)) {
                    reqList.push({ value: item.reqLstId, message: item.reqLstNm });
                }
            });
            this.setState({ reqList });
        });

        setReqMngListParam({ reqListType: '' });
    }

    async getReqs(currentPathName = 'send') {
        const { reqMngListParam, setReqMngListParam, reqMngList, setReqMngList, reqMngListSelected, setReqMngListSelected } = this.props;
        const type = currentPathName === 'received' ? 'received' : 'send';
        setReqMngListParam({ reloadYn: false, reqType: type });
        const changedParam = Object.assign({}, reqMngListParam);
        const { newlyLoaded } = changedParam;

        let reqs;
        let receivedReqsCount;

        if (type === 'send') {
            const [sendReqs, receivedReqs] = await Promise.all([ReqRest.getReqs({ ...changedParam, reqType: 'send' }), ReqRest.getReqs({ ...changedParam, reqType: 'received' })]);
            reqs = sendReqs;
            receivedReqsCount = receivedReqs.data.length;
        } else {
            reqs = await ReqRest.getReqs({ ...changedParam, reqType: 'received' });
            receivedReqsCount = reqs.data.length;
        }

        if (reqs.resultCode === 500) {
            modalAlert('com.alert.internalServerError');
            return;
        }
        if (reqs.resultCode === 400) {
            modalAlert('com.alert.reqInvalidParam');
            return;
        }
        if (reqs.resultCode !== 200) {
            modalAlert(reqs.message);
            return;
        }

        let totalReqMngList = [];
        if (newlyLoaded) {
            setReqMngList({ reqMngList: [] });
            this.setState({ changing: true });
            totalReqMngList = JSON.parse(JSON.stringify(reqs.data));
        } else {
            totalReqMngList = _.concat(JSON.parse(JSON.stringify(reqMngList)), JSON.parse(JSON.stringify(reqs.data)));
        }
        const filteredReqs = type === 'send' ? _.filter(totalReqMngList, req => req.reqStatCd === 'PROCESSING' && req.reqConfmTpCd !== 'KNOX') : totalReqMngList;
        const isAllChecked = filteredReqs.length !== 0 && reqMngListSelected.checkedCnt === filteredReqs.length;

        this.checkAllCheckedYn(totalReqMngList);
        this.setState({ receivedReqsCount, moreYn: reqs.data.length === reqMngListParam.limit });

        setReqMngList({ reqMngList: totalReqMngList });
        setReqMngListSelected({ allChecked: isAllChecked });
    }

    checkAllCheckedYn(reqMngList) {
        const { reqMngListParam } = this.props;

        if (reqMngListParam.reqType === 'send' || reqMngListParam.reqListType !== '') {
            this.setState({ allCheckedYn: true });
        } else {
            this.setState({ allCheckedYn: this.checkDifReqListType(reqMngList) });
        }
    }

    checkDifReqListType(reqMngList) {
        let reqListTypeStr = '';
        let rtnYn = true;

        for (let i = 0; i < reqMngList.length; i += 1) {
            if (i === 0) {
                reqListTypeStr = reqMngList[i].reqLstId;
            } else if (reqListTypeStr !== reqMngList[i].reqLstId) {
                rtnYn = false;
                break;
            } else {
                reqListTypeStr = reqMngList[i].reqLstId;
            }
        }

        return rtnYn;
    }

    moreReqs = () => {
        const { reqMngListParam, setReqMngListParam } = this.props;

        setReqMngListParam({ page: reqMngListParam.page + 1, reloadYn: true, newlyLoaded: false });
    };

    handleWindowResize = () => {
        this.setState({ height: this.calcHeight() });
    };

    calcHeight() {
        return window.innerHeight + window.pageYOffset - 315;
    }

    handleAllChecked = event => {
        const { reqMngList, setReqMngList, reqMngListSelected, setReqMngListSelected, reqMngListParam } = this.props;
        const { maxCount: checkMaxCount } = reqMngListSelected;
        let { checkedCnt: checkedCount } = reqMngListSelected;
        const isChecked = event.target.checked;
        // const checkMaxCount = 3;
        const tmpReqMngList = JSON.parse(JSON.stringify(reqMngList));
        let isAllChecked = isChecked;

        // 전체 선택
        if (isChecked) {
            // 이미 최대 개수 도달한 경우
            if (checkedCount >= checkMaxCount) {
                modalAlert({ id: 'drive.alert.list.select.max.all', values: checkMaxCount });
                return;
            }

            let remainCount = checkMaxCount - checkedCount;
            const filtered = reqMngListParam.reqType === 'send' ? _.filter(tmpReqMngList, req => req.reqStatCd === 'PROCESSING' && req.reqConfmTpCd !== 'KNOX') : tmpReqMngList;

            for (let i = 0; i < filtered.length; i += 1) {
                if (remainCount > 0) {
                    if (!filtered[i].isChecked) {
                        remainCount -= 1;
                        checkedCount += 1;
                    }
                    filtered[i].isChecked = true;
                } else {
                    modalAlert({ id: 'drive.alert.list.select.max.all', values: checkMaxCount });
                    break;
                }
            }

            isAllChecked = filtered.length !== 0 && filtered.length === checkedCount;
        }
        // 전체 선택해제
        else {
            checkedCount = 0;
            for (let i = 0; i < tmpReqMngList.length; i += 1) {
                tmpReqMngList[i].isChecked = false;
            }
        }

        setReqMngListSelected({
            checkedCnt: checkedCount,
            allChecked: isAllChecked,
            enabled: reqMngListParam.reqType === 'send' ? true : this.checkDifReqListType(tmpReqMngList)
        });
        setReqMngList({ reqMngList: tmpReqMngList });
    };

    handleCheckChildElement = (event, index) => {
        const { reqMngList, setReqMngList, setReqMngListSelected, reqMngListSelected, reqMngListParam } = this.props;
        const { maxCount: checkMaxCount } = reqMngListSelected;

        const tmpReqMngList = JSON.parse(JSON.stringify(reqMngList));

        if (event.shiftKey && reqMngListSelected.lastCheckedIndex !== '') {
            const diff = index > reqMngListSelected.lastCheckedIndex ? +1 : -1;
            const predicate = idx => {
                return reqMngListSelected.lastCheckedIndex <= index ? idx <= index : index <= idx;
            };
            let remainCount = checkMaxCount - reqMngListSelected.checkedCnt;

            for (let i = reqMngListSelected.lastCheckedIndex; predicate(i); i += diff) {
                if (reqMngListParam.reqType === 'send' && (tmpReqMngList[i].reqStatCd !== 'PROCESSING' || tmpReqMngList[i].reqConfmTpCd === 'KNOX')) {
                    tmpReqMngList[i].isChecked = false;
                } else if (remainCount > 0) {
                    if (!tmpReqMngList[i].isChecked) remainCount -= 1;
                    tmpReqMngList[i].isChecked = true;
                } else {
                    modalAlert({ id: 'drive.alert.list.select.max', values: checkMaxCount });
                    break;
                }
            }
        } else if (reqMngListParam.reqType === 'send' && (tmpReqMngList[index].reqStatCd !== 'PROCESSING' || tmpReqMngList[index].reqConfmTpCd === 'KNOX')) {
            tmpReqMngList[index].isChecked = false;
        } else if (!tmpReqMngList[index].isChecked && reqMngListSelected.checkedCnt >= checkMaxCount) {
            modalAlert({ id: 'drive.alert.list.select.max', values: checkMaxCount });
            return;
        } else {
            tmpReqMngList[index].isChecked = !tmpReqMngList[index].isChecked;
        }

        let checkedCnt = 0;
        _.forEach(tmpReqMngList, item => {
            if (item.isChecked) checkedCnt += 1;
        });

        setReqMngListSelected({
            checkedCnt,
            allChecked: checkedCnt === reqMngList.length,
            lastCheckedIndex: tmpReqMngList[index].isChecked ? index : '',
            enabled: this.checkDifReqListType(_.filter(tmpReqMngList, { isChecked: true }))
        });
        setReqMngList({ reqMngList: tmpReqMngList });
    };

    clickCheckBoxElement = (event, index) => {
        if (
            (event.target.className === 'label-text' && !event.shiftKey) ||
            event.target.className === 'form-check-input' ||
            event.target.className === 'ellipsis' ||
            event.target.className === 'ic-20-approved' ||
            event.target.className === 'ic-20-rejected' ||
            event.target.className === 'name has-name-card'
        ) {
            return;
        }

        this.handleCheckChildElement(event, index);
    };

    changeReqType(nextReqType) {
        const { setReqMngListParam, push, reqMngListParam } = this.props;
        const previousReqType = reqMngListParam.reqType;
        if (previousReqType !== nextReqType) {
            this.setState({ changing: true });
        } else {
            this.setState({ changing: false });
        }

        this.initReqs();
        setReqMngListParam({
            reqType: nextReqType,
            reqListType: '',
            page: 1,
            limit: 100,
            sortBy: 'regDt',
            sortOrder: 'desc',
            reloadYn: true,
            newlyLoaded: true
        });
        this.getReqList(nextReqType);
        const url = nextReqType === 'received' ? 'received' : 'sent';
        push(`/req_mng/${url}`);
    }

    changeReqListType = reqListType => {
        const { setReqMngListParam } = this.props;

        this.initReqs();
        setReqMngListParam({ reqListType, page: 1, limit: 100, sortBy: 'regDt', sortOrder: 'desc', reloadYn: true, newlyLoaded: true });
    };

    changeSort = () => {
        const { setReqMngListParam, reqMngListParam } = this.props;

        this.initReqs();
        setReqMngListParam({
            page: 1,
            limit: 100,
            sortOrder: reqMngListParam.sortOrder === 'asc' ? 'desc' : 'asc',
            reloadYn: true,
            newlyLoaded: true
        });
    };

    openReqDtlPop = (reqLstId, reqId, index) => {
        const { setReqMngList, setReqMngListSelected, setReqMngListParam } = this.props;
        const popupConfig = {
            mode: Request.MODE.VIEW,
            reqId
        };

        if (reqLstId === 'extExptDt') {
            popupConfig.type = Request.TYPE.EXTENDS_EXPIRATION;
            popupConfig.setReqMngList = setReqMngList;
            popupConfig.setReqMngListSelected = setReqMngListSelected;
            popupConfig.setReqMngListParam = setReqMngListParam;
        } else if (reqLstId === 'excProhWrd') {
            popupConfig.type = Request.TYPE.EXCEPT_NON_COMPLIANCE;
            popupConfig.setReqMngList = setReqMngList;
            popupConfig.setReqMngListSelected = setReqMngListSelected;
            popupConfig.setReqMngListParam = setReqMngListParam;
        } else if (reqLstId === 'reqOffMd') {
            const { reqMngListParam } = this.props;
            popupConfig.type = Request.TYPE.USE_SECURE_FOLDER;
            popupConfig.reqMngListParam = reqMngListParam;
            popupConfig.reload = this.reload;
        } else if (reqLstId === 'creWG') {
            const { reqMngListParam } = this.props;
            popupConfig.type = Request.TYPE.CREATE_WORKGROUP;
            popupConfig.reqMngListParam = reqMngListParam;
            popupConfig.index = index;
            popupConfig.reload = this.reload;
        } else if (reqLstId === 'chgWGDt') {
            const { reqMngListParam } = this.props;
            popupConfig.type = Request.TYPE.WORKGROUP_RETENTION_DATE;
            popupConfig.reqMngListParam = reqMngListParam;
            popupConfig.index = index;
            popupConfig.reload = this.reload;
        } else if (reqLstId === 'excPcCtrl') {
            popupConfig.type = Request.TYPE.EXCEPT_PC_CONTROL;
            popupConfig.index = index;
            popupConfig.reload = this.reload;
        } else if (reqLstId === 'prtAccResi') {
            const { reqMngListParam } = this.props;
            popupConfig.type = Request.TYPE.PARTNER_ACCOUNT_RESISTRATION;
            popupConfig.reqMngListParam = reqMngListParam;
            popupConfig.index = index;
            popupConfig.reload = this.reload;
        } else if (reqLstId === 'prtExpiExt') {
            const { reqMngListParam } = this.props;
            popupConfig.type = Request.TYPE.PARTNER_EXPIRE_EXTENTION;
            popupConfig.reqMngListParam = reqMngListParam;
            popupConfig.index = index;
            popupConfig.reload = this.reload;
        }

        RequestPopup.open(popupConfig);
    };

    reload = () => {
        const { setReqMngListParam } = this.props;
        this.initReqs();
        setReqMngListParam({
            // reqType: 'send',
            // reqListType: '',
            page: 1,
            limit: 100,
            sortBy: 'regDt',
            sortOrder: 'desc',
            reloadYn: true
        });
    };

    initReqs() {
        const { setReqMngListSelected } = this.props;

        setReqMngListSelected({ allChecked: false, checkedCnt: 0 });
        // setReqMngList({ reqMngList: [] });
    }

    getReqContent(req) {
        const { intl } = this.props;
        const { reqLstId, reqContent, reqTgtCount } = req;

        if (reqLstId === 'reqOffMd') {
            return `${intl.formatMessage({ id: 'user.text.approval.useSecureFolder.period' })} : ${reqContent}`;
        }

        if (reqLstId === 'excPcCtrl') {
            return `${intl.formatMessage({ id: 'user.text.approval.exceptPcControl.period' })} : ${reqContent}`;
        }

        if (reqLstId === 'prtExpiExt') {
            return `${reqContent}`;
        }

        return `${reqContent}${reqTgtCount === 1 ? '' : ` ${intl.formatMessage({ id: 'drive.text.list.columns.andOther' }, { count: reqTgtCount })}`}`;
    }

    rowBuilder = ({ style, index }) => {
        const { reqMngList, reqMngListSelected, reqMngListParam, intl } = this.props;
        const { moreYn } = this.state;

        const checkboxDisplayStyle = reqMngListSelected.checkedCnt > 0 ? { display: 'block' } : {};

        if (index === reqMngList.length && moreYn) {
            return (
                <div className="grid-row btns-row" style={style}>
                    <div className="grid-cell">
                        <a className="btn btn-secondary btn-block" role="button" onClick={this.moreReqs}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ReqMngBody.more" />
                            </span>
                        </a>
                    </div>
                </div>
            );
        }
        if (index === reqMngList.length && !moreYn) {
            return <div />;
        }

        const { reqTgtUser, reqUserNm, regUserId, reqStatCd, reqConfmTpCd, isChecked } = reqMngList[index];
        let tgtUserNm = reqMngList[index].reqTgtUserNm;
        let tgtUserNmTitle = reqMngList[index].reqTgtUserNm;
        let tgtUserId = reqMngList[index].reqTgtUserId;
        let isUnregTgtUser = false;

        if (!_.isEmpty(reqTgtUser)) {
            tgtUserId = reqTgtUser[0].reqTgtUserId;
            isUnregTgtUser = ApprovalUtil.isUnregisteredUser(tgtUserId);
            tgtUserNm = isUnregTgtUser ? intl.formatMessage({ id: 'com.text.approval.line.unregisteredUser' }) : reqTgtUser[0].reqTgtUserNm;
            if (reqTgtUser.length > 1) {
                tgtUserNm = `${tgtUserNm} ${intl.formatMessage({ id: `drive.text.list.columns.andOther` }, { count: reqTgtUser.length - 1 })}`;
            }
            tgtUserNmTitle = _.chain(reqTgtUser)
                .map(user => (ApprovalUtil.isUnregisteredUser(user.reqTgtUserId) ? intl.formatMessage({ id: 'com.text.approval.line.unregisteredUser' }) : user.reqTgtUserNm))
                .join(', ')
                .value();
        }
        const isReceived = reqMngListParam.reqType === 'received';

        return (
            <div role="button" className={`grid-row ${isChecked ? ' is-checked ' : ''}`} style={style} onClick={event => this.clickCheckBoxElement(event, index)}>
                <div className="grid-cell files-check-cell">
                    <div className="form-check" style={checkboxDisplayStyle}>
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isChecked || false}
                                onChange={event => this.handleCheckChildElement(event, index)}
                                disabled={reqStatCd === 'PROCESSING' && reqConfmTpCd !== 'KNOX' ? '' : 'disabled'}
                            />
                            <span className="label-text" />
                        </label>
                    </div>
                </div>
                <div className="grid-cell files-reqSect-cell">
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={reqMngList[index].reqLstNm}>
                            {reqMngList[index].reqLstNm}
                        </div>
                    </div>
                </div>
                <div className="grid-cell files-reqInfo-cell">
                    <div role="button" className="g-inline-flex text-link" onClick={() => this.openReqDtlPop(reqMngList[index].reqLstId, reqMngList[index].reqId, index)}>
                        <div className="ellipsis" title={this.getReqContent(reqMngList[index])}>
                            {this.getReqContent(reqMngList[index])}
                        </div>
                    </div>
                </div>
                <div className="grid-cell files-reqStrNm-cell">
                    <div className="g-inline-flex">
                        <div className="ellipsis">
                            <span
                                className="name has-name-card"
                                title={isReceived ? reqUserNm : tgtUserNmTitle}
                                onClick={e => e.stopPropagation()}
                                style={{ cursor: 'pointer' }}
                                role="button"
                                id={`reqUserCard_${reqMngList[index].reqId}`}>
                                {isReceived ? reqUserNm : tgtUserNm}
                            </span>
                            {!isUnregTgtUser && <UserCard target={`reqUserCard_${reqMngList[index].reqId}`} id={isReceived ? regUserId : tgtUserId} />}
                        </div>
                    </div>
                </div>

                <div className="grid-cell files-reqDtm-cell">{DateConverter.convert(reqMngList[index].regDt)}</div>
                <ReqMngBodyReqStat index={index} reload={this.reload} />
            </div>
        );
    };

    renderEmpty = () => {
        const { reqMngListParam, intl } = this.props;

        let emptyMsg = '';

        if (reqMngListParam.reqType === 'received') {
            emptyMsg = intl.formatMessage({ id: 'com.text.ReqMngBody.receivedEmpty' });
        } else {
            emptyMsg = intl.formatMessage({ id: 'com.text.ReqMngBody.sendReqEmpty' });
        }

        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-req" />
                    <p>{emptyMsg}</p>
                </div>
            </div>
        );
    };

    render() {
        const { reqMngList, reqMngListSelected, reqMngListParam, intl, pathname } = this.props;
        const { height, reqList, allCheckedYn, changing, receivedReqsCount } = this.state;
        const checkboxDisplayStyle = reqMngListSelected.checkedCnt > 0 ? { display: 'block' } : {};
        const alarmDisplayStyle = receivedReqsCount > 0 ? {} : { display: 'none' };
        const { allChecked: isAllChecked } = reqMngListSelected;

        return (
            <div className="drv-list">
                <section className="drv-file-panel">
                    <div className="drv-nav">
                        <nav>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${reqMngListParam.reqType === 'send' && pathname === '/req_mng/sent' ? 'active' : ''}`}
                                        role="button"
                                        onClick={() => this.changeReqType('send')}>
                                        <FormattedMessage id="com.tab.ReqMngBody.sendReq" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${reqMngListParam.reqType === 'received' && pathname === '/req_mng/received' ? 'active' : ''}`}
                                        role="button"
                                        onClick={() => this.changeReqType('received')}>
                                        <FormattedMessage id="com.tab.ReqMngBody.receivedReq" />
                                        <em className="new-badge" style={alarmDisplayStyle} />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="comm-title-bar">
                        <div className="section-filter">
                            <span className="select-type-box" style={{ width: '230px' }}>
                                <SelectBox dataWidth="230" options={reqList} value={reqMngListParam.reqListType} onChange={this.changeReqListType} />
                            </span>
                        </div>
                    </div>

                    <div className="req-list">
                        <div className="comm-grid-list" style={{ WebkitUserSelect: 'none', WebkitUserDrag: 'none', WebkitAppRegion: 'no-drag' }}>
                            {reqMngList.length === 0 && changing && this.renderEmpty()}
                            <div className="grid-header-group">
                                <div className="grid-row">
                                    <div className="grid-cell files-check-cell">
                                        <div className="form-check" style={checkboxDisplayStyle}>
                                            {allCheckedYn && (
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" checked={isAllChecked} onChange={this.handleAllChecked} />
                                                    <span className="label-text" />
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="grid-cell files-reqSect-cell">
                                        <span className="column-name">
                                            <FormattedMessage id="com.text.ReqMngBody.ReqListType" />
                                        </span>
                                    </div>
                                    <div className="grid-cell files-reqStrNm-cell">
                                        <span className="column-name">
                                            {reqMngListParam.reqType === 'received'
                                                ? intl.formatMessage({ id: 'com.text.ReqMngBody.reqUserNm' })
                                                : intl.formatMessage({ id: 'com.text.ReqMngBody.tgtUserNm' })}
                                        </span>
                                    </div>
                                    <div className="grid-cell files-reqInfo-cell">
                                        <span className="column-name">
                                            <FormattedMessage id="com.text.ReqMngBody.reqContent" />
                                        </span>
                                    </div>
                                    <div className="grid-cell files-reqDtm-cell">
                                        <a role="button" className={`column-sort ${reqMngListParam.sortOrder}`} onClick={this.changeSort}>
                                            <em>
                                                <FormattedMessage id="com.text.ReqMngBody.reqDt" />
                                            </em>
                                            <span className="btn-ic-nor">
                                                <i className={`ic-12-arr-${reqMngListParam.sortOrder === 'asc' ? 'asce' : 'desc'}`} />
                                            </span>
                                        </a>
                                    </div>
                                    {reqMngListParam.reqType === 'received' && <div className="grid-cell files-reqOption-cell" />}
                                    {reqMngListParam.reqType === 'send' && (
                                        <div className="grid-cell files-reqStat-cell">
                                            <span className="column-name">
                                                <em>
                                                    <FormattedMessage id="com.text.ReqMngBody.reqStatCd" />
                                                </em>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {reqMngList.length !== 0 && (
                                <CustomList
                                    className="grid-row-group scroll-bar"
                                    customScroll={false}
                                    virtualized={true}
                                    style={{ overflowY: 'scroll' }}
                                    windowResize={true}
                                    handleWindowResize={this.handleWindowResize}
                                    height={height}
                                    rowCount={reqMngList.length + 1}
                                    rowHeight={61}
                                    rowBuilder={this.rowBuilder}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

ReqMngBody.propTypes = {
    reqMngList: PropTypes.array.isRequired,
    reqMngListParam: PropTypes.object.isRequired,
    reqMngListSelected: PropTypes.object.isRequired,
    setReqMngListParam: PropTypes.func,
    setReqMngList: PropTypes.func,
    push: PropTypes.func.isRequired,
    setReqMngListSelected: PropTypes.func,
    pathname: PropTypes.string
};

ReqMngBody.defaultProps = {
    setReqMngListParam: () => {},
    setReqMngList: () => {},
    setReqMngListSelected: () => {},
    pathname: 'sent'
};

const mapStateToProps = state => ({
    reqMngList: state.reqMng.reqMngList,
    reqMngListParam: state.reqMng.reqMngListParam,
    reqMngListSelected: state.reqMng.reqMngListSelected
});

const mapDispatchToProps = {
    setReqMngListParam: reqMngActions.setReqMngListParam,
    setReqMngList: reqMngActions.setReqMngList,
    setReqMngListSelected: reqMngActions.setReqMngListSelected,
    push: routerPush
};
const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqMngBody));

export default connected;
