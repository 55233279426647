import React from 'react';
import { FormattedMessage } from 'react-intl';
import DeleteRootRow from 'components/containers/DriveFiles/Row/DeleteRootRow';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import _ from 'lodash';
import Base from './Base';
import FoldersRest from '../../apis/FoldersRest';
import FileCache from '../FileCache';
import { getReduxState } from '../../redux/store';
import { isOffWorkSite } from '../../constants/SiteMode';

class Deleted extends Base {
    ROOT_FOLDER_ID = '';

    pageId = 'deleted';

    menu = {
        header: {
            iconGroupType: null, // 선택 안했을때 나오는 버튼 없음
            iconGroupTypeMulti: 'deleteMulti' // 멀티선택 시 icon 영역
        },
        row: {
            iconGroupType: 'delete',
            menuGroupType: 'delete',
            floatingIconGroupType: null // floating 사용안함
        }
    };

    enforceSearchAfter = folderId => {
        return !!folderId && this.ROOT_FOLDER_ID !== folderId;
    };

    sortPossibleColumns(folderId) {
        if (this.isRoot(folderId)) {
            return ['objtNm'];
        }
        return null;
    }

    showHeaderCheckBox(folderId) {
        if (this.isRoot(folderId)) {
            return false;
        }
        return true;
    }

    searchFunction(params, folderId) {
        return this.isRoot(folderId)
            ? FoldersRest.getFolders(this.pageId, params).then(data => {
                  const { list } = data;
                  const {
                      auth: {
                          user: { id: userId }
                      },
                      site: { siteMode }
                  } = getReduxState();

                  // offlineWg 요건에 따른 'My files' 폴더 미노출
                  return isOffWorkSite(siteMode) ? { ...data, list: _.filter(list, ({ drive: { objtId } }) => objtId !== userId) } : data;
              })
            : FoldersRest.getDeleted(folderId, params).then(data => ({ list: data.map(d => ({ drive: d })), total: -1 }));
    }

    possibleNameClick(folderId) {
        if (this.isRoot(folderId)) return true;
        return false;
    }

    goToPage(file, context, e) {
        const {
            props: { history, folderId }
        } = context;
        const { drive } = file;
        if (this.isRoot(folderId)) {
            history.push(`/${this.pageId}/${drive.objtId}`, { onpstId: drive.onpstId });
            e.stopPropagation();
        } else {
            super.goToPage(file, context, e);
        }
    }

    buildCache() {
        return new FileCache(() => {
            return null; // 휴지통에서는 어떤작업을하던 사라짐
        });
    }

    uploadBuilder() {}

    rowBuilder({ list, loadMore, style, index, folderId }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        if (this.isRoot(folderId)) {
            return <DeleteRootRow file={list[index]} index={index} style={style} />;
        }
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-del" />
                    <p>
                        <FormattedMessage id="drive.text.list.empty.deleted" />
                    </p>
                </div>
            </div>
        );
    }
}

export default new Deleted();
