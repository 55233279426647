import { SUMMARY_RESPONSE_CODE } from '../SummaryConstant';

/**
 * 요약 옵션명이 표시되는 위치에 따라, 리소스를 분리해서 관리
 * 옵션이 추가되는 경우, 아래 규칙을 따라 리소스 생성
 *
 * 옵션 선택 다이어로그: drive.filter.copilot.summary.option.{type}.{subType}
 * 요약 내용 상단: drive.text.copilot.summary.option.{type}.{subType}
 */
const CATEGORY_TEXT = 'text';
const CATEGORY_FILTER = 'filter';
const OPTION_LENGTH_TYPE = 'lengthType';
const OPTION_FORM_TYPE = 'formType';
const OPTION_SCOPE_TYPE = 'scopeType';

function getMessageIdByOptionType(elementType, type, subType) {
    return `drive.${elementType}.copilot.summary.option.${type}.${subType}`;
}

export function getTextMessageIdByLengthType(subType) {
    return getMessageIdByOptionType(CATEGORY_TEXT, OPTION_LENGTH_TYPE, subType);
}

export function getTextMessageIdByFormType(subType) {
    return getMessageIdByOptionType(CATEGORY_TEXT, OPTION_FORM_TYPE, subType);
}

export function getTextMessageIdByScopeType(subType) {
    return getMessageIdByOptionType(CATEGORY_TEXT, OPTION_SCOPE_TYPE, subType);
}

export function getFilterMessageIdByLengthType(subType) {
    return getMessageIdByOptionType(CATEGORY_FILTER, OPTION_LENGTH_TYPE, subType);
}

export function getFilterMessageIdByFormType(subType) {
    return getMessageIdByOptionType(CATEGORY_FILTER, OPTION_FORM_TYPE, subType);
}

export function getFilterMessageIdByScopeType(subType) {
    return getMessageIdByOptionType(CATEGORY_FILTER, OPTION_SCOPE_TYPE, subType);
}

export function getMessageIdBySummaryResponseCode(errorCode) {
    const prefix = 'drive.text.copilot.summary.status.error';

    switch (errorCode) {
        case SUMMARY_RESPONSE_CODE.fileNotAllowed:
            return `${prefix}.fileNotAllowed`;
        case SUMMARY_RESPONSE_CODE.fileNotFound:
            return `${prefix}.fileNotFound`;
        case SUMMARY_RESPONSE_CODE.notSupportedExtType:
            return `${prefix}.notSupportedExt`;
        case SUMMARY_RESPONSE_CODE.textNotExist:
            return `${prefix}.textNotExist`;
        case SUMMARY_RESPONSE_CODE.textSizeLimitExceeded:
            return `${prefix}.textSizeLimitExceeded`;
        case SUMMARY_RESPONSE_CODE.llmInfoNotFound:
            return `${prefix}.llmInfoNotFound`;
        case SUMMARY_RESPONSE_CODE.jobInProgress:
            return `${prefix}.jobInProgress`;
        default:
            console.error(`Summary error code: ${errorCode}`);
            return `${prefix}.unexpected`;
    }
}
