import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Choose, When, Otherwise } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import UserRest from 'apis/UserRest';
import useDebounce from 'components/hooks/UseDebounce';

import UserItem from './UserItem';
import TeamItem from './TeamItem';

const InstantSearchList = props => {
    const [list, setList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { select, query, keyEvent, targetTypes } = props;
    const scrollRef = React.createRef();

    function adjustScroll(targetIndex) {
        const liTagHeight = _.get(scrollRef.current.getElementsByTagName('li'), '[0].offsetHeight', 65);
        const currentClientHeight = scrollRef.current.clientHeight;
        const currentScrollTop = scrollRef.current.scrollTop;
        const selectedItemScrollTop = liTagHeight * _.max([targetIndex, 0]);
        if (selectedItemScrollTop >= currentScrollTop + currentClientHeight) {
            scrollRef.current.scrollTop = currentScrollTop + liTagHeight;
        }
        if (selectedItemScrollTop < currentScrollTop) {
            scrollRef.current.scrollTop = currentScrollTop - liTagHeight;
        }
    }

    const debouncedQuery = useDebounce(query, 600);

    useEffect(() => {
        if (_.isEmpty(debouncedQuery)) {
            setList([]);
        } else {
            UserRest.instant({ query: debouncedQuery, targetTypes }).then(result => {
                setList(result);
            });
        }
    }, [debouncedQuery]);

    useEffect(() => {
        if (_.isEmpty(list)) {
            return;
        }

        if (keyEvent.keyCode === 13) {
            // enter
            if (selectedIndex > -1 && selectedIndex < _.size(list)) {
                select(list[selectedIndex]);
            }

            return;
        }

        let index = selectedIndex;
        if (keyEvent.keyCode === 40) {
            // down
            if (_.size(list) - 1 <= index) {
                return;
            }
            index += 1;
        }

        if (keyEvent.keyCode === 38) {
            // up
            if (index === 0) {
                return;
            }
            index -= 1;
        }

        if (_.includes([38, 40], keyEvent.keyCode)) {
            setSelectedIndex(index);
            adjustScroll(index);
        }
    }, [keyEvent]);

    function initSelectedIndex() {
        if (selectedIndex > -1) {
            setSelectedIndex(-1);
        }
    }

    return (
        <div className="fixed-layer-wrapper" style={{ display: _.isEmpty(query) ? 'none' : 'block' }}>
            <div className="fixed-layer">
                <div className="instant-srch">
                    <div className="scroll">
                        <div ref={scrollRef}>
                            <ul className="instant-srch-list subject-user row-5" onMouseEnter={initSelectedIndex}>
                                <Choose>
                                    <When condition={_.isEmpty(list)}>
                                        <li key="instant_empty_msg" className="empty" style={{ cursor: 'default' }}>
                                            <div className="box-empty">
                                                <div className="message">
                                                    <p>
                                                        <FormattedMessage id="user.guideText.pop_InstantSearch.noItems" />
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </When>
                                    <Otherwise>
                                        {list.map((item, index) => {
                                            const itemProps = { key: item.id, onClick: () => select(item), liTagClassName: index === selectedIndex ? 'on' : '', ...item };
                                            return item.type === 'user' ? <UserItem {...itemProps} /> : <TeamItem {...itemProps} />;
                                        })}
                                    </Otherwise>
                                </Choose>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

InstantSearchList.propTypes = {
    query: PropTypes.string,
    select: PropTypes.func,
    keyEvent: PropTypes.object,
    targetTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

InstantSearchList.defaultProps = {
    query: undefined,
    select: _.noop,
    keyEvent: undefined,
    targetTypes: 'all'
};

export default InstantSearchList;
