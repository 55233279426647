import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import uuidV4 from 'uuid/v4';
// import classNames from 'classnames/bind';
import 'react-quill/dist/quill.snow.css';
import './editorStyles.scss';

const IS_MSIE = navigator.userAgent.match(/Trident/i);

class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = { text: '' };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        const { html } = this.props;
        if (prevProps.html !== html) {
            this.init();
        }
    }

    init = () => {
        const { html } = this.props;
        let text = html;
        if (_.isEmpty(html)) {
            text = IS_MSIE ? '<p>&nbsp;</p>' : '';
        }
        this.setState({
            key: uuidV4(),
            text
        });
    };

    handleChange = (value, editor) => {
        let contents = value;
        if (IS_MSIE) {
            if (_.includes(contents, '<p><br></p>')) {
                contents = contents.replace(/<p><br><\/p>/gi, '<p>&nbsp;</p>');
            }
        }
        const { getHTML, isEmpty } = this.props;
        const empty =
            editor.getText().trim().length === 0 &&
            !_.find(editor.getContents().ops, temp => {
                const { insert } = temp;
                const { image } = insert;
                return image;
            });
        this.setState({ text: contents }, () => {
            getHTML(contents);
            isEmpty(empty);
        });
    };

    render() {
        const { text, key } = this.state;
        return (
            <div className="editor-quill">
                <ReactQuill key={key} defaultValue={text} readOnly={true} onChange={(content, delta, source, editor) => this.handleChange(content, editor)} />
            </div>
        );
    }
}

Editor.propTypes = {
    html: PropTypes.node,
    getHTML: PropTypes.func,
    isEmpty: PropTypes.func
};

Editor.defaultProps = {
    html: '',
    getHTML: _.noop,
    isEmpty: _.noop
};

export default Editor;
