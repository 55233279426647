import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import store from '../../../../redux/store';
import { slectFile, openInput } from '../../../../redux/actions/Upload';

const executor = (props, files, e) => {
    if (!!e.target.files && e.target.files.length > 0) {
        const selectedFiles = [...e.target.files];
        store.dispatch(slectFile(selectedFiles, files[0].drive));

        // eslint-disable-next-line no-param-reassign
        e.target.value = '';

        const { custom = {} } = props;
        if (custom.closeContextMenu) custom.closeContextMenu();
    }
};

class Upload extends BaseMenu {
    getName() {
        return 'upload';
    }

    getLabel() {
        return 'drive.button.list.menu.upload';
    }

    isVisible() {
        return super.isVisible(false, 'upload');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return (
            <span key={this.getName()} className="file-upload">
                <a
                    className="btn-ic-nor"
                    role="button"
                    onClick={() => {
                        const uploadInput = document.getElementById('uploadInput');
                        if (uploadInput) {
                            const newExecutor = _.partial(executor, this.props, this.files);
                            store.dispatch(openInput(this.files[0].drive, newExecutor));
                        }
                    }}>
                    <i className="ic-12-upload" />
                    <span className="btn-text">
                        <FormattedMessage id={this.getLabel()} />
                    </span>
                </a>
            </span>
        );
    }

    getIconDom() {
        return this.getBaseUploadIconDom(this.getName(), 'option-upload', 'ic-20-upload', executor);
    }

    execute(e) {
        executor(this.props, this.files, e);
    }
}

export default Upload;
