import { $http } from 'modules/index';
import { toast } from '../utils/ModalService';

class FileLifeCycleRest {
    policy = undefined;

    // 함수 안에 this가 있음으로. arrow function 으로 정의해야함. 아니면 bind...
    callLifeCyclePolicy = () => {
        // if (this.policy) {
        //     return Promise.resolve(this.policy);
        // }
        return $http.get(`/drive/v1/file-lifecycle/policy`).then(response => {
            const { resultCode, data: policy } = response;
            if (response && resultCode === 200) {
                return policy;
            }
            toast('drive.toast.list.api.folders.fail');
            return Promise.reject(response);
        });
    };

    getShouldRequest() {
        return $http.get('').then(response => response, error => error);
    }

    getApprover(searchTgt) {
        return $http.get(`/drive/v1/file-lifecycle/EXPIRATION/request/approver?searchTgt=${searchTgt}`).then(response => response, error => error);
    }

    saveExtenstionRequest(fileIds, expiredDate, approverId, reason, reasonKey) {
        const params = { fileIds, expiredDate, approverId, reason, reasonKey };
        return $http.post('/drive/v1/file-lifecycle/EXPIRATION/request', params).then(response => response, error => error);
    }

    saveExceptProhitWord(fileIds, approverId, reason, searchTgt, reasonKey) {
        const params = { fileIds, approverId, reason, searchTgt, reasonKey };
        return $http.post('/drive/v1/file-lifecycle/COMPLIANCE/request', params).then(response => response, error => error);
    }

    getRequestSystemType(type = 'COMPLIANCE') {
        return $http.get(`/drive/v1/file-lifecycle/${type}/request/system`).then(response => response, error => error);
    }

    getApproverCandidates(searchTgt) {
        return $http.get(`/drive/v1/file-lifecycle/EXPIRATION/request/approver/candidates?searchTgt=${searchTgt}`).then(response => response, error => error);
    }

    saveRequest(type = 'COMPLIANCE', params = {}) {
        return $http.post(`/drive/v1/file-lifecycle/${type}/request`, params).then(response => response, error => error);
    }

    getReasonPresets(type = 'COMPLIANCE') {
        return $http.get(`/drive/v1/file-lifecycle/${type}/request/reason`).then(response => response, error => error);
    }
}

export default new FileLifeCycleRest();
