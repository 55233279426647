import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CriticalItemDt from 'presentationals/Row/CriticalItemDt';
import _ from 'lodash';
import SelectBox from '../../../presentationals/SelectBox';

class RequestBodyReqRsn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: '',
            editable: false
        };

        this.maxLength = 512;
    }

    onChangeReason = e => {
        const { changeReason } = this.props;

        let reason = '';
        if (e.target.value.length > this.maxLength) {
            reason = e.target.value.substring(0, this.maxLength);
        } else {
            reason = e.target.value;
        }

        this.setState({ reason });
        changeReason(reason);
    };

    hasPresets = () => {
        const { presets } = this.props;
        return presets && presets.length > 0;
    };

    onChangeReasonPreset = value => {
        const { presets, changeReason, changeReasonKey } = this.props;
        const presetByValue = _.keyBy(presets, preset => `${preset.value}`);
        const preset = presetByValue[value];
        if (_.isEmpty(preset)) return;

        const { reason, value: valueKey, editable } = preset;
        this.setState({
            reason,
            editable
        });
        changeReason(reason);
        changeReasonKey(valueKey);
    };

    render() {
        const { intl, isInvalid, presets } = this.props;
        const { reason, editable } = this.state;

        const reasonTitle = intl.formatMessage({ id: 'com.text.approval.requestReason' });
        const presetsTitle = intl.formatMessage({ id: 'com.placeHolder.approval.line.pleaseSelect' }, { target: reasonTitle });
        const isTextareaShow = !this.hasPresets() || editable;

        return (
            <>
                <dl className="row-list">
                    <CriticalItemDt text={intl.formatMessage({ id: 'drive.text.exception.reason' })} />
                    <dd>
                        {this.hasPresets() && (
                            <span className="select-type-box">
                                <SelectBox type="box" dataContainer="body" style={{ width: '100%' }} title={presetsTitle} options={presets} onChange={this.onChangeReasonPreset} />
                            </span>
                        )}

                        {isTextareaShow && (
                            <div className="form-group">
                                <textarea
                                    className={`form-control textarea-sm ${isInvalid ? 'is-invalid' : ''}`}
                                    placeholder={`${intl.formatMessage({ id: 'drive.placeHolder.exception.reason' })}`}
                                    onChange={this.onChangeReason}
                                    value={reason}
                                />
                                <div className="input-remark">
                                    <div className="err-msg" style={{ display: isInvalid ? 'inline-flex' : 'none' }}>
                                        {intl.formatMessage({ id: 'drive.guideText.exception.reason' })}
                                    </div>
                                    <div className="write-count">
                                        <strong className="count-num">{reason.length}</strong> / <span className="write-total">{this.maxLength}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </dd>
                </dl>
            </>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

RequestBodyReqRsn.propTypes = {
    intl: PropTypes.object,
    changeReason: PropTypes.func,
    changeReasonKey: PropTypes.func,
    isInvalid: PropTypes.bool,
    presets: PropTypes.array
};

RequestBodyReqRsn.defaultProps = {
    intl: {},
    isInvalid: false,
    changeReason: () => {},
    changeReasonKey: () => {},
    presets: []
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RequestBodyReqRsn));

export default connected;
