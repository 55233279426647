import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CustomList from 'components/presentationals/List/CustomList';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import UserRest from 'apis/UserRest';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchUserRow from './SearchUserRow';
import GlobalPopup from '../../../redux/service/GlobalPopup';

class SearchUser extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            isFirstLoaded: false,
            showRelateList: false,
            isFocused: false,
            searchType: '',
            orderState: 'A',
            orderStateForName: '',
            orderStateForDeptNm: '',
            orderStateForPosition: '',
            relateListHover: 'off'
        };
    }

    getSearchType = value => {
        const { searchText } = this.state;
        const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

        if (_.isEmpty(trimmedSearchText)) {
            GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
            return;
        }
        this.setState({ searchType: value }, this.getSearchText);
    };

    closeRelateList = () => {
        this.setState({ showRelateList: false });
    };

    getSearchText = () => {
        const { tenantId } = this.props;
        const { searchText, searchType, orderParam, orderState } = this.state;
        let params = {
            offset: 0,
            userStatCd: ['A', 'S', 'L'].join(','),
            tenantId,
            orderParam,
            orderState
        };

        if (_.isEqual(searchType, 'userLangCdNm')) {
            params = _.assign(params, { userLangCdNmLike: searchText });
        }

        if (_.isEqual(searchType, 'userLoginId')) {
            params = _.assign(params, { userLoginIdLike: searchText });
        }

        if (_.isEqual(searchType, '')) {
            params = _.assign(params, { complexSearch: searchText });
        }

        UserRest.getUser(params).then(response => {
            const list = _.get(response, 'data', []);
            const total = _.get(response, 'total', 0);

            this.setState({
                list,
                total,
                isFirstLoaded: true,
                showRelateList: false,
                searchType: ''
            });
        });
    };

    onChangeSearchText = event => {
        const searchText = _.get(event, 'target.value');
        this.setState({ searchText, showRelateList: !_.isEmpty(searchText) });

        if (_.isEmpty(searchText)) {
            this.setState({ searchType: '' });
        }
    };

    clickSearchButton = () => {
        const { searchText } = this.state;
        const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

        if (_.isEmpty(trimmedSearchText)) {
            GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
            return;
        }
        this.setState({ searchText }, this.getSearchText);
    };

    onChangeSearchTextByEnter = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const searchText = _.get(event, 'target.value');

            const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

            if (_.isEmpty(trimmedSearchText)) {
                GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
                return;
            }
            this.setState({ searchText }, this.getSearchText);
        }
    };

    changeOrderState = orderParam => {
        const { orderStateForName, orderStateForDeptNm, orderStateForPosition } = this.state;
        const { languageCode } = this.props;

        let newOrderParam = '';
        let newOrderState = '';
        let newOrderSign = '';
        if (orderParam === 'userLangCdNm') {
            if (orderStateForName === '' || orderStateForName === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '001' ? 'userNm' : 'userEngNm';
            this.setState({ orderStateForName: newOrderSign });
            this.setState({ orderStateForPosition: '' });
            this.setState({ orderStateForDeptNm: '' });
        }

        if (orderParam === 'deptLangCdNm') {
            if (orderStateForDeptNm === '' || orderStateForDeptNm === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '001' ? 'deptNm' : 'deptEngNm';
            this.setState({ orderStateForName: '' });
            this.setState({ orderStateForPosition: '' });
            this.setState({ orderStateForDeptNm: newOrderSign });
        }

        if (orderParam === 'userLangCdCopNm') {
            if (orderStateForPosition === '' || orderStateForPosition === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '001' ? 'userCopNm' : 'userCopEngNm';
            this.setState({ orderStateForName: '' });
            this.setState({ orderStateForPosition: newOrderSign });
            this.setState({ orderStateForDeptNm: '' });
        }
        this.setState({ orderParam: newOrderParam, orderState: newOrderState }, this.getSearchText);
    };

    selectUser = selected => {
        const { handleSelect } = this.props;
        const { list } = this.state;
        const listForHover = Object.assign({}, list);
        _.forEach(list, (item, index) => {
            if (item.userId === selected.userId) {
                listForHover[index].isHovered = true;
            } else {
                listForHover[index].isHovered = false;
            }
        });
        this.setState({ list: listForHover });
        handleSelect(selected);
    };

    rowBuilder = ({ style, index }) => {
        const { list } = this.state;
        return <SearchUserRow user={list[index]} singleSearch={true} checkSearchUserForSingleSelect={this.selectUser} style={style} key={list[index].userId} checkBox={false} />;
    };

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    onBlurInputText = () => {
        this.setState({ isFocused: false });
    };

    clearSearchText = () => {
        // TODO: input의 onfocus랑 이벤트 겹쳐서 가끔 실행안될 때 있어서 onfocus 없애야할듯
        this.setState({ searchText: '', showRelateList: false }, this.getTeamMember);
    };

    relateListhoverOn = () => {
        this.setState({ relateListHover: 'on' });
    };

    relateListhoverOff = () => {
        this.setState({ relateListHover: 'off' });
    };

    render() {
        const { intl } = this.props;
        const { searchText, isFirstLoaded, total, showRelateList, isFocused, orderStateForName, orderStateForPosition, orderStateForDeptNm, relateListHover } = this.state;
        return (
            <div className="modal-body">
                <div className="forms-container">
                    <div className={`box-input-group has-overlay-layer ${isFocused ? 'is-focused' : ''}`}>
                        <a className={`btn-ic-nor btn-func-search ${isFocused ? 'on' : ''}`} role="button" title={intl.formatMessage({ id: 'com.search' })} onClick={this.clickSearchButton}>
                            <i className="ic-16-search" />
                        </a>
                        <input
                            className="form-control"
                            type="text"
                            value={searchText}
                            onChange={event => this.onChangeSearchText(event)}
                            placeholder={intl.formatMessage({ id: 'user.placeHolder.popup.selectPeople.organize.searchUser' })}
                            onKeyDown={event => this.onChangeSearchTextByEnter(event)}
                            onFocus={event => this.onFocus(event)}
                            onBlur={this.onBlurInputText}
                        />
                        <a
                            className="btn-ic-nor btn-func-clear"
                            style={searchText ? { display: 'inline-flex' } : { display: 'none' }}
                            role="button"
                            title={intl.formatMessage({ id: 'com.text.clear' })}
                            onClick={this.clearSearchText}>
                            <i className="ic-16-clear" />
                        </a>

                        <div
                            className={`overlay-layer-wrapper ${showRelateList ? 'show' : ''}`}
                            role="button"
                            onClick={event => this.closeRelateList(event)}
                            onBlur={event => this.closeRelateList(event)}>
                            <div className="overlay-layer">
                                <div className="layer-content">
                                    <div className="layer-body">
                                        <ul className="related-list">
                                            <li onMouseEnter={this.relateListhoverOn} onMouseLeave={this.relateListhoverOff} className={`${relateListHover}`}>
                                                <a role="button" onClick={() => this.getSearchType('userLangCdNm')}>
                                                    {intl.formatMessage({ id: 'com.name' })}: <em>{searchText}</em>
                                                </a>
                                            </li>
                                            <li>
                                                <a role="button" onClick={() => this.getSearchType('userLoginId')}>
                                                    {intl.formatMessage({ id: 'com.email' })}: <em>{searchText}</em>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="srch-flex-layout">
                    <div className="flex-upper">
                        <div className="flex-box-right">
                            <div className="pop-grid-list">
                                <If condition={!isFirstLoaded}>
                                    <div className="box-empty">
                                        <div className="message">
                                            <p>
                                                {intl.formatMessage({ id: 'user.guideText.popup.selectPeople.enterSearchWord' })}
                                                <br />
                                                {intl.formatMessage({ id: 'user.guideText.popup.selectPeople.enterSearchWord2' })}
                                            </p>
                                        </div>
                                    </div>
                                </If>
                                <If condition={isFirstLoaded && total === 0}>
                                    <div className="scroll">
                                        <div>
                                            <div className="grid-row-group row-3" style={{ display: 'none' }}>
                                                <div style={{ height: '500px !important' }} />
                                            </div>
                                            <div className="box-empty row-6">
                                                <div className="message">
                                                    <p> {intl.formatMessage({ id: 'com.no-search-results' })}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </If>
                                <If condition={isFirstLoaded && total !== 0}>
                                    <div className="comm-grid-list only-elective react-list">
                                        <div className="grid-header-group">
                                            <div className="grid-row">
                                                <div className="grid-cell name-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdNm')} className={`column-sort ${orderStateForName}`}>
                                                        <em>
                                                            {' '}
                                                            <FormattedMessage id="com.name" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForName}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell position-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdCopNm')} className={`column-sort ${orderStateForPosition}`}>
                                                        <em>
                                                            <FormattedMessage id="com.position" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForPosition}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell department-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('deptLangCdNm')} className={`column-sort ${orderStateForDeptNm}`}>
                                                        <em>
                                                            <FormattedMessage id="com.department" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForDeptNm}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell email-cell">
                                                    <span className="column-name">
                                                        {' '}
                                                        <FormattedMessage id="com.email" />
                                                    </span>
                                                </div>
                                                <div className="grid-cell mobile-cell">
                                                    <span className="column-name">
                                                        {' '}
                                                        <FormattedMessage id="com.mobile" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll">
                                            <CustomList
                                                className="grid-row-group row-5"
                                                height={230}
                                                customScroll={true}
                                                virtualized={true}
                                                rowCount={total}
                                                rowHeight={46}
                                                rowBuilder={this.rowBuilder}
                                            />
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <div className="flex-box-bottom">
                                <div className="total-count">
                                    <span>
                                        {' '}
                                        <FormattedMessage id="com.text.total" />
                                    </span>
                                    <span className="count">
                                        <i className="num">{total}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SearchUser.propTypes = {
    handleSelect: PropTypes.func,
    intl: PropTypes.object.isRequired,
    tenantId: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired
};

SearchUser.defaultProps = {
    handleSelect: _.noop
};

export default injectIntl(SearchUser);
