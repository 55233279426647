import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import UserRest from 'apis/UserRest';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
// import moment from 'moment';
import ReqRejectPop from 'presentationals/ReqMng/Popup/ReqRejectPop';
import { alert, confirm, largePopup } from '../../../../utils/ModalService';
import ReqRest from '../../../../apis/ReqRest';
import DateConverter from '../../../../utils/DateConverter';

const ReqPartnerExpireExtendPop = props => {
    const { from, reqId, reqInfo, close, reload, index } = props;
    const [requester, setRequester] = useState({});
    const [approver, setApprover] = useState({});
    const [user, setUser] = useState({});
    const [baseDate, setBaseData] = useState('');
    const [newDate, setNewData] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        async function initData() {
            const { req, reqTgtUser, reqDtl } = reqInfo;
            const { reqStatCd, regUserId } = req;
            const requesterUserId = regUserId;
            const approverUserId = reqTgtUser[0].reqTgtUserId;
            const subcontractUserId = reqDtl.userId;
            const { expireDate, oldExpireDate } = reqDtl;

            const userIds = [];
            userIds.push(requesterUserId);
            userIds.push(approverUserId);
            userIds.push(subcontractUserId);

            const params = { userId: userIds.toString(), userStatCd: 'ALL' };

            const res = await UserRest.getUser(params);
            const { resultCode, data } = res;
            if (resultCode !== 200) {
                alert('com.alert.internalServerError');
                return;
            }

            setRequester(data.find(e => e.userId === requesterUserId));
            setApprover(data.find(e => e.userId === approverUserId));
            setUser(data.find(e => e.userId === subcontractUserId));
            setNewData(expireDate);
            setBaseData(oldExpireDate);
            setState(reqStatCd);
        }
        initData();
    }, [reqInfo]);

    const reqCancelEvent = reqPrcsType => {
        const reqPrcsList = [reqId];

        confirm(<FormattedMessage id="com.confirm.ReqMngBodyReqStat.cancel" />).then(
            async () => {
                const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

                if (res.resultCode === 500) {
                    alert('com.alert.internalServerError');
                } else if (res.resultCode === 400) {
                    alert('com.alert.reqInvalidParam');
                } else if (res.resultCode === 403 && !!res.data) {
                    alert(res.data);
                } else {
                    alert(res.message);
                }
                close(true);
                reload();
            },
            () => {}
        );
    };

    const reqRejectEvent = () => {
        largePopup(<ReqRejectPop index={index} rejectType="SINGLE" />);
        reload();
        close();
    };

    const reqApproveEvent = async reqPrcsType => {
        const reqPrcsList = [reqId];

        const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

        if (res.resultCode === 500) {
            alert('com.alert.internalServerError');
        } else if (res.resultCode === 400) {
            alert('com.alert.reqInvalidParam');
        } else if (res.resultCode === 403 && !!res.data) {
            alert(res.data);
        } else {
            alert(res.message);
        }
        close(true);
        reload();
    };

    return (
        <div className="modal-content" style={_.isEmpty(requester) ? { display: 'none' } : {}}>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.popup.reqPartner.expire-extention" />
                </h5>
                <a className="btn-ic-nor" role="button" title={<FormattedMessage id="com.close" />} data-dismiss="modal" onClick={() => close()}>
                    <i className="ic-20-close" />
                </a>
            </div>

            <div className="modal-body">
                <div className="dt-width-120">
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.ReqMngBody.reqUserNm" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title={`${requester.userLangCdNm} ${requester.userLangCdNm || ''} / ${requester.deptLangCdNm || ''} `}>
                                            <span className="name">{requester.userLangCdNm}</span>
                                            <span className="position">{`${approver.userLangCdCopNm || ''} / ${requester.deptLangCdNm || ''}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.reqRequest.approver" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title={`${approver.userLangCdNm} ${approver.userLangCdNm || ''} / ${approver.deptLangCdNm || ''} `}>
                                            <span className="name">{approver.userLangCdNm}</span>
                                            <span className="position">{`${approver.userLangCdCopNm || ''} / ${approver.deptLangCdNm || ''}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.new-expire-date" />
                        </dt>
                        <dd>
                            <div className="dd-text">{_.isEmpty(newDate) ? '' : DateConverter.convertExpireDate(Number(newDate))}</div>
                        </dd>
                    </dl>
                </div>

                <dl className="dt-divider">
                    <dt>
                        <FormattedMessage id="com.targeted" />
                    </dt>
                </dl>

                <div className="data-details">
                    <div>
                        <table className="data-table">
                            <caption>대상 리스트</caption>
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                                <col style={{ width: '210px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '110px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="com.name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.belong-company-name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.id(email)" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.text.cellphone" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.text.previous-expire-date" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="ellipsis" title={user.userLangCdNm}>
                                            {user.userLangCdNm}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ellipsis" title={user.orgnOnpstCoNm}>
                                            {user.orgnOnpstCoNm}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ellipsis" title={user.userLoginId}>
                                            {user.userLoginId}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ellipsis" title={user.userCelno}>
                                            {user.userCelno}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ellipsis" title={_.isEmpty(baseDate) ? '' : DateConverter.convertExpireDate(Number(baseDate))}>
                                            {_.isEmpty(baseDate) ? '' : DateConverter.convertExpireDate(Number(baseDate))}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <If condition={from !== 'RECEIVED'}>
                        <If condition={state === 'PROCESSING'}>
                            <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => reqCancelEvent('CANCEL')}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                                </span>
                            </a>
                        </If>
                        <a className="btn btn-lg btn-primary" role="button" onClick={() => close(true)}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </If>
                    <If condition={from === 'RECEIVED'}>
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => reqRejectEvent()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.reject" />
                            </span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => reqApproveEvent('APPROVAL')}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.approval" />
                            </span>
                        </a>
                    </If>
                </div>
            </div>
        </div>
    );
};

ReqPartnerExpireExtendPop.propTypes = {
    from: PropTypes.string,
    reqId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    reqInfo: PropTypes.object,
    index: PropTypes.number.isRequired
};

ReqPartnerExpireExtendPop.defaultProps = {
    from: '',
    reqInfo: {}
};

export default injectIntl(ReqPartnerExpireExtendPop);
