import moment from 'moment';
import uuidV4 from 'uuid/v4';

export const getDefaultValidation = () => {
    return {
        id: '',
        name: '',
        position: '',
        engName: '',
        engPosition: '',
        deptId: '',
        companyNm: ''
    };
};

export const getNewPartner = () => {
    return {
        uniqueKey: uuidV4(),
        hasKPAccount: true,
        tenantId: '',
        id: '',
        name: '',
        position: '',
        engName: '',
        engPosition: '',
        deptId: '',
        deptNm: '',
        language: '',
        companyNm: '',
        expireDate: moment()
    };
};
