import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Gnb from 'containers/Gnb';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import Upload from '../presentationals/Upload/Upload';
import MainFloatButton from '../presentationals/MainFloatButton';
import DragPointer from '../presentationals/DragDrop/DragPointer';
import { initializeForSummary } from '../containers/Summary/Common/Util/SummaryStorageUtil';
import { summaryActions } from '../../redux/actions/Summary';
import { getDefaultLLM } from '../containers/Summary/Common/Util/SummaryUtil';
import SummaryContent from '../presentationals/SummaryContent';
import PageContent from '../presentationals/PageContent';
import ConfigsRest from '../../apis/ConfigsRest';
import { setSiteMode } from '../../redux/actions/Site';
import EmptyGnb from '../containers/EmptyGnb';
import { SITE_MODE } from '../../constants/SiteMode';

// TODO 우선 기존 코드 유지. refactoring 필요
const isNoGnb = ['/noGnbMyFiles', '/noGnbSharedFiles', '/noGnbWorkgroups'].includes(window.location.pathname);
localStorage.setItem('isNoGnb', localStorage.getItem('isNoGnb') === 'true' ? true : isNoGnb);

if (window.location.pathname === '/home') {
    localStorage.setItem('isNoGnb', false);
}

const Main = ({ location }) => {
    const copilotGnbButtonRef = useRef();

    const [enableSummary, setEnableSummary] = useState(false);
    const [isGNBReadyToRender, setIsGNBReadyToRender] = useState(false);

    const dispatch = useDispatch();

    const fetchSiteModeAndRenderGNB = async () => {
        const response = await ConfigsRest.getProperties();
        const siteMode = _.get(_.find(response, { name: 'siteMode' }), 'value', SITE_MODE.normal);

        dispatch(setSiteMode(siteMode));
        setIsGNBReadyToRender(true);
    };

    const setupLLMIfSummarizationEnabled = async () => {
        // TODO refactor
        const { summarizable, llmList } = await initializeForSummary();

        setEnableSummary(summarizable);

        if (_.isEmpty(llmList)) {
            return;
        }

        dispatch(summaryActions.global.changeLLMList({ llmList }));
        dispatch(summaryActions.global.selectLLM({ llm: getDefaultLLM(llmList) }));
    };

    useEffect(() => {
        (async () => {
            await Promise.all([fetchSiteModeAndRenderGNB(), setupLLMIfSummarizationEnabled()]);
        })();
    }, []);

    return (
        <>
            <div className={`use-ui ${localStorage.getItem('isNoGnb') === 'true' ? 'none__header' : ''}`}>
                <div role="presentation">
                    <header className="header">
                        <Choose>
                            <When condition={isGNBReadyToRender}>
                                <Gnb {...location} copilotGnbButtonRef={copilotGnbButtonRef} enableSummary={enableSummary} />
                            </When>
                            <Otherwise>
                                <EmptyGnb />
                            </Otherwise>
                        </Choose>
                    </header>
                </div>
                <div className="main">
                    <div className="interaction-panel">
                        <div className="page-wrap">
                            <PageContent location={location} />
                        </div>
                    </div>
                </div>
                <Upload />
                <MainFloatButton />
                <DragPointer />
            </div>
            <SummaryContent enableSummary={enableSummary} copilotGnbButtonRef={copilotGnbButtonRef} />
        </>
    );
};

Main.propTypes = {
    location: PropTypes.object.isRequired
};

export default injectIntl(Main);
