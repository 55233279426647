import React from 'react';
import BaseMenu from '../BaseMenu';
import { popup, confirm, toast, alert } from '../../../../utils/ModalService';
import FolderFileNameInputPopup from '../../../containers/Popup/FolderFileNameInputPopup';
import FolderRest from '../../../../apis/FolderRest';
import FoldersRest from '../../../../apis/FoldersRest';
import FileCache from '../../../../utils/FileCache';

const executor = props => {
    const { folder } = props.custom;
    const { addAtHome } = props;

    function createFolder(name) {
        return FolderRest.createFolder(folder, name).then(rslt => {
            const { resultCode, data } = rslt;
            if (resultCode !== 200) {
                if (resultCode === 403) alert('drive.alert.incomLink.noAuthFolder');
                else alert(data || '');
                return false;
            }

            if (addAtHome && resultCode === 200) {
                const param = [{ drive: data, personalization: {} }];
                const cached = [new FileCache().updated(param, true), FoldersRest.addPreferFolders(param, true)];
                return Promise.all(cached).then(() => {
                    toast('drive.toast.newFolderPop.success');
                    props.callback({ reloadType: 'refresh', newFolder: data, folderChanged: true });
                    return true;
                });
            }

            toast('drive.toast.newFolderPop.success');
            props.callback({ reloadType: 'refresh', newFolder: data, folderChanged: true });
            return true;
        });
    }

    const popupProps = {
        headerName: 'drive.title.newFolderPop.header',
        saveApi: createFolder
    };
    const beforeMsg = '변경 사항이 있습니다. 그래도 취소하시겠습니까?';
    const config = {
        component: <FolderFileNameInputPopup {...popupProps} />,
        beforeCloseCallBack: () => confirm(beforeMsg).then(() => true, () => false)
    };
    popup(config);
};

class CreateFolder extends BaseMenu {
    getName() {
        return 'createFolder';
    }

    getLabel() {
        return 'drive.button.list.menu.createFolder';
    }

    isVisible() {
        return super.isVisible(false, 'create');
    }

    isEnable() {
        return true;
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-newfolder', executor);
    }

    execute() {
        executor(this.props);
    }
}

export default CreateFolder;
export { executor };
