import React from 'react';
import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import { popup, viewerPopup } from '../../../../utils/ModalService';
import FileOpen from '../../FileOpen/FileOpen';
import FileViewerPopup from '../../../containers/Popup/FileViewerPopup';

const isOverEditAuth = (authCd = '') => {
    const targetNumCd = _.toNumber(authCd.slice(1, 3));
    const editNumCd = _.toNumber('U60EDIT'.slice(1, 3));
    return targetNumCd >= editNumCd;
};

const executor = (props, files) => {
    // console.log('실행!!! files', files);
    const selectedFile = files[0].drive;
    // const { config } = files[0];
    // const expired = config.drive;
    // console.log('files[0].maxAuthCd, ', files[0].maxAuthCd);
    // const { expirationFuncLimitYn } = expired;

    const openViewer = () => {
        viewerPopup(<FileViewerPopup objtId={selectedFile.objtId} onpstId={selectedFile.onpstId} />).then(
            () => {
                // 뷰어에서 실행 클릭시 이쪽으로 리턴. 편집자가 업데이트 되어야할듯?
                return true;
            },
            () => {
                // 실행안누르고 닫은경우. 아무 변경이 없는루트.
                return false;
            }
        );
    };

    if (files[0].maxAuthCd === 'U20VIEW') {
        openViewer();
    } else {
        popup(<FileOpen file={selectedFile} />).then(
            () => {
                return true;
            },
            () => {
                return false;
            }
        );
    }
};

class Open extends BaseMenu {
    getName() {
        return 'open';
    }

    getLabel() {
        const file = this.files[0];

        if (isOverEditAuth(file.maxAuthCd)) {
            return 'drive.title.download.fileEditOpen';
        }
        if (file.maxAuthCd === 'U40COPY') {
            return 'drive.title.download.fileReadonlyOpen';
        }
        if (file.maxAuthCd === 'U20VIEW') {
            return 'drive.title.download.fileViewerOpen';
        }
        return 'drive.button.list.menu.open';
    }

    isVisible() {
        return super.isVisible(false, 'openFile', 'FILE');
    }

    isEnable() {
        return true;
    }

    getDom() {
        const file = this.files[0];
        const classNm = isOverEditAuth(file.maxAuthCd) ? 'ic-12-modify' : 'ic-12-open';
        return this.getBaseMenuDom(this.getName(), classNm, executor);
        // return this.getBaseMenuDom(this.getName(), 'ic-12-open', executor);
    }

    getIconDom() {
        const file = this.files[0];
        const classNm = isOverEditAuth(file.maxAuthCd) ? 'ic-20-modify' : 'ic-20-open';
        return this.getBaseIconDom(this.getName(), 'option-view', classNm, executor);
        // return this.getBaseIconDom(this.getName(), 'option-view', 'ic-12-open', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Open;
