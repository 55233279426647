import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FolderRest } from 'apis';
import { alert, toast } from 'utils/ModalService';
import CloseButton from 'presentationals/Button/CloseButton';
import SelectBox from 'presentationals/SelectBox';
import CriticalItemDt from 'presentationals/Row/CriticalItemDt';
import { FOLDER } from 'constants/ResultCode';
import { connect } from 'react-redux';
import { If } from 'jsx-control-statements';
import SingleDatePickerWrapper from '../../../presentationals/SingleDatePickerWrapper';
import FileLifeCycleRest from '../../../../apis/FileLifeCycleRest';
import FlcmUtil from '../../../../utils/FlcmUtil';
import ExpirationDateSelector from '../RetentionPeriod/ExpirationDateSelector';

const MAX_LENGTH_ON_REQUESTREASON = 512;

const RequestChangeDatePopup = props => {
    const { workgroupFolder, close, intl, user } = props;
    const { onpstId, objtId, objtNm } = workgroupFolder[0].drive;
    const { initialExpiredDate, maxExpiredDate, minExpiredDate } = FlcmUtil.getExpiredDateInfos(user);
    const { period, unitRscId } = FlcmUtil.getExpiredPeriodLabel(user.config);

    const [approverId, setApproverId] = useState('');
    const [requestReason, setRequestReason] = useState('');
    const [reasonMinLength, setReasonMinLength] = useState(1);
    const [approverList, setApproverList] = useState([]);
    const [isDisableSelect, setDisableSelect] = useState(false);
    const [expiredDt, setExpiredDt] = useState(initialExpiredDate);
    const [opinionPresets, setOpinionPresets] = useState([]);
    const [showTextArea, setShowTextArea] = useState(false);
    const [changeByCalendar, setChangeByCalendar] = useState(false);

    const getApproverList = async () => {
        const response = await FolderRest.getWorkgroupRequestApprover('WORKGROUP_RETENTION_DATE');
        const { resultCode, data } = response;
        if (resultCode === 200) {
            if (_.isEmpty(data)) {
                setDisableSelect(true);
            } else {
                const refined = data.map(approver => ({
                    message: `${approver.userNm} / ${approver.deptNm}`,
                    value: approver.userId
                }));
                setApproverList(refined);
            }
        }
    };

    const getOpinionPresets = async () => {
        const response = await FileLifeCycleRest.getReasonPresets('EXPIRATION');
        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            setOpinionPresets([]);
        }
        if (resultCode === 200) {
            const presets = _.map(data, preset => {
                let editable = false;
                let msg = preset.message;
                let minLength = 1;
                const presetMessage = preset.message;
                if (preset.key === 'ETC') {
                    editable = true;
                    msg = '';
                    minLength = 5;
                }
                return _.extend({}, preset, { editable, msg, minLength, message: presetMessage });
            });
            setOpinionPresets(presets);
        }
    };

    const reasonTitle = intl.formatMessage({ id: 'com.text.approval.requestReason' });
    const presetsTitle = intl.formatMessage({ id: 'com.placeHolder.approval.line.pleaseSelect' }, { target: reasonTitle });

    useEffect(() => {
        getApproverList();
        getOpinionPresets();
    }, []);

    const onChangeReasonPresets = value => {
        const presetByValue = _.keyBy(opinionPresets, preset => `${preset.value}`);
        const preset = presetByValue[value];
        const { msg, editable, minLength } = preset;
        setRequestReason(msg);
        setReasonMinLength(minLength);
        setShowTextArea(editable);
    };

    const onChangeReason = ({ target }) => {
        const { value } = target;
        if (_.size(value) > MAX_LENGTH_ON_REQUESTREASON) {
            return;
        }
        setRequestReason(value);
    };

    const dateChange = date => {
        const formattedDate = date.format('YYYY-MM-DD');
        setExpiredDt(moment(formattedDate));
    };

    const checkDisabled = () => _.isEmpty(approverId) || _.isEmpty(requestReason) || _.size(requestReason.trim()) < reasonMinLength;

    const alertAfterSave = (resultCode, data) => {
        if (resultCode === FOLDER.REQ_NOT_ALLOW_UPDATE_FOLDER) {
            alert('drive.alert.reques.notAllowedFolder');
        } else if (resultCode === FOLDER.REQ_INVALID_APPROVER) {
            alert('drive.alert.request.invalidApprover');
        } else if (resultCode === FOLDER.IOFFICE_INVALID_PARAMETER || resultCode === FOLDER.REQ_NOT_SUPPORT_FOLDER_REQUEST_TYPE_CODE) {
            alert('drive.alert.request.invalid');
        } else if (resultCode === FOLDER.REQ_INVALID_DATE) {
            alert('drive.alert.ReqExtendExpiredDtPop.invalidExpiredDate');
        } else {
            alert(data || '');
        }
    };

    const isExpiredDateToday = () => {
        return expiredDt.format('YYYY-MM-DD') === initialExpiredDate.format('YYYY-MM-DD');
    };

    const onSave = async () => {
        if (isExpiredDateToday()) {
            alert('drive.alert.exception.extension.expirationDateMustBeChanged');
            return;
        }

        const response = await FolderRest.changeWorkgroupDateRequest({
            approverId,
            requestReason: requestReason.trim(),
            expiredDt,
            objtId,
            onpstId
        });
        const { resultCode, data } = response;
        if (resultCode === 200 && !_.isEmpty(data)) {
            // const { skipApproval } = data.folderRequestInfo;
            // if (skipApproval) {
            //     toast('drive.toast.workgroup.dateChg.compeleted');
            // } else {
            toast('com.toast.request.to.create.workgroup');
            // } 요건 변경으로 인해 본인이 본인에게 결재를 올려도 요청 승인을 해야함.
            close();
        } else if (resultCode === 403) {
            alert('drive.alert.selectFolder.noAuth');
        } else {
            alertAfterSave(resultCode, data);
        }
    };

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="drive.text.workgroup.retentionChg" />
                </h5>
                <CloseButton close={close} />
            </div>
            <div className="modal-info">
                <div className="info-msg">
                    <i className="ic-16-info" />
                    <FormattedMessage id="drive.guideText.workgroup.chgBatch" />
                </div>
            </div>
            <ModalBody>
                <div className="dt-width-120">
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.workgroup.name" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-folder-name" title={objtNm}>
                                    {objtNm}
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="drive.text.workgroup.chgExpDt" />
                        </dt>
                        <dd>
                            <div className="forms-container">
                                <SingleDatePickerWrapper
                                    initialDate={expiredDt}
                                    minDate={minExpiredDate}
                                    maxDate={maxExpiredDate}
                                    onDateChange={date => {
                                        dateChange(date);
                                        setChangeByCalendar(true);
                                    }}
                                />
                                <ExpirationDateSelector onSelectExpirationDate={dateChange} onResetChangeByCalendar={() => setChangeByCalendar(false)} changeByCalendar={changeByCalendar} />
                            </div>
                            <div className="info-msg">
                                <i className="ic-16-info" />
                                {intl.formatMessage({ id: 'drive.guideText.exception.expirationDate' })}
                            </div>
                            <If condition={period && unitRscId}>
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedMessage id="drive.guideText.exception.expirationDate2" values={{ n: period, unit: intl.formatMessage({ id: unitRscId }) }} />
                                </div>
                            </If>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <CriticalItemDt text={intl.formatMessage({ id: 'drive.text.workgroup.approver' })} />
                        <dd>
                            <SelectBox
                                className="selectpicker"
                                title={intl.formatMessage({ id: 'drive.guideText.workgroup.selectApprover' })}
                                type="box"
                                options={approverList}
                                value={approverId}
                                onChange={setApproverId}
                                isDisabled={isDisableSelect}
                                dataWidth={550}
                            />
                            <div className="info-msg">
                                <i className="ic-16-info" />
                                <FormattedMessage id="drive.guideText.workgroup.noApprover" />
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <CriticalItemDt text={intl.formatMessage({ id: 'com.text.request.reason' })} />
                        <dd>
                            {opinionPresets && opinionPresets.length !== 0 && (
                                <span className="select-type-box">
                                    <SelectBox
                                        type="box"
                                        dataContainer="body"
                                        style={{ width: '100%' }}
                                        title={presetsTitle}
                                        options={opinionPresets}
                                        onChange={value => onChangeReasonPresets(value)}
                                    />
                                </span>
                            )}

                            {showTextArea && (
                                <div className="form-group">
                                    <textarea
                                        className={`form-control textarea-sm ${!_.isEmpty(requestReason) && _.size(requestReason.trim()) < 5 ? 'is-invalid' : ''}`}
                                        placeholder={`${intl.formatMessage({ id: 'drive.placeHolder.exception.reason' })}`}
                                        maxLength={MAX_LENGTH_ON_REQUESTREASON}
                                        onChange={onChangeReason}
                                        value={requestReason}
                                    />
                                    <div className="input-remark">
                                        {!_.isEmpty(requestReason) && _.size(requestReason.trim()) < 5 && (
                                            <div className="err-msg">
                                                <FormattedMessage id="com.text.write.reason.5letters.ormore" />
                                            </div>
                                        )}
                                        <div className="write-count">
                                            <strong className="count-num">{_.size(requestReason.trim())}</strong>/<span className="write-total">{MAX_LENGTH_ON_REQUESTREASON}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </dd>
                    </dl>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={close}>
                        <span className="btn-text">
                            <FormattedMessage id="com.cancel" />
                        </span>
                    </a>
                    <a className={`btn btn-lg btn-primary ${checkDisabled() ? 'disabled' : ''}`} role="button" disabled={checkDisabled()} onClick={onSave}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </ModalFooter>
        </>
    );
};

RequestChangeDatePopup.propTypes = {
    close: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    workgroupFolder: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(RequestChangeDatePopup));
