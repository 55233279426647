import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const DriveFilesContext = React.createContext({
    state: {},
    props: {},
    actions: {}
});
const { Provider: DriveFilesProvider, Consumer: DriveFilesConsumer } = DriveFilesContext;

const withFilesContext = (Comp, fields) => props => <DriveFilesConsumer>{value => <DriveFilesConsumerWrap props={props} context={value} Comp={Comp} fields={fields} />}</DriveFilesConsumer>;

function compareProps(props, source, target, isDeep = false) {
    const isNotEqual = isDeep ? (s, t) => !_.isEqual(s, t) : (s, t) => s !== t;
    if (
        props.some(ps => {
            const s = _.get(source, ps);
            const t = _.get(target, ps);
            return isNotEqual(s, t);
            // let s = source[ps[0]];
            // let sIdx = 0;
            // let t = target[ps[0]];
            // let tIdx = 0;
            // for (let idx = 1; idx < ps.length; idx += 1) {
            //     if (s) {
            //         s = s[ps[idx]];
            //         sIdx += 1;
            //     }
            //     if (t) {
            //         t = t[ps[idx]];
            //         tIdx += 1;
            //     }
            // }
            // if (sIdx !== tIdx) return true;

            // return s !== t;
        })
    ) {
        return true;
    }
    return false;
}

class DriveFilesConsumerWrap extends React.Component {
    constructor(props) {
        super(props);

        const { context } = props;
        this.sendContext = Object.assign({}, context);
    }

    shouldComponentUpdate(nextProps) {
        const { context, props: compProps } = this.props;
        const { context: nextContext, props: nextCompProps, fields } = nextProps;

        let sendContext = Object.assign({}, nextContext);
        let update = false;
        if (compProps !== nextCompProps) {
            update = true;
        }

        if (fields) {
            if (!compareProps(fields, context, nextContext)) {
                sendContext = Object.assign(this.sendContext, nextContext);
            } else {
                update = true;
            }
        } else {
            update = true;
        }
        this.sendContext = sendContext;
        return update;
    }

    render() {
        const { Comp, props } = this.props;
        return <Comp {...props} context={this.sendContext} />;
    }
}

DriveFilesConsumerWrap.defaultProps = {
    fields: undefined
};

DriveFilesConsumerWrap.propTypes = {
    Comp: PropTypes.any.isRequired,
    context: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired,
    fields: PropTypes.array
};

export { compareProps, DriveFilesProvider, DriveFilesContext, DriveFilesConsumer, withFilesContext };
