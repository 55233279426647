import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import Base from './Base';
import FoldersRest from '../../apis/FoldersRest';
import { LOW_HEIGHT } from '../../components/containers/DriveFiles/HeaderRow/SettingHeader';
import DuplicatedTab from '../../components/presentationals/Duplicated/DuplicatedTab';
import DuplicatedBody from '../../components/presentationals/Duplicated/DuplicatedBody';
import SimilarSearch from '../../components/presentationals/Duplicated/SimilarSearch';

class Similar extends Base {
    pageId = 'similar';

    trackTotal = true;

    menu = {
        header: {
            iconGroupType: null, // 진입 시 icon 영역, 선택 안했을때 나오는 버튼 없음
            iconGroupTypeMulti: 'similarMulti' // 멀티선택 시 icon 영역
        },
        row: {
            iconGroupType: 'similar', // icon 영역
            menuGroupType: 'similar', // 우클릭 영역 및 헤더 더보기 icon 영역
            floatingIconGroupType: null, // 파일명 옆 floating icon 영역
            moreGroupType: 'similar' // 각 행 icon 더보기 버튼 영역
        }
    };

    getDefaultSortColumn() {
        return { sortField: 'objtNm', sortOrder: 'ASC' };
    }

    searchFunction(params) {
        return FoldersRest.getFolders(this.pageId, params);
    }

    showHeaderCheckBox() {
        return true;
    }

    isHideHeaderMenu() {
        return true;
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        // return <SearchRow file={list[index]} index={index} style={style} />;
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    pageBuilder({ state: { firstApproach, viewHeight, total }, props: { history, searchParams = {} } }) {
        const { objtNm } = searchParams;
        if (objtNm) {
            return (
                <section className="drv-file-panel">
                    <DuplicatedTab />
                    <SimilarSearch history={history} />
                    <DuplicatedBody className={`comm-grid-list react-list ${LOW_HEIGHT === viewHeight ? 'list-sm' : ''}`} style={{ display: firstApproach ? '' : 'none' }} isHidden={total === 0} />
                </section>
            );
        }
        return (
            <section className="drv-file-panel">
                <DuplicatedTab />
                <SimilarSearch />
                <div className="box-empty">
                    <div className="message">
                        <span className="ic-empty-dupSearch" />
                        <FormattedHTMLMessage id="drive.text.list.empty.similar.input" tagName="p" />
                    </div>
                </div>
            </section>
        );
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-search" />
                    <FormattedHTMLMessage id="drive.text.list.empty.similar" tagName="p" />
                </div>
            </div>
        );
    }
}

export default new Similar();
