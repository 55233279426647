import CustomModal from 'containers/CustomModal';

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

const TermContent = ({
    isOpen,
    currentSelectedTerm,
    onClickCancelButton,
    onClickConfirmButton,

    intl
}) => {
    console.log('inpop ', currentSelectedTerm);

    return (
        <CustomModal isOpen={isOpen} fade={false} size="lg" backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title"> {currentSelectedTerm.trmsNm}</h5>
                <a className="btn-ic-nor" role="button" title="닫기" data-dismiss="modal" onClick={onClickCancelButton}>
                    <i className="ic-20-close" />
                </a>
            </div>

            <div className="modal-body">
                <div className="form-group" dangerouslySetInnerHTML={{ __html: currentSelectedTerm.trmsCn }} style={{ height: '400px' }}>
                    {/* <iframe title="terms" srcDoc={currentSelectedTerm.trmsCn} /> */}
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClickCancelButton}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                    </a>
                    <a className="btn btn-lg btn-primary" role="button" onClick={() => onClickConfirmButton(true)}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.button.agree' })}</span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

TermContent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    currentSelectedTerm: PropTypes.any.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickConfirmButton: PropTypes.func.isRequired,

    intl: PropTypes.object.isRequired
};

export default injectIntl(TermContent);
