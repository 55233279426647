let state;
let htmlState;

class Clipboard {
    constructor() {
        document.body.addEventListener('copy', e => {
            if (state) {
                e.preventDefault();
                if (e.clipboardData) {
                    e.clipboardData.setData('text/plain', state);
                    if (htmlState) {
                        e.clipboardData.setData('text/html', htmlState);
                    }
                } else if (window.clipboardData) {
                    window.clipboardData.setData('Text', state);
                    if (htmlState) {
                        window.clipboardData.setData('Html', htmlState);
                    }
                }
            }
            state = undefined;
            htmlState = undefined;
        });
    }

    set = (message, htmlMessage) => {
        state = message;
        htmlState = htmlMessage;

        let succeeded = false;
        try {
            if (window.clipboardData) {
                succeeded = window.clipboardData.setData('Text', state);
                if (htmlState) {
                    succeeded = window.clipboardData.setData('Html', htmlState);
                }
            } else {
                succeeded = document.execCommand('copy');
            }
        } catch (err) {
            succeeded = false;
        }

        return succeeded;
    };
}

export default new Clipboard();
