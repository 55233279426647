import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { history } from 'src/redux/store';
import { $http } from 'modules/index';
// import Request from 'constants/Request';
// import RequestPopup from 'components/containers/Popup/RequestPopup';
import SettingsRest from '../../../apis/SettingsRest';
import { auth, globalPopup, toast } from '../../../redux/actions';
import SettingsPresentational from './SettingsPresentational';
import ConfigRest from '../../../apis/ConfigsRest';
import ReqRest from '../../../apis/ReqRest';

const mapConfigTypeServerToClient = {
    FILES_SHARED_TO_ME: 'whenSbShareFOF',
    FILES_UPLOADED_TO_MY_FOLDER: 'whenSbUploadFileToMyFolder',
    MY_FILE_VERSION_UPDATED: 'whenSbVersionUpdateMyFile',
    PARTNER_ACCESS_TIME_WILL_BE_EXPIRED_IN_N: 'whenRemainNDaysMyPartner',
    OWNER_CHANGED_TO_ME: 'whenSbChangeFOFOwnerToMe'
};

const mapConfigTypeClientToServer = {
    whenSbShareFOF: 'FILES_SHARED_TO_ME',
    whenSbUploadFileToMyFolder: 'FILES_UPLOADED_TO_MY_FOLDER',
    whenSbVersionUpdateMyFile: 'MY_FILE_VERSION_UPDATED',
    whenRemainNDaysMyPartner: 'PARTNER_ACCESS_TIME_WILL_BE_EXPIRED_IN_N',
    whenSbChangeFOFOwnerToMe: 'OWNER_CHANGED_TO_ME'
};

const ADDED_TO_SYSTEM_SHARED_FOLDER = 'ADDED_TO_SYSTEM_SHARED_FOLDER';
const FILES_UPLOADED_TO_FAVORITE_FOLDER = 'FILES_UPLOADED_TO_FAVORITE_FOLDER';

class SettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basicData: null,
            settings: {},
            defaultSettings: {},
            languageCode: props.languageCode,
            timeZone: props.timeZone,
            timezoneDiff: props.timezoneDiff,
            needToSave: false,
            loginHistory: [],
            allowNDays: 0,
            emailAlramDays: 0,
            isExtAws: false
        };
    }

    unblock = _.noop;

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.unblock();
    }

    init = () => {
        this.getBasicData();
        this.getNotificationSettings('defaultSettings');
        this.getNotificationSettings('settings');
        this.getLoginHistory();
        this.getConfigData();
    };

    getLoginHistory = async () => {
        const { timezoneDiff } = this.state;

        const loginHistory = await SettingsRest.getLoginHistory(timezoneDiff);
        this.setState({ loginHistory });
    };

    onClickSave = async () => {
        const { languageCode: originLanguageCode, timeZone: originTimeZone, setSettings, showToast, intl, openAlert } = this.props;
        const { languageCode, timeZone, timezoneDiff, settings, basicData } = this.state;
        const { clickExecutionMode, originClickExecutionMode } = basicData;
        if (originLanguageCode !== languageCode) {
            const response = await SettingsRest.putLanguage(languageCode);
            const { resultCode, data, message } = response;
            if (resultCode !== 200 || !data) {
                openAlert(intl.formatMessage({ id: 'com.alert.unexpected-error' }, { code: `${resultCode} / ${message}` }));
                return;
            }
        }
        if (originTimeZone !== timeZone) {
            const response = await SettingsRest.putTimeZone(timeZone);
            const { resultCode, data, message } = response;

            if (resultCode !== 200 || !data) {
                openAlert(intl.formatMessage({ id: 'com.alert.unexpected-error' }, { code: `${resultCode} / ${message}` }));
                return;
            }
        }
        if (clickExecutionMode !== originClickExecutionMode) {
            const response = await SettingsRest.putFilenameClickExecutionMode(clickExecutionMode);
            const { resultCode, data, message } = response;
            if (resultCode !== 200 || !data) {
                openAlert(intl.formatMessage({ id: 'com.alert.unexpected-error' }, { code: `${resultCode} / ${message}` }));
                return;
            }
        }

        let needToSave = false;
        const objectKeys = Object.keys(mapConfigTypeClientToServer);
        for (let i = 0; i < objectKeys.length; i += 1) {
            if (this.originSettings[objectKeys[i]] !== settings[objectKeys[i]]) {
                needToSave = true;
                break;
            }
        }
        if (needToSave) await this.setNotificationSettings(settings);

        setSettings(languageCode, timeZone, timezoneDiff);
        this.setState({ needToSave: false }, () => {
            this.unblock();
            this.init();
        });

        showToast({ id: 'com.alert.save' });
    };

    setIfNeedToSave = () => {
        const { languageCode: originLanguageCode, timeZone: originTimeZone, intl } = this.props;
        const { languageCode, timeZone, settings, basicData } = this.state;
        const { clickExecutionMode, originClickExecutionMode } = basicData;

        console.log(clickExecutionMode);
        console.log(originClickExecutionMode);

        let needToSave = false;
        if (originLanguageCode !== languageCode || originTimeZone !== timeZone || clickExecutionMode !== originClickExecutionMode) needToSave = true;
        const objectKeys = Object.keys(mapConfigTypeClientToServer);
        for (let i = 0; i < objectKeys.length; i += 1) {
            if (this.originSettings[objectKeys[i]] !== settings[objectKeys[i]]) {
                needToSave = true;
                break;
            }
        }

        this.setState({ needToSave }, () => {
            this.unblock();
            const blockMessage = intl.formatMessage({ id: 'user.confirm.setting.leaveWithoutSave' });
            if (needToSave) this.unblock = history.block(blockMessage);
        });
    };

    getConfigData = async () => {
        const response = await ConfigRest.getConfigs();
        const allowNDays = _.find(response, { cfgId: 'EFL_AUT_USER_ALLOW_N_DAY' }, {});
        const emailAlramDays = _.find(response, { cfgId: 'EFL_AUT_NOTI_EMAIL_PARTNER_ACCESS_TIME_WILL_BE_EXPIRED_BASE_DAYS' }, {});

        const res = await $http.get(`/auth/v1/configs/cfg/site-name?tenantId=10000`);
        const { data } = res;

        this.setState({ allowNDays: _.get(allowNDays, 'cfgVal', 0) });
        this.setState({ emailAlramDays: _.get(emailAlramDays, 'cfgVal', 0) });
        this.setState({ isExtAws: _.get(data, 'cfgVal', '') === 'EXT_AWS' });
    };

    getBasicData = async () => {
        const { user } = this.props;
        const { timezoneDiff } = this.state;

        const basicData = await SettingsRest.getSettingsBasicData(user.id, timezoneDiff);
        this.setState({ basicData });
    };

    onChangeLanguage = async languageCode => {
        this.setState({ languageCode }, () => {
            this.setIfNeedToSave();
        });
    };

    onChangeTimezone = async timeZone => {
        const { basicData } = this.state;
        const { timeZoneOptions } = basicData;
        const selectedTimeZone = _.find(timeZoneOptions, { value: timeZone });
        const timezoneDiff = selectedTimeZone.data;
        this.setState({ timeZone, timezoneDiff }, () => {
            this.setIfNeedToSave();
        });
    };

    onClickProfileEdit = () => {
        const { openProfileEdit } = this.props;
        openProfileEdit();
    };

    closeProfileEditPopup = () => {
        const { closeProfileEdit } = this.props;
        closeProfileEdit();
    };

    onClickPasswordChange = () => {
        const { openChangePassword, showToast, intl } = this.props;
        openChangePassword(result => {
            if (result) {
                showToast(intl.formatMessage({ id: 'user.toast.popup.changePW.successfullyChanged' }));
                this.init();
            }
        });
    };

    onClickOwnerChange = () => {
        const { openChangeOwner } = this.props;
        openChangeOwner();
    };

    onChangeFilenameClickMode = () => {
        const { basicData } = this.state;
        const { clickExecutionMode } = basicData;

        this.setState(
            state =>
                produce(state, draft => {
                    if (clickExecutionMode === 'WEB') {
                        draft.basicData.clickExecutionMode = 'PC';
                    } else {
                        draft.basicData.clickExecutionMode = 'WEB';
                    }
                }),
            () => {
                this.setIfNeedToSave();
            }
        );
    };

    onClickSecureModeRequest = async () => {
        console.log('secure mode request');
        const isCheck = await ReqRest.getReqCheck();

        if (isCheck.resultCode === 200) {
            const { openSecureModeRequestPopup } = this.props;
            openSecureModeRequestPopup();
        } else {
            const { openAlert, intl } = this.props;
            openAlert(intl.formatMessage({ id: `${isCheck.data}`, values: { code: `${isCheck.resultCode} / ${isCheck.data}` } }));
        }
    };

    getNotificationSettings = async type => {
        const data = await SettingsRest.getNotificationSettings(type);

        const settings = {};
        data.forEach(item => {
            if (mapConfigTypeServerToClient[item.configType]) {
                settings[mapConfigTypeServerToClient[item.configType]] = item.configYn === 'Y';
            }
        });

        this.setState(state =>
            produce(state, draft => {
                draft[type] = settings;
            })
        );

        if (type === 'settings') this.originSettings = settings; // compare when save
    };

    setNotificationSettings = async settings => {
        const data = [];
        const objectKeys = Object.keys(mapConfigTypeClientToServer);
        objectKeys.forEach(objectKey => {
            data.push({
                configTarget: 'EMAIL',
                configType: 'EMAIL_'.concat(mapConfigTypeClientToServer[objectKey]),
                configYn: settings[objectKey] ? 'Y' : 'N'
            });
            if (objectKey === 'whenSbShareFOF') {
                data.push({
                    configTarget: 'EMAIL',
                    configType: 'EMAIL_'.concat(ADDED_TO_SYSTEM_SHARED_FOLDER),
                    configYn: settings[objectKey] ? 'Y' : 'N'
                });
            }
            if (objectKey === 'whenSbUploadFileToMyFolder') {
                data.push({
                    configTarget: 'EMAIL',
                    configType: 'EMAIL_'.concat(FILES_UPLOADED_TO_FAVORITE_FOLDER),
                    configYn: settings[objectKey] ? 'Y' : 'N'
                });
            }
        });

        await SettingsRest.setNotificationSettings(data);
        this.getNotificationSettings('settings');
    };

    selectAllSettings = isAllAlarmChecked => {
        const nextSetValue = !isAllAlarmChecked;
        this.setState(
            state => {
                const nextState = produce(state, draft => {
                    draft.settings.whenSbShareFOF = nextSetValue;
                    draft.settings.whenSbUploadFileToMyFolder = nextSetValue;
                    draft.settings.whenSbVersionUpdateMyFile = nextSetValue;
                    draft.settings.whenRemainNDaysMyPartner = nextSetValue;
                    draft.settings.whenSbChangeFOFOwnerToMe = nextSetValue;
                });
                return nextState;
            },
            () => {
                this.setIfNeedToSave();
            }
        );
    };

    resetSettings = () => {
        const { defaultSettings } = this.state;
        this.setState(
            state => {
                const nextState = produce(state, draft => {
                    draft.settings = defaultSettings;
                });
                return nextState;
            },
            () => {
                this.setIfNeedToSave();
            }
        );
    };

    onChangeItem = event => {
        const key = event.target.name;
        const value = event.target.checked;
        this.setState(
            state => {
                const nextState = produce(state, draft => {
                    draft.settings[key] = value;
                });
                return nextState;
            },
            () => {
                this.setIfNeedToSave();
            }
        );
    };

    render() {
        const { basicData, settings, needToSave, languageCode, timeZone, loginHistory, allowNDays, emailAlramDays, isExtAws } = this.state;
        const { user } = this.props;
        return (
            <div className="page-set">
                <div className="comm-func-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            <div className="func-bar-title">
                                <h3>
                                    <FormattedMessage id="user.setting.setting" />
                                </h3>
                            </div>
                            <div className="func-bar-filter">
                                <a className={needToSave ? 'btn btn-md btn-primary' : 'btn btn-md btn-primary disabled'} role="button" onClick={this.onClickSave}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.save" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {basicData && (
                    <SettingsPresentational
                        user={user}
                        basicData={basicData}
                        languageCode={languageCode}
                        timeZone={timeZone}
                        onChangeLanguage={this.onChangeLanguage}
                        onChangeTimezone={this.onChangeTimezone}
                        onChangeFilenameClickMode={this.onChangeFilenameClickMode}
                        onClickProfileEdit={this.onClickProfileEdit}
                        onClickPasswordChange={this.onClickPasswordChange}
                        onClickOwnerChange={this.onClickOwnerChange}
                        onClickSecureModeRequest={this.onClickSecureModeRequest}
                        settings={settings}
                        selectAllSettings={this.selectAllSettings}
                        resetSettings={this.resetSettings}
                        onChangeItem={this.onChangeItem}
                        loginHistory={loginHistory}
                        allowNDays={_.toNumber(allowNDays)}
                        emailAlramDays={_.toNumber(emailAlramDays)}
                        isExtAws={isExtAws}
                    />
                )}
                <span className="shadow" />
            </div>
        );
    }
}

SettingsContainer.propTypes = {
    user: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    timezoneDiff: PropTypes.number.isRequired,
    openChangePassword: PropTypes.func.isRequired,
    openChangeOwner: PropTypes.func.isRequired,
    openSecureModeRequestPopup: PropTypes.func.isRequired,
    setSettings: PropTypes.func.isRequired,
    openProfileEdit: PropTypes.func.isRequired,
    closeProfileEdit: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.auth.user,
    languageCode: state.auth.user.locale.languageCode,
    timeZone: state.auth.user.locale.timeZone,
    timezoneDiff: state.auth.user.locale.timezoneDiff
});

const mapDispatchToProps = {
    openChangePassword: globalPopup.openChangePassword,
    openChangeOwner: globalPopup.openChangeOwner,
    setSettings: auth.setSettings,
    openProfileEdit: globalPopup.openProfileEdit,
    openSecureModeRequestPopup: globalPopup.openSecureModeRequestPopup,
    closeProfileEdit: globalPopup.closeProfileEdit,
    showToast: toast.showToast,
    openAlert: globalPopup.openAlert
};

export const ConnectedSettingsContainer = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SettingsContainer)
);

export default injectIntl(SettingsContainer);
