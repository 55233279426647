import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import { FormattedMessage } from 'react-intl';

const SelectedDeptRow = props => {
    const { dept, style, index, checkBox, selectUser, checkSelectedUser, delBox, onDeleteBoxClick } = props;

    const checkSelectedRow = event => {
        const { checked } = event.target;
        checkSelectedUser(index, checked, true);
    };

    const delBoxOnClickEvent = () => {
        console.log('deptId', dept);
        onDeleteBoxClick(dept.id);
    };

    return (
        <div key={dept.id}>
            <If condition={!_.isNil(dept)}>
                <div className={_.get(dept, 'isSelected') ? 'grid-row is-checked' : 'grid-row'} role="button" style={style} onClick={selectUser}>
                    {checkBox ? (
                        <div className="grid-cell check-cell">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" onClick={event => checkSelectedRow(event)} checked={dept.checked} />
                                    <span className="label-text" />
                                </label>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <div className="grid-cell selected-cell">
                        <div className="item-user">
                            <span className="user-thumbnail-ss org">
                                <FormattedMessage id="조직도 부서" />
                            </span>
                            <div className="user-profile">
                                <div title={dept.title}>
                                    <span className="name">
                                        {dept.title}{' '}
                                        {dept.subYn && (
                                            <em className="inclusion">
                                                {' '}
                                                <FormattedMessage id="user.text.popup.selectPeople.organize.includeSubDept" />
                                            </em>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {delBox ? (
                        <FormattedMessage id="com.delete">
                            {title => (
                                <div className="grid-cell del-cell">
                                    <a className="btn-ic-nor" role="button" title={title} onClick={() => delBoxOnClickEvent()}>
                                        <i className="ic-20-close-sm" />
                                    </a>
                                </div>
                            )}
                        </FormattedMessage>
                    ) : (
                        <div />
                    )}
                </div>
            </If>
        </div>
    );
};

export default SelectedDeptRow;

SelectedDeptRow.propTypes = {
    selectUser: PropTypes.func,
    checkSelectedUser: PropTypes.func,
    style: PropTypes.object,
    checkBox: PropTypes.bool,
    index: PropTypes.number,
    onDeleteBoxClick: PropTypes.func,
    delBox: PropTypes.bool,
    dept: PropTypes.object
};

SelectedDeptRow.defaultProps = {
    selectUser: _.noop,
    checkSelectedUser: _.noop,
    style: {},
    checkBox: false,
    index: 0,
    onDeleteBoxClick: _.noop,
    delBox: true,
    dept: {}
};
