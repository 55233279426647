import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class FolderFileNameInputPopup extends Component {
    constructor(props) {
        super(props);
        const { preVal } = props;
        this.state = {
            text: preVal.length > 100 ? preVal.substring(0, 100) : preVal,
            isInvalid: false,
            isChanged: false
        };
        this.maxLength = 100;
        this.loading = false;
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => this.inputRef.current.focus());
    }

    handleOnChange = ({ target }) => {
        const { changedData } = this.props;
        const { value } = target;
        if (value.length <= this.maxLength) {
            this.setState({ text: target.value, isChanged: true }, changedData);
        }
    };

    handleOnKeyDown = ({ key }) => {
        const { close } = this.props;
        const { text, isInvalid } = this.state;
        if (key === 'Escape') {
            this.inputRef.current.blur();
            close();
        }
        if (key === 'Enter' && text.length > 0 && !isInvalid) this.save();
    };

    clear = () => {
        this.setState({ text: '' });
    };

    save = async () => {
        if (this.loading) return;
        const { close, saveApi } = this.props;
        const { text } = this.state;
        this.loading = true;
        const pass = await saveApi(text);
        this.loading = false;
        if (pass) close(true);
    };

    render() {
        const { headerName, close, intl } = this.props;
        const { text, isInvalid, isChanged } = this.state;
        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: headerName })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => close()}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className="input-group-remark">
                        <div className={`box-input-group ${isInvalid ? 'is-invalid' : ''}`}>
                            <input
                                ref={this.inputRef}
                                className="form-control"
                                type="text"
                                placeholder={intl.formatMessage({ id: 'user.placeHolder.writeName' })}
                                value={text}
                                onChange={this.handleOnChange}
                                onKeyDown={this.handleOnKeyDown}
                            />
                            <a
                                className="btn-ic-nor btn-func-clear"
                                role="button"
                                title={intl.formatMessage({ id: 'com.text.clear' })}
                                onClick={this.clear}
                                style={{ display: text.length > 0 ? 'inline-flex' : 'none' }}>
                                <i className="ic-16-clear" />
                            </a>
                        </div>
                        <div className="input-remark">
                            {/* isInvalid && <div className="err-msg">\, /, :, *, ?, &lt;, &gt;, | 를 입력할 수 없습니다.</div> */}
                            <div className="write-count">
                                <strong className="count-num">{text.length}</strong> /<span className="write-total">{this.maxLength}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => close()}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${!isChanged || isInvalid || text.length === 0 ? 'disabled' : ''}`} role="button" onClick={this.save}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.check' })}</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

FolderFileNameInputPopup.propTypes = {
    close: PropTypes.func,
    changedData: PropTypes.func,
    headerName: PropTypes.string,
    saveApi: PropTypes.func,
    preVal: PropTypes.string,
    intl: PropTypes.object
};

FolderFileNameInputPopup.defaultProps = {
    close: () => {},
    changedData: () => {},
    headerName: '',
    saveApi: () => false,
    preVal: '',
    intl: {}
};

export default injectIntl(FolderFileNameInputPopup);
