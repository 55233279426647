import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import connect from 'react-redux/es/connect/connect';
import ClickOutside from './ClickOutside';
import ModalService from '../../utils/ModalService';
import FolderInfoPopup from './FolderInfoPopup';
import ExpirationSearch from '../containers/ExpirationSearch';
import ComplianceSearch from '../containers/ComplianceSearch';
import DeletedSearch from '../containers/DeletedSearch';
import DuplicatedTotal from './Duplicated/DuplicatedTotal';

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.isFilterInnerPopupOpen = false;
        this.state = {
            isMiddleListOpen: false
        };
        this.clickOutSideRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isMiddleListOpen } = this.state;
        if (nextProps !== this.props || nextState.isMiddleListOpen !== isMiddleListOpen) return true;
        return false;
    }

    openMiddleList = () => {
        this.setState({ isMiddleListOpen: true });
    };

    toggleMiddleList = () => {
        const { isMiddleListOpen } = this.state;
        this.setState({ isMiddleListOpen: !isMiddleListOpen });
    };

    closeMiddleList = () => {
        this.setState({ isMiddleListOpen: false });
    };

    move = (folderId, folder) => {
        const { move } = this.props;
        move(folderId, folder);
    };

    refresh = (folderId, folder) => {
        const { refresh } = this.props;
        refresh(folderId, folder);
    };

    onSearch = params => {
        const { onSearch } = this.props;
        onSearch(params);
    };

    makeBreadcrumb(data) {
        const { firstRange, lastRange } = this.props;
        const maxRange = firstRange + lastRange;
        const lastIdx = data.length - 1;

        if (data.length <= maxRange) return data.map((bread, idx) => this.makeBreadItem(bread, idx, lastIdx));

        const first = data.slice(0, firstRange).map((bread, idx) => this.makeBreadItem(bread, idx, lastIdx));
        const middle = this.makeMiddleBreadList(data.slice(firstRange, data.length - lastRange));
        const lastStartIdx = data.length - lastRange;
        const last = data.slice(lastStartIdx).map((bread, idx) => this.makeBreadItem(bread, lastStartIdx + idx, lastIdx));
        return [...first, middle, ...last];
    }

    openInnerPopup = isOpen => {
        this.isFilterInnerPopupOpen = isOpen;
    };

    makeBreadItem(bread, idx, lastIdx) {
        const isShowHelpBtn = lastIdx === 0;

        const { type } = this.props;
        return (
            <li key={`bread_${bread.objtId}`} className={`breadcrumb-item ${idx === lastIdx ? 'active' : ''}`}>
                <a
                    role="button"
                    title={bread.objtNm}
                    onClick={() => {
                        if (idx === lastIdx) {
                            this.refresh(bread.objtId, bread);
                        } else {
                            this.move(bread.objtId, bread);
                        }
                    }}>
                    {bread.objtNm}
                </a>
                {isShowHelpBtn && this.makeHelpBtn()}
                {idx === lastIdx && this.makeRefreshBtn(bread.objtId, bread)}
                {idx > 0 && idx === lastIdx && type === 'deleted' && <DeletedSearch objtId={bread.objtId} onSearch={this.onSearch} />}
                {type === 'compliance' && <ComplianceSearch onSearch={this.onSearch} />}
                {type === 'expiration' && <ExpirationSearch onSearch={this.onSearch} />}
                {type === 'duplicated' && <DuplicatedTotal />}
            </li>
        );
    }

    makeHelpBtn() {
        const { type } = this.props;
        if (type === 'shared' || type === 'workgroup') {
            return (
                <a
                    className="btn-ic-nor btn-sm"
                    role="button"
                    onClick={() => {
                        this.openFolderInfoPopup(type);
                    }}>
                    <i className="ic-16-help" />
                </a>
            );
        }
        return null;
    }

    makeRefreshBtn(objtId, bread) {
        const { intl } = this.props;
        return (
            <>
                <div className="divider" />
                <a className="btn-ic-nor btn-sm btn-func-refresh" role="button" title={intl.formatMessage({ id: 'com.button.refresh' })} onClick={() => this.refresh(objtId, bread)}>
                    <i className="ic-20-refresh"> </i>
                </a>
            </>
        );
    }

    openFolderInfoPopup(type) {
        ModalService()
            .largePopup(<FolderInfoPopup type={type} />)
            .then(() => true, () => false);
    }

    makeMiddleBreadList(data) {
        const { isMiddleListOpen } = this.state;
        return (
            <li key="middleList" className="breadcrumb-item dropdown">
                <a className="btn-ic-nor dropdown-toggle" role="button" onClick={this.toggleMiddleList} onMouseEnter={this.openMiddleList}>
                    <i className="ic-20-depth" />
                </a>
                {isMiddleListOpen && (
                    <div className="dropdown-menu" style={{ display: 'block' }}>
                        {data.map(bread => (
                            <a
                                role="button"
                                key={bread.objtId}
                                className="dropdown-item"
                                title={bread.objtNm}
                                onClick={() => {
                                    this.move(bread.objtId, bread);
                                }}>
                                {bread.objtNm}
                            </a>
                        ))}
                    </div>
                )}
            </li>
        );
    }

    render() {
        const { data } = this.props;
        const breadcrumb = this.makeBreadcrumb(data);
        return (
            <div className="func-bar-title">
                <nav aria-label="breadcrumb">
                    <ClickOutside onClickOutside={this.closeMiddleList} childrenRefs={[this.clickOutSideRef]}>
                        <ol ref={this.clickOutSideRef} className="breadcrumb">
                            {breadcrumb}
                        </ol>
                    </ClickOutside>
                </nav>
            </div>
        );
    }
}

Breadcrumb.propTypes = {
    data: PropTypes.array,
    move: PropTypes.func,
    refresh: PropTypes.func,
    onSearch: PropTypes.func,
    firstRange: PropTypes.number,
    lastRange: PropTypes.number,
    type: PropTypes.string
};

Breadcrumb.defaultProps = {
    data: [],
    move: () => {},
    refresh: () => {},
    onSearch: () => {},
    firstRange: 1,
    lastRange: 2,
    type: 'default'
};

function convertFolderToBreadcrumb(folder) {
    if (!folder.browserPathId || !folder.explorerPathNm) return [];
    const pathValList = folder.browserPathId.split('/');
    const pathValNmList = folder.explorerPathNm.split('\\');
    const breadcrumbData = [];
    for (let i = 1; pathValList.length > i; i += 1) {
        breadcrumbData.push({
            objtId: pathValList[i],
            objtNm: pathValNmList[i],
            accessible: true
        });
    }
    return breadcrumbData;
}

const mapStateToProps = (state, curProps) => {
    const { data = {} } = state.fileReload;
    const { reloadType, changedData } = data;
    const curData = curProps.data;
    if (reloadType === 'cached' && changedData) {
        const newData = curData.map(item => (item.objtId === changedData.objtId ? { ...item, objtNm: changedData.objtNm } : item));
        return { data: newData };
    }
    return {};
};

const connected = connect(
    mapStateToProps,
    null
)(Breadcrumb);

export default injectIntl(connected);
export { convertFolderToBreadcrumb };
