import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import { confirm, alert } from '../../../../utils/ModalService';
import ReqRest from '../../../../apis/ReqRest';
import FileReload from '../../../../utils/FileReload';

// 요청 중 인 건만 취소 가능.
const STATE_CODES = ['PROCESSING'];

const executor = async (props, files) => {
    const reqIds = _.chain(files)
        .map(file => {
            const { extra: { req: { reqId = '' } = {} } = {} } = file;
            return _.trim(reqId);
        })
        .filter(reqId => reqId !== '')
        .value();

    if (reqIds.length < 1) return;

    const doCancel = await confirm({ id: 'com.confirm.ReqMngBodyReqStat.cancel' });
    if (!doCancel) {
        return;
    }

    const res = await ReqRest.updateReqProcess({ reqPrcsType: 'CANCEL', reqPrcsList: reqIds });
    if (res.resultCode === 500) {
        alert('com.alert.internalServerError');
    } else if (res.resultCode === 400) {
        alert('com.alert.reqInvalidParam');
    } else if (res.resultCode === 200) {
        if (reqIds.length > 1) {
            alert({ id: res.message, values: { N: reqIds.length } });
        } else {
            alert(res.message);
        }
    } else if (res.resultCode === 403 && !!res.data) {
        alert(res.data);
    } else {
        alert(res.message);
    }

    FileReload.call(null, files, { reloadType: 'cached', reloadReq: true });
};

const getReqState = file => {
    if (_.isEmpty(file) || _.isEmpty(file.config) || _.isEmpty(file.config.drive)) return false;
    const { expirationRequestStateCode = '', complianceRequestStateCode = '' } = file.config.drive;
    const { extra: { req: { reqStatCd = '', isByMe = false } = {} } = {} } = file;
    const configStateCode = expirationRequestStateCode !== '' ? expirationRequestStateCode : complianceRequestStateCode;
    const requestStateCode = reqStatCd !== '' ? reqStatCd : configStateCode;
    return STATE_CODES.indexOf(requestStateCode) !== -1 && isByMe;
};

class CancelRequest extends BaseMenu {
    getName() {
        return 'cancelRequest';
    }

    getLabel() {
        return 'drive.button.list.menu.cancelRequest';
    }

    isVisible() {
        return super.isVisible(true, null, 'FILE') && this.files.every(getReqState);
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-20-retraction', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-retraction', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default CancelRequest;
