import { $http } from 'modules/index';

class InShareRest {
    static getShareTargets(objtId) {
        return $http.get(`/drive/v1/shares/${objtId}`).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static saveShareTargets(objtIds, shareTargets, isExplorer) {
        const config = { objtIds, shareTargets };
        const url = isExplorer ? `/drive/v1/shares/explorer` : `/drive/v1/shares`;

        return $http.post(url, config).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static removeOwnAuth(objtIds, shareTargets, isExplorer) {
        const config = { objtIds, shareTargets };
        const url = isExplorer ? '/drive/v1/shares/remove/explorer' : '/drive/v1/shares/remove';

        return $http.post(url, config).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static selectConfig() {
        return $http.get(`/drive/v1/shares/config`).then(
            response => {
                const { data } = response;
                return data;
            },
            error => {
                return error;
            }
        );
    }
}
export default InShareRest;
