import React, { useState } from 'react';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InLink from './InLink';
import InShareList from '../../../../presentationals/Share/InShare/InShareList';
import ShareObjectViewPopup from '../../../../presentationals/Share/InShare/ShareObjectViewPopup';
import InShareCount from '../../../../presentationals/Share/InShare/InShareCount';
import InShareInsert from './InShareInsert';
import { popup } from '../../../../../utils/ModalService';

const InShareChange = props => {
    const {
        files,
        isMulti,
        shareTargets,
        addShareTargetFn,
        changeShareTargetFn,
        deleteShareTargetFn,
        closePopup,
        intl,
        isLoading,
        removeOwnAuthFn,
        canRemove,
        getFileName,
        getExtension,
        webViewerYn,
        config,
        cancelToken
    } = props;

    const [isOpenInstantSearch, setIsOpenInstantSearch] = useState(false);
    const [inLinkCoachMark, setInLinkCoachMark] = useState('N');
    const [shareInsertCoachMark, setShareInsertCoachMark] = useState('N');

    const openShareObjectViewPopup = () => {
        popup(<ShareObjectViewPopup files={files} />).then(() => true, () => false);
        setIsOpenInstantSearch(false);
    };

    const showInLinkCoachMark = () => {
        // console.log('showInLinkCoachMark ');
        setInLinkCoachMark('Y');
    };

    const hideInLinkCoachMark = () => {
        // console.log('hideInLinkCoachMark ');
        setInLinkCoachMark('N');
    };

    const showShareInsertCoachMark = () => {
        // console.log('showShareInsertCoachMark ');
        setShareInsertCoachMark('Y');
    };

    const hideShareInsertCoachMark = () => {
        // console.log('hideShareInsertCoachMark ');
        setShareInsertCoachMark('N');
    };

    const closeUpperPopup = () => {
        closePopup();
    };

    return (
        <div className="modal-body">
            <dl className="dt-divider">
                <dt>{intl.formatMessage({ id: 'drive.text.share.selectedItems' })}</dt>
                <dd className="none-divider">
                    <div className="g-inline-flex">
                        <div className="previous">
                            <div className="item-file">
                                <div className="file" title={files[0].drive.objtNm}>
                                    <span className="file-name">{files[0].drive.objtSectCd === 'FILE' ? getFileName(files[0].drive.objtNm) : files[0].drive.objtNm}</span>
                                    <If condition={files[0].drive.objtSectCd === 'FILE'}>
                                        <span className="file-extension">{getExtension(files[0].drive.objtNm)}</span>
                                    </If>
                                </div>
                            </div>
                        </div>
                        <If condition={isMulti}>
                            <div className="next">
                                <FormattedMessage id="drive.text.share.seletedObjectCount" tagName="div" values={{ n: <strong className="num">{files.length - 1}</strong> }} />
                            </div>
                        </If>
                    </div>
                    <If condition={isMulti}>
                        <a className="btn-link" role="button" onClick={openShareObjectViewPopup}>
                            {intl.formatMessage({ id: 'drive.button.share.viewAllItems' })}
                        </a>
                    </If>
                </dd>
            </dl>
            <If condition={!isMulti && files[0].actionPolicy.createInLinkUrl}>
                <InLink
                    files={files}
                    isView={false}
                    showShareInsertCoachMark={showShareInsertCoachMark}
                    showInLinkCoachMark={showInLinkCoachMark}
                    hideInLinkCoachMark={hideInLinkCoachMark}
                    inLinkCoachMark={inLinkCoachMark}
                    hideShareInsertCoachMark={hideShareInsertCoachMark}
                    closeUpperPopup={closeUpperPopup}
                    webViewerYn={webViewerYn}
                    config={config}
                />
            </If>
            <InShareCount isMulti={isMulti} shareTargets={shareTargets} canRemove={canRemove} removeOwnAuthFn={removeOwnAuthFn} />
            <InShareInsert
                addShareTargetFn={addShareTargetFn}
                shareTargets={shareTargets}
                isOpenInstantSearch={isOpenInstantSearch}
                shareInsertCoachMark={shareInsertCoachMark}
                hideShareInsertCoachMark={hideShareInsertCoachMark}
                config={config}
            />
            <InShareList
                shareTargets={shareTargets}
                isMulti={isMulti}
                isView={false}
                rowCount={5}
                changeShareTargetFn={changeShareTargetFn}
                deleteShareTargetFn={deleteShareTargetFn}
                isLoading={isLoading}
                config={config}
                cancelToken={cancelToken}
            />
        </div>
    );
};

InShareChange.propTypes = {
    files: PropTypes.array,
    addShareTargetFn: PropTypes.func,
    deleteShareTargetFn: PropTypes.func,
    changeShareTargetFn: PropTypes.func,
    closePopup: PropTypes.func,
    isMulti: PropTypes.bool,
    shareTargets: PropTypes.array,
    intl: PropTypes.object,
    isLoading: PropTypes.bool,
    removeOwnAuthFn: PropTypes.func,
    canRemove: PropTypes.bool,
    getFileName: PropTypes.func,
    getExtension: PropTypes.func,
    webViewerYn: PropTypes.string,
    config: PropTypes.object,
    cancelToken: PropTypes.object
};

InShareChange.defaultProps = {
    files: [],
    addShareTargetFn: _.noop,
    deleteShareTargetFn: _.noop,
    changeShareTargetFn: _.noop,
    closePopup: _.noop,
    isMulti: false,
    shareTargets: [],
    intl: {},
    isLoading: true,
    removeOwnAuthFn: _.noop,
    canRemove: false,
    getFileName: _.noop,
    getExtension: _.noop,
    webViewerYn: 'N',
    config: {},
    cancelToken: {}
};

export default injectIntl(InShareChange);
