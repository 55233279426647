import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberUtil from '../../../utils/NumberUtil';
import FilesCountRest from '../../../apis/FilesCountRest';

const DuplicatedTotal = ({ totalCount, fileListType }) => {
    const [ownFilesCount, setOwnFilesCount] = useState('');
    const [duplicatedFilesCount, setDuplicatedFilesCount] = useState('');

    useEffect(() => {
        if (fileListType !== 'duplicated') return;
        FilesCountRest.getOwnFilesCount().then(
            ({ resultCode, data: { count } }) => {
                setOwnFilesCount(resultCode === 200 ? count : '');
                setDuplicatedFilesCount(totalCount);
            },
            error => {
                console.log(error);
                setOwnFilesCount('');
                setDuplicatedFilesCount('');
            }
        );
    }, [totalCount, fileListType]);

    return (
        <>
            <div className="divider" />
            <div className="total-count">
                <span className="count">
                    <FormattedMessage id="drive.text.own.count" values={{ n: <i className="num">{NumberUtil.getNumberWithCommas(ownFilesCount)}</i> }} />
                </span>
                <span className="count">
                    <FormattedMessage id="drive.text.duplicated.count" values={{ n: <i className="num">{NumberUtil.getNumberWithCommas(duplicatedFilesCount)}</i> }} />
                </span>
            </div>
        </>
    );
};

DuplicatedTotal.propTypes = {
    totalCount: PropTypes.number,
    fileListType: PropTypes.string.isRequired
};

DuplicatedTotal.defaultProps = {
    totalCount: 0
};

const mapStateToProps = state => ({
    totalCount: state.fileList.totalCount,
    fileListType: state.fileList.fileListType
});

export default connect(mapStateToProps)(DuplicatedTotal);
