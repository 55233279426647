import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import UploadItem from './UploadItem';

const SCROLL_MAX_HEIGHT = 230;
const GRID_ROW_HEIGHT = 46;

class UploadList extends Component {
    constructor(props) {
        super(props);
        this.scrollbars = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { statusCount, event } = this.props;

        if ((prevProps.statusCount.totalCount !== statusCount.totalCount || prevProps.statusCount.queuedCount !== statusCount.queuedCount) && event !== 'remove') {
            this.scrollbars.current.scrollTop(GRID_ROW_HEIGHT * (statusCount.totalCount - statusCount.queuedCount - 2));
        }
    }

    render() {
        const { files, statusCount, onRemove, onCheck, intl, completedClose } = this.props;
        const total = intl.formatMessage({ id: 'drive.text.upload.popup.total' });
        const queue = intl.formatMessage({ id: 'drive.text.upload.popup.status.queue' });
        const complete = intl.formatMessage({ id: 'drive.text.upload.popup.status.complete' });
        const fail = intl.formatMessage({ id: 'drive.text.upload.popup.status.fail' });

        const uploadList = files.map(file => <UploadItem key={file.uid} fileItem={file} onRemove={() => onRemove(file.uid)} />);

        return (
            <div className="uploads-item">
                <div className="item-info">
                    <div className="total-count">
                        <span>{total}</span>
                        <span className="count">
                            <i className="num">{files.length}</i>
                        </span>
                    </div>

                    <ul className="count-detail">
                        <li>
                            {queue} <i className="num">{statusCount.queuedCount}</i>
                        </li>
                        <li>
                            {complete} <i className="num">{statusCount.completedCount}</i>
                        </li>
                        <li>
                            {fail} <i className="num">{statusCount.failedCount}</i>
                        </li>
                    </ul>
                </div>

                <div className="pop-grid-list">
                    <div className="comm-grid-list">
                        <Scrollbars ref={this.scrollbars} className="scroll" autoHeight={true} autoHeightMax={SCROLL_MAX_HEIGHT}>
                            <div className="grid-row-group row-5">
                                <div>{uploadList}</div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>

                <div className="complete-checked">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={e => {
                                    onCheck();
                                    e.stopPropagation();
                                }}
                                checked={completedClose}
                            />
                            <span className="label-text">
                                <FormattedMessage id="drive.text.upload.popup.completedClose" />
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

UploadList.propTypes = {
    files: PropTypes.array,
    statusCount: PropTypes.object,
    onRemove: PropTypes.func,
    onCheck: PropTypes.func,
    intl: PropTypes.object,
    event: PropTypes.string,
    completedClose: PropTypes.bool
};

UploadList.defaultProps = {
    files: [],
    statusCount: {},
    onRemove: () => {},
    onCheck: () => {},
    intl: {},
    event: '',
    completedClose: true
};
export default injectIntl(UploadList);
