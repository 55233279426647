import React, { memo } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import FileInput from './Item/FileInput';

const SingleFilePromptTemplate = memo(() => {
    return (
        <div className="copilot-prompt-input">
            <div className="prompt-command">
                <FileInput isFocus={false} /> <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.ptcont.single" />
            </div>
        </div>
    );
});

export default SingleFilePromptTemplate;
