import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseMenu from '../BaseMenu';

class NoMenu extends BaseMenu {
    getName() {
        return 'noMenu';
    }

    isVisible() {
        return (
            this.files.length > 1 &&
            this.files.every(file => {
                return !(file.drive.objtSectCd === 'FOLDER' && file.drive.onpstSectCd === 'SYSTFOLDER' && file.maxAuthCd === 'NONE');
            })
        );
    }

    isEnable() {
        return true;
    }

    getIconDom() {
        return (
            <div className="info-msg" key={this.getName()}>
                <i className="ic-16-info" />
                <FormattedMessage id="drive.button.list.menu.noMenu" />
            </div>
        );
    }

    execute = () => {};
}

export default NoMenu;
