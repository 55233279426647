import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DownloadRest from 'apis/DownloadRest';
import CharacterImage from './CharacterImage';
import { IMAGE_STATUS } from './CharacterImageConstants';

class CharacterImageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            status: IMAGE_STATUS.INIT
        };
    }

    getProfileImage = async (objtId, userId, cancelToken) => {
        if (_.isEmpty(objtId)) {
            this.setState({ src: '', status: IMAGE_STATUS.NO_IMAGE });
            return;
        }

        // 캐시 처리
        const { cache } = this.props;
        const cacheKey = `${userId}_${objtId}`;
        if (!_.isNil(cache) && !_.isNil(cache[cacheKey])) {
            this.setState({ src: cache[cacheKey], status: IMAGE_STATUS.COMPLETE });
            return;
        }

        try {
            const response = await DownloadRest.downloadProfileImage({ objtId, onpstId: userId }, cancelToken);
            const image = btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            if (image === '') {
                this.setState({ src: '', status: IMAGE_STATUS.NO_IMAGE });
                if (!_.isNil(cache)) cache[cacheKey] = '';
                return;
            }
            const src = `data:image/png;base64,${image}`;

            const temp = new Image();
            temp.onerror = () => {
                if (this.isComponentMounted) {
                    this.setState({ src: '', status: IMAGE_STATUS.ERROR });
                    if (!_.isNil(cache)) cache[cacheKey] = '';
                }
            };
            temp.onload = () => {
                if (this.isComponentMounted) {
                    this.setState({ src, status: IMAGE_STATUS.COMPLETE });
                    if (!_.isNil(cache)) cache[cacheKey] = src;
                }
            };
            temp.src = src;
        } catch (error) {
            if (this.isComponentMounted) {
                this.setState({ src: '', status: IMAGE_STATUS.ERROR });
                if (!_.isNil(cache)) cache[cacheKey] = '';
            }
        }
    };

    componentDidUpdate(prevProps) {
        const { objtId, userId, updateTime, cancelToken } = this.props;
        if (updateTime !== prevProps.updateTime) {
            this.getProfileImage(objtId, userId, cancelToken);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        const { objtId, userId, source, cancelToken } = this.props;
        if (!_.isEmpty(source)) {
            this.setState({ src: source });
            return;
        }

        this.getProfileImage(objtId, userId, cancelToken);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        const { src, status } = this.state;
        const { text, size, userType, userId, characterType, onClick } = this.props;

        const lastNumberOfUserId = userId.substring(userId.length - 1, userId.length);

        const backgroundColorClass = 'p-type0'.concat((lastNumberOfUserId % 6) + 1);

        let imageClassName = `user-thumbnail-${size} ${backgroundColorClass}`;
        if (characterType === 'team') {
            imageClassName = `user-thumbnail-${size} org`;
        }
        if (status === IMAGE_STATUS.ERROR) {
            imageClassName = `user-thumbnail-${size} etc`;
        }
        if (userType === 'P') {
            imageClassName += ' collabo';
        }

        return (
            <CharacterImage
                src={src}
                status={status}
                imageClassName={imageClassName}
                text={text}
                size={size}
                userType={userType}
                backgroundColorClass={backgroundColorClass}
                characterType={characterType}
                onClick={onClick}
            />
        );
    }
}

CharacterImageContainer.propTypes = {
    source: PropTypes.string,
    updateTime: PropTypes.number,
    objtId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    text: PropTypes.string,
    size: PropTypes.string,
    userType: PropTypes.string,
    // backgroundColorClass: PropTypes.string,
    characterType: PropTypes.string,
    onClick: PropTypes.func,
    cache: PropTypes.object,
    cancelToken: PropTypes.object
};

CharacterImageContainer.defaultProps = {
    source: '',
    updateTime: new Date().getTime(),
    text: '',
    size: 'md',
    userType: 'I',
    // backgroundColorClass: 'p-type02',
    characterType: 'user',
    onClick: _.noop,
    cache: null,
    cancelToken: {}
};

export default CharacterImageContainer;
