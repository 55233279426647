import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomList from 'components/presentationals/List/CustomList';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import UserRest from 'apis/UserRest';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchUserRow from './SearchUserRow';
import SelectedUsers from './SelectedUsers';
import GlobalPopup from '../../../redux/service/GlobalPopup';
import ViewMoreRow from './ViewMoreRow';

class SearchUsers extends Component {
    constructor(props) {
        super(props);
        const { firstLoaded, intl, rowCountLimit } = this.props;
        this.state = {
            searchText: '',
            isFirstLoaded: firstLoaded,
            addButtonState: 'disabled',
            list: [],
            total: 0,
            showRelateList: false,
            isFocused: false,
            boardView: false,
            searchGridHeightOnBoardView: 230,
            selectedGridHeightOnBoardView: 138,
            page: 1,
            orderStateForName: '',
            orderStateForPosition: '',
            orderStateForDeptNm: '',
            searchType: '',
            searchListAllChecked: false,
            previousSearchText: '',
            relateListHover: 'off'
        };
        this.rowCount = rowCountLimit + 1;
        this.intl = intl;

        this.lastCheckedIndex = -1;
    }

    setCheckProperty(user, value) {
        const converted = Object.assign({}, user);
        converted.checked = value;
        return converted;
    }

    setAddType(user) {
        const converted = Object.assign({}, user);
        converted.type = 'USER';
        return converted;
    }

    getSearchType = value => {
        const { searchText } = this.state;
        const { rowCountLimit, languageCode } = this.props;

        const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

        if (_.isEmpty(trimmedSearchText)) {
            GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
            return;
        }

        this.rowCount = rowCountLimit + 1;
        this.setState(
            {
                searchType: value,
                page: 1,
                orderParam: languageCode === '002' ? 'userEngNm' : 'userNm',
                orderState: 'A',
                orderStateForName: 'asce',
                orderStateForPosition: '',
                orderStateForDeptNm: ''
            },
            () => this.getSearchText()
        );
    };

    closeRelateList = () => {
        this.setState({ showRelateList: false });
    };

    getSearchText = (limit, previousSearchText, page = 1) => {
        const { user, rowCountLimit } = this.props;
        const { searchText, searchType, orderParam, orderState } = this.state;
        let keyword = searchText;
        let params = {
            offset: (page - 1) * (limit === undefined ? rowCountLimit : limit),
            userStatCd: ['A', 'S', 'L'].join(','),
            tenantId: user.tenantId.toString(),
            limit: limit === undefined ? rowCountLimit : limit,
            orderParam,
            orderState
        };
        if (!_.isEmpty(previousSearchText) && _.isEmpty(searchText)) {
            keyword = previousSearchText;
        }

        if (searchType === '') {
            params = _.assign(params, { complexSearch: keyword });
        }

        if (_.isEqual(searchType, 'userLangCdNm')) {
            params = _.assign(params, { userLangCdNmLike: keyword });
        }
        if (_.isEqual(searchType, 'userLoginId')) {
            params = _.assign(params, { userLoginIdLike: keyword });
        }

        UserRest.getUser(params).then(response => {
            const { list, addButtonState } = this.state;
            let returnedList = _.get(response, 'data', []);
            const total = _.get(response, 'total', 0);

            returnedList = _.map(returnedList, returnedUser => this.setCheckProperty(returnedUser, false));
            returnedList = _.map(returnedList, returnedUser => this.setAddType(returnedUser));

            let newList = [];
            let newAddButtonState = '';
            if (page === 1) {
                newList = returnedList;
                newAddButtonState = 'disabled';
            } else {
                newList = [...list, ...returnedList];
                newAddButtonState = addButtonState;
            }

            const endOfList = newList.length === total;

            const normalRowCount = this.rowCount;
            this.rowCount = endOfList ? newList.length : normalRowCount;

            this.setState(
                {
                    list: newList,
                    total,
                    isFirstLoaded: true,
                    showRelateList: false,
                    searchGridHeightOnBoardView: 230,
                    selectedGridHeightOnBoardView: 138,
                    searchListAllChecked: false,
                    addButtonState: newAddButtonState,
                    searchType
                },
                this.adjustGridHeight
            );

            this.lastCheckedIndex = -1;
        });
    };

    onChangeSearchText = event => {
        const searchText = _.get(event, 'target.value');
        this.setState({
            searchText,
            showRelateList: !_.isEmpty(searchText)
        });

        if (_.isEmpty(searchText)) {
            this.setState({ searchType: '' });
        }
    };

    clickSearchButton = () => {
        const { searchText } = this.state;
        const { rowCountLimit, languageCode } = this.props;
        const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

        if (_.isEmpty(trimmedSearchText)) {
            GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
            return;
        }
        this.rowCount = rowCountLimit + 1;
        this.setState(
            {
                searchText,
                page: 1,
                previousSearchText: searchText,
                orderParam: languageCode === '002' ? 'userEngNm' : 'userNm',
                orderState: 'A',
                orderStateForName: 'asce',
                orderStateForPosition: '',
                orderStateForDeptNm: ''
            },
            () => this.getSearchText()
        );
    };

    onChangeSearchTextByEnter = event => {
        const { rowCountLimit, languageCode } = this.props;

        if (event.keyCode === 13) {
            const { searchText } = this.state;
            const trimmedSearchText = _.isNil(searchText) ? '' : searchText.trim();

            if (_.isEmpty(trimmedSearchText)) {
                GlobalPopup.openAlertPopup(this.intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
                return;
            }

            this.rowCount = rowCountLimit + 1;
            this.setState(
                {
                    searchText,
                    page: 1,
                    previousSearchText: searchText,
                    orderParam: languageCode === '002' ? 'userEngNm' : 'userNm',
                    orderState: 'A',
                    orderStateForName: 'asce',
                    orderStateForPosition: '',
                    orderStateForDeptNm: ''
                },
                () => this.getSearchText()
            );
        }
    };

    clickMoreButton = () => {
        const { page } = this.state;
        const { rowCountLimit } = this.props;
        const newPage = page + 1;
        const newRowCount = rowCountLimit * newPage;
        this.rowCount = newRowCount + 1;
        this.setState({ page: newPage }, () => this.getSearchText(rowCountLimit, undefined, newPage));
    };

    selectUser = () => {
        const { addTarget } = this.props;
        const { list } = this.state;
        const itemList = _.filter(list, ['checked', true]);
        const itemListUnchecked = _.map(itemList, function(item) {
            const itemCopied = _.cloneDeep(item);
            itemCopied.checked = false;
            itemCopied.isHovered = false;
            return itemCopied;
        });
        addTarget(itemListUnchecked);
    };

    checkSearchUser = (index, checked, event) => {
        const { list } = this.state;
        const checkedList = _.cloneDeep(list);

        // shift key + row를 선택하면서
        // this.lastCheckedIndex === -1 (이전에 단순 클릭으로 선택된 row가 없으면)
        // 그냥 무시
        if (!_.isNil(event) && event.shiftKey && this.lastCheckedIndex === -1) {
            return;
        }

        // SearchDepts.jsx 의 checkSelectedUser 과 동일하게 수정!!!
        // shift key + row를 선택하면서
        // this.lastCheckedIndex !== -1 (이전에 단순 클릭으로 선택된 row가 있으면)
        if (!_.isNil(event) && event.shiftKey && this.lastCheckedIndex !== -1) {
            const startIndex = _.min([this.lastCheckedIndex, index]);
            const endIndex = _.max([this.lastCheckedIndex, index]);

            // 이전에 클릭된 row부터(this.lastCheckedIndex) shift + 선택한 row까지만 선택하고 나머지는 다 해제
            _.forEach(checkedList, (draft, i) => {
                draft.checked = startIndex <= i && i <= endIndex;
                draft.isHovered = startIndex <= i && i <= endIndex;
            });
        } else {
            // 단순 row 선택
            checkedList[index].checked = checked;
            checkedList[index].isHovered = checked;

            // 선택하는 경우만 this.lastCheckedIndex 에 저장
            if (checked) {
                this.lastCheckedIndex = index;
            } else if (this.lastCheckedIndex === index) {
                this.lastCheckedIndex = -1;
            }
        }

        const forRemovingAllChecked = _.filter(checkedList, { checked: true });

        // 아무것도 선택된 것이 없으면 this.lastCheckedIndex 초기화
        if (forRemovingAllChecked.length === 0) {
            this.lastCheckedIndex = -1;
        }

        this.setState({ list: checkedList, addButtonState: '', searchListAllChecked: forRemovingAllChecked.length === checkedList.length });
    };

    checkSelectedUserByRowClick = (item, index, checked) => {
        const { list } = this.state;
        const checkedList = Object.assign([], list);
        checkedList[index].checked = checked;
        checkedList[index].isHovered = checked;
        this.setState({ list: checkedList });
        this.setState({ addButtonState: '' });
    };

    handleCheckboxAll = e => {
        const { list } = this.state;
        const { checked } = e.target;

        const checkAllorNot = _.map(list, item => {
            const clone = _.cloneDeep(item);
            clone.checked = checked;
            clone.isHovered = checked;
            return clone;
        });

        this.lastCheckedIndex = -1;

        this.setState({ list: checkAllorNot, searchListAllChecked: checked });
        if (checked) {
            this.setState({ addButtonState: '' });
        } else {
            this.setState({ addButtonState: 'disabled' });
        }
    };

    delBoxOnclick = index => {
        const { selectedList } = this.state;
        const removedList = _.pull(selectedList, selectedList[index]);
        this.setState({ selectedList: removedList });
    };

    rowBuilder = ({ style, index }) => {
        const { list } = this.state;
        const { cancelToken } = this.props;

        return list.length === index || _.isNil(list[index]) ? (
            <ViewMoreRow load={this.clickMoreButton} style={style} />
        ) : (
            <SearchUserRow
                user={list[index]}
                index={index}
                checkSearchUser={this.checkSearchUser}
                style={style}
                key={list[index].userId}
                checkBox={true}
                delBoxOnclick={this.delBoxOnclick}
                checkSelectedUserByRowClick={this.checkSelectedUserByRowClick}
                cancelToken={cancelToken}
            />
        );
    };

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    onBlur = () => {
        this.setState({ isFocused: false });
    };

    onClickWideButton = () => {
        const { boardView } = this.state;
        this.setState({ boardView: !boardView }, () => this.adjustGridHeight());
    };

    adjustGridHeight = () => {
        const { boardView } = this.state;
        if (boardView) {
            this.setState({ searchGridHeightOnBoardView: 92 });
            this.setState({ selectedGridHeightOnBoardView: 276 });
        } else {
            this.setState({ searchGridHeightOnBoardView: 230 });
            this.setState({ selectedGridHeightOnBoardView: 138 });
        }
    };

    changeOrderState = orderParam => {
        const { searchText, previousSearchText, orderStateForName, orderStateForDeptNm, orderStateForPosition } = this.state;
        const { languageCode, rowCountLimit } = this.props;

        let newOrderParam = '';
        let newOrderState = '';
        let newOrderSign = '';
        if (orderParam === 'userLangCdNm') {
            if (orderStateForName === '' || orderStateForName === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '002' ? 'userEngNm' : 'userNm';
            this.setState({ orderStateForName: newOrderSign });
            this.setState({ orderStateForPosition: '' });
            this.setState({ orderStateForDeptNm: '' });
        }

        if (orderParam === 'deptLangCdNm') {
            if (orderStateForDeptNm === '' || orderStateForDeptNm === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '002' ? 'deptEngNm' : 'deptNm';
            this.setState({ orderStateForName: '' });
            this.setState({ orderStateForPosition: '' });
            this.setState({ orderStateForDeptNm: newOrderSign });
        }

        if (orderParam === 'userLangCdCopNm') {
            if (orderStateForPosition === '' || orderStateForPosition === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '002' ? 'userCopEngNm' : 'userCopNm';
            this.setState({ orderStateForName: '' });
            this.setState({ orderStateForPosition: newOrderSign });
            this.setState({ orderStateForDeptNm: '' });
        }

        const newLimit = this.rowCount < rowCountLimit ? this.rowCount : this.rowCount - 1;
        if (_.isEmpty(searchText) && _.isEmpty(previousSearchText)) {
            this.setState({ isFirstLoaded: false });
        } else if (_.isEmpty(searchText) && !_.isEmpty(previousSearchText)) {
            this.setState({ orderParam: newOrderParam, orderState: newOrderState }, () => this.getSearchText(newLimit, previousSearchText, 1));
        } else {
            this.setState({ orderParam: newOrderParam, orderState: newOrderState }, () => this.getSearchText(newLimit, undefined, 1));
        }
    };

    onClickClearButton = () => {
        const { searchText } = this.state;
        this.setState({ searchText: '', showRelateList: false, orderState: 'A', previousSearchText: searchText });
    };

    deleteTargetsByClickIcon = items => {
        const { deleteTargets } = this.props;
        this.adjustGridHeight();
        deleteTargets(items);
    };

    relateListhoverOn = () => {
        this.setState({ relateListHover: 'on' });
    };

    relateListhoverOff = () => {
        this.setState({ relateListHover: 'off' });
    };

    render() {
        const { selected, selectedTotal, selectedAllChecked, deleteTargetUser, deleteTargetDept, checkSelectedUser, handleCheckboxAll, intl, cancelToken } = this.props;

        const {
            searchText,
            isFirstLoaded,
            addButtonState,
            total,
            showRelateList,
            isFocused,
            searchGridHeightOnBoardView,
            selectedGridHeightOnBoardView,
            boardView,
            orderStateForName,
            orderStateForPosition,
            orderStateForDeptNm,
            searchListAllChecked,
            relateListHover
        } = this.state;
        return (
            <React.Fragment>
                <div className="forms-container">
                    <div className={`box-input-group has-overlay-layer ${isFocused ? 'is-focused' : ''}`}>
                        <a
                            className={`btn-ic-nor btn-func-search ${isFocused ? 'on' : ''}`}
                            role="button"
                            title={this.intl.formatMessage({ id: 'com.search' })}
                            onClick={event => this.clickSearchButton(event)}>
                            <i className="ic-16-search" role="button" />
                        </a>
                        <input
                            className="form-control"
                            type="text"
                            value={searchText}
                            onChange={event => this.onChangeSearchText(event)}
                            placeholder={this.intl.formatMessage({ id: 'user.placeHolder.popup.selectPeople.organize.searchUser' })}
                            onKeyDown={event => this.onChangeSearchTextByEnter(event)}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            maxLength={100}
                        />
                        <a
                            className="btn-ic-nor btn-func-clear"
                            role="button"
                            title={this.intl.formatMessage({ id: 'com.text.clear' })}
                            style={searchText ? { display: 'inline-flex' } : { display: 'none' }}
                            onClick={this.onClickClearButton}>
                            <i className="ic-16-clear" />
                        </a>

                        <div
                            className={`overlay-layer-wrapper ${showRelateList ? 'show' : ''}`}
                            role="button"
                            onClick={event => this.closeRelateList(event)}
                            onBlur={event => this.closeRelateList(event)}>
                            <div className="overlay-layer">
                                <div className="layer-content">
                                    <div className="layer-body">
                                        <ul className="related-list">
                                            <li onMouseEnter={this.relateListhoverOn} onMouseLeave={this.relateListhoverOff} className={`${relateListHover} ellipsis`}>
                                                <a role="button" onClick={() => this.getSearchType('userLangCdNm')}>
                                                    <FormattedMessage id="com.name" />: <em>{searchText}</em>
                                                </a>
                                            </li>
                                            <li className="ellipsis">
                                                <a role="button" onClick={() => this.getSearchType('userLoginId')}>
                                                    <FormattedMessage id="com.email" />: <em>{searchText}</em>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`srch-flex-layout ${boardView ? 'boardview' : ''}`}>
                    <div className="flex-upper">
                        <div className="flex-box-right">
                            <div className="pop-grid-list">
                                <If condition={!isFirstLoaded}>
                                    <div className="box-empty">
                                        <div className="message">
                                            <p>
                                                {intl.formatMessage({ id: 'user.guideText.popup.selectPeople.enterSearchWord' })}
                                                <br />
                                                {intl.formatMessage({ id: 'user.guideText.popup.selectPeople.enterSearchWord2' })}
                                            </p>
                                        </div>
                                    </div>
                                </If>
                                <If condition={isFirstLoaded && total !== 0}>
                                    <div className="comm-grid-list react-list">
                                        <div className="grid-header-group">
                                            <div className="grid-row">
                                                <div className="grid-cell check-cell">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input className="form-check-input" type="checkbox" onClick={event => this.handleCheckboxAll(event)} checked={searchListAllChecked} />
                                                            <span className="label-text" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="grid-cell name-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdNm')} className={`column-sort ${orderStateForName}`}>
                                                        <em>
                                                            <FormattedMessage id="com.name" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForName === '' ? 'asce' : orderStateForName}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell position-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdCopNm')} className={`column-sort ${orderStateForPosition}`}>
                                                        <em>
                                                            <FormattedMessage id="com.position" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForPosition === '' ? 'desc' : orderStateForPosition}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell department-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('deptLangCdNm')} className={`column-sort ${orderStateForDeptNm}`}>
                                                        <em>
                                                            <FormattedMessage id="com.department" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForDeptNm === '' ? 'asce' : orderStateForDeptNm}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell email-cell">
                                                    <span className="column-name">
                                                        <FormattedMessage id="com.email" />
                                                    </span>
                                                </div>
                                                <div className="grid-cell mobile-cell">
                                                    <span className="column-name">
                                                        <FormattedMessage id="com.mobile" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll">
                                            <CustomList
                                                className="grid-row-group row-5"
                                                height={searchGridHeightOnBoardView}
                                                customScroll={true}
                                                virtualized={true}
                                                rowCount={this.rowCount}
                                                rowHeight={46}
                                                rowBuilder={this.rowBuilder}
                                            />
                                        </div>
                                    </div>
                                </If>
                                <If condition={isFirstLoaded && total === 0}>
                                    <div className="comm-grid-list react-list">
                                        <div className="grid-header-group">
                                            <div className="grid-row">
                                                <div className="grid-cell check-cell">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input className="form-check-input" type="checkbox" />
                                                            <span className="label-text" />
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="grid-cell name-cell">
                                                    <a role="button" className="column-sort asce">
                                                        <em>
                                                            {' '}
                                                            <FormattedMessage id="com.name" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className="ic-12-arr-asce" />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell position-cell">
                                                    <em className="col-resizer" />
                                                    <a role="button" className="column-sort">
                                                        <em>
                                                            {' '}
                                                            <FormattedMessage id="com.position" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className="ic-12-arr-desc" />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell department-cell">
                                                    <em className="col-resizer" />
                                                    <a role="button" className="column-sort">
                                                        <em>
                                                            {' '}
                                                            <FormattedMessage id="com.department" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className="ic-12-arr-asce" />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell email-cell">
                                                    <em className="col-resizer" />
                                                    <span className="column-name">
                                                        {' '}
                                                        <FormattedMessage id="com.email" />
                                                    </span>
                                                </div>
                                                <div className="grid-cell mobile-cell">
                                                    <em className="col-resizer" />
                                                    <span className="column-name">
                                                        {' '}
                                                        <FormattedMessage id="com.mobile" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll">
                                            <div>
                                                <div className="box-empty row-5">
                                                    <div className="message">
                                                        <p>
                                                            <FormattedMessage id="com.no-search-results" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <div className="flex-box-bottom">
                                <a className={`btn btn-sm btn-secondary ${addButtonState}`} role="button" onClick={this.selectUser}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.add" />
                                    </span>
                                </a>
                                <div className="total-count">
                                    <span>
                                        <FormattedMessage id="com.text.total" />
                                    </span>
                                    <span className="count">
                                        <i className="num">{total}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectedUsers
                        selected={selected}
                        selectedTotal={selectedTotal}
                        onClickWideButton={this.onClickWideButton}
                        deleteTargetUser={deleteTargetUser}
                        deleteTargetDept={deleteTargetDept}
                        deleteTargets={this.deleteTargetsByClickIcon}
                        checkSelectedUser={checkSelectedUser}
                        handleCheckboxAll={handleCheckboxAll}
                        selectedAllChecked={selectedAllChecked}
                        selectedGridHeightOnBoardView={selectedGridHeightOnBoardView}
                        boardView={boardView}
                        cancelToken={cancelToken}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(SearchUsers);

SearchUsers.propTypes = {
    firstLoaded: PropTypes.bool,
    addTarget: PropTypes.func,
    selected: PropTypes.array,
    selectedTotal: PropTypes.number,
    selectedAllChecked: PropTypes.bool,
    deleteTargetUser: PropTypes.func,
    deleteTargetDept: PropTypes.func,
    deleteTargets: PropTypes.func.isRequired,
    checkSelectedUser: PropTypes.func,
    handleCheckboxAll: PropTypes.func,
    languageCode: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    rowCountLimit: PropTypes.number,
    cancelToken: PropTypes.object
};

SearchUsers.defaultProps = {
    firstLoaded: false,
    addTarget: _.noop,
    selected: [],
    selectedTotal: 0,
    selectedAllChecked: false,
    deleteTargetUser: _.noop,
    deleteTargetDept: _.noop,
    checkSelectedUser: _.noop,
    handleCheckboxAll: _.noop,
    rowCountLimit: 100,
    cancelToken: {}
};
