import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SUMMARY_FORM_TYPE, SUMMARY_FORM_TYPES } from '../../SummaryOptionConstant';
import { summaryActions } from '../../../../../../redux/actions/Summary';
import FormOptionItem from './FormOptionItem';

const FormOptionGroup = () => {
    const selectedFormType = useSelector(({ summary }) => summary.optionSelectDialog.summaryOptions.formType);
    const dispatch = useDispatch();

    const handleSelect = value => {
        dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { formType: value } }));
    };

    return (
        <dl className="row-list flex-wrap">
            <dt>
                <FormattedMessage id="drive.text.copilot.summary.option.formType" />
            </dt>
            <dd>
                <div className="copilot-form-check-group">
                    {SUMMARY_FORM_TYPES.map(type => {
                        switch (type) {
                            case SUMMARY_FORM_TYPE.line:
                            case SUMMARY_FORM_TYPE.format:
                                return <FormOptionItem type={type} selectedFormType={selectedFormType} onSelect={handleSelect} key={type} />;
                            default:
                                throw new Error('Unknown summary form type');
                        }
                    })}
                </div>
            </dd>
        </dl>
    );
};

FormOptionGroup.propTypes = {};

FormOptionGroup.defaultProps = {};

export default FormOptionGroup;
