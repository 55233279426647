import _ from 'lodash';
import ApprovalConstants from './ApprovalConstants';

// "reqTgtUserSno": 1,
//     "reqPathSectCd": "APPROVAL",
//     "candidates": [
//     {
//         "reqTgtUserId": "156868362012600357",
//         "reqTgtUserNm": "오세웅",
//         "reqTgtUserDeptNm": "상품기획",
//         "reqTgtUserCopNm": "사원",
//         "reqTgtUserEmail": "flcm.test13@stage.samsungsds.com",
//         "reqTgtUserSno": 1,
//         "reqPathSectCd": "APPROVAL"
//     }
// ],
// "minimum": 1

class ApprovalUtil {
    static APPROVABLE_TYPES = [ApprovalConstants.LINE_TYPE.APPROVAL, ApprovalConstants.LINE_TYPE.CONSENT, ApprovalConstants.LINE_TYPE.PARALLEL_APPROVAL, ApprovalConstants.LINE_TYPE.PARALLEL_CONSENT];

    /**
     * 일반 사용자를 결재선 사용자로 변환
     * @param user
     * @param type
     * @param detail
     * @returns {{userNm, userCopNm: string, deptNm: string, userId, email: string}}
     */
    static convertUserToLineUser(user = {}, type = ApprovalConstants.LINE_TYPE.APPROVAL) {
        const { userId, userNm, deptNm = '', userCopNm = '', userLoginId: email = '' } = user;
        const { opinion = '', statCd = ApprovalConstants.STAT_CODE.PROCESSING, actDt = '' } = user;
        return {
            userId,
            userNm,
            userCopNm: _.isNull(userCopNm) || userCopNm === 'null' ? '' : userCopNm,
            deptNm: _.isNull(deptNm) || deptNm === 'null' ? '' : deptNm,
            email: _.isNull(email) || email === 'null' ? '' : email,
            type,
            statCd,
            opinion,
            actDt,
            isValid: true
        };
    }

    /**
     * 요청 대상을 결재선 사용자로 변환
     * @param reqTgtUser
     * @returns {{userNm, userCopNm: string, deptNm: string, userId, email: string}}
     */
    static convertTargetUserToLineUser(reqTgtUser = {}) {
        const {
            reqTgtUserId: userId,
            reqTgtUserNm: userNm,
            reqTgtUserDeptNm: deptNm = '',
            reqTgtUserCopNm: userCopNm = '',
            reqTgtUserEmail: email = '',
            reqPathSectCd: type = ApprovalConstants.LINE_TYPE.APPROVAL,
            confmStatCd: statCd = ApprovalConstants.STAT_CODE.PROCESSING,
            confmStatChgDt: actDt = '',
            percOpin: opinion = ''
        } = reqTgtUser;
        return {
            userId,
            userNm,
            userCopNm: _.isNull(userCopNm) || userCopNm === 'null' ? '' : userCopNm,
            deptNm: _.isNull(deptNm) || deptNm === 'null' ? '' : deptNm,
            email: _.isNull(email) || email === 'null' ? '' : email,
            type,
            statCd,
            opinion,
            actDt,
            isValid: true
        };
    }

    /**
     * 결재선 사용자를 요청 대상으로 변환
     * @param lineUser
     * @param sno
     * @param type
     * @returns {{reqTgtUserEmail, reqPathSectCd: string, reqTgtUserCopNm, reqTgtUserId, reqTgtUserSno: number, reqTgtUserNm, reqTgtUserDeptNm}}
     */
    static convertLineUserToTargetUser(lineUser = {}, sno = 1, type = ApprovalConstants.LINE_TYPE.APPROVAL) {
        const { userId: reqTgtUserId, userNm: reqTgtUserNm, deptNm: reqTgtUserDeptNm, userCopNm: reqTgtUserCopNm, email: reqTgtUserEmail } = lineUser;
        return {
            reqTgtUserSno: sno,
            reqTgtUserId,
            reqTgtUserNm,
            reqTgtUserDeptNm,
            reqTgtUserCopNm,
            reqTgtUserEmail,
            reqPathSectCd: type
        };
    }

    /**
     * 요청 대상 후보 목록을 결재선으로 변환
     * @param candidates
     */
    static convertCandidatesToLine(candidates = []) {
        const line = {};

        _.forEach(candidates, candidate => {
            const { reqTgtUserSno: sno = 1, candidates: list = [], reqPathSectCd: type = ApprovalConstants.LINE_TYPE.APPROVAL, minimum = 1, reqPathGuide: guide } = candidate;
            const lineUsers = _.map(list, this.convertTargetUserToLineUser);

            if (!line[sno]) {
                line[sno] = [];
            }
            line[sno].push({
                users: lineUsers,
                type,
                minimum,
                guide,
                selected: lineUsers.length === 1 ? [0] : []
            });
        });

        return line;
    }

    /**
     * 요청 대상 목록을 결재선으로 변환
     * @param targetUsers
     */
    static convertTargetUsersToLine(targetUsers = []) {
        const line = {};

        _.forEach(targetUsers, targetUser => {
            const { reqTgtUserSno: sno = 1, reqPathSectCd: type = ApprovalConstants.LINE_TYPE.APPROVAL } = targetUser;
            const lineUser = this.convertTargetUserToLineUser(targetUser);

            if (!line[sno]) {
                line[sno] = [];
            }
            line[sno].push({
                users: [lineUser],
                type,
                minimum: 1,
                selected: [0]
            });
        });

        return line;
    }

    /**
     * 결재선을 요청 대상 목록으로 변환
     * @param line
     * @returns {Array}
     */
    static convertLineToTargetUsers(line = {}) {
        if (_.isEmpty(line)) {
            return [];
        }

        const targetUsers = [];

        const keys = _.chain(line)
            .keys()
            .sortedUniq()
            .value();

        _.forEach(keys, sno => {
            const eachParallel = line[sno];

            _.forEach(eachParallel, eachLine => {
                const { users, type, minimum, selected } = eachLine;

                // 선택 안된 결재자 있는경우/최소 선택 개수보다 작은경우 PASS
                if (!_.isEmpty(selected) && selected.length >= minimum) {
                    // 사용자 선택
                    if (!_.isEmpty(users)) {
                        _.forEach(selected, selectedIdx => {
                            if (users[selectedIdx].isValid) {
                                targetUsers.push(this.convertLineUserToTargetUser(users[selectedIdx], sno, type));
                            }
                        });
                    }
                    // 사용자 입력
                    else {
                        _.forEach(selected, inputUser => {
                            if (inputUser.isValid) {
                                targetUsers.push(this.convertLineUserToTargetUser(inputUser, sno, type));
                            }
                        });
                    }
                }
            });
        });
        return targetUsers;
    }

    /**
     * 결재선의 상신가능 여부
     */
    static isRequestableLine(line = {}) {
        if (_.isEmpty(line)) {
            return false;
        }

        const keys = _.chain(line)
            .keys()
            .sortedUniq()
            .value();

        for (let i = 0; i < keys.length; i += 1) {
            const sno = keys[i];
            const eachParallel = line[sno];

            for (let rIndex = 0; rIndex < eachParallel.length; rIndex += 1) {
                const eachLine = line[sno][rIndex];
                if (!this.isRequestableLineUser(eachLine)) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * 결재선 사용자의 상신가능 여부
     * @param lineUser
     * @returns {boolean}
     */
    static isRequestableLineUser(lineUser = {}) {
        const { users, type, minimum, selected } = lineUser;

        // 승인가능 타입만 확인
        if (this.APPROVABLE_TYPES.indexOf(type) !== -1) {
            // 선택된 결재선 사용자가 없거나, 최소 개수보다 작은경우 FAIL
            if (_.isEmpty(selected) || minimum > selected.length) {
                return false;
            }
            // 선택된 사용자가 유효한지 확인
            if (!_.isEmpty(users) && !_.every(selected, selectedIdx => users[selectedIdx].isValid)) {
                return false;
            }
            // 입력된 사용자가 유효한지 확인
            if (_.isEmpty(users) && !_.every(selected, inputUser => inputUser.isValid)) {
                return false;
            }
        }

        return true;
    }

    /**
     * 미등록 사용자 여부
     * @param userId
     * @returns {boolean}
     */
    static isUnregisteredUser(userId = '') {
        return userId.startsWith(ApprovalConstants.USER.UNREG_USER_ID_PREFIX) && userId.length === 18;
    }
}

export default ApprovalUtil;
