import React, { memo, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import classnames from 'classnames';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Choose, When } from 'jsx-control-statements';
import { SUMMARY_TOOLTIP_DIRECTION } from '../SummaryConstant';
import { summaryActions } from '../../../../../redux/actions/Summary';

const SummaryTooltip = memo(() => {
    const tooltipRef = useRef();

    const [isShow, setIsShow] = useState(false);
    const [positionStyle, setPositionStyle] = useState({ top: '0px', left: '0px' });
    const [arrowPositionStyle, setArrowPositionStyle] = useState({ top: '0px', left: '0px' });

    const { isOpen, tooltipIconRef, direction, content } = useSelector(({ summary }) => summary.tooltip, shallowEqual);
    const { messageId, text } = content;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isOpen || _.isNil(tooltipIconRef)) {
            setIsShow(false);
            return;
        }

        const { width: tooltipWidth, height: tooltipHeight } = tooltipRef.current.getBoundingClientRect();
        const { top: iconTop, bottom: iconBottom, left: iconLeft, right: iconRight, height: iconHeight, width: iconWidth } = tooltipIconRef.current.getBoundingClientRect();

        let top;
        let left;

        switch (direction) {
            case SUMMARY_TOOLTIP_DIRECTION.left:
                top = iconTop + iconHeight / 2 - tooltipHeight / 2;
                left = iconLeft - tooltipWidth;
                break;
            case SUMMARY_TOOLTIP_DIRECTION.right:
                top = iconTop + iconHeight / 2 - tooltipHeight / 2;
                left = iconRight;
                break;
            case SUMMARY_TOOLTIP_DIRECTION.top:
                top = iconTop - tooltipHeight;
                left = iconLeft + iconWidth / 2 - tooltipWidth / 2;
                break;
            case SUMMARY_TOOLTIP_DIRECTION.bottom:
                top = iconBottom;
                left = iconLeft + iconWidth / 2 - tooltipWidth / 2;
                break;
            default:
                console.log(`Unknown direction: ${direction}`);
                dispatch(summaryActions.tooltip.close());
        }

        setPositionStyle({ top: `${top}px`, left: `${left}px`, willChange: 'transform', position: 'absolute' }); // transform: 'translate3d(407px, 297px, 0px);'
        setArrowPositionStyle([SUMMARY_TOOLTIP_DIRECTION.left, SUMMARY_TOOLTIP_DIRECTION.right].includes(direction) ? { top: tooltipHeight / 2 - 3 } : { left: tooltipWidth / 2 - 3 });
        setIsShow(true);
    }, [isOpen, tooltipIconRef]);

    return (
        <div className={classnames('tooltip', `bs-tooltip-${direction}`, isShow && 'show')} style={{ ...positionStyle, display: isOpen ? '' : 'none' }} ref={tooltipRef}>
            <div className="arrow" style={arrowPositionStyle} />
            <div className="tooltip-inner">
                <div className="guide">
                    <Choose>
                        <When condition={messageId}>
                            <FormattedHTMLMessage tagName="span" id={messageId} />
                        </When>
                        <When condition={text}>{text}</When>
                    </Choose>
                </div>
            </div>
        </div>
    );
});

export default SummaryTooltip;
