import React from 'react';
import DriveHeader from '../containers/DriveHeader';
import DriveBody from '../containers/DriveBody';
import Own from '../../utils/DriveConfiguration/Own';

const OwnFiles = props => {
    const pageClass = Own;
    return (
        <div className="page-drv">
            <DriveHeader {...props} type="own" pageClass={pageClass} />
            <DriveBody {...props} pageClass={pageClass} />
        </div>
    );
};

export default OwnFiles;
