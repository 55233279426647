import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import CustomModal from 'containers/CustomModal';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SettingsRest from '../../../apis/SettingsRest';
import { auth as AUTH_ACTIONS } from '../../../redux/actions';
import GlobalPopupService from '../../../redux/service/GlobalPopup';

const TEXT = 'text';
const PASSWORD = 'password';
const SHOW = 'Show';
const HIDE = 'Hide';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            notMatchedCurrentPassword: false,
            notMatchedConfirmPassword: true,
            checkLength: false,
            checkCombination: false,
            checkEqualThreeCharacters: false,
            checkContinuousNumber: false,
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
            firstChange: false,
            checkIsEqualNow: false
        };
        this.checkSave = this.checkSave.bind(this);
    }

    setShowStatusPassword(type) {
        if (_.isEmpty(type)) return;
        this.setState(prevState => ({ [`show${type}`]: !prevState[`show${type}`] }));
    }

    checkCurrentPassword = async event => {
        const { userId } = this.props;
        const { value: password } = event.target;
        this.setState({ currentPassword: password }, async () => {
            const response = await SettingsRest.checkCurrnetPassword(userId, password);
            const { newPassword } = this.state;
            const isCheckEqualNow = !_.isEmpty(password) && !_.isEmpty(newPassword) && password !== newPassword;
            this.setState({ notMatchedCurrentPassword: response.resultCode !== 200, checkIsEqualNow: isCheckEqualNow });
        });
    };

    checkNewPassword = event => {
        const { value: password } = event.target;
        const { currentPassword, confirmPassword } = this.state;

        const isCheckLength = password.length >= 8 && password.length <= 12;
        const isCheckCombine = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{3,}$/.test(password) && !/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(password);
        const isCheckEqualThreeCharacters = !/(\w|\W)\1\1/.test(password) && /.+/.test(password);
        const isCheckContinuousNumber = !/(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)/.test(password) && /.+/.test(password);
        const isCheckEqualNow = !_.isEmpty(password) && !_.isEmpty(currentPassword) && password !== currentPassword;
        const isNotMatched = password !== confirmPassword;

        this.setState({
            newPassword: password,
            checkLength: isCheckLength,
            checkCombination: isCheckCombine,
            checkEqualThreeCharacters: isCheckEqualThreeCharacters,
            checkContinuousNumber: isCheckContinuousNumber,
            checkIsEqualNow: isCheckEqualNow,
            notMatchedConfirmPassword: isNotMatched
        });
    };

    checkConfirmPassword(event) {
        const { value: confirmPassword } = event.target;
        const { newPassword } = this.state;
        this.setState({ confirmPassword, firstChange: true });
        if (_.isEmpty(confirmPassword)) {
            this.setState({ notMatchedConfirmPassword: true });
        } else if (confirmPassword !== newPassword) {
            this.setState({ notMatchedConfirmPassword: true });
        } else {
            this.setState({ notMatchedConfirmPassword: false });
        }
    }

    checkSave() {
        const {
            currentPassword,
            notMatchedCurrentPassword,
            checkLength,
            checkCombination,
            checkEqualThreeCharacters,
            checkContinuousNumber,
            notMatchedConfirmPassword,
            checkIsEqualNow,
            newPassword
        } = this.state;
        return (
            !_.isEmpty(currentPassword) &&
            !_.isEmpty(newPassword) &&
            !notMatchedCurrentPassword &&
            checkLength &&
            checkCombination &&
            checkEqualThreeCharacters &&
            checkContinuousNumber &&
            !notMatchedConfirmPassword &&
            checkIsEqualNow
        );
    }

    save = async () => {
        const { userId, intl } = this.props;
        const { currentPassword, newPassword } = this.state;
        const response = await SettingsRest.changePassword(userId, currentPassword, newPassword);
        const { resultCode, data, message } = response;
        if (resultCode === 200 && data === 1) {
            const { setTokenStatus } = this.props;
            setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.AUTH);
        } else {
            GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
        }
    };

    render() {
        const { intl } = this.props;
        const {
            showCurrentPassword,
            notMatchedCurrentPassword,
            showNewPassword,
            showConfirmPassword,
            notMatchedConfirmPassword,
            checkLength,
            checkCombination,
            checkEqualThreeCharacters,
            checkContinuousNumber,
            firstChange,
            checkIsEqualNow
        } = this.state;
        return (
            <CustomModal isOpen={true} fade={true} backdrop="static" className="modal-md">
                <ModalHeader>
                    <FormattedMessage id="user.title.popup.changePW.changePassword" />
                </ModalHeader>
                <ModalBody>
                    <div className="dt-width-120 sr-setPW">
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="user.text.popup.changePW.currentPassword" />
                            </dt>
                            <dd>
                                <div className="input-group-remark">
                                    <div className={`box-input-group ${notMatchedCurrentPassword ? 'is-invalid' : ''}`}>
                                        <input
                                            className="form-control"
                                            type={showCurrentPassword ? TEXT : PASSWORD}
                                            placeholder={intl.formatMessage({ id: 'user.placeHolder.popup.changePW.enterCurrentPassword' })}
                                            onChange={this.checkCurrentPassword}
                                        />
                                        <a
                                            className={`btn-ic-nor btn-func-eye ${showCurrentPassword ? 'on' : ''}`}
                                            role="button"
                                            title={showCurrentPassword ? SHOW : HIDE}
                                            onClick={() => this.setShowStatusPassword('CurrentPassword')}>
                                            <i className="ic-16-eye" />
                                        </a>
                                    </div>
                                    <If condition={notMatchedCurrentPassword}>
                                        <div className="input-remark">
                                            <div className="err-msg">
                                                <FormattedMessage id="user.text.popup.changePW.incorrectPassword" />
                                            </div>
                                        </div>
                                    </If>
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="user.resetPassword.newPassword" />
                            </dt>
                            <dd>
                                <div className="input-group-remark">
                                    <div className="box-input-group">
                                        <input
                                            className="form-control"
                                            type={showNewPassword ? TEXT : PASSWORD}
                                            placeholder={intl.formatMessage({ id: 'user.resetPassword.enterNewPassword' })}
                                            onChange={event => this.checkNewPassword(event)}
                                        />
                                        <a
                                            className={`btn-ic-nor btn-func-eye ${showNewPassword ? 'on' : ''}`}
                                            role="button"
                                            title={showNewPassword ? SHOW : HIDE}
                                            onClick={() => this.setShowStatusPassword('NewPassword')}>
                                            <i className="ic-16-eye" />
                                        </a>
                                    </div>
                                    <div className="input-tooltip">
                                        <ul className="validation">
                                            <li className={checkLength ? 'on' : ''}>
                                                <span>
                                                    <FormattedMessage id="user.resetPassword.guide.passwordRule.8and12" />
                                                </span>
                                            </li>
                                            <li className={checkCombination ? 'on' : ''}>
                                                <span>
                                                    <FormattedMessage id="user.resetPassword.guide.passwordRule.necessaryChar" />
                                                </span>
                                            </li>
                                            <li className={checkEqualThreeCharacters ? 'on' : ''}>
                                                <span>
                                                    <FormattedMessage id="user.resetPassword.guide.passwordRule.identical3Char" />
                                                </span>
                                            </li>
                                            <li className={checkContinuousNumber ? 'on' : ''}>
                                                <span>
                                                    <FormattedMessage id="user.resetPassword.guide.passwordRule.sequential3Char" />
                                                </span>
                                            </li>
                                            <li className={checkIsEqualNow ? 'on' : ''}>
                                                <span>
                                                    <FormattedMessage id="user.text.resetPassword.not.equal.now.password" />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="user.resetPassword.confirmNewPassword" />
                            </dt>
                            <dd>
                                <div className="input-group-remark">
                                    <div className={`box-input-group ${firstChange && notMatchedConfirmPassword ? 'is-invalid' : ''}`}>
                                        <input
                                            className="form-control"
                                            type={showConfirmPassword ? TEXT : PASSWORD}
                                            placeholder={intl.formatMessage({ id: 'user.resetPassword.reEnterPassword' })}
                                            onChange={event => this.checkConfirmPassword(event)}
                                        />
                                        <a
                                            className={`btn-ic-nor btn-func-eye ${showConfirmPassword ? 'on' : ''}`}
                                            role="button"
                                            title={showConfirmPassword ? SHOW : HIDE}
                                            onClick={() => this.setShowStatusPassword('ConfirmPassword')}>
                                            <i className="ic-16-eye" />
                                        </a>
                                    </div>
                                    <If condition={firstChange && notMatchedConfirmPassword}>
                                        <div className="input-remark">
                                            <div className="err-msg">
                                                <FormattedMessage id="user.resetPassword.notMatchNewPassword" />
                                            </div>
                                        </div>
                                    </If>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className={`btn btn-lg btn-primary ${this.checkSave() ? '' : 'disabled'}`} disabled={!this.checkSave()} role="button" onClick={this.save}>
                            <span className="btn-text">
                                <FormattedMessage id="com.save" />
                            </span>
                        </a>
                    </div>
                </ModalFooter>
            </CustomModal>
        );
    }
}

ChangePassword.propTypes = {
    userId: PropTypes.string.isRequired,
    setTokenStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    userId: state.auth.user.id
});

const mapDispatchToProps = {
    setTokenStatus: AUTH_ACTIONS.setTokenStatus
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangePassword)
);
