import React from 'react';
import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import Socket from '../../Socket/Socket';
import { popup } from '../../../../utils/ModalService';
import FoldersRest from '../../../../apis/FoldersRest';

const OBJECT_TYPE_CODE = {
    FILE: 'FILE',
    FOLDER: 'FOLDER'
};
const DEFAULT_FOLDER_SIZE = -1;
const FOLDER_CHUNK_SIZE = 5;

const getFolderFileIds = async (folders = []) => {
    if (_.isEmpty(folders)) return [];

    const folderChunked = _.chain(folders)
        .map(folder =>
            FoldersRest.getFolder(folder.drive.objtId, {
                onpstId: folder.drive.onpstId,
                objtSectCd: OBJECT_TYPE_CODE.FILE,
                size: DEFAULT_FOLDER_SIZE
            })
        )
        .chunk(FOLDER_CHUNK_SIZE)
        .value();

    const folderFileIds = _.reduce(
        folderChunked,
        async (accFiles, chunk) => {
            const chunkedFiles = await Promise.all(chunk).then(datas => {
                let files = [];
                datas.forEach(({ list = [] }) => {
                    files = files.concat(_.map(list, objtInfo => objtInfo.drive.objtId));
                });
                return files;
            });
            return _.chain(chunkedFiles)
                .uniq()
                .concat(accFiles)
                .value();
        },
        []
    );

    return folderFileIds;
};

const executor = async (props, files) => {
    const fileIds = [];
    const folders = [];

    _.forEach(files, file => {
        if (file.drive.objtSectCd === OBJECT_TYPE_CODE.FILE) {
            fileIds.push(file.drive.objtId);
        } else if (file.drive.objtSectCd === OBJECT_TYPE_CODE.FOLDER) {
            folders.push(file);
        }
    });

    const folderFileIds = await getFolderFileIds(folders);
    const objtIdList = _.chain(fileIds)
        .concat(folderFileIds)
        .uniq()
        .value();

    popup(<Socket type="offline" objtIdList={objtIdList} />).then(
        data => {
            if (data) {
                props.callback(data);
            }
        },
        () => false
    );
};

class SetSecureMode extends BaseMenu {
    getName() {
        return 'setSecureMode';
    }

    getLabel() {
        return 'drive.button.list.menu.setSecureMode';
    }

    isVisible() {
        const {
            custom: { getSiteConfig = _.noop }
        } = this.props;
        const agentType = getSiteConfig('EFL_DRV_AGENT_TYPE');
        return super.isVisible(true, 'setSecureMode') && agentType !== 'S';
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-secure', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-12-secure', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default SetSecureMode;
