const Url = {
    home: '/home',
    own: '/own',
    shared: '/shared',
    workgroup: '/workgroup',
    sharing: '/sharing',
    deleted: '/deleted',
    search: '/search',
    settings: '/settings',
    link: '/link',
    help: '/help',
    notification: '/notification',
    popup: '/popup',
    agentDown: '/agent/download',
    expiration: '/expiration',
    compliance: '/compliance',
    req_mng: '/req_mng',
    agentLocking: '/agent/locking',
    duplicated: '/duplicated',
    similar: '/similar'
};

const PathTitle = {
    own: 'My files',
    shared: 'Shared files',
    workgroup: 'Workgroups'
};

const RootFolderId = {
    own: '111100000000000000',
    workgroup: '111200000000000000',
    shared: '111300000000000000'
};

const getUrlPrefixByLocation = (pathname = '') => {
    if (pathname === '') return '';
    const paths = pathname.split('/');
    if (paths.length > 1) return `/${paths[1]}`;
    return '';
};

const getUrlPrefixByPathTitle = (pathTitle = '') => {
    switch (pathTitle) {
        case PathTitle.workgroup:
            return Url.workgroup;
        case PathTitle.shared:
            return Url.shared;
        case PathTitle.own:
        default:
            return Url.own;
    }
};

const getUrlPrefixByPathVals = (pathVals = []) => {
    if (pathVals.length === 0 || pathVals[0].objtNm === '') return '';
    return getUrlPrefixByPathTitle(pathVals[0].objtNm);
};

const getUrlPrefixByPathValNm = (pathValNm = '') => {
    if (pathValNm === '') return '';
    const paths = pathValNm.split('\\');
    if (paths.length > 1) return getUrlPrefixByPathTitle(paths[1]);
    return '';
};

const getUrlPrefix = (file, location = {}) => {
    const { drive = {}, mine } = file;
    if (mine) {
        return Url.own;
    }
    if (drive.onpstSectCd === 'SYSTFOLDER') {
        return Url.workgroup;
    }

    // 경로 정보
    const { extra: { pathVals = [], pathValNm = '' } = {} } = file;
    if (pathVals.length > 0) {
        return getUrlPrefixByPathVals(pathVals);
    }
    if (pathValNm !== '') {
        return getUrlPrefixByPathValNm(pathValNm);
    }

    // 주소 정보
    const { pathname = '' } = location;
    if (pathname !== '') {
        return getUrlPrefixByLocation(pathname);
    }
    return Url.workgroup;
};

const isRootFolderId = (folderId = '') => {
    return [RootFolderId.own, RootFolderId.workgroup, RootFolderId.shared].indexOf(folderId) !== -1;
};

export { getUrlPrefix, isRootFolderId };
export default Url;
