import { $http } from 'modules/index';

class UploadConfigRest {
    static getConfigs() {
        return $http.get(`/drive/v1/upload-config`).then(
            response => {
                return response.data;
            },
            error => {
                return error;
            }
        );
    }
}

export default UploadConfigRest;
