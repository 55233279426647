import produce from 'immer';
import _ from 'lodash';
import * as ActionTypes from '../actions/PartnerAccount';
import { getDefaultValidation, getNewPartner } from '../service/PartnerAccount';

const initialState = {
    isOpen: false,
    partnerList: [],
    validationList: [],
    defaultLanguage: '001',
    teamSearchIndex: null,
    isSaveAble: false,
    registerType: 'single',
    maxUseDays: 365,
    defaultUserIfYn: 'N',
    defaultPartnerIfYn: 'N'
};

const partnerAccount = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.OPEN_POPUP_AFTER_SET_CONFIGS:
            return produce(state, draft => {
                const newPartner = getNewPartner();
                newPartner.hasKPAccount = draft.defaultUserIfYn;
                newPartner.language = draft.defaultLanguage;
                newPartner.expireDate = newPartner.expireDate.add(draft.maxUseDays, 'days');
                draft.isOpen = true;
                draft.partnerList = [].concat([newPartner]);
                draft.validationList = [].concat([getDefaultValidation()]);
            });
        case ActionTypes.CLOSE_POPUP:
            return initialState;
        case ActionTypes.ADD_PARTNER:
            return produce(state, draft => {
                const newPartnerList = [].concat(draft.partnerList);
                const newPartner = getNewPartner();
                newPartner.hasKPAccount = _.last(draft.partnerList).hasKPAccount;
                newPartner.language = draft.defaultLanguage;
                newPartner.expireDate = newPartner.expireDate.add(draft.maxUseDays, 'days');
                newPartnerList.push(newPartner);
                draft.partnerList = newPartnerList;

                const newValidationList = [].concat(draft.validationList);
                newValidationList.push(getDefaultValidation());
                draft.validationList = newValidationList;
            });
        case ActionTypes.REMOVE_PARTNER:
            return produce(state, draft => {
                const newPartnerList = draft.partnerList.filter((value, idx) => idx !== action.index);
                draft.partnerList = newPartnerList;

                const newValidationList = draft.validationList.filter((value, idx) => idx !== action.index);
                draft.validationList = newValidationList;
            });
        case ActionTypes.CHANGE_PARTNER_VALUE:
            return produce(state, draft => {
                // if (action.target === 'language' && draft.partnerList[action.index][action.target] === '002' && action.value !== '002') {
                //     draft.partnerList[action.index].name = '';
                //     draft.partnerList[action.index].position = '';
                // }
                // if (action.target === 'language' && action.value === '002') {
                //     draft.partnerList[action.index].name = draft.partnerList[action.index].engName;
                //     draft.partnerList[action.index].position = draft.partnerList[action.index].engPosition;
                // }
                // if (draft.partnerList[action.index].language === '002' && action.target === 'engName') {
                //     draft.partnerList[action.index].name = action.value;
                // }
                // if (draft.partnerList[action.index].language === '002' && action.target === 'engPosition') {
                //     draft.partnerList[action.index].position = action.value;
                // }
                draft.partnerList[action.index][action.target] = action.value;
            });
        case ActionTypes.CHANGE_PARTNER_VALIDATION_VALUE:
            return produce(state, draft => {
                draft.validationList[action.index][action.target] = action.value;
            });
        case ActionTypes.SET_DEFAULT_LANGUAGE:
            return produce(state, draft => {
                draft.defaultLanguage = action.language;
            });
        case ActionTypes.RESET_PARTNER_LIST:
            return produce(state, draft => {
                const newPartner = getNewPartner();
                newPartner.hasKPAccount = draft.defaultUserIfYn;
                newPartner.language = draft.defaultLanguage;
                newPartner.expireDate = newPartner.expireDate.add(draft.maxUseDays, 'days');
                draft.partnerList = [].concat([newPartner]);
                draft.validationList = [].concat([getDefaultValidation()]);
            });
        case ActionTypes.SET_TEAM_SEARCH_INDEX:
            return produce(state, draft => {
                draft.teamSearchIndex = action.index;
            });
        case ActionTypes.SET_IS_SAVE_ABLE:
            return produce(state, draft => {
                draft.isSaveAble = action.isSaveAble;
            });
        case ActionTypes.SET_REGISTER_TYPE:
            return produce(state, draft => {
                draft.registerType = action.registerType;
            });
        case ActionTypes.SET_EXPIRE_DATE_CONFIGS:
            return produce(state, draft => {
                draft.maxUseDays = action.maxUseDays;
            });
        case ActionTypes.SET_DEFAULT_USER_IF_YN:
            return produce(state, draft => {
                draft.defaultUserIfYn = action.userIfYn;
            });
        case ActionTypes.SET_DEFAULT_PARTNER_IF_YN:
            return produce(state, draft => {
                draft.defaultPartnerIfYn = action.partnerIfYn;
            });
        default:
            return state;
    }
};

export default partnerAccount;
