const events = {
    start: {},
    stop: {}
};

export default class LNBResize {
    static register(action, key, func) {
        events[action][key] = func;
    }

    static remove(action, key) {
        if (events[action][key]) {
            delete events[key];
        }
    }

    static execute(action) {
        Object.values(events[action]).forEach(func => {
            func.call();
        });
    }
}
