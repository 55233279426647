import React from 'react';
import { object } from 'prop-types';
import { If } from 'jsx-control-statements';
import { FormattedMessage } from 'react-intl';
import SizeConverter from '../../../../utils/SizeConverter';

const FolderSize = props => {
    const { totalInfoMap = {} } = props;
    const { totalSize, folderCnt, fileCnt } = totalInfoMap;
    const isOverBytes = totalSize !== undefined && totalSize >= 1024;

    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.size" />
            </dt>
            <dd>
                <div className="total-count ds-flex">
                    <If condition={totalSize !== undefined}>
                        <strong>
                            {SizeConverter.convert(totalSize)}
                            {isOverBytes && <span className="byte"> ({SizeConverter.withComma(totalSize)})</span>}
                        </strong>
                    </If>
                </div>
                <div className="total-count ds-flex">
                    <FormattedMessage id="drive.text.lwrFolderCount" /> {folderCnt}, <FormattedMessage id="drive.text.lwrFileCount" /> {fileCnt}
                </div>
            </dd>
        </>
    );
};

FolderSize.propTypes = { totalInfoMap: object };

FolderSize.defaultProps = { totalInfoMap: {} };

export default FolderSize;
