import React from 'react';
import { FormattedMessage } from 'react-intl';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import SearchRow from 'components/containers/DriveFiles/Row/SearchRow';
import SearchRest from '../../apis/SearchRest';
import SearchFilesSort from '../../components/containers/DriveFiles/SearchFilesSort';
import DriveFilesBody from '../../components/containers/DriveFiles/DriveFilesBody';
import Base from './Base';
import FileIconStyle from '../FileIconStyle';

class Search extends Base {
    ROOT_FOLDER_ID = '';

    OFFSET_TOP = 236;

    pageId = 'search';

    absoluteHeight = 105;

    cacheWhenLoad = true;

    searchFunction(params) {
        return SearchRest.integrated({ ...params, trackTotal: true });
    }

    getColumns() {}

    getExtensionIcon(file) {
        const { drive, sharedCount = 0 } = file;
        if (drive.objtSectCd === 'FOLDER') {
            return sharedCount ? 'ic-file-sm ic-file-FOLDER-share' : 'ic-file-sm ic-file-FOLDER';
        }
        return FileIconStyle.getStyle('ic-file-sm', drive.objtNm);
    }

    uploadBuilder() {}

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <SearchRow file={list[index]} index={index} style={style} />;
    }

    emptyBuilder() {
        return (
            <div className="result-list">
                <div className="box-empty">
                    <div className="message">
                        <span className="ic-empty-search" />
                        <p>
                            <FormattedMessage id="drive.text.search.result.empty" />
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    pageBuilder({ state: { total, list } }) {
        if (list && !list.length) {
            return <section className="drv-file-panel">{this.emptyBuilder()}</section>;
        }
        return (
            <section className="drv-file-panel">
                <SearchFilesSort total={total} />
                <div className="result-list">
                    <div className="comm-grid-list react-list">
                        <DriveFilesBody />
                    </div>
                </div>
            </section>
        );
    }
}

export default new Search();
