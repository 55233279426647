// action type
const PREFIX = 'GLOBAL_POPUP_';
export const OPEN_ALERT = PREFIX.concat('OPEN_ALERT');
export const CLOSE_ALERT = PREFIX.concat('CLOSE_ALERT');
export const OPEN_CONFIRM = PREFIX.concat('OPEN_CONFIRM');
export const CLOSE_CONFIRM = PREFIX.concat('CLOSE_CONFIRM');
export const CLOSE_CONFIRM_AFTER_CALLBACK = PREFIX.concat('CLOSE_CONFIRM_AFTER_CALLBACK');
export const OPEN_CHANGE_PASSWORD = PREFIX.concat('OPEN_CHANGE_PASSWORD');
export const CLOSE_CHANGE_PASSWORD = PREFIX.concat('CLOSE_CHANGE_PASSWORD');
export const CLOSE_CHANGE_PASSWORD_AFTER_CALLBACK = PREFIX.concat('CLOSE_CHANGE_PASSWORD_AFTER_CALLBACK');
export const OPEN_CHANGE_OWNER = PREFIX.concat('OPEN_CHANGE_OWNER');
export const CLOSE_CHANGE_OWNER = PREFIX.concat('CLOSE_CHANGE_OWNER');
export const OPEN_PROFILE_EDIT = PREFIX.concat('OPEN_PROFILE_EDIT');
export const CLOSE_PROFILE_EDIT = PREFIX.concat('CLOSE_PROFILE_EDIT');
export const OPEN_TEAM_SEARCH_WITH_TREE = PREFIX.concat('OPEN_TEAM_SEARCH_WITH_TREE');
export const CLOSE_TEAM_SEARCH_WITH_TREE = PREFIX.concat('CLOSE_TEAM_SEARCH_WITH_TREE');
export const OPEN_CHANGE_EXPIRATION_DATE = PREFIX.concat('OPEN_CHANGE_EXPIRATION_DATE');
export const CLOSE_CHANGE_EXPIRATION_DATE = PREFIX.concat('CLOSE_CHANGE_EXPIRATION_DATE');
export const OPEN_HELP_QUESTION_POPUP = PREFIX.concat('OPEN_HELP_QUESTION_POPUP');
export const CLOSE_HELP_QUESTION_POPUP = PREFIX.concat('CLOSE_HELP_QUESTION_POPUP');
export const OPEN_DEPT_MEMBER_POPUP = PREFIX.concat('OPEN_DEPT_MEMBER_POPUP');
export const CLOSE_DEPT_MEMBER_POPUP = PREFIX.concat('CLOSE_DEPT_MEMBER_POPUP');
export const OPEN_SECURE_MODE_REQUEST_POPUP = PREFIX.concat('OPEN_SECURE_MODE_REQUEST_POPUP');
export const CLOSE_SECURE_MODE_REQUEST_POPUP = PREFIX.concat('CLOSE_SECURE_MODE_REQUEST_POPUP');

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const openAlert = message => action(OPEN_ALERT, { message });
export const closeAlert = () => action(CLOSE_ALERT);
export const openConfirm = (message, callback) => action(OPEN_CONFIRM, { message, callback });
export const closeConfirm = result => action(CLOSE_CONFIRM, { result });
export const openChangePassword = callback => action(OPEN_CHANGE_PASSWORD, { callback });
export const closeChangePassword = result => action(CLOSE_CHANGE_PASSWORD, { result });
export const openChangeOwner = () => action(OPEN_CHANGE_OWNER);
export const closeChangeOwner = () => action(CLOSE_CHANGE_OWNER);
export const openProfileEdit = () => action(OPEN_PROFILE_EDIT);
export const closeProfileEdit = () => action(CLOSE_PROFILE_EDIT);
export const openTeamSearchWithTree = param => action(OPEN_TEAM_SEARCH_WITH_TREE, { param });
export const closeTeamSearchWithTree = team => action(CLOSE_TEAM_SEARCH_WITH_TREE, { team });
export const openChangeExpirationDate = id => action(OPEN_CHANGE_EXPIRATION_DATE, { id });
export const closeChangeExpirationDate = () => action(CLOSE_CHANGE_EXPIRATION_DATE);
export const openHelpQuestionPopup = () => action(OPEN_HELP_QUESTION_POPUP);
export const closeHelpQuestionPopup = () => action(CLOSE_HELP_QUESTION_POPUP);
export const openDeptMemberPopup = ({ deptId, deptName }) => action(OPEN_DEPT_MEMBER_POPUP, { deptId, deptName });
export const closeDeptMemberPopup = ({ deptId, deptName }) => action(CLOSE_DEPT_MEMBER_POPUP, { deptId, deptName });
export const openSecureModeRequestPopup = () => action(OPEN_SECURE_MODE_REQUEST_POPUP);
export const closeSecureModeRequestPopup = () => action(CLOSE_SECURE_MODE_REQUEST_POPUP);
