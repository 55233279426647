import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UserCard from '../../../containers/NameCard/UserCard';

const Own = props => {
    const { userMap = {} } = props;
    const { userLangCdNm = '', userId = '' } = userMap;
    const isNameCardOpen = userLangCdNm !== '시스템' && userLangCdNm !== 'SYSTEM';
    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.owner" />
            </dt>
            <dd>
                <div className="g-inline-flex">
                    <div className="ellipsis">
                        <span id="ownUserCard" className={`${isNameCardOpen ? 'has-name-card' : ''}`} title={userLangCdNm}>
                            {userLangCdNm}
                        </span>
                    </div>
                </div>
            </dd>
            {isNameCardOpen && <UserCard target="ownUserCard" id={userId} />}
        </>
    );
};

Own.propTypes = { userMap: object };

Own.defaultProps = { userMap: {} };

export default Own;
