import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import Base from './Base';
import FileCache from '../FileCache';
import FoldersRest from '../../apis/FoldersRest';
import FileDropZone from '../../components/presentationals/DragDrop/FileDropZone';

class Workgroup extends Base {
    pageId = 'workgroup';

    cacheWhenLoad = true;

    dndAble = true;

    sortPossibleColumns(folderId) {
        if (this.isRoot(folderId)) {
            return ['objtNm', 'objtStatChgDt'];
        }
        return null;
    }

    isExplorerConstraints(folder) {
        if (!folder || !folder.drive || !folder.drive.objtId || !folder.drive.onpstId || !folder.maxAuthCd) return false;
        return folder.drive.objtId === folder.drive.onpstId && folder.maxAuthCd === 'NONE';
    }

    searchFunction(params, folderId, onpstId, maxAuthCd) {
        if (this.isRoot(folderId)) {
            return FoldersRest.getFolders(this.pageId, params);
        }
        if (folderId === onpstId && maxAuthCd === 'NONE') {
            return FoldersRest.getFolders(this.pageId, { onpstId: folderId, ...params });
        }
        return FoldersRest.getFolder(folderId, params);
    }

    buildCache(folderId, onpstId, maxAuthCd) {
        if (this.isRoot(folderId)) {
            return new FileCache(file => {
                if (file && !file.mine) {
                    if (file.maxAuthCd === 'NONE') {
                        Object.keys(file.drive)
                            .filter(key => !file.drive[key])
                            .forEach(key => {
                                file.drive[key] = file.origin.drive[key]; // eslint-disable-line no-param-reassign
                            });
                    }
                    return file;
                }
                return null;
            });
        }
        return new FileCache(file => {
            if (folderId === onpstId && maxAuthCd === 'NONE') {
                if (file && !file.mine && file.maxAuthCd !== 'NONE' && file.drive && file.drive.onpstId === onpstId) return file;
            } else if (file && !file.mine && file.maxAuthCd !== 'NONE' && file.drive && file.drive.onpstFolderId === folderId) return file;
            return null;
        });
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    /**
     * 업로드 드랍존 처리
     */
    uploadBuilder(folder) {
        return this.dndAble && folder && <FileDropZone folder={folder} pageId={this.pageId} uploader={this.uploader} />;
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-shr" />
                    <FormattedHTMLMessage id="drive.text.list.empty.workgroup" tagName="p" />
                </div>
            </div>
        );
    }
}

export default new Workgroup();
