import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ApprovalConstants from '../ApprovalConstants';
import UserRequestRest from '../../../../../apis/UserRequestRest';
import BaseApproval from './BaseApproval';
import DateRangePickerWrapper from '../../../../presentationals/DateRangePickerWrapper';
import ApprovalUtil from '../ApprovalUtil';
import { alert as modalAlert, toast } from '../../../../../utils/ModalService';

const REQUEST_TYPE = 'PCCONTROL';
const SEARCH_TGT = 'KNOX_DRIVE';

class ExceptPcControl extends BaseApproval {
    type = ApprovalConstants.TYPE.EXCEPT_PC_CONTROL;

    title = 'user.title.approval.exceptPcControl';

    closeDelay = 1500;

    startDate = this.getStartDate();

    endDate = this.getEndDate();

    minDate = moment()
        .utcOffset(this.timeZone)
        .startOf('day');

    getStartDate() {
        if (this.hasDetail()) {
            return moment(this.detail.reqDtl.startDt)
                .utcOffset(this.timeZone)
                .startOf('day');
        }
        return moment()
            .utcOffset(this.timeZone)
            .startOf('day');
    }

    getEndDate() {
        if (this.hasDetail()) {
            return moment(this.detail.reqDtl.endDt)
                .utcOffset(this.timeZone)
                .startOf('day');
        }
        return moment()
            .utcOffset(this.timeZone)
            .add(3, 'month')
            .startOf('day');
    }

    async getApproverCandidates() {
        const response = await UserRequestRest.getApproverCandidates(REQUEST_TYPE);
        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return data;
    }

    async getCandidateLine() {
        const candidates = await this.getApproverCandidates();
        return ApprovalUtil.convertCandidatesToLine(candidates);
    }

    isValidStartDate = () => {
        return this.startDate.isSameOrAfter(this.minDate);
    };

    isValidEndDate = () => {
        if (this.endDate.diff(this.startDate, 'months', true) >= 0 && this.endDate.diff(this.startDate, 'months', true) <= 3) {
            return true;
        }
        return false;
    };

    beforeContentBuilder(contents = {}) {
        if (this.isViewMode()) return <></>;

        const { isValidStartDate = true } = contents;

        const onDatesChange = ({ startDate, endDate }) => {
            if (startDate != null) {
                this.startDate = moment(startDate)
                    .utcOffset(this.timeZone)
                    .startOf('day');
            }
            if (endDate != null) {
                this.endDate = moment(endDate)
                    .utcOffset(this.timeZone)
                    .startOf('day');
            }
            this.setContents({
                isValidStartDate: this.isValidStartDate(),
                isValidEndDate: this.isValidEndDate()
            });
            this.checkSubmittable();
        };

        const startDateText = this.intl.formatMessage({ id: `user.placeHolder.approval.exceptPcControl.startDate` });
        const endDateText = this.intl.formatMessage({ id: `user.placeHolder.approval.exceptPcControl.endDate` });

        return (
            <div className="dt-width-120">
                <dl className="row-list">
                    <dt>
                        <FormattedMessage id="user.text.approval.exceptPcControl.period" />
                    </dt>
                    <dd>
                        <DateRangePickerWrapper
                            startDate={this.startDate}
                            initialStartDate={this.startDate}
                            startDateId="approvalStartDate"
                            startDatePlaceholderText={startDateText}
                            endDate={this.endDate}
                            initialEndDate={this.endDate}
                            endDateId="approvalEndDate"
                            endDatePlaceholderText={endDateText}
                            onDatesChange={onDatesChange}
                            isOutsideRange={() => false}
                            minimumNights={1}
                            minDate={this.minDate}
                        />
                        {!isValidStartDate && (
                            <div className="input-remark">
                                <div className="err-msg">{!isValidStartDate && <FormattedMessage id="user.guideText.approval.exceptPcControl.checkDate" />}</div>
                            </div>
                        )}
                        <div className="input-remark">
                            <div className="err-msg">
                                <FormattedMessage id="user.guideText.approval.exceptPcControl.maxDate" />
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
        );
    }

    hasContent() {
        return this.isViewMode();
    }

    contentBuilder() {
        if (!this.isViewMode) return <></>;

        return (
            <div
                style={{
                    margin: '0',
                    padding: '0',
                    boxSizing: 'content-box'
                }}>
                <table width="672" cellSpacing="0" cellPadding="0" border="0">
                    <tbody>
                        <tr>
                            <td
                                height="48"
                                style={{
                                    verticalAlign: 'top'
                                }}>
                                <h2
                                    style={{
                                        margin: '13px 0 0',
                                        padding: '0',
                                        fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: '#1a1a1a',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        wordBreak: 'break-all',
                                        verticalAlign: 'top'
                                    }}>
                                    <FormattedMessage id="user.title.approval.exceptPcControl" />
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td
                                height="2"
                                style={{
                                    background: '#000'
                                }}
                            />
                        </tr>
                        <tr>
                            <td>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    border="0"
                                    style={{
                                        width: '100%'
                                    }}>
                                    <tbody>
                                        <tr>
                                            <th
                                                width="50"
                                                style={{
                                                    padding: '12px 20px',
                                                    fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                                    fontSize: '13px',
                                                    color: '#666',
                                                    lineHeight: '22px',
                                                    textAlign: 'left',
                                                    background: '#fafafa',
                                                    wordBreak: 'break-all',
                                                    verticalAlign: 'top',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                <FormattedMessage id="user.text.approval.exceptPcControl.period" />
                                            </th>
                                            <td
                                                style={{
                                                    padding: '12px 20px',
                                                    fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                                    fontSize: '13px',
                                                    color: '#1a1a1a',
                                                    lineHeight: '22px',
                                                    textAlign: 'left',
                                                    wordBreak: 'break-all',
                                                    verticalAlign: 'top'
                                                }}>
                                                {_.concat(moment(this.startDate).format('YYYY-MM-DD'), ' ~ ', moment(this.endDate).format('YYYY-MM-DD'))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td
                                height="2"
                                style={{
                                    background: '#000'
                                }}
                            />
                        </tr>
                        <tr>
                            <td height="13" />
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    isSubmittable() {
        return super.isSubmittable() && this.startDate != null && this.endDate != null && this.isValidStartDate() && this.isValidEndDate();
    }

    submitAction() {
        const targetUsers = ApprovalUtil.convertLineToTargetUsers(this.getLine());
        const startDate = this.startDate.format('YYYY-MM-DD');
        const endDate = this.endDate.format('YYYY-MM-DD');
        const params = {
            approvers: targetUsers,
            searchTgt: SEARCH_TGT,
            startDt: startDate,
            endDt: endDate,
            reason: this.draftOpinion
        };

        return UserRequestRest.request(REQUEST_TYPE, params);
    }

    processResultMessage(response = {}) {
        const { message } = response;
        if (message === 'REQ_PCCONTROL_ALREADY_IN_EXCEPT') {
            modalAlert('user.alert.approval.exceptPcControl.alreadyReq');
            return true;
        }
        if (message === 'REQ_PCCONTROL_DATE_INVALID') {
            toast('user.guideText.approval.exceptPcControl.maxDate');
        }
        return false;
    }
}

export default ExceptPcControl;
