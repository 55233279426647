import React from 'react';
import DriveBody from '../containers/DriveBody';
import DriveHeader from '../containers/DriveHeader';
import Workgroup from '../../utils/DriveConfiguration/Workgroup';

const WorkgroupFiles = props => {
    const pageClass = Workgroup;
    return (
        <div className="page-drv">
            <DriveHeader {...props} type="workgroup" pageClass={pageClass} />
            <DriveBody {...props} pageClass={pageClass} />
        </div>
    );
};

export default WorkgroupFiles;
