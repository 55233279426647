import React from 'react';
import PropTypes from 'prop-types';
import FileReload from '../../../../utils/FileReload';
import MenuSupporter from '../../../../utils/FileMenu/MenuSupporter';
import { withFilesContext } from '../DriveFilesContext';
import { getUrlPrefix } from '../../../../utils/Url';
import SearchFileName from '../Column/SearchFileName';
import SearchChangedDate from '../Column/SearchChangedDate';
import SearchSize from '../Column/SearchSize';
import SearchPath from '../Column/SearchPath';
import Option from '../Column/Option';
import SearchContents from '../Column/SearchContents';

const ROOT_FOLDER_ID = {
    WORKGROUP: '111200000000000000',
    SHARED: '111300000000000000'
};

class SearchRow extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hover: false // 선택 상태의 css class 적용 여부
        };

        const {
            context: {
                props: { pageClass }
            }
        } = props;
        this.pageClass = pageClass;
    }

    isExplorerConstraints = () => {
        const { file = {} } = this.props;
        const { extra: { pathVals: filePaths = [] } = {}, drive: { objtSectCd = '' } = {} } = file;
        const fileRootFolder = filePaths.length ? filePaths[0] : {};

        let isExplorerConstraintsValue = false;
        // Shared 1depth 인경우, 이동, 이름변경 제약.
        if (ROOT_FOLDER_ID.SHARED === fileRootFolder.objtId) {
            if (objtSectCd === 'FILE') {
                isExplorerConstraintsValue = filePaths.length === 1;
            } else if (objtSectCd === 'FOLDER') {
                isExplorerConstraintsValue = filePaths.length === 2;
            } else {
                isExplorerConstraintsValue = true;
            }
        }
        return isExplorerConstraintsValue;
    };

    fileIconCustom = {
        statusCallback: isOpen => {
            this.setState({ hover: isOpen });
        },
        openType: 'normal_left',
        isExplorerConstraintsValue: this.isExplorerConstraints()
    };

    handleOnClick = () => {
        const {
            context: {
                props: { setDetailFile }
            },
            file = {}
        } = this.props;
        setDetailFile(file);
    };

    handleOnContextMenu = event => {
        const {
            context: {
                props: { pageClass }
            },
            file = {}
        } = this.props;
        const {
            menu: {
                row: { menuGroupType }
            }
        } = pageClass;

        MenuSupporter.open(menuGroupType, [file], FileReload.call, 'normal', event, this.fileIconCustom.statusCallback, { ...this.fileIconCustom, pageId: this.pageClass.pageId });
        event.preventDefault();
    };

    goToFolder = (pathVals, idx, file) => {
        const {
            context: {
                props: { history }
            }
        } = this.props;

        const prefix = getUrlPrefix(file);

        if (idx === 0) {
            history.push(`${prefix}`);
        } else {
            history.push(`${prefix}/${pathVals[idx].objtId}`, { onpstId: pathVals[idx].onpstId });
        }
    };

    render() {
        const {
            file,
            style,
            context: {
                props: {
                    pageClass: {
                        menu: {
                            row: { iconGroupType }
                        }
                    }
                }
            }
        } = this.props;

        const { drive, extra = {} } = file;
        const { matchedContents = [] } = extra;
        const { hover } = this.state;

        // 검색어 마크는 마크태그로
        return file ? (
            <div
                className={`grid-row ${hover ? ' is-hovered ' : ''}${drive.objtSectCd === 'FOLDER' ? ' folder-row ' : ''}`}
                style={style}
                onClick={this.handleOnClick}
                onContextMenu={this.handleOnContextMenu}
                role="button">
                <div className="grid-cell files-info-cell">
                    <SearchFileName file={file} />
                    {matchedContents.length > 0 && <SearchContents file={file} />}
                    <ul className="li-divider-list">
                        <SearchChangedDate file={file} />
                        <SearchSize file={file} />
                    </ul>
                    <SearchPath file={file} goToFolder={this.goToFolder} />
                </div>
                <Option groupType={iconGroupType} file={file} fileIconCustom={this.fileIconCustom} />
            </div>
        ) : null;
    }
}

SearchRow.propTypes = {
    context: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired
};

SearchRow.displayName = 'SearchRow';
export default withFilesContext(SearchRow, []);
