import React, { Component } from 'react';
import { ConnectedSettingsContainer } from '../presentationals/Settings/SettingsContainer';

class Settings extends Component {
    componentDidMount() {
        document.body.classList.remove('scroll-hidden');
        this.updateFixedContainerWidthInterval = setInterval(() => {
            this.updateFixedContainerWidth();
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(this.updateFixedContainerWidthInterval);
        this.updateFixedContainerWidthInterval = null;
        document.body.classList.add('scroll-hidden');
    }

    updateFixedContainerWidth = () => {
        window.$('.fixed-container').each((index, value) => {
            const fixedWidth = window
                .$(value)
                .parent('.js-for-fixed')
                .width();
            window.$(value).width(fixedWidth);
        });
    };

    render() {
        return <ConnectedSettingsContainer />;
    }
}

export default Settings;
