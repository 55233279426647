import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { includes, last, indexOf, filter } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomList from '../../../presentationals/List/CustomList';
import FoldersRest from '../../../../apis/FoldersRest';
import { executor as createFolderPop } from '../../../presentationals/FileMenu/Menu/CreateFolder';
import CONFIG from './FolderSelectConfig';
import SelectableRow from './SelectableRow';
import Breadcrumb from '../../../presentationals/Breadcrumb';
import { isOffWorkSite, SITE_MODE } from '../../../../constants/SiteMode';

const MAIN = { drive: { objtNm: '위치', objtId: 'root' }, actionPolicy: { create: false } };
const ALL = { drive: { icon: 'ic-20-folder', objtId: 'all', objtNm: 'ALL' }, actionPolicy: { create: false } };
const OWN = { drive: { icon: 'ic-20-user', objtId: 'own', objtNm: 'My files' }, actionPolicy: { create: true } };
const SHARE = { drive: { icon: 'ic-20-share-group', objtId: 'shared', objtNm: 'Shared files' }, actionPolicy: { create: false } };
const SHARING = { drive: { icon: 'ic-20-share-group', objtId: 'sharing', objtNm: 'Sharing files' }, actionPolicy: { create: false } };
const WORKGROUP = { drive: { icon: 'ic-20-workgroups', objtId: 'workgroup', objtNm: 'Workgroups' }, actionPolicy: { create: false } };

const isMain = objtId => objtId === MAIN.drive.objtId;

const isRoot = objtId => includes(['all', 'own', 'shared', 'sharing', 'workgroup'], objtId);

const getRealObjtId = ({ objtId }) => (isRoot(objtId) ? '111100000000000000' : objtId);

const getPreSelectedFolderId = ({ drive = {} }) => {
    const { objtId } = drive;
    if (objtId === '111100000000000000') return 'own';
    if (objtId === '111200000000000000') return 'workgroup';
    if (objtId === '111300000000000000') return 'shared';
    if (objtId === '111400000000000000') return 'sharing';
    return objtId;
};

const dataToRowItem = item => {
    const defaultIcon = 'ic-file-sm ic-file-FOLDER';
    const { actionPolicy, maxAuthCd } = item;
    const { objtId, objtNm, onpstId, icon = defaultIcon } = item.drive;
    return { objtId, objtNm, onpstId, icon, actionPolicy, maxAuthCd };
};

const getRootBreadItemForCopyAndMove = rootFolderId => {
    const rootFolderInfo = {
        '111100000000000000': OWN,
        '111200000000000000': WORKGROUP,
        '111300000000000000': SHARE,
        '111400000000000000': SHARING
    };

    if (!rootFolderInfo[rootFolderId]) {
        console.error('Unknown root folder');
        return null;
    }

    return dataToRowItem(rootFolderInfo[rootFolderId]);
};

const makeBreadcrumbData = (mode, folder) => {
    // main 화면
    const breadcrumb = [dataToRowItem(MAIN)];
    const isZeroDepth = !folder.drive || folder.drive.onpstFolderId === '111000000000000000';
    if (isZeroDepth) return breadcrumb;

    // root 화면. 향후 폴더 정보로만 브레드크럼 만들 수 있도록 수정 필요
    const {
        drive: { pathVal, pathValNm, onpstId, onpstFolderId },
        actionPolicy,
        rootFolderId
    } = folder;

    let rootBreadItem = null;

    switch (mode) {
        case 'sharedOnly':
            rootBreadItem = dataToRowItem(SHARE);
            break;
        case 'sharingOnly':
            rootBreadItem = dataToRowItem(SHARING);
            break;
        case 'workgroupOnly':
            rootBreadItem = dataToRowItem(WORKGROUP);
            break;
        case 'copy':
        case 'move':
            rootBreadItem = getRootBreadItemForCopyAndMove(rootFolderId);
            break;
        default:
            rootBreadItem = dataToRowItem(OWN);
    }

    if (rootBreadItem) breadcrumb.push(rootBreadItem);
    const isOneDepth = includes(['111100000000000000', '111200000000000000', '111300000000000000', '111400000000000000'], onpstFolderId);
    if (isOneDepth || !pathVal || !pathValNm) return breadcrumb;

    // root 하위 화면
    const pathValList = pathVal.substring(1, pathVal.lastIndexOf('/')).split('/');
    const pathValNmList = pathValNm.substring(1, pathValNm.lastIndexOf('/')).split('/');
    // 휴지통 복원 요건이라 actionPolicy 이렇게 써도 됨... 나중에 다른 요건 들어오면 통째로 바꿔야 됨...
    return breadcrumb.concat(pathValList.map((path, idx) => ({ objtId: path, objtNm: pathValNmList[idx], onpstId, actionPolicy })));
};

class FolderSelectPopup extends Component {
    constructor(props) {
        super(props);
        this.setConstantLang();

        this.state = {
            breadcrumb: [{}],
            list: [],
            selectedFolder: {}
        };

        this.rootFolders = this.getRootFolders(props.mode);
        const { beforeSave, api, newFolder } = CONFIG(props.mode);
        this.action = { beforeSave, api };
        this.useNewFolder = newFolder;
    }

    componentDidMount() {
        this.setDefaultState();
    }

    setConstantLang() {
        const { intl } = this.props;
        MAIN.drive.objtNm = intl.formatMessage({ id: 'drive.text.search.filter.path' });
        ALL.drive.objtNm = intl.formatMessage({ id: 'drive.text.selectFolderPop.all' });
        OWN.drive.objtNm = intl.formatMessage({ id: 'user.gnb.myFile' });
        SHARE.drive.objtNm = intl.formatMessage({ id: 'user.gnb.sharedToMe' });
        SHARING.drive.objtNm = intl.formatMessage({ id: 'user.gnb.sharedByMe' });
        WORKGROUP.drive.objtNm = intl.formatMessage({ id: 'user.tab.gnb.workgroup' });
    }

    getRootFolders = mode => {
        const { siteMode } = this.props;

        if (includes(['copy', 'move', 'favorite', 'search'], mode)) {
            // offlineWg 요건에 따른 워크그룹 외 루트폴더 미노출
            return isOffWorkSite(siteMode) ? { list: [WORKGROUP] } : { list: [OWN, SHARE, SHARING, WORKGROUP] };
        }

        if (mode === 'ownOnly') return { list: [OWN] };
        if (mode === 'sharedOnly') return { list: [SHARE] };
        if (mode === 'sharingOnly') return { list: [SHARING] };
        if (mode === 'workgroupOnly') return { list: [WORKGROUP] };
        return { list: [ALL, OWN, SHARE, WORKGROUP] };
    };

    async getFolders(objtId, onpstId, maxAuthCd) {
        // main page (0 depth) 일 경우
        if (isMain(objtId)) return this.rootFolders;

        // let api;
        let data;
        const param = { objtSectCd: 'FOLDER', onpstId, sortField: 'objtNm', sortOrder: 'ASC' };

        // 1 depth 폴더 조회
        if (isRoot(objtId)) {
            data = await FoldersRest.getFolders(objtId, param);
        }
        // 2 depth 폴더 조회
        else if (objtId === onpstId && maxAuthCd === 'NONE') {
            data = await FoldersRest.getFolders('workgroup', param);
        } else {
            data = await FoldersRest.getFolder(objtId, param);
        }
        return data;
    }

    breadMove = (id, item) => this.move(item);

    move = async item => {
        if (item !== ALL) {
            const { breadcrumb } = this.state;
            let newBreadcrumb = [...breadcrumb];
            const curIdx = indexOf(newBreadcrumb, item);
            if (curIdx < 0) newBreadcrumb.push(item);
            else newBreadcrumb = newBreadcrumb.slice(0, curIdx + 1);

            const { objtId, onpstId, maxAuthCd } = last(newBreadcrumb);
            const folders = await this.getFolders(objtId, onpstId, maxAuthCd);
            const list = this.convertData(folders);
            const selectedFolder = item.objtId === 'root' ? {} : item;
            this.setState({
                breadcrumb: newBreadcrumb,
                list,
                selectedFolder
            });
        }
    };

    setDefaultState = async () => {
        const { mode, preSelectedFolder } = this.props;
        const preSelectedFolderId = getPreSelectedFolderId(preSelectedFolder);
        const breadcrumb = makeBreadcrumbData(mode, preSelectedFolder);
        const currentFolder = last(breadcrumb);
        const folders = await this.getFolders(currentFolder.objtId, currentFolder.onpstId, currentFolder.maxAuthCd);
        const list = this.convertData(folders);
        const selectedFolder = preSelectedFolderId ? list.find(item => item.objtId === preSelectedFolderId) || {} : {};
        if (selectedFolder.objtId) selectedFolder.selected = true;
        this.setState({ breadcrumb, list, selectedFolder });
    };

    convertDataForDeletedWorkgroupRestore(list) {
        const { preSelectedFolder } = this.props;
        const { drive } = preSelectedFolder;
        return filter(list, item => {
            return item.drive.onpstId === drive.onpstId || item.drive.objtId === 'workgroup';
        });
    }

    convertData(data) {
        const { mode } = this.props;
        const { list } = data;
        let filteredList = list;
        // 워크그룹 휴지통 복원시 ....
        if (mode === 'workgroupOnly') filteredList = this.convertDataForDeletedWorkgroupRestore(list);

        return filteredList.map(dataToRowItem);
    }

    select = selectedItem => {
        const { list } = this.state;
        const newList = list.map(item => {
            if (item === selectedItem) return { ...item, selected: true };
            if (item.selected) return { ...item, selected: false };
            return item;
        });
        this.setState({
            list: newList,
            selectedFolder: selectedItem
        });
    };

    afterCreateFolder = async ({ newFolder }) => {
        const { breadcrumb } = this.state;
        const currentFolder = last(breadcrumb);
        if (newFolder) {
            const folders = await this.getFolders(currentFolder.objtId, currentFolder.onpstId, currentFolder.maxAuthCd);
            const list = this.convertData(folders);
            const createdFolder = list.find(item => item.objtId === newFolder.objtId) || {};
            this.setState({ list }, () => this.select(createdFolder));
        }
    };

    openCreateFolderPop = () => {
        const { breadcrumb } = this.state;
        const currentFolder = last(breadcrumb);
        const objtId = getRealObjtId(currentFolder);
        const props = {
            custom: {
                folder: { objtId }
            },
            callback: this.afterCreateFolder
        };
        createFolderPop(props);
    };

    save = () => {
        const { close, files } = this.props;
        const { selectedFolder } = this.state;
        const { beforeSave, api } = this.action;
        beforeSave(selectedFolder)
            .then(() => api(selectedFolder, files))
            .then(data => close(data), () => false);
    };

    rowBuilder = ({ style, index }) => {
        const { list } = this.state;
        return <SelectableRow item={list[index]} move={this.move} select={this.select} style={style} />;
    };

    render() {
        const { close, intl } = this.props;
        const { list, breadcrumb, selectedFolder } = this.state;
        const currentFolder = last(breadcrumb);
        const { actionPolicy } = currentFolder;
        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'drive.title.selectFolderPop.header' })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => close()}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className="comm-func-bar">
                        <Breadcrumb data={breadcrumb} firstRange={2} lastRange={2} move={this.breadMove} />
                    </div>
                    <div className="pop-grid-list row-line-type">
                        {list.length > 0 && (
                            <div className="comm-grid-list">
                                <CustomList className="grid-row-group" customScroll={true} virtualized={true} height={368} rowCount={list.length} rowHeight={46} rowBuilder={this.rowBuilder} />
                            </div>
                        )}
                        {list.length === 0 && (
                            <div className="box-empty row-8">
                                <div className="message">
                                    <p>{intl.formatMessage({ id: 'drive.guideText.selectFolderPop.empty' })}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    {this.useNewFolder && actionPolicy && actionPolicy.create && (
                        <div className="modal-footer-option">
                            <a className="btn btn-lg btn-secondary" role="button" onClick={this.openCreateFolderPop}>
                                <span className="btn-text">{intl.formatMessage({ id: 'drive.button.list.menu.createFolder' })}</span>
                            </a>
                        </div>
                    )}
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => close()}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${selectedFolder.objtId ? '' : 'disabled'}`} role="button" onClick={this.save}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.check' })}</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

FolderSelectPopup.propTypes = {
    close: PropTypes.func,
    mode: PropTypes.string,
    files: PropTypes.array,
    preSelectedFolder: PropTypes.object,
    intl: PropTypes.object,
    siteMode: PropTypes.string
};

FolderSelectPopup.defaultProps = {
    close: () => {},
    mode: 'default',
    files: [],
    preSelectedFolder: {},
    intl: {},
    siteMode: SITE_MODE.unknown
};

const mapStateToProps = state => ({
    siteMode: state.site.siteMode
});

export default injectIntl(connect(mapStateToProps)(FolderSelectPopup));
