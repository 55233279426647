import $http from 'modules/Http';

const PREFIX_AUTH = '/auth/v1/users/request';
const PREFIX_COMMON = '/common/v1/req';

class SecureModeRequestRest {
    retreiveApprover(type, searchTgt) {
        return $http.get(`${PREFIX_AUTH}/${type}/approver`, { params: { searchTgt } }).then(response => {
            const { data } = response;
            return data;
        });
    }

    create(type, params) {
        return $http.post(`${PREFIX_AUTH}/${type}`, params);
    }

    retrieveDetail(reqId) {
        return $http.get(`${PREFIX_COMMON}/${reqId}`).then(response => {
            const { data } = response;
            return data;
        });
    }

    getRequestSystemType(type = 'SECUREMODE') {
        return $http.get(`${PREFIX_AUTH}/${type}/system`).then(response => response, error => error);
    }
}

export default new SecureModeRequestRest();
