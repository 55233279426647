import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const FilterKeyword = props => {
    const { children, nameKey, id, value = '', onChange, placeholderKey, intl, guideKey } = props;

    const [focus, setFocus] = React.useState(false);
    const MAX_LENGTH = 100;

    return (
        <dl className="row-list">
            <dt>
                <FormattedMessage id={nameKey} />
            </dt>
            <dd>
                <div className={`box-input-group ${focus && 'is-focused'}`}>
                    {children}
                    <input
                        className="form-control"
                        type="text"
                        placeholder={intl.formatMessage({ id: placeholderKey })}
                        value={value}
                        onChange={e => onChange({ [id]: e.target.value })}
                        onFocus={() => setFocus(true)}
                        onBlur={e => {
                            e.target.value.trim();
                            setFocus(false);
                        }}
                        maxLength={MAX_LENGTH}
                    />
                    <a className="btn-ic-nor btn-func-clear" role="button" title="지우기" onClick={() => onChange({ [id]: '' })}>
                        <i className="ic-16-clear" />
                    </a>
                </div>
                {guideKey && (
                    <div className="info-msg">
                        <FormattedMessage id={guideKey} />
                    </div>
                )}
            </dd>
        </dl>
    );
};

FilterKeyword.defaultProps = {
    children: undefined,
    value: undefined,
    guideKey: null
};

FilterKeyword.propTypes = {
    children: PropTypes.node,
    nameKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholderKey: PropTypes.string.isRequired,
    guideKey: PropTypes.string
};

export default injectIntl(FilterKeyword);
