import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DateConverter from '../../../../utils/DateConverter';

class OutLinkItem extends Component {
    constructor(props) {
        super(props);
        this.resource = {};
    }

    render() {
        const { outLink, newOutLinkId, style, onCopyUrl, onRemove, intl } = this.props;
        const { outLinkId, outLinkFileVerSno, outLinkExpireDt, outLinkDownloadLmtCnt, outLinkPwd, outLinkUrl, outLinkExpiredYn } = outLink;
        let rowStyle = '';

        if (outLinkId === newOutLinkId) {
            rowStyle = ' new-row';
        } else if (outLinkExpiredYn === 'Y') {
            rowStyle = ' expired-row';
        }

        const expireDt = intl.formatMessage({ id: 'drive.text.outLink.expireDt' });
        const expireDtUnlimited = intl.formatMessage({ id: 'drive.text.outLink.expireDt.unlimited' });
        const linkCopy = intl.formatMessage({ id: 'drive.button.outLink.copy' });
        const linkRemove = intl.formatMessage({ id: 'drive.button.outLink.remove' });

        return (
            <div className={`grid-row${rowStyle}`} style={style} key={outLinkId}>
                <div className="grid-cell version-cell">
                    <strong title="">
                        <FormattedMessage id="drive.text.outLink.version" /> {outLinkFileVerSno}
                    </strong>
                </div>
                <div className="grid-cell link-cell">
                    <a className="link-url" href={outLinkUrl} title={outLinkUrl}>
                        {outLinkUrl}
                    </a>
                    <ul className="li-divider-list">
                        <li>
                            <span>{outLinkExpireDt ? `${expireDt} ${DateConverter.convertExpireDtm(outLinkExpireDt)}` : expireDtUnlimited}</span>
                        </li>
                        <li>
                            <span>
                                {outLinkDownloadLmtCnt ? (
                                    <FormattedMessage id="drive.alert.outLink.down.limit" values={{ outLinkDownloadLmtCnt }} />
                                ) : (
                                    <FormattedMessage id="drive.text.outLink.down.unlimited" />
                                )}
                            </span>
                        </li>
                        <li>
                            <span>{outLinkPwd ? <FormattedMessage id="drive.text.outLink.pwd.exist" /> : <FormattedMessage id="drive.alert.outLink.pwd.notExist" />}</span>
                        </li>
                    </ul>
                </div>
                <div className="grid-cell option-cell">
                    <ul className="item-option nav" style={{ visibility: outLinkExpiredYn === 'Y' ? 'hidden' : '' }}>
                        <li className="option-copyfile">
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={linkCopy}
                                onClick={e => {
                                    onCopyUrl(outLink);
                                    e.stopPropagation();
                                }}>
                                <i className="ic-20-copyfile" />
                            </a>
                        </li>
                        <li className="option-delete">
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={linkRemove}
                                onClick={e => {
                                    onRemove(outLink);
                                    e.stopPropagation();
                                }}>
                                <i className="ic-20-delete" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

OutLinkItem.propTypes = {
    outLink: PropTypes.object,
    newOutLinkId: PropTypes.string,
    onCopyUrl: PropTypes.func,
    onRemove: PropTypes.func,
    intl: PropTypes.object,
    style: PropTypes.object
};

OutLinkItem.defaultProps = {
    outLink: {},
    newOutLinkId: '',
    onCopyUrl: () => {},
    onRemove: () => {},
    intl: {},
    style: {}
};

export default injectIntl(OutLinkItem);
