/* eslint-disable */
import 'react-dates/initialize';
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

class CommonDatePickerWrapper extends React.Component {
    constructor(props) {
        super(props);

        const date = !!props.initialDate && moment.isMoment(props.initialDate) ? props.initialDate : moment();
        this.state = {
            selYear: Number(date.format('YYYY')),
            selMonth: Number(date.format('MM')),
            nextFocusingYear: Number(date.format('YYYY')),
            nextFocusingMonth: Number(date.format('MM'))
        };
    }

    /**
     * 콤보박스 년월 변경
     * @param month - 직전 선택된 연월(moment)
     * @param targetYear - 출력 대상 년도
     * @param targetMonth - 출력 대상 월(월 - 1)
     * @param func - 연월 변경 함수
     */
    changeMonthOrYear = (month, targetYear, targetMonth, func) => {
        const { minDate, maxDate } = this.props;
        const curYear = Number(moment().format('YYYY'));
        const curMonth = Number(moment().format('MM'));
        const prevSelYear = Number(moment(month).format('YYYY'));
        const prevSelMonth = Number(moment(month).format('MM'));
        const minYear = minDate === null ? curYear - 10 : Number(moment(minDate).format('YYYY'));
        const maxYear = maxDate === null ? curYear + 10 : Number(moment(maxDate).format('YYYY'));
        const minMonth = minDate === null ? curMonth : Number(moment(minDate).format('MM'));
        const maxMonth = maxDate === null ? curMonth : Number(moment(maxDate).format('MM'));

        let tmpMonth = {};
        let curSelYear = null;
        let curSelMonth = null;
        if (targetYear !== null && targetMonth === null) {
            curSelYear = Number(targetYear);
            curSelMonth = prevSelMonth;
            if (curSelYear === minYear && curSelMonth < minMonth) {
                tmpMonth =
                    minDate === null
                        ? month.month(curMonth - 1)
                        : month.month(
                              Number(
                                  moment(minDate)
                                      .add(-1, 'months')
                                      .format('MM')
                              )
                          );
                func(tmpMonth, targetYear);
            } else if (curSelYear === maxYear && curSelMonth > maxMonth) {
                tmpMonth =
                    maxDate === null
                        ? month.month(curMonth - 1)
                        : month.month(
                              Number(
                                  moment(maxDate)
                                      .add(-1, 'months')
                                      .format('MM')
                              )
                          );
                func(tmpMonth, targetYear);
            } else {
                func(month, targetYear);
            }
        } else if (targetMonth !== null && targetYear === null) {
            curSelYear = prevSelYear;
            curSelMonth = Number(targetMonth) + 1;
            if (curSelYear === minYear && curSelMonth < minMonth) {
                tmpMonth =
                    minDate === null
                        ? month.month(curMonth - 1)
                        : month.month(
                              Number(
                                  moment(minDate)
                                      .add(-1, 'months')
                                      .format('MM')
                              )
                          );
                func(tmpMonth, targetMonth);
            } else if (curSelYear === maxYear && curSelMonth > maxMonth) {
                tmpMonth =
                    maxDate === null
                        ? month.month(curMonth - 1)
                        : month.month(
                              Number(
                                  moment(maxDate)
                                      .add(-1, 'months')
                                      .format('MM')
                              )
                          );
                func(tmpMonth, targetMonth);
            } else {
                func(month, targetMonth);
            }
        }

        // 월이 변경되었다면
        if (tmpMonth && moment.isMoment(tmpMonth)) {
            curSelMonth = Number(tmpMonth.format('MM'));
        }

        this.setState({
            selYear: curSelYear,
            selMonth: curSelMonth,
            direction: 'UNKNOWN'
        });

        this.renderPrevArrow(curSelYear, curSelMonth);
        this.renderNextArrow(curSelYear, curSelMonth);
    };

    renderMonthElement = ({ month, onMonthSelect, onYearSelect, isVisible }) => {
        const { minDate, maxDate } = this.props;
        if (isVisible) {
            const curYear = Number(moment().format('YYYY'));
            const curMonth = Number(moment().format('MM'));
            const maxYear = maxDate === null ? curYear + 10 : Number(moment(maxDate).format('YYYY'));
            const maxMonth = maxDate === null ? curMonth : Number(moment(maxDate).format('MM'));

            let yearList = [];
            for (let i = Number(minDate.format('YYYY')); i <= curYear + (maxYear - curYear); i += 1) {
                yearList = yearList.concat({ key: i, message: i });
            }

            let monthList = [];
            for (let i = 1; i <= 12; i += 1) {
                monthList = monthList.concat({ key: i, message: i });
            }

            const selYear = Number(moment(month, 'YYYY-MM-DD').format('YYYY'));
            const selMonth = Number(moment(month, 'YYYY-MM-DD').format('MM'));

            return (
                <>
                    <select id="filterDateYear" onChange={e => this.changeMonthOrYear(month, e.target.value, null, onYearSelect)} style={{ cursor: 'pointer' }}>
                        {yearList.map(item => {
                            if (item.key === selYear) {
                                return (
                                    <option value={item.key} key={`${item.key} ${item.message}`} selected={true}>
                                        {_.isEmpty(item.message) ? item.key : item.message}
                                    </option>
                                );
                            }

                            return (
                                <option value={item.key} key={`${item.key} ${item.message}`}>
                                    {_.isEmpty(item.message) ? item.key : item.message}
                                </option>
                            );
                        })}
                    </select>
                    <select id="filterDateMonth" onChange={e => this.changeMonthOrYear(month, null, String(Number(e.target.value) - 1), onMonthSelect)} style={{ cursor: 'pointer' }}>
                        {monthList.map(item => {
                            if (item.key === selMonth) {
                                return (
                                    <option value={item.key} key={`${item.key} ${item.message}`} selected={true}>
                                        {_.isEmpty(item.message) ? item.key : item.message}
                                    </option>
                                );
                            }

                            const minYear = minDate === null ? curYear - 10 : Number(moment(minDate).format('YYYY'));
                            const minMonth = minDate === null ? curMonth : Number(moment(minDate).format('MM'));
                            let isDisable = false;
                            let monthColor = '#666666';

                            if ((minYear === selYear && minMonth > item.key) || (maxYear === selYear && maxMonth < item.key)) {
                                isDisable = true;
                                monthColor = '#dddddd';
                            }

                            return (
                                <option value={item.key} key={`${item.key} ${item.message}`} disabled={isDisable} style={{ color: monthColor }}>
                                    {_.isEmpty(item.message) ? item.key : item.message}
                                </option>
                            );
                        })}
                    </select>
                </>
            );
        }

        return <></>;
    };

    minusCurDate = (curYear, curMonth) => {
        const tgtDate = moment()
            .year(curYear)
            .month(curMonth - 2);
        this.setState({
            selYear: Number(tgtDate.format('YYYY')),
            selMonth: Number(tgtDate.format('MM')),
            direction: 'PREVIOUS'
        });
    };

    plusCurDate = (curYear, curMonth) => {
        const tgtDate = moment()
            .year(curYear)
            .month(curMonth);
        this.setState({
            selYear: Number(tgtDate.format('YYYY')),
            selMonth: Number(tgtDate.format('MM')),
            direction: 'NEXT'
        });
    };

    /**
     *
     * @param tgtYear
     * @param tgtMonth
     * @returns {*}
     */
    renderPrevArrow = (tgtYear, tgtMonth) => {
        const { minDate } = this.props;
        const { direction, startDate } = this.state;

        const tmpMinDate = minDate === null ? moment() : minDate;
        const minYear = Number(moment(tmpMinDate).format('YYYY'));
        const minMonth = Number(moment(tmpMinDate).format('MM'));

        let curSelectedYear;
        let curSelectedMonth;
        let tgtSelectedYear;
        let tgtSelectedMonth;
        if (document.getElementById('filterDateYear') === null && document.getElementById('filterDateMonth') === null) {
            const stdDate = startDate ? startDate : moment();
            curSelectedYear = !!tgtYear && tgtYear > 0 ? tgtYear : Number(stdDate.format('YYYY'));
            curSelectedMonth = !!tgtMonth && tgtMonth > 0 ? tgtMonth : Number(stdDate.format('MM'));
            tgtSelectedYear = !!tgtYear && tgtYear > 0 ? tgtYear : Number(stdDate.format('YYYY'));
            tgtSelectedMonth = !!tgtMonth && tgtMonth > 0 ? tgtMonth : Number(stdDate.format('MM'));
        } else {
            curSelectedYear = Number(document.getElementById('filterDateYear').options[document.getElementById('filterDateYear').options.selectedIndex].value);
            curSelectedMonth = Number(document.getElementById('filterDateMonth').options[document.getElementById('filterDateMonth').options.selectedIndex].value);

            let tgtDate;
            if (direction === 'NEXT') {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth);
            } else if (direction === 'UNKNOWN') {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth - 1);
            } else {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth - 2);
            }

            tgtSelectedYear = Number(tgtDate.format('YYYY'));
            tgtSelectedMonth = Number(tgtDate.format('MM'));
        }

        const curSelectedYearMonth = moment()
            .year(curSelectedYear)
            .month(curSelectedMonth)
            .add(-1, 'month');
        const minYearMonth = moment()
            .year(minYear)
            .month(minMonth)
            .add(-1, 'month');
        const tgtSelectedYearMonth = moment()
            .year(tgtSelectedYear)
            .month(tgtSelectedMonth)
            .add(-1, 'month');
        const tgtYearMonth = moment()
            .year(tgtYear)
            .month(tgtMonth)
            .add(-1, 'month');

        if (
            (direction !== 'NEXT' && direction !== 'PREVIOUS' && curSelectedYearMonth.isSameOrBefore(minYearMonth) && tgtYearMonth.isSameOrBefore(minYearMonth)) ||
            ((direction === 'NEXT' || direction === 'PREVIOUS') && tgtSelectedYearMonth.isSameOrBefore(minYearMonth))
        ) {
            return <></>;
        }

        return (
            <div
                role="button"
                tabIndex="0"
                className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
                aria-label="Move backward to switch to the previous month."
                onClick={() => this.minusCurDate(curSelectedYear, curSelectedMonth)}>
                <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
                    <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z" />
                </svg>
            </div>
        );
    };

    /**
     *
     * @param tgtYear
     * @param tgtMonth
     * @returns {*}
     */
    renderNextArrow = (tgtYear, tgtMonth) => {
        const { maxDate } = this.props;
        const { direction, startDate } = this.state;

        const tmpMaxDate = maxDate === null ? moment().add(10, 'years') : maxDate;
        const maxYear = Number(moment(tmpMaxDate).format('YYYY'));
        const maxMonth = Number(moment(tmpMaxDate).format('MM'));

        let curSelectedYear;
        let curSelectedMonth;
        let tgtSelectedYear;
        let tgtSelectedMonth;
        if (document.getElementById('filterDateYear') === null && document.getElementById('filterDateMonth') === null) {
            const stdDate = startDate ? startDate : moment();
            curSelectedYear = !!tgtYear && tgtYear > 0 ? tgtYear : Number(stdDate.format('YYYY'));
            curSelectedMonth = !!tgtMonth && tgtMonth > 0 ? tgtMonth : Number(stdDate.format('MM'));
            tgtSelectedYear = !!tgtYear && tgtYear > 0 ? tgtYear : Number(stdDate.format('YYYY'));
            tgtSelectedMonth = !!tgtMonth && tgtMonth > 0 ? tgtMonth : Number(stdDate.format('MM'));
        } else {
            curSelectedYear = Number(document.getElementById('filterDateYear').options[document.getElementById('filterDateYear').options.selectedIndex].value);
            curSelectedMonth = Number(document.getElementById('filterDateMonth').options[document.getElementById('filterDateMonth').options.selectedIndex].value);

            let tgtDate;
            if (direction === 'NEXT') {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth);
            } else if (direction === 'UNKNOWN') {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth - 1);
            } else {
                tgtDate = moment()
                    .year(curSelectedYear)
                    .month(curSelectedMonth - 2);
            }

            tgtSelectedYear = Number(tgtDate.format('YYYY'));
            tgtSelectedMonth = Number(tgtDate.format('MM'));
        }

        const curSelectedYearMonth = moment()
            .year(curSelectedYear)
            .month(curSelectedMonth)
            .add(-1, 'month');
        const maxYearMonth = moment()
            .year(maxYear)
            .month(maxMonth)
            .add(-1, 'month');
        const tgtSelectedYearMonth = moment()
            .year(tgtSelectedYear)
            .month(tgtSelectedMonth)
            .add(-1, 'month');
        const tgtYearMonth = moment()
            .year(tgtYear)
            .month(tgtMonth)
            .add(-1, 'month');

        if (
            (direction !== 'NEXT' && direction !== 'PREVIOUS' && curSelectedYearMonth.isSameOrAfter(maxYearMonth) && tgtYearMonth.isSameOrAfter(maxYearMonth)) ||
            ((direction === 'NEXT' || direction === 'PREVIOUS') && tgtSelectedYearMonth.isSameOrAfter(maxYearMonth))
        ) {
            return <></>;
        }

        return (
            <div
                role="button"
                tabIndex="0"
                className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
                aria-label="Move forward to switch to the next month."
                onClick={() => this.plusCurDate(curSelectedYear, curSelectedMonth)}>
                <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
                    <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
                </svg>
            </div>
        );
    };
}

const propTypes = {
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj
};
const defaultProps = {
    minDate: null,
    maxDate: null
};

CommonDatePickerWrapper.propTypes = propTypes;
CommonDatePickerWrapper.defaultProps = defaultProps;

export default CommonDatePickerWrapper;
