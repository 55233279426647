import _ from 'lodash';
import produce from 'immer';
import * as ActionTypes from '../actions/GlobalPopup';

const initialState = {
    alert: {
        isOpen: false,
        message: ''
    },
    confirm: {
        isOpen: false,
        message: '',
        callback: _.noop
    },
    changePassword: {
        isOpen: false,
        callback: _.noop
    },
    changeOwner: {
        isOpen: false
    },
    profileEdit: {
        isOpen: false
    },
    teamSearchWithTree: {
        isOpen: false,
        selectedTeam: null
    },
    expirationDate: {
        isOpen: false,
        id: ''
    },
    helpQuestion: {
        isOpen: false
    },
    deptMember: {
        isOpen: false,
        deptId: '',
        deptName: ''
    },
    secureModeRequest: {
        isOpen: false,
        startDate: '',
        endDate: '',
        reason: ''
    }
};

const globalPopup = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.OPEN_ALERT:
            return produce(state, draft => {
                draft.alert.isOpen = true;
                draft.alert.message = action.message;
            });
        case ActionTypes.CLOSE_ALERT:
            return produce(state, draft => {
                draft.alert.isOpen = false;
            });
        case ActionTypes.OPEN_CONFIRM:
            return produce(state, draft => {
                draft.confirm.isOpen = true;
                draft.confirm.message = action.message;
                draft.confirm.callback = action.callback || _.noop;
            });
        case ActionTypes.CLOSE_CONFIRM_AFTER_CALLBACK:
            return produce(state, draft => {
                draft.confirm.isOpen = false;
                draft.confirm.callback = _.noop;
            });
        case ActionTypes.OPEN_CHANGE_PASSWORD:
            return produce(state, draft => {
                draft.changePassword.isOpen = true;
                draft.changePassword.callback = action.callback || _.noop;
            });
        case ActionTypes.CLOSE_CHANGE_PASSWORD_AFTER_CALLBACK:
            return produce(state, draft => {
                draft.changePassword.isOpen = false;
                draft.changePassword.callback = _.noop;
            });
        case ActionTypes.OPEN_CHANGE_OWNER:
            return produce(state, draft => {
                draft.changeOwner.isOpen = true;
            });
        case ActionTypes.CLOSE_CHANGE_OWNER:
            return produce(state, draft => {
                draft.changeOwner.isOpen = false;
            });
        case ActionTypes.OPEN_PROFILE_EDIT:
            return produce(state, draft => {
                draft.profileEdit.isOpen = true;
            });
        case ActionTypes.CLOSE_PROFILE_EDIT:
            return produce(state, draft => {
                draft.profileEdit.isOpen = false;
            });
        case ActionTypes.OPEN_TEAM_SEARCH_WITH_TREE:
            return produce(state, draft => {
                draft.teamSearchWithTree.isOpen = true;
                draft.teamSearchWithTree.selectedTeam = null;
            });
        case ActionTypes.CLOSE_TEAM_SEARCH_WITH_TREE:
            return produce(state, draft => {
                draft.teamSearchWithTree.isOpen = false;
                draft.teamSearchWithTree.selectedTeam = action.team;
            });
        case ActionTypes.OPEN_CHANGE_EXPIRATION_DATE:
            return produce(state, draft => {
                draft.expirationDate.isOpen = true;
                draft.expirationDate.id = action.id;
            });
        case ActionTypes.CLOSE_CHANGE_EXPIRATION_DATE:
            return produce(state, draft => {
                draft.expirationDate.isOpen = false;
                draft.expirationDate.id = '';
            });
        case ActionTypes.OPEN_HELP_QUESTION_POPUP:
            return produce(state, draft => {
                draft.helpQuestion.isOpen = true;
            });
        case ActionTypes.CLOSE_HELP_QUESTION_POPUP:
            return produce(state, draft => {
                draft.helpQuestion.isOpen = false;
            });
        case ActionTypes.OPEN_DEPT_MEMBER_POPUP:
            return produce(state, draft => {
                draft.deptMember.isOpen = true;
                draft.deptMember.deptId = action.deptId;
                draft.deptMember.deptName = action.deptName;
            });
        case ActionTypes.CLOSE_DEPT_MEMBER_POPUP:
            return produce(state, draft => {
                draft.deptMember.isOpen = false;
            });

        case ActionTypes.OPEN_SECURE_MODE_REQUEST_POPUP:
            return produce(state, draft => {
                draft.secureModeRequest.isOpen = true;
            });
        case ActionTypes.CLOSE_SECURE_MODE_REQUEST_POPUP:
            return produce(state, draft => {
                draft.secureModeRequest.isOpen = false;
                draft.secureModeRequest.startDate = '';
                draft.secureModeRequest.endDate = '';
                draft.secureModeRequest.reason = '';
            });

        default:
            return state;
    }
};

export default globalPopup;
