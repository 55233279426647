import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        feedbackDialog: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    draft.isOpen = true;
                    draft.feedbackType = payload.feedbackType;
                    draft.summaryResultSeq = payload.summaryResultSeq;
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                });
            }
        }
    },
    {
        isOpen: false
    },
    { prefix: 'summary' }
);
