import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { partnerAccount as partnerAccountActions, globalPopup as globalPopupActions, toast as toastActions } from 'src/redux/actions';
import HelpRest from '../../apis/HelpRest';
import HelpAside from '../presentationals/HelpAside';
import UserRest from '../../apis/UserRest';

class HelpAsideContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: {
                tel: ' ',
                email: ' ',
                hours: ' | '
            }
        };
    }

    componentDidMount() {
        this.getHelpBasicInfo();
    }

    getHelpBasicInfo = async () => {
        const data = await HelpRest.getHelpBasicInfo();
        const { EFL_COM_HELPCENTER_TEL: tel, EFL_COM_EFSS_LIGHT_EMAIL: email, EFL_COM_HELPCENTER_HOURS: hours } = data;
        const basic = { tel, email, hours };
        this.setState({ basic });
    };

    onClickMobileAppDownloadLink = async () => {
        const { userId } = this.props;
        const response = await UserRest.sendMobileAppDownloadLink(userId);
        const { resultCode, data, message } = response;

        if (resultCode === 200 && data === true) {
            const { showToast } = this.props;
            showToast({ id: 'user.helpcenter.sentLink' });
        } else {
            const { openAlert } = this.props;
            openAlert({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } });
        }
    };

    render() {
        const { openPartnerAccountPopup, openHelpQuestionPopup, sectionCode, languageCode, configs } = this.props;
        const { basic } = this.state;
        return (
            <HelpAside
                tel={basic.tel}
                email={basic.email}
                hours={basic.hours}
                onClickReqPartnerAccount={openPartnerAccountPopup}
                onClickHelpQuestion={openHelpQuestionPopup}
                onClickMobileAppDownloadLink={this.onClickMobileAppDownloadLink}
                sectionCode={sectionCode}
                languageCode={languageCode}
                configs={configs}
            />
        );
    }
}

HelpAsideContainer.propTypes = {
    openPartnerAccountPopup: PropTypes.func.isRequired,
    openHelpQuestionPopup: PropTypes.func.isRequired,
    sectionCode: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
    configs: PropTypes.array.isRequired,
    openAlert: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    sectionCode: state.auth.user.sectionCode,
    languageCode: state.auth.user.locale.languageCode,
    configs: state.config.configs,
    userId: state.auth.user.id
});

const mapDispatchToProps = {
    openPartnerAccountPopup: partnerAccountActions.openPopup,
    openHelpQuestionPopup: globalPopupActions.openHelpQuestionPopup,
    openAlert: globalPopupActions.openAlert,
    showToast: toastActions.showToast
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpAsideContainer);
