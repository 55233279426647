import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import FileViewerPopup from '../../../../../Popup/FileViewerPopup';
import { popup, viewerPopup } from '../../../../../../../utils/ModalService';
import { DriveInfoRest } from '../../../../../../../apis';
import { showSummaryToast } from '../../../../Common/Util/SummaryToastUtil';
import FileOpen from '../../../../../../presentationals/FileOpen/FileOpen';

const FileName = ({ file }) => {
    const { name, extensionName, objtId, onpstId } = file;

    const dispatch = useDispatch();

    // TODO 파일열기도 포함
    const handleClick = async () => {
        const response = await DriveInfoRest.getFolderFileInfo({ objtId, onpstId });

        if (_.isNil(response)) {
            showSummaryToast(dispatch, { messageId: 'com.alert.internalServerError' });
            return;
        }

        const { data: { authCd } = {} } = response;

        if (authCd === 'U20VIEW') {
            viewerPopup(<FileViewerPopup objtId={objtId} onpstId={onpstId} />).then(
                () => {
                    return true;
                },
                () => {
                    return false;
                }
            );

            return;
        }

        popup(<FileOpen file={file} />).then(
            () => {
                return true;
            },
            () => {
                return false;
            }
        );
    };

    return (
        <div className="item-file" role="button" onClick={handleClick}>
            <div className="file">
                <span className="file-name">{name}</span>
                <span className="file-extension">{extensionName}</span>
            </div>
        </div>
    );
};

FileName.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        extensionName: PropTypes.string.isRequired,
        objtId: PropTypes.string.isRequired,
        onpstId: PropTypes.string.isRequired
    }).isRequired
};

FileName.defaultProps = {};

export default FileName;
