/* eslint-disable */
import BaseMenu from '../BaseMenu';
import FileVersionRest from '../../../../apis/FileVersionRest';
import FileReload from '../../../../utils/FileReload';
import {alert} from "../../../../utils/ModalService";
import _ from 'lodash';

let isRevert = false;

const executor = (props, file) => {

    isRevert = true;
    if(props.custom.changeRevertStatus !== undefined){
        props.custom.changeRevertStatus(isRevert);
    }
    FileVersionRest.setFileVersionRevert(file[0].objtId, file[0].onpstId, file[0].fileVerSno).then(
        (result) => {
            if(result.resultCode===200){
                const type = 'revert';
                props.callback(type);
                FileReload.call(null, file, {reloadType :  'refresh'});
                isRevert=false;
                if(props.custom.changeRevertStatus !== undefined){
                    props.custom.changeRevertStatus(isRevert);
                }
            }else if(result.resultCode===20031){
                alert({ id: 'drive.alert.verup.useFile' });
            }

            return true;
        },
        () => {
            // 실행안누르고 닫은경우. 아무 변경이 없는루트.
            return false;
        }
    );

};

class VersionRevert extends BaseMenu {
    getName() {
        return 'versionRevert';
    }

    getLabel() {
        return 'drive.button.list.menu.versionRevert';
    }

    isVisible() {
        let revertYn = true;
        if(!_.isNil(this.files[0].flcmActionPolicyMap)){
            revertYn = this.files[0].flcmActionPolicyMap.revert;
        }
        return super.isVisible(false, 'versionup') && this.files[0].lastVerYn === 'N' && !this.files[0].uploading && !isRevert && revertYn;
    }
    
    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-version', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-version', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default VersionRevert;
