import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReqRest from '../../../../apis/ReqRest';
import { globalPopup, reqMng as reqMngActions } from '../../../../redux/actions';
import { alert } from '../../../../utils/ModalService';

class ReqRejectPop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            percOpin: '',
            isInvalid: true
        };
        this.maxLength = 512;
    }

    onChangeReason = e => {
        let percOpin = '';
        if (e.target.value.length > this.maxLength) {
            percOpin = e.target.value.substring(0, this.maxLength);
        } else {
            percOpin = e.target.value;
        }

        this.setState({ percOpin });
        if (percOpin.trim().length < 5) {
            this.setState({ isInvalid: true });
        } else {
            this.setState({ isInvalid: false });
        }
    };

    onSave = async () => {
        const { reqMngList, setReqMngListParam, rejectType, index, close, openAlert, reqId } = this.props;
        const { percOpin } = this.state;
        const reqPrcsList = [];

        if (rejectType === 'MULTI') {
            _.forEach(reqMngList, item => {
                if (item.isChecked) {
                    reqPrcsList.push(item.reqId);
                }
            });
        } else if (_.isEmpty(index) && reqId) {
            reqPrcsList.push(reqId);
        } else {
            reqPrcsList.push(reqMngList[index].reqId);
        }

        const res = await ReqRest.updateReqProcess({ reqPrcsType: 'REJECT', reqPrcsList, percOpin });

        if (res.resultCode === 500) {
            alert('com.alert.internalServerError');
        } else if (res.resultCode === 400) {
            alert('com.alert.reqInvalidParam');
        } else if (res.resultCode === 200) {
            if (reqPrcsList.length > 1) {
                openAlert({ id: res.message, values: { N: reqPrcsList.length } });
            } else {
                alert(res.message);
            }
        } else if (res.resultCode === 403 && !_.isEmpty(res.data)) {
            alert(res.data);
        } else {
            alert(res.message);
        }

        close();
        this.initReqs();
        setReqMngListParam({ page: 1, limit: 100, reloadYn: true });
    };

    initReqs() {
        const { setReqMngList, setReqMngListSelected } = this.props;

        setReqMngListSelected({ allChecked: false, checkedCnt: 0 });
        setReqMngList({ reqMngList: [] });
    }

    render() {
        const { close, intl } = this.props;
        const { percOpin, isInvalid } = this.state;

        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="com.title.ReqRejectPop.reject" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.title.ReqRejectPop.close' })} data-dismiss="modal" onClick={() => close()}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <div className="dt-width-120">
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="com.title.ReqRejectPop.rejectReason" />
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <textarea
                                        className={`form-control textarea-lg ${isInvalid ? 'is-invalid' : ''}`}
                                        placeholder={intl.formatMessage({ id: 'com.placeHolder.ReqRejectPop.inputRejectReason' })}
                                        onChange={this.onChangeReason}
                                        value={percOpin}
                                    />
                                    <div className="input-remark">
                                        <div className="err-msg" style={{ display: isInvalid ? 'inline-flex' : 'none' }}>
                                            <FormattedMessage id="com.text.ReqRejectPop.inputTextSizeCheck" />
                                        </div>
                                        <div className="write-count">
                                            <strong className="count-num">{percOpin.length}</strong>/<span className="write-total">{this.maxLength}</span>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => close()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${isInvalid ? 'disabled' : ''}`} role="button" onClick={() => this.onSave()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.check" />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reqMngList: state.reqMng.reqMngList,
    openAlert: PropTypes.func
});

const mapDispatchToProps = {
    setReqMngListParam: reqMngActions.setReqMngListParam,
    setReqMngList: reqMngActions.setReqMngList,
    setReqMngListSelected: reqMngActions.setReqMngListSelected,
    openAlert: globalPopup.openAlert
};

ReqRejectPop.propTypes = {
    index: PropTypes.number,
    rejectType: PropTypes.string,
    reqMngList: PropTypes.array.isRequired,
    setReqMngListParam: PropTypes.func,
    setReqMngList: PropTypes.func,
    setReqMngListSelected: PropTypes.func,
    close: PropTypes.func,
    openAlert: PropTypes.func,
    reqId: PropTypes.string
};

ReqRejectPop.defaultProps = {
    index: null,
    rejectType: null,
    setReqMngListParam: () => {},
    close: () => {},
    openAlert: () => {},
    setReqMngList: () => {},
    setReqMngListSelected: () => {},
    reqId: null
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqRejectPop));

export default connected;
