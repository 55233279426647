import { $http } from 'modules/index';

class FileExtRest {
    static getFileExtFilterAndIconGroup(filter, icon) {
        const params = {
            filter,
            icon
        };
        return $http.get(`/drive/v1/file-exts/ext-group`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}
export default FileExtRest;
