import { $http } from 'modules/index';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({ maxAge: 5 * 60 * 1000 });
const ProfileImageCacheHttp = $http.create({ adapter: cache.adapter });
const ThumbnailmageCacheHttp = $http.create({ adapter: cache.adapter });

class DownloadRest {
    cache = {};

    downloadWasTemp(objtId, onpstId, fileVerSno, incomLinkUrl, linkType) {
        const params = {
            onpstId,
            fileVerSno,
            incomLinkUrl,
            linkType
        };
        // console.log('param: ', params);
        return $http.get(`/drive/v1/file-download/was-temp/${objtId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    downloadProfileImage({ objtId, onpstId }, cancelToken = {}) {
        const config = { params: { onpstId }, responseType: 'arraybuffer', cache: true };
        if (cancelToken.token) {
            config.cancelToken = cancelToken.token;
        }
        return ProfileImageCacheHttp.get(`/drive/v1/file-download/profile/${objtId}`, config);
    }

    downloadThumbnail({ objtId, onpstId }, cancelToken = {}) {
        const config = { params: { onpstId }, responseType: 'arraybuffer', cache: true };
        if (cancelToken.token) {
            config.cancelToken = cancelToken.token;
        }
        return ThumbnailmageCacheHttp.get(`/drive/v1/file-download/thumbnail/${objtId}`, config);
    }

    getCacheKey = (objtId, fileVerSno) => objtId.concat('|').concat(fileVerSno);

    downloadThumbnailWithCache({ objtId, onpstId, fileVerSno }) {
        if (this.cache[this.getCacheKey(objtId, fileVerSno)]) {
            return Promise.resolve(this.cache[this.getCacheKey(objtId, fileVerSno)]);
        }

        return this.downloadThumbnail({ objtId, onpstId }).then(result => {
            if (result) {
                this.cache[this.getCacheKey(objtId, fileVerSno)] = result;
            }
            return result;
        });
    }

    downloadFilePath(file) {
        const params = {
            onpstId: file.onpstId
        };
        console.log('param: ', params);
        return $http.get(`/drive/v1/file-download/exp-path-name/${file.objtId}`, { params }).then(
            response => {
                if (response.resultCode === 200) {
                    return response.data;
                }
                return response;
            },
            error => {
                return error;
            }
        );
    }

    downloadFilePathRes(file) {
        const params = {
            onpstId: file.onpstId
        };
        return $http.get(`/drive/v1/file-download/exp-path-name/${file.objtId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}

export default new DownloadRest();
