import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tree, { convertFolderToNode, selectNode } from '../presentationals/Tree';
import FolderRest from '../../apis/FolderRest';
import DownloadRest from '../../apis/DownloadRest';
import store from '../../redux/store';
import FileReloadAction from '../../redux/actions/FileReload';
import LNBResize from '../../utils/LNBResize';
import FileReload from '../../utils/FileReload';
import { toast } from '../../utils/ModalService';

const MY_FILES_ROOT_FOLDER_ID = '111100000000000000';
const handleClasses = {
    right: 'split-handle'
};

const handleClassesDragged = {
    right: 'split-handle drag'
};

class LNBFolderTree extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        //        const { folderId } = this.props;
        this.state = {
            treeData: [],
            rootFolder: {},
            dataLoaded: false,

            width: 224,
            handleClassName: handleClasses
        };
        this.handleNodeClick = this.handleNodeClick.bind(this);
    }

    setFolderTree() {
        FolderRest.getFoldertree(MY_FILES_ROOT_FOLDER_ID).then(response => {
            const { data, resultCode } = response;

            if (resultCode !== 200) {
                toast('drive.toast.list.api.foldertree.fail');
                return;
            }

            const { match } = this.props;
            const { folderId } = match.params; // CURRENT FOLDER ID

            const rootFolder = {
                objtId: MY_FILES_ROOT_FOLDER_ID,
                objtNm: 'own',
                browserPathId: `/${MY_FILES_ROOT_FOLDER_ID}`,
                explorerPathNm: `\\own`
            };

            const convertedData = convertFolderToNode(data);
            const finalData = convertedData;

            // if (mode === 'refresh')
            //     finalData = selectNode({
            //         convertedData,
            //         id: MY_FILES_ROOT_FOLDER_ID
            //     });

            this.setState({ treeData: finalData, rootFolder, dataLoaded: true }, () => this.expandFolder(folderId || MY_FILES_ROOT_FOLDER_ID));
        });
    }

    componentDidMount() {
        this.calculateFolderTreeWidth();
        this.setFolderTree();
        this.reloadSubscribe();
    }

    componentWillUnmount() {
        if (this.reloadUnsubscribe) this.reloadUnsubscribe();
    }

    calculateFolderTreeWidth() {
        const { innerWidth } = window;
        if (innerWidth >= 1025 && innerWidth <= 1600) {
            this.setState({ width: 224 });
        }

        if (innerWidth >= 769 && innerWidth <= 1024) {
            this.setState({ width: 184 });
        }

        if (innerWidth <= 768) {
            this.setState({ width: 130 });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { treeData, handleClassName } = this.state;
        const { folderId } = this.props;
        if (treeData !== nextState.treeData) return true;
        if (handleClassName !== nextState.handleClassName) return true;
        if (folderId !== nextProps.folderId) {
            this.expandFolder(nextProps.folderId);
            return true;
        }
        return false;
    }

    expandFolder(clickedFolderId) {
        const { treeData } = this.state;

        if (!treeData) return;
        const updatedTreeData = selectNode({
            treeData,
            id: clickedFolderId
        });

        if (treeData !== updatedTreeData) {
            this.setState({ treeData: updatedTreeData });
        }
    }

    treeChanged = treeData => {
        this.setState({ treeData });
    };

    async handleNodeClick(e, { node }) {
        const { match } = this.props;
        const { folderId } = match.params; // CURRENT FOLDER ID

        const { location, history, type } = this.props;

        const { rootFolder } = this.state;

        const objtId = node.id; //  NEXT FOLDER ID

        let currentFolder = '';
        if (MY_FILES_ROOT_FOLDER_ID === folderId) {
            currentFolder = rootFolder; // TB_OBJT_ONPST_R에서 root folder의 값은 존재하지 않음..? onpstId를 같이 보내면 될 것 같기도 한데.
        } else {
            [currentFolder] = await DownloadRest.downloadFilePath({ objtId: folderId });
        }

        let nextFolder;
        if (objtId === MY_FILES_ROOT_FOLDER_ID) {
            nextFolder = rootFolder;
        } else {
            [nextFolder] = await DownloadRest.downloadFilePath({ objtId });
        }

        // 새로고침
        if (folderId === nextFolder.objtId) {
            FileReload.call(null, { objtId: nextFolder.objtId }, { reloadType: 'refresh' });
            return;
        }

        // 다른 폴더 이동
        const { browserPathId, explorerPathNm } = rootFolder;

        const pathIdCutIdx = nextFolder.browserPathId.indexOf('/', 2);
        const pathNmCutIdx = nextFolder.explorerPathNm.indexOf('\\', 2);

        currentFolder.browserPathId = browserPathId.concat(currentFolder.browserPathId.substring(pathIdCutIdx));
        currentFolder.explorerPathNm = explorerPathNm.concat(currentFolder.explorerPathNm.substring(pathNmCutIdx));

        const nextPath = location.pathname.replace(currentFolder.objtId, nextFolder.objtId);
        const searchOnpstId = type === 'workgroup' ? currentFolder.onpstId : undefined; // 왜지?

        // this.setState({ originalFolderId: currentFolder.objtId }, this.expandFolder(nextFolder.objtId));
        this.expandFolder(nextFolder.objtId);
        history.push(nextPath, { searchOnpstId });
    }

    onResizeStart() {
        this.setState({ handleClassName: handleClassesDragged });
        LNBResize.execute('start');
    }

    onResizeStop() {
        this.setState({ handleClassName: handleClasses });
        LNBResize.execute('stop');
    }

    async onClickRefreshButton() {
        this.setFolderTree('refresh');
        const node = {
            id: MY_FILES_ROOT_FOLDER_ID
        };
        this.handleNodeClick(() => {}, { node });
    }

    reloadSubscribe = () => {
        this.reloadUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { fileReload } = storeState;
            if (fileReload === this.preFileReload) return;
            this.preFileReload = fileReload;

            const { data = {}, type: actionType } = fileReload;
            const { folderChanged } = data;
            // 폴더 변경시 새로고침 처리
            if (actionType === FileReloadAction.EXECUTE && folderChanged) {
                const { match } = this.props;
                const { folderId } = match.params; // CURRENT FOLDER ID

                this.setFolderTree();
                this.expandFolder(folderId);
            }
        });
    };

    render() {
        const { treeNodeRenderer } = this.props;
        const { treeData, dataLoaded, width, handleClassName } = this.state;

        const rendererProps = {};
        if (treeNodeRenderer) {
            rendererProps.treeNodeRenderer = treeNodeRenderer;
        }

        return (
            <>
                {dataLoaded && (
                    <Resizable
                        onResize={(e, data) => this.onResizeStart(e, data)}
                        id="drv-tree"
                        className="drv-tree"
                        defaultSize={{ width }}
                        onResizeStop={(e, data) => this.onResizeStop(e, data)}
                        enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                        handleClasses={handleClassName}>
                        <div className="scroll-bar">
                            <div className="tree-wrapper">
                                <Tree virtualized={false} treeData={treeData} rootFolderId={111100000000000000} handleNodeClick={this.handleNodeClick} onChange={this.treeChanged} {...rendererProps} />
                            </div>
                        </div>
                        <div className="drv-tree-menu">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="btn-ic-nor" role="button" onClick={() => this.onClickRefreshButton()}>
                                        <i className="ic-20-refresh" />
                                        <span className="btn-text">
                                            <FormattedMessage id="com.button.refresh" />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Resizable>
                )}
            </>
        );
    }
}

export default injectIntl(LNBFolderTree);

LNBFolderTree.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    folderId: PropTypes.string.isRequired,
    treeNodeRenderer: PropTypes.any
};

LNBFolderTree.defaultProps = {
    treeNodeRenderer: null
};
