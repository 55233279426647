import CustomModal from 'containers/CustomModal';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import OneUserSearcherInput from '../containers/OneUserSearcherInput';

const ChangeOwner = ({ isOpen, isResetMember, onChangeResetMemberCheckbox, selectedUser, onRemoveFromSearchInput, onSelectFromSearchList, cancel, confirm }) => {
    return (
        <CustomModal isOpen={isOpen} fade={false} backdrop="static" className="modal-md">
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.LITE_POP_ChangeOwner" />
                </h5>
                <FormattedMessage id="com.close">
                    {title => (
                        <a className="btn-ic-nor" role="button" title={title} data-dismiss="modal" onClick={cancel}>
                            <i className="ic-20-close" />
                        </a>
                    )}
                </FormattedMessage>
            </div>
            <ModalBody>
                <OneUserSearcherInput select={onSelectFromSearchList} remove={onRemoveFromSearchInput} />

                <div className="form-check">
                    <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" checked={isResetMember} onChange={onChangeResetMemberCheckbox} />
                        <span className="label-text">
                            <FormattedMessage id="user.text.LITE_POP_ChangeOwner.sharedMemeberInit" />
                        </span>
                    </label>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={cancel}>
                        <span className="btn-text">
                            <FormattedMessage id="com.cancel" />
                        </span>
                    </a>
                    <a className={selectedUser ? 'btn btn-lg btn-primary' : 'btn btn-lg btn-primary disabled'} role="button" onClick={confirm}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </ModalFooter>
        </CustomModal>
    );
};

ChangeOwner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isResetMember: PropTypes.bool.isRequired,
    onChangeResetMemberCheckbox: PropTypes.func.isRequired,
    selectedUser: PropTypes.object,
    onSelectFromSearchList: PropTypes.func.isRequired,
    onRemoveFromSearchInput: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired
};

ChangeOwner.defaultProps = {
    selectedUser: null
};

export default ChangeOwner;
