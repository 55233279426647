import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import Request from '../../../../constants/Request';
import RequestPopup from '../../../containers/Popup/RequestPopup';
import ReqRest from '../../../../apis/ReqRest';
import { alert } from '../../../../utils/ModalService';

const executor = (props, files) => {
    function openReqPopup() {
        RequestPopup.open({
            mode: Request.MODE.REQUEST,
            type: Request.TYPE.WORKGROUP_RETENTION_DATE,
            target: files
        }).then(
            result => {
                props.callback(result);
            },
            () => {}
        );
    }

    const { objtId } = files[0].drive;
    ReqRest.getReqInfoByTarget({ objtIds: [objtId], reqLstId: 'chgWGDt' }).then(({ data }) => {
        if (!_.isEmpty(data)) {
            const resultData = _.get(data, objtId);
            if (resultData.reqStatCd === 'PROCESSING' || resultData.batStatCd === 'PROCESSING' || resultData.batStatCd === 'READY') alert('drive.alert.workgroup.alreadyReqChg');
            else {
                openReqPopup();
            }
        } else {
            openReqPopup();
        }
    });
};

class RequestChange extends BaseMenu {
    getName() {
        return 'requestChange';
    }

    getLabel() {
        return 'drive.button.workgroup.chgDate';
    }

    isVisible() {
        const {
            custom: { userConfig: { userCstdMap: { cstdChangeRequestPossibleYn = 'N' } = {}, useCstdPolicy = false } = {} }
        } = this.props;
        if (!useCstdPolicy || cstdChangeRequestPossibleYn !== 'Y') return false;
        return (
            super.isVisible(false) &&
            this.files.every(file => {
                if (!file || !file.drive || !file.actionPolicy) return false;
                return file.drive.onpstFolderId === '111200000000000000' && file.drive.onpstSectCd === 'SYSTFOLDER' && file.actionPolicy.changeWorkgroupDate;
            })
        );
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-extend', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-extend', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default RequestChange;
