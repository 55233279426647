import { If } from 'jsx-control-statements';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { injectIntl, FormattedMessage } from 'react-intl';
import { $http } from 'modules/index';
import { push as routerPush } from 'connected-react-router';
import CharacterImage from '../../containers/CharacterImage/CharacterImageContainer';
import SelectBox from '../SelectBox';
import SettingsLoginHistory from './SettingsLoginHistory';
import Url from '../../../utils/Url';
import FilesCountRest from '../../../apis/FilesCountRest';
import NumberUtil from '../../../utils/NumberUtil';

const SettingsPresentational = ({
    user,
    basicData,
    languageCode,
    timeZone,
    onChangeLanguage,
    onChangeTimezone,
    onClickProfileEdit,
    onClickPasswordChange,
    onClickOwnerChange,
    onChangeFilenameClickMode,
    settings,
    selectAllSettings,
    resetSettings,
    onChangeItem,
    loginHistory,
    intl,
    // eslint-disable-next-line no-unused-vars
    allowNDays,
    emailAlramDays,
    onClickSecureModeRequest,
    push,
    isExtAws
}) => {
    const { whenSbShareFOF, whenSbUploadFileToMyFolder, whenSbVersionUpdateMyFile, whenRemainNDaysMyPartner, whenSbChangeFOFOwnerToMe } = settings;
    const { config } = user;
    const { useCstdPolicy } = config;
    const isAllAlarmChecked = whenSbShareFOF && whenSbUploadFileToMyFolder && whenSbVersionUpdateMyFile && whenRemainNDaysMyPartner && whenSbChangeFOFOwnerToMe;
    const {
        cstdOptions: { tenantRenewalMaxYear }
    } = basicData;

    const [webViewerYn, setwebViewerYn] = useState('N');
    const [duplicatedFileCount, setDuplicatedFileCount] = useState('');

    // 내가 등록한 협력사 사용자의 만료 알람 세팅 노출 여부
    const [notiPartnerExpirationText, setNotiPartnerExpirationText] = useState('N');
    function changeablePassword() {
        return !_.get(user, 'linkage.isLinked', false);
    }

    function convertFractionalPart(value) {
        const [decimal, fractionalPart] = value.split('.');

        if (fractionalPart === '00') {
            return decimal;
        }

        return value;
    }
    useEffect(() => {
        $http.get(`/drive/v1/configs/EFL_DRV_FILEVIEWER_USE_YN`).then(response => {
            console.log(response.data);
            setwebViewerYn(response.data);
        });
    });

    useEffect(() => {
        $http.get(`/auth/v1/configs/cfg/EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION`).then(response => {
            console.log(response.data);
            setNotiPartnerExpirationText(response.data.cfgVal);
        });
    }, []);

    useEffect(() => {
        FilesCountRest.getDuplicatedFilesCount().then(
            ({ resultCode, data: { count } }) => {
                setDuplicatedFileCount(resultCode === 200 ? count : '');
            },
            error => {
                setDuplicatedFileCount('');
                console.log(error);
            }
        );
    }, []);

    const isTenantRenewalMaxYearPresent = useMemo(() => {
        return _.isNumber(parseInt(tenantRenewalMaxYear, 10)) && tenantRenewalMaxYear >= 1;
    }, [basicData]);

    return (
        <div className="set-view">
            <section className="set-view-panel">
                <div className="comm-title-bar">
                    <h4 className="section-title">
                        <FormattedMessage id="user.setting.environment" />
                    </h4>
                </div>

                <div className="dt-width-203">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="com.text.language" />
                        </dt>
                        <dd>
                            <SelectBox options={basicData.languageOptions} value={languageCode} onChange={onChangeLanguage} />
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.setting.timezone" />
                        </dt>
                        <dd>
                            <SelectBox options={basicData.timeZoneOptions} value={timeZone} onChange={onChangeTimezone} />
                        </dd>
                    </dl>
                </div>

                <div className="content-divider" />

                <div className="comm-title-bar">
                    <h4 className="section-title">
                        <FormattedMessage id="user.text.setting.account" />
                    </h4>
                </div>

                <div className="dt-width-203">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="com.text.login.id" />
                        </dt>
                        <dd>{user.loginId}</dd>
                    </dl>

                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.setting.profilePhoto" />
                        </dt>
                        <dd>
                            <div className="item-user-thumbnail">
                                <CharacterImage objtId={user.profile.fileId || ''} updateTime={user.profile.updateTime} userId={user.id} text={user.name} size="bg" userType={user.sectionCode} />
                                <a className="btn-ic-line btn-func-photo" role="button" title={intl.formatMessage({ id: 'user.setting.profile.editProfilePhto' })} onClick={onClickProfileEdit}>
                                    <i className="ic-24-cam" />
                                </a>
                            </div>
                        </dd>
                    </dl>

                    {changeablePassword() && (
                        <dl className="row-list text-info">
                            <dt>
                                <FormattedMessage id="user.setting.password" />
                            </dt>
                            <dd>
                                <div className="dd-text">
                                    <a className="btn btn-secondary" role="button" onClick={onClickPasswordChange}>
                                        <span className="btn-text">
                                            <FormattedMessage id="user.setting.change" />
                                        </span>
                                    </a>
                                    <If condition={!_.isEmpty(basicData.showUserPwdLastModDt)}>
                                        ( <FormattedMessage id="user.setting.lastModifed" /> : {basicData.showUserPwdLastModDt} )
                                    </If>
                                </div>
                            </dd>
                        </dl>
                    )}

                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.setting.accessHistory" />
                        </dt>
                        <dd>
                            <div className="pop-grid-list">
                                <div className="comm-grid-list list-sm">
                                    <div className="grid-header-group">
                                        <div className="grid-row">
                                            <div className="grid-cell access-time-cell">
                                                <span className="column-name">
                                                    <FormattedMessage id="user.setting.accessTime" />
                                                </span>
                                            </div>
                                            <div className="grid-cell access-path-cell">
                                                <span className="column-name">
                                                    <FormattedMessage id="user.setting.accessPath" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <Scrollbars className="scroll" autoHeight={true}>
                                        <div>
                                            <div className="grid-row-group row-3">
                                                <SettingsLoginHistory loginHistory={loginHistory} />
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="content-divider" />

                <div className="comm-title-bar">
                    <h4 className="section-title">
                        <FormattedMessage id="user.title.setting.contents" />
                    </h4>
                </div>
                <div className="dt-width-203">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.setting.amountUsed" />
                        </dt>
                        <dd>
                            <div className="utilize-graph">
                                <div className="graph-info">
                                    <div className="total-count">
                                        <span>
                                            <FormattedMessage id="user.setting.summrayOfAmountUsed" values={{ total: convertFractionalPart(basicData.storageAllocSize) }} />
                                        </span>
                                        <span className="count">
                                            <i className="num">{convertFractionalPart(basicData.storageTotalUseSize)} GB</i> ( {basicData.storageTotalUseSizePercentage}%{' '}
                                            <FormattedMessage id="com.in-use" /> )
                                        </span>
                                    </div>
                                </div>
                                <div className="graph-bar">
                                    <ul className="progress" style={{ width: '100%' }}>
                                        <If condition={basicData.storageTotalUseSizePercentage > 100}>
                                            <li style={{ width: '100%', backgroundColor: '#FF0000' }}>Full</li>
                                        </If>
                                        <If condition={basicData.storageTotalUseSizePercentage <= 100}>
                                            <li className="K-drive" style={{ width: `${basicData.kStorageUseSizePercentage}%` }}>
                                                <FormattedMessage id="user.text.storage.kdrive" />
                                            </li>
                                            <If condition={!isExtAws}>
                                                <li className="M-drive" style={{ width: `${basicData.mStorageUseSizePercentage}%` }}>
                                                    <FormattedMessage id="user.text.storage.mdrive" />
                                                </li>
                                            </If>
                                        </If>
                                    </ul>
                                </div>
                                <ul className="graph-detail">
                                    <li className="K-drive">
                                        <FormattedMessage id="user.text.storage.kdrive" />
                                        <span>&nbsp; : &nbsp;</span>
                                        <span style={{ paddingRight: '5px' }}>
                                            <FormattedMessage id="user.setting.summrayOfAmountUsed" values={{ total: convertFractionalPart(basicData.kStorageAllocSize) }} />
                                        </span>
                                        {convertFractionalPart(basicData.kStorageUseOnlySize)} GB
                                        <span>, &nbsp;</span>
                                        <FormattedMessage id="user.text.setting.file.deleted" />
                                        <span>&nbsp;</span>
                                        {convertFractionalPart(basicData.kRecyclebinUseSize)} GB ( {basicData.kStorageUseSizePercentageByKStorage}% <FormattedMessage id="com.in-use" /> )
                                    </li>
                                    <If condition={!isExtAws}>
                                        <li className="M-drive">
                                            <FormattedMessage id="user.text.storage.mdrive" />
                                            <span>&nbsp; : &nbsp;</span>
                                            <span style={{ paddingRight: '5px' }}>
                                                <FormattedMessage id="user.setting.summrayOfAmountUsed" values={{ total: convertFractionalPart(basicData.mStorageAllocSize) }} />
                                            </span>
                                            {convertFractionalPart(basicData.mStorageUseOnlySize)} GB
                                            <span>, &nbsp;</span>
                                            <FormattedMessage id="user.text.setting.file.deleted" />
                                            <span>&nbsp;</span>
                                            {convertFractionalPart(basicData.mRecyclebinUseSize)} GB ( {basicData.mStorageUseSizePercentageByMStorage}% <FormattedMessage id="com.in-use" /> )
                                        </li>
                                    </If>
                                </ul>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="user.text.setting.duplicated" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <em className="state">
                                    {Number.isInteger(duplicatedFileCount) ? (
                                        <FormattedMessage id="user.text.duplicated.count" values={{ n: NumberUtil.getNumberWithCommas(duplicatedFileCount) }} />
                                    ) : (
                                        ''
                                    )}
                                </em>
                                <a className="btn btn-secondary" role="button" onClick={() => push(Url.duplicated)}>
                                    <span className="btn-text">
                                        <FormattedMessage id="user.button.duplicated.manage" />
                                    </span>
                                </a>
                            </div>
                        </dd>
                    </dl>
                    <If condition={useCstdPolicy}>
                        <dl className="row-list text-info">
                            <dt>
                                <FormattedMessage id="user.text.cstd" />
                            </dt>

                            <dd>
                                <div className="dd-row-list">
                                    <ul className="title">
                                        <li>
                                            <FormattedMessage id="user.text.settings.cstd" />
                                        </li>
                                        <li>
                                            <FormattedMessage id="user.text.settings.cstd.afterExpiration" />
                                        </li>
                                        <If condition={basicData.cstdOptions.cstdPolicyPstPrcs === 'DELETE'}>
                                            <li className="sub-li">
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.delete.pauseDay" />
                                            </li>
                                            <li className="sub-li">
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.remindDay" />
                                            </li>
                                        </If>
                                        <li>
                                            <FormattedMessage id="user.text.settings.cstd.request.change" />
                                        </li>
                                        <If condition={isTenantRenewalMaxYearPresent}>
                                            <li>
                                                <FormattedMessage id="user.text.settings.cstd.renewMaxYear" />
                                            </li>
                                        </If>
                                    </ul>

                                    <ul className="substance">
                                        <li>
                                            <If condition={basicData.cstdOptions.cstdPolicyUseYn === 'Y'}>
                                                <If condition={basicData.cstdOptions.cstdPrdYY !== 0}>
                                                    {basicData.cstdOptions.cstdPrdYY}
                                                    <FormattedMessage id="com.text.years" />
                                                </If>
                                                <If condition={basicData.cstdOptions.cstdPrdMM !== 0}>
                                                    {basicData.cstdOptions.cstdPrdMM}
                                                    <FormattedMessage id="com.text.months" />
                                                </If>
                                                <If condition={basicData.cstdOptions.cstdPrdDD !== 0}>
                                                    {basicData.cstdOptions.cstdPrdDD}
                                                    <FormattedMessage id="com.text.days" />
                                                </If>
                                            </If>
                                            <If condition={basicData.cstdOptions.cstdPolicyUseYn !== 'Y'}>
                                                <FormattedMessage id="user.text.settings.cstd.notApply" />
                                                (<FormattedMessage id="user.text.settings.cstd.notApply.onlyNewFile" />)
                                            </If>
                                        </li>
                                        <li>
                                            <If condition={basicData.cstdOptions.cstdPolicyPstPrcs === 'DELETE'}>
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.delete" />
                                            </If>
                                            <If condition={basicData.cstdOptions.cstdPolicyPstPrcs === 'EXPIRE'}>
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.expire" />
                                            </If>
                                        </li>
                                        <If condition={basicData.cstdOptions.cstdPolicyPstPrcs === 'DELETE'}>
                                            <li className="sub-li">
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.afterDay" values={{ n: basicData.cstdOptions.cstdPolicyHestPrd }} />
                                            </li>
                                            <li className="sub-li">
                                                <FormattedMessage id="user.text.settings.cstd.afterExpiration.beforeDay" values={{ n: basicData.cstdOptions.cstdPolicyFileClctDds }} />
                                            </li>
                                        </If>
                                        <li>
                                            <If condition={basicData.cstdOptions.cstdChangeRequestPossibleYn === 'Y'}>
                                                <FormattedMessage id="user.text.settings.cstd.request.change.allow" />
                                            </If>
                                            <If condition={basicData.cstdOptions.cstdChangeRequestPossibleYn !== 'Y'}>
                                                <FormattedMessage id="user.text.settings.cstd.request.change.disallow" />
                                            </If>
                                        </li>
                                        <If condition={isTenantRenewalMaxYearPresent}>
                                            <li>
                                                <FormattedMessage id="user.text.settings.cstd.renewMaxYear.option.nYear" values={{ n: tenantRenewalMaxYear }} />
                                            </li>
                                        </If>
                                    </ul>
                                </div>
                            </dd>
                        </dl>
                    </If>

                    <If condition={webViewerYn === 'Y'}>
                        <dl className="row-list text-info">
                            <dt>
                                <FormattedMessage id="user.guideText.filenameClickExecutionMode" />
                            </dt>
                            <dd>
                                <div className="dd-text">
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="action"
                                                checked={basicData.clickExecutionMode !== 'PC'}
                                                onChange={event => onChangeFilenameClickMode(event.target.checked)}
                                            />
                                            <span className="label-text">
                                                <FormattedMessage id="user.text.openWithWeb" />
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="action"
                                                checked={basicData.clickExecutionMode === 'PC'}
                                                onChange={event => onChangeFilenameClickMode(event.target.checked)}
                                            />
                                            <span className="label-text">
                                                <FormattedMessage id="user.text.openWithPc" />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </If>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="user.setting.changeOwnership" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <a className="btn btn-secondary" role="button" onClick={onClickOwnerChange}>
                                    <span className="btn-text">
                                        <FormattedMessage id="user.setting.change" />
                                    </span>
                                </a>
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedMessage id="user.setting.guideText.changeOwner" />
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="user.text.setting.secureMode" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <If condition={basicData.fileStorageUseYn === 'Y'}>
                                    <em className="state">
                                        <FormattedMessage id="user.text.in-use" />
                                    </em>{' '}
                                    <If condition={basicData.periodOfUseFrom !== '' && basicData.periodOfUseTo !== ''}>
                                        ( {moment(basicData.periodOfUseFrom).format('YYYY-MM-DD')} ~ {moment(basicData.periodOfUseTo).format('YYYY-MM-DD')} )
                                    </If>
                                </If>
                                <If condition={basicData.fileStorageUseYn !== 'Y'}>
                                    <em className="state">
                                        <FormattedMessage id="com.not-inuse" />
                                    </em>
                                </If>
                                <If condition={basicData.fileStorageUseYn !== 'Y' && basicData.agentType !== 'S' && basicData.secureFolderUseRequest !== 'N'}>
                                    <a className="btn btn-secondary" role="button" onClick={onClickSecureModeRequest}>
                                        <span className="btn-text">
                                            <FormattedMessage id="user.text.application-for-use" />
                                        </span>
                                    </a>
                                </If>
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedMessage id="user.guideText.setting.secureMode" />
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="content-divider" />
                <div className="comm-title-bar">
                    <h4 className="section-title">
                        <FormattedMessage id="user.setting.alram.emailNoti" />
                    </h4>
                </div>
                <div className="dt-width-203">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.setting.alram.notiList" />
                        </dt>
                        <dd>
                            <a className="btn btn-secondary" role="button" onClick={() => selectAllSettings(isAllAlarmChecked)}>
                                <span className="btn-text">
                                    {isAllAlarmChecked && <FormattedMessage id="user.text.settings.alram.deselectAll" />}
                                    {!isAllAlarmChecked && <FormattedMessage id="user.setting.alram.selectAll" />}
                                </span>
                            </a>
                            <a className="btn-ic-line" role="button" onClick={resetSettings}>
                                <i className="ic-20-reset" />
                                <span className="btn-text">
                                    <FormattedMessage id="user.text.setting.alarm.reset" />
                                </span>
                            </a>
                            <div className="noti-check">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="whenSbShareFOF" checked={whenSbShareFOF || false} onChange={onChangeItem} />
                                        <span className="label-text">
                                            <FormattedMessage id="user.setting.alram.whenShareFileFolderWithMe" />
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="whenSbUploadFileToMyFolder" checked={whenSbUploadFileToMyFolder || false} onChange={onChangeItem} />
                                        <span className="label-text">
                                            <FormattedMessage id="user.setting.alram.someoneUploadMyFolder" />
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="whenSbVersionUpdateMyFile" checked={whenSbVersionUpdateMyFile || false} onChange={onChangeItem} />
                                        <span className="label-text">
                                            <FormattedMessage id="user.setting.alram.someoneUpdateVersionMyFile" />
                                        </span>
                                    </label>
                                </div>
                                {notiPartnerExpirationText === 'Y' && (
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="whenRemainNDaysMyPartner" checked={whenRemainNDaysMyPartner || false} onChange={onChangeItem} />
                                            <span className="label-text">
                                                <FormattedMessage id="user.setting.alram.notiPartnerExpiration" values={{ n: emailAlramDays === 0 ? 'N' : emailAlramDays }} />
                                            </span>
                                        </label>
                                    </div>
                                )}
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="whenSbChangeFOFOwnerToMe" checked={whenSbChangeFOFOwnerToMe || false} onChange={onChangeItem} />
                                        <span className="label-text">
                                            <FormattedMessage id="user.setting.alram.changeOwnerOtherFileFolderByMe" />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </section>
        </div>
    );
};

SettingsPresentational.propTypes = {
    user: PropTypes.object.isRequired,
    basicData: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    onChangeLanguage: PropTypes.func.isRequired,
    onChangeTimezone: PropTypes.func.isRequired,
    onClickProfileEdit: PropTypes.func.isRequired,
    onClickPasswordChange: PropTypes.func.isRequired,
    onClickOwnerChange: PropTypes.func.isRequired,
    onChangeFilenameClickMode: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    selectAllSettings: PropTypes.func.isRequired,
    resetSettings: PropTypes.func.isRequired,
    onChangeItem: PropTypes.func.isRequired,
    loginHistory: PropTypes.array.isRequired,
    allowNDays: PropTypes.number.isRequired,
    emailAlramDays: PropTypes.number.isRequired,
    onClickSecureModeRequest: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isExtAws: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    push: routerPush
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SettingsPresentational)
);
