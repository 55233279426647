import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SizeConverter from '../../../../utils/SizeConverter';

const SearchSize = props => {
    const {
        file: { drive, version }
    } = props;

    return drive.objtSectCd === 'FOLDER' || !version || !version.fileSize ? (
        <div />
    ) : (
        <li>
            <div className="g-inline-flex">
                <div className="title">
                    <FormattedMessage id="drive.text.list.columns.fileSize" /> :
                </div>
                <div className="next">{SizeConverter.convert(version.fileSize)}</div>
            </div>
        </li>
    );
};

SearchSize.propTypes = {
    file: PropTypes.object.isRequired
};

export default SearchSize;
