import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        tooltip: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    draft.isOpen = true;
                    draft.tooltipIconRef = payload.tooltipIconRef;
                    draft.direction = payload.direction;
                    draft.content = payload.content;
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                });
            }
        }
    },
    { isOpen: false, content: {} },
    { prefix: 'summary' }
);
