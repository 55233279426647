import React from 'react';
import { FormattedMessage } from 'react-intl';
import FoldersRest from '../../apis/FoldersRest';
import Base from './Base';
import FavoriteRow from '../../components/containers/DriveFiles/Row/FavoriteRow';
import MoreRow from '../../components/containers/DriveFiles/Row/MoreRow';
import DriveFilesBody from '../../components/containers/DriveFiles/DriveFilesBody';
import FileCache from '../FileCache';

class Favorite extends Base {
    // OFFSET_TOP = 160;

    pageId = 'prefer';

    menu = {
        header: {
            iconGroupType: null, // 헤더영역 없음
            iconGroupTypeMulti: null // 멀티선택 시 icon 영역
        },
        row: {
            iconGroupType: 'preferred',
            menuGroupType: 'preferred', // 우클릭 사용안함
            floatingIconGroupType: null // floating 사용안함
        }
    };

    absoluteHeight = 60;

    buildCache() {
        return new FileCache(folder => {
            if (folder.drive && folder.drive.onpstFolderId === '111100000000000000') return folder;
            return null;
        });
    }

    searchFunction(params) {
        return FoldersRest.getPersonalFolders('prefer', params);
    }

    getColumns() {}

    uploadBuilder() {
        // if (folder && folder.drive && this.isRoot(folder.drive.objtId)) return null;
        // return super.uploadBuilder(folder);
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <FavoriteRow folder={list[index]} index={index} style={style} />;
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-folder" />
                    <p>
                        <FormattedMessage id="drive.guideText.favor.empty1" />
                        <br />
                        <FormattedMessage id="drive.guideText.favor.empty2" />
                    </p>
                </div>
            </div>
        );
    }

    pageBuilder({ state: { list } }) {
        if (list && !list.length) {
            return <section className="drv-file-panel">{this.emptyBuilder()}</section>;
        }
        return (
            <div className="comm-grid-list">
                <DriveFilesBody />
            </div>
        );
    }
}
export default new Favorite();
