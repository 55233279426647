import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { withFilesContext } from '../DriveFilesContext';
import SearchRest from '../../../../apis/SearchRest';

class SearchFileName extends React.Component {
    handleOnClick = e => {
        const {
            file,
            file: { drive },
            context,
            context: {
                props: { pageClass }
            }
        } = this.props;
        pageClass.goToPage(file, context, e);
        SearchRest.addSearchClick(drive.objtId, drive.onpstId);
    };

    render() {
        const {
            file,
            file: { drive, sharedCount = 0 },
            context: {
                props: { pageClass, searchParams }
            },
            context
        } = this.props;

        const { q = '' } = searchParams;
        let qQueries;
        if (q.includes('*')) {
            qQueries = q
                .split('*')
                .map(qq => qq.trim())
                .filter(qq => qq);
        } else {
            qQueries = q
                ? q
                      .split(' ')
                      .map(qq => qq.trim())
                      .filter(qq => qq)
                : [];
        }

        return (
            <div className="result-value">
                <div className="item-file">
                    <i className={pageClass.getExtensionIcon(file, context)} />
                    <div className="file file-link" role="button" onClick={this.handleOnClick} title={drive.objtNm}>
                        <span className="file-name">
                            <Highlighter searchWords={qQueries} autoEscape={true} textToHighlight={drive.objtNm} />
                        </span>
                    </div>
                </div>
                {drive.objtSectCd === 'FILE' && sharedCount ? (
                    <span className="btn-ic-nor">
                        <i className="ic-20-share-group" />
                    </span>
                ) : null}
            </div>
        );
    }
}

SearchFileName.propTypes = {
    file: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
};

SearchFileName.displayName = 'SearchFileName';
export default withFilesContext(SearchFileName, []);
