import React, { memo, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { summaryActions } from '../../../../../../redux/actions/Summary';

const KeywordInput = memo(({ intl }) => {
    const keywordInputRef = useRef();

    const dispatch = useDispatch();

    const handleInputKeyword = e => {
        const enteredKeyword = e.target.textContent;

        dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { keyword: enteredKeyword } }));

        if (!_.isEmpty(enteredKeyword)) {
            return;
        }

        // span[contenteditable] 입력이 된 후 텍스트 제거로 인해 공란이 되는 경우, br 태그 추가되는 버그 fix
        // https://devops.sdsdev.co.kr/jira/browse/EFSS-60878?focusedId=3169400&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-3169400
        keywordInputRef.current.innerHTML = '';
    };

    const handleFocusKeyword = () => {
        dispatch(summaryActions.fileListBox.close());
    };

    // eslint-disable-next-line consistent-return
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div className="mention instant-keywords">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
                contentEditable="true"
                placeholder={intl.formatMessage({ id: 'drive.placeHolder.copilot.summary.prompt.keyword_input' })}
                spellCheck="false"
                onInput={handleInputKeyword}
                onFocus={handleFocusKeyword}
                onKeyDown={handleKeyDown}
                ref={keywordInputRef}
            />
        </div>
    );
});

export default injectIntl(KeywordInput);
