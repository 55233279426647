import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Spinner from '../../../../../../../../static/img/common/drv/loading_copilot_answers.gif';
import useInterval from '../../../../Common/useCount';
import SummaryRest from '../../../../../../../apis/SummaryRest';
import { AI_MESSAGE_RESULT_STATUS, SUMMARY_RESPONSE_CODE, SUMMARY_RESULT_CODE } from '../../../../Common/SummaryConstant';
import { generateFormattedSummaryDateTime } from '../../../../Common/Util/SummaryUtil';
import { summaryActions } from '../../../../../../../redux/actions/Summary';
import { getMessageIdBySummaryResponseCode } from '../../../../Common/Util/SummaryMessageUtil';

const AiPendingMessage = memo(({ summaryMessage, intl }) => {
    const { summaryResults } = summaryMessage;

    const { files, summaryOptions } = useMemo(() => {
        return _.last(summaryResults);
    }, [summaryResults]);

    const PREFIX_PENDING_MESSAGE_ID = 'drive.text.copilot.summary.status.in_progress';
    const DEFAULT_REQUEST_INTERVAL_SEC = 5;
    const DEFAULT_REQUEST_TIMEOUT_SEC = 180;
    const STEP2_START_SECOND = 4;
    const STEP3_START_SECOND = 31;

    const configs = useSelector(({ config }) => config.configs);
    const user = useSelector(({ auth }) => auth.user);
    const isSidePanelOpen = useSelector(({ summary }) => summary.sidePanel.isOpen);

    const dispatch = useDispatch();

    const [summaryId, setSummaryId] = useState();
    const [messageId, setMessageId] = useState(`${PREFIX_PENDING_MESSAGE_ID}01`);
    const [elapsedSec, setElapsedSec] = useState(0);
    const [isTimeout, setIsTimeout] = useState(false);

    const { requestIntervalSec, requestTimeoutSec } = useMemo(() => {
        const { value: intervalSec } = _.find(configs, ['id', 'EFL_DRV_SUMMARY_REQUEST_INTERVAL_SEC']) || { value: DEFAULT_REQUEST_INTERVAL_SEC };
        const { value: timeoutSec } = _.find(configs, ['id', 'EFL_DRV_SUMMARY_REQUEST_TIMEOUT_SEC']) || { value: DEFAULT_REQUEST_TIMEOUT_SEC };

        return { requestIntervalSec: intervalSec, requestTimeoutSec: timeoutSec };
    }, [configs]);

    const showPendingMessage = () => {
        if (elapsedSec === STEP2_START_SECOND) {
            setMessageId(`${PREFIX_PENDING_MESSAGE_ID}02`);
        }

        if (elapsedSec === STEP3_START_SECOND) {
            setMessageId(`${PREFIX_PENDING_MESSAGE_ID}03`);
        }
    };

    const handleError = ({ errorCode, errorMessage }) => {
        dispatch(summaryActions.thread.showErrorAIMessage({ errorCode, errorMessage, files, summaryOptions }));

        if (!isSidePanelOpen) {
            dispatch(
                summaryActions.notificationDialog.open({
                    resultStatus: AI_MESSAGE_RESULT_STATUS.error,
                    errorMessage,
                    files
                })
            );
        }
    };

    const requestSummaryAndGetSummaryId = async () => {
        const response = await SummaryRest.requestSummary({ files, summaryOptions, user }).catch(error => {
            console.error(error);
            handleError({
                errorCode: SUMMARY_RESULT_CODE.unexpectedError,
                errorMessage: intl.formatMessage({ id: 'drive.text.copilot.summary.status.error.unexpected' })
            });
        });

        if (_.isNil(response)) {
            return null;
        }

        const { message: summaryResponseCode, data } = response;

        if (summaryResponseCode !== SUMMARY_RESPONSE_CODE.success) {
            handleError({
                errorCode: summaryResponseCode,
                errorMessage: intl.formatMessage({ id: getMessageIdBySummaryResponseCode(summaryResponseCode) })
            });

            return null;
        }

        const { summaryId: pendedSummaryId, status } = data;

        if (status !== SUMMARY_RESULT_CODE.init) {
            handleError({
                errorCode: SUMMARY_RESULT_CODE.unexpectedError,
                errorMessage: intl.formatMessage({ id: 'drive.text.copilot.summary.status.error.unexpected' })
            });

            return null;
        }

        return pendedSummaryId;
    };

    const getSummaryResult = async () => {
        const response = await SummaryRest.getSummary({ summaryId }).catch(error => {
            console.error(error);
            handleError({
                errorCode: SUMMARY_RESULT_CODE.unexpectedError,
                errorMessage: intl.formatMessage({ id: 'drive.text.copilot.summary.status.error.unexpected' })
            });
        });

        if (_.isNil(response)) {
            return null;
        }

        const { message: responseMessage, data } = response;

        // 요약 결과 조회 API 응답이 "IOFFICE_SUCCESS"가 아닌 경우
        if (responseMessage !== SUMMARY_RESPONSE_CODE.success) {
            handleError({
                errorCode: responseMessage,
                errorMessage: intl.formatMessage({ id: 'drive.text.copilot.summary.status.error.unexpected' })
            });

            return null;
        }

        const { status, resultContent, statusChgDt } = data;

        // 아직 요약 진행 중인 경우
        if ([SUMMARY_RESULT_CODE.init, SUMMARY_RESULT_CODE.processing].includes(status)) {
            return null;
        }

        // 요약이 완료됐지만, 요약 성공 외 경우
        if (status !== SUMMARY_RESULT_CODE.complete) {
            handleError({
                errorCode: status,
                errorMessage: resultContent
            });

            return null;
        }

        const { locale: { timezoneDiff = 0 } = {} } = user;

        return {
            summaryText: resultContent,
            summaryDateTime: generateFormattedSummaryDateTime(statusChgDt, timezoneDiff),
            files,
            summaryOptions
        };
    };

    // 초단위 timer: 1초에 elapsedSec 1씩 증가
    useInterval(() => {
        showPendingMessage();

        if (elapsedSec >= requestTimeoutSec) {
            setIsTimeout(true);

            return;
        }

        setElapsedSec(elapsedSec + 1);
    }, 1000);

    useEffect(() => {
        if (!isTimeout) {
            return;
        }

        handleError({
            errorCode: SUMMARY_RESULT_CODE.timeout,
            errorMessage: intl.formatMessage({ id: 'drive.text.copilot.summary.status.error.timeout' })
        });
    }, [isTimeout]);

    useEffect(() => {
        (async () => {
            if (elapsedSec === 0) {
                const pendedSummaryId = await requestSummaryAndGetSummaryId();

                if (_.isEmpty(pendedSummaryId)) return;

                setSummaryId(pendedSummaryId);
                return;
            }

            if (!summaryId) {
                return;
            }

            if (elapsedSec % requestIntervalSec !== 0) {
                return;
            }

            if (isTimeout) {
                return;
            }

            // TODO refactoring
            const summaryResult = await getSummaryResult();

            if (_.isNil(summaryResult)) {
                return;
            }

            dispatch(summaryActions.thread.completeToFetchAIMessage({ summaryResult }));
        })();
    }, [elapsedSec]);

    return (
        <>
            <div className="ai-option">
                <div className="ai-option-lt">
                    <div className="ai-logo-loading">
                        <img src={Spinner} alt="loading" />
                    </div>
                </div>
            </div>
            <div className="ai-loading ui-loading-ing">
                <p className="ai-loading-text">
                    <em className="c-text-primary">
                        <FormattedHTMLMessage tagName="span" id={messageId} />
                    </em>
                </p>
            </div>
        </>
    );
});

AiPendingMessage.propTypes = {
    summaryMessage: PropTypes.shape({
        summaryResults: PropTypes.array.isRequired
    }).isRequired
};

export default injectIntl(AiPendingMessage);
