import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import ReqRest from '../../../../apis/ReqRest';
import ReqDtlHeader from './ReqDtlHeader';
import ReqDtlBodyApprover from './ReqDtlBodyApprover';
import ReqDtlBodyRsn from './ReqDtlBodyRsn';
import ReqDtlBodyDtl from './ReqDtlBodyDtl';
import ReqDtlBodyTgtFile from './ReqDtlBodyTgtFile';
import ReqDtlFooter from './ReqDtlFooter';

class ReqExtendExpiredDtPop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            req: null,
            reqDtl: null,
            reqTgt: null,
            reqTgtUser: null
        };
        const { loginUser } = this.props;
        this.timezoneDiff = loginUser.locale.timezoneDiff;
    }

    componentDidMount() {
        this.getReq();
    }

    async getReq() {
        const { reqId, reqInfo } = this.props;
        let reqData;

        if (_.isNil(reqInfo) || _.isEmpty(reqInfo)) {
            const response = await ReqRest.getReq(reqId);
            const { resultCode, data } = response;
            if (resultCode === 200 && !_.isEmpty(data)) {
                reqData = data;
            } else {
                return false;
            }
        } else {
            reqData = reqInfo;
        }

        this.setState({
            req: reqData.req,
            reqDtl: reqData.reqDtl,
            reqTgt: reqData.reqTgt,
            reqTgtUser: reqData.reqTgtUser
        });
        return reqData;
    }

    render() {
        const { close, setReqMngList, setReqMngListSelected, setReqMngListParam } = this.props;
        const { req, reqDtl, reqTgt, reqTgtUser = [] } = this.state;
        let percOpin = '';
        if (!_.isEmpty(reqTgtUser)) {
            percOpin = _.isNil(reqTgtUser[0].percOpin) ? '' : reqTgtUser[0].percOpin;
        }

        return (
            <div className="modal-content">
                <ReqDtlHeader title="drive.title.ReqExtendExpiredDtPop" close={close} />
                <div className="modal-body">
                    {reqTgt != null && <ReqDtlBodyTgtFile reqTgt={reqTgt} />}
                    <div className="dt-width-120">
                        {reqDtl != null && (
                            <ReqDtlBodyDtl
                                title="drive.text.ReqExtendExpiredDtPop.modExpiredDt"
                                content={[reqDtl.newExpiredTime.slice(0, 4), '-', reqDtl.newExpiredTime.slice(4, 6), '-', reqDtl.newExpiredTime.slice(6, 8)].join('')}
                            />
                        )}
                        {req != null && (
                            <ReqDtlBodyDtl
                                title="drive.text.ReqExtendExpiredDtPop.regDt"
                                content={moment(req.regDt)
                                    .add(this.timezoneDiff, 'minutes')
                                    .format('YYYY-MM-DD HH:mm')}
                            />
                        )}
                        {req != null && <ReqDtlBodyRsn title="drive.text.ReqDtlBodyReqRsn.reqRsn" reason={req.reqRsn} />}
                        <div className="content-divider" />
                        {req != null && <ReqDtlBodyApprover id={req.reqTgtUserId} name={req.reqTgtUserNm} deptNm={req.reqTgtUserDeptNm} copNm={req.reqTgtUserCopNm} />}
                        {req != null && <ReqDtlBodyDtl title="drive.text.ReqExtendExpiredDtPop.reqStatCdNm" content={req.reqStatCdNm} />}
                        {req != null && req.reqStatCd !== 'PROCESSING' && (
                            <ReqDtlBodyDtl
                                title="drive.text.ReqExtendExpiredDtPop.chgDt"
                                content={moment(req.chgDt)
                                    .add(this.timezoneDiff, 'minutes')
                                    .format('YYYY-MM-DD HH:mm')}
                            />
                        )}
                        {req != null && req.reqStatCd !== 'PROCESSING' && <ReqDtlBodyRsn title="drive.text.ReqDtlBodyReqRsn.percOpin" reason={percOpin} />}
                    </div>
                </div>
                <ReqDtlFooter
                    close={close}
                    req={req}
                    reqTgt={reqTgt}
                    setReqMngList={setReqMngList}
                    setReqMngListSelected={setReqMngListSelected}
                    setReqMngListParam={setReqMngListParam}
                    useCancelRequest={true}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({ loginUser: state.auth.user });

const mapDispatchToProps = {};

ReqExtendExpiredDtPop.propTypes = {
    reqId: PropTypes.string,
    reqInfo: PropTypes.object,
    close: PropTypes.func,
    setReqMngListParam: PropTypes.func,
    setReqMngList: PropTypes.func,
    setReqMngListSelected: PropTypes.func,
    loginUser: PropTypes.object.isRequired
};

ReqExtendExpiredDtPop.defaultProps = {
    reqId: '',
    reqInfo: {},
    close: () => {},
    setReqMngListParam: () => {},
    setReqMngList: () => {},
    setReqMngListSelected: () => {}
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqExtendExpiredDtPop));

export default connected;
