import React from 'react';
import { number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SizeConverter from '../../../../utils/SizeConverter';

const Size = props => {
    const { size } = props;
    const isOverBytes = size !== undefined && size >= 1024;
    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.size" />
            </dt>
            <dd>
                <div className="total-count ds-flex">
                    <strong>
                        {SizeConverter.convert(size)}
                        <span className="byte">{isOverBytes && ` (${SizeConverter.withComma(size)})`}</span>
                    </strong>
                </div>
            </dd>
        </>
    );
};

Size.propTypes = {
    size: number
};

Size.defaultProps = {
    size: 0
};

export default Size;
