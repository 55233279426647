import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Url from '../../../utils/Url';
import NumberUtil from '../../../utils/NumberUtil';
import { alert } from '../../../utils/ModalService';

const SimilarSearch = props => {
    const {
        intl,
        history,
        history: {
            location: { search }
        },
        totalCount,
        fileListType
    } = props;

    const { objtNm: initObjtNm = '' } = queryString.parse(search) || {};
    const [keyword, setKeyword] = useState(initObjtNm);
    const [objtNm, setObjtNm] = useState(initObjtNm);
    const [inputFocus, setInputFocus] = useState(false);
    const [similarFilesCount, setSimilarFilesCount] = useState('');

    useEffect(() => {
        history.push({ search: objtNm ? `?${queryString.stringify({ objtNm })}` : '' });
    }, [objtNm]);

    useEffect(() => {
        if (fileListType !== 'similar') return;
        setSimilarFilesCount(totalCount);
    }, [totalCount]);

    const searchByKeyword = () => {
        if (keyword && keyword.trim().length >= 5) {
            setObjtNm(keyword.trim());
        } else {
            alert('drive.alert.list.similar.keyword.minLength');
        }
    };

    const clearKeyword = () => {
        setKeyword('');
    };

    const onClickGoBack = () => {
        history.push(Url.duplicated);
    };

    const onChangeKeyword = ({ target: { value } }) => {
        if (!value || !value.trim()) {
            setKeyword('');
        } else {
            setKeyword(value);
        }
    };

    const onClickSearchBtn = () => {
        searchByKeyword();
    };

    const onKeyUpEnter = ({ key }) => {
        if (key !== 'Enter') return;
        searchByKeyword();
    };

    return (
        <div className="comm-title-bar">
            {objtNm && (
                <>
                    <div className="section-srch-result">
                        <a className="btn-ic-nor btn-func-back" role="button" title={intl.formatMessage({ id: 'com.tooltip.go.back' })} onClick={onClickGoBack}>
                            <i className="ic-20-arrow-back" />
                        </a>
                        <div className="total-count">
                            <span className="count">
                                <FormattedMessage id="drive.text.similar.count" values={{ n: <i className="num">{NumberUtil.getNumberWithCommas(similarFilesCount)}</i> }} />
                            </span>
                        </div>
                    </div>
                </>
            )}
            <div className="section-srch">
                <div className={`box-input-group ${inputFocus ? 'is-focused' : ''}`}>
                    <a className="btn-ic-nor btn-func-search" role="button" title={intl.formatMessage({ id: 'com.search' })} onClick={onClickSearchBtn}>
                        <i className="ic-16-search" />
                    </a>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'drive.placeHolder.similar.keyword' })}
                        value={keyword}
                        onChange={onChangeKeyword}
                        onKeyUp={onKeyUpEnter}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <a
                        className="btn-ic-nor btn-func-clear"
                        role="button"
                        title={intl.formatMessage({ id: 'com.text.clear' })}
                        style={keyword ? { display: 'inline-flex' } : { display: 'none' }}
                        onClick={clearKeyword}>
                        <i className="ic-16-clear" />
                    </a>
                </div>
            </div>
        </div>
    );
};

SimilarSearch.propTypes = {
    history: PropTypes.object.isRequired,
    totalCount: PropTypes.number,
    fileListType: PropTypes.string.isRequired
};

SimilarSearch.defaultProps = {
    totalCount: 0
};

const mapStateToProps = state => ({
    totalCount: state.fileList.totalCount,
    fileListType: state.fileList.fileListType
});

export default withRouter(injectIntl(connect(mapStateToProps)(SimilarSearch)));
