import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import LLMSelectDialog from './LLMSelectDialog';
import { summaryActions } from '../../../../../redux/actions/Summary';
import ClickOutside from '../../../../presentationals/ClickOutside';
import { SUMMARY_THREAD_STATUS } from '../../Common/SummaryConstant';

const LLMSelectButton = () => {
    const llmSelectButtonRef = useRef();
    const llmSelectDialogRef = useRef();

    const threadStatus = useSelector(({ summary }) => summary.thread.threadStatus);
    const { llmName } = useSelector(({ summary }) => summary.global.selectedLLM);
    const isLLMSelectDialogOpen = useSelector(({ summary }) => summary.llmSelectDialog.isOpen);

    const dispatch = useDispatch();

    const handleClick = () => {
        if (isLLMSelectDialogOpen) {
            dispatch(summaryActions.llmSelectDialog.close());
            return;
        }

        dispatch(summaryActions.llmSelectDialog.open());
    };

    const handleClickOutside = () => {
        if (isLLMSelectDialogOpen) {
            dispatch(summaryActions.llmSelectDialog.close());
        }
    };

    return (
        <ClickOutside onClickOutside={handleClickOutside} childrenRefs={[llmSelectButtonRef, llmSelectDialogRef]}>
            <div className="aside-panel-header-option" ref={llmSelectButtonRef}>
                <div className="has-overlay-layer">
                    <a
                        className={classnames('btn', 'btn-sm', 'btn-select-type', isLLMSelectDialogOpen && 'active', threadStatus === SUMMARY_THREAD_STATUS.running && 'disabled')}
                        role="button"
                        title="설정"
                        onClick={handleClick}>
                        <span className="btn-text">{llmName}</span>
                    </a>
                    <LLMSelectDialog llmSelectDialogRef={llmSelectDialogRef} />
                </div>
            </div>
        </ClickOutside>
    );
};

export default LLMSelectButton;
