import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import ApprovalConstants from './ApprovalConstants';
import CreateWorkgroup from './Confiruation/CreateWorkgroup';
import BaseApproval from './Confiruation/BaseApproval';
import ExceptNonCompliance from './Confiruation/ExceptNonCompliance';
import ExtendsExpiration from './Confiruation/ExtendsExpiration';
import ExceptPcControl from './Confiruation/ExceptPcControl';
import ApprovalContainer from './ApprovalContainer';
import ModalAlert from '../../../presentationals/ModalAlert';

const ApprovalPopup = props => {
    const { user, type, target, reqId, req, mode, close, intl, configs, locale = {} } = props;
    const { timezoneDiff = 0 } = locale;

    const getTypeClass = (config = {}) => {
        const intlConfig = _.extend({ intl }, config);

        switch (type) {
            case ApprovalConstants.TYPE.CREATE_WORKGROUP:
                return new CreateWorkgroup(intlConfig);
            case ApprovalConstants.TYPE.EXCEPT_NON_COMPLIANCE:
                return new ExceptNonCompliance(intlConfig);
            case ApprovalConstants.TYPE.EXTENDS_EXPIRATION:
                return new ExtendsExpiration(intlConfig);
            case ApprovalConstants.TYPE.EXCEPT_PC_CONTROL:
                return new ExceptPcControl(intlConfig);
            default:
                return new BaseApproval(intlConfig);
        }
    };

    const typeClass = getTypeClass({
        mode,
        user: {
            userId: user.id,
            userNm: user.name,
            userCopNm: user.jobTitle,
            deptNm: user.dept.name
        },
        reqId,
        req,
        target,
        configs,
        timeZone: timezoneDiff,
        rawUser: user
    });

    // 연계 불가 처리
    if (!user.isInterface) {
        return <ModalAlert message={intl.formatMessage({ id: 'com.alert.approval.invalidUser' })} close={close} />;
    }

    // 연계 업무 시스템 계정이지만, 계정정보 중 EP ID가 누락된 경우
    if (!_.get(user, 'linkage.epId')) {
        return <ModalAlert message={intl.formatMessage({ id: 'com.alert.approval.invalidUserEpIdNotExist' })} close={close} />;
    }

    return <ApprovalContainer typeClass={typeClass} close={close} />;
};

ApprovalPopup.propTypes = {
    user: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
    configs: PropTypes.object,
    target: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    req: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    reqId: PropTypes.string
};

ApprovalPopup.defaultProps = {
    target: {},
    req: {},
    reqId: '',
    configs: {}
};

const mapStateToProps = state => ({
    user: state.auth.user,
    locale: state.auth.user.locale,
    configs: state.config.configs
});

export default injectIntl(connect(mapStateToProps)(ApprovalPopup));
