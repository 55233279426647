import React, { Component } from 'react';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';
import _ from 'lodash';
import NotificationRest from 'apis/NotificationRest';
import NotificationConstants from 'constants/Notification';
import Url from 'utils/Url';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import NotificationItem from 'presentationals/NotificationItem';

class NotificationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        };
    }

    componentDidUpdate(prevProps) {
        const { open: prevOpen } = prevProps;
        const { open } = this.props;
        if (open && prevOpen !== open) {
            this.getNotifications();
        }
    }

    getNotifications = () => {
        NotificationRest.getNotifications(NotificationConstants.FROM_TYPES.GNB).then(response => {
            const { resultCode, data } = response;
            if (resultCode === 200) {
                const { notificationViews } = data;
                _.forEach(notificationViews, notification => {
                    const { clickReadTimestamp } = notification;
                    const changedNotification = notification;
                    changedNotification.read = !!clickReadTimestamp;
                    changedNotification.key = uuid();
                    return changedNotification;
                });
                this.setState({ notifications: notificationViews });
            }
        });
    };

    updateNotificationsAsRead = async () => {
        const response = await NotificationRest.updateAllNotificationsAsRead(true);
        const { resultCode, data } = response;
        if (resultCode !== 200 || data !== true) return;
        this.getNotifications();
    };

    handleClose = () => {
        const { closeAction } = this.props;
        closeAction();
    };

    render() {
        const { open, push, clickOutsideRef } = this.props;
        const { notifications } = this.state;
        return (
            <div className={`overlay-layer-wrapper slide ${open ? 'show' : ''}`} data-toggle-button="#nav-notification" style={{ display: open ? '' : 'none' }} ref={clickOutsideRef}>
                <div className="overlay-layer">
                    <div className="layer-content">
                        <div className="layer-header hd-gray">
                            <h1 className="layer-title">
                                <FormattedMessage id="user.noti.notification" />
                            </h1>
                            <a
                                className="btn btn-sm btn-secondary"
                                role="button"
                                onClick={this.updateNotificationsAsRead}
                                style={{ display: !notifications || notifications.length === 0 ? 'none' : '' }}>
                                <span className="btn-text">
                                    <FormattedMessage id="user.noti.markAllAsRead" />
                                </span>
                            </a>
                        </div>
                        <div className="layer-body">
                            <div className="noti-list" style={{ display: !notifications || notifications.length === 0 ? 'none' : '' }}>
                                <Scrollbars className="scroll">
                                    <div>
                                        <ol style={{ display: !notifications || notifications.length === 0 ? 'none' : '' }}>
                                            <NotificationItem notifications={notifications} clickAction={this.handleClose} />
                                        </ol>
                                    </div>
                                </Scrollbars>
                            </div>
                            <a
                                className="btn btn-secondary btn-block"
                                role="button"
                                onClick={() => push(Url.notification)}
                                style={{ display: !notifications || notifications.length === 0 ? 'none' : '' }}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.viewmore" />
                                </span>
                            </a>
                            <div className="box-empty" style={{ display: !notifications || notifications.length === 0 ? '' : 'none' }}>
                                <div className="message">
                                    <p>
                                        <FormattedMessage id="user.noti.noNotifications" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NotificationPopup.propTypes = {
    open: PropTypes.bool,
    push: PropTypes.func.isRequired,
    closeAction: PropTypes.func,
    clickOutsideRef: PropTypes.object
};

NotificationPopup.defaultProps = {
    open: false,
    closeAction: undefined,
    clickOutsideRef: {}
};

const mapDispatchToProps = {
    push: routerPush
};

export default connect(
    null,
    mapDispatchToProps
)(NotificationPopup);
