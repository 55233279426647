import BaseMenu from '../BaseMenu';
import FoldersRest from '../../../../apis/FoldersRest';
import { toast } from '../../../../utils/ModalService';

const executor = (props, files) => {
    FoldersRest.removePreferFolders(files).then(({ data }) => {
        if (data) {
            const msg = {
                id: `drive.toast.favor.removed${files.length > 1 ? 'Multi' : ''}`,
                values: {
                    '0': files[0].drive.objtNm,
                    '1': files.length - 1
                }
            };
            toast(msg);
            props.callback({ type: 'remove', data, reloadPrefer: true });
        }
    });
};

class RemovePreferredFolder extends BaseMenu {
    getName() {
        return 'removePreferredFolder';
    }

    getLabel() {
        return 'drive.button.list.menu.removePreferredFolder';
    }

    isVisible() {
        return this.files.every(file => file.drive && file.drive.objtSectCd === 'FOLDER' && file.personalization && file.personalization.preferred);
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-fav-del', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-fav-del', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default RemovePreferredFolder;
