import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reqMng as reqMngActions } from '../../../../redux/actions';
import ReqRest from '../../../../apis/ReqRest';
import { confirm, alert as modalAlert, largePopup } from '../../../../utils/ModalService';
import ReqRejectPop from '../Popup/ReqRejectPop';
import Tooltips from '../../Tooltips';
import FileIcon from '../../FileMenu/FileIcon';

class ReqMngBodyReqStat extends Component {
    reqProcess = async (reqPrcsType, index) => {
        const { reqMngList, setReqMngListParam } = this.props;
        const reqPrcsList = [];

        if (_.isNil(index)) {
            _.forEach(reqMngList, function(item) {
                if (item.isChecked) {
                    reqPrcsList.push(item.reqId);
                }
            });
        } else {
            reqPrcsList.push(reqMngList[index].reqId);
        }

        if (reqPrcsType === 'CANCEL') {
            const doRequest = await confirm({ id: 'com.confirm.ReqMngBodyReqStat.cancel' });
            if (!doRequest) {
                return;
            }
        }

        const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

        if (res.resultCode === 500) {
            modalAlert('com.alert.internalServerError');
        } else if (res.resultCode === 400) {
            modalAlert('com.alert.reqInvalidParam');
        } else if (res.resultCode === 403 && !_.isEmpty(res.data)) {
            modalAlert(res.data);
        } else {
            modalAlert(res.message);
        }

        this.initReqs();
        setReqMngListParam({ page: 1, limit: 100, reloadYn: true });
    };

    openReqRejectPop = (index, rejectType) => {
        largePopup(<ReqRejectPop index={index} rejectType={rejectType} />);
    };

    initReqs() {
        const { setReqMngList, setReqMngListSelected } = this.props;

        setReqMngListSelected({ allChecked: false, checkedCnt: 0 });
        setReqMngList({ reqMngList: [] });
    }

    renderReqStat = index => {
        const { reqMngListParam, reqMngList, intl } = this.props;

        if (reqMngListParam.reqType !== 'send') return false;

        if (reqMngList[index].reqStatCd === 'PROCESSING') {
            const { reqConfmTpCd } = reqMngList[index];
            return (
                <div className="grid-cell files-reqStat-cell">
                    <em className="req-pending">
                        <FormattedMessage id="com.text.ReqMngBodyReqStat.processing" />
                    </em>
                    {reqConfmTpCd !== 'KNOX' && (
                        <a className="btn btn-sm btn-secondary" role="button" onClick={() => this.reqProcess('CANCEL', index)}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                            </span>
                        </a>
                    )}
                </div>
            );
        }
        if (reqMngList[index].reqStatCd === 'APPROVAL') {
            const { batStatCd } = reqMngList[index];
            return (
                <div className="grid-cell files-reqStat-cell">
                    <em className="req-finish">
                        {_.isEmpty(batStatCd) && <FormattedMessage id="com.text.ReqMngBodyReqStat.approvalComplete" />}
                        {batStatCd === 'READY' && <FormattedMessage id="com.text.reqAapproved.readyBatch" />}
                        {batStatCd === 'PROCESSING' && <FormattedMessage id="com.text.reqApproved.processingBatch" />}
                        {batStatCd === 'SUCCESS' && <FormattedMessage id="com.text.reqApproved.successBatch" />}
                        {batStatCd === 'FAIL' && <FormattedMessage id="com.text.reqApproved.failBatch" />}
                    </em>
                </div>
            );
        }
        if (reqMngList[index].reqStatCd === 'REJECT') {
            const hasReason = !_.isNil(reqMngList[index].percOpin) && reqMngList[index].percOpin !== '';
            const opinMsg = this.getOpinion(reqMngList[index].percOpin);
            return (
                <div className="grid-cell files-reqStat-cell" id={`req-reqRsn-${reqMngList[index].reqId}`}>
                    <em className="req-reject">
                        <FormattedMessage id="com.text.ReqMngBodyReqStat.reject" />
                        {hasReason && ` (${intl.formatMessage({ id: 'com.title.ReqRejectPop.rejectReason' })} : ${opinMsg})`}
                    </em>
                    {hasReason && (
                        <Tooltips targetId={`req-reqRsn-${reqMngList[index].reqId}`} placement="left">
                            <FormattedMessage id="com.title.ReqRejectPop.rejectReason" /> : {opinMsg.substring(0, 500)}
                        </Tooltips>
                    )}
                </div>
            );
        }

        return (
            <div className="grid-cell files-reqStat-cell">
                <em className="req-cancel">
                    <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                </em>
            </div>
        );
    };

    getOpinion = (opinion = '') => {
        if (!opinion.length) return opinion;

        const trimmed = opinion.trim();

        // JSON 객체
        if (trimmed.startsWith('{') && trimmed.endsWith('}')) {
            try {
                const opinObj = JSON.parse(trimmed) || {};
                const { percOpin = '' } = opinObj;
                return percOpin.trim();
            } catch (e) {
                return trimmed;
            }
        }
        // 일반 문자열
        else {
            return trimmed;
        }
    };

    fileIconCustom = {
        openType: 'normal_left',
        moreGroupType: 'recvReq'
    };

    render() {
        const { reqMngListParam, reqMngList, index, intl, reload } = this.props;
        const isSancYn = !_.isEmpty(reqMngList[index]) && !_.isNil(reqMngList[index].sancIfVal) && reqMngList[index].sancIfVal !== '';

        return (
            <React.Fragment>
                {reqMngListParam.reqType === 'received' && (
                    <div className="grid-cell files-reqOption-cell">
                        <ul className="files-option nav">
                            {!isSancYn && (
                                <>
                                    <li className="option-approved">
                                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.button.approval' })} onClick={() => this.reqProcess('APPROVAL', index)}>
                                            <i className="ic-20-approved" />
                                        </a>
                                    </li>
                                    <li className="option-rejected">
                                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.button.reject' })} onClick={() => this.openReqRejectPop(index, 'SINGLE')}>
                                            <i className="ic-20-rejected" />
                                        </a>
                                    </li>
                                    <FileIcon groupType="recvReq" iconType="recvReq" file={reqMngList[index]} callback={reload} custom={this.fileIconCustom} />
                                </>
                            )}
                        </ul>
                    </div>
                )}
                {this.renderReqStat(index)}
            </React.Fragment>
        );
    }
}

ReqMngBodyReqStat.propTypes = {
    reqMngList: PropTypes.array.isRequired,
    reqMngListParam: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    setReqMngListParam: PropTypes.func,
    setReqMngList: PropTypes.func,
    setReqMngListSelected: PropTypes.func,
    reload: PropTypes.func
};

const mapStateToProps = state => ({
    reqMngList: state.reqMng.reqMngList,
    reqMngListParam: state.reqMng.reqMngListParam
});

ReqMngBodyReqStat.defaultProps = {
    setReqMngListParam: () => {},
    setReqMngList: () => {},
    setReqMngListSelected: () => {},
    reload: () => {}
};

const mapDispatchToProps = {
    setReqMngListParam: reqMngActions.setReqMngListParam,
    setReqMngList: reqMngActions.setReqMngList,
    setReqMngListSelected: reqMngActions.setReqMngListSelected
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqMngBodyReqStat));

export default connected;
