import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ApprovalLine from './ApprovalLine';
import ApprovalOpinion from './ApprovalOpinion';
import CustomModal from '../../CustomModal';

const ApprovalContainer = props => {
    const { typeClass, close, intl } = props;

    const [isSubmittable, setIsSubmittable] = useState(false);
    const [contents, setContents] = useState({});

    const onChangeOpinion = opinion => {
        typeClass.setDraftOpinion(opinion);
        typeClass.checkSubmittable();
    };

    useEffect(() => {
        typeClass.setIsSubmittableSetter(setIsSubmittable);
        typeClass.setContentsSetter(setContents);
    }, [typeClass]);

    const submit = async () => {
        const { result, response } = await typeClass.submit();
        if (result) {
            setTimeout(() => {
                close(typeClass.isThrowResult ? response : true);
            }, typeClass.closeDelay);
        }
    };

    const onClose = () => close(false);

    return (
        <CustomModal isOpen={true} fade={false} backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({ id: typeClass.title })}</h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => close(true)}>
                    <i className="ic-20-close" />
                </a>
            </div>
            {typeClass.infoBuilder()}
            <ModalBody>
                <ApprovalLine typeClass={typeClass} />

                {typeClass.beforeContentBuilder(contents)}

                {typeClass.hasContent() && (
                    <div className="appr-details">
                        <div className="scroll-bar">{typeClass.contentBuilder(contents)}</div>
                    </div>
                )}

                {typeClass.afterContentBuilder(contents)}

                {!typeClass.isViewMode() ? (
                    <ApprovalOpinion typeClass={typeClass} onChange={onChangeOpinion} />
                ) : (
                    <div className="info-msg">
                        <i className="ic-16-info" />
                        {intl.formatMessage({ id: 'com.guideText.approval.cancelMethod' }, { solutionName: 'Drive', ifSolutionName: 'Portal' })}
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-option" />
                {!typeClass.isViewMode() ? (
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClose}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${isSubmittable ? '' : 'disabled'}`} role="button" onClick={submit}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                ) : (
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" onClick={onClose}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                )}
            </ModalFooter>
        </CustomModal>
    );
};

ApprovalContainer.propTypes = {
    typeClass: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired
};

export default injectIntl(ApprovalContainer);
