import $http from 'modules/Http';

const prefix = '/drive/v1/socket';

class SocketRest {
    static getOfflineFilePath(params) {
        return $http.post(`${prefix}/offline`, params);
    }
}

export default SocketRest;
