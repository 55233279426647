import React from 'react';
import BaseMenu from '../BaseMenu';
import LinkDown from '../../FileLink/LinkDownload';
import { popup as ModalPopup } from '../../../../utils/ModalService';

const executor = (props, files) => {
    const selectedFiles = [];

    files.forEach(item => selectedFiles.push(item.drive));
    ModalPopup(<LinkDown folderFileList={selectedFiles} />);
};

class LinkDownload extends BaseMenu {
    getName() {
        return 'linkDownload';
    }

    getLabel() {
        return 'drive.button.list.menu.shortcut';
    }

    isVisible() {
        return super.isVisible(true, 'viewInLinkUrl');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-download', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-download', 'ic-20-download', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default LinkDownload;
