import React from 'react';
import PropTypes from 'prop-types';
import ModalService from '../../../../utils/ModalService';
import ComplianceKeywordsPopup from '../../Popup/ComplianceKeywordsPopup';
import CdmDtctRest from '../../../../apis/CdmDtctRest';

const ComplianceKeywords = props => {
    const { file: { config: { version: { complianceDetectedKeywords = '' } } = {} } = {}, cellStyle = {} } = props;

    const openPopup = event => {
        // 클릭시 행 선택되는것 방지
        event.stopPropagation();

        // 단어 최대 개수 서버에서 가져오기
        CdmDtctRest.getMaxCountWords()
            .then(
                count => count,
                error => {
                    console.log(error);
                    return null;
                }
            )
            .then(count => {
                ModalService()
                    .popup(<ComplianceKeywordsPopup keywords={complianceDetectedKeywords} maxCount={count} />)
                    .then(() => true, () => false);
            });
    };

    return (
        <div className="grid-cell files-prohibited-cell" style={cellStyle}>
            <div className="g-inline-flex text-link">
                <div role="button" className="ellipsis" onClick={openPopup}>
                    {complianceDetectedKeywords}
                </div>
            </div>
        </div>
    );
};

ComplianceKeywords.defaultProps = {
    cellStyle: null
};

ComplianceKeywords.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.object
};

export default ComplianceKeywords;
