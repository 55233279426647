import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Tree, { convertFolderToNode, selectNode } from '../presentationals/Tree';
import FolderRest from '../../apis/FolderRest';
import ClickOutside from '../presentationals/ClickOutside';
import { toast } from '../../utils/ModalService';

class FolderTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            treeData: []
        };
        this.needLoad = true;
        this.clickOutSideRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isOpen, treeData } = this.state;
        if (isOpen !== nextState.isOpen || treeData !== nextState.treeData) return true;
        const { rootFolderId, currentFolderId, reload } = this.props;
        this.needLoad = this.needLoad || rootFolderId !== nextProps.rootFolderId;
        this.needLoad = this.needLoad || currentFolderId !== nextProps.currentFolderId;
        this.needLoad = this.needLoad || (reload !== nextProps.reload && nextProps.reload.folderChanged);
        return false;
    }

    async setFolderTree() {
        const { rootFolderId, browserPathId } = this.props;
        if (!rootFolderId) return;
        const { data, resultCode } = await FolderRest.getFoldertree(rootFolderId);

        if (resultCode === 200) {
            this.setState({ treeData: convertFolderToNode(data) }, this.setSyncCurrentFolder);
            this.needLoad = false;
            return;
        }

        // 정상 응답도 아니고, 워크그룹 루트에 권한이 없는 상태도 아니라면 fail
        if (resultCode !== 403) {
            toast('drive.toast.list.api.foldertree.fail');
            this.closeTree();
            return;
        }

        const shareRootFolderId = _(browserPathId)
            .split('/', 4)
            .last();

        // 워크그룹 루트 권한이 없는 경우, 공유 루트 폴더 기준으로 폴더트리 재조회
        const { data: dataByShareRootFolder, resultCode: retryResultCode } = await FolderRest.getFoldertree(shareRootFolderId);

        if (retryResultCode !== 200) {
            toast('drive.toast.list.api.foldertree.fail');
            this.closeTree();
            return;
        }

        this.setState({ treeData: convertFolderToNode(dataByShareRootFolder) }, this.setSyncCurrentFolder);
        this.needLoad = false;
    }

    setSyncCurrentFolder() {
        const { currentFolderId } = this.props;
        const { treeData } = this.state;

        if (!currentFolderId || !treeData) return;

        const updatedTreeData = selectNode({
            treeData,
            id: currentFolderId
        });

        if (treeData !== updatedTreeData) {
            this.setState({ treeData: updatedTreeData });
        }
    }

    openTree = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.closeTree();
        } else {
            if (this.needLoad) this.setFolderTree();
            this.setState({ isOpen: true });
        }
    };

    closeTree = () => {
        const { isOpen } = this.state;
        if (!isOpen) return;
        this.setState({ isOpen: false });
    };

    treeChanged = treeData => {
        this.setState({ treeData });
    };

    handleNodeClick = (e, { node }) => {
        const { move } = this.props;
        move(node.id);
        this.closeTree();
    };

    render() {
        const { treeData, isOpen } = this.state;
        return (
            <ClickOutside onClickOutside={this.closeTree} childrenRefs={[this.clickOutSideRef]}>
                <div className="func-tree has-overlay-layer" ref={this.clickOutSideRef}>
                    <a id="folderTree" className="btn-ic-nor" role="button" onClick={this.openTree}>
                        <i className="ic-24-foldertree" />
                    </a>
                    <div className="overlay-layer-wrapper">
                        {isOpen && (
                            <div className="overlay-layer" style={{ display: 'block' }}>
                                <div className="layer-content">
                                    <div className="layer-body pd-0">
                                        <div className="tree-wrapper">
                                            <Tree
                                                className="tree-wrapper"
                                                customScroll={true}
                                                height={486}
                                                autoHeight={false}
                                                width={427}
                                                leftPadding={19}
                                                treeData={treeData}
                                                onChange={this.treeChanged}
                                                handleNodeClick={this.handleNodeClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

FolderTree.propTypes = {
    rootFolderId: PropTypes.string,
    currentFolderId: PropTypes.string,
    browserPathId: PropTypes.string,
    move: PropTypes.func,
    reload: PropTypes.object
};

FolderTree.defaultProps = {
    rootFolderId: '',
    currentFolderId: '',
    browserPathId: '',
    move: () => {},
    reload: {}
};

const mapStateToProps = state => ({ reload: state.fileReload.data });
const connected = connect(
    mapStateToProps,
    null
)(FolderTree);

export default connected;
