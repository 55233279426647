import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import $ from 'jquery';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { popup, confirm, toast } from '../../../../utils/ModalService';
import store from '../../../../redux/store';
import OutLinkCreate from './OutLinkCreate';
import CustomList from '../../List/CustomList';
import OutLinkItem from './OutLinkItem';
import OutLinkRest from '../../../../apis/OutLinkRest';
import MoreButton from './MoreButton';
import clipboard from '../../../../utils/Clipboard';

import CoachMarkConstants from '../../../../constants/CoachMark';

class OutLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            newOutLinkId: '',
            loaded: false
        };
        this.limit = 50;
        this.moreScroll = true;
        this.innerRef = null;
        this.isLoading = false;
        this.showMore = false;

        const { intl } = this.props;

        const content = (
            <div className="coachmark-conts" id="outlink-coachmark-content">
                <p>{intl.formatMessage({ id: 'drive.text.coachMark.outlink' })}</p>
                <div className="btns">
                    <a className="coachmark-end" id="coachmark-end">
                        {intl.formatMessage({ id: 'com.text.coachMark.stop' })}
                    </a>
                </div>
            </div>
        );

        this.dataContent = renderToString(content);
    }

    componentDidMount() {
        const { loginUser } = this.props;
        const { id } = loginUser;
        this.ownOutLinksLoad();
        const showCoachMark = localStorage.getItem(`${CoachMarkConstants.COACHMARK_OUTLINK}${id}`);

        const { list } = this.state;

        if (showCoachMark !== 'N' && _.isEmpty(list)) {
            setTimeout(this.showCoachMark, 500);
        }
    }

    showCoachMark = () => {
        this.coachmarkOwner = $('#outlink-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: true
        });

        // $('body').append('<div class="coachmark-backdrop"></div>');
        this.popover.popover('show');
        $('[class*="bs-popover-bottom"]').addClass('coachmark');
        $('#outlink-coachmark-content')
            .parents('.popover')
            .attr('style', 'z-index: 1060;');

        // window.addEventListener('click', this.clickOutSide);

        document.getElementById('coachmark-end').addEventListener('click', this.hide);
        // document.getElementById('end-favourite').addEventListener('click', this.hide);
    };

    clickOutSide = () => {
        $('#outlink-coachmark-content')
            .parents('.popover')
            .attr('style', 'z-index: 1049; opacity: 0; display: none;');
    };

    setNLocalStorage = () => {
        const { loginUser } = this.props;
        const { id } = loginUser;
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_OUTLINK}${id}`, 'N');
    };

    hide = () => {
        const ccp = $('.coachmark-conts').parents('.popover');
        //
        // $('body > div.coachmark-backdrop').remove();

        ccp.animate({ opacity: 0 }, 'fast', 'swing');
        this.setNLocalStorage();
    };

    async ownOutLinksLoad() {
        const { list } = this.state;
        const { files } = this.props;

        this.isLoading = true;
        this.showMore = false;

        let hasMoreBtn = false;
        let id = null;
        if (list.length > 1) {
            let lastIdx = list.length - 1;

            if (!this.moreScroll && list[lastIdx].outLinkId === 'more') {
                lastIdx = list.length - 2;
                hasMoreBtn = true;
            }

            id = list[lastIdx].outLinkId;
        }

        const { data } = await OutLinkRest.getOwnOutLinks(files[0].drive.objtId, id, this.limit);

        let newList = [];
        if (!!data && data.length > 0) {
            if (hasMoreBtn) {
                newList = [...list.slice(0, list.length - 1), ...data];
            } else {
                newList = [...list, ...data];
            }

            if (data.length === this.limit) {
                this.showMore = true;

                if (!this.moreScroll) {
                    newList.push({ outLinkId: 'more' });
                }
            }
        } else if (hasMoreBtn) {
            newList = [...list.slice(0, list.length - 1)];
        }

        this.setState({ list: newList, loaded: true });
        this.isLoading = false;
    }

    async justCreatedLinkLoad(outLinkId) {
        const { files } = this.props;
        const { list } = this.state;
        const { data } = await OutLinkRest.getOwnOutLinks(files[0].drive.objtId, outLinkId);

        if (!!data && data.length > 0) {
            this.setState({ newOutLinkId: outLinkId, list: [...data, ...list], loaded: true });
        }
    }

    async deleteLink(outLinkId, outLinkObjtId, outLinkFileVerSno, outLinkOpnstId) {
        const { data } = await OutLinkRest.deleteOutLink(outLinkId, outLinkObjtId, outLinkFileVerSno, outLinkOpnstId);

        if (data) {
            // 외부 링크가 삭제 되었습니다.
            store.dispatch(toast('drive.toast.outLink.deleted'));

            const { list } = this.state;
            const index = list.findIndex(l => l.outLinkId === data);

            if (index > -1) {
                this.setState({
                    list: [...list.slice(0, index), ...list.slice(index + 1, list.length)]
                });
            }
        }
    }

    handleCreate = () => {
        const { files } = this.props;

        OutLinkRest.getOutLinkConfigs().then(configs => {
            const { resultCode, data } = configs;
            if (resultCode !== 200) {
                alert('외부링크 설정조회에러!');
                // const { close } = this.props;
                // close();
            }
            popup(<OutLinkCreate files={files} configs={data} />).then(outLinkId => {
                if (outLinkId) {
                    this.justCreatedLinkLoad(outLinkId);
                }
            });
        });

        this.clickOutSide();
    };

    handleRemove = outLink => {
        const { outLinkId, outLinkObjtId, outLinkFileVerSno } = outLink;
        const { files } = this.props;
        const { onpstId } = files[0].drive;
        // 외부 링크를 삭제하시 겠습니까?
        confirm(<FormattedMessage id="drive.confirm.outLink.delete" />).then(data => {
            if (data) {
                this.deleteLink(outLinkId, outLinkObjtId, outLinkFileVerSno, onpstId);
            }
        });
    };

    handleCopyUrl = outLink => {
        if (clipboard.set(outLink.outLinkUrl)) {
            // 외부 링크가 복사 되었습니다.
            store.dispatch(toast('drive.toast.outLink.copied'));
        } else {
            store.dispatch(toast('drive.toast.linkCopy.fail'));
        }
    };

    handleMore = () => {
        this.ownOutLinksLoad();
    };

    setInnerRef = innerRef => {
        this.innerRef = innerRef;
    };

    loadMoreByScroll = _.debounce((scroll = {}) => {
        if (!this.innerRef || !this.showMore || this.isLoading) return;

        const { target: scrollTarget } = scroll;
        if (!scrollTarget) return;

        const height = 482;
        const {
            current: { scrollHeight }
        } = this.innerRef;
        const { scrollTop } = scrollTarget;

        if (scrollHeight - (height + scrollTop) < 100) {
            this.ownOutLinksLoad();
        }
    }, 150);

    rowBuilder = ({ style, index }) => {
        const { list, newOutLinkId } = this.state;
        if (list[index].outLinkId !== 'more') {
            return <OutLinkItem outLink={list[index]} newOutLinkId={newOutLinkId} onCopyUrl={this.handleCopyUrl} onRemove={this.handleRemove} style={style} key={list[index].outLinkId} />;
        }
        return <MoreButton key="more" style={style} onMore={this.handleMore} />;
    };

    // drive.text.coachMark.outlink

    renderList = () => {
        const { list, loaded } = this.state;

        if (list.length > 0) {
            return (
                <div className="comm-grid-list react-list">
                    <CustomList
                        className="grid-row-group"
                        customScroll={true}
                        virtualized={true}
                        height={482}
                        rowCount={list.length}
                        rowHeight={70}
                        rowBuilder={this.rowBuilder}
                        handleScroll={this.moreScroll ? this.loadMoreByScroll : null}
                        onDidMount={this.setInnerRef}
                    />
                </div>
            );
        }
        if (loaded) {
            return (
                <div className="box-empty row-6_5">
                    <div className="message">
                        <p>
                            <FormattedMessage id="drive.guideText.outLink.notExist" />
                        </p>
                        <p>
                            <FormattedHTMLMessage id="drive.guideText.outLink.desc" tagName="span" />
                        </p>
                    </div>
                </div>
            );
        }
        return <div className="box-empty row-6_5" />;
    };

    render() {
        return (
            <div className="modal-body">
                <a
                    className="btn-ic-line btn-block has-coachmark"
                    id="outlink-coachmark"
                    role="button"
                    onClick={e => {
                        this.handleCreate();
                        e.stopPropagation();
                    }}
                    data-placement="bottom"
                    data-content={this.dataContent}>
                    <i className="ic-20-link" />
                    <span className="btn-text">
                        <FormattedMessage id="drive.button.outLink.create" />
                    </span>
                </a>

                <div className="data-grid-list">{this.renderList()}</div>
            </div>
        );
    }
}

OutLink.propTypes = {
    files: PropTypes.array,
    loginUser: PropTypes.object.isRequired
};

OutLink.defaultProps = {
    files: []
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(OutLink));
