import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Request from '../../../../constants/Request';
import RequestPopup from '../../../containers/Popup/RequestPopup';

const WorkgroupCreateButton = props => {
    const { callback } = props;
    const openCreatePopup = () => {
        RequestPopup.open({
            mode: Request.MODE.REQUEST,
            type: Request.TYPE.CREATE_WORKGROUP,
            reload: callback
        });
    };

    return (
        <ul className="filter-option nav">
            <li className="nav-item">
                <a className="btn-ic-nor" role="button" onClick={openCreatePopup}>
                    <i className="ic-20-workgroups" />
                    <span className="btn-text">
                        <FormattedMessage id="drive.button.menu.create.workgroup" />
                    </span>
                </a>
            </li>
        </ul>
    );
};

WorkgroupCreateButton.propTypes = {
    callback: PropTypes.func
};

WorkgroupCreateButton.defaultProps = {
    callback: () => {}
};

export default WorkgroupCreateButton;
