import _ from 'lodash';

const getAllowCodesWithJoin = (codes = '', allowCodes = [], defaultVal = '', joinStr = ',') => {
    if (!codes || !codes.length) return defaultVal;

    const splited = codes.split(',');
    const newCodes = [];
    _.forEach(splited, code => {
        if (allowCodes.indexOf(_.trim(code)) !== -1) newCodes.push(_.trim(code));
    });
    return newCodes.length ? _.join(newCodes, joinStr) : defaultVal;
};

export default { getAllowCodesWithJoin };
