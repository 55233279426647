// action type
const PREFIX = 'PARTNER_ACCOUNT_';

export const OPEN_POPUP = PREFIX.concat('OPEN_POPUP');
export const OPEN_POPUP_AFTER_SET_CONFIGS = PREFIX.concat('OPEN_POPUP_AFTER_SET_CONFIGS');
export const CLOSE_POPUP = PREFIX.concat('CLOSE_POPUP');
export const ADD_PARTNER = PREFIX.concat('ADD_PARTNER');
export const REMOVE_PARTNER = PREFIX.concat('REMOVE_PARTNER');
export const CHANGE_PARTNER = PREFIX.concat('CHANGE_PARTNER');
export const CHANGE_PARTNER_VALUE = PREFIX.concat('CHANGE_PARTNER_VALUE');
export const CHANGE_PARTNER_VALIDATION_VALUE = PREFIX.concat('CHANGE_PARTNER_VALIDATION_VALUE');
export const SET_DEFAULT_LANGUAGE = PREFIX.concat('SET_DEFAULT_LANGUAGE');
export const RESET_PARTNER_LIST = PREFIX.concat('RESET_PARTNER_LIST');
export const SET_TEAM_SEARCH_INDEX = PREFIX.concat('SET_TEAM_SEARCH_INDEX');
export const SET_IS_SAVE_ABLE = PREFIX.concat('SET_IS_SAVE_ABLE');
export const SET_REGISTER_TYPE = PREFIX.concat('SET_REGISTER_TYPE');
export const SET_EXPIRE_DATE_CONFIGS = PREFIX.concat('SET_EXPIRE_DATE_CONFIGS');
export const SET_DEFAULT_USER_IF_YN = PREFIX.concat('SET_DEFAULT_USER_IF_YN');
export const SET_DEFAULT_PARTNER_IF_YN = PREFIX.concat('SET_DEFAULT_PARTNER_IF_YN');

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const openPopup = () => action(OPEN_POPUP);
export const closePopup = () => action(CLOSE_POPUP);
export const addPartner = () => action(ADD_PARTNER);
export const removePartner = index => action(REMOVE_PARTNER, { index });
export const changePartnerValue = (index, target, value) => action(CHANGE_PARTNER_VALUE, { index, target, value });
export const changePartnerValidationValue = (index, target, value) => action(CHANGE_PARTNER_VALIDATION_VALUE, { index, target, value });
export const setDefaultLanguage = language => action(SET_DEFAULT_LANGUAGE, { language });
export const resetPartnerList = () => action(RESET_PARTNER_LIST);
export const setTeamSearchIndex = index => action(SET_TEAM_SEARCH_INDEX, { index });
export const setIsSaveAble = isSaveAble => action(SET_IS_SAVE_ABLE, { isSaveAble });
export const setRegisterType = registerType => action(SET_REGISTER_TYPE, { registerType });
export const setExpireDateConfigs = maxUseDays => action(SET_EXPIRE_DATE_CONFIGS, { maxUseDays });
export const setDefaultUserIfYn = userIfYn => action(SET_DEFAULT_USER_IF_YN, { userIfYn });
export const setDefaultPartnerIfYn = partnerIfYn => action(SET_DEFAULT_PARTNER_IF_YN, { partnerIfYn });
