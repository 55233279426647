import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class ReqDtlBodyDtl extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, content } = this.props;

        return (
            <React.Fragment>
                <dl className="row-list text-info">
                    <dt>
                        <FormattedMessage id={title} />
                    </dt>
                    <dd>
                        <div className="dd-text">{content}</div>
                    </dd>
                </dl>
            </React.Fragment>
        );
    }
}

ReqDtlBodyDtl.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string
};

ReqDtlBodyDtl.defaultProps = {
    title: '',
    content: ''
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlBodyDtl));

export default connected;
