import React from 'react';
import { FormattedMessage } from 'react-intl';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import FileCache from '../FileCache';
import Base from './Base';

class Own extends Base {
    pageId = 'own';

    dndAble = true;

    buildCache(folderId) {
        return new FileCache(file => {
            if (file && file.mine && file.drive && file.drive.onpstFolderId === folderId) return file;
            return null;
        });
    }

    rowBuilder({ list, loadMore, style, index, isListDragging, setRowDragging, getRowDragging, isRowDragging }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return (
            <NormalRow
                file={list[index]}
                index={index}
                style={style}
                // LIST ITEM 드래그 관련
                isListDragging={isListDragging}
                setRowDragging={setRowDragging}
                getRowDragging={getRowDragging}
                isRowDragging={isRowDragging}
                // LIST 드래그 OVER 관련
                // isListDragOver={isListDragOver}
                // getListDragOver={getListDragOver}
                // setListDragOver={setListDragOver}
            />
        );
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-list" />
                    <p>
                        <FormattedMessage id="drive.text.list.empty.notExists" />
                    </p>
                    <p>
                        <FormattedMessage id="drive.text.list.empty.upload.first" />
                        <br />
                        <FormattedMessage id="drive.text.list.empty.upload.second" />
                    </p>
                </div>
            </div>
        );
    }
}

export default new Own();
