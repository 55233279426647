import React from 'react';
import { object } from 'prop-types';
import { If } from 'jsx-control-statements';
import DateConverter from '../../../../utils/DateConverter';
import TypeName from './TypeName';

const HistoryFolderType = props => {
    const { history } = props;

    let className = '';
    const viewFolder = `${history.prextOnpstFolderNm} → ${history.onpstFolderNm}`;

    if (history.evtSectCd === 'MOVE') className = 'ic-20-move';
    if (history.evtSectCd === 'COPY') className = 'ic-20-copyfile';

    const occuDate = [DateConverter.convert(history.evtOccuDt)];

    return (
        <React.Fragment>
            <dd>
                <span className="btn-ic-nor">
                    <i className={className} />
                </span>
                <TypeName type={history.evtSectCd} objtSectCd={history.objtSectCd} />
                <div className="dd-detail history-what">
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={viewFolder}>
                            {viewFolder}
                        </div>
                    </div>
                </div>
                <div className="dd-detail history-who">
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={history.evtReqstrNm}>
                            {history.evtReqstrNm}
                        </div>
                        <If condition={history.objtSectCd === 'FILE'}>
                            <div className="next divider">
                                <span className="version" title={history.fileVerSno}>
                                    v{history.fileVerSno}
                                </span>
                            </div>
                        </If>
                        <div className="next divider">{occuDate}</div>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    );
};

HistoryFolderType.propTypes = {
    history: object
};

HistoryFolderType.defaultProps = {
    history: {}
};

export default HistoryFolderType;
