export const SITE_MODE = {
    unknown: 'unknown',
    normal: 'normal',
    offlineWg: 'offwork'
};

export const isOffWorkSite = siteMode => {
    return siteMode === SITE_MODE.offlineWg;
};

export const isNotOffWorkSite = siteMode => {
    return !isOffWorkSite(siteMode);
};
