import { $http } from 'modules/index';

class OutLinkRest {
    static outLinkCreate(outLinkObjtId, outLinkFileVerSno, outLinkOnpstId, outLinkExpireDt, outLinkDownloadLmtCnt, outLinkPwd) {
        const uri = '/drive/v1/out-link';
        const data = {
            outLinkObjtId,
            outLinkFileVerSno,
            outLinkOnpstId,
            outLinkExpireDt,
            outLinkDownloadLmtCnt,
            outLinkPwd
        };
        return $http.post(uri, data, {}).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static getOwnOutLinks(objtId, outLinkId, limit) {
        const params = {
            outLinkId,
            limit
        };
        return $http.get(`/drive/v1/out-link/own/${objtId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static deleteOutLink(outLinkId, outLinkObjtId, outLinkFileVerSno, outLinkOnpstId) {
        const params = {
            outLinkObjtId,
            outLinkFileVerSno,
            outLinkOnpstId
        };
        return $http.delete(`/drive/v1/out-link/${outLinkId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static getOutLinkConfigs() {
        return $http.get('/drive/v1/out-link/configs').then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}

export default OutLinkRest;
