import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CharacterImageContainer from 'containers/CharacterImage/CharacterImageContainer';
import { If } from 'jsx-control-statements';
import UserCard from '../NameCard/UserCard';

const SearchUserRow = props => {
    const { checkSearchUser, index, user, style, checkBox, searchByDept, singleSearch, checkSearchUserForSingleSelect, profileCache, cancelToken } = props;

    const checkSearchRow = event => {
        if (singleSearch) {
            checkSearchUserForSingleSelect(user);
            return;
        }

        // event.target : 클릭된 태그
        // event.currentTarget : 이벤트가 바인딩된 태그
        if (!event.shiftKey && event.target !== event.currentTarget) {
            return;
        }

        checkSearchUser(index, !user.checked, event);
    };

    const checkSelectedRow = event => {
        checkSearchUser(index, !user.checked, event);
    };

    return (
        <div className={_.get(user, 'isHovered') ? 'grid-row is-checked' : 'grid-row'} role="button" style={style} onClick={checkSearchRow}>
            {checkBox ? (
                <div className="grid-cell check-cell">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" checked={user.checked} onClick={checkSelectedRow} />
                            <span className="label-text" />
                        </label>
                    </div>
                </div>
            ) : (
                <div />
            )}
            <If condition={searchByDept}>
                <div className="grid-cell name-cell">
                    <div className="item-user">
                        <CharacterImageContainer
                            objtId={user.userPhotoFilePathVal || ''}
                            userId={user.userId}
                            text={user.userLangCdNm}
                            size="sm"
                            userType={user.userSectCd}
                            updateTime={0}
                            cache={profileCache}
                            cancelToken={cancelToken}
                        />
                        <div className="user-profile">
                            <div title={user.userLangCdNm} role="button" id={`UserCard_${user.userId}`}>
                                <span className="name has-name-card">{user.userLangCdNm}</span>
                                <UserCard target={`UserCard_${user.userId}`} id={user.userId} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-cell position-cell">
                    <span className="position" title={user.userLangCdCopNm}>
                        {user.userLangCdCopNm}
                    </span>
                </div>
                <div className="grid-cell email-cell">
                    <span className="email" title={user.userLoginId}>
                        {user.userLoginId}
                    </span>
                </div>
            </If>
            <If condition={!searchByDept}>
                <div className="grid-cell name-cell">
                    <div className="item-user">
                        <CharacterImageContainer
                            objtId={user.userPhotoFilePathVal || ''}
                            userId={user.userId}
                            text={user.userLangCdNm}
                            size="sm"
                            userType={user.userSectCd}
                            updateTime={0}
                            cache={profileCache}
                            cancelToken={cancelToken}
                        />
                        <div className="user-profile">
                            <div title={user.userLangCdNm} role="button" id={`UserCard_${user.userId}`}>
                                <span className="name has-name-card">{user.userLangCdNm}</span>
                                <UserCard target={`UserCard_${user.userId}`} id={user.userId} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-cell position-cell">
                    <span className="position" title={user.userLangCdCopNm}>
                        {user.userLangCdCopNm}
                    </span>
                </div>
                <div className="grid-cell department-cell">
                    <span className="department" title={user.deptLangCdNm}>
                        {user.deptLangCdNm}
                    </span>
                </div>
                <div className="grid-cell email-cell">
                    <span className="email" title={user.userLoginId}>
                        {user.userLoginId}
                    </span>
                </div>
                <div className="grid-cell mobile-cell">
                    <span className="mobile">{user.userCelno}</span>
                </div>
            </If>
        </div>
    );
};

export default SearchUserRow;

SearchUserRow.propTypes = {
    checkSearchUser: PropTypes.func,
    user: PropTypes.object,
    style: PropTypes.object,
    checkBox: PropTypes.bool,
    index: PropTypes.number,
    searchByDept: PropTypes.bool,
    singleSearch: PropTypes.bool,
    checkSearchUserForSingleSelect: PropTypes.func,
    profileCache: PropTypes.object,
    cancelToken: PropTypes.object
};

SearchUserRow.defaultProps = {
    checkSearchUser: _.noop,
    user: {},
    style: {},
    checkBox: false,
    index: 0,
    searchByDept: false,
    singleSearch: false,
    checkSearchUserForSingleSelect: _.noop,
    profileCache: null,
    cancelToken: {}
};
