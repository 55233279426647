import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import TermContent from '../../presentationals/AuthProcess/TermContent';

const LabelWithCheckBoxNewTerms = props => {
    const { getChecked, checkAll, currentSelectedTerm, checkAllClick, forceUncheck } = props;

    const [checked, setChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        console.log('label usEffect', checkAll, checkAllClick);
        if (checkAllClick) {
            if (checkAll) {
                setChecked(true);
                getChecked(true);
            } else {
                setChecked(false);
                getChecked(false);
            }
        }
    }, [checkAll, checkAllClick]);

    useEffect(() => {
        setChecked(false);
        getChecked(false);
    }, [forceUncheck]);

    const setCheckBoxValue = v => {
        setChecked(v);
        getChecked(currentSelectedTerm.trmsCd, v);
    };
    const setCheckBoxValueWithPopup = v => {
        setChecked(v);
        getChecked(currentSelectedTerm.trmsCd, v);
        setIsOpen(false);
    };

    // const requiredTag =
    //     currentSelectedTerm.ncsrYn === 'Y' ? (
    //         <strong>
    //             [<FormattedMessage id="com.text.newTerms.essential" />]
    //         </strong>
    //     ) : (
    //         <strong>
    //             [<FormattedMessage id="com.text.newTerms.selective" />]
    //         </strong>
    //     );
    // const requiredMark = required ? <em className="critical-item" title="필수항목" /> : <></>;

    const onClickCloseTermContent = param => {
        console.log('onClickTermContent', param);
        setIsOpen(false);
    };
    const onClickTermContent = () => {
        // console.log('onClickTermContent', param);
        // this.setState({ currentSelectedTerm: param });

        setIsOpen(true);
    };
    return (
        <>
            <div className="form-check">
                <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" onClick={() => setCheckBoxValue(!checked)} checked={checked} />
                    <span className="label-text">
                        {/* <strong>{requiredTag}</strong> */}
                        {currentSelectedTerm.trmsTtl}
                    </span>
                </label>

                {currentSelectedTerm.trmsCn !== '' && currentSelectedTerm.trmsCn !== undefined && currentSelectedTerm.trmsCn !== null ? (
                    <a className="btn-link" role="button" onClick={() => onClickTermContent()}>
                        <FormattedMessage id="com.text.newTerms.viewDetail" />
                    </a>
                ) : (
                    <></>
                )}
            </div>
            <TermContent isOpen={isOpen} currentSelectedTerm={currentSelectedTerm} onClickCancelButton={onClickCloseTermContent} onClickConfirmButton={setCheckBoxValueWithPopup} />
        </>
    );
};

LabelWithCheckBoxNewTerms.propTypes = {
    getChecked: PropTypes.func,
    checkAll: PropTypes.bool,
    checkAllClick: PropTypes.bool,
    currentSelectedTerm: PropTypes.any,
    forceUncheck: PropTypes.any
};

LabelWithCheckBoxNewTerms.defaultProps = {
    getChecked: () => {},
    checkAll: false,
    checkAllClick: false,
    currentSelectedTerm: {},
    forceUncheck: ''
};

export default LabelWithCheckBoxNewTerms;
