import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import store from '../../../redux/store';
import { POINT_TYPE } from '../../../constants/DragDrop';

class DndPointer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemCount: 0,
            isShow: false
        };

        this.isShow = false;
        this.spanRef = null;

        this.nowX = 0;
        this.nowY = 0;
    }

    componentDidMount() {
        this.dragSubscribe();
        document.addEventListener('drag', this.onDrag);
    }

    componentWillUnmount() {
        if (this.dragUnsubscribe && _.isFunction(this.dragUnsubscribe)) {
            this.dragUnsubscribe();
        }
        document.removeEventListener('drag', this.onDrag);
    }

    dragSubscribe = () => {
        this.dragUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { dragDrop: dragDropState } = storeState;
            if (dragDropState === this.prevDragDropState) {
                return;
            }
            this.prevDragDropState = dragDropState;

            const { isShow = false, itemCount = 0 } = this.state;
            const { isDragging = false, from = '', items = [], cursor = {} } = dragDropState;
            const dragItemCount = items ? items.length : 0;

            // LIST TO TREE에만 적용
            if (from && from !== POINT_TYPE.LIST) {
                this.spanRef.style.display = 'none';
                return;
            }

            if (isShow !== isDragging || itemCount !== dragItemCount) {
                // 시작위치 지정
                if (isDragging) {
                    if (cursor.x && cursor.y) {
                        this.setPosition(cursor.x, cursor.y);
                    }
                    this.spanRef.style.display = 'block';
                } else {
                    this.spanRef.style.display = 'none';
                }
                this.setState({ isShow: isDragging, itemCount: dragItemCount });
            }
        });
    };

    setPosition = (x, y, dis = 5) => {
        if (!this.spanRef || (x === 0 && y === 0)) return;
        if (this.nowX === x && this.nowY === y) return;

        const revisedX = Math.min(window.innerWidth - 110, x);
        const revisedY = Math.min(window.innerHeight - 50, y);

        this.spanRef.style.top = `${revisedY + dis}px`;
        this.spanRef.style.left = `${revisedX + dis}px`;

        this.nowX = revisedX;
        this.nowY = revisedY;
    };

    onDrag = e => {
        const { pageX, pageY } = e;
        this.setPosition(pageX, pageY);
    };

    render() {
        const { intl } = this.props;
        const { itemCount } = this.state;
        const style = {
            position: 'absolute',
            top: '0px',
            left: '0px',
            display: 'none'
        };
        const message = intl.formatMessage({ id: 'drive.guideText.dragdrop.move.pointer' }, { n: itemCount });

        return (
            <>
                <span
                    className="drvfile-dnd"
                    style={style}
                    ref={ref => {
                        this.spanRef = ref;
                    }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </>
        );
    }
}

export default injectIntl(DndPointer);
