/* eslint-disable */
class BlobFile {

        constructor(blob, name) {
            this._createFromBlob(blob, name);
        }

        _createFromBlob(blob, name) {
            this.file = blob;
            this.lastModifiedDate = new Date();
            this.size = blob.size;
            this.type = blob.type;
            this.name = name;
        }

}

export default BlobFile;