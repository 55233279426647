import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getFilterMessageIdByLengthType } from '../../../Common/Util/SummaryMessageUtil';

const LengthOptionItem = memo(({ type, selectedLengthType, onSelect }) => {
    const handleClick = () => {
        onSelect(type);
    };

    return (
        // TODO lint 확인
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={classnames('chip-check', type === selectedLengthType && 'active')} onClick={handleClick}>
            <span className="text">
                <FormattedMessage id={getFilterMessageIdByLengthType(type)} />
            </span>
        </div>
    );
});

LengthOptionItem.propTypes = {
    type: PropTypes.string.isRequired,
    selectedLengthType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};

LengthOptionItem.defaultProps = {};

export default LengthOptionItem;
