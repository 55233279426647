// action type
const PREFIX = 'AUTH_';
export const SET_TOKEN_STATUS = PREFIX.concat('SET_TOKEN_STATUS');

export const TOKEN_STATUS = {
    WAIT: 'WAIT',
    INIT: 'INIT',
    TEMP: 'TEMP',
    AUTH: 'AUTH_ACTIONS',
    NORMAL: 'NORMAL',
    AB_NORMAL: 'AB_NORMAL',
    EXPIRED: 'EXPIRED',
    ACCEPT_TERMS: 'ACCEPT_TERMS',
    ACCEPT_NEW_TEMRS: 'ACCEPT_NEW_TEMRS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD'
};

export const SET_USER = PREFIX.concat('SET_USER');
export const SET_SETTINGS = PREFIX.concat('SET_SETTINGS');
export const SET_PROFILE_FILE_ID = PREFIX.concat('SET_PROFILE_FILE_ID');
export const SET_LOGIN_INFO = PREFIX.concat('SET_LOGIN_INFO');
const defaultTokenStore = {
    user: {},
    auth: {},
    expirationAction: {}
};

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const setTokenStatus = (tokenStatus, { user = {}, auth = {}, expirationAction = {} } = defaultTokenStore) => action(SET_TOKEN_STATUS, { tokenStatus, user, auth, expirationAction });
export const setUser = user => action(SET_USER, { user });
export const setSettings = (languageCode, timeZone, timezoneDiff) => action(SET_SETTINGS, { languageCode, timeZone, timezoneDiff });
export const setProfileFileId = profileFileId => action(SET_PROFILE_FILE_ID, { profileFileId });
export const setLoginInfo = loginInfo => action(SET_LOGIN_INFO, { loginInfo });
