import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Resizable } from 're-resizable';

// const PATTERN_7 = /[a-z ]/g;
// const PATTERN_9 = /[A-Z]/g;

const MAX_WIDTH = 280;
const MIN_WIDTH = 60;

// const getMinWidth = (name = '', width = 0, sortable) => {
//     let minWidth = 0;
//     let { length } = name;
//
//     const matcher7 = name.match(PATTERN_7);
//     if (matcher7 && matcher7.length) {
//         minWidth += matcher7.length * 7;
//         length -= matcher7.length;
//     }
//
//     const matcher9 = name.match(PATTERN_9);
//     if (matcher9 && matcher9.length) {
//         minWidth += matcher9.length * 9;
//         length -= matcher9.length;
//     }
//
//     if (length > 0) {
//         minWidth += length * 14 + sortable ? 32 : 0;
//     }
//     return Math.min(width, minWidth);
// };

class SortHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isResizing: false
        };
        this.ref = null;
    }

    toggleSort = () => {
        const { setSortingOption, sort, column } = this.props;
        const newSort = {
            sortField: column.id
        };
        if (sort.sortField === column.id) {
            newSort.sortOrder = sort.sortOrder === 'DESC' ? 'ASC' : 'DESC';
        } else {
            newSort.sortOrder = column.order;
        }
        setSortingOption({ sort: newSort });
    };

    onResizeStart = () => {
        this.setState({
            isResizing: true
        });
    };

    onResizeStop = (event, direction, ref, delta) => {
        const { column, setColumnWidth } = this.props;
        const { width: deltaWidth = 0 } = delta;
        const width = column.width + deltaWidth;
        setColumnWidth(column.id, width);
        this.setState({
            isResizing: false
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            column: { width: newWidth },
            isLast: newIsLast,
            isIE
        } = this.props;
        const {
            column: { width: oldWidth },
            isLast: oldIsLast
        } = prevProps;

        let isChangeBySetting = false;
        // 마지막 컬럼 여부 변경
        if (oldIsLast !== newIsLast) {
            isChangeBySetting = true;
        }

        // 컬럼 초기화에 의한 변경처리
        if (!prevState.isResizing && oldWidth !== newWidth) {
            isChangeBySetting = true;
        }

        if (isChangeBySetting && this.ref) {
            let correction = newIsLast ? 8 : 32;
            if (isIE) correction = 0;

            const width = newWidth + correction;
            this.ref.updateSize({ width, height: 'auto' });
            this.ref.resizable.style.flexBasis = `${width}px`;
        }
    }

    componentDidMount() {
        const { column, setColumnRef } = this.props;
        setColumnRef(column.id, this.ref);
    }

    render() {
        const { sort, column, sortPossibleColumns, resizable, isLast, intl, remainWidth, isIE } = this.props;
        const { width, maxWidth } = column;
        const { isResizing } = this.state;
        let arrow = '';
        let parrow = '';
        let sortClick = !!column.order;
        if (!sortPossibleColumns || sortPossibleColumns.includes(column.id)) {
            if (column.order) {
                // 마우스 오버시 화살표값 표시
                if (column.order === 'DESC') {
                    arrow = 'ic-12-arr-desc';
                } else {
                    arrow = 'ic-12-arr-asce';
                }
            }
            if (column.id === sort.sortField) {
                // 화살표값 표시
                if (sort.sortOrder === null || sort.sortOrder === 'DESC') {
                    parrow = 'desc';
                    arrow = 'ic-12-arr-desc';
                } else {
                    parrow = 'asce';
                    arrow = 'ic-12-arr-asce';
                }
            }
        } else {
            sortClick = false;
        }

        const columnName = intl.formatMessage({ id: column.name });
        const columnComp = (
            <>
                {column.order && sortClick ? (
                    <a role="button" className={`column-sort ${parrow}`} onClick={this.toggleSort}>
                        <em>{columnName}</em>
                        <span className="btn-ic-nor">
                            <i className={arrow} />
                        </span>
                    </a>
                ) : (
                    <span className="column-name ellipsis">{columnName}</span>
                )}
            </>
        );
        const cellClassName = `grid-cell ${column.headerClass}`;

        const correction = isLast ? 8 : 32;
        let correctedWidth = width;
        let correctedMinWidth = MIN_WIDTH;
        let correctedMaxWidth = Math.min(width + remainWidth, maxWidth || MAX_WIDTH);

        if (isIE) {
            if (isResizing) {
                correctedMaxWidth += correction;
            } else {
                correctedMinWidth = width + correction;
                correctedMaxWidth = width + correction;
            }
        } else {
            correctedWidth += correction;
            correctedMinWidth += correction;
            correctedMaxWidth += correction;
        }

        const cellStyle = Object.assign(
            {},
            {
                flexBasis: correctedWidth
            },
            isLast ? { paddingRight: '0px' } : { paddingRight: '24px' }
        );

        return (
            <>
                {resizable ? (
                    <Resizable
                        className={cellClassName}
                        enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                        ref={ref => {
                            this.ref = ref;
                        }}
                        onResize={this.onResizeStart}
                        onResizeStop={this.onResizeStop}
                        defaultSize={{
                            width: correctedWidth
                        }}
                        minWidth={correctedMinWidth}
                        maxWidth={correctedMaxWidth}
                        style={cellStyle}
                        handleClasses={{
                            left: 'grid-cell'
                        }}
                        handleStyles={{
                            left: {
                                paddingRight: '0px'
                            }
                        }}
                        handleComponent={{
                            left: <em className={isResizing ? 'col-resizer-guide' : 'col-resizer'} style={{ marginLeft: '-3px' }} />
                        }}>
                        {columnComp}
                    </Resizable>
                ) : (
                    <div className={cellClassName}>{columnComp}</div>
                )}
            </>
        );
    }
}

SortHeader.defaultProps = {
    sortPossibleColumns: null,
    resizable: false,
    isLast: false,
    remainWidth: 0,
    isIE: false
};

SortHeader.propTypes = {
    setSortingOption: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    sortPossibleColumns: PropTypes.array,
    column: PropTypes.object.isRequired,
    resizable: PropTypes.bool,
    setColumnWidth: PropTypes.func.isRequired,
    setColumnRef: PropTypes.func.isRequired,
    isLast: PropTypes.bool,
    remainWidth: PropTypes.number,
    isIE: PropTypes.bool
};

export default injectIntl(SortHeader);
