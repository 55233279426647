import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentHead from 'presentationals/DocumentHead';
import WhiteLabelingRest from 'apis/WhiteLabelingRest';
import SiteAction from '../../redux/actions/Site';

const ALTERNATIVE_TITLE_FOR_EMTPY = 'Knox Drive';
const STORAGE_KEY_FOR_DOCUMENT_TITLE = 'DOCUMENT_TITLE_FOR_CACHE';

const WhiteLabelDocumentTitle = props => {
    const defaultTitle = sessionStorage.getItem(STORAGE_KEY_FOR_DOCUMENT_TITLE) || '\u200E'; //  it is an invisible (zero-width) control character. For empty title
    const [title, setTitle] = useState(defaultTitle);

    const { locale, setSolutionName } = props;

    useEffect(() => {
        WhiteLabelingRest.retrieveSolutionName(locale.languageCode).then(data => {
            const value = data || ALTERNATIVE_TITLE_FOR_EMTPY;
            if (defaultTitle !== value) {
                setTitle(value);
                sessionStorage.setItem(STORAGE_KEY_FOR_DOCUMENT_TITLE, value);
                setSolutionName(value);
            }
        });
    }, [locale.languageCode]);

    return <DocumentHead title={title} />;
};

WhiteLabelDocumentTitle.propTypes = {
    locale: PropTypes.object.isRequired,
    setSolutionName: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    locale: state.auth.user.locale
});

export default connect(
    mapStateToProps,
    {
        setSolutionName: solutionName => ({ type: SiteAction.SET_SOLUTION_NAME, solutionName })
    }
)(WhiteLabelDocumentTitle);
