import React, { useEffect, useState } from 'react';
import { If } from 'jsx-control-statements';
import uuidV4 from 'uuid/v4';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import SecureModeRequestRest from '../../../../apis/SecureModeRequestRest';
import ReqDtlHeader from './ReqDtlHeader';
import ReqDtlBodyRsn from './ReqDtlBodyRsn';
import ReqDtlBodyApprover from './ReqDtlBodyApprover';
import ReqRest from '../../../../apis/ReqRest';
import UserRest from '../../../../apis/UserRest';
import { alert, confirm } from '../../../../utils/ModalService';

const ReqSecureModePopup = props => {
    const { mode, reqId, reqInfo, close, intl, reload } = props;
    const [requestDetail, setRequestDetail] = useState({});
    const [request, setRequest] = useState({});
    const [requestTarget, setRequestTarget] = useState({});
    const [opinion, setOpinion] = useState('');
    const [objectId, setObjectId] = useState('');
    const [target, setTarget] = useState({});
    const guideText = intl.formatMessage({ id: 'drive.guideText.securefolder.processreason' });

    const getReq = async () => {
        if (_.isNil(reqInfo) || _.isEmpty(reqInfo)) {
            const req = await SecureModeRequestRest.retrieveDetail(reqId);
            return req;
        }
        return reqInfo;
    };

    useEffect(() => {
        getReq().then(data => {
            const { req, reqDtl, reqTgt } = data;
            setRequest(req);
            setRequestDetail(reqDtl);
            setRequestTarget(reqTgt[0]);
            const { objtId } = reqTgt[0];
            setObjectId(objtId);
        });
    }, []);

    useEffect(() => {
        UserRest.retrieveUserByUserId(objectId).then(data => {
            setTarget(data);
        });
    }, [objectId]);

    const sendRequest = type => {
        const requestIdArray = [];
        requestIdArray.push(request.reqId);
        confirm(<FormattedMessage id="com.confirm.ReqMngBodyReqStat.cancel" />).then(async () => {
            const res = await ReqRest.updateReqProcess({ reqPrcsType: type, reqPrcsList: requestIdArray, percOpin: opinion });
            const { message, resultCode, data } = res;

            if (resultCode === 500) {
                alert('com.alert.internalServerError');
            } else if (resultCode === 400) {
                alert('com.alert.reqInvalidParam');
            } else if (resultCode === 403 && !!data) {
                alert(data);
            } else {
                alert(message);
            }

            close(true);
            reload();
        });
    };

    const insertPercOpin = e => {
        const { value } = e.target;
        setOpinion(value);
    };

    return (
        <div className="modal-content">
            <ReqDtlHeader close={close} title="drive.text.securefolder.title" />
            <div className="modal-body">
                <div className="dt-width-120">
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.ReqMngBody.reqUserNm" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title="홍길동 선임 / 사업그룹(엔터프라이즈모바일)">
                                            <span className="name has-name-card" id={`reqUserCard_${target.userId}`}>
                                                {requestTarget.objtNm}
                                            </span>
                                            <span className="position">
                                                {target.userLangCdCopNm} / {target.deptLangCdNm}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <ReqDtlBodyApprover id={request.reqTgtUserId} name={request.reqTgtUserNm} deptNm={request.reqTgtUserDeptNm} copNm={request.reqTgtUserCopNm} />

                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="drive.text.securefolder.period" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                {moment(requestDetail.startDt).format('YYYY-MM-DD')} ~ {moment(requestDetail.endDt).format('YYYY-MM-DD')}
                            </div>
                        </dd>
                    </dl>
                    <If condition={mode === 'send'}>{request != null && <ReqDtlBodyRsn reason={request.reqRsn} title="drive.text.ReqDtlBodyReqRsn.reqRsn" key={uuidV4()} />}</If>
                    <If condition={mode === 'received'}>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.securefolder.opinion" />
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <textarea className="form-control textarea-sm" placeholder={guideText} onChange={e => insertPercOpin(e)}>
                                        {opinion}
                                    </textarea>
                                </div>
                            </dd>
                        </dl>
                    </If>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />

                <div className="modal-footer-btns">
                    <If condition={mode === 'send'}>
                        {request.reqStatCd === 'PROCESSING' && (
                            <a className="btn btn-lg btn-secondary" role="button" onClick={() => sendRequest('CANCEL')}>
                                <span className="btn-text">
                                    <FormattedMessage id="drive.button.securefolder.cancel" />
                                </span>
                            </a>
                        )}

                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={close}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </If>
                    <If condition={mode === 'received'}>
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => sendRequest('REJECT')}>
                            <span className="btn-text">
                                <FormattedMessage id="drive.button.securefolder.reject" />
                            </span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" onClick={() => sendRequest('APPROVAL')}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.approval" />
                            </span>
                        </a>
                    </If>
                </div>
            </div>
        </div>
    );
};

ReqSecureModePopup.propTypes = {
    mode: PropTypes.string.isRequired,
    reqId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    reqInfo: PropTypes.object,
    reload: PropTypes.func.isRequired
};

ReqSecureModePopup.defaultProps = {
    reqInfo: {}
};

export default injectIntl(ReqSecureModePopup);
