import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import SelectBox from '../../../../../presentationals/SelectBox';
import { summaryActions } from '../../../../../../redux/actions/Summary';
import { getDefaultLLM } from '../../../Common/Util/SummaryUtil';

const LLMDropdown = memo(() => {
    const llmDropdownRef = useRef();

    const { llmList, selectedLLM } = useSelector(({ summary }) => summary.global, shallowEqual);
    const { llmType: selectedLLMType } = selectedLLM;

    const dispatch = useDispatch();

    const llmOptionList = useMemo(() => {
        return llmList.map(({ llmType, llmName }) => ({
            value: llmType,
            message: llmName
        }));
    }, [llmList]);

    const selectLLM = useCallback(
        changedLLMType => {
            const getSelectedLLM = () => _.find(llmList, ({ llmType: type }) => type === changedLLMType) || {};

            const changedLLM = _.isNil(changedLLMType) ? getDefaultLLM(llmList) : getSelectedLLM();

            dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { ...changedLLM } }));
            dispatch(summaryActions.global.selectLLM({ llm: changedLLM }));
        },
        [llmList]
    );

    useEffect(() => {
        if (_.isEmpty(llmList)) {
            return;
        }

        selectLLM(null);
    }, [llmList]);

    const handleChange = llmType => {
        selectLLM(llmType);
    };

    return (
        <dl className="row-list">
            <dt>LLM</dt>
            <dd>
                <span className="select-type-box ui-copilot" ref={llmDropdownRef}>
                    <SelectBox value={selectedLLMType} options={llmOptionList} onChange={handleChange} style={{ width: '100%' }} />
                </span>
            </dd>
        </dl>
    );
});

export default LLMDropdown;
