import store from '../../redux/store';
import FileMenuAction from '../../redux/actions/FileMenu';

export default class MenuSupporter {
    /**
     * 파일메뉴 open 함수
     * @param {String} groupType 메뉴구성종류. MenuMapper.js 참조
     * @param {Array} files 대상 파일/폴더 목록
     * @param {Function} callback 메뉴수행후 처리될 콜백
     * @param {String} openType 메뉴 펼쳐지는 방향. FileMenu.jsx 참조
     * @param {Event} eventObj 클릭 Event 객체
     * @param {Function} statusCallback 메뉴영역 render발생시 수행
     * @param {JSON} custom 기타등등.. 필요한값
     */
    static open(groupType, files, callback, openType, eventObj, statusCallback, custom) {
        store.dispatch({
            type: FileMenuAction.OPEN,
            openType,
            eventObj: Object.assign({}, eventObj),
            statusCallback,
            data: {
                groupType,
                files,
                callback,
                custom
            }
        });
    }
}
