import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Choose, Otherwise, When, If } from 'jsx-control-statements';
import { FormattedMessage, injectIntl } from 'react-intl';

import ConfigsRest from 'apis/ConfigsRest';
import UserRest from 'apis/UserRest';
import CharacterImage from 'containers/CharacterImage/CharacterImageContainer';

import CardPopoverWrapper from './CardPopoverWrapper';
import { globalPopup } from '../../../redux/actions';

const UserCard = props => {
    const { id, close, loginUser, intl, openChangeExpirationDate } = props;
    const [user, setUser] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [useExpireDate, setUseExpireDate] = useState('Y');

    const closeCard = event => {
        event.stopPropagation();
        close();
    };

    useEffect(() => {
        ConfigsRest.getConfig('EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION').then(response => {
            const { cfgVal } = response;
            setUseExpireDate(cfgVal);
        });
    }, []);

    useEffect(() => {
        if (_.isEmpty(id)) return;

        if (user.userId !== id) {
            UserRest.retrieveUserByUserIdWithCache(id).then(result => {
                setUser(result || {});
                setLoaded(true);
            });
        }
    }, [id, user.userId]);

    if (!loaded) {
        return <React.Fragment />;
    }

    if (window.event) {
        const { type } = window.event;
        if (type === 'scroll') {
            window.event.stopPropagation();
            return <React.Fragment />;
        }
    }

    const isLessThenOneMonth = () => {
        const now = moment.utc().utcOffset(loginUser.locale.timezoneDiff);
        const target = moment.utc(user.accessExpirationTime).utcOffset(loginUser.locale.timezoneDiff);

        if (target.diff(now, 'month') < 1) {
            return true;
        }
        return false;
    };

    return (
        <div className="name-card" key={id}>
            <a className="btn-ic-nor close" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={closeCard}>
                <i className="ic-20-close" />
            </a>
            <Choose>
                <When condition={_.isEmpty(user)}>
                    <div className="profile empty">
                        <span className="message">
                            <FormattedMessage id="user.guideText.namecard.notExistsUser" />
                        </span>
                    </div>
                    <div className="profile empty">
                        <span className="message" />
                    </div>
                </When>
                <Otherwise>
                    <div className="item-user-thumbnail">
                        <CharacterImage size="lg" userType={user.userSectCd} text={user.userLangCdNm} userId={user.userId} objtId={user.userPhotoFilePathVal} />
                    </div>
                    <div className="profile">
                        <div className="g-inline-flex">
                            <div className="item-user">
                                <div className="user-profile">
                                    <div>
                                        <span className="name" title={user.userLangCdNm}>
                                            {user.userLangCdNm}
                                        </span>
                                        <span className="position" title={user.userLangCdCopNm}>
                                            {user.userLangCdCopNm}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {user.orgnOnpstCoNm === null ? (
                            <div className="partname" title={`${user.deptLangCdNm}`}>
                                {`${user.deptLangCdNm}`}
                            </div>
                        ) : (
                            <div className="partname" title={`${user.deptLangCdNm} / ${user.orgnOnpstCoNm}`}>
                                {`${user.deptLangCdNm} / ${user.orgnOnpstCoNm}`}
                            </div>
                        )}

                        <If condition={!!user.accessExpirationTime && useExpireDate === 'Y'}>
                            <div className="g-inline-flex">
                                <div className="previous">
                                    <em className={isLessThenOneMonth() ? 'expiry-date end' : 'expiry-date'}>
                                        {' '}
                                        (~
                                        {moment
                                            .utc(user.accessExpirationTime)
                                            .utcOffset(loginUser.locale.timezoneDiff)
                                            .format('YYYY-MM-DD')}
                                        )
                                    </em>
                                </div>
                                <div className="next">
                                    <a
                                        className="btn-link"
                                        role="button"
                                        onClick={() => {
                                            openChangeExpirationDate(user.userId);
                                            close();
                                        }}>
                                        <FormattedMessage id="user.alert.namecard.change" />
                                    </a>
                                </div>
                            </div>
                        </If>
                    </div>

                    <div className="contact">
                        <div className="phone">
                            <span className="tel">{user.userTelno}</span>
                            <span className="mobile">{user.userCelno}</span>
                        </div>
                        <div className="email" title={user.userLoginId}>
                            {user.userLoginId}
                        </div>
                    </div>
                </Otherwise>
            </Choose>
        </div>
    );
};

UserCard.propTypes = {
    intl: PropTypes.object.isRequired,
    id: PropTypes.string,
    close: PropTypes.func,
    loginUser: PropTypes.object.isRequired,
    openChangeExpirationDate: PropTypes.func.isRequired
};

UserCard.defaultProps = {
    id: undefined,
    close: _.noop
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    openChangeExpirationDate: globalPopup.openChangeExpirationDate
};

const connectedWithI18n = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserCard)
);
const UserCardWithPopover = props => <CardPopoverWrapper {...props} card={connectedWithI18n} />;

UserCardWithPopover.propTypes = {
    id: PropTypes.string,
    target: PropTypes.string,
    placement: PropTypes.string
};

UserCardWithPopover.defaultProps = {
    id: undefined,
    target: 'popoverLegacy',
    placement: 'auto'
};

export default UserCardWithPopover;
