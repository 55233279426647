import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import queryString from 'query-string';
import DriveBody from '../containers/DriveBody';
import Search from '../../utils/DriveConfiguration/Search';
import Url from '../../utils/Url';
import { alert } from '../../utils/ModalService';

class SearchFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null
        };
        const { location } = props;
        this.isEmptyQuery = this.checkEmptyQuery(location);
        if (this.isEmptyQuery) {
            alert('com.alert.search.min-query').then(() => {
                this.goBack();
            });
        }
    }

    checkEmptyQuery({ pathname, search }) {
        let filterParams = {};
        if (pathname.indexOf(Url.search) === 0) {
            filterParams = queryString.parse(search);
        }

        const queries = _.chain(filterParams)
            .omit(['time', 'utcOffset'])
            .values()
            .join('')
            .value();

        if (!queries || queries.trim() === '') {
            return true;
        }

        return false;
    }

    setTotal = total => {
        this.setState({ total });
    };

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    convert = total => {
        const reg = /(^\d+)(\d{3})/;
        let n = `${total}`;
        while (reg.test(n)) n = n.replace(reg, '$1,$2');
        return n;
    };

    render() {
        const pageClass = Search;

        const { total } = this.state;
        const { isEmptyQuery } = this;
        const {
            location: { search }
        } = this.props;
        const searchParams = queryString.parse(search);
        const { time = '' } = searchParams;
        const driveKey = `search_time_${time}`;

        return (
            <div className="page-drv">
                <div className="comm-func-bar">
                    <div className="func-btn">
                        <a className="btn-ic-nor btn-func-back" role="button" title="이전" onClick={() => this.goBack()}>
                            <i className="ic-24-arrow-back" />
                        </a>
                    </div>
                    <div className="func-bar-title">
                        <h3>
                            <FormattedMessage id="drive.text.search.result" />
                        </h3>
                        {total != null && total >= 0 && (
                            <div className="total-count">
                                <span>
                                    <FormattedMessage id="drive.text.search.total" />
                                </span>
                                <span className="count">
                                    <i className="num">{this.convert(total)}</i>
                                    <FormattedMessage id="drive.text.search.count" />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                {!isEmptyQuery ? <DriveBody {...this.props} setTotal={this.setTotal} pageClass={pageClass} key={driveKey} /> : null}
            </div>
        );
    }
}
export default SearchFiles;

SearchFiles.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
