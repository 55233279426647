import produce from 'immer';
import { handleActions } from 'redux-actions';
import { SUMMARY_FILE_LIST_TYPE } from '../../../components/containers/Summary/Common/SummaryConstant';

export default handleActions(
    {
        fileListBox: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    draft.isOpen = true;
                    draft.fileInputIndex = payload.fileInputIndex;
                    draft.fileInputRef = payload.fileInputRef;
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                    draft.fileInputIndex = -1;
                    draft.fileInputRef = null;
                });
            },
            retrieveRecentFiles: state => {
                return produce(state, draft => {
                    draft.fileListType = SUMMARY_FILE_LIST_TYPE.retrieveRecentFiles;
                    draft.searchedFileName = '';
                });
            },
            searchByFileName: (state, { payload }) => {
                return produce(state, draft => {
                    draft.fileListType = SUMMARY_FILE_LIST_TYPE.searchByFileName;
                    draft.searchedFileName = payload.searchedFileName;
                });
            }
        }
    },
    { isOpen: false },
    { prefix: 'summary' }
);
