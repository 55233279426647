import PropTypes from 'prop-types';
import React from 'react';

const SettingsLoginHistory = ({ loginHistory = [] }) => {
    const getRender = () => {
        if (!loginHistory) {
            return <></>;
        }
        return loginHistory.map(history => (
            <div key={history.uniqueKey} className="grid-row">
                <div className="grid-cell access-time-cell">{history.accessDate}</div>
                <div className="grid-cell access-path-cell">
                    {history.accessDesc}({history.accssIp})
                </div>
            </div>
        ));
    };
    return <div>{getRender()}</div>;
};

SettingsLoginHistory.propTypes = {
    loginHistory: PropTypes.array.isRequired
};

export default SettingsLoginHistory;
