import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import { alert } from '../../../../utils/ModalService';
import WebOfficeUtil, { WEBOFFICE_CONSTANTS } from '../../WebOffice/WebOfficeUtil';

const executor = (props, files) => {
    const objtInfo = files[0].drive;
    const policy = files[0].actionPolicy;

    const {
        custom: { getSiteConfig = _.noop, loginUser = {} }
    } = props;

    let urlPrefix = getSiteConfig(WEBOFFICE_CONSTANTS.CFG_ID.URL);
    if (!urlPrefix) {
        urlPrefix = WEBOFFICE_CONSTANTS.DEFAULT_URL;
    }
    let appKey;

    // 편집
    if (WebOfficeUtil.isEditorEnable(objtInfo, policy)) {
        appKey = WebOfficeUtil.getEditorKey(objtInfo);
    }
    // 뷰어
    else if (WebOfficeUtil.isViewerEnable(objtInfo, policy)) {
        appKey = WebOfficeUtil.getViewerKey(objtInfo);
    }

    // 실행할 수 없는 파일인 경우
    if (appKey === undefined) {
        alert('drive.text.viewerError.notSupportExtNm');
        return;
    }

    const url = WebOfficeUtil.getUrl(objtInfo, loginUser, appKey, 'open', urlPrefix);
    window.open(url, '_blank');
};

class WebOffice extends BaseMenu {
    getName() {
        return 'weboffice';
    }

    getLabel() {
        return 'drive.button.list.menu.weboffice';
    }

    isVisible() {
        const {
            custom: { getSiteConfig = _.noop }
        } = this.props;
        const useYn = getSiteConfig(WEBOFFICE_CONSTANTS.CFG_ID.USE_YN);
        if (useYn !== 'Y' || this.files.length !== 1 || this.files[0].drive.objtSectCd !== 'FILE') {
            return false;
        }

        const objtInfo = this.files[0].drive;
        const policy = this.files[0].actionPolicy;
        return WebOfficeUtil.isEditorEnable(objtInfo, policy) || WebOfficeUtil.isViewerEnable(objtInfo, policy);
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-name-change', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-name-change', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default WebOffice;
