import { $http } from 'modules/index';

class AuthGuideRest {
    static getAuthGuideCodes() {
        return $http.get('/drive/v1/policy/U/list').then(response => {
            return response.data;
        });
    }

    static getAuthGuideInfo() {
        return $http.get('/drive/v1/policy/U/info').then(response => {
            return response.data;
        });
    }

    static getAuthCds() {
        return $http.get('/drive/v1/policy/A/authCds').then(response => {
            return response.data;
        });
    }
}

export default AuthGuideRest;
