/* eslint-disable */
import React from 'react';
import BaseMenu from '../BaseMenu';
import FileDownload from '../../Download/Download';
import { popup } from '../../../../utils/ModalService';
import _ from "lodash";

const executor = (props, file) => {

    const selectedFiles = [];
    selectedFiles.push(file[0]);

    popup(<FileDownload folderFileList={selectedFiles} />);
};

class VersionDownload extends BaseMenu {
    getName() {
        return 'versionDownload';
    }

    getLabel() {
        return 'drive.button.list.menu.download';
    }

    isVisible() {
        let downloadYn = true;
        if(!_.isNil(this.files[0].flcmActionPolicyMap)){
            downloadYn = this.files[0].flcmActionPolicyMap.download;
        }
        return super.isVisible(false, 'download') && downloadYn;
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-download', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-download', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default VersionDownload;
