import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SelectBox from '../../../presentationals/SelectBox';

const ApprovalOpinion = props => {
    const { intl, typeClass, onChange } = props;

    const [opinion, setOpinion] = useState('');
    const [opinionLength, setOpinionLength] = useState(0);
    const [editable, setEditable] = useState(false);
    const [presets, setPresets] = useState([]);
    const [opinionMinLength, setOpinionMinLength] = useState(5);

    const onChangeOpinion = (value = '') => {
        setOpinion(value);
        setOpinionLength(value.trim().length);
        onChange(value);
    };

    const hasPresets = (p = presets) => {
        return p && p.length > 0;
    };

    useEffect(() => {
        async function getPresets() {
            const p = await typeClass.getOpinionPresets();
            setPresets(p);
            setEditable(!hasPresets(p));
            setOpinionMinLength(hasPresets(p) ? 1 : 5);
        }
        getPresets();
    }, []);

    const getGuideStyle = () => {
        return {
            display: opinionLength < opinionMinLength ? '' : 'none'
        };
    };

    const reasonTitle = intl.formatMessage({ id: 'com.text.approval.requestReason' });
    const presetsTitle = intl.formatMessage({ id: 'com.placeHolder.approval.line.pleaseSelect' }, { target: reasonTitle });
    const presetByValue = _.keyBy(presets, preset => `${preset.value}`);
    const onChangePreset = value => {
        const preset = presetByValue[value];
        if (!preset) return;

        setEditable(preset.editable);
        setOpinionMinLength(preset.minLength);
        typeClass.setDraftOpinionKey(preset.value);
        typeClass.setDraftOpinionMinLength(preset.minLength);

        onChangeOpinion(preset.opinion);
    };

    return (
        <>
            {hasPresets() && (
                <div className="dt-width-120 form-option">
                    <dl className="row-list">
                        <dt>{reasonTitle}</dt>
                        <dd>
                            <span className="select-type-box">
                                <SelectBox type="box" dataContainer="body" style={{ width: '100%' }} title={presetsTitle} options={presets} onChange={onChangePreset} />
                            </span>
                        </dd>
                    </dl>
                </div>
            )}

            {editable && (
                <div className="form-group">
                    <textarea
                        className="form-control textarea-sm"
                        placeholder={intl.formatMessage({ id: 'com.placeHolder.approval.opinion.pleaseInput' })}
                        onChange={e => onChangeOpinion(e.target.value)}
                        maxLength="512"
                        minLength="5"
                        value={opinion}
                    />
                    <div className="input-remark">
                        <div className="err-msg" style={getGuideStyle()}>
                            <FormattedMessage id="com.guideText.approval.opinion.minLength" />
                        </div>
                        <div className="write-count">
                            <strong className="count-num">{opinionLength}</strong> /<span className="write-total">512</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

ApprovalOpinion.propTypes = {
    typeClass: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default injectIntl(ApprovalOpinion);
