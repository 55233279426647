import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import CustomModal from 'containers/CustomModal';
import { connect } from 'react-redux';
import SearchUser from './SearchUser';
import SearchDept from './SearchDept';

class SingleSelectPopup extends Component {
    constructor(prop) {
        super(prop);
        const isActiveUser = _.get(prop, 'tab.user', true);
        this.state = { selected: [], searchType: isActiveUser ? 'USER' : 'DEPT' };
    }

    handleClose = () => {
        const { close } = this.props;
        this.setState({ searchType: 'USER' });
        close({});
    };

    clickOkayButton = () => {
        const { close } = this.props;
        const { selected } = this.state;
        this.setState({ searchType: 'USER' });
        close(selected);
    };

    handleSelect = selectedItem => {
        this.setState({ selected: selectedItem });
    };

    onSelectUserTab = () => {
        this.setState({ searchType: 'USER' });
    };

    onSelectDeptTab = () => {
        this.setState({ searchType: 'DEPT' });
    };

    render() {
        const { show, options, languageCode, user } = this.props;
        const { searchType } = this.state;
        return (
            <CustomModal isOpen={show} fade={false} backdrop="static" size="lg" className="modal-open">
                <div className="modal-header">
                    <nav>
                        <ul className="nav nav-tabs" role="tablist">
                            {options.tab.user && (
                                <li className="nav-item">
                                    <a className="nav-link active" role="button" onClick={this.onSelectUserTab}>
                                        <FormattedMessage id="user.tab.popup.selectPeople.searchPerson" />
                                    </a>
                                </li>
                            )}
                            {options.tab.dept && (
                                <li className="nav-item">
                                    <a className="nav-link" role="button" onClick={this.onSelectDeptTab}>
                                        <FormattedMessage id="user.tab.popup.selectPeople.searchDepartment" />
                                    </a>
                                </li>
                            )}
                        </ul>
                    </nav>

                    <FormattedMessage id="com.close">
                        {title => (
                            <a className="btn-ic-nor" role="button" title={title} data-dismiss="modal" onClick={this.handleClose}>
                                <i className="ic-20-close" />
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <If condition={searchType === 'USER'}>
                    <SearchUser handleSelect={this.handleSelect} languageCode={languageCode} tenantId={user.tenantId} />
                </If>
                <If condition={searchType === 'DEPT'}>
                    <SearchDept handleSelect={this.handleSelect} />
                </If>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={this.handleClose}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" onClick={this.clickOkayButton}>
                            <span className="btn-text">
                                <FormattedMessage id="com.check" />
                            </span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

SingleSelectPopup.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    options: PropTypes.object,
    languageCode: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
};

SingleSelectPopup.defaultProps = {
    show: false,
    close: _.noop,
    options: {
        tab: { user: true, dept: true }
    }
};
const mapStateToProps = state => ({
    languageCode: state.auth.user.locale.languageCode,
    user: state.auth.user
});

export default connect(mapStateToProps)(SingleSelectPopup);
