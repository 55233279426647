import { push as routerPush } from 'connected-react-router';
import CharacterImageContainer from 'containers/CharacterImage/CharacterImageContainer';
import NameCardWithSettings from 'containers/NameCard/NameCardWithSettings';
import NotificationPopup from 'containers/NotificationPopup';
import NoticePopup from 'containers/NoticePopup';
import NoticeAgentInstallPopup from 'containers/NoticeAgentInstallPopup';
import produce from 'immer';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import Logo from 'presentationals/Logo';
import Search from 'presentationals/Search/Search';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Url from 'utils/Url';
import uuidV4 from 'uuid/v4';
import NotificationRest from 'apis/NotificationRest';
import NoticeRest from 'apis/NoticeRest';
import NoticeConstants from 'constants/Notice';
import ClickOutside from 'presentationals/ClickOutside';
import { injectIntl } from 'react-intl';
import { notice as noticeActions, gnb as gnbActions } from '../../redux/actions';
import DriveWebSocket from '../../utils/DriveWebSocket';
import DriveConfigRest from '../../apis/DriveConfigRest';
import DriveConfigs from '../../constants/DriveConfigs';
import CopilotGnbButton from './Summary/Gnb/CopilotGnbButton';
import { isNotOffWorkSite, SITE_MODE } from '../../constants/SiteMode';

const PATHS_NOTIFICATION_EXCEPT = ['/own', '/shared', '/workgroup'];

class Gnb extends Component {
    constructor(props) {
        super(props);
        const { intl, loginUser, siteMode } = props;
        const { config } = loginUser;
        const { flcmUrl, useCstdPolicy, useCpgs, isDocMaster, userCstdMap, userCpgsMap } = config;

        const menus = [];

        if (isNotOffWorkSite(siteMode)) {
            menus.push({
                uniqueKey: uuidV4(),
                text: intl.formatMessage({ id: 'user.gnb.myFile' }),
                url: Url.own,
                dropdown: true,
                openDropDown: false,
                dropdownMenus: [
                    // { uniqueKey: uuidV4(), text: intl.formatMessage({ id: 'user.gnb.myFile' }), url: Url.own },
                    { uniqueKey: uuidV4(), text: intl.formatMessage({ id: 'user.gnb.sharedByMe' }), url: Url.sharing, rawUrl: Url.sharing }
                ],
                style: {
                    display: 'block'
                }
            });
            menus.push({
                uniqueKey: uuidV4(),
                text: intl.formatMessage({ id: 'user.gnb.sharedToMe' }),
                url: Url.shared,
                dropdown: false,
                openDropDown: false
                // dropdownMenus: [{ uniqueKey: uuidV4(), text: intl.formatMessage({ id: 'user.gnb.sharedToMe' }) }]
            });
        }

        menus.push({
            uniqueKey: uuidV4(),
            text: intl.formatMessage({ id: 'user.tab.gnb.workgroup' }),
            url: Url.workgroup,
            dropdown: false,
            openDropDown: false
        });

        const flcmMenus = this.getFlcmGnbMenus(flcmUrl, useCstdPolicy, useCpgs, isDocMaster, userCstdMap, userCpgsMap, intl);
        if (!_.isEmpty(flcmMenus)) {
            menus.push({
                uniqueKey: uuidV4(),
                text: 'FLCM',
                url: `${Url.expiration}?expirationRequestStateCodes=NONE%2CREJECTED`,
                dropdown: true,
                openDropDown: false,
                dropdownMenus: flcmMenus
            });
        }
        menus.push({ uniqueKey: uuidV4(), text: intl.formatMessage({ id: 'user.gnb.recyleBin' }), url: `${Url.deleted}/refresh`, rawUrl: Url.deleted });

        this.state = {
            openNamecardWithSettings: false,
            isOpenSearchInput: false,
            openNotificationPopup: false,
            menus,
            anucIds: {},
            openNoticeAgentInstallPopup: false
        };
        this.closeDropDownTimeout = {};
        this.clickOutsideNotificationRef = React.createRef();
        this.clickOutsideNameCardSettingsRef = React.createRef();

        this.showMenuProcess();
    }

    getFlcmGnbMenus = (flcmUrl, useCstdPolicy, useCpgs, isDocMaster, userCstdMap = {}, userCpgsMap = {}, intl) => {
        const flcmDropDownMenus = [];
        const { cstdPolicyPstPrcs = null } = userCstdMap || {};
        const { cpgsPstPrcs = null } = userCpgsMap || {};
        if (useCstdPolicy) {
            flcmDropDownMenus.push({
                uniqueKey: uuidV4(),
                text: intl.formatMessage({ id: 'user.tab.gnb.expiration' }),
                url: `${Url.expiration}?expirationRequestStateCodes=NONE%2CREJECTED`,
                rawUrl: Url.expiration,
                isLine: false,
                isShow: () => Promise.resolve(),
                convertShowData: () => true || cstdPolicyPstPrcs !== null
            });
        }
        if (useCpgs) {
            flcmDropDownMenus.push({
                uniqueKey: uuidV4(),
                text: intl.formatMessage({ id: 'user.tab.gnb.compliance' }),
                url: `${Url.compliance}?complianceRequestStateCodes=ALL`,
                rawUrl: Url.compliance,
                isLine: false,
                isShow: () => Promise.resolve(),
                convertShowData: () => true || cpgsPstPrcs !== null
            });
        }
        if ((useCstdPolicy || useCpgs) && isDocMaster) {
            flcmDropDownMenus.push({
                uniqueKey: uuidV4(),
                text: intl.formatMessage({ id: 'user.tab.gnb.flcmLink' }),
                url: flcmUrl,
                rawUrl: Url.compliance,
                isLine: true,
                isShow: () => Promise.resolve(),
                convertShowData: () => true || cpgsPstPrcs !== null
            });
        }
        return flcmDropDownMenus;
    };

    /* eslint-disable no-param-reassign */
    showMenuProcess = () => {
        const { menus } = this.state;
        const mapping = [];
        const duplFunction = [];
        const duplPromise = [];
        menus.forEach(menu => {
            if (menu.dropdownMenus) {
                menu.dropdownMenus.forEach(dm => {
                    if (dm.isShow) {
                        const duplIdx = duplFunction.indexOf(dm.isShow);
                        if (duplIdx >= 0) {
                            dm.showPromise = duplPromise[duplIdx];
                        } else {
                            duplFunction.push(dm.isShow);
                            dm.showPromise = dm.isShow();
                            duplPromise.push(dm.showPromise);
                        }
                        mapping.push(dm);
                    }
                });
            } else if (menu.isShow) {
                const duplIdx = duplFunction.indexOf(menu.isShow);
                if (duplIdx >= 0) {
                    menu.showPromise = duplPromise[duplIdx];
                } else {
                    duplFunction.push(menu.isShow);
                    menu.showPromise = menu.isShow();
                    duplPromise.push(menu.showPromise);
                }
                mapping.push(menu);
            }
        });

        Promise.all(mapping.map(m => m.showPromise)).then(datas => {
            mapping.forEach((m, idx) => {
                if (m.convertShowData) {
                    m.show = m.convertShowData(datas[idx]);
                }
            });

            const showMenus = menus.map(m => {
                const returnMenu = m;
                if (returnMenu.show === undefined) {
                    returnMenu.show = true;
                }
                if (returnMenu.dropdownMenus) {
                    returnMenu.dropdownMenus.forEach(dm => {
                        if (dm.show === undefined) {
                            dm.show = true;
                        }
                    });
                    const showDms = returnMenu.dropdownMenus.filter(dm => dm.show);
                    if (showDms.length === 0) {
                        returnMenu.show = false;
                    }
                    // else if (showDms.length === 1) {
                    //     [returnMenu] = showDms;
                    // }
                }
                return returnMenu;
            });
            this.setState({ menus: showMenus });
        });
    };
    /* eslint-enable no-param-reassign */

    componentDidMount() {
        const { noticeSearched } = this.props;

        if (!noticeSearched) {
            this.getPopupNotices();
            this.checkAgentInstalled();
            // noticeActions.noticeSeached();
        }

        this.getNotificationUnreadCount();
    }

    getNotificationUnreadCount = (isForce = false) => {
        const { pathname, setNotificationUnreadCount, notificationPath } = this.props;

        if (!isForce) {
            if (PATHS_NOTIFICATION_EXCEPT.indexOf(pathname) !== -1) return;
            if (pathname === notificationPath) return;
        }

        NotificationRest.getUnreadCount().then(response => {
            const { resultCode, data: unreadCount } = response;
            if (resultCode === 200) {
                setNotificationUnreadCount(unreadCount, pathname);
            }
        });
    };

    getPopupNotices = () => {
        const { loginUser, setNoticeSeached } = this.props;
        const { id } = loginUser;
        NoticeRest.getNotices({ anucPpYn: 'Y' }).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const noticesRead = JSON.parse(localStorage.getItem(`${NoticeConstants.NOTICE_READ_LIST_LOCAL_STORAGE_KEY_PREFIX}${id}`)) || [];
            const { list } = data;
            const notShowingList = [];
            _.forEach(noticesRead, savedNotice => {
                if (_.find(list, notice => notice.anucId === savedNotice)) notShowingList.push(savedNotice);
            });
            localStorage.setItem(`${NoticeConstants.NOTICE_READ_LIST_LOCAL_STORAGE_KEY_PREFIX}${id}`, JSON.stringify(notShowingList));
            this.setState(
                {
                    anucIds: _(list)
                        .filter(notice => !_.includes(notShowingList, notice.anucId))
                        .orderBy(['anucImportanceYn', 'anucStartDt', 'anucId'], ['asc', 'asc', 'asc'])
                        .keyBy('anucId')
                        .mapValues(() => true)
                        .value()
                },
                setNoticeSeached
            );
        });
    };

    async checkAgentInstalled() {
        if (window.location.href.indexOf('/home') < 0) {
            return;
        }

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        if (_.isEqual(agentType, 'S')) {
            return;
        }

        if (navigator.platform.indexOf('Win') === -1) {
            return;
        }

        const { loginUser } = this.props;
        const { id } = loginUser;
        const noticesRead = JSON.parse(localStorage.getItem(`${NoticeConstants.NOTICE_READ_AGENT_INSTALL_LOCAL_STORAGE_KEY_PREFIX}${id}`)) || [];
        if (!_.find(noticesRead, notice => notice === 'agentInstall')) {
            const loginParam = [];
            loginParam.push(loginUser.tenantId);
            loginParam.push(loginUser.loginId);
            loginParam.push('Login');
            loginParam.push('NONE');

            if (!this.websocketResultCheck('Login', await DriveWebSocket(loginParam, agentType, 'Login'))) {
                this.setState({ openNoticeAgentInstallPopup: true });
            }
        }
    }

    websocketResultCheck(type, code) {
        console.log('websocket type: ', type);
        console.log('websocket result: ', code);

        if (!code) {
            return false;
        }
        return true;
    }

    isOpenNamecardWithSettings = () => {
        const { openNamecardWithSettings } = this.state;
        let { openNotificationPopup } = this.state;
        if (openNotificationPopup && !openNamecardWithSettings) openNotificationPopup = false;
        this.setState({ openNamecardWithSettings: !openNamecardWithSettings, openNotificationPopup });
    };

    toggleSearchInput = isOpenSearchInput => {
        this.setState({ isOpenSearchInput });
    };

    openNotificationPopup = async () => {
        const { openNotificationPopup } = this.state;
        let { openNamecardWithSettings } = this.state;
        if (openNamecardWithSettings && !openNotificationPopup) openNamecardWithSettings = false;
        const changedState = { openNotificationPopup: !openNotificationPopup, openNamecardWithSettings };
        const response = await NotificationRest.updateAllNotificationsAsRead(false);
        const { resultCode, data } = response;
        if (resultCode === 200 && data) {
            this.getNotificationUnreadCount(true);
        }
        this.setState(changedState);
    };

    closeNotificationPopup = () => {
        this.setState({ openNotificationPopup: false });
    };

    closeNameCardWithSettings = () => {
        this.setState({ openNamecardWithSettings: false });
    };

    closeDropDownDiv = idx => {
        this.setState(state =>
            produce(state, draft => {
                draft.menus[idx].openDropDown = false;
            })
        );
    };

    onMouseEnterLink = () => {
        const { menus } = this.state;
        const dropDownMenusIdxs = [];
        menus.forEach((menuItem, index) => {
            if (_.has(menuItem, 'dropdown')) dropDownMenusIdxs.push(index);
        });

        this.setState(state =>
            produce(state, draft => {
                _.forEach(dropDownMenusIdxs, idx => {
                    draft.menus[idx].openDropDown = false;
                });
            })
        );
    };

    clearIfExistTimeout = menu => {
        if (_.has(this.closeDropDownTimeout, menu.uniqueKey)) {
            clearTimeout(this.closeDropDownTimeout[menu.uniqueKey]);
        }
    };

    onClickDropDownLink = (event, menu) => {
        event.preventDefault();
        const { push } = this.props;
        push(menu.url);
    };

    onMouseEnterDropDownLink = menu => {
        const { pathname } = this.props;
        const { menus } = this.state;
        const findIdx = _.findIndex(menus, { uniqueKey: menu.uniqueKey });
        this.clearIfExistTimeout(menu);
        this.setState(state =>
            produce(state, draft => {
                draft.menus[findIdx].openDropDown = true;
            })
        );

        if (pathname !== '/home') {
            let currentPathName = pathname;
            if (_.includes(pathname, 'sharing')) {
                currentPathName = '/sharing';
            }
            const current = _.find(menus[findIdx].dropdownMenus, { rawUrl: currentPathName });
            if (!_.isNil(current)) {
                document.getElementById(current.uniqueKey).className = 'dropdown-item active';
            }
        }
    };

    onMouseLeaveDropDownLink = menu => {
        const { menus } = this.state;
        const findIdx = _.findIndex(menus, { uniqueKey: menu.uniqueKey });
        this.clearIfExistTimeout(menu);
        this.closeDropDownTimeout[menu.uniqueKey] = setTimeout(() => {
            this.closeDropDownDiv(findIdx);
        }, 200);
    };

    onMouseEnterDropDownDiv = menu => {
        this.clearIfExistTimeout(menu);
    };

    onMouseLeaveDropDownDiv = menu => {
        const { menus } = this.state;
        const findIdx = _.findIndex(menus, { uniqueKey: menu.uniqueKey });
        this.closeDropDownDiv(findIdx);
    };

    closeNoticePopups = (anucId, doNotShowAgain) => {
        if (doNotShowAgain) {
            const { loginUser } = this.props;
            const { id } = loginUser;
            const noticesRead = JSON.parse(localStorage.getItem(`${NoticeConstants.NOTICE_READ_LIST_LOCAL_STORAGE_KEY_PREFIX}${id}`)) || [];
            noticesRead.push(anucId);
            localStorage.setItem(`${NoticeConstants.NOTICE_READ_LIST_LOCAL_STORAGE_KEY_PREFIX}${id}`, JSON.stringify(noticesRead));
        }
        const { anucIds } = this.state;
        const changedAnucIds = anucIds;
        changedAnucIds[anucId] = false;
        this.setState({ anucIds: _.cloneDeep(changedAnucIds) });
    };

    closeNoticeAgentInstallPopup = doNotShowAgain => {
        if (doNotShowAgain) {
            const { loginUser } = this.props;
            const { id } = loginUser;
            const noticesRead = JSON.parse(localStorage.getItem(`${NoticeConstants.NOTICE_READ_AGENT_INSTALL_LOCAL_STORAGE_KEY_PREFIX}${id}`)) || [];
            noticesRead.push('agentInstall');
            localStorage.setItem(`${NoticeConstants.NOTICE_READ_AGENT_INSTALL_LOCAL_STORAGE_KEY_PREFIX}${id}`, JSON.stringify(noticesRead));
        }
        this.setState({ openNoticeAgentInstallPopup: false });
    };

    moveToHelpCenter = () => {
        const { push } = this.props;
        push('/help');
    };

    render() {
        const { pathname, loginUser, intl, notificationUnreadCount, copilotGnbButtonRef, enableSummary } = this.props;
        const { openNamecardWithSettings, isOpenSearchInput, openNotificationPopup, menus, anucIds } = this.state;
        const { openNoticeAgentInstallPopup } = this.state;
        // let selectedMenu = menus[0];
        let selectedMenuText = menus[0].text;
        const liClassNames = [];
        const linkClassNames = [];
        const collapseNavLinkClassNames = ['navbar-toggler'];

        for (let i = 0; i < menus.length; i += 1) {
            const menu = menus[i];
            let liClassName = 'nav-item';
            let linkClassName = 'nav-link';
            if (menu.dropdown) liClassName = liClassName.concat(' dropdown');
            if (menu.openDropDown) liClassName = liClassName.concat(' show');

            if (pathname.includes(menu.rawUrl) || pathname.includes(menu.url)) {
                linkClassName = linkClassName.concat(' active');
                // selectedMenu = menu;
                selectedMenuText = menu.text;
                collapseNavLinkClassNames.push('active');
            } else if (menu.dropdown && _.some(menu.dropdownMenus, dropdownMenu => pathname.includes(dropdownMenu.url) || pathname.includes(dropdownMenu.rawUrl))) {
                linkClassName = linkClassName.concat(' active');
                // selectedMenu = menu;
                selectedMenuText = _.find(menu.dropdownMenus, dropdownMenu => pathname.includes(dropdownMenu.url) || pathname.includes(dropdownMenu.rawUrl)).text;
                collapseNavLinkClassNames.push('active');
            }
            if (menu.dropdown) linkClassName = linkClassName.concat(' dropdown-toggle');

            liClassNames.push(liClassName);
            linkClassNames.push(linkClassName);
        }

        return (
            <>
                <div className={`header-wrap ${isOpenSearchInput ? 'header-search-focused' : ''}`}>
                    <Logo />
                    <div className="header-gnb" id="header-gnb">
                        <nav className="navbar navbar-expand-md">
                            <a
                                role="button"
                                className={collapseNavLinkClassNames.join(' ')}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                                {selectedMenuText}
                                <span className="navbar-toggler-icon" />
                            </a>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {menus
                                        .filter(m => m.show)
                                        .map((menu, index) => {
                                            return (
                                                <>
                                                    <li key={menu.uniqueKey} className={liClassNames[index]}>
                                                        <If condition={!menu.dropdown}>
                                                            <Link to={menu.url} role="button" className={linkClassNames[index]} onMouseEnter={() => this.onMouseEnterLink()}>
                                                                {menu.text}
                                                            </Link>
                                                        </If>
                                                        <If condition={menu.dropdown}>
                                                            <Link
                                                                to={menu.url}
                                                                role="button"
                                                                className={linkClassNames[index]}
                                                                style={menu.style || {}}
                                                                id="navbarDropdownMenuLink"
                                                                aria-haspopup={true}
                                                                aria-expanded={menu.openDropDown}
                                                                onMouseEnter={() => this.onMouseEnterDropDownLink(menu)}
                                                                onMouseLeave={() => this.onMouseLeaveDropDownLink(menu)}
                                                                onClick={event => this.onClickDropDownLink(event, menu)}>
                                                                {menu.text}
                                                            </Link>

                                                            <div
                                                                className={menu.openDropDown ? 'dropdown-menu show' : 'dropdown-menu'}
                                                                aria-labelledby="navbarDropdownMenuLink"
                                                                onMouseEnter={() => this.onMouseEnterDropDownDiv(menu)}
                                                                onMouseLeave={() => this.onMouseLeaveDropDownDiv(menu)}>
                                                                {menu.dropdownMenus
                                                                    .filter(m => m.show)
                                                                    .map(dropdownMenu => {
                                                                        if (dropdownMenu.url && (dropdownMenu.url.indexOf('http://') >= 0 || dropdownMenu.url.indexOf('https://') >= 0)) {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdown-divider" />
                                                                                    <a
                                                                                        key={dropdownMenu.uniqueKey}
                                                                                        id={dropdownMenu.uniqueKey}
                                                                                        role="button"
                                                                                        className="dropdown-item"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                        href={dropdownMenu.url}>
                                                                                        {dropdownMenu.text}
                                                                                    </a>
                                                                                </>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <Link
                                                                                key={dropdownMenu.uniqueKey}
                                                                                id={dropdownMenu.uniqueKey}
                                                                                role="button"
                                                                                to={dropdownMenu.url}
                                                                                className="dropdown-item">
                                                                                {dropdownMenu.text}
                                                                            </Link>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </If>
                                                    </li>
                                                    {/* offlineWg 요건에 따른 GNB 구분선 표시 조건 변경 */}
                                                    {/* GNB 메뉴에 변경이 생기는 경우에 대한 구분선 위치 및 표시여부의 명확한 기준이 없음 */}
                                                    {/* 따라서, GNB에 Workgroup이 존재하지 않는 경우는 고려하지 않음 */}
                                                    <If condition={menu.url === Url.workgroup}>
                                                        <li className="divider" />
                                                    </If>
                                                </>
                                            );
                                        })}
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <Search toggleSearchInput={this.toggleSearchInput} />
                    <div className="header-nav">
                        <If condition={enableSummary}>
                            <CopilotGnbButton clickOutSideRef={copilotGnbButtonRef} />
                        </If>
                        <div className="nav-notification" id="nav-notification-div">
                            <a
                                className={`btn-ic-nor btn-func-noti ${openNotificationPopup ? 'active' : ''}`}
                                role="button"
                                id="nav-notification"
                                onClick={this.openNotificationPopup}
                                title={intl.formatMessage({ id: 'user.noti.notification' })}>
                                <i className="ic-24-alarm" />
                                <em className="new-badge" style={{ display: notificationUnreadCount > 0 ? '' : 'none' }} />
                            </a>
                            <ClickOutside onClickOutside={this.closeNotificationPopup} childrenRefs={[this.clickOutsideNotificationRef]}>
                                <NotificationPopup open={openNotificationPopup} closeAction={this.closeNotificationPopup} clickOutsideRef={this.clickOutsideNotificationRef} />
                            </ClickOutside>
                        </div>
                        <div className="nav-help">
                            <a className="btn-ic-nor" role="button" onClick={this.moveToHelpCenter} title={intl.formatMessage({ id: 'user.helpcenter.helpcenter' })}>
                                <i className="ic-24-help" />
                            </a>
                        </div>
                        <div className="nav-account">
                            <CharacterImageContainer
                                objtId={loginUser.profile.fileId || ''}
                                updateTime={loginUser.profile.updateTime}
                                userId={loginUser.id}
                                text={loginUser.name}
                                size="md"
                                userType={loginUser.sectionCode}
                                onClick={() => this.isOpenNamecardWithSettings()}
                            />
                            <ClickOutside onClickOutside={this.closeNameCardWithSettings} childrenRefs={[this.clickOutsideNameCardSettingsRef]}>
                                <NameCardWithSettings
                                    isOpen={openNamecardWithSettings}
                                    closeNameCardWithSettings={this.closeNameCardWithSettings}
                                    clickOutsideRef={this.clickOutsideNameCardSettingsRef}
                                />
                            </ClickOutside>
                        </div>
                    </div>
                </div>
                <NoticeAgentInstallPopup show={openNoticeAgentInstallPopup} handleClose={doNotShowAgain => this.closeNoticeAgentInstallPopup(doNotShowAgain)} />
                {!_.isEmpty(anucIds) &&
                    _.map(anucIds, (value, key) => {
                        return <NoticePopup anucId={key} show={value} handleClose={doNotShowAgain => this.closeNoticePopups(key, doNotShowAgain)} showFooter={true} />;
                    })}
            </>
        );
    }
}

Gnb.propTypes = {
    pathname: PropTypes.string,
    loginUser: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    noticeSearched: PropTypes.bool,
    setNoticeSeached: PropTypes.func,
    notificationUnreadCount: PropTypes.func,
    notificationPath: PropTypes.string,
    setNotificationUnreadCount: PropTypes.func,
    intl: PropTypes.object.isRequired,
    copilotGnbButtonRef: PropTypes.shape({ current: PropTypes.any }),
    enableSummary: PropTypes.bool,
    siteMode: PropTypes.string
};

Gnb.defaultProps = {
    pathname: '/',
    noticeSearched: false,
    setNoticeSeached: () => {},
    notificationUnreadCount: 0,
    notificationPath: '/',
    setNotificationUnreadCount: () => {},
    copilotGnbButtonRef: {},
    enableSummary: false,
    siteMode: SITE_MODE.unknown
};

const mapStateToProps = state => ({
    loginUser: state.auth.user,
    noticeSearched: state.notice.noticeSearched,
    notificationUnreadCount: state.gnb.notification.unreadCount,
    notificationPath: state.gnb.notification.path,
    sysConfig: state.config.configs,
    siteMode: state.site.siteMode
});

const mapDispatchToProps = {
    push: routerPush,
    setNoticeSeached: noticeActions.noticeSeached,
    setNotificationUnreadCount: gnbActions.setNotificationUnreadCount
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Gnb)
);
