import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

class ComplianceResult extends React.Component {
    /**
     * 서버 시간과 만료일을 비교하여 차이 반환
     * @param nowDateTime
     * @param targetYMD
     * @param utcOffset
     * @returns {string}
     */
    getDiff = (nowDateTime, targetYMD, utcOffset) => {
        if (_.isEmpty(targetYMD)) return '';
        const target = moment(targetYMD, 'YYYYMMDD')
            .utcOffset(utcOffset)
            .startOf('day');
        const now = moment(nowDateTime)
            .utcOffset(utcOffset)
            .startOf('day');
        const diff = now.diff(target, 'd');
        return `(D${diff >= 0 ? `+${diff}` : `${diff}`})`;
    };

    getResultType = resultType => {
        const { intl } = this.props;
        return intl.formatMessage({ id: `drive.text.list.compliance.result.${_.isEmpty(resultType) || resultType === 'BOTH' ? 'delete' : _.toLower(resultType)}` });
    };

    render() {
        const { file = {}, locale, cellStyle } = this.props;
        const { config = {} } = file;
        const { drive = {} } = config;
        const { complianceResult = '', complianceDeleteDate = '' } = drive;
        const nowDateTime = moment().valueOf();
        const { timezoneDiff = 0 } = locale;
        const resultType = this.getResultType(complianceResult);
        return (
            <div className="grid-cell files-postProcess-cell" title={resultType} style={cellStyle}>
                {resultType}
                {this.getDiff(nowDateTime, complianceDeleteDate, timezoneDiff)}
            </div>
        );
    }
}

ComplianceResult.defaultProps = {
    cellStyle: null
};

ComplianceResult.propTypes = {
    file: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

const mapStateToProps = state => ({
    locale: state.auth.user.locale
});

export default injectIntl(connect(mapStateToProps)(ComplianceResult));
