// action type
const PREFIX = 'FLC_';
export const SET_POLICY = PREFIX.concat('SET_POLICY');
const defaultStore = {
    policy: {}
};

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const setPolicy = (policy = defaultStore) => action(SET_POLICY, { policy });
