import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ApprovalConstants from '../ApprovalConstants';
import FileLifeCycleRest from '../../../../../apis/FileLifeCycleRest';
import BaseApproval from './BaseApproval';
import ApprovalUtil from '../ApprovalUtil';

class ExceptNonCompliance extends BaseApproval {
    type = ApprovalConstants.TYPE.EXCEPT_NON_COMPLIANCE;

    title = 'drive.title.approval.prohibitWordExcept';

    approverNameId = 'com.guideText.approval.line.docuManager';

    async getApproverCandidates() {
        const response = await FileLifeCycleRest.getApproverCandidates(this.systemType);

        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return data;
    }

    async getCandidateLine() {
        const candidates = await this.getApproverCandidates();
        return ApprovalUtil.convertCandidatesToLine(candidates);
    }

    convertToTarget(files = []) {
        if (_.isEmpty(files)) return [];
        return _.map(files, tgt => {
            return {
                objtId: tgt.drive.objtId,
                objtNm: tgt.drive.objtNm,
                objtSectCd: 'FILE',
                result: tgt.config.drive.complianceResult,
                keywords: tgt.config.version.complianceDetectedKeywords,
                url: ''
            };
        });
    }

    convertDetailToTarget() {
        if (!this.hasDetail()) return this.target;
        if (!_.has(this.detail, 'reqTgt') || !_.has(this.detail.reqDtl, 'detected')) return this.target;

        const { detected = [], linkUrl = [] } = this.detail.reqDtl;
        const detectedObj = _.groupBy(detected, 'objtId');
        const linkUrlObj = _.groupBy(linkUrl, 'objtId');

        const newTarget = [];
        _.forEach(this.detail.reqTgt, target => {
            const d = _.has(detectedObj, target.objtId) && detectedObj[target.objtId].length > 0 ? detectedObj[target.objtId][0] : {};
            const l = _.has(linkUrlObj, target.objtId) && linkUrlObj[target.objtId].length > 0 ? linkUrlObj[target.objtId][0] : {};
            const { date = '', keyword = target.keywords || '', deleteDate = '' } = d;
            const { url = '' } = l;
            newTarget.push(
                _.extend({}, target, {
                    detectedDate: date,
                    keywords: keyword,
                    deleteDate,
                    url
                })
            );
        });

        return newTarget;
    }

    async getOpinionPresets() {
        const response = await FileLifeCycleRest.getReasonPresets('COMPLIANCE');
        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return _.map(data, preset => {
            let editable = false;
            let opinion = preset.message;
            let minLength = 1;
            let { message } = preset;
            if (preset.key === 'ETC') {
                editable = true;
                opinion = '';
                message += ''; // ` (${this.intl.formatMessage({ id: 'com.text.approval.requestReason.enter' })})`;
                minLength = 5;
            }
            return _.extend(preset, { editable, opinion, message, minLength });
        });
    }

    openFileLinkUrl = url => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    fileBuilder(file) {
        if (!file) {
            return <></>;
        }

        const fileNameStyle = {
            wordBreak: 'break-all'
        };
        const fileClick = file.url ? this.openFileLinkUrl : _.noop;

        if (file.url) {
            fileNameStyle.color = '#5959ff';
            fileNameStyle.textDecoration = 'underline';
        } else {
            fileNameStyle.cursor = 'default';
            fileNameStyle.textDecoration = 'none';
        }
        const borderStyle = '1px solid #ddd';

        return (
            <tr key={`target_file_${file.objtId}`}>
                <td
                    key={`target_file_name_${file.objtId}`}
                    style={{
                        padding: '10px 20px 10px 20px',
                        fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                        fontSize: '13px',
                        color: '#666',
                        lineHeight: '17px',
                        textAlign: 'left',
                        borderBottom: borderStyle,
                        wordBreak: 'break-all',
                        verticalAlign: 'top'
                    }}>
                    <a role="button" onClick={() => fileClick(file.url)} style={fileNameStyle}>
                        {file.objtNm}
                    </a>
                </td>
                <td
                    key={`target_file_keywords_${file.objtId}`}
                    style={{
                        padding: '10px 20px 10px 0px',
                        fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                        fontSize: '13px',
                        color: '#666',
                        lineHeight: '17px',
                        textAlign: 'left',
                        borderBottom: borderStyle,
                        wordBreak: 'break-all',
                        verticalAlign: 'top'
                    }}>
                    {file.keywords}
                </td>
            </tr>
        );
    }

    filesBuilder() {
        return _.map(this.target, file => this.fileBuilder(file));
    }

    contentBuilder() {
        const borderStyle = '1px solid #ddd';
        return (
            <div
                style={{
                    margin: '0',
                    padding: '0',
                    boxSizing: 'content-box'
                }}>
                <table width="672" cellSpacing="0" cellPadding="0" border="0">
                    <tbody>
                        <tr>
                            <td height="48" style={{ verticalAlign: 'top' }}>
                                <h2
                                    style={{
                                        margin: '13px 0 0',
                                        padding: '0',
                                        fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: '#1a1a1a',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        wordBreak: 'break-all',
                                        verticalAlign: 'top'
                                    }}>
                                    <FormattedMessage id="drive.title.approval.prohibitWordExcept" />
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td height="2" style={{ background: '#000' }} />
                        </tr>
                        <tr>
                            <td height="42" style={{ padding: '0 20px', verticalAlign: 'top' }}>
                                <h4
                                    style={{
                                        margin: '13px 0 0',
                                        padding: '0',
                                        fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        color: '#666',
                                        lineHeight: '22px',
                                        textAlign: 'left',
                                        wordBreak: 'break-all',
                                        verticalAlign: 'top'
                                    }}>
                                    <FormattedMessage id="drive.text.approval.prohibitWordExcept.targetFiles" />
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '0 20px', verticalAlign: 'top' }}>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    border="0"
                                    style={{
                                        width: '100%',
                                        borderTop: borderStyle,
                                        borderLeft: borderStyle,
                                        borderRight: borderStyle,
                                        tableLayout: 'fixed'
                                    }}>
                                    <tbody>
                                        <tr>
                                            <th
                                                width="300"
                                                style={{
                                                    padding: '6px 20px 8px 20px',
                                                    fontWeight: 'normal',
                                                    fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                                    fontSize: '12px',
                                                    color: '#666',
                                                    lineHeight: '16px',
                                                    textAlign: 'left',
                                                    borderBottom: borderStyle,
                                                    wordBreak: 'break-all',
                                                    verticalAlign: 'top'
                                                }}>
                                                <FormattedMessage id="drive.text.approval.prohibitWordExcept.fileName" />
                                            </th>
                                            <th
                                                style={{
                                                    padding: '6px 20px 8px 0px',
                                                    fontWeight: 'normal',
                                                    fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                                    fontSize: '12px',
                                                    color: '#666',
                                                    lineHeight: '16px',
                                                    textAlign: 'left',
                                                    borderBottom: borderStyle,
                                                    wordBreak: 'break-all',
                                                    verticalAlign: 'top'
                                                }}>
                                                <FormattedMessage id="drive.text.approval.prohibitWordExcept.detectedWords" />
                                            </th>
                                        </tr>
                                        {this.filesBuilder()}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '15px 0 13px' }}>
                                <div style={{ height: '2px', background: '#000' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    isSubmittable() {
        return super.isSubmittable() && this.target.length > 0;
    }

    submitAction() {
        const approvers = ApprovalUtil.convertLineToTargetUsers(this.getLine());
        const fileIds = _.map(this.target, file => file.objtId);
        const params = {
            fileIds,
            approvers,
            reason: this.draftOpinion,
            reasonKey: this.draftOpinionKey,
            searchTgt: this.systemType
        };

        return FileLifeCycleRest.saveRequest('COMPLIANCE', params);
    }
}

export default ExceptNonCompliance;
