import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import classnames from 'classnames';
import OutLinkRest from '../../../../apis/OutLinkRest';
import SingleDatePickerWrapper from '../../SingleDatePickerWrapper';
import DateConverter from '../../../../utils/DateConverter';
import { alert, confirm, toast } from '../../../../utils/ModalService';
import isInclusivelyAfterDay from '../../../../utils/isInclusivelyAfterDay';

class OutLinkCreate extends Component {
    constructor(props) {
        super(props);

        this.isUnmilitedExpireDt = props.configs.OUT_LINK_OPTIONS_EXPIRED_MAX === 'UNLIMITED';
        this.expireMaxDt = this.isUnmilitedExpireDt
            ? moment()
                  .add(20, 'year')
                  .month(11)
                  .date(31)
            : moment().add(props.configs.OUT_LINK_OPTIONS_EXPIRED_MAX, 'day');
        this.state = {
            expireDtUnLmt: this.isUnmilitedExpireDt,
            expireDt: moment().add(this.isUnmilitedExpireDt ? 7 : props.configs.OUT_LINK_OPTIONS_EXPIRED_MAX, 'day'),
            downloadUnLmt: props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX === 'UNLIMITED',
            downloadCnt: props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX === 'UNLIMITED' ? 5 : props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX,
            downloadCntSettings: { min: 1, max: props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX === 'UNLIMITED' ? 9999 : props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX },
            pwdSet: props.configs.OUT_LINK_OPTIONS_PWD_SET_YN === 'Y',
            pwd: '',
            options: {
                settings: props.configs.OUT_LINK_OPTIONS,
                expireMax: props.configs.OUT_LINK_OPTIONS_EXPIRED_MAX,
                downloadMax: props.configs.OUT_LINK_OPTIONS_DOWNLOAD_MAX,
                pwdYn: props.configs.OUT_LINK_OPTIONS_PWD_SET_YN
            },
            changed: false
        };
        this.pwdInputBox = React.createRef();
    }

    isInvalidPassword() {
        const { pwd, pwdSet } = this.state;

        if (!pwdSet) {
            return false;
        }

        return !new RegExp('^[a-zA-Z0-9`~!@#$%^&*()-_=+{}|:;\'",<.>?\\[\\]\\\\/]{4,}$').test(pwd);
    }

    doSave() {
        const { expireDtUnLmt, expireDt, downloadUnLmt, pwdSet, downloadCnt, pwd } = this.state;
        const { files, close } = this.props;
        const file = files[0];
        const { drive } = file;
        let { version } = file;
        if (version === undefined) {
            version = {
                fileVerSno: drive.fileVerSno
            };
        }
        if (this.isInvalidPassword(pwd)) {
            this.pwdInputBox.focus();
            return;
        }
        OutLinkRest.outLinkCreate(drive.objtId, version.fileVerSno, drive.onpstId, expireDtUnLmt ? null : expireDt, downloadUnLmt ? null : downloadCnt, !pwdSet ? null : pwd).then(
            result => {
                const { resultCode, data } = result;
                if (resultCode === 200) {
                    toast('drive.toast.shareMakeoutlink.createdOutLink');
                    close(data);
                } else if (resultCode === 20704) {
                    toast('drive.alert.outLinkFile.impossibleExpiredDate');
                } else {
                    alert('drive.alert.shareMakeoutlink.fail').then(() => {
                        close();
                    });
                }
            },
            () => {
                alert('drive.alert.shareMakeoutlink.fail').then(() => {
                    close();
                });
            }
        );
    }

    doCancel() {
        const { close } = this.props;
        const { changed } = this.state;
        if (changed) {
            confirm('drive.confirm.share.cancelMultiShare').then(() => {
                close();
            });
        } else {
            close();
        }
    }

    changeLmtSet(e, chagedField, isUnlimited) {
        if (chagedField === 'expireDt') {
            const { expireDtUnLmt } = this.state;
            if (expireDtUnLmt !== isUnlimited) {
                this.setState({ expireDtUnLmt: !expireDtUnLmt, changed: true });
            }
        } else if (chagedField === 'download') {
            const { downloadUnLmt } = this.state;
            if (downloadUnLmt !== isUnlimited) {
                this.setState({ downloadUnLmt: !downloadUnLmt, changed: true });
            }
        } else if (chagedField === 'pwd') {
            const { pwdSet } = this.state;
            if (pwdSet !== isUnlimited) {
                this.setState({ pwdSet: !pwdSet, pwd: '', changed: true });
            }
        }
    }

    downloadCntKeyIn(e) {
        const { downloadCntSettings, downloadCnt } = this.state;
        const { min, max } = downloadCntSettings;
        const checkedValue = e.target.validity.valid ? e.target.value : downloadCnt;

        const maxNum = _.isNumber(max) ? max : max * 1;
        const minNum = _.isNumber(min) ? min : min * 1;
        const checkedValNum = _.isNumber(checkedValue) ? checkedValue : checkedValue * 1;

        if (checkedValNum < minNum) {
            this.setState({ downloadCnt: min, changed: true });
        } else if (checkedValNum > maxNum) {
            this.setState({ downloadCnt: max, changed: true });
        } else {
            this.setState({ downloadCnt: checkedValue, changed: true });
        }
    }

    increaseDownloadCnt() {
        const { downloadCnt, downloadCntSettings } = this.state;
        const { max } = downloadCntSettings;

        const maxNum = _.isNumber(max) ? max : max * 1;
        const downloadCntNum = _.isNumber(downloadCnt) ? downloadCnt : downloadCnt * 1;

        if (downloadCntNum < maxNum) {
            this.setState({ downloadCnt: downloadCntNum + 1, changed: true });
        }
    }

    decreaseDownloadCnt() {
        const { downloadCnt, downloadCntSettings } = this.state;
        const { min } = downloadCntSettings;

        const minNum = _.isNumber(min) ? min : min * 1;
        const downloadCntNum = _.isNumber(downloadCnt) ? downloadCnt : downloadCnt * 1;

        if (downloadCntNum > minNum) {
            this.setState({ downloadCnt: downloadCntNum - 1, changed: true });
        }
    }

    pwdOnChange(e) {
        if (e.target.validity.valid) {
            this.setState({ pwd: e.target.value, changed: true });
        }
    }

    expireDtChange(date) {
        this.setState({ expireDt: date });
    }

    btnClassName(isActivate, field) {
        let styledClass = 'btn btn-secondary';
        if (isActivate) {
            styledClass = styledClass.concat(' active');
        }
        const { options } = this.state;
        const { settings, expireMax, downloadMax } = options;
        if (settings === 'FIXED') {
            styledClass = styledClass.concat(' disabled');
            return styledClass;
        }

        if (field === 'expireDt' && expireMax !== 'UNLIMITED') {
            styledClass = styledClass.concat(' disabled');
        }
        if (field === 'download' && downloadMax !== 'UNLIMITED') {
            styledClass = styledClass.concat(' disabled');
        }
        return styledClass;
    }

    downloadStepperClassName(settings) {
        let styledClass = 'box-input-group numericStepper';
        if (settings === 'FIXED') {
            styledClass = styledClass.concat(' disabled');
        }
        return styledClass;
    }

    render() {
        const { close, files, intl } = this.props;
        const file = files[0];
        const { drive } = file;
        let { version, users } = file;
        if (version === undefined) {
            version = {
                fileVerSno: drive.fileVerSno
            };
        }
        if (users === undefined) {
            users = {
                objtStatChgUser: drive.objtStatChgUserMap
            };
        }
        const { objtStatChgUser } = users;
        const { expireDtUnLmt, expireDt, downloadUnLmt, downloadCnt, pwdSet, pwd, options } = this.state;
        const { settings, expireMax } = options;

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.shareMakeoutlink.newOutLink" />
                    </h5>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={intl.formatMessage({ id: 'com.close' })}
                        data-dismiss="modal"
                        onClick={e => {
                            close();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <div className="dt-width-120">
                        <dl className="row-list text-info">
                            <dt>
                                <FormattedMessage id="drive.text.shareMakeoutlink.versionInfo" />
                            </dt>
                            <dd>
                                <div className="dd-text">
                                    <FormattedMessage id="drive.text.shareMakeoutlink.version" />
                                    {version.fileVerSno} (<FormattedMessage id="drive.text.shareMakeoutlink.lastModDt" /> : {DateConverter.convert(drive.objtStatChgDt)}, {objtStatChgUser.userNm})
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.shareMakeoutlink.expiredDt" />
                            </dt>
                            <dd>
                                <div className="forms-container">
                                    <div className="btn-group" data-toggle="buttons">
                                        <label
                                            className={this.btnClassName(!expireDtUnLmt, 'expireDt')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'expireDt', false);
                                                e.stopPropagation();
                                            }}>
                                            <input type="radio" name="options1" autoComplete="off" defaultChecked={!expireDtUnLmt} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.limit" />
                                            </span>
                                        </label>
                                        <label
                                            className={this.btnClassName(expireDtUnLmt, 'expireDt')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'expireDt', true);
                                                e.stopPropagation();
                                            }}>
                                            <input type="radio" name="options1" autoComplete="off" defaultChecked={expireDtUnLmt} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.unlimited" />
                                            </span>
                                        </label>
                                    </div>
                                    {!expireDtUnLmt ? (
                                        <div className="box-input-datepicker">
                                            <SingleDatePickerWrapper
                                                initialDate={expireDt}
                                                date={expireDt}
                                                maxDate={this.expireMaxDt}
                                                disabled={settings === 'FIXED'}
                                                isOutsideRange={day =>
                                                    !isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(expireMax === 'UNLIMITED' ? 365 : expireMax, 'day'))
                                                }
                                                onDateChange={date => {
                                                    this.expireDtChange(date);
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.shareMakeoutlink.downloadCount" />
                            </dt>
                            <dd>
                                <div className="forms-container">
                                    <div className="btn-group" data-toggle="buttons">
                                        <label
                                            className={this.btnClassName(!downloadUnLmt, 'download')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'download', false);
                                                e.stopPropagation();
                                            }}>
                                            <input type="radio" name="options2" autoComplete="off" defaultChecked={!downloadUnLmt} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.limit" />
                                            </span>
                                        </label>
                                        <label
                                            className={this.btnClassName(downloadUnLmt, 'download')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'download', true);
                                                e.stopPropagation();
                                            }}>
                                            <input type="radio" name="options2" autoComplete="off" defaultChecked={downloadUnLmt} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.unlimited" />
                                            </span>
                                        </label>
                                    </div>
                                    {!downloadUnLmt ? (
                                        <div className={this.downloadStepperClassName(settings)}>
                                            <a
                                                className="btn-ic-nor btn-func-minus"
                                                role="button"
                                                title={intl.formatMessage({ id: 'drive.button.shareMakeoutlink.minus' })}
                                                onClick={() => this.decreaseDownloadCnt()}>
                                                <i className="ic-20-minus" />
                                            </a>
                                            <input className="form-control" type="text" pattern="[0-9]*" value={downloadCnt} onChange={e => this.downloadCntKeyIn(e)} />
                                            <a
                                                className="btn-ic-nor btn-func-plus"
                                                role="button"
                                                title={intl.formatMessage({ id: 'drive.button.shareMakeoutlink.plus' })}
                                                onClick={() => this.increaseDownloadCnt()}>
                                                <i className="ic-20-plus" />
                                            </a>
                                        </div>
                                    ) : null}
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.shareMakeoutlink.password" />
                            </dt>
                            <dd>
                                <div className="forms-container">
                                    <div className="btn-group" data-toggle="buttons">
                                        <label
                                            className={this.btnClassName(pwdSet, 'pwd')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'pwd', true);
                                            }}>
                                            <input type="radio" name="options3" autoComplete="off" defaultChecked={pwdSet} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.set" />
                                            </span>
                                        </label>
                                        <label
                                            className={this.btnClassName(!pwdSet, 'pwd')}
                                            onClick={e => {
                                                this.changeLmtSet(e, 'pwd', false);
                                            }}>
                                            <input type="radio" name="options3" autoComplete="off" defaultChecked={!pwdSet} />
                                            <span className="btn-text">
                                                <FormattedMessage id="drive.button.shareMakeoutlink.notSet" />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        {pwdSet ? (
                            <dl className="row-list">
                                <dt>
                                    <FormattedMessage id="drive.text.shareMakeoutlink.inputPassword" />
                                </dt>
                                <dd>
                                    <div className="input-group-remark">
                                        <div className={this.isInvalidPassword() ? 'box-input-group is-invalid' : 'box-input-group'}>
                                            <input
                                                ref={ref => {
                                                    this.pwdInputBox = ref;
                                                }}
                                                className="form-control"
                                                type="text"
                                                placeholder={intl.formatMessage({ id: 'drive.placeHolder.shareMakeoutlink.overFourText' })}
                                                value={pwd}
                                                onChange={e => {
                                                    this.pwdOnChange(e);
                                                }}
                                            />
                                        </div>
                                        {this.isInvalidPassword() && (
                                            <div className="input-remark">
                                                <div className="err-msg">
                                                    <FormattedMessage id="drive.guideText.shareMakeoutlink.passwordOverFour" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </dd>
                            </dl>
                        ) : null}
                    </div>
                    <div className="info-msg">
                        <i className="ic-16-info" />
                        <FormattedMessage id="drive.guideText.shareMakeoutlink.securityGuide" values={{ breakingLine: <br /> }} />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />

                    <div className="modal-footer-btns">
                        <a
                            className="btn btn-lg btn-secondary"
                            role="button"
                            data-dismiss="modal"
                            onClick={e => {
                                this.doCancel();
                                e.stopPropagation();
                            }}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a
                            className={classnames('btn', 'btn-lg', 'btn-primary', this.isInvalidPassword() && 'disabled')}
                            role="button"
                            onClick={e => {
                                this.doSave();
                                e.stopPropagation();
                            }}>
                            <span className="btn-text">
                                <FormattedMessage id="com.save" />
                            </span>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

OutLinkCreate.propTypes = {
    files: PropTypes.array,
    configs: PropTypes.object,
    close: PropTypes.func,
    intl: PropTypes.object
};

OutLinkCreate.defaultProps = {
    files: [],
    configs: {},
    close: () => {},
    intl: {}
};

export default injectIntl(OutLinkCreate);
