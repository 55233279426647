import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Url from 'utils/Url';
import DeletedFiles from './DeletedFiles';
import Help from './Help';
import Home from './Home';
import OwnFiles from './OwnFiles';
import SearchFiles from './SearchFiles';
import Settings from './Settings';
import SharedFiles from './SharedFiles';
import SharingFiles from './SharingFiles';
import Notification from './Notification';
import IncomLinkPopup from './IncomLinkPopup';
import IncomTypeLinkPopup from './IncomTypeLinkPopup';
import WorkgroupFiles from './WorkgroupFiles';
import ExpirationFiles from './ExpirationFiles';
import ComplianceFiles from './ComplianceFiles';
import AgentDownloadPopup from './AgentDownloadPopup';
import ReqMng from './ReqMng';
import AgentLockingPopup from './AgentLockingPopup';
import RequestTest from './RequestTest';
import DuplicatedFiles from './DuplicatedFiles';
import SimilarFiles from './SimilarFiles';
import WebOfficePopup from './WebOfficePopup';

const Pages = () => (
    <Switch>
        <Redirect exact={true} from={`${Url.home}/refresh`} to={Url.home} />
        <Route path={Url.home} component={Home} />
        <Redirect exact={true} from={Url.own} to={`${Url.own}/111100000000000000`} />
        <Route exact={true} path={`${Url.own}/:folderId`} component={OwnFiles} />
        <Redirect exact={true} from={`${Url.expiration}/refresh`} to={Url.expiration} />
        <Route exact={true} path={`${Url.expiration}`} render={props => <ExpirationFiles {...props} />} />
        <Redirect exact={true} from={`${Url.compliance}/refresh`} to={Url.compliance} />
        <Route exact={true} path={`${Url.compliance}`} component={ComplianceFiles} />
        <Redirect exact={true} from={Url.shared} to={`${Url.shared}/111100000000000000`} />
        <Route exact={true} path={`${Url.shared}/:folderId`} component={SharedFiles} />
        <Redirect exact={true} from={Url.sharing} to={`${Url.sharing}/111100000000000000`} />
        <Route exact={true} path={`${Url.sharing}/:folderId`} component={SharingFiles} />
        <Redirect exact={true} from={Url.workgroup} to={`${Url.workgroup}/111100000000000000`} />
        <Route exact={true} path={`${Url.workgroup}/:folderId`} component={WorkgroupFiles} />
        <Redirect exact={true} from={`${Url.deleted}/refresh`} to={Url.deleted} />
        <Redirect exact={true} from={`${Url.deleted}/111100000000000000`} to={Url.deleted} />
        <Route exact={true} path={`${Url.deleted}/:folderId?`} render={props => <DeletedFiles {...props} />} />
        <Route path={Url.search} render={props => <SearchFiles {...props} />} />
        <Route path={Url.settings} render={props => <Settings {...props} />} />
        <Route path={`${Url.help}/:options?`} render={props => <Help {...props} />} />
        <Route path={Url.notification} render={props => <Notification {...props} />} />
        <Route exact={true} path={`${Url.link}/:incomLinkUrl`} component={IncomLinkPopup} />
        <Route exact={true} path={`${Url.link}/:objtId/:incomLinkUrl`} component={IncomTypeLinkPopup} />
        <Route exact={true} path={`${Url.agentDown}`} component={AgentDownloadPopup} />
        <Route exact={true} path={`${Url.req_mng}/:type`} component={ReqMng} />
        <Route exact={true} path={`${Url.req_mng}/:type`} component={ReqMng} />
        <Route exact={true} path={`${Url.agentLocking}`} component={AgentLockingPopup} />
        <Route exact={true} path="/rerereq/:system/:type" component={RequestTest} />
        <Route exact={true} path="/rerereq/:system/:type/:reqId" component={RequestTest} />
        <Redirect exact={true} from={`${Url.duplicated}/refresh`} to={Url.duplicated} />
        <Route exact={true} path={`${Url.duplicated}`} render={props => <DuplicatedFiles {...props} />} />
        <Redirect exact={true} from={`${Url.similar}/refresh`} to={Url.similar} />
        <Route exact={true} path={`${Url.similar}`} render={props => <SimilarFiles {...props} />} />
        <Route exact={true} path="/weboffice/:objtId" component={WebOfficePopup} />
    </Switch>
);

export default Pages;
