import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import $ from 'jquery';
// import ClickOutside from 'presentationals/ClickOutside';
import { renderToString } from 'react-dom/server';
import { injectIntl } from 'react-intl';
import Option from '../Column/Option';
import CheckBox from '../Column/CheckBox';
import MenuSupporter from '../../../../utils/FileMenu/MenuSupporter';
import { getCheckedFiles, isChecked, isReachedMaxCount, getMaxCount, isListOverMaxCount } from '../../../../redux/reducers/FileCheck';
import FileReloadAction from '../../../../redux/actions/FileReload';
import FileCheckAction from '../../../../redux/actions/FileCheck';
import DragDropAction from '../../../../redux/actions/DragDrop';
import FileReload from '../../../../utils/FileReload';
import { withFilesContext, compareProps } from '../DriveFilesContext';
import { alert as modalAlert } from '../../../../utils/ModalService';
import { POINT_TYPE, ACTION_TYPE, ITEM_TYPE } from '../../../../constants/DragDrop';
import DragDropUtil from '../../../../utils/DragDrop';
import store from '../../../../redux/store';
import { isDragging, getDragDrop } from '../../../../redux/reducers/DragDrop';
import CoachMarkConstants from '../../../../constants/CoachMark';

class NormalRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false, // 선택 상태의 css class 적용 여부
            checked: false,
            hoverChecked: false, // checkbox영역 노출 여부
            checkDisabled: false, // checkbox 사용불가 여부

            // Row Drag 여부
            isDragging: false,
            // Drop Zone 관련
            isActiveDropZone: false,
            isDragOver: false
        };

        const {
            file: { drive },
            context: {
                props: { pageClass }
            },
            loginUser,
            intl,
            configs: siteConfigs
        } = props;
        this.pageClass = pageClass;
        this.folderInformation = FileReload.getFolderInformation();
        this.isFolder = drive.objtSectCd === 'FOLDER';

        // 더보기버튼 커스텀 가능하도록 수정
        const {
            menu: {
                row: { moreGroupType = 'normal' }
            }
        } = this.pageClass;

        this.fileIconCustom = {
            statusCallback: isOpen => {
                this.setState({ hover: isOpen });
            },
            openType: 'normal_left',
            moreGroupType,
            isExplorerConstraintsValue: this.pageClass.isExplorerConstraints(this.folderInformation),
            userConfig: loginUser.config,
            loginUser,
            getSiteConfig: id => (_.find(siteConfigs, { id }) || {}).value
        };
        this.count = 0;
        this.target = {};
        this.onDragStart = this.onDragStart.bind(this);
        this.dzRef = this.isFolder ? React.createRef() : null;

        const { id } = loginUser;

        this.showCoachMark = localStorage.getItem(`${CoachMarkConstants.COACHMARK_LIST}${id}`);

        const dataContent = (
            <div className="coachmark-conts" id="coachmark-conts">
                <p> {intl.formatMessage({ id: 'com.text.coachMark.list' })}</p>
                <p> {intl.formatMessage({ id: 'com.text.coachMark.list.deleted' })}</p>
                <div className="btns">
                    <a className="coachmark-end" id="stop-list">
                        {intl.formatMessage({ id: 'com.text.coachMark.stop' })}
                    </a>
                </div>
            </div>
        );
        this.dataRendered = renderToString(dataContent);
    }

    handleOnClick = e => {
        const {
            shiftCheck,
            sendCheck,
            sendUnCheck,
            file = {},
            context: {
                props: { setDetailFile, pageClass },
                state: { list },
                actions: { setGridSettings }
            }
        } = this.props;
        // const { drive = {} } = file;

        setDetailFile(file);

        if (e.shiftKey) {
            if (isReachedMaxCount()) {
                modalAlert({ id: 'drive.alert.list.select.max', values: getMaxCount() });
                e.stopPropagation();
                return;
            }
            if (isListOverMaxCount(file, list)) {
                modalAlert({ id: 'drive.alert.list.select.max', values: getMaxCount() });
            }
            shiftCheck(file, list, pageClass.isCheckableFile);
        } else {
            if (!pageClass.isCheckableFile(file)) {
                return;
            }

            const checked = isChecked(file);
            const reversed = !checked;
            if (reversed) {
                if (isReachedMaxCount()) {
                    modalAlert({ id: 'drive.alert.list.select.max', values: getMaxCount() });
                    e.stopPropagation();
                    return;
                }
                sendCheck(file);
            } else sendUnCheck(file);

            this.setState({ checked: reversed });
        }

        setGridSettings({ list });
    };

    handleOnContextMenu = e => {
        const { sendCheck, removeAll, file = {} } = this.props;
        const {
            menu: {
                row: { menuGroupType }
            }
        } = this.pageClass;

        if (!isChecked(file)) {
            removeAll();
            if (isReachedMaxCount()) {
                e.stopPropagation();
                return;
            }
            sendCheck(file);
            this.setState({ checked: true, checkDisabled: false });
        }
        if (menuGroupType) {
            MenuSupporter.open(menuGroupType, getCheckedFiles(), FileReload.call, 'normal', e, undefined, this.fileIconCustom);
        }
        e.preventDefault();
    };

    setHoverChecked = (event, isOpen) => {
        setTimeout(() => {
            this.setState({ hoverChecked: isOpen });
        });
    };

    componentDidMount() {
        const { file, index } = this.props;
        const checked = isChecked(file);
        const checkDisabled = !this.pageClass.isCheckableFile(file);
        const { checked: stateChecked, checkDisabled: stateCheckDisabled } = this.state;
        if (stateChecked !== checked || stateCheckDisabled !== checkDisabled) {
            this.setState({ checked, checkDisabled });
        }
        // if (this.dzRef) {
        //     this.dzRef.addEventListener('dragenter', this.onDropZoneDragEnter);
        //     this.dzRef.addEventListener('dragleave', this.onDropZoneDragLeave);
        // }
        if (isDragging([POINT_TYPE.LOCAL])) {
            const dragDrop = getDragDrop();
            const { drive: { objtId = '' } = {} } = file;
            let { isDragOver = false } = this.state;

            if (dragDrop.overItem && dragDrop.overItem.id === objtId && dragDrop.overItem.position === POINT_TYPE.LIST) {
                isDragOver = true;
            }
            this.setState({ isActiveDropZone: true, isDragOver });
        }
        this.dragSubscribe();

        if (this.pageClass.pageId === 'own' && this.showCoachMark !== 'N' && index === 0) {
            setTimeout(this.show, 1500);
        }
        document.getElementsByClassName('grid-row-group scroll-bar')[0].addEventListener('scroll', _.debounce(this.onScroll, 1000));
    }

    onScroll = () => {
        this.hide();
    };

    hide = () => {
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.remove();
    };

    setNLocalStorage = () => {
        const { loginUser } = this.props;
        const { id } = loginUser;
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_LIST}${id}`, 'N');
    };

    stop = () => {
        this.hide();
        this.setNLocalStorage();
    };

    show = () => {
        const { file } = this.props;
        const { drive } = file;
        this.coachmarkOwner = $(`#${drive.objtId}_has-coachmark`);
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: true
        });

        this.popover.popover('show');
        $('[class*="bs-popover-bottom"]').addClass('coachmark');
        $('[class*="bs-popover-bottom"]').attr('id', 'list-coachmark');
        // $('[class*="bs-popover-bottom"]').setAttribute('clickOutsideRef', '11');

        window.addEventListener('click', function(e) {
            $(`[class*="bs-popover-bottom"]`).each(function() {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });

        //  window.addEventListener('scroll', evt => this.onScroll(evt));

        if (document.getElementById('stop-list') !== null) {
            document.getElementById('stop-list').addEventListener('click', this.stop);
        }
    };

    componentWillUnmount() {
        if (this.dragUnsubscribe && _.isFunction(this.dragUnsubscribe)) {
            this.dragUnsubscribe();
        }

        this.hide();
    }

    dragSubscribe = () => {
        this.dragUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { dragDrop: dragDropState } = storeState;
            if (dragDropState === this.prevDragDropState) {
                return;
            }
            this.prevDragDropState = dragDropState;

            const { isActiveDropZone } = this.state;
            const { isDragging: isItemDragging = false, from = '', action = '' } = dragDropState;

            // LOCAL 파일 업로드가 아닌경우
            if (from !== POINT_TYPE.LOCAL || action !== ACTION_TYPE.UPLOAD) {
                // Drop Zone 비활성화
                if (isActiveDropZone) {
                    this.setState({ isActiveDropZone: false });
                }
                return;
            }

            if (isActiveDropZone !== isItemDragging) {
                this.setState({ isActiveDropZone: isItemDragging });
            }
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { file: nextFile, context: nextContext, isListDragging: nextIsListDragging } = nextProps;
        const { file, context, isListDragging } = this.props;
        // const { drive } = nextFile;
        let render = false;
        if (this.folderInformation !== FileReload.getFolderInformation()) {
            this.folderInformation = FileReload.getFolderInformation();
            this.fileIconCustom = Object.assign({}, this.fileIconCustom, { isExplorerConstraintsValue: this.pageClass.isExplorerConstraints(this.folderInformation) });
            render = true;
        }

        const checked = isChecked(nextFile);
        const checkDisabled = !this.pageClass.isCheckableFile(nextFile);
        const { checked: stateChecked, checkDisabled: stateCheckDisabled } = this.state;
        if (stateChecked !== checked || stateCheckDisabled !== checkDisabled) {
            nextState.checked = checked; // eslint-disable-line no-param-reassign
            nextState.checkDisabled = checkDisabled; // eslint-disable-line no-param-reassign
            render = true;
        }

        // const {
        //     drive: { objtId = '' }
        // } = file;
        // const { isDragOver } = this.state;
        // const { isDragOver: nextIsDragOver } = nextState;
        // const { overItem } = getDragDrop();
        // if (!DragDropUtil.isIE() && overItem && overItem.id === objtId && !isDragOver && nextIsDragOver && isListDragOver && !nextIsListDragOver) return false;

        if (render || file !== nextFile || nextState !== this.state) return true;

        if (compareProps(['state.viewHeight', 'state.viewColumns'], context, nextContext)) {
            return true;
        }

        if (isListDragging !== nextIsListDragging) {
            return true;
        }

        // 컬럼 크기 체크
        // if (this.isChangedColumnWidth(context, nextContext)) {
        //     return true;
        // }

        return false;
    }

    isChangedColumnWidth(context, nextContext) {
        const viewColumns = _.get(context, 'state.viewColumns');
        const nextViewColumns = _.get(nextContext, 'state.viewColumns');

        if (viewColumns.length !== nextViewColumns.length) return true;

        const colById = _.keyBy(viewColumns, 'id');
        const nextColById = _.keyBy(viewColumns, 'id');
        return _.keys(colById).some(id => colById[id].width !== nextColById[id].width);
    }

    // ROW ITEM 드래그 시작
    onDragStart(e) {
        const { file = {}, setRowDragging, startDrag } = this.props;
        const { checked } = this.state;

        let files = null;
        let items = null;

        // 체크된 ITEM - 체크된 것들 중 이동 가능한 것들만
        if (checked) {
            files = getCheckedFiles();
        }
        // 체크된 ITEM 아닌 경우 - 해당 파일만
        else {
            files = [file];
        }

        items = _.chain(files)
            .filter(item => item.actionPolicy && item.actionPolicy.move)
            .map(item => item.drive)
            .value();

        // 이동 가능한 파일 없음.
        if (!items || !items.length) {
            setRowDragging(null);
            return false;
        }

        // TREE용 redux store에 드래그 아이템 저장
        const { pageX, pageY } = e;
        startDrag(items, { x: pageX, y: pageY });

        // LIST용 드래그 아이템 저장
        const itemIds = _.map(items, 'objtId');
        setRowDragging(itemIds);

        // DROP 이벤트용 아이템 초기화
        e.dataTransfer.setData('text', '');

        this.setState({
            isDragging: true
        });

        if (DragDropUtil.isIE()) {
            const targetElm = e.target;
            const cloneElm = targetElm.cloneNode(true);
            const parentElm = targetElm.parentNode;

            parentElm.insertBefore(cloneElm, targetElm);
            targetElm.style.opacity = 0.01;

            setTimeout(function() {
                parentElm.removeChild(cloneElm);
                targetElm.style.opacity = 1.0;
            }, 0);
        } else {
            const noneElm = document.createElement('div');
            noneElm.style.display = 'none';
            e.dataTransfer.setDragImage(noneElm, 0, 0);
        }

        return true;
    }

    // ROW ITEM 드래그 종료
    onDragEnd = e => {
        e.preventDefault();
        const { setRowDragging, endDrag } = this.props;
        this.setState({
            isDragging: false
        });
        setRowDragging(null);
        endDrag();
    };

    onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    onDropZoneDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();

        // DRAG ITEM 체크
        // LOCAL TO LIST만 가능
        if (!isDragging([POINT_TYPE.LOCAL])) {
            return;
        }

        const {
            enterDrag,
            file: { drive = {}, actionPolicy = {} }
        } = this.props;

        const { objtId = '' } = drive;
        const { upload: uploadable = false } = actionPolicy;

        if (objtId) {
            enterDrag({ id: objtId, type: ITEM_TYPE.FOLDER, position: POINT_TYPE.LIST }, uploadable);
        }

        this.setState({ isDragOver: true });
    };

    onDropZoneDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();

        // DRAG ITEM 체크
        // LOCAL TO LIST만 가능
        if (!isDragging([POINT_TYPE.LOCAL])) {
            return;
        }

        const {
            leaveDrag,
            file: { drive = {} }
        } = this.props;
        const { objtId = '' } = drive;
        const dragDrop = getDragDrop();

        // LIST FOLDER 에서 다른곳으로 DRAG OVER 된 경우
        // LEAVE 대상이 드랍존이 아닌경우
        // LEAVE 처리
        if (dragDrop.overItem && dragDrop.overItem.id === objtId && dragDrop.overItem.position === POINT_TYPE.LIST && !DragDropUtil.isDropZone(e.relatedTarget)) {
            leaveDrag();
        }

        this.setState({ isDragOver: false });
    };

    onDropZoneDrop = e => {
        e.preventDefault();
        e.stopPropagation();

        // Document에 지정된 이벤트 수행 방지
        if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
            e.nativeEvent.stopImmediatePropagation();
        }

        // 로컬파일 업로드만 가능
        const { endDrag } = this.props;
        if (!isDragging([POINT_TYPE.LOCAL])) {
            endDrag();
            this.setState({ isDragOver: false });
            return;
        }

        // 업로드 처리
        const { file = {} } = this.props;
        const { actionPolicy = {} } = file;
        const { upload: uploadable = false } = actionPolicy;
        const { uploader } = this.pageClass;

        // 업로드 가능 여부에 따라 실제 처리
        if (uploadable && uploader) {
            uploader(file, e);
        }

        endDrag();
        this.setState({ isDragOver: false });
    };

    getCellQuery = column => {
        if (!['expiration', 'compliance', 'similar'].includes(this.pageClass.pageId)) return null;
        if (column.id !== 'objtNm') return null;

        const {
            context: {
                props: { searchParams = {} }
            }
        } = this.props;
        return searchParams[column.id];
    };

    getCellStyle = (column, idx, colLength) => {
        const cellStyle = {};
        if (column.width) cellStyle.flexBasis = column.width;
        if (idx + 1 !== colLength) cellStyle.paddingRight = '24px';
        return cellStyle;
    };

    getColumnRender = (column, idx, columnLength) => {
        const { file = {} } = this.props;
        const { hover } = this.state;

        return <column.bodyComp pageClass={this.pageClass} key={column.id} file={file} hover={hover} cellStyle={this.getCellStyle(column, idx, columnLength)} query={this.getCellQuery(column)} />;
    };

    render() {
        const {
            file = {},
            style,
            context: { state },
            isRowDragging,
            index
        } = this.props;
        const {
            menu: {
                row: { iconGroupType }
            }
        } = this.pageClass;
        const { drive = {}, actionPolicy = {} } = file;
        const { move: movable = false, upload: uploadable = false } = actionPolicy;
        const { hover, checked, hoverChecked, checkDisabled, isDragging: isItemDragging, isActiveDropZone, isDragOver } = this.state;
        const draggable = this.pageClass.dndAble && this.pageClass.pageId === 'own' && movable;
        const isDraggingItem = isItemDragging || isRowDragging(drive.objtId);
        const dropZoneStyle = Object.assign({}, style, {
            zIndex: '200',
            display: isActiveDropZone ? 'block' : 'none'
        });
        const rowStyle = Object.assign({}, style, {
            pointerEvents: isActiveDropZone ? 'none' : 'auto'
        });

        return (
            <>
                <div
                    id={drive.objtId}
                    className={classnames(
                        'grid-row',
                        checked && 'is-checked',
                        hover && 'is-hoverd',
                        drive.objtSectCd === 'FOLDER' && 'folder-row',
                        isDraggingItem && 'dnd-item',
                        this.isFolder && 'dzdzdz'
                    )}
                    draggable={draggable} // LIST TO TREE
                    onDragStart={this.onDragStart} // LIST TO TREE
                    onDragEnd={this.onDragEnd} // LIST TO TREE
                    onDragOver={this.onDragOver}
                    key={`list_row_${drive.objtId}`}
                    style={rowStyle}
                    onClick={this.handleOnClick}
                    onContextMenu={this.handleOnContextMenu}
                    onMouseEnter={e => this.setHoverChecked(e, true)}
                    onMouseLeave={e => this.setHoverChecked(e, false)}
                    role="button">
                    <CheckBox file={file} checked={checked} hover={hoverChecked} disabled={checkDisabled} />
                    {state.viewColumns.map((column, idx) => this.getColumnRender(column, idx, state.viewColumns.length))}
                    <Option groupType={iconGroupType} file={file} fileIconCustom={this.fileIconCustom} />
                </div>

                {this.showCoachMark !== 'N' && index === 0 && this.pageClass.pageId === 'own' && (
                    <div
                        className={classnames(
                            'grid-row',
                            'has-coachmark',
                            checked && 'is-checked',
                            hover && 'is-hoverd',
                            this.isFolder && 'folder-row',
                            isDraggingItem && 'dnd-item',
                            this.isFolder && 'dzdzdz'
                        )}
                        data-placement="bottom"
                        data-coachmark-order="30"
                        data-content={this.dataRendered}
                        data-toggle="popover"
                        id={`${drive.objtId}_has-coachmark`}
                        draggable={draggable} // LIST TO TREE
                        onDragStart={this.onDragStart} // LIST TO TREE
                        onDragEnd={this.onDragEnd} // LIST TO TREE
                        onDragOver={this.onDragOver}
                        key={`list_row_${drive.objtId}_has-coachmark`}
                        style={rowStyle}
                        onClick={this.handleOnClick}
                        onContextMenu={this.handleOnContextMenu}
                        onMouseEnter={e => this.setHoverChecked(e, true)}
                        onMouseLeave={e => this.setHoverChecked(e, false)}
                        role="button">
                        <CheckBox file={file} checked={checked} hover={hoverChecked} disabled={checkDisabled} />
                        {state.viewColumns.map((column, idx) => this.getColumnRender(column, idx, state.viewColumns.length))}
                        <Option groupType={iconGroupType} file={file} fileIconCustom={this.fileIconCustom} />
                    </div>
                )}

                {this.isFolder && this.pageClass.dndAble && !isDraggingItem && (
                    <div
                        key={`list_row_drop_zone_${drive.objtId}`}
                        className={classnames('grid-row', 'folder-row', isDragOver && 'dnd-zone', !uploadable && 'failure', 'dzdzdz')}
                        ref={ref => {
                            if (ref) {
                                ref.style.setProperty('background-color', 'transparent', 'important');
                            }
                            this.dzRef = ref;
                        }}
                        onDragEnter={this.onDropZoneDragEnter}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDropZoneDragLeave}
                        onDrop={this.onDropZoneDrop}
                        style={dropZoneStyle}>
                        <div
                            className="grid-cell dzdzdz"
                            style={{
                                width: '100%',
                                height: '100%',
                                padding: '0',
                                margin: '0',
                                pointerEvents: 'none',
                                zIndex: '190'
                            }}
                        />
                    </div>
                )}
            </>
        );
    }
}

NormalRow.propTypes = {
    context: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    // 체크박스 액션
    removeAll: PropTypes.func.isRequired,
    shiftCheck: PropTypes.func.isRequired,
    sendCheck: PropTypes.func.isRequired,
    sendUnCheck: PropTypes.func.isRequired,
    // ROW 드래그 액션
    startDrag: PropTypes.func.isRequired,
    endDrag: PropTypes.func.isRequired,
    enterDrag: PropTypes.func.isRequired,
    leaveDrag: PropTypes.func.isRequired,
    // 리스트 드래그 관련
    isListDragging: PropTypes.bool,
    isRowDragging: PropTypes.func,
    setRowDragging: PropTypes.func,

    index: PropTypes.number,
    loginUser: PropTypes.object.isRequired,
    configs: PropTypes.object.isRequired
};

NormalRow.defaultProps = {
    isListDragging: false,
    isRowDragging: () => false,
    setRowDragging: () => {},
    index: ''
};

const mapStateToProps = state => ({
    loginUser: state.auth.user,
    configs: state.config.configs
});

NormalRow.displayName = 'NormalRow';
export default injectIntl(
    connect(
        mapStateToProps,
        {
            shiftCheck: (file, list, filter) => ({ type: FileCheckAction.SHIFT_ADD, file, list, filter }),
            removeAll: () => ({ type: FileReloadAction.EXECUTE, data: { reloadType: 'uncheckedAll' } }),
            sendCheck: file => ({ type: FileCheckAction.ADD, file }),
            sendUnCheck: file => ({ type: FileCheckAction.REMOVE, file }),
            startDrag: (items, cursor = null) => ({ type: DragDropAction.START_DRAG, from: POINT_TYPE.LIST, action: ACTION_TYPE.MOVE, items, cursor }),
            endDrag: () => ({ type: DragDropAction.END_DRAG }),
            enterDrag: (overItem, isDroppable) => ({ type: DragDropAction.ENTER_DRAG, isDragOver: true, isDroppable, overItem }),
            leaveDrag: () => ({ type: DragDropAction.LEAVE_DRAG })
        }
    )(withFilesContext(NormalRow))
);
