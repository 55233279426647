import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FilterCheckbox = ({ label, value, isChecked, onChange }) => {
    const [checked, setChecked] = useState(false);

    const handleChange = e => {
        onChange({ checked: e.target.checked, value });
    };

    useEffect(() => {
        setChecked(isChecked(value));
    });

    return (
        <div className="form-check">
            <label className="form-check-label">
                <input className="form-check-input" type="checkbox" checked={checked} onChange={handleChange} />
                <span className="label-text">{label}</span>
            </label>
        </div>
    );
};

FilterCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isChecked: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

FilterCheckbox.defaultProps = {};

export default FilterCheckbox;
