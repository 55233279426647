import _ from 'lodash';
import moment from 'moment';
import $http from 'modules/Http';

export const GET_HELP_BASIC_INFO_URL = '/common/v1/helpcenter/info';
export const GET_QUESTION_DEFAULT_INFO_URL = '/common/v1/helpcenter/question';

export default class HelpRest {
    static getHelpBasicInfo = async () => {
        const response = await $http.get(GET_HELP_BASIC_INFO_URL);
        return response.data;
    };

    static postQuestion = async (param, files) => {
        let response;
        if (_.isEmpty(files)) {
            response = await $http.post('/common/v1/helpcenter/question', param);
        } else {
            const data = new FormData();
            const objectKeys = Object.keys(param);
            _.forEach(objectKeys, objectKey => {
                if (!_.isEmpty(param[objectKey])) {
                    data.append(objectKey, param[objectKey]);
                }
            });
            for (let i = 0; i < files.length; i += 1) {
                data.append('file', files[i].file);
            }
            response = await $http.post('/common/v1/helpcenter/question/attachment', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        return response;
    };

    static getIsDuplicatedLoginId = async (userId, tenantId) => {
        const response = await $http.get('/auth/v1/user/valid', { params: { userLoginId: userId, tenantId } });
        if (response.resultCode === 10501) return true;
        return false;
    };

    static reqPartnerAccount = async (partnerList, tenantId, useExpireYn) => {
        const paramPartnerList = [];
        for (let i = 0; i < partnerList.length; i += 1) {
            const partner = partnerList[i];
            const paramPartner = {
                userIfYn: partner.hasKPAccount ? 'Y' : 'N',
                userLoginId: partner.id,
                langCd: partner.language,
                userNm: partner.name,
                userCopNm: partner.position,
                userEngNm: partner.engName,
                userCopEngNm: partner.engPosition,
                orgnOnpstCoNm: partner.companyNm,
                deptId: partner.deptId,
                lightDriveStorageAllocSize: partner.lightDriveStorageAllocSize,
                mailDriveStorageAllocSize: partner.mailDriveStorageAllocSize
            };
            if (!partner.hasKPAccount) {
                if (useExpireYn === 'N') {
                    const YYYYMMDDFORMAT = moment('9999-12-31').format('YYYY-MM-DD');
                    const momentTil235959 = `${YYYYMMDDFORMAT}T23:59:59`;
                    const timezoneAdded = moment(momentTil235959);
                    paramPartner.accessExpirationTime = timezoneAdded.valueOf();
                    paramPartner.notAllowedSyncPeriodYmd = timezoneAdded.format('YYYYMMDD');
                } else {
                    const expireDateMoment = partner.expireDate;
                    expireDateMoment
                        .hour(23)
                        .minute(59)
                        .second(59);
                    paramPartner.accessExpirationTime = expireDateMoment.valueOf();
                    paramPartner.notAllowedSyncPeriodYmd = expireDateMoment.format('YYYYMMDD');
                }
            } else {
                paramPartner.notAllowedSyncPeriodYmd = '99991231';
            }
            paramPartnerList.push(paramPartner);
        }

        const response = await $http.post('/auth/v1/users/subcontractors', paramPartnerList, { params: { tenantId } });
        return response;
    };

    static getQuestionDefaultInfo = async () => {
        const response = await $http.get(GET_QUESTION_DEFAULT_INFO_URL);
        return response.data;
    };
}
