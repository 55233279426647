import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import { injectIntl } from 'react-intl';

import InstantSearchList from './InstantSearchList';
import ClickOutside from '../../presentationals/ClickOutside';

const InstantSearchOnlyOneInput = props => {
    const inputRef = React.createRef();
    const husksRef = React.createRef();
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [value, setValue] = useState('');
    const [keyUpEvent, setKeyUpEvent] = useState();
    const { select, remove, intl, targetFromParent, targetTypes, placeholder } = props;
    const onChange = e => {
        setQuery(e.target.value);
    };

    const setTarget = target => {
        const selectedValue = `${_.get(target, 'name', '')} ${_.get(target, 'userCopNm', '')}/${_.get(target, 'deptNm', '')}`;
        setValue(selectedValue);
        setQuery(selectedValue);
        setIsOpen(false);
    };
    const onSelect = target => {
        setTarget(target);
        select(target);
    };

    const onRemove = () => {
        remove();
        setQuery('');
        setValue('');
        inputRef.current.focus();
    };

    const onFocus = () => {
        setIsOpen(true);
    };

    const onKeyUp = e => {
        const { nativeEvent } = e;
        setKeyUpEvent(nativeEvent);
    };

    useEffect(() => {
        if (!_.isEmpty(targetFromParent)) {
            setTarget(targetFromParent);
        }
    }, [targetFromParent]);

    const readOnly = !_.isEmpty(value);

    return (
        <ClickOutside onClickOutside={() => setIsOpen(false)} childrenRefs={[husksRef]}>
            <div className={`box-input-group has-instant-srch only-value ${readOnly ? 'readonly' : ''}`} title={value} ref={husksRef}>
                <input
                    name="instant-search-input"
                    className="form-control"
                    readOnly={readOnly}
                    placeholder={intl.formatMessage({ id: placeholder })}
                    type="text"
                    value={query}
                    onChange={onChange}
                    id="instant-searcher"
                    autoComplete="off"
                    onFocus={onFocus}
                    onKeyUp={onKeyUp}
                    ref={inputRef}
                />
                <a className="btn-ic-nor btn-func-clear" role="button" style={{ display: readOnly ? 'inline-flex' : 'none' }}>
                    <i className="ic-16-clear" role="button" onClick={onRemove} />
                </a>
                <If condition={isOpen}>
                    <InstantSearchList query={query} select={onSelect} keyEvent={keyUpEvent} targetTypes={targetTypes} />
                </If>
            </div>
        </ClickOutside>
    );
};

InstantSearchOnlyOneInput.propTypes = {
    intl: PropTypes.object.isRequired,
    select: PropTypes.func,
    remove: PropTypes.func,
    targetFromParent: PropTypes.object,
    targetTypes: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
    placeholder: PropTypes.string
};

InstantSearchOnlyOneInput.defaultProps = {
    select: _.noop,
    remove: _.noop,
    targetFromParent: undefined,
    targetTypes: 'all',
    placeholder: 'user.placeHolder.pop_InstantSearch.enterName'
};

export default injectIntl(InstantSearchOnlyOneInput);
