import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseMenu from '../BaseMenu';
import RecycleBinRest from '../../../../apis/RecycleBinRest';
import DriveInfoRest from '../../../../apis/DriveInfoRest';
import FolderSelectPopup from '../../../containers/Popup/FolderSelect/FolderSelectPopup';
import { popup, toast } from '../../../../utils/ModalService';
import ModalAlert from '../../ModalAlert';

const executor = (props, files) => {
    const { onpstSectCd, onpstId } = files[0].drive;

    function complete() {
        toast('drive.toast.restore.complete');
        props.callback();
    }

    function fail() {
        toast('drive.toast.restore.fail');
    }

    function getOnpstFolderId(destFolder) {
        const { objtId } = destFolder;
        if (objtId === 'own') {
            return '111100000000000000';
        }

        return objtId;
    }

    function isNormalFolder({ folderFile = {} } = {}) {
        return !!folderFile && folderFile.objtDelCd === 'NORMAL';
    }

    function convertToOnpstFolder(folder) {
        if (isNormalFolder(folder)) {
            const rootFolder = onpstSectCd === 'INDVFOLDER' ? 'own' : 'workgroup';
            return {
                mode: rootFolder.concat('Only'),
                preSelectedFolder: {
                    drive: folder.folderFile,
                    actionPolicy: { create: true }
                }
            };
        }

        return undefined;
    }

    function convertToOnpstFolders(folders) {
        if (!!folders && folders.length > 0) {
            const abnormal = folders.filter(folder => !isNormalFolder(folder));
            if (abnormal.length > 0) {
                return undefined;
            }
            return convertToOnpstFolder(folders[0]);
        }

        return undefined;
    }

    function restoreWithOnpstFolder(options = { mode: onpstSectCd === 'INDVFOLDER' ? 'ownOnly' : 'workgroupOnly' }) {
        popup(<FolderSelectPopup {...options} />).then(
            destFolder => {
                RecycleBinRest.restoreObjtList(
                    files.map(({ drive }) => {
                        return {
                            objtId: drive.objtId,
                            onpstId: drive.onpstId,
                            onpstFolderId: getOnpstFolderId(destFolder)
                        };
                    })
                ).then(complete, fail);
            },
            () => false
        );
    }

    function chkOnpstFolderStatus() {
        const onpstFolderIds = files.map(file => file.drive.onpstFolderId).join();

        return DriveInfoRest.getBasicFolderFileInfoList(onpstFolderIds, onpstId).then(result => {
            if (result.resultCode === 200) {
                return convertToOnpstFolders(result.data);
            }

            return undefined;
        });
    }

    function customConfirm(options) {
        if (options) return Promise.resolve(options);

        const alertProps = {
            btnMessage: { okay: { id: 'drive.button.restore.folderSelect' }, cancel: { id: 'com.cancel' } },
            message: <FormattedMessage id="drive.confirm.restore.folderSelect" />,
            useCancel: true
        };

        return popup(<ModalAlert {...alertProps} />).then(ret => (ret ? Promise.resolve() : Promise.reject()));
    }

    function getOnpstFolder(options) {
        if (options || onpstSectCd === 'INDVFOLDER') return Promise.resolve(options);

        // 워크그룹인데 상위폴더 정보가 없는경우
        // 워크그룹정보 세팅
        return DriveInfoRest.getBasicFolderFileInfo(onpstId, onpstId).then(res => {
            if (res.resultCode === 200) {
                return convertToOnpstFolder(res.data);
            }

            return undefined;
        });
    }

    chkOnpstFolderStatus()
        .then(customConfirm)
        .then(getOnpstFolder)
        .then(restoreWithOnpstFolder);
};

class Restore extends BaseMenu {
    getName() {
        return 'restore';
    }

    getLabel() {
        return 'drive.button.list.menu.restore';
    }

    isVisible() {
        return true; // super.isVisible(true, 'restore');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-restore-folder', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-view', 'ic-20-restore-folder', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Restore;
