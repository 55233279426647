import BaseMenu from '../BaseMenu';
import FileReload from '../../../../utils/FileReload';

const executor = (props, files) => {
    FileReload.call(props, files, { reloadType: 'openDetail' });
};

class Information extends BaseMenu {
    getName() {
        return 'information';
    }

    getLabel() {
        return 'drive.button.list.menu.information';
    }

    isVisible() {
        return super.isVisible(false, 'viewList');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-info', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-info', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Information;
