import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import FileIconStyle from '../../../../utils/FileIconStyle';

class ReqDtlBodyTgtFile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderTgtRow = () => {
        const { reqTgt } = this.props;

        const listItems = reqTgt.map(item => (
            <div className="inner-grid-view" key={`tgt_row_${item.objtId}`}>
                <div className="grid-cell file-cell">
                    <div className="item-file">
                        <i className={FileIconStyle.getStyle(`ic-file-md`, item.objtNm)} />
                        <div className="file" title="">
                            <span className="file-name">{item.objtNm.split('.')[0]}</span>
                            <span className="file-extension">{item.objtNm.split('.')[1]}</span>
                        </div>
                    </div>
                </div>
            </div>
        ));

        return <div className="box-line box-grid-list">{listItems}</div>;
    };

    render() {
        const { reqTgt } = this.props;

        return (
            <React.Fragment>
                <dl className="dt-divider">
                    <dt>
                        <FormattedMessage id="drive.title.ReqDtlBodyTgtFile" />
                    </dt>
                    <dd>
                        <div className="total-count">
                            <span>
                                <FormattedMessage id="drive.text.ReqDtlBodyTgtFile.total" />
                            </span>
                            <span className="count">
                                <i className="num">{reqTgt.length}</i>
                            </span>
                        </div>
                    </dd>
                </dl>

                {this.renderTgtRow()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

ReqDtlBodyTgtFile.propTypes = {
    reqTgt: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ReqDtlBodyTgtFile.defaultProps = {
    reqTgt: {}
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlBodyTgtFile));

export default connected;
