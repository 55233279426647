import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalPopup } from '../../redux/actions';
import ChangePassword from '../presentationals/ChangePassword';
import SettingsRest from '../../apis/SettingsRest';

const initialState = {
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    notMatchedCurrentPassword: false,
    notMatchedConfirmPassword: true,
    checkLength: false,
    checkCombination: false,
    checkEqualThreeCharacters: false,
    checkContinuousNumber: false,
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
    firstChange: false,
    checkIsEqualNow: false
};

class ChangePasswordContainer extends Component {
    constructor(props) {
        super(props);
        this.state = _.clone(initialState);
    }

    setShowStatusPassword = type => {
        if (_.isEmpty(type)) return;
        this.setState(prevState => ({ [`show${type}`]: !prevState[`show${type}`] }));
    };

    checkCurrentPassword = async event => {
        const { userId } = this.props;
        const { value: password } = event.target;
        this.setState({ currentPassword: password }, async () => {
            const response = await SettingsRest.checkCurrnetPassword(userId, password);
            const { newPassword } = this.state;
            const isCheckEqualNow = !_.isEmpty(password) && !_.isEmpty(newPassword) && password !== newPassword;
            this.setState({ notMatchedCurrentPassword: response.resultCode !== 200, checkIsEqualNow: isCheckEqualNow });
        });
    };

    checkNewPassword = event => {
        const { value: password } = event.target;
        const { currentPassword, confirmPassword } = this.state;

        const isCheckLength = password.length >= 8 && password.length <= 12;
        const isCheckCombine = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{3,}$/.test(password) && !/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(password);
        const isCheckEqualThreeCharacters = !/(\w|\W)\1\1/.test(password) && /.+/.test(password);
        const isCheckContinuousNumber = !/(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)/.test(password) && /.+/.test(password);
        const isCheckEqualNow = !_.isEmpty(password) && !_.isEmpty(currentPassword) && password !== currentPassword;
        const isNotMatched = password !== confirmPassword;

        this.setState({
            newPassword: password,
            checkLength: isCheckLength,
            checkCombination: isCheckCombine,
            checkEqualThreeCharacters: isCheckEqualThreeCharacters,
            checkContinuousNumber: isCheckContinuousNumber,
            checkIsEqualNow: isCheckEqualNow,
            notMatchedConfirmPassword: isNotMatched
        });
    };

    checkConfirmPassword = event => {
        const { value: confirmPassword } = event.target;
        this.setState({ confirmPassword });
        const { newPassword } = this.state;
        this.setState({ firstChange: true });
        if (_.isEmpty(confirmPassword)) {
            this.setState({ notMatchedConfirmPassword: true });
        } else if (confirmPassword !== newPassword) {
            this.setState({ notMatchedConfirmPassword: true });
        } else {
            this.setState({ notMatchedConfirmPassword: false });
        }
    };

    checkSave = () => {
        const {
            currentPassword,
            notMatchedCurrentPassword,
            checkLength,
            checkCombination,
            checkEqualThreeCharacters,
            checkContinuousNumber,
            notMatchedConfirmPassword,
            checkIsEqualNow,
            newPassword
        } = this.state;
        return (
            !_.isEmpty(currentPassword) &&
            !_.isEmpty(newPassword) &&
            !notMatchedCurrentPassword &&
            checkLength &&
            checkCombination &&
            checkEqualThreeCharacters &&
            checkContinuousNumber &&
            !notMatchedConfirmPassword &&
            checkIsEqualNow
        );
    };

    save = async () => {
        const { closeChangePassword, userId, openAlert } = this.props;
        const { currentPassword, newPassword } = this.state;
        const response = await SettingsRest.changePassword(userId, currentPassword, newPassword);
        if (response.resultCode === 200 && response.data === 1) {
            closeChangePassword(true);
            this.setState(_.clone(initialState));
        } else {
            openAlert({ id: 'com.alert.unexpected-error', values: { code: `${response.resultCode} / ${response.message}` } });
        }
    };

    cancel = () => {
        const { closeChangePassword } = this.props;
        closeChangePassword(false);
        this.setState(_.clone(initialState));
    };

    render() {
        const { isOpen } = this.props;
        const {
            showCurrentPassword,
            notMatchedCurrentPassword,
            showNewPassword,
            showConfirmPassword,
            notMatchedConfirmPassword,
            checkLength,
            checkCombination,
            checkEqualThreeCharacters,
            checkContinuousNumber,
            firstChange,
            currentPassword,
            newPassword,
            confirmPassword,
            checkIsEqualNow
        } = this.state;
        return (
            <ChangePassword
                isOpen={isOpen}
                showCurrentPassword={showCurrentPassword}
                notMatchedCurrentPassword={notMatchedCurrentPassword}
                showNewPassword={showNewPassword}
                showConfirmPassword={showConfirmPassword}
                notMatchedConfirmPassword={notMatchedConfirmPassword}
                checkLength={checkLength}
                checkCombination={checkCombination}
                checkEqualThreeCharacters={checkEqualThreeCharacters}
                checkContinuousNumber={checkContinuousNumber}
                firstChange={firstChange}
                setShowStatusPassword={this.setShowStatusPassword}
                checkCurrentPassword={this.checkCurrentPassword}
                checkNewPassword={this.checkNewPassword}
                checkConfirmPassword={this.checkConfirmPassword}
                checkSave={this.checkSave}
                save={this.save}
                cancel={this.cancel}
                currentPassword={currentPassword || ''}
                newPassword={newPassword || ''}
                confirmPassword={confirmPassword || ''}
                checkIsEqualNow={checkIsEqualNow}
            />
        );
    }
}

ChangePasswordContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeChangePassword: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    openAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.changePassword.isOpen,
    userId: state.auth.user.id
});

const mapDispatchToProps = {
    closeChangePassword: globalPopup.closeChangePassword,
    openAlert: globalPopup.openAlert
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordContainer);
