import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import FileIconStyle from '../../../utils/FileIconStyle';

class UploadItem extends Component {
    constructor(props) {
        super(props);
        this.resource = {};
    }

    renderUploadProgressCell() {
        const { fileItem } = this.props;
        const { status, progress } = fileItem;

        if (status === 'failed') {
            return <i className="ic-20-fail" />;
        }
        if (status === 'completed') {
            return <i className="ic-20-done" />;
        }
        return (
            <div className={`c-progress p${progress}`}>
                <div className="slice">
                    <div className="bar" />
                    <div className="fill" />
                </div>
            </div>
        );
    }

    render() {
        const { fileItem, style, onRemoveUploadItem, intl } = this.props;
        const { file, isError, progress } = fileItem;
        const failed = intl.formatMessage({ id: 'drive.text.upload.status.failed' });
        const remove = intl.formatMessage({ id: 'drive.button.upload.item.remove' });

        return (
            <div className="grid-row" style={style} key={0}>
                <div className="grid-cell link-cell">
                    <div className="item-file">
                        <i className={FileIconStyle.getStyle('ic-file-sm', file.name)} />
                        <div className="file file-link" title={file.name}>
                            <span className="file-name">{file.nm}</span>
                            {file.ext.length > 0 ? <span className="file-extension">{file.ext}</span> : ''}
                        </div>
                    </div>
                </div>
                <div className="grid-cell upload-progress-cell">{this.renderUploadProgressCell()}</div>
                <div className="grid-cell upload-status-cell">
                    <span className="percent">{isError ? failed : `${progress}%`}</span>
                </div>
                <div className="grid-cell upload-del-cell">
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={remove}
                        onClick={e => {
                            onRemoveUploadItem();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-close-sm" />
                    </a>
                </div>
            </div>
        );
    }
}

UploadItem.propTypes = {
    fileItem: PropTypes.object,
    intl: PropTypes.object,
    style: PropTypes.object,
    onRemoveUploadItem: PropTypes.func
};

UploadItem.defaultProps = {
    fileItem: {},
    intl: {},
    style: {},
    onRemoveUploadItem: () => {}
};

export default injectIntl(UploadItem);
