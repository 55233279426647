/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import GuideRest from '../../apis/GuideRest';

let guide = {
    enable: false,
    isInit: false
};

export const getInfo = () => {
    return guide;
};

export const isLoaded = () => {
    const walkmeToRemoves = document.getElementsByClassName('walkme-to-remove');
    return walkmeToRemoves.length > 0;
};

const QuickGuide = () => {
    const setInfo = async () => {
        const result = await GuideRest.getGuideInfo();
        const { data, resultCode } = result;

        if (resultCode !== 200) {
            return {
                enable: false
            };
        }

        guide = Object.assign(data, { isInit: true });
        return guide;
    };

    useEffect(() => {
        (async function() {
            // 퀵 가이드 정보 조회
            if (!guide.isInit) {
                await setInfo();
            }

            // 이미 존재하는지 확인
            if (isLoaded()) {
                return;
            }

            // 사용안함 처리
            if (!guide.enable && (guide.url || '').length < 1) {
                return;
            }

            // 워크미 추가
            const walkme = document.createElement('script');
            walkme.type = 'text/javascript';
            walkme.async = true;
            walkme.src = guide.url;

            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(walkme, s);
            window._walkmeConfig = {
                smartLoad: true
            };
        })();
    }, []);
    return null;
};

export default QuickGuide;
