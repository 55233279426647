import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'react-quill/dist/quill.snow.css'; // 어드민에서 사용하고있는 에디터사용 컨텐츠 표기위한 css (like 공지사항/FAQ)

import NoticeRest from 'apis/NoticeRest';
import CustomModal from 'containers/CustomModal';

import Editor from '../presentationals/editor/Editor';

class NoticePopup extends Component {
    constructor(props) {
        super(props);
        this.state = { notice: {} };
    }

    componentDidMount() {
        const { show } = this.props;
        if (show) this.getNotice();
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show !== show && show) {
            this.getNotice();
        }
    }

    getNotice = () => {
        const { anucId } = this.props;
        if (!anucId) return;
        NoticeRest.getNotice(anucId).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            this.setState({ notice: data });
        });
    };

    render() {
        const { show, handleClose, intl, anucId, showFooter } = this.props;
        const { notice } = this.state;
        const { anucImportanceYn, anucTitle, anucStartDt, anucDtlContent } = notice;
        const formmatedDate = moment(anucStartDt).format('YYYY-MM-DD');
        return (
            <CustomModal isOpen={show} fade={true} size="lg" backdrop="static" id={anucId}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="user.helpcenter.announcement" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => handleClose(false)}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className={`notice-header ${anucImportanceYn === 'Y' ? 'headline' : ''}`}>
                        <div className="notice-title">{anucTitle}</div>
                        <em className="update">{formmatedDate}</em>
                    </div>
                    <div className="notice-body">
                        <Editor html={anucDtlContent} />
                    </div>
                </div>
                <div className="modal-footer line" style={{ display: showFooter ? '' : 'none' }}>
                    <div className="modal-footer-option">
                        <div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" onChange={() => handleClose(true)} />
                                <span className="label-text">{intl.formatMessage({ id: 'user.gnb.doNotSeeAgain' })}</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer-btns" />
                </div>
            </CustomModal>
        );
    }
}

NoticePopup.propTypes = {
    intl: PropTypes.object.isRequired,
    show: PropTypes.bool,
    anucId: PropTypes.string,
    handleClose: PropTypes.func,
    showFooter: PropTypes.bool
};

NoticePopup.defaultProps = {
    show: false,
    anucId: '',
    handleClose: undefined,
    showFooter: false
};

export default injectIntl(NoticePopup);
