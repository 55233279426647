import React, { useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Url from 'utils/Url';
import uuidV4 from 'uuid/v4';
import DriveBody from '../containers/DriveBody';
import DriveHeader from '../containers/DriveHeader';
import Compliance from '../../utils/DriveConfiguration/Compliance';
import GlobalPopupService from '../../redux/service/GlobalPopup';
import FileListAction from '../../redux/actions/FileList';

const ComplianceFiles = props => {
    const { loginUser, history, setTotal } = props;
    const { config } = loginUser;
    const { useCpgs } = config;

    if (!useCpgs) {
        history.push(Url.home);
        GlobalPopupService.openAlertPopup({ id: 'com.alert.no-authority.page' });
    }

    const pageClass = Compliance;

    useEffect(() => {
        document.body.classList.remove('scroll-hidden');
        document.body.classList.add('res-except');
    }, []);

    useEffect(() => {
        return () => {
            document.body.classList.remove('res-except');
            document.body.classList.add('scroll-hidden');
        };
    }, []);

    return (
        <div className="page-drv" key={uuidV4()}>
            <DriveHeader {...props} type="compliance" pageClass={pageClass}>
                <div className="info-msg">
                    <i className="ic-16-info" />
                    <FormattedHTMLMessage id="drive.text.list.compliance.info.right" tagName="span" />
                </div>
            </DriveHeader>
            <DriveBody {...props} pageClass={pageClass} setTotal={setTotal} />
        </div>
    );
};

ComplianceFiles.propTypes = {
    loginUser: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setTotal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(
    mapStateToProps,
    {
        setTotal: total => ({ type: FileListAction.SET_TOTAL, count: total })
    }
)(ComplianceFiles);
