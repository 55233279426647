import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Tooltip } from 'reactstrap';
import styles from './Tooltips.css';

const cx = classnames.bind(styles);

class Tooltips extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        const { tooltipOpen } = this.state;

        this.setState({
            tooltipOpen: !tooltipOpen
        });
    }

    render() {
        const { targetId, placement, trigger, autohide, placementPrefix, children, tooltipRef, flip, modifiers, style } = this.props;
        const { tooltipOpen } = this.state;
        return (
            <Tooltip
                target={targetId}
                placement={placement}
                trigger={trigger}
                autohide={autohide}
                placementPrefix={placementPrefix}
                isOpen={tooltipOpen}
                toggle={this.toggle}
                innerClassName={cx('tooltip-inner')}
                ref={tooltipRef}
                modifiers={modifiers}
                flip={flip}
                style={style}>
                {children}
            </Tooltip>
        );
    }
}

Tooltips.propTypes = {
    targetId: PropTypes.string.isRequired,
    placement: PropTypes.string,
    placementPrefix: PropTypes.string,
    autohide: PropTypes.bool,
    trigger: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    tooltipRef: PropTypes.any,
    flip: PropTypes.bool,
    modifiers: PropTypes.any,
    style: PropTypes.object
};

Tooltips.defaultProps = {
    placement: 'top',
    autohide: false,
    placementPrefix: 'bs-tooltip',
    trigger: 'click hover focus',
    tooltipRef: undefined,
    flip: true,
    modifiers: undefined,
    style: undefined
};

export default Tooltips;
