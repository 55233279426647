import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from './Auth';
import fileCheck from './FileCheck';
import fileMenu from './FileMenu';
import fileReload from './FileReload';
import loading from './Loading';
import globalPopup from './GlobalPopup';
import toast from './Toast';
import upload from './Upload';
import partnerAccount from './PartnerAccount';
import config from './Config';
import reqMng from './ReqMng';
import fileLifeCycle from './FileLifeCycle';
import notice from './Notice';
import dragDrop from './DragDrop';
import fileList from './FileList';
import site from './Site';
import gnb from './Gnb';
import * as summaryReducers from './summary';

export default history =>
    combineReducers({
        router: connectRouter(history),
        summary: combineReducers({ ...summaryReducers }),
        globalPopup,
        toast,
        upload,
        fileMenu,
        fileCheck,
        fileReload,
        auth,
        partnerAccount,
        loading,
        config,
        reqMng,
        fileLifeCycle,
        notice,
        dragDrop,
        fileList,
        site,
        gnb
    });
