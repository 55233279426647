import $http from 'modules/Http';

const prefix = '/common/v1/helpcenter/guide';

class GuideRest {
    static getGuideInfo() {
        return $http.get(`${prefix}/info`);
    }
}

export default GuideRest;
