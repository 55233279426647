import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// import uuidV4 from 'uuid/v4';
import Recent from '../../../utils/DriveConfiguration/Recent';
import DriveFiles from './DriveFiles';
import RecentCoachMark from '../RecentCoachMark';

const DriveRecentFiles = ({ history, showCoachMark, hideRecentCoachMark, showFavoriteCoachMark }) => {
    const [showCoachMarkYN, setShowCoachMarkYn] = useState(showCoachMark);

    useEffect(() => {
        setShowCoachMarkYn(showCoachMark);
    }, [showCoachMark]);

    return (
        <div className="home-latest">
            {' '}
            <div className="fixed-panel">
                <div className="comm-func-bar">
                    <div className="func-bar-title">
                        {showCoachMarkYN === 'N' ? (
                            <h3>
                                <FormattedMessage id="drive.title.recent" />
                            </h3>
                        ) : (
                            <RecentCoachMark hideRecentCoachMark={hideRecentCoachMark} showFavoriteCoachMark={showFavoriteCoachMark} />
                        )}
                    </div>
                </div>
                <DriveFiles pageClass={Recent} history={history} refreshWhenMount="true" />
            </div>
        </div>
    );
};

DriveRecentFiles.propTypes = {
    history: PropTypes.object.isRequired,
    showCoachMark: PropTypes.string.isRequired,
    hideRecentCoachMark: PropTypes.func.isRequired,
    showFavoriteCoachMark: PropTypes.func.isRequired
};

export default DriveRecentFiles;
