import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PeriodButton = props => {
    const { intl, isActive, buttonLabelId, onClick } = props;

    return (
        <a className={classnames('btn', 'btn-secondary', isActive() && 'active')} role="button" onClick={onClick}>
            <span className="btn-text">{intl.formatMessage({ id: buttonLabelId })}</span>
        </a>
    );
};

PeriodButton.propTypes = {
    isActive: PropTypes.func.isRequired,
    buttonLabelId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default injectIntl(PeriodButton);
