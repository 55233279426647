import produce from 'immer';
import _ from 'lodash';
import * as ActionTypes from '../actions/Toast';

const initialState = {
    show: false,
    message: '',
    linkMessage: '',
    onLinkClick: () => {}
};

const confirm = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.SHOW:
            if (_.isEmpty(action.linkMessage)) {
                return produce(state, draft => {
                    draft.show = true;
                    draft.message = action.message;
                });
            }

            return produce(state, draft => {
                draft.show = true;
                draft.message = action.message;
                draft.linkMessage = action.linkMessage;
                draft.onLinkClick = action.onLinkClick;
            });
        case ActionTypes.HIDE:
            return produce(state, draft => {
                draft.show = false;
            });
        case ActionTypes.CLEAR:
            return Object.assign({}, state, initialState);
        default:
            return state;
    }
};

export default confirm;
