import _ from 'lodash';
import $http from 'modules/Http';

class WhiteLabelingRest {
    retrieveSolutionName(lang) {
        const langCd = lang || '002';
        const code = `EFL_COM_SOLUTION_NAME_${langCd}`;
        return $http.get(`/common/v1/configs/cfg/${code}`).then(res => _.get(res, 'data.cfgVal'));
    }

    async isExternalSite() {
        const siteNm = await $http.get(`/auth/v1/configs/cfg/site-name`).then(res => _.get(res, 'data.cfgVal'));
        return !!siteNm && siteNm === 'EXT_AWS';
    }

    async getHomeLogoImgNm() {
        const imgNm = await $http.get(`/auth/v1/configs/cfg/img-name/EFL_AUT_HOME_LOGO_IMG_NM`).then(res => {
            const { data } = res;
            const cfgMap = {};
            _.forEach(data, cfg => {
                cfgMap[cfg.cfgId] = cfg.cfgVal;
            });
            return cfgMap;
        });
        return imgNm;
    }

    async isExistImage(imageUrl) {
        const isExist = await $http.head(imageUrl).then(() => true, () => false);
        return isExist;
    }
}

// WhiteLabelingRest.CODES_BY_LANG = {
//     '001': 'EFL_COM_SOLUTION_NAME_KO',
//     '002': 'EFL_COM_SOLUTION_NAME_EN',
//     '003': 'EFL_COM_SOLUTION_NAME_GE'
// };

export default new WhiteLabelingRest();
