import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import MuuriGrid from 'react-muuri';
import { If } from 'jsx-control-statements';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import FoldersRest from 'src/apis/FoldersRest';
import FavoriteCoachMark from 'components/containers/FavoriteCoachMark';
import { $http } from 'modules/index';
import { popup, toast, alert } from '../../../utils/ModalService';
import FolderSelectPopup from '../Popup/FolderSelect/FolderSelectPopup';
import * as actions from '../../../redux/actions';
import FileCache from '../../../utils/FileCache';
import Favorite from '../../../utils/DriveConfiguration/Favorite';
import DriveFiles from '../DriveFiles/DriveFiles';
import FavoriteCard from './FavoriteCard';
import PrivacyPopup from '../PrivacyPopup';
import ServiceTermsPopup from '../ServiceTermsPopup';
import PrivacyForBrityPopup from '../Popup/PrivacyForBrityPopup';

const MAX_LENGTH = 30;

const FavoriteContainer = props => {
    const { history, intl, showCoachMark, loginUser } = props;
    const [list, setList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cardView, setCardView] = useState(window.favoriteViewOption === undefined ? true : window.favoriteViewOption);
    const gridElement = React.createRef();
    const [page, setPage] = useState(1);
    const [viewMore, setViewMore] = useState(false);
    const [openPrivacyPopup, setOpenPrivacyPopup] = useState(false);
    const [openServiceTermsPopup, setOpenServiceTermsPopup] = useState(false);
    const [showCoachMarkYn, setShowCoachMarkYn] = useState(showCoachMark);
    const [isExt, setIsExt] = useState('');
    const [loadTime, setLoadTime] = useState(Date.now());
    let grid;

    const cardViewTooltip = intl.formatMessage({ id: 'drive.tooltip.favor.cardView' });
    const listViewTooltip = intl.formatMessage({ id: 'drive.tooltip.favor.listView' });

    const updateFixedContainerWidth = () => {
        window.$('.fixed-container').each((index, value) => {
            const fixedWidth = window
                .$(value)
                .parent('.js-for-fixed')
                .width();
            window.$(value).width(fixedWidth);
        });
    };

    const fetchData = (size = 30, offset = 0) => {
        const params = {
            size,
            offset,
            trackTotal: true
        };
        FoldersRest.getPersonalFolders('prefer', params).then(data => {
            setIsLoaded(true);
            if (data.list.length < data.total) setViewMore(true);
            setList(data.list);
            setLoadTime(Date.now());
        });
    };

    const updated = useCallback(() => {
        fetchData();
    }, []);

    useEffect(() => {
        $http.get(`/auth/v1/configs/cfg/site-name`).then(response => {
            if (response.data.cfgVal === 'EXT_AWS') setIsExt('EXT_AWS');
        });
    }, []);

    useEffect(() => {
        if (list) {
            if (grid) {
                grid.grid.destroy();
                grid.grid.refreshItems().layout();
            }
            document.body.classList.remove('scroll-hidden');
        } else {
            document.body.classList.add('scroll-hidden');
        }
        fetchData();
        setInterval(() => {
            updateFixedContainerWidth();
        }, 100);
    }, []); // component did mount

    useEffect(() => {
        if (cardView) {
            grid = new MuuriGrid({
                node: gridElement.current,
                defaultOptions: {
                    dragEnabled: false,
                    showDuration: 0
                }
            });

            grid.grid.refreshItems().layout();
        }
    }, [list, cardView]);

    useEffect(() => {
        if (grid) {
            grid.grid.on('load', function() {
                grid.grid.refreshItems().layout();
            });
        }
    }, [isLoaded]);
    //
    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        window.$('.btn-group').each((index, value) => {
            const newValue = Object.assign(value, {});
            if (window.favoriteViewOption === false) {
                newValue.firstElementChild.classList.value = 'btn btn-ic';
                newValue.firstElementChild.classList.remove('active');
                newValue.lastElementChild.classList.value = 'btn btn-ic active';
                newValue.lastElementChild.classList.add('active');
            } else {
                newValue.firstElementChild.classList.value = 'btn btn-ic active';
                newValue.firstElementChild.classList.add('active');
                newValue.lastElementChild.classList.value = 'btn btn-ic';
                newValue.lastElementChild.classList.remove('active');
            }

            return newValue;
        });

        fetchData();
    }, [cardView]);

    const afterCreate = data => {
        const { result } = data;
        const { resultCode, addPreferred } = result;
        if (resultCode === 403) {
            toast('com.alert.no-auth-folder');
        } else if (resultCode !== 200) {
            toast('com.alert.failed');
        } else {
            toast('drive.toast.favor.added');
            new FileCache().updated(addPreferred).then(() => fetchData());
        }
    };
    const onClickCreateFolder = () => {
        const popupProps = { mode: 'favorite', drive: '111100000000000000', cardView };
        popup(<FolderSelectPopup {...popupProps} />).then(data => afterCreate(data));
    };

    const reloadAfterChanged = (prop, folder, result) => {
        const { type, data } = result;
        if (type === 'remove' && data) {
            const removedList = list.filter(f => f.drive.objtId !== folder[0].drive.objtId);
            setList(removedList);
        } else {
            updated();
        }
    };

    const toggleView = flag => {
        window.favoriteViewOption = flag;
        setCardView(flag);
    };

    const reloadAfterShared = () => {
        updated();
    };

    const onClickPrivacyPopup = () => {
        setOpenPrivacyPopup(true);
    };

    const closePrivacyPopup = () => {
        setOpenPrivacyPopup(false);
    };

    const onClickServiceTermsPopup = () => {
        setOpenServiceTermsPopup(true);
    };

    const closeServiceTermsPopup = () => {
        setOpenServiceTermsPopup(false);
    };

    useEffect(() => {
        setShowCoachMarkYn(showCoachMark);
    }, [showCoachMark]);

    const onClickViewMoreButton = lastIndex => {
        const params = {
            size: MAX_LENGTH,
            offset: lastIndex,
            trackTotal: true
        };
        FoldersRest.getPersonalFolders('prefer', params).then(data => {
            if (data.list.length > 0) {
                const newList = [...list, ...data.list];
                setList(newList);
                setIsLoaded(true);
                setPage(page + 1);
                const newViewMoreFlag = newList.length < data.total;
                setViewMore(newViewMoreFlag);
            }
        });
    };

    const openPurchaseEnquiry = () => {
        const tag = (
            <>
                {`${intl.formatMessage({ id: 'user.text.purchaseEnquiry' })} : drive.sales@samsung.com`}
                <br />
                <br />
                {intl.formatMessage({ id: 'user.alert.purchaseEnquiry.text' })}
            </>
        );
        alert(tag);
    };

    return (
        <>
            <div className="home-favorite">
                <div className="comm-func-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            {showCoachMarkYn === 'Y' && (
                                <div className="func-bar-title">
                                    <FavoriteCoachMark showCoachMarkYn={showCoachMarkYn} />
                                    <a className="btn-ic-nor btn-func-folder" role="button" title={intl.formatMessage({ id: 'drive.button.favorFolder.add' })} onClick={onClickCreateFolder}>
                                        <i className="ic-24-fav-add" />
                                    </a>
                                </div>
                            )}{' '}
                            {showCoachMarkYn === 'N' && (
                                <div className="func-bar-title">
                                    <h3>
                                        <FormattedMessage id="drive.title.favorFolder" />
                                    </h3>

                                    <a className="btn-ic-nor btn-func-folder" role="button" title={intl.formatMessage({ id: 'drive.button.favorFolder.add' })} onClick={onClickCreateFolder}>
                                        <i className="ic-24-fav-add" />
                                    </a>
                                </div>
                            )}
                            <div className="func-bar-filter">
                                <div className="btn-group" data-toggle="buttons">
                                    <label className="btn btn-ic active" title={cardViewTooltip} onClick={() => toggleView(true)}>
                                        <input type="radio" name="view-option" value="fold" autoComplete="off" defaultChecked={true} />
                                        <i className="ic-20-gridView" />
                                    </label>
                                    <label className="btn btn-ic" title={listViewTooltip} onClick={() => toggleView(false)}>
                                        <input type="radio" name="view-option" value="unfold" autoComplete="off" />
                                        <i className="ic-20-listView" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {cardView ? (
                    <FavoriteCard
                        list={list}
                        isLoaded={isLoaded}
                        history={history}
                        reloadAfterChanged={reloadAfterChanged}
                        reloadAfterShared={reloadAfterShared}
                        gridElement={gridElement}
                        viewMore={viewMore}
                        onClickViewMoreButton={onClickViewMoreButton}
                    />
                ) : (
                    <div className="list">
                        <DriveFiles pageClass={Favorite} history={history} refreshWhenMount="true" key={`favorite_list_${loadTime}`} />
                    </div>
                )}

                <If condition={list.length === 0 && cardView}>
                    <div className="box-empty">
                        <div className="message">
                            <span className="ic-empty-folder" />
                            <p>
                                <FormattedMessage id="drive.guideText.favor.empty1" />
                                <br />
                                <FormattedMessage id="drive.guideText.favor.empty2" />
                            </p>
                        </div>
                    </div>
                </If>
            </div>
            <div className="home-footer">
                <div className="footer-copy">
                    <span className="copy">Copyright 2019-2020 SAMSUNG. All rights reserved.</span>{' '}
                    <a className="btn-link" role="button" onClick={onClickPrivacyPopup}>
                        <FormattedMessage id="user.gnb.privacyPolicy" />
                    </a>
                    {isExt === 'EXT_AWS' && (
                        <>
                            <span className="divider" />
                            <a className="btn-link" role="button" onClick={onClickServiceTermsPopup}>
                                <FormattedMessage id="user.text.serviceTermsPolicy" />
                            </a>
                            {loginUser.isTrial && (
                                <>
                                    <span className="divider" />
                                    <a className="btn-link" role="button" onClick={openPurchaseEnquiry}>
                                        <FormattedMessage id="user.text.purchaseEnquiry" />
                                    </a>
                                </>
                            )}
                        </>
                    )}
                </div>

                {openPrivacyPopup && isExt !== 'EXT_AWS' && <PrivacyPopup show={openPrivacyPopup} handleClose={closePrivacyPopup} />}
                {openPrivacyPopup && isExt === 'EXT_AWS' && <PrivacyForBrityPopup show={openPrivacyPopup} handleClose={closePrivacyPopup} />}
                {openServiceTermsPopup && <ServiceTermsPopup show={openServiceTermsPopup} handleClose={closeServiceTermsPopup} />}
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    reload: state.fileReload,
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    handleFileSelected: actions.upload.slectFile
};

FavoriteContainer.propTypes = {
    history: PropTypes.object.isRequired,
    showCoachMark: PropTypes.string,
    loginUser: PropTypes.object.isRequired
};

FavoriteContainer.defaultProps = {
    showCoachMark: 'Y'
};
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FavoriteContainer)
);
