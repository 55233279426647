import produce from 'immer';
import { handleActions } from 'redux-actions';

// @see https://redux-actions.js.org/api/handleaction#handleactions
export default handleActions(
    {
        global: {
            changeLLMList: (state, { payload }) => {
                return produce(state, draft => {
                    draft.llmList = payload.llmList;
                });
            },
            selectLLM: (state, { payload }) => {
                return produce(state, draft => {
                    draft.selectedLLM = payload.llm;
                });
            }
        }
    },
    { llmList: [], selectedLLM: {}, selectedFileCount: 0 },
    { prefix: 'summary' }
);
