import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import WorkgroupCreateButton from './Menu/WorkgroupCreateButton';
import MenuMapper from '../../../utils/FileMenu/MenuMapper';
import FileIcon from './FileIcon';
import FileReloadAction from '../../../redux/actions/FileReload';
import FileReload from '../../../utils/FileReload';
import FoldersRest from '../../../apis/FoldersRest';
import FolderRest from '../../../apis/FolderRest';
import Url from '../../../utils/Url';
import { MY_ROOT_FOLDER_ID } from '../../../constants/DriveConstant';
import store from '../../../redux/store';

const SHARED_FOLDER_ID = '111300000000000000';

class DriveHeaderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { folder: undefined, moreOpen: false, workgroupCreateCfgVal: false };

        this.unsubscribe = null;
        this.preFileReload = null;
    }

    fixedFileIconCustom = {
        statusCallback: isOpen => {
            this.setState({ moreOpen: isOpen });
        }
    };

    setFolderInfo() {
        const {
            setFolderInformation,
            folderId: objtId,
            loginUser: { id },
            type
        } = this.props;

        if (objtId) {
            // 휴지통 - 내파일인 경우 가상의 폴더이기때문에 데이터 안불러옴.
            if (objtId === id) {
                setFolderInformation({ maxAuthCd: 'U80OWN', drive: { objtId, onpstId: objtId } });
            } else {
                FoldersRest.getPair([{ drive: { objtId } }], false).then(searchFiles => {
                    if (searchFiles && searchFiles.length) {
                        this.setState({ folder: searchFiles[0] });
                        setFolderInformation(searchFiles[0]);
                    }
                });
            }
        }

        if (type === 'workgroup') {
            this.setState({ workgroupCreateCfgVal: false });
            this.getWorkgroupCreateCfg();
        }
    }

    componentDidMount() {
        this.setFolderInfo();

        if (!this.unsubscribe) {
            this.subscribe();
        }
    }

    componentDidUpdate(prevProps) {
        const { folderId: prevFolderId } = prevProps;
        const { folderId } = this.props;
        if (prevFolderId !== folderId) {
            this.setFolderInfo();
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    async getWorkgroupCreateCfg() {
        const response = await FolderRest.getCfgWorkgroupCreatableYn();
        const { resultCode, data } = response;
        if (resultCode === 200 && !_.isNil(data) && !_.isEmpty(data) && data.cfgVal === 'N') {
            this.setState({ workgroupCreateCfgVal: false });
        } else {
            this.setState({ workgroupCreateCfgVal: true });
        }
    }

    isDirectSharedFolder() {
        const { folder } = this.state;
        // 상위폴더도 공유받았는지 확인, 직공유 받은 폴더는 행위 제약.
        if (!folder || !folder.drive || !folder.drive.objtId || !folder.extra || !folder.extra.authorizedPath) return false;
        const { authorizedPath: path = {} } = folder.extra;
        const { pathVal = '' } = path;
        const expectedPathVal = `/${SHARED_FOLDER_ID}/${folder.drive.objtId}`;

        return pathVal === expectedPathVal;
    }

    subscribe = () => {
        if (this.unsubscribe) return;

        this.unsubscribe = store.subscribe(() => {
            const {
                setFolderInformation,
                folderId,
                loginUser: { id }
            } = this.props;

            // 휴지통에선 수행 안함
            if (folderId === id) return;

            const storeState = store.getState();
            const { fileReload } = storeState;
            const { data = {}, type: actionType } = fileReload;

            if (actionType === FileReloadAction.SET_FOLDER_INFORMATION) return;

            if (fileReload === this.preFileReload) return;
            this.preFileReload = fileReload;

            const { changedData, reloadType } = data;

            if (reloadType === 'cached' && changedData) {
                const { objtId, objtNm } = changedData;
                if (objtId !== folderId) return;

                const { folder } = this.state;
                const {
                    drive: { objtNm: prevObjtNm }
                } = folder;
                if (prevObjtNm === objtNm) return;

                const newDrive = Object.assign({}, folder.drive, { objtNm });
                const newFolder = Object.assign({}, folder, { drive: newDrive });

                this.setState({ folder: newFolder });
                setFolderInformation(newFolder);
            }
        });
    };

    render() {
        const { files = [], custom = {}, pageClass, removeAll, pathname, type, loginUser, configs: siteConfigs } = this.props;
        const { menu = {} } = pageClass;
        const {
            header = {},
            row: { menuGroupType = 'normal' }
        } = menu;
        const { iconGroupType: groupType, iconGroupTypeMulti: multiGroupType } = header;
        const callback = FileReload.call;
        const iconType = 'header';
        const { folder, moreOpen, workgroupCreateCfgVal } = this.state;
        const { config: userConfig } = loginUser;

        custom.openType = 'left';
        custom.moreGroupType = menuGroupType;
        custom.status = moreOpen;
        custom.isExplorerConstraintsValue = pageClass.isExplorerConstraints(folder);
        custom.userConfig = userConfig;
        custom.loginUser = loginUser;
        custom.getSiteConfig = id => (_.find(siteConfigs, { id }) || {}).value;
        Object.assign(custom, this.fixedFileIconCustom);

        let icons = [];
        if (files.length) {
            const menusClass = new MenuMapper({ groupType: menuGroupType, callback, custom, iconType }, files).getMenusClass().filter(ins => !ins.isDivider());
            if (menusClass.length) {
                icons = new MenuMapper({ groupType: multiGroupType, callback, custom, iconType }, files).getIcons();
            } else {
                // 권한없는 workgroup포함 or 함께 수행할수 있는 기능이 없다는 메세지
                icons = new MenuMapper({ groupType: 'headerMultiNoMenu', callback, custom, iconType }, files).getIcons();
            }
        } else if (groupType) {
            if (folder) {
                // Shared 직공유 여부 확인
                if (pageClass.pageId === 'shared' && !custom.isExplorerConstraintsValue) {
                    custom.isExplorerConstraintsValue = this.isDirectSharedFolder();
                }

                const menusClass = new MenuMapper({ groupType: menuGroupType, callback, custom, iconType }, [folder]).getMenusClass().filter(ins => !ins.isDivider());
                if (menusClass.length) {
                    // TODO 특수한 경우. 쉐어폴리시에서 처리된다면 제거 가능
                    if (pathname.indexOf(Url.own) === 0 && pathname.indexOf(MY_ROOT_FOLDER_ID) >= 0) {
                        icons = new MenuMapper({ groupType: 'ownRoot', callback, custom, iconType }, [folder]).getIcons();
                    } else if (folder.drive.objtId !== MY_ROOT_FOLDER_ID) {
                        icons = new MenuMapper({ groupType, callback, custom, iconType }, [folder]).getIcons();
                    }
                }
            }
        }
        const isHideRoot = pageClass.isHideHeaderMenu() && files.length < 1 && !(type === 'workgroup' && pathname === '/workgroup/111100000000000000') && icons.length < 1;
        const count = <i className="num">{files.length}</i>;
        return (
            <>
                {!isHideRoot && (
                    <div className="func-bar-filter">
                        {files.length > 0 && (
                            <div className="filter-multi">
                                <span className="count">
                                    <FormattedMessage id="drive.text.list.selected" values={{ count }} />
                                </span>
                                <a className="btn-link" role="button" onClick={removeAll}>
                                    <FormattedMessage id="drive.text.list.unselect" />
                                </a>
                            </div>
                        )}
                        {type === 'workgroup' && pathname === '/workgroup/111100000000000000' && workgroupCreateCfgVal && <WorkgroupCreateButton callback={callback} />}
                        {(icons.length > 0 || files.length > 0) && <FileIcon iconType={iconType} files={files} icons={icons} />}
                    </div>
                )}
            </>
        );
    }
}

DriveHeaderMenu.defaultProps = {
    files: undefined,
    folderId: undefined,
    custom: undefined,
    type: 'default'
};

DriveHeaderMenu.propTypes = {
    files: PropTypes.array,
    folderId: PropTypes.string,
    custom: PropTypes.object,
    pathname: PropTypes.string.isRequired,
    pageClass: PropTypes.object.isRequired,
    removeAll: PropTypes.func.isRequired,
    setFolderInformation: PropTypes.func.isRequired,
    loginUser: PropTypes.object.isRequired,
    configs: PropTypes.array.isRequired,
    type: PropTypes.string
};

const mapStateToProps = state => ({
    files: state.fileCheck.checkedFiles,
    loginUser: state.auth.user,
    configs: state.config.configs
});

export default connect(
    mapStateToProps,
    {
        removeAll: () => ({ type: FileReloadAction.EXECUTE, data: { reloadType: 'uncheckedAll' } }),
        setFolderInformation: folder => ({
            type: FileReloadAction.SET_FOLDER_INFORMATION,
            folder
        })
    }
)(DriveHeaderMenu);
