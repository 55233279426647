import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import uuidV4 from 'uuid/v4';
import DriveConfigs from 'constants/DriveConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
import DriveBody from '../containers/DriveBody';
import DriveHeader from '../containers/DriveHeader';
import Deleted from '../../utils/DriveConfiguration/Deleted';
import DriveConfigRest from '../../apis/DriveConfigRest';
import RecycleBinRest from '../../apis/RecycleBinRest';
import { isNotOffWorkSite, SITE_MODE } from '../../constants/SiteMode';

const POLICY_MSG_KEY = {
    MYFILES: 'drive.text.list.deleted.info.onlyMyfiles',
    WORKGROUP: 'drive.text.list.deleted.infoWithAuth'
};

const AUTH_MSG_KEY = {
    edit: 'drive.text.restore.workgroup.auth.edit',
    share: 'drive.text.restore.workgroup.auth.share'
};

class DeletedFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletePeriod: 0,
            restorePolicy: undefined
        };
    }

    componentDidMount() {
        this.loadData();
    }

    getAuthMsg = policy => {
        const { intl } = this.props;

        if (_.isUndefined(policy)) return '';
        if (_.isEmpty(policy)) return intl.formatMessage({ id: POLICY_MSG_KEY.MYFILES });

        const { myfiles = [], workgroup = [] } = policy;

        // 워크그룹 복원 권한 없는 경우
        if (workgroup.length < 1 && myfiles.indexOf('own') !== -1) {
            return intl.formatMessage({ id: POLICY_MSG_KEY.MYFILES });
        }

        const authMsg = workgroup
            .map(authCd => AUTH_MSG_KEY[authCd] || '')
            .filter(msgKey => msgKey.length)
            .map(id => intl.formatMessage({ id }))
            .join('/');

        if (authMsg.length) {
            return intl.formatMessage({ id: POLICY_MSG_KEY.WORKGROUP }, { authCds: authMsg });
        }
        return intl.formatMessage({ id: POLICY_MSG_KEY.MYFILES });
    };

    loadData = async () => {
        const deletePeriod = await DriveConfigRest.getConfig(DriveConfigs.RECYCLEBIN_PERIOD);
        const restorePolicy = await RecycleBinRest.getRestorePolicy();
        this.setState({ deletePeriod, restorePolicy });
    };

    render() {
        const {
            match: {
                params: { folderId }
            },
            siteMode
        } = this.props;

        const pageClass = Deleted;
        const { deletePeriod, restorePolicy } = this.state;
        return (
            <div className="page-drv" key={uuidV4()}>
                <DriveHeader {...this.props} type="deleted" pageClass={pageClass}>
                    {/* offlineWg 요건에 따른 가이드 미노출 */}
                    {isNotOffWorkSite(siteMode) && (
                        <div className="info-msg" style={{ display: 'block' }}>
                            <i className="ic-16-info" />
                            {folderId ? (
                                <FormattedHTMLMessage id="drive.text.list.deleted.info.short" values={{ adminValue: deletePeriod }} tagName="span" />
                            ) : (
                                <span>{this.getAuthMsg(restorePolicy)}</span>
                            )}
                        </div>
                    )}
                </DriveHeader>
                <DriveBody {...this.props} pageClass={pageClass} />
            </div>
        );
    }
}

DeletedFiles.propTypes = {
    match: PropTypes.object.isRequired,
    siteMode: PropTypes.string
};

DeletedFiles.defaultProps = {
    siteMode: SITE_MODE.unknown
};

const mapStateToProps = state => ({
    siteMode: state.site.siteMode
});

export default injectIntl(connect(mapStateToProps)(DeletedFiles));
