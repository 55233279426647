import _ from 'lodash';
import $http from 'modules/Http';

class UserRest {
    cache = {};

    retrieveUserByUserId(userId) {
        if (_.isEmpty(userId)) {
            return Promise.reject();
        }
        return $http.get('/auth/v1/users', { params: { userId } }).then(res => _.get(res.data, '[0]'));
    }

    instant({ query, targetTypes = 'all' }) {
        const params = {
            query
        };
        let url = '/common/v1/search/instant';
        if (_.includes(targetTypes, 'user')) {
            url = '/common/v1/search/instant/user';
        }
        return $http.get(url, { params }).then(res => res.data);
    }

    getUser(params = {}) {
        return $http.get('/auth/v1/users', { params }).then(response => {
            return response;
        });
    }

    sendMobileAppDownloadLink(userId) {
        return $http.get(`/auth/v1/users/mobileApp/downloadLink/send`, { params: { userId } }).then(response => {
            return response;
        });
    }

    updatePartnerExpiredTime(params) {
        return $http.put(`/auth/v1/users/subcontractor/${params.userId}/accessExpiredTime`, null, { params: { accessExpiredTime: params.accessExpirationTime, tenantId: params.tenantId } });
    }

    retrieveUserByUserIdWithCache(userId) {
        if (this.cache[userId]) {
            return Promise.resolve(this.cache[userId]);
        }

        return this.retrieveUserByUserId(userId).then(result => {
            if (result) {
                this.cache[userId] = result;
            }

            return result;
        });
    }

    retrieveUserApprover() {
        return $http.get(`/auth/v1/user/approver`);
    }
}

export default new UserRest();
