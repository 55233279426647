import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class SelectBoxWithFilter extends React.Component {
    componentDidMount() {
        window.$.fn.selectpicker.Constructor.BootstrapVersion = '4';

        this.$el = window.$(this.el);
        this.$el.selectpicker();
    }

    componentDidUpdate(prevProps) {
        const { options, value } = this.props;
        if (prevProps.options !== options || prevProps.value !== value) {
            this.$el.selectpicker('refresh');
        }
    }

    componentWillUnmount() {
        this.$el.selectpicker('destroy');
    }

    handleChange = e => {
        const { onChange } = this.props;
        onChange(e.target.value);
    };

    render() {
        const { options, inputRef, value, dataWidth, dataContainer, isDisabled } = this.props;

        return (
            <>
                <select
                    className="selectpicker"
                    data-width={dataWidth}
                    data-container={dataContainer}
                    ref={el => {
                        this.el = el;
                        inputRef(el);
                    }}
                    value={value}
                    disabled={isDisabled ? 'disabled' : ''}
                    onChange={this.handleChange}>
                    {options.map(item => (
                        <option key={String(item.value)} value={item.value}>
                            {item.message}
                        </option>
                    ))}
                </select>
            </>
        );
    }
}

SelectBoxWithFilter.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    inputRef: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataContainer: PropTypes.string,
    isDisabled: PropTypes.bool
};

SelectBoxWithFilter.defaultProps = {
    options: [],
    onChange: _.noop,
    inputRef: _.noop,
    value: '',
    dataWidth: '',
    dataContainer: '',
    isDisabled: false
};

export default SelectBoxWithFilter;
