import { useEffect } from 'react';
import PropTypes from 'prop-types';

const DocumentHead = props => {
    const { title } = props;
    useEffect(() => {
        if (title) {
            // 제목
            document.title = title;

            // 파비콘
            const exist = document.querySelector("link[rel~='icon']");
            if (!exist) {
                const favicon = `/favicon.ico?v=20210628170530`;

                const icon = document.createElement('link');
                icon.rel = 'icon';
                icon.href = favicon;
                document.getElementsByTagName('head')[0].appendChild(icon);

                const shortcut = document.createElement('link');
                shortcut.rel = 'shortcut icon';
                shortcut.href = favicon;
                document.getElementsByTagName('head')[0].appendChild(shortcut);
            }
        }
    }, [title]);
    return null;
};

DocumentHead.propTypes = {
    title: PropTypes.string.isRequired
};

export default DocumentHead;
