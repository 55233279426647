import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import UserRest from 'apis/UserRest';
import ConfigsRest from 'apis/ConfigsRest';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import moment from 'moment';
import ReqRejectPop from 'presentationals/ReqMng/Popup/ReqRejectPop';
import { alert, confirm, largePopup } from '../../../../utils/ModalService';
import ReqRest from '../../../../apis/ReqRest';
import cookie from '../../../../utils/Cookie';

const ReqPartnerResistrationPop = props => {
    const { from, reqId, reqInfo, close, reload, intl, index } = props;
    const [requester, setRequester] = useState({});
    const [approver, setApprover] = useState({});
    const [users, setUsers] = useState([]);
    const [solutionName, setSolutionName] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        async function initData() {
            const { req, reqTgt, reqTgtUser, reqDtl } = reqInfo;
            const { reqStatCd } = req;
            setState(reqStatCd);

            const requesterUserId = reqTgt[0].objtId;
            const approverUserId = reqTgtUser[0].reqTgtUserId;
            const subcontractUserIds = reqDtl.userIds;
            const userIds = [];
            userIds.push(requesterUserId);
            userIds.push(approverUserId);
            userIds.push(subcontractUserIds);

            const params = { userId: userIds.toString(), userStatCd: 'ALL' };

            const res = await UserRest.getUser(params);
            const { resultCode, data } = res;
            if (resultCode !== 200) {
                alert('com.alert.internalServerError');
                return;
            }

            setRequester(data.find(user => user.userId === requesterUserId));
            setApprover(data.find(user => user.userId === approverUserId));

            const userList = subcontractUserIds.map(userId => data.find(user => user.userId === userId));
            setUsers(userList);

            // const SYSTEM_NAME_CFG_KEY_BY_LANG_CD = {
            //     '001': 'EFL_AUT_INTERFACE_SYSTEM_NAME_KO',
            //     '002': 'EFL_AUT_INTERFACE_SYSTEM_NAME_EN',
            //     '003': 'EFL_AUT_INTERFACE_SYSTEM_NAME_DE',
            //     '004': 'EFL_AUT_INTERFACE_SYSTEM_NAME_CH'
            // };

            const userLangCd = cookie.getCookieText('langCd') || '001';
            const cfgKey = `EFL_AUT_INTERFACE_SYSTEM_NAME_${userLangCd}`;
            ConfigsRest.getConfigs().then(response => {
                const solutionNm = response.resultCode === 200 && response.data ? _.get(_.find(response.data, { cfgId: cfgKey }), 'cfgVal', 'Knox') : 'Knox';
                setSolutionName(solutionNm);
            });
        }
        initData();
    }, [reqInfo]);

    const alertMessage = (resultCode, message, data) => {
        if (resultCode === 500) {
            alert('com.alert.internalServerError');
        } else if (resultCode === 400) {
            alert('com.alert.reqInvalidParam');
        } else if (resultCode === 403 && !!data) {
            alert(data);
        } else {
            alert(message);
        }
    };

    const reqCancelEvent = reqPrcsType => {
        const reqPrcsList = [reqId];

        confirm(<FormattedMessage id="com.confirm.ReqMngBodyReqStat.cancel" />).then(
            async () => {
                const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

                alertMessage(res.resultCode, res.message, res.data);

                close(true);
                reload();
            },
            () => {}
        );
    };

    const reqRejectEvent = () => {
        largePopup(<ReqRejectPop index={index} rejectType="SINGLE" />);
        reload();
        close();
    };

    const reqApproveEvent = async reqPrcsType => {
        const reqPrcsList = [reqId];

        const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

        alertMessage(res.resultCode, res.message, res.data);

        close(true);
        reload();
    };

    const getUserInfo = (user, key) => {
        return user && user[key] ? user[key] : '';
    };

    const requesterCopNm = getUserInfo(requester, 'userLangCdCopNm');
    const requesterDeptNm = getUserInfo(requester, 'deptLangCdNm');
    const approverCopNm = getUserInfo(approver, 'userLangCdCopNm');
    const approverDeptNm = getUserInfo(approver, 'deptLangCdNm');

    return (
        <div className="modal-content" style={_.isEmpty(requester) ? { display: 'none' } : {}}>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.popup.reqPartnerAccount.regAccount" />
                </h5>
                <a className="btn-ic-nor" role="button" title={<FormattedMessage id="com.close" />} data-dismiss="modal" onClick={() => close()}>
                    <i className="ic-20-close" />
                </a>
            </div>

            <div className="modal-body">
                <div className="dt-width-120">
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.ReqMngBody.reqUserNm" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title={`${requester.userLangCdNm} ${requesterCopNm} / ${requesterDeptNm} `}>
                                            <span className="name">{requester.userLangCdNm}</span>
                                            <span className="position">{`${requesterCopNm} / ${requesterDeptNm}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.reqRequest.approver" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title={`${approver.userLangCdNm} ${approverCopNm} / ${approverDeptNm} `}>
                                            <span className="name">{approver.userLangCdNm}</span>
                                            <span className="position">{`${approverCopNm} / ${approverDeptNm}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>

                <dl className="dt-divider">
                    <dt>
                        <FormattedMessage id="user.text.popup.reqPartnerAccount.signUp" />
                    </dt>
                    <dd>
                        <div className="total-count">
                            <span>
                                <FormattedMessage id="com.text.total" />
                            </span>
                            <span className="count">
                                <i className="num">{users.length}</i>
                            </span>
                        </div>
                    </dd>
                </dl>

                <div className="data-details">
                    <div className="scroll-bar">
                        <table className="data-table">
                            <caption>
                                <FormattedMessage id="com.text.reqRequest.target-list" />
                            </caption>
                            <colgroup>
                                <col style={{ width: '128px' }} />
                                <col style={{ width: '112px' }} />
                                <col style={{ width: '70px' }} />
                                <col style={{ width: '50px' }} />
                                <col style={{ width: '90px' }} />
                                <col style={{ width: '90px' }} />
                                <col style={{ width: '90px' }} />
                                <col style={{ width: '80px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="ellipsis" title={intl.formatMessage({ id: 'user.text.reqPartnerAccount.account' }, { linkedSystem: solutionName })}>
                                            {intl.formatMessage({ id: 'user.text.reqPartnerAccount.account' }, { linkedSystem: solutionName })}
                                        </div>
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.id(email)" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.position" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="user.text.reqPartnerAccount.name.english" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="user.text.reqPartnerAccount.position.english" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="com.belong-company-name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="user.text.popup.reqPartnerAccount.team" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="user.text.popup.partnerDetail.expirationDate" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(e => {
                                    const userIfMsg =
                                        e.userIfYn === 'Y'
                                            ? intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.exist' })
                                            : intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.none' });
                                    const expirationTime = e.accessExpirationTime ? moment(e.accessExpirationTime).format('YYYY-MM-DD') : '';

                                    return (
                                        <tr key={e.userId}>
                                            <td>
                                                <div className="ellipsis" title={userIfMsg}>
                                                    {userIfMsg}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.userLoginId}>
                                                    {e.userLoginId}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.userNm}>
                                                    {e.userNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.userCopNm}>
                                                    {e.userCopNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.userEngNm}>
                                                    {e.userEngNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.userCopEngNm}>
                                                    {e.userCopEngNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.orgnOnpstCoNm}>
                                                    {e.orgnOnpstCoNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={e.deptNm}>
                                                    {e.deptNm}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ellipsis" title={expirationTime}>
                                                    {expirationTime}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <If condition={from !== 'RECEIVED'}>
                        <If condition={state === 'PROCESSING'}>
                            <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => reqCancelEvent('CANCEL')}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                                </span>
                            </a>
                        </If>
                        <a className="btn btn-lg btn-primary" role="button" onClick={() => close(true)}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </If>
                    <If condition={from === 'RECEIVED'}>
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => reqRejectEvent()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.reject" />
                            </span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => reqApproveEvent('APPROVAL')}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.approval" />
                            </span>
                        </a>
                    </If>
                </div>
            </div>
        </div>
    );
};

ReqPartnerResistrationPop.propTypes = {
    from: PropTypes.string,
    reqId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    reqInfo: PropTypes.object,
    index: PropTypes.number.isRequired
};

ReqPartnerResistrationPop.defaultProps = {
    from: '',
    reqInfo: {}
};

export default injectIntl(ReqPartnerResistrationPop);
