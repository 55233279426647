import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { globalPopup } from '../../redux/actions';
import FAQ from '../presentationals/FAQ';
import HelpAside from '../containers/HelpAside';
import Notice from '../presentationals/Notice';
import HelpTabs from '../presentationals/Tabs/HelpTabs';
import ReleaseNote from '../presentationals/ReleaseNote';

class Help extends Component {
    componentDidMount() {
        document.body.classList.remove('scroll-hidden');
        this.updateFixedContainerWidthInterval = setInterval(() => {
            this.updateFixedContainerWidth();
        }, 100);

        const { match, openHelpQuestionPopup } = this.props;
        const options = _.get(match, 'params.options', '');
        if (options === 'question') openHelpQuestionPopup();
    }

    componentWillUnmount() {
        clearInterval(this.updateFixedContainerWidthInterval);
        this.updateFixedContainerWidthInterval = null;
        document.body.classList.add('scroll-hidden');
    }

    updateFixedContainerWidth = () => {
        window.$('.fixed-container').each((index, value) => {
            const fixedWidth = window
                .$(value)
                .parent('.js-for-fixed')
                .width();
            window.$(value).width(fixedWidth);
        });
    };

    render() {
        const { intl } = this.props;
        const menus = [intl.formatMessage({ id: 'user.helpcenter.faq' }), intl.formatMessage({ id: 'user.helpcenter.announcement' }), intl.formatMessage({ id: 'user.text.release.note' })];
        return (
            <div className="page-help">
                <div className="comm-func-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            <div className="func-bar-title">
                                <h3>{intl.formatMessage({ id: 'user.helpcenter.helpcenter' })}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="help-view">
                    <HelpAside />
                    <HelpTabs menus={menus}>
                        <FAQ />
                        <Notice />
                        <ReleaseNote />
                    </HelpTabs>
                    <span className="shadow" />
                </div>
            </div>
        );
    }
}

Help.propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    openHelpQuestionPopup: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    openHelpQuestionPopup: globalPopup.openHelpQuestionPopup
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(Help)
);
