import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { If } from 'jsx-control-statements';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CustomModal from 'containers/CustomModal';
import { globalPopup } from '../../../../redux/actions';
import SecureModeDatePicker from './SecureModeDatePicker';

const SecureModeRequestContainer = props => {
    const { isOpen, closePopup, create, approver, startDt, endDt, dateChange, insertReason, reason, reasonLength, intl, saveDisable, clearData } = props;
    // const [saveDisable, setSaveDisable] = useState(true);
    const guideText = intl.formatMessage({ id: 'drive.guideText.securefolder.reason-guidetext' });
    const closeButtonTooltip = intl.formatMessage({ id: 'com.close' });

    const onClickRequestButton = () => {
        create();
        clearData();
    };

    const onChangeRequestReason = e => {
        const { value } = e.target;
        insertReason(value);
    };

    const makeApproverTooltip = () => {
        if (approver) {
            const { userLangCdNm, userLangCdCopNm, deptLangCdNm, uprDeptLangCdNm } = approver;
            const userInfo = userLangCdNm.concat(userLangCdCopNm, '/');
            const deptInfo = deptLangCdNm.concat('(', uprDeptLangCdNm, ')');
            return userInfo.concat(deptInfo);
        }

        return '';
    };

    const onClosePopup = () => {
        clearData();
        closePopup();
    };

    return (
        <>
            <CustomModal isOpen={isOpen} fade={false} backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.text.securefolder.title" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={closeButtonTooltip} data-dismiss="modal" onClick={closePopup}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <div className="dt-width-120">
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.securefolder.period" />
                            </dt>
                            <dd>
                                <SecureModeDatePicker
                                    startDate={startDt}
                                    endDate={endDt}
                                    onDatesChange={({ startDate, endDate }) => {
                                        dateChange({ startDate, endDate });
                                    }}
                                    minDate={moment()}
                                    maxDate={moment().add(10, 'years')}
                                />

                                <div className="input-remark">
                                    <div className="err-msg" style={{ display: 'none' }}>
                                        <FormattedMessage id="drive.guideText.secureFolder.period-guide" />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.securefolder.approver" />
                            </dt>
                            <dd>
                                <div className="dd-text">
                                    <div className="item-user">
                                        <div className="user-profile">
                                            <div title={() => makeApproverTooltip()}>
                                                <span className="name has-name-card">{approver.userLangCdNm}</span>
                                                <span className="position">
                                                    {approver.userLangCdCopNm} / {approver.deptLangCdNm}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>

                        <dl className="row-list">
                            <dt>
                                <FormattedMessage id="drive.text.secureFolder.reason" />
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <textarea className="form-control textarea-lg" placeholder={guideText} onChange={e => onChangeRequestReason(e)} maxLength={512} minLength={5} value={reason} />
                                    <div className="input-remark">
                                        <If condition={reasonLength < 5}>
                                            <div className="err-msg">
                                                <FormattedMessage id="drive.guideText.securefolder.morethan5words" />
                                            </div>
                                        </If>
                                        <If condition={reasonLength >= 5}>
                                            <div />
                                        </If>
                                        <div className="write-count">
                                            <strong className="count-num">{reasonLength}</strong> /<span className="write-total">512</span>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClosePopup}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${saveDisable ? 'disabled' : ''}`} role="button" onClick={onClickRequestButton} disabled={saveDisable}>
                            <span className="btn-text">
                                <FormattedMessage id="drive.button.securefolder.request" />
                            </span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

SecureModeRequestContainer.defaultProps = {
    startDt: null,
    endDt: null,
    reasonLength: ''
};

SecureModeRequestContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    approver: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    create: PropTypes.func.isRequired,
    insertReason: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
    dateChange: PropTypes.func.isRequired,
    startDt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endDt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    reasonLength: PropTypes.string,
    reason: PropTypes.string.isRequired,
    saveDisable: PropTypes.bool.isRequired,
    clearData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.secureModeRequest.isOpen,
    user: state.auth.user
});

const mapDispatchToProps = {
    closePopup: globalPopup.closeSecureModeRequestPopup
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SecureModeRequestContainer)
);
