import { Fragment } from 'react';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ko from 'react-intl/locale-data/ko';
import de from 'react-intl/locale-data/de';
import zh from 'react-intl/locale-data/zh';
import _ from 'lodash';
import i18nMessages from 'src/i18n'; // TODO : 추후 동적 로딩 방법을 고려해보자...

// moment locale 전역 주입!
import 'moment/locale/en-gb';
import 'moment/locale/zh-cn';
import 'moment/locale/de';
import 'moment/locale/ko';
import cookie from '../utils/Cookie';

addLocaleData([...en, ...ko, ...de, ...zh]);

const LANGUAGES = {
    '001': 'ko',
    '002': 'en',
    '003': 'de',
    '004': 'zh'
};

function mapStateToProps(state) {
    const key = _.get(state, 'auth.user.locale.languageCode', cookie.getCookieText('langCd') || '001');
    const locale = LANGUAGES[key];
    return {
        locale,
        messages: i18nMessages[locale],
        textComponent: Fragment,
        key: locale
    };
}
export default connect(mapStateToProps)(IntlProvider);
