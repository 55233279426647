import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SecureModeReqeustRest from 'apis/SecureModeRequestRest';
import { globalPopup, toast } from '../../../../redux/actions';

import SecureModeRequestContainer from './SecureModeRequestContainer';

const TYPE = 'SECUREMODE';
const SEARCH_TGT = 'KNOX_DRIVE';

const SecureModeRequestPopup = props => {
    const { isOpen, user, showToast, closePopup, intl } = props;
    const { dept } = user;
    const [approver, setApprover] = useState('');
    const [startDt, setStartDt] = useState('');
    const [endDt, setEndDt] = useState('');
    const [reason, setReason] = useState('');
    const [reasonLength, setReasonLength] = useState(0);

    useEffect(() => {
        SecureModeReqeustRest.retreiveApprover(TYPE, SEARCH_TGT).then((data = []) => {
            if (data && data[0]) {
                setApprover(data[0]);
            }
        });
    }, []);

    const clearData = () => {
        setStartDt('');
        setEndDt('');
        setReason('');
        setReasonLength(0);
    };

    const create = () => {
        const dateInvalid = intl.formatMessage({ id: 'com.alert.validation.dateCheck' });
        const reasonInvalid = intl.formatMessage({ id: 'com.alert.validation.reason' });
        if (startDt === '' || endDt === '') {
            showToast(dateInvalid);
            return;
        }

        if (reason === '' || reasonLength < 5) {
            showToast(reasonInvalid);
            return;
        }
        const params = {
            approverId: approver.userId,
            searchTgt: SEARCH_TGT,
            startDt: startDt.format('YYYY-MM-DD'),
            endDt: endDt.format('YYYY-MM-DD'),
            reason: reason.trim()
        };

        const saved = intl.formatMessage({ id: 'com.alert.save' });
        const failed = intl.formatMessage({ id: 'com.alert.failed' });

        SecureModeReqeustRest.create(TYPE, params).then(res => {
            const { resultCode } = res;
            if (resultCode === 200) {
                showToast(saved);
            } else {
                showToast(failed);
            }
            clearData();
            closePopup();
        });
    };

    const dateChange = ({ startDate, endDate }) => {
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        setStartDt(moment(formattedStartDate));
        setEndDt(moment(formattedEndDate));
    };

    const insertReason = (value = '') => {
        const rLength = value.trim().length;
        setReason(value);
        setReasonLength(rLength);
    };

    const isSaveDisable = () => {
        if (startDt === '' || !moment(startDt).isValid()) return true;
        if (endDt === '' || !moment(endDt).isValid()) return true;
        if (reason.trim().length === 0 || reasonLength < 5) return true;
        return false;
    };

    return (
        <SecureModeRequestContainer
            isOpen={isOpen}
            startDt={startDt}
            endDt={endDt}
            approver={approver}
            dept={dept}
            create={create}
            dateChange={dateChange}
            insertReason={insertReason}
            reason={reason}
            reasonLength={reasonLength}
            saveDisable={isSaveDisable()}
            clearData={clearData}
        />
    );
};

SecureModeRequestPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    closePopup: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.secureModeRequest.isOpen,
    user: state.auth.user
});

const mapDispatchToProps = {
    closePopup: globalPopup.closeSecureModeRequestPopup,
    showToast: toast.showToast
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SecureModeRequestPopup)
);
