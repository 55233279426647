import React, { Component } from 'react';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import store from 'src/redux/store';
import { slectFile } from 'src/redux/actions/Upload';
import { alert } from 'utils/ModalService';
import DriveFilesHeader from '../../components/containers/DriveFiles/DriveFilesHeader';
import DriveFilesBody from '../../components/containers/DriveFiles/DriveFilesBody';
import FoldersRest from '../../apis/FoldersRest';
import FileIconStyle from '../FileIconStyle';
import Viewer from '../../components/presentationals/FileMenu/Menu/Viewer';
import FileReload from '../FileReload';
import { getUrlPrefix } from '../Url';
import FileCache from '../FileCache';
import * as Constants from '../../constants/DriveConstant';
import FileDropZone from '../../components/presentationals/DragDrop/FileDropZone';
import { LOW_HEIGHT } from '../../components/containers/DriveFiles/HeaderRow/SettingHeader';

class Base extends Component {
    // 루트 페이지값
    ROOT_FOLDER_ID = Constants.MY_ROOT_FOLDER_ID;

    // 리스트 offset_top고정 값, 최근사용파일, 통합검색 제외하고 동일.
    OFFSET_TOP = 211;

    // 페이지아아디, url경로와 코드값 조회시 쓰임
    pageId = 'base';

    // 메뉴 처리 값
    menu = {
        // 우측 상단 처리
        header: {
            iconGroupType: 'header', // 진입 시 icon 영역
            iconGroupTypeMulti: 'headerMulti' // 멀티선택 시 icon 영역
        },
        // 개별 row 처리
        row: {
            iconGroupType: 'normal', // icon 영역
            menuGroupType: 'normal', // 우클릭 영역 및 icon 더보기 영역
            floatingIconGroupType: 'floatingFavorite' // 파일명 옆 floating icon 영역
        }
    };

    // list의 높이 세팅과 관계없이 절대적인 row 높이를 지정하는 경우 쓰임. 높이 세팅을 따를 경우 null 혹은 선언하지 않아야 함
    absoluteHeight;

    // column값을 한번만 불러와서 가지고 있는 용도로 쓰임
    columns = {};

    // 조회시 캐쉬여부. ES조회페이지(통검,공유받은파일,공유한파일)에만 적용됨
    cacheWhenLoad = false;

    // DB 조회 API에서 searchAfter값이 안넘어오는경우 list size를가지고 판단할지 여부. 휴지통에만 적용됨
    enforceSearchAfter = () => false;

    dndAble = false;

    moreScroll = true;

    // 조회결과 총 개수 포함여부
    trackTotal = false;

    /**
     * 정렬가능컬럼을 특정할 필요가 있는 경우. null이면 기본값에 따르게됨
     */

    sortPossibleColumns() {
        return null;
    }

    /**
     * 탐색기 제약이 적용되는 케이스의 경우, true. 제약이 필요한 기능에서만 사용함
     */
    isExplorerConstraints() {
        return false;
    }

    /**
     * 리스트 테이블 헤더에 checkbox를 보여줄지 여부. 기본은 true이고 deleted-root에서는 false임
     */
    showHeaderCheckBox() {
        return true;
    }

    /**
     * 선택가능한 파일 목록 반환
     */
    getCheckableFiles(list) {
        return list;
    }

    /**
     * 리스트 추가 선택 가능 여부
     */
    isCheckableOverMax(list, maxCount) {
        return this.getCheckableFiles(list).length > maxCount;
    }

    /**
     * 리스트 테이블 헤더 checkbox로 선택되는 파일 목록 반환. 기본은 전체, Compliance, Expiration은 요청상태로 처리
     */
    isCheckableFile() {
        return true;
    }

    /**
     * folderId로 Root 페이지 인지 확인
     */
    isRoot(folderId) {
        return !folderId || folderId === this.ROOT_FOLDER_ID;
    }

    /**
     * 목록 조회 함수
     */
    searchFunction(params, folderId) {
        return this.isRoot(folderId) ? FoldersRest.getFolders(this.pageId, params) : FoldersRest.getFolder(folderId, params);
    }

    /**
     * 컬럼 목록 조회 함수
     */
    async getColumns() {
        const id = this.pageId;
        if (!this.columns[id]) {
            this.columns[id] = await FoldersRest.getColumns(id);
        }
        return this.columns[id];
    }

    getDefaultSortColumn() {
        return { sortField: 'objtNm', sortOrder: 'ASC' };
    }

    /**
     * 파일 이름 영역 클릭가능 여부
     */
    possibleNameClick() {
        return true;
    }

    /**
     * 파일 타입 아이콘 처리
     */
    getExtensionIcon(file, context) {
        const { drive, sharedCount = 0, users: { fileEditingUser } = {} } = file;

        const {
            state: { viewHeight }
        } = context;
        const fileIcon = LOW_HEIGHT === viewHeight ? 'ic-file-sm' : 'ic-file-md';

        // 편집 중 아이콘 처리
        if (drive.objtSectCd === 'FILE' && fileEditingUser) {
            return `${fileIcon} ic-lock`;
        }

        if (drive.objtSectCd === 'FOLDER') {
            if (drive.onpstSectCd === 'SYSTFOLDER') {
                return `${fileIcon} ic-file-FOLDER-collabo`;
            }
            return sharedCount ? `${fileIcon} ic-file-FOLDER-share` : `${fileIcon} ic-file-FOLDER`;
        }
        return FileIconStyle.getStyle(fileIcon, drive.objtNm);
    }

    /**
     * 파일 이름 클릭시 페이지 전환 함수
     */
    goToPage(file, context, e) {
        const {
            props: { history }
        } = context;
        const { drive } = file;
        const { location = {} } = history;
        const url = getUrlPrefix(file, location);

        e.stopPropagation();

        if (drive.objtSectCd === 'FOLDER') {
            history.push(`${url}/${drive.objtId}`, { onpstId: drive.onpstId, maxAuthCd: file.maxAuthCd });
        } else if (drive.objtSectCd !== 'FOLDER' && context.props.pageClass.pageId === 'compliance') {
            // 프라이버시 검출건은 키워드 노출 안됨
            const { config: { version: versionConfig = {} } = {} } = file;
            const { complianceDetectedKeywords = '' } = versionConfig;
            const complianceYn = complianceDetectedKeywords !== '' ? 'Y' : 'N';
            new Viewer({ callback: FileReload.call }, [file], complianceYn).execute();
        } else {
            new Viewer({ callback: FileReload.call }, [file], 'N').execute();
        }
    }

    /**
     * 캐쉬 처리 로직
     */
    buildCache() {
        return new FileCache(file => {
            if (file && file.maxAuthCd !== 'NONE') return file;
            return null;
        });
    }

    /**
     * 업로드 드랍존 처리
     */
    uploadBuilder(folder) {
        return this.dndAble && folder && folder.actionPolicy && folder.actionPolicy.upload && <FileDropZone folder={folder} pageId={this.pageId} uploader={this.uploader} />;
    }

    /**
     * 업로드 로직
     */

    uploader = (folder, event) => {
        event.preventDefault();
        event.stopPropagation();
        const { drive } = folder;

        const transferitems = event.dataTransfer.items;
        const transferfiles = event.dataTransfer.files;
        // const { effectAllowed } = event.dataTransfer; 2.5.0-102 list to tree dnd 시 dnd upload랑 구분하기 위함. all/ copylink/ uninitialized
        // chrome 메시지 규칙 : chrome 은 폴더/파일 둘다 transferitems, transferfiles 값이 똑같이 들어오므로 폴더/파일 구분없이 alert 메시지 사용
        // IE 메시지 규칙 : IE 는 폴더일 경우 transferfiles 값이 0, 파일일 경우 transferfiles 값이 0 이상이므로 폴더/파일 구분하여 alert 메시지 사용

        // chrome에서는 폴더/파일 둘다 transferitems로 들어오기 때문에 파일만 필터링 (IE 에서는 transferitems는 항상 undefined 이므로 이 로직은 타지 않는다)

        if (!!transferitems && !!transferitems.length) {
            let files = [];
            for (let i = 0; i < transferitems.length; i += 1) {
                const entry = transferitems[i].webkitGetAsEntry();
                if (!!entry && entry.isFile) {
                    files = [...files, transferitems[i].getAsFile()];
                }
            }
            if (files.length > 0) {
                store.dispatch(slectFile(files, drive));
            }
            if (files.length !== transferfiles.length) {
                alert('drive.alert.upload.drop.fail');
            }
        } else if (!transferfiles || transferfiles.length < 1) {
            const agent = navigator.userAgent;
            if ((navigator.appName === 'Netscape' && agent.search('Trident') !== -1) || agent.toLowerCase().indexOf('msie') !== -1) {
                alert('drive.alert.upload.filter.folder');
            } else {
                alert('drive.alert.upload.drop.fail');
            }
        } else {
            const selectedFiles = [...transferfiles];
            store.dispatch(slectFile(selectedFiles, drive));
        }
    };

    /**
     * Row 처리
     */
    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    /**
     * 빈 페이지 처리
     */
    emptyBuilder() {}

    /**
     * 페이지 전체 영역 처리
     */
    pageBuilder({ state: { firstApproach, list, viewHeight } }) {
        const clsName = `comm-grid-list react-list ${LOW_HEIGHT === viewHeight ? 'list-sm' : ''}`;
        const style = { display: firstApproach ? '' : 'none' };
        const isHidden = list && !list.length;

        return (
            <section className="drv-file-panel">
                <div className={clsName} style={style}>
                    {!isHidden && <DriveFilesHeader />}
                    <DriveFilesBody />
                </div>
            </section>
        );
    }

    /**
     * 제목 부분 직접 처리하는 경우 선언.
     */
    getTitleArea() {
        return null;
    }

    /**
     * 파일 헤더메뉴 숨기기 여부
     */
    isHideHeaderMenu() {
        return false;
    }
}

export default Base;
