import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CharacterImage from 'containers/CharacterImage/CharacterImage';

const TeamItem = props => {
    const { name, id, liTagClassName, onClick } = props;
    return (
        <li key={id} id={id} className={liTagClassName} onClick={onClick} role="presentation">
            <div className="item-user">
                <CharacterImage size="lg" imageClassName="user-thumbnail-md org" />
                <div className="user-profile">
                    <div title={name}>
                        <span className="name has-name-card">{name}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};

TeamItem.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    liTagClassName: PropTypes.string,
    onClick: PropTypes.func
};

TeamItem.defaultProps = {
    name: '',
    id: new Date(),
    liTagClassName: undefined,
    onClick: _.noop
};

export default TeamItem;
