import { $http } from 'modules/index';

class RecycleBinRest {
    static deleteTemporaryObjt(objtId, onpstId) {
        const objtIds = [];
        objtIds.push(objtId);

        return RecycleBinRest.deleteTemporaryObjtList(objtIds, onpstId);
    }

    static deleteTemporaryObjtList(param) {
        return RecycleBinRest.updateObjtDelCd(param, 'temporary');
    }

    static restoreObjtList(param) {
        return RecycleBinRest.updateObjtDelCd(param, 'normal');
    }

    static deletePermanentObjtList(param) {
        return RecycleBinRest.updateObjtDelCd(param, 'permanent');
    }

    static updateObjtDelCd(param, delStat) {
        return $http.patch(`/drive/v1/objtList/delete-stat/${delStat}`, param).then(response => {
            if (response.resultCode === 200) {
                return response.data;
            }
            return Promise.reject(response);
        });
    }

    static getRestorePolicy() {
        return $http.get(`/drive/v1/recyclebin/restore/policy`).then(response => {
            if (response.resultCode === 200) {
                return response.data;
            }
            return {};
        });
    }

    static restoreObjtListToOriginFolder(param) {
        return $http.post(`/drive/v1/recyclebin/restore/objts-to-origin`, param).then(({ resultCode, data }) => (resultCode === 200 ? data : {}));
    }
}

export default RecycleBinRest;
