import $http from 'modules/Http';

const prefix = '/common/v1/req';

class ReqRest {
    static getReqList(params) {
        return $http.get(`${prefix}/list`, { params });
    }

    static getReqs(params) {
        return $http.get(`${prefix}`, { params });
    }

    static updateReqProcess(params) {
        return $http.post(`${prefix}/process`, params);
    }

    static getReq(reqId) {
        return $http.get(`${prefix}/${reqId}`);
    }

    static getReqCheck() {
        return $http.get(`${prefix}/check`);
    }

    static getReqInfoByTarget(params) {
        return $http.post(`${prefix}/status/target`, params);
    }
}

export default ReqRest;
