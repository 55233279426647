import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PromptDropdown from './PromptDropdown';
import FileListBox from './FileListBox';
import PromptTemplate from './PromptTemplate';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { SUMMARY_THREAD_STATUS } from '../../Common/SummaryConstant';
import SummarySendButton from './SummarySendButton';

const PromptInput = memo(() => {
    const threadStatus = useSelector(({ summary }) => summary.thread.threadStatus);
    const promptTemplateType = useSelector(({ summary }) => summary.promptInput.promptTemplate.promptTemplateType);

    const dispatch = useDispatch();

    const isPromptTemplateSelected = useMemo(() => {
        return !_.isNil(promptTemplateType);
    }, [promptTemplateType]);

    const handleClickCancelButton = () => {
        dispatch(summaryActions.promptInput.clear());
    };

    return (
        <div className="aside-panel-footer">
            <div className={classNames('copilot-prompt', isPromptTemplateSelected && 'is-focused', threadStatus === SUMMARY_THREAD_STATUS.running && 'is-disabled')}>
                <div className="copilot-prompt-submit">
                    <PromptDropdown />
                    <div className="prompt-placeholder">
                        <FormattedMessage id="drive.placeHolder.copilot.summary.promptInput" />
                    </div>
                    <div className="prompt-submit">
                        <a className="btn btn-xs btn-secondary" role="button" onClick={handleClickCancelButton}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <SummarySendButton />
                    </div>
                </div>
                <If condition={isPromptTemplateSelected}>
                    <PromptTemplate />
                </If>
                <FileListBox />
            </div>
        </div>
    );
});

export default PromptInput;
