import React, { memo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { summaryActions } from '../../../../../redux/actions/Summary';

const SummaryTooltipIcon = memo(({ direction, content }) => {
    const tooltipIconRef = useRef();

    const dispatch = useDispatch();

    const handleMouseEnterIcon = () => {
        dispatch(summaryActions.tooltip.open({ tooltipIconRef, direction, content }));
    };

    const handleMouseLeaveIcon = () => {
        dispatch(summaryActions.tooltip.close());
    };

    return (
        <span className="btn-ic-nor btn-sm" ref={tooltipIconRef} onMouseEnter={handleMouseEnterIcon} onMouseLeave={handleMouseLeaveIcon}>
            <i className="ic-20-copilot-info" />
        </span>
    );
});

SummaryTooltipIcon.propTypes = {
    direction: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
};

SummaryTooltipIcon.defaultProps = {};

export default SummaryTooltipIcon;
