import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { getFilterMessageIdByFormType } from '../../../Common/Util/SummaryMessageUtil';

const FormOptionItem = memo(({ type, selectedFormType, onSelect }) => {
    const handleChange = () => {
        onSelect(type);
    };

    return (
        <div className="form-check">
            <label className="form-check-label">
                <input className="form-check-input" type="radio" name="summary-option-form-type" value={type} checked={type === selectedFormType} onChange={handleChange} />
                <span className="label-text">
                    <FormattedMessage id={getFilterMessageIdByFormType(type)} />
                </span>
            </label>
        </div>
    );
});

FormOptionItem.propTypes = {
    type: PropTypes.string.isRequired,
    selectedFormType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};

FormOptionItem.defaultProps = {};

export default FormOptionItem;
