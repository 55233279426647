import React from 'react';
import PropTypes from 'prop-types';
import UserCard from 'components/containers/NameCard/UserCard';
import DateConverter from '../../../../utils/DateConverter';

const ChangedDate = props => {
    const {
        file: { drive, users = {} },
        cellStyle
    } = props;
    const { objtStatChgUser = {} } = users;
    const changedDate = drive.objtStatChgDt ? DateConverter.convert(drive.objtStatChgDt) : '-';
    return (
        <>
            <div className="grid-cell files-date-cell" style={cellStyle}>
                <div className="g-inline-flex">
                    <div className="previous" title={changedDate}>
                        {changedDate}
                    </div>
                    {objtStatChgUser.userNm ? (
                        <div className="ellipsis comma">
                            <span
                                className="has-name-card"
                                title={objtStatChgUser.userNm}
                                id={`files-date-cell-${drive.objtId}`}
                                onClick={e => e.stopPropagation()}
                                role="button"
                                style={{ cursor: 'pointer' }}>
                                {objtStatChgUser.userNm}
                            </span>
                            <UserCard target={`files-date-cell-${drive.objtId}`} id={drive.objtStatChgUserId} />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

ChangedDate.defaultProps = {
    cellStyle: null
};

ChangedDate.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.object
};

ChangedDate.displayName = 'ChangedDate';
export default React.memo(ChangedDate);
