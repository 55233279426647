import React from 'react';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { IMAGE_STATUS } from './CharacterImageConstants';

const CharacterImage = ({ src, status, imageClassName, text, onClick, intl }) => {
    return (
        <span className={imageClassName} role="button" onClick={onClick}>
            <If condition={status === IMAGE_STATUS.COMPLETE && !_.isEmpty(src)}>
                <img src={src} className="profile-img" alt={intl.formatMessage({ id: 'user.title.profile.image.alt' })} />
            </If>
            <If condition={status === IMAGE_STATUS.NO_IMAGE}>
                <i>{_.get(text, '[0]')}</i>
            </If>
        </span>
    );
};

CharacterImage.propTypes = {
    src: PropTypes.string,
    status: PropTypes.string,
    imageClassName: PropTypes.string.isRequired,
    text: PropTypes.string,
    onClick: PropTypes.func
};

CharacterImage.defaultProps = {
    src: undefined,
    status: IMAGE_STATUS.INIT,
    onClick: _.noop,
    text: ''
};

export default injectIntl(CharacterImage);
