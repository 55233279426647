import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UploadManager from './UploadManager';
import UploadInput from './UploadInput';

class Upload extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.refUploadManager = React.createRef();
    }

    shouldComponentUpdate(nextProps) {
        this.refUploadManager.current.addToQueue(nextProps.addFiles);
        return false;
    }

    render() {
        return (
            <>
                <UploadManager ref={this.refUploadManager} />
                <UploadInput />
            </>
        );
    }
}

const mapStateToProps = state => {
    return { addFiles: state.upload.addFiles };
};

Upload.propTypes = {
    addFiles: PropTypes.object
};

Upload.defaultProps = {
    addFiles: {}
};

export default connect(
    mapStateToProps,
    null
)(Upload);
