import React, { Component } from 'react';
//  import { DateRangePicker } from 'react-dates';
// import underscore from 'lodash';
import PropTypes from 'prop-types';
import DateRangePickerWrapper from 'components/presentationals/DateRangePickerWrapper';

class SecureModeDatePicker extends Component {
    render() {
        const { startDate, endDate, onDatesChange, minDate, maxDate } = this.props;
        return (
            <DateRangePickerWrapper
                startDate={startDate} // momentPropTypes.momentObj or null,
                endDate={endDate} // momentPropTypes.momentObj or null,
                onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                onFocusChange={this.onFocusedInputChange} // PropTypes.func.isRequired,
                displayFormat="YYYY-MM-DD"
                isOutsideRange={() => false}
                minimumNights={0}
                startDatePlaceholderText=""
                endDatePlaceholderText=""
                minDate={minDate}
                maxDate={maxDate}
            />
        );
    }
}

SecureModeDatePicker.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    onDatesChange: PropTypes.func
};

SecureModeDatePicker.defaultProps = {
    startDate: {},
    endDate: {},
    minDate: {},
    maxDate: {},
    onDatesChange: () => {}
};

export default SecureModeDatePicker;
