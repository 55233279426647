import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SelectBox from '../../presentationals/SelectBox';
import { withFilesContext } from './DriveFilesContext';

class SearchFilesSort extends Component {
    componentDidMount() {
        const {
            context: {
                actions: { setGridSettings }
            },
            intl
        } = this.props;

        this.options = [
            { value: 'score', message: intl.formatMessage({ id: 'drive.text.search.result.sort.first' }) },
            { value: 'objtStatChgDt', message: intl.formatMessage({ id: 'drive.text.search.result.sort.second' }) }
        ];

        const sort = { sortField: this.options[0].value, sortOrder: 'DESC' };

        setGridSettings({ sort });
    }

    selectSort = sortField => {
        const {
            context: {
                actions: { setGridSettings }
            }
        } = this.props;
        const sort = { sortField, sortOrder: 'DESC' };
        setGridSettings({ sort });
    };

    render() {
        const {
            total,
            context: {
                state: {
                    sort: { sortField }
                }
            }
        } = this.props;
        return (
            total > 0 && (
                <div className="comm-title-bar">
                    <div className="section-filter">
                        <span className="select-type-box">
                            <SelectBox
                                className="selectpicker"
                                options={this.options}
                                value={sortField}
                                onChange={value => {
                                    this.selectSort(value);
                                }}
                                dataWidth="204"
                            />
                        </span>
                    </div>
                </div>
            )
        );
    }
}

SearchFilesSort.propTypes = {
    context: PropTypes.object.isRequired,
    total: PropTypes.number,
    intl: PropTypes.object.isRequired
};

SearchFilesSort.defaultProps = {
    total: 0
};

SearchFilesSort.displayName = 'SearchFilesSort';
export default withFilesContext(injectIntl(SearchFilesSort));
