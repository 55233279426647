import React, { memo, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../../../../containers/Summary/Common/SummaryConstant';

const MultipleFilesSummaryMenu = memo(({ files, iconType, intl }) => {
    const optionSelectDialogOpenButtonRef = useRef();

    const dispatch = useDispatch();

    const getIconClassName = () => {
        switch (iconType) {
            case 'header':
            case 'row':
                return 'ic-20-copilot-summary';
            default:
                return 'ic-12-copilot-summary';
        }
    };

    const getMenuLabel = label => {
        if (iconType === 'row') {
            return '';
        }

        return <span className="btn-text">{label}</span>;
    };

    const handleClick = () => {
        dispatch(summaryActions.promptInput.insertPromptTemplate({ promptTemplateType: SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword }));
        dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files } }));
        dispatch(summaryActions.sidePanel.open());
    };

    const renderIcon = label => {
        return (
            <a className="btn-ic-nor" role="button" ref={optionSelectDialogOpenButtonRef} onClick={handleClick} title={intl.formatMessage({ id: 'drive.button.list.menu.multiple_files_summary' })}>
                <i className={getIconClassName()} />
                {getMenuLabel(label)}
            </a>
        );
    };

    return (
        <>
            <FormattedMessage id="drive.button.list.menu.multiple_files_summary">
                {label => (
                    <Choose>
                        <When condition={iconType === 'row'}>
                            <li className="nav-item">{renderIcon(label)}</li>
                        </When>
                        <Otherwise>{renderIcon(label)}</Otherwise>
                    </Choose>
                )}
            </FormattedMessage>
        </>
    );
});

MultipleFilesSummaryMenu.propTypes = {
    files: PropTypes.object,
    iconType: PropTypes.string
};

MultipleFilesSummaryMenu.defaultProps = {
    files: [],
    iconType: null
};

export default injectIntl(MultipleFilesSummaryMenu);
