const RECYCLEBIN_PERIOD = 'EFL_DRV_RECYCLEBIN_STORAGE_PERIOD';
const AGENT_DOWNLOAD_INFO = 'EFL_DRV_AGENT_DOWNLOAD_INFO';
const UPLOADER_DOWNLOAD_INFO = 'EFL_DRV_UPLOADER_DOWNLOAD_INFO';
const FILE_CHECK_MAX_COUNT = 'EFL_DRV_FILE_CHECK_MAX_COUNT';
const CDM_FILE_CHECK_MAX_COUNT = 'EFL_DRV_CDM_FILE_CHECK_MAX_COUNT';
const FLCM_FILE_CHECK_MAX_COUNT = 'EFL_DRV_FLCM_FILE_CHECK_MAX_COUNT';
const DEFAULT_AUTH_CD_FOLDER = 'EFL_DRV_DEFAULT_AUTH_CD_FOLDER';
const DEFAULT_AUTH_CD_FILE = 'EFL_DRV_DEFAULT_AUTH_CD_FILE';
const FILEVIEWER_USE_YN = 'EFL_DRV_FILEVIEWER_USE_YN';
const SHARE_AUTH_MAP = 'EFL_DRV_SHARE_AUTH_MAP';
const AGENT_TYPE = 'EFL_DRV_AGENT_TYPE';
const SECURE_FOLDER_USE_REQUEST = 'EFL_DRV_SECURE_FOLDER_USE_REQUEST_YN';

export default {
    RECYCLEBIN_PERIOD,
    AGENT_DOWNLOAD_INFO,
    UPLOADER_DOWNLOAD_INFO,
    FILE_CHECK_MAX_COUNT,
    CDM_FILE_CHECK_MAX_COUNT,
    FLCM_FILE_CHECK_MAX_COUNT,
    DEFAULT_AUTH_CD_FOLDER,
    DEFAULT_AUTH_CD_FILE,
    FILEVIEWER_USE_YN,
    SHARE_AUTH_MAP,
    AGENT_TYPE,
    SECURE_FOLDER_USE_REQUEST
};
