import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSummarizableExtensionListFromConfigs } from './Util/SummaryUtil';

const SummaryGuideDetail = memo(({ configs }) => {
    const extensionList = useMemo(() => {
        const summarizableExtensionList = getSummarizableExtensionListFromConfigs(configs);
        return summarizableExtensionList.join(', ');
    }, [configs]);

    return (
        <div className="copilot-guide-detail">
            <dl>
                <dt>
                    <i className="ic-16-copilot-attention" />
                    <FormattedMessage id="drive.guideText.copilot.summary.sup1" />
                </dt>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup1.sub1" />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup1.sub2" />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup1.sub3" />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup1.sub4" />
                </dd>
            </dl>
            <dl>
                <dt>
                    <i className="ic-16-copilot-prohibition" />
                    <FormattedMessage id="drive.guideText.copilot.summary.sup2" />
                </dt>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup2.sub1" />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup2.sub2" />
                </dd>
            </dl>
            <dl>
                <dt>
                    <i className="ic-16-copilot-restriction" />
                    <FormattedMessage id="drive.guideText.copilot.summary.sup3" />
                </dt>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup3.sub1" values={{ extensionList }} />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup3.sub3" />
                </dd>
                <dd>
                    <FormattedMessage id="drive.guideText.copilot.summary.sup3.sub4" />
                </dd>
            </dl>
            <div className="guide-marker">
                <FormattedMessage id="drive.guideText.copilot.summary.warning01" />
            </div>
        </div>
    );
});

SummaryGuideDetail.propTypes = {
    configs: PropTypes.array
};

SummaryGuideDetail.defaultProps = {
    configs: []
};

const mapStateToProps = ({ config }) => ({
    configs: config.configs
});

export default connect(mapStateToProps)(SummaryGuideDetail);
