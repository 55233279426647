import React from 'react';
import { object } from 'prop-types';
import { popup } from '../../utils/ModalService';
import IncomTypeLink from '../presentationals/FileLink/IncomTypeLink';

const openPopup = data => {
    console.log('data: ', data);
    return popup(<IncomTypeLink {...data} />);
};

const IncomTypeLinkPopup = ({ match, location }) => {
    const { incomLinkUrl, objtId } = match.params;
    openPopup({ incomLinkUrl, location, objtId }).then(() => {}, () => {});
    return <></>;
};

IncomTypeLinkPopup.propTypes = {
    match: object.isRequired,
    location: object.isRequired
};

export default IncomTypeLinkPopup;
