import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import FileUploader from '../../../utils/Fileuploader/FileUploader';
import Filters from '../../../utils/Fileuploader/Filters';
import UploadList from './UploadList';
import { confirm } from '../../../utils/ModalService';
import FileReload from '../../../utils/FileReload';
import UploadStatus from './UploadStatus';

export function getProcessStatus(item) {
    if (item.isError) {
        return 'failed';
    }
    if (item.isSuccess) {
        return 'completed';
    }
    if (item.isUploading) {
        return 'uploading';
    }
    if (item.isCancel) {
        return 'canceled';
    }
    return 'queued';
}

export function createFileFromFileItem(fileItem) {
    const { uid, file, isCancel, isError, isReady, isSuccess, isUploaded, isUploading, progress, uploaded } = fileItem;

    const status = getProcessStatus(fileItem);

    const item = { uid, file, isCancel, isError, isReady, isSuccess, isUploaded, isUploading, progress, uploaded, status };

    return item;
}

export function createFilesFromQueue(uploaderqueue) {
    const queue = uploaderqueue.map(fileItem => {
        return createFileFromFileItem(fileItem);
    });
    return queue;
}

const initialState = {
    files: [],
    listVisible: true,
    event: '',
    completedClose: false
};

const initialFileStateCount = {
    totalCount: 0,
    failedCount: 0,
    completedCount: 0,
    queuedCount: 0,
    uploadingCount: 0,
    totalSize: 0,
    uploadedSize: 0
};

class UploadManager extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.uploader = null;
        this.fileStateCount = { ...initialFileStateCount };
    }

    componentDidMount() {
        const fileuploader = new FileUploader({ filters: Filters.getFilters(['size', 'ext']) });

        fileuploader.onRender = () => {
            this.setState({ files: createFilesFromQueue(fileuploader.queue), event: 'render' });
        };

        fileuploader.onAfterRemoveFile = () => {
            this.setState({ files: createFilesFromQueue(fileuploader.queue), event: 'remove' });
        };

        fileuploader.onAfterClearQueue = () => {
            this.setState({ ...initialState });
        };

        fileuploader.onWhenAddingFileFailedAll = fails => {
            Filters.filterdItemsHandler(fails, fileuploader);
        };

        fileuploader.onProgressItem = fileItem => {
            const { files } = this.state;

            const index = files.findIndex(item => item.uid === fileItem.uid);

            this.setState({
                files: [...files.slice(0, index), createFileFromFileItem(fileItem), ...files.slice(index + 1, files.length)],
                event: 'progress'
            });
        };

        fileuploader.onSuccessItem = fileItem => {
            const files = [{ drive: Object.assign({}, fileItem.result.data) }];
            console.log('uploadComplete', files);
            FileReload.call(null, files, { reloadType: 'uploadComplete', reloadCreated: true });
        };

        this.uploader = fileuploader;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentWillUnmount() {
        this.uploader.clearQueue();
    }

    addToQueue(addFiles) {
        const { files, metadata } = addFiles;
        this.uploader.addToQueue(files, { metadata });
    }

    handleRemove = uid => {
        const { files } = this.state;
        const index = files.findIndex(item => item.uid === uid);
        const { status } = files[index];
        console.log('handle remove', status);
        if (status === 'failed' || status === 'completed') {
            this.uploader.removeFromQueueByKey(uid);
        } else {
            // 업로드를 취소하시겠습니까?
            confirm(<FormattedMessage id="drive.confirm.upload.popup.cancel" />).then(data => {
                if (data) {
                    this.uploader.isUploading = false;
                    this.uploader.removeFromQueueByKey(uid);
                }
            });
        }
    };

    handleToggle = () => {
        const { listVisible } = this.state;
        this.setState({ listVisible: !listVisible });
    };

    handleClose = () => {
        if (this.fileStateCount.queuedCount > 0 || this.fileStateCount.uploadingCount > 0) {
            // 모든 업로드를 취소하시겠습니까?
            confirm(<FormattedMessage id="drive.confirm.upload.popup.cancelAll" />).then(data => {
                if (data) {
                    this.uploader.isUploading = false;
                    this.uploader.clearQueue();
                }
            });
        } else {
            this.uploader.clearQueue();
        }
    };

    handleCheck = () => {
        const { completedClose } = this.state;
        this.setState({ completedClose: !completedClose });
    };

    renderUploadsManager = () => {
        const { files, listVisible, event, completedClose } = this.state;

        if (files.length > 0) {
            const initStatusCount = { ...initialFileStateCount };

            const statusCount = files.reduce((initialStatusCount, item) => {
                initStatusCount.totalSize += item.file.size;
                initStatusCount.totalCount += 1;

                if (item.status === 'failed') {
                    initStatusCount.failedCount += 1;
                    initStatusCount.uploadedSize += item.file.size;
                } else if (item.status === 'completed') {
                    initStatusCount.completedCount += 1;
                    initStatusCount.uploadedSize += item.file.size;
                } else if (item.status === 'queued') {
                    initStatusCount.queuedCount += 1;
                } else {
                    initStatusCount.uploadedSize += item.uploaded;
                    initStatusCount.uploadingCount += 1;
                }

                return initStatusCount;
            }, initStatusCount);

            if (statusCount.totalSize === 0) {
                statusCount.totalProgress = 0;
            } else {
                statusCount.totalProgress = Math.floor((statusCount.uploadedSize * 100) / statusCount.totalSize);
            }

            this.fileStateCount = { ...statusCount };

            if (!!completedClose && statusCount.totalCount === statusCount.completedCount && statusCount.failedCount === 0) {
                this.handleClose();
            }

            return (
                <div className="uploads-manager-container is-visible boardview">
                    <div className="uploads-manager">
                        <UploadStatus files={files} listVisible={listVisible} statusCount={statusCount} onToggle={this.handleToggle} onClose={this.handleClose} />
                        {listVisible ? (
                            <UploadList files={files} statusCount={statusCount} event={event} completedClose={completedClose} onRemove={this.handleRemove} onCheck={this.handleCheck} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        }
        return '';
    };

    render() {
        return (
            <div className="fixed-snackbar">
                <div className="snackbar-body">{this.renderUploadsManager()}</div>
            </div>
        );
    }
}

export default UploadManager;
