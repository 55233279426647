import { $http } from 'modules/index';

class FileVersionRest {
    static getFileVersions(objtId, onpstId, offset, limit) {
        const params = {
            onpstId,
            offset,
            limit
        };
        return $http.get(`/drive/v1/file-version/${objtId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static setFileVersionRevert(objtId, onpstId, fileVerSno) {
        const params = {
            objtId,
            onpstId,
            fileVerSno
        };

        return $http.post(`/drive/v1/file-version/revert`, {}, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static deleteFileVersion(objtId, onpstId, fileVerSno) {
        const params = {
            objtId,
            onpstId,
            fileVerSno
        };
        return $http.delete(`/drive/v1/file-version/delete`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}

export default FileVersionRest;
