import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

const IconFilterKey = {
    IconFilterExtNm: 'iconFilterConfig'
};

const getTypeGroup = () => {
    const IconFilterConfigStorage = localStorage.getItem(IconFilterKey.IconFilterExtNm);
    const iconFilterGroup = JSON.parse(IconFilterConfigStorage);
    return iconFilterGroup.filterGroup;
};

const FileType = props => {
    const {
        file: { drive = {} },
        intl,
        cellStyle
    } = props;

    let typeName = intl.formatMessage({ id: `drive.filter.search.extension.${drive.objtSectCd.toLowerCase()}` });
    if (drive.objtSectCd === 'FILE') {
        const extension = drive.objtNm.substring(drive.objtNm.lastIndexOf('.') + 1).toLowerCase();
        const typeGroup = getTypeGroup();
        typeName = _.findKey(typeGroup, extensions => extensions.indexOf(extension) !== -1);
        if (undefined === typeName) {
            typeName = intl.formatMessage({ id: `drive.filter.search.extension.etc` });
        }
    }

    return (
        <div className="grid-cell files-type-cell" style={cellStyle} title={typeName}>
            {typeName}
        </div>
    );
};

FileType.defaultProps = {
    cellStyle: null
};

FileType.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

FileType.displayName = 'FileType';
export default injectIntl(FileType);
