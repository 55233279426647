import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ModalBody } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import ReleaseNoteRest from 'apis/ReleaseNoteRest';
import CustomModal from '../CustomModal';
import GlobalPopupService from '../../../redux/service/GlobalPopup';
import 'react-quill/dist/quill.snow.css'; // 어드민에서 사용하고있는 에디터사용 컨텐츠 표기위한 css (like 공지사항/FAQ)

import Editor from '../../presentationals/editor/Editor';

// const StyledDiv = styled.div`
//     img {
//         max-width: 100%;
//     }
// `;

const ReleaseNotePopup = props => {
    const { intl, id, closeCallback, isOpen } = props;
    const [show, setShow] = useState(isOpen);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [date, setDate] = useState('');

    useEffect(() => {
        ReleaseNoteRest.getReleasenote(id).then(resolve => {
            const { resultCode, message, data } = resolve;
            if (resultCode === 200) {
                const { relnoteTitle, relnoteDtlContent, relnoteRegDt } = data;
                setTitle(relnoteTitle);
                setContent(relnoteDtlContent);
                setDate(relnoteRegDt);
            } else {
                GlobalPopupService.openAlertPopup({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } });
            }
        });
    }, []);

    const handleClose = isShow => {
        setShow(isShow);
        closeCallback();
    };

    const getDate = dateString => {
        return moment(dateString).format('YYYY-MM-DD');
    };

    return (
        <CustomModal isOpen={show} fade={true} size="lg" backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.release.note.show.content" />
                </h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => handleClose(false)}>
                    <i className="ic-20-close" />
                </a>
            </div>
            <ModalBody>
                <div className="notice-header">
                    <div className="notice-title">{title}</div>
                    <em className="update">{getDate(date)}</em>
                </div>
                <div className="notice-body">
                    <Editor html={content} />
                </div>
            </ModalBody>
        </CustomModal>
    );
};

ReleaseNotePopup.propTypes = {
    id: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default injectIntl(ReleaseNotePopup);
