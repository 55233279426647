import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import ConfigsRest from 'apis/ConfigsRest';
import { If } from 'jsx-control-statements';
import { globalPopup, reqMng as reqMngActions } from '../../../../redux/actions';
import ReqRest from '../../../../apis/ReqRest';
import { confirm, alert, largePopup } from '../../../../utils/ModalService';
import ReqRejectPop from '../Popup/ReqRejectPop';
import DriveConfigRest from '../../../../apis/DriveConfigRest';
import DriveConfigs from '../../../../constants/DriveConfigs';

class ReqMngHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { deleteYn: 'N', maxSearchDays: 30 };
        const { setReqMngListSelected } = props;

        // 최대 선택가능 개수 설정
        this.getSelectMaxCount().then(maxCount => {
            setReqMngListSelected({ maxCount });
        });
    }

    componentDidMount() {
        ConfigsRest.getCommonConfig('EFL_COM_PERMANENTLY_DELETE_YN').then(response => {
            const { cfgVal } = response;
            this.setState({ deleteYn: cfgVal });
        });

        ConfigsRest.getCommonConfig('EFL_COM_PERMANENTLY_DELETE_DAY').then(response => {
            const { cfgVal } = response;
            this.setState({ maxSearchDays: cfgVal });
        });
    }

    unCheckAll = () => {
        const { reqMngList, setReqMngList, setReqMngListSelected } = this.props;

        const tmpReqMngList = _.clone(reqMngList);
        for (let i = 0; i < tmpReqMngList.length; i += 1) {
            tmpReqMngList[i].isChecked = false;
        }

        setReqMngList({ reqMngList: tmpReqMngList });
        setReqMngListSelected({ allChecked: false, checkedCnt: 0, lastCheckedIndex: '' });
    };

    reqProcess = async reqPrcsType => {
        const { reqMngList, setReqMngListParam, reqMngListSelected, openAlert } = this.props;

        if (reqMngListSelected.checkedCnt === 0) return;

        const reqPrcsList = [];

        _.forEach(reqMngList, item => {
            if (item.isChecked) {
                reqPrcsList.push(item.reqId);
            }
        });

        if (reqPrcsType === 'CANCEL') {
            const doRequest = await confirm({ id: 'com.confirm.ReqMngBodyReqStat.cancel' });
            if (!doRequest) {
                return;
            }
        }

        const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList });

        if (res.resultCode === 500) {
            alert('com.alert.internalServerError');
        } else if (res.resultCode === 400) {
            alert('com.alert.reqInvalidParam');
        } else if (res.resultCode === 200) {
            if (reqPrcsList.length > 1) {
                openAlert({ id: res.message, values: { N: reqPrcsList.length } });
            } else {
                alert(res.message);
            }
        } else if (res.resultCode === 403 && !!res.data) {
            alert(res.data);
        } else {
            alert(res.message);
        }

        this.initReqs();
        setReqMngListParam({ page: 1, limit: 100, reloadYn: true });
    };

    async getSelectMaxCount() {
        const maxCountStr = await DriveConfigRest.getConfig(DriveConfigs.FILE_CHECK_MAX_COUNT, true);
        let maxCount = 100;

        if (!_.isNil(maxCountStr) && maxCountStr !== '') {
            try {
                maxCount = _.toNumber(maxCountStr);
            } catch (e) {
                console.log('Error! when convert to number', maxCountStr);
            }
        }
        return maxCount;
    }

    initReqs() {
        const { setReqMngList, setReqMngListSelected } = this.props;

        setReqMngListSelected({ allChecked: false, checkedCnt: 0 });
        setReqMngList({ reqMngList: [] });
    }

    openReqRejectPop = (index, rejectType) => {
        const { reqMngListSelected } = this.props;

        if (reqMngListSelected.checkedCnt === 0) return;

        largePopup(<ReqRejectPop index={index} rejectType={rejectType} />);
    };

    render() {
        const { reqMngListSelected, reqMngListParam } = this.props;
        const { deleteYn, maxSearchDays } = this.state;

        return (
            <div className="comm-func-bar">
                <div className="func-bar-title">
                    <h3>
                        <FormattedMessage id="com.title.reqMng" />
                    </h3>
                </div>
                {reqMngListSelected.checkedCnt > 0 && (
                    <div className="func-bar-filter">
                        <div className="filter-multi">
                            <span className="count">
                                <i className="num">{reqMngListSelected.checkedCnt}</i>
                                <FormattedMessage id="com.text.selected" />
                            </span>
                            <a className="btn-link" role="button" onClick={this.unCheckAll}>
                                <FormattedMessage id="com.text.unselected" />
                            </a>
                        </div>
                        {reqMngListParam.reqType === 'send' && (
                            <ul className="filter-option nav">
                                <li className="nav-item">
                                    <a className="btn-ic-nor" role="button" onClick={() => this.reqProcess('CANCEL')}>
                                        <i className="ic-20-retraction" />
                                        <span className="btn-text">
                                            <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        )}
                        {reqMngListParam.reqType === 'received' && (
                            <ul className="filter-option nav">
                                <li className="nav-item">
                                    <a className={`btn-ic-nor ${reqMngListSelected.enabled ? '' : 'disabled'}`} role="button" onClick={() => this.reqProcess('APPROVAL')}>
                                        <i className="ic-20-approved" />
                                        <span className="btn-text">
                                            <FormattedMessage id="com.button.approval" />
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`btn-ic-nor ${reqMngListSelected.enabled ? '' : 'disabled'}`} role="button" onClick={() => this.openReqRejectPop(null, 'MULTI')}>
                                        <i className="ic-20-rejected" />
                                        <span className="btn-text">
                                            <FormattedMessage id="com.button.reject" />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>
                )}
                <If condition={deleteYn === 'Y'}>
                    <div className="info-msg">
                        <i className="ic-16-info" />
                        <FormattedHTMLMessage id="com.text.req.limit-days" tagName="p" values={{ n: maxSearchDays }} />
                    </div>
                </If>
            </div>
        );
    }
}

ReqMngHeader.propTypes = {
    reqMngList: PropTypes.array.isRequired,
    reqMngListParam: PropTypes.object.isRequired,
    reqMngListSelected: PropTypes.object.isRequired,
    setReqMngList: PropTypes.func,
    setReqMngListSelected: PropTypes.func,
    setReqMngListParam: PropTypes.func,
    openAlert: PropTypes.func
};

ReqMngHeader.defaultProps = {
    setReqMngList: () => {},
    setReqMngListSelected: () => {},
    setReqMngListParam: () => {},
    openAlert: () => {}
};

const mapStateToProps = state => ({
    reqMngList: state.reqMng.reqMngList,
    reqMngListParam: state.reqMng.reqMngListParam,
    reqMngListSelected: state.reqMng.reqMngListSelected,
    openAlert: PropTypes.func.isRequired
});

const mapDispatchToProps = {
    setReqMngListParam: reqMngActions.setReqMngListParam,
    setReqMngList: reqMngActions.setReqMngList,
    setReqMngListSelected: reqMngActions.setReqMngListSelected,
    openAlert: globalPopup.openAlert
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReqMngHeader);

export default connected;
