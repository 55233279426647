const LINE_TYPE = {
    DRAFT: 'draft',
    APPROVAL: 'APPROVAL',
    CONSENT: 'CONSENT',
    NOTIFY: 'NOTIFY',
    PARALLEL_APPROVAL: 'PAPPROVAL',
    PARALLEL_CONSENT: 'PCONSENT'
};

const TYPE = {
    EXCEPT_NON_COMPLIANCE: 'EXCEPT_NON_COMPLIANCE',
    EXTENDS_EXPIRATION: 'EXTENDS_EXPIRATION',
    USE_SECURE_FOLDER: 'USE_SECURE_FOLDER',
    CREATE_WORKGROUP: 'CREATE_WORKGROUP',
    EXCEPT_PC_CONTROL: 'EXCEPT_PC_CONTROL',
    CREATE_OUT_LINK: 'CREATE_OUT_LINK'
};

const MODE = {
    REQUEST: 'REQUEST',
    VIEW: 'VIEW'
};

const STAT_CODE = {
    PROCESSING: 'PROCESSING',
    APPROVAL: 'APPROVAL',
    CANCEL: 'CANCEL',
    REJECT: 'REJECT'
};

const USER = {
    UNREG_USER_ID_PREFIX: '999999990099999'
};

export default {
    TYPE,
    LINE_TYPE,
    MODE,
    STAT_CODE,
    USER
};
