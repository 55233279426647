import moment from 'moment/moment';
import store from '../redux/store';

const FORMAT = {
    TODAY: 'HH:mm',
    THIS_YEAR: 'MM-DD HH:mm',
    NOT_THIS_YEAR: 'YYYY-MM-DD HH:mm'
};

const FORMAT_HISTORY = {
    THIS_YEAR: 'MM-DD',
    NOT_THIS_YEAR: 'YYYY-MM-DD'
};

const getDiff = () => {
    const storeState = store.getState();
    const { auth: { user: { locale: { timezoneDiff: diff = 0 } = {} } = {} } = {} } = storeState;
    return Number(diff);
};

function getFormat(value) {
    const now = moment().utcOffset(getDiff());
    const isThisYear = value.year() === now.year();
    const isToday = value.isSame(now, 'day');
    if (isToday) {
        return FORMAT.TODAY;
    }
    return isThisYear ? FORMAT.THIS_YEAR : FORMAT.NOT_THIS_YEAR;
}

function getFormatHistory(value) {
    const now = moment().utcOffset(getDiff());
    const isThisYear = value.year() === now.year();

    return isThisYear ? FORMAT_HISTORY.THIS_YEAR : FORMAT_HISTORY.NOT_THIS_YEAR;
}

/*
    당일 : HH:MM
    당해년도 : MM-DD HH:MM
    전년도/미래년도 : YYYY-MM-DD HH:MM
 */
class DateConverter {
    convert = value => {
        if (!value) return value;
        const m = moment(value).utcOffset(getDiff());
        const selectedFormat = getFormat(m);
        return m.isValid() ? m.format(selectedFormat) : value;
    };

    convertHistory = value => {
        const m = moment(value).utcOffset(getDiff());
        const selectedFormat = getFormatHistory(m);
        return m.isValid() ? m.format(selectedFormat) : value;
    };

    convertExpireDtm = value => {
        const m = moment(value).utcOffset(getDiff());
        return m.isValid() ? m.format('YYYY-MM-DD HH:mm') : value;
    };

    convertExpireDate = value => {
        const m = moment(value).utcOffset(getDiff());
        return m.isValid() ? m.format('YYYY-MM-DD') : value;
    };

    convertDate = value => {
        const m = moment(value).utcOffset(getDiff());
        return m.isValid() ? m.format('YYYY-MM-DD') : value;
    };

    convertDateFormat = (value, format = 'YYYY-MM-DD') => {
        const m = moment(value).utcOffset(getDiff());
        return m.isValid() ? m.format(format) : value;
    };

    convertDeletedDate = value => {
        const m = moment(value).add(getDiff(), 'minutes');
        if (m.format('YYYY-MM-DD') === '0001-01-01' || value == null) {
            return '-';
        }
        return m.isValid() ? m.format('YYYY-MM-DD  HH:mm') : value;
    };
}

export default new DateConverter();
