import CustomModal from 'containers/CustomModal';
import { bool, func, string, oneOfType, object } from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

export const Alert = props => {
    const { isOpen, message, closeAlert } = props;

    return (
        <CustomModal isOpen={isOpen} fade={false} size="sm" backdrop="static">
            <div className="modal-body">
                <dl className="col-list">
                    <dt className="nor ht-md">{_.isObject(message) ? <FormattedMessage id={message.id} values={message.values} /> : message}</dt>
                </dl>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-primary" role="button" onClick={closeAlert}>
                        <span className="btn-text">
                            <FormattedMessage id="com.close" />
                        </span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

Alert.propTypes = {
    isOpen: bool.isRequired,
    message: oneOfType([string, object]).isRequired,
    closeAlert: func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.alert.isOpen,
    message: state.globalPopup.alert.message
});

const connected = connect(
    mapStateToProps,
    { closeAlert: actions.globalPopup.closeAlert }
)(Alert);

export default connected;
