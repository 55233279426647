import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReqRest from '../../../../apis/ReqRest';
import { alert, confirm } from '../../../../utils/ModalService';

class ReqDtlFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    cancelRequest(reqPrcsType, reqId, reqTgt) {
        const { close, setReqMngListParam } = this.props;
        const reqPrcsList = [];
        reqPrcsList.push(reqId);

        confirm(<FormattedMessage id="com.confirm.ReqMngBodyReqStat.cancel" />).then(
            async () => {
                const res = await ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList, reqTgt });

                if (res.resultCode === 500) {
                    alert('com.alert.internalServerError');
                } else if (res.resultCode === 400) {
                    alert('com.alert.reqInvalidParam');
                } else if (res.resultCode === 403 && !!res.data) {
                    alert(res.data);
                } else {
                    alert(res.message);
                }
                close(true);
                this.initReqs();
                setReqMngListParam({ page: 1, limit: 100, reloadYn: true });
            },
            () => {}
        );
    }

    initReqs() {
        const { setReqMngList, setReqMngListSelected } = this.props;

        setReqMngListSelected({ allChecked: false, checkedCnt: 0 });
        setReqMngList({ reqMngList: [] });
    }

    render() {
        const { close, req, reqTgt, useCancelRequest } = this.props;

        return (
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    {req != null && req.reqStatCd === 'PROCESSING' && useCancelRequest === true && (
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => this.cancelRequest('CANCEL', req.reqId, reqTgt)}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ReqMngBodyReqStat.cancel" />
                            </span>
                        </a>
                    )}
                    <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => close(false)}>
                        <span className="btn-text">
                            <FormattedMessage id="com.button.ok" />
                        </span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

ReqDtlFooter.propTypes = {
    close: PropTypes.func,
    req: PropTypes.object,
    reqTgt: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    useCancelRequest: PropTypes.bool,
    setReqMngListParam: PropTypes.func,
    setReqMngList: PropTypes.func,
    setReqMngListSelected: PropTypes.func
};

ReqDtlFooter.defaultProps = {
    close: () => {},
    req: {},
    reqTgt: {},
    useCancelRequest: false,
    setReqMngListParam: () => {},
    setReqMngList: () => {},
    setReqMngListSelected: () => {}
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlFooter));

export default connected;
