import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { globalPopup } from 'src/redux/actions';
import DeptRest from 'apis/DeptRest';
import UserRest from 'apis/UserRest';
import CharacterImage from 'containers/CharacterImage/CharacterImage';
import CardPopoverWrapper from './CardPopoverWrapper';

const isKoreanUser = lang => lang === '001';

const DeptCard = props => {
    const { id, languageCode, tenantId, close, intl, openMemberPopup } = props;
    const [dept, setDept] = useState({});
    const [memberTotal, setMemberTotal] = useState(0);
    const [leader, setLeader] = useState({});

    const closeCard = event => {
        event.stopPropagation();
        close();
    };

    useEffect(() => {
        if (_.isEmpty(id)) return;

        if (dept.deptId !== id) {
            Promise.all([DeptRest.retrieveDeptByIdWithCache(id, tenantId), DeptRest.getDepartmentMemberCountWithCache(id)]).then(([retrievedDept, total]) => {
                setDept(retrievedDept);
                setMemberTotal(total);
                if (!_.isEmpty(retrievedDept.deptLeadrUserId)) {
                    UserRest.retrieveUserByUserIdWithCache(retrievedDept.deptLeadrUserId).then(setLeader);
                }
            });
        }
    }, [id]);

    if (_.isEmpty(dept)) {
        return <React.Fragment />;
    }

    const getDeptName = () => (isKoreanUser(languageCode) ? dept.deptNm : dept.deptEngNm);

    const openDeptMemberPopup = () => {
        openMemberPopup({ deptId: id, deptName: getDeptName(), languageCode });
        close();
    };

    return (
        <div className="name-card">
            <a className="btn-ic-nor close" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={closeCard}>
                <i className="ic-20-close" />
            </a>
            <div className="item-user-thumbnail">
                <CharacterImage size="lg" imageClassName="user-thumbnail-md org" />
            </div>
            <div className="profile">
                <div className="g-inline-flex">
                    <div className="item-user">
                        <div className="user-profile">
                            <div title={isKoreanUser(languageCode) ? dept.deptNm : dept.deptEngNm}>
                                <span className="name">{isKoreanUser(languageCode) ? dept.deptNm : dept.deptEngNm}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="li-divider-list">
                    {!_.isEmpty(leader) && (
                        <li>
                            <span className="captain has-name-card" title={`${intl.formatMessage({ id: 'user.namecard.teamLeader' })} ${leader.userLangCdNm}`}>
                                <FormattedMessage id="user.namecard.teamLeader" />
                                {` ${leader.userLangCdNm}`}
                            </span>
                        </li>
                    )}
                    <li>
                        <span className="text-link" role="button" onClick={openDeptMemberPopup}>
                            <FormattedMessage id="user.namecard.temaMember" values={{ value: memberTotal }} />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

DeptCard.propTypes = {
    id: PropTypes.string,
    languageCode: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    close: PropTypes.func,
    openMemberPopup: PropTypes.func.isRequired
};

DeptCard.defaultProps = {
    id: undefined,
    close: _.noop
};

const mapStateToProps = state => ({
    languageCode: _.get(state.auth.user, 'locale.languageCode'),
    tenantId: _.get(state.auth.user, 'tenantId')
});

const mapDispatchToProps = {
    openMemberPopup: globalPopup.openDeptMemberPopup
};

const ConnectedWithI18n = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DeptCard)
);
const DeptCardWithPopover = props => <CardPopoverWrapper {...props} card={ConnectedWithI18n} />;

DeptCardWithPopover.propTypes = {
    id: PropTypes.string,
    target: PropTypes.string,
    placement: PropTypes.string,
    languageCode: PropTypes.string,
    close: PropTypes.func
};

DeptCardWithPopover.defaultProps = {
    id: undefined,
    target: 'popoverLegacy',
    placement: 'auto',
    languageCode: '001',
    close: _.noop
};

export default DeptCardWithPopover;
