import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MoreButton = ({ style, onMore }) => {
    const btnStyle = {
        marginTop: '0px'
    };
    const textStyle = {
        paddingTop: '24px'
    };

    return (
        <div className="grid-row btns-row" style={style} key={-1}>
            <div className="grid-cell">
                <a
                    className="btn btn-secondary btn-block"
                    role="button"
                    style={btnStyle}
                    onClick={e => {
                        onMore();
                        e.stopPropagation();
                    }}>
                    <span className="btn-text" style={textStyle}>
                        <FormattedMessage id="com.viewmore" />
                    </span>
                </a>
            </div>
        </div>
    );
};

MoreButton.propTypes = {
    onMore: PropTypes.func,
    style: PropTypes.object
};

MoreButton.defaultProps = {
    onMore: () => {},
    style: {}
};

export default MoreButton;
