import React from 'react';
import 'bootstrap';
import 'bootstrap-select';
import { ConnectedRouter } from 'connected-react-router';
import HelpQuestionContainer from 'containers/HelpQuestion';
import { Authentication, ChangeOwner, ChangePasswordContainer, ModalContainer, ProfileEditPopup } from 'containers/index';
import { ConnectedTeamSearchWithTree } from 'containers/Popup/TeamSearch/TeamSearchWithTree';
import ReqPartnerAccountPopup from 'containers/ReqPartnerAccountPopup';
import SecureModeRequest from 'containers/Popup/SecureModeRequest/SecureModeRequestPopup';
import WhiteLabelDocumentTitle from 'containers/WhiteLabelDocumentTitle';
import { axiosInstance } from 'modules';
import Main from 'pages/Main';
import 'popper.js';
import { ConnectedAlert, ConnectedConfirm, ConnectedToast, FileMenu, Loading } from 'presentationals/index';
import { AxiosProvider } from 'react-axios';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'url-search-params-polyfill';
import ChangeExpirationDate from 'src/components/containers/Partner/ChangeExpirationDate';
import ConnectedIntlProvider from 'components/ConnectedIntlProvider';
import rootSaga from './redux/sagas';
import store, { history } from './redux/store';
import DirectPopupGate from './components/pages/DirectPopupGate';
import FileExt from './utils/FileExt';
import 'react-web-animations-js-wrapper';
import 'css/datepicker/_datepicker.css';
import 'css/common.css';
import 'css/page_drv.css';
import 'css/page_help.css';
import 'css/page_home.css';
import 'css/page_noti.css';
import 'css/page_set.css';
import 'css/terms.css';
// import 'css/privacy.css';
import DeptMemberPopup from './components/containers/Popup/DeptMemberPopup';
import WhiteLabelLogo from './components/containers/WhiteLabelLogo';
import QuickGuide from './components/containers/QuickGuide';
import AuthCd from './utils/AuthCd';

store.runSaga(rootSaga);

const EfssLightApp = (
    <Provider store={store}>
        <AxiosProvider instance={axiosInstance}>
            <ConnectedIntlProvider>
                <>
                    <Authentication>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route exact={true} path="/popup/:type/:objtId/:onpstId" component={DirectPopupGate} />
                                <Route path="/" component={Main} />
                            </Switch>
                        </ConnectedRouter>
                        <ChangePasswordContainer />
                        <ChangeOwner />
                        <ProfileEditPopup />
                        <SecureModeRequest />
                        <FileMenu history={history} />
                        <ConnectedTeamSearchWithTree />
                        <ChangeExpirationDate />
                        <ReqPartnerAccountPopup />
                        <HelpQuestionContainer />
                        <ConnectedToast />
                        <Loading />
                        <FileExt />
                        <DeptMemberPopup />
                        <WhiteLabelDocumentTitle />
                        <WhiteLabelLogo />
                        <QuickGuide />
                        <AuthCd />
                    </Authentication>
                    <ConnectedConfirm />
                    <ConnectedAlert />
                    <ModalContainer />
                </>
            </ConnectedIntlProvider>
        </AxiosProvider>
    </Provider>
);

ReactDOM.render(EfssLightApp, document.getElementById('root'));
