/* eslint-disable */
import _ from 'lodash';
import DriveConfigRest from "../apis/DriveConfigRest";
import DriveConfigs from "../constants/DriveConfigs";

function DriveWebSocket(listParam, agentType, callType) {
    return new Promise(function(resolve, reject) {
        // create a new websocket and connect
        let wsImpl = window.WebSocket || window.MozWebSocket;
        let ws;
        let start = new Date().getTime();
        let end = null;

        if(!wsImpl){
            console.log('window has not websocket.');
            resolve(false);
        }

        console.log('ws agentType :: ', agentType);
        console.log('ws callType :: ', callType);

        let protocol = 'ws';
        let url = agentType === 'S' ? (callType === 'Open' ? '://localhost:28383/' : '://localhost:38383/') : '://localhost:18383/';

        if (window.location.protocol === 'https:') {
            protocol = 'wss';
            url = agentType === 'S' ? (callType === 'Open' ? '://localhost:28181/' : '://localhost:38181/') : '://localhost:18181/';
        }

        console.log('ws host :: ', protocol + url);

        try {
            ws = new wsImpl(protocol + url);
        } catch(e) {
            console.log('websocket not connected.');
            console.error(e);
            resolve(false);
        }

        if(!ws){
            console.log('edm websocket not connected.');
            resolve(false);
        }

        // when data is comming from the server, this metod is called
        ws.onmessage = function (evt) {
            console.log("evt.data: ", evt.data);
            end = new Date().getTime();
            console.log("onmessage exe time: ", end - start);
            setTimeout(() => {
                ws.close();
            });

            resolve(evt.data);
        }

        // when the connection is established, this method is called
        ws.onopen = function () {
            console.log('.. connection open');
            end = new Date().getTime();
            console.log("open exe time: ", end - start);
            // if (!!window.external && !!window.external.GetAuthToken && !!window.external.GetAuthToken()) {
            //     return resolve(false);
            // }

            // listParam.forEach(function(item, idx) {
            //     console.log(idx + " data: " + data);
            // })

            // console.log("listParam: ", listParam);
            let requestData = _.join(listParam, String.fromCharCode(8));
            // console.log("requestData: ", requestData);

            ws.send(requestData);
        }

        ws.onerror = function(event) {
            console.log('edm websocket connection error ', event);
            resolve(false);
        }

        // when the connection is closed, this method is called
        ws.onclose = function () {
            console.log('.. connection closed');
            end = new Date().getTime();
            console.log("close exe time: ", end - start);
        }
    });
}

export default DriveWebSocket;