import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = props => {
    const { close } = props;
    return (
        <a className="btn-ic-nor" role="button" title="닫기" data-dismiss="modal" onClick={close}>
            <i className="ic-20-close" />
        </a>
    );
};

CloseButton.propTypes = {
    close: PropTypes.func.isRequired
};

export default CloseButton;
