import ActionType from '../actions/FileCheck';

const initialState = {};

let checkedFiles = {};
let lastestFile;
let maxCount = 100;

export const getCheckedFiles = () => {
    const copy = Object.assign({}, checkedFiles);
    return Object.keys(copy).map(k => copy[k]);
};

export const isChecked = file => {
    const objtId = (file && file.drive && file.drive.objtId) || (file && file.objtId);
    return objtId && checkedFiles[objtId] != null;
};

export const isReachedMaxCount = () => {
    return Object.keys(checkedFiles).length >= maxCount;
};

export const getMaxCount = () => {
    return maxCount;
};

export const getCheckedFileIds = () => {
    const copy = Object.assign({}, checkedFiles);
    return Object.keys(copy);
};

export const isListOverMaxCount = (file, list, filter = () => true) => {
    const lastestIdx = lastestFile ? lastestFile.index : file.index;
    const fileIdx = file.index;
    const remainCount = maxCount - Object.keys(checkedFiles).length;
    if (remainCount < 1) {
        return true;
    }
    if (isChecked(lastestFile) && fileIdx >= 0 && lastestIdx >= 0) {
        const maxIdx = Math.max(lastestIdx, fileIdx);
        let uncheckedCount = 0;
        if (list.length > maxIdx) {
            const startIdx = lastestIdx;
            const endIdx = fileIdx;
            const diff = lastestIdx <= fileIdx ? 1 : -1;
            const predicate = idx => (lastestIdx <= fileIdx ? idx <= endIdx : idx >= endIdx);
            for (let i = startIdx; predicate(i); i += diff) {
                if (filter(checkedFiles[list[i].drive.objtId])) {
                    if (!checkedFiles[list[i].drive.objtId]) {
                        uncheckedCount += 1;
                    }
                    if (remainCount < uncheckedCount) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

export default (state = initialState, action = {}) => {
    const { file = {} } = action;
    const { drive = {} } = file;
    switch (action.type) {
        case ActionType.ADD:
            if (drive.objtId != null && !isReachedMaxCount()) {
                checkedFiles[drive.objtId] = file;
                lastestFile = file;
            }
            return {
                checkedFiles: Object.keys(checkedFiles).map(k => checkedFiles[k])
            };
        case ActionType.SHIFT_ADD:
            if (drive.objtId != null && action.list) {
                const lastestIdx = lastestFile ? lastestFile.index : file.index;
                const fileIdx = file.index;
                const { filter = () => true } = action;
                if (isChecked(lastestFile) && fileIdx >= 0 && lastestIdx >= 0) {
                    const maxIdx = Math.max(lastestIdx, fileIdx);
                    if (action.list.length > maxIdx) {
                        const startIdx = lastestIdx;
                        const endIdx = fileIdx;
                        const diff = lastestIdx <= fileIdx ? 1 : -1;
                        const predicate = idx => (lastestIdx <= fileIdx ? idx <= endIdx : idx >= endIdx);
                        for (let i = startIdx; predicate(i); i += diff) {
                            if (isReachedMaxCount()) {
                                break;
                            }
                            if (filter(action.list[i])) {
                                checkedFiles[action.list[i].drive.objtId] = action.list[i];
                            }
                        }
                    }
                }
            }
            return {
                checkedFiles: Object.keys(checkedFiles).map(k => checkedFiles[k])
            };
        case ActionType.REMOVE:
            if (drive.objtId != null) {
                delete checkedFiles[drive.objtId];
                lastestFile = file;
            }
            return {
                checkedFiles: Object.keys(checkedFiles).map(k => checkedFiles[k])
            };
        case ActionType.REMOVE_ALL:
            checkedFiles = {};
            lastestFile = undefined;
            return {
                checkedFiles: []
            };
        case ActionType.SET_MAX_COUNT:
            maxCount = action.count;
            return {
                maxCount
            };
        case ActionType.SET_FILES:
            if (action.list && action.list.length) {
                action.list.forEach(actionFile => {
                    if (isChecked(actionFile)) {
                        const objtId = (actionFile && actionFile.drive && actionFile.drive.objtId) || (actionFile && actionFile.objtId);
                        checkedFiles[objtId] = actionFile;
                    }
                });
            }
            return {
                checkedFiles: Object.keys(checkedFiles).map(k => checkedFiles[k])
            };
        default:
            return state;
    }
};
