import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomList from 'components/presentationals/List/CustomList';
import TeamTree from 'components/containers/TeamTree';
import TeamSearchList from 'components/containers/Popup/TeamSearch/TeamSearchList';
import { If } from 'jsx-control-statements';
import UserRest from 'apis/UserRest';
import DeptRest from 'apis/DeptRest';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchUserRow from './SearchUserRow';
import SelectedUsers from './SelectedUsers';
import GlobalPopup from '../../../redux/service/GlobalPopup';

class SearchDepts extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            searchText: '',
            isOpenTeamList: false,
            teamMember: [],
            total: 0,
            team: [],
            retrievedTeam: [],
            isFocused: false,
            hasSubDept: true,
            boardView: false,
            orderStateForName: '',
            orderStateForPosition: '',
            treeHeightOnBoardView: 268,
            searchGridHeightOnBoardView: 230,
            selectedGridHeightOnBoardView: 138,
            orderState: 'A',
            addButtonState: 'disabled',
            addButtonStateForTeamMember: 'disabled',
            searchTeamMemberListAllChecked: false
        };

        this.profileImages = {};
        this.lastCheckedIndex = -1;
    }

    selectUser = () => {
        const { addTarget } = this.props;
        const { teamMember } = this.state;
        const itemList = _.filter(teamMember, ['checked', true]);
        const itemListUnchecked = _.map(itemList, function(item) {
            const itemCopied = Object.assign({}, item);
            itemCopied.checked = false;
            return itemCopied;
        });
        addTarget(itemListUnchecked);
    };

    checkSelectedUser = (index, checked, event) => {
        const { teamMember } = this.state;
        const checkedList = _.cloneDeep(teamMember);

        // shift key + row를 선택하면서
        // this.lastCheckedIndex === -1 (이전에 단순 클릭으로 선택된 row가 없으면)
        // 그냥 무시
        if (!_.isNil(event) && event.shiftKey && this.lastCheckedIndex === -1) {
            return;
        }

        // SearchUsers.jsx 의 checkSearchUser 과 동일하게 수정!!!
        // shift key + row를 선택하면서
        // this.lastCheckedIndex !== -1 (이전에 단순 클릭으로 선택된 row가 있으면)
        if (!_.isNil(event) && event.shiftKey && this.lastCheckedIndex !== -1) {
            const startIndex = _.min([this.lastCheckedIndex, index]);
            const endIndex = _.max([this.lastCheckedIndex, index]);

            // 이전에 클릭된 row부터(this.lastCheckedIndex) shift + 선택한 row까지만 선택하고 나머지는 다 해제
            _.forEach(checkedList, (draft, i) => {
                draft.checked = startIndex <= i && i <= endIndex;
                draft.isHovered = startIndex <= i && i <= endIndex;
            });
        } else {
            // 단순 row 선택
            checkedList[index].checked = checked;
            checkedList[index].isHovered = checked;

            // 선택하는 경우만 this.lastCheckedIndex 에 저장
            if (checked) {
                this.lastCheckedIndex = index;
            } else if (!checked && this.lastCheckedIndex === index) {
                this.lastCheckedIndex = -1;
            }
        }

        const forRemovingAllChecked = _.filter(checkedList, { checked: true });

        // 아무것도 선택된 것이 없으면 this.lastCheckedIndex 초기화
        if (forRemovingAllChecked.length === 0) {
            this.lastCheckedIndex = -1;
        }

        this.setState({
            teamMember: checkedList,
            addButtonState: '',
            searchTeamMemberListAllChecked: forRemovingAllChecked.length === checkedList.length,
            addButtonStateForTeamMember: forRemovingAllChecked.length > 0 ? '' : 'disabled'
        });
    };

    handleCheckboxAll = e => {
        const { teamMember } = this.state;
        const { checked } = e.target;

        const checkAllorNot = _.map(teamMember, item => {
            const clone = _.cloneDeep(item);
            clone.checked = checked;
            clone.isHovered = checked;
            return clone;
        });

        this.lastCheckedIndex = -1;

        this.setState({ teamMember: checkAllorNot, searchTeamMemberListAllChecked: checked });
        if (checked) {
            this.setState({ addButtonStateForTeamMember: '' });
        } else {
            this.setState({ addButtonStateForTeamMember: 'disabled' });
        }
    };

    setCheckProperty(user, value) {
        const converted = Object.assign({}, user);
        converted.checked = value;
        return converted;
    }

    setAddType(user) {
        const converted = Object.assign({}, user);
        converted.type = 'USER';
        return converted;
    }

    search = () => {
        const { searchText } = this.state;
        const { intl } = this.props;
        const trimmedSearchText = searchText.trim();
        if (_.isEmpty(trimmedSearchText)) {
            GlobalPopup.openAlertPopup(intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
            return;
        }
        this.setState({ isOpenTeamList: true });
    };

    onKeyUp = e => {
        if (e.key === 'Enter') {
            this.search();
        }
    };

    onClickDeptSearch = () => {
        this.search();
    };

    onChangeInputValue = e => {
        const value = _.get(e, 'target.value');
        this.setState({ searchText: value });
    };

    closeTeamListDialog = data => {
        this.setState({ isOpenTeamList: false });

        if (data != null) {
            const { user } = this.props;
            DeptRest.retreiveSubDepartments(user.tenantId, data.deptId).then(returnedData => {
                const copied = Object.assign({}, data);
                copied.children = _.filter(returnedData.data, subDept => subDept.deptId !== data.deptId);

                this.setState(
                    { retrievedTeam: copied, hasSubDept: !_.isEmpty(copied.children), addButtonState: '', addButtonStateForTeamMember: 'disabled', team: this.sanitizeRetrievedTeamData(copied) },
                    this.getTeamMember(copied.deptId)
                );
            });
        }
    };

    getTeamMember = retrievedDeptId => {
        const { user } = this.props;
        const { team, orderParam, orderState } = this.state;
        const deptId = _.isNil(retrievedDeptId) ? team.id : retrievedDeptId;

        this.lastCheckedIndex = -1;

        const params = {
            offset: 0,
            tenantId: user.tenantId.toString(),
            deptId,
            orderParam,
            orderState
        };

        UserRest.getUser(params).then(response => {
            let list = _.get(response, 'data', []);
            const total = _.get(response, 'total', 0);

            list = _.map(list, returnedUser => this.setCheckProperty(returnedUser, false));
            list = _.map(list, returnedUser => this.setAddType(returnedUser));
            this.setState(
                {
                    teamMember: list,
                    total,
                    searchTeamMemberListAllChecked: false,
                    addButtonStateForTeamMember: 'disabled'
                },
                this.adjustGridHeight
            );
        });
    };

    selectTeam = value => {
        const children = _.get(value, 'children', []);
        this.setState({ hasSubDept: !_.isEmpty(children), team: value, addButtonState: '', addButtonStateForTeamMember: 'disabled', searchTeamMemberListAllChecked: false }, this.getTeamMember);
    };

    sanitizeRetrievedTeamData = team => {
        return { id: team.deptId, title: team.deptLangCdNm, parentId: team.uprDeptId };
    };

    onChangeAddType = (event, value) => {
        const { addTarget } = this.props;
        const { team, retrievedTeam } = this.state;
        const targetTeam = _.isEmpty(team) ? this.sanitizeRetrievedTeamData(retrievedTeam) : team;
        if (!_.isEmpty(targetTeam)) {
            const teamListByType = _.cloneDeep(targetTeam);
            teamListByType.type = 'DEPT';
            if (value === 'DEPT_SUB') {
                teamListByType.subYn = true;
            } else {
                teamListByType.subYn = false;
            }
            teamListByType.checked = false;
            addTarget([teamListByType]);
        }
    };

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    onClickWideButton = () => {
        const { boardView } = this.state;
        this.setState({ boardView: !boardView }, () => this.adjustGridHeight());
    };

    adjustGridHeight = () => {
        const { boardView } = this.state;
        if (boardView) {
            this.setState({ treeHeightOnBoardView: 130, searchGridHeightOnBoardView: 92, selectedGridHeightOnBoardView: 276 });
        } else {
            this.setState({ treeHeightOnBoardView: 268, searchGridHeightOnBoardView: 230, selectedGridHeightOnBoardView: 138 });
        }
    };

    onClickClearButton = () => {
        this.setState({ searchText: '' });
    };

    deleteTargetsByClickIcon = items => {
        const { deleteTargets } = this.props;
        this.adjustGridHeight();
        deleteTargets(items);
    };

    changeOrderState = orderParam => {
        const { orderStateForName, orderStateForPosition } = this.state;
        const { languageCode } = this.props;
        let newOrderParam = '';
        let newOrderState = '';
        let newOrderSign = '';
        if (orderParam === 'userLangCdNm') {
            if (orderStateForName === '' || orderStateForName === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '001' ? 'userNm' : 'userEngNm';
            this.setState({ orderStateForName: newOrderSign });
            this.setState({ orderStateForPosition: '' });
        }

        if (orderParam === 'userLangCdCopNm') {
            if (orderStateForPosition === '' || orderStateForPosition === 'desc') {
                newOrderState = 'A';
                newOrderSign = 'asce';
            } else {
                newOrderState = 'D';
                newOrderSign = 'desc';
            }
            newOrderParam = languageCode === '001' ? 'userCopNm' : 'userCopEngNm';
            this.setState({ orderStateForName: '' });
            this.setState({ orderStateForPosition: newOrderSign });
        }

        this.setState({ orderParam: newOrderParam, orderState: newOrderState }, () => this.getTeamMember());
    };

    clearSearchText = () => {
        // TODO: input의 onfocus랑 이벤트 겹쳐서 가끔 실행안될 때 있어서 onfocus 없애야할듯
        this.setState({ searchText: '' });
    };

    render() {
        const { selected, selectedTotal, selectedAllChecked, deleteTargetDept, checkSelectedUser, handleCheckboxAll, intl, deleteTargetUser, cancelToken } = this.props;
        const {
            isOpenTeamList,
            searchText,
            teamMember,
            total,
            isFocused,
            hasSubDept,
            treeHeightOnBoardView,
            searchGridHeightOnBoardView,
            selectedGridHeightOnBoardView,
            boardView,
            orderStateForName,
            orderStateForPosition,
            addButtonState,
            retrievedTeam,
            addButtonStateForTeamMember,
            searchTeamMemberListAllChecked
        } = this.state;
        const rowBuilder = ({ style, index }) => {
            return (
                <SearchUserRow
                    user={teamMember[index]}
                    searchByDept={true}
                    index={index}
                    style={style}
                    key={`${teamMember[index].userId}_${teamMember[index].checked}`}
                    checkBox={true}
                    delBox={true}
                    checkSearchUser={this.checkSelectedUser}
                    profileCache={this.profileImages}
                    cancelToken={cancelToken}
                />
            );
        };
        return (
            <React.Fragment>
                <div className="forms-container">
                    <div className={`box-input-group has-overlay-layer ${isFocused ? 'is-focused' : ''}`}>
                        <a
                            className={`btn-ic-nor btn-func-search ${isFocused ? 'on' : ''}`}
                            role="button"
                            title={intl.formatMessage({ id: 'com.search' })}
                            onClick={event => this.onClickDeptSearch(event)}>
                            <i className="ic-16-search" />
                        </a>
                        <input
                            className="form-control"
                            type="text"
                            placeholder={intl.formatMessage({ id: 'user.placeHolder.popup.selectPeople.organize.searchDeptName' })}
                            onFocus={event => this.onFocus(event)}
                            onKeyUp={this.onKeyUp}
                            onChange={e => this.onChangeInputValue(e)}
                            value={searchText}
                            maxLength={100}
                        />
                        <a
                            className="btn-ic-nor btn-func-clear"
                            role="button"
                            title={intl.formatMessage({ id: 'com.text.clear' })}
                            style={searchText ? { display: 'inline-flex' } : { display: 'none' }}
                            onClick={this.clearSearchText}>
                            <i className="ic-16-clear" />
                        </a>
                        <If condition={isOpenTeamList}>
                            <TeamSearchList query={searchText} isOpen={isOpenTeamList} close={this.closeTeamListDialog} />
                        </If>
                    </div>
                </div>
                <div className={`srch-flex-layout ${boardView ? 'boardview' : ''}`}>
                    <div className="flex-upper">
                        <div className="flex-box-left">
                            <TeamTree selectTeam={this.selectTeam} height={treeHeightOnBoardView} searchedTeam={retrievedTeam} autoHeight={false} />
                            <div className="flex-box-bottom">
                                <If condition={hasSubDept}>
                                    <div className="dropdown">
                                        <a
                                            className={`btn btn-sm btn-secondary dropdown-toggle ${addButtonState}`}
                                            role="button"
                                            id="organizeMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <span className="btn-text">
                                                <FormattedMessage id="user.filter.popup.selectPeople.organize.addDept" />
                                            </span>
                                        </a>

                                        <div className="dropdown-menu" aria-labelledby="organizeMenuLink">
                                            <a
                                                className="dropdown-item"
                                                role="button"
                                                onClick={event => {
                                                    this.onChangeAddType(event, 'DEPT');
                                                }}>
                                                <FormattedMessage id="user.filter.popup.selectPeople.organize.addSelectedDept" />
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                role="button"
                                                onClick={event => {
                                                    this.onChangeAddType(event, 'DEPT_SUB');
                                                }}>
                                                <FormattedMessage id="user.filter.popup.selectPeople.organize.addDeptIncludeSubDept" />
                                            </a>
                                        </div>
                                    </div>
                                </If>
                                <If condition={!hasSubDept}>
                                    <a
                                        className={`btn btn-sm btn-secondary ${addButtonState}`}
                                        role="button"
                                        onClick={event => {
                                            this.onChangeAddType(event, 'DEPT');
                                        }}>
                                        <span className="btn-text">
                                            <FormattedMessage id="user.filter.popup.selectPeople.organize.addDept" />
                                        </span>
                                    </a>
                                </If>
                            </div>
                        </div>
                        <div className="flex-box-right">
                            <div className="pop-grid-list">
                                <If condition={teamMember.length === 0}>
                                    <div className="box-empty">
                                        <div className="message">
                                            <p>
                                                <FormattedMessage id="user.guideText.popup.selectPeople.organize.noItem" />
                                            </p>
                                        </div>
                                    </div>
                                </If>
                                <If condition={teamMember.length !== 0}>
                                    <div className="comm-grid-list react-list">
                                        <div className="grid-header-group">
                                            <div className="grid-row">
                                                <div className="grid-cell check-cell">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onClick={event => this.handleCheckboxAll(event)}
                                                                checked={searchTeamMemberListAllChecked}
                                                            />
                                                            <span className="label-text" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="grid-cell name-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdNm')} className={`column-sort ${orderStateForName}`}>
                                                        {' '}
                                                        <em>
                                                            <FormattedMessage id="com.name" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForName}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell position-cell">
                                                    <a role="button" onClick={() => this.changeOrderState('userLangCdCopNm')} className={`column-sort ${orderStateForPosition}`}>
                                                        <em>
                                                            <FormattedMessage id="com.position" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className={`ic-12-arr-${orderStateForPosition}`} />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell email-cell">
                                                    <span className="column-name">
                                                        <FormattedMessage id="com.email" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll">
                                            <CustomList
                                                className="grid-row-group row-5"
                                                customScroll={true}
                                                virtualized={true}
                                                height={searchGridHeightOnBoardView}
                                                rowCount={total}
                                                rowHeight={46}
                                                rowBuilder={rowBuilder}
                                            />
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <div className="flex-box-bottom">
                                <a className={`btn btn-sm btn-secondary ${addButtonStateForTeamMember}`} role="button" onClick={this.selectUser}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.add" />
                                    </span>
                                </a>
                                <div className="total-count">
                                    <span>
                                        <FormattedMessage id="com.text.total" />{' '}
                                    </span>
                                    <span className="count">
                                        <i className="num">{total}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectedUsers
                        selected={selected}
                        selectedTotal={selectedTotal}
                        onClickWideButton={this.onClickWideButton}
                        deleteTargetDept={deleteTargetDept}
                        deleteTargetUser={deleteTargetUser}
                        deleteTargets={this.deleteTargetsByClickIcon}
                        checkSelectedUser={checkSelectedUser}
                        handleCheckboxAll={handleCheckboxAll}
                        selectedAllChecked={selectedAllChecked}
                        selectedGridHeightOnBoardView={selectedGridHeightOnBoardView}
                        boardView={boardView}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(SearchDepts);

SearchDepts.propTypes = {
    addTarget: PropTypes.func,
    selected: PropTypes.array,
    selectedTotal: PropTypes.number,
    selectedAllChecked: PropTypes.bool,
    deleteTargetDept: PropTypes.func,
    deleteTargetUser: PropTypes.func,
    deleteTargets: PropTypes.func,
    checkSelectedUser: PropTypes.func,
    handleCheckboxAll: PropTypes.func,
    user: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    cancelToken: PropTypes.object
};

SearchDepts.defaultProps = {
    addTarget: _.noop,
    selected: [],
    selectedTotal: 0,
    selectedAllChecked: false,
    deleteTargetDept: _.noop,
    deleteTargetUser: _.noop,
    deleteTargets: _.noop,
    checkSelectedUser: _.noop,
    handleCheckboxAll: _.noop,
    cancelToken: {}
};
