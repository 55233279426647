import React from 'react';
import { FormattedMessage } from 'react-intl';

import { confirm, popup, viewerPopup, alert } from '../../../../utils/ModalService';
import BaseMenu from '../BaseMenu';
import FileViewerPopup from '../../../containers/Popup/FileViewerPopup';
import SettingsRest from '../../../../apis/SettingsRest';
import FileOpen from '../../FileOpen/FileOpen';
import DriveConfigRest from '../../../../apis/DriveConfigRest';

const OPEN_TYPE_PC = 'PC';

const openViewer = (drive, complianceYn) => {
    viewerPopup(<FileViewerPopup objtId={drive.objtId} onpstId={drive.onpstId} complianceYn={complianceYn} />).then(
        () => {
            // 뷰어에서 실행 클릭시 이쪽으로 리턴. 편집자가 업데이트 되어야할듯?
            return true;
        },
        () => {
            // 실행안누르고 닫은경우. 아무 변경이 없는루트.
            return false;
        }
    );
};

const openFile = file => {
    popup(<FileOpen file={file.drive} />).then(
        () => {
            return true;
        },
        () => {
            return false;
        }
    );
};

const whenPCViewer = file => {
    const { actionPolicy = {} } = file;
    const { openFile: policyOpenFile = false, viewFileWithViewer: policyViewer = false } = actionPolicy;

    // 파일 열기, 뷰어 실행 권한 없음
    if (!policyOpenFile && !policyViewer) {
        alert({ id: 'drive.alert.incomLink.noAuthFile' });
        return;
    }

    // 파일 열기 권한 있음
    if (policyOpenFile) {
        openFile(file);
    }
    // 파일 열기 없고, 뷰어 실행 권한 있음
    else if (policyViewer) {
        confirm(<FormattedMessage id="drive.alert.viewer.readonly" />).then(data => {
            if (data) {
                const { drive } = file;
                openViewer(drive);
            }
        });
    }
};

const whenWebViewer = (file, complianceYn) => {
    const { drive, actionPolicy = {} } = file;
    const { viewFileWithViewer: policyViewer = false } = actionPolicy;

    // 뷰어 실행 권한 없음
    if (!policyViewer) {
        alert({ id: 'drive.alert.incomLink.noAuthFile' });
        return;
    }
    openViewer(drive, complianceYn);
};

const executor = async (props, files, complianceYn) => {
    // props.callback();
    const file = files[0];
    const webViewerYn = await DriveConfigRest.getConfig('EFL_DRV_FILEVIEWER_USE_YN');

    if (webViewerYn === 'N') {
        whenPCViewer(file);
    } else {
        SettingsRest.getCfg('EFL_DRV_FILENAME_CLICK_EXECUTION_MODE').then(data => {
            const { cfgVal } = data;

            if (cfgVal === OPEN_TYPE_PC) {
                whenPCViewer(file);
            } else {
                whenWebViewer(file, complianceYn);
            }
        });
    }
};

class Viewer extends BaseMenu {
    getName() {
        return 'viewer';
    }

    getLabel() {
        return '파일 뷰어';
    }

    isVisible() {
        return true;
    }

    isEnable() {
        return true;
    }

    execute() {
        executor(this.props, this.files, this.complianceYn);
    }
}

export default Viewer;
