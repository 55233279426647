import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';
import Url from 'utils/Url';
import uuidV4 from 'uuid/v4';
import DriveBody from '../containers/DriveBody';
import DriveHeader from '../containers/DriveHeader';
import Expiration from '../../utils/DriveConfiguration/Expiration';
import GlobalPopupService from '../../redux/service/GlobalPopup';
import FileListAction from '../../redux/actions/FileList';

class ExpirationFiles extends React.Component {
    componentDidMount() {
        document.body.classList.remove('scroll-hidden');
        document.body.classList.add('res-except');
    }

    componentWillUnmount() {
        document.body.classList.remove('res-except');
        document.body.classList.add('scroll-hidden');
    }

    getExpirationInfo(adminValue) {
        return (
            <div className="info-msg">
                <i className="ic-16-info" />
                <FormattedHTMLMessage id="drive.text.list.expiration.info.keep" values={{ adminValue }} tagName="span" />
            </div>
        );
    }

    render() {
        const pageClass = Expiration;

        const { loginUser, history, setTotal } = this.props;
        const { config } = loginUser;
        const { useCstdPolicy, userCstdMap = {} } = config;
        const { cstdPolicyPstPrcs: policyType = 'DELETE', cstdPolicyFileClctDds = '' } = userCstdMap;

        let adminValue = 0;
        try {
            adminValue = Number.parseInt(cstdPolicyFileClctDds, 10);
            if (Number.isNaN(adminValue)) {
                adminValue = 0;
            }
        } catch (e) {
            console.log('Error! when convert to number', cstdPolicyFileClctDds);
        }

        if (!useCstdPolicy) {
            history.push(Url.home);
            GlobalPopupService.openAlertPopup({ id: 'com.alert.no-authority.page' });
        }

        let isHiddenInfo = '';
        if (
            policyType === 'EXPIRE' &&
            (history.location.preParams === undefined ||
                history.location.preParams === null ||
                (history.location.preParams.expirationDateFrom === undefined && history.location.preParams.expirationDateTo === undefined))
        ) {
            isHiddenInfo = this.getExpirationInfo(adminValue);
        }

        return (
            <div className="page-drv" key={uuidV4()}>
                <DriveHeader {...this.props} type="expiration" pageClass={pageClass}>
                    {policyType === 'DELETE' ? (
                        <div className="info-msg">
                            <i className="ic-16-info" />
                            <FormattedHTMLMessage id="drive.text.list.expiration.info.delete" values={{ adminValue }} tagName="span" />
                        </div>
                    ) : (
                        isHiddenInfo
                    )}
                </DriveHeader>
                <DriveBody {...this.props} pageClass={pageClass} setTotal={setTotal} />
            </div>
        );
    }
}

ExpirationFiles.propTypes = {
    loginUser: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setTotal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(
    mapStateToProps,
    {
        setTotal: total => ({ type: FileListAction.SET_TOTAL, count: total })
    }
)(ExpirationFiles);
