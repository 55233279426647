import React, { Component } from 'react';
import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { globalPopup, toast } from 'src/redux/actions';
import uuidV4 from 'uuid/v4';
import { injectIntl } from 'react-intl';
import HelpRest from '../../apis/HelpRest';
import WhiteLabelingRest from '../../apis/WhiteLabelingRest';
import HelpQuestion from '../presentationals/HelpQuestion';

const VOLUMN_UNIT = 1024;
const VOLUMN_LIMIT = 10 * 1024 * 1024;

const extIconClasses = [
    { className: 'ic-file-WORD', extensions: ['doc', 'docx', 'docm', 'dotx', 'dot'] },
    { className: 'ic-file-EXCEL', extensions: ['xls', 'xlsx', 'xlsb', 'xlsm', 'csv', 'xlt', 'xltx'] },
    { className: 'ic-file-PPT', extensions: ['ppt', 'pptx', 'pptm', 'ppsx', 'pps', 'potx', 'pot'] },
    { className: 'ic-file-PDF', extensions: ['pdf'] },
    { className: 'ic-file-IMAGE', extensions: ['bmp', 'jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff'] },
    { className: 'ic-file-VIDEO', extensions: ['avi', 'mp4', 'wmv', 'nar', 'ray', 'mov', 'mpeg', 'mpg'] },
    { className: 'ic-file-NOTEPAD', extensions: ['rft', 'txt'] },
    { className: 'ic-file-HWP', extensions: ['hwp', 'hml', 'hst', 'hwt'] },
    { className: 'ic-file-WEB', extensions: ['html', 'mht', 'htm'] },
    { className: 'ic-file-PSD', extensions: ['psd'] },
    { className: 'ic-file-XD', extensions: ['xd'] },
    { className: 'ic-file-AI', extensions: ['ai'] },
    { className: 'ic-file-ZIP', extensions: ['zip', 'apk', 'rar', '7z', 'alz'] }
];

class HelpQuestionContainer extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.intl = intl;
        this.buttonInputEl = null;
        this.dropInputEl = null;
        this.defaultContent = intl.formatMessage({ id: 'user.helpcenter.basicEnvironmentGuideText' });
        this.state = {
            isInited: false,
            title: '',
            content: this.defaultContent,
            valid: true,
            files: [],
            fromEmail: '',
            toEmail: '',
            ccEmail: '',
            bccEmail: '',
            isExternalSite: false
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        if (!prevProps.isOpen && isOpen) {
            this.getQuestionDefaultInfo();
        }
    }

    getQuestionDefaultInfo = async () => {
        const defaultInfo = await HelpRest.getQuestionDefaultInfo();
        const isExternalSite = await WhiteLabelingRest.isExternalSite();
        const { fromEmail, toEmail, ccEmail, bccEmail } = defaultInfo;
        this.setState({ fromEmail, toEmail, ccEmail, bccEmail, isInited: true, isExternalSite });
    };

    onClickCancelButton = () => {
        const { openConfirm } = this.props;
        const { title, content, files } = this.state;
        if (title !== '' || content !== this.defaultContent || !_.isEmpty(files)) {
            openConfirm(this.intl.formatMessage({ id: 'user.helpcenter.confirm.deletion' }), result => {
                if (result) this.closeAndReset();
            });
        } else {
            this.closeAndReset();
        }
    };

    closeAndReset = () => {
        const { onClose } = this.props;
        onClose();
        this.setState({
            isInited: false,
            title: '',
            content: this.defaultContent,
            valid: true,
            files: [],
            fromEmail: '',
            toEmail: '',
            ccEmail: '',
            bccEmail: '',
            isExternalSite: false
        });
    };

    onClickConfirmButton = async () => {
        const { title, content, files, fromEmail, toEmail, ccEmail, bccEmail } = this.state;
        const { openAlert } = this.props;
        if (content.trim() === '') {
            openAlert(this.intl.formatMessage({ id: 'user.alert.helpcenter.invalidParam' }));
        } else {
            const param = { fromEmail, toEmail, ccEmail, bccEmail, title, content };
            const response = await HelpRest.postQuestion(param, files);
            const { resultCode, data, message } = response;

            if (resultCode === 200 && data === 1) {
                this.closeAndReset();
                const { showToast } = this.props;
                showToast(this.intl.formatMessage({ id: 'user.helpcenter.completeGuide.oneToOneInquiry' }));
            } else if (resultCode === 400 && message === 'IOFFICE_INVALID_PARAMETER') {
                openAlert(this.intl.formatMessage({ id: 'user.alert.helpcenter.invalidParam' }));
            } else {
                openAlert(this.intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
            }
        }
    };

    onChangeTitle = event => {
        this.setState({ title: event.target.value, valid: !_.isEmpty(event.target.value) });
    };

    onChangeContent = event => {
        this.setState({ content: event.target.value });
    };

    onBlurTitleInput = event => {
        const trimValue = _.trim(event.target.value);
        this.setState({ title: trimValue });
        if (_.isEmpty(trimValue)) this.setState({ title: trimValue, valid: false });
    };

    addFiles = (newFiles, callback) => {
        const { files } = this.state;

        // check files count
        const fileCount = files.length + newFiles.length;
        if (fileCount > 10) {
            const { openAlert } = this.props;
            openAlert(this.intl.formatMessage({ id: 'user.alert.helpcenter.attachment.limit' }));
            this.setFormattedFilesToState(files, [], callback);
            return;
        }

        // check file names
        for (let i = 0; i < files.length; i += 1) {
            for (let j = 0; j < newFiles.length; j += 1) {
                if (`${files[i].name}.${files[i].extension}` === newFiles[j].name) {
                    const { openAlert } = this.props;
                    openAlert(this.intl.formatMessage({ id: 'com.alert.helpcenter.attachment.duplicate' }));
                    this.setFormattedFilesToState(files, [], callback);
                    return;
                }
            }
        }

        // check files volumn sum
        let newFilesVolumnSum = 0;
        for (let i = 0; i < newFiles.length; i += 1) {
            newFilesVolumnSum += parseInt(newFiles[i].size, 10);
        }
        let filesVolumnSum = 0;
        for (let i = 0; i < files.length; i += 1) {
            filesVolumnSum += parseInt(files[i].size, 10);
        }

        if (newFilesVolumnSum + filesVolumnSum > VOLUMN_LIMIT) {
            const { openAlert } = this.props;
            openAlert(this.intl.formatMessage({ id: 'user.helpcenter.guide.maxSize' }));
            this.setFormattedFilesToState(files, [], callback);
            return;
        }

        this.setFormattedFilesToState(files, newFiles, callback);
    };

    setFormattedFilesToState = (files, newFiles, callback) => {
        const newFomattedFiles = [].concat(files);
        for (let i = 0; i < newFiles.length; i += 1) {
            const newFile = newFiles[i];
            const { name, size } = newFile;
            let fileName = name;
            let fileExtension = '';
            if (name.lastIndexOf('.') !== -1) {
                fileName = name.substring(0, name.lastIndexOf('.'));
                fileExtension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            }
            const fileSizeArr = [];
            if (size > VOLUMN_UNIT * VOLUMN_UNIT) {
                fileSizeArr.push((size / (VOLUMN_UNIT * VOLUMN_UNIT)).toFixed(2));
                fileSizeArr.push(' MB');
            } else {
                fileSizeArr.push((size / VOLUMN_UNIT).toFixed(2));
                fileSizeArr.push(' KB');
            }
            let extClassName = 'ic-file-ETC';
            _.forEach(extIconClasses, ({ className, extensions }) => {
                if (extensions.includes(fileExtension)) extClassName = className;
            });
            extClassName = 'ic-file-sm '.concat(extClassName);
            const newFormattedFile = {
                uniqueKey: uuidV4(),
                name: fileName,
                extension: fileExtension,
                size,
                formattedSize: fileSizeArr.join(''),
                file: newFile,
                extClassName
            };
            newFomattedFiles.push(newFormattedFile);
        }

        this.setState(
            state =>
                produce(state, draft => {
                    draft.files = newFomattedFiles;
                }),
            () => {
                if (callback instanceof Function) callback();
            }
        );
    };

    onChangeFile = event => {
        this.addFiles(event.target.files, this.resetInputData);
    };

    resetInputData = () => {
        if (this.buttonInputEl) this.buttonInputEl.value = '';
        if (this.dropInputEl) this.dropInputEl.value = '';
    };

    onDropFile = files => {
        this.addFiles(files);
    };

    onClickRemoveFile = uniqueKey => {
        this.setState(state =>
            produce(state, draft => {
                draft.files = draft.files.filter(file => file.uniqueKey !== uniqueKey);
            })
        );
    };

    render() {
        const { isOpen } = this.props;
        const { title, content, valid, files, fromEmail, toEmail, ccEmail, isInited, isExternalSite } = this.state;
        return (
            <>
                {isInited && (
                    <HelpQuestion
                        isOpen={isOpen}
                        fromEmail={fromEmail}
                        toEmail={toEmail}
                        ccEmail={ccEmail}
                        title={title}
                        content={content}
                        valid={valid}
                        isExternalSite={isExternalSite}
                        onClickCancelButton={this.onClickCancelButton}
                        onClickConfirmButton={this.onClickConfirmButton}
                        onChangeTitle={this.onChangeTitle}
                        onChangeContent={this.onChangeContent}
                        onBlurTitleInput={this.onBlurTitleInput}
                        onChangeFile={this.onChangeFile}
                        onDropFile={this.onDropFile}
                        files={files}
                        onClickRemoveFile={this.onClickRemoveFile}
                        setButtonInputRef={el => {
                            this.buttonInputEl = el;
                        }}
                        setDropInputRef={el => {
                            this.dropInputEl = el;
                        }}
                    />
                )}
            </>
        );
    }
}

HelpQuestionContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired,
    openConfirm: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.helpQuestion.isOpen
});

const mapDispatchToProps = {
    onClose: globalPopup.closeHelpQuestionPopup,
    openAlert: globalPopup.openAlert,
    openConfirm: globalPopup.openConfirm,
    showToast: toast.showToast
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HelpQuestionContainer)
);
