import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DriveHeader from '../containers/DriveHeader';
import DriveBody from '../containers/DriveBody';
import Duplicated from '../../utils/DriveConfiguration/Duplicated';
import FileListAction from '../../redux/actions/FileList';

const DuplicatedFiles = props => {
    const pageClass = Duplicated;
    const { setTotal } = props;
    return (
        <div className="page-drv">
            <DriveHeader {...props} type="duplicated" pageClass={pageClass}>
                <div className="info-msg">
                    <i className="ic-16-info" />
                    <FormattedHTMLMessage id="drive.text.list.duplicated.info" tagName="span" />
                </div>
            </DriveHeader>
            <DriveBody {...props} pageClass={pageClass} setTotal={setTotal} />
        </div>
    );
};

DuplicatedFiles.propTypes = {
    setTotal: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        setTotal: total => ({ type: FileListAction.SET_TOTAL, count: total, fileListType: 'duplicated' })
    }
)(DuplicatedFiles);
