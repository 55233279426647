import React from 'react';
import PropTypes from 'prop-types';
import ReqMngHeader from 'presentationals/ReqMng/List/ReqMngHeader';
import ReqMngBody from 'presentationals/ReqMng/List/ReqMngBody';
import Own from '../../utils/DriveConfiguration/Own';

const ReqMng = props => {
    const pageClass = Own;
    const { location } = props;
    const { pathname } = location;
    return (
        <div className="page-drv">
            <ReqMngHeader />
            <ReqMngBody pageClass={pageClass} pathname={pathname} />
        </div>
    );
};

export default ReqMng;

ReqMng.propTypes = {
    location: PropTypes.object.isRequired
};
