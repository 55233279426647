import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../../Common/SummaryConstant';
import SummaryPopups from '../Popup/SummaryPopups';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { getSummarizableExtensionListFromConfigs } from '../../Common/Util/SummaryUtil';

const SummaryOnboarding = ({ intl }) => {
    const configs = useSelector(({ config }) => config.configs);

    const dispatch = useDispatch();

    const copilotName = useMemo(() => {
        const { value } = _.find(configs, ({ id }) => id === 'EFL_COM_COPILOT_NAME') || { value: 'Knox Copilot' };
        return value;
    }, [configs]);

    const extensionList = useMemo(() => {
        const summarizableExtensionList = getSummarizableExtensionListFromConfigs(configs);
        return summarizableExtensionList.join(', ');
    }, [configs]);

    const handleClickRecommendedPrompt = promptTemplateType => {
        dispatch(summaryActions.promptInput.insertPromptTemplate({ promptTemplateType }));
    };

    return (
        <div className="aside-panel-body">
            <div className="ai-container">
                <div className="ai-entry">
                    <div className="ai-copilot-guide">
                        <div className="copilot-guide-title">
                            <div className="copywriting">
                                <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.catchphrase" />
                            </div>
                            <h6>
                                <strong>{copilotName}</strong>
                            </h6>
                        </div>
                        <div className="copilot-guide-detail">
                            <p className="paragraph">
                                <FormattedMessage id="drive.text.copilot.summary.welcome" />
                            </p>
                            <ul className="recommend-prompt">
                                <li>
                                    <div role="button" onClick={() => handleClickRecommendedPrompt(SUMMARY_PROMPT_TEMPLATE_TYPE.single)}>
                                        <strong className="mention">
                                            <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.file" />
                                        </strong>
                                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.ptcont.single" />
                                    </div>
                                </li>
                                <li>
                                    <div role="button" onClick={() => handleClickRecommendedPrompt(SUMMARY_PROMPT_TEMPLATE_TYPE.multiple)}>
                                        <strong className="mention">
                                            <FormattedMessage id="drive.text.copilot.summary.fileOne" />,
                                        </strong>
                                        <strong className="mention">
                                            <FormattedMessage id="drive.text.copilot.summary.fileTwo" />
                                        </strong>
                                        <br />
                                        <FormattedMessage id="drive.text.copilot.summary.ptcont.multiple" />
                                    </div>
                                </li>
                                <li>
                                    <div role="button" onClick={() => handleClickRecommendedPrompt(SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword)}>
                                        <strong className="mention">
                                            <FormattedMessage id="drive.text.copilot.summary.fileOne" />,
                                        </strong>
                                        <strong className="mention">
                                            <FormattedMessage id="drive.text.copilot.summary.fileTwo" />
                                        </strong>
                                        <br />
                                        <FormattedHTMLMessage
                                            tagName="span"
                                            id="drive.text.copilot.summary.ptcont.withKeyword"
                                            values={{ keyword: intl.formatMessage({ id: 'drive.text.copilot.summary.keyword' }) }}
                                        />
                                    </div>
                                </li>
                            </ul>

                            <div className="guide-marker">
                                <FormattedMessage id="drive.guideText.copilot.summary.warning01" />
                            </div>
                            <div className="guide-marker">
                                <FormattedMessage id="drive.guideText.copilot.summary.sup3.sub1" values={{ extensionList }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SummaryPopups />
        </div>
    );
};

SummaryOnboarding.propTypes = {};

SummaryOnboarding.defaultProps = {};

export default injectIntl(SummaryOnboarding);
