import React from 'react';
import PropTypes from 'prop-types';
import DateConverter from '../../../../utils/DateConverter';

const ComplianceDetectedDate = props => {
    const { file: { config: { version: { complianceDetectedDate = '' } = {} } = {} } = {}, cellStyle } = props;
    const value = complianceDetectedDate.length > 0 ? DateConverter.convert(complianceDetectedDate) : '-';
    return (
        <div className="grid-cell files-detectTime-cell" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

ComplianceDetectedDate.defaultProps = {
    cellStyle: null
};

ComplianceDetectedDate.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.object
};

export default ComplianceDetectedDate;
