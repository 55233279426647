import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import ComplianceFilter from './ComplianceFilter';
import ClickOutside from '../presentationals/ClickOutside';
import store from '../../redux/store';
import NumberUtil from '../../utils/NumberUtil';
import QueryUtil from '../../utils/QueryUtil';

const REQUEST_STATE_CODES = ['ALL', 'PROCESSING', 'NONE', 'REJECTED'];

const countStyle = {
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    alignItems: 'center',
    margin: '0 0 0 2px',
    height: '24px',
    fontSize: '.8125rem'
};

class ComplianceSearch extends React.Component {
    constructor(props) {
        super(props);
        const {
            location: { search = '' }
        } = this.props;

        const queryParam = queryString.parse(search);
        const preParams = Object.assign({}, queryParam, {
            complianceRequestStateCodes: QueryUtil.getAllowCodesWithJoin(queryParam.complianceRequestStateCodes, REQUEST_STATE_CODES, 'ALL')
        });

        this.isFilterInnerPopupOpen = false;
        this.state = {
            isFilterOpen: false,
            filterParams: preParams,
            total: null,
            isFiltered: this.isFilteredParam(preParams)
        };

        this.onClickOutside = this.onClickOutside.bind(this);
        this.onClickFilter = this.onClickFilter.bind(this);

        this.clickOutSideRef = React.createRef();

        this.totalUnsubscribe = null;
        this.prevFileListState = null;

        this.queryParam = preParams;
        this.initParams = {
            objtNm: '',
            complianceRequestStateCodes: 'ALL'
        };
    }

    componentDidMount() {
        const { filterParams } = this.state;
        if (Object.keys(filterParams).length > 0) {
            this.onClickOutside();
        }
        this.totalSubscribe();
    }

    componentWillUnmount() {
        if (this.totalUnsubscribe && _.isFunction(this.totalUnsubscribe)) {
            this.totalUnsubscribe();
        }
    }

    totalSubscribe = () => {
        this.totalUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { fileList: fileListState } = storeState;
            if (fileListState === this.prevFileListState) {
                return;
            }
            this.prevFileListState = fileListState;

            const { total } = this.state;
            const { totalCount: newTotal = null } = fileListState;

            if (total !== newTotal) {
                this.setState({ total: newTotal });
            }
        });
    };

    onClickOutside() {
        if (this.isFilterInnerPopupOpen) return;
        const { isFilterOpen } = this.state;
        if (isFilterOpen) {
            this.setState({
                isFilterOpen: false
            });
        }
    }

    openInnerPopup = isOpen => {
        this.isFilterInnerPopupOpen = isOpen;
    };

    onClickFilter(isFilterOpen) {
        const param = Object.assign({}, this.queryParam);
        this.setState({
            isFilterOpen,
            filterParams: param,
            isFiltered: this.isFilteredParam(param)
        });
    }

    isFilteredParam = (param = {}) => {
        let isSelectedAll = false;
        if (param.complianceRequestStateCodes === 'ALL') {
            isSelectedAll = true;
        }
        return (param.objtNm !== undefined && param.objtNm !== '') || !isSelectedAll;
    };

    goSearch = (params = {}) => {
        const { onSearch } = this.props;

        const searchParams = {};
        if (_.isEmpty(params)) {
            Object.assign(searchParams, this.initParams);
        } else {
            Object.assign(searchParams, params);
        }

        this.setState({
            isFiltered: this.isFilteredParam(searchParams),
            filterParams: searchParams
        });

        onSearch(searchParams);
    };

    render() {
        const { intl } = this.props;
        const { isFilterOpen, filterParams, total, isFiltered } = this.state;

        return (
            <div className="func-inner-filter has-overlay-layer">
                <ClickOutside onClickOutside={this.onClickOutside} childrenRefs={[this.clickOutSideRef]}>
                    <div className="header-search" ref={this.clickOutSideRef}>
                        <div title={intl.formatMessage({ id: 'drive.tooltip.search.filter' })}>
                            <a
                                id="quick-search-filter"
                                className={`btn-ic-nor btn-sm ${isFiltered ? 'on' : ''}`}
                                role="button"
                                data-placement="bottom"
                                onClick={() => this.onClickFilter(!isFilterOpen)}
                                data-content={this.dataRendered}>
                                <i className="ic-20-filter" />
                            </a>
                            {total !== null && (
                                <div className="total-count" style={countStyle}>
                                    {isFiltered ? (
                                        <span className="count">
                                            <FormattedMessage id="user.helpcenter.resultCount" values={{ n: <i className="num">{NumberUtil.getNumberWithCommas(total)}</i> }} />
                                        </span>
                                    ) : (
                                        <>
                                            <span>
                                                <FormattedMessage id="com.text.total" />
                                            </span>
                                            <span className="count">
                                                <i className="num">{NumberUtil.getNumberWithCommas(total)}</i>
                                            </span>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {isFilterOpen && (
                            <ComplianceFilter openInnerPopup={this.openInnerPopup} params={filterParams} initParams={this.initParams} search={this.goSearch} close={() => this.onClickFilter(false)} />
                        )}
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

ComplianceSearch.defaultProps = {
    onSearch: () => {}
};

ComplianceSearch.propTypes = {
    intl: PropTypes.object.isRequired,
    onSearch: PropTypes.func,
    location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default withRouter(injectIntl(connect(mapStateToProps)(ComplianceSearch)));
