/* eslint-disable */
import React, {Component} from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {alert, confirm} from '../../../utils/ModalService';
import SocketRest from '../../../apis/SocketRest';
import AuthService from '../../../utils/AuthService';
import Url from "../../../utils/Url";
import HelpRest from '../../../apis/HelpRest';
import DriveWebSocket from '../../../utils/DriveWebSocket';
import _ from 'lodash';
import DriveConfigRest from "../../../apis/DriveConfigRest";
import DriveConfigs from "../../../constants/DriveConfigs";

class Socket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleMsg: null,
            contentMsg: null,
            loading: false
        }
    }

    componentDidMount() {
        // Windows OS에서만 파일 실행 기능 지원
        if (navigator.platform.indexOf("Win") === -1) {
            const osLimitConfig = {
                message: <FormattedMessage id="user.text.helpcenter.manualDownload.unsupportedOS"/>
            };
            alert(osLimitConfig);
            this.props.close();
            return ;
        }

        this.setState({loading: true});
        this.checkType();
        this.process();
    }

    checkType() {
        const {type, intl} = this.props;

        if ('offline' === type) {
            this.setState({contentMsg: intl.formatMessage({ id: 'drive.alert.Socket.LITE_LIST_FnDetail_File_T5' })});
        }
    }

    async process() {
        const {type, objtIdList, intl} = this.props;

        const loginUser = AuthService.getLoginUser();
        let tenantId = loginUser.tenantId;
        let loginId = loginUser.loginId;
        let loginParam = [];

        loginParam.push(tenantId);
        loginParam.push(loginId);
        loginParam.push("Login");
        loginParam.push("NONE");

        if (objtIdList.length === 0) {
            this.setState({loading: false});
            this.props.close();
            alert(intl.formatMessage({ id: 'drive.alert.Socket.noFile' }));
            return;
        }

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        if(this.websocketResultCheck('Login', await DriveWebSocket(loginParam, agentType, 'Login'))) {
            if ('offline' === type) {
                this.callOffline(await SocketRest.getOfflineFilePath({objtIdList}));
            }
        }
    }

    async callOffline(res) {
        let params = [];
        const offlineFileInfos = res.data;
        const loginUser = AuthService.getLoginUser();

        params.push(offlineFileInfos[0].tenantId);
        params.push(loginUser.loginId);
        params.push("Offline");
        for(let i=0; i<offlineFileInfos.length; i++) {
            params.push(`${offlineFileInfos[i].objtId}|${offlineFileInfos[i].fileVerSno}|${offlineFileInfos[i].verClbyStoreSno}|${offlineFileInfos[i].fullPathNm}`);
        }

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        if(this.websocketResultCheck('Offline', await DriveWebSocket(params, agentType, 'Offline'))) {
            console.log('drive websocket call success.(login, Offline)');
        }
    }

    websocketResultCheck(type, code) {
        console.log("websocket type: ", type);
        console.log("websocket result: ", code);
        const { intl } = this.props;

        if(!code) {
            // Websocket 연결이 되어있지 않으면 socket에서 false리턴
            // alert(intl.formatHTMLMessage({ id: 'drive.alert.download.agentSockError' }));
            const connFailMsg = intl.formatMessage({ id: 'drive.alert.Socket.agentSockError' });
            const connFailConfig = {
                message: connFailMsg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                }), btnMessage: { okay: { id: 'drive.button.download.installAgentBtn' } , cancel: { id: 'com.button.ok' } }
            };

            confirm(connFailConfig).then(() => {
                let newPageUrl = Url.agentDown;
                window.open(newPageUrl, '_blank');
            }).then(() => {
                this.props.close();
            });
            this.setState({loading: false});
            this.props.close();
            return false;
        }
        let codeArray = code.split("_");
        let result = codeArray[1];
        //let result = code.substr(code.length-1, 1).toString();

        // console.log("result: ", result);

        /** WebSocket 오류 코드 일람
         *  Websocket 연결 실패(또는 미설치)와 Login_1 오류만 특정하여 메시지 처리하고, 나머지 오류는 함께 처리한다.
         *
         *** Login Websocket Result Code
         * 0 : 정상(공유메모리에 있는 에이전트의 접속 정보와 일치 시)
         * 1 : 비정상(공유메모리에 있는 에이전트의 접속 정보와 일치하지 않을 시)
         * 2 : 비정상(테넌트 ID 혹은 사용자 ID 가 입력되지 않은 경우)
         * 3 : 비정상(로직내 오류발생 시)
         * 9 : 비정상(Agent 미로그인상태 or 미실행 or 미설치)

         *** Offline 모드 추가
         * 0 : 정상
         * 1 : 비정상(K드라이브와 인증정보 맞지 않음)
         * 2 : 비정상(K드라이브 로그오프중)
         * 3 : 비정상(OFFLINE 모듈 실행되지 않음.)
         * 4 : _존재하는파일명 + "|" + 존재하는파일명1 + "|" + .... : 비정상(파일존재함.)
         * 5 : 해당 PC에 Secure Folder 만든 사용자가 아님.
         */
        if(result === '0') {
            if(type === 'Login') {
                return true;
            } else {
                this.setState({loading: false});
                this.props.close();
                alert('drive.alert.Socket.LITE_LIST_FnDetail_File_T5');
                return true;
            }

        } else if(type === 'Login' && result === '1') {
            // 1 : 비정상(공유메모리에 있는 에이전트의 접속 정보와 일치하지 않을 시)
            let msg = intl.formatMessage({ id: 'drive.alert.download.loginAccntDiff' });

            const loginDiffConfig = {
                message: msg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                })
            };
            this.setState({loading: false});
            this.props.close();
            alert(loginDiffConfig);

        } else if(type === 'Login' && result === '9') {
            // 9 : 비정상(Agent 미로그인상태 or 미실행 or 미설치)
            let msg = intl.formatMessage({ id: 'drive.alert.download.agentLoginCheckFail' });

            const loginFailConfig = {
                message: msg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                })
            };
            this.setState({loading: false});
            this.props.close();
            alert(loginFailConfig);

        } else if (type === 'Offline' && result === '3') {
            const connFailMsg = intl.formatMessage({ id: 'drive.alert.Socket.secureSocketError' });
            const connFailConfig = {
                message: connFailMsg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                }), btnMessage: { okay: { id: 'drive.button.download.installAgentBtn' } , cancel: { id: 'com.button.ok' } }
            };

            confirm(connFailConfig).then(() => {
                let newPageUrl = Url.agentDown;
                window.open(newPageUrl, '_blank');
            }).then(() => {
                this.props.close();
            });
            this.setState({loading: false});
            this.props.close();
            return false;

        } else if (type === 'Offline' && result === '4') {
            let msg = intl.formatMessage({ id: 'drive.alert.Socket.alreadyExist' });
            let fileStr = code.substr(10, code.length);
            fileStr = _.replace(fileStr, new RegExp('[|]', 'g'), '\\n');
            msg += fileStr;

            const msgConfig = {
                message: msg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                })
            };

            this.setState({loading: false});
            this.props.close();
            alert(msgConfig);

        } else if (type === 'Offline' && result === '5') {
            let msg = intl.formatMessage({ id: 'drive.alert.notEqualCreateUser' });
            let fileStr = code.substr(10, code.length);
            fileStr = _.replace(fileStr, new RegExp('[|]', 'g'), '\\n');
            msg += fileStr;

            const msgConfig = {
                message: msg.split('\\n').map(line => {
                    return (<span>{line}<br/></span>)
                })
            };

            this.setState({loading: false});
            this.props.close();
            alert(msgConfig);

        } else {

            this.setState({loading: false});
            // 기타 오류 발생시
            HelpRest.getHelpBasicInfo().then((data) => {
                if(!!data) {
                    const helpCntrNum = data.EFL_COM_HELPCENTER_TEL;
                    let msg = intl.formatMessage({ id: 'drive.alert.Socket.fail'});
                    msg = msg.replace('{helpCntrNum}', helpCntrNum);

                    const fileOpenCommConfig = {
                        message: msg.split('\\n').map(line => {
                            return (<span>{line}<br/></span>)
                        })
                    };

                    alert(fileOpenCommConfig).then(() => {
                        this.props.close();
                    });
                }
            })
        }
    }


    render() {
        const {loading} = this.state;
        return (
            <React.Fragment>
                {loading &&
                <div className="loadingSpinner">
                    <div className="dy-progress-page">
                        <div className="spinner"/>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    };

}

export default injectIntl(Socket);