import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import InstantSearchOnlyOneInput from 'containers/InstantSearch/InstantSearchOnlyOneInput';
import SingleSelectPopup from 'containers/AdvancedSearch/SingleSelectPopup';

const sanitize = draft => {
    draft.id = draft.userId;
    draft.name = draft.userLangCdNm;
    draft.type = 'user';
    return draft;
};
const sanitizeForDisplay = item => ({
    name: item.userLangCdNm || '',
    userCopNm: item.userLangCdCopNm || '',
    deptNm: item.deptLangCdNm || ''
});

const OneUserSearcherInput = props => {
    const { select, remove, intl, placeholder } = props;
    const [isOpenAdvanced, setAdvanced] = useState(false);
    const [target, setTarget] = useState(); // 상세검색에서 선택되었을 경우, 상세검색 inputbox에 값 업데이트해주기위한 상태

    const closeAdvanced = item => {
        if (!_.isEmpty(item)) {
            select(sanitize(item));
            setTarget(sanitizeForDisplay(item));
        }
        setAdvanced(false);
    };

    return (
        <>
            <div className="forms-container">
                <InstantSearchOnlyOneInput target="instant-searcher" select={select} remove={remove} targetFromParent={target} targetTypes="user" placeholder={placeholder} />
                <a className="btn-ic-line" role="button" title={intl.formatMessage({ id: 'com.search' })} onClick={() => setAdvanced(true)}>
                    <i className="ic-20-search-people" />
                </a>
            </div>
            <SingleSelectPopup show={isOpenAdvanced} close={closeAdvanced} options={{ tab: { user: true, dept: false } }} />
        </>
    );
};

OneUserSearcherInput.propTypes = {
    select: PropTypes.func,
    remove: PropTypes.func,
    placeholder: PropTypes.string
};

OneUserSearcherInput.defaultProps = {
    select: _.noop,
    remove: _.noop,
    placeholder: undefined
};

export default injectIntl(OneUserSearcherInput);
