import React from 'react';
import BaseMenu from '../BaseMenu';

class Divider extends BaseMenu {
    isDivider() {
        return true;
    }

    isVisible = () => {
        return true;
    };

    isEnable = () => {
        return true;
    };

    getDom = key => {
        return <div key={key} className="divider" />;
    };

    execute = () => {};
}

export default Divider;
