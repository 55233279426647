import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import SearchUsers from './SearchUsers';
import SearchDepts from './SearchDepts';
import { axiosInstance } from '../../../modules/index';

class MultiSelectPopup extends Component {
    constructor(prop) {
        super(prop);
        this.state = { selected: [], selectedAllChecked: false, searchType: 'USER' };
        this.cancelToken = axiosInstance.CancelToken.source();
    }

    componentDidUpdate(prevProps, prevState) {
        const { show: prevShow = false } = prevState;
        const { show: nowShow = false } = this.state;
        if (!prevShow && nowShow) {
            this.cancelToken = axiosInstance.CancelToken.source();
        }
    }

    componentWillUnmount() {
        if (this.cancelToken.cancel) {
            this.cancelToken.cancel();
        }
    }

    handleClose = () => {
        const { close } = this.props;
        if (this.cancelToken.cancel) {
            this.cancelToken.cancel();
        }
        close({});
    };

    clickOkayButton = () => {
        const { close } = this.props;
        const { selected } = this.state;
        close(selected);
    };

    addTarget = newItems => {
        if (!_.isEmpty(newItems)) {
            const { selected } = this.state;

            const added = [...selected, ...newItems];

            let sanitized = [];
            if (newItems[0].type === 'USER') {
                const users = _.filter(added, { type: 'USER' });
                const depts = _.filter(added, { type: 'DEPT' });
                const sanitizedUser = _.uniqBy(users, 'userId');
                sanitized = [...sanitizedUser, ...depts];
            }
            if (newItems[0].type === 'DEPT') {
                const users = _.filter(added, { type: 'USER' });
                const depts = _.filter(added, { type: 'DEPT' });
                const sanitizedDepts = _.uniqBy(depts, 'id');
                sanitized = [...users, ...sanitizedDepts];
            }

            this.setState({ selected: sanitized });
            this.setState({ selectedTotal: sanitized.length });
            this.setState({ selectedAllChecked: false });
        }
    };

    deleteTargetUser = userId => {
        const { selected, selectedAllChecked } = this.state;
        const removed = _.filter(selected, item => item.userId !== userId);

        this.setState({ selected: removed });
        this.setState({ selectedTotal: removed.length });
        this.setState({ selectedAllChecked: selectedAllChecked && removed.length > 0 });
    };

    deleteTargetDept = deptId => {
        const { selected, selectedAllChecked } = this.state;
        const removed = _.filter(selected, item => item.id !== deptId);

        this.setState({ selected: removed });
        this.setState({ selectedTotal: removed.length });
        this.setState({ selectedAllChecked: selectedAllChecked && removed.length > 0 });
    };

    deleteTargets = items => {
        const { selected } = this.state;
        let removed = selected;
        _.forEach(items, item => {
            removed = _.pull(removed, item);
        });
        this.setState({ selectedAllChecked: false, selected: removed, selectedTotal: removed.length });
    };

    checkSelectedUser = (index, checked, aleadySelected) => {
        const { selected } = this.state;
        const selectedForCheck = _.cloneDeep(selected);
        selectedForCheck[index].checked = checked;
        if (aleadySelected) selectedForCheck[index].isSelected = checked;
        const forRemovingAllChecked = _.filter(selectedForCheck, { checked: true });
        this.setState({ selected: selectedForCheck, selectedAllChecked: forRemovingAllChecked.length === selectedForCheck.length });
    };

    handleCheckboxAll = (checked, aleadySelected) => {
        const { selected } = this.state;

        const pickedList = _.map(selected, item => {
            const picked = _.cloneDeep(item);
            picked.checked = checked;
            if (aleadySelected) picked.isSelected = checked;
            return picked;
        });

        this.setState({ selectedAllChecked: checked, selected: pickedList });
    };

    onSelectUserTab = () => {
        this.setState({ searchType: 'USER' });
    };

    onSelectDeptTab = () => {
        this.setState({ searchType: 'DEPT' });
    };

    render() {
        const { show, user, languageCode } = this.props;
        const { selected, selectedTotal, selectedAllChecked, searchType } = this.state;
        return (
            <If condition={show}>
                <div className="modal-header">
                    <nav>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className={`nav-link ${searchType === 'USER' ? 'active' : ''}`} role="button" onClick={this.onSelectUserTab}>
                                    <FormattedMessage id="user.tab.popup.selectPeople.searchPerson" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${searchType === 'DEPT' ? 'active' : ''}`} role="button" onClick={this.onSelectDeptTab}>
                                    <FormattedMessage id="user.tab.popup.selectPeople.searchDepartment" />
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <FormattedMessage id="com.close">
                        {title => (
                            <a className="btn-ic-nor" role="button" title={title} data-dismiss="modal" onClick={this.handleClose}>
                                <i className="ic-20-close" />
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <div className="modal-body">
                    <If condition={searchType === 'USER'}>
                        <SearchUsers
                            handleSelect={this.handleSelect}
                            addTarget={this.addTarget}
                            selected={selected}
                            selectedTotal={selectedTotal}
                            deleteTargetUser={this.deleteTargetUser}
                            deleteTargetDept={this.deleteTargetDept}
                            deleteTargets={this.deleteTargets}
                            checkSelectedUser={this.checkSelectedUser}
                            handleCheckboxAll={this.handleCheckboxAll}
                            selectedAllChecked={selectedAllChecked}
                            user={user}
                            languageCode={languageCode}
                            deleteUserIds={this.deleteUserIds}
                            cancelToken={this.cancelToken}
                        />
                    </If>
                    <If condition={searchType === 'DEPT'}>
                        <SearchDepts
                            handleSelect={this.handleSelect}
                            addTarget={this.addTarget}
                            selected={selected}
                            selectedTotal={selectedTotal}
                            deleteTargetDept={this.deleteTargetDept}
                            deleteTargetUser={this.deleteTargetUser}
                            deleteTargets={this.deleteTargets}
                            checkSelectedUser={this.checkSelectedUser}
                            handleCheckboxAll={this.handleCheckboxAll}
                            selectedAllChecked={selectedAllChecked}
                            user={user}
                            languageCode={languageCode}
                            cancelToken={this.cancelToken}
                        />
                    </If>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={this.handleClose}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className="btn btn-lg btn-primary" role="button" onClick={this.clickOkayButton}>
                            <span className="btn-text">
                                <FormattedMessage id="com.check" />
                            </span>
                        </a>
                    </div>
                </div>
            </If>
        );
    }
}

MultiSelectPopup.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    user: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired
};

MultiSelectPopup.defaultProps = {
    show: false,
    close: _.noop
};

const mapStateToProps = state => ({
    user: state.auth.user,
    languageCode: state.auth.user.locale.languageCode
});

export default connect(
    mapStateToProps,
    null
)(MultiSelectPopup);
