import React, { Component } from 'react';
import { func, object, string, number } from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';
import CustomModal from '../CustomModal';

const DEFAULT_MAX_COUNT_KEYWORDS = 30;

class ComplianceKeywordsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    closePopup() {
        const { close } = this.props;
        close();
    }

    getMaxCount() {
        const { maxCount } = this.props;
        return maxCount == null ? DEFAULT_MAX_COUNT_KEYWORDS : maxCount;
    }

    render() {
        const { intl, keywords } = this.props;
        const slicedKeywords = _.chain(keywords)
            .split(',')
            .slice(0, this.getMaxCount())
            .join(', ')
            .value();

        return (
            <CustomModal isOpen={true} fade={true} backdrop="static" className="modal-sm">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.prohibitWordDetail" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => this.closePopup()}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <ModalBody>
                    <div className="prohibitWord-detail">{slicedKeywords}</div>
                    <div className="info-msg">
                        <i className="ic-16-info" />
                        <FormattedMessage id="drive.text.prohibitWordDetail.maxCount" values={{ n: this.getMaxCount() }} />
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => this.closePopup()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </div>
                </ModalFooter>
            </CustomModal>
        );
    }
}

ComplianceKeywordsPopup.propTypes = {
    close: func,
    intl: object,
    keywords: string,
    maxCount: number
};

ComplianceKeywordsPopup.defaultProps = {
    close: () => {},
    intl: {},
    keywords: '',
    maxCount: DEFAULT_MAX_COUNT_KEYWORDS
};
export default injectIntl(ComplianceKeywordsPopup);
