/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import BaseApproval from './BaseApproval';
import FolderRest from '../../../../../apis/FolderRest';
import ApprovalConstants from '../ApprovalConstants';
import ApprovalUtil from '../ApprovalUtil';
import { alert as modalAlert, toast } from '../../../../../utils/ModalService';
import { FOLDER } from 'constants/ResultCode';

class CreateWorkgroup extends BaseApproval {
    type = ApprovalConstants.TYPE.CREATE_WORKGROUP;

    title = 'drive.title.approval.createWorkgroup';

    isThrowResult = true;

    async getApproverCandidates() {
        const response = await FolderRest.getRequestApproverCandidates('CREATE_WORKGROUP');

        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return data;
    }

    async getCandidateLine() {
        const candidates = await this.getApproverCandidates();
        return ApprovalUtil.convertCandidatesToLine(candidates);
    }

    contentBuilder() {
        const workgroupName = this.isViewMode() && this.target.length ? this.target[0].objtNm : this.target.workgroupName;
        const allocUseSize = this.isViewMode() && this.detail.reqDtl ? this.detail.reqDtl.allocUseSize : this.target.allocUseSize;

        return (
            <div style={{ margin: '0', padding: '0', width: '672px', boxSizing: 'content-box' }}>
                <table cellSpacing="0" cellPadding="0" border="0" style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'top', height: '48px' }}>
                                <h2 style={{
                                    margin: '13px 0 0',
                                    padding: 0,
                                    fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: '#1a1a1a',
                                    lineHeight: '20px',
                                    textAlign: 'center',
                                    wordBreak: 'break-all',
                                    verticalAlign: 'top'
                                }}>
                                    <FormattedMessage id="drive.title.approval.createWorkgroup" />
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ background: '#000', height: '2px' }} />
                        </tr>
                        <tr>
                            <td>
                                <table cellSpacing="0" cellPadding="0" border="0" style={{ width: '100%' }}>
                                    <tbody>
                                    <tr>
                                        <th style={{
                                            width: '50px',
                                            padding: '12px 20px',
                                            fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                            fontSize: '13px',
                                            color: '#666',
                                            lineHeight: '22px',
                                            textAlign: 'left',
                                            background: '#fafafa',
                                            wordBreak: 'break-all',
                                            verticalAlign: 'top',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <FormattedMessage id="drive.text.approval.createWorkgroup.name" />
                                        </th>
                                        <td style={{
                                            padding: '12px 20px',
                                            fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                            fontSize: '13px',
                                            color: '#1a1a1a',
                                            lineHeight: '22px',
                                            textAlign: 'left',
                                            wordBreak: 'break-all',
                                            verticalAlign: 'top'
                                        }}>
                                            {workgroupName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{
                                            width: '50px',
                                            padding: '12px 20px',
                                            fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                            fontSize: '13px',
                                            color: '#666',
                                            lineHeight: '22px',
                                            textAlign: 'left',
                                            background: '#fafafa',
                                            wordBreak: 'break-all',
                                            verticalAlign: 'top',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <FormattedMessage id="com.text.workgroup.usage" />
                                        </th>
                                        <td style={{
                                            padding: '12px 20px',
                                            fontFamily: "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif",
                                            fontSize: '13px',
                                            color: '#1a1a1a',
                                            lineHeight: '22px',
                                            textAlign: 'left',
                                            wordBreak: 'break-all',
                                            verticalAlign: 'top'
                                        }}>
                                            {allocUseSize}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ background: '#000', height: '2px' }} />
                        </tr>
                        <tr>
                            <td style={{ height: '13px' }} />
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    submitAction() {
        const targetUsers = ApprovalUtil.convertLineToTargetUsers(this.getLine());
        const params = {
            approvers: targetUsers,
            workgroupName: this.target.workgroupName,
            allocUseSize: this.target.allocUseSize,
            requestReason: this.draftOpinion
        };

        return FolderRest.createFolderWorkgroupRequest(params);
    }

    processResultMessage(response = {}) {
        const { resultCode, data } = response;
        if (resultCode === FOLDER.EVENT_COMMON_PROCESS_WORKGROUPS_DUP_NAME) {
            modalAlert('com.alert.already.existed.name');
        } else if (resultCode === FOLDER.REQ_NOT_SUPPORT_FOLDER_REQUEST_TYPE_CODE) {
            modalAlert('com.alert.over.usage');
        } else if (resultCode === FOLDER.REQ_INVALID_APPROVER) {
            modalAlert('com.alert.failed');
        } else if (resultCode === FOLDER.NOT_FOUND_DEPARTMENT_LEADER) {
            modalAlert('drive.alert.failGetDepartmentLeader');
        } else if (resultCode === FOLDER.NOT_FOUND_DEPARTMENT_INFO) {
            modalAlert('drive.alert.failGetDepartmentInfo');
        }
        modalAlert(data);
        // toast({ id: 'com.alert.unexpected-error', values: { code: resultCode } });
        return true;
    }
}

export default CreateWorkgroup;
