import React, { memo, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import classNames from 'classnames';
import ClickOutside from '../../../../presentationals/ClickOutside';
import LLMSelectButton from './LLMSelectButton';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { SUMMARY_THREAD_STATUS } from '../../Common/SummaryConstant';

const SidePanelHeader = memo(({ intl }) => {
    const moreContentMenuRef = useRef();

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

    const llmList = useSelector(({ summary }) => summary.global.llmList);
    const configs = useSelector(({ config }) => config.configs);
    const summaryMessages = useSelector(({ summary }) => summary.thread.summaryMessages);
    const threadStatus = useSelector(({ summary }) => summary.thread.threadStatus);

    const dispatch = useDispatch();

    const { isChatbotUsable, chatbotId } = useMemo(() => {
        const { value: copilotChatbotYn } = _.find(configs, ['id', 'EFL_COM_COPILOT_CHATBOT_YN']) || {};
        const { value: copilotChatbotId } = _.find(configs, ['id', 'EFL_COM_COPILOT_CHATBOT_ID']) || {};

        return {
            isChatbotUsable: copilotChatbotYn === 'Y' && !_.isEmpty(copilotChatbotId),
            chatbotId
        };
    }, [configs]);

    const handleClickChatbotButton = () => {
        window.open(`knoxim://ids=${chatbotId}`, '_self');
    };

    const handleClickMoreButton = () => {
        setIsContextMenuOpen(prev => !prev);
    };

    const handleClickOutsideMoreButton = () => {
        setIsContextMenuOpen(false);
    };

    const handleClickCloseButton = () => {
        dispatch(summaryActions.sidePanel.close());
        dispatch(summaryActions.optionSelectDialog.close());
    };

    const handleClickGuideMenu = () => {
        setIsContextMenuOpen(false);
        dispatch(summaryActions.guideModal.open());
    };

    const handleClickClearMessagesMenu = () => {
        if (_.isEmpty(summaryMessages)) {
            return;
        }

        setIsContextMenuOpen(false);
        dispatch(summaryActions.threadClearConfirmDialog.open());
    };

    return (
        <div className="aside-panel-header">
            <h5 className="aside-panel-title">
                <FormattedMessage id="drive.text.copilot.summary.title" />
            </h5>
            <If condition={llmList.length >= 2}>
                <LLMSelectButton />
            </If>
            <div className="aside-panel-header-btns">
                <ul>
                    <If condition={isChatbotUsable}>
                        <li>
                            <a className="btn-ic-radius" role="button" title={intl.formatMessage({ id: 'drive.title.copilot.summary.bot' })} onClick={handleClickChatbotButton}>
                                <i className="ic-20-copilot-chatbot" />
                            </a>
                        </li>
                    </If>
                    <ClickOutside onClickOutside={handleClickOutsideMoreButton} childrenRefs={[moreContentMenuRef]}>
                        <li ref={moreContentMenuRef}>
                            <div className="dropdown">
                                <a className="btn-ic-radius dropdown-toggle" role="button" title="더보기" onClick={handleClickMoreButton}>
                                    <i className="ic-20-copilot-more" />
                                </a>
                                <div
                                    className={classNames('dropdown-menu', isContextMenuOpen && 'show')}
                                    style={
                                        isContextMenuOpen
                                            ? {
                                                  position: 'absolute',
                                                  willChange: 'transform',
                                                  top: '0px',
                                                  left: '0px',
                                                  transform: 'translate3d(-24px, 20px, 0px)'
                                              }
                                            : {}
                                    }>
                                    <a className="dropdown-item" role="button" onClick={handleClickGuideMenu}>
                                        <FormattedMessage id="drive.text.copilot.summary.usage_guide" />
                                    </a>
                                    <a
                                        className={classNames('dropdown-item', (_.isEmpty(summaryMessages) || threadStatus === SUMMARY_THREAD_STATUS.running) && 'disabled')}
                                        role="button"
                                        onClick={handleClickClearMessagesMenu}>
                                        <span className="item-point-color">
                                            <FormattedMessage id="drive.text.copilot.summary.clearMessages" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ClickOutside>
                    <li>
                        <a className="btn-ic-radius" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close-sm" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
});

export default injectIntl(SidePanelHeader);
