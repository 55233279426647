import React, { useEffect, useRef } from 'react';
import { If } from 'jsx-control-statements';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CustomModal from 'containers/CustomModal';
import HelpQuestionFileDropZone from '../containers/HelpQuestionFileDropZone';
import CriticalItemDt from './Row/CriticalItemDt';

const HelpQuestion = ({
    isOpen,
    fromEmail,
    toEmail,
    ccEmail,
    title,
    content,
    valid,
    isExternalSite,
    onClickCancelButton,
    onClickConfirmButton,
    onChangeTitle,
    onChangeContent,
    onBlurTitleInput,
    onChangeFile,
    onDropFile,
    files,
    onClickRemoveFile,
    intl,
    setButtonInputRef,
    setDropInputRef
}) => {
    const titleInputEl = useRef(null);

    useEffect(() => {
        if (titleInputEl.current) titleInputEl.current.focus();
    }, []);

    return (
        <CustomModal id="HelpQuestionPopup" isOpen={isOpen} fade={false} size="lg" backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.helpcenter.oneToOneInquiry" />
                </h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={onClickCancelButton}>
                    <i className="ic-20-close" />
                </a>
            </div>
            <div className="modal-body">
                <div className="dt-width-120 sr-inputOnly">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.helpcenter.recipient" />
                        </dt>
                        <dd>
                            <div className="box-input-group readonly">
                                <input className="form-control" type="text" placeholder="" value={toEmail} readOnly={true} />
                                <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })}>
                                    <i className="ic-16-clear" />
                                </a>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.helpcenter.cc" />
                        </dt>
                        <dd>
                            <div className="box-input-group readonly">
                                <input className="form-control" type="text" placeholder="" value={ccEmail} readOnly={true} />
                                <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })}>
                                    <i className="ic-16-clear" />
                                </a>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.helpcenter.sent" />
                        </dt>
                        <dd>
                            <div className="box-input-group readonly">
                                <input className="form-control" type="text" placeholder="" value={fromEmail} readOnly={true} />
                                <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })}>
                                    <i className="ic-16-clear" />
                                </a>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="dt-width-120">
                    <dl className="row-list">
                        <dt>
                            <CriticalItemDt text={intl.formatMessage({ id: 'user.helpcenter.title' })} />
                        </dt>
                        <dd>
                            <div className="input-group-remark">
                                <div className={valid ? 'box-input-group' : 'box-input-group is-invalid'}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'user.placeHolder.helpcenter.title.isRequired' })}
                                        ref={titleInputEl}
                                        onBlur={onBlurTitleInput}
                                        value={title}
                                        onChange={onChangeTitle}
                                        maxLength={100}
                                    />
                                    <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>

                                <div className="input-remark">
                                    {!valid && (
                                        <div className="err-msg">
                                            <FormattedMessage id="user.helpcenter.requiredField" />
                                        </div>
                                    )}
                                    <div className="write-count">
                                        <strong className="count-num">{title.length}</strong>/<span className="write-total">100</span>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="form-group">
                    <textarea className="form-control textarea-lg" value={content} onChange={onChangeContent} />
                </div>
                <If condition={!isExternalSite}>
                    <div className="comm-title-bar">
                        <h5 className="article-title width-120">
                            <FormattedMessage id="user.helpcenter.attachedFile" />
                        </h5>
                        <span className="file-upload">
                            <input type="file" className="upload" ref={setButtonInputRef} onChange={onChangeFile} multiple={true} />
                            <a className="btn btn-secondary" role="button">
                                <span className="btn-text">
                                    <FormattedMessage id="user.helpcenter.addFile" />
                                </span>
                            </a>
                        </span>
                    </div>
                    <div className="box-line update-zone">
                        <If condition={!_.isEmpty(files)}>
                            {files.map(file => (
                                <div key={file.uniqueKey} className="inner-grid-view">
                                    <div className="grid-cell upload-item-cell">
                                        <div className="item-file">
                                            <i className={file.extClassName} />
                                            <div className="file" title="">
                                                <span className="file-name">{file.name}</span>
                                                <span className="file-extension">{file.extension}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-cell upload-size-cell">
                                        <span className="size">{file.formattedSize}</span>
                                    </div>
                                    <div className="grid-cell upload-del-cell">
                                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.delete' })} onClick={() => onClickRemoveFile(file.uniqueKey)}>
                                            <i className="ic-20-close-sm" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </If>
                        <If condition={_.isEmpty(files)}>
                            <div className="box-empty">
                                <div className="message">
                                    <span className="ic-20-upload" />
                                    <em className="infix-lg">
                                        <FormattedMessage id="user.helpcenter.guide.dragForUpload" />
                                    </em>
                                    <em className="infix-xs">
                                        <FormattedMessage id="user.guideText.helpcenter.select.upload.file" />
                                    </em>
                                </div>
                                <span className="file-upload">
                                    <input type="file" className="upload" ref={setDropInputRef} onChange={onChangeFile} multiple={true} />
                                </span>
                            </div>
                        </If>
                        <HelpQuestionFileDropZone onDrop={onDropFile} />
                    </div>
                </If>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClickCancelButton}>
                        <span className="btn-text">
                            <FormattedMessage id="com.cancel" />
                        </span>
                    </a>
                    <a className={!_.isEmpty(title) ? 'btn btn-lg btn-primary' : 'btn btn-lg btn-primary disabled'} role="button" onClick={onClickConfirmButton}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

HelpQuestion.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fromEmail: PropTypes.string.isRequired,
    toEmail: PropTypes.string.isRequired,
    ccEmail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    isExternalSite: PropTypes.bool.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickConfirmButton: PropTypes.func.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    onChangeContent: PropTypes.func.isRequired,
    onBlurTitleInput: PropTypes.func.isRequired,
    onChangeFile: PropTypes.func.isRequired,
    onDropFile: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    onClickRemoveFile: PropTypes.func.isRequired,
    setButtonInputRef: PropTypes.func.isRequired,
    setDropInputRef: PropTypes.func.isRequired
};

export default injectIntl(HelpQuestion);
