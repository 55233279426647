import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

class ClickOutside extends React.Component {
    constructor(props) {
        super(props);
        this.wrap = React.createRef();
        this.onClickOutside = this.onClickOutside.bind(this);
        this.setWrap = this.setWrap.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutside, true);
    }

    onClickOutside(e) {
        const { childrenRefs, onClickOutside, childrenClassNames } = this.props;
        const isNotChildrenRef = childrenRefs.every(ref => {
            if (ref && ref.current && !ref.current.contains(e.target)) {
                return true;
            }
            return false;
        });
        // TODO 106-1에는 element에 class 2개 이상 나열된 경우도 처리
        // TODO className으로 element를 식별하는 방식은 좋지 않음 -> 더 나은 해결책이 필요
        const isNotChildrenClassName = !_.includes(childrenClassNames, e.target.className);

        if (isNotChildrenRef && isNotChildrenClassName) {
            onClickOutside(e);
        }
    }

    setWrap(ref) {
        this.wrap = ref;
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

ClickOutside.defaultProps = {
    onClickOutside: () => {},
    childrenClassNames: []
};

ClickOutside.propTypes = {
    onClickOutside: PropTypes.func,
    children: PropTypes.node.isRequired,
    childrenRefs: PropTypes.array.isRequired,
    childrenClassNames: PropTypes.array
};

export default ClickOutside;
