import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SiteAction from '../../redux/actions/Site';
import WhiteLabelingRest from '../../apis/WhiteLabelingRest';

const staticPath = '/static/images/';
const CFG_ID_EXTERNAL_IMG_USE_YN = 'EFL_AUT_EXTERNAL_LOGO_IMG_USE_YN';
const CFG_ID_LOGO_IMAGE_NAME = 'EFL_AUT_HOME_LOGO_IMG_NM';
const DEFAULT_IMAGE_NAME = 'logo_knox_drive.svg';

function importAll(resources) {
    const images = {};
    resources.keys().forEach(item => {
        images[item.replace('./', '')] = resources(item);
    });
    return images;
}

const WhiteLabelLogo = props => {
    const { setLogo } = props;
    const images = importAll(require.context('../../../static/img/whiteLabeling/comm', false, /\.(png|jpe?g|svg)$/));

    const fetchLogo = async () => {
        const imgCfgMap = await WhiteLabelingRest.getHomeLogoImgNm();

        // 파일명
        const imageName = _.get(imgCfgMap, CFG_ID_LOGO_IMAGE_NAME);
        if (!imageName) {
            setLogo(images[DEFAULT_IMAGE_NAME]);
            return;
        }

        // 외부 이미지 사용 여부
        const externalYn = _.get(imgCfgMap, CFG_ID_EXTERNAL_IMG_USE_YN);

        // 이미지 설정
        const logoImage = externalYn === 'Y' ? staticPath + imageName : images[imageName];
        setLogo(logoImage);
    };

    useEffect(() => {
        fetchLogo();
    }, []);

    return <></>;
};

WhiteLabelLogo.propTypes = {
    setLogo: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        setLogo: logo => ({ type: SiteAction.SET_LOGO, logo })
    }
)(WhiteLabelLogo);
