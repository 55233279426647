import HelpRest from 'apis/HelpRest';
import SettingsRest from 'apis/SettingsRest';
import UserRest from 'apis/UserRest';
import _ from 'lodash';
import { LICENSE_EXCEPTION_CODES_WITH_MESSAGES } from 'modules/Http';
import ReqPartnerAccountPopup from 'presentationals/ReqPartnerAccountPopup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ConfigsRest from 'apis/ConfigsRest';
import { globalPopup as globalPopupActions, partnerAccount as partnerAccountActions, toast as toastActions } from 'src/redux/actions';
import store from '../../redux/store';
import ReqRest from '../../apis/ReqRest';

class ReqPartnerAccountPopupContainer extends Component {
    state = {
        languageOptions: [],
        useExpireYn: 'Y',
        lightDriveStorageAllocSize: 5,
        mailDriveStorageAllocSize: 5,
        useAgentPcControl: 'N',
        helpNum: ''
    };

    componentDidMount() {
        this.getLanguageOptions();
        ConfigsRest.getConfig('EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION').then(response => {
            const { cfgVal } = response;
            this.setState({ useExpireYn: cfgVal });
        });

        ConfigsRest.getConfig('EFL_AUTH_USER_STORAGE_DEFAULT_L_DRIV_ALLOC_SIZE').then(response => {
            const { cfgVal } = response;
            this.setState({ lightDriveStorageAllocSize: Number(cfgVal) });
        });
        ConfigsRest.getConfig('EFL_AUTH_USER_STORAGE_DEFAULT_M_DRIV_ALLOC_SIZE').then(response => {
            const { cfgVal } = response;
            this.setState({ mailDriveStorageAllocSize: Number(cfgVal) });
        });

        ConfigsRest.getDriveConfig('EFL_DRV_AGENT_PC_CTRL_EXCEPT_USE_YN').then(response => {
            const { cfgVal } = response;
            this.setState({ useAgentPcControl: cfgVal });
        });

        HelpRest.getHelpBasicInfo().then(data => {
            const { EFL_COM_HELPCENTER_TEL: tel } = data;
            this.setState({ helpNum: tel });
        });

        this.reqYn = 'Y'; // 기획 요건으로 무조건 요청 처리
        // ConfigsRest.getConfig('EFL_AUT_PARTNER_ACCOUNT_REQ_YN').then(response => {
        //     const { cfgVal } = response;
        //     this.reqYn = cfgVal;
        // });

        const storeState = store.getState();
        const {
            auth: {
                user: { id }
            }
        } = storeState;

        this.userId = id;
        UserRest.retrieveUserApprover().then(response => {
            const { data } = response;
            this.approver = data || {};
        });
    }

    getLanguageOptions = async () => {
        const languageOptions = await SettingsRest.getLanguageOptions();
        languageOptions.forEach(draft => {
            draft.value = draft.code;
            draft.message = draft.name;
        });

        this.setState({ languageOptions });
    };

    onClickRegisterType = isSingle => {
        const { resetPartnerList, setRegisterType } = this.props;
        const registerType = isSingle ? 'single' : 'multi';
        setRegisterType(registerType);
        if (registerType === 'multi') resetPartnerList();
    };

    onClickCancelButton = () => {
        const { closePopup } = this.props;
        closePopup();
    };

    onClickConfirmButton = () => {
        const { openConfirm } = this.props;
        if (this.reqYn === 'Y') {
            if (this.userId !== this.approver.userId) {
                openConfirm(<FormattedHTMLMessage id="user.confirm.ReqPartner.req-confirm" tagName="p" values={{ name: this.approver.userLangCdNm }} />, e => {
                    if (e) this.createPartnerAccount('user.toast.reqPartnerAccount.req-confirm');
                });
            } else {
                this.createPartnerAccount('user.toast.reqPartnerAccount.req-confirm');
            }
        } else {
            this.createPartnerAccount('user.toast.popup.reqPartnerAccount.accountRegistered');
        }
    };

    createPartnerAccount = async toastMsgKey => {
        const { partnerList, closePopup, openAlert, showToast, tenantId, intl } = this.props;
        const { useExpireYn, lightDriveStorageAllocSize, mailDriveStorageAllocSize } = this.state;

        const partnerIds = partnerList.map(partner => partner.id);
        const distinctPartnerIds = _.uniq(partnerIds);
        if (partnerIds.length !== distinctPartnerIds.length) {
            openAlert(intl.formatMessage({ id: 'user.alert.ReqPartnerBundle.duplicatedEmailExists' }));
            return;
        }

        const partnerListMergeDdata = partnerList.map(partner => {
            const temp = _.cloneDeep(partner);
            temp.lightDriveStorageAllocSize = lightDriveStorageAllocSize;
            temp.mailDriveStorageAllocSize = mailDriveStorageAllocSize;
            return temp;
        });
        const response = await HelpRest.reqPartnerAccount(partnerListMergeDdata, tenantId, useExpireYn);
        const { resultCode, data } = response;
        if (resultCode === 200) {
            if (this.userId === this.approver.userId) {
                const reqPrcsList = [data.reqId];
                await ReqRest.updateReqProcess({ reqPrcsType: 'APPROVAL', reqPrcsList });
                showToast(<FormattedMessage id="user.toast.popup.reqPartnerAccount.accountRegistered" />);
            } else {
                showToast(<FormattedHTMLMessage id={toastMsgKey} tagName="p" />);
            }
            closePopup();
        } else if (resultCode === 10512) {
            const { helpNum } = this.state;
            openAlert({ id: 'com.alert.req.approver.invalid', values: { number: helpNum } });
        } else if (_.isNil(LICENSE_EXCEPTION_CODES_WITH_MESSAGES[resultCode])) {
            openAlert({ id: 'com.alert.unexpected-error', values: { code: `${response.resultCode} / ${response.message}` } });
        }
    };

    addPartner = () => {
        const { addPartner } = this.props;
        addPartner();
    };

    render() {
        const { isOpen, partnerList, isSaveAble, registerType } = this.props;
        const { languageOptions, useExpireYn, useAgentPcControl } = this.state;
        return (
            <ReqPartnerAccountPopup
                registerType={registerType}
                onClickRegisterType={this.onClickRegisterType}
                isOpen={isOpen}
                languageOptions={languageOptions}
                partnerList={partnerList}
                onClickCancelButton={this.onClickCancelButton}
                onClickConfirmButton={this.onClickConfirmButton}
                addPartner={this.addPartner}
                isSaveAble={isSaveAble}
                useExpireYn={useExpireYn}
                useAgentPcControl={useAgentPcControl}
            />
        );
    }
}

ReqPartnerAccountPopupContainer.propTypes = {
    tenantId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    partnerList: PropTypes.array.isRequired,
    closePopup: PropTypes.func.isRequired,
    addPartner: PropTypes.func.isRequired,
    resetPartnerList: PropTypes.func.isRequired,
    isSaveAble: PropTypes.bool.isRequired,
    registerType: PropTypes.string.isRequired,
    setRegisterType: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired,
    openConfirm: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    tenantId: state.auth.user.tenantId,
    isOpen: state.partnerAccount.isOpen,
    partnerList: state.partnerAccount.partnerList,
    isSaveAble: state.partnerAccount.isSaveAble,
    registerType: state.partnerAccount.registerType
});

const mapDispatchToProps = {
    closePopup: partnerAccountActions.closePopup,
    addPartner: partnerAccountActions.addPartner,
    resetPartnerList: partnerAccountActions.resetPartnerList,
    setRegisterType: partnerAccountActions.setRegisterType,
    showToast: toastActions.showToast,
    openAlert: globalPopupActions.openAlert,
    openConfirm: globalPopupActions.openConfirm
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReqPartnerAccountPopupContainer)
);
