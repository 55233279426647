import * as ActionTypes from '../actions/Upload';

const initialState = {
    id: '',
    metadata: {},
    files: []
};

function filetransfer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.ADD_FILE:
            return {
                addFiles: action.addFiles
            };
        // case ActionTypes.SELECT_FILE:
        //     return {
        //         addFiles: { files: action.files, id: '', metadata: [] }
        //     };
        case ActionTypes.OPEN_INPUT:
            return {
                folder: action.folder,
                executor: action.executor,
                time: Date.now()
            };
        default:
            return state;
    }
}

export default filetransfer;
