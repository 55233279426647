import { func, string, array, object, bool } from 'prop-types';
import React from 'react';
import MenuMapper from '../../../utils/FileMenu/MenuMapper';

const FileIcon = props => {
    const { groupType, iconType, files = [], file, icons: propsIcons = [], callback, custom = {}, atHome, cardViewOption } = props;

    if (file) files.push(file);

    const [status, setStatus] = React.useState(false);

    const outerStatusCallback = custom.statusCallback;
    custom.statusCallback = isOpen => {
        setStatus(isOpen);
        if (outerStatusCallback) outerStatusCallback(isOpen);
    };

    custom.status = status;

    let icons = propsIcons;
    if (groupType && files.length && !icons.length) {
        icons = new MenuMapper({ groupType, iconType, callback, custom }, files).getIcons();
    }

    if (iconType === 'inner-row') {
        return <>{icons.length > 0 && { icons }}</>;
    }

    let className = 'filter-option nav';
    if (iconType === 'row') {
        className = 'files-option nav';
    }

    if (iconType === 'homeFavorite') {
        icons = new MenuMapper({ groupType, iconType, callback, custom }, files).getIcons();
    }

    let menuRender = <></>;

    if ((iconType === 'recvReq' || atHome) && icons.length > 0) {
        menuRender = icons;
    } else if (icons.length > 0 && !cardViewOption) {
        menuRender = <ul className={className}>{icons}</ul>;
    }

    return menuRender;
};

FileIcon.defaultProps = {
    groupType: undefined,
    iconType: 'row',
    files: undefined,
    file: undefined,
    icons: undefined,
    callback: () => {},
    custom: undefined,
    atHome: false,
    cardViewOption: false
};

FileIcon.propTypes = {
    groupType: string,
    iconType: string,
    files: array,
    file: object,
    icons: array,
    callback: func,
    custom: object,
    atHome: bool,
    cardViewOption: bool
};

export default FileIcon;
