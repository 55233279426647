import React from 'react';
import _ from 'lodash';
import BaseMenu from '../../BaseMenu';
import { isSummarizableExtension, toCompactedFileInfo } from '../../../../containers/Summary/Common/Util/SummaryUtil';
import { isSummarizableOnlyCache } from '../../../../containers/Summary/Common/Util/SummaryStorageUtil';
import { getReduxState } from '../../../../../redux/store';
import SummaryFileSelectMenu from './SummaryFileSelectMenu';

class SummaryFileSelect extends BaseMenu {
    getName() {
        return 'SummaryFileSelect';
    }

    getLabel() {
        return 'drive.button.list.menu.summary_file_select';
    }

    isVisible() {
        const { config: { configs } = {}, summary } = getReduxState();

        // 1. 요약 파일 선택 메뉴는 파일 2개 이하 선택 시에만 가능
        if (this.files.length >= 3) {
            return false;
        }

        // 2. 우측패널 프롬프트 인풋에 템플릿 선택 상태
        const promptTemplateType = _.get(summary, 'promptInput.promptTemplate.promptTemplateType');

        if (_.isNil(promptTemplateType)) {
            return false;
        }

        // 3. 요약 기능 사용 가능한 사용자 여부(사용자 소속 테넌트 요약 사용 여부 + 사용자 요약 가능 여부)
        // - 사용자 소속 테넌트 요약 사용 여부: KD AP > 테넌트 관리 > 환경설정 > 웹/탐색기 > 요약 기능 사용 여부
        // - 사용자 요약 가능 여부: AI Admin LLM 목록 조회 API(/drive/v1/ai/llm/user) 응답에 LLM 목록 유무
        if (!isSummarizableOnlyCache()) {
            return false;
        }

        // 4. 요약 대상 파일 actionPolicy.viewFileWithViewer 권한 존재 여부
        if (!super.isVisible(true, 'viewFileWithViewer', 'FILE')) {
            return false;
        }

        // 5. 요약 대상 파일 요약 가능 확장자 여부
        return this.files.every(file => {
            const { extensionName } = toCompactedFileInfo(file);
            return isSummarizableExtension(configs, extensionName);
        });
    }

    isEnable() {
        return true;
    }

    getSummaryMenuComp() {
        const { iconType } = this.props;
        const compactedFiles = this.files.map(toCompactedFileInfo);

        return <SummaryFileSelectMenu files={compactedFiles} iconType={iconType} labelId={this.getLabel()} />;
    }

    getDom() {
        return this.getSummaryMenuComp();
    }

    getIconDom() {
        return this.getSummaryMenuComp();
    }
}

export default SummaryFileSelect;
