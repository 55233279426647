const MAX_COUNT_OF_NOTIFICATIONS_ON_GNB = 30;
const FROM_TYPES = { GNB: 'GNB', LIST: 'LIST' };
const NOTIFICATION_CATEGORIES = {
    SHARED: 'SHARED',
    UPLOAD: 'UPLOAD',
    VERSIONUP: 'VERSIONUP',
    PARTNER_ACCOUNT: 'PARTNER_ACCOUNT',
    OWNER_MODIFICATION: 'OWNER_MODIFICATION',
    REQUEST_MANAGEMENT: 'REQUEST_MANAGEMENT',
    SUMMARY: 'SUMMARY'
};
const READ_STATUS = { READ: 'READ', UNREAD: 'UNREAD' };
const MOVE_TARGET_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
    USER: 'USER',
    NONE: 'NONE',
    REQUEST: 'REQUEST'
};
const REQUEST_NOTIFICATION_TYPE_PREFIX = {
    REQUEST: 'REQUESTED_',
    RESPOND: 'RESPONDED_'
};

const REQUEST_MOVE_TYPE_BY_NOTIFICATION_TYPE = {
    REQUEST: 'RECEIVED',
    RESPOND: 'SENT'
};

export default {
    MAX_COUNT_OF_NOTIFICATIONS_ON_GNB,
    FROM_TYPES,
    NOTIFICATION_CATEGORIES,
    READ_STATUS,
    MOVE_TARGET_TYPES,
    REQUEST_NOTIFICATION_TYPE_PREFIX,
    REQUEST_MOVE_TYPE_BY_NOTIFICATION_TYPE
};
