import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import InLinkRest from '../../../apis/InLinkRest';
import FileIconStyle from '../../../utils/FileIconStyle';
import Url from '../../../utils/Url';
import FileDownload from '../Download/Download';
import { popup, viewerPopup, alert } from '../../../utils/ModalService';
import FileViewerPopup from '../../containers/Popup/FileViewerPopup';

class IncomTypeLink extends Component {
    constructor(props) {
        super(props);
        // console.log('props: ', props);
        this.state = {
            folderFile: {},
            complianceYn: 'N'
        };
    }

    componentDidMount() {
        this.getIncomLinkData();
    }

    async getIncomLinkData() {
        const { incomLinkUrl, close, location, push, objtId } = this.props;

        const incomLinkUrlArray = incomLinkUrl.split('_');
        let complianceYn = 'N';

        // Link case
        // 1.금칙어 없는 경우 FLCMLINK_fRKHyPtbPM
        // 2.금칙어 있는 경우 FLCMLINK_fRKHyPtbPM_Y
        const chgIncomLinkUrl = `${incomLinkUrlArray[0]}_${incomLinkUrlArray[1]}`;
        if (incomLinkUrlArray.length > 2) {
            [, , complianceYn] = incomLinkUrlArray;
        }

        const incomLink = await InLinkRest.getIncomTypeLinkInfo(objtId, chgIncomLinkUrl);

        const { resultCode, data } = incomLink;

        if (resultCode !== 200) {
            let msg = '';
            if (incomLinkUrl.indexOf('_') > -1) {
                msg = 'drive.alert.incomLink.noAuthFile';
            } else {
                msg = 'drive.alert.incomLink.noAuthFolder';
            }
            alert(msg).then(() => {
                const { search } = location;
                if (search === '?redirectHome=true') {
                    close();
                    push('/home');
                } else {
                    this.close();
                }
            });
            return;
        }

        const { authCd, folderFile, browserPathId } = data;

        const browserPathNmArray = _.split(browserPathId, '/');

        this.setState({ folderFile, incomLinkUrl: chgIncomLinkUrl, complianceYn, browserPathNmArray });

        if (folderFile.objtSectCd === 'FOLDER') {
            // 폴더링크인 경우 해당 폴더 경로로 이동
            this.moveFileList();
            close();
        }

        // 해당 링크가 권한자만 접근 가능한 링크이면 권한체크 수행
        if (folderFile.incomLinkUnautPermCd === null) {
            if (authCd === 'NONE' || authCd === null) {
                // 비권한자의 링크 접근. 오류 얼럿 출력.
                alert('drive.alert.incomLink.noAuthFolder').then(() => {
                    this.close();
                });
            }
        }
    }

    setExpFileOpenParam(folderFile) {
        const param = [];
        param.push(folderFile.tenantId);
        param.push(folderFile.onpstId);
        param.push('Login');
        param.push('NONE');
        // param.push(incomLink.explorerPathNm);

        return param;
    }

    moveFileList = () => {
        const { folderFile, browserPathNmArray } = this.state;
        const { loginUser, push, close } = this.props;
        // onpstId 가 현재사용자의 userId와 같으면 내소유 | 다르면 공유받은폴더
        let toShareFolder = '';
        if (folderFile.onpstSectCd === 'SYSTFOLDER') toShareFolder = Url.workgroup;
        else if (folderFile.onpstSectCd === 'SHAREDFOLDER' || browserPathNmArray[1] === '111300000000000000') toShareFolder = Url.shared;
        else toShareFolder = Url.sharing;

        const toFolder = folderFile.onpstId === loginUser.id ? Url.own : toShareFolder;
        // const url = toFolder.concat('/').concat(folderFile.onpstFolderId);
        let url = folderFile.objtSectCd === 'FOLDER' ? toFolder.concat('/').concat(folderFile.objtId) : toFolder.concat('/').concat(folderFile.onpstFolderId);
        // 이거말고 router 써야할거같다능. 새로고침되면서 pages의 객체나 로그인이 이상해질수도 있어서 교체
        // window.location.replace(url);

        // 폴더 권한은 없고 파일만 공유 된 경우 :  shared/111100000000000000 가야함.
        if (browserPathNmArray.length === 2 && browserPathNmArray[1] === '111300000000000000') {
            // browserPathNmArray.length <= 3 에서 === 2로 수정함.
            // Shared files 하위폴더의 browserPathNmArray가 3이기때문에 해당 조건에 걸리고, 최상위 폴더로 넘어감. 2020.11.20
            url = Url.shared.concat('/').concat('111100000000000000');
        }

        push(url);
        // console.log('>>>>>>>>>>url', url);
        setTimeout(() => {
            close();
        }, 500);
    };

    goFileViewer = () => {
        const { folderFile, incomLinkUrl, complianceYn } = this.state;
        viewerPopup(<FileViewerPopup type="LINK_TYPE_NORMAL" objtId={folderFile.objtId} incomLinkUrl={incomLinkUrl} complianceYn={complianceYn} />).then(
            () => {
                return true;
            },
            () => {
                return false;
            }
        );
    };

    async downloadFile() {
        const { folderFile, incomLinkUrl } = this.state;
        const { callback } = this.props;
        // let fileVerSno = fileVer != null ? fileVer.fileVerSno : null;

        popup(<FileDownload folderFileList={folderFile} incomLinkUrl={incomLinkUrl} linkType="LINK_TYPE_NORMAL" />).then(data => {
            if (data) {
                // props.callback(data);
                callback(data);
            }
        });
    }

    moreCallBack = () => {
        // const { drive: folderFile } = file;
        // console.log(folderFile); // FileIcon에 넣었던 형태
    };

    fileIconCustom = {
        // statusCallback: isOpen => {
        //     setHover(isOpen);
        // },
        openType: 'left',
        moreGroupType: 'inLink'
    };

    close = () => {
        const { push, close } = this.props;
        close();
        push('/home');
    };

    getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    render() {
        const { intl } = this.props;
        const { folderFile } = this.state;
        const downloadBtnYn = folderFile.incomLinkUnautPermCd === 'DOWNLOAD';

        if (!folderFile.objtNm || folderFile.objtSectCd === 'FOLDER') return '';

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.incomLink.title" />
                    </h5>
                    <FormattedMessage id="com.close">
                        {closeTitle => (
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={closeTitle}
                                data-dismiss="modal"
                                onClick={() => {
                                    this.close();
                                }}>
                                <i className="ic-20-close" />
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <div className="modal-body">
                    <div className="box-line">
                        <div className="inner-grid-view">
                            <div className="grid-cell file-cell">
                                <div className="item-file">
                                    <i className={FileIconStyle.getStyle('ic-file-sm', folderFile.objtNm)} />
                                    <div className="file" title={folderFile.objtNm}>
                                        <span className="file-name">{this.getFileName(folderFile.objtNm)}</span>
                                        <span className="file-extension">{this.getExtension(folderFile.objtNm)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-cell option-cell">
                                <ul className="item-option nav">
                                    {downloadBtnYn && (
                                        <li className="option-download">
                                            <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'drive.button.list.menu.download' })}>
                                                <i className="ic-20-download" role="button" onClick={() => this.downloadFile()} />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileViewer()}>
                            <span className="btn-text">
                                <FormattedMessage id="drive.button.incomLink.fileViewer" />
                            </span>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

IncomTypeLink.propTypes = {
    // match: PropTypes.object,
    incomLinkUrl: PropTypes.string,
    objtId: PropTypes.string,
    loginUser: PropTypes.object,
    callback: PropTypes.func,
    push: PropTypes.func,
    close: PropTypes.func,
    location: PropTypes.object
};
IncomTypeLink.defaultProps = {
    // match: {},
    incomLinkUrl: {},
    loginUser: {},
    callback: () => {},
    push: () => {},
    close: () => {},
    location: {},
    objtId: ''
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    push: routerPush
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(IncomTypeLink));
