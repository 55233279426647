import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class RequestFooter extends Component {
    closePopup = isResolved => {
        const { close } = this.props;
        close(isResolved);
    };

    render() {
        const { intl, save, isValidForm } = this.props;

        return (
            <div className="modal-footer">
                <div className="modal-footer-option" />

                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => this.closePopup(false)}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                    </a>
                    <a className={`btn btn-lg btn-primary ${isValidForm ? '' : 'disabled'}`} role="button" onClick={() => save()}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

RequestFooter.propTypes = {
    intl: PropTypes.object,
    close: PropTypes.func,
    save: PropTypes.func,
    isValidForm: PropTypes.bool
};

RequestFooter.defaultProps = {
    close: () => {},
    save: () => {},
    intl: {},
    isValidForm: false
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RequestFooter));

export default connected;
