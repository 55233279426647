import React from 'react';
import { object } from 'prop-types';
import DriveInfoRest from '../../apis/DriveInfoRest';
import { largePopup, popup, viewerPopup } from '../../utils/ModalService';
import SharePopup from '../containers/Popup/Share/SharePopup';
import FileVersion from '../presentationals/FileVersion/FileVersion';
import FileViewerPopup from '../containers/Popup/FileViewerPopup';
import FileViewerRest from '../../apis/FileViewerRest';
import InShareRest from '../../apis/InShareRest';
import { DEFAULT_SHARE_AUTH_MAP } from '../../constants/ShareAuth';

const KEY = {
    type: 'type',
    objtId: 'objtId',
    onpstId: 'onpstId'
};

const TYPE = {
    share: 'share',
    version: 'version',
    viewer: 'viewer'
};

const param = { isIncludeComInfo: true, isIncludeShareTarget: false };
const getObjtInfo = (objtId, onpstId) => Promise.all([DriveInfoRest.getFolderFileInfo({ objtId, onpstId, ...param }), FileViewerRest.getViewerUseYn(), InShareRest.selectConfig()]);

const convertData = (responses = [{}, 'N', {}]) => {
    const { data: objt } = responses[0];
    const webViewerYn = responses[1];
    const shareConfig = Object.assign({}, DEFAULT_SHARE_AUTH_MAP, responses[2]);

    const actionPolicy = objt.sharePolicy;
    const drive = { ...objt, sharePolicy: null };
    return [{ drive, actionPolicy, webViewerYn, shareConfig }];
};

const openPopup = type => data => {
    const objt = data[0];
    if (type === TYPE.share) return largePopup(<SharePopup files={data} isExplorer={true} webViewerYn={objt.webViewerYn} config={objt.shareConfig} />);
    if (type === TYPE.version) return popup(<FileVersion files={data} />);
    if (type === TYPE.viewer) return viewerPopup(<FileViewerPopup type="NORMAL" objtId={objt.drive.objtId} />);
    return Promise.reject();
};

const savePopup = rslt => console.log('save!!!!!!!!!!', rslt);

const cancelPopup = () => false;

const windowClose = () => window.open('about:blank', '_self').close();

const DirectPopupGate = ({ match }) => {
    const type = match.params[KEY.type];
    const objtId = match.params[KEY.objtId];
    const onpstId = match.params[KEY.onpstId];

    getObjtInfo(objtId, onpstId)
        .then(convertData)
        .then(openPopup(type))
        .then(savePopup, cancelPopup)
        .then(windowClose);

    return <></>;
};

DirectPopupGate.propTypes = { match: object.isRequired };

export default DirectPopupGate;
