/* eslint-disable */
import React from 'react';
import { array } from 'prop-types';
import CharacterImageContainer from '../../../containers/CharacterImage/CharacterImageContainer';
import CustomList from '../../List/CustomList';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

const SharePeoplePopup = props => {
    const { shareInfo, intl } = props;

    const rowBuilder = ({ style, index }) => {
        const shareTgt = shareInfo[index];

        return (
            <div className="grid-row" style={style}>
                <div className="grid-cell user-cell">
                    <div className="item-user">
                        <CharacterImageContainer
                            objtId={_.isEmpty(shareTgt.objtShareTgtPhotoFilePathVal) ? '' : shareTgt.objtShareTgtPhotoFilePathVal}
                            userId={_.toString(shareTgt.objtShareTgtId)}
                            text={shareTgt.objtShareTgtNm}
                            size="md"
                            userType={shareTgt.objtShareTgtUserSectCd}
                            characterType={shareTgt.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'team'}
                        />
                        <div className="user-profile">
                            <Choose>
                                <When condition={shareTgt.objtShareTgtSectCd === 'INDIVIDUAL'}>
                                    <div title={`${shareTgt.objtShareTgtNm} ${shareTgt.objtShareTgtCopNm}`}>
                                        <span className="name">{shareTgt.objtShareTgtNm}</span>
                                        <span className="position">{shareTgt.objtShareTgtCopNm}</span>
                                    </div>
                                    <span className="partname">{shareTgt.objtShareTgtDeptNm}</span>
                                </When>
                                <Otherwise>
                                    <div title={shareTgt.objtShareTgtNm}>
                                        <span className="name">{shareTgt.objtShareTgtNm}</span>
                                    </div>
                                </Otherwise>
                            </Choose>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="drive.title.sharePeoplePopTitle" />
                </h5>
                <a
                    className="btn-ic-nor"
                    role="button"
                    title={intl.formatMessage({ id: 'com.close' })}
                    data-dismiss="modal"
                    onClick={e => {
                        props.close();
                        e.stopPropagation();
                    }}>
                    <i className="ic-20-close" />
                </a>
            </div>

            <div className="modal-body">
                <div className="srch-grid-list">
                    <div className="comm-grid-list react-list">
                        <CustomList className="grid-row-group" customScroll={true} virtualized={true} height={390} rowCount={shareInfo.length} rowHeight={65} rowBuilder={rowBuilder} />
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a
                        className="btn btn-lg btn-primary"
                        role="button"
                        data-dismiss="modal"
                        onClick={e => {
                            props.close();
                            e.stopPropagation();
                        }}>
                        <span className="btn-text">
                            <FormattedMessage id="com.button.ok" />
                        </span>
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

SharePeoplePopup.propTypes = {
    shareInfo: array
};

SharePeoplePopup.defaultProps = {
    shareInfo: []
};

export default injectIntl(SharePeoplePopup);
