import moment from 'moment/moment';

class LocalStorage {
    get = key => {
        return window.localStorage.getItem(key);
    };

    set = (key, value) => {
        window.localStorage.setItem(key, value);
    };

    getJsonValue = key => {
        return JSON.parse(this.get(key));
    };

    setJsonValue = (key, jsonValue) => {
        this.set(key, JSON.stringify(jsonValue));
    };

    getExpireDate = () => {
        let expire = new Date();
        expire.setDate(expire.getDate() + 365);
        expire = moment(expire)
            .utcOffset(0)
            .format('YYYY-MM-DDTHH:mm:ssZ');
        return expire;
    };
}

export default new LocalStorage();
