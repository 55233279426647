import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import store from '../../redux/store';

const DEFAULT_SOLUTION_NAME = 'Knox Drive';

const Logo = props => {
    let logoUnsubscribe = null;
    let prevSiteState = null;

    const { location } = props;

    const [logo, setLogo] = useState(null);
    const [solutionName, setSolutionName] = useState(DEFAULT_SOLUTION_NAME);

    const logoSubscribe = () => {
        logoUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { site: siteState } = storeState;
            if (_.isEqual(siteState, prevSiteState)) {
                return;
            }
            prevSiteState = _.cloneDeep(siteState);

            const { logo: storeLogo, solutionName: storeSolutionName } = siteState;
            if (logo !== storeLogo) {
                setLogo(storeLogo);
            }
            if (solutionName !== storeSolutionName) {
                setSolutionName(storeSolutionName);
            }
        });
    };

    useEffect(() => {
        logoSubscribe();
        return () => {
            if (logoUnsubscribe) logoUnsubscribe();
        };
    });

    const goToHome = event => {
        const { pathname } = location;

        if (pathname === '/home') {
            event.preventDefault();
            window.location.reload();
        }
    };

    return (
        <div className="header-logo">
            <Link to="/home" className="logo" onClick={goToHome}>
                <h1>{logo && <img src={logo} alt={solutionName} />}</h1>
            </Link>
        </div>
    );
};

Logo.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(Logo);
