/* eslint-disable */


function isPromise(value) {
    return Boolean(value && typeof value.then === 'function');
}

class Pipeline {
    /**
     * @param {Array<Function>} pipes
     */
    constructor(pipes = []) {
        this.pipes = pipes;
    }
    next(args) {
        let pipe = this.pipes.shift();

        if (!pipe) {
            this.onSuccessful(...args);
            return;
        }

        let err = new Error('The filter has not passed');
        err.pipe = pipe;
        err.args = args;

        let back = pipe.fn(...args);
        if(isPromise(back)){
            back.then(data => {
                if(data){
                    this.next(args);
                }else{
                    this.onThrown(err);
                }
            })
        }else{
            let isDone = Boolean(back);
            if (isDone) {
                this.next(args);
            } else {
                this.onThrown(err);
            }
        }
    }
    exec(...args) {
        this.next(args);
    }
    onThrown(err) {

    }
    onSuccessful(...args) {

    }
}

export default Pipeline;