import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FileInput from './Item/FileInput';
import KeywordInput from './Item/KeywordInput';
import { LANGUAGE_CODE } from '../../Common/SummaryConstant';

const SingleFileWithKeywordPromptTemplate = memo(() => {
    const languageCode = useSelector(({ auth }) => auth.user.locale.languageCode);

    // TODO i18n 어순 고려한 형태로
    return (
        <div className="copilot-prompt-input">
            <div className="prompt-command">
                <FileInput isFocus={false} />
                {languageCode === LANGUAGE_CODE.korean && <FormattedMessage id="drive.text.copilot.summary.ptcont.josaEul" />} <KeywordInput isFocus={false} />{' '}
                <FormattedMessage id="drive.text.copilot.summary.ptcont.withKeywordSimple" />
            </div>
        </div>
    );
});

export default SingleFileWithKeywordPromptTemplate;
