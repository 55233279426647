import produce from 'immer';
import _ from 'lodash';
import { locale } from 'modules/moment';
import { TOKEN_STATUS, SET_TOKEN_STATUS, SET_USER, SET_SETTINGS, SET_PROFILE_FILE_ID, SET_LOGIN_INFO } from '../actions/Auth';
import cookie from '../../utils/Cookie';

const initialState = {
    tokenStatus: TOKEN_STATUS.WAIT,
    user: {},
    auth: {},
    expirationAction: {
        isLogout: false,
        needAlert: true
    }
};

const convertLoginUser = user => ({
    id: user.userId,
    loginId: user.userLoginId,
    name: user.userNm,
    sectionCode: user.userSectCd,
    telNo: user.userTelno,
    celNo: user.userCelno,
    isInterface: user.userIfYn,
    jobTitle: user.userCopNm,
    region: user.region,
    tenantId: user.tenantId,
    isTrial: user.isTrial,
    accessRestrictions: {
        expirationTime: user.accessExpirationTime,
        allowNday: user.allowNday
    },
    linkage: {
        knoxVersion: user.mySingleVer,
        epId: user.epId,
        isLinked: user.userIfYn
    },
    config: user.config,
    dept: {
        id: user.deptId,
        name: user.deptNm,
        path: user.deptPathVal,
        parentDeptName: user.uprDeptNm
    },
    company: {
        id: user.coId,
        name: user.coNm
    },
    locale: {
        languageCode: user.langCd,
        timeZone: user.timeZoneCode,
        timezoneDiff: _.toNumber(user.timeZoneDiffernce)
    },
    profile: {
        fileId: user.userPhotoFilePathVal,
        updateTime: new Date().getTime()
    }
});

const auth = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_TOKEN_STATUS:
            if (action.tokenStatus === TOKEN_STATUS.EXPIRED) {
                cookie.deleteCookie('tenantId');
                return produce(state, draft => {
                    draft.tokenStatus = action.tokenStatus;
                    draft.user = {};
                    draft.auth = action.auth;
                    draft.expirationAction = action.expirationAction;
                });
            }
            return produce(state, draft => {
                draft.tokenStatus = action.tokenStatus;
                draft.auth = action.auth;
                draft.expirationAction = initialState.expirationAction;
                if (!_.isEmpty(action.user)) {
                    draft.user = convertLoginUser(action.user);
                    locale(draft.user.locale.languageCode);
                    cookie.setCookieText('langCd', draft.user.locale.languageCode);
                    cookie.setCookieText('tenantId', draft.user.tenantId);
                }
            });
        case SET_USER:
            return produce(state, draft => {
                draft.user = convertLoginUser(action.user);
                locale(draft.user.locale.languageCode);
                cookie.setCookieText('langCd', draft.user.locale.languageCode);
                cookie.setCookieText('tenantId', draft.user.tenantId);
            });
        case SET_SETTINGS:
            locale(action.languageCode);
            cookie.setCookieText('langCd', action.languageCode);
            return produce(state, draft => {
                draft.user.locale.languageCode = action.languageCode;
                draft.user.locale.timeZone = action.timeZone;
                draft.user.locale.timezoneDiff = action.timezoneDiff;
            });
        case SET_PROFILE_FILE_ID:
            return produce(state, draft => {
                draft.user.profile.fileId = action.profileFileId;
                draft.user.profile.updateTime = new Date().getTime();
            });
        case SET_LOGIN_INFO:
            return produce(state, draft => {
                draft.loginInfo = action.loginInfo;
            });
        default:
            return state;
    }
};

export default auth;
