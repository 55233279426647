import React from 'react';
import PropTypes from 'prop-types';
import UserCard from 'components/containers/NameCard/UserCard';

const UserName = props => {
    const {
        file: { users = {}, drive = {} },
        cellStyle
    } = props;
    const { onpst = {} } = users;
    const { objtId, onpstId, onpstSectCd } = drive;
    return (
        <div className="grid-cell files-owner-cell" style={cellStyle}>
            <div role="button" className="g-inline-flex" id={`files-owner-cell-${objtId}`} onClick={e => e.stopPropagation()} style={{ cursor: onpstSectCd === 'INDVFOLDER' ? 'pointer' : '' }}>
                <div className="ellipsis" title={onpst.userNm}>
                    <span>{onpst.userNm}</span>
                    {onpstSectCd === 'INDVFOLDER' ? <UserCard target={`files-owner-cell-${objtId}`} id={onpstId} /> : null}
                </div>
            </div>
        </div>
    );
};

UserName.defaultProps = {
    cellStyle: null
};

UserName.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

UserName.displayName = 'UserName';
export default React.memo(UserName);
