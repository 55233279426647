import React, { useState } from 'react';
import { slice, noop } from 'lodash';
import PropTypes from 'prop-types';

const FavoriteFolder = props => {
    const { folderList, move, folderLengthTobeShown } = props;
    const [hover] = useState(false);

    const onClickFolderName = f => {
        move(f);
    };

    return (
        <>
            {slice(folderList, 0, folderLengthTobeShown).map(folder => (
                <li className={`item-list ${hover ? ' is-hovered' : ''}`}>
                    <div className="item-file">
                        <i className="ic-file-md ic-file-FOLDER" />
                        <div className="file file-link" title={folder.drive.objtNm} onClick={() => onClickFolderName(folder)} role="presentation">
                            <span className="file-name">{folder.drive.objtNm}</span>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
};

export default FavoriteFolder;

FavoriteFolder.propTypes = {
    folderList: PropTypes.array,
    move: PropTypes.func,
    folderLengthTobeShown: PropTypes.number
};

FavoriteFolder.defaultProps = {
    folderList: [],
    move: noop,
    folderLengthTobeShown: 3
};
