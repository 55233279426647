import { $http } from 'modules/index';

class DriveConfigRest {
    cache = {};

    callConfigRest = (id, fromRedis = false) => $http.get(`/drive/v1/configs/${id}?fromRedis=${fromRedis}`).then(response => response.data);

    getConfig = (id, fromRedis = false) => {
        if (this.cache[id]) {
            return Promise.resolve(this.cache[id]);
        }
        return this.callConfigRest(id, fromRedis).then(result => {
            if (result) {
                this.cache[id] = result;
            }
            return result;
        });
    };

    getConfigs = (ids = [], tenantId = '') => {
        const configs = {};
        const paramIds = [];

        ids.forEach(id => {
            if (this.cache[id]) {
                configs[id] = this.cache[id];
            } else {
                paramIds.push(id);
            }
        });

        // 캐시만으로 처리되면 반환
        if (paramIds.length < 1) {
            return Promise.resolve(configs);
        }

        // 없는 것들 조회
        const params = {
            ids: paramIds.join(','),
            tenantId
        };

        return $http.get(`/drive/v1/configs/list`, { params }).then(response => {
            const { data = [], resultCode, message } = response;

            if (resultCode === 200) {
                data.forEach(cfg => {
                    if (!this.cache[cfg.cfgId]) {
                        this.cache[cfg.cfgId] = cfg.cfgVal;
                        configs[cfg.cfgId] = cfg.cfgVal;
                    }
                });
            } else {
                return message;
            }
            return configs;
        });
    };
}

export default new DriveConfigRest();
