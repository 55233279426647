import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { popup } from '../../../../utils/ModalService';
import RequestFileListPopup from './RequestFileListPopup';

class RequestBodyFiles extends Component {
    constructor(props) {
        super(props);

        this.initFileData();
    }

    initFileData = () => {
        const { files, setFileIds } = this.props;
        this.firstFile = files[0].drive.objtNm;
        this.firstFileNm = this.firstFile.substr(0, this.firstFile.lastIndexOf('.'));
        this.firstFileExtNm = this.firstFile.substr(this.firstFile.lastIndexOf('.') + 1);
        const fileIds = _.map(files, file => {
            const { objtId } = file.drive;
            return objtId;
        });
        setFileIds(fileIds);
    };

    showFiles = () => {
        const { files } = this.props;
        popup(<RequestFileListPopup files={files} />);
    };

    render() {
        const { intl, files } = this.props;

        return (
            <>
                <dl className="row-list text-info">
                    <dt>{intl.formatMessage({ id: 'drive.text.share.selectedItems' })}</dt>
                    <dd>
                        <div className="dd-text">
                            <div className="g-inline-flex">
                                <div className="item-file">
                                    <div className="file" title={this.firstFile}>
                                        <span className="file-name">{this.firstFileNm}</span>
                                        <span className="file-extension">{this.firstFileExtNm}</span>
                                    </div>
                                </div>
                                <If condition={files.length > 1}>
                                    <div className="next">
                                        <FormattedMessage id="drive.text.share.seletedObjectCount" tagName="div" values={{ n: <strong className="num">{files.length - 1}</strong> }} />
                                    </div>
                                </If>
                            </div>
                            <If condition={files.length > 1}>
                                <a className="btn-link" role="button" onClick={this.showFiles}>
                                    {intl.formatMessage({ id: 'drive.button.share.viewAllItems' })}
                                </a>
                            </If>
                        </div>
                    </dd>
                </dl>
            </>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

RequestBodyFiles.propTypes = {
    intl: PropTypes.object,
    files: PropTypes.array,
    setFileIds: PropTypes.func
};

RequestBodyFiles.defaultProps = {
    setFileIds: () => {},
    intl: {},
    files: []
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RequestBodyFiles));

export default connected;
