import $http from 'modules/Http';

const PREFIX = '/auth/v1/configs/cfg';
const PROPERTIES_PREFIX = '/auth/v1/configs/properties';
const COMMON_PREFIX = '/common/v1/configs/cfg';
const DRIVE_PREFIX = '/common/v1/configs/cfg';

class ConfigsRest {
    static getConfigs = async () => {
        const response = await $http.get(PREFIX);
        return response.data;
    };

    static getProperties = async () => {
        const response = await $http.get(PROPERTIES_PREFIX);
        return response.data;
    };

    static getConfig = async cfgId => {
        const response = await $http.get(`${PREFIX}/${cfgId}`);
        return response.data;
    };

    static getCommonConfig = async cfgId => {
        const response = await $http.get(`${COMMON_PREFIX}/${cfgId}`);
        return response.data;
    };

    static getDriveConfig = async cfgId => {
        const response = await $http.get(`${DRIVE_PREFIX}/${cfgId}`);
        return response.data;
    };
}

export default ConfigsRest;
