import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FileInput from './Item/FileInput';
import KeywordInput from './Item/KeywordInput';
import { LANGUAGE_CODE } from '../../Common/SummaryConstant';

const MultipleFilesWithKeywordPromptTemplate = memo(() => {
    const languageCode = useSelector(({ auth }) => auth.user.locale.languageCode);

    return (
        <div className="copilot-prompt-input">
            <div className="prompt-command">
                <FileInput fileInputIndex={0} />
                {', '}
                <FileInput fileInputIndex={1} />
                {languageCode === LANGUAGE_CODE.korean && <FormattedMessage id="drive.text.copilot.summary.ptcont.josaEul" />} <KeywordInput />{' '}
                <FormattedMessage id="drive.text.copilot.summary.ptcont.withKeywordSimple" />
            </div>
        </div>
    );
});

export default MultipleFilesWithKeywordPromptTemplate;
