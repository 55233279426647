import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SummaryThread from './Thread/SummaryThread';
import SummaryPopups from './Popup/SummaryPopups';

const SidePanelBody = memo(({ isOpen }) => {
    const sidePanelBodyRef = useRef();

    const summaryMessages = useSelector(({ summary }) => summary.thread.summaryMessages);

    const moveSidePanelScrollToBottom = useCallback(() => {
        sidePanelBodyRef.current.scrollTop = sidePanelBodyRef.current.scrollHeight;
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (_.isNil(sidePanelBodyRef.current)) {
            return;
        }

        moveSidePanelScrollToBottom();
    }, [isOpen, summaryMessages]);

    return (
        <div className="aside-panel-body" ref={sidePanelBodyRef}>
            <div className="ai-container">
                <SummaryThread moveSidePanelScrollToBottom={moveSidePanelScrollToBottom} />
            </div>
            <SummaryPopups />
        </div>
    );
});

SidePanelBody.propTypes = {
    isOpen: PropTypes.bool
};

SidePanelBody.defaultProps = {
    isOpen: false
};

export default SidePanelBody;
