import React, { Component } from 'react';
import $ from 'jquery';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CoachMarkConstants from 'constants/CoachMark';
import SelectBox from '../../../../presentationals/SelectBox';
import InstantSearchList from '../../../InstantSearch/InstantSearchList';
import { alert, largePopup } from '../../../../../utils/ModalService';
import MultiSelectPopup from '../../../AdvancedSearch/MultiSelectPopup';
import AuthService from '../../../../../utils/AuthService';

class InShareInsert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '', // 순간검색 검색어
            defaultAuthCd: props.config.defaultAuthCd || 'U40COPY', // default 권한
            isOpenInstantSearch: props.isOpenInstantSearch,
            keyUpEvent: undefined
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { defaultAuthCd, isOpenInstantSearch, query, keyUpEvent } = this.state;
        const { shareInsertCoachMark } = this.props;

        // console.log('shouldComponentUpdate shareInsertCoachMark ', shareInsertCoachMark);

        if (
            isOpenInstantSearch !== nextState.isOpenInstantSearch ||
            defaultAuthCd !== nextState.defaultAuthCd ||
            query !== nextState.query ||
            keyUpEvent !== nextState.keyUpEvent ||
            shareInsertCoachMark !== nextProps.shareInsertCoachMark
        ) {
            return true;
        }

        return false;
    }

    checkShareTargetValidation(shareTargets, target) {
        // 소유자를 추가한 경우
        if (
            shareTargets.some(shareTarget => {
                return shareTarget.authCd === 'U80OWN' && (shareTarget.objtShareTgtId === target.id || shareTarget.objtShareTgtId === target.userId);
            })
        ) {
            return 1;
        }

        // 기 공유대상을 추가한 경우
        if (
            shareTargets.some(shareTarget => {
                return !shareTarget.inheritedAuth && (shareTarget.objtShareTgtId === target.id || shareTarget.objtShareTgtId === target.userId);
            })
        ) {
            return 2;
        }

        // 공유자 추가 가능한 경우
        return 0;
    }

    changeDefaultAuthCd = value => {
        this.setState({ defaultAuthCd: value });
    };

    onSelect = target => {
        this.addShareTargets([target]);
        this.setState({ query: '' });
    };

    clear = () => {
        this.setState({ query: '' });
    };

    addShareTargets = targets => {
        const { intl } = this.props;

        if (_.isEmpty(targets)) {
            return;
        }

        const { defaultAuthCd } = this.state;
        const { shareTargets, addShareTargetFn } = this.props;
        let failedCount = 0;
        let checkShareTargetResult = 0;
        const newShareTargets = [];

        targets.forEach(target => {
            // 추가할 공유대상 정합성 체크 (return 0 : 추가 가능, 1 : 소유자를 추가한 경우, 2 : 기 공유대상을 추가한 경우)
            checkShareTargetResult = this.checkShareTargetValidation(shareTargets, target);

            if (checkShareTargetResult !== 0) {
                failedCount += 1;
            } else {
                // 추가할 공유대상 정보 생성
                const addShareTarget = {
                    objtShareTgtId: target.id || target.userId,
                    objtShareTgtSectCd: target.type.toLowerCase() === 'dept' ? 'DEPARTMENT' : 'INDIVIDUAL',
                    authCd: defaultAuthCd,
                    lwrDeptIcluYn: target.subYn ? 'Y' : null,
                    objtShareTgtNm: target.name || target.userLangCdNm || target.title,
                    objtShareTgtCopNm: target.userCopNm || target.userLangCdCopNm,
                    objtShareTgtDeptNm: target.deptNm || target.deptLangCdNm,
                    objtShareTgtPhotoFilePathVal: target.userPhotoFilePathVal,
                    evtSectCd: 'SHAREADD',
                    inheritedAuth: false,
                    isNew: true
                };

                if (newShareTargets.findIndex(newShareTarget => newShareTarget.objtShareTgtId === addShareTarget.objtShareTgtId) === -1) {
                    newShareTargets.push(addShareTarget);
                }
            }
        });

        if (!_.isEmpty(newShareTargets)) {
            addShareTargetFn(newShareTargets);
        }

        if (failedCount > 0) {
            if (targets.length === 1) {
                if (checkShareTargetResult === 1) {
                    alert(intl.formatMessage({ id: 'drive.alert.share.cannotAddOwner' }));
                } else if (checkShareTargetResult === 2) {
                    alert(intl.formatMessage({ id: 'drive.alert.share.existShareMember' }));
                }
            } else {
                alert(intl.formatMessage({ id: 'drive.alert.share.existShareMemberOrAddOwner' }));
            }
        }
    };

    changeInstantSearchQuery = e => {
        this.setState({ query: e.target.value });
    };

    openAdvancedSearchPopup = () => {
        largePopup(<MultiSelectPopup show={true} />).then(users => this.addShareTargets(users), () => false);
        this.setState({ isOpenInstantSearch: false });
    };

    openInstantSearch = () => {
        const { hideShareInsertCoachMark } = this.props;
        hideShareInsertCoachMark();
        document.querySelector('.has-instant-srch').classList.add('is-focused');
        this.setState({ isOpenInstantSearch: true });
    };

    focusOutInstantSearch = () => {
        document.querySelector('.has-instant-srch').classList.remove('is-focused');
    };

    onKeyUp = e => {
        const { nativeEvent } = e;
        if (nativeEvent.keyCode === 13 || nativeEvent.keyCode === 38 || nativeEvent.keyCode === 40) {
            this.setState({ keyUpEvent: nativeEvent });
        }
    };

    hide = mode => {
        const { hideShareInsertCoachMark } = this.props;
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.animate({ opacity: 0 }, 'fast', 'swing');
        ccp.hide();

        $('body > div.coachmark-backdrop').remove();
        if (mode === 'stop') {
            this.setNLocalStorage();
        }

        hideShareInsertCoachMark();
    };

    setNLocalStorage = () => {
        const id = AuthService.getUserId();
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_SHARE}${id}`, 'N');
    };

    popoverShareInsertCoachMark() {
        const { hideShareInsertCoachMark } = this.props;

        this.coachmarkOwner = $('#shareinsert-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: false
        });

        if (document.getElementsByClassName('coachmark-backdrop').length === 0) {
            const coachBg = $('<div class="coachmark-backdrop"></div>');
            $('body').append(coachBg);

            coachBg.click(() => {
                const ccp = $('.coachmark-conts').parents('.popover');
                ccp.remove();
                hideShareInsertCoachMark();
                coachBg.remove();
            });
        }
        this.popover.addClass('coachmark').popover('show');

        $('#conts-shareinsert')
            .parents('.popover')
            .attr('style', 'z-index: 1060;');
        $('#conts-shareinsert')
            .parents('.popover')
            .addClass('coachmark');

        if (document.getElementById('stop-shareinsert') !== null) {
            document.getElementById('stop-shareinsert').addEventListener('click', () => this.hide('stop'));
        }

        if (document.getElementById('confirm-shareinsert') !== null) {
            document.getElementById('confirm-shareinsert').addEventListener('click', () => this.hide('confirm'));
        }
    }

    render() {
        const { query, defaultAuthCd, keyUpEvent } = this.state;
        const { intl, shareInsertCoachMark, config } = this.props;

        // console.log('render shareInsertCoachMark ', shareInsertCoachMark);
        const nomoreMsg = intl.formatMessage({ id: 'com.text.coachMark.stop' });
        const coachMarkMsg = intl.formatMessage({ id: 'drive.text.coachMark.shareInsert' });
        const coachMarkHtml = `<div class='coachmark-conts' id='conts-shareinsert'><p>${coachMarkMsg}</p>
            <div class='btns'><a class='coachmark-end' id='stop-shareinsert'>${nomoreMsg}</a></div></div>`;

        if (shareInsertCoachMark === 'Y') {
            this.popoverShareInsertCoachMark();
        }

        return (
            <div className="forms-container">
                <SelectBox
                    options={config.list}
                    dataWidth="120"
                    onChange={value => {
                        this.changeDefaultAuthCd(value);
                    }}
                    value={defaultAuthCd}
                    type="box"
                />
                <div className="box-input-group has-instant-srch has-coachmark" id="shareinsert-coachmark" data-placement="bottom" data-coachmark-order="130" data-content={coachMarkHtml}>
                    <input
                        id="instant-searcher"
                        className="form-control"
                        type="text"
                        value={query}
                        placeholder={intl.formatMessage({ id: 'drive.placeHolder.share.insertName' })}
                        maxLength="100"
                        autoComplete="off"
                        onChange={this.changeInstantSearchQuery}
                        onFocus={this.openInstantSearch}
                        onBlur={this.focusOutInstantSearch}
                        onKeyUp={this.onKeyUp}
                    />
                    <a
                        className="btn-ic-nor btn-func-clear"
                        role="button"
                        title={intl.formatMessage({ id: 'com.text.clear' })}
                        onClick={this.clear}
                        style={{ display: query.length > 0 ? 'inline-flex' : 'none' }}>
                        <i className="ic-16-clear" />
                    </a>
                    <InstantSearchList target="instant-searcher" query={query} select={value => this.onSelect(value)} keyEvent={keyUpEvent} />
                </div>
                <a className="btn-ic-line" role="button" title={intl.formatMessage({ id: 'com.search' })} onClick={this.openAdvancedSearchPopup}>
                    <i className="ic-20-search-people" />
                </a>
            </div>
        );
    }
}

InShareInsert.propTypes = {
    addShareTargetFn: PropTypes.func,
    shareTargets: PropTypes.array,
    isOpenInstantSearch: PropTypes.bool,
    intl: PropTypes.object,
    shareInsertCoachMark: PropTypes.string,
    hideShareInsertCoachMark: PropTypes.func,
    config: PropTypes.object
};

InShareInsert.defaultProps = {
    addShareTargetFn: _.noop,
    shareTargets: [],
    isOpenInstantSearch: false,
    intl: {},
    shareInsertCoachMark: 'N',
    hideShareInsertCoachMark: _.noop,
    config: {}
};

export default injectIntl(InShareInsert);
