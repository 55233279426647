import CustomModal from 'containers/CustomModal';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import PartnerAccountComponentContainer from '../containers/PartnerAccountComponent';

const ReqPartnerAccountPopup = ({
    registerType,
    onClickRegisterType,
    isOpen,
    languageOptions,
    partnerList,
    onClickCancelButton,
    onClickConfirmButton,
    addPartner,
    isSaveAble,
    intl,
    useExpireYn,
    useAgentPcControl
}) => {
    return (
        <CustomModal isOpen={isOpen} fade={false} size="lg" backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({ id: 'user.title.popup.reqPartnerAccount.regAccount' })}</h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={onClickCancelButton}>
                    <i className="ic-20-close" />
                </a>
            </div>
            <If condition={useAgentPcControl === 'Y'}>
                <div className="modal-info">
                    <div className="info-msg">
                        <i className="ic-16-info" />
                        {intl.formatMessage({ id: 'com.text.lockingAccount' })}
                    </div>
                </div>
            </If>
            <div className="modal-body">
                <div className="comm-title-bar">
                    <h4 className="section-title">{intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.signUp' })}</h4>
                    <div className="btn-group" data-toggle="buttons">
                        <label className={registerType === 'single' ? 'btn btn-light active' : 'btn btn-light'} onClick={() => onClickRegisterType(true)}>
                            <input type="radio" id="single" name="options" autoComplete="off" checked={registerType === 'single'} readOnly={true} />
                            <span className="btn-text" style={{ cursor: 'default' }}>
                                {intl.formatMessage({ id: 'user.tab.popup.reqPartnerAccount.individual' })}
                            </span>
                        </label>
                        {/* <label className={registerType === 'multi' ? 'btn btn-light active' : 'btn btn-light'} onClick={() => onClickRegisterType(false)}>
                            <input type="radio" id="multi" name="options" autoComplete="off" checked={registerType === 'multi'} readOnly={true} />
                            <span className="btn-text">일괄 등록</span>
                        </label> */}
                    </div>
                </div>
                <If condition={registerType === 'single'}>
                    <div className="comm-group-list">
                        {partnerList.map((partner, index) => (
                            <PartnerAccountComponentContainer key={partner.uniqueKey} partner={partner} languageOptions={languageOptions} index={index} useExpireYn={useExpireYn} />
                        ))}
                    </div>
                    <If condition={partnerList.length < 10}>
                        <a className="btn-ic-line btn-block" role="button" onClick={addPartner}>
                            <span className="btn-text">{intl.formatMessage({ id: 'user.guideText.popup.reqPartnerAccount.addMoreAccount' })}</span>
                        </a>
                    </If>
                </If>
                <If condition={registerType === 'multi'}>
                    <>
                        <div className="dt-width-120">
                            <dl className="row-list">
                                <dt>계정 사용 기간</dt>
                                <dd>
                                    <div className="box-input-datepicker">
                                        <em className="start-date">2019-10-12</em>
                                        <span className="dash" />
                                        <input className="form-control" type="text" placeholder="End Date" />
                                        <a className="btn-ic-line" role="button" title="달력 호출">
                                            <i className="ic-20-calendar" />
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                        </div>

                        <div className="comm-title-bar">
                            <h5 className="article-title width-120">일괄 등록</h5>

                            <a className="btn-ic-line" role="button">
                                <i className="ic-20-download" />
                                <span className="btn-text">양식 다운로드</span>
                            </a>

                            <span className="file-upload">
                                <input type="file" className="upload" />
                                <a className="btn btn-secondary" role="button">
                                    <span className="btn-text">파일 추가</span>
                                </a>
                            </span>
                        </div>

                        <div className="box-line update-zone">
                            <div className="inner-grid-view" style={{ display: 'none' }}>
                                <div className="grid-cell upload-item-cell">
                                    <div className="item-file">
                                        <i className="ic-file-sm ic-file-EXCEL" />
                                        <div className="file" title="">
                                            <span className="file-name">파일명파일명파일명파일명파일명파일명파일명 파일명파일명파일명파일명파일명파일명파일명</span>
                                            <span className="file-extension">docx</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-cell upload-size-cell">
                                    <span className="size">123.45 KB</span>
                                </div>

                                <div className="grid-cell upload-verify-cell">
                                    <span className="verify">대기중</span>
                                </div>
                                <div className="grid-cell upload-progress-cell">
                                    <div className="c-progress p0">
                                        <div className="slice">
                                            <div className="bar" />
                                            <div className="fill" />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-cell upload-status-cell">
                                    <span className="percent">0%</span>
                                </div>
                                <div className="grid-cell upload-del-cell">
                                    <a className="btn-ic-nor" role="button" title="삭제">
                                        <i className="ic-20-close-sm" />
                                    </a>
                                </div>
                            </div>

                            <div className="box-empty">
                                <div className="message">
                                    <span className="ic-20-upload" />

                                    <em className="infix-lg">여기를 클릭하시거나 업로드할 파일을 끌어다 놓으세요.</em>
                                    <em className="infix-xs">여기를 클릭하여 업로드할 파일을 선택해 주세요.</em>
                                </div>
                                <span className="file-upload">
                                    <input type="file" className="upload" />
                                </span>
                            </div>

                            <div className="dnd-zone active" style={{ display: 'block' }}>
                                <span>업로드 하시려면 여기에 놓으세요.</span>
                            </div>
                        </div>

                        <div className="info-msg">
                            <i className="ic-16-info" />
                            최대 200개 계정까지 신청 가능합니다.
                        </div>
                    </>
                </If>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClickCancelButton}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.cancel' })}</span>
                    </a>
                    <a className={isSaveAble ? 'btn btn-lg btn-primary' : 'btn btn-lg btn-primary disabled'} role="button" onClick={onClickConfirmButton}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

ReqPartnerAccountPopup.propTypes = {
    registerType: PropTypes.string.isRequired,
    onClickRegisterType: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    languageOptions: PropTypes.array.isRequired,
    partnerList: PropTypes.array.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickConfirmButton: PropTypes.func.isRequired,
    addPartner: PropTypes.func.isRequired,
    isSaveAble: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    useExpireYn: PropTypes.string.isRequired,
    useAgentPcControl: PropTypes.string.isRequired
};

export default injectIntl(ReqPartnerAccountPopup);
