import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

/**
 * 서버 시간과 만료일을 비교하여 차이 반환
 * @param nowDateTime
 * @param targetDate
 * @param utcOffset
 * @returns {string}
 */
function getDiff(nowDateTime, targetDate, utcOffset) {
    const target = moment(targetDate)
        .utcOffset(utcOffset)
        .startOf('day');
    const now = moment(nowDateTime)
        .utcOffset(utcOffset)
        .startOf('day');
    const diff = now.diff(target, 'd');
    return `(D${diff >= 0 ? `+${diff}` : `${diff}`})`;
}

/**
 * YYYYMMDD를 YYYY-MM-DD로변환
 * @param targetDate
 * @param utcOffset
 * @returns {String}
 */
function convert(targetDate, utcOffset) {
    return moment(targetDate)
        .utcOffset(utcOffset)
        .format('YYYY-MM-DD');
}

const ExpiredDate = props => {
    const { file: { config: { drive = {} } = {} } = {}, locale = {}, cellStyle } = props;
    const { expiredDate } = drive;
    const nowDateTime = moment().valueOf();
    const { timezoneDiff = 0 } = locale;
    const hasExpiredDate = !!expiredDate;
    const value = hasExpiredDate ? `${convert(expiredDate, timezoneDiff)} ${getDiff(nowDateTime, expiredDate, timezoneDiff)}` : '-';

    return (
        <div className="grid-cell files-expireDt-cell ellipsis" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

ExpiredDate.defaultProps = {
    cellStyle: null
};

ExpiredDate.propTypes = {
    file: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

ExpiredDate.displayName = 'ExpiredDate';

const mapStateToProps = state => ({
    locale: state.auth.user.locale
});

export default React.memo(connect(mapStateToProps)(ExpiredDate));
