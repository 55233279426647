import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { If } from 'jsx-control-statements';
import { auth as AUTH_ACTIONS, globalPopup as GLOBAL_POPUP_ACTIONS } from 'src/redux/actions';
import { TOKEN_STATUS } from 'src/redux/actions/Auth';
import ModalService from 'utils/ModalService';
import AuthRest from 'apis/AuthRest';
import CharacterImageContainer from 'containers/CharacterImage/CharacterImageContainer';
import NoticeConstants from 'constants/Notice';
import { $http } from 'modules/index';
import ConfigsRest from 'apis/ConfigsRest';
import cookie from '../../../utils/Cookie';

class NameCardWithSettings extends Component {
    constructor(props) {
        super(props);
        const { loginUser } = props;

        this.loginuser = loginUser;

        this.state = {
            isAdmin: false,
            isExt: false,
            isRequestMngView: false,
            driveAdminUrl: '',
            accountConfigUrl: '',
            siteMode: 'normal'
        };
    }

    componentDidMount() {
        $http
            .get(
                `/common/v1/userinfo/userIsAdmin?userId=${this.loginuser.loginId
                    .toString()
                    .substring(0, this.loginuser.loginId.toString().indexOf('@'))}&tenantId=${this.loginuser.tenantId.toString()}`
            )
            .then(response => {
                this.setState({ isAdmin: response.data });
            });

        $http.get(`/auth/v1/configs/cfg/site-name`).then(response => {
            const { loginInfo } = this.props;
            const { ifSsoMethod } = loginInfo;

            const EP6_UTOKEN = cookie.getCookieText('EP6_UTOKEN');
            if (ifSsoMethod === 'SESSION_FILTER' && _.isEmpty(EP6_UTOKEN)) {
                this.setState({ isExt: false });
            } else {
                this.setState({ isExt: response.data.cfgVal === 'EXT_AWS' });
            }
        });

        // 6)
        $http.get(`/auth/v1/configs/cfg/EFL_AUT_REG_PARTNER_ACCOUNT_BY_USER_YN`).then(response => {
            if (response.data.cfgVal === 'Y') {
                this.setState({ isRequestMngView: true });
            }
        });

        // 7)
        $http.get(`/auth/v1/configs/cfg/EFL_AUTH_USER_SUBCONTRACTOR_ACCESS_EXPIRE_DATE_REQUIRED_YN_FOR_MANUAL_CREATION`).then(response => {
            if (response.data.cfgVal === 'Y') {
                this.setState({ isRequestMngView: true });
            }
        });

        // 1), 4), 5)
        $http.get(`/common/v1/configs/cfg/EFL_COM_USE_FLCM_YN`).then(response => {
            if (response.data.cfgVal === 'Y') {
                this.setState({ isRequestMngView: true });
            }
        });

        // 2)
        $http.get(`/common/v1/configs/cfg/EFL_COM_WG_CREATE_USE_YN`).then(r1 => {
            if (r1.data.cfgVal === 'Y') {
                $http.get(`/common/v1/configs/cfg/EFL_COM_WG_CREATE_APPROVE_USE_YN`).then(r2 => {
                    if (r2.data.cfgVal === 'Y') {
                        this.setState({ isRequestMngView: true });
                    }
                });
            }
        });

        // 3)
        $http.get(`/drive/v1/configs/EFL_DRV_AGENT_PC_CTRL_EXCEPT_USE_YN`).then(response => {
            if (response.data !== null) {
                if (response.data === 'Y') {
                    this.setState({ isRequestMngView: true });
                }
            } else {
                console.log('EFL_DRV_AGENT_PC_CTRL_EXCEPT_USE_YN call fail');
            }
        });

        // 8)
        $http.get(`/drive/v1/configs/EFL_DRV_SECURE_FOLDER_USE_REQUEST_YN`).then(response => {
            if (response.data !== null) {
                if (response.data === 'Y') {
                    this.setState({ isRequestMngView: true });
                }
            } else {
                console.log('EFL_DRV_SECURE_FOLDER_USE_REQUEST_YN call fail');
            }
        });

        ConfigsRest.getConfigs().then(response => {
            const driveAdminUrl = _.get(_.find(response, { cfgId: 'EFL_COM_DRIVE_ADMIN_URL_EXTERNAL' }), 'cfgVal', '');
            const membershipPageUrl = _.get(_.find(response, { cfgId: 'EFL_COM_MEMBERSHIP_PAGE_URL_EXTERNAL' }), 'cfgVal', '');
            const accountConfigUrl = _.get(_.find(response, { cfgId: 'EFL_COM_ACCOUNT_CONFIG_URL_EXTERNAL' }), 'cfgVal', '');

            this.setState({ driveAdminUrl, accountConfigUrl, membershipPageUrl });
        });

        ConfigsRest.getProperties().then(response => {
            const siteMode = _.get(_.find(response, { name: 'siteMode' }), 'value', 'normal');
            this.setState({ siteMode });
        });
    }

    logout = () => {
        const { setTokenStatus, loginUser, intl } = this.props;
        const { id } = loginUser;
        ModalService()
            .confirm(intl.formatMessage({ id: 'user.alert.logout' }))
            .then(result => {
                if (result === true) {
                    AuthRest.deleteToken().finally(() => {
                        // const { host } = window.location;
                        // const hostArr = host.split('.');
                        // const domain = `.${hostArr[hostArr.length - 2]}.${hostArr[hostArr.length - 1]}`;
                        localStorage.removeItem(`${NoticeConstants.NOTICE_LOCAL_STORAGE_KEY_PREFIX}${id}`);
                        // 통합 세션에 따른 쿠키값 제거 추가
                        setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: false, isLogout: true } });
                    });
                }
            });
    };

    onClickMenu = url => {
        const { push, pathname, closeNameCardWithSettings } = this.props;

        if (url === pathname) {
            closeNameCardWithSettings();
        } else {
            push(url);
        }
    };

    render() {
        const { isOpen, loginUser, openProfileEdit, intl, clickOutsideRef } = this.props;
        const { isAdmin, isExt, isRequestMngView, driveAdminUrl, accountConfigUrl, membershipPageUrl, siteMode } = this.state;

        return (
            <>
                <div className={`overlay-layer-wrapper slide ${isOpen ? 'show' : ''}`} data-toggle-button="#nav-account" ref={clickOutsideRef}>
                    <div className="overlay-layer">
                        <div className="layer-content">
                            <div className="layer-body">
                                <div className="name-card account">
                                    <div className="item-user-thumbnail">
                                        <CharacterImageContainer
                                            objtId={loginUser.profile.fileId || ''}
                                            updateTime={loginUser.profile.updateTime}
                                            userId={loginUser.id}
                                            text={loginUser.name}
                                            size="lg"
                                            userType={loginUser.sectionCode}
                                        />
                                        <a className="btn-ic-line btn-func-photo" role="button" title={intl.formatMessage({ id: 'user.setting.profile.editProfilePhto' })} onClick={openProfileEdit}>
                                            <i className="ic-24-cam" />
                                        </a>
                                    </div>
                                    <div className="profile">
                                        <div className="g-inline-flex">
                                            <div className="item-user">
                                                <div className="user-profile">
                                                    <div title={loginUser.name.concat(' ').concat(loginUser.jobTitle)}>
                                                        <span className="name">{loginUser.name}</span>
                                                        <span className="position">{loginUser.jobTitle}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <If condition={!_.isEmpty(loginUser.accessRestrictions.expirationTime)}>
                                                <div className="next">
                                                    <em>(~{moment(loginUser.accessRestrictions.expirationTime).format('YYYY-MM-DD')})</em>
                                                </div>
                                                <div className="next divider">
                                                    <a className="btn-link" role="button">
                                                        <FormattedMessage id="user.setting.change" />
                                                    </a>
                                                </div>
                                            </If>
                                        </div>
                                        <div className="partname" title={loginUser.dept.name}>
                                            {loginUser.dept.name}
                                        </div>
                                    </div>
                                    <If condition={!_.isEmpty(loginUser.accessRestrictions.expirationTime)}>
                                        <div className="info-msg">
                                            <i className="ic-16-info" />
                                            <FormattedMessage id="user.gnb.helpMessageForextend" />
                                        </div>
                                    </If>
                                    <div className="menu">
                                        <If condition={isRequestMngView === true}>
                                            <a className="btn-ic-nor" role="button" onClick={() => this.onClickMenu('/req_mng/sent')}>
                                                <i className="ic-20-req" />
                                                <span className="btn-text">
                                                    <FormattedMessage id="user.gnb.manageReq" />
                                                </span>
                                            </a>
                                        </If>
                                        <a className="btn-ic-nor" role="button" onClick={() => this.onClickMenu('/duplicated')}>
                                            <i className="ic-20-dupFiles" />
                                            <span className="btn-text">
                                                <FormattedMessage id="user.text.gnb.duplicated" />
                                            </span>
                                        </a>
                                        <a className="btn-ic-nor" role="button" onClick={() => this.onClickMenu('/settings')}>
                                            <i className="ic-20-setting-account" />
                                            <span className="btn-text">
                                                <FormattedMessage id="user.gnb.setting" />
                                            </span>
                                        </a>
                                        <div className="divider" />
                                        <If condition={siteMode !== 'offwork'}>
                                            <If condition={isAdmin === true && isExt === true}>
                                                <a className="btn-ic-nor" role="button" rel="noopener noreferrer" target="_blank" href={driveAdminUrl}>
                                                    <i className="ic-20-shortcut" />
                                                    <span className="btn-text">
                                                        <FormattedMessage id="com.text.goToDrive" />
                                                    </span>
                                                </a>
                                                <a className="btn-ic-nor" role="button" rel="noopener noreferrer" target="_blank" href={membershipPageUrl}>
                                                    <i className="ic-20-setting-account" />
                                                    <span className="btn-text">
                                                        <FormattedMessage id="com.text.goToMembership" />
                                                    </span>
                                                </a>
                                            </If>
                                            <If condition={isExt === true}>
                                                <a className="btn-ic-nor" role="button" rel="noopener noreferrer" target="_blank" href={accountConfigUrl}>
                                                    <i className="ic-20-setting-account" />
                                                    <span className="btn-text">
                                                        <FormattedMessage id="com.text.accountConfig" />
                                                    </span>
                                                </a>

                                                <div className="divider" />
                                            </If>
                                        </If>
                                        <a className="btn-ic-nor" role="button" onClick={() => this.logout()}>
                                            <i className="ic-20-logout" />
                                            <span className="btn-text">
                                                <FormattedMessage id="user.gnb.signOut" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

NameCardWithSettings.propTypes = {
    isOpen: PropTypes.bool,
    setTokenStatus: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    loginUser: PropTypes.object.isRequired,
    openProfileEdit: PropTypes.func.isRequired,
    closeNameCardWithSettings: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    clickOutsideRef: PropTypes.object,
    loginInfo: PropTypes.object
};

NameCardWithSettings.defaultProps = {
    isOpen: false,
    clickOutsideRef: {},
    loginInfo: {}
};

const mapStateToProps = state => ({
    loginUser: state.auth.user,
    pathname: state.router.location.pathname,
    loginInfo: state.auth.loginInfo
});

const mapDispatchToProps = {
    setTokenStatus: AUTH_ACTIONS.setTokenStatus,
    push: routerPush,
    openProfileEdit: GLOBAL_POPUP_ACTIONS.openProfileEdit
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NameCardWithSettings)
);
