import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { If } from 'jsx-control-statements';
import ApprovalConstants from '../ApprovalConstants';
import FileLifeCycleRest from '../../../../../apis/FileLifeCycleRest';
import BaseApproval from './BaseApproval';
import SingleDatePickerWrapper from '../../../../presentationals/SingleDatePickerWrapper';
import { alert, toast } from '../../../../../utils/ModalService';
import ApprovalUtil from '../ApprovalUtil';
import FlcmUtil from '../../../../../utils/FlcmUtil';
import ExpirationDateSelector from '../../RetentionPeriod/ExpirationDateSelector';

class ExtendsExpiration extends BaseApproval {
    type = ApprovalConstants.TYPE.EXTENDS_EXPIRATION;

    title = 'drive.title.approval.expireFileExcept';

    approverNameId = 'com.guideText.approval.line.docuManager';

    changeByCalendar = false;

    constructor(props) {
        super(props);

        this.expiredDateInfos = FlcmUtil.getExpiredDateInfos(this.rawUser);
        this.newExpiredDate = this.getExpiredDate();
    }

    getExpiredDate() {
        if (this.hasDetail()) {
            return moment(this.detail.reqDtl.newExpiredTime)
                .utcOffset(this.timeZone)
                .format('YYYY-MM-DD');
        }
        return this.expiredDateInfos.initialExpiredDate.format('YYYY-MM-DD');
    }

    async getApproverCandidates() {
        const response = await FileLifeCycleRest.getApproverCandidates(this.systemType);

        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return data;
    }

    async getCandidateLine() {
        const candidates = await this.getApproverCandidates();
        return ApprovalUtil.convertCandidatesToLine(candidates);
    }

    async getOpinionPresets() {
        const response = await FileLifeCycleRest.getReasonPresets('EXPIRATION');
        const { resultCode, data } = response;
        if (resultCode !== 200 || _.isEmpty(data)) {
            return [];
        }
        return _.map(data, preset => {
            let editable = false;
            let opinion = preset.message;
            let minLength = 1;
            let { message } = preset;
            if (preset.key === 'ETC') {
                editable = true;
                opinion = '';
                message += ''; // (${this.intl.formatMessage({ id: 'com.text.approval.requestReason.enter' })})'';
                minLength = 5;
            }
            return _.extend(preset, { editable, opinion, message, minLength });
        });
    }

    convertToTarget(files = []) {
        if (_.isEmpty(files)) return [];
        return _.map(files, tgt => {
            const { drive, config = {} } = tgt;
            const { drive: driveConfig = {} } = config;
            const { expiredDate = '' } = driveConfig;
            return {
                objtId: drive.objtId,
                objtNm: drive.objtNm,
                onpstId: drive.onpstId,
                objtSectCd: 'FILE',
                expiredDate
            };
        });
    }

    openFileLinkUrl = url => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    getFileLinkUrl = objtId => {
        if (!this.hasDetail() || !this.isViewMode() || objtId === '') {
            return '';
        }
        const { linkUrl = [] } = this.detail.reqDtl;
        const linkIdx = _.findIndex(linkUrl, ['objtId', objtId]);
        if (linkIdx === -1 || !linkUrl[linkIdx].url) {
            return '';
        }
        const { url } = linkUrl[linkIdx];
        return url;
    };

    fileBuilder(file) {
        const expiredDate = moment(file.expiredDate)
            .utcOffset(this.timeZone)
            .format('YYYY-MM-DD');

        const fileNameStyle = {
            wordBreak: 'break-all'
        };
        const fileLink = this.getFileLinkUrl(file.objtId);
        const fileClick = fileLink ? this.openFileLinkUrl : _.noop;

        if (fileLink) {
            fileNameStyle.color = '#5959ff';
            fileNameStyle.textDecoration = 'underline';
        } else {
            fileNameStyle.cursor = 'default';
            fileNameStyle.textDecoration = 'none';
        }

        const fontStyle = "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif";
        const borderStyle = '1px solid #ddd';

        return (
            <tr key={`target_file_${file.objtId}`}>
                <td
                    key={`target_file_name_${file.objtId}`}
                    style={{
                        padding: '10px 20px 10px 20px',
                        fontFamily: fontStyle,
                        fontSize: '13px',
                        color: '#666',
                        lineHeight: '17px',
                        textAlign: 'left',
                        borderBottom: borderStyle,
                        wordBreak: 'break-all',
                        verticalAlign: 'top'
                    }}>
                    <a role="button" onClick={() => fileClick(fileLink)} style={fileNameStyle}>
                        {file.objtNm}
                    </a>
                </td>
                {false && (
                    <td
                        key={`target_file_expiredDate_${file.objtId}`}
                        style={{
                            padding: '10px 20px 10px 0px',
                            fontFamily: fontStyle,
                            fontSize: '13px',
                            color: '#666',
                            lineHeight: '17px',
                            textAlign: 'left',
                            borderBottom: borderStyle,
                            wordBreak: 'break-all',
                            verticalAlign: 'top'
                        }}>
                        {expiredDate}
                    </td>
                )}
            </tr>
        );
    }

    filesBuilder() {
        return _.map(this.target, file => this.fileBuilder(file));
    }

    beforeContentBuilder() {
        if (this.isViewMode()) return <></>;

        const onChangeExpiredDate = date => {
            const newDate = date.format('YYYY-MM-DD');
            this.newExpiredDate = newDate;
            this.setContents({
                newExpiredDate: newDate
            });

            this.checkSubmittable();
        };

        const expiredDate = moment(this.newExpiredDate);

        const { period, unitRscId } = FlcmUtil.getExpiredPeriodLabel(this.rawUser.config);

        return (
            <div className="dt-width-120">
                <dl className="row-list">
                    <dt>
                        <FormattedMessage id="drive.text.approval.expireFileExcept.changeDate" />
                    </dt>
                    <dd>
                        <div className="forms-container">
                            <SingleDatePickerWrapper
                                initialDate={expiredDate}
                                date={expiredDate}
                                minDate={this.expiredDateInfos.minExpiredDate}
                                maxDate={this.expiredDateInfos.maxExpiredDate}
                                onDateChange={date => {
                                    onChangeExpiredDate(date);
                                    this.changeByCalendar = true;
                                }}
                            />
                            <ExpirationDateSelector
                                onSelectExpirationDate={onChangeExpiredDate}
                                onResetChangeByCalendar={() => {
                                    this.changeByCalendar = false;
                                }}
                                changeByCalendar={this.changeByCalendar}
                            />
                        </div>
                        <div className="info-msg">
                            <i className="ic-16-info" />
                            <FormattedMessage id="drive.guideText.approval.expireFileExcept.timezone" />
                        </div>
                        <If condition={period && unitRscId}>
                            <div className="info-msg">
                                <i className="ic-16-info" />
                                <FormattedMessage id="drive.guideText.exception.expirationDate2" values={{ n: period, unit: this.intl.formatMessage({ id: unitRscId }) }} />
                            </div>
                        </If>
                    </dd>
                </dl>
            </div>
        );
    }

    contentBuilder() {
        const fontStyle = "'Malgun Ghothic','맑은 고딕','돋움',Dotum,Arial,Sans-serif";
        const borderStyle = '1px solid #ddd';
        return (
            <div
                style={{
                    margin: '0',
                    padding: '0',
                    boxSizing: 'content-box'
                }}>
                <table width="672" cellSpacing="0" cellPadding="0" border="0">
                    <tbody>
                        <tr>
                            <td height="48" style={{ verticalAlign: 'top' }}>
                                <h2
                                    style={{
                                        margin: '13px 0 0',
                                        padding: '0',
                                        fontFamily: fontStyle,
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: '#1a1a1a',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        wordBreak: 'break-all',
                                        verticalAlign: 'top'
                                    }}>
                                    <FormattedMessage id="drive.title.approval.expireFileExcept" />
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td height="2" style={{ background: '#000' }} />
                        </tr>
                        {this.isViewMode() && (
                            <tr>
                                <td>
                                    <table
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border="0"
                                        style={{
                                            width: '100%',
                                            borderBottom: borderStyle
                                        }}>
                                        <tbody>
                                            <tr>
                                                <th
                                                    width="70"
                                                    style={{
                                                        padding: '12px 20px',
                                                        fontFamily: fontStyle,
                                                        fontSize: '13px',
                                                        color: '#666',
                                                        lineHeight: '22px',
                                                        textAlign: 'left',
                                                        background: '#fafafa',
                                                        wordBreak: 'break-all',
                                                        verticalAlign: 'top',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                    <FormattedMessage id="drive.text.approval.expireFileExcept.changeDate" />
                                                </th>
                                                <td
                                                    style={{
                                                        padding: '12px 20px',
                                                        fontFamily: fontStyle,
                                                        fontSize: '13px',
                                                        color: '#1a1a1a',
                                                        lineHeight: '22px',
                                                        textAlign: 'left',
                                                        wordBreak: 'break-all',
                                                        verticalAlign: 'top'
                                                    }}>
                                                    {this.newExpiredDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td height="42" style={{ padding: '0 20px', verticalAlign: 'top' }}>
                                <h4
                                    style={{
                                        margin: '13px 0 0',
                                        padding: '0',
                                        fontFamily: fontStyle,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        color: '#666',
                                        lineHeight: '22px',
                                        textAlign: 'left',
                                        wordBreak: 'break-all',
                                        verticalAlign: 'top'
                                    }}>
                                    <FormattedMessage id="drive.text.approval.expireFileExcept.targetFiles" />
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '0 20px', verticalAlign: 'top' }}>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    border="0"
                                    style={{
                                        width: '100%',
                                        borderTop: borderStyle,
                                        borderLeft: borderStyle,
                                        borderRight: borderStyle,
                                        tableLayout: 'fixed'
                                    }}>
                                    <tbody>
                                        <tr>
                                            <th
                                                width="470"
                                                style={{
                                                    padding: '6px 20px 8px 20px',
                                                    fontWeight: 'normal',
                                                    fontFamily: fontStyle,
                                                    fontSize: '12px',
                                                    color: '#666',
                                                    lineHeight: '16px',
                                                    textAlign: 'left',
                                                    borderBottom: borderStyle,
                                                    wordBreak: 'break-all',
                                                    verticalAlign: 'top'
                                                }}>
                                                <FormattedMessage id="drive.text.approval.expireFileExcept.fileName" />
                                            </th>
                                            {false && (
                                                <th
                                                    style={{
                                                        padding: '6px 20px 8px 0px',
                                                        fontWeight: 'normal',
                                                        fontFamily: fontStyle,
                                                        fontSize: '12px',
                                                        color: '#666',
                                                        lineHeight: '16px',
                                                        textAlign: 'left',
                                                        borderBottom: borderStyle,
                                                        wordBreak: 'break-all',
                                                        verticalAlign: 'top'
                                                    }}>
                                                    <FormattedMessage id="drive.text.approval.expireFileExcept.expiredDate" />
                                                </th>
                                            )}
                                        </tr>
                                        {this.filesBuilder()}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '15px 0 13px' }}>
                                <div style={{ height: '2px', background: '#000' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    isSubmittable() {
        const selectedDate = moment(this.newExpiredDate).utcOffset(this.timeZone);
        return (
            super.isSubmittable() &&
            this.target.length > 0 &&
            this.newExpiredDate !== '' &&
            selectedDate.startOf('day').isAfter(
                moment()
                    .utcOffset(this.timeZone)
                    .startOf('day')
            )
        );
    }

    submitAction() {
        const approvers = ApprovalUtil.convertLineToTargetUsers(this.getLine());
        const fileIds = _.map(this.target, file => file.objtId);
        const expiredDate = this.newExpiredDate;
        const params = {
            fileIds,
            expiredDate,
            approvers,
            reason: this.draftOpinion,
            searchTgt: this.systemType,
            renewalReason: this.draftOpinionKey
        };

        return FileLifeCycleRest.saveRequest('EXPIRATION', params);
    }

    processResultMessage(response = {}) {
        const { message, resultCode } = response;
        if (message === 'REQ_INVALID_DATE') {
            toast('drive.alert.ReqExtendExpiredDtPop.invalidExpiredDate');
            return true;
        }
        if (resultCode === 20554) {
            alert('drive.alert.ReqExtendExpiredDtPop.noauth');
            return true;
        }
        return false;
    }
}

export default ExtendsExpiration;
