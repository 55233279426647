import produce from 'immer';
import { SET_POLICY } from '../actions/FileLifeCycle';

const initialState = {
    policy: {}
};

const fileLifeCycle = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_POLICY:
            return produce(state, draft => {
                draft.policy = action.policy;
            });
        default:
            return state;
    }
};

export default fileLifeCycle;
