import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { withFilesContext } from '../DriveFilesContext';

const SearchContents = props => {
    const {
        file: { extra = {} },
        context: {
            props: { searchParams }
        }
    } = props;
    const { matchedContents = [] } = extra;
    const { q = '' } = searchParams;
    const qQueries = q
        ? q
              .split(' ')
              .map(qq => qq.trim())
              .filter(qq => qq)
        : [];
    const text = `... ${_.join(matchedContents, ' ... ')} ...`;

    return (
        <div className="result-detail">
            <Highlighter searchWords={qQueries} autoEscape={true} textToHighlight={text} />
        </div>
    );
};

SearchContents.propTypes = {
    file: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
};

SearchContents.displayName = 'SearchContents';
export default withFilesContext(SearchContents, []);
