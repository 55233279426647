// action type
const PREFIX = 'SITE_';
const SET_LOGO = PREFIX.concat('SET_LOGO');
const SET_SOLUTION_NAME = PREFIX.concat('SET_SOLUTION_NAME');

const SET_SITE_MODE = PREFIX.concat('SET_SITE_MODE');

export const setSiteMode = siteMode => ({
    type: SET_SITE_MODE,
    siteMode
});

export default { SET_LOGO, SET_SOLUTION_NAME, SET_SITE_MODE };
