export const SUMMARY_RESULTS_COUNT_LIMIT = 5;
export const SUMMARY_FILE_COUNT_LIMIT = 2;

export const SUMMARY_MESSAGE_TYPE = {
    user: 'user',
    ai: 'ai',
    recommendedPrompt: 'recommended_prompt'
};

export const AI_MESSAGE_STATUS = {
    pending: 'pending',
    done: 'done'
};

export const AI_MESSAGE_RESULT_STATUS = {
    waitForResult: 'waitForResult',
    fetchStop: 'fetchStop',
    fetchComplete: 'fetchComplete',
    prepareToStopTyping: 'prepareToStopTyping',
    typingStop: 'typingStop',
    typingComplete: 'typingComplete',
    impossible: 'impossible',
    error: 'error'
};

// 중단 가능 상태
export const STOPABLE_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.waitForResult, AI_MESSAGE_RESULT_STATUS.fetchComplete];
// 다시 생성 가능 상태
export const REGENERATABLE_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.fetchStop, AI_MESSAGE_RESULT_STATUS.typingStop, AI_MESSAGE_RESULT_STATUS.typingComplete];
// 성공 상태
export const SUCCESS_RESULT_STATUS_LIST = [
    AI_MESSAGE_RESULT_STATUS.fetchComplete,
    AI_MESSAGE_RESULT_STATUS.prepareToStopTyping,
    AI_MESSAGE_RESULT_STATUS.typingStop,
    AI_MESSAGE_RESULT_STATUS.typingComplete
];
// 에러 상태
export const ERROR_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.error, AI_MESSAGE_RESULT_STATUS.impossible];
// 중단 상태
export const STOP_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.fetchStop, AI_MESSAGE_RESULT_STATUS.typingStop];
// 진행 중 상태
export const IN_PROGRESS_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.waitForResult, AI_MESSAGE_RESULT_STATUS.fetchComplete, AI_MESSAGE_RESULT_STATUS.prepareToStopTyping];
// 복사 가능 상태
export const COPIABLE_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.typingStop, AI_MESSAGE_RESULT_STATUS.typingComplete];
// 요약 옵션 표시 가능 상태
export const OPTION_SHOWABLE_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.fetchComplete, AI_MESSAGE_RESULT_STATUS.typingStop, AI_MESSAGE_RESULT_STATUS.typingComplete];
// 단순 요약 내용 상태(Non typing)
export const PLAIN_TEXT_RESULT_STATUS_LIST = [AI_MESSAGE_RESULT_STATUS.typingStop, AI_MESSAGE_RESULT_STATUS.typingComplete];

export const SUMMARY_RESPONSE_CODE = {
    success: 'IOFFICE_SUCCESS',
    fileNotAllowed: 'SUMMARY_FILE_NOT_ALLOWED',
    fileNotFound: 'SUMMARY_FILE_NOT_FOUND',
    notSupportedExtType: 'SUMMARY_NOT_SUPPORTED_EXT_TYPE',
    textNotExist: 'SUMMARY_TEXT_NOT_EXIST',
    textSizeLimitExceeded: 'SUMMARY_TEXT_SIZE_LIMIT_EXCEEDED',
    llmInfoNotFound: 'SUMMARY_LLM_INFO_NOT_FOUND',
    jobInProgress: 'SUMMARY_JOB_IN_PROGRESS',
    jobNotFound: 'SUMMARY_JOB_NOT_FOUND',
    llmTypeNotAllowed: 'SUMMARY_LLM_TYPE_NOT_ALLOWED'
};

export const SUMMARY_RESULT_CODE = {
    init: 'init',
    processing: 'processing',
    complete: 'complete',
    timeout: 'timeout',
    blocked: 'blocked',
    llmError: 'llmError',
    textSizeSkip: 'textSizeSkip',
    kwrdNotRelated: 'kwrdNotRelated',
    unexpectedError: 'unexpectedError'
};

export const REGENERATABLE_ERROR_CODES = [SUMMARY_RESPONSE_CODE.textNotExist, SUMMARY_RESULT_CODE.timeout];

export const SUMMARY_FEEDBACK_TYPE = {
    like: 'like',
    dislike: 'dislike'
};

export const SUMMARY_PROMPT_TEMPLATE_TYPE = {
    single: 'single',
    singleWithKeyword: 'singleWithKeyword',
    multiple: 'multiple',
    multipleWithKeyword: 'multipleWithKeyword'
};

export const SUMMARY_FILE_LIST_TYPE = {
    retrieveRecentFiles: 'retrieveRecentFiles',
    searchByFileName: 'searchByFileName'
};

export const SUMMARY_TOOLTIP_DIRECTION = {
    left: 'left',
    right: 'right',
    top: 'top',
    bottom: 'bottom'
};

export const SUMMARY_GENERATION_MODE = {
    generation: 'generation',
    regeneration: 'regeneration',
    conditional: 'conditional'
};

export const SUMMARY_THREAD_STATUS = {
    running: 'running',
    ready: 'ready'
};

export const LANGUAGE_CODE = {
    korean: '001',
    english: '002',
    deutsch: '003',
    chinese: '004'
};
