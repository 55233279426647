import produce from 'immer';
import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
    AI_MESSAGE_RESULT_STATUS,
    AI_MESSAGE_STATUS,
    SUMMARY_MESSAGE_TYPE,
    SUMMARY_RESULT_CODE,
    SUMMARY_RESULTS_COUNT_LIMIT,
    SUMMARY_THREAD_STATUS
} from '../../../components/containers/Summary/Common/SummaryConstant';

const getActiveAIMessage = summaryMessages => _.findLast(summaryMessages, ({ type }) => type === SUMMARY_MESSAGE_TYPE.ai);
const getActiveRecommendedPromptIndex = summaryMessages => _.findLastIndex(summaryMessages, ({ type }) => type === SUMMARY_MESSAGE_TYPE.recommendedPrompt);
const getLatestSummaryResult = ({ summaryResults }) => summaryResults[summaryResults.length - 1];

export default handleActions(
    {
        thread: {
            load: (state, { payload }) => {
                return produce(state, draft => {
                    draft.summaryMessages = payload.summaryMessages;
                });
            },
            clear: state => {
                return produce(state, draft => {
                    draft.summaryMessages = [];
                });
            },
            generateUserMessage: (state, { payload }) => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.running;

                    draft.summaryMessages.push({
                        type: SUMMARY_MESSAGE_TYPE.user,
                        ...payload.summaryMessage
                    });

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    if (!_.isNil(activeAIMessage)) {
                        activeAIMessage.archived = true;
                    }

                    const activeRecommendedPromptIndex = getActiveRecommendedPromptIndex(draft.summaryMessages);

                    if (activeRecommendedPromptIndex !== -1) {
                        draft.summaryMessages.splice(activeRecommendedPromptIndex, 1);
                    }
                });
            },
            generateRecommendedPrompt: state => {
                return produce(state, draft => {
                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    const { files, summaryOptions } = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];

                    draft.summaryMessages.push({
                        type: SUMMARY_MESSAGE_TYPE.recommendedPrompt,
                        files,
                        summaryOptions
                    });
                });
            },
            selectFeedback: (state, { payload }) => {
                return produce(state, draft => {
                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    const summaryResult = activeAIMessage.summaryResults[payload.summaryResultSeq - 1];
                    summaryResult.feedbackType = payload.feedbackType;
                });
            },
            generateAIMessage: (state, { payload }) => {
                return produce(state, draft => {
                    const { id, files, summaryOptions } = payload.summaryMessage;

                    draft.summaryMessages.push({
                        type: SUMMARY_MESSAGE_TYPE.ai,
                        aiMessageStatus: AI_MESSAGE_STATUS.pending,
                        summaryResults: [
                            {
                                resultStatus: AI_MESSAGE_RESULT_STATUS.waitForResult,
                                files,
                                summaryOptions
                            }
                        ],
                        id
                    });
                });
            },
            regenerateAIMessage: (state, { payload = {} }) => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.running;

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.pending;

                    if (activeAIMessage.summaryResults.length >= SUMMARY_RESULTS_COUNT_LIMIT) {
                        activeAIMessage.summaryResults.splice(0, 1);
                    }

                    const { files, summaryOptions } = getLatestSummaryResult(activeAIMessage);

                    activeAIMessage.summaryResults.push({
                        resultStatus: AI_MESSAGE_RESULT_STATUS.waitForResult,
                        summaryOptions: _.isNil(payload.changedSummaryOptions) ? summaryOptions : { ...summaryOptions, ...payload.changedSummaryOptions },
                        files
                    });

                    const activeRecommendedPromptIndex = getActiveRecommendedPromptIndex(draft.summaryMessages);

                    if (activeRecommendedPromptIndex !== -1) {
                        draft.summaryMessages.splice(activeRecommendedPromptIndex, 1);
                    }
                });
            },
            stopFetchAIMessage: state => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.ready;

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.resultStatus = AI_MESSAGE_RESULT_STATUS.fetchStop;
                });
            },
            prepareToStopTypingAIMessage: state => {
                return produce(state, draft => {
                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.resultStatus = AI_MESSAGE_RESULT_STATUS.prepareToStopTyping;
                });
            },
            stopTypingAIMessage: (state, { payload }) => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.ready;

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.resultStatus = AI_MESSAGE_RESULT_STATUS.typingStop;
                    summaryResult.summaryText = payload.summaryText;
                });
            },
            showErrorAIMessage: (state, { payload }) => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.ready;

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.errorCode = payload.errorCode;
                    summaryResult.errorMessage = payload.errorMessage;
                    summaryResult.resultStatus = payload.errorCode === SUMMARY_RESULT_CODE.blocked ? AI_MESSAGE_RESULT_STATUS.impossible : AI_MESSAGE_RESULT_STATUS.error;
                });
            },
            completeToFetchAIMessage: (state, { payload }) => {
                return produce(state, draft => {
                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const { summaryText, summaryDateTime } = payload.summaryResult;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.resultStatus = AI_MESSAGE_RESULT_STATUS.fetchComplete;
                    summaryResult.summaryText = summaryText;
                    summaryResult.summaryDateTime = summaryDateTime;
                });
            },
            completeToTypeAIMessage: state => {
                return produce(state, draft => {
                    draft.threadStatus = SUMMARY_THREAD_STATUS.ready;

                    const activeAIMessage = getActiveAIMessage(draft.summaryMessages);

                    activeAIMessage.aiMessageStatus = AI_MESSAGE_STATUS.done;

                    const summaryResult = activeAIMessage.summaryResults[activeAIMessage.summaryResults.length - 1];
                    summaryResult.resultStatus = AI_MESSAGE_RESULT_STATUS.typingComplete;
                });
            }
        }
    },
    {
        summaryMessages: []
    },
    { prefix: 'summary' }
);
