import { $http } from 'modules/index';

class HistoryRest {
    static getFileFolderHistory(objtId, onpstId, evtSectCd, searchAfter) {
        const params = {
            onpstId,
            evtSectCd,
            searchAfter
        };
        return $http.get(`/drive/v1/history/${objtId}`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}

export default HistoryRest;
