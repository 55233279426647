import ActionType from '../actions/Loading';

const initialState = {
    loadings: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionType.OPEN:
            return {
                loadings: Object.assign({}, state.loadings, { [action.key]: true })
            };
        case ActionType.CLOSE: {
            const loadings = Object.assign({}, state.loadings);
            delete loadings[action.key];
            return {
                loadings
            };
        }
        default:
            return state;
    }
};
