import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import FilterKeyword from './FilterKeyword';
import FilterDate from './FilterDate';
import SelectBox from '../SelectBox';
import { popup, confirm } from '../../../utils/ModalService';
import FolderSelectPopup from '../../containers/Popup/FolderSelect/FolderSelectPopup';
import FoldersRest from '../../../apis/FoldersRest';
import FileIconStyle from '../../../utils/FileIconStyle';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        const { params: parentParams = {}, query, intl } = props;
        this.state = Object.assign({}, parentParams, { q: query, selectedFolder: {} });
        const filterGroup = FileIconStyle.getFilterGroup();
        this.options = [{ message: intl.formatMessage({ id: 'drive.filter.search.extension.all' }), value: 'all' }];
        filterGroup.forEach((value, key) => {
            this.options.push({ message: key, value: `${value}` });
        });
        this.options.push({ message: intl.formatMessage({ id: 'drive.filter.search.extension.folder' }), value: 'folder' });

        if (parentParams.objtSectCd === 'FOLDER') {
            this.state.extensions = 'folder';
        }

        this.allFolders = intl.formatMessage({ id: 'drive.text.selectFolderPop.all' });
        this.sharedFiles = intl.formatMessage({ id: 'drive.button.search.filter.path.shared' });
        this.sharingFiles = intl.formatMessage({ id: 'drive.button.search.filter.path.sharing' });
        this.workgroups = intl.formatMessage({ id: 'drive.button.search.filter.path.workgroup' });
        this.myFiles = intl.formatMessage({ id: 'drive.button.search.filter.path.own' });
    }

    componentDidMount() {
        this.initSelectedFolder();
    }

    selectBoxOnChange = value => {
        this.setState({ extensions: value });
    };

    onChange = value => {
        this.setState(value);
    };

    initSelectedFolder = () => {
        const { params } = this.props;
        const { onpstFolderId, mine, onpstId, onpstType } = params;
        if (mine === 'true') {
            this.setState({ selectedFolder: { objtId: 'own', objtNm: this.myFiles } });
        } else if (mine === 'false') {
            if (onpstType === 'SYSTFOLDER') {
                this.setState({ selectedFolder: { objtId: 'workgroup', objtNm: this.workgroups } });
            } else if (onpstType === 'SHAREDFOLDER') {
                this.setState({ selectedFolder: { objtId: 'shared', objtNm: this.sharedFiles } });
            } else {
                this.setState({ selectedFolder: { objtId: 'sharing', objtNm: this.sharingFiles } });
            }
        } else if (onpstFolderId) {
            FoldersRest.getPair([{ drive: { objtId: onpstFolderId, onpstId } }], false).then(files => {
                if (files && files.length) {
                    this.setState({ selectedFolder: { objtId: files[0].drive.objtId, objtNm: files[0].drive.objtNm } });
                }
            });
        } else {
            this.setState({ selectedFolder: { objtId: 'all', objtNm: this.allFolders } });
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { params } = nextProps;
        const { params: preParams } = this.props;
        if (params !== preParams) {
            Object.assign(nextState, this.getInitParams());
        }
        return true;
    }

    getInitParams() {
        const newParams = {};
        const params = { selectedFolder: { objtId: 'all', objtNm: this.allFolders }, extensions: 'all' };
        Object.keys(this.state).forEach(key => {
            newParams[key] = params[key];
        });

        return newParams;
    }

    init = () => {
        const { openInnerPopup, initParams } = this.props;
        // 검색 필터를 초기화 하시겠습니까?
        confirm(<FormattedMessage id="drive.confirm.search.filter.init" />).then(
            result => {
                if (result) {
                    initParams();
                    this.setState(this.getInitParams());
                }
                openInnerPopup(false);
            },
            () => {
                openInnerPopup(false);
            }
        );
        openInnerPopup(true);
    };

    openFolderSelectPopup = () => {
        const { openInnerPopup } = this.props;
        popup(<FolderSelectPopup mode="search" />).then(
            selectedFolder => {
                const { objtId, objtNm } = selectedFolder;
                this.setState({ selectedFolder: { objtId, objtNm: objtId === 'all' ? this.allFolders : objtNm } });
                openInnerPopup(false);
            },
            () => {
                openInnerPopup(false);
            }
        );
        openInnerPopup(true);
    };

    goSearch = () => {
        const { goSearchPage, loginUser } = this.props;
        const { selectedFolder: folder, extensions } = this.state;
        const params = Object.assign({}, this.state);

        Object.keys(params).forEach(k => {
            if (typeof params[k] === 'string') params[k] = params[k].trim();
        });

        params.utcOffset = loginUser.locale.timezoneDiff;

        delete params.selectedFolder;
        delete params.mine;
        delete params.onpstFolderId;
        delete params.onpstId;
        delete params.onpstType;
        if (folder && folder.objtId) {
            if (folder.objtId === 'own') {
                params.mine = 'true';
            } else if (folder.objtId === 'shared') {
                params.mine = 'false';
                params.onpstType = 'SHAREDFOLDER';
            } else if (folder.objtId === 'sharing') {
                params.mine = 'false';
                params.onpstType = 'SHARINGFOLDER';
            } else if (folder.objtId === 'workgroup') {
                params.mine = 'false';
                params.onpstType = 'SYSTFOLDER';
            } else if (folder.objtId !== 'all') {
                params.onpstFolderId = folder.objtId;
                params.onpstId = folder.onpstId;
            }
        }

        delete params.objtSectCd;
        if (extensions === 'all') {
            delete params.extensions;
        } else if (extensions === 'folder') {
            delete params.extensions;
            params.objtSectCd = 'FOLDER';
        }

        goSearchPage(params);
    };

    render() {
        const { close, loginUser } = this.props;
        const { q = '', onpstNm = '', objtStatChgUserNm = '', tagInfoVal = '', selectedFolder, extensions = '' } = this.state;
        let { objtStatChgDtFrom, objtStatChgDtTo, objtExpirationDtFrom, objtExpirationDtTo } = this.state;
        if (objtStatChgDtFrom) objtStatChgDtFrom = moment(objtStatChgDtFrom);
        if (objtStatChgDtTo) objtStatChgDtTo = moment(objtStatChgDtTo);
        if (objtExpirationDtFrom) objtExpirationDtFrom = moment(objtExpirationDtFrom);
        if (objtExpirationDtTo) objtExpirationDtTo = moment(objtExpirationDtTo);

        const { config = {} } = loginUser;
        const { useFlcm = false, useCstdPolicy = false } = config;
        const isUseExpiration = useFlcm && useCstdPolicy;

        return (
            <div className="overlay-layer-wrapper slide show">
                <div className="overlay-layer">
                    <div className="layer-content">
                        <div className="layer-header">
                            <h1 className="layer-title">
                                <FormattedMessage id="drive.text.search.filter.title" />
                            </h1>
                            <a id="quick-search-filter-close" className="btn-ic-nor" role="button" title="닫기" onClick={close}>
                                <i className="ic-20-close" />
                            </a>
                        </div>
                        <div className="layer-body">
                            <div className="dt-width-120">
                                <FilterKeyword
                                    id="q"
                                    nameKey="drive.text.search.filter.query"
                                    onChange={this.onChange}
                                    value={q}
                                    placeholderKey="drive.placeHolder.search.filter.query"
                                    guideKey="drive.guideText.search.filter.query"
                                />

                                <dl className="row-list">
                                    <dt>
                                        <FormattedMessage id="drive.text.search.filter.type" />
                                    </dt>
                                    <dd>
                                        <SelectBox className="selectpicker" type="box" options={this.options} onChange={this.selectBoxOnChange} value={extensions} />
                                    </dd>
                                </dl>
                                <dl className="row-list">
                                    <dt>
                                        <FormattedMessage id="drive.text.search.filter.path" />
                                    </dt>
                                    <dd>
                                        <a className="btn-ic-line" role="button" onClick={this.openFolderSelectPopup}>
                                            <i className="ic-20-folder" />
                                            <span className="btn-text">{selectedFolder.objtNm}</span>
                                        </a>
                                    </dd>
                                </dl>
                                <FilterKeyword
                                    id="onpstNm"
                                    nameKey="drive.text.search.filter.onpstNm"
                                    onChange={this.onChange}
                                    value={onpstNm}
                                    placeholderKey="drive.placeHolder.search.filter.onpstNm"
                                />
                                <FilterKeyword
                                    id="objtStatChgUserNm"
                                    nameKey="drive.text.search.filter.objtStatUserNm"
                                    onChange={this.onChange}
                                    value={objtStatChgUserNm}
                                    placeholderKey="drive.placeHolder.search.filter.objtStatUserNm"
                                />
                                <dl className="row-list">
                                    <dt>
                                        <FormattedMessage id="drive.text.search.filter.objtStatChgDt" />
                                    </dt>
                                    <dd>
                                        <FilterDate
                                            startDate={objtStatChgDtFrom}
                                            endDate={objtStatChgDtTo}
                                            onDatesChange={({ startDate, endDate }) => {
                                                const f = 'YYYY-MM-DD';
                                                let vStartDate = startDate;
                                                if (vStartDate) {
                                                    vStartDate = vStartDate.format(f);
                                                }
                                                let vEndDate = endDate;
                                                if (vEndDate) {
                                                    vEndDate = vEndDate.format(f);
                                                }
                                                this.onChange({ objtStatChgDtFrom: vStartDate, objtStatChgDtTo: vEndDate });
                                            }}
                                            minDate={moment().add(-10, 'years')}
                                            maxDate={moment().add(1, 'days')}
                                        />
                                    </dd>
                                </dl>
                                {isUseExpiration && (
                                    <dl className="row-list">
                                        <dt>
                                            <FormattedMessage id="com.expiration-date" />
                                        </dt>
                                        <dd>
                                            <FilterDate
                                                startDate={objtExpirationDtFrom}
                                                endDate={objtExpirationDtTo}
                                                onDatesChange={({ startDate, endDate }) => {
                                                    const f = 'YYYYMMDD';
                                                    let vStartDate = startDate;
                                                    if (vStartDate) {
                                                        vStartDate = vStartDate.format(f);
                                                    }
                                                    let vEndDate = endDate;
                                                    if (vEndDate) {
                                                        vEndDate = vEndDate.format(f);
                                                    }
                                                    this.onChange({ objtExpirationDtFrom: vStartDate, objtExpirationDtTo: vEndDate });
                                                }}
                                                minDate={moment().add(-10, 'years')}
                                                maxDate={moment().add(10, 'years')}
                                            />
                                        </dd>
                                    </dl>
                                )}
                                <FilterKeyword id="tagInfoVal" nameKey="drive.text.search.filter.tag" onChange={this.onChange} value={tagInfoVal} placeholderKey="drive.placeHolder.search.filter.tag">
                                    <span className="btn-ic-nor">
                                        <i className="ic-16-hash" />
                                    </span>
                                </FilterKeyword>
                            </div>
                        </div>
                        <div className="layer-footer">
                            <div className="layer-footer-option">
                                <a className="btn-ic-line btn-lg" role="button" onClick={this.init}>
                                    <i className="ic-20-reset" />
                                    <span className="btn-text">
                                        <FormattedMessage id="com.reset" />
                                    </span>
                                </a>
                            </div>
                            <div className="layer-footer-btns">
                                <a className="btn btn-lg btn-secondary" role="button" onClick={close}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.cancel" />
                                    </span>
                                </a>
                                <a className="btn btn-lg btn-primary" role="button" onClick={this.goSearch}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.search" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Filter.defaultProps = {
    params: undefined,
    query: undefined
};

Filter.propTypes = {
    params: PropTypes.object,
    query: PropTypes.string,
    initParams: PropTypes.func.isRequired,
    openInnerPopup: PropTypes.func.isRequired,
    goSearchPage: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(Filter));
