import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { Choose, When } from 'jsx-control-statements';
import classnames from 'classnames';
import { STOP_RESULT_STATUS_LIST, AI_MESSAGE_RESULT_STATUS } from '../../../../Common/SummaryConstant';

const SummaryFailureResult = ({ summaryResult }) => {
    const { resultStatus, errorMessage } = summaryResult;

    const getClassNameByResultStatus = () => {
        if (STOP_RESULT_STATUS_LIST.includes(resultStatus)) {
            return 'ui-loading-stop';
        }

        if (resultStatus === AI_MESSAGE_RESULT_STATUS.impossible) {
            return 'ui-loading-danger';
        }

        if (resultStatus === AI_MESSAGE_RESULT_STATUS.error) {
            return 'ui-loading-error';
        }

        return '';
    };

    return (
        <div className={classnames('ai-loading', getClassNameByResultStatus())}>
            <p className="ai-loading-text" style={{ whiteSpace: 'pre-wrap' }}>
                <Choose>
                    <When condition={resultStatus === AI_MESSAGE_RESULT_STATUS.fetchStop}>
                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.status.stop" />
                    </When>
                    <When condition={resultStatus === AI_MESSAGE_RESULT_STATUS.error}>{errorMessage}</When>
                    <When condition={resultStatus === AI_MESSAGE_RESULT_STATUS.impossible}>
                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.status.error.blocked" />
                    </When>
                </Choose>
            </p>
        </div>
    );
};

SummaryFailureResult.propTypes = {
    summaryResult: PropTypes.shape({
        resultStatus: PropTypes.string.isRequired,
        files: PropTypes.object.isRequired, // TODO
        summaryOptions: PropTypes.object.isRequired,
        summaryText: PropTypes.string,
        summaryDateTime: PropTypes.string,
        errorMessage: PropTypes.string
    }).isRequired
};

export default SummaryFailureResult;
