/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import ApprovalConstants from './ApprovalConstants';
import SelectBox from '../../../presentationals/SelectBox';
import moment from 'moment';
import ApprovalLineUserInput from "./ApprovalLineUserInput";
import ApprovalUtil from './ApprovalUtil';

const LINE_USER_CLASS_NAME = {
    [ApprovalConstants.LINE_TYPE.DRAFT]: '0',
    [ApprovalConstants.LINE_TYPE.APPROVAL]: '1',
    [ApprovalConstants.LINE_TYPE.CONSENT]: '2',
    [ApprovalConstants.LINE_TYPE.NOTIFY]: '3',
    [ApprovalConstants.LINE_TYPE.PARALLEL_APPROVAL]: '1',
    [ApprovalConstants.LINE_TYPE.PARALLEL_CONSENT]: '2'
};

const LINE_USER_LANG_ID = {
    [ApprovalConstants.LINE_TYPE.DRAFT]: 'draft',
    [ApprovalConstants.LINE_TYPE.APPROVAL]: 'approval',
    [ApprovalConstants.LINE_TYPE.CONSENT]: 'consent',
    [ApprovalConstants.LINE_TYPE.NOTIFY]: 'notification',
    [ApprovalConstants.LINE_TYPE.PARALLEL_APPROVAL]: 'parallelApproval',
    [ApprovalConstants.LINE_TYPE.PARALLEL_CONSENT]: 'parallelConsent'
};

const LINE_USER_STAT_CD_CLASS_NAME = {
    [ApprovalConstants.STAT_CODE.APPROVAL]: '4',
    [ApprovalConstants.STAT_CODE.REJECT]: '2',
};

const LINE_USER_STAT_CD_LANG_ID = {
    [ApprovalConstants.STAT_CODE.APPROVAL]: 'approved',
    [ApprovalConstants.STAT_CODE.CANCEL]: 'cancelled',
    [ApprovalConstants.STAT_CODE.REJECT]: 'rejected',
};

const ApprovalLine = props => {
    const { intl, typeClass } = props;

    const [line, setLine] = useState({});
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        async function fetchLine() {
            let line;
            if (!typeClass.isViewMode()) {
                line = await typeClass.getCandidateLine();
                console.log('line', line);
            } else {
                line = await typeClass.getRequestedLine();
            }
            setLine(line);
            typeClass.setLine(line);
        }
        fetchLine();
    }, []);

    const getUser = (pIndex = 0, rIndex = 0, user = {}, type = ApprovalConstants.LINE_TYPE.APPROVAL) => {
        const { userId, userNm, userCopNm = '', deptNm = '', opinion = '', statCd = ApprovalConstants.STAT_CODE.PROCESSING, actDt = '' } = user;
        const title = `${userNm} ${userCopNm} / ${deptNm}`;
        const position = `${userCopNm} / ${deptNm}`;

        // 미등록 사용자 처리
        const isUnregisteredUser = ApprovalUtil.isUnregisteredUser(userId);
        const userNmDis = isUnregisteredUser ? intl.formatMessage({ id: 'com.text.approval.line.unregisteredUser' }) : userNm;
        const titleDis = isUnregisteredUser ? userNmDis : title;
        const positionDis = isUnregisteredUser ? '' : position;

        // 클래스, 구분 표기 방법
        // 기안자 - 기안/취소
        // 결재/합의 - 결재/합의/승인/반려
        // 통보 - 통보
        let typeStep = '';
        let typeId = '';
        if (type === ApprovalConstants.LINE_TYPE.DRAFT) {
            if (statCd === ApprovalConstants.STAT_CODE.CANCEL) {
                typeId = LINE_USER_STAT_CD_LANG_ID[statCd]
            } else {
                typeId = LINE_USER_LANG_ID[type];
            }
            typeStep = LINE_USER_CLASS_NAME[type];
        } else {
            if (statCd === ApprovalConstants.STAT_CODE.PROCESSING || statCd === ApprovalConstants.STAT_CODE.CANCEL || type === ApprovalConstants.LINE_TYPE.NOTIFY) {
                typeId = LINE_USER_LANG_ID[type];
                typeStep = LINE_USER_CLASS_NAME[type];
            } else {
                typeId = LINE_USER_STAT_CD_LANG_ID[statCd];
                typeStep = LINE_USER_STAT_CD_CLASS_NAME[statCd];
            }
        }
        const typeClassName = `appr-step${typeStep}`;
        const typeLangId = `com.text.approval.line.type.${typeId}`;
        const isViewMode = typeClass.isViewMode();

        // 처리일시 표기 방법
        // 기안자 = actDt(req regDt)
        // 승인자 = 상태가 진행중(PROCESSING) 아닌경우, actDt(reqTgtUser lastModDt) / 그 외 ''
        // 통보자 = 상태가 완료(APPROVAL)인 경우, actDt(reqTgtUser lastModDt) / 그 외 ''
        let showActDt = '';
        switch (type) {
            case ApprovalConstants.LINE_TYPE.DRAFT:
                showActDt = actDt;
                break;
            case ApprovalConstants.LINE_TYPE.NOTIFY:
                showActDt = statCd === ApprovalConstants.STAT_CODE.APPROVAL ? actDt : '';
                break;
            default:
                showActDt = statCd !== ApprovalConstants.STAT_CODE.PROCESSING ? actDt : '';
        }
        const formattedActDt = showActDt !== '' ? moment(showActDt).utcOffset(typeClass.timeZone).format('YYYY-MM-DD HH:mm') : '';

        const opinionMsg = getUserOpinion(user, opinion);

        return (
            <div className="grid-row" key={`approval_line_${pIndex}_${rIndex}_${userId}`}>
                {isViewMode && opinion !== '' && (
                    <div className="grid-cell appr-process-cell">
                        <span className={typeClassName}>{opinionMsg}</span>
                    </div>
                )}

                <div className="grid-cell appr-num-cell">{pIndex}</div>
                <div className="grid-cell appr-division-cell">
                    <span className={typeClassName}>{intl.formatMessage({ id: typeLangId })}</span>
                </div>
                <div className="grid-cell appr-line-cell">
                    <div className="item-user">
                        <div className="user-profile">
                            <div title={titleDis}>
                                <span className="name">{userNmDis}</span>
                                <span className="position">{positionDis}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {isViewMode && (
                    <div className="grid-cell appr-processTime-cell">{formattedActDt}</div>
                )}
            </div>
        );
    };

    const getUserSelector = (pIndex = 1, rIndex = 0, users = [], type = ApprovalConstants.LINE_TYPE.APPROVAL, selectorIdx = 1) => {
        const typeClassName = `appr-step${LINE_USER_CLASS_NAME[type]}`;
        const typeLangId = `com.text.approval.line.type.${LINE_USER_LANG_ID[type]}`;

        const target = intl.formatMessage({ id: typeClass.approverNameId });
        const title = intl.formatMessage({ id: 'com.placeHolder.approval.line.pleaseSelect' }, { target });

        const options = _.map(users, (user, idx) => {
            const { userNm, userCopNm = '', deptNm = '' } = user;
            const message = `${userNm} ${userCopNm} / ${deptNm}`;
            return {
                value: idx,
                message
            };
        });
        const rowClass = `grid-row${typeClass.hasNotAuthLineByIdx(pIndex, rIndex) ? ' err-row' : ''}`;

        const handle = idx => {
            typeClass.selectLineUser(pIndex, rIndex, idx);
            typeClass.checkSubmittable();
            setIsInvalid(!ApprovalUtil.isRequestableLine(line));
        };
        const isViewMode = typeClass.isViewMode();

        return (
            <div className={rowClass} key={`approval_line_${pIndex}_${rIndex}_${type}_selector_${selectorIdx}`}>
                {isViewMode && <div className="grid-cell appr-process-cell" />}

                <div className="grid-cell appr-num-cell">{pIndex}</div>
                <div className="grid-cell appr-division-cell">
                    <span className={typeClassName}>{intl.formatMessage({ id: typeLangId })}</span>
                </div>
                <div className="grid-cell appr-line-cell">
                    <div className="forms-container">
                        <SelectBox type="box" dataContainer="body" title={title} options={options} onChange={handle} />
                    </div>
                </div>

                {isViewMode && <div className="grid-cell appr-processTime-cell" />}
            </div>
        );
    };

    const getUserInput = (pIndex = 1, rIndex = 0, type = ApprovalConstants.LINE_TYPE.APPROVAL, guideText = undefined) => {
        const compKey = `approval_line_${pIndex}_${rIndex}_${type}_userinput`;

        const onSelect = user => {
            typeClass.inputLineUser(pIndex, rIndex, user);
            typeClass.checkSubmittable();
            setIsInvalid(!ApprovalUtil.isRequestableLine(line));
        };

        const onClear = () => {
            typeClass.clearLineUser(pIndex, rIndex);
            typeClass.checkSubmittable();
            setIsInvalid(false);
        };

        return (
            <ApprovalLineUserInput key={compKey} onSelect={onSelect} onClear={onClear} typeClass={typeClass} type={type} pIndex={pIndex} rIndex={rIndex} guideText={guideText} />
        );
    };

    const getLineUsers = () => {
        const lineUsers = [getUser(0, 0, typeClass.draftUser, ApprovalConstants.LINE_TYPE.DRAFT)];
        const indexKeys = _.chain(line)
            .keys()
            .sortBy()
            .value();

        _.forEach(indexKeys, sno => {
            const eachParallel = line[sno];

            _.forEach(eachParallel, (eachLine, idx) => {
                // 지정된 경우
                if (eachLine.users.length === 1) {
                    lineUsers.push(getUser(sno, idx, eachLine.users[0], eachLine.type));
                }
                // 직접 입력
                else if (eachLine.users.length === 0) {
                    lineUsers.push(getUserInput(sno, idx, eachLine.type, eachLine.guide));
                }
                // 후보 선택
                else {
                    for (let i=0; i<eachLine.minimum; i++) {
                        lineUsers.push(getUserSelector(sno, idx, eachLine.users, eachLine.type, i));
                    }
                }
            });
        });

        return lineUsers;
    };

    const getUserOpinion = (user = {}, opinion = '') => {
        if (!opinion.length) return opinion;

        const trimmed = opinion.trim();

        // JSON 객체
        if (trimmed.startsWith('{') && trimmed.endsWith('}')) {
            try {
                const opinObj = JSON.parse(trimmed) || {};
                const { userId, userNm = '', userCopNm = '', deptNm = '' } = user;
                const { proxyYn = 'N', percOpin = '', originUserId = '', originUserNm = '', originUserCopNm = '', originUserDeptNm = '' } = opinObj;
                const opinMsg = percOpin.trim();

                // 대리 결재 X
                if (proxyYn !== 'Y') return opinMsg;

                // 원본 대상자
                const isUnRegOriginUser = ApprovalUtil.isUnregisteredUser(originUserId);
                const originNmDis = isUnRegOriginUser ? intl.formatMessage({ id: 'com.text.approval.line.unregisteredUser' }) : originUserNm;
                const originTitleDis = isUnRegOriginUser ? originNmDis : `${originUserNm} ${originUserCopNm} / ${originUserDeptNm}`;

                // 현재 대상자
                const isUnRegUser = ApprovalUtil.isUnregisteredUser(userId);
                const userNmDis = isUnRegUser ? intl.formatMessage({ id: 'com.text.approval.line.unregisteredUser' }) : userNm;
                const titleDis = isUnRegUser ? userNmDis : `${userNm} ${userCopNm} / ${deptNm}`;

                // 메시지 구성
                const proxyMsg = `[${intl.formatMessage({ id: 'com.text.approval.line.proxyApproval' })}] ${originTitleDis} → ${titleDis}`;
                return opinMsg.length ? `${proxyMsg}\n \n${opinMsg}` : proxyMsg;
            } catch (e) {
                return trimmed;
            }
        }
        // 일반 문자열
        else {
            return trimmed;
        }
    };

    return (
        <>
            <div className="apprAuto-grid-list">
                <div className="comm-grid-list">
                    <div className="grid-header-group">
                        <div className="grid-row">
                            <div className="grid-cell appr-num-cell">
                                <span className="column-name">{intl.formatMessage({ id: 'com.text.approval.line.seq' })}</span>
                            </div>
                            <div className="grid-cell appr-division-cell">
                                <span className="column-name">{intl.formatMessage({ id: 'com.text.approval.line.type' })}</span>
                            </div>
                            <div className="grid-cell appr-line-cell">
                                <span className="column-name">{intl.formatMessage({ id: 'com.text.approval.line.name' })}</span>
                            </div>
                            {typeClass.isViewMode() && (
                                <div className="grid-cell appr-processTime-cell">
                                    <span className="column-name">{intl.formatMessage({ id: 'com.text.approval.line.actDt' })}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid-row-group">
                        <div>{getLineUsers()}</div>
                    </div>
                </div>
            </div>
            {isInvalid && (
                <div className="err-msg">{intl.formatMessage({ id: 'com.guideText.approval.line.noneAuthority' })}</div>
            )}
        </>
    );
};

ApprovalLine.propTypes = {
    typeClass: PropTypes.object.isRequired
};

export default injectIntl(ApprovalLine);

export { LINE_USER_CLASS_NAME, LINE_USER_LANG_ID };
