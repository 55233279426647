/* 1 KB 미만 : ‘숫자 + Bytes’  형태로 출력한다.
1 KB 이상 ~ 1 MB 미만 : ‘숫자 + KB’ 형태로 출력하며, 소수점이 존재하는 경우 최대 소수점 둘째 자리까지 표기한다.
1 MB 이상 ~ 1 GB 미만 : ‘숫자 + MB’ 형태로 출력하며, 소수점이 존재하는 경우 최대 소수점 둘째 자리까지 표기한다.
1 GB이상 ~ 1 TB 미만 :‘숫자 + GB’ 형태로 출력하며, 소수점이 존재하는 경우 최대 소수점 둘째 자리까지 표기한다. */
import _ from 'lodash';

class SizeConverter {
    convert = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat(_.floor(bytes / k ** i, dm))} ${sizes[i]}`;
    };

    withComma = bytes => {
        if (bytes === 0) return '0 Bytes';

        let comma = '';

        for (let strBytes = `${bytes}`; strBytes.length > 0; strBytes = strBytes.slice(0, -3)) {
            comma = `${strBytes.slice(-3)}${comma.length > 0 ? ',' : ''}${comma}`;
        }
        return `${comma} Bytes`;
    };
}

export default new SizeConverter();
