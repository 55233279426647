import React, { Component } from 'react';
import $ from 'jquery';
import CoachMarkConstants from 'constants/CoachMark';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server';

class FavoriteCoachMark extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        // this.setNLocalStorage = this.setNLocalStorage.bind(this);

        const { intl } = this.props;
        const dataContent = (
            <div className="coachmark-conts">
                <p>{intl.formatMessage({ id: 'com.text.coachMark.favourite' })}</p>
                <div className="btns">
                    <a className="coachmark-end" id="stop-favourite">
                        {intl.formatMessage({ id: 'com.text.coachMark.stop' })}
                    </a>
                </div>
            </div>
        );
        this.dataRendered = renderToString(dataContent);
    }

    componentDidMount() {
        setTimeout(this.show, 500);
    }

    setNLocalStorage = () => {
        const { loginUser } = this.props;
        const { id } = loginUser;
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_HOME}${id}`, 'N');
    };

    hide = () => {
        const ccp = $('.coachmark-conts').parents('.popover');
        //
        // $('body > div.coachmark-backdrop').remove();

        ccp.animate({ opacity: 0 }, 'fast', 'swing');
        this.setNLocalStorage();
    };

    show = () => {
        this.coachmarkOwner = $('#favourite-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: true
        });

        // $('body').append('<div class="coachmark-backdrop"></div>');
        this.popover.popover('show');
        $('[class*="bs-popover-bottom"]').addClass('coachmark');

        document.getElementById('stop-favourite').addEventListener('click', this.hide);
        // document.getElementById('end-favourite').addEventListener('click', this.hide);

        window.addEventListener('click', function(e) {
            $('[class*="bs-popover-bottom"]').each(function() {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
    };

    render() {
        return (
            <h3 className="has-coachmark" id="favourite-coachmark" data-placement="bottom" data-coachmark-order="20" data-content={this.dataRendered}>
                <FormattedMessage id="drive.title.favorFolder" />
            </h3>
        );
    }
}

FavoriteCoachMark.propTypes = {
    loginUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(FavoriteCoachMark));
