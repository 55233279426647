import React, { Component } from 'react';
import AuthRest from 'apis/AuthRest';
import CustomModal from 'containers/CustomModal';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl } from 'react-intl';
import BrityTermRest from 'apis/BrityTermsRest';
import AUTH from 'constants/Auth';
import SelectBox from 'presentationals/SelectBox';
import { If } from 'jsx-control-statements';
import GlobalPopupService from '../../../redux/service/GlobalPopup';
import { auth as AUTH_ACTIONS } from '../../../redux/actions';
// import TermContent from './TermContent';
import LabelWithCheckBoxNewTerms from '../../brity/modules/LabelWithCheckBoxNewTerms';
// import { TOKEN_STATUS } from '../../../redux/actions/Auth';
// brity로 부터 조회한 통합 약관을 동의하는 화면
class AcceptNewTerm extends Component {
    constructor(props) {
        super(props);
        // const { intl } = this.props;
        const time = new Date().getTime();
        this.state = {
            cntryCode: [],
            termsData: {}, // 조회된 모든 약관 데이터 저장
            selectedCntry: 'NONE',
            isAcceptButtonDisable: true,
            checkAll: false,
            checkAllClick: false,
            forceUncheck: time
        };

        // this.termsType = [AUTH.TERMS_TYPE.ITEMS, AUTH.TERMS_TYPE.POLICY];
        // this.categories = [{ value: '', message: intl.formatMessage({ id: 'user.helpcenter.allTypes' }) }];
        this.agreeTerms = [];
    }

    componentDidMount() {
        // this.getTermsTest();
        this.getCntryCode();
        this.getTerms();
    }

    getCntryCode() {
        const { intl, user } = this.props;
        const langCd = user.locale.languageCode;
        BrityTermRest.getBrityTermsCntry(langCd).then(response => {
            console.log('getCntryCode response', response, langCd);
            const { resultCode, data } = response;
            const cntryData = [];
            const cntryList = data.data;
            switch (resultCode) {
                case AUTH.RESULT_CODE.SUCCESS:
                    console.log('getCntryCode success');
                    // temp3.push({ value: 'NONE', message: intl.formatMessage({ id: 'com.text.newTerms.selective' }) });
                    for (let i = 0; i < cntryList.length; i += 1) {
                        const message = langCd === '001' ? cntryList[i].cntryNm : cntryList[i].enCntryNm;
                        const par = { value: cntryList[i].cntryCd, message };
                        cntryData.push(par);
                    }
                    cntryData.splice(0, 0, { value: '', message: intl.formatMessage({ id: 'com.text.newTerms.selective' }), hidden: true });
                    this.setState({ cntryCode: cntryData });
                    break;
                default:
                    console.error(JSON.stringify(response));
            }
        });
    }

    getTerms() {
        const { selectedCntry } = this.state;
        const { user } = this.props;
        this.agreeTerms = [];

        console.log('getTerms response input contry', selectedCntry);
        if (selectedCntry === 'NONE') {
            this.setState({ termsData: [], isAcceptButtonDisable: true });

            return;
        }
        const langCd = user.locale.languageCode === '001' ? 'ko' : 'en';
        BrityTermRest.getBrityTerms(selectedCntry, langCd).then(response => {
            console.log('getTerms response', response);

            const { resultCode, data } = response;
            if (resultCode === 200) {
                const list = data.data.trmsCns;
                list.map(temp => this.agreeTerms.push({ trmsCd: temp.trmsCd, trmsVerVal: temp.trmsVerVal, agmtYn: 'N', ncsrYn: temp.ncsrYn }));
                const time = new Date().getTime();
                this.setState({ termsData: data, forceUncheck: time });
            }
        });
    }

    getTermsTest() {
        const temp = {
            cntryCd: 'KR',
            zoneCd: 'KR',
            trmsCns: [
                {
                    trmsCd: 'PCURKR',
                    trmsNm: '개인정보수집및이용동의(필수)(KR)',
                    agmtTrgtYn: 'Y',
                    ncsrYn: 'Y',
                    alignNo: 1,
                    trmsVerVal: 'v1.0',
                    langCd: 'KO',
                    trmsTtl: '개인정보수집및이용동의(필수)',
                    trmsCn: '개인정보수집및이용동의(필수)(KR) 약관내용'
                },
                {
                    trmsCd: 'PCUOKR',
                    trmsNm: '개인정보수집및이용동의(선택)(KR)',
                    agmtTrgtYn: 'Y',
                    ncsrYn: 'N',
                    alignNo: 2,
                    trmsVerVal: 'v1.0',
                    langCd: 'KO',
                    trmsTtl: '개인정보수집및이용동의(선택)',
                    trmsCn: '개인정보수집및이용동의(선택)(KR) 약관 내용'
                }
            ]
        };

        const temp2 = [
            {
                cntryCd: 'DE',
                cntryNm: '독일',
                enCntryNm: 'Germany',
                zoneCd: 'EU'
            },
            {
                cntryCd: 'KR',
                cntryNm: '대한민국',
                enCntryNm: 'Korea',
                zoneCd: 'KR'
            }
        ];
        const temp3 = [];
        for (let i = 0; i < temp2.length; i += 1) {
            const par = { value: temp2[i].cntryCd, message: temp2[i].cntryNm };
            temp3.push(par);
        }

        this.setState({ cntryCode: temp3, termsData: temp });
    }

    acceptTerms() {
        console.log('acceptTerms', this.agreeTerms);
        // TODO 통합약관 정보 기록 필요?
        const { selectedCntry } = this.state;
        BrityTermRest.agreeBrityTerms(selectedCntry, this.agreeTerms).then(response => {
            console.log('acceptTerms response', response);

            const { resultCode, data } = response;
            console.log('insert api result ', data);
            if (resultCode === 200 && data.data.result === 'success') {
                this.changeBrityDriveStat();
            } else {
                console.log('에러메시지');
            }
        });
    }

    rejectTerms() {
        const { setTokenStatus } = this.props;
        setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.EXPIRED);
    }

    changeBrityDriveStat() {
        AuthRest.agreeForNewTerm().then(
            ({ resultCode, message }) => {
                if (resultCode === AUTH.RESULT_CODE.SUCCESS) {
                    const { setTokenStatus } = this.props;
                    setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.AUTH);
                } else {
                    const { intl } = this.props;
                    GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
                }
            },
            () => {
                const { setTokenStatus } = this.props;
                setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.EXPIRED);
            }
        );
    }

    onClickCheckbox = (trmsCd, agmtBool) => {
        console.log('checkbox 1', this.agreeTerms, trmsCd, agmtBool);
        let isCheckAll = true;
        for (let i = 0; i < this.agreeTerms.length; i += 1) {
            if (this.agreeTerms[i].trmsCd === trmsCd) {
                console.log('adasfasfasfasfasfasd', this.agreeTerms[i].trmsCd, this.agreeTerms[i].agmtYn, !this.agreeTerms[i].agmtYn);

                this.agreeTerms[i].agmtYn = agmtBool ? 'Y' : 'N';
            }
            if (this.agreeTerms[i].agmtYn === 'N') isCheckAll = false;
        }

        this.setState({ checkAll: isCheckAll, checkAllClick: false, isAcceptButtonDisable: this.checkAcceptButtonDisable() });
    };

    onChangeCntry = param => {
        // this.setState({ selectedCntry: param });
        this.setState(draft => {
            draft.selectedCntry = param;
            draft.checkAll = false;
            draft.checkAllClick = false;

            return draft;
        }, this.getTerms);
    };

    checkAcceptButtonDisable = () => {
        console.log('checkAcceptButtonDisable', this.agreeTerms);
        for (let i = 0; i < this.agreeTerms.length; i += 1) {
            if (this.agreeTerms[i].ncsrYn === 'Y') {
                if (this.agreeTerms[i].agmtYn === 'N') return true;
            }
        }
        return false;
    };

    checkAll = param => {
        console.log('checkAll', param);
        for (let i = 0; i < this.agreeTerms.length; i += 1) {
            this.agreeTerms[i].agmtYn = param ? 'Y' : 'N';
        }

        this.setState({ checkAll: param, checkAllClick: true, isAcceptButtonDisable: this.checkAcceptButtonDisable() });
    };

    render() {
        const { cntryCode, termsData, selectedCntry, isAcceptButtonDisable, checkAll, checkAllClick, forceUncheck } = this.state;
        const { intl } = this.props;

        let essentialRow = [];
        let selectiveRow = [];
        // this.agreeTerms = [];
        console.log('in render termsData', termsData);
        if (!_.isEmpty(termsData)) {
            const list = termsData.data.trmsCns;
            // list.map(temp => this.agreeTerms.push({ trmsCd: temp.trmsCd, trmsVerVal: temp.trmsVerVal, agmtYn: 'N', ncsrYn: temp.ncsrYn }));
            essentialRow = list.map(temp => (
                <If condition={temp.ncsrYn === 'Y'}>
                    <LabelWithCheckBoxNewTerms currentSelectedTerm={temp} getChecked={this.onClickCheckbox} checkAll={checkAll} checkAllClick={checkAllClick} forceUncheck={forceUncheck} />
                </If>
            ));
            selectiveRow = list.map(temp => (
                <If condition={temp.ncsrYn === 'N'}>
                    <LabelWithCheckBoxNewTerms currentSelectedTerm={temp} getChecked={this.onClickCheckbox} checkAll={checkAll} checkAllClick={checkAllClick} forceUncheck={forceUncheck} />
                </If>
            ));
        }

        return (
            <CustomModal isOpen={true} fade={true} backdrop="static" className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'com.text.newTerms.title' })}</h5>
                    <a className="btn-ic-nor" role="button" title="닫기" data-dismiss="modal" onClick={() => this.rejectTerms()}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <div className="box-text">
                        <p>
                            {intl.formatMessage({ id: 'com.text.newTerms.sub1' })} <br />
                            {intl.formatMessage({ id: 'com.text.newTerms.sub2' })}
                        </p>
                    </div>

                    <div className="dt-width-120">
                        <dl className="row-list">
                            <dt>{intl.formatMessage({ id: 'com.text.newTerms.currentCntry' })}</dt>
                            <dd>
                                {/* <span className="select-type-box"> */}
                                {/*    <select className="selectpicker" title="클릭하여 선택해주세요."> */}
                                {/*        <option selected={true}>대한민국</option> */}
                                {/*    </select> */}
                                {/* </span> */}

                                <span className="select-type-box">
                                    <SelectBox options={cntryCode} onChange={this.onChangeCntry} value={selectedCntry} style={{ width: '100%' }} />
                                </span>
                            </dd>
                        </dl>
                    </div>

                    <div className="comm-title-bar">
                        <strong className="title">{intl.formatMessage({ id: 'com.text.newTerms.title' })}</strong>
                    </div>

                    {selectedCntry !== 'NONE' ? (
                        <div className="terms-agree box-line" disabled={selectedCntry !== 'NONE'}>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" onClick={() => this.checkAll(!checkAll)} checked={checkAll} />
                                    <span className="label-text">
                                        <strong>전체 항목 동의</strong>
                                    </span>
                                </label>
                            </div>

                            <hr className="divider" />
                            {essentialRow}

                            <hr className="divider" />

                            {selectiveRow}
                        </div>
                    ) : (
                        ''
                    )}
                    {/* <div className="info-msg"> */}
                    {/*    <i className="ic-16-info" /> */}
                    {/*    {intl.formatMessage({ id: 'com.text.newTerms.sumText1' })} */}
                    {/*    <br /> */}
                    {/*    {intl.formatMessage({ id: 'com.text.newTerms.sumText2' })} */}
                    {/*    <br /> */}
                    {/*    {intl.formatMessage({ id: 'com.text.newTerms.sumText3' })} */}
                    {/* </div> */}
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-option" />

                    <div className="modal-footer-btns">
                        <a className={`btn btn-lg btn-primary ${isAcceptButtonDisable ? 'disabled' : ''}`} role="button" data-dismiss="modal" onClick={() => this.acceptTerms()}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

AcceptNewTerm.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    setTokenStatus: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapDispatchToProps = {
    setTokenStatus: AUTH_ACTIONS.setTokenStatus
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(AcceptNewTerm)
);
