import React, { lazy, memo, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Url from 'utils/Url';

const Pages = lazy(() => import(/* webpackChunkName: "pages" */ '../pages/Pages'));

// TODO 기존 코드인데, 일부 pathname은 분리해서 routing 하는 지 확인 후 refactor
const PageContent = memo(({ location }) => {
    const { pathname } = location;

    return (
        <Suspense fallback={<></>}>
            <Pages />
            {pathname === '/' && <Redirect to={Url.home} />}
            {pathname === '/noGnbMyFiles' && <Redirect to={Url.own} />}
            {pathname === '/noGnbSharedFiles' && <Redirect to={Url.shared} />}
            {pathname === '/noGnbWorkgroups' && <Redirect to={Url.workgroup} />}
        </Suspense>
    );
});

PageContent.propTypes = {
    location: PropTypes.object.isRequired
};

export default PageContent;
