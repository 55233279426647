import React from 'react';
import moment from 'moment/moment';
import AuthGuideRest from '../apis/AuthGuideRest';
import AuthService from './AuthService';

const AuthCdsDefault = {
    list: [
        {
            authAbbrCd: 'view',
            authCd: 'U20VIEW',
            authCdNm: '조회 가능'
        },
        {
            authAbbrCd: 'copy',
            authCd: 'U40COPY',
            authCdNm: '복사 가능'
        },
        {
            authAbbrCd: 'edit',
            authCd: 'U60EDIT',
            authCdNm: '편집 가능'
        },
        {
            authAbbrCd: 'share',
            authCd: 'U70SHARE',
            authCdNm: '공유 가능'
        },
        {
            authAbbrCd: 'own',
            authCd: 'U80OWN',
            authCdNm: '소유자'
        },
        {
            authAbbrCd: 'manage',
            authCd: 'A50MANAGE',
            authCdNm: '관리자'
        }
    ],
    map: {
        U20VIEW: '조회 가능',
        U40COPY: '복사 가능',
        U60EDIT: '편집 가능',
        U70SHARE: '공유 가능',
        U80OWN: '소유자',
        A50MANAGE: '관리자'
    },
    langCd: '001'
};

let AUTH_CDS = null;

export const getAuthMap = () => {
    const authCds = AUTH_CDS || {};
    return authCds.map || AuthCdsDefault.map || {};
};

const AuthCd = () => {
    function convertListToMap(list = []) {
        const map = {};
        list.forEach(authCd => {
            map[authCd.authCd] = authCd.authCdNm;
        });
        return map;
    }

    function getExpireDate() {
        let expire = new Date();
        expire.setDate(expire.getDate() + 1);
        expire = moment(expire)
            .utcOffset(0)
            .format('YYYY-MM-DDTHH:mm:ssZ');
        return expire;
    }

    function setAuthCds() {
        const { locale } = AuthService.getLoginUser();
        const expireDt = getExpireDate();
        AuthGuideRest.getAuthCds().then(
            result => {
                AUTH_CDS = {
                    list: result,
                    map: convertListToMap(result),
                    langCd: locale.languageCode,
                    expireDt
                };
            },
            () => {
                AUTH_CDS = Object.assign({}, AuthCdsDefault, { langCd: locale.languageCode, expireDt });
            }
        );
    }

    setAuthCds();

    return <></>;
};

export default AuthCd;
