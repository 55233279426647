const PREFIX = 'REQ_MNG_';

export const SET_REQ_MNG_LIST = PREFIX.concat('SET_REQ_MNG_LIST');
export const SET_REQ_MNG_LIST_PARAM = PREFIX.concat('SET_REQ_MNG_LIST_PARAM');
export const SET_REQ_MNG_LIST_SELECTED = PREFIX.concat('SET_REQ_MNG_LIST_SELECTED');

function action(type, payload = {}) {
    return { type, ...payload };
}

export const setReqMngList = reqMngList => action(SET_REQ_MNG_LIST, reqMngList);
export const setReqMngListParam = reqMngListParam => action(SET_REQ_MNG_LIST_PARAM, { reqMngListParam });
export const setReqMngListSelected = reqMngListSelected => action(SET_REQ_MNG_LIST_SELECTED, { reqMngListSelected });
