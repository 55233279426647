import moment from 'moment';
import _ from 'lodash';

export function toCompactedFileInfo(file) {
    const { drive: { objtNm: fileFullName, objtId, onpstId } = {} } = file;

    const indexOfDotInFileFullName = fileFullName.lastIndexOf('.');
    const isFileWithoutExtension = indexOfDotInFileFullName === -1;

    const name = isFileWithoutExtension ? fileFullName : fileFullName.substring(0, indexOfDotInFileFullName);
    const extensionName = isFileWithoutExtension ? null : fileFullName.substring(indexOfDotInFileFullName + 1);

    return {
        name,
        extensionName,
        objtId,
        onpstId
    };
}

export function generateFormattedSummaryDateTime(statusChgDt, timezoneDiff) {
    if (!statusChgDt || !moment(statusChgDt).isValid()) {
        return '';
    }

    return moment(statusChgDt)
        .utcOffset(timezoneDiff)
        .format('YYYY-MM-DD HH:mm:ss');
}

export function getSummarizableExtensionListFromConfigs(configs) {
    const { value } = _.find(configs, ['id', 'EFL_DRV_SUMMARY_TARGET_EXT_LIST']) || { value: '' };

    return _(value)
        .split(',')
        .map(_.trim)
        .filter(_.negate(_.isEmpty))
        .value();
}

export function isSummarizableExtension(configs, extensionName) {
    const summarizableExtensionList = getSummarizableExtensionListFromConfigs(configs);
    return summarizableExtensionList.includes(extensionName);
}

export function getPromptTemplateAddedFileCount(files) {
    return _.filter(files, file => !_.isNil(file)).length;
}

export function getDefaultLLM(llmList) {
    return _.find(llmList, ({ llmDefaultYn }) => llmDefaultYn === 'Y') || llmList[0];
}

export function isInvalidScopeTypeKeyword(keyword) {
    const includeEmoji = /\p{Extended_Pictographic}/u.test(keyword);
    const includeMultipleSpace = !/^[^ ]*\s?[^ ]*$/.test(keyword);

    return includeEmoji || includeMultipleSpace;
}
