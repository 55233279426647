const ACTION_TYPE = {
    MOVE: 'move',
    UPLOAD: 'upload',
    COPY: 'copy'
};

const ITEM_TYPE = {
    FOLDER: 'folder',
    FILE: 'file'
};

const POINT_TYPE = {
    TREE: 'tree',
    LIST: 'list',
    LOCAL: 'local',
    SCREEN: 'screen'
};

export { ACTION_TYPE, ITEM_TYPE, POINT_TYPE };
