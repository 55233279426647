import React, { memo, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { AI_MESSAGE_STATUS } from '../../../../Common/SummaryConstant';
import FileName from './FileName';
import { summaryActions } from '../../../../../../../redux/actions/Summary';
import { summaryStorage } from '../../../../Common/Util/SummaryStorageUtil';
import { SUMMARY_SCOPE_TYPE } from '../../../../OptionSelectDialog/SummaryOptionConstant';

const UserMessage = memo(({ summaryMessage }) => {
    const { files, summaryOptions, archived, text } = summaryMessage;
    const { scopeType, scopeTypeKeyword } = summaryOptions;

    const dispatch = useDispatch();

    useEffect(() => {
        if (archived) {
            return;
        }

        const newAiMessage = {
            id: `${uuidv4()}-${Date.now()}`,
            aiMessageStatus: AI_MESSAGE_STATUS.pending,
            files,
            summaryOptions
        };

        summaryStorage.thread.addMessage(summaryMessage);
        dispatch(summaryActions.thread.generateAIMessage({ summaryMessage: newAiMessage }));
    }, []);

    return (
        <div className="ai-prompt">
            <div className="text-balloon">
                <div className="text-balloon-inner">
                    <Choose>
                        <When condition={!_.isNil(text)}>
                            <div className="text-balloon-text">{text}</div>
                        </When>
                        <Otherwise>
                            {files.map(file => {
                                const { objtId } = file;
                                return <FileName key={objtId} file={file} />;
                            })}
                            <div className="text-balloon-text">
                                {' '}
                                <Choose>
                                    <When condition={scopeType === SUMMARY_SCOPE_TYPE.keyword}>
                                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.prompt_suffix_with_keyword" values={{ keyword: scopeTypeKeyword }} />
                                    </When>
                                    <Otherwise>
                                        <FormattedMessage id="drive.text.copilot.summary.prompt_suffix" />
                                    </Otherwise>
                                </Choose>
                            </div>
                        </Otherwise>
                    </Choose>
                </div>
            </div>
        </div>
    );
});

UserMessage.propTypes = {
    summaryMessage: PropTypes.shape({
        content: PropTypes.string,
        files: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                extensionName: PropTypes.string.isRequired,
                objtId: PropTypes.string.isRequired,
                onpstId: PropTypes.string.isRequired
            })
        ),
        summaryOptions: PropTypes.object.isRequired,
        archived: PropTypes.bool,
        text: PropTypes.string
    }).isRequired
};

UserMessage.defaultProps = {};

export default UserMessage;
