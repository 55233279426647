import React, { useMemo } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isInvalidScopeTypeKeyword } from '../../Common/Util/SummaryUtil';
import { showSummaryToast } from '../../Common/Util/SummaryToastUtil';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { checkValidity, convertToUserMessage } from '../../Common/Util/PromptTemplateUtil';

const SummarySendButton = ({ intl }) => {
    const selectedLLM = useSelector(({ summary }) => summary.global.selectedLLM);
    const promptTemplate = useSelector(({ summary }) => summary.promptInput.promptTemplate);
    const { promptTemplateType, templateData } = promptTemplate;

    const dispatch = useDispatch();

    const isInvalidPromptTemplate = useMemo(() => {
        if (_.isNil(promptTemplateType)) {
            return true;
        }

        return !checkValidity(promptTemplate);
    }, [templateData]);

    const handleClickSendButton = () => {
        const { keyword } = templateData;

        // TODO refactor
        if (!_.isNil(keyword) && isInvalidScopeTypeKeyword(keyword)) {
            showSummaryToast(dispatch, { messageId: 'drive.tooltip.copilot.summary.keywordConstraint' });
            return;
        }

        dispatch(summaryActions.thread.generateUserMessage({ summaryMessage: convertToUserMessage(promptTemplate, selectedLLM) }));
        dispatch(summaryActions.promptInput.clear());
    };

    return (
        <a
            className={classNames('btn-ic-nor', isInvalidPromptTemplate && 'disabled')}
            role="button"
            title={intl.formatMessage({ id: 'drive.title.copilot.summary.promptInput.send' })}
            onClick={handleClickSendButton}>
            <i className="ic-24-copilot-send" />
        </a>
    );
};

export default injectIntl(SummarySendButton);
