import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { withFilesContext } from '../DriveFilesContext';
import { getUrlPrefix } from '../../../../utils/Url';

const FolderPath = props => {
    const {
        file = {},
        context: {
            props: { history }
        },
        cellStyle,
        intl
    } = props;

    const { extra = {}, maxAuthCd = 'NONE', drive = {} } = file;
    const { pathVals = [] } = extra;
    const { objtId, onpstFolderId } = drive;

    const pathName = _.map(pathVals, 'objtNm').join(' > ');
    const pathIdx = pathVals.length - 1;

    // const goToFolder = idx => {
    //     const prefix = getUrlPrefix(file);
    //     if (idx === 0) {
    //         history.push(`${prefix}`);
    //     } else {
    //         history.push(`${prefix}/${pathVals[idx].objtId}`, { onpstId: pathVals[idx].onpstId });
    //     }
    // };

    const openFolderInNewTab = idx => {
        const prefix = getUrlPrefix(file);
        window.open(idx === 0 ? `${prefix}` : `${prefix}/${pathVals[idx].objtId}`, '_blank');
    };

    const handleClick = idx => {
        openFolderInNewTab(idx);
    };

    const hasAuth = () => maxAuthCd !== 'NONE';

    const getNonAuthGuideText = () => {
        // 권한 있는경우 or 비정상 데이터
        if (hasAuth() || objtId === onpstFolderId) return '';

        const nonAuthMsg = intl.formatMessage({ id: 'drive.text.list.noAuth' });
        let availFunc = null;
        let funcMsg = null;

        const locationPath = _.get(history, 'location.pathname') || '';
        switch (locationPath) {
            case '/expiration':
                availFunc = intl.formatMessage({ id: 'drive.title.requestExtension' });
                break;
            case '/compliance':
                availFunc = intl.formatMessage({ id: 'drive.title.exceptComlianceFile' });
                break;
            default:
        }
        if (availFunc) {
            funcMsg = intl.formatMessage({ id: 'drive.text.list.noAuth.availableFunc' }, { func: availFunc });
        }

        return funcMsg ? `${nonAuthMsg}, ${funcMsg}` : nonAuthMsg;
    };

    return (
        <div className="grid-cell files-route-cell" style={cellStyle}>
            <div className="route">
                {hasAuth() ? (
                    <a className="path" role="button" title={pathName} onClick={() => handleClick(pathIdx)}>
                        {pathName}
                    </a>
                ) : (
                    <span className="path" title={getNonAuthGuideText()}>
                        {getNonAuthGuideText()}
                    </span>
                )}
            </div>
        </div>
    );
};

FolderPath.defaultProps = {
    cellStyle: null
};

FolderPath.propTypes = {
    context: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

FolderPath.displayName = 'FolderPath';
export default injectIntl(withFilesContext(FolderPath, []));
