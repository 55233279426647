import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class UploadStatus extends Component {
    renderStatusTitle = (files, statusCount) => {
        if (files.length === statusCount.completedCount + statusCount.failedCount) {
            if (statusCount.completedCount > 0) {
                return (
                    <div className="status-title">
                        <div className="status-detail">
                            <em className="num">{statusCount.completedCount}</em>
                            <FormattedMessage id="drive.text.upload.popup.complete.count" />
                        </div>
                        {statusCount.failedCount > 0 ? (
                            <div className="status-other">
                                <em className="num">{statusCount.failedCount}</em>
                                <FormattedMessage id="drive.text.upload.popup.fail.count" />
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
            }
            return (
                <div className="status-title">
                    <div className="status-detail">
                        <em className="num">{statusCount.failedCount}</em>
                        <FormattedMessage id="drive.text.upload.popup.fail.count" />
                    </div>
                </div>
            );
        }
        return (
            <div className="status-title">
                <div className="status-detail">
                    <em className="num">{files.length}</em>
                    <FormattedMessage id="drive.text.upload.popup.uploading.count" />{' '}
                    <span className="percent">
                        ( <em>{statusCount.totalProgress}</em>% )
                    </span>
                </div>
            </div>
        );
    };

    render() {
        const { files, statusCount, listVisible, intl, onToggle, onClose } = this.props;
        const fold = intl.formatMessage({ id: 'drive.button.upload.popup.fold' });
        const expand = intl.formatMessage({ id: 'drive.button.upload.popup.expand' });
        const close = intl.formatMessage({ id: 'drive.button.upload.popup.close' });

        return (
            <React.Fragment>
                <div className="uploads-progress">
                    <div className="uploads-progress-bar" style={{ width: `${statusCount.totalProgress}%` }}>
                        <span className="percent">{statusCount.totalProgress}%</span>
                    </div>
                </div>
                <div className="uploads-status">
                    <div className="status-title">{this.renderStatusTitle(files, statusCount)}</div>
                    <a
                        className={`btn-ic-nor btn-func-toggle-view${listVisible ? ' on' : ''}`}
                        role="button"
                        title={listVisible ? fold : expand}
                        data-boardview=".uploads-manager-container"
                        data-title-on={fold}
                        data-title-off={expand}
                        onClick={e => {
                            onToggle();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-slide-up" />
                    </a>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={close}
                        onClick={e => {
                            onClose();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>
            </React.Fragment>
        );
    }
}

UploadStatus.propTypes = {
    files: PropTypes.array,
    listVisible: PropTypes.bool,
    statusCount: PropTypes.object,
    onToggle: PropTypes.func,
    onClose: PropTypes.func,
    intl: PropTypes.object
};

UploadStatus.defaultProps = {
    files: [],
    listVisible: false,
    statusCount: {},
    onClose: () => {},
    onToggle: () => {},
    intl: {}
};

export default injectIntl(UploadStatus);
