import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'src/redux/actions';
import { FormattedMessage } from 'react-intl';

export const Toast = props => {
    const { show, message, linkMessage, onLinkClick, hideToast } = props;
    return (
        <>
            {show && (
                <div className="toast">
                    <div className="toast_conts">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <div>{_.isObject(message) ? _.isEmpty(message.id) ? message : <FormattedMessage id={message.id} values={message.values} /> : message}</div>
                        {!_.isEmpty(linkMessage) && (
                            <button style={{ display: 'inline-block' }} type="button" onClick={onLinkClick}>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {_.isObject(linkMessage) ? _.isEmpty(message.id) ? message : <FormattedMessage id={message.id} values={message.values} /> : linkMessage}
                            </button>
                        )}
                        <a className="btn-ic-nor close" role="button" onClick={hideToast}>
                            <i className="ic-20-close" />
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

Toast.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onLinkClick: PropTypes.func,
    hideToast: PropTypes.func.isRequired
};

Toast.defaultProps = {
    message: '',
    linkMessage: '',
    onLinkClick: _.noop
};

const mapStateToProps = state => ({
    show: state.toast.show,
    message: state.toast.message,
    linkMessage: state.toast.linkMessage,
    onLinkClick: state.toast.onLinkClick
});

const mapDispatchToProps = {
    hideToast: toast.hideToast
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(Toast);

export default connected;
