import _ from 'lodash';
import enCom from './en/com.json';
import enUser from './en/user.json';
import enDrive from './en/drive.json';

import koCom from './ko/com.json';
import koUser from './ko/user.json';
import koDrive from './ko/drive.json';

import deCom from './ge/com.json';
import deUser from './ge/user.json';
import deDrive from './ge/drive.json';

import zhCom from './cn/com.json';
import zhUser from './cn/user.json';
import zhDrive from './cn/drive.json';

const addPrefix = (collection, prefix) => {
    const result = {};
    _.forEach(collection, (value, key) => {
        result[`${prefix}.${key}`] = value;
    });
    return result;
};

export default {
    en: _.assign(addPrefix(enCom, 'com'), addPrefix(enUser, 'user'), addPrefix(enDrive, 'drive')),
    ko: _.assign(addPrefix(koCom, 'com'), addPrefix(koUser, 'user'), addPrefix(koDrive, 'drive')),
    de: _.assign(addPrefix(deCom, 'com'), addPrefix(deUser, 'user'), addPrefix(deDrive, 'drive')),
    zh: _.assign(addPrefix(zhCom, 'com'), addPrefix(zhUser, 'user'), addPrefix(zhDrive, 'drive'))
};
