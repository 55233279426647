export const FOLDER = {
    REQ_NOT_SUPPORT_FOLDER_REQUEST_TYPE_CODE: 20551,
    EVENT_COMMON_PROCESS_WORKGROUPS_DUP_NAME: 20123,
    REQ_INVALID_APPROVER: 20553,
    REQ_NOT_ALLOW_UPDATE_FOLDER: 20555,
    IOFFICE_INVALID_PARAMETER: 400,
    NOT_FOUND_DEPARTMENT_LEADER: 20941,
    NOT_FOUND_DEPARTMENT_INFO: 20942,
    REQ_INVALID_DATE: 20556
};

export default {
    FOLDER
};
