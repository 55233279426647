/* eslint-disable */
import BaseMenu from '../BaseMenu';
import FileVersionRest from "../../../../apis/FileVersionRest";
import FileReload from "../../../../utils/FileReload";
import {toast,alert} from "../../../../utils/ModalService";
import _ from "lodash";

const executor = (props, file) => {
    FileVersionRest.deleteFileVersion(file[0].objtId, file[0].onpstId, file[0].fileVerSno).then(
        (result) => {
            if (result.resultCode === 200){

                const type = 'delete';
                props.callback(type);
                FileReload.call(null, file, {reloadType :  'refresh'});
            }else if(result.resultCode === 20031){
                alert({ id: 'drive.alert.verHistory.useFile' });
            }else{
                toast({ id: 'drive.toast.tempDelete.fail' });
            }
            return true;
        },
        () => {
            // 실행안누르고 닫은경우. 아무 변경이 없는루트.
            return false;
        }
    );
};

class VersionDelete extends BaseMenu {
    getName() {
        return 'versionDelete';
    }

    getLabel() {
        return 'drive.button.list.menu.delete';
    }

    isVisible() {
        // EFSS-56068
        const [{ lastVerYn } = {}] = this.files || [];
        if (lastVerYn === 'Y') return false;

        let deleteYn = true;
        if(!_.isNil(this.files[0].flcmActionPolicyMap)){
            deleteYn = this.files[0].flcmActionPolicyMap.delete;
        }
        return super.isVisible(false, 'versionup') && !this.files[0].uploading && this.files[0].listSize > 1 && deleteYn;
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-delete', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-delete', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}
export default VersionDelete;
