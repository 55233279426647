import React from 'react';
import { object } from 'prop-types';
import { popup } from '../../utils/ModalService';
import WebOffice from '../presentationals/WebOffice/WebOffice';

const openPopup = data => {
    return popup(<WebOffice {...data} />);
};

const WebOfficePopup = ({ match, location }) => {
    const { objtId } = match.params;
    openPopup({ objtId, location }).then(() => {}, () => {});
    return <></>;
};

WebOfficePopup.propTypes = {
    match: object.isRequired,
    location: object.isRequired
};

export default WebOfficePopup;
