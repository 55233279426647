import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDragDrop, isDragging } from '../../../redux/reducers/DragDrop';
import DragDropAction from '../../../redux/actions/DragDrop';
import { ACTION_TYPE, ITEM_TYPE, POINT_TYPE } from '../../../constants/DragDrop';
import { isRootFolderId } from '../../../utils/Url';

class FileDropZone extends React.Component {
    constructor(props) {
        super(props);

        this.oldTarget = null;
    }

    componentDidMount() {
        document.addEventListener('dragenter', this.onDragEnter);
        document.addEventListener('dragover', this.onDragOver);
        document.addEventListener('dragleave', this.onDragLeave);
        document.addEventListener('drop', this.onDrop);
    }

    componentWillUnmount() {
        document.removeEventListener('dragenter', this.onDragEnter);
        document.removeEventListener('dragover', this.onDragOver);
        document.removeEventListener('dragleave', this.onDragLeave);
        document.removeEventListener('drop', this.onDrop);
    }

    /**
     * 업로드 가능 여부
     * @returns {boolean}
     */
    isUploadable = () => {
        const { folder, pageId, uploader } = this.props;
        const { drive = {}, actionPolicy = {} } = folder;
        const { objtId: folderId = '' } = drive;
        const { upload: uploadable = false } = actionPolicy;
        const isSharedOrWorkgroupRoot = isRootFolderId(folderId) && (pageId === 'shared' || pageId === 'workgroup');

        return uploader && folderId && !isSharedOrWorkgroupRoot && uploadable;
    };

    isDropZone = node => {
        return node && node.classList && node.classList.contains('dzdzdz');
    };

    onDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();

        const { startDrag, enterDrag, folder } = this.props;
        const { drive = {} } = folder;
        const { objtId: folderId = '' } = drive;

        const dragDrop = getDragDrop();
        const { overItem = {} } = dragDrop;
        const { position: overItemPosition = '' } = overItem || {};

        this.oldTarget = e.target;

        // DRAG ITEM 정보가 없는데, 드래그 중인 경우
        // LOCAL FILE 드래그 처리
        if (!dragDrop.from && !dragDrop.action) {
            startDrag();
        }

        if (!isDragging([POINT_TYPE.LOCAL])) {
            return;
        }

        // Drop Zone 여부
        const isDropZone = this.isDropZone(e.target);
        if (!isDropZone && overItemPosition !== POINT_TYPE.SCREEN) {
            enterDrag({ id: folderId, type: ITEM_TYPE.FOLDER, position: POINT_TYPE.SCREEN }, this.isUploadable());
        }
    };

    onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    endDrag = () => {
        const { endDrag } = this.props;
        const dragDrop = getDragDrop();

        if (dragDrop.from === POINT_TYPE.LOCAL && dragDrop.action === ACTION_TYPE.UPLOAD) {
            endDrag();
        }
    };

    onDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();

        // 로컬 파일 업로드만 처리
        if (!isDragging([POINT_TYPE.LOCAL])) {
            return;
        }

        // ESC
        if (this.oldTarget === e.target) {
            this.endDrag();
        }
    };

    onDrop = e => {
        e.preventDefault();
        e.stopPropagation();

        const { endDrag } = this.props;

        // 로컬 파일 업로드만 처리
        if (!isDragging([POINT_TYPE.LOCAL])) {
            endDrag();
            return;
        }

        const { folder, uploader } = this.props;
        if (this.isUploadable()) {
            uploader(folder, e);
        }
        endDrag();
    };

    render() {
        return <></>;
    }
}

FileDropZone.propTypes = {
    startDrag: PropTypes.func.isRequired,
    endDrag: PropTypes.func.isRequired,
    enterDrag: PropTypes.func.isRequired,
    // leaveDrag: PropTypes.func.isRequired,

    folder: PropTypes.object,
    pageId: PropTypes.string,
    uploader: PropTypes.func
};

FileDropZone.defaultProps = {
    folder: {},
    pageId: '',
    uploader: () => {}
};

export default connect(
    null,
    {
        startDrag: () => ({ type: DragDropAction.START_DRAG, from: POINT_TYPE.LOCAL, action: ACTION_TYPE.UPLOAD, items: ['files'] }),
        endDrag: () => ({ type: DragDropAction.END_DRAG }),
        enterDrag: (overItem, isDroppable) => ({ type: DragDropAction.ENTER_DRAG, isDragOver: true, isDroppable, overItem })
        // leaveDrag: () => ({ type: DragDropAction.LEAVE_DRAG })
    }
)(FileDropZone);
