import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TermsRest from 'apis/TermsRest';
import SelectBox from 'presentationals/SelectBox';
import CustomModal from 'containers/CustomModal';
import AuthService from '../../utils/AuthService';
import SignatureUtil from '../../utils/SignatureUtil';

class PrivacyPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { privacy: [], isHtmlFile: false, fileDownloadUrl: '' };
    }

    componentDidMount() {
        const { show } = this.props;
        if (show) this.getPrivacy();
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show !== show && show) {
            this.getPrivacy();
        }
    }

    getPrivacy = () => {
        const params = { limit: 100 };
        TermsRest.getPrivacy(params).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { list } = data;
            if (!list || list.length === 0) return;
            this.setState({ privacy: list }, () => this.onPrivacyChange(list[0].termsId));
            //
            //
            // TermsRest.getTerm(list[0].termsId).then(detailsResponse => {
            //     const { resultCode: detailsResultCode, data: detailsData } = detailsResponse;
            //     if (detailsResultCode !== 200) {
            //         this.setState({ privacy: list });
            //         return;
            //     }
            //     const { termsId, termsContent } = detailsData;
            //     this.setState({ privacy: list, termsId, termsContent });
            // });
        });
    };

    onPrivacyChange = value => {
        // console.log('qweqwewq', value);
        const { termsId, privacy } = this.state;
        if (termsId === value) return;
        const terms = _.find(privacy, { termsId: value });
        // console.log('aaeee', terms);
        if (_.isEmpty(terms.objtId)) {
            // console.log('isEmpty', terms);
            TermsRest.getTerm(value).then(response => {
                const { resultCode, data } = response;
                if (resultCode !== 200) return;
                const { termsId: changed, termsContent } = data;
                this.setState({ termsId: changed, termsContent, isHtmlFile: false });
            });
        } else {
            // console.log(' not isEmpty', terms);
            // const { objtId } = terms;
            // const { fileVerSno } = terms;
            const tuid = AuthService.getAuthToken();
            const chip = AuthService.getSignatureKey();

            const url = `common/v1/helpcenter/terms/html/${terms.termsId}`;
            const params = ''; // objtId + fileVerSno; // { objtId: terms.objtId, fileVerSno: terms.fileVerSno };
            const method = 'GET';
            const signature = SignatureUtil.generateSignature({ url, params, method }, chip);
            const queryStr = `?tuid=${tuid}&hmac=${encodeURIComponent(signature)}`;

            console.log('p', url + queryStr);
            this.setState({ fileDownloadUrl: url + queryStr, termsId: terms.termsId, isHtmlFile: true });
        }
    };
    //
    // onPrivacyChange = value => {
    //     const { termsId } = this.state;
    //     if (termsId === value) return;
    //     TermsRest.getTerm(value).then(response => {
    //         const { resultCode, data } = response;
    //         if (resultCode !== 200) return;
    //         const { termsId: changed, termsContent } = data;
    //         this.setState({ termsId: changed, termsContent });
    //     });
    // };
    //

    render() {
        const { show, handleClose, intl } = this.props;
        const { privacy, termsId, termsContent, isHtmlFile, fileDownloadUrl } = this.state;
        const privacyList = _.map(privacy, item => {
            const { termsId: value, termsRegDt } = item;
            return { value, message: intl.formatMessage({ id: 'user.text.privacy.policy.history' }, { date: moment(termsRegDt).format('YYYY-MM-DD') }) };
        });
        return (
            <CustomModal isOpen={show} fade={true} size="lg" backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'user.gnb.privacyPolicy' })}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={handleClose}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <span className="select-type-box">
                        <SelectBox options={privacyList} onChange={this.onPrivacyChange} value={termsId} type="box" />
                    </span>
                    {isHtmlFile ? (
                        <div className="policy">
                            {/* <div className="form-group" dangerouslySetInnerHTML={{ __html: html }} style={{ height: '400px' }}> */}
                            {/*    /!* <textarea className="form-control textarea-lg document" readOnly={true} value={privacy.trmsCn} /> *!/ */}
                            {/* </div> */}
                            <iframe title="aa" id="privacy_policy" src={fileDownloadUrl} />
                        </div>
                    ) : (
                        <div className="form-group">
                            <textarea className="form-control textarea-lg document" readOnly={true} value={termsContent} />
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={handleClose}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                        </a>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

PrivacyPopup.propTypes = {
    intl: PropTypes.object.isRequired,
    show: PropTypes.bool,
    handleClose: PropTypes.func
};

PrivacyPopup.defaultProps = {
    show: false,
    handleClose: _.noop
};

export default injectIntl(PrivacyPopup);
