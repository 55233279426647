import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Option from '../Column/Option';
import { withFilesContext } from '../DriveFilesContext';
import { alert } from '../../../../utils/ModalService';

class FavoriteRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
    }

    fileIconCustom = {
        statusCallback: isOpen => {
            this.setState({ hover: isOpen });
        },
        openType: 'normal_left',
        moreGroupType: 'preferred'
    };

    onClick = e => {
        const {
            folder,
            context,
            context: {
                props: { pageClass }
            }
        } = this.props;
        if (folder.drive.objtDelCd === 'DTEMPORARY') {
            alert('drive.alert.favorFolder.alreadyRemoved');
            return;
        }
        pageClass.goToPage(folder, context, e);
    };

    render() {
        const {
            folder,
            style: propsStyle,
            context: {
                props: {
                    pageClass: {
                        menu: {
                            row: { iconGroupType }
                        }
                    },
                    pageClass
                }
            },
            context
        } = this.props;

        const { drive, extra, maxAuth } = folder;
        const { children = { folder: 0, file: 0 } } = extra;
        const { hover } = this.state;
        // if (index === 0) {
        //     style = Object.assign({}, propsStyle, { height: propsStyle.height + 15 });
        // } else {
        //     style = Object.assign({}, propsStyle, { top: propsStyle.top + 15 });
        // }
        const style = Object.assign({}, propsStyle, { top: propsStyle.top + 15 });
        return folder ? (
            <div style={style} className={`grid-row ${hover ? ' is-hovered ' : ''}$ folder-row`}>
                <div className="grid-cell files-name-cell">
                    <div className="item-file">
                        <i className={pageClass.getExtensionIcon(folder, context)} />
                        <div className="file file-link" role="button" onClick={this.onClick}>
                            <span className="file-name">{drive.objtNm}</span>
                        </div>
                    </div>
                </div>
                <div className="grid-cell files-folderDetail-cell" style={{ '-ms-flex': '0 0 200px', flex: '0 0 200px' }}>
                    <FormattedMessage id="drive.text.lwrFolderCount" /> {children.folder}, <FormattedMessage id="drive.text.lwrFileCount" /> {children.file}
                </div>
                <div className="grid-cell files-authority-cell">{maxAuth}</div>
                <Option groupType={iconGroupType} file={folder} fileIconCustom={this.fileIconCustom} atHome={true} />
            </div>
        ) : null;
    }
}

FavoriteRow.propTypes = {
    folder: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired
};

FavoriteRow.displayName = 'FavoriteRow';
export default withFilesContext(FavoriteRow, []);
