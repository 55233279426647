import React from 'react';
import PropTypes from 'prop-types';

const CriticalItemDt = ({ text }) => {
    return (
        <dt>
            {text} <em className="critical-item" title="필수항목" />
        </dt>
    );
};

CriticalItemDt.propTypes = {
    text: PropTypes.string.isRequired
};

export default CriticalItemDt;
