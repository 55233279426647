import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Typed from 'typed.js';
import { If } from 'jsx-control-statements';
import { useDispatch, useSelector } from 'react-redux';
import { summaryActions } from '../../../../../../../redux/actions/Summary';
import { AI_MESSAGE_RESULT_STATUS, PLAIN_TEXT_RESULT_STATUS_LIST } from '../../../../Common/SummaryConstant';

const SummaryText = memo(({ summaryResult, archived }) => {
    const { resultStatus, files, summaryText } = summaryResult;

    const summaryTypedTextRef = useRef();
    const typedRef = useRef();

    const isSidePanelOpen = useSelector(({ summary }) => summary.sidePanel.isOpen);

    const dispatch = useDispatch();

    const isPlainTextResultStatus = () => {
        return archived || PLAIN_TEXT_RESULT_STATUS_LIST.includes(resultStatus);
    };

    useEffect(() => {
        if (isPlainTextResultStatus()) {
            return;
        }

        typedRef.current = new Typed(summaryTypedTextRef.current, {
            strings: [summaryText],
            typeSpeed: -10000,
            showCursor: false,
            onComplete: () => {
                dispatch(summaryActions.thread.completeToTypeAIMessage());
                dispatch(summaryActions.thread.generateRecommendedPrompt());

                if (!isSidePanelOpen) {
                    dispatch(summaryActions.notificationDialog.open({ resultStatus, files }));
                }
            }
        });

        // eslint-disable-next-line consistent-return
        return () => {
            typedRef.current.destroy();
        };
    }, []);

    useEffect(() => {
        // 타이핑 중단 준비 단계(prepareToStopTyping)가 아니면 skip
        if (archived || resultStatus !== AI_MESSAGE_RESULT_STATUS.prepareToStopTyping) {
            return;
        }

        dispatch(summaryActions.thread.stopTypingAIMessage({ summaryText: summaryTypedTextRef.current.textContent }));
        typedRef.current.stop();
    }, [resultStatus]);

    return (
        <pre className="ai-result-text" ref={summaryTypedTextRef}>
            <If condition={isPlainTextResultStatus()}>{summaryText}</If>
        </pre>
    );
});

SummaryText.propTypes = {
    summaryResult: PropTypes.object.isRequired, // TODO
    archived: PropTypes.bool
};

SummaryText.defaultProps = {
    archived: false
};

export default SummaryText;
