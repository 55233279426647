import CustomModal from 'containers/CustomModal';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const TEXT = 'text';
const PASSWORD = 'password';
const SHOW = 'Show';
const HIDE = 'Hide';

const ChangePassword = ({
    isOpen,
    showCurrentPassword,
    notMatchedCurrentPassword,
    showNewPassword,
    showConfirmPassword,
    notMatchedConfirmPassword,
    checkLength,
    checkCombination,
    checkEqualThreeCharacters,
    checkContinuousNumber,
    firstChange,
    setShowStatusPassword,
    checkCurrentPassword,
    checkNewPassword,
    checkConfirmPassword,
    checkIsEqualNow,
    checkSave,
    save,
    cancel,
    currentPassword,
    newPassword,
    confirmPassword
}) => {
    return (
        <CustomModal isOpen={isOpen} fade={false} backdrop="static" className="modal-md">
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.popup.changePW.changePassword" />
                </h5>
                <FormattedMessage id="com.close">
                    {title => (
                        <a className="btn-ic-nor" role="button" title={title} data-dismiss="modal" onClick={cancel}>
                            <i className="ic-20-close" />
                        </a>
                    )}
                </FormattedMessage>
            </div>
            <ModalBody>
                <div className="dt-width-120 sr-setPW">
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.text.popup.changePW.currentPassword" />
                        </dt>
                        <dd>
                            <div className="input-group-remark">
                                <div className={`box-input-group ${notMatchedCurrentPassword ? 'is-invalid' : ''}`}>
                                    <FormattedMessage id="user.placeHolder.popup.changePW.enterCurrentPassword">
                                        {placeHolder => (
                                            <input
                                                className="form-control"
                                                type={showCurrentPassword ? TEXT : PASSWORD}
                                                placeholder={placeHolder}
                                                value={currentPassword}
                                                onChange={checkCurrentPassword}
                                            />
                                        )}
                                    </FormattedMessage>

                                    <a
                                        className={`btn-ic-nor btn-func-eye ${showCurrentPassword ? 'on' : ''}`}
                                        role="button"
                                        title={showCurrentPassword ? SHOW : HIDE}
                                        onClick={() => setShowStatusPassword('CurrentPassword')}>
                                        <i className="ic-16-eye" />
                                    </a>
                                </div>
                                <If condition={notMatchedCurrentPassword}>
                                    <div className="input-remark">
                                        <div className="err-msg">
                                            <FormattedMessage id="user.text.popup.changePW.incorrectPassword" />
                                        </div>
                                    </div>
                                </If>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.text.popup.changePW.newPassword" />
                        </dt>
                        <dd>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <FormattedMessage id="user.placeHolder.popup.changePW.enterNewPassword">
                                        {placeholder => (
                                            <input className="form-control" type={showNewPassword ? TEXT : PASSWORD} placeholder={placeholder} value={newPassword} onChange={checkNewPassword} />
                                        )}
                                    </FormattedMessage>

                                    <a
                                        className={`btn-ic-nor btn-func-eye ${showNewPassword ? 'on' : ''}`}
                                        role="button"
                                        title={showNewPassword ? SHOW : HIDE}
                                        onClick={() => setShowStatusPassword('NewPassword')}>
                                        <i className="ic-16-eye" />
                                    </a>
                                </div>
                                <div className="input-tooltip">
                                    <ul className="validation">
                                        <li className={checkLength ? 'on' : ''}>
                                            <span>
                                                <FormattedMessage id="user.text.popup.changePW.invalidLength" />
                                            </span>
                                        </li>
                                        <li className={checkCombination ? 'on' : ''}>
                                            <span>
                                                <FormattedMessage id="user.text.popup.changePW.invalidPassword1" />
                                            </span>
                                        </li>
                                        <li className={checkEqualThreeCharacters ? 'on' : ''}>
                                            <span>
                                                <FormattedMessage id="user.text.popup.changePW.invalidPassword2" />
                                            </span>
                                        </li>
                                        <li className={checkContinuousNumber ? 'on' : ''}>
                                            <span>
                                                <FormattedMessage id="user.text.popup.changePW.invalidPassword3" />
                                            </span>
                                        </li>
                                        <li className={checkIsEqualNow ? 'on' : ''}>
                                            <span>
                                                <FormattedMessage id="user.text.resetPassword.not.equal.now.password" />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="row-list">
                        <dt>
                            <FormattedMessage id="user.text.popup.changePW.confirmNewPassword" />
                        </dt>
                        <dd>
                            <div className="input-group-remark">
                                <div className={`box-input-group ${firstChange && notMatchedConfirmPassword ? 'is-invalid' : ''}`}>
                                    <FormattedMessage id="user.placeHolder.popup.changePW.enterPasswordAgain">
                                        {placeholder => (
                                            <input
                                                className="form-control"
                                                type={showConfirmPassword ? TEXT : PASSWORD}
                                                placeholder={placeholder}
                                                value={confirmPassword}
                                                onChange={checkConfirmPassword}
                                            />
                                        )}
                                    </FormattedMessage>
                                    <a
                                        className={`btn-ic-nor btn-func-eye ${showConfirmPassword ? 'on' : ''}`}
                                        role="button"
                                        title={showConfirmPassword ? SHOW : HIDE}
                                        onClick={() => setShowStatusPassword('ConfirmPassword')}>
                                        <i className="ic-16-eye" />
                                    </a>
                                </div>
                                <If condition={firstChange && notMatchedConfirmPassword}>
                                    <div className="input-remark">
                                        <div className="err-msg">
                                            <FormattedMessage id="user.text.popup.changePW.notMatchNewPassword" />
                                        </div>
                                    </div>
                                </If>
                            </div>
                        </dd>
                    </dl>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className={`btn btn-lg btn-primary ${checkSave() ? '' : 'disabled'}`} role="button" disabled={!checkSave()} onClick={save}>
                        <span className="btn-text">
                            <FormattedMessage id="com.save" />
                        </span>
                    </a>
                </div>
            </ModalFooter>
        </CustomModal>
    );
};

ChangePassword.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCurrentPassword: PropTypes.bool.isRequired,
    notMatchedCurrentPassword: PropTypes.bool.isRequired,
    showNewPassword: PropTypes.bool.isRequired,
    showConfirmPassword: PropTypes.bool.isRequired,
    notMatchedConfirmPassword: PropTypes.bool.isRequired,
    checkLength: PropTypes.bool.isRequired,
    checkCombination: PropTypes.bool.isRequired,
    checkEqualThreeCharacters: PropTypes.bool.isRequired,
    checkContinuousNumber: PropTypes.bool.isRequired,
    firstChange: PropTypes.bool.isRequired,
    setShowStatusPassword: PropTypes.func.isRequired,
    checkCurrentPassword: PropTypes.func.isRequired,
    checkNewPassword: PropTypes.func.isRequired,
    checkConfirmPassword: PropTypes.func.isRequired,
    checkSave: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    checkIsEqualNow: PropTypes.bool.isRequired
};

export default ChangePassword;
