import CustomModal from 'containers/CustomModal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PopupFrame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
        this.modalData = {};
    }

    close = data => {
        this.modalData = data;
        this.setState({
            isOpen: false
        });
    };

    handleOnClosed = () => {
        const { close } = this.props;
        close(this.modalData);
    };

    render() {
        const { children, className, modalClassName } = this.props;
        const { isOpen } = this.state;
        const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { close: this.close }));
        return (
            <CustomModal isOpen={isOpen} fade={false} backdrop="static" onClosed={this.handleOnClosed} className={className} modalClassName={modalClassName}>
                {childrenWithProps}
            </CustomModal>
        );
    }
}

PopupFrame.propTypes = {
    children: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    modalClassName: PropTypes.string.isRequired
    // beforeCloseCallBack: PropTypes.any.isRequired,
    // autoCloseOption: PropTypes.object.isRequired
};

export default PopupFrame;
