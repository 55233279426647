/* eslint-disable no-param-reassign */
import { $http } from 'modules/index';
import * as moment from 'moment/moment';

class SearchRest {
    static instant(params = {}) {
        const config = { params };
        return $http.get(`/drive/v1/search/instant`, config).then(response => {
            return response.data.list;
        });
    }

    static integrated(params = {}) {
        if (params.objtStatChgDtFrom) {
            params.objtStatChgDtFrom = moment(`${params.objtStatChgDtFrom}T00:00:00+00:00`)
                .subtract(Number(params.utcOffset), 'm')
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }
        if (params.objtStatChgDtTo) {
            params.objtStatChgDtTo = moment(`${params.objtStatChgDtTo}T23:59:59+00:00`)
                .subtract(Number(params.utcOffset), 'm')
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }
        delete params.utcOffset;

        const config = { params };
        return $http.get(`/drive/v1/search/integrated`, config).then(response => {
            return response.data;
        });
    }

    static searchClick() {
        return $http.get(`/drive/v1/folders/personal/search-click`).then(response => {
            return response.data.list;
        });
    }

    static addSearchClick(objtId, onpstId) {
        const config = { params: { onpstId } };
        return $http.put(`/drive/v1/folders/personal/search-click/${objtId}`, config).then(response => {
            return response.data;
        });
    }
}

export default SearchRest;
