import React from 'react';
import BaseMenu from '../BaseMenu';
import { largePopup } from '../../../../utils/ModalService';
import ReqRejectPop from '../../ReqMng/Popup/ReqRejectPop';

const executor = (props, files) => {
    largePopup(<ReqRejectPop reqId={files[0].reqId} rejectType="SINGLE" />);
};

class RejectRequest extends BaseMenu {
    getName() {
        return 'rejectRequest';
    }

    getLabel() {
        return 'com.button.reject';
    }

    isVisible() {
        return true;
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-rejected', executor);
    }

    // getIconDom() {
    //     return this.getBaseIconDom(this.getName(), null, 'ic-20-extend', executor);
    // }

    execute() {
        executor(this.props, this.files);
    }
}

export default RejectRequest;
