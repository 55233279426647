import React, { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import ClickOutside from '../../../../presentationals/ClickOutside';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../../Common/SummaryConstant';
import { summaryActions } from '../../../../../redux/actions/Summary';

const PromptDropdown = () => {
    const TRANSFORM_Y = -79;
    const PROMPT_HEIGHT = 40;

    const summaryPromptButtonRef = useRef();
    const summaryPromptDropdownRef = useRef();

    const [transformY, setTransformY] = useState(TRANSFORM_Y);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const promptTemplateTypes = useMemo(() => {
        return Object.values(SUMMARY_PROMPT_TEMPLATE_TYPE);
    }, []);

    useEffect(() => {
        setTransformY(TRANSFORM_Y - PROMPT_HEIGHT * (promptTemplateTypes.length - 1));
    }, []);

    const handleClick = () => {
        setIsOpen(prev => !prev);
    };

    const handleClickOutside = () => {
        setIsOpen(false);
    };

    const handleClickPromptTemplate = promptTemplateType => {
        dispatch(summaryActions.promptInput.insertPromptTemplate({ promptTemplateType }));
        setIsOpen(false);
    };

    return (
        <ClickOutside onClickOutside={handleClickOutside} childrenRefs={[summaryPromptButtonRef, summaryPromptDropdownRef]}>
            <div className={classnames('dropdown', isOpen && 'show')}>
                <a className="btn-ic-nor dropdown-toggle" role="button" onClick={handleClick} ref={summaryPromptButtonRef}>
                    <i className="ic-30-copilot-fab" />
                </a>
                <div
                    className={classnames('dropdown-menu', isOpen && 'show')}
                    style={{ position: 'absolute', transform: `translate3d(0px, ${transformY}px, 0px)`, top: '0px', left: '0px', willChange: 'transform' }}
                    ref={summaryPromptDropdownRef}>
                    <div className="title">
                        <FormattedMessage id="drive.text.copilot.summary.recommended_prompt" />
                    </div>
                    <ul>
                        {promptTemplateTypes.map(promptTemplateType => {
                            return (
                                <li key={promptTemplateType} onClick={() => handleClickPromptTemplate(promptTemplateType)}>
                                    <a className="prompt-item" role="button">
                                        <span>
                                            <FormattedMessage id={`drive.text.copilot.summary.pt.${promptTemplateType}`} />
                                        </span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </ClickOutside>
    );
};

PromptDropdown.propTypes = {};

PromptDropdown.defaultProps = {};

export default PromptDropdown;
