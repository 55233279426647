import store from '../redux/store';
import FileReloadAction from '../redux/actions/FileReload';

export default class FileReload {
    /**
     * 파일 재조회 함수. 메뉴 콜백용
     * @param {JSON} props. 미사용변수. 메뉴콜백 순서맞추기 위해 필요
     * @param {JSON} files
     * @param {JSON} data
     * @param {String} data.reloadType refresh, cached, uncheckedAll, openDetail, uploadComplete
     * @param {Array} data.reloadFiles 대상 파일/폴더 목록
     * @param {Boolean} data.reloadCreated cached인 경우만 사용. 업로드된 데이터여부
     * @param {Boolean} data.reloadPrefer cached인 경우만 사용. prefer 데이터 여부
     * @param {Boolean} data.reloadReq cached인 경우만 사용. 요청 데이터 여부
     */
    static call(props, files, data = {}) {
        const { reloadType = 'cached', reloadFiles = files, reloadCreated = false, reloadPrefer = false, reloadReq = false } = data;
        store.dispatch({
            type: FileReloadAction.EXECUTE,
            data: {
                ...data,
                reloadType,
                reloadFiles,
                reloadCreated,
                reloadPrefer,
                reloadReq
            }
        });
    }

    static getState() {
        const storeState = store.getState();
        const { fileReload } = storeState;
        return fileReload;
    }

    static getFolderInformation() {
        const storeState = store.getState();
        const { fileReload } = storeState;
        const { folder = { drive: {}, actionPolicy: {} } } = fileReload;
        return folder;
    }
}
