import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import FileIconStyle from '../../../utils/FileIconStyle';
import { alert } from '../../../utils/ModalService';
import WebOfficeRest from '../../../apis/WebOfficeRest';
import WebOfficeUtil from './WebOfficeUtil';

class WebOffice extends Component {
    constructor(props) {
        super(props);
        // console.log('props: ', props);
        this.state = {
            objtInfo: {},
            sharePolicy: {},
            webOfficeYn: 'Y',
            webOfficeUrl: ''
        };
    }

    componentDidMount() {
        const { configs } = this.props;
        const webOfficeYn = WebOfficeUtil.getUseYn(configs);
        const webOfficeUrl = WebOfficeUtil.getUrlPrefix(configs);
        this.setState({ webOfficeYn, webOfficeUrl });
        this.getObjtInfo();
    }

    getObjtInfo = async () => {
        // const { match } = this.props;
        // const { incomLinkUrl } = match.params;
        const { objtId } = this.props;

        const objtResponse = await WebOfficeRest.getObjtInfo(objtId);
        console.log('objtInfo : ', objtResponse);
        const { resultCode, data } = objtResponse;

        if (resultCode !== 200) {
            alert('drive.alert.incomLink.noAuthFile').then(() => {
                this.close();
            });
            return;
        }

        const { objtSectCd = 'FILE', sharePolicy } = data;
        if (objtSectCd === 'FOLDER') {
            alert('drive.alert.incomLink.noAuthFile').then(() => {
                this.close();
            });
            return;
        }

        this.setState({ objtInfo: data, sharePolicy });
    };

    getUrl = (objtInfo, appKey, reqType) => {
        const { loginUser } = this.props;
        const { webOfficeUrl } = this.state;
        return WebOfficeUtil.getUrl(objtInfo, loginUser, appKey, reqType, webOfficeUrl);
    };

    getParams = (objtInfo, appKey) => {
        const { loginUser } = this.props;
        return WebOfficeUtil.getParams(objtInfo, loginUser, appKey);
    };

    goFileViewer = () => {
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getViewerKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const url = this.getUrl(objtInfo, appKey, 'open');
        window.open(url, '_blank');
    };

    goFileEditor = () => {
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getEditorKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const url = this.getUrl(objtInfo, appKey, 'open');
        window.open(url, '_blank');
    };

    goFileLock = () => {
        console.log('goFileLock');
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getViewerKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const params = this.getParams(objtInfo, appKey);
        this.setObjtLock(params);
    };

    goFileUnLock = () => {
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getViewerKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const params = this.getParams(objtInfo, appKey);
        this.setObjtUnlock(params);
    };

    goFileDownload = () => {
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getViewerKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const params = this.getParams(objtInfo, appKey);
        this.getObjtStream(params);
        // const url = this.getUrl(objtInfo.objtNm, objtId, appKey, 'get');
        // window.open(url, '_blank');
    };

    goFileUpload = () => {
        const { objtInfo } = this.state;

        const appKey = WebOfficeUtil.getViewerKey(objtInfo);
        if (appKey === undefined) {
            alert('drive.text.viewerError.notSupportExtNm');
            return;
        }

        const params = this.getParams(objtInfo, appKey);
        this.putObjtStream(params, 'abcTest123');
    };

    setObjtLock = async params => {
        const { objtId } = this.props;
        const objtResponse = await WebOfficeRest.setObjtLock(objtId, params);
        console.log('setObjtLock : ', objtResponse);
    };

    setObjtUnlock = async params => {
        const { objtId } = this.props;
        const objtResponse = await WebOfficeRest.setObjtUnlock(objtId, params);
        console.log('setObjtUnlock : ', objtResponse);
    };

    getObjtStream = async params => {
        const { objtId } = this.props;
        const objtResponse = await WebOfficeRest.getObjtStream(objtId, params);
        console.log('getObjtStream : ', objtResponse);
    };

    putObjtStream = async (params, body) => {
        const { objtId } = this.props;
        const objtResponse = await WebOfficeRest.putObjtStream(objtId, params, body);
        console.log('putObjtStream : ', objtResponse);
    };

    moreCallBack = () => {
        // const { drive: folderFile } = file;
        // console.log(folderFile); // FileIcon에 넣었던 형태
    };

    fileIconCustom = {
        // statusCallback: isOpen => {
        //     setHover(isOpen);
        // },
        openType: 'left',
        moreGroupType: 'inLink'
    };

    close = () => {
        // maru close는 하나만. ie에서 탭닫기 컨펌이 close()횟수만큼 나옴 O_O
        // window.close();
        // self.close();s
        // window.opener = window.location.href;
        // self.close();
        window.open('about:blank', '_self').close();
    };

    render() {
        const { objtInfo, sharePolicy, webOfficeYn } = this.state;

        console.log('objtInfo', objtInfo);
        console.log('sharePolicy', sharePolicy);

        // 웹오피스 테스트용
        const testYn = false;
        let officeBtnYn = true;
        let officeViewerYn = true;
        let officeEditorYn = true;
        if (_.isNil(sharePolicy)) {
            officeBtnYn = false;
        } else {
            officeViewerYn = WebOfficeUtil.isViewerEnable(objtInfo, sharePolicy);
            officeEditorYn = WebOfficeUtil.isEditorEnable(objtInfo, sharePolicy);
        }

        if (!objtInfo.objtNm || objtInfo.objtSectCd === 'FOLDER') return '';

        console.log('officeBtnYn', officeBtnYn);
        console.log('officeViewerYn', officeViewerYn);
        console.log('officeEditorYn', officeEditorYn);

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.incomLink.title" />
                    </h5>
                    <FormattedMessage id="com.close">
                        {closeTitle => (
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={closeTitle}
                                data-dismiss="modal"
                                onClick={() => {
                                    this.close();
                                }}>
                                <i className="ic-20-close" />
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <div className="modal-body">
                    <div className="box-line">
                        <div className="inner-grid-view">
                            <div className="grid-cell file-cell">
                                <div className="item-file">
                                    <i className={FileIconStyle.getStyle('ic-file-sm', objtInfo.objtNm)} />
                                    <div className="file" title={objtInfo.objtNm}>
                                        <span className="file-name">{WebOfficeUtil.getFileName(objtInfo.objtNm)}</span>
                                        <span className="file-extension">{WebOfficeUtil.getExtension(objtInfo.objtNm)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-cell option-cell">
                                <ul className="item-option nav">
                                    <li className="option-more" />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <If condition={webOfficeYn === 'Y'}>
                    <div className="modal-footer">
                        <div className="modal-footer-option" />
                        {officeBtnYn && (
                            <div className="modal-footer-btns">
                                {officeViewerYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileViewer()}>
                                        <span className="btn-text">뷰어 열기</span>
                                    </a>
                                )}
                                {officeEditorYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileEditor()}>
                                        <span className="btn-text">편집기 열기</span>
                                    </a>
                                )}
                                {testYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileLock()}>
                                        <span className="btn-text">LOCK</span>
                                    </a>
                                )}
                                {testYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileUnLock()}>
                                        <span className="btn-text">UNLOCK</span>
                                    </a>
                                )}
                                {testYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileDownload()}>
                                        <span className="btn-text">다운로드</span>
                                    </a>
                                )}
                                {testYn && (
                                    <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileUpload()}>
                                        <span className="btn-text">버전업</span>
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </If>
            </React.Fragment>
        );
    }
}

WebOffice.propTypes = {
    // match: PropTypes.object,
    objtId: PropTypes.string,
    // callback: PropTypes.func,
    // close: PropTypes.func,
    loginUser: PropTypes.object.isRequired,
    configs: PropTypes.array.isRequired
    // push: PropTypes.func.isRequired,
    // location: PropTypes.object.isRequired,
    // intl: PropTypes.object.isRequired
};
WebOffice.defaultProps = {
    // match: {},
    objtId: ''
    // callback: () => {},
    // close: () => {}
};

const mapStateToProps = state => ({
    loginUser: state.auth.user,
    configs: state.config.configs
});

const mapDispatchToProps = {
    push: routerPush
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(WebOffice));
