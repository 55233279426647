import { $http } from 'modules/index';

class DriveCodeRest {
    static retrieveCodes(tenantId = '10001', locale = '001') {
        const params = {
            tenantId,
            langCd: locale
        };
        return $http.get('/drive/v1/code/4001700/list', { params });
    }

    static agentDownloadUrlCode(tenantId) {
        const param = {
            tenantId,
            langCd: '001'
        };
        return $http.get('/drive/v1/code/4003000/list', { param }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }
}

export default DriveCodeRest;
