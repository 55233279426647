import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FoldersRest from 'src/apis/FoldersRest';
import FileIconStyle from 'src/utils/FileIconStyle';
import Option from 'src/components/containers/DriveFiles/Column/Option';
import { withFilesContext } from 'src/components/containers/DriveFiles/DriveFilesContext';
import Viewer from '../../presentationals/FileMenu/Menu/Viewer';
import FileReload from '../../../utils/FileReload';
import { getUrlPrefix } from '../../../utils/Url';

const FavoriteFile = props => {
    const [hover, setHover] = useState(false);
    const { file, context } = props;
    const goToPage = (f, c, e) => {
        const {
            props: { history }
        } = c;
        const { drive } = f;

        const url = getUrlPrefix(f);

        if (drive.objtSectCd === 'FOLDER') {
            history.push(`${url}/${drive.objtId}`, { onpstId: drive.onpstId, maxAuthCd: f.maxAuthCd });
            e.stopPropagation();
        } else {
            new Viewer({ callback: FileReload.call }, [f]).execute();
        }
    };

    const openFile = (selectedFile, e) => {
        goToPage(selectedFile, context, e);
    };

    const fileIconCustom = {
        statusCallback: isOpen => {
            setHover(isOpen);
        },
        openType: 'normal_left',
        moreGroupType: 'recent'
    };
    return (
        <>
            <li className={`item-list ${hover ? ' is-hovered' : ''}`}>
                <div className="item-file" key={file.drive.objtId}>
                    <i className={FileIconStyle.getStyle(`ic-file-md`, file.drive.objtNm)} />
                    <div className="file file-link" title={file.drive.objtNm} onClick={e => openFile(file, e)} role="presentation">
                        <span className="file-name">{file.drive.objtNm}</span>
                    </div>
                </div>
                <ul className="files-option nav">
                    <Option groupType="normal" iconType="normal" file={file} fileIconCustom={fileIconCustom} atHome={true} cardViewOption={true} />
                </ul>
            </li>
        </>
    );
};

const FavoriteFiles = props => {
    const { rootFolderInfo, context, fileLengthTobeShown } = props;
    const { objtId, onpstId } = rootFolderInfo;
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        FoldersRest.getFolder(objtId, { onpstId, size: fileLengthTobeShown, objtSectCd: 'FILE', sortOrder: 'DESC', sortField: 'objtStatChgDt' }).then(data => setFileList(data.list));
    }, [rootFolderInfo]);

    return (
        <>
            {fileList.map(file => (
                <FavoriteFile file={file} key={file.drive.objtId} context={context} />
            ))}
        </>
    );
};

FavoriteFiles.displayName = 'FavoriteFiles';
FavoriteFiles.propTypes = {
    context: PropTypes.object.isRequired,
    fileLengthTobeShown: PropTypes.number.isRequired,
    rootFolderInfo: PropTypes.object.isRequired
};

FavoriteFile.propTypes = {
    file: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
};

export default withFilesContext(FavoriteFiles, []);
