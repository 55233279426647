import _ from 'lodash';
import $http from 'modules/Http';

class DeptRest {
    cache = {};

    memberTotalCache = {};

    retrieveDeptById(deptId, tenantId) {
        return $http.get('/auth/v1/depts', { params: { deptId, tenantId } }).then(res => _.get(res.data, '[0]'));
    }

    retrieveDeptByIdWithCache(id, tenantId) {
        if (this.cache[id]) {
            return Promise.resolve(this.cache[id]);
        }

        return this.retrieveDeptById(id, tenantId).then(dept => {
            this.cache[id] = dept;
            return dept;
        });
    }

    retrieveDepts(params) {
        return $http.get('/auth/v1/depts', { params }).then(res => res.data);
    }

    getDepartmentMemberCount(deptId) {
        return $http.get('/auth/v1/users', { params: { deptId } }).then(res => res.total);
    }

    getDepartmentMemberCountWithCache(deptId) {
        if (this.memberTotalCache[deptId]) {
            return this.memberTotalCache[deptId];
        }
        return this.getDepartmentMemberCount(deptId).then(total => {
            this.memberTotalCache[deptId] = total;
            return total;
        });
    }

    retreiveSubDepartments(tenantId, deptId) {
        return $http.get(`/auth/v1/depts/${deptId}/sub`, { params: { tenantId } });
    }
}

export default new DeptRest();
