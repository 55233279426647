/* eslint-disable */
import React from 'react';
import { alert } from '../ModalService';
import SizeConverter from "../SizeConverter";
import { FormattedMessage } from 'react-intl';

const EXT_MNG_TYP   = 'EFL_DRV_CNTS_EXTENSION_MANAGE_TYPE_L';
const MAX_SIZE      = 'EFL_DRV_UPLOAD_MAX_FILE_SIZE';
const SELECT_LIMIT  = 'EFL_DRV_UPLOAD_SELECT_FILE_LIMIT';
const NO_EXT_NM     = ':no_ext_nm';

const getExt = name => {

    let ext = null;
    const lastIndex = name.lastIndexOf('.');
    if(lastIndex > -1){
        ext = name.substring(lastIndex+1).toLowerCase();
    }

    return ext;

}

const isFunction = value => {
    return Boolean(value && typeof value === 'function');
}

class Filters {

    constructor() {

        this.defaultSelectLimit = 100;
    }

    getFilters = (names) => {
        let filters = [];

        if(Array.isArray(names)){
            names.forEach(name => { filters.unshift({name : name, fn : this[name]})});
            return filters;
        }

        return filters;
    }

    selectlimit = (items, uploader) => {

        const { configs } = uploader.configs;
        const limit = (!!configs[SELECT_LIMIT]) ? configs[SELECT_LIMIT] : this.defaultSelectLimit ;

        if(items.length <= limit){
            return [...items];
        }else{
            return [...items.slice(0, limit)];
        }
    }

    folder = (item) => {
        return (!!item.type || !!item.size);
    }

    quelimit(item, options, uploader) {
        return uploader.queue.length < uploader.queueLimit;
    }

    ext = (item, options, uploader) => {

        const { exts, configs } = uploader.configs;
        const typ = configs[EXT_MNG_TYP];

        if('NOT_USE' === typ) {
            return true;
        }
        let ext = getExt(item.name);
        if(ext == null) ext = NO_EXT_NM;

        if('PERMIT' === typ){
            return (exts.indexOf(ext) > -1);
        }else if('RESTRICTION' === typ){
            return (exts.indexOf(ext) < 0);
        }

        return false;
    }

    size = (item, options, uploader) => {
        const { configs } = uploader.configs;
        return (item.size < configs[MAX_SIZE]);
    }

    selectlimitHandler = (items, uploader) => {

        const { configs } = uploader.configs;
        const limit = (!!configs[SELECT_LIMIT]) ? configs[SELECT_LIMIT] : this.defaultSelectLimit ;

        //alert('한번에 선택 가능한 파일 수는 100개 입니다.');
        alert(<FormattedMessage id="drive.alert.upload.filter.selectlimit" values={{ limit }} />);
    }

    folderHandler = (items, uploader) => {

        //폴더는 업로드 할 수 없습니다.
        alert(<FormattedMessage id="drive.alert.upload.filter.folder" />);

    }

    quelimitHandler = (items, uploader) => {

        //console.log('quelimitHandler', items);
    }

    extHandler = (items, uploader) => {
        const exts = items.map(item => getExt(item.item.name))
                     .reduce((result, ext) => (result.indexOf(ext) > -1) ? [...result] : [...result, ext], []).join();

        //{exts} 확장자는 업로드 할 수 없습니다.
        alert(<FormattedMessage id="drive.alert.upload.filter.exts" values={{ exts: exts }} />);
    }

    sizeHandler = (items, uploader) => {
        const { configs } = uploader.configs;
        const size = SizeConverter.convert(configs[MAX_SIZE]);

        //파일크기가 {maxSize} 이상인 경우는 업로드 할 수 없습니다.
        alert(<FormattedMessage id="drive.alert.upload.filter.size" values={{ maxSize: size }} />);
    }

    filterdItemsHandler = (fails, uploader) => {

        const grouping = fails.reduce(
            (result, item) => ({
                ...result,
                [item.filter.name]: [
                    ...(result[item.filter.name] || []),
                    item,
                ],
            }),
            {},
        );

        for (let key of Object.keys(grouping)) {

            const handler = `${key}Handler`;

            if(isFunction(this[handler])){
                this[handler](grouping[key], uploader);
            }
        }

    }
}

export default new Filters();
