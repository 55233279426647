import ConfigsRest from 'apis/ConfigsRest';
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { SET_SETTINGS, SET_USER } from '../actions/Auth';
import { CLOSE_TEAM_SEARCH_WITH_TREE, OPEN_TEAM_SEARCH_WITH_TREE } from '../actions/GlobalPopup';
import {
    ADD_PARTNER,
    CHANGE_PARTNER_VALIDATION_VALUE,
    CHANGE_PARTNER_VALUE,
    OPEN_POPUP,
    OPEN_POPUP_AFTER_SET_CONFIGS,
    REMOVE_PARTNER,
    SET_DEFAULT_LANGUAGE,
    SET_EXPIRE_DATE_CONFIGS,
    SET_IS_SAVE_ABLE,
    SET_REGISTER_TYPE,
    SET_TEAM_SEARCH_INDEX,
    SET_DEFAULT_USER_IF_YN,
    SET_DEFAULT_PARTNER_IF_YN
} from '../actions/PartnerAccount';

const getTeamSearchIndex = state => state.partnerAccount.teamSearchIndex;
const getPartnerList = state => state.partnerAccount.partnerList;
const getValidationList = state => state.partnerAccount.validationList;
const getRegisterType = state => state.partnerAccount.registerType;
const getLanguageCode = state => state.auth.user.locale.languageCode;

function* setUser(action) {
    yield put({ type: SET_DEFAULT_LANGUAGE, language: action.user.langCd });
}

function* setSettings(action) {
    yield put({ type: SET_DEFAULT_LANGUAGE, language: action.languageCode });
}

function* openTeamSearchWithTree(action) {
    yield put({ type: SET_TEAM_SEARCH_INDEX, index: action.param.index });
}

function* closeTeamSearchWithTree(action) {
    const teamSearchIndex = yield select(getTeamSearchIndex);
    if (teamSearchIndex !== null && !_.isEmpty(action.team)) {
        yield put({ type: CHANGE_PARTNER_VALUE, index: teamSearchIndex, target: 'deptId', value: action.team.id });
        yield put({ type: CHANGE_PARTNER_VALUE, index: teamSearchIndex, target: 'deptNm', value: action.team.title });
        yield put({ type: CHANGE_PARTNER_VALIDATION_VALUE, index: teamSearchIndex, target: 'deptId', value: '' });
        yield put({ type: SET_TEAM_SEARCH_INDEX, index: null });
    }
}

function isEmptyString(obj, keys) {
    for (let i = 0; i < keys.length; i += 1) {
        if (obj[keys.pop()] === '') {
            return true;
        }
    }
    return false;
}

function* setIsSaveAble() {
    let isSaveAble = true;
    const partnerList = yield select(getPartnerList);
    const validationList = yield select(getValidationList);
    const registerType = yield select(getRegisterType);

    if (registerType === 'single') {
        for (let i = 0; i < partnerList.length; i += 1) {
            const partner = partnerList[i];
            const keys = ['id', 'name', 'engName', 'deptId', 'companyNm'];
            if (isEmptyString(partner, keys)) {
                isSaveAble = false;
            }
            // if (partner.id === '' || partner.name === '' || partner.position === '' || partner.engName === '' || partner.engPosition === '' || partner.deptId === '' || partner.companyNm === '')
            //     isSaveAble = false;

            if (!isSaveAble) break;
        }

        if (isSaveAble) {
            for (let i = 0; i < validationList.length; i += 1) {
                const validation = validationList[i];
                if (validation.id !== '' || (partnerList[i].language !== '002' && validation.name !== '') || (partnerList[i].language !== '002' && validation.position !== '')) isSaveAble = false;
                if (validation.engName !== '') isSaveAble = false;
                if (validation.engPosition !== '') isSaveAble = false;
                if (validation.deptId !== '') isSaveAble = false;
                if (validation.companyNm !== '') isSaveAble = false;
                if (!isSaveAble) break;
            }
        }
    } else if (registerType === 'multi') {
        isSaveAble = false;
    }

    yield put({ type: SET_IS_SAVE_ABLE, isSaveAble });
}

function* openPopup() {
    const configs = yield call(ConfigsRest.getConfigs);
    let maxUseDays = 365;
    if (_.find(configs, { cfgId: 'EFL_AUTH_USER_SUBCONTRACTOR_DEFAULT_MAX_USE_DAYS' })) {
        maxUseDays = parseInt(_.find(configs, { cfgId: 'EFL_AUTH_USER_SUBCONTRACTOR_DEFAULT_MAX_USE_DAYS' }).cfgVal, 10);
    }
    yield put({ type: SET_EXPIRE_DATE_CONFIGS, maxUseDays });

    let userIfYn = 'N';
    if (_.find(configs, { cfgId: 'EFL_AUTH_USER_IF_YN' })) {
        userIfYn = _.find(configs, { cfgId: 'EFL_AUTH_USER_IF_YN' }).cfgVal;
    }
    yield put({ type: SET_DEFAULT_USER_IF_YN, userIfYn });

    let partnerIfYn = 'N';
    if (_.find(configs, { cfgId: 'EFL_AUTH_SUBCONTRACTOR_IF_YN' })) {
        partnerIfYn = _.find(configs, { cfgId: 'EFL_AUTH_SUBCONTRACTOR_IF_YN' }).cfgVal;
    }
    yield put({ type: SET_DEFAULT_PARTNER_IF_YN, partnerIfYn });

    const languageCode = yield select(getLanguageCode);
    yield put({ type: SET_DEFAULT_LANGUAGE, language: languageCode });

    yield put({ type: OPEN_POPUP_AFTER_SET_CONFIGS });
}

function* watchSetUser() {
    yield takeLatest(SET_USER, setUser);
}

function* watchSetSettings() {
    yield takeLatest(SET_SETTINGS, setSettings);
}

function* watchOpenTeamSearchWithTree() {
    yield takeLatest(OPEN_TEAM_SEARCH_WITH_TREE, openTeamSearchWithTree);
}

function* watchCloseTeamSearchWithTree() {
    yield takeLatest(CLOSE_TEAM_SEARCH_WITH_TREE, closeTeamSearchWithTree);
}

function* watchIsSaveAble() {
    yield takeLatest([ADD_PARTNER, REMOVE_PARTNER, CHANGE_PARTNER_VALUE, CHANGE_PARTNER_VALIDATION_VALUE, SET_REGISTER_TYPE], setIsSaveAble);
}

function* watchOpenPopup() {
    yield takeLatest(OPEN_POPUP, openPopup);
}

export default function* rootSaga() {
    yield all([watchSetUser(), watchSetSettings(), watchOpenTeamSearchWithTree(), watchCloseTeamSearchWithTree(), watchIsSaveAble(), watchOpenPopup()]);
}
