import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import Thumbnail from './Thumbnail';
import { withFilesContext } from '../DriveFilesContext';
import Tooltips from '../../../presentationals/Tooltips';
import FileIcon from '../../../presentationals/FileMenu/FileIcon';
import FileReload from '../../../../utils/FileReload';

class FileName extends React.PureComponent {
    handleOnClick = e => {
        const { file, context, pageClass } = this.props;
        pageClass.goToPage(file, context, e);
    };

    getNameComp = (objtNm = '', query = '') => {
        const q = query ? query.trim() : '';
        // eslint-disable-next-line prettier/prettier
        const qQueries = q ? q.split(' ').map(qq => qq.trim()).filter(qq => qq) : [];
        return <span className="file-name">{qQueries.length ? <Highlighter searchWords={qQueries} autoEscape={true} textToHighlight={objtNm} /> : objtNm}</span>;
    };

    render() {
        const {
            file,
            file: { drive, version: { fileVerSno = 1 } = {}, sharedCount = 0, users: { fileEditingUser } = {} },
            context: {
                props: { folderId }
            },
            context,
            pageClass,
            query
        } = this.props;

        const {
            menu: {
                row: { floatingIconGroupType }
            }
        } = pageClass;

        // 1depth (shared || workgroup) 목록인 경우, shareRootMrkNm 표시(즐겨찾기 때문에 웹에서 한번 더 처리)
        let fileName = drive.objtNm;
        if (folderId === pageClass.ROOT_FOLDER_ID && (pageClass.pageId === 'shared' || pageClass.pageId === 'workgroup')) {
            if (drive.objtSectCd === 'FOLDER' && !!drive.shareRootMrkNm) {
                fileName = (drive.shareRootMrkNm || '').trim();
            }
        }

        const possibleClick = pageClass.possibleNameClick(folderId);
        const extensionIcon = pageClass.getExtensionIcon(file, context);
        const lowerExtNm = drive.objtNm.substring(drive.objtNm.lastIndexOf('.') + 1).toLowerCase();

        return (
            <div className="grid-cell files-name-cell">
                <div className="item-file">
                    <i id={`files-name-cell-${drive.objtId}`} className={extensionIcon} />
                    {drive.objtSectCd === 'FILE' && fileEditingUser && (
                        <Tooltips targetId={`files-name-cell-${drive.objtId}`} placement="auto">
                            <div className="empty">
                                <FormattedMessage id="drive.tooltip.list.columns.fileType.edit" values={{ names: fileEditingUser.userNm }} />
                            </div>
                        </Tooltips>
                    )}

                    {drive.objtSectCd === 'FILE' && !fileEditingUser && extensionIcon.indexOf('IMAGE') >= 0 && (
                        <Tooltips targetId={`files-name-cell-${drive.objtId}`} placement="auto" style={{ minWidth: '195px', minHeight: '155px' }}>
                            <Thumbnail objtId={drive.objtId} onpstId={drive.onpstId} fileVerSno={fileVerSno} ext={lowerExtNm} />
                        </Tooltips>
                    )}

                    {possibleClick ? (
                        <div className="file file-link" role="button" onClick={this.handleOnClick} title={fileName}>
                            {this.getNameComp(fileName, query)}
                        </div>
                    ) : (
                        <div className="file" title={fileName}>
                            {this.getNameComp(fileName, query)}
                        </div>
                    )}
                </div>

                <FileIcon groupType={floatingIconGroupType} iconType="row-floating" files={[file]} callback={FileReload.call} />

                {drive.objtSectCd === 'FILE' && sharedCount ? (
                    <span className="btn-ic-nor">
                        <i className="ic-20-share-group" />
                    </span>
                ) : null}
            </div>
        );
    }
}

FileName.defaultProps = {
    query: undefined
};

FileName.propTypes = {
    file: PropTypes.object.isRequired,
    pageClass: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    query: PropTypes.string
};

FileName.displayName = 'FileName';
export default withFilesContext(FileName, ['state.viewHeight']);
