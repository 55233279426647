import React from 'react';
import PropTypes from 'prop-types';
import DateConverter from '../../../../utils/DateConverter';

const CreatedDate = props => {
    const {
        file: { drive = {} },
        cellStyle
    } = props;
    const value = drive.objtRegDt ? DateConverter.convert(drive.objtRegDt) : '-';
    return (
        <div className="grid-cell files-createdDt-cell ellipsis" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

CreatedDate.defaultProps = {
    cellStyle: null
};

CreatedDate.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

CreatedDate.displayName = 'CreatedDate';
export default React.memo(CreatedDate);
