import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CryptoJS from 'crypto-js';

class SendToMail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.sendToMail();
    }

    sendToMail() {
        const { close, folderFileList } = this.props;

        console.log(folderFileList);
        const params = {};
        const files = [];
        params.cw_title = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse('title'));
        params.cw_contents = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(''));
        params.cw_userId = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse('flcm.test03@stage.samsungsds.com'));

        folderFileList.forEach(file => {
            const cwFile = {};
            const objtNmArr = file.objtNm.split('.');
            const fileNm = objtNmArr[0];
            const fileExtNm = objtNmArr[1];
            cwFile.gatewayUrl = '';
            cwFile.globLinkKeyVal = '';
            cwFile.fileNm = fileNm;
            cwFile.fileExtNm = fileExtNm;
            cwFile.fileSize = '111';

            files.push(cwFile);
        });
        params.cw_attachments = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify({ files })));

        const jsonForm = document.createElement('form');
        jsonForm.setAttribute('target', 'sendToMailPop');
        jsonForm.setAttribute('method', 'post');
        jsonForm.setAttribute('action', 'http://kr1.stage.samsung.net/mail/jsp/writeFormForExt');
        jsonForm.setAttribute('id', 'formForSendToMail');

        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', 'params');
        hiddenField.setAttribute('value', JSON.stringify(params));
        jsonForm.appendChild(hiddenField);

        const popup = window.open('about:blank', 'sendToMailPop', 'width=920, height=707, scrollbars=no, resizable=yes');
        popup.document.write(jsonForm.outerHTML);
        popup.document.getElementById('formForSendToMail').submit();

        close();
    }

    render() {
        return (
            <React.Fragment>
                <div />
            </React.Fragment>
        );
    }
}
SendToMail.propTypes = {
    close: PropTypes.func,
    folderFileList: PropTypes.array
};

SendToMail.defaultProps = {
    close: () => {},
    folderFileList: []
};

export default SendToMail;
