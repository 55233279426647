import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { If } from 'jsx-control-statements';
import NotificationConstants from 'constants/Notification';
import SelectBox from 'presentationals/SelectBox';
import NotificationRest from 'apis/NotificationRest';
import moment from 'moment';
import uuid from 'uuid/v4';
import _ from 'lodash';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import NotificationItem from '../presentationals/NotificationItem';
import ConfigsRest from '../../apis/ConfigsRest';

class Notification extends Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;
        this.intl = intl;
        this.state = {
            notifications: [],
            totalCount: 0,
            displayedNotificationCount: 0,
            notificationCategory: '',
            readStatus: '',
            searchString: '',
            searchInputFocus: false,
            deleteYn: 'N',
            maxSearchDays: '30'
        };
        this.lastNotification = {};
        this.searchedKeyword = '';
        this.search = false;
    }

    componentDidMount() {
        document.body.classList.remove('scroll-hidden');
        this.updateFixedContainerWidthInterval = setInterval(() => {
            this.updateFixedContainerWidth();
        }, 100);
        this.search = false;
        this.getNotifications();

        ConfigsRest.getCommonConfig('EFL_COM_PERMANENTLY_DELETE_YN').then(response => {
            const { cfgVal } = response;
            this.setState({ deleteYn: cfgVal });
        });

        ConfigsRest.getCommonConfig('EFL_COM_PERMANENTLY_DELETE_DAY').then(response => {
            const { cfgVal } = response;
            this.setState({ maxSearchDays: cfgVal });
        });
    }

    componentWillUnmount() {
        clearInterval(this.updateFixedContainerWidthInterval);
        this.updateFixedContainerWidthInterval = null;
        document.body.classList.add('scroll-hidden');
    }

    updateFixedContainerWidth = () => {
        window.$('.fixed-container').each((index, value) => {
            const fixedWidth = window
                .$(value)
                .parent('.js-for-fixed')
                .width();
            window.$(value).width(fixedWidth);
        });
    };

    getSearchParam = (isMore = false) => {
        const { notificationCategory, readStatus, searchString } = this.state;
        const searchParam = {};
        if (notificationCategory) searchParam.notificationCategory = notificationCategory;
        if (readStatus && !this.search) searchParam.readStatus = readStatus;

        // 더보기
        if (isMore) {
            const { offset, lastNotificationId } = this.lastNotification;
            searchParam.offset = offset;
            searchParam.lastNotificationId = lastNotificationId;
            // 검색어 있는 경우
            if (this.search) {
                searchParam.searchType = 'ALL';
                searchParam.searchString = this.searchedKeyword;
            }
        }
        // 검색
        else if (this.search) {
            searchParam.searchString = searchString;
            searchParam.searchType = 'ALL';
            this.searchedKeyword = searchString;
        }
        return searchParam;
    };

    getNotifications = isMore => {
        const { notifications, displayedNotificationCount } = this.state;
        const searchParam = this.getSearchParam(isMore);

        NotificationRest.getNotifications(NotificationConstants.FROM_TYPES.LIST, null, _.isEmpty(searchParam) ? null : searchParam).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { notificationViews, totalCount } = data;
            if (notificationViews && notificationViews.length > 0) {
                const lastNotification = notificationViews[notificationViews.length - 1];
                const { createdTimestamp, stackedNotificationIds } = lastNotification;
                this.lastNotification = { offset: createdTimestamp, lastNotificationId: stackedNotificationIds && stackedNotificationIds[0] };
            }
            _.forEach(notificationViews, notification => {
                const { clickReadTimestamp } = notification;
                const changedNotification = notification;
                changedNotification.read = !!clickReadTimestamp;
                changedNotification.key = uuid();
                return changedNotification;
            });
            let changedNotifications = [];
            if (this.search) {
                changedNotifications = isMore ? notifications.concat(notificationViews) : notificationViews;
            } else {
                const groupedNotifications = _(notificationViews)
                    .groupBy(item => moment(item.createdTimestamp).format('YYYY-MM-DD'))
                    .toPairs()
                    .value();
                if (isMore) {
                    _.forEach(groupedNotifications, newNotification => {
                        const target = _.find(notifications, item => item[0] === newNotification[0]);
                        if (target) target[1].push(...newNotification[1]);
                        else notifications.push(newNotification);
                    });
                    changedNotifications = notifications;
                } else {
                    changedNotifications = groupedNotifications;
                }
            }
            this.setState({
                notifications: changedNotifications,
                totalCount,
                displayedNotificationCount: isMore ? displayedNotificationCount + notificationViews.length : notificationViews.length
            });
        });
    };

    onCategoryChange = notificationCategory => {
        this.setState({ notificationCategory }, this.getNotifications);
    };

    onReadStatusChange = readStatus => {
        this.setState({ readStatus }, this.getNotifications);
    };

    onSearchStringChange = ({ target }) => {
        const { value: searchString } = target;
        this.setState({ searchString });
    };

    onSearch = () => {
        // const { searchString } = this.state;
        // if (!searchString || searchString.trim().length === 0) return;
        this.search = true;
        const { searchString: value = '' } = this.state;
        this.setState({ searchString: _.trim(value) }, this.getNotifications);
    };

    inputKeyUpEvent = ({ key }) => {
        if (key === 'Enter') this.onSearch();
    };

    cancelSearch = () => {
        this.search = false;
        this.searchedKeyword = '';
        this.setState({ searchString: '' }, this.getNotifications);
    };

    clearSearchString = () => {
        this.setState({ searchString: '' });
    };

    updateNotificationAsRead = (key, updated, groupKey) => {
        const { notifications } = this.state;
        const changedNotifications = notifications;
        if (!updated) return;
        if (this.search) {
            _.set(_.find(changedNotifications, ['key', key]), 'read', true);
        } else {
            const group = _.find(changedNotifications, groupNotification => groupNotification[0] === groupKey);
            if (group && group.length > 1) _.set(_.find(group[1], ['key', key]), 'read', true);
        }
        this.setState({ notifications: changedNotifications });
    };

    render() {
        const { intl } = this.props;
        const categories = [
            { value: '', message: intl.formatMessage({ id: 'user.noti.allNotification' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.SHARED, message: intl.formatMessage({ id: 'user.noti.share' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.UPLOAD, message: intl.formatMessage({ id: 'user.noti.uplaod' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.VERSIONUP, message: intl.formatMessage({ id: 'user.noti.versionUpdate' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.PARTNER_ACCOUNT, message: intl.formatMessage({ id: 'user.noti.partnerAccountExpired' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.OWNER_MODIFICATION, message: intl.formatMessage({ id: 'user.noti.changeOwnership' }) },
            { value: NotificationConstants.NOTIFICATION_CATEGORIES.REQUEST_MANAGEMENT, message: intl.formatMessage({ id: 'user.text.noti.requestManagement' }) }
        ];
        const readStatuses = [
            { value: '', message: intl.formatMessage({ id: 'user.noti.allStatus' }) },
            { value: NotificationConstants.READ_STATUS.READ, message: intl.formatMessage({ id: 'user.noti.read' }) },
            { value: NotificationConstants.READ_STATUS.UNREAD, message: intl.formatMessage({ id: 'user.noti.unread' }) }
        ];
        const { notifications, totalCount, notificationCategory, readStatus, searchString, displayedNotificationCount, searchInputFocus, deleteYn, maxSearchDays } = this.state;

        return (
            <div className="page-noti">
                <div className="comm-func-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            <div className="func-bar-title">
                                <h3>
                                    <FormattedMessage id="user.noti.notification" />
                                </h3>
                            </div>
                            <If condition={deleteYn === 'Y'}>
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedHTMLMessage id="user.text.req.search.limit-days" tagName="p" values={{ n: maxSearchDays }} />
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
                <div className="noti-view">
                    <div className="comm-title-bar js-for-fixed">
                        <div className="fixed-container">
                            <div className="fixed">
                                <div className="section-srch-result" style={{ display: this.search ? '' : 'none' }}>
                                    <a className="btn-ic-nor btn-func-back" role="button" title={intl.formatMessage({ id: 'com.tooltip.go.back' })} onClick={this.cancelSearch}>
                                        <i className="ic-20-arrow-back" />
                                    </a>
                                    <div className="total-count">
                                        <span className="count">
                                            <FormattedMessage id="user.helpcenter.resultCount" values={{ n: <i className="num">{totalCount}</i> }} />
                                        </span>
                                    </div>
                                </div>
                                <div className="section-filter">
                                    <SelectBox options={categories} onChange={this.onCategoryChange} value={notificationCategory} />
                                    <If condition={!this.search}>
                                        <SelectBox options={readStatuses} onChange={this.onReadStatusChange} value={readStatus} />
                                    </If>
                                </div>
                                <div className="section-srch">
                                    <div className={`box-input-group ${searchInputFocus ? 'is-focused' : ''}`}>
                                        <a
                                            className={_.isEmpty(searchString) ? 'btn-ic-nor btn-func-search' : 'btn-ic-nor btn-func-search on'}
                                            role="button"
                                            title={intl.formatMessage({ id: 'com.search' })}
                                            onClick={this.onSearch}>
                                            <i className="ic-16-search" />
                                        </a>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={intl.formatMessage({ id: 'user.noti.searchNoti' })}
                                            maxLength={100}
                                            value={searchString}
                                            onChange={this.onSearchStringChange}
                                            onKeyUp={this.inputKeyUpEvent}
                                            onFocus={() => this.setState({ searchInputFocus: true })}
                                            onBlur={() => this.setState({ searchInputFocus: false })}
                                        />
                                        <a
                                            className="btn-ic-nor btn-func-clear"
                                            role="button"
                                            title={intl.formatMessage({ id: 'com.text.clear' })}
                                            style={{ display: searchString.length > 0 ? 'inline-flex' : 'none' }}
                                            onClick={this.clearSearchString}>
                                            <i className="ic-16-clear" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="noti-view-panel">
                        <div className="noti-list" style={{ display: !notifications || notifications.length === 0 ? 'none' : '' }}>
                            {this.search ? (
                                <ol>
                                    <NotificationItem
                                        notifications={notifications}
                                        clickAction={(stackedNotificationIds, updated) => this.updateNotificationAsRead(stackedNotificationIds, updated)}
                                        hasNameCard={true}
                                        searchKeyword={this.searchedKeyword}
                                    />
                                </ol>
                            ) : (
                                notifications.map(notificationGroup => (
                                    <Fragment key={uuid()}>
                                        <h4 className="noti-title">{notificationGroup[0]}</h4>
                                        <ol key={notificationGroup[0]}>
                                            <NotificationItem
                                                notifications={notificationGroup[1]}
                                                clickAction={(key, updated) => this.updateNotificationAsRead(key, updated, notificationGroup[0])}
                                                hasNameCard={true}
                                            />
                                        </ol>
                                    </Fragment>
                                ))
                            )}
                        </div>
                        <div className="box-empty" style={{ display: !notifications || notifications.length === 0 ? '' : 'none' }}>
                            <div className="message" style={{ display: this.search ? 'none' : '' }}>
                                <span className="ic-empty-noti" />
                                <p>{intl.formatMessage({ id: 'user.noti.noNotifications' })}</p>
                            </div>
                            <div className="message" style={{ display: this.search ? '' : 'none' }}>
                                <span className="ic-empty-search" />
                                <p>{intl.formatMessage({ id: 'com.no-search-results' })}</p>
                            </div>
                        </div>
                        <a
                            className="btn btn-secondary btn-block"
                            role="button"
                            style={{ display: notifications && notifications.length > 0 && totalCount > displayedNotificationCount ? '' : 'none' }}
                            onClick={() => this.getNotifications(true)}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.viewmore' })}</span>
                        </a>
                    </section>
                    <span className="shadow" />
                </div>
            </div>
        );
    }
}

Notification.propTypes = {
    intl: PropTypes.object.isRequired
};

export default injectIntl(Notification);
