import { $http } from 'modules/index';
import _ from 'lodash';
import DriveConfigRest from './DriveConfigRest';

class FileViewerRest {
    static getViewerInfoNormal(objtId, fileVerSno, onpstId, pComplianceYn) {
        const complianceYn = _.isNil(pComplianceYn) ? 'N' : pComplianceYn;
        const params = {
            fileVerSno,
            onpstId,
            complianceYn
        };
        return $http.get(`/drive/v1/viewer/${objtId}/viewer-info`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static getViewerInfoIncomLink(objtId, incomLinkUrl, pComplianceYn) {
        const complianceYn = _.isNil(pComplianceYn) ? 'N' : pComplianceYn;
        const params = {
            incomLinkUrl,
            complianceYn
        };
        return $http.get(`/drive/v1/viewer/${objtId}/incomelink-viewer-info`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static getViewerInfoIncomTypeLink(objtId, incomLinkUrl, pComplianceYn) {
        const complianceYn = _.isNil(pComplianceYn) ? 'N' : pComplianceYn;
        const params = {
            incomLinkUrl,
            complianceYn
        };
        return $http.get(`/drive/v1/viewer/${objtId}/incometypelink-viewer-info`, { params }).then(
            response => {
                return response;
            },
            error => {
                return error;
            }
        );
    }

    static getViewerUseYn() {
        return DriveConfigRest.getConfig('EFL_DRV_FILEVIEWER_USE_YN').then(response => {
            if (['Y', 'N'].indexOf(response) !== -1) {
                return response;
            }
            return 'N';
        });
    }
}
export default FileViewerRest;
