import React from 'react';
import { FormattedMessage } from 'react-intl';
import { confirm, toast, alert } from 'src/utils/ModalService';
import RecycleBinRest from 'src/apis/RecycleBinRest';
import BaseMenu from '../BaseMenu';

function getParentFolderId(pathVal) {
    const rootFolderId = '111100000000000000';
    if (!pathVal) return rootFolderId;
    const folders = pathVal.split('/');
    if (pathVal.length < 2) return rootFolderId;
    return folders[folders.length - 2];
}

const executor = (props, files) => {
    console.log('props ', props);

    function complete() {
        const forcedRefreshTypeList = ['duplicated', 'duplicatedMulti', 'similar', 'similarMulti', 'expiration', 'expirationMulti', 'compliance', 'complianceMulti'];

        if (forcedRefreshTypeList.includes(props.groupType)) {
            toast('drive.toast.tempDelete.complete');
            props.callback({ reloadType: 'refresh' });
            return;
        }

        if (props.custom.folder) {
            const { folder, move } = props.custom;
            const deleted = files[0].drive;
            if (deleted.objtId === folder.objtId) {
                toast('drive.toast.tempDelete.completeNMoveUpper');
                move(getParentFolderId(folder.browserPathId));
            } else {
                toast('drive.toast.tempDelete.complete');
                props.callback({ reloadType: 'cached', reloadCreated: false, reloadFiles: files, folderChanged: true });
            }
        } else {
            toast('drive.toast.tempDelete.complete');
            props.callback({ reloadType: 'cached', reloadCreated: false, reloadFiles: files, folderChanged: true });
        }
    }

    function fail(result) {
        const { resultCode } = result;
        if (resultCode === 20611) {
            if (files.length === 1) {
                if (files[0].drive.onpstSectCd === 'FOLDER') {
                    // 폴더 단일 선택
                    // 삭제를 할 수 없습니다. 하위파일이 사용 중인지 확인해주세요.
                    toast('drive.toast.tempDelete.containUseFileFolder');
                } else if (files[0].drive.onpstSectCd === 'FILE') {
                    // 파일 단일 선택
                    //  삭제를 할 수 없습니다. 파일이 실행 중인지 확인해주세요.
                    toast(' drive.toast.tempDelete.isUseFile');
                }
            } else {
                let fileCnt = 0;
                for (let i = 0; i < files.length; i += 1) {
                    if (files[i].drive.onpstSectCd === 'FILE') {
                        fileCnt += 1;
                    }
                }

                if (fileCnt === files.length) {
                    // 파일 멀티 선택
                    // 삭제 할 수 없습니다. 선택한 파일 중 사용중인 파일이 있는지 확인해주세요.
                    toast('drive.toast.tempDelete.containUseFile');
                } else {
                    // 폴더/파일 멀티 선택
                    // 삭제 할 수 없습니다. 사용 중인 파일이 있는지 확인해주세요.
                    toast('drive.toast.tempDelete.containUseFIles');
                }
            }
        } else if (resultCode === 20613) {
            // Workgroup루트폴더 삭제시 하위에 파일 있는지 확인
            alert('drive.alert.not.empty.in.workgroup.root.folder');
        } else {
            toast('drive.toast.tempDelete.fail');
        }
    }

    function deleteObjts() {
        return RecycleBinRest.deleteTemporaryObjtList(
            files.map(({ drive }) => {
                return { objtId: drive.objtId, onpstId: drive.onpstId, onpstFolderId: drive.onpstFolderId };
            })
        );
    }

    confirm(<FormattedMessage id="drive.confirm.tempDelete" />).then(result => result && deleteObjts().then(complete, fail));
};

class Delete extends BaseMenu {
    getName() {
        return 'delete';
    }

    getLabel() {
        return 'drive.button.list.menu.delete';
    }

    isVisible() {
        return super.isVisible(true, 'delete');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-delete', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-delete', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Delete;
