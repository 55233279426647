import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { summaryStorage } from '../../Common/Util/SummaryStorageUtil';

const ThreadClearConfirmDialog = ({ intl }) => {
    const isOpen = useSelector(({ summary }) => summary.threadClearConfirmDialog.isOpen);
    const dispatch = useDispatch();

    const handleClickCloseButton = () => {
        dispatch(summaryActions.threadClearConfirmDialog.close());
    };

    const handleClickOkButton = () => {
        dispatch(summaryActions.threadClearConfirmDialog.close());
        dispatch(summaryActions.thread.clear());
        summaryStorage.thread.remove();
    };

    return (
        <div className={classnames('modal', 'fade', isOpen && 'show')} role="dialog" aria-hidden="true" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">전체 삭제</h5>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close" />
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="box-text">
                            <p>
                                전체 내용이 모두 삭제됩니다.
                                <br />
                                삭제하시겠습니까?
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-btns">
                            <a className="btn btn-md btn-secondary" role="button" onClick={handleClickCloseButton}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.cancel" />
                                </span>
                            </a>
                            <a className="btn btn-md btn-warning" role="button" onClick={handleClickOkButton}>
                                <span className="btn-text">삭제</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ThreadClearConfirmDialog.propTypes = {};

ThreadClearConfirmDialog.defaultProps = {};

export default injectIntl(ThreadClearConfirmDialog);
