import AUTH from 'constants/Auth';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AuthRest from 'apis/AuthRest';
import { TOKEN_STATUS } from 'src/redux/actions/Auth';
import AuthService from 'utils/AuthService';

const NormalTokenProcess = ({ children, user, setUser, setTokenStatus }) => {
    const needGetUserInfo = _.isEmpty(user);
    useEffect(() => {
        if (needGetUserInfo) {
            AuthRest.getLoginInfo().then(response => {
                const { resultCode } = response;
                switch (resultCode) {
                    case AUTH.RESULT_CODE.SUCCESS:
                        AuthService.removePathnameAndSearch();
                        setUser(response.data.user);
                        AuthService.setUserId(response.data.user.userId);
                        break;
                    case AUTH.RESULT_CODE.CHANGE_PASSWORD:
                        setTokenStatus(TOKEN_STATUS.CHANGE_PASSWORD);
                        break;
                    case AUTH.RESULT_CODE.AUTH_TOKEN_EXPIRED:
                        setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: false, isLogout: false } });
                        break;
                    default:
                        console.error(JSON.stringify(response));
                        setTokenStatus(TOKEN_STATUS.AB_NORMAL);
                }
            });
        }
    }, [_.get(user, 'userId')]);

    return (
        <Choose>
            <When condition={needGetUserInfo}>
                <></>
            </When>
            <Otherwise>{children}</Otherwise>
        </Choose>
    );
};

NormalTokenProcess.propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    setTokenStatus: PropTypes.func.isRequired
};

export default NormalTokenProcess;
