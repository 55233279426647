const RESULT_CODE = {
    SUCCESS: 200,
    AUTH_INVALID_TOKEN: 40001,
    AUTH_TOKEN_EXPIRED: 40002,
    AUTH_NOT_MATCH_SIGNATURE: 40003,
    AUTH_NOT_MATCH_USERAGENT: 40004,
    AUTH_NOT_MATCH_ACCESS_IP: 40005,
    AUTH_NO_AUTHORITY: 40006
};

const RESULT_STATUS = {
    NORMAL: 'Normal',
    AB_NORMAL: 'Abnormal'
};

const PROCESS_CODE = {
    CHANGE_PASSWORD: 10440,
    ACCEPT_TERMS: 10441,
    ACCEPT_NEW_TEMRS: 10445
};

const TRIGGER_OF_EXPIRATION = {
    LOGOUT: 'LOGOUT',
    NORMAL: 'NORMAL'
};

const TERMS_TYPE = {
    ITEMS: '01',
    CONSIGNMENT: '02',
    POLICY: '03'
};

export default {
    RESULT_CODE,
    RESULT_STATUS,
    PROCESS_CODE,
    TRIGGER_OF_EXPIRATION,
    TERMS_TYPE
};
