import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { If } from 'jsx-control-statements';
import moment from 'moment';
import DateConverter from '../../../../utils/DateConverter';
import Tooltips from '../../Tooltips';
import Request from '../../../../constants/Request';
import RequestPopup from '../../../containers/Popup/RequestPopup';

const DueDate = props => {
    const { date = moment().valueOf(), expirationRequestStateCode = {}, loginUser, objtConfig, isShared } = props;
    const { config = {} } = loginUser;
    const { useCstdPolicy, userCstdMap = {} } = config;
    const { cstdChangeRequestPossibleYn = 'N' } = userCstdMap;

    const openChangeExpirationDate = () => {
        const { reload, objt } = props;
        const expiredDate = DateConverter.convertDateFormat(date, 'YYYYMMDD');
        const target = [{ drive: objt, config: { drive: { expiredDate } } }];

        RequestPopup.open({
            mode: Request.MODE.REQUEST,
            type: Request.TYPE.EXTENDS_EXPIRATION,
            target
        }).then(
            result => {
                reload(result);
            },
            () => {}
        );
    };

    const getCodeName = code => {
        const { intl } = props;
        switch (code) {
            case 'PROCESSING':
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.changeProcessing' });
            case 'REJECTED':
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.rejected' });
            default:
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.none' });
        }
    };

    return (
        <>
            <If condition={useCstdPolicy}>
                <dt>
                    <FormattedMessage id="drive.text.expirationDate" />
                    <span className="btn-ic-nor btn-sm" data-toggle="tooltip" data-placement="top" data-trigger="manual" data-html="true" title="">
                        <i className="ic-16-help" data-toggle="tooltip" id="expirationDateTooltip" />
                    </span>
                    <Tooltips targetId="expirationDateTooltip" placement="bottom">
                        <div className="guide" key="expirationDate_tooltip">
                            <FormattedMessage id="drive.tooltip.expirationDateInfo" />
                        </div>
                    </Tooltips>
                </dt>
                <dd>
                    <div className="g-inline-flex">
                        <div className="previous">{DateConverter.convertExpireDate(date)}</div>
                        <div className="next divider">
                            {expirationRequestStateCode === 'PROCESSING' && <em className="state-ing">{getCodeName(expirationRequestStateCode)}</em>}
                            {expirationRequestStateCode === '' && <em className="state-keep">{getCodeName(expirationRequestStateCode)}</em>}
                            {expirationRequestStateCode === 'REJECTED' && <em className="state-reject">{getCodeName(expirationRequestStateCode)}</em>}
                        </div>
                    </div>
                    {!isShared && cstdChangeRequestPossibleYn === 'Y' && expirationRequestStateCode !== 'PROCESSING' && objtConfig.partnerExtendExpirationImpossibleYn !== 'Y' && (
                        <a className="btn btn-sm btn-secondary" role="button" onClick={openChangeExpirationDate}>
                            <span className="btn-text">
                                <FormattedMessage id="drive.button.changeExpirationDate" />
                            </span>
                        </a>
                    )}
                </dd>
            </If>
        </>
    );
};

DueDate.propTypes = {
    intl: PropTypes.object.isRequired,
    objt: PropTypes.object.isRequired,
    objtConfig: PropTypes.object.isRequired,
    date: PropTypes.number.isRequired,
    reload: PropTypes.func.isRequired,
    expirationRequestStateCode: PropTypes.string,
    loginUser: PropTypes.object.isRequired,
    isShared: PropTypes.bool
};

DueDate.defaultProps = {
    expirationRequestStateCode: '',
    isShared: false
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(DueDate)
);
