import React from 'react';
import { FormattedMessage } from 'react-intl';

class BaseMenu {
    constructor(props, files, complianceYn) {
        this.props = props;
        this.files = files;
        this.props.name = this.getName();
        this.complianceYn = complianceYn;
    }

    isDivider() {
        return false;
    }

    getName() {
        return 'base';
    }

    getLabel() {
        return 'base';
    }

    isVisible(isMulti, actionName, objtSectCd) {
        if (!isMulti && this.files.length !== 1) return false;
        return this.files.every(file => {
            let result = true;
            if (actionName) {
                result = file.actionPolicy && file.actionPolicy[actionName];
            }
            if (result && objtSectCd) {
                result = file.drive && file.drive.objtSectCd === objtSectCd;
            }
            return result;
        });
    }

    isEnable() {
        return false;
    }

    getDom() {
        return null;
    }

    getIconDom() {
        return null;
    }

    getBaseMenuDom(key, className, executor) {
        let parentClassName = 'btn-ic-nor';
        if (!this.isEnable()) {
            parentClassName += ' disabled';
        }
        return (
            <FormattedMessage key={key} id={this.getLabel()}>
                {label => (
                    <a className={parentClassName} role="button" onClick={e => executor(this.props, this.files, e)}>
                        <i className={className} />
                        <span className="btn-text">{label}</span>
                    </a>
                )}
            </FormattedMessage>
        );
    }

    getDefaultIconRender = (className, label, innerClassName, executor) => {
        return (
            <a className={className} role="button" title={label} onClick={e => executor(this.props, this.files, e)}>
                <i className={innerClassName} />
            </a>
        );
    };

    getBaseIconDom(key, className, innerClassName, executor, active) {
        const { iconType } = this.props;
        let parentClassName = 'btn-ic-nor';
        if (!this.isEnable()) {
            parentClassName += ' disabled';
        }
        if (active) {
            parentClassName += ' active';
        }

        switch (iconType) {
            case 'header':
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => (
                            <li className="nav-item">
                                <a className={parentClassName} role="button" title={label} onClick={e => executor(this.props, this.files, e)}>
                                    <i className={innerClassName} />
                                    <span className="btn-text">{label}</span>
                                </a>
                            </li>
                        )}
                    </FormattedMessage>
                );
            case 'home':
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => (
                            <li className="nav-item">
                                <a className={parentClassName} role="button" title={label} onClick={e => executor(this.props, this.files, e)}>
                                    <i className={innerClassName} />
                                    <span className="btn-text">{label}</span>
                                </a>
                            </li>
                        )}
                    </FormattedMessage>
                );
            case 'row-floating':
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => this.getDefaultIconRender(className, label, innerClassName, executor)}
                    </FormattedMessage>
                );
            case 'homeShareFavorite':
                return (
                    <div className="item-share">
                        <a className="btn-ic-nor btn-func-share" role="button" onClick={e => executor(this.props, this.files, e)}>
                            <i className="ic-20-share" />
                            <span className="btn-text">
                                <FormattedMessage id="user.noti.share" />
                            </span>
                        </a>
                    </div>
                );
            default:
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => (
                            <li className={className}>
                                <a className={parentClassName} role="button" title={label} onClick={e => executor(this.props, this.files, e)}>
                                    <i className={innerClassName} />
                                </a>
                            </li>
                        )}
                    </FormattedMessage>
                );
        }
    }

    getBaseUploadIconDom(key, className, innerClassName, executor, active) {
        const { iconType } = this.props;
        let parentClassName = 'btn-ic-nor';
        if (!this.isEnable()) {
            parentClassName += ' disabled';
        }
        if (active) {
            parentClassName += ' active';
        }

        switch (iconType) {
            case 'header':
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => (
                            <li key={this.getName()} className="nav-item">
                                <span className="file-upload">
                                    <input type="file" title="" className="upload" multiple={true} onChange={e => executor(this.props, this.files, e)} />
                                    <a className={parentClassName} role="button">
                                        <i className={innerClassName} />
                                        <span className="btn-text">{label}</span>
                                    </a>
                                </span>
                            </li>
                        )}
                    </FormattedMessage>
                );
            case 'homeFavorite':
                return (
                    <li className="option-upload">
                        <span className="file-upload">
                            <input type="file" title="" className="upload" multiple={true} onChange={e => executor(this.props, this.files, e)} />
                            <a className="btn-ic-nor" role="button">
                                <i className="ic-20-upload" />
                                <span className="btn-text">
                                    {' '}
                                    <FormattedMessage key={key} id={this.getLabel()} />{' '}
                                </span>
                            </a>
                        </span>
                    </li>
                );

            case 'row-floating':
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => this.getDefaultIconRender(className, label, innerClassName, executor)}
                    </FormattedMessage>
                );
            default:
                return (
                    <FormattedMessage key={key} id={this.getLabel()}>
                        {label => (
                            <li className={className}>
                                <span className="file-upload">
                                    <input type="file" title={label} className="upload" multiple={true} onChange={e => executor(this.props, this.files, e)} />
                                    <a className={parentClassName} role="button" title={label}>
                                        <i className={innerClassName} />
                                    </a>
                                </span>
                            </li>
                        )}
                    </FormattedMessage>
                );
        }
    }
}

export default BaseMenu;
