import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import { OPTIONS_DIALOG_POSITION_TYPE, SUMMARY_OPTIONS, SUMMARY_SCOPE_TYPE } from './SummaryOptionConstant';
import { summaryActions } from '../../../../redux/actions/Summary';
import LLMDropdown from './Option/LLM/LLMDropdown';
import LengthOptionGroup from './Option/Length/LengthOptionGroup';
import FormOptionGroup from './Option/Form/FormOptionGroup';
import ScopeOptionGroup from './Option/Scope/ScopeOptionGroup';
import { IN_PROGRESS_RESULT_STATUS_LIST, SUMMARY_GENERATION_MODE, SUMMARY_MESSAGE_TYPE } from '../Common/SummaryConstant';
import { isInvalidScopeTypeKeyword } from '../Common/Util/SummaryUtil';

const OptionSelectDialog = memo(({ optionSelectDialogRef, intl }) => {
    const dialogRef = useRef();

    const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] = useState(false);
    const [isReadyToShow, setIsReadyToShow] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [positionStyle, setPositionStyle] = useState({ top: '0px', left: '0px' });

    const { exceptOptionList = [], isOpen, positionType, generationMode, optionSelectDialogOpenButtonRef, files, summaryOptions } = useSelector(
        ({ summary }) => summary.optionSelectDialog,
        shallowEqual
    );
    const { llmList, selectedLLM } = useSelector(({ summary }) => summary.global, shallowEqual);
    const summaryMessages = useSelector(({ summary }) => summary.thread.summaryMessages);

    const dispatch = useDispatch();

    const previousScopeType = useMemo(() => {
        if (_.isEmpty(summaryMessages)) {
            return null;
        }

        // TODO util
        const { summaryResults } = _.findLast(summaryMessages, ({ type }) => type === SUMMARY_MESSAGE_TYPE.ai) || {};

        if (_.isEmpty(summaryResults)) {
            return null;
        }

        const { summaryOptions: { scopeType } = {} } = _.last(summaryResults);

        return scopeType;
    }, [summaryMessages]);

    const getAroundMenuPositionStyle = () => {
        if (!optionSelectDialogOpenButtonRef) {
            return null;
        }

        const { top: menuTop, bottom: menuBottom, left: menuLeft } = optionSelectDialogOpenButtonRef.current.getBoundingClientRect();
        const { width: dialogWidth, height: dialogHeight } = dialogRef.current.getBoundingClientRect();
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

        const top = menuBottom + dialogHeight < windowHeight ? menuBottom : menuTop - dialogHeight;
        const left = menuLeft + dialogWidth < windowWidth ? menuLeft : windowWidth - dialogWidth;

        // eslint-disable-next-line consistent-return
        return {
            top,
            left
        };
    };

    const getCenterPositionStyle = () => {
        const { width: dialogWidth, height: dialogHeight } = dialogRef.current.getBoundingClientRect();
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

        const top = windowHeight / 2 - dialogHeight / 2;
        const left = windowWidth / 2 - dialogWidth / 2;

        return {
            top,
            left
        };
    };

    const handleClickCancelButton = () => {
        dispatch(summaryActions.optionSelectDialog.close());
    };

    const handleClickGenerateButton = () => {
        const { scopeType, scopeTypeKeyword } = summaryOptions;

        if (scopeType === SUMMARY_SCOPE_TYPE.keyword && isInvalidScopeTypeKeyword(scopeTypeKeyword)) {
            dispatch(summaryActions.wrongKeywordDialog.open());
            return;
        }

        // 요약이 진행 중인 상태에서 옵션 선택 팝업을 통해 새로운 요약을 요청하는 경우, 새로운 요약 진행 여부 팝업을 표시
        if (!_.isEmpty(summaryMessages)) {
            const { summaryResults } = _.findLast(summaryMessages, ({ type }) => type === SUMMARY_MESSAGE_TYPE.ai);
            const { resultStatus: currentResultStatus } = _.last(summaryResults);

            if (IN_PROGRESS_RESULT_STATUS_LIST.includes(currentResultStatus)) {
                dispatch(summaryActions.stopForNewDialog.open({ files, summaryOptions }));
                dispatch(summaryActions.optionSelectDialog.close());
                dispatch(summaryActions.notificationDialog.close());
                dispatch(summaryActions.sidePanel.open());
                return;
            }
        }

        const summaryOptionsWithGlobalLLM = {
            ...summaryOptions,
            ...selectedLLM
        };

        switch (generationMode) {
            case SUMMARY_GENERATION_MODE.generation:
                dispatch(summaryActions.thread.generateUserMessage({ summaryMessage: { summaryOptions: summaryOptionsWithGlobalLLM, files } }));
                break;
            case SUMMARY_GENERATION_MODE.regeneration:
                dispatch(summaryActions.thread.regenerateAIMessage({ changedSummaryOptions: selectedLLM }));
                break;
            case SUMMARY_GENERATION_MODE.conditional:
                if (scopeType === previousScopeType) {
                    dispatch(summaryActions.thread.regenerateAIMessage({ changedSummaryOptions: selectedLLM }));
                } else {
                    dispatch(summaryActions.thread.generateUserMessage({ summaryMessage: { summaryOptions: summaryOptionsWithGlobalLLM, files } }));
                }
                break;
            default:
                break;
        }

        dispatch(summaryActions.optionSelectDialog.close());
        dispatch(summaryActions.notificationDialog.close());
        dispatch(summaryActions.fileListBox.close());
        dispatch(summaryActions.promptInput.clear());
        dispatch(summaryActions.sidePanel.open());
    };

    const handleClickCloseButton = () => {
        dispatch(summaryActions.optionSelectDialog.close());
    };

    useEffect(() => {
        const { scopeType, scopeTypeKeyword } = summaryOptions;

        if (scopeType !== SUMMARY_SCOPE_TYPE.keyword) {
            setIsGenerateButtonDisabled(false);
            return;
        }

        setIsGenerateButtonDisabled(_.isNil(scopeTypeKeyword) || _.isEmpty(_.trim(scopeTypeKeyword)));
    }, [summaryOptions]);

    useEffect(() => {
        if (!isOpen) {
            setIsReadyToShow(false);
            return;
        }

        setIsReadyToShow(true);
    }, [isOpen]);

    useEffect(() => {
        if (!isReadyToShow) {
            setIsShow(false);
            return;
        }

        // TODO refactor
        const positionInfo = positionType === OPTIONS_DIALOG_POSITION_TYPE.center ? getCenterPositionStyle() : getAroundMenuPositionStyle();

        if (_.isNil(positionInfo)) {
            return;
        }

        const { top, left } = positionInfo;

        setPositionStyle({ top: `${top}px`, left: `${left}px` });
        setIsShow(true);
    }, [isReadyToShow]);

    return (
        <>
            <div
                className={classnames('overlay-layer-wrapper', isReadyToShow && 'show')}
                role="dialog"
                style={{ ...positionStyle, zIndex: 1050, display: isOpen ? '' : 'none', visibility: !isShow ? 'hidden' : '' }}
                ref={optionSelectDialogRef}>
                <div className="overlay-layer">
                    <div className="layer-content layer-sm" style={{ width: '300px' }} ref={dialogRef}>
                        <div className="layer-header layer-header-lg">
                            <h1 className="layer-title">
                                <FormattedMessage id="drive.text.copilot.summary.optionSelectDialog.title" />
                            </h1>
                            <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                                <i className="ic-20-close" />
                            </a>
                        </div>
                        <div className="layer-body">
                            <div className="dt-width-60">
                                <If condition={!exceptOptionList.includes(SUMMARY_OPTIONS.llm) && llmList.length >= 2}>
                                    <LLMDropdown />
                                </If>
                                <If condition={!exceptOptionList.includes(SUMMARY_OPTIONS.length)}>
                                    <LengthOptionGroup />
                                </If>
                                <If condition={!exceptOptionList.includes(SUMMARY_OPTIONS.form)}>
                                    <FormOptionGroup />
                                </If>
                                <If condition={!exceptOptionList.includes(SUMMARY_OPTIONS.scope)}>
                                    <ScopeOptionGroup />
                                </If>
                            </div>
                        </div>
                        <div className="layer-footer">
                            <div className="layer-footer-btns">
                                <a className="btn btn-md btn-secondary" role="button" onClick={handleClickCancelButton}>
                                    <span className="btn-text">
                                        <FormattedMessage id="drive.button.copilot.summary.cancel" />
                                    </span>
                                </a>
                                <a className={classnames('btn', 'btn-md', 'btn-gradation', isGenerateButtonDisabled && 'disabled')} role="button" onClick={handleClickGenerateButton}>
                                    <span className="btn-text">
                                        <FormattedMessage id="drive.button.copilot.summary.generation" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isReadyToShow && positionType === OPTIONS_DIALOG_POSITION_TYPE.center && <div className="modal-backdrop fade show" style={{ zIndex: 1049 }} />}
        </>
    );
});

OptionSelectDialog.propTypes = {
    optionSelectDialogRef: PropTypes.object.isRequired
};

export default injectIntl(OptionSelectDialog);
