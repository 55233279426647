import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { alert, toast } from '../../../../utils/ModalService';
import FileLifeCycleRest from '../../../../apis/FileLifeCycleRest';
import RequestHeader from './RequestHeader';
import RequestBodyFiles from './RequestBodyFiles';
import RequestBodyApprover from './RequestBodyApprover';
import RequestBodyReqRsn from './RequestBodyReqRsn';
import RequestFooter from './RequestFooter';

class RequestComplianceExceptionPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileIds: [],
            target: '',
            reason: '',
            reasonKey: '',
            reasonPresets: [],
            reasonMinLength: 5,
            searchTgt: 'FLCM',
            // searchTgt: 'KNOX_DRIVE',
            isInvalid: false,
            isValidForm: false
        };
        this.maxLength = 512;
    }

    componentDidMount() {
        this.initRequestData();
        this.getReasonPresets();
    }

    initRequestData = () => {
        this.setState({ isValidForm: false });
    };

    setFileData = fileIds => {
        this.setState({ fileIds });
    };

    onChangeTarget = value => {
        this.setState({ target: value }, this.checkValidation);
    };

    onChangeReason(reason = '') {
        const { reasonMinLength } = this.state;
        this.setState({ reason }, () => {
            if (reason.trim().length < reasonMinLength) {
                this.setState({ isInvalid: true }, this.checkValidation);
            } else {
                this.setState({ isInvalid: false }, this.checkValidation);
            }
        });
    }

    getReasonPresets() {
        const { intl } = this.props;
        FileLifeCycleRest.getReasonPresets('COMPLIANCE').then(response => {
            const { message, data, resultCode } = response;
            if (resultCode !== 200 || _.isEmpty(data)) {
                alert(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
            }

            const presets = _.map(data, preset => {
                let editable = false;
                let reason = preset.message;
                let minLength = 1;
                const presetMessage = preset.message;
                if (preset.key === 'ETC') {
                    editable = true;
                    reason = '';
                    minLength = 5;
                }
                return _.extend({}, preset, { editable, reason, message: presetMessage, minLength });
            });
            this.setState({ reasonPresets: presets });
        });
    }

    onChangeReasonKey(reasonKey) {
        const { reasonPresets = [], reason = '' } = this.state;
        const picked = _.find(reasonPresets, preset => {
            return preset.value === reasonKey;
        });
        const pickedMinLength = picked ? picked.minLength : 5;
        const pickedReason = picked ? picked.reason : reason;

        this.setState({ reasonKey, reason: pickedReason, reasonMinLength: pickedMinLength }, this.checkValidation);
    }

    checkValidation = () => {
        const { target, reason = '', reasonMinLength } = this.state;
        if (target === '' || reason.trim().length < reasonMinLength) {
            this.setState({ isValidForm: false });
        } else {
            this.setState({ isValidForm: true });
        }
    };

    closePopup = isResolved => {
        const { close } = this.props;
        close(isResolved);
    };

    save = () => {
        const { intl } = this.props;
        const { fileIds, target, reason = '', searchTgt, reasonKey } = this.state;
        FileLifeCycleRest.saveExceptProhitWord(fileIds, target, reason.trim(), searchTgt, reasonKey).then(
            response => {
                const { resultCode, message } = response;
                if (resultCode !== 200) {
                    if (message === 'IOFFICE_FORBIDDEN') {
                        alert('drive.alert.exception.extension.impossible');
                    } else {
                        alert('drive.alert.exception.compliance');
                    }
                    return;
                }
                if (target === null || target === '') {
                    this.closePopup(true);
                    toast('drive.toast.exception.compliance.success');
                } else {
                    this.closePopup(true);
                    toast('drive.toast.exception.compliance.request.success');
                }
            },
            () => {
                alert(intl.formatMessage({ id: 'drive.alert.exception.compliance' }));
            }
        );
    };

    render() {
        const { headerName, files, close } = this.props;
        const { isInvalid, isValidForm, searchTgt, reasonPresets } = this.state;
        return (
            <>
                <RequestHeader headerName={headerName} close={close} />
                <div className="modal-body">
                    <div className="dt-width-120">
                        <RequestBodyFiles files={files} setFileIds={fileIds => this.setFileData(fileIds)} />
                        <RequestBodyApprover changeTarget={this.onChangeTarget} searchTgt={searchTgt} />
                        <RequestBodyReqRsn isInvalid={isInvalid} presets={reasonPresets} changeReason={value => this.onChangeReason(value)} changeReasonKey={value => this.onChangeReasonKey(value)} />
                    </div>
                </div>

                <RequestFooter isValidForm={isValidForm} close={close} save={() => this.save()} />
            </>
        );
    }
}

RequestComplianceExceptionPopup.propTypes = {
    headerName: PropTypes.string.isRequired,
    close: PropTypes.func,
    files: PropTypes.array.isRequired,
    intl: PropTypes.object
};

RequestComplianceExceptionPopup.defaultProps = {
    close: () => {},
    intl: {}
};

export default injectIntl(RequestComplianceExceptionPopup);
