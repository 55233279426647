import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import { FormattedMessage } from 'react-intl';
import CharacterImageContainer from 'containers/CharacterImage/CharacterImageContainer';

const SelectedUserRow = props => {
    const { user, style, index, checkBox, selectUser, checkSelectedUser, delBox, onDeleteBoxClick, cancelToken } = props;
    const checkSelectedRow = event => {
        const { checked } = event.target;
        checkSelectedUser(index, checked, true);
        selectUser(user);
    };

    const delBoxOnClickEvent = (userId, event) => {
        onDeleteBoxClick(userId, event, true);
    };

    return (
        <div key={user.userId}>
            <If condition={!_.isNil(user)}>
                <div className={_.get(user, 'isSelected') ? 'grid-row is-checked' : 'grid-row'} role="button" style={style} onClick={selectUser}>
                    {checkBox ? (
                        <div className="grid-cell check-cell">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" onClick={event => checkSelectedRow(event)} checked={user.checked} />
                                    <span className="label-text" />
                                </label>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <div className="grid-cell selected-cell">
                        <div className="item-user">
                            <CharacterImageContainer
                                objtId={user.userPhotoFilePathVal || ''}
                                userId={user.userId}
                                text={user.userLangCdNm}
                                size="sm"
                                userType={user.userSectCd}
                                updateTime={0}
                                cancelToken={cancelToken}
                            />
                            <div className="user-profile">
                                <div title={user.userLangCdNm}>
                                    <span className="name">{user.userLangCdNm}</span>
                                    <span className="position">
                                        {user.userLangCdCopNm} / {user.deptLangCdNm}
                                    </span>
                                    <span className="email">{user.userLoginId}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {delBox ? (
                        <FormattedMessage id="com.delete">
                            {title => (
                                <div className="grid-cell del-cell">
                                    <a className="btn-ic-nor" role="button" title={title} onClick={event => delBoxOnClickEvent(user.userId, event)}>
                                        <i className="ic-20-close-sm" />
                                    </a>
                                </div>
                            )}
                        </FormattedMessage>
                    ) : (
                        <div />
                    )}
                </div>
            </If>
            <If condition={_.isNil(user)}>
                <div />
            </If>
        </div>
    );
};

export default SelectedUserRow;

SelectedUserRow.propTypes = {
    selectUser: PropTypes.func,
    checkSelectedUser: PropTypes.func,
    user: PropTypes.object,
    style: PropTypes.object,
    checkBox: PropTypes.bool,
    index: PropTypes.number,
    onDeleteBoxClick: PropTypes.func,
    delBox: PropTypes.bool,
    cancelToken: PropTypes.object
};

SelectedUserRow.defaultProps = {
    selectUser: _.noop,
    checkSelectedUser: _.noop,
    user: {},
    style: {},
    checkBox: false,
    index: 0,
    onDeleteBoxClick: _.noop,
    delBox: true,
    cancelToken: {}
};
