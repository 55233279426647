/* eslint-disable */
import { $http } from 'modules/index';
import FileCache from '../utils/FileCache';
import { toast } from '../utils/ModalService';

const baseResponseHandler = (p, failMsgId = 'drive.toast.list.api.folders.fail') => {
    return p.then(
        res => {
            if (res && res.resultCode === 200) {
                return res;
            }
            if (res && res.resultCode === 40002) {
                toast(res.message);
                return Promise.reject(res);
            }
            toast(failMsgId);
            return Promise.reject(res);
        },
        res => {
            toast(failMsgId);
            return Promise.reject(res);
        }
    );
};

class FoldersRest {
    static getFolders(page = 'own', params = {}) {
        const config = { params: { category: page, ...params } };
        return baseResponseHandler($http.get(`/drive/v1/folders`, config)).then(response => response.data);
    }

    static getFolder(folderId, params = {}) {
        const config = { params };
        return baseResponseHandler($http.get(`/drive/v1/folders/${folderId}`, config)).then(response => response.data);
    }

    static getPersonalFolders(type = 'used', params = {}) {
        const config = { params };
        return baseResponseHandler($http.get(`/drive/v1/folders/personal/${type}`, config)).then(response => {
            if (type === 'prefer' && response.data && response.data.list) {
                const originLength = response.data.list.length;
                response.data.list = new FileCache().convertPreferred(response.data.list);

                // 전체 개수 보정
                const convertedLength = response.data.list.length;
                if (originLength !== convertedLength) {
                    if (response.data.total >= 0) {
                        response.data.total = response.data.total - (originLength - convertedLength);
                    } else {
                        response.data.total = convertedLength;
                    }
                }
            }
            return response.data;
        });
    }

    static getDeleted(folderId, params = {}) {
        const config = { params };
        return baseResponseHandler($http.get(`/drive/v1/recyclebin/${folderId}`, config)).then(response => response.data);
    }

    static getColumns(page = 'own') {
        return baseResponseHandler($http.get(`/drive/v1/columns/${page}`), 'drive.toast.list.api.columns.fail').then(response => response.data);
    }

    static getPair(files, withChildrenCount = true, withConfig = false, reqType = '') {
        const config = { params: { action: 'get', withChildrenCount, withConfig, reqType } };
        const data = files.map(f => {
            if (f.drive) {
                return { objtId: f.drive.objtId, onpstId: f.drive.onpstId };
            }
            return { objtId: f.objtId, onpstId: f.onpstId };
        });
        return $http.post('/drive/v1/folders', data, config).then(response => {
            if (response.data) {
                return response.data.list;
            }
            if (response.message === 'IOFFICE_FORBIDDEN' && response.resultCode === 403) {
                if (data.length > 1) return [];
                return files.map(f => {
                    return Object.assign({}, f, { maxAuthCd: 'NONE' });
                });
            }
            return Promise.reject(response);
        });
    }

    static addPreferFolders(folders, addAtHome) {
        const addPreferred = folders.map(file => {
            file.personalization = file.personalization || {};
            file.personalization.preferred = new Date().getTime();
            return file;
        });

        let data = {};
        if (addAtHome) {
            data = addPreferred.map(added => ({ objtId: added.drive.objtId, value: added.personalization.preferred }));
        } else {
            data = addPreferred.map(({ drive, personalization }) => ({ objtId: drive.objtId, value: personalization.preferred }));
        }

        return $http.put('/drive/v1/folders/personal/prefer/bulk', data).then(response => {
            if (response && response.data === true) {
                new FileCache().updatedPreferred(addPreferred, true);
                response.addPreferred = addPreferred;
                return response;
            } else if (response.resultCode === 403) {
                return response;
            } else {
                return Promise.reject(response);
            }
        });
    }

    static removePreferFolders(folders) {
        const removePreferred = folders.map(file => {
            file.personalization = file.personalization || {};
            file.personalization.preferred = null;
            return file;
        });
        const data = removePreferred.map(({ drive }) => ({ objtId: drive.objtId, value: null }));
        return $http.put('/drive/v1/folders/personal/prefer/bulk', data).then(response => {
            if (response && response.data === true) {
                new FileCache().updatedPreferred(removePreferred);
                return response;
            }
            return Promise.reject(response);
        });
    }
}

export default FoldersRest;
