import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import AuthGuidePopup from '../../../containers/Popup/AuthGuidePopup';
import ModalService from '../../../../utils/ModalService';
import { getAuthMap } from '../../../../utils/AuthCd';

const DEFAULT_AUTH_MAP = {
    U20VIEW: 'com.viewable',
    U40COPY: 'com.copyable',
    U60EDIT: 'com.editable',
    U70SHARE: 'com.text.shareable',
    U80OWN: 'com.owner'
};

const Auth = props => {
    const { authCd, intl } = props;
    const authMap = getAuthMap();
    const authNm = authMap[authCd] || intl.formatMessage({ id: DEFAULT_AUTH_MAP[authCd] });
    const openAuthGuidePopup = () => {
        ModalService()
            .largePopup(<AuthGuidePopup />)
            .then(() => true, () => false);
    };

    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.myAuth" />
                <a className="btn-ic-nor btn-sm" role="button" onClick={openAuthGuidePopup}>
                    <i className="ic-16-info" />
                </a>
            </dt>
            <dd>
                <div className="g-inline-flex">
                    <div className="ellipsis">{authNm}</div>
                </div>
            </dd>
        </>
    );
};

Auth.propTypes = { authCd: string };

Auth.defaultProps = { authCd: '' };

export default injectIntl(Auth);
