import { spawn } from 'redux-saga/effects';
import toast from './Toast';
import upload from './Upload';
import auth from './Auth';
import globalPopup from './GlobalPopup';
import partnerAccount from './PartnerAccount';

export default function* rootSaga() {
    yield spawn(toast);
    yield spawn(upload);
    yield spawn(auth);
    yield spawn(globalPopup);
    yield spawn(partnerAccount);
}
