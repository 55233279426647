import More from './More';
import MenuSupporter from '../../../../utils/FileMenu/MenuSupporter';

const executor = (props, files, e) => {
    const { custom = {} } = props;
    const { openType, moreGroupType = 'delete' } = custom;
    const callback = (inProps, inFiles, inData) => {
        props.callback(inData);
    };
    MenuSupporter.open(moreGroupType, files, callback, openType, e, custom.statusCallback, custom);
};

class DeletedMore extends More {
    getIconDom() {
        const { custom = {} } = this.props;
        const { status } = custom;
        return this.getBaseIconDom(this.getName(), 'option-more-sm', 'ic-20-more', executor, status);
    }
}

export default DeletedMore;
