import React from 'react';
import PropTypes from 'prop-types';

const FileVerSno = props => {
    const {
        file: { drive = {}, version = {} },
        cellStyle
    } = props;
    const value = drive.objtSectCd === 'FILE' ? version.fileVerSno : '-';
    return (
        <div className="grid-cell files-version-cell" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

FileVerSno.defaultProps = {
    cellStyle: null
};

FileVerSno.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default FileVerSno;
