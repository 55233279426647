import * as Menu from '../../components/presentationals/FileMenu/Menu';

const groups = {
    normal: (props, files) => [
        new Menu.SingleFileSummary(props, files),
        new Menu.MultipleFilesSummary(props, files),
        new Menu.SummaryFileSelect(props, files),
        new Menu.Divider(props, files),
        new Menu.Open(props, files),
        new Menu.WebOffice(props, files),
        new Menu.Share(props, files),
        new Menu.LinkCopy(props, files),
        new Menu.Download(props, files),
        new Menu.SetSecureMode(props, files),
        new Menu.Divider(props, files),
        new Menu.Copy(props, files),
        new Menu.Move(props, files),
        new Menu.Information(props, files),
        new Menu.Divider(props, files),
        new Menu.NameChange(props, files),
        new Menu.VersionManager(props, files),
        new Menu.AddPreferredFolder(props, files),
        new Menu.RemovePreferredFolder(props, files),
        new Menu.Divider(props, files),
        new Menu.RequestChange(props, files),
        new Menu.Delete(props, files)
    ],
    recent: (props, files) => [
        new Menu.SingleFileSummary(props, files),
        new Menu.MultipleFilesSummary(props, files),
        new Menu.SummaryFileSelect(props, files),
        new Menu.Open(props, files),
        new Menu.Share(props, files),
        new Menu.LinkCopy(props, files),
        new Menu.Download(props, files),
        new Menu.SetSecureMode(props, files),
        new Menu.Divider(props, files),
        new Menu.Copy(props, files),
        new Menu.Move(props, files),
        new Menu.Divider(props, files),
        new Menu.NameChange(props, files),
        new Menu.VersionManager(props, files),
        new Menu.AddPreferredFolder(props, files),
        new Menu.RemovePreferredFolder(props, files),
        new Menu.Divider(props, files),
        new Menu.Delete(props, files)
    ],
    version: (props, files) => [new Menu.VersionDownload(props, files), new Menu.VersionDelete(props, files)],
    preferred: (props, files) => [new Menu.Upload(props, files), new Menu.Share(props, files), new Menu.RemovePreferredFolder(props, files)],
    inLink: (props, files) => [
        new Menu.Open(props, files),
        new Menu.Share(props, files),
        new Menu.LinkCopy(props, files),
        new Menu.InLinkDownload(props, files),
        new Menu.Divider(props, files),
        new Menu.Copy(props, files),
        new Menu.Divider(props, files),
        new Menu.VersionManager(props, files)
    ],
    delete: (props, files) => [new Menu.RestoreToOriginFolder(props, files), new Menu.Restore(props, files), new Menu.PermanentDelete(props, files)],
    expiration: (props, files) => [
        new Menu.Open(props, files),
        new Menu.Divider(props, files),
        new Menu.ExpirationExtension(props, files),
        new Menu.CancelRequest(props, files),
        new Menu.Information(props, files),
        new Menu.Divider(props, files),
        new Menu.Delete(props, files)
    ],
    homeFavoriteFile: (props, files) => [new Menu.Viewer(props, files), new Menu.Open(props, files), new Menu.Share(props, files), new Menu.More(props, files)],
    homeFavorite: (props, files) => [new Menu.Upload(props, files)],
    homeAddFavorite: (props, files) => [new Menu.CreateFolder(props, files)],
    homeShareFavorite: (props, files) => [new Menu.Share(props, files)],
    compliance: (props, files) => [
        new Menu.Open(props, files),
        new Menu.Divider(props, files),
        new Menu.RequestException(props, files),
        new Menu.CancelRequest(props, files),
        new Menu.Information(props, files),
        new Menu.Divider(props, files),
        new Menu.Delete(props, files)
    ],
    recvReq: (props, files) => [new Menu.ApproveRequest(props, files), new Menu.RejectRequest(props, files)],
    duplicated: (props, files) => [new Menu.Open(props, files), new Menu.Divider(props, files), new Menu.Delete(props, files)],
    similar: (props, files) => [new Menu.Open(props, files), new Menu.Divider(props, files), new Menu.Delete(props, files)]
};

const iconGroups = {
    header: (props, files) => [new Menu.Upload(props, files), new Menu.CreateFolder(props, files), new Menu.Share(props, files), new Menu.More(props, files)],
    headerMulti: (props, files) => [
        new Menu.SingleFileSummary(props, files),
        new Menu.MultipleFilesSummary(props, files),
        new Menu.SummaryFileSelect(props, files),
        new Menu.Share(props, files),
        new Menu.More(props, files)
    ],
    headerMultiNoMenu: (props, files) => [new Menu.NoAuthWorkgroup(props, files), new Menu.NoMenu(props, files)],
    ownRoot: (props, files) => [new Menu.Upload(props, files), new Menu.CreateFolder(props, files)],
    version: (props, files) => [new Menu.More(props, files)],
    normal: (props, files) => [
        new Menu.SingleFileSummary(props, files),
        new Menu.SummaryFileSelect(props, files),
        new Menu.Open(props, files),
        new Menu.Share(props, files),
        new Menu.More(props, files)
    ],
    delete: (props, files) => [new Menu.RestoreToOriginFolder(props, files), new Menu.Restore(props, files), new Menu.PermanentDelete(props, files)],
    deleteMulti: (props, files) => [new Menu.RestoreToOriginFolder(props, files), new Menu.Restore(props, files), new Menu.PermanentDelete(props, files)],
    preferred: (props, files) => [new Menu.Upload(props, files), new Menu.Share(props, files), new Menu.More(props, files)],
    inLink: (props, files) => [new Menu.InLinkDownload(props, files), new Menu.More(props, files)],
    floatingFavorite: (props, files) => [new Menu.AddPreferredFolderRow(props, files), new Menu.RemovePreferredFolderRow(props, files)],
    expiration: (props, files) => [new Menu.Open(props, files), new Menu.ExpirationExtension(props, files), new Menu.CancelRequest(props, files), new Menu.More(props, files)],
    expirationMulti: (props, files) => {
        const {
            custom: { userConfig: { userCstdMap: { cstdChangeRequestPossibleYn = 'N' } = {} } = {} }
        } = props;
        if (cstdChangeRequestPossibleYn === 'Y') {
            return [new Menu.ExpirationExtension(props, files), new Menu.CancelRequest(props, files), files.length === 1 ? new Menu.More(props, files) : new Menu.Delete(props, files)];
        }
        return [new Menu.Delete(props, files)];
    },
    homeFavorite: (props, files) => [new Menu.Upload(props, files)],
    homeFavoriteItem: (props, files) => [new Menu.More(props, files)],
    homeFavoriteFile: (props, files) => [new Menu.Viewer(props, files), new Menu.Open(props, files), new Menu.Share(props, files), new Menu.More(props, files)],
    homeAddFavorite: (props, files) => [new Menu.CreateFolder(props, files)],
    homeShareFavorite: (props, files) => [new Menu.Share(props, files)],
    compliance: (props, files) => [new Menu.Open(props, files), new Menu.RequestException(props, files), new Menu.CancelRequest(props, files), new Menu.More(props, files)],
    complianceMulti: (props, files) => [new Menu.RequestException(props, files), new Menu.CancelRequest(props, files), new Menu.More(props, files)],
    recvReq: (props, files) => [new Menu.DeletedMore(props, files)],
    duplicated: (props, files) => [new Menu.Open(props, files), new Menu.Delete(props, files)],
    duplicatedMulti: (props, files) => [new Menu.Open(props, files), new Menu.Delete(props, files)],
    similar: (props, files) => [new Menu.Open(props, files), new Menu.Delete(props, files), new Menu.More(props, files)],
    similarMulti: (props, files) => [new Menu.Open(props, files), new Menu.Delete(props, files)]
};

/**
 * ** menu **
 * groupType
 *   normal : 일반 화면 메뉴
 *   more_normal : 일반 화면 더보기 메뉴 -- TODO
 * ** icon **
 * groupType
 *   header : 우측 상단 메뉴
 *   normal : row 우측 메뉴
 */
class MenuMapper {
    constructor(props, files) {
        this.props = Object.assign({}, props);
        this.files = Object.assign([], files);

        const innerCallback = this.props.callback || (() => {});
        this.props.callback = data => {
            // console.log('재조회 함수 수행. 파라미터는 this.files!!');
            innerCallback(this.props, this.files, data);
        };
    }

    getMenusClass = () => {
        const menuCreator = groups[this.props.groupType];
        if (!menuCreator) {
            return [];
        }

        let isDivider = true;
        const menus = menuCreator(this.props, this.files)
            .filter(ins => ins.isVisible())
            .map(ins => {
                if (ins.isDivider()) {
                    if (!isDivider) {
                        isDivider = true;
                        return ins;
                    }
                } else {
                    isDivider = false;
                    return ins;
                }
                return null;
            })
            .filter(c => c != null);
        if (menus.length && menus[menus.length - 1].isDivider()) {
            return menus.slice(0, menus.length - 1);
        }
        return menus;
    };

    getMenus = () => {
        let key = 0;
        return this.getMenusClass().map(ins => {
            key += 1;
            return ins.getDom(key);
        });
    };

    getIcons = (preClass = []) => {
        const menuCreator = iconGroups[this.props.groupType];
        if (!menuCreator) {
            return [];
        }

        let key = 0;
        return [...preClass, ...menuCreator(this.props, this.files)]
            .filter(ins => ins.isVisible())
            .map(ins => {
                key += 1;
                return ins.getIconDom(key);
            })
            .filter(dom => dom != null);
    };
}

export default MenuMapper;
