import _ from 'lodash';
import { $http } from '../modules';
import { SUMMARY_SCOPE_TYPE } from '../components/containers/Summary/OptionSelectDialog/SummaryOptionConstant';

class SummaryRest {
    static async getSummarizableTenant() {
        const response = await $http.get(`/drive/v1/configs/EFL_DRV_SUMMARY_USE_YN`);
        return _.get(response, 'data') === 'Y';
    }

    static async getLLMList() {
        return $http.get('/drive/v1/ai/llm/user');
    }

    // TODO refactor
    static async requestSummary({ files, summaryOptions, user }) {
        const { llmId, llmName, llmType, lengthType, formType, scopeType, scopeTypeKeyword } = summaryOptions;
        const {
            locale: { languageCode }
        } = user;

        const objtIdList = files.map(({ objtId }) => objtId);

        const requestBody = Object.assign(
            {
                language: languageCode,
                length: lengthType,
                form: formType,
                llmId,
                llmName,
                llmType
            },
            scopeType === SUMMARY_SCOPE_TYPE.keyword ? { keyword: scopeTypeKeyword } : {}
        );

        if (objtIdList.length === 1) {
            const [objtId] = objtIdList;
            requestBody.objtId = objtId;
        } else {
            requestBody.objtIdList = objtIdList;
        }

        return $http.post('/drive/v1/summary', requestBody);
    }

    static async getSummary({ summaryId }) {
        return $http.get('/drive/v1/summary', { params: { summaryId } });
    }
}

export default SummaryRest;
