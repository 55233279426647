import React from 'react';
import { object } from 'prop-types';
import { popup } from '../../utils/ModalService';
import IncomLink from '../presentationals/FileLink/IncomLink';

const openPopup = data => {
    console.log('data: ', data);
    return popup(<IncomLink {...data} />);
};

const IncomLinkPopup = ({ match, location }) => {
    const { incomLinkUrl } = match.params;
    openPopup({ incomLinkUrl, location }).then(() => {}, () => {});
    return <></>;
};

IncomLinkPopup.propTypes = {
    match: object.isRequired,
    location: object.isRequired
};

export default IncomLinkPopup;
