import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import AuthService from 'utils/AuthService';
import AuthRest from '../../../apis/AuthRest';

class AuthRemoverBeforeunload extends React.PureComponent {
    componentDidMount() {
        $(window).on('beforeunload', () => {
            AuthRest.deleteToken();
            AuthService.removeAuthTokenAndSignKey();
            AuthService.removePathnameAndSearch();
        });
    }

    componentWillUnmount() {
        $(window).off('beforeunload');
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

AuthRemoverBeforeunload.propTypes = {
    children: PropTypes.any.isRequired
};

export default AuthRemoverBeforeunload;
