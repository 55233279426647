import localStorage from './LocalStorage';
import cookie from './Cookie';
import store from '../redux/store';

class ConfigCache {
    addTenantId = key => {
        const storeState = store.getState();
        const { auth: { user: { tenantId = '' } = {} } = {} } = storeState;
        return `${key}_${tenantId}`;
    };

    filterExpired = config => {
        Object.keys(config).forEach(k => {
            if (new Date(config[k].expireDate) < new Date()) {
                delete config[k]; // eslint-disable-line no-param-reassign
            }
        });
        return config;
    };

    /**
     * 설정정보 저장.
     * localStorage() 사용불가시. 쿠키 사용
     */
    setConfig = (pKey, pInnerKey, value) => {
        const key = this.addTenantId(pKey);
        const innerKey = pInnerKey || 'common';

        try {
            let config = localStorage.getJsonValue(key);
            if (!config) config = {};
            config[innerKey] = { value, expireDate: localStorage.getExpireDate() };
            localStorage.setJsonValue(key, config);
        } catch (e) {
            console.log(e);
            if (value) {
                cookie.setCookieTextWithExpireDays(`${key}_${innerKey}`, JSON.stringify(value), 30);
            }
        }
    };

    /**
     * 설정정보 조회.
     * localStorage() 사용불가시. 쿠키 사용
     */
    getConfig = (pKey, pInnerKey) => {
        const innerKey = pInnerKey || 'common';
        const key = this.addTenantId(pKey);

        try {
            let config = localStorage.getJsonValue(key);
            // localStorage()에 설정값 존재 여부
            if (config) {
                // 만료된것들 삭제
                config = this.filterExpired(config);
                localStorage.setJsonValue(key, config);
                return config[innerKey] ? config[innerKey].value : null;
            }
            return null;
        } catch (e) {
            console.log(e);
            const v = cookie.getCookieText(`${key}_${innerKey}`);
            if (v) return JSON.parse(v);
            return v;
        }
    };
}

export default new ConfigCache();
