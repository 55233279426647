import React, { memo } from 'react';
import { Choose, When } from 'jsx-control-statements';
import { useSelector } from 'react-redux';
import SingleFilePromptTemplate from '../PromptTemplate/SingleFilePromptTemplate';
import SingleFileWithKeywordPromptTemplate from '../PromptTemplate/SingleFileWithKeywordPromptTemplate';
import MultipleFilesPromptTemplate from '../PromptTemplate/MultipleFilesPromptTemplate';
import MultipleFilesWithKeywordPromptTemplate from '../PromptTemplate/MultipleFilesWithKeywordPromptTemplate';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../../Common/SummaryConstant';

const PromptTemplate = memo(() => {
    const promptTemplateType = useSelector(({ summary }) => summary.promptInput.promptTemplate.promptTemplateType);

    return (
        <Choose>
            <When condition={promptTemplateType === SUMMARY_PROMPT_TEMPLATE_TYPE.single}>
                <SingleFilePromptTemplate />
            </When>
            <When condition={promptTemplateType === SUMMARY_PROMPT_TEMPLATE_TYPE.singleWithKeyword}>
                <SingleFileWithKeywordPromptTemplate />
            </When>
            <When condition={promptTemplateType === SUMMARY_PROMPT_TEMPLATE_TYPE.multiple}>
                <MultipleFilesPromptTemplate />
            </When>
            <When condition={promptTemplateType === SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword}>
                <MultipleFilesWithKeywordPromptTemplate />
            </When>
        </Choose>
    );
});

export default PromptTemplate;
