import $http from 'modules/Http';

const prefix = '/common/v1/codes';

class CodesRest {
    static getCode(codeId) {
        return $http.get(`${prefix}/${codeId}`);
    }
}

export default CodesRest;
