/* eslint-disable */
import React, { Component } from 'react';
import DownloadRest from '../../../apis/DownloadRest';
import AuthService from '../../../utils/AuthService';
import SignatureUtil from '../../../utils/SignatureUtil';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { alert } from '../../../utils/ModalService';
import _ from 'lodash';

class FileDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folderFileList: [],
            type: '',
            tagInfoVal: '',
            incomLinkUrl: ''
        };
    }

    componentDidMount() {
        this.intervalDownload(0);
    }

    closePopup = () => {
        this.props.close();
        // e.stopPropagation();
    };

    async intervalDownload(idx) {
        const { folderFileList, type, incomLinkUrl, linkType } = this.props;
        let list = [];
        let failIdx = idx;

        if (!!type && type === 'Manual') {
            this.downloadManual();
        } else {
            if (folderFileList instanceof Array) {
                list = folderFileList;
            } else {
                const file = {
                    objtId: folderFileList.objtId,
                    onpstId: folderFileList.onpstId,
                    fileVerSno: folderFileList.fileVerSno
                };

                list.push(file);
            }

            if (list.length >= idx + 1) {
                setTimeout(() => {
                    const item = list[idx];
                    DownloadRest.downloadWasTemp(item.objtId, item.onpstId, item.fileVerSno, incomLinkUrl, linkType).then(
                        result => {
                            console.log('downloadWasTemp result: ', result);
                            if (result.resultCode === 200) {
                                this.goFileDownload(item, incomLinkUrl);

                                this.intervalDownload(idx + 1);
                            } else {
                                failIdx = failIdx + 1;
                                if (list.length === failIdx) {
                                    // 20450: 권한 20451: 삭제된 파일
                                    alert('drive.alert.viewer.cannotOpen').then(() => {
                                        this.closePopup();
                                    });
                                }
                            }
                        },
                        fail => {
                            console.log('downloadWasTemp fail result: ', fail);
                        }
                    );
                }, 1000);
            }
        }
    }

    goFileDownload(item, incomLinkUrl) {
        const { linkType } = this.props;
        const tuid = AuthService.getAuthToken();
        const chip = AuthService.getSignatureKey();

        let url = '/drive/v1/file-download/' + item.objtId;
        let fileVerSno = item.fileVerSno || '';
        if (!incomLinkUrl) {
            incomLinkUrl = 'N';
        }
        let params = '';
        if (item.fileVerSno == null) {
            if (_.isNil(linkType)) {
                params = { onpstId: item.onpstId, incomLinkUrl: incomLinkUrl };
            } else {
                params = { linkType: linkType, onpstId: item.onpstId, incomLinkUrl: incomLinkUrl  };
            }

        } else {
            if (_.isNil(linkType)) {
                params = { onpstId: item.onpstId, incomLinkUrl: incomLinkUrl, fileVerSno: item.fileVerSno };
            } else {
                params = { linkType: linkType, onpstId: item.onpstId, incomLinkUrl: incomLinkUrl, fileVerSno: item.fileVerSno };
            }
        }

        let method = 'GET';
        let signature = SignatureUtil.generateSignature({ url, params, method }, chip);
        let queryStr = '';
        if (item.fileVerSno == null) {
            if (_.isNil(linkType)) {
                queryStr = '?incomLinkUrl=' + incomLinkUrl + '&onpstId=' + item.onpstId + '&tuid=' + tuid + '&hmac=' + encodeURIComponent(signature)
            } else {
                queryStr = '?incomLinkUrl=' + incomLinkUrl + '&linkType='+ linkType + '&onpstId=' + item.onpstId + '&tuid=' + tuid + '&hmac=' + encodeURIComponent(signature)
            }

        } else {
            if (_.isNil(linkType)) {
                queryStr = '?fileVerSno=' + fileVerSno + '&incomLinkUrl=' + incomLinkUrl + '&onpstId=' + item.onpstId + '&tuid=' + tuid + '&hmac=' + encodeURIComponent(signature);
            } else {
                queryStr = '?fileVerSno=' + fileVerSno + '&incomLinkUrl=' + incomLinkUrl + '&onpstId=' + item.onpstId + '&linkType='+ linkType + '&tuid=' + tuid + '&hmac=' + encodeURIComponent(signature);
            }
        }

        let frame = document.createElement('iframe');
        frame.id = 'downloadFrame' + Math.random();
        frame.style.visibility = 'hidden';
        document.body.appendChild(frame);

        frame.src = url + queryStr;
        frame.onload = this.closePopup();
    }

    downloadManual() {
        const { tagInfoVal } = this.props;
        const tuid = AuthService.getAuthToken();
        const chip = AuthService.getSignatureKey();

        let url = '/drive/v1/file-download/manual';
        let params = { tagInfoVal: tagInfoVal };
        let method = 'GET';
        let signature = SignatureUtil.generateSignature({ url, params, method }, chip);
        let queryStr = '?tagInfoVal=' + tagInfoVal + '&tuid=' + tuid + '&hmac=' + encodeURIComponent(signature);

        let frame = document.createElement('iframe');
        frame.id = 'downloadFrame' + Math.random();
        frame.style.visibility = 'hidden';
        document.body.appendChild(frame);

        frame.src = url + queryStr;
        frame.onload = this.closePopup();
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.download.prepareDown" />
                    </h5>
                </div>

                <div className="modal-body">
                    <div className="guide-load">
                        <FormattedHTMLMessage id="drive.guideText.download.prepareDown" tagName="p" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Download.defaultProps = {
//     folderFileList: []
// };

export default FileDownload;
