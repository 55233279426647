/* eslint-disable */
import React from 'react';
import { array } from 'prop-types';
import HistoryDefaultType from './HistoryDefaultType';
import HistoryFolderType from './HistoryFolderType';
import HistoryRenameType from './HistoryRenameType';
import HistoryShareType from './HistoryShareType';
import HistoryShareModType from './HistoryShareModType';
import DateConverter from '../../../../utils/DateConverter';

const rowBuilder = ({ baseDay, baseDayList }) => {
    return (
        <>
            <dt key={baseDay}>{DateConverter.convertHistory(baseDay)}</dt>
            {baseDayList.map(item => {
                if (item.evtSectCd === 'MOVE' || item.evtSectCd === 'COPY') {
                    return <HistoryFolderType key={item.evtOccuDt+item.evtSectCd} history={item} />;
                }
                if (item.evtSectCd === 'RENAME_FILE' || item.evtSectCd === 'RENAME_FOLDER') {
                    return <HistoryRenameType key={item.evtOccuDt+item.evtSectCd} history={item} />;
                }
                if (item.evtSectCd === 'SHAREADD' || item.evtSectCd === 'SHAREDEL') {
                    return <HistoryShareType key={item.evtOccuDt+item.evtSectCd} history={item} />;
                }
                if (item.evtSectCd === 'SHAREMOD') {
                    return <HistoryShareModType key={item.evtOccuDt+item.evtSectCd} history={item} />;
                }
                return <HistoryDefaultType key={item.evtOccuDt+item.evtSectCd} history={item} />;
            })}
        </>
    )
};

const DayHistory = props => {
    const { list } = props;
    let baseDay = '';
    let baseDayList = [];
    const totalList = [];

    for (let i = 0; i < list.length; i += 1) {
        if (baseDay !== list[i].evtDay || i + 1 === list.length) {
            let sameDayCheck = false;
            let lastNumberCheck = false;

            if (baseDay === list[i].evtDay) sameDayCheck = true;
            if (i + 1 === list.length) lastNumberCheck = true;

            if (sameDayCheck && lastNumberCheck) {
                baseDayList.push(list[i]);
            }

            let newList = rowBuilder({ baseDay, baseDayList });
            if (baseDay !== '') {
                totalList.push(newList);
            }

            baseDay = list[i].evtDay;
            baseDayList = [];
            if (!sameDayCheck && lastNumberCheck) {
                baseDayList.push(list[i]);
                newList = rowBuilder({ baseDay, baseDayList });
                totalList.push(newList);
                baseDayList = [];
            } else {
                baseDayList.push(list[i]);
            }
        } else {
            baseDayList.push(list[i]);
        }
    }

    return <>{totalList}</>;
};

DayHistory.propTypes = {
    list: array
};

DayHistory.defaultProps = {
    list: []
};

export default DayHistory;
