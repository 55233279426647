import _ from 'lodash';
import moment from 'moment/moment';

const VALID_MIN_VAL_TENANT_RENEWAL_MAX_YEAR = 1;

const REQ_STAT_CD = {
    PROCESSING: 'PROCESSING'
};

const getReqStatCds = (list = []) => {
    return _.chain(list)
        .map(file => {
            const { extra: { req: { reqStatCd = '' } = {} } = {} } = file;
            return reqStatCd;
        })
        .uniq()
        .value();
};

const isReqProcessingFiles = (reqStatCds = []) => {
    return reqStatCds.length === 1 && reqStatCds.indexOf(REQ_STAT_CD.PROCESSING) === 0;
};

const isReqNonProcessingFiles = (reqStatCds = []) => {
    return reqStatCds.indexOf(REQ_STAT_CD.PROCESSING) === -1;
};

const isTenantRenewalMaxYearValid = digitizedTenantRenewalMaxYear => {
    return _.isNumber(digitizedTenantRenewalMaxYear) && digitizedTenantRenewalMaxYear >= VALID_MIN_VAL_TENANT_RENEWAL_MAX_YEAR;
};

const getExpiredDateInfos = (user = {}) => {
    const { locale: { timezoneDiff = 0 } = {}, config } = user;
    const { userCstdMap: { cstdPrdMM, cstdPrdDD, cstdPrdYY, tenantRenewalMaxYear } = {} } = config;

    const digitizedTenantRenewalMaxYear = parseInt(tenantRenewalMaxYear, 10);

    // 1. 보존 기한 변경 요청 최대일자(개인) -> 개인 보존 기한 정책
    const personalRenewalMaxDate = moment()
        .utcOffset(timezoneDiff)
        .add({ years: cstdPrdYY, months: cstdPrdMM, days: cstdPrdDD });

    // 2. NASCA 만료 최대일자
    const nascaExpiredMaxDate = moment('2099-12-31');

    const expiredDateList = [personalRenewalMaxDate, nascaExpiredMaxDate];

    if (isTenantRenewalMaxYearValid(digitizedTenantRenewalMaxYear)) {
        const tenantRenewalMaxDate = moment()
            .utcOffset(timezoneDiff)
            .add({ years: digitizedTenantRenewalMaxYear });

        expiredDateList.push(tenantRenewalMaxDate);
    }

    // 1, 2, 3 일자 중 가장 작은 일자가 최대일자
    const maxExpiredDate = moment.min(expiredDateList);

    const nowDate = moment().utcOffset(timezoneDiff);

    return {
        initialExpiredDate: nowDate,
        minExpiredDate: nowDate,
        maxExpiredDate
    };
};

const getExpiredPeriodLabel = (config = {}) => {
    const { userCstdMap: { cstdPrdMM, cstdPrdDD, cstdPrdYY, tenantRenewalMaxYear } = {} } = config;
    const isValidPeriod = period => period !== '00';

    const digitizedTenantRenewalMaxYear = parseInt(tenantRenewalMaxYear, 10);

    if (isTenantRenewalMaxYearValid(digitizedTenantRenewalMaxYear)) {
        return {
            period: digitizedTenantRenewalMaxYear,
            unitRscId: 'com.text.years'
        };
    }

    if (isValidPeriod(cstdPrdYY)) {
        return {
            period: parseInt(cstdPrdYY, 10),
            unitRscId: 'com.text.years'
        };
    }

    if (isValidPeriod(cstdPrdMM)) {
        return {
            period: parseInt(cstdPrdMM, 10),
            unitRscId: 'com.text.months'
        };
    }

    if (isValidPeriod(cstdPrdDD)) {
        return {
            period: parseInt(cstdPrdDD, 10),
            unitRscId: 'com.text.days'
        };
    }

    return {};
};

export default { REQ_STAT_CD, getReqStatCds, isReqProcessingFiles, isReqNonProcessingFiles, getExpiredDateInfos, getExpiredPeriodLabel };
