import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import CustomList from '../../List/CustomList';
import ShareObject from './row/ShareObject';

const ShareObjectViewPopup = ({ files, close, intl }) => {
    if (_.isEmpty(files)) return '';

    // 폴더 > 파일 순서로 이름 오름차순 정렬
    files.sort((a, b) => {
        if (a.drive.objtSectCd === b.drive.objtSectCd) {
            if (a.drive.objtNm < b.drive.objtNm) {
                return -1;
            }

            if (a.drive.objtNm > b.drive.objtNm) {
                return 1;
            }

            return 0;
        }

        if (a.drive.objtSectCd === 'FOLDER' && b.drive.objtSectCd === 'FILE') {
            return -1;
        }

        return 0;
    });

    const rowBuilder = ({ style, index }) => {
        rowBuilder.propTypes = {
            style: PropTypes.object,
            index: PropTypes.number
        };

        rowBuilder.defaultProps = {
            style: {},
            index: 0
        };

        return <ShareObject file={files[index].drive} style={style} key={index} />;
    };

    return (
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({ id: 'drive.button.share.viewAllItems' })}</h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => close(false)}>
                    <i className="ic-20-close" />
                </a>
            </div>

            <div className="modal-body">
                <div className="pop-grid-list view-mode-type">
                    <div className="comm-grid-list react-list">
                        <CustomList className="grid-row-group" customScroll={true} virtualized={true} height={368} rowCount={files.length} rowHeight={46} rowBuilder={rowBuilder} />
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-primary" role="button" onClick={() => close(false)}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

ShareObjectViewPopup.propTypes = {
    files: PropTypes.array,
    close: PropTypes.func,
    intl: PropTypes.object
};

ShareObjectViewPopup.defaultProps = {
    files: [],
    close: _.noop,
    intl: {}
};

export default injectIntl(ShareObjectViewPopup);
