class Cookie {
    /* 쿠키 값 조회 */
    getCookieText = cName => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${cName}=`);
        let cValue = '';
        if (parts.length === 2) {
            cValue = parts
                .pop()
                .split(';')
                .shift();
        }
        return cValue;
    };

    /* 쿠키 값 저장 - 만료기간 1년 */
    setCookieText = (cName, cValue) => {
        this.setCookieTextWithExpireDays(cName, cValue, 365);
    };

    setCookieTextWithExpireDays = (cName, cValue, cExpireDays) => {
        const expire = new Date();
        expire.setDate(expire.getDate() + cExpireDays);
        let cookies = `${cName}=${cValue}; path=/ `;
        cookies += `;expires=${expire.toUTCString()};`;
        document.cookie = cookies;
    };

    setCookieTextWithExpireMins = (cName, cValue, cExpireMins) => {
        const expire = new Date();
        expire.setMinutes(expire.getMinutes() + cExpireMins);
        let cookies = `${cName}=${cValue}; path=/ `;
        cookies += `;expires=${expire.toUTCString()};`;
        document.cookie = cookies;
    };

    setCookieTextWithExpireSeconds = (cName, cValue, cExpireSeconds) => {
        const expire = new Date();
        expire.setSeconds(expire.getSeconds() + cExpireSeconds);
        let cookies = `${cName}=${cValue}; path=/ `;
        cookies += `;expires=${expire.toUTCString()};`;
        document.cookie = cookies;
    };

    deleteCookie = (cName, domain) => {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() - 1);
        if (domain) {
            document.cookie = `${cName}= ; domain=${domain}; expires=${expireDate.toUTCString()}; path=/`;
        } else {
            document.cookie = `${cName}= ; expires=${expireDate.toUTCString()}; path=/`;
        }
    };
}

export default new Cookie();
