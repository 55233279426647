import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import store from '../../../redux/store';
import LoadingAction from '../../../redux/actions/Loading';
import FileCheckAction from '../../../redux/actions/FileCheck';
import { isChecked } from '../../../redux/reducers/FileCheck';
import FileReloadAction from '../../../redux/actions/FileReload';
import CustomList from '../../presentationals/List/CustomList';
import { withFilesContext, compareProps } from './DriveFilesContext';
import FoldersRest from '../../../apis/FoldersRest';
import DropToast from '../../presentationals/DragDrop/DropToast';
import { POINT_TYPE } from '../../../constants/DragDrop';

class DriveFilesBody extends React.Component {
    constructor(props) {
        super(props);
        const storeState = store.getState();
        const { fileReload } = storeState;
        this.preFileReload = fileReload;
        this.invertedList = {};
        this.formalList = [];
        this.showMore = false;
        const { folder = { drive: {}, actionPolicy: {} } } = fileReload;
        const {
            context: {
                props: { pageClass, folderId },
                state: { list }
            }
        } = this.props;
        this.state = {
            height: this.calcHeight(),
            folderInformation: folder,

            // LIST 아이템 드래그 여부
            isDragging: false,
            dragItemIds: [],
            dragItemIsOver: false,
            dragItemIsDroppable: false
        };
        this.cache = pageClass.buildCache(folderId);
        this.syncInvertedList(list);
        this.listStyle = { overflowY: false, overflowX: false };
        this.innerRef = null;
        this.isLoading = false;

        // LIST 아이템 드래그 관련
        this.setRowDragging = this.setRowDragging.bind(this);
        this.getRowDragging = this.getRowDragging.bind(this);
        this.isRowDragging = this.isRowDragging.bind(this);
    }

    componentDidMount() {
        this.subscribe();
        this.dragSubscribe();
        const {
            context: {
                props: { refreshWhenMount }
            }
        } = this.props;
        if (refreshWhenMount) {
            this.dataLoad(false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            context: prevContext,
            context: {
                props: { pageClass: prevPageClass }
            }
        } = prevProps;
        const {
            context,
            context: {
                props: { pageClass, folderId },
                state: { sort }
            }
        } = this.props;
        const { folderInformation } = this.state;
        const { folderInformation: prevFolderInformation } = prevState;

        if (folderId) {
            if (!folderInformation || !folderInformation.maxAuthCd || !sort || !sort.sortField) return;
        }
        if (
            compareProps(['props.folderId', 'props.onpstId', 'state.sort', 'props.search'], context, prevContext, true) ||
            (folderId && !prevFolderInformation.maxAuthCd && folderInformation !== prevFolderInformation)
        ) {
            if (pageClass.pageId !== prevPageClass.pageId && !compareProps(['state.sort'], context, prevContext, true)) {
                return;
            }
            this.dataLoad(false);
        }
    }

    componentWillUnmount() {
        if (_.isFunction(this.unsubscribe)) this.unsubscribe();
        if (_.isFunction(this.dragUnsubscribe)) this.dragUnsubscribe();
        const {
            removeAllCheck
            // context: { props: pageClass }
        } = this.props;
        removeAllCheck();
    }

    async dataLoad(more, retry) {
        const {
            context: {
                state,
                state: { list: formal = [] },
                props: { setTotal, folderId, onpstId, pageClass, closeDetailFile, searchParams, maxAuthCd },
                actions: { setGridSettings }
            },
            removeAllCheck,
            openLoading,
            closeLoading
        } = this.props;
        const { folderInformation } = this.state;
        const LOAD_SIZE = pageClass.pageId === 'prefer' ? 30 : 100;

        let searchMaxAuthCd = maxAuthCd;
        let searchOnpstId = onpstId;
        if (folderInformation.drive.objtId === folderId && !pageClass.isRoot(folderId)) {
            searchOnpstId = folderInformation.drive.onpstId;
            searchMaxAuthCd = folderInformation.maxAuthCd;
        }
        openLoading();
        this.isLoading = true;
        this.formalList = [...formal];
        try {
            const addParams = { size: retry ? LOAD_SIZE * 2 : LOAD_SIZE, onpstId: searchOnpstId, ...state.sort, trackTotal: pageClass.trackTotal };
            if (more) {
                const [lastObject] = this.formalList.slice(-1);
                const { extra = {} } = lastObject;
                const { sortValues } = extra; // null 일수도 있음
                addParams.searchAfter = pageClass.enforceSearchAfter(folderId) ? this.formalList.length - 1 : sortValues;
            }

            const { list: rawList, total } = await pageClass.searchFunction(Object.assign({}, searchParams, addParams), folderId, searchOnpstId, searchMaxAuthCd);
            const rawLen = rawList.length;
            this.showMore = false;
            // 조회해온 list가 LOAD_SIZE만큼이면 더보기 노출
            if (rawLen === LOAD_SIZE) {
                this.showMore = true;
            }

            this.cache = pageClass.buildCache(folderId, searchOnpstId, searchMaxAuthCd);
            let list = pageClass.cacheWhenLoad ? rawList.map(file => this.cache.filter(file)).filter(file => file) : rawList;

            // filter 적용으로 파일수가 반이상 줄었다면, 2배로 재조회를 1회만 해준다.
            if (this.showMore && !retry && rawList.length / 2 > list.length) {
                this.dataLoad(more, true);
                return;
            }
            if (more) {
                list = [...this.formalList, ...list];
            } else {
                this.invertedList = {};
                this.formalList = [];
            }
            this.syncInvertedList(list);
            // let index = 0;
            // list.forEach(file => {
            //     file.index = index; // eslint-disable-line no-param-reassign
            //     index += 1;
            //     this.invertedList[file.drive.objtId] = file;
            // });
            if (more) {
                setGridSettings({ list });
            } else {
                let emptyRoot = false;
                if (pageClass.isRoot(folderId) && !rawLen) emptyRoot = true;
                const gridSettings = {
                    firstApproach: true,
                    emptyRoot,
                    list
                };
                if (total !== -1) {
                    setTotal(total);
                    gridSettings.total = total;
                }
                // const { folderInformation } = this.state;
                removeAllCheck();
                closeDetailFile();
                setGridSettings(gridSettings);
            }
        } finally {
            closeLoading();
            this.isLoading = false;
        }
    }

    setTotalCount = (total = 0) => {
        const {
            context: {
                props: { setTotal = () => {} }
            }
        } = this.props;
        setTotal(total);
    };

    /**
     * 목록에서 삭제, 검색필터 등으로 필터링된 파일이 있는 경우, 후처리
     * 만료/Non compl. 메뉴처럼 상태도 고려하도록 수정
     *
     * @param list 목록에 표시된 파일 목록
     * @param sendUnCheck 체크박스 해제 dispatch
     * @param deletedFiles 목록에서 필터링된 파일 목록
     * @param total 목록 수가 아닌 조회 가능한 전체 개수(trackTotal 참고)
     */
    handleDeleted = (list, sendUnCheck, deletedFiles) => {
        const { totalCount } = this.props;

        const deletedFileKeys = Object.keys(deletedFiles);

        if (_.isEmpty(deletedFileKeys)) {
            return list;
        }

        deletedFileKeys.forEach(key => {
            sendUnCheck(deletedFiles[key]);
        });

        const listCount = list.length;

        if (deletedFileKeys.length === listCount) {
            // 전제 다 지워진 경우. 전체 refresh
            this.dataLoad();
            return [];
        }

        const filteredList = list.filter(({ drive }) => drive && !deletedFiles[drive.objtId]);
        const filteredListCount = filteredList.length;

        this.invertedList = {};
        this.formalList = [];

        this.syncInvertedList(filteredList);

        this.setTotalCount(_.isNil(totalCount) ? filteredListCount : totalCount - (listCount - filteredListCount));

        return filteredList;
    };

    syncInvertedList = list => {
        if (!list) return;
        let index = 0;
        list.forEach(file => {
            file.index = index; // eslint-disable-line no-param-reassign
            index += 1;
            this.invertedList[file.drive.objtId] = file;
        });
    };

    handleRefershed = (sendCheck, setDetailFile, refreshTargets) => {
        if (refreshTargets.length) {
            refreshTargets
                .filter(file => isChecked(file))
                .forEach(file => {
                    sendCheck(file);
                });
            setDetailFile(undefined, false, refreshTargets);
        }
    };

    reloadProcess = data => {
        const { reloadType: type = 'refresh', reloadPrefer: prefer, reloadFiles: files, reloadCreated: created, reloadReq } = data;
        const {
            context: {
                props: { setDetailFile }
            }
        } = this.props;
        switch (type) {
            case 'cached': {
                if (created) {
                    this.cache.created(files).then(() => this.dataLoad());
                } else {
                    this.cache.updated(files, prefer).then(() => {
                        const {
                            context: {
                                state: { list: contextList },
                                actions: { setGridSettings }
                            },
                            sendUnCheck,
                            sendCheck,
                            setCheckFiles
                        } = this.props;
                        const refreshTargets = [];
                        const list = contextList;
                        const deletedFiles = {};
                        files.forEach(file => {
                            const objtId = file.objtId || file.drive.objtId;
                            const originData = this.invertedList[objtId];
                            if (originData) {
                                const filteredFile = this.cache.filter(originData);
                                if (filteredFile && filteredFile.drive) {
                                    const { index } = originData;
                                    list[index] = filteredFile;
                                    filteredFile.index = index;
                                    this.invertedList[objtId] = filteredFile;
                                    refreshTargets.push(filteredFile);
                                } else {
                                    deletedFiles[objtId] = originData;
                                }
                            }
                        });

                        if (reloadReq) {
                            setCheckFiles(refreshTargets);
                        }

                        const filteredList = this.handleDeleted(list, sendUnCheck, deletedFiles);
                        setGridSettings({ list: filteredList });
                        this.handleRefershed(sendCheck, setDetailFile, refreshTargets);

                        if (reloadReq) {
                            // TODO 107 Config maxCount
                            // cstd: EFL_DRV_FLCM_FILE_CHECK_MAX_COUNT
                            // cpgs: EFL_DRV_CDM_FILE_CHECK_MAX_COUNT
                            if (filteredList.length < 100) {
                                this.dataLoad(true);
                            }
                        }
                    });
                }
                break;
            }

            case 'uncheckedAll': {
                const {
                    removeAllCheck,
                    context: {
                        actions: { setGridSettings },
                        props: { closeDetailFile }
                    }
                } = this.props;
                removeAllCheck();
                setGridSettings({});
                closeDetailFile();
                break;
            }
            case 'openDetail': {
                if (files.length) {
                    setDetailFile(files[0], true);
                }
                break;
            }

            case 'uploadComplete': {
                const {
                    context: {
                        props: { folderId }
                    }
                } = this.props;
                if (undefined === folderId) {
                    this.dataLoad();
                } else if (_.find(files, file => file.drive.onpstFolderId === folderId)) {
                    this.debounceDataLoad();
                }
                break;
            }

            case 'workgroup': {
                FoldersRest.getPair(files, true).then(searchFiles => {
                    if (searchFiles && searchFiles.length) {
                        const {
                            context: {
                                state: { list: contextList },
                                actions: { setGridSettings }
                            },
                            sendUnCheck,
                            sendCheck
                        } = this.props;
                        const list = contextList;
                        const deletedFiles = {};
                        const newData = searchFiles[0];
                        list.unshift(newData);
                        this.syncInvertedList(list);
                        setGridSettings({ list: this.handleDeleted(list, sendUnCheck, deletedFiles), emptyRoot: false });
                        this.handleRefershed(sendCheck, setDetailFile, newData);
                    }
                });
                break;
            }

            default: {
                this.dataLoad();
            }
        }
    };

    debounceDataLoad = _.debounce(() => {
        this.dataLoad();
    }, 1000);

    subscribe = () => {
        this.unsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { fileReload } = storeState;
            if (fileReload === this.preFileReload) return;
            this.preFileReload = fileReload;

            const { data = {}, folder, type: actionType } = fileReload;
            if (actionType === FileReloadAction.SET_FOLDER_INFORMATION) {
                if (folder) {
                    const {
                        context: {
                            props: { setDetailFile }
                        }
                    } = this.props;
                    setDetailFile(undefined, false, [folder]);

                    this.setState({ folderInformation: folder });
                }
            } else {
                this.reloadProcess(data);
            }
        });
    };

    handleWindowResize = () => {
        this.setState({ height: this.calcHeight() });
    };

    calcHeight() {
        const {
            context: {
                props: { pageClass }
            }
        } = this.props;
        return window.innerHeight + window.pageYOffset - pageClass.OFFSET_TOP;
    }

    // 리스트 아이템 드래그 여부 세팅
    setRowDragging(dragItemIds) {
        const isDragging = !!dragItemIds && dragItemIds.length > 0;
        const itemIds = !!dragItemIds && dragItemIds.length > 0 ? [].concat(dragItemIds) : [];

        this.setState({
            isDragging,
            dragItemIds: itemIds
        });
    }

    getRowDragging() {
        const { isDragging, dragItemIds } = this.state;
        return {
            isDragging,
            dragItemIds
        };
    }

    isRowDragging(objtId = '') {
        const { isDragging = false, dragItemIds = [] } = this.state;
        if (!objtId) {
            return isDragging;
        }
        return isDragging && dragItemIds.length > 0 && dragItemIds.indexOf(objtId) !== -1;
    }

    dragSubscribe = () => {
        this.dragUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { dragDrop: dragDropState } = storeState;
            const {
                context: {
                    props: { folderId }
                }
            } = this.props;
            // if (dragDropState === this.prevDragDropState) {
            //     return;
            // }
            // this.prevDragDropState = dragDropState;

            const { isDragging = false, isDragOver = false, isDroppable = false, overItem = {} } = dragDropState;
            const { id: overItemId = '', position: overItemPosition = '' } = overItem || {};

            if (!isDragging) {
                this.setState({ dragItemIsOver: false, dragItemIsDroppable: true });
                return;
            }

            if (overItemPosition === POINT_TYPE.SCREEN && overItemId === folderId) {
                this.setState({ dragItemIsOver: isDragOver, dragItemIsDroppable: isDroppable });
            } else {
                this.setState({ dragItemIsOver: false, dragItemIsDroppable: true });
            }
        });
    };

    setInnerRef = innerRef => {
        this.innerRef = innerRef;
    };

    loadMoreByScroll = _.debounce((scroll = {}) => {
        if (!this.innerRef || !this.showMore || this.isLoading) return;

        const { scrollDirection, scrollOffset } = scroll;
        if (scrollDirection !== 'forward') return;

        const { height } = this.state;
        const {
            current: { scrollHeight }
        } = this.innerRef;

        if (scrollHeight - (height + scrollOffset) < 100) {
            this.dataLoad(true);
        }
    }, 150);

    rowBuilder = ({ style, index }) => {
        const {
            context: {
                props: { pageClass, folderId },
                state: { list }
            }
        } = this.props;
        const { isDragging } = this.state;

        return pageClass.rowBuilder({
            list,
            loadMore: () => this.dataLoad(true),
            style,
            index,
            folderId,
            isListDragging: isDragging,
            setRowDragging: this.setRowDragging,
            getRowDragging: this.getRowDragging,
            isRowDragging: this.isRowDragging
        });
    };

    render() {
        const {
            context: {
                props: { pageClass, folderId },
                state,
                state: { list }
                // temp: { folderInformation: contextFolderInfo } = {}
            }
        } = this.props;
        const { height, folderInformation, isDragging, dragItemIsOver, dragItemIsDroppable } = this.state;
        if (!list) return null;

        // let mergeFolderInfo = folderInformation;
        // if (contextFolderInfo && folderId === contextFolderInfo.drive.objtId) {
        //     mergeFolderInfo = contextFolderInfo;
        // }

        if (!list.length) {
            return (
                <>
                    {pageClass.emptyBuilder()}
                    {pageClass.dndAble && pageClass.uploadBuilder(folderInformation)}
                    {pageClass.dndAble && <DropToast />}
                </>
            );
        }

        // 더보기가 나와야할때는 + 1 해줌
        const rowCount = this.showMore && !pageClass.moreScroll ? list.length + 1 : list.length;

        return (
            <>
                <CustomList
                    className={classnames(
                        'grid-row-group',
                        'scroll-bar',
                        pageClass.dndAble && !isDragging && dragItemIsOver && 'upload-zone',
                        pageClass.dndAble && !isDragging && dragItemIsOver && !dragItemIsDroppable && 'failure',
                        pageClass.dndAble && 'dzdzdz'
                    )}
                    customScroll={false}
                    virtualized={true}
                    windowResize={true}
                    handleWindowResize={this.handleWindowResize}
                    height={height}
                    rowCount={rowCount}
                    rowHeight={pageClass.absoluteHeight || state.viewHeight}
                    rowBuilder={this.rowBuilder}
                    listStyle={{ overflowY: false, overflowX: false }}
                    handleScroll={pageClass.moreScroll ? this.loadMoreByScroll : null}
                    onDidMount={this.setInnerRef}
                />
                {pageClass.dndAble && pageClass.uploadBuilder(folderInformation.drive.objtId === folderId ? folderInformation : null)}
                {pageClass.dndAble && <DropToast />}
            </>
        );
    }
}

DriveFilesBody.propTypes = {
    context: PropTypes.object.isRequired,
    openLoading: PropTypes.func.isRequired,
    closeLoading: PropTypes.func.isRequired,
    removeAllCheck: PropTypes.func.isRequired,
    sendCheck: PropTypes.func.isRequired,
    sendUnCheck: PropTypes.func.isRequired,
    setCheckFiles: PropTypes.func.isRequired,
    totalCount: PropTypes.number
};

DriveFilesBody.defaultProps = {
    totalCount: 0
};

const mapStateToProps = state => ({
    totalCount: state.fileList.totalCount
});

DriveFilesBody.displayName = 'DriveFilesBody';
export default connect(
    mapStateToProps,
    {
        openLoading: () => ({ type: LoadingAction.OPEN, key: 'driveFiles' }),
        closeLoading: () => ({ type: LoadingAction.CLOSE, key: 'driveFiles' }),
        removeAllCheck: () => ({ type: FileCheckAction.REMOVE_ALL }),
        sendCheck: file => ({ type: FileCheckAction.ADD, file }),
        sendUnCheck: file => ({ type: FileCheckAction.REMOVE, file }),
        setCheckFiles: list => ({ type: FileCheckAction.SET_FILES, list })
    }
)(withFilesContext(DriveFilesBody));
