import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import queryString from 'query-string';
import _ from 'lodash';
import FoldersRest from '../../apis/FoldersRest';
import FileCache from '../FileCache';
import { ALL, CANCEL, NONE, RAW_VALUES_SEPARATOR } from '../../components/containers/Filter/FilterConstants';
import Base from './Base';

class Expiration extends Base {
    pageId = 'expiration';

    cacheWhenLoad = true;

    // 메뉴 처리 값
    menu = {
        // 우측 상단 처리
        header: {
            iconGroupType: null, // 진입 시 icon 영역
            iconGroupTypeMulti: 'expirationMulti' // 멀티선택 시 icon 영역
        },
        // 개별 row 처리
        row: {
            iconGroupType: 'expiration', // icon 영역
            menuGroupType: 'expiration', // 우클릭 영역 및 헤더 더보기 icon 영역
            floatingIconGroupType: null, // 파일명 옆 floating icon 영역
            moreGroupType: 'expiration' // 각 행 icon 더보기 버튼 영역
        }
    };

    trackTotal = true;

    getDefaultSortColumn() {
        return { sortField: 'expiredDate', sortOrder: 'ASC' };
    }

    searchFunction(params) {
        return FoldersRest.getFolders(this.pageId, params);
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    buildCache() {
        return new FileCache(
            file => {
                // 파일의 보존기한 변경 요청 상태
                const stateCode = _.get(file, 'config.drive.expirationRequestStateCode');

                // 만료 (예정) 파일 검색필터 중 보존기한 변경 요청 상태
                //   - ALL: 모든 상태
                //   - PROCESSING: 요청 중
                //   - REJECTED: 반려
                //   - NONE: 미요청
                //     "NONE"은 2가지 상태에 대한 통합 상태: 1) 요청을 한번도 하지 않은 상태 2) 요청 후 취소(CANCEL)한 상태
                const { expirationRequestStateCodes } = queryString.parse(window.location.search);
                const filteredStateCodes = _.split(expirationRequestStateCodes, RAW_VALUES_SEPARATOR);

                if (_.includes(filteredStateCodes, ALL)) {
                    return file;
                }

                // 검색필터에 미요청(NONE)이 존재하면, 요청취소(CANCEL)된 파일도 미요청으로 간주
                if (_.includes(filteredStateCodes, NONE)) {
                    filteredStateCodes.push(CANCEL);
                }

                return _.includes(filteredStateCodes, stateCode) ? file : null;
            },
            false,
            true,
            'EXPIRATION'
        );
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-expire" />
                    <FormattedHTMLMessage id="drive.text.list.empty.expiration" tagName="p" />
                </div>
            </div>
        );
    }

    isHideHeaderMenu() {
        return true;
    }
}

export default new Expiration();
