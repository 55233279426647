import $http from 'modules/Http';

const prefix = '/drive/v1/cdmdtct';

class CdmDtctRest {
    static getDetectedKeywords(params) {
        return $http.get(`${prefix}/detectedKeywords`, { params });
    }

    static getMaxCountWords(params = {}) {
        return $http.get(`${prefix}/maxCountWords`, { params }).then(
            response => {
                if (response.resultCode === 200) {
                    return response.data;
                }
                return Promise.reject(response);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }
}

export default CdmDtctRest;
