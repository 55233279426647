import store from '../store';
import { openAlert, openConfirm } from '../actions/GlobalPopup';

// GlobalPopupService is not recommended, please use Redux action.
export default class GlobalPopupService {
    static openAlertPopup = message => {
        return new Promise(resolve => {
            store.dispatch(openAlert(message));
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const { isOpen } = state.globalPopup.alert;
                if (!isOpen) {
                    resolve();
                    unsubscribe();
                }
            });
        });
    };

    static openConfirmPopup = message => {
        return new Promise(resolve => {
            store.dispatch(openConfirm(message));
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const { isOpen, result } = state.globalPopup.confirm;
                if (!isOpen) {
                    resolve(result);
                    unsubscribe();
                }
            });
        });
    };
}
