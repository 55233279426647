import React from 'react';
import BaseMenu from '../BaseMenu';
import DriveInfoRest from '../../../../apis/DriveInfoRest';
import FolderFileNameInputPopup from '../../../containers/Popup/FolderFileNameInputPopup';
import { confirm, popup, toast, alert } from '../../../../utils/ModalService';

const executor = (props, files) => {
    const tgt = files[0];
    const { drive } = tgt;

    function rename(name) {
        return DriveInfoRest.updateFolderFileName(drive, name).then(rslt => {
            const { resultCode, data } = rslt;

            if (resultCode === 20031) {
                // alert({ id: 'drive.alert.rename.useFile' });
                if (drive.objtSectCd === 'FOLDER') {
                    alert({ id: 'drive.alert.rename.useFolder' });
                } else {
                    alert({ id: 'drive.alert.rename.useFile' });
                }
                return false;
            }

            if (resultCode === 20123) {
                alert({ id: 'drive.alert.rename.duplicate.workgroupNm' });
                return false;
            }

            if (resultCode !== 200) {
                if (drive.objtSectCd === 'FOLDER' && data === null) {
                    alert({ id: 'drive.alert.selectFolder.noAuth' });
                } else if (drive.objtSectCd !== 'FOLDER' && data === null) {
                    alert({ id: 'drive.alert.rename.noAuth' });
                } else {
                    alert(data || '');
                }
                return false;
            }
            toast('user.toast.renamePop.success');
            const { objtId, objtSectCd } = drive;
            const folderChanged = objtSectCd === 'FOLDER';
            const changedData = folderChanged ? { objtId, objtNm: name.trim() } : undefined;
            props.callback({ folderChanged, changedData });
            return true;
        });
    }

    const popupProps = {
        headerName: 'user.title.popup.rename',
        saveApi: rename,
        preVal: drive.objtSectCd === 'FILE' ? drive.objtNm.replace(/\.[^/.]+$/, '') : drive.objtNm
    };
    const beforeMsg = '변경 사항이 있습니다. 그래도 취소하시겠습니까?';
    const config = {
        component: <FolderFileNameInputPopup {...popupProps} />,
        beforeCloseCallBack: () => confirm(beforeMsg).then(() => true, () => false)
    };
    popup(config);
};

class NameChange extends BaseMenu {
    getName() {
        return 'nameChange';
    }

    getLabel() {
        return 'drive.button.list.menu.nameChange';
    }

    isVisible() {
        // 이름변경, 이동은 공유받은 파일에서 제약. 편집은 Agent에서 알아서한다. https://efss.samsung.net/cc/#/view/post/154701429054905568?coCdVal=C60
        const { custom: { isExplorerConstraintsValue = false } = {} } = this.props;
        return !isExplorerConstraintsValue && super.isVisible(false, 'rename');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-name-change', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-name-change', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default NameChange;
