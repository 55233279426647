import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { alert, toast } from 'src/utils/ModalService';
import DriveInfoRest from 'apis/DriveInfoRest';
import { connect } from 'react-redux';
import FileReload from 'utils/FileReload';
import _ from 'lodash';
import DragDropAction from '../../redux/actions/DragDrop';
import { getDragDrop } from '../../redux/reducers/DragDrop';
import { POINT_TYPE, ACTION_TYPE, ITEM_TYPE } from '../../constants/DragDrop';
import store from '../../redux/store';

class TreeNode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dragHover: false,

            isActiveDropZone: false
        };

        const { intl } = this.props;
        this.intl = intl;

        this.onDrop = this.onDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
    }

    componentDidMount() {
        this.dragSubscribe();
    }

    componentWillUnmount() {
        if (this.dragUnsubscribe && _.isFunction(this.dragUnsubscribe)) {
            this.dragUnsubscribe();
        }
    }

    dragSubscribe = () => {
        this.dragUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { dragDrop: dragDropState } = storeState;
            if (dragDropState === this.prevDragDropState) {
                return;
            }
            this.prevDragDropState = dragDropState;

            const { isActiveDropZone } = this.state;
            const { isDragging = false, from = '', action = '' } = dragDropState;

            // LIST TO TREE 이동이 아니면 패스
            if (from !== POINT_TYPE.LIST || action !== ACTION_TYPE.MOVE) {
                this.setState({ isActiveDropZone: false });
                return;
            }

            if (isActiveDropZone !== isDragging) {
                this.setState({ isActiveDropZone: isDragging });
            }
        });
    };

    onDragEnter(e) {
        e.preventDefault();
        e.stopPropagation();

        const { enterDrag, node: { id = '' } = {} } = this.props;
        const dragDrop = getDragDrop();

        // DRAG ITEM 권한 체크
        // LIST TO TREE는 일단 가능하도록 처리 - OWN 에서만 TREE를 사용해서
        if (id && dragDrop && dragDrop.from === POINT_TYPE.LIST && dragDrop.items && dragDrop.items.length > 0) {
            enterDrag({ id, type: ITEM_TYPE.FOLDER, position: POINT_TYPE.TREE }, true);
            this.setState({ dragHover: true });
        }
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();

        const { leaveDrag, node: { id = '' } = {} } = this.props;
        const dragDrop = getDragDrop();

        // TREE FOLDER 에서 다른곳으로 DRAG OVER 된 경우, LEAVE 처리
        if (id && dragDrop.overItem && dragDrop.overItem.id === id && dragDrop.overItem.position === POINT_TYPE.TREE) {
            leaveDrag();
        }
        this.setState({ dragHover: false });
    }

    async onDrop(e, node) {
        e.preventDefault();
        e.stopPropagation();
        if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
            e.nativeEvent.stopImmediatePropagation();
        }

        const { id: objtId } = node;
        const { loginUser, endDrag } = this.props;
        const dragDrop = getDragDrop() || {};
        const files = _.cloneDeep(dragDrop.items || []);

        const destFolder = {
            objtId,
            onpstId: loginUser.id
        };

        // DRAG ITEM 이 없는경우
        if (_.isEmpty(files)) {
            endDrag();
            this.setState({ dragHover: false });
            return;
        }

        // LIST TO TREE

        // 리스트 폴더와 DROP 대상 폴더가 같은 경우
        // A 폴더의 하위 아이템들을 A폴더로 이동
        const originOnpstFolderID = files[0].onpstFolderId;

        // DRAG ITEM에 DROP 대상 폴더가 포함된 경우
        // A 폴더가 드래그된 상태로, A폴더로 이동
        const sameFolderIds = _.chain(files)
            .filter(file => file.objtId === objtId)
            .map(file => file.objtId)
            .value();

        if (originOnpstFolderID === objtId || sameFolderIds.length > 0) {
            alert('drive.alert.list.sameFolder');
            this.setState({ dragHover: false });
            return;
        }

        // 그 외 이동 가능여부 확인 API 호출
        const targetFiles = [].concat(files);
        const validResponse = await DriveInfoRest.moveValidateFolderFile(destFolder, targetFiles);

        // 이동 불가능 케이스 메시지 처리
        const validMessage = this.getMoveMessageKey(validResponse);
        if (validMessage) {
            alert(validMessage);
            this.setState({ dragHover: false });
            return;
        }

        // 이동 처리
        const moveResponse = await DriveInfoRest.moveFolderFile(destFolder, targetFiles);
        const { resultCode: moveResultCode = 400, data: moveData = {} } = moveResponse;
        const { success: moveSuccessCount = 0 } = moveData;

        // 이동 결과 후처리
        if (moveResultCode === 200 && moveData && moveSuccessCount > 0) {
            toast('drive.toast.move.success');

            // 이동 대상 중 폴더가 포함되어 있으면, 트리 새로고침
            const folderChanged = (_.filter(files, file => file.objtSectCd === 'FOLDER') || []).length > 0;
            this.reload(folderChanged);
        } else if (moveResultCode === 403) {
            alert('drive.alert.incomLink.noAuthFolder');
        } else if (_.includes([20911, 20912, 20031], moveResultCode)) {
            alert(this.getMoveMessageKey(moveResponse));
        } else {
            alert('drive.alert.move.fail');
        }

        endDrag();
        this.setState({ dragHover: false });
    }

    reload = (folderChanged = false) => {
        FileReload.call(null, [], { reloadType: 'reload', folderChanged });
    };

    getMoveMessageKey = ({ resultCode = 400, message = '' }) => {
        if (resultCode === 200) return '';
        // 목적 폴더 삭제
        if (resultCode === 20352) return 'drive.alert.list.deletedFolder';
        // 이동 대상 폴더 삭제
        if (resultCode === 20353) return 'drive.alert.list.deletedItem';
        // 이동시 서클 생성
        if (resultCode === 20912) return 'drive.alert.list.move.subFolder';
        // 최대 깊이 초과
        if (resultCode === 20911) return 'drive.alert.invalid.exceed50Depth';
        // 이동 대상 사용중
        if (resultCode === 20031) return message;
        // 그 외
        return 'com.alert.failed';
    };

    render() {
        const { dragHover, isActiveDropZone } = this.state;
        const {
            children,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth,
            scaffoldBlockPxWidth,
            lowerSiblingCounts,
            connectDropTarget,
            isOver,
            draggedNode,
            canDrop,
            treeIndex,
            treeId, // Delete from otherProps
            getPrevRow, // Delete from otherProps
            node, // Delete from otherProps
            path, // Delete from otherProps
            rowDirection,
            loginUser, // Delete from otherProps
            enterDrag, // Delete from otherProps
            leaveDrag, // Delete from otherProps
            endDrag, // Delete from otherProps
            ...otherProps
        } = this.props;

        // Construct the scaffold representing the structure of the tree
        const scaffoldBlockCount = lowerSiblingCounts.length;
        const scaffold = [];
        lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
            let lineClass = '';
            if (lowerSiblingCount > 0) {
                // At this level in the tree, the nodes had sibling nodes further down

                if (listIndex === 0) {
                    // Top-left corner of the tree
                    // +-----+
                    // |     |
                    // |  +--+
                    // |  |  |
                    // +--+--+
                    lineClass = 'rst__lineHalfHorizontalRight rst__lineHalfVerticalBottom';
                } else if (i === scaffoldBlockCount - 1) {
                    // Last scaffold block in the row, right before the row content
                    // +--+--+
                    // |  |  |
                    // |  +--+
                    // |  |  |
                    // +--+--+
                    lineClass = 'rst__lineHalfHorizontalRight rst__lineFullVertical';
                } else {
                    // Simply connecting the line extending down to the next sibling on this level
                    // +--+--+
                    // |  |  |
                    // |  |  |
                    // |  |  |
                    // +--+--+
                    lineClass = 'rst__lineFullVertical';
                }
            } else if (listIndex === 0) {
                // Top-left corner of the tree, but has no siblings
                // +-----+
                // |     |
                // |  +--+
                // |     |
                // +-----+
                lineClass = 'rst__lineHalfHorizontalRight';
            } else if (i === scaffoldBlockCount - 1) {
                // The last or only node in this level of the tree
                // +--+--+
                // |  |  |
                // |  +--+
                // |     |
                // +-----+
                lineClass = 'rst__lineHalfVerticalTop rst__lineHalfHorizontalRight';
            }

            scaffold.push(<div key={`pre_${1 + i}`} style={{ width: scaffoldBlockPxWidth }} className={'rst__lineBlock'.concat(lineClass)} />);

            if (treeIndex !== listIndex && i === swapDepth) {
                // This row has been shifted, and is at the depth of
                // the line pointing to the new destination
                let highlightLineClass = '';

                if (listIndex === swapFrom + swapLength - 1) {
                    // This block is on the bottom (target) line
                    // This block points at the target block (where the row will go when released)
                    highlightLineClass = ' rst__highlightBottomLeftCorner';
                } else if (treeIndex === swapFrom) {
                    // This block is on the top (source) line
                    highlightLineClass = ' rst__highlightTopLeftCorner';
                } else {
                    // This block is between the bottom and top
                    highlightLineClass = ' rst__highlightLineVertical';
                }

                let style;
                if (rowDirection === 'rtl') {
                    style = {
                        width: scaffoldBlockPxWidth,
                        right: scaffoldBlockPxWidth * i
                    };
                } else {
                    // Default ltr
                    style = {
                        width: scaffoldBlockPxWidth,
                        left: scaffoldBlockPxWidth * i
                    };
                }

                scaffold.push(
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        style={style}
                        className={'rst__absoluteLineBlock '.concat(highlightLineClass)}
                    />
                );
            }
        });

        let style;
        if (rowDirection === 'rtl') {
            style = { right: scaffoldBlockPxWidth * scaffoldBlockCount };
        } else {
            // Default ltr
            style = { left: scaffoldBlockPxWidth * scaffoldBlockCount };
        }

        const { id: nodeId = '' } = node || {};

        return connectDropTarget(
            <div
                key={`tree_node_wrapper_${nodeId}`}
                style={{
                    position: 'relative'
                }}>
                <div {...otherProps} className="rst__node">
                    {scaffold}

                    <div className="rst__nodeContent " style={style}>
                        {Children.map(children, child =>
                            cloneElement(child, {
                                isOver,
                                canDrop,
                                draggedNode
                            })
                        )}
                    </div>
                </div>
                <div
                    key={`tree_drop_zone_${nodeId}`}
                    onDragEnter={e => this.onDragEnter(e)}
                    onDragOver={e => this.onDragOver(e)}
                    onDragLeave={e => this.onDragLeave(e)}
                    onDrop={e => this.onDrop(e, node)}
                    className={`rst__node dzdzdz ${dragHover ? 'dnd-zone' : ''}`}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '105',
                        top: '0px',
                        left: '0px',
                        opacity: 1,
                        display: isActiveDropZone ? 'block' : 'none'
                    }}
                />
            </div>
        );
    }
}

TreeNode.defaultProps = {
    swapFrom: null,
    swapDepth: null,
    swapLength: null,
    canDrop: false,
    draggedNode: null,
    rowDirection: 'ltr'
};

TreeNode.propTypes = {
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    swapFrom: PropTypes.number,
    swapDepth: PropTypes.number,
    swapLength: PropTypes.number,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,

    listIndex: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,

    // Drop target
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool,
    draggedNode: PropTypes.shape({}),

    // used in dndManager
    getPrevRow: PropTypes.func.isRequired,
    node: PropTypes.shape({}).isRequired,
    path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,

    // rtl support
    rowDirection: PropTypes.string,

    loginUser: PropTypes.object.isRequired,
    enterDrag: PropTypes.func.isRequired,
    leaveDrag: PropTypes.func.isRequired,
    endDrag: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(
    mapStateToProps,
    {
        enterDrag: (overItem, isDroppable) => ({ type: DragDropAction.ENTER_DRAG, isDragOver: true, isDroppable, overItem }),
        leaveDrag: () => ({ type: DragDropAction.LEAVE_DRAG }),
        endDrag: () => ({ type: DragDropAction.END_DRAG })
    }
)(TreeNode);
