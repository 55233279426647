import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { partnerAccount as partnerAccountActions, globalPopup as globalPopupActions } from 'src/redux/actions';
import HelpRest from '../../apis/HelpRest';
import PartnerAccountComponent from '../presentationals/PartnerAccountComponent';

const regexpStrings = {
    id: /^[a-zA-Z0-9\\._-]+(?:\.[a-zA-Z0-9\\._-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z]*[a-zA-Z])+$/,
    name: '^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\\s]+$',
    position: '^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\\s]+$',
    engName: '^[a-zA-Z0-9\\s]+$',
    engPosition: '^[a-zA-Z0-9\\s]+$',
    companyNm: '.+'
};

class PartnerAccountComponentContainer extends Component {
    onChangeValue = (target, value) => {
        const { index, changePartnerValue } = this.props;
        changePartnerValue(index, target, value);
    };

    onBlurInput = async (target, value) => {
        const { partner, index, changePartnerValue, changePartnerValidationValue, tenantId } = this.props;
        const trimValue = _.trim(value);
        changePartnerValue(index, target, trimValue);

        if (_.isEmpty(partner[target].trim()) && (target === 'position' || target === 'engPosition')) {
            changePartnerValidationValue(index, target, '');
            return;
        }

        if (_.isEmpty(partner[target].trim())) {
            changePartnerValidationValue(index, target, 'empty');
            return;
        }

        const inputRegexp = new RegExp(regexpStrings[target]);
        const valid = inputRegexp.test(partner[target]);

        if (target === 'id' && valid) {
            const isDuplicatedLoginId = await HelpRest.getIsDuplicatedLoginId(partner.id, tenantId);
            if (isDuplicatedLoginId) {
                changePartnerValidationValue(index, target, 'duplicated');
                return;
            }
        }

        changePartnerValidationValue(index, target, valid ? '' : 'invalid');
    };

    onClickClearButton = target => {
        const { index, changePartnerValue, changePartnerValidationValue } = this.props;
        changePartnerValue(index, target, '');

        if (target !== 'position' && target !== 'engPosition') {
            changePartnerValidationValue(index, target, 'empty');
        }
    };

    onClickTeamSearch = () => {
        const { openTeamSearchWithTree, index } = this.props;
        openTeamSearchWithTree({ index });
    };

    render() {
        const { partner, languageOptions, validation, index, removePartner, maxUseDays, configs, loginUser, useExpireYn } = this.props;
        return (
            <PartnerAccountComponent
                partner={partner}
                languageOptions={languageOptions}
                validation={validation}
                index={index}
                onChangeValue={this.onChangeValue}
                removePartner={removePartner}
                onBlurInput={this.onBlurInput}
                onClickClearButton={this.onClickClearButton}
                onClickTeamSearch={this.onClickTeamSearch}
                maxUseDays={maxUseDays}
                configs={configs}
                loginUser={loginUser}
                useExpireYn={useExpireYn}
            />
        );
    }
}

PartnerAccountComponentContainer.propTypes = {
    tenantId: PropTypes.string.isRequired,
    partner: PropTypes.object.isRequired,
    languageOptions: PropTypes.array.isRequired,
    validation: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    changePartnerValue: PropTypes.func.isRequired,
    removePartner: PropTypes.func.isRequired,
    changePartnerValidationValue: PropTypes.func.isRequired,
    openTeamSearchWithTree: PropTypes.func.isRequired,
    maxUseDays: PropTypes.number.isRequired,
    configs: PropTypes.array.isRequired,
    loginUser: PropTypes.object.isRequired,
    useExpireYn: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
    tenantId: state.auth.user.tenantId,
    validation: state.partnerAccount.validationList[props.index],
    defaultLanguage: state.partnerAccount.defaultLanguage,
    maxUseDays: state.partnerAccount.maxUseDays,
    configs: state.config.configs,
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    changePartnerValue: partnerAccountActions.changePartnerValue,
    removePartner: partnerAccountActions.removePartner,
    changePartnerValidationValue: partnerAccountActions.changePartnerValidationValue,
    openTeamSearchWithTree: globalPopupActions.openTeamSearchWithTree
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PartnerAccountComponentContainer);
