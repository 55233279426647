import { Base64 } from 'js-base64';
import _ from 'lodash';
import { $http } from 'modules/index';
import moment from 'moment';
import uuidV4 from 'uuid/v4';
import DriveConfigRest from './DriveConfigRest';
import DriveConfigs from '../constants/DriveConfigs';

export const GET_TENANT_NOTIFICATION_SETTINGS = '/auth/v1/configs/tenant/notification';
export const GET_NOTIFICATION_SETTINGS = '/auth/v1/configs/user/notification';
export const SET_NOTIFICATION_SETTINGS = '/auth/v1/configs/user/notification';
export const EFL_DRV_FILENAME_CLICK_EXECUTION_MODE = 'EFL_DRV_FILENAME_CLICK_EXECUTION_MODE';

export default class SettingsRest {
    static getSettingsBasicData = async (userId, timeDiff) => {
        const user = await SettingsRest.getUser(userId);
        const languageOptions = await SettingsRest.getLanguageOptions();
        const timeZoneOptions = await SettingsRest.getTimeZoneOptions();
        const cstdOptions = await SettingsRest.getCstdOptions(user.tenantId);
        // const postProcess = await SettingsRest.getPostProcess(user.tenantId);
        const clickExecutionMode = await SettingsRest.getCfg(EFL_DRV_FILENAME_CLICK_EXECUTION_MODE);
        const secureMode = await SettingsRest.getCfgStoragePrd();
        // const cstdOptions = { cstdPolicyNm: '보관기간A', cstdPrdYY: '', cstdPrdMM: '', cstdPrdDD: '27' };

        const basicData = {};

        basicData.showUserPwdLastModDt =
            moment(user.userPwdLastModDt).isValid() && !_.isUndefined(timeDiff) // 2020.12.21 사용자의 비번최근수정일자가 있으면서, timeDiff가 undefined일 경우 에러발생. 체크로직 추가
                ? moment(user.userPwdLastModDt)
                      .utcOffset(timeDiff)
                      .format('YYYY-MM-DD')
                : '';
        basicData.userPhotoFilePathVal = user.userPhotoFilePathVal;

        basicData.kStorageAllocSize = Number(user.lightDriveStorageAllocSize).toFixed(2);
        basicData.mStorageAllocSize = Number(user.mailDriveStorageAllocSize).toFixed(2);
        basicData.storageAllocSize = Number(Number(basicData.kStorageAllocSize) + Number(basicData.mStorageAllocSize)).toFixed(2);

        basicData.kStorageUseSize = Number(user.lightStorageUseSize).toFixed(2); // kRecyclebinUseSize 포함된 사용량
        basicData.kRecyclebinUseSize = Number(user.lightRecyclebinUseSize).toFixed(2);

        basicData.mStorageUseSize = Number(user.mailStorageUseSize).toFixed(2); // mRecyclebinUseSize 포함된 사용량
        basicData.mRecyclebinUseSize = Number(user.mailRecyclebinUseSize).toFixed(2);

        basicData.storageTotalUseSize = Number(Number(basicData.kStorageUseSize) + Number(basicData.mStorageUseSize)).toFixed(2);
        basicData.storageTotalUseSizePercentage = Math.round((basicData.storageTotalUseSize / basicData.storageAllocSize) * 100);

        basicData.kStorageUseOnlySize = Number(Number(basicData.kStorageUseSize) - Number(basicData.kRecyclebinUseSize)).toFixed(2);
        basicData.kStorageUseSizePercentageByKStorage = Math.round((basicData.kStorageUseSize / basicData.kStorageAllocSize) * 100);
        basicData.kStorageUseSizePercentage = Math.round((basicData.kStorageUseSize / basicData.storageAllocSize) * 100);
        basicData.mStorageUseOnlySize = Number(Number(basicData.mStorageUseSize) - Number(basicData.mRecyclebinUseSize)).toFixed(2);
        basicData.mStorageUseSizePercentageByMStorage = Math.round((basicData.mStorageUseSize / basicData.mStorageAllocSize) * 100);
        basicData.mStorageUseSizePercentage = Math.round((basicData.mStorageUseSize / basicData.storageAllocSize) * 100);

        if (Number.isNaN(basicData.storageTotalUseSizePercentage)) basicData.storageTotalUseSizePercentage = 0;
        if (Number.isNaN(basicData.kStorageUseSizePercentage)) basicData.kStorageUseSizePercentage = 0;
        if (Number.isNaN(basicData.kStorageUseSizePercentageByKStorage)) basicData.kStorageUseSizePercentageByKStorage = 0;
        if (Number.isNaN(basicData.mStorageUseSizePercentage)) basicData.mStorageUseSizePercentage = 0;
        if (Number.isNaN(basicData.mStorageUseSizePercentageByMStorage)) basicData.mStorageUseSizePercentageByMStorage = 0;

        basicData.languageOptions = languageOptions;
        basicData.timeZoneOptions = timeZoneOptions;

        if (cstdOptions != null && Object.keys(cstdOptions).length) {
            cstdOptions.cstdPrdYY = _.isEmpty(cstdOptions.cstdPrdYY) ? 0 : Number(cstdOptions.cstdPrdYY);
            cstdOptions.cstdPrdMM = _.isEmpty(cstdOptions.cstdPrdMM) ? 0 : Number(cstdOptions.cstdPrdMM);
            cstdOptions.cstdPrdDD = _.isEmpty(cstdOptions.cstdPrdDD) ? 0 : Number(cstdOptions.cstdPrdDD);
            cstdOptions.cstdPolicyNm = _.isEmpty(cstdOptions.cstdPolicyNm) ? '' : cstdOptions.cstdPolicyNm;
            cstdOptions.cstdPrdUnlimitedYn = _.isEmpty(cstdOptions.cstdPrdUnlimitedYn) ? 'N' : cstdOptions.cstdPrdUnlimitedYn;

            basicData.cstdOptions = cstdOptions;
        } else {
            const tempCstdOptions = {};
            tempCstdOptions.cstdPolicyNm = '';
            tempCstdOptions.cstdPrdYY = 0;
            tempCstdOptions.cstdPrdMM = 0;
            tempCstdOptions.cstdPrdDD = 0;
            tempCstdOptions.cstdPrdUnlimitedYn = 'N';
            basicData.cstdOptions = tempCstdOptions;
        }
        basicData.clickExecutionMode = clickExecutionMode.cfgVal;
        basicData.originClickExecutionMode = clickExecutionMode.cfgVal;

        basicData.fileStorageUseYn = _.isEmpty(secureMode.fileStorageUseYn) ? 'N' : secureMode.fileStorageUseYn;
        if (basicData.fileStorageUseYn !== 'N') {
            basicData.periodOfUseFrom = _.isEmpty(secureMode.periodOfUseFrom) ? '' : secureMode.periodOfUseFrom;
            basicData.periodOfUseTo = _.isEmpty(secureMode.periodOfUseTo) ? '' : secureMode.periodOfUseTo;
        }

        basicData.agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        basicData.secureFolderUseRequest = await DriveConfigRest.getConfig(DriveConfigs.SECURE_FOLDER_USE_REQUEST);

        // if (postProcess != null && Object.keys(postProcess).length) {
        //     postProcess.cstdPolicyPstPrcs = _.isEmpty(postProcess.cstdPolicyPstPrcs) ? '' : postProcess.cstdPolicyPstPrcs;
        //     postProcess.cstdPolicyHestPrd = _.isEmpty(postProcess.cstdPolicyHestPrd) ? 0 : postProcess.cstdPolicyHestPrd;
        //     // if (!_.isEmpty(postProcess.cstdPolicyPstPrcs)) {
        //     //     postProcess.cstdPolicyPstPrcs = postProcess.cstdPolicyPstPrcs;
        //     // } else {
        //     //     postProcess.cstdPolicyPstPrcs = '';
        //     // }
        //     // if (!_.isEmpty(postProcess.cstdPolicyHestPrd)) {
        //     //     postProcess.cstdPolicyHestPrd = Number(postProcess.cstdPolicyHestPrd);
        //     // } else {
        //     //     postProcess.cstdPolicyHestPrd = 0;
        //     // }
        //     basicData.postProcess = postProcess;
        // } else {
        //     const tempPostProcess = {};
        //     tempPostProcess.cstdPolicyPstPrcs = '';
        //     tempPostProcess.cstdPolicyHestPrd = 0;
        //     basicData.postProcess = tempPostProcess;
        // }

        _.forEach(basicData.languageOptions, draft => {
            draft.value = draft.code;
            draft.message = draft.name;
        });
        _.forEach(basicData.timeZoneOptions, draft => {
            draft.data = draft.value;
            draft.value = draft.code;
            draft.message = draft.name;
        });
        return basicData;
    };

    static getUser = async userId => {
        const response = await $http.get(`/auth/v1/users/${userId}`);
        return response.data;
    };

    static getCfg = async cfgId => {
        const response = await $http.get(`/auth/v1/user/configs/${cfgId}`);
        return response.data;
    };

    static getLanguageOptions = async () => {
        const response = await $http.get('/auth/v1/codes/language');
        return response.data;
    };

    static getTimeZoneOptions = async () => {
        const response = await $http.get('/auth/v1/codes/timezone');
        return response.data;
    };

    static getCstdOptions = async tenantId => {
        const response = await $http.get(`/common/v1/cstd-policy/${tenantId}/user`);
        return response.data;
    };

    static getPostProcess = async tenantId => {
        const response = await $http.get(`/common/v1/flcm/${tenantId}/post-process-config`);
        return response.data;
    };

    static putLanguage = async language => {
        /*  brity 로그아웃시 언어 코드 맞추는 부분은 서로 영향도가 커서 제외하기로 함
        let brityLanguage = 'ko_KR.EUC-KR'; // brity 한글 코드
        if (language === '002') {
            brityLanguage = 'en_US.EUC-KR'; // brity 영문 코드
        } else if (language === '003') {
            brityLanguage = 'en_US.EUC-KR'; // brity 영문 코드 (brity는 독일어 미지원이므로)
        } else if (language === '004') {
            brityLanguage = 'zh_CN.GB2312'; // brity 중국어 코드
        }

        const { host } = window.location;
        const hostArr = host.split('.');
        const target = `.${hostArr[hostArr.length - 2]}.${hostArr[hostArr.length - 1]}`;
        const expire = new Date();
        expire.setDate(expire.getDate() + 356);
        const cookies = `saveLanguage=${brityLanguage}; domain=${target}; path=/; expires=${expire.toUTCString()};`;
        document.cookie = cookies;
        */

        const response = await $http.put('/auth/v1/user/configs-session', [{ cfgId: 'EFL_AUT_CODE_LANGUAGE', cfgVal: language }]);
        return response;
    };

    static putTimeZone = async timeZone => {
        const response = await $http.put('/auth/v1/user/configs-session', [{ cfgId: 'EFL_AUT_CODE_TIME_ZONE', cfgVal: timeZone }]);
        return response;
    };

    static putFilenameClickExecutionMode = async clickExecutionMode => {
        const response = await $http.put('/auth/v1/user/configs-session', [{ cfgId: EFL_DRV_FILENAME_CLICK_EXECUTION_MODE, cfgVal: clickExecutionMode }]);
        return response;
    };

    static postChangeOwner = async (userId, cleanShareYn) => {
        const response = await $http.post('/drive/v1/move/auth', { targetUserId: userId, cleanShareYn });
        return response;
    };

    static getNotificationSettings = async type => {
        let url;
        if (type === 'defaultSettings') {
            url = GET_TENANT_NOTIFICATION_SETTINGS;
        } else if (type === 'settings') {
            url = GET_NOTIFICATION_SETTINGS;
        }
        const response = await $http.get(url, { params: { configTarget: 'EMAIL' } });
        const result = response.data;
        result.forEach(draft => {
            draft.configType = draft.configType.substring(draft.configTarget.length + 1);
        });

        return result;
    };

    static setNotificationSettings(data) {
        return $http.put(SET_NOTIFICATION_SETTINGS, data);
    }

    static getLoginHistory = async timeDiff => {
        const { data: deviceCodes } = await $http.get('/auth/v1/codes/2000007');
        const { data: devicePlatformCodes } = await $http.get('/auth/v1/codes/2000008');
        const { data: loginHistory } = await $http.get('/auth/v1/userHistory/login');

        _.forEach(loginHistory, draft => {
            draft.uniqueKey = uuidV4();
            const now = moment();
            const accessDate = moment(draft.accssDt).utcOffset(timeDiff);

            if (now.year() === accessDate.year() && now.dayOfYear() === accessDate.dayOfYear()) {
                draft.accessDate = accessDate.format('HH:mm');
            } else if (now.year() === accessDate.year()) {
                draft.accessDate = accessDate.format('MM-DD HH:mm');
            } else {
                draft.accessDate = accessDate.format('YYYY-MM-DD HH:mm');
            }
            const accessDescArr = [];
            const deviceCode = _.find(deviceCodes, { codeValidVal: draft.deviceTypCd });
            if (!_.isEmpty(deviceCode)) accessDescArr.push(deviceCode.thCdValidValNm);
            const devicePlatformCode = _.find(devicePlatformCodes, { codeValidVal: draft.devicePlatformCd });
            if (!_.isEmpty(devicePlatformCode)) accessDescArr.push(devicePlatformCode.thCdValidValNm);
            draft.accessDesc = accessDescArr.join('');
        });
        return loginHistory;
    };

    static updateProfileFile = async (userId, userPhotoFilePathVal) => {
        await $http.put(`/auth/v1/users/${userId}/photo`, null, { params: { userPhotoFilePathVal } });
    };

    static checkCurrnetPassword = async (userId, password) => {
        const response = await $http.put(`/auth/v1/user/${userId}/password`, { oldPassword: Base64.encode(password) });
        return response;
    };

    static changePassword = async (userId, password, newPassword) => {
        const response = await $http.put(`/auth/v1/user/${userId}/password`, { oldPassword: Base64.encode(password), newPassword: Base64.encode(newPassword) });
        return response;
    };

    static getCfgStoragePrd = async () => {
        const response = await $http.get('/auth/v1/user/configs/storage-prd');
        return response.data;
    };
}
