// action type
const PREFIX = 'TOAST_';
export const SHOW = PREFIX.concat('SHOW');
export const HIDE = PREFIX.concat('HIDE');
export const CLEAR = PREFIX.concat('CLEAR');

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const showToast = (message, duration, linkMessage, onLinkClick) => action(SHOW, { message, duration, linkMessage, onLinkClick });
export const hideToast = () => action(HIDE);
export const clearToast = () => action(CLEAR);
