import $http from 'modules/Http';
import { Base64 } from 'js-base64';

const prefix = '/common/v1/helpcenter/anuc';

class NoticeRest {
    static getNotices(params) {
        const changedParams = params;
        const { keyword, limit } = params;
        changedParams.anucStatCd = 'Y';
        if (!limit) changedParams.limit = 100;
        if (keyword) changedParams.keyword = Base64.encode(keyword);
        return $http.get(prefix, { params: changedParams });
    }

    static getNotice(anucId) {
        return $http.get(`${prefix}/${anucId}`);
    }
}

export default NoticeRest;
