import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SUMMARY_LENGTH_TYPES } from '../../SummaryOptionConstant';
import { summaryActions } from '../../../../../../redux/actions/Summary';
import LengthOptionItem from './LengthOptionItem';

const LengthOptionGroup = () => {
    const selectedLengthType = useSelector(({ summary }) => summary.optionSelectDialog.summaryOptions.lengthType);
    const dispatch = useDispatch();

    const handleClick = value => {
        dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { lengthType: value } }));
    };

    return (
        <dl className="row-list flex-wrap">
            <dt>
                <FormattedMessage id="drive.text.copilot.summary.option.lengthType" />
            </dt>
            <dd>
                <div className="copilot-chip-group">
                    {SUMMARY_LENGTH_TYPES.map(type => {
                        return <LengthOptionItem type={type} selectedLengthType={selectedLengthType} onSelect={handleClick} key={type} />;
                    })}
                </div>
            </dd>
        </dl>
    );
};

LengthOptionGroup.propTypes = {};

LengthOptionGroup.defaultProps = {};

export default LengthOptionGroup;
