import ActionType from '../actions/FileMenu';

const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionType.OPEN:
            return {
                isOpen: true,
                openType: action.openType,
                eventObj: action.eventObj,
                statusCallback: action.statusCallback,
                data: action.data
            };
        case ActionType.CLOSE:
            return {
                isOpen: false,
                statusCallback: action.statusCallback
            };
        default:
            return state;
    }
};
