import { $http } from 'modules/index';

const preFix = '/drive/v1';

class DriveInfoRest {
    static copyFolderFile(destFolder, objtList) {
        return $http.post(`${preFix}/copy`, { destFolder, objtList });
    }

    static moveFolderFile(destFolder, objtList) {
        return $http.post(`${preFix}/move`, { destFolder, objtList });
    }

    static moveValidateFolderFile(destFolder, objtList) {
        return $http.post(`${preFix}/move/validate`, { destFolder, objtList });
    }

    static getFolderFileInfo({ objtId, onpstId, isIncludeComInfo = true, isIncludeShareTarget = true }) {
        const config = { params: { objtId, onpstId, isIncludeComInfo, isIncludeShareTarget } };
        return $http.get(`${preFix}/objt/objtInfo`, config);
    }

    static getBasicFolderFileInfo(objtId, onpstId) {
        const config = { params: { objtId, onpstId } };
        return $http.get(`${preFix}/objt`, config);
    }

    static updateFolderFileName(tgt, newName) {
        const { objtId, onpstId } = tgt;
        const config = { params: { objtId, onpstId, name: newName } };
        return $http.post(`${preFix}/objt/name`, {}, config);
    }

    static addTag({ objtId, onpstId }, tag) {
        const config = { params: { objtId, onpstId, tag } };
        return $http.post(`${preFix}/objt/tag`, {}, config);
    }

    static removeTag({ objtId, onpstId }, tag) {
        const config = { params: { objtId, onpstId, tag } };
        return $http.post(`${preFix}/objt/tag-remove`, {}, config);
    }

    static fileUseCheck({ objtId, onpstId }) {
        const config = { params: { objtId, onpstId } };
        return $http.post(`${preFix}/objt/use`, {}, config);
    }

    static getBasicFolderFileInfoList(objtIds, onpstId, isIncludeComInfo = false) {
        const config = { params: { objtIds, onpstId, isIncludeComInfo } };
        return $http.get(`${preFix}/objt/multi`, config);
    }
}

export default DriveInfoRest;
