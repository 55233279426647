import PropTypes from 'prop-types';
import { includes } from 'lodash';
import React from 'react';

const SelectableRow = props => {
    const { item, move, select, style } = props;
    const { icon, objtNm, objtId, hasAuth = true, selected = false } = item;
    const handleClick = (e, clickedItem) => {
        const { target } = e;
        if (!includes(['btn-ic-nor', 'ic-12-arr-list'], target.className)) select(clickedItem);
    };
    return (
        <div
            className={`grid-row ${selected ? 'is-checked' : ''} ${hasAuth ? '' : 'expired-row'}`}
            style={style}
            role="button"
            onClick={e => handleClick(e, item)}
            onDoubleClick={() => {
                if (objtId !== 'all') move(item);
            }}>
            <div className="grid-cell list-cell">
                <div className="item-file">
                    <i className={icon} />
                    <div className="file" title={objtNm}>
                        <span className="file-name">{objtNm}</span>
                    </div>
                </div>
            </div>
            {objtId !== 'all' && (
                <div className="grid-cell more-cell">
                    <a className="btn-ic-nor" role="button" title={`'${objtNm}'폴더로 이동`} onClick={() => move(item)}>
                        <i className="ic-12-arr-list" />
                    </a>
                </div>
            )}
        </div>
    );
};

SelectableRow.propTypes = {
    item: PropTypes.object.isRequired,
    move: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired
};

export default SelectableRow;
