import React from 'react';
import BaseMenu from '../BaseMenu';
import { alert as modalAlert, popup } from '../../../../utils/ModalService';
import FolderSelectPopup from '../../../containers/Popup/FolderSelect/FolderSelectPopup';
import { DriveInfoRest } from '../../../../apis';

const getPreSelectedFolder = ({ browserPathId: pathVal, pathValNm, onpstId }) => {
    const [, rootFolderId, ...pathIds] = pathVal.split('/');
    const [, rootFolderNm, ...pathNames] = pathValNm.split('\\');
    const pathIdsLen = pathIds.length;
    const topId = '111000000000000000';
    const preSelectedFolderDrive =
        pathIdsLen === 1
            ? {
                  objtId: rootFolderId,
                  onpstFolderId: topId,
                  pathVal: `/${topId}/${rootFolderId}`,
                  pathValNm: `/${rootFolderNm}`,
                  onpstId
              }
            : {
                  objtId: pathIds[pathIdsLen - 2],
                  onpstFolderId: pathIdsLen >= 3 ? pathIds[pathIdsLen - 3] : rootFolderId,
                  pathVal: `/${pathIds.slice(0, pathIdsLen - 1).join('/')}`,
                  pathValNm: `/${pathNames.slice(0, pathIdsLen - 1).join('/')}`,
                  onpstId
              };
    return {
        drive: preSelectedFolderDrive,
        actionPolicy: { create: true },
        rootFolderId
    };
};

const executor = (props, files) => {
    const refresh = () => props.callback({ reloadType: 'refresh', folderChanged: true });
    const [{ drive }] = files;
    DriveInfoRest.getFolderFileInfo(drive).then(({ resultCode, message, data }) => {
        if (resultCode === 200) {
            const popupProps = { mode: 'copy', preSelectedFolder: getPreSelectedFolder(data), files };
            popup(<FolderSelectPopup {...popupProps} />).then(refresh, () => false);
            return;
        }

        if (resultCode === 403) {
            modalAlert({ id: 'drive.alert.copy.noAuth' });
            return;
        }

        modalAlert({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } });
    });
};

class Copy extends BaseMenu {
    getName() {
        return 'copy';
    }

    getLabel() {
        return 'drive.button.list.menu.copy';
    }

    isVisible() {
        return super.isVisible(true, 'copy');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-copy', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-copy', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Copy;
