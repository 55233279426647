import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import DeletedFilter from './DeletedFilter';
import ClickOutside from '../presentationals/ClickOutside';
import QueryUtil from '../../utils/QueryUtil';

const OBJT_DEL_CDS = ['ALL', 'DTEMPORARY', 'DPRSRVEXP', 'DCPGS'];
const OBJT_SECT_CD = ['ALL', 'FOLDER', 'FILE'];

class DeletedSearch extends React.Component {
    constructor(props) {
        super(props);
        const {
            location: { search = '' }
        } = this.props;

        const queryParam = queryString.parse(search);
        const preParams = Object.assign({}, queryParam, {
            objtDelCds: QueryUtil.getAllowCodesWithJoin(queryParam.objtDelCds, OBJT_DEL_CDS, 'ALL'),
            objtSectCd: QueryUtil.getAllowCodesWithJoin(queryParam.objtSectCd, OBJT_SECT_CD, 'ALL')
        });

        this.isFilterInnerPopupOpen = false;
        this.state = {
            isFilterOpen: false,
            filterParams: preParams
        };

        this.onClickOutside = this.onClickOutside.bind(this);
        this.onClickFilter = this.onClickFilter.bind(this);

        this.clickOutSideRef = React.createRef();

        this.queryParam = preParams;
        this.initParams = {
            objtNm: '',
            objtDelDtFrom: undefined,
            objtDelDtTo: undefined,
            objtDelCds: 'ALL',
            objtSectCd: 'ALL'
        };
    }

    componentDidMount() {
        const { filterParams } = this.state;
        if (Object.keys(filterParams).length > 0) {
            this.onClickOutside();
        }
    }

    onClickOutside() {
        if (this.isFilterInnerPopupOpen) return;
        const { isFilterOpen } = this.state;
        if (isFilterOpen) {
            this.setState({
                isFilterOpen: false
            });
        }
    }

    openInnerPopup = isOpen => {
        this.isFilterInnerPopupOpen = isOpen;
    };

    onClickFilter(isFilterOpen) {
        const param = Object.assign({}, this.queryParam);
        this.setState({
            isFilterOpen,
            filterParams: param
        });
    }

    goSearch = (params = {}) => {
        const { onSearch } = this.props;

        const searchParams = {};
        if (_.isEmpty(params)) {
            Object.assign(searchParams, this.initParams);
        } else {
            Object.assign(searchParams, params);
        }

        this.setState({
            filterParams: searchParams
        });

        onSearch(searchParams);
    };

    render() {
        const { intl, loginUser = {} } = this.props;
        const { isFilterOpen, filterParams } = this.state;
        const { config: { useFlcm = false } = {} } = loginUser;

        let isSelectedAll = false;
        if (
            (filterParams.objtNm === '' || filterParams.objtNm === undefined) &&
            filterParams.objtSectCd === 'ALL' &&
            filterParams.objtDelCds === 'ALL' &&
            filterParams.objtDelDtTo === undefined &&
            filterParams.objtDelDtFrom === undefined
        ) {
            isSelectedAll = true;
        }

        return (
            <div className="func-inner-filter has-overlay-layer">
                <ClickOutside onClickOutside={this.onClickOutside} childrenRefs={[this.clickOutSideRef]}>
                    <div className="header-search" ref={this.clickOutSideRef}>
                        <div title={intl.formatMessage({ id: 'drive.tooltip.search.filter' })}>
                            <a
                                id="quick-search-filter"
                                className={`btn-ic-nor btn-sm ${Object.keys(filterParams).length > 0 && !isSelectedAll ? 'on' : ''}`}
                                role="button"
                                data-placement="bottom"
                                onClick={() => this.onClickFilter(!isFilterOpen)}
                                data-content={this.dataRendered}>
                                <i className="ic-20-filter" />
                            </a>
                        </div>
                        {isFilterOpen && (
                            <DeletedFilter
                                openInnerPopup={this.openInnerPopup}
                                params={filterParams}
                                initParams={this.initParams}
                                search={this.goSearch}
                                close={() => this.onClickFilter(false)}
                                useFlcmYn={useFlcm ? 'Y' : 'N'}
                            />
                        )}
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

DeletedSearch.defaultProps = {
    onSearch: () => {}
};

DeletedSearch.propTypes = {
    intl: PropTypes.object.isRequired,
    onSearch: PropTypes.func,
    location: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default withRouter(injectIntl(connect(mapStateToProps)(DeletedSearch)));
