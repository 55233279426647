import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { summaryActions } from '../../../../../../../redux/actions/Summary';
import { AI_MESSAGE_RESULT_STATUS } from '../../../../Common/SummaryConstant';

const SummaryStopButton = ({ summaryMessage }) => {
    const { summaryResults } = summaryMessage;

    const dispatch = useDispatch();

    const handleClick = () => {
        const { resultStatus } = _.last(summaryResults);

        if (resultStatus === AI_MESSAGE_RESULT_STATUS.waitForResult) {
            dispatch(summaryActions.thread.stopFetchAIMessage());
            return;
        }

        dispatch(summaryActions.thread.prepareToStopTypingAIMessage());
    };

    return (
        <div className="ai-fab">
            <div className="ai-fab-btns">
                <a className="btn-ic-line btn-gradation" role="button" onClick={handleClick}>
                    <i className="ic-20-copilot-waiting" />
                    <span className="btn-text">
                        <FormattedMessage id="drive.button.copilot.summary.stop" />
                    </span>
                </a>
            </div>
        </div>
    );
};

SummaryStopButton.propTypes = {
    summaryMessage: PropTypes.shape({
        files: PropTypes.array.isRequired, // TODO
        summaryOptions: PropTypes.object.isRequired,
        summaryResults: PropTypes.array.isRequired
    }).isRequired
};

SummaryStopButton.defaultProps = {};

export default SummaryStopButton;
