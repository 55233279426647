import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { IN_PROGRESS_RESULT_STATUS_LIST } from '../../../../../Common/SummaryConstant';

const ResultNavButtons = ({ summaryResult, summaryResultTotalCount, summaryResultSeq, onNavigate, intl }) => {
    const { resultStatus } = summaryResult;

    const handleClickPrevButton = () => {
        onNavigate(currentSeq => currentSeq - 1);
    };

    const handleClickNextButton = () => {
        onNavigate(currentSeq => currentSeq + 1);
    };

    return (
        <div className="ai-count-nav">
            <a
                className={classnames('btn-ic-nor', 'btn-sm', (summaryResultSeq === 1 || IN_PROGRESS_RESULT_STATUS_LIST.includes(resultStatus)) && 'disabled')}
                role="button"
                title={intl.formatMessage({ id: 'drive.button.copilot.summary.prev_text' })}
                onClick={handleClickPrevButton}>
                <i className="ic-12-copilot-arr-prev" />
            </a>
            <span className="count">
                {summaryResultSeq}
                {'/'}
                {summaryResultTotalCount}
            </span>
            <a
                className={classnames('btn-ic-nor', 'btn-sm', (summaryResultSeq === summaryResultTotalCount || IN_PROGRESS_RESULT_STATUS_LIST.includes(resultStatus)) && 'disabled')}
                role="button"
                title={intl.formatMessage({ id: 'drive.button.copilot.summary.next_text' })}
                onClick={handleClickNextButton}>
                <i className="ic-12-copilot-arr-next" />
            </a>
        </div>
    );
};

ResultNavButtons.propTypes = {
    summaryResult: PropTypes.object.isRequired,
    summaryResultTotalCount: PropTypes.number.isRequired,
    summaryResultSeq: PropTypes.number.isRequired,
    onNavigate: PropTypes.func.isRequired
};

ResultNavButtons.defaultProps = {};

export default injectIntl(ResultNavButtons);
