import React, { Component } from 'react';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import InLinkRest from '../../../../../apis/InLinkRest';
import { toast } from '../../../../../utils/ModalService';

class InLinkSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incomLinkUnautPermCd: ''
        };
    }

    componentDidMount() {
        // console.log('inLinkSetting componentDidMount ', this.props, this.state);
        const { incomLinkUnautPermCd } = this.props;
        this.setState({ incomLinkUnautPermCd });
    }

    setIncomLinkUnautPermCd() {
        const { files } = this.props;
        const { objtId, onpstId } = files[0].drive;
        const { incomLinkUnautPermCd } = this.state;
        const { close } = this.props;

        InLinkRest.setIncomLinkUnautPermCd(objtId, onpstId, incomLinkUnautPermCd).then(
            result => {
                console.log(result);
                close(incomLinkUnautPermCd);
            },
            error => {
                console.log(error);
                toast('drive.toast.linkCopy.noAuth');
            }
        );
    }

    chgIncomLinkUnautPermCd(e) {
        this.setState({ incomLinkUnautPermCd: e.target.value });
    }

    render() {
        const { incomLinkUnautPermCd } = this.state;
        const { close, webViewerYn } = this.props;

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.inLink.setting" />
                    </h5>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title="닫기"
                        data-dismiss="modal"
                        onClick={() => {
                            close();
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlink-set"
                                value="unauth"
                                checked={incomLinkUnautPermCd === 'unauth'}
                                onChange={e => {
                                    this.chgIncomLinkUnautPermCd(e);
                                }}
                            />
                            <span className="label-text">
                                <FormattedMessage id="drive.text.inLink.settingUnauth" />
                            </span>
                        </label>
                    </div>

                    <If condition={webViewerYn === 'Y'}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlink-set"
                                    value="view"
                                    checked={incomLinkUnautPermCd === 'view'}
                                    onChange={e => {
                                        this.chgIncomLinkUnautPermCd(e);
                                    }}
                                />
                                <span className="label-text">
                                    <FormattedMessage id="drive.text.inLink.settingView" />
                                </span>
                            </label>
                        </div>
                    </If>

                    {webViewerYn === 'Y' ? (
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlink-set"
                                    value="download"
                                    checked={incomLinkUnautPermCd === 'download'}
                                    onChange={e => {
                                        this.chgIncomLinkUnautPermCd(e);
                                    }}
                                />
                                <span className="label-text">
                                    <FormattedMessage id="drive.text.inLink.settingDownload" />
                                </span>
                            </label>
                        </div>
                    ) : (
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlink-set"
                                    value="download"
                                    checked={incomLinkUnautPermCd === 'download'}
                                    onChange={e => {
                                        this.chgIncomLinkUnautPermCd(e);
                                    }}
                                />
                                <span className="label-text">
                                    <FormattedMessage id="com.text.allUserDownload" />
                                </span>
                            </label>
                        </div>
                    )}
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a
                            className="btn btn-lg btn-secondary"
                            role="button"
                            data-dismiss="modal"
                            onClick={() => {
                                close();
                            }}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a
                            className="btn btn-lg btn-primary"
                            role="button"
                            onClick={e => {
                                this.setIncomLinkUnautPermCd();
                                e.stopPropagation();
                            }}>
                            <span className="btn-text">
                                <FormattedMessage id="com.save" />
                            </span>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

InLinkSetting.propTypes = {
    files: PropTypes.array,
    incomLinkUnautPermCd: PropTypes.string,
    webViewerYn: PropTypes.string,
    close: PropTypes.func
};

InLinkSetting.defaultProps = {
    files: [],
    incomLinkUnautPermCd: '',
    webViewerYn: 'N',
    close: () => {}
};

export default injectIntl(InLinkSetting);
