import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import store from '../../../redux/store';
import { ACTION_TYPE } from '../../../constants/DragDrop';

class DropToast extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dragActionType: '',
            dragItemIsOver: false,
            dragItemIsDroppable: true
        };
    }

    componentDidMount() {
        this.dragSubscribe();
    }

    componentWillUnmount() {
        if (this.dragUnsubscribe) this.dragUnsubscribe();
    }

    dragSubscribe = () => {
        this.dragUnsubscribe = store.subscribe(() => {
            const storeState = store.getState();
            const { dragDrop: dragDropState } = storeState;

            const { dragActionType, dragItemIsOver, dragItemIsDroppable } = this.state;
            const { isDragging = false, isDragOver = false, isDroppable = false, action = '' } = dragDropState;

            // DRAG 종료되면 안보이게 처리
            if (!isDragging) {
                this.setState({ dragItemIsOver: false });
                return;
            }

            if (dragActionType !== action || dragItemIsOver !== isDragOver || dragItemIsDroppable !== isDroppable) {
                this.setState({ dragActionType: action, dragItemIsOver: isDragOver, dragItemIsDroppable: isDroppable });
            }
        });
    };

    render() {
        const { dragActionType = ACTION_TYPE.MOVE, dragItemIsOver, dragItemIsDroppable } = this.state;

        let messageKey = '';
        // 업로드
        if (dragActionType === ACTION_TYPE.UPLOAD) {
            messageKey = dragItemIsDroppable ? 'drive.text.dropzone.drophere' : 'drive.guideText.noAuthFolder';
        }
        // 이동
        else {
            messageKey = dragItemIsDroppable ? 'drive.guideText.dragdrop.move' : 'drive.guideText.dragdrop.move.noAuth';
        }

        let icon = 'active';
        if (dragActionType === ACTION_TYPE.MOVE) {
            icon = 'dnd-active';
        }

        return (
            <div className={classnames('dnd-zone', icon, !dragItemIsDroppable && 'failure')} style={{ display: dragItemIsOver ? 'block' : 'none' }}>
                <span>
                    <FormattedMessage id={messageKey} />
                </span>
            </div>
        );
    }
}

export default DropToast;
