import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { summaryActions } from '../../../../../redux/actions/Summary';

const SummaryStopForNewDialog = ({ intl }) => {
    const { isOpen, files, summaryOptions } = useSelector(({ summary }) => summary.stopForNewDialog, shallowEqual);
    const selectedLLM = useSelector(({ summary }) => summary.global.selectedLLM);

    const dispatch = useDispatch();

    const handleClickCloseButton = () => {
        dispatch(summaryActions.stopForNewDialog.close());
    };

    const handleClickOkButton = () => {
        dispatch(summaryActions.stopForNewDialog.close());
        dispatch(summaryActions.thread.stopFetchAIMessage({}));
        dispatch(
            summaryActions.thread.generateUserMessage({
                summaryMessage: {
                    summaryOptions: { ...summaryOptions, ...selectedLLM },
                    files
                }
            })
        );
    };

    return (
        <div className={classnames('modal', 'fade', isOpen && 'show')} role="dialog" aria-hidden="true" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="drive.text.copilot.confirm_title" />
                        </h5>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close" />
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="box-text">
                            <FormattedHTMLMessage tagName="span" id="drive.confirm.copilot.summary.stop_for_other_summary" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-btns">
                            <a className="btn btn-md btn-secondary" role="button" data-dismiss="modal" onClick={handleClickCloseButton}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.cancel" />
                                </span>
                            </a>
                            <a className="btn btn-md btn-primary" role="button" data-dismiss="modal" onClick={handleClickOkButton}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.button.ok" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SummaryStopForNewDialog.propTypes = {};

SummaryStopForNewDialog.defaultProps = {};

export default injectIntl(SummaryStopForNewDialog);
