import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Choose, If, Otherwise, When } from 'jsx-control-statements';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ClickOutside from '../../../../presentationals/ClickOutside';
import { SUMMARY_FILE_LIST_TYPE } from '../../Common/SummaryConstant';
import FoldersRest from '../../../../../apis/FoldersRest';
import FileItem from './FileItem';
import { getSummarizableExtensionListFromConfigs, isSummarizableExtension, toCompactedFileInfo } from '../../Common/Util/SummaryUtil';
import { summaryActions } from '../../../../../redux/actions/Summary';
import SearchRest from '../../../../../apis/SearchRest';

const FileListBox = ({ intl }) => {
    const fileListBoxRef = useRef();

    const [latestSortValues, setLatestSortValues] = useState();
    const [fileList, setFileList] = useState([]);

    const configs = useSelector(({ config }) => config.configs);
    const { isOpen, fileListType, searchedFileName, fileInputIndex, fileInputRef } = useSelector(({ summary }) => summary.fileListBox, shallowEqual);
    const dispatch = useDispatch();

    const summarizableExtensionList = useMemo(() => {
        return getSummarizableExtensionListFromConfigs(configs);
    }, [configs]);

    const retrieveRecentFiles = async () => {
        // TODO API 수정 혹은 추가 개발
        // 확장자 config 값에 있는 파일만 필터링
        const response = await FoldersRest.getPersonalFolders('used', { withAuthorizedPath: true });

        if (_.isNil(response)) {
            return;
        }

        const { list } = response;

        if (_.isEmpty(list)) {
            setFileList([]);
            return;
        }

        const filteredList = list.filter(file => {
            const { extensionName } = toCompactedFileInfo(file);
            return isSummarizableExtension(configs, extensionName);
        });

        setFileList(filteredList);
    };

    const searchByFileName = async more => {
        const requestParams = {
            q: searchedFileName,
            extensions: summarizableExtensionList.join(','),
            time: Date.now(),
            size: 10,
            sortField: 'score',
            sortOrder: 'DESC',
            trackTotal: true
        };

        if (more) {
            requestParams.searchAfter = latestSortValues;
        }

        const response = await SearchRest.integrated(requestParams);

        if (_.isNil(response)) {
            setFileList([]);
            return;
        }

        const { list } = response;

        if (_.isEmpty(list)) {
            if (more) {
                return;
            }

            setFileList([]);
            return;
        }

        const { extra: { sortValues } = {} } = _.last(list);

        setLatestSortValues(sortValues);
        setFileList(prevList => (more ? [...prevList, ...list] : list));
    };

    const handleClickOutside = () => {
        if (!isOpen) {
            return;
        }

        dispatch(summaryActions.fileListBox.close());
    };

    const handleScroll = async e => {
        if (fileListType !== SUMMARY_FILE_LIST_TYPE.searchByFileName) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = e.target;

        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
            await searchByFileName(true);
        }
    };

    useEffect(() => {
        (async () => {
            setFileList([]);

            if (!isOpen) {
                return;
            }

            switch (fileListType) {
                case SUMMARY_FILE_LIST_TYPE.retrieveRecentFiles:
                    await retrieveRecentFiles();
                    return;
                case SUMMARY_FILE_LIST_TYPE.searchByFileName:
                    await searchByFileName();
                    return;
                default:
                    console.error('Unknown file list type');
            }
        })();
    }, [searchedFileName, fileListType, fileInputRef, isOpen]);

    return (
        <ClickOutside onClickOutside={handleClickOutside} childrenRefs={[fileListBoxRef, fileInputRef]}>
            <div className="fixed-layer-wrapper" style={{ display: isOpen ? 'block' : 'none' }} ref={fileListBoxRef}>
                <div className="fixed-layer">
                    <div className="instant-srch">
                        <div className="instant-srch-header">
                            <h5 className="instant-srch-title">
                                {intl.formatMessage({
                                    id:
                                        fileListType === SUMMARY_FILE_LIST_TYPE.retrieveRecentFiles
                                            ? 'drive.text.copilot.summary.fileListBox.recent'
                                            : 'drive.text.copilot.summary.fileListBox.searchResult'
                                })}
                            </h5>
                        </div>
                        <div className="scroll" onScroll={handleScroll}>
                            <div>
                                <ul className="instant-srch-list subject-file row-5">
                                    <Choose>
                                        <When condition={_.isEmpty(fileList)}>
                                            <li className="empty">
                                                <div className="box-empty">
                                                    <div className="message">
                                                        <strong>
                                                            <FormattedMessage id="drive.text.copilot.summary.fileListBox.empty" />
                                                        </strong>
                                                        <If condition={fileListType === SUMMARY_FILE_LIST_TYPE.retrieveRecentFiles}>
                                                            <p>
                                                                <FormattedMessage id="drive.text.copilot.summary.fileListBox.recentIsHere" />
                                                            </p>
                                                        </If>
                                                    </div>
                                                </div>
                                            </li>
                                        </When>
                                        <Otherwise>
                                            {fileList.map(file => {
                                                const {
                                                    drive: { objtId }
                                                } = file;

                                                return <FileItem searchedFileName={searchedFileName} file={file} fileInputIndex={fileInputIndex} key={objtId} />;
                                            })}
                                        </Otherwise>
                                    </Choose>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ClickOutside>
    );
};

FileListBox.propTypes = {};

FileListBox.defaultProps = {};

export default injectIntl(FileListBox);
