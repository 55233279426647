import AddPreferredFolder from './AddPreferredFolder';
import { toast } from '../../../../utils/ModalService';
import FoldersRest from '../../../../apis/FoldersRest';

const executor = (props, files, e) => {
    e.stopPropagation();
    FoldersRest.addPreferFolders(files).then(({ data }) => {
        // const { drive } = files[0];
        if (data) {
            toast('drive.toast.favor.added');
            props.callback({ reloadPrefer: true });
        }
    });
};
class AddPreferredFolderRow extends AddPreferredFolder {
    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'btn-ic-nor btn-func-fav', 'ic-20-fav-add', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default AddPreferredFolderRow;
