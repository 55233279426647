import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import ReqRest from '../../../../apis/ReqRest';
import { alert as modalAlert } from '../../../../utils/ModalService';

const executor = (props, files) => {
    const reqPrcsList = [];
    reqPrcsList.push(files[0].reqId);

    ReqRest.updateReqProcess({ reqPrcsType: 'APPROVAL', reqPrcsList }).then(res => {
        if (res.resultCode === 500) {
            modalAlert('com.alert.internalServerError');
        } else if (res.resultCode === 400) {
            modalAlert('com.alert.reqInvalidParam');
        } else if (res.resultCode === 403 && !_.isEmpty(res.data)) {
            modalAlert(res.data);
        } else {
            modalAlert(res.message);
        }
        props.callback();
    });
};

class ApproveRequest extends BaseMenu {
    getName() {
        return 'approveRequest';
    }

    getLabel() {
        return 'com.button.approval';
    }

    isVisible() {
        return true;
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-approved', executor);
    }

    // getIconDom() {
    //     return this.getBaseIconDom(this.getName(), null, 'ic-20-extend', executor);
    // }

    execute() {
        executor(this.props, this.files);
    }
}

export default ApproveRequest;
