import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Checky = props => {
    const { checked, onChange, nameKey, disabled } = props;

    return (
        <div className="form-check">
            <label className="form-check-label">
                <input className="form-check-input" type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
                <span className="label-text">
                    <FormattedMessage id={nameKey} />
                </span>
            </label>
        </div>
    );
};

Checky.propTypes = {
    checked: bool.isRequired,
    onChange: func.isRequired,
    nameKey: string.isRequired,
    disabled: bool.isRequired
};

export default Checky;
