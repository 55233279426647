import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        toast: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    // draft.isOpen = true;
                    draft.toasts.push(payload.toast);
                });
            },
            close: (state, { payload }) => {
                return produce(state, draft => {
                    draft.toasts = draft.toasts.filter(({ id }) => id !== payload.id);
                    // draft.isOpen = false;
                });
            }
        }
    },
    { toasts: [], isOpen: false, content: {} },
    { prefix: 'summary' }
);
