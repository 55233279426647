import _ from 'lodash';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import { Canvas, CropMenu, Darkroom, History, Toolbar } from 'react-darkroom';
import { Transform } from 'react-darkroom/lib/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { globalPopup } from '../../redux/actions';

const VOLUMN_UNIT = 1024;
const canvasWidth = 432;
const canvasHeight = 296;
const EMPTY_BASE64 = 'data:image/png;base64,';

function fileController(thread = { source: null }, action) {
    switch (action.type) {
        case 'SET_FILE':
            return Object.assign({}, thread, {
                source: action.file,
                angle: 0
            });
        default:
            return thread;
    }
}
function imageController(thread = { crop: false, source: null, angle: 0 }, action) {
    switch (action.type) {
        case 'ROTATE_LEFT':
            return Object.assign({}, thread, {
                angle: thread.angle - 90,
                source: action.image
            });
        case 'ROTATE_RIGHT':
            return Object.assign({}, thread, {
                angle: thread.angle + 90,
                source: action.image
            });
        case 'START_CROPPING':
            return Object.assign({}, thread, {
                crop: true
            });
        case 'STOP_CROPPING':
            return Object.assign({}, thread, {
                crop: false
            });
        case 'CONFIRM_CROP':
            return Object.assign({}, thread, {
                crop: false,
                source: action.image
            });
        default:
            return thread;
    }
}

/**
 * Darkroom API methods
 *
 * Set image/width/height
 * Set Rotation
 * Use React.Children.foreach can iterate through each item
 * for a declarative API
 * Image rotation -> Should that be state?  Or should we set that
 * independantly and allow for image rotation to be easily set?
 *
 * Controls to be a separate and optional component for maximum
 * re-usability.
 */

class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { step: 0, thread: [{ crop: false, source: props.source === '' ? null : props.source, angle: 0 }], agreement: false };
        ['onFileChange', 'deleteFile', 'update', 'onRedo', 'onUndo', 'onRotateLeft', 'onCropStart', 'onCropConfirm', 'onCropCancel', 'onRotateRight'].forEach(method => {
            this[method] = this[method].bind(this);
        });
    }

    componentDidMount() {
        this.canvasWrapperRef.refs.canvas.parentElement.style.width = '';
        this.canvasWrapperRef.refs.canvas.parentElement.style.height = '';
    }

    componentWillUnmount() {
        if (this.cropBoxInterval !== null) {
            clearInterval(this.cropBoxInterval);
            this.cropBoxInterval = null;
        }
    }

    update(action) {
        const { thread, step } = this.state;
        let nextThread;
        let nextStep = thread.length;
        let newThread;

        switch (action.type) {
            case 'SET_FILE':
                nextThread = fileController(thread[step], action);
                break;
            case 'UNDO':
                nextStep = step - 1;
                break;
            case 'REDO':
                nextStep = step + 1;
                break;
            case 'ROTATE_LEFT':
            case 'ROTATE_RIGHT':
            case 'START_CROPPING':
            case 'STOP_CROPPING':
            case 'CONFIRM_CROP':
                nextThread = imageController(thread[step], action);
                break;
            default:
                break;
        }

        if (action.type !== 'UNDO' && action.type !== 'REDO' && (step > 0 && step < thread.length - 1)) {
            newThread = [...thread.slice(0, step), nextThread];
            nextStep = newThread.length - 1;
        } else {
            newThread = nextThread ? [...thread, nextThread] : [].concat(thread);
        }

        const newState = Object.assign({}, this.state, {
            step: nextStep,
            thread: newThread
        });
        this.setState(newState);

        if (action.type === 'START_CROPPING') {
            this.cropBoxInterval = setInterval(() => {
                if (this.canvasWrapperRef && this.canvasWrapperRef.cropBox) {
                    const { width, height } = this.canvasWrapperRef.cropBox;
                    if (width === height) return;
                    const maxVal = Math.max(width, height);
                    this.canvasWrapperRef.cropBox.setWidth(maxVal);
                    this.canvasWrapperRef.cropBox.setHeight(maxVal);
                }
            }, 0);
        } else if (action.type === 'STOP_CROPPING' || action.type === 'CONFIRM_CROP') {
            clearInterval(this.cropBoxInterval);
            this.cropBoxInterval = null;
        }
    }

    readFile = (file, done) => {
        const { openAlert } = this.props;
        const { name, size } = file;
        let fileExtension = '';
        if (name.lastIndexOf('.') !== -1) {
            fileExtension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        }

        if (fileExtension !== 'jpg' && fileExtension !== 'png') {
            openAlert({ id: 'user.setting.profile.guide.allowedExtensions' });
            return;
        }

        if (size > 2 * VOLUMN_UNIT * VOLUMN_UNIT) {
            openAlert({ id: 'user.alert.setting.profile.volumn.limit' });
            return;
        }

        const reader = new FileReader();
        reader.onload = e => done(e.target.result);
        reader.readAsDataURL(file);
    };

    onFileChange(e) {
        const inputTag = e.target;

        this.readFile(e.target.files[0], file => {
            const tmpImg = new Image();
            tmpImg.onerror = () => {
                const { onNotImageFile } = this.props;
                onNotImageFile();
                inputTag.value = '';
            };

            tmpImg.onload = () => {
                this.update({ type: 'SET_FILE', file });
            };

            tmpImg.src = file;
        });
    }

    onUndo() {
        this.update({ type: 'UNDO' });
    }

    onRedo() {
        this.update({ type: 'REDO' });
    }

    processRotate = type => {
        const { thread, step } = this.state;
        const { source } = thread[step];
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.height;
            canvas.height = image.width;

            if (type === 'ROTATE_LEFT') {
                ctx.rotate((-90 * Math.PI) / 180);
                ctx.translate(-canvas.height, 0);
            } else if (type === 'ROTATE_RIGHT') {
                ctx.rotate((90 * Math.PI) / 180);
                ctx.translate(0, -canvas.width);
            }
            ctx.drawImage(image, 0, 0);
            this.update({ type, image: canvas.toDataURL('image/png', 1.0) });
        };
        image.src = source;
    };

    onRotateLeft() {
        this.processRotate('ROTATE_LEFT');
    }

    onRotateRight() {
        this.processRotate('ROTATE_RIGHT');
    }

    onCropStart() {
        this.update({ type: 'START_CROPPING' });
    }

    onCropCancel() {
        this.update({ type: 'STOP_CROPPING' });
    }

    cropImage = (imageBase64, cropRect, boundRect) => {
        return new Promise(resolve => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const imageObj = new Image();
            imageObj.onload = () => {
                const scaleFactorWidth = imageObj.width / canvasWidth;
                const scaleFactorHeight = imageObj.height / canvasHeight;
                const scaleFactor = Math.max(scaleFactorWidth, scaleFactorHeight);
                canvas.width = boundRect.width * scaleFactor;
                canvas.height = boundRect.height * scaleFactor;
                ctx.scale(scaleFactor, scaleFactor);

                Transform.rotateImage(ctx, 0);
                Transform.renderCentered(ctx, imageObj, imageObj, boundRect);

                const scaledImage = new Image();
                scaledImage.onload = () => {
                    const croppedCanvas = document.createElement('canvas');
                    const croppedCtx = croppedCanvas.getContext('2d');

                    croppedCanvas.width = 140;
                    croppedCanvas.height = 140;

                    croppedCtx.drawImage(scaledImage, cropRect.x * scaleFactor, cropRect.y * scaleFactor, cropRect.width * scaleFactor, cropRect.height * scaleFactor, 0, 0, 140, 140);

                    resolve(croppedCanvas.toDataURL('image/png', 1.0));
                };
                scaledImage.src = canvas.toDataURL('image/png', 1.0);
            };
            imageObj.src = imageBase64;
        });
    };

    onCropConfirm() {
        const { thread, step } = this.state;
        const { source } = thread[step];
        const { x, y, width, height, hasFocus } = this.canvasWrapperRef.cropBox;

        if (hasFocus === undefined || !hasFocus) {
            return;
        }

        this.cropImage(source, { x, y, width, height }, { width: canvasWidth, height: canvasHeight }).then(image => this.update({ type: 'CONFIRM_CROP', image }));
    }

    setFileselectRef = ref => {
        this.fileselectRef = ref;
    };

    setCanvasWrapperRef = ref => {
        this.canvasWrapperRef = ref;
    };

    selectFile = () => {
        this.fileselectRef.click();
    };

    deleteFile() {
        this.fileselectRef.value = '';
        this.update({ type: 'SET_FILE', file: EMPTY_BASE64 });
    }

    onChangeAgreement = event => {
        this.setState({ agreement: event.target.checked });
    };

    handleCancel = () => {
        const { cancel } = this.props;
        cancel();
    };

    handleSave = () => {
        const { agreement } = this.state;
        if (!agreement) return;

        const { save } = this.props;
        const { thread, step } = this.state;
        const current = thread[step];
        const { source } = current;

        const image = new Image();
        image.onload = () => {
            save(source);
        };
        image.onerror = () => {
            save(EMPTY_BASE64);
        };
        image.src = source;
    };

    render() {
        const { userId, userName, intl, profileFileId } = this.props;
        const { thread, step, agreement } = this.state;
        const current = thread[step];
        const { source, crop } = current;
        const hasFile = source !== null && source !== EMPTY_BASE64;
        const isSaveAble = agreement && (hasFile || (!hasFile && !_.isEmpty(profileFileId)));

        const lastNumberOfUserId = userId.substring(userId.length - 1, userId.length);

        const backgroundColorClass = 'p-type0'.concat((lastNumberOfUserId % 6) + 1);

        return (
            <>
                <Darkroom>
                    <Toolbar>
                        <History step={step} length={thread.length - 1}>
                            <button type="button" action="back" onClick={this.onUndo} data-ifEmpty="disable" data-tipsy="Undo" className="tipsy tipsy--sw">
                                <span className="icon icon-undo2" />
                            </button>
                            <button type="button" action="forward" onClick={this.onRedo} data-ifEmpty="disable" data-tipsy="Redo" className="tipsy tipsy--sw">
                                <span className="icon icon-redo2" />
                            </button>
                        </History>
                        <button type="button" disabled={!hasFile} onClick={this.onRotateLeft} data-tipsy="Rotate Left" className="tipsy tipsy--sw">
                            <span className="icon icon-undo" />
                        </button>
                        <button type="button" disabled={!hasFile} onClick={this.onRotateRight} data-tipsy="Rotate Right" className="tipsy tipsy--sw">
                            <span className="icon icon-redo" />
                        </button>
                        <CropMenu isCropping={crop}>
                            <button type="button" disabled={!hasFile} data-showOnlyWhen="croppingIsOff" onClick={this.onCropStart} data-tipsy="Crop" className="tipsy tipsy--sw">
                                <span className="icon icon-crop" />
                            </button>
                            <button type="button" className="active" disabled={!hasFile} data-showOnlyWhen="croppingIsOn" style={{ color: 'cyan' }}>
                                <span className="icon icon-crop" />
                            </button>
                            <button
                                type="button"
                                disabled={!hasFile}
                                data-showOnlyWhen="croppingIsOn"
                                onClick={this.onCropConfirm}
                                style={{ color: 'green' }}
                                data-tipsy="Confirm"
                                className="tipsy tipsy--sw">
                                <span className="icon icon-checkmark" />
                            </button>
                            <button
                                type="button"
                                disabled={!hasFile}
                                data-showOnlyWhen="croppingIsOn"
                                onClick={this.onCropCancel}
                                style={{ color: 'red' }}
                                data-tipsy="Cancel"
                                className="tipsy tipsy--sw">
                                <span className="icon icon-cross" />
                            </button>
                        </CropMenu>
                    </Toolbar>
                    <Canvas ref={this.setCanvasWrapperRef} crop={crop} source={source} angle={0} width={canvasWidth} height={canvasHeight} style={{ display: 'block', cursor: 'crosshair' }} />
                    <menu>
                        <ul>
                            <li>
                                <button type="button" title={intl.formatMessage({ id: 'user.title.setting.profile.change.select.image' })} onClick={this.selectFile}>
                                    <span className="icon icon-image" />
                                    <input type="file" accept=".jpg, .png" ref={this.setFileselectRef} onChange={this.onFileChange} style={{ display: 'none' }} />
                                </button>
                            </li>

                            <li>
                                <button type="button" title={intl.formatMessage({ id: 'user.title.setting.profile.change.delete' })} disabled={!hasFile} onClick={this.deleteFile}>
                                    <span className="icon icon-delete" />
                                </button>
                            </li>
                        </ul>
                    </menu>
                </Darkroom>
                <div className="darkroom-preview">
                    <div className="preview">
                        <h3>
                            <FormattedMessage id="user.setting.profile.preview" />
                        </h3>
                        <div className="thumbnail">
                            <span className={`user-thumbnail-bg ${backgroundColorClass}`}>
                                <If condition={hasFile}>
                                    <img src={source} className="profile-img" alt={intl.formatMessage({ id: 'user.title.profile.image.alt' })} />
                                </If>
                                <If condition={!hasFile}>
                                    <i>{userName.substring(0, 1)}</i>
                                </If>
                            </span>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" checked={agreement} onChange={this.onChangeAgreement} />
                                <span className="label-text">
                                    <FormattedMessage id="user.setting.profile.guide.registerPhoto" />
                                </span>
                            </label>
                        </div>

                        <div className="info-msg">
                            <i className="ic-16-info" />
                            <FormattedMessage id="user.setting.profile.guide.registrationPolicy" />
                        </div>
                        <div className="info-msg">
                            <i className="ic-16-info" />
                            <FormattedMessage id="user.setting.profile.guide.allowedExtensions" />
                        </div>
                    </div>

                    <div className="btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={this.handleCancel}>
                            <span role="button" className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className={isSaveAble ? 'btn btn-lg btn-primary' : 'btn btn-lg btn-primary disabled'} role="button" onClick={this.handleSave}>
                            <span role="button" className="btn-text">
                                <FormattedMessage id="com.save" />
                            </span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

ProfileEdit.propTypes = {
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    onNotImageFile: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired,
    profileFileId: PropTypes.string.isRequired
};

const mapDispatchToProps = {
    openAlert: globalPopup.openAlert
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(ProfileEdit)
);
