import React, { memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationDialog from './NotificationDialog';
import { summaryActions } from '../../../../redux/actions/Summary';

const CopilotGnbButton = memo(({ clickOutSideRef }) => {
    const { isSidePanelOpen, isNotificationDialogOpen, isOptionSelectDialogOpen } = useSelector(({ summary }) => {
        return {
            isSidePanelOpen: summary.sidePanel.isOpen,
            isNotificationDialogOpen: summary.notificationDialog.isOpen,
            isOptionSelectDialogOpen: summary.optionSelectDialog.isOpen
        };
    }, shallowEqual);
    const selectedFileCount = useSelector(({ summary }) => summary.global.selectedFileCount);

    const dispatch = useDispatch();

    const handleClick = () => {
        if (isNotificationDialogOpen) dispatch(summaryActions.notificationDialog.close());
        if (isOptionSelectDialogOpen) dispatch(summaryActions.optionSelectDialog.close());

        if (isSidePanelOpen) {
            dispatch(summaryActions.sidePanel.close());
            return;
        }

        dispatch(summaryActions.sidePanel.open());
    };

    return (
        <div className="nav-copilot" ref={clickOutSideRef}>
            <a className="btn-ic-nor btn-func-copilot" role="button" title="Copilot" onClick={handleClick}>
                <i className="ic-24-copilot" />
                {selectedFileCount === 1 && <em className="summary-file-badge" />}
            </a>
            <NotificationDialog />
        </div>
    );
});

CopilotGnbButton.propTypes = {
    clickOutSideRef: PropTypes.object.isRequired
};

CopilotGnbButton.defaultProps = {};

export default CopilotGnbButton;
