import { $http } from 'modules/index';

const prefix = '/drive/v1/files/count';

class FilesCountRest {
    static getOwnFilesCount() {
        return $http.get(`${prefix}?category=own`);
    }

    static getDuplicatedFilesCount() {
        return $http.get(`${prefix}?category=duplicated`);
    }

    static getSimilarFilesCount(objtNm) {
        return $http.get(`${prefix}?category=similar&objtNm=${objtNm}`);
    }
}

export default FilesCountRest;
