import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

const DeleteUser = props => {
    const {
        file: { drive },
        cellStyle
    } = props;

    const { objtDelUserNm } = drive;
    const deleteUserName = isNil(objtDelUserNm) ? '-' : objtDelUserNm;
    return (
        <div className="grid-cell files-deleteer-cell" style={cellStyle}>
            <div className="ellipsis comma" title={deleteUserName}>
                {deleteUserName}
            </div>
        </div>
    );
};

DeleteUser.defaultProps = {
    cellStyle: null
};

DeleteUser.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default DeleteUser;
