import ActionType from '../actions/FileReload';

const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionType.EXECUTE:
            return Object.assign({}, state, {
                type: action.type,
                data: action.data
            });
        case ActionType.SET_FOLDER_INFORMATION:
            return Object.assign({}, state, {
                type: action.type,
                folder: action.folder
            });
        default:
            return state;
    }
};
