import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

const LoginProcessing = ({ msgKey }) => {
    return (
        <div className="com-saml-wrapper">
            <div className="com-saml">
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>

                <div className="saml-guide">
                    <FormattedHTMLMessage id={msgKey} tagName="div" />
                </div>
            </div>
        </div>
    );
};

LoginProcessing.propTypes = {
    msgKey: PropTypes.string.isRequired
};

export default LoginProcessing;
