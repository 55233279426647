import _ from 'lodash';
import store from 'src/redux/store';
// import cookie from './Cookie';

class AuthService {
    // 사용자 인증 토큰 tuid
    setAuthToken = authToken => {
        sessionStorage.setItem('authToken', authToken);
    };

    // 인증 시그니처 암호화정보 chip
    setSignatureKey = signatureKey => {
        sessionStorage.setItem('signatureKey', signatureKey);
    };

    // 인증 시그니처 암호화정보 chip
    setUserId = userId => {
        localStorage.setItem('userId', userId);
    };

    getAuthTokenAndSignKey = () => {
        return {
            authToken: sessionStorage.getItem('authToken'),
            signatureKey: sessionStorage.getItem('signatureKey')
        };
    };

    removeAuthTokenAndSignKey = () => {
        localStorage.removeItem('search');
        localStorage.removeItem('pathname');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('signatureKey');

        // 요약 관련 sessionStorage clear
        Object.keys(sessionStorage).forEach(key => {
            if (!key.startsWith('summary/')) {
                return;
            }

            sessionStorage.removeItem(key);
        });
    };

    // 사용자 인증 토큰 tuid
    getAuthToken = () => {
        return sessionStorage.getItem('authToken');
    };

    // 인증 시그니처 암호화정보 chip
    getSignatureKey = () => {
        return sessionStorage.getItem('signatureKey');
    };

    getPathname = () => {
        return localStorage.getItem('pathname');
    };

    getUserId = () => {
        return localStorage.getItem('userId');
    };

    getPathnameAndSearch = () => {
        return {
            pathname: localStorage.getItem('pathname'),
            search: localStorage.getItem('search')
        };
    };

    setPathnameAndSearch = (pathname, search) => {
        localStorage.setItem('pathname', pathname);
        localStorage.setItem('search', search);
    };

    removePathnameAndSearch = () => {
        localStorage.removeItem('pathname');
        localStorage.removeItem('search');
    };

    removeUserId = () => {
        localStorage.removeItem('userId');
    };

    // store에 있는 로그인한 사용자 정보 조회
    getLoginUser = () => {
        const snapshot = store.getState();
        return _.get(snapshot, 'auth.user', {});
    };
}

export default new AuthService();
