import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import Request from '../../../../constants/Request';
import RequestPopup from '../../../containers/Popup/RequestPopup';
import FileReload from '../../../../utils/FileReload';

// 만료파일은 나중에 다시 연장신청 할수 있어서 처리중인것만 아니면, 신청 가능하도록 처리.
const CANT_REQ_STATE_CODES = ['PROCESSING'];

const executor = (props, files) => {
    RequestPopup.open({
        mode: Request.MODE.REQUEST,
        type: Request.TYPE.EXTENDS_EXPIRATION,
        target: files
    }).then(
        () => {
            // props.callback(result);
            FileReload.call(null, files, { reloadType: 'cached', reloadReq: true });
        },
        () => {}
    );
};

class ExpirationExtension extends BaseMenu {
    getName() {
        return 'expirationExtension';
    }

    getLabel() {
        return 'drive.button.list.menu.extend';
    }

    isVisible() {
        const {
            custom: { userConfig: { userCstdMap: { cstdChangeRequestPossibleYn = 'N' } = {} } = {} }
        } = this.props;
        if (cstdChangeRequestPossibleYn !== 'Y') return false;
        return (
            super.isVisible(true, null, 'FILE') &&
            this.files.every(file => {
                if (_.isEmpty(file) || _.isEmpty(file.config) || _.isEmpty(file.config.drive) || file.config.cstdChangeRequestPossibleYn === 'N') return false;
                const { expirationRequestStateCode = '' } = file.config.drive;
                const { extra: { req: { reqStatCd = '' } = {} } = {} } = file;
                const requestStateCode = reqStatCd !== '' ? reqStatCd : expirationRequestStateCode;
                return CANT_REQ_STATE_CODES.indexOf(requestStateCode) === -1;
            })
        );
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-extend', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-extend', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default ExpirationExtension;
