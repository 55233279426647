import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { LINE_USER_CLASS_NAME, LINE_USER_LANG_ID } from './ApprovalLine';
import OneUserSearcherInput from '../../OneUserSearcherInput';
import ApprovalLineGuide from './ApprovalLineGuide';
import UserRest from '../../../../apis/UserRest';
import { alert } from '../../../../utils/ModalService';

const ApprovalLineUserInput = props => {
    const { intl, typeClass, onSelect, onClear, type, pIndex, rIndex, guideText } = props;

    const [isOpenGuide, setIsOpenGuide] = useState(false);
    const [userId, setUserId] = useState('');
    const [isInvalid, setIsInvalid] = useState(typeClass.hasNotAuthLineByIdx(pIndex, rIndex));

    const typeClassName = `appr-step${LINE_USER_CLASS_NAME[type]}`;
    const typeLangId = `com.text.approval.line.type.${LINE_USER_LANG_ID[type]}`;

    const rowClass = `grid-row${isInvalid ? ' err-row' : ''}`;

    const isViewMode = typeClass.isViewMode();

    const openGuideText = () => {
        setIsOpenGuide(true);
    };

    const closeGuideText = () => {
        setIsOpenGuide(false);
    };

    const isUserIf = async id => {
        const param = {
            userId: id,
            userIfYn: 'Y'
        };
        const res = await UserRest.getUser(param);
        const { resultCode, data = [] } = res;
        if (resultCode !== 200) {
            alert('com.alert.internalServerError');
        }
        return data.length > 0 && data[0].userId === id && data[0].userIfYn === 'Y';
    };

    const onSelectUser = async user => {
        setUserId(user.id);
        let isValidUser = false;

        // 사용자 구분 별로 연계 여부 확인
        if (user.userSectCd === 'I') {
            isValidUser = await isUserIf(user.id);
        }

        onSelect({
            userId: user.id,
            userNm: user.name,
            deptNm: user.deptNm,
            opinion: '',
            userCopNm: '',
            email: user.userLoginId,
            isValid: isValidUser
        });
        setIsInvalid(!isValidUser);
    };

    const onRemoveUser = () => {
        setUserId('');
        setIsInvalid(true);
        onClear();
        console.log('remove user', userId.substring(userId.length - 1));
    };

    return (
        <div className={rowClass}>
            {isViewMode && <div className="grid-cell appr-process-cell" />}

            <div className="grid-cell appr-num-cell">{pIndex}</div>
            <div className="grid-cell appr-division-cell">
                <span className={typeClassName}>{intl.formatMessage({ id: typeLangId })}</span>
            </div>
            <div className="grid-cell appr-line-cell">
                <div className="forms-container">
                    <OneUserSearcherInput select={onSelectUser} remove={onRemoveUser} placeholder="com.guideText.approval.line.inputUser" />
                    {guideText && (
                        <>
                            <a className="btn btn-md btn-secondary" role="button" onClick={openGuideText}>
                                <span className="btn-text">{intl.formatMessage({ id: 'com.text.sancPath.guide' })}</span>
                            </a>
                            {isOpenGuide && <ApprovalLineGuide guideText={guideText} close={closeGuideText} />}
                        </>
                    )}
                </div>
            </div>

            {isViewMode && <div className="grid-cell appr-processTime-cell" />}
        </div>
    );
};

ApprovalLineUserInput.propTypes = {
    typeClass: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    pIndex: PropTypes.string.isRequired,
    rIndex: PropTypes.number.isRequired,
    guideText: PropTypes.string
};

ApprovalLineUserInput.defaultProps = {
    guideText: undefined
};

export default injectIntl(ApprovalLineUserInput);
