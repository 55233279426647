/* eslint-disable */
import React, { Component } from 'react';
import DriveWebSocket from '../../../utils/DriveWebSocket';
import DownloadRest from '../../../apis/DownloadRest';
import AuthService from '../../../utils/AuthService';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { alert, confirm, largePopup } from '../../../utils/ModalService';
import HelpRest from '../../../apis/HelpRest';
import AgentDownload from '../Download/AgentDownload';
import DriveConfigRest from "../../../apis/DriveConfigRest";
import DriveConfigs from "../../../constants/DriveConfigs";

class FileOpen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {}
        };
    }

    componentDidMount() {
        // Windows OS에서만 파일 실행 기능 지원
        if (navigator.platform.indexOf('Win') === -1) {
            const osLimitConfig = {
                message: <FormattedMessage id="user.text.helpcenter.manualDownload.unsupportedOS" />
            };
            alert(osLimitConfig);
            this.props.close();
            return ;
        }

        this.fileOpen(() => {}).catch();
    }

    async fileOpen() {
        const { file } = this.props;
        const loginUser = AuthService.getLoginUser();

        // console.log("this.props: ", this.props);
        // console.log("fileOpen file param: ", file);

        let tenantId = loginUser.tenantId;
        let loginId = loginUser.loginId;
        let loginParam = [];
        let fileOpenParam = [];

        const agentType = await DriveConfigRest.getConfig(DriveConfigs.AGENT_TYPE);
        console.log('## ## agentType 1 ', agentType);

        if (agentType !== 'S') {
            console.log('## ## A');
            loginParam.push(tenantId);
            loginParam.push(loginId); // 로그인 유저의 로그인 아이디 (ex: khw.kwon@samsung.com)
            loginParam.push('Login');
            loginParam.push('NONE');
        } else {
            console.log('## ## B');
            loginParam.push('login');
            loginParam.push(tenantId);
            loginParam.push(loginId);
        }

        console.log('## ## agentType 2-1 ', agentType);

        if (this.websocketResultCheck('Login', agentType, await DriveWebSocket(loginParam, agentType, 'Login'))) {
            try {
                let result = await DownloadRest.downloadFilePath(file);
                console.log('downloadFilePath result: ', result);

                let expPathNm = result[0].explorerPathNm;

                if (agentType !== 'S') {
                    console.log('## ## AA');
                    fileOpenParam.push(tenantId);
                    fileOpenParam.push(file.onpstId);
                    fileOpenParam.push('Open');
                    fileOpenParam.push(expPathNm);
                } else {
                    console.log('## ## BB');
                    fileOpenParam.push('open');
                    fileOpenParam.push(expPathNm);
                }

            } catch (e) {
                console.log('downloadFilePath error: ', e);

                const delFileDownConfig = {
                    message: <FormattedMessage id="drive.alert.viewer.cannotOpen" />
                };
                alert(delFileDownConfig).then(() => {
                    this.props.close();
                });
            }

            console.log('## ## agentType 2-2 ', agentType);

            if (this.websocketResultCheck('Open', agentType, await DriveWebSocket(fileOpenParam, agentType, 'Open'))) {
                console.log('drive websocket call success.(login, open)');
            }
        }

        await this.props.close();
    }

    websocketResultCheck(type, agentType, code) {
        console.log('websocket type: ', type);
        console.log('websocket result: ', code);
        console.log('## ## agentType 3 ', agentType);

        const { intl } = this.props;

        const renderLine = line => {
            return (<span>{line}<br /></span>);
        };

        if (!code) {
            if (agentType !== 'S') {
                console.log('## ## 1');
                // Websocket 연결이 되어있지 않으면 socket에서 false리턴
                // alert(intl.formatHTMLMessage({ id: 'drive.alert.download.agentSockError' }));
                const connFailMsg = intl.formatMessage({ id: 'drive.alert.download.agentSockError' });
                const connFailConfig = {
                    message: connFailMsg.split('\\n').map(renderLine),
                    btnMessage: { okay: { id: 'com.button.ok' }, cancel: { id: 'drive.button.download.installAgentBtn' } }
                };
                console.log('## ## 1-1');
                confirm(connFailConfig)
                    .then(
                        () => {},
                        () => {
                            // let newPageUrl = Url.agentDown;
                            // window.open(newPageUrl, '_blank');
                            largePopup(<AgentDownload isClose={true} />);
                        }
                    )
                    .then(() => {
                        this.props.close();
                    });
                console.log('## ## 1-2');
            } else {
                console.log('## ## 2');
                let msg = intl.formatMessage({ id: 'drive.alert.download.agentLoginCheckFail' });

                const loginFailConfig = {
                    message: msg.split('\\n').map(renderLine)
                };
                console.log('## ## 2-1');
                alert(loginFailConfig);
            }
            console.log('## ## 3');
            return false;
        }
        let result = code.substr(code.length - 1, 1).toString();

        console.log('result: ', result);

        /** WebSocket 오류 코드 일람
         *  Websocket 연결 실패(또는 미설치)와 Login_1 오류만 특정하여 메시지 처리하고, 나머지 오류는 함께 처리한다.
         *
         *** Login Websocket Result Code
         * 0 : 정상(공유메모리에 있는 에이전트의 접속 정보와 일치 시)
         * 1 : 비정상(공유메모리에 있는 에이전트의 접속 정보와 일치하지 않을 시)
         * 2 : 비정상(테넌트 ID 혹은 사용자 ID 가 입력되지 않은 경우)
         * 3 : 비정상(로직내 오류발생 시)
         * 9 : 비정상(Agent 미로그인상태 or 미실행 or 미설치)

         *** File Open Websocket Result Code
         * 0 : 정상(파일 실행)
         * 1 : 비정상(파일경로에 실제 파일이 없는 경우)
         * 2 : 비정상(파일경로가 입력되지 않은 경우)
         * 3 : 비정상(로직내 오류발생 시)
         */
        if (result === '0') {
            return true;
        } else if (type === 'Login' && result === '1') {
            // 1 : 비정상(공유메모리에 있는 에이전트의 접속 정보와 일치하지 않을 시)
            let msg = intl.formatMessage({ id: 'drive.alert.download.loginAccntDiff' });

            const loginDiffConfig = {
                message: msg.split('\\n').map(renderLine)
            };
            alert(loginDiffConfig);
        } else if (type === 'Open' && result === '3') {
            console.log('File Open, result 3');
        } else if (type === 'Login' && result === '9') {
            // 9 : 비정상(Agent 미로그인상태 or 미실행 or 미설치)
            let msg = intl.formatMessage({ id: 'drive.alert.download.agentLoginCheckFail' });

            const loginFailConfig = {
                message: msg.split('\\n').map(renderLine)
            };
            alert(loginFailConfig);
        } else {
            // 기타 오류 발생시
            HelpRest.getHelpBasicInfo().then(data => {
                if (!!data) {
                    console.log('data: ', data);
                    const helpCntrNum = data.EFL_COM_HELPCENTER_TEL;
                    let msg = intl.formatMessage({ id: 'drive.alert.download.fileOpenCommError' });
                    msg = msg.replace('{helpCntrNum}', helpCntrNum);

                    const fileOpenCommConfig = {
                        message: msg.split('\\n').map(renderLine)
                    };

                    alert(fileOpenCommConfig).then(() => {
                        this.props.close();
                    });
                }
            });
        }
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.download.prepareOpen" />
                    </h5>
                </div>

                <div className="modal-body">
                    <div className="guide-load">
                        <div className="dy-progress-page">
                            <div className="spinner" />
                        </div>
                        <FormattedHTMLMessage id="drive.guideText.download.prepareOpen" tagName="p" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(FileOpen);
