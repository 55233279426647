import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AuthService from 'utils/AuthService';
import { RedirectToLoginPage } from './index';
import GlobalPopupService from '../../../redux/service/GlobalPopup';

const ExpiredTokenProcess = ({ expirationAction, intl }) => {
    const { isLogout, needAlert } = expirationAction;
    const [doRedirect, setDoRedirect] = useState(!needAlert);

    if (needAlert) {
        GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.expired.session' })).then(() => {
            setDoRedirect(true);
        });
    }

    AuthService.removeAuthTokenAndSignKey();

    return doRedirect ? <RedirectToLoginPage dontNeedSSO={isLogout} isLogout={isLogout} /> : <></>;
};

ExpiredTokenProcess.propTypes = {
    expirationAction: PropTypes.object,
    intl: PropTypes.object.isRequired
};

ExpiredTokenProcess.defaultProps = {
    expirationAction: {}
};

const mapStateToProps = state => ({
    expirationAction: state.auth.expirationAction
});

export default injectIntl(connect(mapStateToProps)(ExpiredTokenProcess));
