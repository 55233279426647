import React from 'react';
import PropTypes from 'prop-types';
import FileReload from '../../../../utils/FileReload';
import FileIcon from '../../../presentationals/FileMenu/FileIcon';

const onClickFileIcon = e => e.stopPropagation();

const Option = props => {
    const { fileIconCustom, groupType, file, atHome, cardViewOption } = props;
    const data = { reloadType: 'refresh' };
    const callback = atHome ? () => FileReload.call(props, [file], data) : FileReload.call;
    return (
        <>
            {cardViewOption ? (
                <FileIcon groupType={groupType} atHome={atHome} file={file} callback={callback} custom={fileIconCustom} />
            ) : (
                <div className="grid-cell files-option-cell" onClick={onClickFileIcon} role="presentation">
                    <FileIcon groupType={groupType} file={file} callback={callback} custom={fileIconCustom} />
                </div>
            )}
        </>
    );
};

Option.propTypes = {
    file: PropTypes.object.isRequired,
    groupType: PropTypes.string.isRequired,
    fileIconCustom: PropTypes.object.isRequired,
    atHome: PropTypes.bool,
    cardViewOption: PropTypes.bool
};

Option.defaultProps = {
    atHome: false,
    cardViewOption: false
};

export default Option;
