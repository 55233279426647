import { $http } from 'modules/index';

const returnResponse = response => {
    if (response.resultCode === 200) {
        return response.data;
    }
    return Promise.reject(response);
};

class InLinkRest {
    static getIncomLinkUrl(objtId, onpstId) {
        return $http.get(`/drive/v1/link/url/${onpstId}/${objtId}`).then(returnResponse, error => error);
    }

    static getIncomLinkUrlList(incomLinkParmList) {
        return $http.post(`/drive/v1/link/url`, incomLinkParmList).then(returnResponse, error => error);
    }

    static setIncomLinkUnautPermCd(objtId, onpstId, unautPermCd) {
        return $http.patch(`/drive/v1/link/url/${onpstId}/${objtId}/unaut-perm-cd/${unautPermCd}`).then(returnResponse, error => error);
    }

    static getIncomLinkInfo(incomLinkUrl) {
        return $http.get(`/drive/v1/link/${incomLinkUrl}`);
    }

    static getIncomTypeLinkInfo(objtId, incomLinkUrl) {
        return $http.get(`/drive/v1/link/${objtId}/${incomLinkUrl}`);
    }

    static getLinkDownload(objtId, onpstId) {
        const params = {
            objtId,
            onpstId
        };
        return $http.get(`/drive/v1/link/download`, { params });
    }
}

export default InLinkRest;
