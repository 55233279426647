import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FolderRest } from 'apis';
import { alert, toast, largePopup } from 'utils/ModalService';
import CloseButton from 'presentationals/Button/CloseButton';
import SelectBox from 'presentationals/SelectBox';
import CriticalItemDt from 'presentationals/Row/CriticalItemDt';
import { FOLDER } from 'constants/ResultCode';
import { If } from 'jsx-control-statements';
import { connect } from 'react-redux';
import ApprovalPopup from '../Approval/ApprovalPopup';

const Volumns = [
    {
        message: '10GB',
        value: '10'
    },
    {
        message: '50GB',
        value: '50'
    },
    {
        message: '100GB',
        value: '100'
    },
    {
        message: '200GB',
        value: '200'
    },
    {
        message: '300GB',
        value: '300'
    }
];

const MAX_LENGTH_ON_WORKGROUPNAME = 100;
const MAX_LENGTH_ON_REQUESTREASON = 512;

const getValueByBool = (boolValue = false, trueValue = '', falseValue = '') => {
    return boolValue ? trueValue : falseValue;
};

const RequestCreatePopup = ({ close, intl, reload, isSelfApproval, loginUser, approval, phoneNum }) => {
    const { id: userId, name: userNm } = loginUser;

    const isSimple = isSelfApproval || approval;

    const [workgroupName, setWorkgroupName] = useState('');
    const [approverId, setApproverId] = useState(getValueByBool(isSimple, userId, ''));
    const [allocUseSize, setAllocUseSize] = useState('10');
    const [requestReason, setRequestReason] = useState(getValueByBool(isSimple, '.....', ''));
    const [approverList, setApproverList] = useState(getValueByBool(isSimple, [{ message: userNm, value: userId }], []));
    const [isDisableSelect, setDisableSelect] = useState(false);

    const getApproverList = async () => {
        const response = await FolderRest.getWorkgroupRequestApprover('CREATE_WORKGROUP');
        const { resultCode, data } = response;

        if (resultCode !== 200) return;

        if (_.isEmpty(data)) {
            setDisableSelect(true);
            return;
        }

        const refined = data.map(approver => ({
            message: `${approver.userNm} / ${approver.deptNm}`,
            value: approver.userId
        }));
        setApproverList(refined);

        // 자가 결재인 경우 1번 자동선택
        if (isSelfApproval && refined.length) {
            setApproverId(refined[0].value);
        }
    };

    useEffect(() => {
        if (!isSimple) {
            getApproverList();
        }
    }, []);

    const setTextWithMaxLength = (value, max, setter) => {
        if (_.size(value) > max) {
            return;
        }
        setter(value);
    };

    const onChangeWorkgroupName = ({ target }) => {
        const { value } = target;
        setTextWithMaxLength(value, MAX_LENGTH_ON_WORKGROUPNAME, setWorkgroupName);
    };

    const onChangeReason = ({ target }) => {
        const { value } = target;
        setTextWithMaxLength(value, MAX_LENGTH_ON_REQUESTREASON, setRequestReason);
    };

    const checkDisabled = () => _.isEmpty(workgroupName) || _.isEmpty(approverId) || _.isEmpty(allocUseSize) || _.isEmpty(requestReason) || _.size(_.trimEnd(_.trimStart(requestReason))) < 5;

    const alertAfterSave = (resultCode, data = '') => {
        switch (resultCode) {
            case FOLDER.EVENT_COMMON_PROCESS_WORKGROUPS_DUP_NAME:
                alert('com.alert.already.existed.name');
                break;
            case FOLDER.REQ_NOT_SUPPORT_FOLDER_REQUEST_TYPE_CODE:
                alert('com.alert.over.usage');
                break;
            case FOLDER.REQ_INVALID_APPROVER:
                alert('com.alert.failed');
                break;
            case FOLDER.NOT_FOUND_DEPARTMENT_LEADER:
                alert('drive.alert.failGetDepartmentLeader');
                break;
            case FOLDER.NOT_FOUND_DEPARTMENT_INFO:
                alert('drive.alert.failGetDepartmentInfo');
                break;
            default:
                alert(data);
        }
    };

    const processAfterSave = (response = {}, isShowMessage = true) => {
        const { data } = response;
        const { isCreatedFolder } = data;
        let messageId = 'com.toast.request.to.create.workgroup';

        if (isCreatedFolder) {
            messageId = 'com.toast.create.workgroup';
            const files = [];
            files.push(data.folderFile);
            reload(null, files, { reloadType: 'workgroup' });
        }

        if (isShowMessage) {
            toast(messageId);
        }

        close();
    };

    const openApprovalPopup = () => {
        const target = {
            workgroupName,
            allocUseSize
        };
        largePopup(<ApprovalPopup {...approval} target={target} />).then(response => {
            processAfterSave(response, false);
        });
    };

    const onSave = async () => {
        const response = await FolderRest.createFolderWorkgroupRequest({
            approverId,
            requestReason: requestReason.trim(),
            workgroupName,
            allocUseSize
        });
        const { resultCode, data } = response;
        if (resultCode === 200 && !_.isEmpty(data)) {
            processAfterSave(response);
            return;
        }

        alertAfterSave(resultCode, data);
    };

    const onClickSaveButton = () => {
        if (!isSelfApproval && approval) {
            openApprovalPopup();
            return;
        }

        onSave();
    };

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="com.title.workgroup.create.request" />
                </h5>
                <CloseButton close={close} />
            </div>
            <ModalBody>
                <div className="dt-width-120">
                    <dl className="row-list">
                        <CriticalItemDt text={intl.formatMessage({ id: 'com.text.workgroup.name' })} />
                        <dd>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'com.placeHolder.input.workgroup.name' })}
                                        onChange={onChangeWorkgroupName}
                                        maxLength={MAX_LENGTH_ON_WORKGROUPNAME}
                                    />
                                    <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })} onClick={() => setWorkgroupName('')}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="write-count">
                                        <strong className="count-num">{_.size(workgroupName)}</strong> /<span className="write-total">{MAX_LENGTH_ON_WORKGROUPNAME}</span>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <If condition={!isSimple}>
                        <dl className="row-list">
                            <CriticalItemDt text={intl.formatMessage({ id: 'drive.text.securefolder.approver' })} />
                            <dd>
                                <SelectBox
                                    className="selectpicker"
                                    title={intl.formatMessage({ id: 'com.title.select.approver' })}
                                    type="box"
                                    options={approverList}
                                    value={approverId}
                                    onChange={setApproverId}
                                    isDisabled={isDisableSelect}
                                    dataWidth={550}
                                />
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedMessage id="com.guideText.no.approver" values={{ phoneNumber: phoneNum }} />
                                </div>
                            </dd>
                        </dl>
                    </If>
                    <If condition={!isSelfApproval}>
                        <dl className="row-list">
                            <CriticalItemDt text={intl.formatMessage({ id: 'com.text.workgroup.usage' })} />
                            <dd>
                                <SelectBox className="selectpicker" type="box" options={Volumns} value={allocUseSize} onChange={setAllocUseSize} dataWidth={100} />
                            </dd>
                        </dl>
                    </If>
                </div>
                <If condition={!isSimple}>
                    <div className="dt-width-120">
                        <dl className="row-list flex-wrap">
                            <CriticalItemDt text={intl.formatMessage({ id: 'com.text.request.reason' })} />
                            <dd>
                                <div className="form-group">
                                    <textarea
                                        className={!_.isEmpty(requestReason) && _.size(_.trimEnd(_.trimStart(requestReason))) < 5 ? 'form-control textarea-sm is-invalid' : 'form-control textarea-sm '}
                                        placeholder={intl.formatMessage({ id: 'com.placeHolder.input.workgroup.reason' })}
                                        maxLength={MAX_LENGTH_ON_REQUESTREASON}
                                        value={requestReason}
                                        onChange={onChangeReason}
                                    />
                                    <div className="input-remark">
                                        {!_.isEmpty(requestReason) && _.size(_.trimEnd(_.trimStart(requestReason))) < 5 && (
                                            <div className="err-msg">
                                                <FormattedMessage id="com.text.write.reason.5letters.ormore" />
                                            </div>
                                        )}
                                        <div className="write-count">
                                            <strong className="count-num">{_.size(_.trimEnd(_.trimStart(requestReason)))}</strong> /<span className="write-total">{MAX_LENGTH_ON_REQUESTREASON}</span>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </If>
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={close}>
                        <span className="btn-text">
                            <FormattedMessage id="com.cancel" />
                        </span>
                    </a>
                    <a className={`btn btn-lg btn-primary ${checkDisabled() ? 'disabled' : ''}`} role="button" disabled={checkDisabled()} onClick={onClickSaveButton}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </ModalFooter>
        </>
    );
};

RequestCreatePopup.propTypes = {
    close: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    isSelfApproval: PropTypes.bool.isRequired,
    loginUser: PropTypes.object.isRequired,
    approval: PropTypes.any,
    phoneNum: PropTypes.string
};

RequestCreatePopup.defaultProps = {
    approval: undefined,
    phoneNum: ' '
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(RequestCreatePopup));
