import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Choose, If, Otherwise, When } from 'jsx-control-statements';
import SummarySidePanelHeader from './Header/SidePanelHeader';
import SidePanelBody from './SidePanelBody';
import PromptInput from './PromptInput/PromptInput';
import animatedBackgroundSrc from '../../../../../static/img/common/drv/copilot_entry.mp4';
import SummaryOnboarding from './Onboarding/SummaryOnboarding';
import { summaryStorage } from '../Common/Util/SummaryStorageUtil';
import { summaryActions } from '../../../../redux/actions/Summary';
import { isInvalidScopeTypeKeyword } from '../Common/Util/SummaryUtil';
import { showSummaryToast } from '../Common/Util/SummaryToastUtil';
import { checkValidity, convertToUserMessage } from '../Common/Util/PromptTemplateUtil';

const SidePanel = ({ sidePanelRef }) => {
    const [isClosedCompletely, setIsClosedCompletely] = useState(false);

    const { isOpen, summaryMessages } = useSelector(
        ({ summary }) => ({
            isOpen: summary.sidePanel.isOpen,
            summaryMessages: summary.thread.summaryMessages
        }),
        shallowEqual
    );
    const selectedLLM = useSelector(({ summary }) => summary.global.selectedLLM);
    const promptTemplate = useSelector(({ summary }) => summary.promptInput.promptTemplate);
    const { promptTemplateType, templateData } = promptTemplate;

    const dispatch = useDispatch();

    useEffect(() => {
        const { summaryMessages: cachedSummaryMessages } = summaryStorage.thread.get(true);

        dispatch(summaryActions.thread.load({ summaryMessages: cachedSummaryMessages }));
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setIsClosedCompletely(false);
    }, [isOpen]);

    // TODO why
    const handleCloseCompletely = useCallback(() => {
        if (!isOpen) {
            setIsClosedCompletely(true);
        }
    }, [isOpen]);

    const isOnboardingShowing = useMemo(() => {
        // 열려있거나 닫히는 중인 경우
        const isOpenOrClosing = isOpen || !isClosedCompletely;
        const isSummaryMessagesEmpty = _.isEmpty(summaryMessages);

        return isOpenOrClosing && isSummaryMessagesEmpty;
    }, [isOpen, isClosedCompletely, summaryMessages]);

    const handleEnterKeyDown = e => {
        if (!isOpen || e.key !== 'Enter' || !checkValidity(promptTemplate)) {
            return;
        }

        const { keyword } = templateData;

        if (!_.isNil(keyword) && isInvalidScopeTypeKeyword(keyword)) {
            showSummaryToast(dispatch, { messageId: 'drive.tooltip.copilot.summary.keywordConstraint' });
            return;
        }

        dispatch(summaryActions.thread.generateUserMessage({ summaryMessage: convertToUserMessage(promptTemplate, selectedLLM) }));
        dispatch(summaryActions.promptInput.clear());
    };

    useEffect(() => {
        // 닫힌 경우
        if (!isOpen) {
            sidePanelRef.current.blur();

            const { files } = templateData;

            // 프롬프트 인풋에 프롬프트 템플릿이 세팅된 상태인데, 닫힌 경우
            if (!_.isNil(promptTemplateType) && summaryStorage.inProgressNotification.isEnabled()) {
                dispatch(summaryActions.notificationDialog.open({ isInProgress: true, files }));
            }

            return;
        }

        if (!checkValidity(promptTemplate)) {
            return;
        }

        sidePanelRef.current.focus();
    }, [isOpen]);

    return (
        <div
            className={classnames('aside-panel', isOpen && 'show')}
            role="dialog"
            ref={sidePanelRef}
            onTransitionEnd={handleCloseCompletely}
            onKeyDown={handleEnterKeyDown}
            style={{ outline: 'none' }}
            tabIndex="-1">
            <div className="aside-panel-content aside-panel-md copilot-entry">
                <SummarySidePanelHeader />
                <Choose>
                    <When condition={_.isEmpty(summaryMessages)}>
                        <SummaryOnboarding />
                    </When>
                    <Otherwise>
                        <SidePanelBody isOpen={isOpen} />
                    </Otherwise>
                </Choose>
                <PromptInput />
            </div>
            <If condition={isOnboardingShowing}>
                <div className="copilot-entry-video">
                    <video className="video-content" autoPlay={true} muted={true} loop={true}>
                        <source src={animatedBackgroundSrc} type="video/mp4" />
                    </video>
                </div>
            </If>
        </div>
    );
};

SidePanel.propTypes = {
    sidePanelRef: PropTypes.object.isRequired
};

export default SidePanel;
