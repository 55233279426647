import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CheckBoxHeader from './HeaderRow/CheckBoxHeader';
import SettingHeader from './HeaderRow/SettingHeader';
import { withFilesContext } from './DriveFilesContext';
import SortHeader from './HeaderRow/SortHeader';
import DragDropUtil from '../../../utils/DragDrop';
import LNBResize from '../../../utils/LNBResize';

let rowWidth = 1744;
let colRefs = {};
let viewColumnsRef = null;
let remainColWidthRef = 0;

const DriveFilesHeader = props => {
    const {
        context: {
            state,
            actions,
            props: { pageClass, folderId }
        }
    } = props;
    const rowRef = useRef(null);
    const [remainColWidth, setRemainColWidth] = useState(0);
    const [isLNBResizing, setIsLNBResizing] = useState(false);
    const sortPossibleColumns = pageClass.sortPossibleColumns(folderId);
    const setColumnWidth = (id, width) => {
        const viewColumns = [].concat(state.viewColumns);
        viewColumns.forEach(column => {
            if (column.id === id && column.width !== width) {
                column.width = width; // eslint-disable-line no-param-reassign
            }
        });
        actions.setGridSettings({
            columnWidths: [
                {
                    id,
                    width
                }
            ],
            viewColumns
        });
    };
    const isIE = DragDropUtil.isIE();

    const setColumnRef = (id, ref) => {
        if (ref) {
            colRefs[id] = ref;
        }
    };

    const getFixedColumnWidth = () => {
        if (!rowRef || !rowRef.current || !rowRef.current.children) return 0;

        const rowElm = rowRef.current;
        const childrenElm = rowElm.children;
        const fixed = {
            'grid-cell files-check-cell': 40,
            'grid-cell files-option-cell': 56
        };

        let fixedWidth = 0;
        for (let i = 0; i < childrenElm.length; i += 1) {
            if (fixed[childrenElm[i].className]) {
                fixedWidth += fixed[childrenElm[i].className];
            }
        }
        return fixedWidth;
    };

    const calcRemainWidth = (viewColumns = state.viewColumns) => {
        if (rowWidth < 1) return 0;

        const fixedWidth = getFixedColumnWidth();
        let calcWidth = 0;
        const nameColWidth = 154;
        let colCount = 0;
        viewColumns.forEach(column => {
            const ref = colRefs[column.id];
            if (ref) {
                calcWidth += column.width;
                colCount += 1;
            }
        });
        const calcCorrection = colCount > 0 ? (colCount - 1) * 32 + 8 : 0;
        return rowWidth - fixedWidth - calcWidth - calcCorrection - nameColWidth;
    };

    const resetRemainWidth = (viewColumns = state.viewColumns) => {
        const resizableColumns = viewColumns.filter(column => column.id !== 'objtNm');
        const isReady = resizableColumns.every(column => {
            return colRefs[column.id];
        });

        if (isReady) {
            const remainWidth = calcRemainWidth(viewColumns);
            // 크기 변경 없으면 패스
            if (remainWidth === remainColWidthRef) {
                return;
            }

            remainColWidthRef = remainWidth;
            // 전체 조정이 필요한 경우(LNB 크기 조정)
            if (remainWidth < 0) {
                // 기본보다 큰것들만 처리
                const resizedIds = resizableColumns.filter(column => column.width > column.defaultWidth).map(column => column.id);
                let revised = remainWidth * -1;
                const newColumns = [].concat(viewColumns);
                const newColumnWidths = [];

                // 앞선 컬럼부터 크기 조정
                newColumns.forEach(column => {
                    if (resizedIds.indexOf(column.id) !== -1 && revised > 0) {
                        const diff = Math.min(column.width - column.defaultWidth, revised);
                        column.width -= diff; // eslint-disable-line no-param-reassign
                        revised -= diff;

                        newColumnWidths.push({
                            id: column.id,
                            width: column.width
                        });
                    }
                });

                // 크기 변경 처리
                actions.setGridSettings({
                    columnWidths: newColumnWidths,
                    viewColumns: newColumns
                });
                remainColWidthRef = 0;
            }
            setRemainColWidth(remainColWidthRef);
        }
    };

    const onLNBResizeStart = () => {
        setIsLNBResizing(true);
    };

    const onLNBResizeStop = () => {
        if (rowRef && rowRef.current) {
            const resizedWidth = rowRef.current.clientWidth;
            if (rowWidth !== resizedWidth) {
                rowWidth = resizedWidth;
                resetRemainWidth(viewColumnsRef);
            }
            setIsLNBResizing(false);
        }
    };

    // Did mount
    useEffect(() => {
        resetRemainWidth();
    }, [rowRef.current]);

    // 컬럼 정보 변경시 최대 사이즈 수정
    useEffect(() => {
        viewColumnsRef = state.viewColumns;
        resetRemainWidth();
    }, [state.viewColumns]);

    // 페이지 변경에 따른 처리
    useEffect(() => {
        colRefs = {};

        if (pageClass.pageId === 'own') {
            rowWidth = 1510;
            LNBResize.register('start', 'rowHeader', onLNBResizeStart);
            LNBResize.register('stop', 'rowHeader', onLNBResizeStop);
        } else {
            rowWidth = 1744;
        }
        resetRemainWidth();

        return function() {
            if (pageClass.pageId === 'own') {
                LNBResize.remove('start', 'rowHeader');
                LNBResize.remove('stop', 'rowHeader');
            }
        };
    }, [pageClass.pageId]);

    return (
        <div className="grid-header-group" id="gridHeader">
            <div className="grid-row" ref={rowRef}>
                <CheckBoxHeader />
                {!isLNBResizing &&
                    state.viewColumns &&
                    state.viewColumns.map((column, idx) => {
                        return (
                            <SortHeader
                                key={column.id}
                                column={column}
                                sort={state.sort}
                                setSortingOption={actions.setGridSettings}
                                sortPossibleColumns={sortPossibleColumns}
                                resizable={idx > 0}
                                setColumnWidth={setColumnWidth}
                                remainWidth={remainColWidth}
                                isLast={idx + 1 === state.viewColumns.length}
                                isIE={isIE}
                                setColumnRef={setColumnRef}
                            />
                        );
                    })}
                {isLNBResizing && (
                    <div className="grid-cell files-name-cell">
                        <FormattedMessage id="drive.text.list.columns.folderTree.resizing" />
                    </div>
                )}
                <SettingHeader />
            </div>
        </div>
    );
};

DriveFilesHeader.propTypes = {
    context: PropTypes.object.isRequired
};

DriveFilesHeader.displayName = 'DriveFilesHeader';
export default React.memo(withFilesContext(DriveFilesHeader, ['state.viewColumns', 'state.sort']));
