import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Checkbox = props => {
    const { checked, checkedFiles, hover, disabled } = props;

    const style = { display: 'block', visibility: 'hidden' };

    if (checkedFiles.length || hover) {
        style.visibility = 'visible';
    }

    return (
        <div className="grid-cell files-check-cell">
            <div className="form-check" style={style}>
                <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" checked={checked} readOnly={true} disabled={disabled} />
                    <span className="label-text" role="button" onClick={e => e.stopPropagation()} />
                </label>
            </div>
        </div>
    );
};

Checkbox.propTypes = {
    hover: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    checkedFiles: PropTypes.array,
    disabled: PropTypes.bool
};

Checkbox.defaultProps = {
    checkedFiles: [],
    disabled: false
};

const mapStateToProps = state => ({
    checkedFiles: state.fileCheck.checkedFiles
});

// export default Checkbox;
export default connect(mapStateToProps)(Checkbox);
