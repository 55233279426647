import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AUTH from 'constants/Auth';
import AuthRest from 'apis/AuthRest';
import { TOKEN_STATUS } from '../../../redux/actions/Auth';
import AuthService from '../../../utils/AuthService';

const AuthTokenProcess = ({ setTokenStatus }) => {
    const fetchData = async () => {
        const response = await AuthRest.getTokenStatus();
        const { resultCode, data } = response;
        if (resultCode === AUTH.RESULT_CODE.SUCCESS) {
            const { status } = data;
            if (status === AUTH.RESULT_STATUS.NORMAL) {
                setTokenStatus(TOKEN_STATUS.NORMAL);
            } else {
                const { needProcess } = data;
                if (_.isEmpty(needProcess)) {
                    setTokenStatus(TOKEN_STATUS.AB_NORMAL);
                } else {
                    _.orderBy(needProcess, ['priority'], ['asc']);
                    const firstProcess = _.get(needProcess, '0', {});
                    if (!_.isEmpty(firstProcess)) {
                        const { code } = firstProcess;
                        if (code === AUTH.PROCESS_CODE.CHANGE_PASSWORD) {
                            setTokenStatus(TOKEN_STATUS.CHANGE_PASSWORD);
                        } else if (code === AUTH.PROCESS_CODE.ACCEPT_TERMS) {
                            setTokenStatus(TOKEN_STATUS.ACCEPT_TERMS);
                        } else if (code === AUTH.PROCESS_CODE.ACCEPT_NEW_TEMRS) {
                            setTokenStatus(TOKEN_STATUS.ACCEPT_NEW_TEMRS);
                        } else {
                            setTokenStatus(TOKEN_STATUS.AB_NORMAL);
                        }
                    }
                }
            }
        } else if (resultCode === AUTH.RESULT_CODE.AUTH_TOKEN_EXPIRED) {
            setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: false, isLogout: false } });
        } else {
            console.error(JSON.stringify(response));
            AuthService.removeAuthTokenAndSignKey();
            setTokenStatus(TOKEN_STATUS.AB_NORMAL);
        }
    };

    useEffect(() => {
        fetchData();
    });

    return <></>;
};

AuthTokenProcess.propTypes = {
    setTokenStatus: PropTypes.func.isRequired
};

export default AuthTokenProcess;
