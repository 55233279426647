import DeptRest from 'apis/DeptRest';
import CustomModal from 'containers/CustomModal';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { When, Otherwise, Choose } from 'jsx-control-statements';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const TeamSearchList = props => {
    const { close, isOpen, query, loginUser } = props;
    const [team, setTeam] = useState([]);
    const [teams, setTeams] = useState([]);
    const isKoreaLanguage = loginUser.locale.languageCode === '001';

    useEffect(() => {
        const params = { tenantId: loginUser.tenantId };
        if (isKoreaLanguage) {
            params.deptNmLike = query;
        } else {
            params.deptEngNmLike = query;
        }
        DeptRest.retrieveDepts(params).then(data => {
            setTeams(data);
        });
    }, [query]);

    function selectTeam(selected) {
        setTeam(selected);
    }

    function onClose() {
        close();
    }

    function onSelect() {
        close(team);
    }

    function onDoubleClickRow(target) {
        setTeam(target);
        close(target);
    }

    return (
        <CustomModal isOpen={isOpen} fade={false} backdrop="static" className="modal-open">
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'block', overflowY: 'hidden' }}>
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <FormattedMessage id="user.title.popup.searchDept" />
                            </h5>
                            <FormattedMessage id="com.close">
                                {title => (
                                    <a className="btn-ic-nor" role="button" title={title} data-dismiss="modal" onClick={onClose}>
                                        <i className="ic-20-close" />
                                    </a>
                                )}
                            </FormattedMessage>
                        </div>
                        <div className="modal-body">
                            <div className="box-line">
                                <div className="scroll">
                                    <div>
                                        <ul className="data-list row-6 only-elective">
                                            <Choose>
                                                <When condition={_.isEmpty(teams)}>
                                                    <li key="instant_empty_msg" className="empty">
                                                        <div className="box-empty">
                                                            <div className="message">
                                                                <p>
                                                                    <FormattedMessage id="com.no-search-results" />
                                                                    <br />
                                                                    <FormattedMessage id="com.alert.no-search-results.confirmSearchText" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </When>
                                                <Otherwise>
                                                    {teams.map(t => (
                                                        <li
                                                            key={t.deptId}
                                                            role="presentation"
                                                            onClick={() => selectTeam(t)}
                                                            onDoubleClick={() => onDoubleClickRow(t)}
                                                            className={t.deptId === team.deptId ? 'on' : ''}
                                                            title={t.deptLangCdNm}>
                                                            <span
                                                                className="partname"
                                                                style={{
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    wordBreak: 'normal'
                                                                }}>
                                                                {t.deptLangCdNm}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </Otherwise>
                                            </Choose>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-option" />
                            <div className="modal-footer-btns">
                                <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClose}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.cancel" />
                                    </span>
                                </a>
                                <a className={`btn btn-lg btn-primary ${_.isEmpty(team) ? 'disabled' : ''}`} role="button" onClick={onSelect}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.button.ok" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

TeamSearchList.propTypes = {
    close: PropTypes.func,
    isOpen: PropTypes.bool,
    query: PropTypes.string.isRequired,
    loginUser: PropTypes.object.isRequired
};

TeamSearchList.defaultProps = {
    close: _.noop,
    isOpen: false
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(mapStateToProps)(TeamSearchList);
