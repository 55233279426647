import { all, put, takeLatest, select } from 'redux-saga/effects';
import { CLOSE_CONFIRM, CLOSE_CONFIRM_AFTER_CALLBACK, CLOSE_CHANGE_PASSWORD, CLOSE_CHANGE_PASSWORD_AFTER_CALLBACK } from '../actions/GlobalPopup';

export const getCloseConfirmCallback = state => state.globalPopup.confirm.callback;
export const getCloseChangePasswordCallback = state => state.globalPopup.changePassword.callback;

function* closeConfirm(action) {
    const callback = yield select(getCloseConfirmCallback);
    callback(action.result);
    yield put({ type: CLOSE_CONFIRM_AFTER_CALLBACK });
}

function* closeChangePassword(action) {
    const callback = yield select(getCloseChangePasswordCallback);
    callback(action.result);
    yield put({ type: CLOSE_CHANGE_PASSWORD_AFTER_CALLBACK });
}

function* watchCloseConfirm() {
    yield takeLatest(CLOSE_CONFIRM, closeConfirm);
}

function* watchCloseChangePassword() {
    yield takeLatest(CLOSE_CHANGE_PASSWORD, closeChangePassword);
}

export default function* rootSaga() {
    yield all([watchCloseConfirm(), watchCloseChangePassword()]);
}
