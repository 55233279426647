import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import ApprovalConstants from '../ApprovalConstants';
import { toast, alert as modalAlert } from '../../../../../utils/ModalService';
import ApprovalUtil from '../ApprovalUtil';

class BaseApproval extends Component {
    type = 'BASE';

    mode = ApprovalConstants.MODE.REQUEST;

    approverNameId = 'com.placeHolder.approval.line.approval';

    consenterNameId = 'com.placeHolder.approval.line.consent';

    notifyNameId = 'com.text.approval.line.type.notification';

    title = '';

    line = {};

    // 기안 관련
    draftUser = {};

    draftOpinion = '';

    draftOpinionKey = '';

    draftOpinionMinLength = 5;

    // 요청 ID
    id = '';

    // 대상 객체
    target = {};

    // 상세 정보
    detail = {};

    // 상신 성공 메시지
    successSumbitId = 'com.toast.approval.submit';

    // 요청 시스템
    systemType = 'KNOX_APPROVAL';

    // 사용자 타임존
    timeZone = 0;

    // 창닫기 전 대기시간
    closeDelay = 500;

    // 결과 넘기기 여부
    isThrowResult = false;

    // 초기화
    constructor({ intl, mode, user, reqId, req, target, timeZone, rawUser }) {
        super({});

        this.intl = intl;
        this.setMode(mode);
        this.setDraftUser(user, req);
        this.setId(reqId);
        this.setTarget(target);
        this.setDetail(req);
        this.timeZone = timeZone;
        this.setRawUser(rawUser);
    }

    // 상신가능 여부 체크함수
    setIsSubmittable = () => {};

    // 상신내용 설정 함수
    setContents = () => {};

    // 읽기전용 여부
    isViewMode = () => {
        return this.mode === ApprovalConstants.MODE.VIEW;
    };

    /**
     * 기안자 설정
     * @param user
     * @param req
     */
    setDraftUser(user = {}, req = {}) {
        const { req: detail = {} } = req;
        const { reqRsn = '', reqStatCd = ApprovalConstants.STAT_CODE.PROCESSING, regDt = '', reqStatChgDt = '', percOpin = '' } = detail;
        const actDt = reqStatCd === ApprovalConstants.STAT_CODE.CANCEL ? reqStatChgDt : regDt;
        const draftUser = _.extend(
            {
                opinion: reqStatCd === ApprovalConstants.STAT_CODE.CANCEL ? percOpin : reqRsn,
                statCd: reqStatCd,
                actDt: !actDt ? '' : moment.utc(actDt).valueOf()
            },
            user
        );
        this.draftUser = ApprovalUtil.convertUserToLineUser(draftUser, ApprovalConstants.LINE_TYPE.DRAFT);
    }

    /**
     * 상신의견 설정
     * @param opinion
     */
    setDraftOpinion(opinion = '') {
        this.draftOpinion = opinion.trim();
    }

    setDraftOpinionKey(opinionKey = '') {
        this.draftOpinionKey = opinionKey;
    }

    setDraftOpinionMinLength(minLength = 5) {
        this.draftOpinionMinLength = minLength;
    }

    /**
     * 요청 ID 설정
     * @param id
     */
    setId(id = '') {
        this.id = id;
    }

    /**
     * 대상 객체 설정
     * @param target
     */
    setTarget(target = {}) {
        this.target = this.convertToTarget(target);
    }

    /**
     * 대상 객체를 요청 대상 객체로 변경
     * @param target
     */
    convertToTarget(target = {}) {
        return target;
    }

    /**
     * 상세정보 객체 설정
     * @param detail
     */
    setDetail(detail = {}) {
        this.detail = detail;
        this.target = this.convertDetailToTarget();
    }

    setRawUser(rawUser = {}) {
        this.rawUser = rawUser;
    }

    /**
     * 보기 모드, 요청정보 보유 여부
     * @returns {*|boolean}
     */
    hasDetail() {
        return this.isViewMode() && !_.isNil(this.detail) && !_.isEmpty(this.detail);
    }

    /**
     * 요청 정보에서 대상 객체 구성
     */
    convertDetailToTarget() {
        if (!this.hasDetail()) return this.target;
        const { reqTgt } = this.detail;
        return reqTgt;
    }

    /**
     * 요청 상태 반환
     * @returns {string}
     */
    getStatCd() {
        if (_.isNil(this.detail) || _.isNil(this.detail.req)) return ApprovalConstants.STAT_CODE.PROCESSING;
        const { reqStatCd = ApprovalConstants.STAT_CODE.PROCESSING } = this.detail.req;
        return reqStatCd;
    }

    /**
     * 팝업 모드 설정
     * @param mode
     */
    setMode(mode = ApprovalConstants.MODE.REQUEST) {
        this.mode = mode;
    }

    setIsSubmittableSetter(setter) {
        this.setIsSubmittable = setter;
    }

    setContentsSetter(setter) {
        this.setContents = setter;
    }

    /**
     * 결재선 관련
     */
    getCandidateLine() {
        return {};
    }

    getLine() {
        return this.line;
    }

    setLine(line = {}) {
        this.line = line;
    }

    /**
     * 결재 사용자 선택
     * @param sno
     * @param rIndex
     * @param idx
     * @returns {boolean}
     */
    selectLineUser(sno = 1, rIndex = 0, idx = 0) {
        if (!this.line[sno] || !this.line[sno][rIndex] || !this.line[sno][rIndex].users[idx] || this.line[sno][rIndex].selected.indexOf(idx) !== -1) return false;
        this.line[sno][rIndex].selected.push(idx);
        return true;
    }

    /**
     * 결재 사용자 입력
     * @param sno
     * @param rIndex
     * @param user
     * @returns {boolean}
     */
    inputLineUser(sno = 1, rIndex = 0, user = {}) {
        if (!this.line[sno] || !this.line[sno][rIndex] || !_.isEmpty(this.line[sno][rIndex].users)) return false;
        this.line[sno][rIndex].selected = [user];
        return true;
    }

    /**
     * 결재 사용자 입력 초기화
     * @param sno
     * @param rIndex
     * @returns {boolean}
     */
    clearLineUser(sno = 1, rIndex = 0) {
        if (!this.line[sno] || !this.line[sno][rIndex] || !_.isEmpty(this.line[sno][rIndex].users)) return false;
        this.line[sno][rIndex].selected = [];
        return true;
    }

    /**
     * 특정 결재 순번의 정상 여부
     * @param sno
     * @param rIndex
     * @returns {boolean}
     */
    hasNotAuthLineByIdx(sno = 1, rIndex = 0) {
        if (!this.line[sno] || !this.line[sno][rIndex] || _.isEmpty(this.line[sno][rIndex].selected)) {
            return true;
        }
        const lineUser = this.line[sno][rIndex];
        return !ApprovalUtil.isRequestableLineUser(lineUser);
    }

    /**
     * 요청된(상신된) 결재선 반환
     */
    getRequestedLine() {
        if (_.isNil(this.detail) || _.isEmpty(this.detail)) return {};

        const { req = {}, reqTgtUser = [] } = this.detail;
        const { reqTgtUserId = '', reqTgtUserNm = '', reqTgtUserDeptNm = '', reqTgtUserCopNm = '' } = req;

        if (_.isEmpty(reqTgtUser)) {
            reqTgtUser.push({
                reqTgtUserSno: 1,
                reqTgtUserId,
                reqTgtUserNm,
                reqTgtUserDeptNm,
                reqTgtUserCopNm,
                reqPathSectCd: ApprovalConstants.LINE_TYPE.APPROVAL
            });
        }
        return ApprovalUtil.convertTargetUsersToLine(reqTgtUser);
    }

    /**
     * 현재 결재선에서 특정 타입만 반환
     * @param type
     * @returns {Array}
     */
    getLineByType(type = ApprovalConstants.LINE_TYPE.APPROVAL) {
        return _.chain(this.line)
            .values()
            .filter(user => user.type === type)
            .value();
    }

    /**
     * 요청사유 프리셋 조회
     * @returns {Array}
     */
    getOpinionPresets() {
        return [];
    }

    /**
     * 상신 가능 여부 설정
     */
    checkSubmittable() {
        this.setIsSubmittable(this.isSubmittable());
    }

    /**
     * 상신 가능 여부
     * @returns {boolean}
     */
    isSubmittable() {
        return ApprovalUtil.isRequestableLine(this.getLine()) && this.draftOpinion.length >= this.draftOpinionMinLength;
    }

    submitAction() {}

    /**
     * 결재
     */
    async submit() {
        if (this.isSubmittable()) {
            const response = await this.submitAction();
            const { resultCode, data, message } = response;
            if (resultCode === 200 && data) {
                toast(this.successSumbitId);
                return { result: true, response };
            }
            if (resultCode === 500 && message === 'IOFFICE_INTERNAL_SERVER_EXCEPTION') {
                modalAlert('com.alert.internalServerError');
                return { result: false, response };
            }
            if (resultCode === 503 && message === 'IOFFICE_REMOTE_SERVER_EXCEPTION') {
                modalAlert('com.alert.approval.failToRequest');
                return { result: false, response };
            }
            if (this.processResultMessage(response)) {
                return { result: false, response };
            }
            toast(message);
            return { result: false, response };
        }
        return { result: false };
    }

    processResultMessage() {
        return false;
    }

    /**
     * 결재 상태 조회
     */
    get() {}

    /**
     * 안내문
     */
    infoBuilder() {
        return <></>;
    }

    /**
     * 스크롤바 본문 상단 영역
     */
    beforeContentBuilder() {
        return <></>;
    }

    /**
     * 결재 본문
     */
    contentBuilder() {
        return <></>;
    }

    hasContent() {
        return true;
    }

    /**
     * 스크롤바 본문 하단 영역
     */
    afterContentBuilder() {
        return <></>;
    }
}

BaseApproval.propTypes = {
    user: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    target: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    req: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    reqId: PropTypes.string,
    rawUser: PropTypes.object.isRequired
};

BaseApproval.defaultProps = {
    target: {},
    req: {},
    reqId: ''
};

export default BaseApproval;
