import * as ActionType from '../actions/Gnb';

const initialState = {
    notification: {
        unreadCount: 0,
        timestamp: -1,
        path: '/'
    }
};

const gnb = Object.assign({}, initialState);

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionType.SET_NOTIFICATION_UNREAD_COUNT:
            gnb.notification.unreadCount = action.count;
            gnb.notification.timestamp = Date.now();
            gnb.notification.path = action.path;
            return gnb;
        default:
            return state;
    }
};
