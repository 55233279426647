/* eslint-disable */
class FileLikeObject {
            /**
         * Creates an instance of FileLikeObject
         * @param {File|HTMLInputElement|Object} fileOrInput
         * @constructor
         */
        constructor(file) {
            this._createFromObject(file);
        }
        /**
         * Creates file like object from object
         * @param {File|FileLikeObject} object
         * @private
         */
        _createFromObject(object) {
            this.lastModifiedDate = object.lastModified; 
            this.size = object.size;
            this.type = object.type;
            this.name = object.name;

            const index = this.name.lastIndexOf('.');
            if(index > -1){
                this.nm  = this.name.substring(0,index);
                this.ext = this.name.substring(index+1).toLowerCase();
            }else{
                this.nm = this.name;
                this.ext = '';
            }
        }

}

export default FileLikeObject;