import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import CustomModal from 'containers/CustomModal';
import HelpRest from 'apis/HelpRest';

class HelpCenterPopup extends Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;

        this.state = {
            tel: '',
            email: '',
            hours: ''
        };

        this.closeButtonText = intl.formatMessage({ id: 'com.close' });
    }

    async componentDidMount() {
        const data = await HelpRest.getHelpBasicInfo();
        const { EFL_COM_HELPCENTER_TEL: tel, EFL_COM_EFSS_LIGHT_EMAIL: email, EFL_COM_HELPCENTER_HOURS: hours } = data;
        this.setState({ tel, email, hours });
    }

    render() {
        const { show, handleClose } = this.props;
        const { tel, email, hours } = this.state;

        return (
            <CustomModal isOpen={show} fade={true} size="lg" backdrop="static">
                <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'block', overflowY: 'hidden' }}>
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    <FormattedMessage id="user.helpcenter.helpcenter" />
                                </h5>
                                <a className="btn-ic-nor" role="button" title={this.closeButtonText} data-dismiss="modal" onClick={handleClose}>
                                    <i className="ic-20-close" />
                                </a>
                            </div>

                            <div className="modal-body">
                                <div className="dt-width-120">
                                    <dl className="row-list text-info">
                                        <dt>
                                            <FormattedMessage id="user.popHelpcenter.reqPhone" />
                                        </dt>
                                        <dd>
                                            <div className="dd-text">
                                                <span className="phone">{tel}</span>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="row-list text-info">
                                        <dt>
                                            <FormattedMessage id="com.email" />
                                        </dt>
                                        <dd>
                                            <div className="dd-text">
                                                <span className="email">{email}</span>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="row-list text-info">
                                        <dt>
                                            <FormattedMessage id="user.popHelpcenter.operatingTime" />
                                        </dt>
                                        <dd>
                                            <div className="dd-text">{hours}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <div className="modal-footer-option" />
                                <div className="modal-footer-btns">
                                    <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={handleClose}>
                                        <span className="btn-text">
                                            <FormattedMessage id="com.button.ok" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

HelpCenterPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default injectIntl(HelpCenterPopup);
