import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AuthService from 'utils/AuthService';
import AUTH from 'constants/Auth';
import { TOKEN_STATUS } from '../../../redux/actions/Auth';
import AuthRest from '../../../apis/AuthRest';

const TempTokenProcess = ({ setTokenStatus }) => {
    const fetchData = async () => {
        const response = await AuthRest.postTokenNormalize();
        const { resultCode, data } = response;
        if (resultCode !== 200) {
            return <> `Authentication error code : ${resultCode}` </>;
        }
        const { auth, user } = data;
        switch (resultCode) {
            case AUTH.RESULT_CODE.SUCCESS:
                AuthService.setAuthToken(auth.tuid);
                AuthService.setSignatureKey(auth.chip);
                return setTokenStatus(TOKEN_STATUS.AUTH, { user, auth });
            case AUTH.RESULT_CODE.AUTH_TOKEN_EXPIRED:
            default:
                console.error(JSON.stringify(response));
                return setTokenStatus(TOKEN_STATUS.EXPIRED);
        }
    };

    useEffect(() => {
        fetchData();
    });

    return <></>;
};

TempTokenProcess.propTypes = {
    setTokenStatus: PropTypes.func.isRequired
};

export default TempTokenProcess;
