import React from 'react';
import { Choose, Otherwise, When, If } from 'jsx-control-statements';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NameCard from '../../../../containers/NameCard/NameCard';
import Tooltips from '../../../Tooltips';
import CharacterImageContainer from '../../../../containers/CharacterImage/CharacterImageContainer';
import Url from '../../../../../utils/Url';
import { getAuthMap } from '../../../../../utils/AuthCd';

const InheritedShareTarget = ({ inheritedShareTarget, index, style, intl, cancelToken }) => {
    if (_.isEmpty(inheritedShareTarget)) return '';

    const authCds = getAuthMap();

    const isAccessableInheritedFolder = !_.isEmpty(inheritedShareTarget.inheritedFolderPathValNm);

    const openInheritedFolder = () => {
        const inheritedFolderIds = _.split(inheritedShareTarget.inheritedFolderId, '/');
        const toFolder = inheritedFolderIds[0] === '111200000000000000' ? Url.workgroup : Url.shared;
        const url = `${inheritedFolderIds[0] === '111100000000000000' ? Url.own : toFolder}/${inheritedFolderIds[inheritedFolderIds.length - 1]}`;
        window.open(url, '_blank');
    };

    return (
        <div className="grid-row inherit-row" style={style}>
            <div className="grid-cell user-cell">
                <div className="item-user">
                    <CharacterImageContainer
                        objtId={_.isEmpty(inheritedShareTarget.objtShareTgtPhotoFilePathVal) ? '' : inheritedShareTarget.objtShareTgtPhotoFilePathVal}
                        userId={inheritedShareTarget.objtShareTgtId}
                        text={inheritedShareTarget.objtShareTgtNm}
                        size="md"
                        userType={inheritedShareTarget.objtShareTgtUserSectCd}
                        characterType={inheritedShareTarget.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'team'}
                        cancelToken={cancelToken}
                    />
                    <div className="user-profile">
                        <Choose>
                            <When condition={inheritedShareTarget.objtShareTgtSectCd === 'INDIVIDUAL'}>
                                <div title={`${inheritedShareTarget.objtShareTgtNm} ${_.toString(inheritedShareTarget.objtShareTgtCopNm)}`}>
                                    <span id={`inheritedShareTarget-${index}-div`} className="name has-name-card">
                                        {inheritedShareTarget.objtShareTgtNm}
                                    </span>
                                    <span className="position">{inheritedShareTarget.objtShareTgtCopNm}</span>
                                </div>
                                <span className="partname">{inheritedShareTarget.objtShareTgtDeptNm}</span>
                            </When>
                            <Otherwise>
                                <div title={inheritedShareTarget.objtShareTgtNm}>
                                    <span id={`inheritedShareTarget-${index}-div`} className="name has-name-card">
                                        {inheritedShareTarget.objtShareTgtNm}
                                    </span>
                                </div>
                            </Otherwise>
                        </Choose>
                    </div>
                </div>
            </div>
            <div className="grid-cell inclusion-cell">
                <If condition={inheritedShareTarget.objtShareTgtSectCd === 'DEPARTMENT'}>
                    <a className={`btn btn-sm btn-toggle ${inheritedShareTarget.lwrDeptIcluYn === 'Y' ? 'on' : 'off'} disabled`} role="button">
                        <span className="btn-text">
                            {intl.formatMessage(inheritedShareTarget.lwrDeptIcluYn === 'Y' ? { id: 'drive.button.share.includeLowerDept' } : { id: 'drive.button.share.excludeLowerDept' })}
                        </span>
                    </a>
                </If>
            </div>
            <div className="grid-cell authority-cell">
                <span className="select-type-line">
                    <span className="select-type-line">
                        <div className="dropdown bootstrap-select disabled" style={{ width: 83 }}>
                            <button
                                type="button"
                                className="btn dropdown-toggle disabled btn-light"
                                data-toggle="dropdown"
                                tabIndex="-1"
                                aria-disabled="true"
                                title={authCds[inheritedShareTarget.authCd]}>
                                <div className="filter-option">
                                    <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">{authCds[inheritedShareTarget.authCd]}</div>
                                    </div>{' '}
                                </div>
                            </button>
                        </div>
                    </span>
                </span>
            </div>
            <div className="grid-cell del-cell">
                <If condition={isAccessableInheritedFolder}>
                    <a id={`folderPathTooltip-${index}`} className="btn-ic-nor" role="button" onClick={openInheritedFolder}>
                        <i className="ic-20-folder" />
                    </a>
                </If>
                <If condition={!isAccessableInheritedFolder}>
                    <span id={`folderPathTooltip-${index}`} className="btn-ic-nor disabled">
                        <i className="ic-20-folder" />
                    </span>
                </If>
                <Tooltips targetId={`folderPathTooltip-${index}`} placement="left">
                    <div className="path">{isAccessableInheritedFolder ? inheritedShareTarget.inheritedFolderPathValNm : intl.formatMessage({ id: 'drive.tooltip.share.inaccessibleFolder' })}</div>
                </Tooltips>
            </div>
            <NameCard target={`inheritedShareTarget-${index}-div`} type={inheritedShareTarget.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'dept'} id={inheritedShareTarget.objtShareTgtId} />
        </div>
    );
};

InheritedShareTarget.propTypes = {
    inheritedShareTarget: PropTypes.object,
    index: PropTypes.number,
    style: PropTypes.object,
    intl: PropTypes.object,
    cancelToken: PropTypes.object
};

InheritedShareTarget.defaultProps = {
    inheritedShareTarget: {},
    index: 0,
    style: {},
    intl: {},
    cancelToken: {}
};

export default injectIntl(InheritedShareTarget);
