import React, { Component } from 'react';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CustomList from 'components/presentationals/List/CustomList';
import TeamTree from 'components/containers/TeamTree';
import TeamSearchList from 'components/containers/Popup/TeamSearch/TeamSearchList';
import UserRest from 'apis/UserRest';

import SearchTeamMemberRow from './SearchTeamMemberRow';

class SearchDept extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            isOpenTeamList: false,
            teamMember: [],
            isFocused: false,
            searchedTeam: undefined
        };
    }

    search = () => {
        this.setState({ isOpenTeamList: true });
    };

    onKeyUp = e => {
        if (e.key === 'Enter') {
            this.search();
        }
    };

    onChangeInputValue = e => {
        const value = _.get(e, 'target.value');
        this.setState({ searchText: value });
    };

    closeTeamListDialog = data => {
        this.setState({ isOpenTeamList: false });
        if (data != null) {
            this.selectTeam(data);
        }
    };

    selectTeam = data => {
        if (_.isNil(data.children)) {
            console.log('when it does not have no child');
        }
        this.setState({
            searchedTeam: data
        });

        this.getTeamMember(data);
    };

    getTeamMember = data => {
        const { user } = this.props;
        const deptId = data.id;
        const params = {
            offset: 0,
            userStatCd: 'A',
            tenantId: user.tenantId.toString(),
            deptId
        };

        UserRest.getUser(params).then(response => {
            const list = _.get(response, 'data', []);
            const total = _.get(response, 'total', 0);
            this.setState({
                teamMember: list,
                total
            });
        });
    };

    rowBuilder = ({ style, index }) => {
        const { teamMember } = this.state;
        return <SearchTeamMemberRow user={teamMember[index]} selectUser={this.selectUser} style={style} key={teamMember[index].userId} checkBox={false} />;
    };

    selectUser = selected => {
        const { handleSelect } = this.props;
        const { teamMember } = this.state;
        const listForHover = Object.assign({}, teamMember);
        _.forEach(teamMember, (item, i) => {
            if (item.userId === selected.userId) {
                listForHover[i].isHovered = true;
            } else {
                listForHover[i].isHovered = false;
            }
            listForHover[i].type = 'USER';
        });
        this.setState({ teamMember: listForHover });
        handleSelect(selected);
    };

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    render() {
        const { isOpenTeamList, searchText, teamMember, total, isFocused, searchedTeam } = this.state;
        return (
            <div className="modal-body">
                <div className="forms-container">
                    <div className={`box-input-group has-overlay-layer ${isFocused ? 'is-focused' : ''}`}>
                        <FormattedMessage id="com.search">
                            {title => (
                                <a className="btn-ic-nor btn-func-search" role="button" title={title}>
                                    <i className="ic-16-search" />
                                </a>
                            )}
                        </FormattedMessage>

                        <FormattedMessage id="user.placeHolder.popup.selectPeople.organize.searchDeptName">
                            {placeHolder => (
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={placeHolder}
                                    onFocus={event => this.onFocus(event)}
                                    onKeyUp={this.onKeyUp}
                                    onChange={e => this.onChangeInputValue(e)}
                                />
                            )}
                        </FormattedMessage>

                        <FormattedMessage id="com.text.clear">
                            {title => (
                                <a className="btn-ic-nor btn-func-clear" role="button" title={title}>
                                    <i className="ic-16-clear" />
                                </a>
                            )}
                        </FormattedMessage>

                        <TeamSearchList query={searchText} isOpen={isOpenTeamList} close={this.closeTeamListDialog} />
                    </div>
                </div>
                <div className="srch-flex-layout">
                    <div className="flex-upper">
                        <div className="flex-box-left">
                            <div className="box-line">
                                <TeamTree selectTeam={this.selectTeam} height={314} searchedTeam={searchedTeam} autoHeigth={false} />
                            </div>
                            <div className="flex-box-bottom" />
                        </div>
                        <div className="flex-box-right">
                            <div className="pop-grid-list">
                                <If condition={teamMember.length === 0}>
                                    <div className="box-empty">
                                        <div className="message">
                                            <p>
                                                <FormattedMessage id="com.no-search-results" />
                                            </p>
                                        </div>
                                    </div>
                                </If>
                                <If condition={teamMember.length !== 0}>
                                    <div className="comm-grid-list only-elective react-list">
                                        <div className="grid-header-group">
                                            <div className="grid-row">
                                                <div className="grid-cell name-cell">
                                                    <a role="button" className="column-sort asce">
                                                        <em>
                                                            <FormattedMessage id="com.name" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className="ic-12-arr-asce" />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell position-cell">
                                                    <a role="button" className="column-sort">
                                                        <em>
                                                            <FormattedMessage id="com.position" />
                                                        </em>
                                                        <span className="btn-ic-nor btn-sm">
                                                            <i className="ic-12-arr-desc" />
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="grid-cell email-cell">
                                                    <span className="column-name">
                                                        <FormattedMessage id="com.email" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <CustomList className="grid-row-group row-6" customScroll={true} virtualized={true} height={276} rowCount={total} rowHeight={46} rowBuilder={this.rowBuilder} />
                                    </div>
                                </If>
                            </div>
                            <div className="flex-box-bottom">
                                <div className="total-count">
                                    <span>
                                        <FormattedMessage id="com.text.total" />
                                    </span>
                                    <span className="count">
                                        <i className="num">{total}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchDept;

SearchDept.propTypes = {
    handleSelect: PropTypes.func,
    user: PropTypes.object.isRequired
};

SearchDept.defaultProps = {
    handleSelect: _.noop
};
