import React from 'react';
import BaseMenu from '../BaseMenu';
import SharePopup from '../../../containers/Popup/Share/SharePopup';
import { largePopup } from '../../../../utils/ModalService';
import FileViewerRest from '../../../../apis/FileViewerRest';
import InShareRest from '../../../../apis/InShareRest';
import { DEFAULT_SHARE_AUTH_MAP } from '../../../../constants/ShareAuth';

const executor = async (props, files) => {
    const webViewerYn = await FileViewerRest.getViewerUseYn();
    const shareConfig = (await InShareRest.selectConfig()) || {};
    const config = Object.assign({}, DEFAULT_SHARE_AUTH_MAP, shareConfig);

    largePopup(<SharePopup files={files} webViewerYn={webViewerYn} config={config} />).then(
        data => {
            if (data) {
                props.callback(data);
            }
        },
        () => false
    );
};

class Share extends BaseMenu {
    getName() {
        return 'share';
    }

    getLabel() {
        return 'drive.button.list.menu.share';
    }

    isVisible() {
        return super.isVisible(true, 'viewShareList') && (this.files.length > 1 ? this.files.every(file => file.actionPolicy.manageShare === true) : true);
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-share', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-share', 'ic-20-share', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default Share;
