import { If } from 'jsx-control-statements';
import _ from 'lodash';
import moment from 'moment';
import Tooltips from 'presentationals/Tooltips';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import SelectBox from './SelectBox';
import SingleDatePickerWrapper from './SingleDatePickerWrapper';
import store from '../../redux/store';

const INTERFACE_SYSTEM_CONFIG_ID_PREFIX = 'EFL_AUT_INTERFACE_SYSTEM_NAME_';

const PartnerAccountComponent = ({
    partner,
    languageOptions,
    validation,
    index,
    onChangeValue,
    removePartner,
    onBlurInput,
    onClickClearButton,
    onClickTeamSearch,
    maxUseDays,
    intl,
    configs,
    loginUser,
    useExpireYn
}) => {
    const clearTitle = intl.formatMessage({ id: 'com.text.clear' });
    const requiredFieldMessage = intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.requiredField' });
    const languageCode = _.get(loginUser, 'locale.languageCode', '001');
    const configId = `${INTERFACE_SYSTEM_CONFIG_ID_PREFIX}${languageCode}`;

    const linkedSystemNameConfig = _.find(configs, ['id', configId]);

    const linkedSystemName = linkedSystemNameConfig ? linkedSystemNameConfig.value : '';

    const userIfSystemYnConfig = _.find(configs, ['id', 'EFL_AUTH_USER_IF_YN']);

    const userIfSystemYn = userIfSystemYnConfig ? userIfSystemYnConfig.value : '';

    const partnerIfSystemYnConfig = _.find(configs, ['id', 'EFL_AUTH_SUBCONTRACTOR_IF_YN']);

    const partnerIfSystemYn = partnerIfSystemYnConfig ? partnerIfSystemYnConfig.value : '';

    if (userIfSystemYn !== 'Y' || partnerIfSystemYn !== 'Y') {
        onChangeValue('hasKPAccount', false);
    }

    const storeState = store.getState();
    const { auth: { user: { locale: { timezoneDiff: diff = 0 } = {} } = {} } = {} } = storeState;
    const minDate = moment()
        .utcOffset(diff)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    const maxDate = moment()
        .utcOffset(diff)
        .add(maxUseDays, 'days')
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    return (
        <div className="box-add-line">
            <If condition={index > 0}>
                <a className="btn-ic-nor btn-sm close" role="button" title={intl.formatMessage({ id: 'com.delete' })} onClick={() => removePartner(index)}>
                    <i className="ic-20-close-sm" />
                </a>
            </If>
            <div className={userIfSystemYn === 'Y' && partnerIfSystemYn === 'Y' ? 'dt-width-auto' : ''}>
                <If condition={userIfSystemYn === 'Y' && partnerIfSystemYn === 'Y'}>
                    <dl className="row-list">
                        <dt>
                            <div className="ellipsis" title={intl.formatMessage({ id: 'user.text.reqPartnerAccount.account' }, { linkedSystem: linkedSystemName })}>
                                {intl.formatMessage({ id: 'user.text.reqPartnerAccount.account' }, { linkedSystem: linkedSystemName })}
                            </div>
                        </dt>
                        <dd>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`exampleRadios1-2_${index}`}
                                        checked={partner.hasKPAccount}
                                        onChange={event => onChangeValue('hasKPAccount', event.target.checked)}
                                    />
                                    <span className="label-text">{intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.exist' })}</span>
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`exampleRadios1-2_${index}`}
                                        checked={!partner.hasKPAccount}
                                        onChange={event => onChangeValue('hasKPAccount', !event.target.checked)}
                                    />
                                    <span className="label-text">{intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.none' })}</span>
                                </label>
                            </div>
                        </dd>
                    </dl>
                </If>
            </div>
            <table className="data-input">
                <caption>{intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.signUp' })}</caption>
                <colgroup>
                    <col style={{ width: '131px' }} />
                    <col style={{ width: '195px' }} />
                    <col style={{ width: '130px' }} />
                    <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            ID &nbsp;
                            <em className="critical-item" title="" />
                        </th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.id}
                                        onChange={event => onChangeValue('id', event.target.value)}
                                        onBlur={event => onBlurInput('id', event.target.value)}
                                        maxLength={50}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('id')}
                                        style={{ display: partner.id !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.id === 'empty'}>{requiredFieldMessage}</If>
                                        <If condition={validation.id === 'duplicated'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.alreadyExistEmail' })}</If>
                                        <If condition={validation.id === 'invalid'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.invalidEmail' })}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <th>
                            {intl.formatMessage({ id: 'user.text.popup.reqPartnerForm.language' })}
                            <span className="btn-ic-nor btn-sm" data-toggle="tooltip" data-placement="top" data-trigger="manual" data-html="true" id={`LanguageToolTip-${index}`}>
                                <i className="ic-16-help" />
                            </span>
                            <Tooltips targetId={`LanguageToolTip-${index}`} placement="top">
                                {intl.formatMessage({ id: 'user.tooltip.reqPartnerAccount.selected.language.mail' })}
                            </Tooltips>
                        </th>
                        <td>
                            <span className="select-type-box">
                                <SelectBox options={languageOptions} value={partner.language} onChange={value => onChangeValue('language', value)} dataWidth={195} />
                            </span>
                        </td>
                    </tr>

                    <tr className="ko">
                        <th>
                            {intl.formatMessage({ id: 'com.name' })}
                            &nbsp;
                            <em className="critical-item" title="" />
                        </th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.name}
                                        onChange={event => onChangeValue('name', event.target.value)}
                                        onBlur={event => onBlurInput('name', event.target.value)}
                                        maxLength={50}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('name')}
                                        style={{ display: partner.name !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.name === 'empty'}>{requiredFieldMessage}</If>
                                        <If condition={validation.name === 'invalid'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.invalidText' })}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <th>{intl.formatMessage({ id: 'com.position' })}</th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.position}
                                        onChange={event => onChangeValue('position', event.target.value)}
                                        onBlur={event => onBlurInput('position', event.target.value)}
                                        maxLength={100}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('position')}
                                        style={{ display: partner.position !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.position === 'invalid'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.invalidText' })}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr className="en">
                        <th>
                            {intl.formatMessage({ id: 'user.text.reqPartnerAccount.name.english' })}
                            &nbsp;
                            <em className="critical-item" title="" />
                        </th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.engName}
                                        onChange={event => onChangeValue('engName', event.target.value)}
                                        onBlur={event => onBlurInput('engName', event.target.value)}
                                        maxLength={50}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('engName')}
                                        style={{ display: partner.engName !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.engName === 'empty'}>{requiredFieldMessage}</If>
                                        <If condition={validation.engName === 'invalid'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.invalidText' })}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <th>{intl.formatMessage({ id: 'user.text.reqPartnerAccount.position.english' })}</th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.engPosition}
                                        onChange={event => onChangeValue('engPosition', event.target.value)}
                                        onBlur={event => onBlurInput('engPosition', event.target.value)}
                                        maxLength={100}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('engPosition')}
                                        style={{ display: partner.engPosition !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.engPosition === 'invalid'}>{intl.formatMessage({ id: 'user.text.popup.reqPartnerBundle.invalidText' })}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {intl.formatMessage({ id: 'com.belong-company' })}
                            &nbsp;
                            <em className="critical-item" title="" />
                        </th>
                        <td>
                            <div className="input-group-remark">
                                <div className="box-input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={partner.companyNm}
                                        onChange={event => onChangeValue('companyNm', event.target.value)}
                                        onBlur={event => onBlurInput('companyNm', event.target.value)}
                                        maxLength={100}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={clearTitle}
                                        onClick={() => onClickClearButton('companyNm')}
                                        style={{ display: partner.companyNm !== '' ? 'inline-flex' : 'none' }}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                                <div className="input-remark">
                                    <div className="err-msg">
                                        <If condition={validation.companyNm === 'empty'}>{requiredFieldMessage}</If>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <th>
                            {intl.formatMessage({ id: 'user.text.popup.reqPartnerAccount.team' })}
                            <em className="critical-item" title="" />
                        </th>
                        <td>
                            <div className="input-group-remark">
                                <div className="forms-container">
                                    <div className="box-input-group readonly">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={intl.formatMessage({ id: 'user.tab.popup.selectPeople.searchDepartment' })}
                                            value={partner.deptNm}
                                            readOnly={true}
                                        />
                                        <a className="btn-ic-nor btn-func-clear" role="button" title={clearTitle}>
                                            <i className="ic-16-clear" />
                                        </a>
                                    </div>
                                    <a className="btn-ic-line" role="button" onClick={onClickTeamSearch}>
                                        <i className="ic-20-search-organize" />
                                    </a>
                                </div>
                            </div>
                            <div className="input-remark">
                                <div className="err-msg">
                                    <If condition={validation.deptId === 'empty'}>{requiredFieldMessage}</If>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <If condition={!partner.hasKPAccount && useExpireYn === 'Y'}>
                        <tr>
                            <th>{intl.formatMessage({ id: 'user.text.popup.partnerDetail.expirationDate' })}</th>
                            <td>
                                <SingleDatePickerWrapper
                                    initialDate={partner.expireDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    date={partner.expireDate}
                                    onDateChange={date => onChangeValue('expireDate', date)}
                                    block={true}
                                />
                            </td>
                            <th />
                            <td />
                        </tr>
                    </If>
                </tbody>
            </table>
        </div>
    );
};

PartnerAccountComponent.propTypes = {
    partner: PropTypes.object.isRequired,
    languageOptions: PropTypes.array.isRequired,
    validation: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    removePartner: PropTypes.func.isRequired,
    onBlurInput: PropTypes.func.isRequired,
    onClickClearButton: PropTypes.func.isRequired,
    onClickTeamSearch: PropTypes.func.isRequired,
    maxUseDays: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
    configs: PropTypes.array.isRequired,
    loginUser: PropTypes.object.isRequired,
    useExpireYn: PropTypes.string.isRequired
};

export default injectIntl(PartnerAccountComponent);
