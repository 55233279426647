import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FilterCheckbox from './FilterCheckbox';
import { ALL, RAW_VALUES_SEPARATOR } from './FilterConstants';

const FilterCheckboxGroup = memo(({ groupInfo, rawSelectedValues, onChangeRawSelectedValues }) => {
    const values = useMemo(() => {
        if (_.isEmpty(rawSelectedValues)) {
            return [];
        }

        if (rawSelectedValues === ALL) {
            return groupInfo.map(({ value }) => value);
        }

        return rawSelectedValues.split(RAW_VALUES_SEPARATOR);
    }, [rawSelectedValues]);

    const isChecked = useCallback(
        value => {
            return values.some(v => v === value);
        },
        [values]
    );

    const handleChange = ({ value, checked }) => {
        const newValues = checked ? [...values, value] : [values.filter(v => v !== value)];

        if (newValues.length === groupInfo.length) {
            onChangeRawSelectedValues(ALL);
            return;
        }

        const newRawValues = newValues.join(RAW_VALUES_SEPARATOR);

        onChangeRawSelectedValues(newRawValues);
    };

    return (
        <div className="form-check-group">
            <div className="form-col-2">
                {groupInfo.map(({ label, value }) => (
                    <FilterCheckbox key={value} label={label} value={value} isChecked={isChecked} onChange={handleChange} />
                ))}
            </div>
        </div>
    );
});

FilterCheckboxGroup.propTypes = {
    groupInfo: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ).isRequired,
    rawSelectedValues: PropTypes.string,
    onChangeRawSelectedValues: PropTypes.func.isRequired
};

FilterCheckboxGroup.defaultProps = {
    rawSelectedValues: ''
};

export default FilterCheckboxGroup;
