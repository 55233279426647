import { all, call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import ConfigsRest from 'apis/ConfigsRest';
import AuthRest from 'apis/AuthRest';
import { SET_SETTINGS, SET_TOKEN_STATUS, SET_USER, TOKEN_STATUS } from '../actions/Auth';
import { SET_CONFIGS } from '../actions/Config';
import { SET_POLICY } from '../actions/FileLifeCycle';

const langCdMap = {
    '001': 'ko',
    '002': 'en',
    '003': 'ge'
};

function* setLanguage(action) {
    let langCd = '001'; // default
    if (action.type === SET_TOKEN_STATUS && action.tokenStatus === TOKEN_STATUS.AUTH) {
        langCd = _.get(action, 'user.langCd', langCd);
    } else if (action.type === SET_USER) {
        langCd = _.get(action, 'user.langCd', langCd);
    } else if (action.type === SET_SETTINGS) {
        langCd = action.languageCode;
        // again set login user by new lang
        const response = yield call(AuthRest.getLoginInfo);
        const loginUser = _.get(response, 'data.user');
        if (loginUser) {
            yield put({ type: SET_USER, user: loginUser });
        }
    }
    document.querySelector('html').setAttribute('lang', langCdMap[langCd]);
}

function* setConfig(action) {
    if (action.type === SET_TOKEN_STATUS && action.tokenStatus === TOKEN_STATUS.NORMAL) {
        const configs = yield call(ConfigsRest.getConfigs);
        const converted = _.map(configs, config => {
            return { id: config.cfgId, value: config.cfgVal };
        });
        yield put({ type: SET_CONFIGS, configs: converted });
    } else {
        yield put({ type: SET_CONFIGS, configs: {} });
    }
}

function* setPolicy(action) {
    if (action.type === SET_TOKEN_STATUS && action.tokenStatus === TOKEN_STATUS.NORMAL) {
        // FLCM, CPGS 정책 조회 로직 제거
        // const policy = yield call(FileLifeCycleRest.callLifeCyclePolicy);
        const policy = {};
        if (policy) {
            yield put({ type: SET_POLICY, policy });
        }
    } else {
        yield put({ type: SET_POLICY, policy: {} });
    }
}

function* watchSetLanguage() {
    yield takeLatest([SET_TOKEN_STATUS, SET_USER, SET_SETTINGS], setLanguage);
}

function* watchSetTokenStatus() {
    yield takeLatest(SET_TOKEN_STATUS, setConfig);
}

function* watchSetPolicy() {
    yield takeLatest(SET_TOKEN_STATUS, setPolicy);
}

export default function* rootSaga() {
    yield all([watchSetLanguage(), watchSetTokenStatus(), watchSetPolicy()]);
}
