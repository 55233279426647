import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SUMMARY_SCOPE_TYPE, SUMMARY_SCOPE_TYPES } from '../../SummaryOptionConstant';
import { summaryActions } from '../../../../../../redux/actions/Summary';
import ScopeOptionItem from './ScopeOptionItem';
import ScopeOptionKeywordItem from './ScopeOptionKeywordItem';

const ScopeOptionGroup = () => {
    const selectedScopeType = useSelector(({ summary }) => summary.optionSelectDialog.summaryOptions.scopeType);
    const dispatch = useDispatch();

    const handleSelect = value => {
        dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { scopeType: value } }));
    };

    return (
        <dl className="row-list flex-wrap">
            <dt>
                <FormattedMessage id="drive.text.copilot.summary.option.scopeType" />
            </dt>
            <dd>
                <div className="copilot-form-check-group">
                    {SUMMARY_SCOPE_TYPES.map(type => {
                        switch (type) {
                            case SUMMARY_SCOPE_TYPE.document:
                                return <ScopeOptionItem type={type} selectedScopeType={selectedScopeType} onSelect={handleSelect} key={type} />;
                            case SUMMARY_SCOPE_TYPE.keyword:
                                return <ScopeOptionKeywordItem type={type} selectedScopeType={selectedScopeType} onSelect={handleSelect} key={type} />;
                            default:
                                throw new Error('Unknown summary scopeType type');
                        }
                    })}
                </div>
            </dd>
        </dl>
    );
};

ScopeOptionGroup.propTypes = {};

ScopeOptionGroup.defaultProps = {};

export default ScopeOptionGroup;
