import $http from 'modules/Http';

const prefix = '/common/v1/helpcenter/terms';

class TermsRest {
    static getPrivacy(params) {
        return $http.get(`${prefix}/privacy`, { params });
    }

    static getServiceTerms(params) {
        return $http.get(`${prefix}/service`, { params });
    }

    static getTerm(termsId) {
        return $http.get(`${prefix}/${termsId}`);
    }

    static getTermsFinals() {
        return $http.get(`${prefix}/finals`);
    }

    static getAcceptTermsFinals() {
        return $http.get(`${prefix}/acceptList`);
    }
}

export default TermsRest;
