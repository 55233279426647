import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseMenu from '../BaseMenu';
import Tooltips from '../../Tooltips';

class NoAuthWorkgroup extends BaseMenu {
    getName() {
        return 'noAuthWorkgroup';
    }

    isVisible() {
        return this.files.some(file => {
            return file.drive.objtSectCd === 'FOLDER' && file.drive.onpstSectCd === 'SYSTFOLDER' && file.maxAuthCd === 'NONE';
        });
    }

    isEnable() {
        return true;
    }

    getIconDom() {
        return (
            <div className="help-msg" key={this.getName()}>
                <FormattedMessage id="drive.button.list.menu.includeNoAuthWorkgroup" />
                <i className="ic-16-help" data-toggle="tooltip" id="includeNoAuthWorkgroupTooltip" />

                <Tooltips targetId="includeNoAuthWorkgroupTooltip" placement="bottom">
                    <div className="guide" key={this.getName().concat('_tooltip')}>
                        <FormattedMessage id="drive.button.list.menu.includeNoAuthWorkgroup.detail" />
                        <br />
                        <FormattedMessage id="drive.button.list.menu.includeNoAuthWorkgroup.detail2" />
                    </div>
                </Tooltips>
            </div>
        );
    }

    execute = () => {};
}

export default NoAuthWorkgroup;
