import _ from 'lodash';
import BaseMenu from '../BaseMenu';
import Request from '../../../../constants/Request';
import RequestPopup from '../../../containers/Popup/RequestPopup';
import FileReload from '../../../../utils/FileReload';

const CANT_REQ_STATE_CODES = ['PROCESSING', 'APPROVAL'];

const executor = (props, files) => {
    RequestPopup.open({
        mode: Request.MODE.REQUEST,
        type: Request.TYPE.EXCEPT_NON_COMPLIANCE,
        target: files
    }).then(
        () => {
            // props.callback(result);
            FileReload.call(null, files, { reloadType: 'cached', reloadReq: true });
        },
        () => {}
    );
};

class RequestException extends BaseMenu {
    getName() {
        return 'requestException';
    }

    getLabel() {
        return 'drive.button.list.menu.requestException';
    }

    isVisible() {
        return (
            super.isVisible(true, null, 'FILE') &&
            this.files.every(file => {
                if (_.isEmpty(file) || _.isEmpty(file.config) || _.isEmpty(file.config.drive) || file.config.cpgsChangeRequestPossibleYn === 'N') return false;
                const { complianceRequestStateCode = '' } = file.config.drive;
                const { extra: { req: { reqStatCd = '' } = {} } = {} } = file;
                const requestStateCode = reqStatCd !== '' ? reqStatCd : complianceRequestStateCode;
                return CANT_REQ_STATE_CODES.indexOf(requestStateCode) === -1;
            })
        );
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-exception', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-exception', 'ic-20-exception', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default RequestException;
