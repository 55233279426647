import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { If } from 'jsx-control-statements';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomList from 'components/presentationals/List/CustomList';
import SelectedUserRow from './SelectedUserRow';
import SelectedDeptRow from './SelectedDeptRow';

class SelectedUsers extends PureComponent {
    onSelectCheckboxAll = event => {
        const { handleCheckboxAll } = this.props;
        handleCheckboxAll(event.target.checked, true);
        event.stopPropagation();
    };

    clickWideButton = () => {
        const { onClickWideButton } = this.props;
        onClickWideButton();
    };

    deleteTargetByClick = () => {
        const { deleteTargets, selected } = this.props;
        const selectedItems = _.filter(selected, { checked: true });
        if (selectedItems.length !== 0) {
            deleteTargets(selectedItems);
        }
    };

    render() {
        const { selectedTotal, intl, selected, deleteTargetDept, checkSelectedUser, selectedAllChecked, deleteTargetUser, selectedGridHeightOnBoardView, boardView, cancelToken } = this.props;
        const rowBuilder = ({ style, index }) => {
            return (
                <div>
                    <If condition={!_.isEmpty(selected) && selected[index].type === 'USER'}>
                        <SelectedUserRow
                            user={selected[index]}
                            index={index}
                            checkSelectedUser={checkSelectedUser}
                            style={style}
                            key={selected[index].userId + selected[index].checked}
                            checkBox={true}
                            delBox={true}
                            onDeleteBoxClick={deleteTargetUser}
                            selectedGridHeightOnBoardView={selectedGridHeightOnBoardView}
                            cancelToken={cancelToken}
                        />
                    </If>
                    <If condition={!_.isEmpty(selected) && selected[index].type.startsWith('DEPT')}>
                        <SelectedDeptRow
                            dept={selected[index]}
                            checkSelectedUser={checkSelectedUser}
                            style={style}
                            checkBox={true}
                            delBox={true}
                            onDeleteBoxClick={deleteTargetDept}
                            index={index}
                            handleSelect={this.handleSelect}
                            addTarget={this.addTarget}
                            key={selected[index].deptId}
                            selectedGridHeightOnBoardView={selectedGridHeightOnBoardView}
                        />
                    </If>
                </div>
            );
        };
        return (
            <div className="flex-lower">
                <div className="pop-grid-list hd-gray">
                    <div className="comm-grid-list react-list">
                        <div className="grid-header-group">
                            <div className="grid-row">
                                <div className="grid-cell check-cell">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onClick={event => this.onSelectCheckboxAll(event)}
                                                checked={selectedAllChecked}
                                                disabled={selected.length === 0}
                                            />
                                            <span className="label-text" />
                                        </label>
                                    </div>
                                </div>
                                <div className="grid-cell selected-cell">
                                    <ul className="li-divider-list">
                                        <li>
                                            <span className="title">
                                                <FormattedMessage id="com.selected-list" />
                                            </span>
                                        </li>
                                        <li>
                                            <div className="total-count">
                                                <span>
                                                    <FormattedMessage id="com.text.total" />
                                                </span>
                                                <span className="count">
                                                    <i className="num">{selectedTotal}</i>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="btn-ic-nor btn-sm" role="button" onClick={this.deleteTargetByClick}>
                                                <i className="ic-12-delete" />
                                                <span className="btn-text">
                                                    <FormattedMessage id="com.delete" />
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="grid-cell del-cell">
                                    <a
                                        className={`btn-ic-nor btn-func-toggle-view${boardView ? ' on' : ''}`}
                                        onClick={event => this.clickWideButton(event)}
                                        role="button"
                                        title={`${boardView ? intl.formatMessage({ id: 'com.button.popup.fold' }) : intl.formatMessage({ id: 'com.button.popup.expand' })}`}
                                        data-boardview=".srch-flex-layout"
                                        data-title-on="접어보기"
                                        data-title-off="펼쳐보기">
                                        <i className="ic-20-slide-up" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <If condition={selectedTotal !== 0}>
                            <div className="scroll">
                                <div style={{ height: '500px !important' }}>
                                    <CustomList
                                        className="grid-row-group row-3"
                                        virtualized={true}
                                        height={selectedGridHeightOnBoardView}
                                        rowCount={selectedTotal}
                                        rowHeight={46}
                                        rowBuilder={rowBuilder}
                                    />
                                </div>
                            </div>
                        </If>
                        <If condition={selectedTotal === 0}>
                            <div style={{ height: `${selectedGridHeightOnBoardView}px` }}>
                                <div className="box-empty row-3">
                                    <div className="message">
                                        <p>
                                            <FormattedMessage id="user.guideText.popup.selectPeople.noSelectedItem" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(SelectedUsers);

SelectedUsers.propTypes = {
    selectedTotal: PropTypes.number,
    selected: PropTypes.array,
    deleteTargetUser: PropTypes.func,
    deleteTargetDept: PropTypes.func,
    deleteTargets: PropTypes.func,
    checkSelectedUser: PropTypes.func,
    handleCheckboxAll: PropTypes.func,
    selectedAllChecked: PropTypes.bool,
    onClickWideButton: PropTypes.func,
    selectedGridHeightOnBoardView: PropTypes.number,
    boardView: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    cancelToken: PropTypes.object
};

SelectedUsers.defaultProps = {
    selectedTotal: 0,
    selected: [],
    deleteTargetUser: _.noop,
    deleteTargetDept: _.noop,
    deleteTargets: _.noop,
    checkSelectedUser: _.noop,
    handleCheckboxAll: _.noop,
    selectedAllChecked: false,
    onClickWideButton: _.noop,
    selectedGridHeightOnBoardView: 138,
    boardView: false,
    cancelToken: {}
};
