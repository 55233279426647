import SiteAction from '../actions/Site';

const initialState = {
    logo: null,
    solutionName: null
};

const siteState = Object.assign({}, initialState);

export const getLogo = () => {
    return siteState.logo;
};

export const getSolutionName = () => {
    return siteState.solutionName;
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SiteAction.SET_LOGO:
            if (action.logo) {
                siteState.logo = action.logo;
                return siteState;
            }
            return initialState;
        case SiteAction.SET_SOLUTION_NAME:
            if (action.solutionName) {
                siteState.solutionName = action.solutionName;
                return siteState;
            }
            return initialState;
        case SiteAction.SET_SITE_MODE:
            if (action.siteMode) {
                siteState.siteMode = action.siteMode;
                return siteState;
            }
            return initialState;
        default:
            return state;
    }
};
