import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import { $http } from 'modules/index';
import InLinkRest from '../../../apis/InLinkRest';
import FileIconStyle from '../../../utils/FileIconStyle';
import Url from '../../../utils/Url';
import FileDownload from '../Download/Download';
import { popup, viewerPopup, alert } from '../../../utils/ModalService';
import FileViewerPopup from '../../containers/Popup/FileViewerPopup';
import FileIcon from '../FileMenu/FileIcon';

class IncomLink extends Component {
    constructor(props) {
        super(props);
        // console.log('props: ', props);
        this.state = {
            incomLink: {},
            folderFile: {},
            config: {},
            explorerPathNm: '',
            incomLinkUrl: '',
            complianceYn: 'N',
            authCd: '',
            webViewerYn: 'Y'
        };
    }

    componentDidMount() {
        $http.get(`/drive/v1/configs/EFL_DRV_FILEVIEWER_USE_YN`).then(response => {
            console.log(response.data);
            this.setState({ webViewerYn: response.data });
        });
        this.getIncomLinkData();
    }

    getIncomLinkData = async () => {
        // const { match } = this.props;
        // const { incomLinkUrl } = match.params;
        const { incomLinkUrl, close, location, push, intl } = this.props;

        const incomLinkUrlArray = incomLinkUrl.split('_');
        // console.log('incomLinkUrlArray >> ', incomLinkUrlArray);

        let incomLink = '';
        let chgIncomLinkUrl = '';
        let complianceYn = 'N';

        if (incomLinkUrlArray.length > 1) {
            // 파일일 경우
            chgIncomLinkUrl = `${incomLinkUrlArray[0]}_${incomLinkUrlArray[1]}`;
            if (incomLinkUrlArray.length > 2) {
                [, , complianceYn] = incomLinkUrlArray;
            }
        } else {
            // 폴더일 경우
            [chgIncomLinkUrl] = incomLinkUrlArray;
        }

        // console.log('chgIncomLinkUrl >> ', chgIncomLinkUrl);

        incomLink = await InLinkRest.getIncomLinkInfo(chgIncomLinkUrl);

        console.log('incomLink result: ', incomLink);

        const { resultCode, data } = incomLink;

        if (resultCode !== 200) {
            let msg = '';
            if (incomLinkUrl.indexOf('_') > -1) {
                msg = 'drive.alert.incomLink.noAuthFile';
            } else {
                msg = 'drive.alert.incomLink.noAuthFolder';
            }
            alert(msg).then(() => {
                const { search } = location;
                if (search === '?redirectHome=true') {
                    close();
                    push('/home');
                } else {
                    this.close();
                }
            });
            return;
        }

        const { authCd, folderFile, explorerPathNm, browserPathId, fileLifecycleUsability } = data;

        const browserPathNmArray = _.split(browserPathId, '/');

        let config = null;
        if (folderFile.objtSectCd === 'FILE' && !_.isNil(fileLifecycleUsability)) {
            config = {
                drive: {
                    expirationFuncLimitYn: fileLifecycleUsability.limitByFlcm,
                    complianceFuncLimitYn: fileLifecycleUsability.limitByCpgs
                }
            };

            if (config.drive.complianceFuncLimitYn) {
                // 1) 공유권한없이 옵션에 의해 파일링크 화면 접근 시
                if (authCd === null && folderFile.incomLinkUnautPermCd !== null) {
                    // Non-Compliance 용어가 검출된 파일입니다. 해당파일의 조회/다운로드가 불가합니다.
                    alert(intl.formatMessage({ id: 'drive.alert.shareLink.cpgs' }));
                }

                // 2) 공유권한이 있어, 권한 있는 사용자의 파일링크 화면 접근 시
                // 2-1) 조회까지만 가능한 경우
                if (authCd !== null && !data.sharePolicy.downloadInLink) {
                    // Non-Compliance 용어가 검출된 파일입니다. 해당파일의 다운로드가 불가합니다.
                    alert(intl.formatMessage({ id: 'drive.alert.shareLink.cpgsViewAuth' }));
                }
                // 2-2) 다운로드 가능 이상인 경우
                if (authCd !== null && data.sharePolicy.downloadInLink) {
                    // Non-Compliance 용어가 검출된 파일입니다.
                    alert(intl.formatMessage({ id: 'drive.alert.shareLink.cpgsDownAuth' }));
                }
            }

            if (config.drive.expirationFuncLimitYn) {
                alert(intl.formatMessage({ id: 'drive.alert.shareLink.flcm' }));
            }
        }

        this.setState({ incomLink: data, folderFile, explorerPathNm, incomLinkUrl: chgIncomLinkUrl, complianceYn, browserPathNmArray, config, authCd });

        if (folderFile.objtSectCd === 'FOLDER') {
            // 폴더링크인 경우 해당 폴더 경로로 이동
            this.moveFileList();
            close();
        }

        // 해당 링크가 권한자만 접근 가능한 링크이면 권한체크 수행 -> 앞단 sharePolicy 에서 api 호출부터 403 이 떨어지므로 해당 조건 체크 불필요
        /* if (folderFile.objtSectCd === 'FILE' && folderFile.incomLinkUnautPermCd === null) {
            if (authCd === null) {
                // 비권한자의 링크 접근. 오류 얼럿 출력.
                alert('drive.alert.incomLink.noAuthFolder').then(() => {
                    this.close();
                });
            }
        } */
    };

    setExpFileOpenParam(folderFile) {
        const param = [];
        param.push(folderFile.tenantId);
        param.push(folderFile.onpstId);
        param.push('Login');
        param.push('NONE');
        // param.push(incomLink.explorerPathNm);

        return param;
    }

    moveFileList = () => {
        const { folderFile, browserPathNmArray } = this.state;
        const { loginUser, push, close } = this.props;
        // onpstId 가 현재사용자의 userId와 같으면 내소유 | 다르면 공유받은폴더
        let toShareFolder = '';
        if (folderFile.onpstSectCd === 'SYSTFOLDER') toShareFolder = Url.workgroup;
        else if (folderFile.onpstSectCd === 'SHAREDFOLDER' || browserPathNmArray[1] === '111300000000000000') toShareFolder = Url.shared;
        else toShareFolder = Url.sharing;

        const toFolder = folderFile.onpstId === loginUser.id ? Url.own : toShareFolder;
        // const url = toFolder.concat('/').concat(folderFile.onpstFolderId);
        let url = folderFile.objtSectCd === 'FOLDER' ? toFolder.concat('/').concat(folderFile.objtId) : toFolder.concat('/').concat(folderFile.onpstFolderId);
        // 이거말고 router 써야할거같다능. 새로고침되면서 pages의 객체나 로그인이 이상해질수도 있어서 교체
        // window.location.replace(url);

        // 폴더 권한은 없고 파일만 공유 된 경우 :  shared/111100000000000000 가야함.
        if (browserPathNmArray.length === 2 && browserPathNmArray[1] === '111300000000000000') {
            // browserPathNmArray.length <= 3 에서 === 2로 수정함.
            // Shared files 하위폴더의 browserPathNmArray가 3이기때문에 해당 조건에 걸리고, 최상위 폴더로 넘어감. 2020.11.20
            url = Url.shared.concat('/').concat('111100000000000000');
        }

        push(url);
        // console.log('>>>>>>>>>>url', url);
        setTimeout(() => {
            close();
        }, 500);
    };

    goFileViewer = () => {
        const { folderFile, incomLinkUrl, complianceYn } = this.state;
        viewerPopup(<FileViewerPopup type="LINK_NORMAL" objtId={folderFile.objtId} incomLinkUrl={incomLinkUrl} complianceYn={complianceYn} />).then(
            () => {
                return true;
            },
            () => {
                return false;
            }
        );
    };

    async downloadFile() {
        const { folderFile, incomLinkUrl } = this.state;
        const { callback } = this.props;
        // let fileVerSno = fileVer != null ? fileVer.fileVerSno : null;

        popup(<FileDownload folderFileList={folderFile} incomLinkUrl={incomLinkUrl} />).then(data => {
            if (data) {
                // props.callback(data);
                callback(data);
            }
        });
    }

    moreCallBack = () => {
        // const { drive: folderFile } = file;
        // console.log(folderFile); // FileIcon에 넣었던 형태
    };

    fileIconCustom = {
        // statusCallback: isOpen => {
        //     setHover(isOpen);
        // },
        openType: 'left',
        moreGroupType: 'inLink'
    };

    close = () => {
        const { push, close } = this.props;
        close();
        push('/home');
    };

    getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    render() {
        const { intl } = this.props;
        const { incomLink, folderFile, explorerPathNm, config, authCd, webViewerYn } = this.state;
        const showMyAuth = _.isNil(incomLink.sharePolicy) ? false : incomLink.sharePolicy.viewInLinkUrl;

        // console.log('folderFile.  ', folderFile);
        // console.log('incomLink.  ', incomLink);

        const downloadBtnYn = _.isNil(incomLink.sharePolicy) ? false : folderFile.incomLinkUnautPermCd === 'DOWNLOAD' && !config.drive.complianceFuncLimitYn && !incomLink.sharePolicy.downloadInLink;

        let viewerBtnYn = true;

        if (_.isNil(incomLink.sharePolicy)) {
            viewerBtnYn = false;
        } else {
            if (folderFile.incomLinkUnautPermCd === null && !incomLink.sharePolicy.viewFileWithViewer) {
                viewerBtnYn = false;
            }

            if ((folderFile.incomLinkUnautPermCd === 'DOWNLOAD' || folderFile.incomLinkUnautPermCd === 'VIEW') && !incomLink.sharePolicy.viewFileWithViewer && config.drive.complianceFuncLimitYn) {
                viewerBtnYn = false;
            }
        }

        const replacedPathName = explorerPathNm === null ? '' : explorerPathNm.replace('\\', '').replace(/\\/gi, '>');

        if (!folderFile.objtNm || folderFile.objtSectCd === 'FOLDER') return '';

        const fileUsers = !_.isNil(incomLink.objtStatChgUser) ? _.pick(incomLink, 'objtStatChgUser') : {};

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.incomLink.title" />
                    </h5>
                    <FormattedMessage id="com.close">
                        {closeTitle => (
                            <a
                                className="btn-ic-nor"
                                role="button"
                                title={closeTitle}
                                data-dismiss="modal"
                                onClick={() => {
                                    this.close();
                                }}>
                                <i className="ic-20-close" />
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <div className="modal-body">
                    <div className="box-line">
                        <div className="inner-grid-view">
                            <div className="grid-cell file-cell">
                                <div className="item-file">
                                    <i className={FileIconStyle.getStyle('ic-file-sm', folderFile.objtNm)} />
                                    <div className="file" title={folderFile.objtNm}>
                                        <span className="file-name">{this.getFileName(folderFile.objtNm)}</span>
                                        <span className="file-extension">{this.getExtension(folderFile.objtNm)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-cell option-cell">
                                <ul className="item-option nav">
                                    {downloadBtnYn && (
                                        <li className="option-download">
                                            <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'drive.button.list.menu.download' })}>
                                                <i className="ic-20-download" role="button" onClick={() => this.downloadFile()} />
                                            </a>
                                        </li>
                                    )}
                                    <li className="option-more">
                                        <FileIcon
                                            groupType="inLink"
                                            file={{ drive: folderFile, actionPolicy: incomLink.sharePolicy, config, maxAuthCd: authCd, users: fileUsers }}
                                            callback={this.moreCallBack}
                                            custom={this.fileIconCustom}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showMyAuth && (
                        <div className="dt-width-120">
                            <dl className="row-list text-info">
                                <dt>
                                    <FormattedMessage id="drive.title.incomLink.filePathLabel" />
                                </dt>
                                <dd>
                                    <div className="dd-text">
                                        <div className="route">
                                            <a className="path" role="button" title={replacedPathName} onClick={() => this.moveFileList()}>
                                                {replacedPathName}
                                            </a>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="row-list text-info">
                                <dt>
                                    <FormattedMessage id="drive.title.incomLink.myAuthLabel" />
                                </dt>
                                <dd>
                                    <div className="dd-text">{incomLink.authCdNm}</div>
                                </dd>
                            </dl>
                        </div>
                    )}
                </div>
                <If condition={webViewerYn === 'Y'}>
                    <div className="modal-footer">
                        <div className="modal-footer-option" />
                        {viewerBtnYn && (
                            <div className="modal-footer-btns">
                                <a className="btn btn-lg btn-primary" role="button" onClick={() => this.goFileViewer()}>
                                    <span className="btn-text">
                                        <FormattedMessage id="drive.button.incomLink.fileViewer" />
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                </If>
            </React.Fragment>
        );
    }
}

IncomLink.propTypes = {
    // match: PropTypes.object,
    incomLinkUrl: PropTypes.string,
    loginUser: PropTypes.object,
    callback: PropTypes.func,
    push: PropTypes.func,
    close: PropTypes.func,
    location: PropTypes.object,
    intl: PropTypes.object
};
IncomLink.defaultProps = {
    // match: {},
    incomLinkUrl: {},
    loginUser: {},
    callback: () => {},
    push: () => {},
    close: () => {},
    location: {},
    intl: {}
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    push: routerPush
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(IncomLink));
