import $http from 'modules/Http';

const prefix = '/auth/v1/terms';

class BrityTermsRest {
    static getBrityTermsCntry(langCd) {
        console.log('getBrityTermsCntry', langCd);
        // const input = { agreeForNewTerms: 'N' };

        return $http.post(`${prefix}/brity/cntry?langCd=${langCd}`, {});
    }

    static getBrityTerms(cntryCd, langCd) {
        console.log('getBrityTerms', langCd);
        return $http.post(`${prefix}/brity?cntryCd=${cntryCd}&langCd=${langCd}`, {});
    }

    static agreeBrityTerms(langCd, params) {
        return $http.post(`${prefix}/brityAgree?cntryCd=${langCd}`, params);
    }

    // 개인정보 처리방침 팝업

    static getBrityTermsVerList() {
        console.log('getBrityTermsVerList');
        // const input = { agreeForNewTerms: 'N' };
        return $http.post(`${prefix}/brity/privacy`, {});
    }

    static getBrityTermsDetail(trmsCd, trmsVerVal) {
        console.log('getBrityTermsDetail', trmsCd, trmsVerVal);
        return $http.post(`${prefix}/brity/detail?trmsCd=${trmsCd}&trmsVerVal=${trmsVerVal}`, {});
    }
}

export default BrityTermsRest;
