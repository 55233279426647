import React, { Fragment, useEffect } from 'react';
import AuthService from 'utils/AuthService';
import PropTypes from 'prop-types';

const RedirectPage = props => {
    const { user } = props;

    let redirectUrl = window.location.origin;
    const { pathname, search } = AuthService.getPathnameAndSearch();
    // const userId = AuthService.getUserId();
    AuthService.setUserId(user ? user.id : '');
    redirectUrl = redirectUrl.concat(pathname);
    redirectUrl = redirectUrl.concat(search);

    AuthService.removePathnameAndSearch();

    useEffect(() => {
        window.location.replace(redirectUrl);
        // if (user && user.id === userId) {
        //     window.location.replace(redirectUrl);
        // }
    });

    return <Fragment />;
};

RedirectPage.propTypes = {
    user: PropTypes.object
};

RedirectPage.defaultProps = {
    user: {}
};

export default RedirectPage;
