import CustomModal from 'containers/CustomModal';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../redux/actions';

export const Confirm = props => {
    const { isOpen, message, closeConfirm } = props;
    return (
        <CustomModal isOpen={isOpen} fade={false} size="sm" backdrop="static">
            <div className="modal-body">
                <dl className="col-list">
                    <dt className="nor ht-md">{message}</dt>
                </dl>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a
                        className="btn btn-lg btn-secondary"
                        role="button"
                        data-dismiss="modal"
                        onClick={() => {
                            closeConfirm(false);
                        }}>
                        <span className="btn-text">
                            <FormattedMessage id="com.cancel" />
                        </span>
                    </a>
                    <a
                        className="btn btn-lg btn-primary"
                        role="button"
                        onClick={() => {
                            closeConfirm(true);
                        }}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

Confirm.propTypes = {
    isOpen: bool.isRequired,
    message: string.isRequired,
    closeConfirm: func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.confirm.isOpen,
    message: state.globalPopup.confirm.message
});

const connected = connect(
    mapStateToProps,
    { closeConfirm: actions.globalPopup.closeConfirm }
)(Confirm);

export default connected;
