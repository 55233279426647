const PREFIX = 'UPLOAD_';
export const SELECT_FILE = PREFIX.concat('SELECT_FILE');
export const ADD_FILE = PREFIX.concat('ADD_FILE');
export const OPEN_INPUT = PREFIX.concat('OPEN_INPUT');

export const addFile = addFiles => ({
    type: ADD_FILE,
    addFiles
});

export const slectFile = (files, metadata) => ({
    type: SELECT_FILE,
    files,
    metadata
});

export const openInput = (folder, executor) => ({
    type: OPEN_INPUT,
    folder,
    executor
});
