import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import UserRest from 'apis/UserRest';
import CustomModal from 'containers/CustomModal';
import CharacterImage from 'containers/CharacterImage/CharacterImageContainer';
import CustomList from 'presentationals/List/CustomList';
import { globalPopup } from 'src/redux/actions';
import GlobalPopupService from 'src/redux/service/GlobalPopup';

const RowBuilder = ({ style, row: member, languageCode }) => {
    const userNm = languageCode === '001' ? member.userNm : member.userEngNm;
    const userCopNm = languageCode === '001' ? member.userCopNm : member.userCopEngNm;

    return (
        <div className="grid-row" style={style}>
            <div className="grid-cell user-cell">
                <div className="item-user">
                    <CharacterImage objtId={member.userPhotoFilePathVal} userId={member.userId} text={userNm} size="ss" userType={member.userSectCd} />
                    <div className="user-profile">
                        <div title={`${userNm} ${userCopNm}`}>
                            <span className="name">{userNm}</span>
                            <span className="position">{userCopNm}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

RowBuilder.propTypes = {
    style: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired
};

const DeptMemberPopup = ({ isOpen, deptId, deptName, closeMemberPopup, intl, languageCode }) => {
    const [memberList, setMemberList] = useState([]);

    const rowBuilder = ({ style, index }) => {
        return RowBuilder({ row: memberList[index], style, languageCode });
    };

    useEffect(() => {
        if (isOpen) {
            UserRest.getUser({ deptId }).then(resolve => {
                const { message, data, resultCode } = resolve;
                if (resultCode === 200) {
                    setMemberList(data);
                } else {
                    GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
                }
            });
        }
    }, [deptId]);

    return (
        <CustomModal isOpen={isOpen} fade={true} backdrop="static" className="modal-md">
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id="user.title.show.member" values={{ dept: deptName }} />
                </h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={closeMemberPopup}>
                    <i className="ic-20-close" />
                </a>
            </div>
            <ModalBody>
                <div className="pop-grid-list">
                    <div className="comm-grid-list react-list">
                        <CustomList className="grid-row-group row-8" customScroll={true} virtualized={true} height={350} rowCount={memberList.length || 0} rowHeight={46} rowBuilder={rowBuilder} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter />
        </CustomModal>
    );
};

DeptMemberPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    deptId: PropTypes.string.isRequired,
    deptName: PropTypes.string.isRequired,
    closeMemberPopup: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    languageCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isOpen: _.get(state.globalPopup.deptMember, 'isOpen'),
    deptId: _.get(state.globalPopup.deptMember, 'deptId'),
    deptName: _.get(state.globalPopup.deptMember, 'deptName')
});

const mapDispatchToProps = {
    closeMemberPopup: globalPopup.closeDeptMemberPopup
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DeptMemberPopup)
);
