import React, { PureComponent } from 'react';
import uuidV4 from 'uuid/v4';
import _ from 'lodash';

class ModalFactory extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            modals: {},
            uniqueStack: []
        };
        this.defaultOptions = {
            exitTimeout: 2000,
            enterTimeout: 200
        };
    }

    getModals = () => {
        const { modals, uniqueStack } = this.state;
        const keys = Object.keys(modals);

        const mapKeys = keys.map(key => {
            const { Component, props, close } = modals[key];
            return <Component {...props} key={key} close={close} open={Boolean(uniqueStack.find(h => h === key))} />;
        });

        return mapKeys;
    };

    create = (Component, options = {}) => {
        return props =>
            new Promise((promiseResolve, promiseReject) => {
                const uuid = uuidV4();
                const resultOptions = { ...this.defaultOptions, ...options };
                const close = value => {
                    this.delete(uuid);
                    if (value) promiseResolve(value);
                    else promiseReject();
                };

                const { modals, uniqueStack } = this.state;
                this.setState(
                    {
                        modals: {
                            ...modals,
                            [uuid]: {
                                Component,
                                props,
                                close,
                                ...resultOptions
                            }
                        }
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ uniqueStack: [...uniqueStack, uuid] });
                        }, resultOptions.enterTimeout);
                    }
                );
            });
    };

    delete = uuid => {
        const {
            modals: { uuid: target },
            uniqueStack
        } = this.state;
        const exitTimeout = target && target.exitTimeout;
        this.setState(
            {
                uniqueStack: uniqueStack.filter(h => h !== uuid)
            },
            () => {
                setTimeout(this.omitState, exitTimeout);
            }
        );
    };

    omitState = uuid => {
        const { modals } = this.state;
        const rest = _.omit(modals, [uuid]);
        this.setState({ modals: rest });
    };

    render() {
        return <>{this.getModals()}</>;
    }
}

export default ModalFactory;
