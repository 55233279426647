import React from 'react';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FileIconStyle from '../../../../../utils/FileIconStyle';

const ShareObject = ({ file, style }) => {
    if (_.isEmpty(file)) return '';

    const getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    const getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    return (
        <div className="grid-row" style={style}>
            <div className="grid-cell file-cell">
                <div className="item-file">
                    <If condition={file.objtSectCd === 'FOLDER'}>
                        <i className="ic-file-sm ic-file-FOLDER" />
                    </If>
                    <If condition={file.objtSectCd === 'FILE'}>
                        <i className={FileIconStyle.getStyle('ic-file-sm', file.objtNm)} />
                    </If>
                    <div className="file" title={file.objtNm}>
                        <If condition={file.objtSectCd === 'FOLDER'}>
                            <span className="file-name">{file.objtNm}</span>
                        </If>
                        <If condition={file.objtSectCd === 'FILE'}>
                            <span className="file-name">{getFileName(file.objtNm)}</span>
                            <span className="file-extension">{getExtension(file.objtNm)}</span>
                        </If>
                    </div>
                </div>
            </div>
        </div>
    );
};

ShareObject.propTypes = {
    file: PropTypes.object,
    style: PropTypes.object
};

ShareObject.defaultProps = {
    file: {},
    style: {}
};

export default ShareObject;
