import React from 'react';
import PropTypes from 'prop-types';
import FileIconStyle from '../../../../utils/FileIconStyle';

class RequestFileItem extends React.PureComponent {
    render() {
        const { file, style } = this.props;
        const fileFullNm = file.drive.objtNm;
        const fileNm = fileFullNm.substr(0, fileFullNm.lastIndexOf('.'));
        const fileExtNm = fileFullNm.substr(fileFullNm.lastIndexOf('.') + 1);
        const extensionIcon = FileIconStyle.getStyle('ic-file-sm', fileFullNm);

        return (
            <>
                <div className="grid-row" style={style}>
                    <div className="grid-cell file-cell">
                        <div className="item-file">
                            <i className={extensionIcon} />
                            <div className="file" title={fileFullNm}>
                                <span className="file-name">{fileNm}</span>
                                <span className="file-extension">{fileExtNm}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

RequestFileItem.propTypes = {
    file: PropTypes.object.isRequired,
    style: PropTypes.object
};

RequestFileItem.defaultProps = {
    style: {}
};

export default RequestFileItem;
