import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import ReleaseNoteRest from 'apis/ReleaseNoteRest';
import GlobalPopupService from '../../redux/service/GlobalPopup';
import ReleaseNotePopup from '../containers/Popup/ReleaseNotePopup';

const LIMIT = 50;

const ReleaseNote = props => {
    const { intl } = props;
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [releasenoteList, setReleasenoteList] = useState([]);
    const [releasenoteTotal, setReleasenoteTotal] = useState(1);
    const [searching, setSearching] = useState(false);
    const [searchedKeyword, setSearchedKeyword] = useState('');
    const [searchInputFocus, setSearchInputFocus] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const scrollToTop = () => window.scrollTo(0, 0);

    const doSearch = () => {
        ReleaseNoteRest.getReleasenotes({ keyword: searchedKeyword, page, limit: LIMIT }).then(resolve => {
            const { resultCode, message, data } = resolve;
            if (_.isEmpty(searchedKeyword)) {
                setSearching(false);
                setKeyword('');
            } else {
                setSearching(true);
                setKeyword(searchedKeyword);
            }

            if (resultCode === 200) {
                const { list, total } = data;

                if (page === 1) {
                    scrollToTop();
                    setReleasenoteList(list);
                } else {
                    setReleasenoteList([...releasenoteList, ...list]);
                }

                setReleasenoteTotal(total);

                if ((page - 1) * LIMIT + list.length < total) {
                    setViewMore(true);
                } else {
                    setViewMore(false);
                }
                // if (list.length === LIMIT) {
                //
                //     setViewMore(true);
                // } else {
                //     setViewMore(false);
                // }
            } else {
                GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
            }
        });
    };

    const doClear = () => {
        setPage(1);
        setKeyword(null);
        setSearchedKeyword(null);
    };

    useEffect(() => {
        doSearch();
    }, [page, searchedKeyword]);

    const onKeywordChange = ({ target }) => {
        const { value } = target;
        setKeyword(value);
    };

    const inputKeyUpEvent = ({ key }) => {
        if (!keyword || keyword.trim().length === 0) return;
        if (key === 'Enter') {
            setPage(1);
            setSearchedKeyword(keyword.trim());
        }
    };

    const openReleaseNotePopup = id => {
        setIsOpen(true);
        setSelectedId(id);
    };

    const closeReleaseNotePopup = () => {
        setIsOpen(false);
        setSelectedId(null);
    };

    const getDate = dateString => {
        return moment(dateString).format('YYYY-MM-DD');
    };

    return (
        <>
            <div className="comm-title-bar js-for-fixed">
                <div className="fixed-container">
                    <div className="fixed">
                        {searching && (
                            <div className="section-srch-result">
                                <a className="btn-ic-nor btn-func-back" role="button" title={intl.formatMessage({ id: 'com.tooltip.go.back' })} onClick={doClear}>
                                    <i className="ic-20-arrow-back" />
                                </a>
                                <div className="total-count">
                                    <span className="count">
                                        <FormattedMessage id="user.helpcenter.resultCount" values={{ n: <i className="num">{releasenoteTotal}</i> }} />
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="section-srch">
                            <div className={`box-input-group ${searchInputFocus ? 'is-focused' : ''}`}>
                                <a
                                    className="btn-ic-nor btn-func-search"
                                    role="button"
                                    title={intl.formatMessage({ id: 'com.search' })}
                                    onClick={() => {
                                        if (!keyword || keyword.trim().length === 0) return;
                                        setSearchedKeyword(keyword);
                                    }}>
                                    <i className="ic-16-search" />
                                </a>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={intl.formatMessage({ id: 'user.placeHolder.release.note' })}
                                    value={keyword}
                                    onChange={onKeywordChange}
                                    onKeyUp={inputKeyUpEvent}
                                    onFocus={() => setSearchInputFocus(true)}
                                    onBlur={() => setSearchInputFocus(false)}
                                />
                                {!_.isEmpty(keyword) && (
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        style={{ display: 'inline-flex' }}
                                        role="button"
                                        title={intl.formatMessage({ id: 'com.text.clear' })}
                                        onClick={() => setKeyword('')}>
                                        <i className="ic-16-clear" />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="help-view-conts">
                {_.isEmpty(releasenoteList) ? (
                    <div className="box-empty">
                        <div className="message">
                            <span className={searching ? 'ic-empty-search' : 'ic-empty-release'} />
                            <p>
                                <FormattedMessage id={searching ? 'user.text.release.note.empty.searching' : 'user.text.release.note.empty'} />
                            </p>
                        </div>
                    </div>
                ) : (
                    <ul className="notice-list">
                        {releasenoteList.map(releasenote => (
                            <li>
                                <div className="notice-title">
                                    <a role="button" onClick={() => openReleaseNotePopup(releasenote.relnoteId)}>
                                        {searching && !_.isEmpty(searchedKeyword) ? (
                                            <Highlighter searchWords={[searchedKeyword]} autoEscape={true} textToHighlight={releasenote.relnoteTitle} />
                                        ) : (
                                            <>{releasenote.relnoteTitle}</>
                                        )}
                                    </a>
                                </div>
                                <em className="update">{getDate(releasenote.relnoteRegDt)}</em>
                            </li>
                        ))}
                    </ul>
                )}
                {viewMore && (
                    <a className="btn btn-secondary btn-block" role="button" onClick={() => setPage(page + 1)}>
                        <span className="btn-text">
                            <FormattedMessage id="com.viewmore" />
                        </span>
                    </a>
                )}
            </div>
            {isOpen && <ReleaseNotePopup id={selectedId} isOpen={isOpen} closeCallback={closeReleaseNotePopup} />}
        </>
    );
};

export default injectIntl(ReleaseNote);
