import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class ReqDtlBodyRsn extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, reason = '' } = this.props;
        const convertedReason = reason.replace(/\s?<br\s?\/>\s?/g, '\n');

        return (
            <React.Fragment>
                <dl className="row-list">
                    <dt>
                        <FormattedMessage id={title} />
                    </dt>
                    <dd>
                        <div className="form-group">
                            <textarea className="form-control textarea-sm" readOnly={true} value={convertedReason} />
                        </div>
                    </dd>
                </dl>
            </React.Fragment>
        );
    }
}

ReqDtlBodyRsn.propTypes = {
    title: PropTypes.string,
    reason: PropTypes.string
};

ReqDtlBodyRsn.defaultProps = {
    title: '',
    reason: ''
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlBodyRsn));

export default connected;
