import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { If } from 'jsx-control-statements';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { summaryActions } from '../../../../../../redux/actions/Summary';
import useDebounce from '../../../../../hooks/UseDebounce';
import { showSummaryToast } from '../../../Common/Util/SummaryToastUtil';

const FileInput = memo(({ fileInputIndex, intl }) => {
    const fileInputRef = useRef();

    const [enteredFileName, setEnteredFileName] = useState('');
    const [isFileSelected, setIsFileSelected] = useState(false);

    // const isSidePanelOpen = useSelector(({ summary }) => summary.sidePanel.isOpen);
    const focusedFileInputIndex = useSelector(({ summary }) => summary.fileListBox.fileInputIndex);
    const files = useSelector(({ summary }) => summary.promptInput.promptTemplate.templateData.files);

    const file = useMemo(() => {
        return files[fileInputIndex];
    }, [files]);

    const dispatch = useDispatch();

    const debouncedFileName = useDebounce(enteredFileName, 300);

    const unselectFile = needDispatch => {
        setEnteredFileName('');
        setIsFileSelected(false);
        fileInputRef.current.textContent = '';

        if (needDispatch) {
            const newFiles = [...files];
            newFiles[fileInputIndex] = null;

            dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files: newFiles } }));
        }
    };

    const openFileListBoxAndRequestFiles = () => {
        dispatch(summaryActions.fileListBox.open({ fileInputIndex, fileInputRef }));

        if (enteredFileName) {
            dispatch(summaryActions.fileListBox.searchByFileName({ searchedFileName: enteredFileName }));
            return;
        }

        dispatch(summaryActions.fileListBox.retrieveRecentFiles());
    };

    const handleFocusFileInput = () => {
        if (isFileSelected) {
            return;
        }

        openFileListBoxAndRequestFiles();
    };

    const handleInputFileName = e => {
        const fileName = e.target.textContent;

        setEnteredFileName(fileName);

        if (!_.isEmpty(fileName)) {
            return;
        }

        fileInputRef.current.innerHTML = '';
    };

    const handleKeyDown = e => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                e.preventDefault();
                break;
            default:
                break;
        }
    };

    const handleClickUnselectButton = () => {
        unselectFile(true);
    };

    useEffect(() => {
        if (_.isNil(file)) {
            unselectFile(false);
            return;
        }

        if (files.length === 2 && _.uniqBy(files, 'objtId').length === 1) {
            unselectFile(true);
            showSummaryToast(dispatch, { messageId: 'drive.toast.copilot.summary.duplicateFile' });

            return;
        }

        const { name, extensionName } = file;

        setEnteredFileName(name);
        setIsFileSelected(true);

        fileInputRef.current.textContent = _.isNil(extensionName) ? name : `${name}.${extensionName}`;
    }, [file]);

    useEffect(() => {
        if (isFileSelected || fileInputIndex !== focusedFileInputIndex) {
            return;
        }

        openFileListBoxAndRequestFiles();
    }, [debouncedFileName]);

    return (
        <div className="mention instant-files">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
                ref={fileInputRef}
                contentEditable={!isFileSelected}
                placeholder={intl.formatMessage({ id: 'drive.placeHolder.copilot.summary.prompt.file_select' })}
                spellCheck="false"
                onInput={handleInputFileName}
                onFocus={handleFocusFileInput}
                onKeyDown={handleKeyDown}
            />
            <If condition={isFileSelected}>
                <a className="mention-close" role="button" title={intl.formatMessage({ id: 'com.delete' })} onClick={handleClickUnselectButton}>
                    <i className="ic-16-copilot-close" />
                </a>
            </If>
        </div>
    );
});

FileInput.propTypes = {
    fileInputIndex: PropTypes.number
};

FileInput.defaultProps = {
    fileInputIndex: 0
};

export default injectIntl(FileInput);
