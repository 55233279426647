import React from 'react';
import { Choose, When, If } from 'jsx-control-statements';
import _ from 'lodash';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CustomList from '../../List/CustomList';
import Owner from './row/Owner';
import ShareTarget from './row/ShareTarget';
import InheritedShareTarget from './row/InheritedShareTarget';

const InShareList = ({ isMulti, shareTargets, isView, rowCount, changeShareTargetFn, deleteShareTargetFn, isLoading, config, cancelToken }) => {
    const changeShareTargetInfo = (objtShareTgtId, newAuthCd, newLwrDeptIcluYn, newEvtSectCd) => {
        changeShareTargetFn(objtShareTgtId, newAuthCd, newLwrDeptIcluYn, newEvtSectCd);
    };

    const deleteShareTarget = objtShareTgtId => {
        deleteShareTargetFn(objtShareTgtId);
    };

    const rowBuilder = ({ style, index }) => {
        rowBuilder.propTypes = {
            style: PropTypes.object,
            index: PropTypes.number
        };

        rowBuilder.defaultProps = {
            style: {},
            index: 0
        };

        return (
            <Choose>
                <When condition={shareTargets[index].authCd === 'U80OWN'}>
                    <Owner owner={shareTargets[index]} style={style} key={`${shareTargets[index].objtShareTgtId}-${shareTargets[index].inheritedFolderId}`} />
                </When>
                <When condition={!shareTargets[index].inheritedAuth}>
                    <ShareTarget
                        shareTarget={shareTargets[index]}
                        index={index}
                        isView={isView}
                        style={style}
                        key={`${shareTargets[index].objtShareTgtId}-${shareTargets[index].inheritedFolderId}`}
                        changeShareTargetFn={changeShareTargetInfo}
                        deleteShareTargetFn={deleteShareTarget}
                        isMulti={isMulti}
                        config={config}
                        cancelToken={cancelToken}
                    />
                </When>
                <When condition={shareTargets[index].inheritedAuth}>
                    <InheritedShareTarget
                        inheritedShareTarget={shareTargets[index]}
                        index={index}
                        style={style}
                        key={`${shareTargets[index].objtShareTgtId}-${shareTargets[index].inheritedFolderId}`}
                        config={config}
                        cancelToken={cancelToken}
                    />
                </When>
            </Choose>
        );
    };

    return (
        <div className="srch-grid-list">
            <div className="comm-grid-list react-list">
                <If condition={shareTargets.length > 0}>
                    <CustomList
                        className="grid-row-group scroll-bar"
                        customScroll={false}
                        virtualized={true}
                        height={65 * rowCount}
                        rowCount={shareTargets.length}
                        rowHeight={65}
                        rowBuilder={rowBuilder}
                    />
                </If>
                <If condition={shareTargets.length === 0}>
                    <div className="scroll-bar">
                        <div className={`box-empty row-${rowCount}`}>
                            <If condition={!isLoading}>
                                <div className="message">
                                    <FormattedHTMLMessage id={isMulti ? 'drive.text.share.noAddMultiTargets' : 'drive.text.share.noAddTargets'} tagName="p" />
                                </div>
                            </If>
                        </div>
                    </div>
                </If>
            </div>
        </div>
    );
};

InShareList.propTypes = {
    shareTargets: PropTypes.array,
    isMulti: PropTypes.bool,
    isView: PropTypes.bool,
    rowCount: PropTypes.number,
    changeShareTargetFn: PropTypes.func,
    deleteShareTargetFn: PropTypes.func,
    isLoading: PropTypes.bool,
    config: PropTypes.object,
    cancelToken: PropTypes.object
};

InShareList.defaultProps = {
    shareTargets: [],
    isMulti: false,
    isView: true,
    rowCount: 5,
    changeShareTargetFn: _.noop,
    deleteShareTargetFn: _.noop,
    isLoading: true,
    config: {},
    cancelToken: {}
};

export default injectIntl(InShareList);
