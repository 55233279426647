import _ from 'lodash';
import { flow, map, pickBy, sortBy, join, toPairs } from 'lodash/fp';

import CryptoJS from 'crypto-js';

const URL_TEST = /^(\/)?(.+)$/;

class SignatureUtil {
    generateSignature(config, chip) {
        let origin = this.parseUrl(config.url);
        const { params, data } = config;
        if (params) {
            if (_.isObject(params)) {
                const newParam = _.cloneDeep(params);
                newParam.tuid = null; // remove tuid hmac data
                origin += this.parseParam(newParam);
            } else {
                origin += params;
            }
        }
        if (data && config.method !== 'get') {
            origin += JSON.stringify(data);
        }

        return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(origin, chip));
    }

    isTarget(param) {
        if (_.isNil(param)) {
            return false;
        }
        if (_.isObject(param) && _.isEmpty(param)) {
            return false;
        }
        return true;
    }

    manipulate(param) {
        if (_.isArray(param)) {
            return param.join('');
        }
        if (_.isObject(param)) {
            return JSON.stringify(param);
        }
        return param;
    }

    parseUrl(url) {
        const [path, params] = url.split('?');
        let parsed = path.replace(URL_TEST, '/$2');

        if (_.isNil(params)) {
            return parsed;
        }

        parsed += flow(
            map(pair => {
                if (pair.split('=')[1] === undefined) {
                    return '';
                }
                return decodeURIComponent(pair.split('=')[1]);
            }),
            map(value => {
                return value.replace(/,/g, '');
            }),
            join('')
        )(params.split('&'));

        return parsed;
    }

    parseParam(params) {
        return flow(
            pickBy(this.isTarget),
            toPairs,
            sortBy(0),
            map(1),
            map(this.manipulate),
            join('')
        )(params);
    }

    parseForm(data) {
        return flow(
            pickBy(this.isTarget),
            map(param => {
                if (_.isArray(param)) {
                    return param.join('');
                }
                if (_.isObject(param)) {
                    return this.parseForm(param);
                }
                return param;
            }),
            join('')
        )(data);
    }

    // AES256 encrypt
    encrypt(data, key) {
        return CryptoJS.AES.encrypt(data, key).toString();
    }

    // AES256 decrypt
    decrypt(encData, key) {
        const bytes = CryptoJS.AES.decrypt(encData, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

export default new SignatureUtil();
