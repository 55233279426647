import _ from 'lodash';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../SummaryConstant';
import { SUMMARY_FORM_TYPE, SUMMARY_LENGTH_TYPE, SUMMARY_SCOPE_TYPE } from '../../OptionSelectDialog/SummaryOptionConstant';

function isFileSelected({ files }, validFileLength) {
    if (!_.isArray(files)) {
        return false;
    }

    const isValidFileLength = files.length === validFileLength;
    const isAllFileValid = _.every(files, _.negate(_.isNil));

    return isValidFileLength && isAllFileValid;
}

function isValidKeyword({ keyword }) {
    return !_.isNil(keyword) && !_.isEmpty(keyword);
}

export function checkValidity(promptTemplate) {
    if (_.isNil(promptTemplate)) {
        return false;
    }

    const { promptTemplateType, templateData } = promptTemplate;

    if (_.isNil(templateData)) {
        return false;
    }

    if (_.isEmpty(promptTemplateType)) {
        return false;
    }

    switch (promptTemplateType) {
        case SUMMARY_PROMPT_TEMPLATE_TYPE.single:
            return isFileSelected(templateData, 1);
        case SUMMARY_PROMPT_TEMPLATE_TYPE.singleWithKeyword:
            return isFileSelected(templateData, 1) && isValidKeyword(templateData);
        case SUMMARY_PROMPT_TEMPLATE_TYPE.multiple:
            return isFileSelected(templateData, 2);
        case SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword:
            return isFileSelected(templateData, 2) && isValidKeyword(templateData);
        default:
            console.error('Unknown promptTemplateType');
            return false;
    }
}

const defaultSummaryOptions = {
    lengthType: SUMMARY_LENGTH_TYPE.medium,
    formType: SUMMARY_FORM_TYPE.line,
    scopeType: SUMMARY_SCOPE_TYPE.document
};

// TODO summaryOptions 정리
export const convertToUserMessage = (promptTemplate, llmInfo) => {
    const { promptTemplateType, templateData } = promptTemplate;
    const { files, keyword } = templateData;

    switch (promptTemplateType) {
        case SUMMARY_PROMPT_TEMPLATE_TYPE.single:
            return {
                summaryOptions: {
                    ...defaultSummaryOptions,
                    ...llmInfo
                },
                files
            };
        case SUMMARY_PROMPT_TEMPLATE_TYPE.singleWithKeyword:
            return {
                summaryOptions: {
                    ...defaultSummaryOptions,
                    ...llmInfo,
                    scopeType: SUMMARY_SCOPE_TYPE.keyword,
                    scopeTypeKeyword: keyword
                },
                files
            };
        case SUMMARY_PROMPT_TEMPLATE_TYPE.multiple:
            return {
                summaryOptions: {
                    ...defaultSummaryOptions,
                    ...llmInfo
                },
                files
            };
        case SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword:
            return {
                summaryOptions: {
                    ...defaultSummaryOptions,
                    ...llmInfo,
                    scopeType: SUMMARY_SCOPE_TYPE.keyword,
                    scopeTypeKeyword: keyword
                },
                files
            };
        default:
            throw new Error('Unknown promptTemplateType');
    }
};

export const getFileIconByExtensionName = extensionName => {
    const iconClasses = [
        { className: 'ic-file-WORD', extensions: ['doc', 'docx', 'docm', 'dotx', 'dot'] },
        { className: 'ic-file-PPT', extensions: ['ppt', 'pptx', 'pptm', 'ppsx', 'pps', 'potx', 'pot'] },
        { className: 'ic-file-PDF', extensions: ['pdf'] },
        { className: 'ic-file-EXCEL', extensions: ['xls', 'xlsx', 'xlsb', 'xlsm', 'csv', 'xlt', 'xltx'] },
        { className: 'ic-file-IMAGE', extensions: ['bmp', 'jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff'] },
        { className: 'ic-file-VIDEO', extensions: ['avi', 'mp4', 'wmv', 'nar', 'ray', 'mov', 'mpeg', 'mpg'] },
        { className: 'ic-file-NOTEPAD', extensions: ['rft', 'txt'] },
        { className: 'ic-file-HWP', extensions: ['hwp', 'hml', 'hst', 'hwt'] },
        { className: 'ic-file-WEB', extensions: ['html', 'mht', 'htm'] },
        { className: 'ic-file-PSD', extensions: ['psd'] },
        { className: 'ic-file-XD', extensions: ['xd'] },
        { className: 'ic-file-AI', extensions: ['ai'] },
        { className: 'ic-file-ZIP', extensions: ['zip', 'apk', 'rar', '7z', 'alz'] }
    ];

    const [{ className: iconClassName } = {}] = iconClasses.filter(({ extensions }) => extensions.includes(extensionName));

    return iconClassName || '';
};
