const KNOX_DRIVE = 'KNOX_DRIVE';
const KNOX_APPROVAL = 'KNOX_APPROVAL';
const FLCM = 'FLCM';

const SYSTEM_TYPE = {
    KNOX_DRIVE,
    KNOX_APPROVAL,
    FLCM
};

const TYPE = {
    EXCEPT_NON_COMPLIANCE: 'EXCEPT_NON_COMPLIANCE',
    EXTENDS_EXPIRATION: 'EXTENDS_EXPIRATION',
    USE_SECURE_FOLDER: 'USE_SECURE_FOLDER',
    CREATE_WORKGROUP: 'CREATE_WORKGROUP',
    WORKGROUP_RETENTION_DATE: 'WORKGROUP_RETENTION_DATE',
    EXCEPT_PC_CONTROL: 'EXCEPT_PC_CONTROL',
    PARTNER_ACCOUNT_RESISTRATION: 'PARTNER_ACCOUNT_RESISTRATION',
    PARTNER_EXPIRE_EXTENTION: 'PARTNER_EXPIRE_EXTENTION',
    CREATE_OUT_LINK: 'CREATE_OUT_LINK'
};

const MODE = {
    REQUEST: 'REQUEST',
    VIEW: 'VIEW'
};

export default {
    SYSTEM_TYPE,
    TYPE,
    MODE
};
