/* eslint-disable */
import extend from 'extend';
import AuthService from 'utils/AuthService';
import FileItem from './FileItem';
import FileLikeObject from './FileLikeObject';
import BlobFile from './BlobFile';
import Pipeline from './Pipeline';
import Signature from '../SignatureUtil';
import Filters from './Filters';
import Config from './Config';
import moment from 'moment';

class FileUploader {
    constructor(options) {
        extend(
            this,
            {
                url: '/drive/v1/upload',
                alias: 'multipartFile',
                headers: {},
                queue: [],
                progress: 0,
                autoUpload: true,
                removeAfterUpload: false,
                method: 'POST',
                filters: [],
                formData: {},
                queueLimit: Number.MAX_VALUE,
                withCredentials: false,
                disableMultipart: false,
                type: null,
                defaultFilters: ['quelimit'],
                configs: {}
            },
            options,
            {
                isUploading: false,
                _nextIndex: 0,
                _uidIndex: 0
            }
        );

        // 최초 생성 시 config 조회 해 놓자..
        Config.getConfig();

        if (Array.isArray(this.defaultFilters)) {
            this.defaultFilters.forEach(name => {
                this.filters.unshift({ name, fn: Filters[name] });
            });
        }
    }

    /**
     * Adds items to the queue
     * @param {File|HTMLInputElement|Object|FileList|Array<Object>} files
     * @param {Object} [options]
     * @param {Array<Function>|String} filters
     */
    async addToQueue(files, options, filters) {
        this.configs = await Config.getConfig();
        let incomingQueue = Array.isArray(files) ? files : [files];
        const arrayOfFilters = this._getFilters(filters);
        const count = this.queue.length;
        const addedFileItems = [];
        const filterdFileItems = [];
        let slicedQueue = [];

        let done = () => {
            if (this.queue.length !== count) {
                this._onAfterAddingAll(addedFileItems);
                this.progress = this._getTotalProgress();
            }

            if (filterdFileItems.length > 0) {
                this._onWhenAddingFileFailedAll(filterdFileItems);
            }

            this._render();
            if (this.autoUpload) this.uploadAll();
        };

        let slice;

        const next = () => {
            const something = slicedQueue.shift();

            if (!something) {
                return slice();
            }

            if (!this.isFile(something) && !this.isBlobFile(something)) {
                return done();
            }

            const file = something;

            const pipeline = new Pipeline([...arrayOfFilters]);

            const onThrown = err => {
                const [file, options] = err.args;
                filterdFileItems.push({ item: file, filter: err.pipe, options });
                this._onWhenAddingFileFailed(file, err.pipe, options);
                next();
            };

            const onSuccessful = (file, options) => {
                const fileItem = new FileItem(this, file, options);
                addedFileItems.push(fileItem);
                this.queue.push(fileItem);
                this._onAfterAddingFile(fileItem);
                next();
            };

            pipeline.onThrown = onThrown;
            pipeline.onSuccessful = onSuccessful;
            pipeline.exec(file, options, this);

            return () => {};
        };

        let i = 0;
        let j = 0;

        slice = () => {
            if (incomingQueue.length > j) {
                i = j;
                j = j + 200;
                slicedQueue = incomingQueue.slice(i, j);
                next();
            } else {
                done();
            }
        };

        slice();
    }

    /**
     * Remove items from the queue. Remove last: index = -1
     * @param {FileItem|Number} value
     */
    removeFromQueue(value) {
        const index = this.getIndexOfItem(value);
        const item = this.queue[index];
        if (item.isUploading) item.cancel();
        this.queue.splice(index, 1);

        this.onAfterRemoveFile();
    }

    /**
     * Remove items from the queue. Remove last: index = -1
     * @param {uid} value
     */
    removeFromQueueByKey(uid) {
        const index = this.findIndex(uid);
        const item = this.queue[index];
        if (item.isUploading) item.cancel();
        this.queue.splice(index, 1);

        this.onAfterRemoveFile();
    }

    /**
     * Clears the queue
     */
    clearQueue() {
        while (this.queue.length) {
            this.queue[0].remove();
        }
        this.progress = 0;

        this.onAfterClearQueue();
    }

    /**
     * Uploads a item from the queue
     * @param {FileItem|Number} value
     */
    uploadItem(value) {
        const index = this.getIndexOfItem(value);
        const item = this.queue[index];

        item._prepareToUploading();
        if (this.isUploading) return;

        this._onBeforeUploadItem(item);
        if (item.isCancel) return;

        item.isUploading = true;
        this.isUploading = true;
        this._xhrTransport(item);
        this._render();
    }

    /**
     * Cancels uploading of item from the queue
     * @param {FileItem|Number} value
     */
    cancelItem(value) {
        const index = this.getIndexOfItem(value);
        const item = this.queue[index];
        if (!item) return;
        // item.isCancel = true;
        if (item.isUploading) {
            // It will call this._onCancelItem() & this._onCompleteItem() asynchronously
            item._xhr.abort();
            if (!!item._xhrs && Array.isArray(item._xhrs)) {
                item._xhrs.forEach(xhr => xhr.abort());
            }
        } else {
            const dummy = [undefined, 0, {}];
            const onNextTick = () => {
                this._onCancelItem(item, ...dummy);
                this._onCompleteItem(item, ...dummy);
            };
            setTimeout(onNextTick); // Trigger callbacks asynchronously (setImmediate emulation)
        }
    }

    /**
     * Uploads all not uploaded items of queue
     */
    uploadAll() {
        const items = this.getNotUploadedItems().filter(item => !item.isUploading);

        if (!items.length) return;

        items.forEach(item => item._prepareToUploading());
        items[0].upload();
    }

    /**
     * Cancels all uploads
     */
    cancelAll() {
        const items = this.getNotUploadedItems();
        items.forEach(item => item.cancel());
    }

    /**
     * Returns "true" if value an instance of File
     * @param {*} value
     * @returns {Boolean}
     * @private
     */
    isFile(value) {
        return this.constructor.isFile(value);
    }

    /**
     * Returns "true" if value an instance of FileLikeObject
     * @param {*} value
     * @returns {Boolean}
     * @private
     */
    isFileLikeObject(value) {
        return this.constructor.isFileLikeObject(value);
    }

    isBlobFile(value) {
        return this.constructor.isBlobFile(value);
    }

    /**
     * Returns "true" if value is array like object
     * @param {*} value
     * @returns {Boolean}
     */
    isArrayLikeObject(value) {
        return this.constructor.isArrayLikeObject(value);
    }

    /**
     * Returns a index of item from the queue
     * @param {Item|Number} value
     * @returns {Number}
     */
    getIndexOfItem(value) {
        return this.queue.indexOf(value);
    }

    /**
     * Returns a index of item from the queue
     * @param {uid} uid
     * @returns {Number}
     */
    findIndex(uid) {
        return this.queue.findIndex(item => item.uid === uid);
    }

    /**
     * Returns not uploaded items
     * @returns {Array}
     */
    getNotUploadedItems() {
        return this.queue.filter(item => !item.isUploaded);
    }

    /**
     * Returns items ready for upload
     * @returns {Array}
     */
    getReadyItems() {
        return this.queue.filter(item => item.isReady && !item.isUploading).sort((item1, item2) => item1.index - item2.index);
    }

    /** ********************
     * Callback
     ********************* */
    /**
     * Callback
     */
    onRender() {}

    /**
     * Callback
     * @param {Array} fileItems
     */
    onAfterAddingAll(fileItems) {}

    /**
     * Callback
     * @param {FileItem} fileItem
     */
    onAfterAddingFile(fileItem) {}

    /**
     * Callback
     * @param {File|Object} item
     * @param {Object} filter
     * @param {Object} options
     */
    onWhenAddingFileFailed(item, filter, options) {}

    /**
     * Callback
     * @param {Array} item, filter, options
     */
    onWhenAddingFileFailedAll(items) {}

    /**
     * Callback
     */
    onAfterRemoveFile() {}

    /**
     * Callback
     */
    onAfterClearQueue() {}

    /**
     * Callback
     * @param {FileItem} fileItem
     */
    onBeforeUploadItem(fileItem) {}

    /**
     * Callback
     * @param {FileItem} fileItem
     * @param {Number} progress
     */
    onProgressItem(fileItem, progress) {}

    /**
     * Callback
     * @param {Number} progress
     */
    onProgressAll(progress) {}

    /**
     * Callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     */
    onSuccessItem(item, response, status, headers) {}

    /**
     * Callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     */
    onErrorItem(item, response, status, headers) {}

    /**
     * Callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     */
    onCancelItem(item, response, status, headers) {}

    /**
     * Callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     */
    onCompleteItem(item, response, status, headers) {}

    /**
     * Callback
     */
    onCompleteAll() {}

    /** ********************
     * PRIVATE
     ********************* */
    /**
     * Returns the total progress
     * @param {Number} [value]
     * @returns {Number}
     * @private
     */
    _getTotalProgress(value) {
        if (this.removeAfterUpload) return value || 0;

        const notUploaded = this.getNotUploadedItems().length;
        const uploaded = notUploaded ? this.queue.length - notUploaded : this.queue.length;
        const ratio = 100 / this.queue.length;
        const current = ((value || 0) * ratio) / 100;

        return Math.round(uploaded * ratio + current);
    }

    /**
     * Returns array of filters
     * @param {Array<Function>|String} filters
     * @returns {Array<Function>}
     * @private
     */
    _getFilters(filters) {
        if (!filters) return this.filters;
        if (Array.isArray(filters)) return filters;
        const names = filters.match(/[^\s,]+/g);
        return this.filters.filter(filter => names.indexOf(filter.name) !== -1);
    }

    /**
     * Updates html
     * @private
     */
    _render() {
        this.onRender();
    }

    /**
     * Returns "true" if item is a file (not folder)
     * @param {File|FileLikeObject} item
     * @returns {Boolean}
     * @private
     */
    _folderFilter(item) {
        return !!(item.size || item.type);
    }

    /**
     * Returns "true" if the limit has not been reached
     * @returns {Boolean}
     * @private
     */
    _queueLimitFilter() {
        return this.queue.length < this.queueLimit;
    }

    /**
     * Checks whether upload successful
     * @param {Number} status
     * @returns {Boolean}
     * @private
     */
    _isSuccessCode(status, response) {
        if (status === 200 && !!response) {
            return true;
        }
        return false;

        // return (status >= 200 && status < 300) || status === 304;
    }

    /**
     * Transforms the server response
     * @param {*} response
     * @param {Object} headers
     * @returns {*}
     * @private
     */
    _transformResponse(response, headers) {
        //const headersGetter = this._headersGetter(headers);
        // forEach($http.defaults.transformResponse, (transformFn) => {
        //     response = transformFn(response, headersGetter);
        // });
        // return response;
        return response;
    }

    /**
     * Parsed response headers
     * @param headers
     * @returns {Object}
     * @see https://github.com/angular/angular.js/blob/master/src/ng/http.js
     * @private
     */
    _parseHeaders(headers) {
        const parsed = {};
        let key;
        let val;
        let i;

        if (!headers) return parsed;

        headers.split('\n').forEach(line => {
            i = line.indexOf(':');
            key = line
                .slice(0, i)
                .trim()
                .toLowerCase();
            val = line.slice(i + 1).trim();

            if (key) {
                parsed[key] = parsed[key] ? `${parsed[key]}, ${val}` : val;
            }
        });

        return parsed;
    }

    /**
     * Returns function that returns headers
     * @param {Object} parsedHeaders
     * @returns {Function}
     * @private
     */
    _headersGetter(parsedHeaders) {
        return name => {
            if (name) {
                return parsedHeaders[name.toLowerCase()] || null;
            }
            return parsedHeaders;
        };
    }

    /**
     * The XMLHttpRequest transport
     * @param {FileItem} item
     * @private
     */
    _xhrTransport(item) {
        let transPortType = this.configs.configs.EFL_DRV_UPLOAD_TRANSPORT_TYPE;
        let chunkSize = this.configs.configs.EFL_DRV_UPLOAD_CHUNK_SIZE;
        let chunkCount = this.configs.configs.EFL_DRV_UPLOAD_CHUNK_COUNT;
        let chunkMinSize = this.configs.configs.EFL_DRV_UPLOADf_CHUNK_MIN_SIZE;

        if (!chunkSize) chunkSize = 10485760;
        if (!chunkCount) chunkCount = 1;
        if (!chunkMinSize || chunkMinSize > chunkSize) chunkMinSize = chunkSize;
        if (!transPortType) transPortType = 'ALTERNATIVE';

        if (transPortType === 'MULTIPART') {
            this._proccessMultipartUpload(item);
        } else if (transPortType === 'FILE') {
            this._processMultiChunkUpload(item, chunkSize, chunkCount, chunkMinSize);
        } else if (item._file.size > chunkMinSize) {
            this._processMultiChunkUpload(item, chunkSize, chunkCount, chunkMinSize);
        } else {
            this._proccessMultipartUpload(item);
        }
    }

    /**
     * Send File to Server Via XMLHttpRequest
     * @param {FileItem} item
     * @private
     */
    _processMultiChunkUpload(item, chunkSize, chunkCount, chunkMinSize) {
        let upload;
        let loop;
        let uploads;
        let chunkProgress;
        let chunkSuccess;
        let chunkError;
        let nextChunks;
        let mergeChunk;
        const fileSize = item._file.size;
        let start = 0;
        let end = 0;

        upload = () => {
            const xhr = (item._xhr = new XMLHttpRequest());

            if (typeof item._file.size !== 'number') {
                throw new TypeError('The file specified is no longer valid');
            }

            xhr.upload.onprogress = event => {
                const loaded = event.lengthComputable ? event.loaded : 0;
                let uploaded = start + loaded;
                if (uploaded > end) uploaded = end;
                if (uploaded === item.file.size && item.file.size > 0) uploaded = item.file.size - 1;
                const progress = Math.floor((uploaded * 100) / item.file.size);
                this._onProgressItem(item, progress, uploaded);
            };

            xhr.onload = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());

                if (this._isSuccessCode(xhr.status, xhr.response)) {
                    const response = JSON.parse(xhr.response);

                    if (!!response && !!response.resultCode && response.resultCode === 200) {
                        if (!!response.data && !!response.data.objtId) {
                            item.result = response;
                            this._onSuccessItem(item, xhr.response, xhr.status, headers);
                            this._onCompleteItem(item, xhr.response, xhr.status, headers);
                        } else {
                            loop();
                        }
                    } else {
                        item.result = response;
                        this._onErrorItem(item, xhr.response, xhr.status, headers);
                        this._onCompleteItem(item, xhr.response, xhr.status, headers);
                    }
                } else {
                    item.result = xhr.response;
                    this._onErrorItem(item, xhr.response, xhr.status, headers);
                    this._onCompleteItem(item, xhr.response, xhr.status, headers);
                }
            };

            xhr.onerror = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());
                const response = this._transformResponse(xhr.response, headers);
                this._onErrorItem(item, response, xhr.status, headers);
                this._onCompleteItem(item, response, xhr.status, headers);
            };

            xhr.onabort = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());
                const response = this._transformResponse(xhr.response, headers);
                this._onCancelItem(item, response, xhr.status, headers);
                this._onCompleteItem(item, response, xhr.status, headers);
            };

            const url = item.uploadUrl.fileUrl;
            const method = 'POST';

            xhr.open(method, url, true);
            xhr.setRequestHeader('X-Upload-Range', `${start}_${end}`);
            xhr.setRequestHeader('X-File-Size', fileSize);
            xhr.setRequestHeader('tuid', AuthService.getAuthToken());
            xhr.setRequestHeader('hmac', Signature.generateSignature({ url, method }, AuthService.getSignatureKey()));
            xhr.withCredentials = true;

            xhr.send(item._file.slice(start, end));
        };

        loop = () => {
            const progress = Math.floor((end * 100) / item.file.size);
            this._onProgressItem(item, progress, end);

            start = end;
            end = start + chunkSize;
            if (end > fileSize) {
                end = fileSize;
            }
            upload();
        };

        chunkProgress = (chunks, idx, event) => {
            const loaded = event.lengthComputable ? event.loaded : 0;
            chunks.chunks[idx].uploaded = loaded > chunks.chunks[idx].size ? chunks.chunks[idx].size : loaded;
            let uploaded = chunks.start + chunks.chunks.reduce((r, i) => r + i.uploaded, 0);
            if (uploaded >= chunks.end) uploaded = chunks.end - 1;
            this._onProgressItem(item, Math.floor((uploaded * 100) / fileSize), uploaded);
        };

        chunkSuccess = (chunks, idx) => {
            chunks.chunks[idx].send = true;
            chunks.sendCount++;
            if (chunks.sendCount === chunks.chunks.length) {
                let uploaded = end;
                if (uploaded >= fileSize) uploaded = fileSize - 1;
                this._onProgressItem(item, Math.floor((uploaded * 100) / fileSize), uploaded);
                mergeChunk(chunks);
            }
        };

        chunkError = (chunks, idx, item, response, status, headers) => {
            for (let i = 0; i < chunks.chunks.length; i++) {
                if (!chunks.chunks[i].send) {
                    chunks.chunks[i]._xhr.abort();
                }
            }
            this._onErrorItem(item, response, status, headers);
            this._onCompleteItem(item, response, status, headers);
        };

        uploads = (chunks, idx) => {
            const xhr = (item._xhrs[idx] = new XMLHttpRequest());

            if (typeof item._file.size !== 'number') {
                throw new TypeError('The file specified is no longer valid');
            }

            xhr.upload.onprogress = event => {
                chunkProgress(chunks, idx, event);
            };

            xhr.onload = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());

                if (this._isSuccessCode(xhr.status, xhr.response)) {
                    const response = JSON.parse(xhr.response);

                    if (!!response && !!response.resultCode && response.resultCode === 200) {
                        chunkSuccess(chunks, idx);
                    } else {
                        item.result = response;
                        chunkError(chunks, idx, item, xhr.response, xhr.status, headers);
                    }
                } else {
                    item.result = xhr.response;
                    chunkError(chunks, idx, item, xhr.response, xhr.status, headers);
                }
            };

            const url = item.uploadUrl.fileUrl;
            const method = 'POST';
            const st = chunks.chunks[idx].start;
            const ed = chunks.chunks[idx].end;
            const range = `${st}_${ed}/${st}_${ed}`;

            xhr.open(method, url, true);
            xhr.setRequestHeader('X-Upload-Range', range);
            xhr.setRequestHeader('X-File-Size', fileSize);
            xhr.setRequestHeader('tuid', AuthService.getAuthToken());
            xhr.setRequestHeader('hmac', Signature.generateSignature({ url, method }, AuthService.getSignatureKey()));
            xhr.withCredentials = true;

            xhr.send(item._file.slice(st, ed));
        };

        nextChunks = () => {
            if (start + chunkSize * chunkCount > fileSize) {
                chunkSize = Math.ceil((fileSize - start) / chunkCount);
                if (chunkSize < chunkMinSize) chunkSize = chunkMinSize;
            }
            let idx = 0;
            const chunks = { chunks: [], start, end, sendCount: 0, length: 0, size: 0 };

            for (; start < fileSize && idx < chunkCount; idx++) {
                end = start + chunkSize;
                if (end > fileSize) end = fileSize;
                const chunk = { idx, start, end, send: false, uploaded: 0, size: end - start };
                chunks.chunks.push(chunk);
                start = end;
            }

            chunks.length = idx;
            chunks.end = end;
            chunks.size = chunks.end - chunks.start;

            if (chunks.chunks.length === 1) {
                start = chunks.start;
                end = chunks.end;
                upload();
            } else {
                item._xhrs = [];
                for (let i = 0; i < chunks.chunks.length; i++) {
                    uploads(chunks, i);
                }
            }
        };

        mergeChunk = chunks => {
            const xhr = (item._xhr = new XMLHttpRequest());
            xhr.onload = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());

                if (this._isSuccessCode(xhr.status, xhr.response)) {
                    const response = JSON.parse(xhr.response);

                    if (!!response && !!response.resultCode && response.resultCode === 200) {
                        if (!!response.data && !!response.data.objtId) {
                            item.result = response;
                            this._onSuccessItem(item, xhr.response, xhr.status, headers);
                            this._onCompleteItem(item, xhr.response, xhr.status, headers);
                        } else {
                            nextChunks();
                        }
                    } else {
                        item.result = response;
                        this._onErrorItem(item, xhr.response, xhr.status, headers);
                        this._onCompleteItem(item, xhr.response, xhr.status, headers);
                    }
                } else {
                    this._onErrorItem(item, xhr.response, xhr.status, headers);
                    this._onCompleteItem(item, xhr.response, xhr.status, headers);
                }
            };

            xhr.onabort = () => {
                if (!item.isCancel) {
                    const headers = this._parseHeaders(xhr.getAllResponseHeaders());
                    const response = this._transformResponse(xhr.response, headers);
                    this._onCancelItem(item, response, xhr.status, headers);
                    this._onCompleteItem(item, response, xhr.status, headers);
                }
            };

            const data = { fileSize, phyFileId: item.phyFileId, start: chunks.start, end: chunks.end, chunkList: chunks.chunks.map(chunk => `${chunk.start}_${chunk.end}`) };
            const url = item.uploadUrl.mergeUrl;
            const method = 'POST';

            xhr.open(method, url, true);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('tuid', AuthService.getAuthToken());
            xhr.setRequestHeader('hmac', Signature.generateSignature({ url, data, method }, AuthService.getSignatureKey()));
            xhr.withCredentials = true;
            xhr.send(JSON.stringify(data));
        };

        const meta = () => {
            const xhr = (item._xhr = new XMLHttpRequest());

            if (typeof item._file.size !== 'number') {
                throw new TypeError('The file specified is no longer valid');
            }

            xhr.onload = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());

                if (this._isSuccessCode(xhr.status, xhr.response)) {
                    const response = JSON.parse(xhr.response);

                    if (!!response && !!response.resultCode && response.resultCode === 200) {
                        item.phyFileId = response.data.phyFileId;
                        if (response.data.jsessionid) {
                            item.jsessionid = response.data.jsessionid;
                            item.uploadUrl.fileUrl = `${item.uploadUrl.fileUrl}${response.data.phyFileId};jsessionid=${response.data.jsessionid}`;
                            item.uploadUrl.mergeUrl = `${item.uploadUrl.mergeUrl};jsessionid=${response.data.jsessionid}`;
                        } else {
                            item.uploadUrl.fileUrl = `${item.uploadUrl.fileUrl}${response.data.phyFileId}`;
                        }
                        if (chunkCount > 1 && fileSize > 0) {
                            nextChunks();
                        } else {
                            loop();
                        }
                    } else {
                        item.result = response;
                        this._onErrorItem(item, xhr.response, xhr.status, headers);
                        this._onCompleteItem(item, xhr.response, xhr.status, headers);
                    }
                } else {
                    this._onErrorItem(item, xhr.response, xhr.status, headers);
                    this._onCompleteItem(item, xhr.response, xhr.status, headers);
                }
            };

            xhr.onabort = () => {
                const headers = this._parseHeaders(xhr.getAllResponseHeaders());
                const response = this._transformResponse(xhr.response, headers);
                this._onCancelItem(item, response, xhr.status, headers);
                this._onCompleteItem(item, response, xhr.status, headers);
            };

            console.log('chunk upload item : >> >', item);

            const timezoneDiff = AuthService.getLoginUser().locale.timezoneDiff;
            console.log('timezoneDiff : >> >', timezoneDiff);

            let kstDate;

            const agent = navigator.userAgent;
            if ((navigator.appName === 'Netscape' && agent.search('Trident') !== -1) || agent.toLowerCase().indexOf('msie') !== -1) {
                kstDate = item._file.lastModifiedDate;
            } else {
                kstDate = new Date(item._file.lastModified);
            }

            console.log('kstDate : >> >', kstDate);
            const utcDate = new Date(kstDate.getTime() - timezoneDiff * 60 * 1000);
            console.log('utcDate : >> >', utcDate);

            const objtStatChgDt = moment(utcDate).format('YYYYMMDDHHmmss');
            item.metadata.objtStatChgDt = objtStatChgDt;

            const data = { fileSize, localFileName: item.file.name, objtStatChgDt, ...item.formData, ...item.metadata };
            const url = item.uploadUrl.metaUrl;
            const method = 'POST';

            xhr.open(method, url, true);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('tuid', AuthService.getAuthToken());
            xhr.setRequestHeader('hmac', Signature.generateSignature({ url, data, method }, AuthService.getSignatureKey()));
            xhr.withCredentials = true;
            xhr.send(JSON.stringify(data));
        };

        meta();
    }

    /**
     * Send File to Server Via XMLHttpRequest
     * @param {FileItem} item
     * @private
     */
    _proccessMultipartUpload(item) {
        const fileSize = item._file.size;

        const xhr = (item._xhr = new XMLHttpRequest());

        if (typeof item._file.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
        }

        xhr.upload.onprogress = event => {
            let uploaded = event.lengthComputable ? event.loaded : 0;
            if (uploaded > fileSize) uploaded = fileSize;
            if (uploaded === fileSize && fileSize > 0) uploaded = fileSize - 1;
            const progress = Math.floor((uploaded * 100) / fileSize);
            this._onProgressItem(item, progress, uploaded);
        };

        xhr.onload = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());

            if (this._isSuccessCode(xhr.status, xhr.response)) {
                const response = JSON.parse(xhr.response);
                item.result = response;

                if (!!response && !!response.resultCode && response.resultCode === 200) {
                    this._onSuccessItem(item, xhr.response, xhr.status, headers);
                    this._onCompleteItem(item, xhr.response, xhr.status, headers);
                } else {
                    this._onErrorItem(item, xhr.response, xhr.status, headers);
                    this._onCompleteItem(item, xhr.response, xhr.status, headers);
                }
            } else {
                this._onErrorItem(item, xhr.response, xhr.status, headers);
                this._onCompleteItem(item, xhr.response, xhr.status, headers);
            }
        };

        xhr.onabort = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());
            const response = this._transformResponse(xhr.response, headers);
            this._onCancelItem(item, response, xhr.status, headers);
            this._onCompleteItem(item, response, xhr.status, headers);
        };

        console.log('multi upload item >> ', item);

        const timezoneDiff = AuthService.getLoginUser().locale.timezoneDiff;
        console.log('timezoneDiff : >> >', timezoneDiff);

        let kstDate;

        const agent = navigator.userAgent;
        if ((navigator.appName === 'Netscape' && agent.search('Trident') !== -1) || agent.toLowerCase().indexOf('msie') !== -1) {
            kstDate = item._file.lastModifiedDate;
        } else {
            kstDate = new Date(item._file.lastModified);
        }

        console.log('kstDate : >> >', kstDate);
        const utcDate = new Date(kstDate.getTime() - timezoneDiff * 60 * 1000);
        console.log('utcDate : >> >', utcDate);

        const objtStatChgDt = moment(utcDate).format('YYYYMMDDHHmmss');
        item.metadata.objtStatChgDt = objtStatChgDt;

        const sendable = new FormData();
        const paramObj = {};
        for (const [key, value] of Object.entries(item.formData)) {
            if (value) {
                sendable.append(key, value);
                paramObj[key] = value;
            }
        }
        for (const [key, value] of Object.entries(item.metadata)) {
            if (value) {
                sendable.append(key, value);
                paramObj[key] = value;
            }
        }

        const params = Object.keys(paramObj)
            .sort()
            .reduce((a, c) => ((a[c] = paramObj[c]), a), {});
        const url = item.uploadUrl.multipartUrl;
        const method = 'POST';

        const config = { url, method, params };
        const hmac = Signature.generateSignature(config, AuthService.getSignatureKey());
        xhr.open(method, url, true);
        xhr.setRequestHeader('tuid', AuthService.getAuthToken());
        xhr.setRequestHeader('hmac', hmac);
        xhr.withCredentials = true;

        sendable.append(item.alias, item._file, item.file.name);
        xhr.send(sendable);
    }

    /**
     * Inner callback
     * @param {File|Object} item
     * @param {Object} filter
     * @param {Object} options
     * @private
     */
    _onWhenAddingFileFailed(item, filter, options) {
        this.onWhenAddingFileFailed(item, filter, options);
    }

    /**
     * Inner callback
     * @param {Array<FileLikeObject, filter, options>} items
     */
    _onWhenAddingFileFailedAll(items) {
        this.onWhenAddingFileFailedAll(items);
    }

    /**
     * Inner callback
     * @param {FileItem} item
     */
    _onAfterAddingFile(item) {
        this.onAfterAddingFile(item);
    }

    /**
     * Inner callback
     * @param {Array<FileItem>} items
     */
    _onAfterAddingAll(items) {
        this.onAfterAddingAll(items);
    }

    /**
     *  Inner callback
     * @param {FileItem} item
     * @private
     */
    _onBeforeUploadItem(item) {
        item._onBeforeUpload();
        this.onBeforeUploadItem(item);
    }

    /**
     * Inner callback
     * @param {FileItem} item
     * @param {Number} progress
     * @private
     */
    _onProgressItem(item, progress, uploaded) {
        if (isNaN(progress)) {
            progress = 0;
        }
        const total = this._getTotalProgress(progress);
        this.progress = total;
        item._onProgress(progress, uploaded);
        this.onProgressItem(item, progress);
        this.onProgressAll(total);
        // this._render();
    }

    /**
     * Inner callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     * @private
     */
    _onSuccessItem(item, response, status, headers) {
        item._onSuccess(response, status, headers);
        this.onSuccessItem(item, response, status, headers);
    }

    /**
     * Inner callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     * @private
     */
    _onErrorItem(item, response, status, headers) {
        item._onError(response, status, headers);
        this.onErrorItem(item, response, status, headers);
    }

    /**
     * Inner callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     * @private
     */
    _onCancelItem(item, response, status, headers) {
        item._onCancel(response, status, headers);
        this.onCancelItem(item, response, status, headers);
    }

    /**
     * Inner callback
     * @param {FileItem} item
     * @param {*} response
     * @param {Number} status
     * @param {Object} headers
     * @private
     */
    _onCompleteItem(item, response, status, headers) {
        item._onComplete(response, status, headers);
        this.onCompleteItem(item, response, status, headers);

        const nextItem = this.getReadyItems()[0];
        this.isUploading = false;

        if (nextItem) {
            nextItem.upload();
            return;
        }

        this.onCompleteAll();
        this.progress = this._getTotalProgress();
        this._render();
    }

    /** ********************
     * STATIC
     ********************* */
    /**
     * Returns "true" if value an instance of File
     * @param {*} value
     * @returns {Boolean}
     * @private
     */
    static isFile(value) {
        return File && value instanceof File;
    }

    /**
     * Returns "true" if value an instance of FileLikeObject
     * @param {*} value
     * @returns {Boolean}
     * @private
     */
    static isFileLikeObject(value) {
        return value instanceof FileLikeObject;
    }

    static isBlobFile(value) {
        return value instanceof BlobFile;
    }

    /**
     * Returns "true" if value is array like object
     * @param {*} value
     * @returns {Boolean}
     */
    static isArrayLikeObject(value) {
        return isObject(value) && 'length' in value;
    }

    /**
     * Inherits a target (Class_1) by a source (Class_2)
     * @param {Function} target
     * @param {Function} source
     */
    static inherit(target, source) {
        target.prototype = Object.create(source.prototype);
        target.prototype.constructor = target;
        target.super_ = source;
    }
}

export default FileUploader;
