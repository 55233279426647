import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SortableTree, { changeNodeAtPath, find, map, getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';
import { Scrollbars } from 'react-custom-scrollbars';

function getNodeKey({ node }) {
    return node.id;
}

function getNodeById({ treeData, id, ids }) {
    let newIds = ids;
    if (_.isEmpty(newIds)) newIds = [id];
    const { matches = {} } = find({
        treeData,
        getNodeKey,
        searchMethod: ({ node }) => _.includes(newIds, node.id)
    });
    return matches;
}

function openNode({ treeData, ids }) {
    const nodeInfos = getNodeById({ treeData, ids });
    let newTreeData = treeData;
    nodeInfos.forEach(nodeInfo => {
        const { node, path } = nodeInfo;
        newTreeData = changeNodeAtPath({
            treeData,
            path,
            newNode: { ...node, expanded: true },
            getNodeKey
        });
    });
    return newTreeData;
}

function getSelectedNodeInfos(treeData) {
    const { matches } = find({
        treeData,
        getNodeKey,
        searchMethod: ({ node }) => node.selected
    });
    return matches;
}

function isInList(list, tgt) {
    for (let i = 0; i < list.length; i += 1) {
        if (tgt === list[i]) return true;
    }
    return false;
}

function updateNode({ treeData, id, nodeInfo, query }) {
    let newNodeInfo = _.clone(nodeInfo);
    if (!newNodeInfo) {
        const newNodeInfos = getNodeById({ treeData, id });
        newNodeInfo = _.get(newNodeInfos, '[0]', {});
    }
    if (_.isEmpty(newNodeInfo)) return treeData;

    const { path } = newNodeInfo;
    let newTreeData = treeData;
    if (query.expanded) {
        newTreeData = map({
            treeData,
            callback: ({ node }) => {
                if (isInList(path, node.id)) return { ...node, expanded: true };
                return node;
            },
            getNodeKey,
            ignoreCollapsed: false
        });
    }

    return changeNodeAtPath({
        treeData: newTreeData,
        path,
        newNode: { ...newNodeInfo.node, ...query },
        getNodeKey
    });
}

function selectNode({ treeData, id }) {
    const nodeInfos = getSelectedNodeInfos(treeData);
    let newTreeData = treeData;
    if (nodeInfos.length > 0) {
        // if (nodeInfos[0].node.id === id) {
        //     return newTreeData;
        // }
        newTreeData = updateNode({
            treeData,
            nodeInfo: nodeInfos[0],
            query: { selected: false }
        });
    }

    return updateNode({
        treeData: newTreeData,
        id,
        query: {
            expanded: true,
            selected: true
        }
    });
}

// function getOpenedNode(treeData) {
//     const { matches } = find({
//         treeData,
//         getNodeKey,
//         searchMethod: ({ node }) => node.expanded
//     });
//     return matches;
// }

function convertFolderToNode(data) {
    const newData = [...data];
    if (newData.length > 0) newData[0].onpstFolderId = '0';
    const flatData = newData.map(item => ({
        id: item.objtId,
        parentId: item.onpstFolderId,
        title: item.objtNm
    }));
    return getTreeFromFlatData({ flatData });
}

function convertDepartToNode(data) {
    const flatData = data.map(item => ({
        id: item.departmentId,
        parentId: item.upperDepartmentId
    }));
    return getFlatDataFromTree({ flatData });
}

class Tree extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        this.scrollReCalculate();
    }

    treeNodeClick = (e, rowInfo) => {
        const { treeData, onChange, handleNodeClick } = this.props;
        const newTreeData = selectNode({
            treeData,
            id: rowInfo.node.id
        });
        onChange(newTreeData);
        handleNodeClick(e, rowInfo);
    };

    componentDidUpdate() {
        const { virtualized } = this.props;
        if (virtualized) return this.scrollReCalculate();
        return _.noop();
    }

    componentWillUnmount() {
        this.clearScrollForceUpdateTimer();
    }

    scrollReCalculate() {
        const { className, width, height, nodeHeight, leftPadding } = this.props;
        if (_.has(this.scrollRef, 'current.view')) {
            let totalWidth = width;
            const totalHeight = height;
            const rows = this.scrollRef.current.view.getElementsByClassName('rst__nodeContent');
            for (let i = 0; rows.length > i; i += 1) {
                const row = rows[i];
                totalWidth = Math.max(row.offsetLeft + row.offsetWidth, totalWidth);
            }
            const wrapper = this.scrollRef.current.view.getElementsByClassName(className)[0];
            const beforeWidth = wrapper.style.width;
            const beforeHeight = wrapper.style.height;
            wrapper.style.width = `${totalWidth + leftPadding}px`;
            wrapper.style.height = `${Math.max(totalHeight, rows.length * nodeHeight)}px`;

            if (beforeWidth !== wrapper.style.width || beforeHeight !== wrapper.style.height) {
                this.scrollbarForceUpdateTimer = setTimeout(this.scrollRef.current.forceUpdate.bind(this), 50);
            }

            this.moveScrollToSelectedNode();
        }
    }

    moveScrollToSelectedNode() {
        const { nodeHeight, treeData } = this.props;
        if (_.has(this.scrollRef, 'current.view')) {
            const selectedNods = getSelectedNodeInfos(treeData);
            const selectedIndex = _.get(selectedNods, '[0].treeIndex', -1);
            if (selectedIndex > 0) {
                this.scrollRef.current.scrollTop((selectedIndex - 1) * nodeHeight);
            }
        }
    }

    clearScrollForceUpdateTimer() {
        if (!_.isEmpty(this.scrollbarForceUpdateTimer)) {
            clearTimeout(this.scrollbarForceUpdateTimer);
        }
    }

    render() {
        const { className, width, height, nodeHeight, widthSpace, treeData, onChange, searchMethod, selectedIndex, autoHeight, virtualized, treeNodeRenderer } = this.props;

        const rendererProps = {};
        if (treeNodeRenderer) {
            rendererProps.treeNodeRenderer = treeNodeRenderer;
        }

        return virtualized ? (
            <Scrollbars ref={this.scrollRef} className="scroll" autoHeight={autoHeight} autoHeightMax={height} onUpdate={this.updated}>
                <div className={className} style={{ height, width }}>
                    <SortableTree
                        treeData={treeData}
                        onChange={onChange}
                        getNodeKey={getNodeKey}
                        canDrag={false}
                        rowHeight={nodeHeight}
                        scaffoldBlockPxWidth={widthSpace}
                        searchMethod={searchMethod}
                        searchFocusOffset={selectedIndex}
                        canDrop={true}
                        generateNodeProps={rowInfo => ({
                            className: rowInfo.node.type + (rowInfo.node.expanded ? ' open' : ' close'),
                            buttons: [
                                <a className={rowInfo.node.selected ? ' selected' : ''} role="button" onClick={e => this.treeNodeClick(e, rowInfo)}>
                                    <i>icon</i>
                                    {rowInfo.node.title}
                                </a>
                            ]
                        })}
                        {...rendererProps}
                    />
                </div>
            </Scrollbars>
        ) : (
            <SortableTree
                treeData={treeData}
                getNodeKey={getNodeKey}
                onChange={onChange}
                canDrag={false}
                rowHeight={32}
                scaffoldBlockPxWidth={widthSpace}
                searchMethod={searchMethod}
                searchFocusOffset={selectedIndex}
                isVirtualized={false}
                generateNodeProps={rowInfo => ({
                    className: rowInfo.node.type + (rowInfo.node.expanded ? ' open' : ' close'),
                    buttons: [
                        <a className={rowInfo.node.selected ? ' selected' : ''} role="button" onClick={e => this.treeNodeClick(e, rowInfo)}>
                            <i>icon</i>
                            {rowInfo.node.title}
                        </a>
                    ]
                })}
                {...rendererProps}
            />
        );
    }
}

Tree.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    nodeHeight: PropTypes.number,
    widthSpace: PropTypes.number,
    leftPadding: PropTypes.number,
    searchMethod: PropTypes.func,
    selectedIndex: PropTypes.number,
    autoHeight: PropTypes.bool,
    treeData: PropTypes.array,
    onChange: PropTypes.func,
    handleNodeClick: PropTypes.func,
    className: PropTypes.string,
    virtualized: PropTypes.bool,
    treeNodeRenderer: PropTypes.any
};

Tree.defaultProps = {
    height: 308,
    width: 362,
    nodeHeight: 32,
    widthSpace: 12,
    leftPadding: 19,
    searchMethod: _.noop,
    selectedIndex: 0,
    autoHeight: true,
    treeData: [],
    onChange: _.noop,
    handleNodeClick: _.noop,
    className: '',
    virtualized: true,
    treeNodeRenderer: null
};

export default Tree;
export { openNode, updateNode, convertFolderToNode, convertDepartToNode, selectNode, getSelectedNodeInfos };

// openNode,
// getSelectedNode,
// getOpendNode,
// updateNode : title, expanded, selected, type
// insertNode : children
// deleteNode,
// convertData
