import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        promptInput: {
            clear: state => {
                return produce(state, draft => {
                    draft.promptTemplate.promptTemplateType = null;
                    draft.promptTemplate.templateData = { files: [], keyword: '' };
                });
            },
            insertPromptTemplate: (state, { payload }) => {
                return produce(state, draft => {
                    draft.promptTemplate.promptTemplateType = payload.promptTemplateType;
                    draft.promptTemplate.templateData = { files: [], keyword: '' };
                });
            },
            putDataToPromptTemplate: (state, { payload }) => {
                return produce(state, draft => {
                    draft.promptTemplate.templateData = { ...draft.promptTemplate.templateData, ...payload.templateData };
                });
            }
        }
    },
    {
        promptTemplate: { templateData: { files: [], keyword: '' } }
    },
    { prefix: 'summary' }
);
