import React from 'react';
import { FormattedMessage } from 'react-intl';
import NormalRow from 'components/containers/DriveFiles/Row/NormalRow';
import MoreRow from 'components/containers/DriveFiles/Row/MoreRow';
import Base from './Base';
import FileCache from '../FileCache';
import FileDropZone from '../../components/presentationals/DragDrop/FileDropZone';

class Shared extends Base {
    pageId = 'shared';

    cacheWhenLoad = true;

    dndAble = true;

    isExplorerConstraints(folder) {
        if (!folder || !folder.drive || !folder.drive.objtId) return false;
        return this.isRoot(folder.drive.objtId);
    }

    buildCache(folderId) {
        if (this.isRoot(folderId)) {
            return new FileCache(file => {
                if (file && !file.mine && file.maxAuthCd !== 'NONE') return file;
                return null;
            });
        }
        return new FileCache(file => {
            if (file && !file.mine && file.maxAuthCd !== 'NONE' && file.drive && file.drive.onpstFolderId === folderId) return file;
            return null;
        });
    }

    rowBuilder({ list, loadMore, style, index }) {
        if (index === list.length) {
            return <MoreRow style={style} loadMore={loadMore} />;
        }
        return <NormalRow file={list[index]} index={index} style={style} />;
    }

    /**
     * 업로드 드랍존 처리
     */
    uploadBuilder(folder) {
        return this.dndAble && folder && <FileDropZone folder={folder} pageId={this.pageId} uploader={this.uploader} />;
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-shr" />
                    <p>
                        <FormattedMessage id="drive.text.list.empty.shared" />
                        <br />
                        <FormattedMessage id="drive.text.list.empty.shared.second" />
                    </p>
                </div>
            </div>
        );
    }
}

export default new Shared();
