import React, { memo, useEffect, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { SUMMARY_TOOLTIP_DIRECTION } from '../../../Common/SummaryConstant';
import SummaryTooltipIcon from '../../../Common/Tooltip/SummaryTooltipIcon';
import { getFilterMessageIdByScopeType } from '../../../Common/Util/SummaryMessageUtil';
import { summaryActions } from '../../../../../../redux/actions/Summary';

const ScopeOptionKeywordItem = memo(({ type, selectedScopeType, onSelect, intl }) => {
    const keywordInputRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (type === selectedScopeType) {
            keywordInputRef.current.focus();
            return;
        }

        dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { scopeTypeKeyword: '' } }));
        keywordInputRef.current.value = '';
    }, [selectedScopeType]);

    const handleChangeType = () => {
        onSelect(type);
    };

    const handleChangeKeywordInput = e => {
        const keyword = e.target.value;
        dispatch(summaryActions.optionSelectDialog.changeOption({ summaryOptions: { scopeTypeKeyword: keyword } }));
    };

    return (
        <div className="form-check">
            <label className="form-check-label">
                <input className="form-check-input" type="radio" name="summary-option-scope-type" value={type} checked={type === selectedScopeType} onChange={handleChangeType} />
                <span className="label-text">
                    <FormattedMessage id={getFilterMessageIdByScopeType(type)} />
                </span>
                <SummaryTooltipIcon direction={SUMMARY_TOOLTIP_DIRECTION.top} content={{ messageId: 'drive.tooltip.copilot.summary.keywordConstraint' }} />
            </label>
            <div className={classnames('box-input-group', type !== selectedScopeType && 'disabled')}>
                <input
                    className="form-control"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'drive.placeHolder.copilot.summary.promptInput.keyword' })}
                    onChange={handleChangeKeywordInput}
                    ref={keywordInputRef}
                />
                <a className="btn-ic-nor btn-func-clear" role="button" title={intl.formatMessage({ id: 'com.text.clear' })}>
                    <i className="ic-16-clear" />
                </a>
            </div>
        </div>
    );
});

ScopeOptionKeywordItem.propTypes = {
    type: PropTypes.string.isRequired,
    selectedScopeType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};

ScopeOptionKeywordItem.defaultProps = {};

export default injectIntl(ScopeOptionKeywordItem);
