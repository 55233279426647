import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ViewMoreRow = props => {
    const { load, style } = props;
    return (
        <div className="grid-row btns-row" style={style}>
            <div className="grid-cell">
                <a className="btn btn-secondary btn-block" role="button" onClick={() => load()}>
                    <span className="btn-text">
                        <FormattedMessage id="com.viewmore" />
                    </span>
                </a>
            </div>
        </div>
    );
};

ViewMoreRow.propTypes = {
    load: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired
};

export default ViewMoreRow;
