import React from 'react';
import _ from 'lodash';
import DateRangePickerWrapper from '../DateRangePickerWrapper';

class FilterDate extends DateRangePickerWrapper {
    render() {
        const { startDate, endDate, focusedInput, onDatesChange, minDate, maxDate } = this.props;
        const props = _.omit(this.props, ['autoFocus', 'autoFocusEndDate', 'initialStartDate', 'initialEndDate', 'stateDateWrapper', 'minDate', 'maxDate']);

        return (
            <DateRangePickerWrapper
                {...props}
                initialStartDate={startDate}
                initialEndDate={endDate}
                startDate={startDate}
                endDate={endDate}
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={this.onFocusedInputChange}
                displayFormat="YYYY-MM-DD"
                isOutsideRange={() => false}
                minimumNights={0}
                renderMonthElement={this.renderMonthElement}
                minDate={minDate}
                maxDate={maxDate}
            />
        );
    }
}

export default FilterDate;
