import _ from 'lodash';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';

import DownloadRest from 'apis/DownloadRest';

const EXCEPTION_EXTS = ['tif', 'tiff'];

class Thumbnail extends Component {
    STATUS = {
        INIT: 'INIT',
        ERROR: 'ERROR',
        COMPLETE: 'COMPLETE',
        NO_IMAGE: 'NO_IMAGE',
        EXCEPTION: 'EXCEPTION'
    };

    constructor(props) {
        super(props);
        this.state = {
            src: '',
            status: this.isExceptionExt() ? this.STATUS.EXCEPTION : this.STATUS.INIT
        };
    }

    isExceptionExt = () => {
        const { ext } = this.props;
        return EXCEPTION_EXTS.indexOf(ext) >= 0;
    };

    getThumbnail = async (objtId, onpstId, fileVerSno) => {
        try {
            const response = await DownloadRest.downloadThumbnailWithCache({ objtId, onpstId, fileVerSno });
            const image = btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const src = `data:image/png;base64,${image}`;

            const temp = new Image();
            temp.onerror = () => {
                if (this.isComponentMounted) this.setState({ src: '', status: this.STATUS.ERROR });
            };
            temp.onload = () => {
                if (this.isComponentMounted) this.setState({ src, status: this.STATUS.COMPLETE });
            };
            temp.src = src;
        } catch (error) {
            if (this.isComponentMounted) this.setState({ src: '', status: this.STATUS.ERROR });
        }
    };

    componentDidUpdate(prevProps) {
        const { objtId, onpstId, fileVerSno, updateTime } = this.props;
        if (!this.isExceptionExt() && updateTime !== prevProps.updateTime) {
            this.getThumbnail(objtId, onpstId, fileVerSno);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        const { objtId, onpstId, fileVerSno } = this.props;
        if (!_.isEmpty(objtId)) {
            if (!this.isExceptionExt()) {
                this.getThumbnail(objtId, onpstId, fileVerSno);
            }
        } else {
            this.setState({ src: '', status: this.STATUS.NO_IMAGE });
        }
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        const { src, status } = this.state;
        return (
            <>
                <If condition={status === this.STATUS.COMPLETE && !_.isEmpty(src)}>
                    <div className="image-thumbnail">
                        <div className="thumbnail">
                            <img src={src} alt="thumbnail" />
                        </div>
                    </div>
                </If>

                <If condition={status === this.STATUS.ERROR}>
                    <div className="empty">
                        <FormattedMessage id="drive.tooltip.thumbnail.error" />{' '}
                    </div>
                </If>
                <If condition={status === this.STATUS.NO_IMAGE}>
                    <div className="empty">
                        <FormattedMessage id="drive.tooltip.thumbnail.deleted" />{' '}
                    </div>
                </If>
                <If condition={status === this.STATUS.EXCEPTION}>
                    <div className="empty">
                        <FormattedMessage id="drive.tooltip.thumbnail.exception" />{' '}
                    </div>
                </If>
            </>
        );
    }
}

Thumbnail.propTypes = {
    updateTime: PropTypes.number,
    objtId: PropTypes.string.isRequired,
    onpstId: PropTypes.string.isRequired,
    fileVerSno: PropTypes.number.isRequired,
    ext: PropTypes.string
};

Thumbnail.defaultProps = {
    updateTime: new Date().getTime(),
    ext: ''
};

export default Thumbnail;
