import React, { useState, useEffect } from 'react';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import FileIcon from 'src/components/presentationals/FileMenu/FileIcon';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import { alert } from '../../../utils/ModalService';
import FavoriteFolder from './FavoriteFolder';
import FavoriteFiles from './FavoriteFiles';

import { getUrlPrefix } from '../../../utils/Url';

import FileReload from '../../../utils/FileReload';

import * as actions from '../../../redux/actions';
import MenuMapper from '../../../utils/FileMenu/MenuMapper';
import FoldersRest from '../../../apis/FoldersRest';
import DownloadRest from '../../../apis/DownloadRest';

const FavoriteItem = props => {
    const { folder: parentFolder, isLoaded, history, reloadAfterChanged, reloadAfterShared, reload } = props;

    const [folder, setFolder] = useState(parentFolder);
    const [hover] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [fileLengthTobeShown, setFileLengthTobeShown] = useState(0);
    const [folderLengthTobeShown, setFolderLengthTobeShown] = useState(0);
    const callback = FileReload.call;
    const [folderLoaded, setFolderLoaded] = useState(false);

    const { drive, extra /* , actionPolicy = {} */, maxAuth } = folder;
    const { children = { folder: 0, file: 0 } } = extra;

    useEffect(() => {
        const { data: { reloadFiles = [] } = {} } = reload;
        if (reloadFiles.length) {
            // if (reloadFiles.filter(file => file.drive.onpstFolderId === folder.drive.objtId).length) {
            FoldersRest.getPair([folder], true).then(searchFiles => {
                if (searchFiles && searchFiles.length) {
                    setFolder(searchFiles[0]);
                    setFolderLoaded(true);
                }
            });
            //  }
        }
    }, [reload]);

    const calculateList = flist => {
        if (flist.length < 3) {
            setFileLengthTobeShown(6 - flist.length);
            setFolderLengthTobeShown(flist.length);
        } else {
            setFileLengthTobeShown(3);
            setFolderLengthTobeShown(3);
        }
    };

    useEffect(() => {
        FoldersRest.getFolder(drive.objtId, { onpstId: drive.onpstId, size: 6, objtSectCd: 'FOLDER', withPath: true, sortField: 'objtStatChgDt', sortOrder: 'DESC' }).then(data => {
            calculateList(data.list);
            setFolderList(data.list);
            setFolderLoaded(true);
        });
    }, folderList);

    const fileIconCustom = {
        openType: 'left',
        moreGroupType: 'preferred'
    };

    const icons = new MenuMapper({ callback, fileIconCustom, iconType: 'home' }, folder).getIcons();

    const move = async f => {
        if (f.drive.objtDelCd === 'NORMAL' && f.maxAuthCd !== 'NONE') {
            // 폴더 진입 전 권한 체크
            const response = await DownloadRest.downloadFilePathRes({ objtId: f.drive.objtId });
            if (response.resultCode !== 200) {
                alert('drive.alert.selectFolder.noAuth').then(() => {
                    history.push('/home/refresh');
                });
                return;
            }
            // workgroup의 경우 하위폴더가 공유된 경우 워크그룹 자체에는 권한이 없을 수 있으므로 회피
            if (_.includes(response.data[0].browserPathId, '111200000000000000')) {
                if (response.data[0].objtId !== response.data[0].onpstId && response.data[0].maxAuthCd === null) {
                    alert('drive.alert.selectFolder.noAuth').then(() => {
                        history.push('/home/refresh');
                    });
                    return;
                }
            } else if (response.data[0].maxAuthCd === null) {
                alert('drive.alert.selectFolder.noAuth').then(() => {
                    history.push('/home/refresh');
                });
                return;
            }

            const url = getUrlPrefix(f);
            history.push(`${url}/${f.drive.objtId}`, { onpstId: f.drive.onpstId, maxAuthCd: f.maxAuthCd });
        } else if (f.maxAuthCd === 'NONE') {
            alert('drive.alert.favorFolder.noAuth');
        } else {
            alert('drive.alert.favorFolder.alreadyRemoved');
        }
    };

    const getIconStyle = f => {
        const { sharedCount = 0 } = f;
        let style = sharedCount ? `ic-26-share` : ``;
        if (f.drive.objtSectCd === 'FOLDER' && f.drive.onpstSectCd === 'SYSTFOLDER') {
            style = `ic-26-collabo`;
        }
        return style;
    };

    return (
        <div className="item">
            <div className={`item-content ${hover ? 'is-hovered' : ''}`}>
                <div className="item-content-option">
                    <ul className="nav">
                        <FileIcon groupType="homeFavoriteItem" file={folder} custom={fileIconCustom} callback={reloadAfterChanged} />
                    </ul>
                </div>
                <div className="item-content-header">
                    <i className={`${getIconStyle(folder)}`} />
                    <h5 className="title">
                        <a role="button" title={drive.objtNm} onClick={() => move(folder)}>
                            {drive.objtNm}
                        </a>
                    </h5>
                    <div className="details">
                        <div className="g-inline-flex">
                            <div className="ellipsis">
                                <FormattedMessage id="drive.text.lwrFolderCount" /> {children.folder}, <FormattedMessage id="drive.text.lwrFileCount" /> {children.file}
                            </div>
                            <div className="next divider">
                                <span className="authority">{maxAuth}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-content-body">
                    <ul className="icb-folder-list">
                        <If condition={isLoaded}>
                            <FavoriteFolder folderList={folderList} folderLengthTobeShown={folderLengthTobeShown} history={history} move={move} />
                        </If>
                    </ul>
                    <ul className="icb-file-list">
                        <If condition={isLoaded && folderLoaded}>
                            {' '}
                            <FavoriteFiles rootFolderInfo={drive} fileLengthTobeShown={fileLengthTobeShown} key={fileLengthTobeShown} />
                        </If>
                    </ul>
                </div>
                <div className="item-content-footer">
                    <ul className="item-option nav">
                        <FileIcon file={folder} groupType="homeFavorite" iconType="homeFavorite" icons={icons} />
                    </ul>
                    <FileIcon file={folder} groupType="homeShareFavorite" iconType="homeShareFavorite" icons={icons} callback={reloadAfterShared} />
                </div>
            </div>
        </div>
    );
};

FavoriteItem.propTypes = {
    folder: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    reloadAfterChanged: PropTypes.func.isRequired,
    reloadAfterShared: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    reload: state.fileReload
});

const mapDispatchToProps = {
    handleFileSelected: actions.upload.slectFile
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FavoriteItem);
