import React, { memo, useEffect, useRef, useState } from 'react';
import { Choose, If, When } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AI_MESSAGE_STATUS, STOPABLE_RESULT_STATUS_LIST } from '../../../../Common/SummaryConstant';
import SummaryStopButton from './SummaryStopButton';
import AiPendingMessage from './AiPendingMessage';
import AiResultMessage from './AiResultMessage';

const AIMessage = memo(({ summaryMessage, moveSidePanelScrollToBottom }) => {
    const { aiMessageStatus, summaryResults, archived } = summaryMessage;

    const aiMessageBodyRef = useRef();

    const [currentResultStatus, setCurrentResultStatus] = useState();

    useEffect(() => {
        if (archived || !aiMessageBodyRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            moveSidePanelScrollToBottom();
            // TODO 한줄 씩 저장? 그러면 새로고침되어도 중간단계를 저장할 수 있음
        });
        resizeObserver.observe(aiMessageBodyRef.current);

        // eslint-disable-next-line consistent-return
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        const { resultStatus } = _.last(summaryResults);
        setCurrentResultStatus(resultStatus);
    }, [summaryMessage.summaryResults]);

    return (
        <>
            <div className="ai-body" ref={aiMessageBodyRef}>
                <Choose>
                    <When condition={aiMessageStatus === AI_MESSAGE_STATUS.pending}>
                        <AiPendingMessage summaryMessage={summaryMessage} />
                    </When>
                    <When condition={aiMessageStatus === AI_MESSAGE_STATUS.done}>
                        <AiResultMessage summaryMessage={summaryMessage} />
                    </When>
                </Choose>
            </div>
            <If condition={!archived && STOPABLE_RESULT_STATUS_LIST.includes(currentResultStatus)}>
                <SummaryStopButton summaryMessage={summaryMessage} />
            </If>
        </>
    );
});

AIMessage.propTypes = {
    summaryMessage: PropTypes.shape({
        aiMessageStatus: PropTypes.string.isRequired,
        summaryResults: PropTypes.array.isRequired,
        archived: PropTypes.bool
    }).isRequired,
    moveSidePanelScrollToBottom: PropTypes.func.isRequired
};

AIMessage.defaultProps = {};

export default AIMessage;
