import { $http } from 'modules/index';

class WebOfficeRest {
    static getObjtInfo(objtId) {
        return $http.get(`/drive/v1/weboffice/hancom/${objtId}`);
    }

    static setObjtLock(objtId, params) {
        return $http.post(`/drive/v1/weboffice/hancom/${objtId}/lock?${params}`);
    }

    static setObjtUnlock(objtId, params) {
        return $http.post(`/drive/v1/weboffice/hancom/${objtId}/unlock?${params}`);
    }

    static getObjtStream(objtId, params) {
        return $http.get(`/drive/v1/weboffice/hancom/${objtId}/get?${params}`);
    }

    static putObjtStream(objtId, params, body) {
        return $http.put(`/drive/v1/weboffice/hancom/${objtId}/put?${params}`, body);
    }
}

export default WebOfficeRest;
