import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { summaryActions } from '../../../../../../../redux/actions/Summary';
import { SUMMARY_FORM_TYPE, SUMMARY_LENGTH_TYPE } from '../../../../OptionSelectDialog/SummaryOptionConstant';

const RecommendPrompts = ({ summaryMessage, intl }) => {
    const { files, summaryOptions } = summaryMessage;

    const prompts = useMemo(() => {
        const { lengthType: oldLengthType, formType: oldFormType } = summaryOptions;

        let newLengthType;
        let newFormType;

        switch (oldLengthType) {
            case SUMMARY_LENGTH_TYPE.short:
                newLengthType = SUMMARY_LENGTH_TYPE.medium;
                break;
            case SUMMARY_LENGTH_TYPE.medium:
                newLengthType = SUMMARY_LENGTH_TYPE.short;
                break;
            case SUMMARY_LENGTH_TYPE.long:
                newLengthType = SUMMARY_LENGTH_TYPE.medium;
                break;
            default:
                break;
        }

        switch (oldFormType) {
            case SUMMARY_FORM_TYPE.line:
                newFormType = SUMMARY_FORM_TYPE.format;
                break;
            case SUMMARY_FORM_TYPE.format:
                newFormType = SUMMARY_FORM_TYPE.line;
                break;
            case SUMMARY_FORM_TYPE.keyword:
                newFormType = SUMMARY_FORM_TYPE.line;
                break;
            default:
                break;
        }

        return [
            { text: intl.formatMessage({ id: `drive.text.copilot.summary.option.lengthType.from_${oldLengthType}` }), summaryOptions: { lengthType: newLengthType } },
            { text: intl.formatMessage({ id: `drive.text.copilot.summary.option.formType.from_${oldFormType}` }), summaryOptions: { formType: newFormType } }
        ];
    }, [summaryMessage]);

    const selectedLLM = useSelector(({ summary }) => summary.global.selectedLLM);

    const dispatch = useDispatch();

    const handleClickPrompt = prompt => {
        const { summaryOptions: changedSummaryOptions, text } = prompt;

        dispatch(
            summaryActions.thread.generateUserMessage({
                summaryMessage: {
                    files,
                    text,
                    summaryOptions: { ...summaryOptions, ...changedSummaryOptions, ...selectedLLM }
                }
            })
        );
    };

    return (
        <div className="ai-recommend-prompt">
            {prompts.map(prompt => {
                const { text } = prompt;

                return (
                    <div className="prompt-balloon" role="button" onClick={() => handleClickPrompt(prompt)} key={text}>
                        <i className="ic-20-copilot-summary" />
                        <span>{text}</span>
                    </div>
                );
            })}
        </div>
    );
};

RecommendPrompts.propTypes = {
    summaryMessage: PropTypes.shape({
        files: PropTypes.array.isRequired, // TODO
        summaryOptions: PropTypes.object.isRequired
    }).isRequired
};

RecommendPrompts.defaultProps = {};

export default injectIntl(RecommendPrompts);
