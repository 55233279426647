import { put, takeEvery, all } from 'redux-saga/effects';
import { upload as Actions } from '../actions';
import { getAddFileId } from '../../utils/Fileuploader/FileUploaderUtil';

function* addFile(action) {
    console.log('action', action);
    const { files, metadata } = action;
    const id = getAddFileId();
    const { objtId, onpstId } = metadata;
    yield put({ type: Actions.ADD_FILE, addFiles: { id, files, metadata: { onpstFolderId: objtId, onpstId } } });
}

function* watchSelectFile() {
    yield takeEvery(Actions.SELECT_FILE, addFile);
}

export default function* uploadSaga() {
    yield all([watchSelectFile()]);
}
