import { func, object } from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AuthGuideRest from '../../../apis/AuthGuideRest';

class AuthGuidePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authList: [],
            authCds: []
        };
    }

    componentDidMount() {
        this.authInfoSet();
    }

    authInfoSet() {
        AuthGuideRest.getAuthGuideInfo().then(data => {
            const { list, authCds } = data;
            this.setState({ authList: list, authCds });
        });
    }

    closePopup() {
        const { close } = this.props;
        close();
    }

    render() {
        const { authList, authCds } = this.state;
        const { close, intl } = this.props;
        const authRow = authList.map(auth => (
            <tr key={auth.policyTypCd}>
                <td>{auth.policyTypCdNm}</td>
                {authCds.map(authCd => (
                    <td key={`${auth.policyTypCd}_${authCd.authAbbrCd}`}>{auth[authCd.authAbbrCd] === 'Y' ? 'O' : '-'}</td>
                ))}
            </tr>
        ));

        // 컬럼 크기 계산
        const colWidth = {};
        if (authCds.length) {
            let remainWidth = 671 - 240;
            const defaultWidth = Math.floor(remainWidth / authCds.length);

            for (let idx = 0; idx < authCds.length - 1; idx += 1) {
                colWidth[authCds[idx].authAbbrCd] = `${defaultWidth}px`;
                remainWidth -= defaultWidth;
            }

            // 마지막 컬럼
            const lastAuthAbbrCd = authCds[authCds.length - 1].authAbbrCd;
            colWidth[lastAuthAbbrCd] = `${remainWidth}px`;
        }

        return (
            <React.Fragment>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.authorityguide.authTitle" />
                    </h5>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        data-dismiss="modal"
                        title={intl.formatMessage({ id: 'com.close' })}
                        onClick={() => {
                            close();
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <div className="modal-body">
                    <table className="data-table">
                        <caption>
                            <FormattedMessage id="drive.title.authorityguide.authTitle" />
                        </caption>
                        <colgroup>
                            <col style={{ width: '240px' }} />
                            {authCds.map(authCd => (
                                <col key={`col_${authCd.authAbbrCd}`} style={{ width: colWidth[authCd.authAbbrCd] }} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th />
                                {authCds.map(authCd => (
                                    <th key={`th_${authCd.authAbbrCd}`}>{authCd.authCdNm}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{authRow}</tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a
                            className="btn btn-lg btn-primary"
                            role="button"
                            data-dismiss="modal"
                            onClick={() => {
                                this.closePopup();
                            }}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

AuthGuidePopup.propTypes = {
    close: func,
    intl: object
};

AuthGuidePopup.defaultProps = {
    close: () => {},
    intl: {}
};
export default injectIntl(AuthGuidePopup);
