import React from 'react';
import PropTypes from 'prop-types';

const MaxAuth = props => {
    const {
        file: { maxAuth = '' },
        cellStyle
    } = props;
    const value = maxAuth || '-';
    return (
        <div className="grid-cell files-authority-cell" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

MaxAuth.defaultProps = {
    cellStyle: null
};

MaxAuth.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default MaxAuth;
