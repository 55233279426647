import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createModal } from 'containers/Modal';
import PopupFrame from '../components/presentationals/PopupFrame';
import ModalAlert from '../components/presentationals/ModalAlert';
import store from '../redux/store';
import { toast as reduxToast } from '../redux/actions';

/*
* 버그 발견시 정용찬 프로한테 알려주세요.

- import 방법
1. import ModalService from '......../ModalService';
2. import { popup } from '......../ModalService';


- 사용법
1. ModalService 를 import 했을 경우
ModalService().popup(config).then(
    data => {
        console.log('close with data!!!!', data);
    },
    () => {
        console.log('fail or cancel');
    }
);
2. popup 만 import 했을 경우 ( then 이하 1번과 동일 )
popup(config).then();


- config 정보
1. config 전체 정의
const config = {
    component: <MyComponent myProp1={myProp1} myProp2={myProp2} ... />,         : 필수 / 팝업 컨텐츠로 들어갈 컴포넌트 주입
    beforeCloseCallBack: () => new Promise((res,rej) => {                       : default (()=>Promise.resolve(true)) / 팝업 닫기전 호출되는 콜백 함수. res(true) 필수. true 안돌려주면 안닫힘.
        myFunction.then(chk => {
            if(chk) res(true);
        })
    )},
    autoCloseOption: {                                                          : default ({outsideClick: true, escape: true}) / 외부영역 혹은 esc키 누를경우 자동 닫힘 처리
        outsideClick: true,
        escape: false
    }
}
2. config 간편 정의 ( 컴포넌트 외에 전부 default 값을 쓸 경우 )
const config = <MyComponent myProp1={myProp1} myProp2={myProp2} ... />;


- 내부 컴포넌트 시작 포인트 ( 팝업별로 사이즈가 다름 modal-lg, modal-md, modal-sm )
<div class="modal-dialog modal-md" role="document"><div>


- 내 컴포넌트에서 사용할 수 있는 props
1. close : 닫기 함수
  * close(data) - data 에 값이 있을 경우 팝업을 호출한 then 절에서 resolve 케이스로 받음
  * close() - 아무것도 안넘겼을 경우 then 절에서 reject 케이스로 받음
2. changedData : 데이터가 변경되었음을 알리는 함수
  * changedData() - 이와 같이 호출했을 경우, 팝업 닫기 ( 취소, 외부영역 클릭, esc )시에 beforeCloseCallBack 을 호출해 줌

 */

const popup = config => {
    let baseConfig = config;

    if (config.$$typeof) baseConfig = { component: config };

    const {
        component = <div />,
        beforeCloseCallBack = () => Promise.resolve(true),
        className = 'modal-md',
        modalClassName = '',
        autoCloseOption = {
            escape: true,
            outsideClick: true
        }
    } = baseConfig;

    if (!component) return Promise.reject();

    return createModal(props => (
        <PopupFrame {...props} beforeCloseCallBack={beforeCloseCallBack} autoCloseOption={autoCloseOption} className={className} modalClassName={modalClassName}>
            {component}
        </PopupFrame>
    ));
};

const smallPopup = config => {
    let baseConfig = config;
    if (config.$$typeof) baseConfig = { component: config };
    return popup({ ...baseConfig, className: 'modal-sm' });
};

const largePopup = config => {
    let baseConfig = config;
    if (config.$$typeof) baseConfig = { component: config };
    return popup({ ...baseConfig, className: 'modal-lg' });
};

const viewerPopup = config => {
    let baseConfig = config;
    if (config.$$typeof) baseConfig = { component: config };
    return popup({ ...baseConfig, className: 'modal-viewer', modalClassName: 'viewer' });
};

/*
config = {
    message: String,            - 필수
    btnMessage : {              - 선택
        okay: String,               (default 확인)
        cancel: String              (default 취소)
    },
}
*/

const setBaseConfig = config => {
    let baseConfig = config;
    if (typeof config === 'string') baseConfig = { message: <FormattedMessage id={config} /> };
    else if (typeof config === 'object' && config.id) baseConfig = { message: <FormattedMessage id={config.id || ''} values={{ n: config.values || '' }} /> };
    else if (config.$$typeof) baseConfig = { message: config };
    return baseConfig;
};

const alert = (config = {}) =>
    smallPopup({
        component: <ModalAlert {...setBaseConfig(config)} />,
        autoCloseOption: {
            escape: false,
            outsideClick: false
        }
    });

const confirm = (config = {}) => alert({ ...setBaseConfig(config), useCancel: true });

const toast = (...args) => store.dispatch(reduxToast.showToast(...args.map(i => (typeof i === 'string' ? { id: i } : i))));

const ModalService = () => ({ popup, smallPopup, largePopup, viewerPopup, alert, confirm, toast });

export default ModalService;
export { popup, smallPopup, largePopup, viewerPopup, alert, confirm, toast };
