import React from 'react';
import _ from 'lodash';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import NotificationConstants from 'constants/Notification';
import FileViewerPopup from 'containers/Popup/FileViewerPopup';
import { viewerPopup } from 'utils/ModalService';
import Url from 'utils/Url';
import NotificationRest from 'apis/NotificationRest';
import UserCard from 'containers/NameCard/UserCard';

const NotificationItem = props => {
    const { notifications, push, clickAction, timezoneDiff, hasNameCard, searchKeyword } = props;

    const getNotificationClassName = notificationCategory => {
        let className = 'ic-20-upload';
        switch (notificationCategory) {
            case NotificationConstants.NOTIFICATION_CATEGORIES.SHARED: // 파일, 폴더 공유
                className = 'ic-20-share';
                break;
            case NotificationConstants.NOTIFICATION_CATEGORIES.UPLOAD: // 파일 업로드, 파일 버전 업데이트
            case NotificationConstants.NOTIFICATION_CATEGORIES.VERSIONUP:
                className = 'ic-20-upload';
                break;
            case NotificationConstants.NOTIFICATION_CATEGORIES.PARTNER_ACCOUNT: // 협력사 계정 만료 알림
                className = 'ic-20-collabo';
                break;
            case NotificationConstants.NOTIFICATION_CATEGORIES.OWNER_MODIFICATION: // 소유권 변경
                className = 'ic-20-owner-change';
                break;
            case NotificationConstants.NOTIFICATION_CATEGORIES.REQUEST_MANAGEMENT: // 요청 관리
                className = 'ic-20-req';
                break;
            case NotificationConstants.NOTIFICATION_CATEGORIES.SUMMARY: // 파일 요약
                className = 'ic-20-copilot';
                break;
            default:
                break;
        }
        return className;
    };

    const getRelativeTime = createdTimestamp => {
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        const created = moment(createdTimestamp);

        if (!created.isValid()) return '';

        if (!yesterday.isSameOrBefore(created)) {
            const FORMAT = today.year() === created.year() ? 'MM-DD' : 'YYYY-MM-DD';
            return moment(created)
                .utcOffset(timezoneDiff)
                .format(FORMAT);
        }

        // 상대 시간 표현
        const secondsGap = today.diff(created, 'seconds'); // 1분, 방금전, just
        if (secondsGap <= 60) {
            return <FormattedMessage id="com.text.justBefore" />;
        }
        const minutesGap = today.diff(created, 'minutes'); // 분 단위 (2-59분)
        if (minutesGap <= 60) {
            return <FormattedMessage id="com.text.nMinutesAgo" values={{ n: minutesGap }} />;
        }
        const hoursGap = today.diff(created, 'hours'); // 시간 단위 (1-24시간)
        if (hoursGap <= 24) {
            return <FormattedMessage id="com.text.nHoursAgo" values={{ n: hoursGap }} />;
        }
        return <FormattedMessage id="com.text.yesterday" />; // 어제
    };

    const updateNotificationsAsRead = async stackedNotificationIds => {
        const response = await NotificationRest.updateNotificationsAsRead(stackedNotificationIds);
        const { resultCode, data } = response;
        return resultCode === 200 && data;
    };

    const getReqMngType = notificationType => {
        if (notificationType.indexOf(NotificationConstants.REQUEST_NOTIFICATION_TYPE_PREFIX.REQUEST) === 0) {
            return NotificationConstants.REQUEST_MOVE_TYPE_BY_NOTIFICATION_TYPE.REQUEST;
        }
        if (notificationType.indexOf(NotificationConstants.REQUEST_NOTIFICATION_TYPE_PREFIX.RESPOND) === 0) {
            return NotificationConstants.REQUEST_MOVE_TYPE_BY_NOTIFICATION_TYPE.RESPOND;
        }
        return NotificationConstants.REQUEST_MOVE_TYPE_BY_NOTIFICATION_TYPE.REQUEST;
    };

    const moveAction = (event, notificationType, moveTargetType, moveTargetId, stackedNotificationIds, key) => {
        const { target } = event;
        const { id: targetId } = target;
        if (_.startsWith(targetId, 'notification-user-name-card')) {
            event.stopPropagation();
            return;
        }
        const updated = updateNotificationsAsRead(stackedNotificationIds);
        switch (moveTargetType) {
            case NotificationConstants.MOVE_TARGET_TYPES.FILE: // 파일로 이동
                viewerPopup(<FileViewerPopup objtId={moveTargetId} />).then(
                    () => {
                        return true;
                    },
                    () => {
                        return false;
                    }
                );
                break;
            case NotificationConstants.MOVE_TARGET_TYPES.FOLDER: // 폴더로 이동
                push(`${Url.link}/${moveTargetId}?redirectHome=true`);
                break;
            case NotificationConstants.MOVE_TARGET_TYPES.REQUEST:
                push(`${Url.req_mng}/${getReqMngType(notificationType).toLowerCase()}`);
                break;
            case NotificationConstants.MOVE_TARGET_TYPES.NONE: // move action 없음
            default:
                break;
        }
        clickAction(key, updated);
    };

    const parseMessage = msg => {
        try {
            const { message } = JSON.parse(msg);
            return message;
        } catch (e) {
            return msg;
        }
    };

    const convertMarkedMessage = (msg, keyword) => {
        let message = msg;
        const originMessage = msg;
        const indexArr = [];
        let pointer = 0;
        if (!_.isEmpty(keyword)) {
            const lowerSearchKeyword = keyword.toLowerCase();
            message = message.toLowerCase();

            while (pointer < message.length) {
                const findIndex = message.indexOf(lowerSearchKeyword, pointer);
                if (findIndex > -1) {
                    indexArr.push(findIndex);
                    pointer = findIndex + 1;
                } else {
                    break;
                }
            }

            message = '';
            for (let i = 0; i < originMessage.length; i += 1) {
                if (i === indexArr[0]) {
                    message = message.concat('<mark>');
                }
                message = message.concat(originMessage[i]);
                if (i === indexArr[0] + keyword.length - 1) {
                    message = message.concat('</mark>');
                    indexArr.shift();
                }
            }
        }
        return message;
    };

    const convertMessage = (message, key) => {
        let convertedMessage = _.replace(message, /<sender>(.*?)<\/sender>/g, (match, sender) => {
            const formattedSender = _.size(searchKeyword) > 0 && searchKeyword === sender ? `<mark>${sender}</mark>` : `${sender}`;
            return `<span class="name ${hasNameCard ? 'has-name-card' : ''}" id="${
                hasNameCard ? `notification-user-name-card-${key}` : ''
            }" onclick="event.stopPropagation()">${formattedSender}</span>`;
        });
        convertedMessage = _.replace(convertedMessage, /<folder>(.*?)<\/folder>/g, (match, folder) => `<span class="item-folder-name">${folder}</span>`);
        return convertedMessage;
    };

    const convertAdditionalMessage = additionalMessage => {
        let convertedAdditionalMessage = parseMessage(additionalMessage);

        convertedAdditionalMessage = _.replace(
            convertedAdditionalMessage,
            /<folder>(.*?)<\/folder>/g,
            (match, folder) => `<div class="item-file"><div class="file"><span class="file-name">${folder}</div></div>`
        );
        convertedAdditionalMessage = _.replace(convertedAdditionalMessage, /<file>(.*?)<\/file>/g, (match, file) => {
            let fileName = file;
            let fileExtension = '';

            if (file.lastIndexOf('.') > -1) {
                fileName = file.substring(0, file.lastIndexOf('.'));
                fileExtension = file.substring(file.lastIndexOf('.') + 1);
            }

            fileName = convertMarkedMessage(fileName, searchKeyword);
            fileExtension = convertMarkedMessage(fileExtension, searchKeyword);

            fileExtension = _.size(_.trim(fileExtension)) > 0 ? `<span class="file-extension">${fileExtension}</span>` : '';
            return `<div class="item-file"><div class="file"><span class="file-name">${fileName}</span>${fileExtension}</div></div>`;
        });
        convertedAdditionalMessage = _.replace(convertedAdditionalMessage, /<version>(.*?)<\/version>/g, (match, version) => `<div class="next divider">${version}</div>`);

        convertedAdditionalMessage = _.replace(convertedAdditionalMessage, /<strong>(.*?)<\/strong>/g, (match, target) => {
            return `<strong>${convertMarkedMessage(target, searchKeyword)}</strong>`;
        });

        return convertedAdditionalMessage;
    };

    if (_.isEmpty(notifications)) return <></>;

    return _.map(notifications, notification => {
        const {
            key,
            read,
            notificationType,
            notificationCategory,
            moveTargetType,
            moveTargetId,
            stackedNotificationIds,
            clickReadTimestamp,
            createdTimestamp,
            message,
            additionalMessage,
            senders
        } = notification;
        const hasSender = /<sender>(.*?)<\/sender>/g.test(message);
        const convertedMessage = convertMessage(message, key);
        const convertedAdditionalMessage = convertAdditionalMessage(additionalMessage);
        let nameCard;
        if (hasNameCard && hasSender && _.size(senders) > 0) {
            const { type, id } = senders[0];
            if (type === 'USER' && id) {
                nameCard = (
                    <div onClick={event => event.stopPropagation()} role="button">
                        <UserCard target={`notification-user-name-card-${key}`} id={id} />
                    </div>
                );
            }
        }
        return (
            <li className={clickReadTimestamp || read ? '' : 'new'} key={key}>
                <span className="btn-ic-nor">
                    <i className={getNotificationClassName(notificationCategory)} />
                </span>
                <div className="noti-details" onClick={event => moveAction(event, notificationType, moveTargetType, moveTargetId, stackedNotificationIds, key)} role="button">
                    <div className="case" dangerouslySetInnerHTML={{ __html: convertedMessage }} title={message && message.replace(/(<([^>]+)>)/gi, '')} />
                    <div className="g-inline-flex" dangerouslySetInnerHTML={{ __html: convertedAdditionalMessage }} />
                    <div className="update">{getRelativeTime(createdTimestamp)}</div>
                    {nameCard}
                </div>
            </li>
        );
    });
};

NotificationItem.propTypes = {
    notifications: PropTypes.array,
    push: PropTypes.func.isRequired,
    clickAction: PropTypes.func,
    timezoneDiff: PropTypes.number.isRequired,
    hasNameCard: PropTypes.bool,
    searchKeyword: PropTypes.string
};

NotificationItem.defaultProps = {
    notifications: [],
    clickAction: _.noop,
    hasNameCard: false,
    searchKeyword: ''
};

const mapStateToProps = state => ({
    timezoneDiff: state.auth.user.locale.timezoneDiff
});

const mapDispatchToProps = {
    push: routerPush
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationItem);
