import React from 'react';
import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import InLink from '../../../containers/Popup/Share/InShare/InLink';
import InShareCount from './InShareCount';
import InShareList from './InShareList';

const InShareView = ({ files, shareTargets, isLoading, removeOwnAuthFn, canRemove, intl, getFileName, getExtension, webViewerYn, config, cancelToken, closePopup }) => {
    return (
        <div className="modal-body">
            <dl className="dt-divider">
                <dt>{intl.formatMessage({ id: 'drive.text.share.selectedItems' })}</dt>
                <dd className="none-divider">
                    <div className="g-inline-flex">
                        <div className="previous">
                            <div className="item-file">
                                <div className="file" title={files[0].drive.objtNm}>
                                    <span className="file-name">{files[0].drive.objtSectCd === 'FILE' ? getFileName(files[0].drive.objtNm) : files[0].drive.objtNm}</span>
                                    <If condition={files[0].drive.objtSectCd === 'FILE'}>
                                        <span className="file-extension">{getExtension(files[0].drive.objtNm)}</span>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
            <If condition={files[0].actionPolicy.createInLinkUrl}>
                <InLink files={files} isView={true} webViewerYn={webViewerYn} closeUpperPopup={closePopup} />
            </If>
            <InShareCount isMulti={false} shareTargets={shareTargets} canRemove={canRemove} removeOwnAuthFn={removeOwnAuthFn} />
            <InShareList isMulti={false} shareTargets={shareTargets} isView={true} rowCount={6} isLoading={isLoading} config={config} cancelToken={cancelToken} />
        </div>
    );
};

InShareView.propTypes = {
    files: PropTypes.array,
    shareTargets: PropTypes.array,
    isLoading: PropTypes.bool,
    removeOwnAuthFn: PropTypes.func,
    canRemove: PropTypes.bool,
    getFileName: PropTypes.func,
    getExtension: PropTypes.func,
    webViewerYn: PropTypes.string,
    config: PropTypes.object,
    cancelToken: PropTypes.object,
    closePopup: PropTypes.func
};

InShareView.defaultProps = {
    files: [],
    shareTargets: [],
    isLoading: true,
    removeOwnAuthFn: _.noop,
    canRemove: false,
    getFileName: _.noop,
    getExtension: _.noop,
    webViewerYn: 'N',
    config: {},
    cancelToken: {},
    closePopup: _.noop
};

export default injectIntl(InShareView);
