/*
호출샘플
className={FileIconStyle.getStyle('ic-file-sm', viewerInfo.objtNm)}
첫번째 sizeStyle 는 ic-file-sm (작은거) / ic-file-md (중간거) / ic-file-lg (큰거)
두번째  extName 은 확장자만 주지말고 파일명 전체다넣기  test.ppt 처럼 파일명 주면 알아서 마지막 . 부터 잘라서 처리함.
.이 없거나 확장자가 없거나 요 아래 extensionMap 에 등록된거 아니면 ETC로 리턴되니 참고

 */
const IconFilterKey = {
    IconFilterExtNm: 'iconFilterConfig'
};

function findExtensionGroup(extension) {
    const IconFilterConfigStorage = localStorage.getItem(IconFilterKey.IconFilterExtNm);
    const iconFilterGroup = JSON.parse(IconFilterConfigStorage);
    try {
        return undefined === iconFilterGroup.iconGroup[extension] ? 'ETC' : iconFilterGroup.iconGroup[extension];
    } catch (e) {
        return 'ETC';
    }
}

class FileIconStyle {
    getFilterGroup() {
        const IconFilterConfigStorage = localStorage.getItem(IconFilterKey.IconFilterExtNm);
        const iconFilterGroup = JSON.parse(IconFilterConfigStorage);
        const filterGroupMap = new Map();
        Object.keys(iconFilterGroup.filterGroup).forEach(k => {
            filterGroupMap.set(k, iconFilterGroup.filterGroup[k]);
        });
        return filterGroupMap;
    }

    getStyle(sizeStyle, fullName) {
        if (undefined === fullName) {
            return sizeStyle;
        }

        if (fullName === null) {
            return sizeStyle
                .concat(' ')
                .concat('ic-file-')
                .concat('ETC');
        }

        const lowerExtNm = fullName.substring(fullName.lastIndexOf('.') + 1).toLowerCase();
        const extension = findExtensionGroup(lowerExtNm);

        const iconStyle = sizeStyle
            .concat(' ')
            .concat('ic-file-')
            .concat(extension);
        return iconStyle;
    }
}
export default new FileIconStyle();
