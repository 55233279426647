import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import store from '../redux/store';

const SECONDS_OF_MINUTE = 60;
const MINUTES_OF_HOUR = 60;
const HOURS_OF_DAY = 24;

const FORMAT = {
    // JUST: '방금 전',
    // MINUTES_AGO: '분 전',
    // HOURS_AGO: '시간 전',
    YESTERDAY: 'HH:mm',
    THIS_YEAR: 'MM-DD',
    NOT_THIS_YEAR: 'YYYY-MM-DD'
};

/*
    등록 후 ~ 1분 미만 : Just now / 방금 전
    1분 이후 ~ 1시간 미만 : MMmins ago / MM분 전
    1시간 이후~ 24시간 미만 : Hhhours ago / HH시간 전
    어제(24시간 이후) : Yesterday HH:MM / 어제 HH:MM
    그 외 (24시간 이후) - 당해년도 : MM-DD
    그 외 (24시간 이후) - 전년도 : YYYY-MM-DD
*/
class DateFromNow extends React.Component {
    convert = value => {
        const storeState = store.getState();
        const { auth: { user: { locale: { timezoneDiff: diff = 0 } = {} } = {} } = {} } = storeState;

        const m = moment(value).utcOffset(diff);
        const now = moment().utcOffset(diff);
        const yesterday = moment()
            .utcOffset(diff)
            .subtract(1, 'day');

        const isYesterday = m.isSame(yesterday, 'day');
        const isThisYear = m.year() === now.year();

        if (now.diff(m, 'seconds') <= SECONDS_OF_MINUTE) {
            return <FormattedMessage id="drive.text.list.date.just" />;
        }

        const minintesFromNow = now.diff(m, 'minutes');
        if (minintesFromNow < MINUTES_OF_HOUR) {
            return <FormattedMessage id="drive.text.list.date.minutesAgo" values={{ count: minintesFromNow }} />;
        }

        const hoursFromNow = now.diff(m, 'hours');
        if (hoursFromNow < HOURS_OF_DAY) {
            return <FormattedMessage id="drive.text.list.date.hoursAgo" values={{ count: hoursFromNow }} />;
        }
        if (isYesterday) {
            return (
                <FormattedMessage id="drive.text.list.date.yesterday">
                    {msg => {
                        return `${msg} ${m.format(FORMAT.YESTERDAY)}`;
                    }}
                </FormattedMessage>
            );
        }
        if (isThisYear) {
            return m.format(FORMAT.THIS_YEAR);
        }
        return m.format(FORMAT.NOT_THIS_YEAR);
    };

    render() {
        const { date } = this.props;
        return date ? this.convert(date) : '-';
    }
}

DateFromNow.defaultProps = {
    date: undefined
};

DateFromNow.propTypes = {
    date: PropTypes.string
};

export default DateFromNow;
