import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomModal from '../../CustomModal';

const ApprovalLineGuide = props => {
    const { intl, guideText, close } = props;

    return (
        <CustomModal className="modal-md" isOpen={true} fade={false} backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({ id: 'com.text.sancPath.guide' })}</h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={close}>
                    <i className="ic-20-close" />
                </a>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <textarea className="form-control textarea-lg" readOnly={true} value={guideText}>
                        {guideText}
                    </textarea>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={close}>
                        <span className="btn-text">
                            <FormattedMessage id="com.check" />
                        </span>
                    </a>
                </div>
            </div>
        </CustomModal>
    );
};

ApprovalLineGuide.propTypes = {
    guideText: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
};

export default injectIntl(ApprovalLineGuide);
