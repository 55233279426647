import BaseMenu from '../BaseMenu';
import MenuSupporter from '../../../../utils/FileMenu/MenuSupporter';
import MenuMapper from '../../../../utils/FileMenu/MenuMapper';

const executor = (props, files, e) => {
    const { custom = {} } = props;
    const { openType, moreGroupType = 'normal' } = custom;
    const callback = (inProps, inFiles, inData) => {
        props.callback(inData);
    };
    MenuSupporter.open(moreGroupType, files, callback, openType, e, custom.statusCallback, custom);
};

class More extends BaseMenu {
    getName() {
        return 'more';
    }

    getLabel() {
        return 'drive.button.list.menu.more';
    }

    isVisible() {
        const { custom = {} } = this.props;
        const { moreGroupType = 'normal' } = custom;
        const menusClass = new MenuMapper({ groupType: moreGroupType, custom }, this.files).getMenusClass().filter(ins => !ins.isDivider());
        return menusClass.length;
    }

    isEnable() {
        return this.files.length;
    }

    getIconDom() {
        const { custom = {} } = this.props;
        const { status } = custom;
        return this.getBaseIconDom(this.getName(), 'option-more', 'ic-20-more', executor, status);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default More;
