import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { alert } from '../../../../utils/ModalService';
import DriveInfoRest from '../../../../apis/DriveInfoRest';

const notAllowedChar = /[^ㄱ-힣\w]/;
const maxTagLength = 100;
let elapsed = true;

function tagValidChk(value) {
    if (!(value.length === 0 || value.length > maxTagLength || notAllowedChar.test(value))) {
        return true;
    }
    return false;
}

function failAlert(resultCode) {
    switch (resultCode) {
        case 20921:
            break;
        case 20922:
            alert('drive.alert.tagMaxTenError');
            break;
        default:
            alert('drive.alert.tagChangeFail');
    }
}

function intervalCall(interval, setText) {
    // interval 시간 안에 다시 호출된 함수 콜은 무시한다
    return fn => {
        if (!elapsed) {
            setText('');
            return; // 마지막 호출 후 제한된 경과시간이 지나지 않은 경우 리턴
        }
        elapsed = false;
        fn();
        setTimeout(() => {
            elapsed = true;
        }, interval);
    };
}

const Tag = props => {
    const [text, setText] = useState('');
    const [notAllowChar, setNotAllowChar] = useState(false);
    const { info, reload, intl } = props;
    const { setTag = false } = info.sharePolicy;
    const tags = info.tagInfoVal ? info.tagInfoVal.split(',') : [];
    const intervalCall300 = intervalCall(300, setText);

    let tagInpuBox = React.createRef();

    const chgTag = async (mode, tag) => {
        const api = mode === 'add' ? DriveInfoRest.addTag : DriveInfoRest.removeTag;
        const { resultCode } = await api(info, tag);
        if (resultCode !== 200) failAlert(resultCode);
        else reload();
    };

    const handleOnChange = ({ target }) => {
        const { value } = target;
        setNotAllowChar(notAllowedChar.test(value));
        if (value.length <= maxTagLength) setText(value);
    };

    const handleOnKeyDown = e => {
        const { key, target } = e;
        if (key === 'Enter' && tagValidChk(text)) {
            intervalCall300(() => {
                chgTag('add', text);
                target.blur();
                setText('');
                tagInpuBox.focus();
            });
        }
    };

    const inputTagFocus = () => {
        document.getElementById('tag-input-box').classList.add('is-focused');
    };

    const inputTagFocusOut = () => {
        document.getElementById('tag-input-box').classList.remove('is-focused');
    };

    const clear = () => {
        setText('');
        setNotAllowChar(notAllowedChar.test(''));
    };

    const handleRemove = tag => chgTag('remove', tag);

    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.tag" />
            </dt>
            <dd>
                {setTag && (
                    <div className="box-input-group" id="tag-input-box">
                        <span className="btn-ic-nor">
                            <i className="ic-16-hash" />
                        </span>
                        <input
                            ref={ref => {
                                tagInpuBox = ref;
                            }}
                            className="form-control"
                            type="text"
                            placeholder={intl.formatMessage({ id: 'drive.placeHolder.maxCharLength' })}
                            value={text}
                            onChange={handleOnChange}
                            onKeyDown={handleOnKeyDown}
                            onFocus={inputTagFocus}
                            onBlur={inputTagFocusOut}
                        />
                        <a
                            className="btn-ic-nor btn-func-clear"
                            role="button"
                            title={intl.formatMessage({ id: 'com.text.clear' })}
                            onClick={clear}
                            style={{ display: text.length > 0 ? 'inline-flex' : 'none' }}>
                            <i className="ic-16-clear" />
                        </a>
                    </div>
                )}
                {notAllowChar && (
                    <div className="input-remark">
                        <div className="err-msg">
                            <FormattedMessage id="drive.text.tagErrorMsg1" />
                        </div>
                    </div>
                )}
                <div className="tag-box">
                    {tags.map(tag => (
                        <div className="tag-chip-set has-close" key={tag}>
                            <span className="tag-text" title={tag}>
                                {tag}
                            </span>
                            {setTag && (
                                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.delete' })} onClick={() => handleRemove(tag)}>
                                    <i className="ic-12-close-tag" />
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </dd>
        </>
    );
};

Tag.propTypes = {
    info: object,
    reload: func
};

Tag.defaultProps = {
    info: {},
    reload: () => false
};

export default injectIntl(Tag);
