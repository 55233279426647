import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const DeleteReason = props => {
    const {
        file: { drive },
        intl,
        cellStyle
    } = props;

    const { objtDelCd } = drive;

    let deleteReason;

    switch (objtDelCd) {
        case 'DPRSRVEXP':
            deleteReason = intl.formatMessage({ id: 'drive.text.expired' });
            break;
        case 'DCPGS':
            deleteReason = intl.formatMessage({ id: 'drive.text.non-compliance' });
            break;

        case 'DTEMPORARY':
            deleteReason = intl.formatMessage({ id: 'drive.text.deleted' });
            break;
        default:
            deleteReason = '-';
            break;
    }

    return (
        <div className="grid-cell files-delReason-cell" style={cellStyle} title={deleteReason}>
            {deleteReason}
        </div>
    );
};

DeleteReason.defaultProps = {
    cellStyle: null
};

DeleteReason.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default injectIntl(DeleteReason);
