import axios from 'axios';
import AUTH_CONST from 'constants/Auth';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import { auth as AUTH_ACTION } from 'src/redux/actions';
// import { connect } from 'react-redux';
import Loading from 'src/redux/actions/Loading';
import GlobalPopupService from 'src/redux/service/GlobalPopup';
import store from 'src/redux/store';
import AuthService from 'utils/AuthService';
import Signature from 'utils/SignatureUtil';
import { TOKEN_STATUS } from '../redux/actions/Auth';
import cookie from '../utils/Cookie';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get.Pragma = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

const LOADING_AVOID_URL = ['/drive/v1/file-download/profile/', '/drive/v1/summary'];
const HMAC_AVOID_URL = ['/auth/v1/users/compare/token', '/auth/v1/domains/auth', '/auth/v1/auth/token/session-filter', '/common/v1/auth/token/session-filter', '/drive/v1/auth/token/session-filter'];

const EXPIRED_STATUS_CODES = [AUTH_CONST.RESULT_CODE.AUTH_INVALID_TOKEN, AUTH_CONST.RESULT_CODE.AUTH_TOKEN_EXPIRED];
export const LICENSE_EXCEPTION_CODES_WITH_MESSAGES = {
    10024: 'com.alert.license.expiration', // 라이센스 만료
    10025: 'com.alert.license.exceed.numberOfusers', // 라이센스 사용자 수 초과
    10026: 'com.alert.license.unregistered' // 라이센스 없음
};

const SYSTEM_ACCESS_POLICY_MESSAGE = {
    40300: 'com.alert.system.policy.blocked',
    20401: 'com.alert.system.policy.impossible',
    20402: 'drive.alert.download.delFile'
};

const doDisplatchLoading = (url, status) => {
    if (_.isEmpty(LOADING_AVOID_URL.filter(e => url.startsWith(e)))) {
        store.dispatch({ type: status });
    }
};

// Add a request interceptor
const requestInterceptorOnSuccess = config => {
    const { url, passHmac } = config;
    doDisplatchLoading(url, Loading.OPEN);
    // store.dispatch({ type: Loading.OPEN });
    const configAddedToken = config;

    if (_.isObject(config.params) && !_.isEmpty(config.params)) {
        const keys = Object.keys(config.params);
        const encodedParams = {};
        keys.forEach(e => {
            if (config.params[e]) {
                if (typeof config.params[e] === 'string') {
                    encodedParams[e] = encodeURIComponent(config.params[e]);
                } else {
                    encodedParams[e] = config.params[e];
                }
            } else {
                encodedParams[e] = config.params[e];
            }
        });
        // eslint-disable-next-line no-param-reassign
        config.params = encodedParams;
    }

    const params = _.get(configAddedToken, 'params');
    if (_.isObject(params) && !_.isEmpty(params)) {
        configAddedToken.params = _.fromPairs(_.toPairs(params).sort());
    }

    if (!_.isEmpty(HMAC_AVOID_URL.filter(e => url.startsWith(e))) || passHmac) {
        return configAddedToken;
    }

    if (_.isEmpty(AuthService.getAuthToken()) || _.isEmpty(AuthService.getSignatureKey())) {
        store.dispatch(AUTH_ACTION.setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: true, isLogout: false } }));
        return Promise.reject();
    }

    configAddedToken.headers.tuid = AuthService.getAuthToken();
    if (config.url === '/common/v1/helpcenter/question/attachment' && config.headers['Content-Type'] === 'multipart/form-data') {
        // 개행문자 (\n) 서버에서 처리가 안되서 url 로만 hmac 생성
        configAddedToken.headers.hmac = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(config.url, AuthService.getSignatureKey()));
    } else {
        configAddedToken.headers.hmac = Signature.generateSignature(config, AuthService.getSignatureKey());
    }

    return configAddedToken;
};
const requestInterceptorOnError = error => Promise.reject(error.response.data);
axios.interceptors.request.use(requestInterceptorOnSuccess, requestInterceptorOnError);

// Add a response interceptor
const responseInterceptorOnSuccess = success => {
    const { data, config } = success;
    const { url } = config;

    const resultCode = _.get(data, 'resultCode');
    if (_.includes(EXPIRED_STATUS_CODES, resultCode)) {
        // token expired
        store.dispatch(AUTH_ACTION.setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: true, isLogout: false } }));
        return new Promise(() =>
            setTimeout(() => {
                throw new Error('TOKEN_EXPIRED');
            })
        );
    }

    if (!_.isNil(LICENSE_EXCEPTION_CODES_WITH_MESSAGES[resultCode])) {
        GlobalPopupService.openAlertPopup({ id: LICENSE_EXCEPTION_CODES_WITH_MESSAGES[resultCode] });
    }

    if (!_.isNil(SYSTEM_ACCESS_POLICY_MESSAGE[resultCode])) {
        GlobalPopupService.openAlertPopup({ id: SYSTEM_ACCESS_POLICY_MESSAGE[resultCode] }).then(() => {
            window.open('about:blank', '_self').close();
        });
        return Promise.reject(data);
    }

    doDisplatchLoading(url, Loading.CLOSE);
    // store.dispatch({ type: Loading.CLOSE });
    return data;
};

const responseInterceptorOnError = error => {
    const { response, config } = error;
    const { url } = config;
    const { status } = response;

    const ssoMethod = cookie.getCookieText('ssoMethod');
    if (ssoMethod === 'SESSION_FILTER') {
        if (status === 403) {
            store.dispatch(AUTH_ACTION.setTokenStatus(TOKEN_STATUS.EXPIRED, { expirationAction: { needAlert: true, isLogout: false } }));
        } else {
            GlobalPopupService.openAlertPopup({ id: 'com.alert.unexpected-error', values: { code: status } });
        }
    }
    doDisplatchLoading(url, Loading.CLOSE);
    // store.dispatch({ type: Loading.CLOSE });
    return Promise.reject(_.get(response, 'data', null));
};
axios.interceptors.response.use(responseInterceptorOnSuccess, responseInterceptorOnError);

class $http {
    static request(config) {
        return axios.request(config);
    }

    static get(uri, config) {
        return axios.get(uri, config);
    }

    static post(uri, data, config) {
        return axios.post(uri, data, config);
    }

    static put(uri, data, config) {
        return axios.put(uri, data, config);
    }

    static delete(uri, config) {
        return axios.delete(uri, config);
    }

    static head(uri, config) {
        return axios.head(uri, config);
    }

    static options(uri, config) {
        return axios.options(uri, config);
    }

    static patch(uri, config) {
        return axios.patch(uri, config);
    }

    static create(config) {
        const instance = axios.create(config);
        instance.interceptors.request.use(requestInterceptorOnSuccess, requestInterceptorOnError);
        instance.interceptors.response.use(responseInterceptorOnSuccess, responseInterceptorOnError);
        return instance;
    }

    static createWithoutResponseInterceptor(config) {
        const instance = axios.create(config);
        instance.interceptors.request.use(requestInterceptorOnSuccess, requestInterceptorOnError);
        return instance;
    }
}

export { axios as axiosInstance };
export default $http;
