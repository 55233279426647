import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { END } from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { openConfirm } from '../actions/GlobalPopup';

let store = null;
const middlewares = [];

const showConfirm = message => {
    return new Promise(resolve => {
        store.dispatch(
            openConfirm(message, result => {
                resolve(result);
            })
        );
    });
};

export const history = createBrowserHistory({
    getUserConfirmation: async (message, callback) => {
        const result = await showConfirm(message);
        callback(result);
    }
});

function getStore() {
    if (store != null) return store;

    const sagaMiddleware = createSagaMiddleware();
    middlewares.push(routerMiddleware(history));
    middlewares.push(sagaMiddleware);

    if (process.env.NODE_ENV === 'development') {
        store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middlewares)));
        if (module.hot) {
            // Enable Webpack hot module replacement for reducers
            module.hot.accept('../reducers', () => {
                const nextRootReducer = require('../reducers').default; // eslint-disable-line
                store.replaceReducer(nextRootReducer);
            });
        }

        middlewares.push(
            createLogger({
                collapsed: true,
                predicate: (getState, action) => action.type.startsWith('summary')
            })
        );
    } else {
        store = createStore(rootReducer(history), applyMiddleware(...middlewares));
    }
    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);
    return store;
}

export default getStore();

export function getReduxState() {
    return (getStore() || {}).getState();
}
