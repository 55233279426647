import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { If } from 'jsx-control-statements';
import { getTextMessageIdByLengthType, getTextMessageIdByFormType } from '../../../../Common/Util/SummaryMessageUtil';
import SummaryText from './SummaryText';
import { AI_MESSAGE_RESULT_STATUS, OPTION_SHOWABLE_RESULT_STATUS_LIST } from '../../../../Common/SummaryConstant';

const SummarySuccessResult = ({ summaryResult, archived, intl }) => {
    const { resultStatus, summaryOptions: { lengthType, formType } = {} } = summaryResult;

    return (
        <>
            <If condition={resultStatus === AI_MESSAGE_RESULT_STATUS.typingStop}>
                <div className="ai-loading ui-loading-stop" />
            </If>
            <div className="ai-result">
                <If condition={OPTION_SHOWABLE_RESULT_STATUS_LIST.includes(resultStatus)}>
                    <div className="ai-result-keyword">
                        <FormattedHTMLMessage
                            tagName="span"
                            id="drive.text.copilot.summary.to_text"
                            values={{ lengthType: intl.formatMessage({ id: getTextMessageIdByLengthType(lengthType) }), formType: intl.formatMessage({ id: getTextMessageIdByFormType(formType) }) }}
                        />
                    </div>
                </If>
                <SummaryText summaryResult={summaryResult} archived={archived} />
            </div>
        </>
    );
};

SummarySuccessResult.propTypes = {
    summaryResult: PropTypes.shape({
        resultStatus: PropTypes.string.isRequired,
        files: PropTypes.object.isRequired, // TODO
        summaryOptions: PropTypes.object.isRequired,
        summaryText: PropTypes.string,
        summaryDateTime: PropTypes.string
    }).isRequired,
    archived: PropTypes.bool
};

SummarySuccessResult.defaultProps = {
    archived: false
};

export default injectIntl(SummarySuccessResult);
