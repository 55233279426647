import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from '../presentationals/DriveDetail/Tab';
import DriveDetailHistory from './DriveDetailHistory';
import { Name } from '../presentationals/DriveDetail/Info';
import DriveDetailInfo from './DriveDetailInfo';
import FileReload from '../../utils/FileReload';

const additionalClass = 'has__drv-file-detail';
const driveDetailScrollOffset = 234;
const calcHeight = () => window.innerHeight - driveDetailScrollOffset;

class DriveDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'info',
            height: calcHeight()
        };
        this.useUiEle = null;
    }

    changeTab = mode => this.setState({ mode });

    componentDidMount() {
        this.useUiEle = document.getElementsByClassName('use-ui');
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        if (isOpen === prevProps.isOpen) return;
        const { classList } = this.useUiEle[0];
        if (isOpen && !classList.contains(additionalClass)) classList.add(additionalClass);
        else classList.remove(additionalClass);
    }

    componentWillUnmount() {
        const { classList } = this.useUiEle[0];
        classList.remove(additionalClass);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    reload = () => {
        const { detailObject } = this.props;
        FileReload.call(undefined, [detailObject]);
    };

    handleWindowResize = () => {
        this.setState({ height: calcHeight() });
    };

    render() {
        const { isOpen, close, detailObject, history } = this.props;
        const { drive, sharedCount = 0 } = detailObject;

        if (!isOpen || !drive) return '';

        const { mode, height } = this.state;
        return (
            <aside className="drv-file-detail">
                <a
                    className="btn-ic-nor close"
                    role="button"
                    onClick={() => {
                        close();
                    }}>
                    <i className="ic-20-close" />
                </a>
                <Name objt={drive} isShared={sharedCount > 0} />
                <div className="nav-tab-conts">
                    <Tab mode={mode} changeTab={this.changeTab} />
                    {mode === 'info' && <DriveDetailInfo detailObject={detailObject} history={history} reload={this.reload} height={height} />}
                    {mode === 'history' && <DriveDetailHistory objt={drive} height={height} />}
                </div>
            </aside>
        );
    }
}

DriveDetail.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    detailObject: PropTypes.object,
    history: PropTypes.object.isRequired
};

DriveDetail.defaultProps = {
    isOpen: false,
    close: () => {},
    detailObject: {}
};

export default DriveDetail;
