import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        notificationDialog: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    draft.isOpen = true;
                    draft.resultStatus = payload.resultStatus;
                    draft.files = payload.files;
                    draft.isInProgress = !!payload.isInProgress;

                    if (payload.errorMessage) {
                        draft.errorMessage = payload.errorMessage;
                    }
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                });
            }
        }
    },
    { isOpen: false, files: [] },
    { prefix: 'summary' }
);
