/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getTreeFromFlatData } from 'react-sortable-tree';
import Tree, { selectNode } from 'presentationals/Tree';
import DeptRest from 'apis/DeptRest';

const TeamTree = props => {
    const scrollBarsRef = React.createRef();
    const { height, width, leftPadding, loginUser, selectTeam, searchedTeam, autoHeight } = props;
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        if (_.isEmpty(teams)) {
            DeptRest.retrieveDepts({ tenantId: loginUser.tenantId }).then(data => {
                // const isEnglishLanguage = loginUser.locale.languageCode === '002';
                const flatData = _.map(data, t => ({
                    id: t.deptId,
                    parentId: t.deptId === t.uprDeptId ? '0' : t.uprDeptId,
                    title: t.deptLangCdNm
                }));
                setTeams(getTreeFromFlatData({ flatData }));
            });

            return;
        }

        const searchedId = _.get(searchedTeam, 'deptId');
        const expanded = selectNode({ treeData: teams, id: searchedId });
        setTeams(_.cloneDeep(expanded));
    }, [searchedTeam, loginUser.userId]);

    function handleClick(e, { node }) {
        selectTeam(node);
    }

    return (
        <div className="box-line">
            <Tree
                className="tree-wrapper organize"
                scrollBarsRef={scrollBarsRef}
                customScroll={true}
                height={height}
                width={width}
                leftPadding={leftPadding}
                treeData={teams}
                handleNodeClick={handleClick}
                onChange={setTeams}
                autoHeight={autoHeight}
            />
        </div>
    );
};

TeamTree.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    leftPadding: PropTypes.number,
    loginUser: PropTypes.object.isRequired,
    selectTeam: PropTypes.func,
    searchedTeam: PropTypes.Object,
    autoHeight: PropTypes.bool
};

TeamTree.defaultProps = {
    height: 343,
    width: 314,
    leftPadding: 0,
    selectTeam: _.noop,
    searchedTeam: undefined,
    autoHeight: true
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(mapStateToProps)(TeamTree);
