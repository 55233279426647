import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';

class CustomModal extends Component {
    onModalOpened = () => {
        this.modalEl.parentElement.parentElement.style.position = 'absolute';
        this.modalEl.parentElement.parentElement.style.zIndex = '1050';
        this.modalEl.parentElement.parentElement.style.display = 'block';
        const { onOpened } = this.props;
        onOpened();
    };

    render() {
        const { children } = this.props;
        return (
            <Modal
                {...this.props}
                innerRef={el => {
                    this.modalEl = el;
                }}
                onOpened={this.onModalOpened}>
                {children}
            </Modal>
        );
    }
}

CustomModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onOpened: PropTypes.func
};

CustomModal.defaultProps = {
    onOpened: _.noop
};

export default CustomModal;
