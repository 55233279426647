import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SizeConverter from '../../../utils/SizeConverter';
import FileIconStyle from '../../../utils/FileIconStyle';

class UploadItem extends Component {
    shouldComponentUpdate(nextProps) {
        const { fileItem } = this.props;
        return fileItem !== nextProps.fileItem;
    }

    renderUploadProgressCell() {
        const { fileItem } = this.props;
        const { status, progress } = fileItem;

        if (status === 'failed') {
            return <i className="ic-20-fail" />;
        }
        if (status === 'completed') {
            return <i className="ic-20-done" />;
        }
        return (
            <div className={`c-progress p${progress}`}>
                <div className="slice">
                    <div className="bar" />
                    <div className="fill" />
                </div>
            </div>
        );
    }

    render() {
        const { fileItem } = this.props;
        const { file, isError, progress, status } = fileItem;
        const { onRemove, intl } = this.props;
        const failed = intl.formatMessage({ id: 'drive.text.upload.status.failed' });
        const remove = intl.formatMessage({ id: 'drive.button.upload.item.remove' });

        return (
            <div className={`grid-row${status === 'queued' ? ' standby-row' : ''}`}>
                <div className="grid-cell upload-item-cell">
                    <div className="item-file">
                        <i className={FileIconStyle.getStyle('ic-file-sm', file.name)} />
                        <div className="file" title={file.name}>
                            <span className="file-name">{file.nm}</span>
                            {file.ext.length > 0 ? <span className="file-extension">{file.ext}</span> : ''}
                        </div>
                    </div>
                </div>
                <div className="grid-cell upload-size-cell">
                    <span className="size">{SizeConverter.convert(file.size)}</span>
                </div>
                <div className="grid-cell upload-progress-cell">{this.renderUploadProgressCell()}</div>
                <div className="grid-cell upload-status-cell">
                    <span className="percent">{isError ? failed : `${progress}%`}</span>
                </div>
                <div className="grid-cell upload-del-cell">
                    <a
                        className="btn-ic-nor btn-sm"
                        role="button"
                        title={remove}
                        onClick={e => {
                            onRemove();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-close-sm" />
                    </a>
                </div>
            </div>
        );
    }
}

UploadItem.propTypes = {
    fileItem: PropTypes.object,
    onRemove: PropTypes.func,
    intl: PropTypes.object
};

UploadItem.defaultProps = {
    fileItem: {},
    onRemove: () => {},
    intl: {}
};

export default injectIntl(UploadItem);
