import React from 'react';
import _ from 'lodash';
import ModalFactory from './ModalFactory';

const modal = {};

const PromiseModal = () => (
    <ModalFactory
        ref={node => {
            modal.stack = node;
        }}
    />
);

const createModal = (Component, options, props) => modal.stack.create(Component, options)(props);
const getModalCount = () => {
    return _.size(_.get(modal, 'stack.state.modals', {}));
};

export default PromiseModal;

export { createModal, getModalCount };
