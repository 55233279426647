import React from 'react';
import { number, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { popup } from '../../../../utils/ModalService';
import FileVersion from '../../FileVersion/FileVersion';

const Location = props => {
    const { verSno, objtId, onpstId, objtNm } = props;
    const openVersionHistoryPopup = () => {
        const files = [{ drive: { objtId, onpstId, objtNm } }];
        popup(<FileVersion files={files} />).then(
            data => {
                if (data) {
                    console.log('');
                }
            },
            () => false
        );
    };
    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="drive.text.nowVersion" />
            </dt>
            <dd>
                <div className="g-inline-flex">
                    <div className="ellipsis">
                        <em className="version-num">{verSno}</em>
                    </div>
                    <div className="next">
                        <a className="btn btn-sm btn-secondary" role="button" onClick={openVersionHistoryPopup}>
                            <span className="btn-text">
                                <FormattedMessage id="drive.text.openVersionHistory" />
                            </span>
                        </a>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    );
};

Location.propTypes = {
    verSno: number,
    objtId: string,
    onpstId: string,
    objtNm: string
};

Location.defaultProps = {
    verSno: 0,
    objtId: '',
    onpstId: '',
    objtNm: ''
};

export default Location;
