import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DateFromNow from 'utils/DateFromNow';
import FileIconStyle from '../../../../utils/FileIconStyle';
import Option from '../Column/Option';
import { withFilesContext } from '../DriveFilesContext';
import { fileTypes } from '../../../presentationals/DriveDetail/History/TypeName';

class RecentRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
    }

    fileIconCustom = {
        statusCallback: isOpen => {
            this.setState({ hover: isOpen });
        },
        openType: 'normal_left',
        moreGroupType: 'recent'
    };

    onClick = e => {
        const {
            file,
            context,
            context: {
                props: { pageClass }
            }
        } = this.props;
        pageClass.goToPage(file, context, e);
    };

    render() {
        const {
            file,
            index,
            style: propsStyle,
            context: {
                props: {
                    pageClass: {
                        menu: {
                            row: { iconGroupType }
                        }
                    }
                }
            }
        } = this.props;
        const { drive, personalization = {} } = file;
        const { hover } = this.state;

        const iconSize = index === 0 ? 'lg' : 'md';
        // const [name, extension] = drive.objtNm.split('.');

        const seperate = drive.objtNm.lastIndexOf('.');
        const name = seperate > 0 ? drive.objtNm.substring(0, seperate) : drive.objtNm;
        const extension = seperate > 0 ? drive.objtNm.substring(seperate + 1) : null;

        let style = null;
        if (index === 0) {
            style = Object.assign({}, propsStyle, { height: propsStyle.height + 15 });
        } else {
            style = Object.assign({}, propsStyle, { top: propsStyle.top + 15 });
        }

        return file ? (
            <div style={style} className={`grid-row ${hover ? ' is-hovered ' : ''}${drive.objtSectCd === 'FOLDER' ? ' folder-row ' : ''}`}>
                <div className="grid-cell files-name-cell">
                    <div className="item-file">
                        <i className={FileIconStyle.getStyle(`ic-file-${iconSize}`, drive.objtNm)} />
                        <div className="file file-link" role="button" onClick={this.onClick}>
                            <span className="file-name">{name}</span>
                            {extension && <span className="file-extension">{extension}</span>}
                        </div>
                    </div>
                    <div className="g-inline-flex update">
                        <div className="previous">
                            <DateFromNow date={personalization.usedDt} />
                        </div>
                        <div className="comma">
                            <span>
                                <FormattedMessage id={fileTypes[personalization.usedType]} />
                            </span>
                        </div>
                    </div>
                </div>
                <Option groupType={iconGroupType} file={file} fileIconCustom={this.fileIconCustom} />
            </div>
        ) : null;
    }
}

RecentRow.propTypes = {
    file: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    context: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired
};

RecentRow.displayName = 'RecentRow';
export default withFilesContext(RecentRow, []);
