/* eslint-disable */
import React from 'react';
import { array } from 'prop-types';
import { If } from 'jsx-control-statements';
import { FormattedMessage } from 'react-intl';
import { popup } from '../../../../utils/ModalService';
import SharePeoplePopup from './SharePeoplePopup';

const SharePeople = props => {
    const { shareInfo } = props;

    const openSharePeoplePopup = () => {
        popup(<SharePeoplePopup shareInfo={shareInfo} />);
    };

    return (
        <>
            <div className="ellipsis" title={shareInfo[0].objtShareTgtNm} onClick={openSharePeoplePopup}>
                {shareInfo[0].objtShareTgtNm}
            </div>
            <If condition={shareInfo.length > 1}>
                <div className="next" onClick={openSharePeoplePopup}>
                    <FormattedMessage id="drive.text.share.seletedObjectCount" values={{ n: shareInfo.length - 1 }} />
                </div>
            </If>
        </>
    );
};

SharePeople.propTypes = {
    shareInfo: array
};

SharePeople.defaultProps = {
    shareInfo: []
};

export default SharePeople;
