import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class HelpQuestionFileDropZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropOn: false
        };
        this.isComponentMounted = false;
        this.popupEl = null;
        this.dropZoneEl = null;
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.popupEl = document.querySelector('#HelpQuestionPopup').parentElement;
        this.dropZoneEl = document.querySelector('#HelpQuestionFileDropZone');

        this.popupEl.addEventListener('dragenter', this.onDragEnterPopup);
        this.popupEl.addEventListener('dragover', this.onDragOverPopup);
        this.popupEl.addEventListener('dragleave', this.onDragLeavePopup);
        this.popupEl.addEventListener('drop', this.onDropPopup);
        this.dropZoneEl.addEventListener('dragover', this.onDragOverDropZone);
        this.dropZoneEl.addEventListener('drop', this.onDropDropZone);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
        this.popupEl.removeEventListener('dragenter', this.onDragEnterPopup);
        this.popupEl.removeEventListener('dragover', this.onDragOverPopup);
        this.popupEl.removeEventListener('dragleave', this.onDragLeavePopup);
        this.popupEl.removeEventListener('drop', this.onDropPopup);
        this.dropZoneEl.removeEventListener('dragover', this.onDragOverDropZone);
        this.dropZoneEl.removeEventListener('drop', this.onDropDropZone);
    }

    onDragEnterPopup = () => {
        if (this.isComponentMounted) this.setState({ isDropOn: true });
    };

    onDragOverPopup = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    onDragLeavePopup = event => {
        event.preventDefault();
        event.stopPropagation();
        const { clientX, clientY } = event;
        if (clientX <= 0 || clientY <= 0) {
            if (this.isComponentMounted) this.setState({ isDropOn: false });
        }
    };

    onDropPopup = event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.isComponentMounted) this.setState({ isDropOn: false });
    };

    onDragOverDropZone = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    onDropDropZone = event => {
        event.preventDefault();
        event.stopPropagation();
        const { files } = event.dataTransfer;
        const filesRe = [];

        for (let i = 0; i < files.length; i += 1) {
            if (
                files[i].type === ''
                /* folder 일경우 올라가지 않게 하기위한것이지만 간혹 파일중에서도 type이 없는것이 있음 (예:파일서버에올리기위해확장자바꾼로그파일) 어차피 그런것들은 업로드하다가 에러나기때문에 미리 막음 */
            ) {
                // continue;
            } else {
                filesRe.push(files[i]);
            }
        }

        const { onDrop } = this.props;
        onDrop(filesRe);
        if (this.isComponentMounted) this.setState({ isDropOn: false });
    };

    render() {
        const { isDropOn } = this.state;
        return (
            <div id="HelpQuestionFileDropZone" className="dnd-zone active" style={{ display: isDropOn ? 'block' : 'none' }}>
                <span>
                    <FormattedMessage id="drive.text.dropzone.drophere" />
                </span>
            </div>
        );
    }
}

HelpQuestionFileDropZone.propTypes = {
    onDrop: PropTypes.func.isRequired
};

export default HelpQuestionFileDropZone;
