import moment from 'moment';

const KEYS = {
    '001': 'ko',
    '002': 'en',
    '003': 'de',
    '004': 'zh-cn'
};

function locale(lang) {
    moment.locale(KEYS[lang] || 'ko');
}

export { locale }; // eslint-disable-line
