import React, { Component } from 'react';
import { Choose, Otherwise, When, If } from 'jsx-control-statements';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NameCard from '../../../../containers/NameCard/NameCard';
import SelectBox from '../../../SelectBox';
import CharacterImageContainer from '../../../../containers/CharacterImage/CharacterImageContainer';

class ShareTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    shouldComponentUpdate(nextProps) {
        const { shareTarget, index } = this.props;

        if (
            shareTarget.objtShareTgtId !== nextProps.shareTarget.objtShareTgtId ||
            shareTarget.authCd !== nextProps.shareTarget.authCd ||
            shareTarget.lwrDeptIcluYn !== nextProps.shareTarget.lwrDeptIcluYn ||
            shareTarget.evtSectCd !== nextProps.shareTarget.evtSectCd ||
            index !== nextProps.index
        ) {
            return true;
        }

        return false;
    }

    changeAuthCd = value => {
        const { shareTarget, changeShareTargetFn } = this.props;

        if (shareTarget.evtSectCd !== 'SHAREADD') {
            changeShareTargetFn(shareTarget.objtShareTgtId, value, shareTarget.lwrDeptIcluYn, 'SHAREMOD');
        } else {
            changeShareTargetFn(shareTarget.objtShareTgtId, value, shareTarget.lwrDeptIcluYn, shareTarget.evtSectCd);
        }
    };

    deleteShareTarget = () => {
        const { shareTarget, deleteShareTargetFn } = this.props;

        deleteShareTargetFn(shareTarget.objtShareTgtId);
    };

    changeLwrDeptIcluYn = () => {
        const { shareTarget, changeShareTargetFn } = this.props;

        if (shareTarget.evtSectCd !== 'SHAREADD') {
            changeShareTargetFn(shareTarget.objtShareTgtId, shareTarget.authCd, shareTarget.lwrDeptIcluYn === 'Y' ? null : 'Y', 'SHAREMOD');
        } else {
            changeShareTargetFn(shareTarget.objtShareTgtId, shareTarget.authCd, shareTarget.lwrDeptIcluYn === 'Y' ? null : 'Y', shareTarget.evtSectCd);
        }
    };

    changeBatchChangeValue = value => {
        const { shareTarget, changeShareTargetFn } = this.props;

        if (shareTarget.evtSectCd !== value) {
            changeShareTargetFn(shareTarget.objtShareTgtId, shareTarget.authCd, shareTarget.lwrDeptIcluYn, value);
        }
    };

    render() {
        const { shareTarget, index, isView, style, isMulti, intl, config, cancelToken } = this.props;

        return (
            <div className={`grid-row${shareTarget.isNew && !isMulti ? ' new-row' : ''}`} style={style}>
                <div className="grid-cell user-cell">
                    <div className="item-user">
                        <CharacterImageContainer
                            objtId={_.isEmpty(shareTarget.objtShareTgtPhotoFilePathVal) ? '' : shareTarget.objtShareTgtPhotoFilePathVal}
                            userId={shareTarget.objtShareTgtId}
                            text={shareTarget.objtShareTgtNm}
                            size="md"
                            userType={shareTarget.objtShareTgtUserSectCd}
                            characterType={shareTarget.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'team'}
                            cancelToken={cancelToken}
                        />
                        <div className="user-profile">
                            <Choose>
                                <When condition={shareTarget.objtShareTgtSectCd === 'INDIVIDUAL'}>
                                    <div title={`${shareTarget.objtShareTgtNm} ${_.toString(shareTarget.objtShareTgtCopNm)}`}>
                                        <span id={`shareTarget-${index}-div`} className="name has-name-card">
                                            {shareTarget.objtShareTgtNm}
                                        </span>
                                        <span className="position">{shareTarget.objtShareTgtCopNm}</span>
                                    </div>
                                    <span className="partname">{shareTarget.objtShareTgtDeptNm}</span>
                                </When>
                                <Otherwise>
                                    <div title={shareTarget.objtShareTgtNm}>
                                        <span id={`shareTarget-${index}-div`} className="name has-name-card">
                                            {shareTarget.objtShareTgtNm}
                                        </span>
                                    </div>
                                </Otherwise>
                            </Choose>
                        </div>
                    </div>
                </div>
                <div className="grid-cell inclusion-cell">
                    <If condition={shareTarget.objtShareTgtSectCd === 'DEPARTMENT' && shareTarget.evtSectCd !== 'SHAREDEL'}>
                        <a className={`btn btn-sm btn-toggle ${shareTarget.lwrDeptIcluYn === 'Y' ? 'on' : 'off'} ${isView ? 'disabled' : ''}`} role="button" onClick={this.changeLwrDeptIcluYn}>
                            <span className="btn-text">
                                {intl.formatMessage(shareTarget.lwrDeptIcluYn === 'Y' ? { id: 'drive.button.share.includeLowerDept' } : { id: 'drive.button.share.excludeLowerDept' })}
                            </span>
                        </a>
                    </If>
                </div>
                <If condition={isMulti}>
                    <div className="grid-cell batch-cell">
                        <div className="btn-group" data-toggle="buttons">
                            <label className={`btn btn-sm btn-secondary ${shareTarget.evtSectCd === 'SHAREADD' ? 'active' : ''}`} onClick={() => this.changeBatchChangeValue('SHAREADD')}>
                                <input type="radio" name="options" autoComplete="off" />
                                <span className="btn-text">{intl.formatMessage({ id: 'com.add' })}</span>
                            </label>
                            <label className={`btn btn-sm btn-secondary ${shareTarget.evtSectCd === 'SHAREDEL' ? 'active' : ''}`} onClick={() => this.changeBatchChangeValue('SHAREDEL')}>
                                <input type="radio" name="options" autoComplete="off" />
                                <span className="btn-text">{intl.formatMessage({ id: 'com.delete' })}</span>
                            </label>
                        </div>
                    </div>
                </If>
                <div className="grid-cell authority-cell">
                    <If condition={shareTarget.evtSectCd !== 'SHAREDEL'}>
                        <SelectBox
                            options={config.list}
                            value={shareTarget.authCd}
                            dataContainer="body"
                            dataWidth="83"
                            onChange={value => {
                                this.changeAuthCd(value);
                            }}
                            isDisabled={!!isView}
                            type="line"
                            activeStyle={{
                                color: '#5959ff',
                                fontWeight: 'bold'
                            }}
                        />
                    </If>
                </div>
                <div className="grid-cell del-cell">
                    <If condition={!isView}>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.delete' })} onClick={this.deleteShareTarget}>
                            <i className="ic-20-close-sm" />
                        </a>
                    </If>
                </div>
                <NameCard target={`shareTarget-${index}-div`} type={shareTarget.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'dept'} id={shareTarget.objtShareTgtId} />
            </div>
        );
    }
}

ShareTarget.propTypes = {
    shareTarget: PropTypes.object,
    index: PropTypes.number,
    isView: PropTypes.bool,
    style: PropTypes.object,
    changeShareTargetFn: PropTypes.func,
    deleteShareTargetFn: PropTypes.func,
    isMulti: PropTypes.bool,
    intl: PropTypes.object,
    config: PropTypes.object,
    cancelToken: PropTypes.object
};

ShareTarget.defaultProps = {
    shareTarget: {},
    index: 0,
    isView: true,
    style: {},
    changeShareTargetFn: _.noop,
    deleteShareTargetFn: _.noop,
    isMulti: false,
    intl: {},
    config: {},
    cancelToken: {}
};

export default injectIntl(ShareTarget);
