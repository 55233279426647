import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Loading = props => {
    const { loadings } = props;
    return (
        Object.keys(loadings).length > 0 && (
            <div className="loadingSpinner">
                <div className="dy-progress-page">
                    <div className="spinner" />
                </div>
            </div>
        )
    );
};

const mapStateToProps = state => ({
    loadings: state.loading.loadings
});

Loading.propTypes = {
    loadings: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Loading);
