import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class ReqDtlHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { close, title } = this.props;

        return (
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id={title} />
                </h5>
                <a className="btn-ic-nor" role="button" title="닫기" data-dismiss="modal" onClick={() => close()}>
                    <i className="ic-20-close" />
                </a>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

ReqDtlHeader.propTypes = {
    close: PropTypes.func,
    title: PropTypes.string
};

ReqDtlHeader.defaultProps = {
    close: () => {},
    title: ''
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlHeader));

export default connected;
