import 'react-dates/initialize';
import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import _ from 'lodash';
import omit from 'lodash/omit';
import { SingleDatePicker } from 'react-dates';
import { SingleDatePickerPhrases } from 'src/constants/defaultPhrases';
import isInclusivelyAfterDay from 'src/utils/isInclusivelyAfterDay';
import CommonDatePickerWrapper from './CommonDatePickerWrapper';

const SingleDatePickerShape = {
    date: momentPropTypes.momentObj,
    onDateChange: PropTypes.func,
    focused: PropTypes.bool,
    onFocusChange: PropTypes.func
};

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    initialDate: momentPropTypes.momentObj,
    ...SingleDatePickerShape,

    // input related props
    showClearDate: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    screenReaderInputMessage: PropTypes.string,
    customInputIcon: PropTypes.object,
    block: PropTypes.bool,
    regular: PropTypes.bool,
    verticalSpacing: PropTypes.object,
    keepFocusOnInput: PropTypes.bool,

    // calendar presentation and interaction related props
    renderMonthText: PropTypes.string,
    orientation: PropTypes.string,
    anchorDirection: PropTypes.string,
    horizontalMargin: PropTypes.number,
    withPortal: PropTypes.bool,
    withFullScreenPortal: PropTypes.bool,
    initialVisibleMonth: PropTypes.object,
    keepOpenOnDateSelect: PropTypes.bool,
    reopenPickerOnClearDate: PropTypes.bool,
    isRTL: PropTypes.bool,

    // navigation related props
    navPrev: PropTypes.object,
    navNext: PropTypes.object,
    onPrevMonthClick: PropTypes.func,
    onNextMonthClick: PropTypes.func,
    onClose: PropTypes.func,

    // day presentation and interaction related props
    renderCalendarDay: PropTypes.object,
    renderDayContents: PropTypes.object,
    enableOutsideDays: PropTypes.bool,
    isDayBlocked: PropTypes.func,
    isOutsideRange: PropTypes.func,
    isDayHighlighted: PropTypes.func,

    // internationalization props
    monthFormat: PropTypes.string,
    phrases: PropTypes.object,

    // customized
    displayFormat: PropTypes.string,
    showDefaultInputIcon: PropTypes.bool,
    inputIconPosition: PropTypes.string,
    small: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    hideKeyboardShortcutsPanel: PropTypes.bool,
    onDateChange: PropTypes.func,
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj
};

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    initialDate: null,
    showClearDate: false,

    // input related props
    id: 'date',
    placeholder: 'Date',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    regular: false,
    verticalSpacing: undefined,
    keepFocusOnInput: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: 'horizontal',
    anchorDirection: 'left',
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDate: false,
    isRTL: false,

    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: _.noop,
    isDayHighlighted: () => {},

    // internationalization props
    monthFormat: 'YYYY.MM',
    phrases: SingleDatePickerPhrases,

    // customized
    displayFormat: 'YYYY-MM-DD',
    showDefaultInputIcon: true,
    transitionDuration: 0,
    inputIconPosition: 'after',
    small: true,
    numberOfMonths: 1,
    hideKeyboardShortcutsPanel: true,
    customInputIcon: <i className="ic-20-calendar" />,
    block: false,
    onDateChange: _.noop,
    minDate: moment(),
    maxDate: null
};

class SingleDatePickerWrapper extends CommonDatePickerWrapper {
    constructor(props) {
        super(props);

        const date = !!props.initialDate && moment.isMoment(props.initialDate) ? props.initialDate : moment();
        this.state = {
            focused: props.autoFocus,
            date: props.initialDate,
            selYear: Number(date.format('YYYY')),
            selMonth: Number(date.format('MM'))
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { initialDate } = props;
        const { date, focused } = state;

        if (initialDate !== date) {
            return {
                focused,
                date: initialDate,
                selYear: Number(initialDate.format('YYYY')),
                selMonth: Number(initialDate.format('MM'))
            };
        }

        return null;
    }

    onDateChange = date => {
        const { onDateChange } = this.props;
        if (date != null) {
            this.setState({ date });
            onDateChange(date);
        }
    };

    onFocusChange = ({ focused }) => {
        this.setState({ focused });
    };

    onClose = ({ date }) => {
        this.setState({ selYear: date.year(), selMonth: date.month() + 1 });
    };

    getOutsideRange = day => {
        const { isOutsideRange, minDate, maxDate } = this.props;
        if (!_.isEmpty(isOutsideRange)) {
            return isOutsideRange;
        }

        if (!_.isEmpty(minDate) && !_.isEmpty(maxDate)) {
            const compareMinDate = moment.min(day, minDate).format('YYYY-MM-DD');
            const compareMaxDate = moment.max(day, maxDate).format('YYYY-MM-DD');

            const formattedMinDate = moment(minDate).format('YYYY-MM-DD');
            const formattedMaxDate = moment(maxDate).format('YYYY-MM-DD');

            return !(formattedMinDate === compareMinDate && formattedMaxDate === compareMaxDate);
        }

        if (_.isEmpty(minDate) && !_.isEmpty(maxDate)) {
            return isInclusivelyAfterDay(day, maxDate);
        }

        if (!_.isEmpty(minDate) && _.isEmpty(maxDate)) {
            return !isInclusivelyAfterDay(day, minDate);
        }

        return !isInclusivelyAfterDay(day, moment());
    };

    renderPrevArrow = (selYear, selMonth) => {
        const { minDate } = this.props;

        const selYearMonth = moment()
            .year(selYear)
            .month(selMonth)
            .add(-1, 'month');

        if (selYearMonth.isSameOrBefore(minDate === null ? moment() : minDate, 'month')) return <></>;

        return (
            <div
                role="button"
                tabIndex="0"
                className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
                aria-label="Move backward to switch to the previous month."
                onClick={() => this.minusCurDate(selYear, selMonth)}>
                <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
                    <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z" />
                </svg>
            </div>
        );
    };

    renderNextArrow = (selYear, selMonth) => {
        const { maxDate } = this.props;

        const selYearMonth = moment()
            .year(selYear)
            .month(selMonth)
            .add(-1, 'month');

        if (selYearMonth.isSameOrAfter(maxDate === null ? moment().add(10, 'years') : maxDate, 'month')) return <></>;

        return (
            <div
                role="button"
                tabIndex="0"
                className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
                aria-label="Move forward to switch to the next month."
                onClick={() => this.plusCurDate(selYear, selMonth)}>
                <svg className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1" focusable="false" viewBox="0 0 1000 1000">
                    <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
                </svg>
            </div>
        );
    };

    render() {
        const { focused, date, selYear, selMonth } = this.state;
        const { minDate, maxDate } = this.props;
        const props = omit(this.props, ['autoFocus', 'initialDate', 'onDateChange', 'minDate', 'maxDate']);

        return (
            <SingleDatePicker
                {...props}
                id="date_input"
                date={date}
                focused={focused}
                onDateChange={this.onDateChange}
                onFocusChange={this.onFocusChange}
                onClose={this.onClose}
                displayFormat="YYYY-MM-DD"
                isOutsideRange={this.getOutsideRange}
                renderMonthElement={this.renderMonthElement}
                minDate={minDate}
                maxDate={maxDate}
                navPrev={this.renderPrevArrow(selYear, selMonth)}
                navNext={this.renderNextArrow(selYear, selMonth)}
            />
        );
    }
}

SingleDatePickerWrapper.propTypes = propTypes;
SingleDatePickerWrapper.defaultProps = defaultProps;

export default SingleDatePickerWrapper;
