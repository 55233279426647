import $http from 'modules/Http';

const prefix = '/common/v1/notification';

class NotificationRest {
    static getNotifications(from, offset, searchParam) {
        const params = { from, offset, ...searchParam };
        return $http.get(prefix, { params });
    }

    static updateNotificationsAsRead(notificationIds) {
        return $http.put(prefix, notificationIds);
    }

    static updateAllNotificationsAsRead(updateClickedTimestamp) {
        const params = updateClickedTimestamp ? { allByClick: true } : {};
        return $http.put(`${prefix}/all`, null, { params });
    }

    static getUnreadCount() {
        return $http.get(`${prefix}/unreadCount`);
    }
}
export default NotificationRest;
