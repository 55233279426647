import { createActions } from 'redux-actions';

// @see https://redux-actions.js.org/api/createaction#createactions
// eslint-disable-next-line import/prefer-default-export
export const summaryActions = createActions(
    {
        global: {
            changeLLMList: undefined, // given undefined, the identity function will be used
            selectLLM: undefined
        },
        feedbackDialog: {
            open: undefined,
            close: undefined
        },
        fileListBox: {
            open: undefined,
            close: undefined,
            retrieveRecentFiles: undefined,
            searchByFileName: undefined
        },
        fileReSelectConfirmDialog: {
            open: undefined,
            close: undefined
        },
        guideModal: {
            open: undefined,
            close: undefined
        },
        limitConfirmDialog: {
            open: undefined,
            close: undefined
        },
        llmSelectDialog: {
            open: undefined,
            close: undefined
        },
        notificationDialog: {
            open: undefined,
            close: undefined
        },
        optionSelectDialog: {
            open: undefined,
            close: undefined,
            changeOption: undefined
        },
        promptInput: {
            insertPromptTemplate: undefined,
            putDataToPromptTemplate: undefined,
            clear: undefined
        },
        sidePanel: {
            open: undefined,
            close: undefined
        },
        stopForNewDialog: {
            open: undefined,
            close: undefined
        },
        thread: {
            load: undefined,
            save: undefined,
            clear: undefined,
            generateUserMessage: undefined,
            generateRecommendedPrompt: undefined,
            selectFeedback: undefined,
            generateAIMessage: undefined, // (N/A) -> pending
            regenerateAIMessage: undefined, // complete, error, stop -> pending
            stopFetchAIMessage: undefined, // pending -> stopFetch
            prepareToStopTypingAIMessage: undefined, // fetchComplete -> prepareToStopTyping
            stopTypingAIMessage: undefined, // prepareToStopTyping -> stopTyping
            showErrorAIMessage: undefined, // pending -> error
            completeToFetchAIMessage: undefined, // pending -> fetchComplete
            completeToTypeAIMessage: undefined // fetchComplete -> typingComplete
        },
        threadClearConfirmDialog: {
            open: undefined,
            close: undefined
        },
        tooltip: {
            open: undefined,
            close: undefined
        },
        toast: {
            open: undefined,
            close: undefined
        },
        wrongKeywordDialog: {
            open: undefined,
            close: undefined
        }
    },
    { prefix: 'summary' }
);
