import { delay } from 'redux-saga';
import { takeLatest, put, all } from 'redux-saga/effects';
import { toast as Actions } from '../actions';

function* showToast({ duration = 2000 }) {
    yield delay(duration);
    yield put({ type: Actions.HIDE });
}

function* hideToast() {
    yield delay(200);
    yield put({ type: Actions.CLEAR });
}

function* watchShowToast() {
    yield takeLatest(Actions.SHOW, showToast);
}

function* watchHideToast() {
    yield takeLatest(Actions.HIDE, hideToast);
}

export default function* rootSaga() {
    yield all([watchShowToast(), watchHideToast()]);
}
