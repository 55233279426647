import React from 'react';
import _ from 'lodash';
import Request from '../../../constants/Request';
import FileLifeCycleRest from '../../../apis/FileLifeCycleRest';
import FolderRest from '../../../apis/FolderRest';
import { toast, largePopup, alert as modalAlert } from '../../../utils/ModalService';
import { globalPopup } from '../../../redux/actions';
import ApprovalPopup from './Approval/ApprovalPopup';
import RequestComplianceExceptionPopup from './RequestException/RequestComplianceExceptionPopup';
import ReqComplianceExceptionPop from '../../presentationals/ReqMng/Popup/ReqComplianceExceptionPop';
import ReqExtendExpiredDtPop from '../../presentationals/ReqMng/Popup/ReqExtendExpiredDtPop';
import ReqSecureModePopup from '../../presentationals/ReqMng/Popup/ReqSecureModePopup';
import ReqWorkGroupFolderPop from './WorkgroupFolderRequest/ManagementPopup';
import CreateRequestPopup from './WorkgroupFolderRequest/RequestCreatePopup';
import ReqWorkgroupChangeDatePopup from './WorkgroupFolderRequest/RequestChangeDatePopup';
import RequestExceptionPopup from './RequestException/RequestExceptionPopup';
import ReqRest from '../../../apis/ReqRest';
import SecureModeRequestRest from '../../../apis/SecureModeRequestRest';
import UserRequestRest from '../../../apis/UserRequestRest';
import ReqPartnerResistrationPop from '../../presentationals/ReqMng/Popup/ReqPartnerResistrationPop';
import ReqPartnerExpireExtendPop from '../../presentationals/ReqMng/Popup/ReqPartnerExpireExtendPop';
import HelpRest from '../../../apis/HelpRest';

const getSystemTypeFunc = type => {
    switch (type) {
        case Request.TYPE.USE_SECURE_FOLDER:
            return SecureModeRequestRest.getRequestSystemType('SECUREMODE');
        case Request.TYPE.EXCEPT_NON_COMPLIANCE:
            return FileLifeCycleRest.getRequestSystemType('COMPLIANCE');
        case Request.TYPE.EXTENDS_EXPIRATION:
            return FileLifeCycleRest.getRequestSystemType('EXPIRATION');
        case Request.TYPE.WORKGROUP_RETENTION_DATE:
            return FolderRest.getRequestSystemType('WORKGROUP_RETENTION_DATE');
        case Request.TYPE.EXCEPT_PC_CONTROL:
            return UserRequestRest.getRequestSystemType('PCCONTROL');
        default:
            return FolderRest.getRequestSystemType('CREATE_WORKGROUP');
    }
};

async function getSystemType(type) {
    const response = await getSystemTypeFunc(type);
    const { resultCode, data, message } = response;

    if (resultCode === 200 && !_.isNil(data) && data !== '') {
        return data;
    }

    if (resultCode === 500 && message === 'IOFFICE_INTERNAL_SERVER_EXCEPTION') {
        modalAlert('com.alert.internalServerError');
    } else {
        toast(message);
    }
    return null;
}

async function getReq(reqId) {
    const response = await ReqRest.getReq(reqId);
    const { resultCode, data, message } = response;

    if (resultCode === 200 && !_.isNil(data) && data !== '') {
        return data;
    }

    if (resultCode === 500 && message === 'IOFFICE_INTERNAL_SERVER_EXCEPTION') {
        modalAlert('com.alert.internalServerError');
    } else {
        toast(message);
    }
    return null;
}

async function isSelfApprovalByType(type) {
    if (type !== Request.TYPE.CREATE_WORKGROUP) return false;

    const response = await FolderRest.getCfgWorkgroupCreateApproveUseYn();
    const { resultCode, data } = response;

    if (resultCode === 200 && !_.isNil(data) && !_.isEmpty(data)) {
        return data.cfgVal === 'N';
    }
    return false;
}

const getApprovalPopup = (config = {}) => {
    const { type, mode, reqId, req, target, reload, isSelfApproval = false, phoneNum } = config;

    const approval = <ApprovalPopup type={type} mode={mode} target={target} reqId={reqId} req={req} />;

    if (mode === Request.MODE.VIEW) {
        return approval;
    }
    if (type === Request.TYPE.CREATE_WORKGROUP) {
        return <CreateRequestPopup reload={reload} isSelfApproval={isSelfApproval} approval={config} phoneNum={phoneNum} />;
    }
    if (type === Request.TYPE.CREATE_OUT_LINK) {
        return <></>;
    }
    return approval;
};

const getRequestPopup = (config = {}) => {
    const { type, mode, reqId, req, target, reload, isSelfApproval = false, phoneNum } = config;

    switch (type) {
        case Request.TYPE.USE_SECURE_FOLDER:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam } = config;
                return <ReqSecureModePopup reqId={reqId} reqInfo={req} mode={reqMngListParam.reqType} reload={reload} />;
            }
            return <></>;
        case Request.TYPE.EXCEPT_NON_COMPLIANCE:
            if (mode === Request.MODE.VIEW) {
                const { setReqMngList, setReqMngListSelected, setReqMngListParam } = config;
                return <ReqComplianceExceptionPop reqId={reqId} reqInfo={req} setReqMngList={setReqMngList} setReqMngListSelected={setReqMngListSelected} setReqMngListParam={setReqMngListParam} />;
            }
            return <RequestComplianceExceptionPopup files={target} headerName="drive.title.exceptComlianceFile" />;
        case Request.TYPE.EXTENDS_EXPIRATION:
            if (mode === Request.MODE.VIEW) {
                const { setReqMngList, setReqMngListSelected, setReqMngListParam } = config;
                return <ReqExtendExpiredDtPop reqId={reqId} reqInfo={req} setReqMngList={setReqMngList} setReqMngListSelected={setReqMngListSelected} setReqMngListParam={setReqMngListParam} />;
            }
            return <RequestExceptionPopup files={target} headerName="drive.title.requestExtension" />;
        case Request.TYPE.CREATE_WORKGROUP:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, index } = config;
                return <ReqWorkGroupFolderPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} isSelfApproval={isSelfApproval} />;
            }
            return <CreateRequestPopup reload={reload} isSelfApproval={isSelfApproval} phoneNum={phoneNum} />;
        case Request.TYPE.WORKGROUP_RETENTION_DATE:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, index } = config;
                return <ReqWorkGroupFolderPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} />;
            }
            return <ReqWorkgroupChangeDatePopup workgroupFolder={target} />;
        case Request.TYPE.PARTNER_ACCOUNT_RESISTRATION:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, index } = config;
                return <ReqPartnerResistrationPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} />;
            }
            return <ReqPartnerResistrationPop reqId={reqId} reqInfo={req} reload={reload} />;
        case Request.TYPE.PARTNER_EXPIRE_EXTENTION:
            if (mode === Request.MODE.VIEW) {
                const { reqMngListParam, index } = config;
                return <ReqPartnerExpireExtendPop from={reqMngListParam.reqType.toUpperCase()} reqId={reqId} reqInfo={req} index={index} reload={reload} />;
            }
            return <ReqPartnerExpireExtendPop reqId={reqId} reqInfo={req} reload={reload} />;
        default:
            return <></>;
    }
};

function fail() {
    return new Promise(() => false);
}

const open = async (config = {}) => {
    const { type = Request.TYPE.CREATE_WORKGROUP, mode = Request.MODE.REQUEST, reqId = '' } = config;
    const isSelfApproval = await isSelfApprovalByType(type);
    const helpBasicInfo = await HelpRest.getHelpBasicInfo();
    const phoneNum = helpBasicInfo.EFL_COM_HELPCENTER_TEL;

    if (mode === Request.MODE.VIEW) {
        // 보기면 요청정보 미리 조회해서 넘기기
        if (reqId === '') return toast('com.alert.reqInvalidParam');
        const req = await getReq(reqId);
        if (_.isNil(req)) return fail();

        // 기존에 요청된 정보를 바탕으로 시스템 타입 추정.
        const { req: reqInfo } = req;
        const { sancIfVal = '' } = reqInfo;
        const systemType = _.isNil(sancIfVal) || sancIfVal === '' ? Request.SYSTEM_TYPE.KNOX_DRIVE : Request.SYSTEM_TYPE.KNOX_APPROVAL;
        const reqConfig = _.extend({ req }, config, { isSelfApproval }, { phoneNum });

        if (systemType === Request.SYSTEM_TYPE.KNOX_APPROVAL) {
            return largePopup(getApprovalPopup(reqConfig));
        }
        return largePopup(getRequestPopup(reqConfig));
    }
    if (mode === Request.MODE.REQUEST) {
        // 신규 요청이면 시스템 타입 조회 후 처리
        const systemType = await getSystemType(type);
        if (_.isNil(systemType)) return fail();

        const reqConfig = _.extend({}, config, { isSelfApproval }, { phoneNum });

        if (systemType === Request.SYSTEM_TYPE.KNOX_APPROVAL) {
            return largePopup(getApprovalPopup(reqConfig));
        }
        if (type === Request.TYPE.USE_SECURE_FOLDER) {
            return globalPopup.openSecureModeRequestPopup();
        }
        return largePopup(getRequestPopup(reqConfig));
    }

    return fail();
};

// const component = async (config = {}) => {
//     open(config).then(() => {}, () => {});
//     return <></>;
// };
// const RequestPopup = () => ({ open });
export default { open };
