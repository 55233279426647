import React from 'react';
import { UncontrolledPopover } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const CardPopoverWrapper = props => {
    const { target, placement, card: Card } = props;
    const popoverRef = React.createRef();
    const close = () => {
        if (!!popoverRef && !!popoverRef.current) {
            popoverRef.current.toggle();
        }
    };

    const omitted = _.omit(props, ['placement', 'card']);
    return (
        <UncontrolledPopover
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            ref={popoverRef}
            trigger="legacy"
            placement={placement}
            target={target}
            hideArrow={true}
            fade={true}
            className="name-card-container">
            <Card {...omitted} close={close} />
        </UncontrolledPopover>
    );
};

CardPopoverWrapper.propTypes = {
    id: PropTypes.string,
    target: PropTypes.string,
    placement: PropTypes.string,
    card: PropTypes.any.isRequired
};

CardPopoverWrapper.defaultProps = {
    id: undefined,
    target: 'popoverLegacy',
    placement: 'auto'
};

export default CardPopoverWrapper;
