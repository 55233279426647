const COACHMARK_HOME = 'homeCoachMark_';
const COACHMARK_UPLOAD = 'uploadCoachMark_';
const COACHMARK_SHARE = 'shareCoachMark_';
const COACHMARK_LIST = 'listCoachMark_';
const COACHMARK_SEARCH = 'searchCoachMark_';
const COACHMARK_OUTLINK = 'outLinkCoachMark_';

export default {
    COACHMARK_HOME,
    COACHMARK_UPLOAD,
    COACHMARK_SHARE,
    COACHMARK_LIST,
    COACHMARK_SEARCH,
    COACHMARK_OUTLINK
};
