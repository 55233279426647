import React from 'react';
import { If } from 'jsx-control-statements';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltips from '../../Tooltips';

const InShareCount = ({ isMulti, shareTargets, intl, canRemove, removeOwnAuthFn }) => {
    // console.log('canRemove ', canRemove);
    const isHasOwner = shareTargets.some(share => share.authCd === 'U80OWN');

    return (
        <dl className="dt-divider">
            <If condition={!isMulti}>
                <dt>{intl.formatMessage({ id: 'drive.text.share.shareMember' })}</dt>
            </If>
            <If condition={isMulti}>
                <dt>
                    {`${intl.formatMessage({ id: 'drive.text.share.shareMemberBatchChange' })} `}
                    <span id="batchChangeTooltip" className="btn-ic-nor btn-sm">
                        <i className="ic-16-help" />
                    </span>
                    <Tooltips targetId="batchChangeTooltip" placement="top">
                        <div className="guide">{intl.formatMessage({ id: 'drive.guideText.share.shareMemberBatchChangeDescription' })}</div>
                    </Tooltips>
                </dt>
            </If>
            <dd>
                <div className="total-count">
                    <span>{intl.formatMessage({ id: 'drive.text.share.totalCount' })}</span>
                    <span className="count">
                        <i className="num">{shareTargets.length - (isHasOwner ? 1 : 0)}</i>
                    </span>
                </div>
            </dd>
            <If condition={canRemove}>
                <dd className="dd-rt">
                    <a className="btn btn-secondary" role="button">
                        <span className="btn-text" onClick={removeOwnAuthFn} role="button">
                            {intl.formatMessage({ id: 'drive.button.removeOwnAuth' })}
                        </span>
                    </a>
                </dd>
            </If>
        </dl>
    );
};

InShareCount.propTypes = {
    shareTargets: PropTypes.array,
    isMulti: PropTypes.bool,
    intl: PropTypes.object,
    canRemove: PropTypes.bool,
    removeOwnAuthFn: PropTypes.func
};

InShareCount.defaultProps = {
    shareTargets: [],
    isMulti: false,
    intl: {},
    canRemove: false,
    removeOwnAuthFn: _.noop
};

export default injectIntl(InShareCount);
