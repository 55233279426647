import _ from 'lodash';
import FoldersRest from '../apis/FoldersRest';

const PREFERRED_POSTFIX = '_preferred';

// 캐쉬를 정리하는 주기
const cleanIntervalSeconds = 5;
// 정리대상 item 기준 (아래보다 오래된것이면 정리)
const cleanStandardSeconds = 10;

const cachedData = {};
let intervalFunction = null;

function cleaner() {
    const keys = Object.keys(cachedData);
    keys.forEach(key => {
        const cached = cachedData[key];
        const old = (new Date().getTime() - cached.cachedTime) / 1000;
        if (old > cleanStandardSeconds) {
            delete cachedData[key];
        }
    });

    if (!keys.length) {
        clearInterval(intervalFunction);
        intervalFunction = null;
    }
}

function add(key, file = {}, created = false, deleted = false, preferred = false, requested = false) {
    if (key == null) return;
    const info = {};
    info.file = Object.assign({}, file);
    if (!preferred && !requested && file && file.drive && file.drive.lastModDt) {
        info.cachedTime = new Date(file.drive.lastModDt).getTime();
    } else if (!requested && cachedData[key] && cachedData[key].cachedTime) {
        info.cachedTime = cachedData[key].cachedTime + 1;
    } else {
        info.cachedTime = Date.now();
    }
    info.created = created;
    info.deleted = deleted;
    info.requested = requested;
    cachedData[key] = info;
    if (!intervalFunction) {
        intervalFunction = setInterval(cleaner, cleanIntervalSeconds * 1000);
    }
}

class FileCache {
    constructor(postFilter = file => file, withDeleted = false, withConfig = false, reqType = '') {
        this.postEachFilter = postFilter;
        this.postFilter = (files = []) => files.map(file => this.postEachFilter(file)).filter(file => file);
        this.withDeleted = withDeleted;
        this.withConfig = withConfig;
        this.reqType = reqType;
    }

    /**
     * files에 대한 캐싱 처리 결과 리턴
     */
    /* 신규파일에 대한 캐싱이 필요할 때 적용. 아래로직은 테스트된 로직은 아님
convert(files = [], created = true, sort = {}) {
    let returnFiles = files.filter(f => f.drive);
    // let total = 0;
    if (created) {
        // 캐쉬된 파일이 있는 경우, 정렬에 맞게 넣어준다.
        const cachedItems = Object.keys(cachedData)
            .map(k => cachedData[k])
            .filter(c => c && c.created && c.file && c.file.drive)
            .map(c => c.file);
        if (cachedItems.length) {
            const { order = 'DESC', column } = sort;
            if (column) {
                const { name } = column;
                const colArr = [{ name: 'objtSectCd', order: 'DESC' }, { name, order }, { name: 'objtId', order: 'ASC' }];

                // a가 크면, 오름차순
                const sortFunction = (a, b) => {
                    for (let idx = 0; idx < colArr.length; idx += 1) {
                        const { name, order, nullIsMax } = colArr[idx];
                        const aValue = a.drive[name];
                        const bValue = b.drive[name];
                        if ((!aValue && !bValue) || aValue === bValue) continue;
                        if (aValue && bValue) {
                            if (order === 'DESC' && aValue < bValue) return true;
                            else if (order === 'ASC' && aValue > bValue) return true;
                        } else {
                            if (order === 'DESC') {
                                if (nullIsMax && !bValue) return true;
                                else if (!nullIsMax && !aValue) return true;
                            }
                            else if (order === 'ASC') {
                                if (nullIsMax && !aValue) return true;
                                else if (!nullIsMax && !bValue) return true;
                            }
                        }
                        return false;
                    }
                    return false;
                };
                cachedItems.sort((a, b) => sortFunction(a, b) ? 1 : -1);
                let createdIdx = 0;
                const dataWithCreated = [];
                returnFiles.forEach(item => {
                    while (cachedItems[createdIdx] && sortFunction(item, cachedItems[createdIdx])) {
                        if (item.drive.objtId === cachedItems[createdIdx].drive.objtId) createdIdx += 1;
                        else {
                            const convertedItem = this.postEachFilter(cachedItems[createdIdx]);
                            if (convertedItem) {
                                // total++;
                                dataWithCreated.push(convertedItem);
                            }
                            createdIdx += 1;
                        }
                    }
                    dataWithCreated.push(item);
                });
                while (cachedItems[createdIdx]) {
                    const convertedItem = this.postEachFilter(cachedItems[createdIdx]);
                    if (convertedItem) {
                        // total++;
                        dataWithCreated.push(convertedItem);
                    }
                    createdIdx += 1;
                }
                returnFiles = dataWithCreated;
            }
        }
    }
    return returnFiles.map(f => this.filter(f)).filter(f => f && f.drive);
}
*/

    /**
     * 즐겨찾기 cache 데이터 입히기.
     * 조회된 데이터 중. 이미 제거된 데이터의 경우 삭제,
     * 캐싱된 데이터 중. 조회되지 않은 데이터의 경우 추가
     */
    convertPreferred(files = []) {
        // 캐싱된 즐겨찾기 목록 조회
        const cachedItems = Object.keys(cachedData)
            .filter(c => c.indexOf(PREFERRED_POSTFIX) >= 0)
            .filter(c => cachedData[c].created)
            // 즐겨찾기 캐싱데이터를 사용하되, 그 사이 db데이터로 캐싱된 데이터가 있는 경우. merge한다
            .map(c => this.filter(cachedData[c].file, true))
            .filter(d => d && d.personalization && d.personalization.preferred && d.extra);

        const searchItems = files.map(f => this.filter(f, true)).filter(d => d && d.personalization && d.personalization.preferred);
        if (cachedItems.length) {
            const sortFunction = (a, b) => {
                if (!a.personalization) return -1;
                if (!b.personalization) return 1;
                return a.personalization.preferred > b.personalization.preferred ? -1 : 1;
            };
            const cachedItemsMap = {};
            cachedItems.forEach(c => {
                cachedItemsMap[c.drive.objtId] = c;
            });
            return cachedItems.concat(searchItems.filter(item => !cachedItemsMap[item.drive.objtId])).sort(sortFunction);
        }
        return searchItems;
    }

    filter(file, withDeleted = this.withDeleted) {
        if (file && file.drive) {
            const key = file.drive.objtId;
            if (cachedData[key] && !cachedData[key].created) {
                const cached = cachedData[key];
                if (cached.requested) {
                    console.log('cached(reloaded)', cached);
                    console.log('time diff', 'cachedTime', cached.cachedTime, 'drive lastModDt', new Date(file.drive.lastModDt).getTime());
                }
                if (cached.cachedTime < new Date(file.drive.lastModDt).getTime()) {
                    delete cachedData[key];
                    return this.filterPreferred(file);
                }
                if (cached.deleted && !withDeleted) {
                    return null;
                }
                const filteredFile = Object.assign({}, cached.file);
                if (cached.requested) {
                    console.log('old file', Object.assign({}, file));
                    if (file.origin) {
                        console.log('old origin', Object.assign({}, file.origin));
                    }
                    console.log('cached before assign', Object.assign({}, filteredFile));
                }
                if (file.origin) {
                    filteredFile.origin = file.origin;
                    filteredFile.extra = Object.assign({}, file.origin.extra, _.omit(filteredFile.extra, ['sortValues']));
                    filteredFile.personalization = filteredFile.personalization || {};
                    Object.assign(filteredFile.personalization, _.omit(file.origin.personalization, ['preferred']));
                } else {
                    filteredFile.origin = file;
                    filteredFile.extra = Object.assign({}, file.extra, _.omit(filteredFile.extra, ['sortValues']));
                    filteredFile.personalization = filteredFile.personalization || {};
                    Object.assign(filteredFile.personalization, _.omit(file.personalization, ['preferred']));
                }
                if (cached.requested) {
                    console.log('cached after assign', Object.assign({}, filteredFile));
                }
                return this.filterPreferred(this.postEachFilter(filteredFile));
            }
        }
        return this.filterPreferred(file);
    }

    filterPreferred(f) {
        const file = f;
        if (file && file.drive) {
            const key = `${file.drive.objtId}${PREFERRED_POSTFIX}`;
            const cached = cachedData[key];
            if (cached && cached.file.personalization) {
                file.personalization = file.personalization || {};
                file.personalization.preferred = cached.file.personalization.preferred;
            }
        }
        return file;
    }

    /**
     * 신규 파일로 캐싱
     */
    created(files = []) {
        if (files.length) {
            return FoldersRest.getPair(files).then(selectedFiles => {
                selectedFiles
                    .filter(f => f && f.drive)
                    .forEach(f => {
                        add(f.drive.objtId, f, true);
                    });
            });
        }
        return Promise.resolve();
    }

    /**
     * 수정 파일로 캐싱. 조회결과 데이터 없으면, 삭제 or 권한없는 파일로 처리
     */
    updated(files = []) {
        if (files.length) {
            return FoldersRest.getPair(files, true, this.withConfig, this.reqType).then((selectedFiles = []) => {
                const selectedFilesMap = {};
                selectedFiles
                    .filter(f => f && f.drive)
                    .forEach(f => {
                        selectedFilesMap[f.drive.objtId] = f;
                    });

                files
                    .filter(f => f && f.drive)
                    .forEach(f => {
                        const selectedFile = selectedFilesMap[f.drive.objtId];
                        if (selectedFile && selectedFile.drive) {
                            const deleted = selectedFile.drive.objtDelCd !== 'NORMAL' || selectedFile.maxAuthCd === 'NONE';
                            add(f.drive.objtId, selectedFile, false, deleted, false, this.reqType !== '');
                        }
                    });
            });
        }
        return Promise.resolve();
    }

    updatedPreferred(files = [], created = false) {
        if (files.length) {
            files.forEach(f => {
                add(`${f.drive.objtId}${PREFERRED_POSTFIX}`, f, created, false, true);
            });
        }
    }
}

export default FileCache;
