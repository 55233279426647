import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import ReqRest from 'apis/ReqRest';
import { alert, confirm, largePopup, toast } from 'utils/ModalService';
import CloseButton from 'presentationals/Button/CloseButton';
import ReqRejectPop from 'presentationals/ReqMng/Popup/ReqRejectPop';
import moment from 'moment';

const FROM_SEND = 'SEND';
const FROM_RECEIVED = 'RECEIVED';
const REQUEST_CANCEL = 'CANCEL';
const REQUEST_REJECT = 'REJECT';
const REQUEST_APPROVAL = 'APPROVAL';
const REQUEST_PROCESSING = 'PROCESSING';

const ManagementPopup = ({ from, reqId, reqInfo, index, close, reload, intl, isSelfApproval }) => {
    const [approverName, setApproverName] = useState('');
    const [approverPosition, setApproverPosition] = useState('');
    const [requesterName, setRequesterName] = useState('');
    const [requesterPosition, setRequesterPosition] = useState('');
    const [workgroupFolderName, setWorkgroupFolderName] = useState('');
    const [useSize, setUseSize] = useState('0');
    const [expiredDt, setExpiredDt] = useState('');
    const [reason, setReason] = useState('0');
    const [requestStatus, setRequestStatus] = useState('');
    const [reqType, setReqType] = useState('');

    const getReq = async () => {
        if (_.isNil(reqInfo) || _.isEmpty(reqInfo)) {
            const response = await ReqRest.getReq(reqId);
            const { resultCode, data } = response;
            if (resultCode === 200 && !_.isEmpty(data)) {
                return data;
            }
            return {};
        }
        return reqInfo;
    };

    const getRequest = async () => {
        const reqData = await getReq();
        if (!_.isEmpty(reqData)) {
            const { req, reqDtl, reqTgt } = reqData;
            const { allocUseSize, retentionDt } = reqDtl;
            const { reqLstId, reqUserNm, reqUserDeptNm, reqUserCopNm, reqTgtUserNm, reqTgtUserCopNm, reqTgtUserDeptNm, reqRsn, reqStatCd } = req;
            const workgroup = _.get(reqTgt, '0', {});
            setUseSize(allocUseSize);
            setExpiredDt(moment(retentionDt).format('YYYY-MM-DD'));
            setReqType(reqLstId);
            setRequesterName(reqUserNm);
            setRequesterPosition(!_.isEmpty(reqUserCopNm) ? `${reqUserCopNm} / ${reqUserDeptNm}` : reqUserDeptNm);
            setApproverName(reqTgtUserNm);
            setApproverPosition(!_.isEmpty(reqTgtUserCopNm) ? `${reqTgtUserCopNm} / ${reqTgtUserDeptNm}` : reqTgtUserDeptNm);
            setWorkgroupFolderName(_.get(workgroup, 'objtNm', ''));
            setReason(reqRsn);
            setRequestStatus(reqStatCd);
        }
    };

    const updateRequest = async reqPrcsType => ReqRest.updateReqProcess({ reqPrcsType, reqPrcsList: [reqId] });

    useEffect(() => {
        getRequest();
    }, [getRequest]);

    const revertRequest = async () => {
        const reqPrcsType = from === FROM_SEND ? REQUEST_CANCEL : REQUEST_REJECT;
        if (from === FROM_RECEIVED) {
            largePopup(<ReqRejectPop index={index} rejectType="SINGLE" />);
            reload();
            close();
        } else {
            const doRequest = await confirm({ id: 'com.confirm.ReqMngBodyReqStat.cancel' });
            if (doRequest) {
                const response = await updateRequest(reqPrcsType);
                const { resultCode } = response;
                if (resultCode === 200) {
                    toast({ id: 'com.alert.cancelReq' });
                    reload();
                    close();
                } else if (resultCode === 403) {
                    alert({ id: 'com.alert.alreadyApprovedReq.single' });
                    reload();
                    close();
                } else {
                    toast({ id: 'com.alert.failed' });
                }
            }
        }
    };

    const sendRequest = async () => {
        if (from === FROM_RECEIVED) {
            const response = await ReqRest.updateReqProcess({ reqPrcsType: REQUEST_APPROVAL, reqPrcsList: [reqId] });
            const { resultCode, data } = response;
            if (resultCode === 403 && data) {
                alert(data);
            } else if (resultCode === 200 && data) {
                toast({ id: 'com.alert.approveReq' });
                reload();
                close();
            } else {
                alert({ id: 'com.alert.failed' });
            }
        } else {
            reload();
            close();
        }
    };

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">{reqType === 'creWG' ? <FormattedMessage id="com.title.workgroup.create.request" /> : <FormattedMessage id="drive.text.workgroup.retentionChg" />}</h5>
                <CloseButton close={close} />
            </div>
            <ModalBody>
                <div className="dt-width-120">
                    <dl className="row-list text-info">
                        <dt>{intl.formatMessage({ id: 'com.text.ReqMngBody.reqUserNm' })}</dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-user">
                                    <div className="user-profile">
                                        <div title={`${requesterName} ${requesterPosition}`}>
                                            <span className="name">{requesterName}</span>
                                            <span className="position">{requesterPosition}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    {(!isSelfApproval || reqType !== 'creWG') && (
                        <dl className="row-list text-info">
                            <dt>{intl.formatMessage({ id: 'drive.text.workgroup.approver' })}</dt>
                            <dd>
                                <div className="dd-text">
                                    <div className="item-user">
                                        <div className="user-profile">
                                            <div title={`${approverName} ${approverPosition}`}>
                                                <span className="name">{approverName}</span>
                                                <span className="position">{approverPosition}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    )}
                    <dl className="row-list text-info">
                        <dt>
                            <FormattedMessage id="com.text.workgroup.name" />
                        </dt>
                        <dd>
                            <div className="dd-text">
                                <div className="item-folder-name" title={workgroupFolderName}>
                                    {workgroupFolderName}
                                </div>
                            </div>
                        </dd>
                    </dl>
                    {reqType === 'creWG' ? (
                        !isSelfApproval && (
                            <dl className="row-list text-info">
                                <dt>
                                    <FormattedMessage id="com.text.workgroup.usage" />
                                </dt>
                                <dd>
                                    <div className="dd-text">{useSize}GB</div>
                                </dd>
                            </dl>
                        )
                    ) : (
                        <>
                            <dl className="row-list text-info">
                                <dt>
                                    <FormattedMessage id="drive.text.workgroup.chgExpDt" />
                                </dt>
                                <dd>
                                    <div className="dd-text">{expiredDt}</div>
                                </dd>
                            </dl>
                            <dl className="row-list">
                                <dt>{intl.formatMessage({ id: 'drive.text.secureFolder.reason' })}</dt>
                                <dd>
                                    <div className="form-group">
                                        <textarea className="form-control textarea-sm" readOnly={true} value={reason} />
                                    </div>
                                </dd>
                            </dl>
                        </>
                    )}
                </div>
                {reqType === 'creWG' && !isSelfApproval && (
                    <div className="dt-width-120" style={{ marginTop: '22px' }}>
                        <dl className="row-list flex-wrap">
                            <dt>{intl.formatMessage({ id: 'drive.text.secureFolder.reason' })}</dt>
                            <dd>
                                <div className="form-group">
                                    <textarea className="form-control textarea-sm" readOnly={true} value={reason} />
                                </div>
                            </dd>
                        </dl>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="modal-footer-option" />
                <div className="modal-footer-btns">
                    {from === FROM_SEND && (
                        <>
                            {requestStatus === REQUEST_PROCESSING && (
                                <a className="btn btn-lg btn-secondary" role="button" onClick={revertRequest}>
                                    <span className="btn-text">{intl.formatMessage({ id: 'drive.button.securefolder.cancel' })}</span>
                                </a>
                            )}
                            <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={sendRequest}>
                                <span className="btn-text">{intl.formatMessage({ id: 'com.check' })}</span>
                            </a>
                        </>
                    )}
                    {from === FROM_RECEIVED && requestStatus === REQUEST_PROCESSING && (
                        <>
                            <a className="btn btn-lg btn-secondary" role="button" onClick={revertRequest}>
                                <span className="btn-text">{intl.formatMessage({ id: 'com.button.reject' })}</span>
                            </a>
                            <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={sendRequest}>
                                <span className="btn-text">{intl.formatMessage({ id: 'com.button.approval' })}</span>
                            </a>
                        </>
                    )}
                </div>
            </ModalFooter>
        </>
    );
};

ManagementPopup.propTypes = {
    from: PropTypes.oneOf([FROM_SEND, FROM_RECEIVED]).isRequired,
    reqId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    reqInfo: PropTypes.object,
    isSelfApproval: PropTypes.bool.isRequired
};

ManagementPopup.defaultProps = {
    reqInfo: {}
};

export default injectIntl(ManagementPopup);
