import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Choose, If, Otherwise, When } from 'jsx-control-statements';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import clipboard from '../../../../../../../utils/Clipboard';
import SummarySuccessResult from './SummarySuccessResult';
import { AI_MESSAGE_RESULT_STATUS, COPIABLE_RESULT_STATUS_LIST, SUCCESS_RESULT_STATUS_LIST } from '../../../../Common/SummaryConstant';
import { summaryStorage } from '../../../../Common/Util/SummaryStorageUtil';
import { showSummaryToast } from '../../../../Common/Util/SummaryToastUtil';
import SummaryResultFunctionButtonGroup from './FunctionButtonGroup/SummaryResultFunctionButtonGroup';
import SummaryFailureResult from './SummaryFailureResult';

const AiResultMessage = memo(({ summaryMessage, intl }) => {
    const { summaryResults, archived } = summaryMessage;

    const [currentSummaryResultSeq, setCurrentSummaryResultSeq] = useState(summaryResults.length);

    const dispatch = useDispatch();

    const currentSummaryResult = useMemo(() => {
        return summaryResults[currentSummaryResultSeq - 1];
    }, [summaryResults, currentSummaryResultSeq]);

    const { resultStatus, files, summaryOptions, summaryText, summaryDateTime } = currentSummaryResult;
    const { llmName } = summaryOptions;

    // 저장
    useEffect(() => {
        if (archived) {
            return;
        }

        summaryStorage.thread.addMessage(summaryMessage);
    }, [currentSummaryResult]);

    const handleCopyText = () => {
        if (_.isEmpty(files)) {
            showSummaryToast(dispatch, { messageId: 'drive.text.copilot.summary.copy.fail' });
            return;
        }

        const fileMetaText = intl.formatMessage(
            { id: 'drive.text.copilot.summary.file_meta_for_copy' },
            { fileNames: files.map(({ name, extensionName }) => `"${name}.${extensionName}"`).join(', ') }
        );

        const isCopySuccess = clipboard.set(`${fileMetaText}\n\n${summaryText}`);

        showSummaryToast(dispatch, { messageId: `drive.text.copilot.summary.copy.${isCopySuccess ? 'success' : 'fail'}` });
    };

    return (
        <>
            <div className="ai-option">
                <div className="ai-option-lt">
                    <div className="ai-logo">
                        <i className="ic-20-copilot" />
                    </div>
                </div>
                <div className="ai-option-btns">
                    <If condition={COPIABLE_RESULT_STATUS_LIST.includes(resultStatus)}>
                        <a className="btn-ic-radius" role="button" title={intl.formatMessage({ id: 'drive.button.copilot.summary.copy' })} onClick={handleCopyText}>
                            <i className="ic-20-copilot-copy" />
                        </a>
                    </If>
                </div>
            </div>
            <Choose>
                <When condition={SUCCESS_RESULT_STATUS_LIST.includes(resultStatus)}>
                    <SummarySuccessResult summaryResult={currentSummaryResult} archived={archived} />
                </When>
                <Otherwise>
                    <SummaryFailureResult summaryResult={currentSummaryResult} archived={archived} />
                </Otherwise>
            </Choose>
            <div className="ai-set">
                <If condition={resultStatus === AI_MESSAGE_RESULT_STATUS.typingComplete}>
                    <div className="ai-set-modal">
                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.answer_from" values={{ llmName }} />
                    </div>
                </If>
                <SummaryResultFunctionButtonGroup
                    archived={archived}
                    files={files}
                    summaryResult={currentSummaryResult}
                    resultTotalCount={summaryResults.length}
                    resultSeq={currentSummaryResultSeq}
                    onNavigate={setCurrentSummaryResultSeq}
                />
            </div>
            <If condition={!_.isEmpty(summaryDateTime)}>
                <div className="ai-update">
                    <div className="ai-update-date">{summaryDateTime}</div>
                </div>
            </If>
        </>
    );
});

AiResultMessage.propTypes = {
    summaryMessage: PropTypes.shape({
        summaryResults: PropTypes.arrayOf(
            PropTypes.shape({
                files: PropTypes.array.isRequired,
                summaryOptions: PropTypes.object.isRequired,
                summaryText: PropTypes.string,
                summaryDateTime: PropTypes.string
            })
        ),
        archived: PropTypes.bool
    }).isRequired
};

AiResultMessage.defaultProps = {};

export default injectIntl(AiResultMessage);
