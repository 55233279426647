import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AuthService from 'utils/AuthService';
import { connect } from 'react-redux';
import AuthRest, { REDIRECT_LOGIN_PAGE } from '../../../apis/AuthRest';
import cookie from '../../../utils/Cookie';

function convertBrowserLangCodeToOurLangCode() {
    const userLangCd = cookie.getCookieText('langCd');
    if (userLangCd) {
        return userLangCd;
    }

    const browserUserlang = window.navigator.userLanguage || window.navigator.language;
    let browserLangCd = '002'; // 기본값 영어
    if (browserUserlang.indexOf('ko') > -1) {
        // 한국어
        browserLangCd = '001';
    } else if (browserUserlang.indexOf('de') > -1) {
        // 독일어
        browserLangCd = '003';
    } else if (browserUserlang.indexOf('zh') > -1) {
        // 중국어
        browserLangCd = '004';
    }
    return browserLangCd;
}

const RedirectToLoginPage = props => {
    const redirectForm = useRef(null);
    const redirectUrl = window.location.origin;
    // const redirectUrl = window.location.href;

    const { dontNeedSSO, isLogout } = props;
    const { loginInfo } = props;
    const { commAutAddr, ifSsoMethod, commLogOutAddr, tuid = '' } = loginInfo;

    useEffect(() => {
        async function fetchLogout() {
            const sessionRemoveArr = [AuthRest.deleteHttpSession('auth'), AuthRest.deleteHttpSession('common'), AuthRest.deleteHttpSession('drive')];
            try {
                const results = await Promise.all(sessionRemoveArr);

                results.forEach(result => {
                    const { message, resultCode } = result;

                    if (resultCode !== 200) {
                        alert(`Logout exception occur ${resultCode} / ${message}`);
                    }
                });
            } catch (e) {
                console.log(e);
                alert(e);
            }

            if (ifSsoMethod === 'SESSION_FILTER' && !_.isEmpty(commAutAddr)) {
                const tokenHttp = new XMLHttpRequest();

                tokenHttp.open('GET', commLogOutAddr);
                tokenHttp.withCredentials = true;
                tokenHttp.send();

                tokenHttp.onreadystatechange = () => {
                    if (tokenHttp.readyState === XMLHttpRequest.DONE) {
                        window.location.href = `${commAutAddr}?redirectUri=${redirectUrl}&interfaceToken=${tuid}`;
                    }
                };
            } else {
                redirectForm.current.submit();
            }
        }

        fetchLogout();
    }, []);

    if (!isLogout) {
        AuthService.setPathnameAndSearch(window.location.pathname, window.location.search);
    }

    if (ifSsoMethod === 'SESSION_FILTER' && !_.isEmpty(commAutAddr)) {
        return <></>;
    }

    const langCd = convertBrowserLangCodeToOurLangCode(); // FIXME [ayden] Does it need to set the language used recently?
    const loginMode = 'temporary'; // default

    return (
        <form ref={redirectForm} action={REDIRECT_LOGIN_PAGE} method="POST">
            <input type="hidden" name="redirectUrl" defaultValue={redirectUrl} />
            <input type="hidden" name="langCd" defaultValue={langCd} />
            <input type="hidden" name="loginMode" defaultValue={loginMode} />
            <input type="hidden" name="isLoginTried" defaultValue={dontNeedSSO} />
        </form>
    );
};

RedirectToLoginPage.propTypes = {
    dontNeedSSO: PropTypes.bool,
    isLogout: PropTypes.bool,
    loginInfo: PropTypes.object
};

RedirectToLoginPage.defaultProps = {
    dontNeedSSO: false,
    isLogout: false,
    loginInfo: {}
};

const mapStateToProps = state => ({
    loginInfo: state.auth.loginInfo
});

export default connect(
    mapStateToProps,
    null
)(RedirectToLoginPage);
