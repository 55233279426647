import $http from 'modules/Http';
import { Base64 } from 'js-base64';
import _ from 'lodash';

const prefix = '/common/v1/helpcenter/relnote';

class ReleaseNoteRest {
    static getReleasenotes(params) {
        const converted = { ...params };
        const { keyword, limit } = params;
        if (_.isEmpty(limit)) {
            converted.limit = 50;
        }
        if (keyword) {
            converted.keyword = Base64.encode(keyword);
        }
        return $http.get(prefix, { params: converted });
    }

    static getReleasenote(relnoteId) {
        return $http.get(`${prefix}/${relnoteId}`);
    }
}

export default ReleaseNoteRest;
