import produce from 'immer';
import { SET_CONFIGS } from '../actions/Config';

const config = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_CONFIGS:
            return produce(state, draft => {
                draft.configs = action.configs;
            });
        default:
            return state;
    }
};

export default config;
