import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import FileInput from './Item/FileInput';

const MultipleFilesPromptTemplate = memo(() => {
    return (
        <div className="copilot-prompt-input">
            <div className="prompt-command">
                <FileInput fileInputIndex={0} />
                {', '}
                <FileInput fileInputIndex={1} /> <FormattedMessage id="drive.text.copilot.summary.ptcont.multiple" />
            </div>
        </div>
    );
});

export default MultipleFilesPromptTemplate;
