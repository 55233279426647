import produce from 'immer';
import { handleActions } from 'redux-actions';

export default handleActions(
    {
        wrongKeywordDialog: {
            open: state => {
                return produce(state, draft => {
                    draft.isOpen = true;
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                });
            }
        }
    },
    { isOpen: false },
    { prefix: 'summary' }
);
