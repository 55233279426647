/* eslint-disable */
import React from 'react';

const NormalList = props => {

    const { accessRef, className, itemCount, itemSize, itemBuilder } = props;

    let rows = [];
    for(let i=0; itemCount > i; i++){
        rows.push(itemBuilder({
            style: { width:'100%', height:itemSize, top: itemSize * i },
            index: i
        }));
    }

    return (
        <div className={className} style={{ height: itemCount * itemSize }}>
            <div ref={accessRef}>
                {rows}
            </div>
        </div>
    );
};

export default NormalList;
