import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import FilterKeyword from '../presentationals/Search/FilterKeyword';
import FilterDate from '../presentationals/Search/FilterDate';
import Tooltips from '../presentationals/Tooltips';
import { confirm } from '../../utils/ModalService';
import FilterCheckboxGroup from './Filter/FilterCheckboxGroup';
import { ALL } from './Filter/FilterConstants';
import RequiredLabel from './Filter/RequiredLabel';

class ExpirationFilter extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props;
        const { objtNm = '', expirationDateFrom, expirationDateTo, expirationRequestStateCodes = ALL, expirationUsedRecentlyCodes = ALL } = params;

        this.state = {
            objtNm,
            expirationDateFrom,
            expirationDateTo,
            expirationRequestStateCodes,
            expirationUsedRecentlyCodes
        };
    }

    componentWillUnmount() {
        this.onCancel();
    }

    onKeywordChange = objtNm => {
        this.setState(objtNm);
    };

    onDateChange = tgtDt => {
        this.setState({
            expirationDateFrom: tgtDt.expirationDateFrom,
            expirationDateTo: tgtDt.expirationDateTo
        });
    };

    onCancel = () => {
        const { close } = this.props;
        close();
    };

    onInit = () => {
        const { openInnerPopup, initParams } = this.props;
        // 검색 필터를 초기화 하시겠습니까?
        confirm(<FormattedMessage id="drive.confirm.search.filter.init" />).then(
            result => {
                if (result) {
                    this.setState(initParams);
                }
                openInnerPopup(false);
            },
            () => {
                openInnerPopup(false);
            }
        );
        openInnerPopup(true);
    };

    onSearch = () => {
        const { search } = this.props;
        const stateParam = Object.assign({}, this.state);
        const stateKeys = _.keys(stateParam);
        const searchParam = {};

        stateKeys
            .filter(key => !_.isNil(stateParam[key]) && _.trim(stateParam[key]).length)
            .forEach(key => {
                searchParam[key] = _.trim(stateParam[key]);
            });

        search(searchParam);
    };

    handleChangeRequestState = selectedCodes => {
        this.setState({
            expirationRequestStateCodes: selectedCodes
        });
    };

    handleChangeUsedRecently = selectedCodes => {
        this.setState({
            expirationUsedRecentlyCodes: selectedCodes
        });
    };

    render() {
        const { objtNm, expirationDateFrom, expirationDateTo, expirationRequestStateCodes, expirationUsedRecentlyCodes } = this.state;
        const { intl } = this.props;

        const cannotSearch = _.isEmpty(expirationRequestStateCodes) || _.isEmpty(expirationUsedRecentlyCodes);

        return (
            <div className="overlay-layer-wrapper slide show">
                <div className="overlay-layer">
                    <div className="layer-content layer-sm">
                        <div className="layer-header hd-gray">
                            <h1 className="layer-title">
                                <FormattedMessage id="drive.text.search.filter.title" />
                            </h1>
                            <a id="quick-search-filter-close" className="btn-ic-nor" role="button" title="닫기" onClick={this.onCancel}>
                                <i className="ic-20-close" />
                            </a>
                        </div>
                        <div className="layer-body">
                            <div className="dt-width-120">
                                <FilterKeyword
                                    id="objtNm"
                                    nameKey="drive.text.search.filter.fileNm"
                                    onChange={this.onKeywordChange}
                                    value={objtNm}
                                    placeholderKey="drive.placeHolder.search.filter.fileNm"
                                />
                                <dl className="row-list">
                                    <dt>
                                        <FormattedMessage id="com.expiration-date" />
                                    </dt>
                                    <dd>
                                        <FilterDate
                                            startDate={expirationDateFrom !== undefined ? moment(expirationDateFrom) : expirationDateFrom}
                                            endDate={expirationDateTo !== undefined ? moment(expirationDateTo) : expirationDateTo}
                                            onDatesChange={({ startDate, endDate }) => {
                                                const f = 'YYYYMMDD';
                                                let vStartDate = startDate;
                                                if (vStartDate) {
                                                    vStartDate = vStartDate.format(f);
                                                }
                                                let vEndDate = endDate;
                                                if (vEndDate) {
                                                    vEndDate = vEndDate.format(f);
                                                }
                                                this.onDateChange({ expirationDateFrom: vStartDate, expirationDateTo: vEndDate });
                                            }}
                                            minDate={moment().add(-10, 'years')}
                                            maxDate={moment().add(10, 'years')}
                                        />
                                    </dd>
                                </dl>
                                <dl className="row-list">
                                    <dt>
                                        <RequiredLabel messageId="drive.text.list.columns.expirationRequestStateCode" />
                                    </dt>
                                    <dd>
                                        <FilterCheckboxGroup
                                            groupInfo={[
                                                { label: intl.formatMessage({ id: 'drive.text.expiration.requestState.processing' }), value: 'PROCESSING' },
                                                { label: intl.formatMessage({ id: 'drive.text.expiration.requestState.none' }), value: 'NONE' },
                                                { label: intl.formatMessage({ id: 'drive.text.expiration.requestState.rejected' }), value: 'REJECTED' }
                                            ]}
                                            rawSelectedValues={expirationRequestStateCodes}
                                            onChangeRawSelectedValues={this.handleChangeRequestState}
                                        />
                                    </dd>
                                </dl>
                                <dl className="row-list">
                                    <dt>
                                        <span className="btn-ic-nor btn-sm" data-toggle="tooltip" data-placement="top" data-trigger="manual" data-html="true">
                                            <RequiredLabel messageId="drive.text.expiration.usedRecentlyYn" />
                                            <i className="ic-16-help" id="usedRecentlyTooltipId" />
                                        </span>
                                        <Tooltips targetId="usedRecentlyTooltipId" placement="top">
                                            <div className="guide">
                                                <FormattedMessage id="drive.tooltip.filter.expiration.userRecentlyYnGuide" />
                                            </div>
                                        </Tooltips>
                                    </dt>
                                    <dd>
                                        <FilterCheckboxGroup
                                            groupInfo={[
                                                {
                                                    label: intl.formatMessage({ id: 'com.text.notUsed' }),
                                                    value: 'NONE'
                                                },
                                                {
                                                    label: intl.formatMessage({ id: 'com.text.used' }),
                                                    value: 'USED'
                                                }
                                            ]}
                                            rawSelectedValues={expirationUsedRecentlyCodes}
                                            onChangeRawSelectedValues={this.handleChangeUsedRecently}
                                        />
                                    </dd>
                                </dl>
                            </div>
                            <div className="info-msg">
                                <i className="ic-16-info" />
                                <em className="critical-item" title={intl.formatMessage({ id: 'drive.text.search.filter.requiredItem' })} />{' '}
                                <FormattedMessage id="drive.guideText.search.filter.requiredItem" />
                            </div>
                        </div>
                        <div className="layer-footer">
                            <div className="layer-footer-option">
                                <a className="btn-ic-line btn-lg" role="button" onClick={this.onInit}>
                                    <i className="ic-20-reset" />
                                    <span className="btn-text">
                                        <FormattedMessage id="com.reset" />
                                    </span>
                                </a>
                            </div>
                            <div className="layer-footer-btns">
                                <a className="btn btn-lg btn-secondary" role="button" onClick={this.onCancel}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.cancel" />
                                    </span>
                                </a>
                                <a className={classnames('btn', 'btn-lg', 'btn-primary', cannotSearch && 'disabled')} role="button" onClick={this.onSearch}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.search" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ExpirationFilter.defaultProps = {
    params: {},
    initParams: {}
};

ExpirationFilter.propTypes = {
    params: PropTypes.object,
    initParams: PropTypes.object,
    intl: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    openInnerPopup: PropTypes.func.isRequired
};

export default injectIntl(ExpirationFilter);
