import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import UserMessage from './Message/User/UserMessage';
import AIMessage from './Message/AI/AIMessage';
import { SUMMARY_MESSAGE_TYPE } from '../../Common/SummaryConstant';
import RecommendPrompts from './Message/Etc/RecommendPrompts';

const SummaryThread = ({ moveSidePanelScrollToBottom }) => {
    const summaryMessages = useSelector(({ summary }) => summary.thread.summaryMessages);

    return (
        <div className="ai-inner">
            <div className="ai-content">
                {summaryMessages.map((summaryMessage, index) => {
                    const { type } = summaryMessage;

                    switch (type) {
                        case SUMMARY_MESSAGE_TYPE.user:
                            // eslint-disable-next-line react/no-array-index-key
                            return <UserMessage summaryMessage={summaryMessage} key={`user_${index}`} />;
                        case SUMMARY_MESSAGE_TYPE.ai:
                            // eslint-disable-next-line react/no-array-index-key
                            return <AIMessage summaryMessage={summaryMessage} moveSidePanelScrollToBottom={moveSidePanelScrollToBottom} key={`ai_${index}`} />;
                        case SUMMARY_MESSAGE_TYPE.recommendedPrompt:
                            // eslint-disable-next-line react/no-array-index-key
                            return <RecommendPrompts summaryMessage={summaryMessage} key={`rp_${index}`} />;
                        default:
                            console.error(`Unknown message type `, type, summaryMessage);
                            // eslint-disable-next-line react/no-array-index-key
                            return <React.Fragment key={`unknown_${index}`} />;
                    }
                })}
            </div>
        </div>
    );
};

SummaryThread.propTypes = {
    moveSidePanelScrollToBottom: PropTypes.func.isRequired
};

SummaryThread.defaultProps = {};

export default SummaryThread;
