import { func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';
import CustomModal from 'containers/CustomModal';

class FolderInfoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    closePopup() {
        const { close } = this.props;
        close();
    }

    showInfo() {
        const { type } = this.props;
        if (type === 'shared') {
            return (
                <ul className="hyphen-list">
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharedfilesDesc1" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharedfilesDesc2" />
                        <br />
                        <FormattedMessage id="drive.text.folderRoleInfo.sharedfilesDesc3" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharedfilesDesc4" />
                    </li>
                </ul>
            );
        }
        if (type === 'sharing') {
            return (
                <ul className="hyphen-list">
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharingfilesDesc1" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharingfilesDesc1" />
                        <br />
                        <FormattedMessage id="drive.text.folderRoleInfo.sharingfilesDesc1" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.sharingfilesDesc1" />
                    </li>
                </ul>
            );
        }
        if (type === 'workgroup') {
            return (
                <ul className="hyphen-list">
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.workgroupsDesc1" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.workgroupsDesc2" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.workgroupsDesc3" />
                        <br />
                        <FormattedMessage id="drive.text.folderRoleInfo.workgroupsDesc4" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.workgroupsDesc5" />
                    </li>
                </ul>
            );
        }
        if (type === 'compliance') {
            return (
                <ul className="hyphen-list">
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.complianceDesc1" />
                    </li>
                    <li>
                        <FormattedMessage id="drive.text.folderRoleInfo.complianceDesc2" />
                    </li>
                </ul>
            );
        }
        return {};
    }

    showTitle() {
        const { type } = this.props;
        if (type === 'shared') {
            return <FormattedMessage id="drive.title.folderRoleInfo.sharedfiles" />;
        }
        if (type === 'workgroup') {
            return <FormattedMessage id="drive.title.folderRoleInfo.workgroups" />;
        }
        if (type === 'compliance') {
            return <FormattedMessage id="drive.title.folderRoleInfo.compliance" />;
        }
        return {};
    }

    render() {
        const { intl } = this.props;
        return (
            <CustomModal isOpen={true} fade={true} backdrop="static" className="modal-md">
                <div className="modal-header">
                    <h5 className="modal-title">{this.showTitle()}</h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => this.closePopup()}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <ModalBody>{this.showInfo()}</ModalBody>
                <ModalFooter>
                    <div className="modal-footer-option" />

                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={() => this.closePopup()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </div>
                </ModalFooter>
            </CustomModal>
        );
    }
}

FolderInfoPopup.propTypes = {
    close: func,
    intl: object,
    type: string
};

FolderInfoPopup.defaultProps = {
    close: () => {},
    intl: {},
    type: 'default'
};
export default injectIntl(FolderInfoPopup);
