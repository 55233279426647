export const SUMMARY_OPTIONS = {
    llm: 'llm',
    length: 'length',
    form: 'form',
    scope: 'scope'
};

export const SUMMARY_LENGTH_TYPE = {
    short: 'short',
    medium: 'medium',
    long: 'long'
};

export const SUMMARY_LENGTH_TYPES = Object.values(SUMMARY_LENGTH_TYPE);

export const SUMMARY_FORM_TYPE = {
    line: 'line',
    format: 'format'
};

export const SUMMARY_FORM_TYPES = Object.values(SUMMARY_FORM_TYPE);

export const SUMMARY_SCOPE_TYPE = {
    document: 'document',
    keyword: 'keyword'
};

export const SUMMARY_SCOPE_TYPES = Object.values(SUMMARY_SCOPE_TYPE);

export const OPTIONS_DIALOG_POSITION_TYPE = {
    center: 'center',
    around: 'around'
};

export const DEFAULT_SUMMARY_OPTIONS = {
    lengthType: SUMMARY_LENGTH_TYPE.medium,
    formType: SUMMARY_FORM_TYPE.line,
    scopeType: SUMMARY_SCOPE_TYPE.document
};
