import React, { Component } from 'react';
import AuthRest from 'apis/AuthRest';
import CustomModal from 'containers/CustomModal';
import { produce } from 'immer/dist/immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import TermRest from 'apis/TermsRest';
import AUTH from 'constants/Auth';
import GlobalPopupService from '../../../redux/service/GlobalPopup';
import { auth as AUTH_ACTIONS } from '../../../redux/actions';
import Editor from '../editor/Editor';
import 'react-quill/dist/quill.snow.css';
import './editorStyles.scss';

// DB에 저장된 약관 값은 001~004까지 여러개이나
// 현재 화면에 노출되고 있는것은 001, 002 두개
// 화면 랜더링시 강제로 두개만 하드코딩되어있고
// 체크 확인등의 value check에서도 2라는 값이 고정되어있다

// 따라서 추후에 다른 약관 추가시 이런 값들을 모두 수정해주어야함
class AcceptTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedListAll: [false],
            itemContents: '',
            itemDtlContents: '',
            consignmentContents: '',
            policyContents: '',
            isDisabled: true,
            isAdditioanlTerms: false,
            isAdditioanlTermsCheck: true,
            termsData: [] // 조회된 모든 약관 데이터 저장
        };

        this.termsType = [AUTH.TERMS_TYPE.ITEMS, AUTH.TERMS_TYPE.POLICY];
    }

    componentDidMount() {
        this.getTerms();
    }

    getTerms() {
        TermRest.getAcceptTermsFinals().then(resolve => {
            console.log('aa', resolve);
            const { data, resultCode, message } = resolve;
            if (resultCode === 200) {
                const { list } = data;
                const itemContents = _.find(list, content => content.termsTypeSectCd === AUTH.TERMS_TYPE.ITEMS);
                const policyContents = _.find(list, content => content.termsTypeSectCd === AUTH.TERMS_TYPE.POLICY);
                if (resolve.data.additionalTermYn !== null) {
                    if (resolve.data.additionalTermYn.cfgVal === 'Y') {
                        this.setState({ isAdditionalTerms: true, isAdditioanlTermsCheck: false });
                    }
                }
                this.setState({
                    itemContents: _.get(itemContents, 'termsContent', ''),
                    itemDtlContents: _.get(itemContents, 'termsDtlContent', ''),
                    policyContents: _.get(policyContents, 'termsContent', ''),
                    termsData: list.sort((a, b) => {
                        if (a.termsTypeSectCd > b.termsTypeSectCd) {
                            return 1;
                        }
                        if (a.termsTypeSectCd < b.termsTypeSectCd) {
                            return -1;
                        }
                        return 0;
                    })
                });
            } else {
                GlobalPopupService.openAlertPopup({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } });
            }
        });
    }

    handleCheckboxClick(event, index) {
        const { checked } = _.get(event, 'target', {});
        const nextState = produce(this.state, draft => {
            draft.checkedListAll[index] = checked;
        });

        const { checkedListAll, isAdditioanlTermsCheck, termsData } = nextState;
        console.log('rrr', checkedListAll);
        const isAble =
            _.reduce(
                checkedListAll,
                (sum, value) => {
                    return value ? sum + 1 : sum;
                },
                0
            ) === termsData.length && isAdditioanlTermsCheck;

        this.setState({ ...nextState, isDisabled: !isAble });
    }

    handleCheckboxClickAdditionalTerms(event) {
        const { checked } = _.get(event, 'target', {});
        const nextState = produce(this.state, draft => {
            draft.isAdditioanlTermsCheck = checked;
        });

        const { checkedListAll, isAdditioanlTermsCheck, termsData } = nextState;

        const isAble =
            _.reduce(
                checkedListAll,
                (sum, value) => {
                    return value ? sum + 1 : sum;
                },
                0
            ) === termsData.length && isAdditioanlTermsCheck;

        this.setState({ ...nextState, isDisabled: !isAble });
    }

    acceptTerms() {
        if (!this.getCheckedListAll()) {
            // console.log('isall ', !this.getCheckedListAll());
            return;
        }
        const { termsData } = this.state;

        AuthRest.agreeForTerm(termsData).then(
            ({ resultCode, message }) => {
                if (resultCode === AUTH.RESULT_CODE.SUCCESS) {
                    const { setTokenStatus } = this.props;
                    setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.AUTH);
                } else {
                    const { intl } = this.props;
                    GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
                }
            },
            () => {
                const { setTokenStatus } = this.props;
                setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.EXPIRED);
            }
        );
    }

    getCheckedListAll() {
        const { checkedListAll, termsData } = this.state;

        const result = _.reduce(
            checkedListAll,
            (sum, value) => {
                return value ? sum + 1 : sum;
            },
            0
        );

        return result === termsData.length;
    }

    handleCheckboxAll() {
        const isAllChecked = this.getCheckedListAll();
        const newCheckedValue = !isAllChecked;
        this.setState({ checkedListAll: [newCheckedValue], isDisabled: !newCheckedValue });
    }

    rejectTerms() {
        const { setTokenStatus } = this.props;
        setTokenStatus(AUTH_ACTIONS.TOKEN_STATUS.EXPIRED);
    }

    render() {
        const { checkedListAll, isDisabled, isAdditionalTerms, isAdditioanlTermsCheck, termsData } = this.state;
        const { intl } = this.props;
        return (
            <CustomModal isOpen={true} fade={true} backdrop="static" className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title">{intl.formatMessage({ id: 'user.terms.termsOfAgree' })}</h5>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={intl.formatMessage({ id: 'com.close' })}
                        data-dismiss="modal"
                        onClick={e => {
                            this.rejectTerms();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <ModalBody>
                    {termsData.map((column, index) => {
                        return column.termsContent === '' || column.termsContent === undefined ? (
                            <div className="terms-check" key={column.termsId}>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" checked={checkedListAll[index]} onClick={event => this.handleCheckboxClick(event, index)} />
                                        <span className="label-text">
                                            {/* <FormattedMessage id="user.terms.checkMessage.agreePersnalItems" /> */}
                                            {column.termsTitle}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <>
                                {column.termsDtlContent === '' || column.termsDtlContent === undefined ? (
                                    <div className="form-group">
                                        <textarea className="form-control textarea-lg document" readOnly={true} value={column.termsContent} />
                                    </div>
                                ) : (
                                    <div className="form-group viewer">
                                        <Editor html={column.termsDtlContent} />
                                    </div>
                                )}
                                <div className="terms-check">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" checked={checkedListAll[index]} onClick={event => this.handleCheckboxClick(event, index)} />
                                            <span className="label-text">
                                                {/* <FormattedMessage id="user.terms.checkMessage.agreePersnalItems" /> */}
                                                {column.termsTitle}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </>
                        );
                    })}

                    {isAdditionalTerms && (
                        <>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" checked={isAdditioanlTermsCheck} onClick={event => this.handleCheckboxClickAdditionalTerms(event)} />
                                    <span className="label-text">
                                        <FormattedMessage id="com.text.additionalTerm" />
                                    </span>
                                </label>
                            </div>
                        </>
                    )}
                    {/* <div className="form-group"> */}
                    {/*    <textarea className="form-control textarea-sm document" value={policyContents} readOnly={true} /> */}
                    {/* </div> */}
                    {/* <div className="terms-check"> */}
                    {/*    <div className="form-check"> */}
                    {/*        <label className="form-check-label"> */}
                    {/*            <input className="form-check-input" type="checkbox" checked={checkedListAll[1]} onClick={event => this.handleCheckboxClick(event, 1)} /> */}
                    {/*            <span className="label-text"> */}
                    {/*                <FormattedMessage id="user.terms.checkMessage.agreePrivacyPolicy" /> */}
                    {/*            </span> */}
                    {/*        </label> */}
                    {/*    </div> */}
                    {/* </div> */}
                    {/* <div className="terms-all-check"> */}
                    {/*    <div className="form-check"> */}
                    {/*        <label className="form-check-label"> */}
                    {/*            <input className="form-check-input" type="checkbox" checked={this.getCheckedListAll()} onClick={() => this.handleCheckboxAll()} /> */}
                    {/*            <span className="label-text"> */}
                    {/*                <FormattedMessage id="user.text.eula.checkAll" /> */}
                    {/*            </span> */}
                    {/*        </label> */}
                    {/*    </div> */}
                    {/* </div> */}
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={() => this.rejectTerms()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.cancel" />
                            </span>
                        </a>
                        <a className={`btn btn-lg btn-primary ${isDisabled ? 'disabled' : ''}`} role="button" disabled={isDisabled} onClick={() => this.acceptTerms()}>
                            <span className="btn-text">
                                <FormattedMessage id="com.check" />
                            </span>
                        </a>
                    </div>
                </ModalFooter>
            </CustomModal>
        );
    }
}

AcceptTerm.propTypes = {
    setTokenStatus: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    setTokenStatus: AUTH_ACTIONS.setTokenStatus
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(AcceptTerm)
);
