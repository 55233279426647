import React from 'react';
import { largePopup } from '../../utils/ModalService';
import AgentDownload from '../presentationals/Download/AgentDownload';

const openPopup = data => {
    console.log('data: ', data);
    return largePopup(<AgentDownload />);
};

const AgentDownloadPopup = () => {
    openPopup().then(() => {}, () => {});
    return <></>;
};

export default AgentDownloadPopup;
