import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import DriveRecentFiles from '../containers/DriveFiles/DriveRecentFiles';
import FavoriteContainer from '../containers/HomeFavorite/FavoriteContainer';

import CoachMarkConstants from '../../constants/CoachMark';

const Home = ({ history, loginUser }) => {
    const { id } = loginUser;

    const showRecent = _.isNil(localStorage.getItem(`${CoachMarkConstants.COACHMARK_HOME}${id}`)) ? 'Y' : 'N';
    const [recentCoachMark, setRecentCoachMark] = useState(showRecent);
    const [favoriteCoachMark, setFavoriteCoachMark] = useState('N');
    const hideRecentCoachMark = () => {
        setRecentCoachMark('N');
    };

    const showFavoriteCoachMark = () => {
        setFavoriteCoachMark('Y');
    };

    return (
        <div className="page-home">
            <DriveRecentFiles history={history} showCoachMark={recentCoachMark} hideRecentCoachMark={hideRecentCoachMark} showFavoriteCoachMark={showFavoriteCoachMark} />
            <FavoriteContainer history={history} showCoachMark={favoriteCoachMark} />
        </div>
    );
};

Home.propTypes = {
    history: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default connect(mapStateToProps)(Home);
