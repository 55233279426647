import BaseMenu from '../BaseMenu';
import InLinkRest from '../../../../apis/InLinkRest';
import clipboard from '../../../../utils/Clipboard';
import { toast } from '../../../../utils/ModalService';

const executor = (props, files) => {
    function complete() {
        toast('drive.toast.linkCopy.complete');
        props.callback();
    }

    function fail() {
        toast('drive.toast.linkCopy.fail');
    }

    function getIncomLinkUrl() {
        const incomLinkParmList = [];

        for (let i = 0; i < files.length; i += 1) {
            incomLinkParmList.push({ objtId: files[i].drive.objtId, onpstId: files[i].drive.onpstId });
        }

        return InLinkRest.getIncomLinkUrlList(incomLinkParmList);
    }

    function copyToClipboard(result) {
        let copiedLink = '';

        for (let i = 0; i < result.length; i += 1) {
            if (i > 0) {
                copiedLink = copiedLink.concat('\r\n');
            }
            copiedLink = copiedLink.concat(result[i].incomLinkUrl);
        }

        if (clipboard.set(copiedLink)) {
            complete();
        } else {
            fail();
        }
    }

    getIncomLinkUrl().then(copyToClipboard, () => toast('drive.toast.linkCopy.noAuth'));
};

class LinkCopy extends BaseMenu {
    getName() {
        return 'linkCopy';
    }

    getLabel() {
        return 'drive.button.list.menu.linkCopy';
    }

    isVisible() {
        return super.isVisible(true, 'createInLinkUrl');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-link', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-link', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default LinkCopy;
