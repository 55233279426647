import $http from 'modules/Http';

const PREFIX_AUTH = '/auth/v1/users/request';

class UserRequestRest {
    static getApproverCandidates(type, searchTgt = 'KNOX_DRIVE') {
        return $http.get(`${PREFIX_AUTH}/${type}/approver/candidates`, { params: { searchTgt } }).then(response => response, error => error);
    }

    static request(type, params = {}) {
        return $http.post(`${PREFIX_AUTH}/${type}`, params).then(response => response, error => error);
    }

    static getRequestSystemType(type = 'PCCONTROL') {
        return $http.get(`${PREFIX_AUTH}/${type}/system`).then(response => response, error => error);
    }

    static check(type) {
        return $http.get(`${PREFIX_AUTH}/${type}/check`).then(response => response, error => error);
    }
}

export default UserRequestRest;
