import React, { useEffect, useState } from 'react';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import queryString from 'query-string';
import AuthService from 'utils/AuthService';
import {
    AuthTokenProcess,
    NormalTokenProcess,
    RedirectPage,
    RedirectToLoginPage,
    TempTokenProcess,
    AcceptTerm,
    ChangePassword,
    ExpiredTokenProcess,
    AuthRemoverBeforeunload,
    AcceptNewTerm
} from 'presentationals/AuthProcess/index';
// import GlobalPopupService from 'src/redux/service/GlobalPopup';
import CustomModal from 'containers/CustomModal';
import AuthRest from 'apis/AuthRest';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import cookie from '../../utils/Cookie';
import { auth as authActions } from '../../redux/actions';
import { TOKEN_STATUS } from '../../redux/actions/Auth';
import LoginProcessing from './Login/LoginProcessing';

const BrityLangCdMap = {
    ko_KR: '001',
    en_US: '002',
    ja_JP: '002',
    zh_CN: '004'
};

const errorMsgKey = {
    AUTH_USER_NOTEXIST_ID: 'user.text.login.AUTH_USER_NOTEXIST_ID',
    DATA_DUPLICATE: 'user.text.login.DATA_DUPLICATE_MSG',
    AUTH_USER_STATUS_DISABLE: 'user.text.login.AUTH_USER_STATUS_DISABLE',
    AUTH_ACCESS_POLICY_DENIED: 'user.text.login.AUTH_ACCESS_POLICY_DENIED',
    LOGIN_OVERLOAD_CHECK: 'user.text.login.LOGIN_OVERLOAD_CHECK',
    LOGIN_LICENSE_EXPIRED: 'user.text.login.LOGIN_LICENSE_EXPIRED'
};

const removeUrlParam = () => {
    const searchParams = queryString.parse(window.location.search);
    delete searchParams.tuid;
    delete searchParams.chip;
    const stringified = queryString.stringify(searchParams);
    const newUrlParam = _.isEmpty(stringified) ? window.location.pathname : `${window.location.pathname}?${stringified}`;
    window.history.replaceState({}, document.title, newUrlParam);
};

const Authentication = ({ tokenStatus, user, children, setTokenStatus, setUser, setLoginInfo }) => {
    const [loginMsg, setLoginMsg] = useState('com.text.login.processing');
    const [alertMsg, setAlertMsg] = useState('com.text.login.processing-error');
    const [msgValue, setMsgValue] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [helpcenterInfo, setHelpcenterInfo] = useState({});
    const [langCd, setLangCd] = useState('001');
    const { authToken, signatureKey } = AuthService.getAuthTokenAndSignKey();
    const searchParams = queryString.parse(window.location.search);
    const hasAuthentication = !_.isEmpty(searchParams.tuid) && !_.isEmpty(searchParams.chip);
    const hasAuthenticationAtLocal = authToken && signatureKey;
    const pathname = AuthService.getPathname();

    const getToken = async () => {
        const result = await AuthRest.getTokenBySessionFilter(langCd);
        const { data, message, resultCode } = result;

        if (resultCode === 200) {
            const { auth } = data;
            const { tuid, chip } = auth;
            AuthService.setAuthToken(tuid);
            AuthService.setSignatureKey(chip);
            setTokenStatus(TOKEN_STATUS.TEMP);
        } else if (resultCode === 403) {
            setTokenStatus(TOKEN_STATUS.EXPIRED);
        } else {
            setLoginMsg('com.text.login.processing-error');

            const msgKey = errorMsgKey[message] ? errorMsgKey[message] : 'com.text.login.processing-error';
            setAlertMsg(msgKey);
            setOpenAlert(true);
        }
    };

    const closeAlert = () => {
        setOpenAlert(false);
        if (_.isEmpty(msgValue)) {
            setTokenStatus(TOKEN_STATUS.EXPIRED);
        }
    };

    useEffect(() => {
        async function fetchDomain() {
            const { hostname } = window.location;
            const result = await AuthRest.getDomainInfo(hostname);
            const { data, resultCode } = result;
            if (resultCode === 200) {
                // 대외 && 모바일 일때만 appDownloadUrl로 전환
                if (isMobile && data.siteNm === 'EXT_AWS') {
                    // eslint-disable-next-line no-restricted-globals
                    location.replace(data.mobileAppDownloadUrl);
                }
                if (hasAuthentication) {
                    const tuid = decodeURIComponent(searchParams.tuid.replace(/\+/g, '%20'));
                    data.tuid = tuid;
                }
                setLoginInfo(data);
                const { ifSsoMethod, helpInfoYn, helpCenterMail, helpCenterNum, helpCenterHours } = data;

                if (ifSsoMethod === 'SESSION_FILTER') {
                    const brityWorksLangCookie = cookie.getCookieText('saveLanguage');
                    let loginLangCd = '001';
                    if (!_.isEmpty(brityWorksLangCookie)) {
                        loginLangCd = BrityLangCdMap[brityWorksLangCookie.split('.')[0]] ? BrityLangCdMap[brityWorksLangCookie.split('.')[0]] : '001';
                        setLangCd(loginLangCd);
                    }

                    let convertedHelpNum = helpCenterNum;
                    cookie.setCookieTextWithExpireDays('ssoMethod', ifSsoMethod, 7);
                    try {
                        const helpNumObject = JSON.parse(helpCenterNum);
                        convertedHelpNum = helpNumObject[langCd];
                    } catch (e) {
                        console.log(e);
                    }
                    const helpCenter = { helpInfoYn, helpCenterMail, helpCenterNum: convertedHelpNum, helpCenterHours };
                    setHelpcenterInfo(helpCenter);

                    const EP6_UTOKEN = cookie.getCookieText('EP6_UTOKEN');
                    const DV_UTOKEN = cookie.getCookieText('DV_UTOKEN');
                    if (_.isEmpty(EP6_UTOKEN) && _.isEmpty(DV_UTOKEN)) {
                        setTokenStatus(TOKEN_STATUS.EXPIRED);
                    } else {
                        // 토큰이 있는 경우 통합인증 세션 사용자와 agent(tuid)사용자가 일치하는지 확인한다.
                        if (hasAuthentication) {
                            const res = await AuthRest.compareUserInfoWithToken(data.tuid);
                            if (res.message === 'AUTH_USER_INVALID_ID') {
                                // 두계정이 다른 경우
                                const { originUser, newUser } = res.data;
                                setHelpcenterInfo({ helpInfoYn: 'N' });
                                setLoginMsg('com.text.login.processing-error');
                                setAlertMsg('com.text.login.session.differ');
                                setMsgValue({ originUser, newUser });
                                setOpenAlert(true);
                                return;
                            }
                            if (res.message !== 'IOFFICE_SUCCESS') {
                                // 무언가 문제가 발생한 경우
                                setHelpcenterInfo({ helpInfoYn: 'N' });
                                setLoginMsg('com.text.login.processing-error');
                                setAlertMsg('com.text.login.processing-error');
                                setOpenAlert(true);
                                return;
                            }
                        } else if (hasAuthenticationAtLocal) {
                            // 내 토큰과 브리티 세션 토큰을 비교해본다.
                            const res = await AuthRest.compareUserInfoWithToken(authToken);
                            if (res.message === 'IOFFICE_SUCCESS') {
                                // 어딘가에서 받아온 토큰이 있는 케이스외에 현재 나의 local에 토큰이 있는 경우 재발급 받지 않는다.
                                setTokenStatus(TOKEN_STATUS.INIT);
                            } else {
                                setAlertMsg('com.text.login.processing-error');
                                setOpenAlert(true);
                                setTokenStatus(TOKEN_STATUS.EXPIRED);
                            }
                            return;
                        }
                        getToken();
                    }
                } else {
                    setTokenStatus(TOKEN_STATUS.INIT);
                }
            } else {
                setLoginMsg('com.text.login.processing-error');
                // const msg = intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } });
                // GlobalPopupService.openAlertPopup(msg).then(() => setTokenStatus(TOKEN_STATUS.EXPIRED));
            }
        }

        fetchDomain();
    }, []);

    useEffect(() => {
        if (tokenStatus === TOKEN_STATUS.INIT) {
            if (hasAuthenticationAtLocal) {
                setTokenStatus(TOKEN_STATUS.AUTH);
            }

            if (hasAuthentication) {
                const tuid = decodeURIComponent(searchParams.tuid.replace(/\+/g, '%20'));
                const chip = decodeURIComponent(searchParams.chip.replace(/\+/g, '%20'));
                AuthService.setAuthToken(tuid);
                AuthService.setSignatureKey(chip);
                setTokenStatus(TOKEN_STATUS.TEMP);
            }
        }
        if (tokenStatus === TOKEN_STATUS.ACCEPT_TERMS || tokenStatus === TOKEN_STATUS.CHANGE_PASSWORD || tokenStatus === TOKEN_STATUS.TEMP) {
            removeUrlParam();
        }
    }, [tokenStatus]);

    if (tokenStatus === TOKEN_STATUS.WAIT) {
        return (
            <>
                <LoginProcessing msgKey={loginMsg} />;
                <CustomModal isOpen={openAlert} fade={false} size="sm" backdrop="static">
                    <div className="modal-body">
                        <dl className="col-list">
                            <dt className="nor ht-md">
                                {helpcenterInfo.helpInfoYn !== 'N' ? (
                                    <>
                                        <FormattedHTMLMessage id={alertMsg} tagName="div" values={msgValue} />
                                        <br />
                                        <FormattedMessage id="user.gnb.helpCenter" />
                                        <br />
                                        <br />
                                        <FormattedMessage id="user.popHelpcenter.reqPhone" />
                                        &nbsp;&nbsp;:&nbsp;&nbsp;{helpcenterInfo.helpCenterNum}
                                        <br />
                                        <br />
                                        <FormattedMessage id="com.email" />
                                        &nbsp;&nbsp;:&nbsp;&nbsp;{helpcenterInfo.helpCenterMail}
                                        <br />
                                        <br />
                                        <FormattedMessage id="user.popHelpcenter.operatingTime" />
                                        &nbsp;&nbsp;:&nbsp;&nbsp;{helpcenterInfo.helpCenterHours}
                                        <br />
                                    </>
                                ) : (
                                    <FormattedHTMLMessage id={alertMsg} tagName="div" values={msgValue} />
                                )}
                            </dt>
                        </dl>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-option" />
                        <div className="modal-footer-btns">
                            <a className="btn btn-lg btn-primary" role="button" onClick={closeAlert}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.close" />
                                </span>
                            </a>
                        </div>
                    </div>
                </CustomModal>
            </>
        );
    }

    return (
        <>
            <Choose>
                <When condition={tokenStatus === TOKEN_STATUS.WAIT}>
                    <></>
                </When>
                <When condition={tokenStatus === TOKEN_STATUS.INIT}>{hasAuthentication || hasAuthenticationAtLocal ? <></> : <RedirectToLoginPage />}</When>
                <When condition={tokenStatus === TOKEN_STATUS.TEMP}>
                    <TempTokenProcess setTokenStatus={setTokenStatus} />
                </When>
                <When condition={tokenStatus === TOKEN_STATUS.AUTH}>
                    <AuthTokenProcess setTokenStatus={setTokenStatus} />
                </When>
                <When condition={tokenStatus === TOKEN_STATUS.NORMAL}>
                    <NormalTokenProcess user={user} setUser={setUser} setTokenStatus={setTokenStatus}>
                        {children}
                    </NormalTokenProcess>
                    {pathname && pathname !== '/' && pathname !== window.location.pathname && <RedirectPage user={user} />}
                </When>
                <When condition={tokenStatus === TOKEN_STATUS.EXPIRED}>
                    <ExpiredTokenProcess setTokenStatus={setTokenStatus} />
                </When>

                <When condition={tokenStatus === TOKEN_STATUS.ACCEPT_TERMS}>
                    <AuthRemoverBeforeunload>
                        <AcceptTerm />
                    </AuthRemoverBeforeunload>
                </When>
                <When condition={tokenStatus === TOKEN_STATUS.ACCEPT_NEW_TEMRS}>
                    <AuthRemoverBeforeunload>
                        <AcceptNewTerm user={user} />
                    </AuthRemoverBeforeunload>
                </When>

                <When condition={tokenStatus === TOKEN_STATUS.CHANGE_PASSWORD}>
                    <AuthRemoverBeforeunload>
                        <ChangePassword user={user} />
                    </AuthRemoverBeforeunload>
                </When>

                <Otherwise>
                    <div>정상적인 인증 상태가 아닙니다. 서버관리자에게 문의하세요.</div>
                </Otherwise>
            </Choose>
        </>
    );
};

Authentication.propTypes = {
    children: PropTypes.node.isRequired,
    tokenStatus: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    setTokenStatus: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    setLoginInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    tokenStatus: state.auth.tokenStatus,
    user: state.auth.user
});

const mapDispatchToProps = {
    setTokenStatus: authActions.setTokenStatus,
    setUser: authActions.setUser,
    setLoginInfo: authActions.setLoginInfo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authentication);
