import { summaryActions } from '../../../../../redux/actions/Summary';

// eslint-disable-next-line import/prefer-default-export
export const showSummaryToast = (dispatch, content, delay = 2000) => {
    const id = Date.now();

    dispatch(summaryActions.toast.open({ toast: { id, content } }));
    setTimeout(() => {
        dispatch(summaryActions.toast.close({ id }));
    }, delay);
};
