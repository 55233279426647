import React, { Component } from 'react';
import $ from 'jquery';

import { injectIntl, FormattedMessage } from 'react-intl';
import CoachMarkConstants from 'constants/CoachMark';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server';

class RecentCoachMark extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        const { intl } = props;
        const dataContent = (
            <div className="coachmark-conts">
                <p> {intl.formatMessage({ id: 'com.text.coachMark.recent' })}</p>
                <div className="btns">
                    <a className="coachmark-stop" id="stop-recent" onClick={this.setNLocalStorage} role="button">
                        {intl.formatMessage({ id: 'com.text.coachMark.stop' })}
                    </a>
                    <a className="coachmark-next" id="next-recent">
                        {intl.formatMessage({ id: 'com.text.next' })}
                    </a>
                </div>
            </div>
        );
        this.dataRendered = renderToString(dataContent);

        // this.setNLocalStorage = this.setNLocalStorage.bind(this);
    }

    setNLocalStorage = () => {
        const { loginUser } = this.props;
        const { id } = loginUser;
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_HOME}${id}`, 'N');
    };

    showNext = () => {
        const { showFavoriteCoachMark } = this.props;
        this.hide('next');
        showFavoriteCoachMark();
        this.setNLocalStorage();
    };

    hide = mode => {
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.animate({ opacity: 0 }, 'fast', 'swing');

        if (mode === 'stop') {
            $('.coachmark-conts').popover('hide');
        }
        this.setNLocalStorage();
    };

    componentDidMount() {
        //    const { showCoachMarkState } = this.state;
        const { loginUser } = this.props;
        const { id } = loginUser;
        const showRecent = _.isNil(localStorage.getItem(`${CoachMarkConstants.COACHMARK_HOME}${id}`)) ? 'Y' : 'N';
        if (showRecent !== 'N') {
            setTimeout(this.show, 2500);
        }
    }

    show = () => {
        this.coachmarkOwner = $('#recent-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: true,
            fadeIn: 'slow'
        });

        this.popover.addClass('coachmark').popover('show');

        $('[class*="bs-popover-bottom"]').addClass('coachmark');
        // $('[class*="bs-popover-bottom"]').setAttribute('z-index', -1);

        if (document.getElementById('stop-recent') !== null) {
            document.getElementById('stop-recent').addEventListener('click', () => this.hide('stop'));
        }

        if (document.getElementById('next-recent') !== null) {
            document.getElementById('next-recent').addEventListener('click', this.showNext);
        }

        window.addEventListener('click', function(e) {
            $('[class*="bs-popover-bottom"]').each(function() {
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0 && e.target.id !== 'logo') {
                    $(this).popover('hide');
                }
            });
        });
    };

    render() {
        const { loginUser } = this.props;
        const { id } = loginUser;

        const showRecent = _.isNil(localStorage.getItem(`${CoachMarkConstants.COACHMARK_HOME}${id}`)) ? 'Y' : 'N';
        return showRecent === 'N' ? (
            <h3>
                {' '}
                <FormattedMessage id="drive.title.recent" />
            </h3>
        ) : (
            <>
                <h3
                    className="has-coachmark"
                    id="recent-coachmark"
                    data-placement="bottom"
                    data-coachmark-order="10" // com.text.coachMark.recent
                    data-content={this.dataRendered}>
                    <FormattedMessage id="drive.title.recent" />
                </h3>{' '}
            </>
        );
    }
}

RecentCoachMark.propTypes = {
    loginUser: PropTypes.object.isRequired,
    showFavoriteCoachMark: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(RecentCoachMark));
