import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class RequestHeader extends Component {
    closePopup = isResolved => {
        const { close } = this.props;
        close(isResolved);
    };

    render() {
        const { headerName, intl } = this.props;

        return (
            <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({ id: headerName })}</h5>
                <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={() => this.closePopup(false)}>
                    <i className="ic-20-close" />
                </a>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

RequestHeader.propTypes = {
    intl: PropTypes.object,
    close: PropTypes.func,
    headerName: PropTypes.string
};

RequestHeader.defaultProps = {
    close: () => {},
    intl: {},
    headerName: ''
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RequestHeader));

export default connected;
