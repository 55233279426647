import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Choose, If, Otherwise, When } from 'jsx-control-statements';
import _ from 'lodash';
import { summaryActions } from '../../../../redux/actions/Summary';
import { ERROR_RESULT_STATUS_LIST } from '../Common/SummaryConstant';
import { summaryStorage } from '../Common/Util/SummaryStorageUtil';

// TODO refactor
const NotificationDialog = memo(({ intl }) => {
    const { isOpen, resultStatus, files, isInProgress } = useSelector(({ summary }) => summary.notificationDialog, shallowEqual);
    const isSidePanelOpen = useSelector(({ summary }) => summary.sidePanel.isOpen);

    const [isInProgressDisabled, setIsInProgressDisabled] = useState(false);

    const dispatch = useDispatch();

    const filteredFiles = useMemo(() => {
        return _.filter(files, _.negate(_.isNil));
    }, [files]);

    const handleClickCloseButton = () => {
        dispatch(summaryActions.notificationDialog.close());
    };

    const handleClickResultButton = () => {
        dispatch(summaryActions.notificationDialog.close());
        dispatch(summaryActions.sidePanel.open());
    };

    const handleChange = e => {
        const isChecked = e.target.checked;

        setIsInProgressDisabled(isChecked);

        if (isChecked) {
            summaryStorage.inProgressNotification.disabled();
            return;
        }

        summaryStorage.inProgressNotification.enabled();
    };

    useEffect(() => {
        if (!isSidePanelOpen) {
            return;
        }

        dispatch(summaryActions.notificationDialog.close());
    }, [isSidePanelOpen]);

    return (
        <div className={classnames('overlay-layer-wrapper', isOpen && 'show')}>
            <div className="overlay-layer">
                <div className="layer-content layer-sm">
                    <div className="layer-header hd-gray">
                        <h1 className="layer-title">
                            <Choose>
                                <When condition={isInProgress}>
                                    <FormattedMessage id="drive.text.copilot.summary.notification.title.inProgress" />
                                </When>
                                <When condition={ERROR_RESULT_STATUS_LIST.includes(resultStatus)}>
                                    <FormattedMessage id="drive.text.copilot.summary.notification.title.fail" />
                                </When>
                                <Otherwise>
                                    <FormattedMessage id="drive.text.copilot.summary.notification.title.success" />
                                </Otherwise>
                            </Choose>
                        </h1>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close" />
                        </a>
                    </div>
                    <div className="layer-body">
                        <div className="box-text">
                            <p>
                                <Choose>
                                    <When condition={isInProgress}>
                                        <FormattedHTMLMessage tagName="span" id="drive.text.copilot.summary.notification.message.inProgress" />
                                    </When>
                                    <When condition={ERROR_RESULT_STATUS_LIST.includes(resultStatus)}>
                                        <FormattedMessage id="drive.text.copilot.summary.notification.fail" />
                                    </When>
                                    <Otherwise>
                                        <FormattedMessage id="drive.text.copilot.summary.notification.completed" />
                                    </Otherwise>
                                </Choose>
                            </p>
                        </div>
                        <If condition={filteredFiles.length >= 1}>
                            <div className="summary-report">
                                <div className="text">
                                    <FormattedMessage id="drive.text.copilot.summary.notification.fileName" /> :
                                </div>
                                {filteredFiles.map(file => {
                                    const { name, extensionName } = file;

                                    return (
                                        <React.Fragment key={`${name}`}>
                                            <div className="item-file">
                                                <div className="file">
                                                    <span className="file-name">{name}</span>
                                                    {extensionName && <span className="file-extension">{extensionName}</span>}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </If>
                    </div>
                    <div className="layer-footer">
                        <If condition={isInProgress}>
                            <div className="layer-footer-option">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" onChange={handleChange} checked={isInProgressDisabled} />
                                        <span className="c-label-text">
                                            <FormattedMessage id="drive.text.copilot.summary.notification.doNoSeeAgain" />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </If>
                        <div className="layer-footer-btns">
                            <a className="c-btn-link" role="button" onClick={handleClickResultButton}>
                                <Choose>
                                    <When condition={isInProgress}>
                                        <FormattedMessage id="drive.button.copilot.summary.notification.openCopilot" />
                                    </When>
                                    <Otherwise>
                                        <FormattedMessage id="drive.button.copilot.summary.notification.result" />
                                    </Otherwise>
                                </Choose>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default injectIntl(NotificationDialog);
