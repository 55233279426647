import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import OutLink from '../../../presentationals/Share/OutLink/OutLink';
import InShareView from '../../../presentationals/Share/InShare/InShareView';
import InShareChange from './InShare/InShareChange';
import InShareRest from '../../../../apis/InShareRest';
import ModalService, { alert, confirm, toast } from '../../../../utils/ModalService';
import DriveInfoRest from '../../../../apis/DriveInfoRest';
import { getAuthMap } from '../../../../utils/AuthCd';
import { DEFAULT_SHARE_AUTH_CDS } from '../../../../constants/ShareAuth';
import { axiosInstance } from '../../../../modules';
import AuthGuidePopup from '../AuthGuidePopup';

class SharePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navTab: 'in',
            isView: props.files[0].actionPolicy.viewShareList && !props.files[0].actionPolicy.manageShare && props.files.length === 1,
            isMulti: props.files.length > 1,
            owner: {},
            shareTargets: [],
            deletedShareTargets: [], // 삭제된 공유대상 목록
            isLoading: true,
            btnDisabled: true,
            canRemove: false,
            authConfig: this.getAuthConfig()
        };
        this.cancelToken = axiosInstance.CancelToken.source();
    }

    componentDidMount() {
        const { files } = this.props;
        const { isMulti } = this.state;

        // console.log('files >>>> ', files);

        let flcmLimited = false;

        if (files[0].config !== undefined) {
            for (let i = 0; i < files.length; i += 1) {
                if (files[i].config.drive.expirationFuncLimitYn === 'Y') {
                    flcmLimited = true;
                    break;
                }
            }
        } else if (files[0].drive.flcmFuncLimitYn === 'Y') {
            flcmLimited = true;
        }

        // console.log('flcmLimited >>>> ', flcmLimited);

        if (flcmLimited) {
            if (isMulti) {
                alert({ id: 'drive.alert.share.multiFlcm' });
            } else {
                alert({ id: 'drive.alert.share.monoFlcm' });
            }
        }

        if (files.length === 1) {
            // 선택된 객체 수가 1개인 경우에만 공유대상 조회
            const { objtId, onpstSectCd } = files[0].drive;
            this.getShareTargets(objtId, onpstSectCd);
        } else {
            this.setState({ isLoading: false });
        }
    }

    componentWillUnmount() {
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.remove();
        if (this.cancelToken.cancel) {
            this.cancelToken.cancel();
        }
    }

    getDefaultAuthList = () => {
        const { intl } = this.props;
        const list = _.cloneDeep(DEFAULT_SHARE_AUTH_CDS) || [];
        return list.map(authCd => {
            return {
                message: intl.formatMessage({ id: authCd.message }),
                value: authCd.value
            };
        });
    };

    getAuthConfig = () => {
        const { config, files } = this.props;
        const authMap = getAuthMap();

        // 선택된 파일/폴더 수 확인
        const objtCount = {
            FILE: 0,
            FOLDER: 0
        };

        files.forEach(file => {
            if (_.has(objtCount, file.drive.objtSectCd)) {
                objtCount[file.drive.objtSectCd] += 1;
            }
        });

        const sectCd = objtCount.FILE > 0 && objtCount.FOLDER === 0 ? 'FILE' : 'FOLDER';

        const defaultAuthCd = _.get(config, `DEFAULT.${sectCd}`);

        const authCds = _.chain(config)
            .keys()
            .sortedUniq()
            .filter(key => {
                return authMap[key] && _.isBoolean(config[key][sectCd]) && config[key][sectCd];
            })
            .map(key => {
                return { value: key, message: authMap[key] };
            })
            .value();

        return {
            list: !_.isEmpty(authCds) ? authCds : this.getDefaultAuthList(),
            defaultAuthCd,
            sectCd,
            objtCount
        };
    };

    handleClickNavTab = navTab => {
        this.setState({ navTab });
    };

    addShareTarget = newShareTargets => {
        const { owner, shareTargets, deletedShareTargets } = this.state;

        if (_.isEmpty(owner)) {
            // 소유자가 없을 경우
            this.setState({ shareTargets: [...newShareTargets, ...shareTargets], btnDisabled: false });
        } else {
            // 소유자가 있을 경우
            this.setState({ shareTargets: [owner, ...newShareTargets, ...shareTargets.slice(1)], btnDisabled: false });
        }

        // 추가한 공유대상이 삭제된 공유대상 목록에 존재하면 삭제된 공유대상 목록에서 제거
        this.setState({
            deletedShareTargets: [
                ...deletedShareTargets.filter(deletedShareTarget => {
                    return newShareTargets.findIndex(newShareTarget => newShareTarget.objtShareTgtId === deletedShareTarget.objtShareTgtId) === -1;
                })
            ]
        });
    };

    deleteShareTarget = objtShareTgtId => {
        const { shareTargets, deletedShareTargets, isMulti } = this.state;
        const itemIndex = shareTargets.findIndex(share => !share.inheritedAuth && share.objtShareTgtId === objtShareTgtId);

        // 삭제된 공유대상은 삭제된 공유대상ID 목록에 추가하여 관리
        // 단, 신규로 추가된 공유대상을 목록에서 삭제한 경우 또는 다건 공유에서 기존에 있던 공유대상을 추가했다가 x 버튼을 눌러 목록에서 삭제한 경우는 유효한 공유대상 목록에서 제거처리만 함
        if (!isMulti && shareTargets[itemIndex].evtSectCd !== 'SHAREADD') {
            shareTargets[itemIndex].evtSectCd = 'SHAREDEL';
            this.setState({ deletedShareTargets: [...deletedShareTargets, shareTargets[itemIndex]], btnDisabled: false });
        }

        this.setState({
            shareTargets: shareTargets.filter((share, index) => {
                return index !== itemIndex;
            }),
            isLoading: false
        });
    };

    changeShareTarget = (objtShareTgtId, newAuthCd, newLwrDeptIcluYn, newEvtSectCd) => {
        const { shareTargets, deletedShareTargets } = this.state;

        // 추가 또는 수정된 공유대상이 삭제된 공유대상 목록에 존재하면 삭제된 공유대상 목록에서 제거
        const deletedItemIndex = deletedShareTargets.findIndex(deletedShareTarget => deletedShareTarget.objtShareTgtId === objtShareTgtId);

        if (deletedItemIndex > -1) {
            this.setState({ deletedShareTargets: [...deletedShareTargets.slice(0, deletedItemIndex), ...deletedShareTargets.slice(deletedItemIndex + 1)] });
        }

        const itemIndex = shareTargets.findIndex(share => share.objtShareTgtId === objtShareTgtId);
        const shareTarget = _.cloneDeep(shareTargets[itemIndex]);
        shareTarget.authCd = newAuthCd;
        shareTarget.lwrDeptIcluYn = newLwrDeptIcluYn;
        shareTarget.evtSectCd = newEvtSectCd;

        if (itemIndex > -1) {
            this.setState({ shareTargets: [...shareTargets.slice(0, itemIndex), shareTarget, ...shareTargets.slice(itemIndex + 1)], btnDisabled: false });
        }
    };

    closeUpperPopup = () => {
        const { close } = this.props;
        close(true);
    };

    getShareTargets = (objtId, onpstSectCd) => {
        const { close, userId } = this.props;

        InShareRest.getShareTargets(objtId).then(
            result => {
                const { data, resultCode, message } = result;

                if (resultCode === 403 && message === 'IOFFICE_FORBIDDEN') {
                    alert({ id: 'drive.toast.linkCopy.noAuth' });
                    close(true);
                    return;
                }
                if (resultCode !== 200) {
                    alert({ id: 'drive.alert.share.viewFailMessage' });
                    close(true);
                    return;
                }

                const allShareTargets = this.getAllShareTargets(data.owner, data.shareTargets, data.inheritedShareTargets);

                // 1. 워크크룹 소속인 경우
                // 2. 워크그룹 소속이 아닌 경우, 소유자가 아닐 때만 내 권한 제거 버튼에 대한 validaion을 체크
                // console.log('onpstSectCd ', onpstSectCd, 'userId ', userId);
                const isWorkgroup = onpstSectCd === 'SYSTFOLDER';
                const isNotOwner = data.owner && data.owner.objtShareTgtId !== userId;
                if (isWorkgroup || isNotOwner) {
                    this.getCanRemove(data.shareTargets);
                }

                this.setState({
                    owner: data.owner,
                    shareTargets: allShareTargets,
                    isLoading: false
                });
            },
            () => {
                alert({ id: 'drive.alert.share.viewFailMessage' });
                close(true);
            }
        );
    };

    isRootWorkgroup = () => {
        const { files } = this.props;
        if (files.length > 1) return false;

        const { drive } = files[0] || {};
        return drive.onpstSectCd === 'SYSTFOLDER' && drive.objtId === drive.onpstId;
    };

    getSaveMessage = (isRemoveOwnAuth = false) => {
        const { config, userId } = this.props;
        const { shareTargets, authConfig = {} } = this.state;
        const { objtCount } = authConfig;

        // 내 권한 제거인 경우(isRemoveOwnAuth === true), 저장 가능 여부 체크를 위해 공유자 중 현재 사용자는 제외
        // 아닌 경우(isRemoveOwnAuth === false), 공유자 전체 그대로
        const filteredShareTargets = isRemoveOwnAuth ? shareTargets.filter(({ objtShareTgtId }) => objtShareTgtId !== userId) : shareTargets;

        // 상위 폴더의 권한은 제외
        const selectedAuthCds = _.chain(filteredShareTargets)
            .filter(target => !target.inheritedAuth)
            .map('authCd')
            .uniq()
            .filter(authCd => !_.isEmpty(config[authCd]))
            .value();

        const sectCds = _.chain(objtCount)
            .keys()
            .filter(sectCd => objtCount[sectCd] > 0)
            .value();

        let check;
        let hasCanShareManageAuthCd = false;

        _.forEach(selectedAuthCds, authCd => {
            const auth = config[authCd];
            const { canShareManage = false } = auth;

            hasCanShareManageAuthCd = hasCanShareManageAuthCd || canShareManage;

            const needCheckSectCd = _.find(sectCds, sectCd => {
                return !_.isBoolean(auth[sectCd]) || !auth[sectCd];
            });

            if (check === undefined && needCheckSectCd !== undefined) {
                check = auth[needCheckSectCd];
            }
        });

        // 워크그룹이고, 공유자 관리 권한이 한명도 없는 경우.
        if (this.isRootWorkgroup() && !hasCanShareManageAuthCd) {
            return {
                type: 'alert',
                message: 'drive.alert.share.needCanShareManageUser'
            };
        }

        // false
        if (_.isBoolean(check) && !check) {
            return {
                type: 'alert',
                message: 'drive.alert.share.saveFailMessage'
            };
        }
        // object
        if (_.isObject(check) && !_.isEmpty(check)) {
            return _.pick(check, ['type', 'message']);
        }

        // 정상 처리
        return {
            type: 'confirm',
            message: 'drive.confirm.share.saveMessage'
        };
    };

    saveShare = () => {
        const { files, close, isExplorer } = this.props;
        const { shareTargets, deletedShareTargets, isMulti } = this.state;

        let cpgsLimited = false;

        if (files[0].config !== undefined) {
            for (let i = 0; i < files.length; i += 1) {
                if (files[i].config.drive.complianceFuncLimitYn === 'Y') {
                    cpgsLimited = true;
                    break;
                }
            }
        } else if (files[0].drive.cpgsFuncLimitYn === 'Y') {
            cpgsLimited = true;
        }

        const hasDelete = !_.isEmpty(deletedShareTargets);
        const hasUpdate = _.some(shareTargets, share => !_.isEmpty(share.evtSectCd));

        // console.log('cpgsLimited >>>> ', cpgsLimited);

        // 삭제 없이 추가/수정만 있는 경우
        if (cpgsLimited && hasUpdate) {
            if (isMulti) {
                alert({ id: 'drive.alert.share.multiCpgs' });
            } else {
                alert({ id: 'drive.alert.share.monoCpgs' });
            }

            return;
        }

        // 변경된 데이터가 없을 경우 그대로 팝업 종료
        if (!hasUpdate && !hasDelete) {
            close(false);
            return;
        }

        // 변경된 데이터가 있을 경우 저장 처리
        // 공유대상 일괄변경의 경우 파일이 1개 이상 선택되었고, 공유대상 중 '편집가능' 권한으로 셋팅된 경우 메시지 처리
        const saveMessage = this.getSaveMessage();

        if (saveMessage.type === 'alert') {
            alert({ id: saveMessage.message });
            return;
        }

        confirm({ id: saveMessage.message }).then(
            () => {
                InShareRest.saveShareTargets(
                    files.map(file => file.drive.objtId),
                    [...shareTargets.filter(share => !share.inheritedAuth && !_.isEmpty(share.evtSectCd) && share.authCd !== 'U80OWN'), ...deletedShareTargets],
                    isExplorer
                ).then(
                    result => {
                        // NonCompl. 검출
                        if (result.resultCode === 20504) {
                            if (isMulti) {
                                alert({ id: 'drive.alert.share.multiCpgs' });
                            } else {
                                alert({ id: 'drive.alert.share.monoCpgs' });
                            }
                            close(true);
                            return;
                        }

                        // 파일명 길이 초과
                        if (result.resultCode === 20110) {
                            alert({ id: 'drive.alert.share.fullPath.tooLong' });
                            close(true);
                            return;
                        }

                        if (result.resultCode !== 200) {
                            alert({ id: 'drive.alert.share.saveFailMessage' });
                            close(true);
                            return;
                        }

                        toast({ id: isMulti ? 'drive.toast.share.saveSuccessMultiShare' : 'drive.toast.share.saveSuccessShare' });

                        // 탐색기를 통한 공유 팝업 오픈 후 저장 성공시 toast메시지 노출을 위해 1500ms 지연 후 팝업 닫기
                        setTimeout(
                            () => {
                                close(true);
                            },
                            isExplorer ? 1500 : 0
                        );
                    },
                    () => {
                        alert({ id: 'drive.alert.share.saveFailMessage' });
                        close(true);
                    }
                );
            },
            () => false
        );
    };

    getCancelMessage = () => {
        const { shareTargets, deletedShareTargets, isMulti } = this.state;

        // 변경된 데이터가 없을 경우 그대로 팝업 종료
        if (
            shareTargets.every(share => {
                return _.isEmpty(share.evtSectCd);
            }) &&
            deletedShareTargets.length === 0
        ) {
            return '';
        }
        return isMulti ? 'drive.confirm.share.cancelMultiShare' : 'drive.alert.share.cancelShare';
    };

    cancelShare = () => {
        const { close } = this.props;

        const message = this.getCancelMessage();

        // 변경된 데이터 없는 경우
        if (message.length < 1) {
            close(false);
            return;
        }

        confirm({ id: message }).then(() => close(false), () => false);
    };

    confirm = () => {
        const { close } = this.props;
        close(false);
    };

    getAllShareTargets = (owner, shareTargets, inheritedShareTargets) => {
        const allShareTargets = _.concat(shareTargets, inheritedShareTargets);
        if (!_.isEmpty(owner)) allShareTargets.unshift(owner);

        return allShareTargets;
    };

    getCanRemove = shareTargets => {
        const { userId, files } = this.props;

        // 공유대상 목록에 개인으로 직접 공유받은 경우에 한해서 내 권한 제거 버튼 활성화 (상속받은 공유권한 이거나 부서로 공유받았을 경우 비활성화)
        if (_.find(shareTargets, shareTarget => shareTarget.objtShareTgtId === userId)) {
            // 만료된 파일인 경우는 비활성화
            if (files[0].drive.objtSectCd === 'FILE') {
                DriveInfoRest.getFolderFileInfo({ objtId: files[0].drive.objtId, onpstId: files[0].drive.onpstId }).then(result => {
                    if (result.data.flcmFuncLimitYn !== 'Y') {
                        this.setState({
                            canRemove: true
                        });
                    }
                });
            } else {
                this.setState({
                    canRemove: true
                });
            }
        }
    };

    removeOwnAuthFn = () => {
        const { files, close, isExplorer, userId } = this.props;
        const { shareTargets } = this.state;

        // 최상위 권한을 보유한 사용자가 "내 권한 제거"를 수행하려는 사용자인 경우, 제거 방지
        // 기존 저장 가능 여부 체크 로직 사용
        const saveMessage = this.getSaveMessage(true);

        if (saveMessage.type === 'alert') {
            alert({ id: saveMessage.message });
            return;
        }

        if (_.find(shareTargets, shareTarget => shareTarget.objtShareTgtId === userId)) {
            const confirmMessage = 'drive.confirm.removeOwnAuth';

            const shareTarget = _.find(shareTargets, target => target.objtShareTgtId === userId);

            confirm({ id: confirmMessage }).then(
                () => {
                    shareTarget.evtSectCd = 'SHAREDEL';

                    InShareRest.removeOwnAuth(files.map(file => file.drive.objtId), [shareTarget], isExplorer).then(
                        result => {
                            if (result.resultCode !== 200) {
                                alert({ id: 'drive.alert.failRemoveOwnAuth' });
                                close(true);
                                return;
                            }

                            toast({ id: 'drive.toast.successRemoveOwnAuth' });

                            // 탐색기를 통한 공유 팝업 오픈 후 저장 성공시 toast메시지 노출을 위해 1500ms 지연 후 팝업 닫기
                            setTimeout(
                                () => {
                                    close(true);
                                },
                                isExplorer ? 1500 : 0
                            );
                        },
                        () => {
                            alert({ id: 'drive.alert.failRemoveOwnAuth' });
                            close(true);
                        }
                    );
                },
                () => false
            );
        }
    };

    getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    openAuthGuidePopup = () => {
        ModalService()
            .largePopup(<AuthGuidePopup />)
            .then(() => true, () => false);
    };

    render() {
        const { navTab, isView, isMulti, shareTargets, isLoading, btnDisabled, canRemove, authConfig } = this.state;
        const { files, close, intl, webViewerYn } = this.props;

        let renderPage;
        let footer;

        if (navTab === 'in') {
            if (isView) {
                renderPage = (
                    <InShareView
                        files={files}
                        shareTargets={shareTargets}
                        isLoading={isLoading}
                        removeOwnAuthFn={this.removeOwnAuthFn}
                        canRemove={canRemove}
                        getFileName={this.getFileName}
                        getExtension={this.getExtension}
                        webViewerYn={webViewerYn}
                        config={authConfig}
                        cancelToken={this.cancelToken}
                        closePopup={this.closeUpperPopup}
                    />
                );
            } else {
                renderPage = (
                    <InShareChange
                        files={files}
                        isMulti={isMulti}
                        addShareTargetFn={this.addShareTarget}
                        deleteShareTargetFn={this.deleteShareTarget}
                        changeShareTargetFn={this.changeShareTarget}
                        closePopup={this.closeUpperPopup}
                        shareTargets={shareTargets}
                        isLoading={isLoading}
                        removeOwnAuthFn={this.removeOwnAuthFn}
                        canRemove={canRemove}
                        getFileName={this.getFileName}
                        getExtension={this.getExtension}
                        webViewerYn={webViewerYn}
                        config={authConfig}
                        cancelToken={this.cancelToken}
                    />
                );
            }

            footer = (
                <div className="modal-footer-btns">
                    <a className={`btn btn-lg ${isView ? 'btn-primary' : 'btn-secondary'}`} role="button" data-dismiss="modal" onClick={isView ? this.confirm : this.cancelShare}>
                        <span className="btn-text">{isView ? intl.formatMessage({ id: 'com.button.ok' }) : intl.formatMessage({ id: 'com.cancel' })}</span>
                    </a>
                    <If condition={!isView}>
                        <a className={`btn btn-lg btn-primary${btnDisabled ? ' disabled' : ''}`} role="button" onClick={this.saveShare}>
                            <span className="btn-text">{intl.formatMessage({ id: 'com.save' })}</span>
                        </a>
                    </If>
                </div>
            );
        } else {
            renderPage = <OutLink files={files} />;

            footer = (
                <div className="modal-footer-btns">
                    <a className="btn btn-lg btn-primary" role="button" data-dismiss="modal" onClick={this.confirm}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.button.ok' })}</span>
                    </a>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="modal-header">
                    <If condition={!isView && !isMulti && files[0].actionPolicy.createPublicLink}>
                        <nav>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${navTab === 'in' ? 'active' : ''}`}
                                        role="button"
                                        onClick={e => {
                                            this.handleClickNavTab('in');
                                            e.stopPropagation();
                                        }}>
                                        {intl.formatMessage({ id: 'drive.tab.share.inShare' })}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${navTab === 'out' ? 'active' : ''}`}
                                        role="button"
                                        onClick={e => {
                                            this.handleClickNavTab('out');
                                            e.stopPropagation();
                                        }}>
                                        {intl.formatMessage({ id: 'drive.tab.share.outShare' })}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </If>
                    <If condition={isView || isMulti || !files[0].actionPolicy.createPublicLink}>
                        <h5 className="modal-title">{intl.formatMessage({ id: isMulti ? 'drive.tab.share.inShareMulti' : 'drive.tab.share.inShare' })}</h5>
                    </If>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={intl.formatMessage({ id: 'com.close' })}
                        data-dismiss="modal"
                        onClick={e => {
                            if (isView) {
                                close(false);
                                e.stopPropagation();
                            } else {
                                this.cancelShare();
                            }
                        }}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <If condition={!isView}>
                    <div className="modal-info">
                        <div className="info-msg">
                            <i className="ic-16-info" />
                            <If condition={isMulti}>
                                <FormattedHTMLMessage id="drive.guideText.share.editorGuideMessage" tagName="span" />
                            </If>
                            <If condition={!isMulti}>
                                <FormattedMessage id={`drive.guideText.share.editorGuideMessageFor${files[0].drive.objtSectCd === 'FILE' ? 'File' : 'Folder'}`} />
                            </If>
                            <a className="btn-link has-coachmark" role="button" onClick={this.openAuthGuidePopup}>
                                <FormattedMessage id="drive.title.authorityguide.authTitle" />
                            </a>
                        </div>
                    </div>
                </If>
                {renderPage}
                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    {footer}
                </div>
            </React.Fragment>
        );
    }
}

SharePopup.propTypes = {
    files: PropTypes.array,
    close: PropTypes.func,
    intl: PropTypes.object,
    isExplorer: PropTypes.bool,
    userId: PropTypes.string.isRequired,
    webViewerYn: PropTypes.string,
    config: PropTypes.object
};

SharePopup.defaultProps = {
    files: [],
    close: _.noop,
    intl: {},
    isExplorer: false,
    webViewerYn: 'N',
    config: {}
};

const mapStateToProps = state => ({
    userId: state.auth.user.id
});

export default injectIntl(connect(mapStateToProps)(SharePopup));
