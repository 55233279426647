import React, { Component } from 'react';
import { If } from 'jsx-control-statements';
import { FormattedMessage, injectIntl } from 'react-intl';
import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CoachMarkConstants from 'constants/CoachMark';
import InLinkRest from '../../../../../apis/InLinkRest';
import { popup, toast } from '../../../../../utils/ModalService';
import InLinkSetting from './InLinkSetting';
import clipboard from '../../../../../utils/Clipboard';
import AuthService from '../../../../../utils/AuthService';
import Tooltips from '../../../../presentationals/Tooltips';

class InLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incomLinkUrl: '',
            incomLinkUnautPermCd: '',
            copyLinkCoachMark: 'N'
        };
    }

    componentDidMount() {
        const { files, showInLinkCoachMark, hideInLinkCoachMark } = this.props;
        this.setInComLinkUrl();
        const ccp = $('.coachmark-conts').parents('.popover');
        ccp.remove();

        const { setInLink } = files[0].actionPolicy;
        if (setInLink) {
            showInLinkCoachMark();
        } else if (!_.isNil(hideInLinkCoachMark) && _.isFunction(hideInLinkCoachMark)) {
            hideInLinkCoachMark();
        }
    }

    setInComLinkUrl() {
        const { files, closeUpperPopup } = this.props;
        const { objtId, onpstId } = files[0].drive;

        InLinkRest.getIncomLinkUrl(objtId, onpstId).then(
            result => {
                this.setState({ incomLinkUrl: result.incomLinkUrl, incomLinkUnautPermCd: result.incomLinkUnautPermCd });
            },
            error => {
                console.log(error);
                closeUpperPopup();
                toast('drive.toast.linkCopy.noAuth');
            }
        );
    }

    openInLinkSetting = () => {
        const { files, webViewerYn } = this.props;
        const { incomLinkUnautPermCd } = this.state;

        popup(<InLinkSetting files={files} incomLinkUnautPermCd={incomLinkUnautPermCd} webViewerYn={webViewerYn} />).then(data => {
            if (data) {
                this.setState({ incomLinkUnautPermCd: data });
            }
        });
    };

    copyIncomLinkUrl = () => {
        const { incomLinkUrl } = this.state;

        if (clipboard.set(incomLinkUrl)) {
            toast('drive.toast.linkCopy.complete');
        } else {
            toast('drive.toast.linkCopy.fail');
        }
    };

    hide = mode => {
        $('body > div.coachmark-backdrop').remove();

        if (mode === 'stop') {
            const ccp = $('.coachmark-conts').parents('.popover');
            ccp.remove();
            this.setNLocalStorage();
        }
    };

    showNext = type => {
        const { showShareInsertCoachMark, hideInLinkCoachMark } = this.props;

        this.hide('next');

        if (type === 'inlink') {
            // const ccp = $('.coachmark-conts').parents('.popover');
            // ccp.animate({ opacity: 0 }, 'fast', 'swing');
            // $('[class*="bs-popover-right"]').attr('style', 'z-index: 1049; opacity: 0; display: none;');

            hideInLinkCoachMark();
            $('#conts-inlink')
                .parents('.popover')
                .attr('style', 'z-index: 1049; opacity: 0; display: none;');

            this.setState({ copyLinkCoachMark: 'Y' });
            this.popoverCopyLinkCoachmark();
        }
        if (type === 'copylink') {
            showShareInsertCoachMark();
            this.setState({ copyLinkCoachMark: 'N' });
        }
    };

    setNLocalStorage = () => {
        const id = AuthService.getUserId();
        localStorage.setItem(`${CoachMarkConstants.COACHMARK_SHARE}${id}`, 'N');
    };

    popoverCopyLinkCoachmark = () => {
        const { hideShareInsertCoachMark } = this.props;

        this.coachmarkOwner = $('#copylink-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: false
        });

        if (document.getElementsByClassName('coachmark-backdrop').length === 0) {
            const coachBg = $('<div class="coachmark-backdrop"></div>');
            $('body').append(coachBg);

            coachBg.click(() => {
                const ccp = $('.coachmark-conts').parents('.popover');
                ccp.remove();
                hideShareInsertCoachMark();
                coachBg.remove();
            });
        }

        this.popover.addClass('coachmark').popover('show');

        $('#conts-copylink')
            .parents('.popover')
            .attr('style', 'z-index: 1060;');
        $('#conts-copylink')
            .parents('.popover')
            .addClass('coachmark');

        if (document.getElementById('stop-copylink') !== null) {
            document.getElementById('stop-copylink').addEventListener('click', () => this.hide('stop'));
        }

        if (document.getElementById('next-copylink') !== null) {
            document.getElementById('next-copylink').addEventListener('click', () => this.showNext('copylink'));
        }
    };

    popoverInlinkCoachmark = () => {
        const { hideInLinkCoachMark, hideShareInsertCoachMark } = this.props;

        this.coachmarkOwner = $('#inlink-coachmark');
        this.popover = this.coachmarkOwner.popover({
            html: true,
            trigger: 'manual',
            animation: false
        });

        if (document.getElementsByClassName('coachmark-backdrop').length === 0) {
            const coachBg = $('<div class="coachmark-backdrop"></div>');
            $('body').append(coachBg);

            coachBg.click(() => {
                const ccp = $('.coachmark-conts').parents('.popover');
                ccp.remove();
                hideInLinkCoachMark();
                hideShareInsertCoachMark();
                coachBg.remove();
            });
        }

        this.popover.addClass('coachmark').popover('show');

        $('#conts-inlink')
            .parents('.popover')
            .attr('style', 'z-index: 1060;');
        $('#conts-inlink')
            .parents('.popover')
            .addClass('coachmark');

        if (document.getElementById('stop-inlink') !== null) {
            document.getElementById('stop-inlink').addEventListener('click', () => this.hide('stop'));
        }

        if (document.getElementById('next-inlink') !== null) {
            document.getElementById('next-inlink').addEventListener('click', () => this.showNext('inlink'));
        }
    };

    render() {
        const { incomLinkUrl, incomLinkUnautPermCd, copyLinkCoachMark } = this.state;
        const { files, intl, inLinkCoachMark } = this.props;
        const { setInLink } = files[0].actionPolicy;
        const { objtSectCd } = files[0].drive;
        const tooltipMsg = objtSectCd === 'FILE' ? intl.formatMessage({ id: 'drive.tooltip.inLink.fileGuide' }) : intl.formatMessage({ id: 'drive.tooltip.inLink.folderGuide' });
        let guideMsg = '';
        switch (incomLinkUnautPermCd) {
            case 'unauth':
                guideMsg = intl.formatMessage({ id: 'drive.text.inLink.settingUnauth' });
                break;
            case 'view':
                guideMsg = intl.formatMessage({ id: 'drive.text.inLink.settingView' });
                break;
            case 'download':
                guideMsg = intl.formatMessage({ id: 'drive.text.inLink.settingDownload' });
                break;
            default:
                break;
        }

        const id = AuthService.getUserId();
        const showShareCoachMark = localStorage.getItem(`${CoachMarkConstants.COACHMARK_SHARE}${id}`);

        // console.log('showShareCoachMark  ', showShareCoachMark, 'inLinkCoachMark ', inLinkCoachMark);
        if (showShareCoachMark !== 'N' && inLinkCoachMark === 'Y') {
            this.popoverInlinkCoachmark();
        }

        if (copyLinkCoachMark === 'N') {
            $('#conts-copylink')
                .parents('.popover')
                .attr('style', 'z-index: 1049; opacity: 0; display: none;');
        }

        const nomoreMsg = intl.formatMessage({ id: 'com.text.coachMark.stop' });
        const nextMsg = intl.formatMessage({ id: 'com.text.next' });
        const inlinkCoachMarkMsg = intl.formatMessage({ id: 'drive.text.coachMark.linkSetting' });
        const linkCopyCoachMarkMsg = intl.formatMessage({ id: 'drive.text.coachMark.linkCopy' });
        const inlinkCoachMarkHtml = `<div class='coachmark-conts' id='conts-inlink'><p>${inlinkCoachMarkMsg}</p>
            <div class='btns'><a class='coachmark-end' id='stop-inlink'>${nomoreMsg}</a><a class='coachmark-next' id='next-inlink'>${nextMsg}</a></div></div>`;
        const linkCopyCoachMarkHtml = `<div class='coachmark-conts' id='conts-copylink'><p>${linkCopyCoachMarkMsg}</p>
            <div class='btns'><a class='coachmark-end' id='stop-copylink'>${nomoreMsg}</a><a class='coachmark-next' id='next-copylink'>${nextMsg}</a></div></div>`;

        return (
            <React.Fragment>
                <dl className="dt-divider">
                    <dt>
                        <FormattedMessage id="drive.title.inLink" />
                        <If condition={objtSectCd === 'FOLDER'}>
                            <span id="linkDescriptionTooltip" className="btn-ic-nor btn-sm">
                                <i className="ic-16-help" />
                            </span>
                            <Tooltips targetId="linkDescriptionTooltip" placement="right">
                                <div className="guide">{tooltipMsg}</div>
                            </Tooltips>
                        </If>
                    </dt>
                    <If condition={objtSectCd === 'FILE'}>
                        <dd>
                            {guideMsg}
                            <span id="linkDescriptionTooltip" className="btn-ic-nor btn-sm">
                                <i className="ic-16-help" />
                            </span>
                            <Tooltips targetId="linkDescriptionTooltip" placement="right">
                                <div className="guide">{tooltipMsg}</div>
                            </Tooltips>
                            <If condition={setInLink}>
                                {showShareCoachMark !== 'N' && inLinkCoachMark === 'Y' ? (
                                    <a
                                        className="btn-link has-coachmark"
                                        id="inlink-coachmark"
                                        role="button"
                                        data-placement="right"
                                        data-coachmark-order="110"
                                        data-content={inlinkCoachMarkHtml}
                                        onClick={e => {
                                            this.openInLinkSetting();
                                            e.stopPropagation();
                                        }}>
                                        <FormattedMessage id="drive.button.inLink.setting" />
                                    </a>
                                ) : (
                                    <a
                                        className="btn-link has-coachmark"
                                        role="button"
                                        onClick={e => {
                                            this.openInLinkSetting();
                                            e.stopPropagation();
                                        }}>
                                        <FormattedMessage id="drive.button.inLink.setting" />
                                    </a>
                                )}
                            </If>
                        </dd>
                    </If>
                </dl>

                <div className="forms-container">
                    <div className="box-input-group readonly">
                        <input className="form-control" type="text" placeholder="" value={incomLinkUrl} readOnly={true} />
                        <a className="btn-ic-nor btn-func-clear" role="button" title="지우기">
                            <i className="ic-16-clear" />
                        </a>
                    </div>
                    <a
                        className="btn-ic-line has-coachmark"
                        id="copylink-coachmark"
                        role="button"
                        data-placement="bottom"
                        data-coachmark-order="120"
                        data-content={linkCopyCoachMarkHtml}
                        onClick={e => {
                            this.copyIncomLinkUrl();
                            e.stopPropagation();
                        }}>
                        <i className="ic-20-copyfile" />
                        <span className="btn-text">
                            <FormattedMessage id="drive.button.inLink.linkCopy" />
                        </span>
                    </a>
                </div>
            </React.Fragment>
        );
    }
}

InLink.propTypes = {
    files: PropTypes.array,
    intl: PropTypes.object,
    showShareInsertCoachMark: PropTypes.func,
    showInLinkCoachMark: PropTypes.func,
    hideInLinkCoachMark: PropTypes.func,
    hideShareInsertCoachMark: PropTypes.func,
    closeUpperPopup: PropTypes.func,
    inLinkCoachMark: PropTypes.string,
    webViewerYn: PropTypes.string
};

InLink.defaultProps = {
    files: [],
    intl: {},
    showShareInsertCoachMark: _.noop(),
    showInLinkCoachMark: _.noop(),
    hideInLinkCoachMark: _.noop(),
    hideShareInsertCoachMark: _.noop(),
    closeUpperPopup: _.noop(),
    inLinkCoachMark: 'N',
    webViewerYn: 'N'
};

export default injectIntl(InLink);
