import { $http } from 'modules/index';
import _ from 'lodash';

const preFix = '/drive/v1';

class PcclientRest {
    static getDomainUrl() {
        return $http.get(`${preFix}/pcclient/domain`);
    }

    static getHelpCenterTel() {
        const code = 'EFL_COM_HELPCENTER_TEL';
        return $http.get(`/common/v1/configs/cfg/${code}`).then(res => _.get(res, 'data.cfgVal'));
    }
}

export default PcclientRest;
