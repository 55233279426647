import React from 'react';
import { object } from 'prop-types';
import { Choose, When } from 'jsx-control-statements';
import DateConverter from '../../../../utils/DateConverter';
import TypeName from './TypeName';
import SharePeople from './SharePeople';

const HistoryShareType = props => {
    const { history } = props;
    const occuDate = [DateConverter.convert(history.evtOccuDt)];

    return (
        <React.Fragment>
            <dd>
                <span className="btn-ic-nor">
                    <i className="ic-20-share" />
                </span>
                <TypeName type={history.evtSectCd} objtSectCd={history.objtSectCd} />
                <div className="dd-detail history-what">
                    <div className="g-inline-flex text-link">
                        <SharePeople shareInfo={history.shareInfo} />
                    </div>
                </div>
                <div className="dd-detail history-who">
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={history.evtReqstrNm}>
                            {history.evtReqstrNm}
                        </div>
                        <Choose>
                            <When condition={history.objtSectCd === 'FILE'}>
                                <div className="next divider">
                                    <span className="version" title={history.fileVerSno}>
                                        v{history.fileVerSno}
                                    </span>
                                </div>
                            </When>
                        </Choose>
                        <div className="next divider">{occuDate}</div>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    );
};

HistoryShareType.propTypes = {
    history: object
};

HistoryShareType.defaultProps = {
    history: {}
};

export default HistoryShareType;
