import React from 'react';
import CustomModal from 'containers/CustomModal';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import DownloadRest from '../../apis/DownloadRest';
import SettingsRest from '../../apis/SettingsRest';
import { auth, globalPopup } from '../../redux/actions';
import BlobFile from '../../utils/Fileuploader/BlobFile';
import FileUploader from '../../utils/Fileuploader/FileUploader';
import ProfileEdit from '../presentationals/ProfileEdit';

const dataURItoBlob = param => {
    if (typeof param !== 'string') {
        throw new Error('Invalid argument: param (dataURI) must be a string');
    }
    let dataURI = param;
    dataURI = dataURI.split(',');
    const type = dataURI[0].split(':')[1].split(';')[0];
    const byteString = atob(dataURI[1]);
    const byteStringLength = byteString.length;
    const arrayBuffer = new ArrayBuffer(byteStringLength);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStringLength; i += 1) {
        intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([intArray], {
        type
    });

    const now = new Date();
    blob.lastModifiedDate = now; // IE 인 경우 사용
    blob.lastModified = now; // IE가 아닌 경우 사용

    return blob;
};

// 자릿수 공백을 0으로 채우는 함수
const zerofill = (paramNum, len) => {
    let out = '';
    let num = paramNum;
    if (typeof num === 'number') num = num.toString();
    if (num.length >= len) {
        return num;
    }

    for (let i = 0; i < len - num.length; i += 1) out += '0';
    return out + num;
};

const getNowDate = () => {
    const d = new Date();
    return d.getFullYear() + zerofill(d.getMonth() + 1, 2) + zerofill(d.getDate(), 2);
};

class ProfileEditPopup extends React.Component {
    constructor(props) {
        super(props);
        this.uploader = null;
        this.state = {
            source: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen, profileFileId, userId } = this.props;
        if (isOpen !== prevProps.isOpen) {
            this.getProfileImage(profileFileId, userId);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        const fileuploader = new FileUploader({ type: 'profile' });

        fileuploader.onSuccessItem = fileItem => {
            this.uploader.clearQueue();
            const { setProfileFileId, closePopup, userId } = this.props;
            SettingsRest.updateProfileFile(userId, fileItem.result.data.objtId);
            setProfileFileId(fileItem.result.data.objtId);
            closePopup();
            if (this.isComponentMounted) this.setState({ source: '' });
        };

        fileuploader.onErrorItem = () => {
            this.uploader.clearQueue();
        };

        this.uploader = fileuploader;

        const { profileFileId, userId } = this.props;
        this.getProfileImage(profileFileId, userId);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    handleCancel = () => {
        const { closePopup } = this.props;
        closePopup();
        this.setState({ source: '' });
    };

    handleSave = source => {
        const { profileFileId } = this.props;
        const blob = dataURItoBlob(source);
        const blobFile = new BlobFile(blob, getNowDate().concat('.png'));

        //  기존 profileFileId가 있으면, objtId를 metadata 세팅(버전업)
        if (!_.isEmpty(profileFileId)) {
            this.uploader.addToQueue(blobFile, { metadata: { objtId: profileFileId } });
            // 기존 profileFileId가 있으면, objtId를 metadata 넣을 필요 없음(업로드)
        } else {
            this.uploader.addToQueue(blobFile);
        }
    };

    getProfileImage = async (objtId, userId) => {
        const param = { objtId, onpstId: userId };
        if (_.isEmpty(objtId)) {
            if (this.isComponentMounted) this.setState({ source: '' });
        } else {
            const response = await DownloadRest.downloadProfileImage(param);
            const image = btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            let source = '';
            if (image !== '') source = `data:image/png;base64,${image}`;
            if (this.isComponentMounted) this.setState({ source });
        }
    };

    onNotImageFile = () => {
        const { openAlert } = this.props;
        openAlert({ id: 'user.setting.profile.guide.allowedExtensions' });
    };

    render() {
        const { source } = this.state;
        const { isOpen, userName, intl, profileFileId, userId } = this.props;

        return (
            <CustomModal isOpen={isOpen} fade={false} backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="user.setting.profile.editProfilePhto" />
                    </h5>
                    <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} data-dismiss="modal" onClick={this.handleCancel}>
                        <i className="ic-20-close" />
                    </a>
                </div>
                <ModalBody>
                    <div className="darkroom-wrapper">
                        <ProfileEdit
                            profileFileId={profileFileId}
                            source={source}
                            cancel={this.handleCancel}
                            save={this.handleSave}
                            onNotImageFile={this.onNotImageFile}
                            userId={userId}
                            userName={userName}
                        />
                    </div>
                </ModalBody>
            </CustomModal>
        );
    }
}

ProfileEditPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    profileFileId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    setProfileFileId: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.profileEdit.isOpen,
    profileFileId: state.auth.user.profile.fileId || '',
    userId: state.auth.user.id,
    userName: state.auth.user.name
});

const mapDispatchToProps = {
    closePopup: globalPopup.closeProfileEdit,
    setProfileFileId: auth.setProfileFileId,
    openAlert: globalPopup.openAlert
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProfileEditPopup)
);
