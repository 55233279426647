import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { If } from 'jsx-control-statements';
import moment from 'moment';
import { alert, toast } from '../../../../utils/ModalService';
import SingleDatePickerWrapper from '../../../presentationals/SingleDatePickerWrapper';
import FileLifeCycleRest from '../../../../apis/FileLifeCycleRest';
import RequestHeader from './RequestHeader';
import RequestBodyFiles from './RequestBodyFiles';
import RequestBodyApprover from './RequestBodyApprover';
import RequestBodyReqRsn from './RequestBodyReqRsn';
import RequestFooter from './RequestFooter';
import FlcmUtil from '../../../../utils/FlcmUtil';
import ExpirationDateSelector from '../RetentionPeriod/ExpirationDateSelector';

class RequestExceptionPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileIds: [],
            expiredDate: {},
            target: '',
            reason: '',
            reasonKey: '',
            reasonPresets: [],
            reasonMinLength: 5,
            isInvalid: false,
            isValidForm: false,
            changeByCalendar: false
        };
        this.maxLength = 512;
    }

    componentDidMount() {
        const { user } = this.props;
        const { initialExpiredDate, maxExpiredDate, minExpiredDate } = FlcmUtil.getExpiredDateInfos(user);
        this.initialExpiredDate = initialExpiredDate;
        this.maxExpiredDate = maxExpiredDate;
        this.minExpiredDate = minExpiredDate;
        this.setState({
            expiredDate: this.initialExpiredDate.format('YYYY-MM-DD')
        });
        this.initRequestData();
        this.getReasonPresets();
    }

    initRequestData = () => {
        this.setState({ isValidForm: false });
    };

    setFileData = fileIds => {
        this.setState({ fileIds });
    };

    onChangeExpiredDate = date => {
        this.setState({ expiredDate: date.format('YYYY-MM-DD') }, this.checkValidation);
    };

    onChangeTarget = value => {
        this.setState({ target: value }, this.checkValidation);
    };

    onChangeReason(reason = '') {
        const { reasonMinLength } = this.state;
        this.setState({ reason }, () => {
            if (reason.trim().length < reasonMinLength) {
                this.setState({ isInvalid: true }, this.checkValidation);
            } else {
                this.setState({ isInvalid: false }, this.checkValidation);
            }
        });
    }

    onChangeReasonKey(reasonKey) {
        const { reasonPresets = [], reason = '' } = this.state;
        const picked = _.find(reasonPresets, preset => {
            return preset.value === reasonKey;
        });
        const pickedMinLength = picked ? picked.minLength : 5;
        const pickedReason = picked ? picked.reason : reason;

        this.setState({ reasonKey, reason: pickedReason, reasonMinLength: pickedMinLength }, this.checkValidation);
    }

    getReasonPresets() {
        const { intl } = this.props;
        FileLifeCycleRest.getReasonPresets('EXPIRATION').then(response => {
            const { message, data, resultCode } = response;
            if (resultCode !== 200 || _.isEmpty(data)) {
                alert(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
            }

            const presets = _.map(data, preset => {
                let editable = false;
                let reason = preset.message;
                let minLength = 1;
                const presetMessage = preset.message;
                if (preset.key === 'ETC') {
                    editable = true;
                    reason = '';
                    minLength = 5;
                }
                return _.extend({}, preset, { editable, reason, message: presetMessage, minLength });
            });
            this.setState({ reasonPresets: presets });
        });
    }

    // 필수값에 대한 유효성 체크
    checkValidation = () => {
        const { target, reason = '', reasonMinLength } = this.state;
        if (target === '' || reason.trim().length < reasonMinLength) {
            this.setState({ isValidForm: false });
        } else {
            this.setState({ isValidForm: true });
        }
    };

    closePopup = isResolved => {
        const { close } = this.props;
        close(isResolved);
    };

    isExpiredDateToday = () => {
        const { expiredDate } = this.state;
        return expiredDate === this.initialExpiredDate.format('YYYY-MM-DD');
    };

    save = () => {
        const { intl } = this.props;
        const { fileIds, expiredDate, target, reason = '', reasonKey } = this.state;

        if (this.isExpiredDateToday()) {
            alert('drive.alert.exception.extension.expirationDateMustBeChanged');
            return;
        }

        FileLifeCycleRest.saveExtenstionRequest(fileIds, expiredDate, target, reason.trim(), reasonKey).then(
            response => {
                const { resultCode, message } = response;
                if (resultCode !== 200) {
                    if (message === 'IOFFICE_FORBIDDEN') {
                        alert('drive.alert.exception.extension.impossible');
                    } else if (message === 'REQ_INVALID_DATE') {
                        const errorMessage = intl.formatMessage({ id: 'drive.alert.ReqExtendExpiredDtPop.invalidExpiredDate' });
                        const msg = {
                            message: errorMessage.split('\\n').map(line => {
                                return (
                                    <span>
                                        {line}
                                        <br />
                                    </span>
                                );
                            })
                        };
                        alert(msg);
                    } else {
                        alert('drive.alert.exception.extenstion');
                    }
                    return;
                }
                if (target === null || target === '') {
                    this.closePopup(true);
                    toast('drive.toast.exception.extension.success');
                } else {
                    this.closePopup(true);
                    toast('drive.toast.exception.extension.request.success');
                }
            },
            () => {
                alert(intl.formatMessage({ id: 'drive.alert.exception.extenstion' }));
            }
        );
    };

    render() {
        const { headerName, files, intl, close, user } = this.props;
        const { isInvalid, isValidForm, reasonPresets, expiredDate, changeByCalendar } = this.state;

        const { period, unitRscId } = FlcmUtil.getExpiredPeriodLabel(user.config);

        return (
            <>
                <RequestHeader headerName={headerName} close={close} />
                <div className="modal-body">
                    <div className="dt-width-120">
                        <RequestBodyFiles files={files} setFileIds={fileIds => this.setFileData(fileIds)} />
                        <If condition={headerName === 'drive.title.requestExtension'}>
                            <dl className="row-list">
                                <dt>{intl.formatMessage({ id: 'drive.text.exception.expirationDate' })}</dt>
                                <dd>
                                    <div className="forms-container">
                                        <SingleDatePickerWrapper
                                            initialDate={moment(expiredDate)}
                                            minDate={this.minExpiredDate}
                                            maxDate={this.maxExpiredDate}
                                            onDateChange={date => {
                                                this.onChangeExpiredDate(date);
                                                this.setState({ changeByCalendar: true });
                                            }}
                                        />
                                        <ExpirationDateSelector
                                            onSelectExpirationDate={this.onChangeExpiredDate}
                                            onResetChangeByCalendar={() => this.setState({ changeByCalendar: false })}
                                            changeByCalendar={changeByCalendar}
                                        />
                                    </div>
                                    <div className="info-msg">
                                        <i className="ic-16-info" />
                                        {intl.formatMessage({ id: 'drive.guideText.exception.expirationDate' })}
                                    </div>
                                    <If condition={period && unitRscId}>
                                        <div className="info-msg">
                                            <i className="ic-16-info" />
                                            <FormattedMessage id="drive.guideText.exception.expirationDate2" values={{ n: period, unit: intl.formatMessage({ id: unitRscId }) }} />
                                        </div>
                                    </If>
                                </dd>
                            </dl>
                        </If>
                        <RequestBodyApprover changeTarget={this.onChangeTarget} searchTgt="FLCM" />
                        <RequestBodyReqRsn isInvalid={isInvalid} presets={reasonPresets} changeReason={value => this.onChangeReason(value)} changeReasonKey={value => this.onChangeReasonKey(value)} />
                    </div>
                </div>

                <RequestFooter isValidForm={isValidForm} close={close} save={() => this.save()} />
            </>
        );
    }
}

RequestExceptionPopup.propTypes = {
    headerName: PropTypes.string.isRequired,
    close: PropTypes.func,
    files: PropTypes.array.isRequired,
    intl: PropTypes.object,
    user: PropTypes.object.isRequired
};

RequestExceptionPopup.defaultProps = {
    close: () => {},
    intl: {}
};

const mapStateToProps = state => ({
    user: state.auth.user
});

export default injectIntl(connect(mapStateToProps)(RequestExceptionPopup));
