// TODO 대대적인 수정이 필요

import _ from 'lodash';
import SummaryRest from '../../../../../apis/SummaryRest';
import { AI_MESSAGE_RESULT_STATUS, AI_MESSAGE_STATUS, SUMMARY_MESSAGE_TYPE } from '../SummaryConstant';

const STORAGE = sessionStorage;
const KEY_ENABLE_SUMMARY = `summary/enable`;
const KEY_LLM_LIST = `summary/llm_list`;
const KEY_THREAD = `summary/thread`;
const KEY_NOTI = `summary/in_progress_noti_disabled`;

export const summaryStorage = {
    enableSummary: {
        get: () => STORAGE.getItem(KEY_ENABLE_SUMMARY),
        set: isEnabled => STORAGE.setItem(KEY_ENABLE_SUMMARY, isEnabled ? 'Y' : 'N')
    },
    llmList: {
        get: () => JSON.parse(STORAGE.getItem(KEY_LLM_LIST) || '[]'),
        set: llmList => STORAGE.setItem(KEY_LLM_LIST, JSON.stringify(llmList))
    },
    thread: {
        // 세션 내 유지되는 thread 데이터를 가져옴
        get: needAdjustment => {
            const thread = JSON.parse(STORAGE.getItem(KEY_THREAD) || '{ "summaryMessages": [] }');

            const archivedMessages = thread.summaryMessages.map(message => ({ ...message, archived: true }));

            const { type, files, summaryOptions } = _.last(archivedMessages) || {};

            // TODO 가장 최근 생성된 요약 메시지가 AI type이지만, 가장 최근 생성된 요약 결과가 종단 상태가 아닌 경우
            // 가장 최근 생성된 요약 메시지가 User type인 경우, 데이터 보정
            // (새로고침 등 으로 fetch가 완료되지 않아 User 메시지만 홀로 있는 상태)
            if (needAdjustment && type === SUMMARY_MESSAGE_TYPE.user) {
                archivedMessages.push({
                    type: SUMMARY_MESSAGE_TYPE.ai,
                    aiMessageStatus: AI_MESSAGE_STATUS.done,
                    summaryResults: [
                        {
                            resultStatus: AI_MESSAGE_RESULT_STATUS.fetchStop,
                            files,
                            summaryOptions
                        }
                    ],
                    archived: true
                });
            }

            // 추천 프롬프트 버블인 경우, 제거
            if (type === SUMMARY_MESSAGE_TYPE.recommendedPrompt) {
                archivedMessages.splice(archivedMessages.length - 1, 1);
            }

            // 새로고침 등의 이유로 storage에서 thread 데이터를 가져오는 경우, 저장된 모든 메시지 archived 처리
            return { summaryMessages: archivedMessages };
        },
        addMessage: message => {
            const thread = summaryStorage.thread.get();
            const { summaryMessages } = thread;
            const { id: curMessageId, type } = message;

            // 다시 생성 등 으로 기존 프롬프트에 대한 추가 AI 메시지인 경우, 직전 AI 메시지 폐기
            const adjustedSummaryMessages = type === SUMMARY_MESSAGE_TYPE.ai ? summaryMessages.filter(({ id }) => id !== curMessageId) : summaryMessages;

            STORAGE.setItem(KEY_THREAD, JSON.stringify({ ...thread, summaryMessages: [...adjustedSummaryMessages, message] }));
        },
        remove: () => {
            STORAGE.removeItem(KEY_THREAD);
        }
    },
    inProgressNotification: {
        disabled: () => STORAGE.setItem(KEY_NOTI, 'Y'),
        enabled: () => STORAGE.setItem(KEY_NOTI, 'N'),
        isEnabled: () => STORAGE.getItem(KEY_NOTI) !== 'Y'
    }
};

async function fetchLLMList() {
    const response = await SummaryRest.getLLMList();

    return _.get(response, 'data');
}

async function isSummarizableTenant() {
    return SummaryRest.getSummarizableTenant();
}

// TODO refactor
export function isSummarizableOnlyCache() {
    return summaryStorage.enableSummary.get() === 'Y';
}

// TODO refactor
export async function initializeForSummary() {
    const cachedEnableSummary = summaryStorage.enableSummary.get();
    const cachedLLMList = summaryStorage.llmList.get();

    if (cachedEnableSummary === 'Y' && !_.isEmpty(cachedLLMList)) {
        return { summarizable: true, llmList: summaryStorage.llmList.get() };
    }

    if (!(await isSummarizableTenant())) {
        summaryStorage.enableSummary.set(false);
        summaryStorage.llmList.set([]);

        return { summarizable: false, llmList: [] };
    }

    const fetchedLLMList = await fetchLLMList();

    if (_.isEmpty(fetchedLLMList)) {
        summaryStorage.enableSummary.set(false);
        summaryStorage.llmList.set([]);

        return { summarizable: false, llmList: [] };
    }

    const refinedLLMList = _.map(_.uniqBy(fetchedLLMList, 'modelId'), ({ modelId, modelName, llmId, ...extra }) => ({
        llmType: modelId,
        llmName: modelName,
        llmId,
        extra
    }));

    summaryStorage.enableSummary.set(true);
    summaryStorage.llmList.set(refinedLLMList);

    return { summarizable: true, llmList: refinedLLMList };
}
