export const DEFAULT_SHARE_AUTH_MAP = {
    U20VIEW: {
        FILE: true,
        FOLDER: true
    },
    U40COPY: {
        FILE: true,
        FOLDER: true
    },
    U60EDIT: {
        FILE: {
            type: 'confirm',
            message: 'drive.confirm.share.unassignableEditAuthForFile'
        },
        FOLDER: true
    },
    DEFAULT: {
        FILE: 'U40COPY',
        FOLDER: 'U40COPY'
    }
};

export const DEFAULT_SHARE_AUTH_CDS = [{ value: 'U20VIEW', message: 'com.viewable' }, { value: 'U40COPY', message: 'com.copyable' }, { value: 'U60EDIT', message: 'com.editable' }];
