import UserRest from 'apis/UserRest';
import ConfigsRest from 'apis/ConfigsRest';
import CustomModal from 'containers/CustomModal';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import moment from 'moment';
import SingleDatePickerWrapper from 'presentationals/SingleDatePickerWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import GlobalPopupService from 'src/redux/service/GlobalPopup';
import { globalPopup as globalPopupActions, globalPopup } from '../../../redux/actions';
import store from '../../../redux/store';
import ReqRest from '../../../apis/ReqRest';

class ChangeExpirationDate extends Component {
    constructor(props) {
        super(props);

        const storeState = store.getState();
        const {
            auth: {
                user: { id }
            }
        } = storeState;
        this.userId = id;

        this.state = {
            partner: {},
            expirationTime: moment()
        };
        this.maxUseDays = 365;
    }

    componentDidMount() {
        const { id } = this.props;
        ConfigsRest.getConfig('EFL_AUTH_USER_SUBCONTRACTOR_DEFAULT_MAX_USE_DAYS').then(response => {
            const { cfgVal } = response;
            this.maxUseDays = Number(cfgVal || this.maxUseDays);

            if (_.isEmpty(id)) return;
            UserRest.retrieveUserByUserId(id).then(result => {
                this.setState({
                    partner: this.convertUser(result) || {},
                    expirationTime: moment().add(this.maxUseDays, 'days') || moment().add(1, 'days')
                });
            });
        });

        this.reqYn = 'Y'; // 기획 요건으로 무조건 Y처리
        // ConfigsRest.getConfig('EFL_AUT_PARTNER_ACCOUNT_REQ_YN').then(response => {
        //     const { cfgVal } = response;
        //     this.reqYn = cfgVal;
        // });

        UserRest.retrieveUserApprover().then(response => {
            const { data } = response;
            this.approver = data || {};
        });
    }

    componentDidUpdate(prevProps) {
        const { id, isOpen } = this.props;
        if (isOpen && prevProps.id !== id) {
            this.componentDidMount();
        }
    }

    convertUser = result => {
        const name = _.get(result, 'userLangCdNm', '');
        const position = `${result.userLangCdCopNm || ''} / ${result.deptLangCdNm}`;
        return {
            ...result,
            userId: _.get(result, 'userId', ''),
            position,
            name,
            title: `${name} ${position}`
        };
    };

    save = () => {
        if (this.reqYn === 'Y') {
            const { openConfirm } = this.props;
            if (this.userId !== this.approver.userId) {
                openConfirm(<FormattedHTMLMessage id="user.confirm.ReqPartner.expire-req-confirm" tagName="p" values={{ name: this.approver.userLangCdNm }} />, e => {
                    if (e) this.udpateReq();
                });
            } else {
                this.udpateReq();
            }
        } else {
            this.udpateReq();
        }
    };

    udpateReq = () => {
        const { partner, expirationTime } = this.state;
        const { loginUser, intl } = this.props;

        UserRest.updatePartnerExpiredTime({ userId: partner.userId, accessExpirationTime: moment(expirationTime).valueOf(), tenantId: loginUser.tenantId.toString() }).then(async resolve => {
            const { resultCode, data, message } = resolve;
            if (resultCode === 200) {
                const { reqYn } = data;
                if (reqYn === 'Y') {
                    if (this.userId !== this.approver.userId) {
                        GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'user.alert.popup.req.expire-extend-requested' })).then(() => {
                            const { closeChangeExpirationDate } = this.props;
                            closeChangeExpirationDate();
                        });
                    } else {
                        const reqPrcsList = [data.reqId];
                        await ReqRest.updateReqProcess({ reqPrcsType: 'APPROVAL', reqPrcsList });
                        GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'user.toast.popup.changeExpirationDate.accessExpChanged' })).then(() => {
                            const { closeChangeExpirationDate } = this.props;
                            closeChangeExpirationDate();
                        });
                    }
                } else {
                    GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'user.toast.popup.changeExpirationDate.accessExpChanged' })).then(() => {
                        const { closeChangeExpirationDate } = this.props;
                        closeChangeExpirationDate();
                    });
                }
            } else if (resultCode === 10677) {
                GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'user.alert.popup.req.expire-extend.aleady-req' }));
            } else {
                GlobalPopupService.openAlertPopup(intl.formatMessage({ id: 'com.alert.unexpected-error', values: { code: `${resultCode} / ${message}` } }));
            }
        });
    };

    onOpen = () => {
        // console.log('onOpen');
    };

    addMonth = month => {
        const { expirationTime } = this.state;

        let chengedTime = moment(expirationTime).add(month, 'months');
        const now = moment();
        const max = moment().add(this.maxUseDays, 'days');

        const changeDate = moment(chengedTime).format('YYYY-MM-DD');
        const nowDate = moment(now).format('YYYY-MM-DD');
        const maxDate = moment(max).format('YYYY-MM-DD');

        if (changeDate < nowDate) {
            chengedTime = now;
        } else if (changeDate > maxDate) {
            chengedTime = max;
        }

        this.setState({ expirationTime: chengedTime });
    };

    close = () => {
        const { closeChangeExpirationDate } = this.props;
        closeChangeExpirationDate();
    };

    getExpirationTime = value => {
        this.setState({ expirationTime: value });
    };

    isSameExpireDate = () => {
        const { partner, expirationTime } = this.state;
        const baseData = moment(partner.accessExpirationTime).format('YYYY-MM-DD');
        const targetDate = expirationTime.format('YYYY-MM-DD');
        return baseData === targetDate;
    };

    render() {
        const { isOpen, intl } = this.props;
        const { partner, expirationTime } = this.state;
        return (
            <CustomModal isOpen={isOpen}>
                <If condition={_.isEmpty(partner)}>
                    <>{intl.formatMessage({ id: 'user.guideText.namecard.notExistsUser' })}</>
                </If>
                <If condition={!_.isEmpty(partner)}>
                    <ModalHeader>{intl.formatMessage({ id: 'user.title.popup.changeExpirationDate.changeExp' })}</ModalHeader>
                    <ModalBody>
                        <div className="dt-width-120">
                            <dl className="row-list text-info">
                                <dt>{intl.formatMessage({ id: 'user.text.popup.changeExpirationDate.account' })}</dt>
                                <dd>
                                    <div className="dd-text">
                                        <div className="item-user">
                                            <div className="user-profile">
                                                <div title={partner.title}>
                                                    <span className="name has-name-card">{partner.name}</span>
                                                    <span className="position">{partner.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="row-list">
                                <dt>{intl.formatMessage({ id: 'user.text.popup.changeExpirationDate.accessExpDate' })}</dt>
                                <dd>
                                    <div className="forms-container">
                                        <SingleDatePickerWrapper
                                            showDefaultInputIcon={false}
                                            customInputIcon={<i className="ic-20-calendar" />}
                                            minDate={moment()}
                                            maxDate={moment().add(this.maxUseDays, 'days')}
                                            initialDate={expirationTime}
                                            onDateChange={this.getExpirationTime}
                                        />
                                        <div className="btn-group" role="group">
                                            <a className="btn-ic-line" role="button" onClick={() => this.addMonth(-1)}>
                                                <i className="ic-20-minus" />
                                                <span className="btn-text">{intl.formatMessage({ id: 'user.button.changeExpirationDate.one.month' })}</span>
                                            </a>
                                            <a className="btn-ic-line" role="button" onClick={() => this.addMonth(1)}>
                                                <i className="ic-20-plus" />
                                                <span className="btn-text">{intl.formatMessage({ id: 'user.button.changeExpirationDate.one.month' })}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="info-msg">
                                        <i className="ic-16-info" />
                                        {intl.formatMessage({ id: 'user.text.popup.changeExpirationDate.extendLimitDesc' }, { N: this.maxUseDays })}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {intl.formatMessage({ id: 'user.text.popup.partnerDetail.expirationDate' })}
                                        {` : ${moment(partner.accessExpirationTime).format('YYYY-MM-DD')}`}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="modal-footer-option" />
                        <div className="modal-footer-btns">
                            <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={this.close}>
                                <span role="button" className="btn-text">
                                    {intl.formatMessage({ id: 'com.cancel' })}
                                </span>
                            </a>
                            <a className={this.isSameExpireDate() ? 'btn btn-lg btn-primary disabled' : 'btn btn-lg btn-primary'} role="button" onClick={this.save}>
                                <span role="button" className="btn-text">
                                    {intl.formatMessage({ id: 'com.save' })}
                                </span>
                            </a>
                        </div>
                    </ModalFooter>
                </If>
            </CustomModal>
        );
    }
}

ChangeExpirationDate.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    closeChangeExpirationDate: PropTypes.func.isRequired,
    loginUser: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    openConfirm: PropTypes.func.isRequired
};

ChangeExpirationDate.defaultProps = {
    isOpen: false
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.expirationDate.isOpen,
    id: state.globalPopup.expirationDate.id,
    loginUser: state.auth.user
});

const mapDispatchToProps = {
    closeChangeExpirationDate: globalPopup.closeChangeExpirationDate,
    openConfirm: globalPopupActions.openConfirm
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangeExpirationDate)
);
