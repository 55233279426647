import React from 'react';
import PropTypes from 'prop-types';

import CharacterImage from 'containers/CharacterImage/CharacterImageContainer';

const UserItem = props => {
    const { name, id, userCopNm, userLoginId, userPhotoFilePathVal, deptNm, liTagClassName, onClick, userSectCd } = props;
    return (
        <li key={id} id={id} className={liTagClassName} onClick={onClick} role="presentation">
            <div className="item-user">
                <CharacterImage size="md" characterType="user" text={name} userType={userSectCd} objtId={userPhotoFilePathVal} userId={id} updateTime={0} />
                <div className="user-profile">
                    <div title={`${name} ${userCopNm} ${deptNm}`}>
                        <span className="name has-name-card">{name}</span>
                        <span className="position">
                            {userCopNm} / {deptNm}
                        </span>
                    </div>
                    <span className="email">{userLoginId}</span>
                </div>
            </div>
        </li>
    );
};

UserItem.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    userCopNm: PropTypes.string,
    userLoginId: PropTypes.string,
    userPhotoFilePathVal: PropTypes.string,
    deptNm: PropTypes.string,
    liTagClassName: PropTypes.string,
    onClick: PropTypes.func,
    userSectCd: PropTypes.string
};

UserItem.defaultProps = {
    name: '',
    id: new Date(),
    userCopNm: '',
    userLoginId: '',
    userPhotoFilePathVal: undefined,
    deptNm: '',
    liTagClassName: undefined,
    onClick: undefined,
    userSectCd: 'I'
};

export default UserItem;
