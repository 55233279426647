import $http from 'modules/Http';
import { Base64 } from 'js-base64';

const prefix = '/common/v1/helpcenter/faq';

class FAQRest {
    static getFAQs(params) {
        const changedParams = params;
        const { keyword } = params;
        if (keyword) changedParams.keyword = Base64.encode(keyword);
        return $http.get(prefix, { params: changedParams });
    }

    static getFAQContent(faqId) {
        return $http.get(`${prefix}/${faqId}`);
    }
}

export default FAQRest;
