import produce from 'immer';
import { handleActions } from 'redux-actions';
import { DEFAULT_SUMMARY_OPTIONS } from '../../../components/containers/Summary/OptionSelectDialog/SummaryOptionConstant';

export default handleActions(
    {
        optionSelectDialog: {
            open: (state, { payload }) => {
                return produce(state, draft => {
                    draft.isOpen = true;
                    draft.generationMode = payload.generationMode;
                    draft.positionType = payload.positionType;
                    draft.files = payload.files;
                    draft.optionSelectDialogOpenButtonRef = payload.optionSelectDialogOpenButtonRef;
                    draft.exceptOptionList = payload.exceptOptionList;
                });
            },
            close: state => {
                return produce(state, draft => {
                    draft.isOpen = false;
                });
            },
            changeOption: (state, { payload }) => {
                return produce(state, draft => {
                    draft.summaryOptions = { ...draft.summaryOptions, ...payload.summaryOptions };
                });
            }
        }
    },
    {
        isOpen: true,
        summaryOptions: DEFAULT_SUMMARY_OPTIONS
    },
    { prefix: 'summary' }
);
