import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withFilesContext } from '../DriveFilesContext';

const DeleteRootRow = props => {
    const {
        file = {},
        style,
        context: { props: { pageClass } = {}, state },
        loginUser: { id },
        intl
    } = props;

    const { drive = {} } = file;

    if (id === drive.objtId) {
        file.drive.objtNm = intl.formatMessage({ id: 'drive.text.list.columns.myfiles' });
    }

    const getCellStyle = (column, idx, colLength) => {
        const cellStyle = {};
        if (column.width) cellStyle.flexBasis = column.width;
        if (idx + 1 !== colLength) cellStyle.paddingRight = '24px';
        return cellStyle;
    };

    return (
        <div key={drive.objtId} style={style} role="button" className="grid-row folder-row">
            <div className="grid-cell files-check-cell">
                <div className="form-check">
                    <label className="form-check-label" />
                </div>
            </div>
            {state.viewColumns.map((column, idx) => (
                <column.bodyComp pageClass={pageClass} key={column.id} file={file} cellStyle={getCellStyle(column, idx, state.viewColumns.length)} />
            ))}
            <div className="grid-cell files-option-cell" role="presentation" />
        </div>
    );
};

DeleteRootRow.propTypes = {
    file: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    loginUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loginUser: state.auth.user
});

DeleteRootRow.displayName = 'DeleteRootRow';
export default connect(mapStateToProps)(injectIntl(withFilesContext(DeleteRootRow)), ['state.viewHeight', 'state.viewColumns']);
