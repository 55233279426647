import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { summaryActions } from '../../../../../redux/actions/Summary';

const WrongKeywordDialog = ({ wrongKeywordDialogRef, intl }) => {
    const isOpen = useSelector(({ summary }) => summary.wrongKeywordDialog.isOpen);

    const dispatch = useDispatch();

    const handleClickCloseButton = () => {
        dispatch(summaryActions.wrongKeywordDialog.close());
    };

    return (
        <>
            <div className={classnames('modal', 'fade', isOpen && 'show')} role="dialog" aria-hidden="true" style={{ display: isOpen ? 'block' : 'none' }} ref={wrongKeywordDialogRef}>
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <FormattedMessage id="drive.text.copilot.confirm_title" />
                            </h5>
                            <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                                <i className="ic-20-copilot-close" />
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="box-text">
                                <FormattedHTMLMessage tagName="span" id="drive.tooltip.copilot.summary.keywordConstraint" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-btns">
                                <a className="btn btn-md btn-primary" role="button" data-dismiss="modal" onClick={handleClickCloseButton}>
                                    <span className="btn-text">
                                        <FormattedMessage id="com.button.ok" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show" style={{ zIndex: 1049 }} />}
        </>
    );
};

WrongKeywordDialog.propTypes = {
    wrongKeywordDialogRef: PropTypes.object.isRequired
};

WrongKeywordDialog.defaultProps = {};

export default injectIntl(WrongKeywordDialog);
