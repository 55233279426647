import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CharacterImage from 'containers/CharacterImage/CharacterImageContainer';

const SearchTeamMemberRow = props => {
    const { user, style, index, checkBox, selectUser, checkSelectedUser } = props;

    const checkSelectedRow = event => {
        checkSelectedUser(user, index, event);
        selectUser(user);
    };

    const handleOnClickEvent = () => {
        if (!checkBox) {
            selectUser(user, index);
        }
    };

    return (
        <div className={_.get(user, 'isHovered') ? 'grid-row is-checked' : 'grid-row'} role="button" style={style} onClick={handleOnClickEvent}>
            {checkBox ? (
                <div className="grid-cell check-cell">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" onClick={event => checkSelectedRow(event)} checked={user.checked} />
                            <span className="label-text" />
                        </label>
                    </div>
                </div>
            ) : (
                <div />
            )}
            <div className="grid-cell name-cell">
                <div className="item-user">
                    <span className="user-thumbnail-ss p-type01">
                        <i>
                            <CharacterImage objtId={user.userPhotoFilePathVal} userId={user.userId} text={user.userNm} size="bg" userType={user.sectionCode} />
                        </i>
                    </span>
                    <div className="user-profile">
                        <div title={user.userLangCdNm}>
                            <span className="name has-name-card">{user.userLangCdNm}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-cell position-cell">
                <span className="position" title={user.userLangCdCopNm}>
                    {user.userLangCdCopNm}
                </span>
            </div>

            <div className="grid-cell email-cell">
                <span className="email" title={user.userLoginId}>
                    {user.userLoginId}
                </span>
            </div>
        </div>
    );
};

export default SearchTeamMemberRow;

SearchTeamMemberRow.propTypes = {
    selectUser: PropTypes.func,
    checkSelectedUser: PropTypes.func,
    user: PropTypes.object,
    style: PropTypes.object,
    checkBox: PropTypes.bool,
    index: PropTypes.number
};

SearchTeamMemberRow.defaultProps = {
    selectUser: _.noop,
    checkSelectedUser: _.noop,
    user: {},
    style: {},
    checkBox: false,
    index: 0
};
