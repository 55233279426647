import { NavLink } from 'react-router-dom';
import React from 'react';
import { injectIntl } from 'react-intl';
import Url from '../../../utils/Url';

const DuplicatedTab = props => {
    const { intl } = props;
    const tabs = [
        {
            to: Url.duplicated,
            label: intl.formatMessage({ id: 'drive.tab.duplicated' })
        },
        {
            to: Url.similar,
            label: intl.formatMessage({ id: 'drive.tab.similar' })
        }
    ];

    return (
        <div className="drv-nav">
            <nav>
                <ul className="nav nav-tabs" role="tablist">
                    {tabs.map(({ to, label }) => {
                        return (
                            <li className="nav-item">
                                <NavLink exact={true} to={to} className="nav-link" activeClassName="active" role="tab" activeStyle={{ pointerEvents: 'none' }}>
                                    {label}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default injectIntl(DuplicatedTab);
