import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import UserCard from '../../../containers/NameCard/UserCard';

class ReqDtlBodyApprover extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, name, deptNm, copNm } = this.props;

        return (
            <React.Fragment>
                <dl className="row-list text-info">
                    <dt>
                        <FormattedMessage id="drive.title.ReqDtlBodyApprover" />
                    </dt>
                    <dd>
                        <div className="dd-text">
                            <div className="item-user">
                                <div className="user-profile">
                                    <div title="홍길동 선임 / 사업그룹(엔터프라이즈모바일)">
                                        <span className="name has-name-card" id={`reqUserCard_${id}`}>
                                            {name}
                                        </span>
                                        <span className="position">
                                            {copNm} / {deptNm}
                                        </span>
                                        <UserCard target={`reqUserCard_${id}`} id={id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dd>
                </dl>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

ReqDtlBodyApprover.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    deptNm: PropTypes.string,
    copNm: PropTypes.string
};

ReqDtlBodyApprover.defaultProps = {
    id: '',
    name: '',
    deptNm: '',
    copNm: ''
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReqDtlBodyApprover));

export default connected;
