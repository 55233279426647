import React from 'react';
import SummaryGuideModal from './SummaryGuideModal';
import SummaryStopForNewDialog from './SummaryStopForNewDialog';
import SummaryLimitConfirmDialog from './SummaryLimitConfirmDialog';
import FeedbackDialog from './FeedbackDialog';
import ThreadClearConfirmDialog from './ThreadClearConfirmDialog';
import SummaryToasts from './SummaryToasts';
import FileReselectConfirmDialog from './FileReselectConfirmDialog';

const SummaryPopups = () => {
    return (
        <>
            <SummaryGuideModal />
            <SummaryStopForNewDialog />
            <SummaryLimitConfirmDialog />
            <FeedbackDialog />
            <ThreadClearConfirmDialog />
            <FileReselectConfirmDialog />
            <SummaryToasts />
        </>
    );
};

SummaryPopups.propTypes = {};

SummaryPopups.defaultProps = {};

export default SummaryPopups;
