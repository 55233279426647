import React from 'react';
import { FormattedMessage } from 'react-intl';
import FoldersRest from '../../apis/FoldersRest';
import Base from './Base';
import RecentRow from '../../components/containers/DriveFiles/Row/RecentRow';
import DriveFilesBody from '../../components/containers/DriveFiles/DriveFilesBody';

class Recent extends Base {
    OFFSET_TOP = 140;

    pageId = 'used';

    menu = {
        header: {
            iconGroupType: null, // 헤더영역 없음
            iconGroupTypeMulti: null // 멀티선택 시 icon 영역
        },
        row: {
            iconGroupType: 'normal',
            menuGroupType: null, // 우클릭 사용안함
            floatingIconGroupType: null // floating 사용안함
        }
    };

    absoluteHeight = 69;

    searchFunction() {
        return FoldersRest.getPersonalFolders('used');
    }

    getColumns() {}

    uploadBuilder() {}

    rowBuilder({ list, style, index }) {
        return <RecentRow file={list[index]} index={index} style={style} />;
    }

    emptyBuilder() {
        return (
            <div className="box-empty">
                <div className="message">
                    <span className="ic-empty-item" />
                    <p>
                        <FormattedMessage id="drive.text.list.empty.notExists">{message => <>{message}</>}</FormattedMessage>
                        <br />
                        <FormattedMessage id="drive.text.list.empty.recent">{message => <>{message}</>}</FormattedMessage>
                    </p>
                </div>
            </div>
        );
    }

    pageBuilder({ state: { list } }) {
        if (list && !list.length) {
            return <section className="drv-file-panel">{this.emptyBuilder()}</section>;
        }
        return (
            <div className="comm-grid-list">
                <DriveFilesBody />
            </div>
        );
    }
}

export default new Recent();
