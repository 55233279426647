/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomList from '../List/CustomList';
import VersionUpdate from './VersionUpdate';
import UploadItem from './UploadItem';
import VersionItem from './VersionItem';
import MoreButton from './MoreButton';
import FileVersionRest from '../../../apis/FileVersionRest';
import { alert, confirm, toast } from '../../../utils/ModalService';
import { FormattedMessage, injectIntl } from 'react-intl';
import FileReload from '../../../utils/FileReload';

class FileVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            uploading: false,
            isRevert: false,
            actionPolicy: {}
        };
        this.refVersionUpdate = React.createRef();
        this.fileVerSno = 0;
        this.more = false;
        this.limit = 50;
        this.offset = 0;
        this.versionLoad = this.versionLoad.bind(this);
    }

    componentDidMount() {
        this.versionLoad();
    }

    callBack = (props, data, type) => {
        if (type === 'revert' || type === 'delete') {
            this.offset = 0;
            this.versionLoad();
        }
    };

    versionLoad() {
        FileVersionRest.getFileVersions(this.props.files[0].drive.objtId, this.props.files[0].drive.onpstId, this.offset, this.limit).then(
            result => {
                if (result.resultCode === 200) {
                    const data = result.data;

                    if (!!data && data.length > 0) {
                        let { uploading } = this.state;
                        let list = [];

                        this.setState({ actionPolicy: data[0].actionPolicyMap });

                        if (this.offset === 0) {
                            list = [...data];
                            uploading = false;
                        } else {
                            if (this.state.list[this.state.list.length - 1].fileVerSno === -1) {
                                list = [...this.state.list.slice(0, this.state.list.length - 1), ...data];
                            } else {
                                list = [...this.state.list, ...data];
                            }
                        }

                        if (data.length === this.limit) {
                            // data 길이와 리미트가 같을경우 즉 다시 조회해온 양이 50개일경우 more 버튼을 만들어줌.
                            this.more = true;
                            list = [...list, { fileVerSno: -1 }];
                        } else {
                            this.more = false;
                        }

                        this.fileVerSno = data[data.length - 1].fileVerSno;
                        this.setState({ uploading, list });

                        if (this.more) {
                            this.offset = this.state.list.length - 1;
                        }
                    } else {
                        this.more = false;
                        if (this.state.list.length > 0 && this.state.list[this.state.list.length - 1].fileVerSno === -1) {
                            const list = [...this.state.list.slice(0, this.state.list.length - 1)];
                            this.setState({ list });
                        }
                    }
                } else {
                    const noAuthMsgId = 'drive.alert.incomLink.noAuthFile';

                    alert(noAuthMsgId).then(() => {
                        FileReload.call(null, this.props.files[0].drive, { reloadType: 'refresh', folderChanged: true });
                        this.props.close();
                    });
                }
            },
            () => {
                // 실행안누르고 닫은경우. 아무 변경이 없는루트.
                return false;
            }
        );
    }

    handleAfterAddingFile = fileItem => {
        if (!this.state.uploading) {
            const list = [{ fileVerSno: 0, fileItem: fileItem }, ...this.state.list];
            this.setState({ uploading: true, list: list });
        }
    };

    handleChangeStateUploadItem = fileItem => {
        const { list } = this.state;
        const uploadItem = { ...list[0], fileItem };
        this.setState({ uploading: true, list: [uploadItem, ...list.slice(1, list.length)] });
    };

    changeRevertStatus = isRevert => {
        this.setState({ isRevert: isRevert });
    };

    handleRemoveUploadItem = () => {
        //버전 업데이트를 중지하시겠습니까?
        confirm(<FormattedMessage id="drive.confirm.verup.remove" />).then(data => {
            if (!!data) {
                this.refVersionUpdate.current.clearQueue();
                if (this.state.uploading) {
                    const { list } = this.state;
                    this.setState({ uploading: false, list: [...list.slice(1, list.length)] });
                }
            }
        });
    };

    handleSuccessUploadItem = () => {
        this.setState({ uploading: false });
        //버전 업데이트가 완료되었습니다.
        toast('drive.toast.verup.completed');
        FileReload.call(null, this.props.files[0], { reloadType: 'refresh' });
        this.offset = 0;
        this.versionLoad();
    };

    handleClickMore = () => {
        this.versionLoad();
    };

    rowBuilder = ({ style, index }) => {
        const { list } = this.state;

        // 정상버전이 1개 초과일때만 삭제버튼 활성화.
        let listSize = 0;
        list.forEach(item => {
            if (item.fileVerDelCd === 'NORMAL') {
                listSize++;
            }
        });

        if (list[index].fileVerSno === 0) {
            return <UploadItem fileItem={list[index].fileItem} style={style} key={0} onRemoveUploadItem={this.handleRemoveUploadItem} />;
        } else if (list[index].fileVerSno === -1) {
            return <MoreButton key={-1} style={style} onClickMore={this.handleClickMore} />;
        } else if (list[index].fileVerDelCd === 'NORMAL') {
            return (
                <VersionItem
                    version={list[index]}
                    actionPolicy={this.state.actionPolicy}
                    versionLoad={this.callBack}
                    uploading={this.state.uploading}
                    changeRevertStatus={this.changeRevertStatus}
                    listSize={listSize}
                    style={style}
                    key={list[index].fileVerSno}
                />
            );
        }

        return <div />;
    };
    
    handleClick = e => {
        const { uploading } = this.state;
        const { close } = this.props;

        if (uploading) {
            confirm(<FormattedMessage id="drive.confirm.cancelUpdate" />).then(
                () => {
                    close();
                    e.stopPropagation();
                },
                () => false
            );
        } else {
            close();
            e.stopPropagation();
        }
    };

    render() {
        const { list, actionPolicy, uploading, isRevert } = this.state;
        const { intl } = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id="drive.title.fileVersionTitle" />
                    </h5>
                    <a
                        className="btn-ic-nor"
                        role="button"
                        title={intl.formatMessage({ id: 'com.close' })}
                        data-dismiss="modal"
                        onClick={this.handleClick}>
                        <i className="ic-20-close" />
                    </a>
                </div>

                <div className="modal-body">
                    <div className="comm-title-bar">
                        <VersionUpdate
                            file={this.props.files[0]}
                            actionPolicy={actionPolicy}
                            isRevert={isRevert}
                            uploading={uploading}
                            onAfterAddingFile={this.handleAfterAddingFile}
                            onChangeStateUploadItem={this.handleChangeStateUploadItem}
                            onSuccessUploadItem={this.handleSuccessUploadItem}
                            ref={this.refVersionUpdate}
                        />
                    </div>

                    <div className="data-grid-list">
                        <div className="comm-grid-list react-list">
                            <CustomList className="grid-row-group" customScroll={true} virtualized={true} height={350} rowCount={list.length} rowHeight={70} rowBuilder={this.rowBuilder} />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-option" />
                    <div className="modal-footer-btns">
                        <a
                            className="btn btn-lg btn-primary"
                            role="button"
                            data-dismiss="modal"
                            onClick={this.handleClick}>
                            <span className="btn-text">
                                <FormattedMessage id="com.button.ok" />
                            </span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

FileVersion.propTypes = {
    files: PropTypes.array
};

FileVersion.defaultProps = {
    files: []
};

export default injectIntl(FileVersion);
