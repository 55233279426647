/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import UserCard from 'components/containers/NameCard/UserCard';
import { withFilesContext } from '../DriveFilesContext';
import DateConverter from '../../../../utils/DateConverter';

const SearchChangedDate = props => {
    const {
        file: { drive, users = {} },
        context: {
            props: { searchParams }
        }
    } = props;
    const { objtStatChgUser = {} } = users;

    const { objtStatChgUserNm = '' } = searchParams;
    const objtStatChgUserNmQueries = objtStatChgUserNm ? objtStatChgUserNm.split(' ').map(qq => qq.trim()).filter(qq => qq) : [];
    return (
        <li>
            <div className="g-inline-flex">
                <div className="title">
                    <FormattedMessage id="drive.text.list.columns.objtStatChgDt" /> :
                </div>
                <div className="next">{drive.objtStatChgDt ? DateConverter.convert(drive.objtStatChgDt) : '-'}</div>
                {objtStatChgUser.userNm && (
                    <div className="ellipsis comma">
                        <span
                            className="has-name-card"
                            title={objtStatChgUser.userNm}
                            id={`files-objtStatChgDt-cell-${drive.objtId}`}
                            onClick={e => e.stopPropagation()}
                            role="button"
                            style={{ cursor: 'pointer' }}>
                            <Highlighter searchWords={objtStatChgUserNmQueries} autoEscape={true} textToHighlight={objtStatChgUser.userNm || ''} />
                        </span>
                        <UserCard target={`files-objtStatChgDt-cell-${drive.objtId}`} id={drive.objtStatChgUserId} />
                    </div>
                )}
            </div>
        </li>
    );
};

SearchChangedDate.propTypes = {
    file: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
};

SearchChangedDate.displayName = 'SearchChangedDate';
export default withFilesContext(SearchChangedDate, []);
