import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FixedSizeList } from 'react-window';
import NormalList from './NormalList';

class CustomList extends Component {
    listRef = React.createRef();

    innerRef = React.createRef();

    componentDidMount() {
        const { windowResize, handleWindowResize, className, onDidMount, virtualized } = this.props;
        if (windowResize) {
            window.addEventListener('resize', handleWindowResize);
            document.getElementsByClassName(className)[0].addEventListener('scroll', function(e) {
                if (e.currentTarget.scrollLeft) document.getElementById('gridHeader').scrollLeft = this.scrollLeft;
            });
        }
        if (onDidMount) {
            onDidMount(virtualized ? this.innerRef : this.listRef);
        }
    }

    componentWillUnmount() {
        const { windowResize, handleWindowResize } = this.props;
        if (windowResize) {
            window.removeEventListener('resize', handleWindowResize);
        }
    }

    handleCustomScrollByList = e => {
        const { target } = e;
        const { scrollTop } = target;
        const { handleScroll } = this.props;
        this.listRef.current.scrollTo(scrollTop);
        handleScroll(e);
    };

    handleCustomScrollByWindow = e => {
        const { target } = e;
        const { scrollTop } = target;
        const { handleScroll } = this.props;
        this.listRef.current.scrollTop = scrollTop;
        handleScroll(e);
    };

    render() {
        const { className, virtualized, height, rowCount, rowHeight, customScroll, rowBuilder, style, handleScroll } = this.props;
        const { listStyle = Object.assign({}, { overflow: customScroll ? false : 'auto' }, style) } = this.props;

        let list;

        if (virtualized) {
            list = (
                <FixedSizeList
                    className={className}
                    height={height}
                    itemCount={rowCount}
                    itemSize={rowHeight}
                    overscanCount={10}
                    width="100%"
                    style={listStyle}
                    ref={this.listRef}
                    innerRef={this.innerRef}
                    onScroll={handleScroll}>
                    {rowBuilder}
                </FixedSizeList>
            );
        } else {
            list = <NormalList className={className} itemCount={rowCount} itemSize={rowHeight} itemBuilder={rowBuilder} accessRef={this.listRef} />;
        }

        if (customScroll) {
            return (
                <Scrollbars className="scroll" autoHeight={true} autoHeightMax={height} onScroll={virtualized ? this.handleCustomScrollByList : this.handleCustomScrollByWindow}>
                    {list}
                </Scrollbars>
            );
        }

        return list;
    }
}

CustomList.propTypes = {
    rowCount: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    rowBuilder: PropTypes.func.isRequired,
    windowResize: PropTypes.bool,
    handleWindowResize: PropTypes.func,
    customScroll: PropTypes.bool,
    virtualized: PropTypes.bool,
    height: PropTypes.number,
    className: PropTypes.string.isRequired,
    handleScroll: PropTypes.func,
    listStyle: PropTypes.object,
    style: PropTypes.object,
    onDidMount: PropTypes.func
};

CustomList.defaultProps = {
    windowResize: false,
    handleWindowResize: () => {},
    customScroll: true,
    virtualized: true,
    height: 0,
    handleScroll: () => {},
    listStyle: undefined,
    style: {},
    onDidMount: null
};

export default CustomList;
