/* eslint-disable */
import CustomModal from 'containers/CustomModal';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { globalPopup } from 'src/redux/actions';
import GlobalPopup from 'src/redux/service/GlobalPopup';

import TeamTree from '../../TeamTree';
import TeamSearchList from './TeamSearchList';

const TeamSearchWithTree = props => {
    const { close, isOpen, intl } = props;
    const [team, setTeam] = useState([]);
    const [query, setQuery] = useState('');
    const [inputVal, setInputVal] = useState(null);
    const [isOpenTeamList, setOpenTeamList] = useState(false);

    function init() {
        setTeam([]);
        setQuery('');
        setInputVal(null);
        setOpenTeamList(false);
    }

    function onClose() {
        close();
        init();
    }

    function onSelect(selected) {
        let selectedTeam = team;
        if (_.isObject(selected)) {
            selectedTeam = {
                id: selected.deptId,
                title: selected.deptLangCdNm
            };
        }
        close(selectedTeam);
        init();
    }

    function search() {
        if (_.trim(inputVal)) {
            setQuery(_.trim(inputVal));
            setOpenTeamList(true);
        } else {
            GlobalPopup.openAlertPopup(intl.formatMessage({ id: 'com.alert.no-search-results.confirmSearchText' }));
        }
    }

    function onKeyUp(e) {
        if (e.key === 'Enter') {
            search();
        }
    }

    function onChangeInputValue(e) {
        const value = _.get(e, 'target.value');
        setInputVal(value);
    }

    function closeTeamListDialog(selected) {
        if (_.isObject(selected)) {
            onSelect(selected);
        }
        setOpenTeamList(false);
    }

    return (
        <>
            <CustomModal isOpen={isOpen} fade={false} backdrop="static" className="modal-open" zIndex={1051}>
                <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'block', overflowY: 'hidden' }}>
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    <FormattedMessage id="user.title.popup.searchDept.searchTeam" />
                                </h5>
                                <FormattedMessage id="com.close">
                                    {closeTitle => (
                                        <a className="btn-ic-nor" role="button" title={closeTitle} data-dismiss="modal" onClick={onClose}>
                                            <i className="ic-20-close" />
                                        </a>
                                    )}
                                </FormattedMessage>
                            </div>

                            <div className="modal-body">
                                <div className="forms-container">
                                    <div className="box-input-group">
                                        <FormattedMessage id="com.search">
                                            {searchTitle => (
                                                <a className="btn-ic-nor btn-func-search" role="button" title={searchTitle} onClick={search}>
                                                    <i className="ic-16-search" />
                                                </a>
                                            )}
                                        </FormattedMessage>

                                        <FormattedMessage id="user.placeHolder.popup.searchDept.enterTeamName">
                                            {deptNamePlaceHolder => (
                                                <input className="form-control" type="text" placeholder={deptNamePlaceHolder} onKeyUp={onKeyUp} onChange={e => onChangeInputValue(e)} />
                                            )}
                                        </FormattedMessage>

                                        <FormattedMessage id="com.text.clear">
                                            {clearTitle => (
                                                <a className="btn-ic-nor btn-func-clear" role="button" title={clearTitle}>
                                                    <i className="ic-16-clear" />
                                                </a>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>
                                <TeamTree selectTeam={setTeam} searchQuery={query} />
                            </div>
                            <div className="modal-footer">
                                <div className="modal-footer-option" />
                                <div className="modal-footer-btns">
                                    <a className="btn btn-lg btn-secondary" role="button" data-dismiss="modal" onClick={onClose}>
                                        <span className="btn-text">
                                            <FormattedMessage id="com.cancel" />
                                        </span>
                                    </a>
                                    <a className={`btn btn-lg btn-primary ${_.isEmpty(team) ? 'disabled' : ''}`} role="button" onClick={() => onSelect()}>
                                        <span className="btn-text">
                                            <FormattedMessage id="com.check" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && !_.isEmpty(query) && <TeamSearchList query={query} isOpen={isOpenTeamList} close={closeTeamListDialog} />}
            </CustomModal>
        </>
    );
};

TeamSearchWithTree.propTypes = {
    close: PropTypes.func,
    isOpen: PropTypes.bool,
    intl: PropTypes.object.isRequired
};

TeamSearchWithTree.defaultProps = {
    close: _.noop,
    isOpen: false
};

const mapStateToProps = state => ({
    locale: state.auth.user.locale
});
const mapDispatchToProps = {
    close: globalPopup.closeTeamSearchWithTree
};
export default connect(mapStateToProps)(TeamSearchWithTree);

const mapStateToPropsForGlobal = state => ({
    isOpen: state.globalPopup.teamSearchWithTree.isOpen
});

export const ConnectedTeamSearchWithTree = injectIntl(
    connect(
        mapStateToPropsForGlobal,
        mapDispatchToProps
    )(TeamSearchWithTree)
);
