import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import Highlighter from 'react-highlight-words';
import FileIconStyle from '../../../utils/FileIconStyle';
import { getUrlPrefix } from '../../../utils/Url';
import Viewer from '../FileMenu/Menu/Viewer';
import SearchRest from '../../../apis/SearchRest';

class Instant extends React.Component {
    constructor(props) {
        super(props);
        this.state = { goMore: false };
        this.realQuery = null;
        this.scrollRef = React.createRef();

        this.debounceCall = _.debounce(query => {
            let call = null;
            if (this.realQuery === query) return;
            this.realQuery = query;
            if (query) call = SearchRest.instant({ q: query });
            else call = SearchRest.searchClick();
            let hQueries;
            if (query.includes('*')) {
                hQueries = query
                    .split('*')
                    .map(qq => qq.trim())
                    .filter(qq => qq);
            } else {
                hQueries = query
                    ? query
                          .split(' ')
                          .map(qq => qq.trim())
                          .filter(qq => qq)
                    : [];
            }

            call.then(list => {
                if (!this.mounted || !list) return;
                const reactList = list.map(file => {
                    const { drive, extra = {}, sharedCount = 0 } = file;
                    const { pathVals = [], matchedContents = [] } = extra;
                    const viewPath = pathVals.map((path, idx) => {
                        if (idx === pathVals.length - 1) {
                            return (
                                <li key={path.objtId} className="breadcrumb-item active" aria-current="page">
                                    {path.objtNm}
                                </li>
                            );
                        }
                        return (
                            <li key={path.objtId} className="breadcrumb-item">
                                {path.objtNm}
                            </li>
                        );
                    });
                    const matchedText = `... ${_.join(matchedContents, ' ... ')} ...`;

                    let fileClassName = '';
                    if (drive.objtSectCd === 'FOLDER') {
                        if (sharedCount) {
                            fileClassName = 'ic-file-sm ic-file-FOLDER-share';
                        } else {
                            fileClassName = 'ic-file-sm ic-file-FOLDER';
                        }
                    } else {
                        fileClassName = FileIconStyle.getStyle('ic-file-sm', drive.objtNm);
                    }
                    return (
                        <div className="grid-row" key={drive.objtId}>
                            <div className="grid-cell data-cell">
                                <div className="item-file">
                                    <i className={fileClassName} />
                                    <div className="file file-link" title={drive.objtNm}>
                                        <span className="file-name" role="button" onClick={() => this.onClick(file)}>
                                            <Highlighter searchWords={hQueries} autoEscape={true} textToHighlight={drive.objtNm} />
                                        </span>
                                    </div>
                                </div>
                                {matchedContents.length > 0 && (
                                    <div className="result-detail">
                                        <Highlighter searchWords={hQueries} autoEscape={true} textToHighlight={matchedText} />
                                    </div>
                                )}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">{viewPath}</ol>
                                </nav>
                            </div>
                        </div>
                    );
                });
                if (query) this.setState({ instantList: reactList });
                else this.setState({ searchClickList: reactList });
            });
        }, 100);
    }

    onClick = file => {
        const { history } = this.props;
        const { drive } = file;
        SearchRest.addSearchClick(drive.objtId, drive.onpstId);
        if (drive.objtSectCd === 'FOLDER') {
            const url = getUrlPrefix(file);
            history.push(`${url}/${drive.objtId}`, { onpstId: drive.onpstId, maxAuthCd: file.maxAuthCd });
        } else {
            new Viewer({}, [file]).execute();
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { goMore } = nextState;
        if (this.props !== nextProps && !goMore) {
            const { query, isInstantOpen } = nextProps;
            if (isInstantOpen) {
                this.debounceCall(query);
            }
        }
        return true;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        this.scrollRef.view.style.minHeight = '350px';
        this.scrollRef.view.style.maxHeight = '350px';
    }

    render() {
        const { query, isInstantOpen, goSearchPage } = this.props;
        const { instantList, searchClickList } = this.state;
        return (
            <div className={`overlay-layer-wrapper slide ${isInstantOpen ? 'show' : ''}`}>
                <div className="overlay-layer">
                    <div className="layer-content">
                        <div className="layer-header hd-gray">
                            <div className="search-instant">
                                {query ? (
                                    <a role="button" onClick={() => goSearchPage({ q: query })}>
                                        <FormattedMessage id="drive.button.search.instant.title" values={{ query: <em title={query}>{query}</em> }} />
                                    </a>
                                ) : (
                                    <h2>
                                        <FormattedMessage id="drive.text.search.searchClick.title" />
                                    </h2>
                                )}
                            </div>
                        </div>
                        <div className="layer-body pd-0">
                            <div className="data-grid-list">
                                <div className="comm-grid-list">
                                    <Scrollbars
                                        ref={ref => {
                                            this.scrollRef = ref;
                                        }}
                                        className="scroll"
                                        autoHeight={true}
                                        autoHeightMin={350}
                                        autoHeightMax={350}>
                                        {(query && instantList && !instantList.length) || (!query && searchClickList && !searchClickList.length) ? (
                                            <div className="box-empty row-5">
                                                <div className="message">
                                                    {query ? (
                                                        <p>
                                                            <FormattedMessage id="drive.text.search.result.empty" />
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            <FormattedMessage id="drive.text.search.searchClick.empty" />
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="grid-row-group row-5">{query ? instantList : searchClickList}</div>
                                        )}
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Instant.defaultProps = {
    query: undefined
};

Instant.propTypes = {
    history: PropTypes.object.isRequired,
    query: PropTypes.string,
    isInstantOpen: PropTypes.bool.isRequired,
    goSearchPage: PropTypes.func.isRequired
};

export default injectIntl(Instant);
