import ActionType from '../actions/FileList';

const initialState = {};

let totalCount = 0;

export const getTotalCount = () => {
    return totalCount;
};

export default (state = initialState, action = {}) => {
    const { type, count, fileListType } = action;
    switch (type) {
        case ActionType.SET_TOTAL:
            totalCount = count;
            return {
                totalCount,
                fileListType
            };
        default:
            return state;
    }
};
