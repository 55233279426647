// action type
const PREFIX = 'GNB_';
const NOTIFICATION = PREFIX.concat('NOTIFICATION_');
export const SET_NOTIFICATION_UNREAD_COUNT = NOTIFICATION.concat('SET_UNREAD_COUNT');

function action(type, payload = {}) {
    return { type, ...payload };
}

// action creator
export const setNotificationUnreadCount = (count = 0, path = '/') => action(SET_NOTIFICATION_UNREAD_COUNT, { count, path });
