import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes, { string } from 'prop-types';
import _ from 'lodash';
import { getUrlPrefix } from '../../../../utils/Url';

// const getDepth = file => {
//     const { extra: { pathValNm = '' } = {} } = file;
//     if (pathValNm === '') return 0;
//     const paths = pathValNm.split('\\');
//     const filtered = _.filter(paths, path => path !== null && path.trim().length > 0);
//     return filtered.length;
// };

const Location = props => {
    const { pathValNm, orgPathValNm, history, detailObject, lastAuthFolderId } = props;

    const move = () => {
        if (detailObject.drive.objtDelCd === 'NORMAL') {
            const file = _.assign({}, detailObject, { extra: { pathValNm: orgPathValNm } });
            const url = getUrlPrefix(file);

            if (lastAuthFolderId === '111100000000000000') {
                history.push(`/own`, { category: 'own', size: 100, sortField: 'objtNm', sortOrder: 'ASC', trackTotal: false });
            } else if (lastAuthFolderId === '111200000000000000') {
                history.push(`/workgroup`, { category: 'workgroup', size: 100, sortField: 'objtNm', sortOrder: 'ASC', trackTotal: false });
            } else if (lastAuthFolderId === '111300000000000000') {
                history.push(`/shared`, { category: 'shared', size: 100, sortField: 'objtNm', sortOrder: 'ASC', trackTotal: false });
            } else {
                history.push(`${url}/${lastAuthFolderId}`, { onpstId: detailObject.drive.onpstId, maxAuthCd: detailObject.maxAuthCd });
            }
        } else {
            alert('drive.alert.favorFolder.alreadyRemoved');
        }
    };

    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="drive.text.place" />
            </dt>
            <dd>
                <div className="item-file">
                    <i className="ic-file-sm ic-file-FOLDER" />
                    <div className="route">
                        <a className="path" role="button" title={pathValNm} onClick={move}>
                            {pathValNm}
                        </a>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    );
};

Location.propTypes = {
    history: PropTypes.object.isRequired,
    pathValNm: string,
    orgPathValNm: string,
    lastAuthFolderId: string,
    detailObject: PropTypes.object
};

Location.defaultProps = {
    pathValNm: '',
    orgPathValNm: '',
    lastAuthFolderId: '',
    detailObject: {}
};

export default Location;
