import React from 'react';
import configCache from 'utils/ConfigCache';
import ConfigCacheKey from 'utils/ConfigCacheKey';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Checky from './Checky';
import ExpiredDate from '../Column/ExpiredDate';
import ExpirationRequestStateCode from '../Column/ExpirationRequestStateCode';
import FileName from '../Column/FileName';
import ChangedDate from '../Column/ChangedDate';
import Size from '../Column/Size';
import UserName from '../Column/UserName';
import MaxAuth from '../Column/MaxAuth';
import FileVerSno from '../Column/FileVerSno';
import PermanentDeleteDueDt from '../Column/PermanentDeleteDueDt';
import ComplianceKeywords from '../Column/ComplianceKeywords';
import ComplianceDetectedDate from '../Column/ComplianceDetectedDate';
import ComplianceRequestStateCode from '../Column/ComplianceRequestStateCode';
import ComplianceResult from '../Column/ComplianceResult';
import CreatedDate from '../Column/CreatedDate';
import DeleteReason from '../Column/DeleteReason';
import DeleteUser from '../Column/DeleteUser';
import DeleteDate from '../Column/DeleteDate';
import { withFilesContext } from '../DriveFilesContext';
import ClickOutside from '../../../presentationals/ClickOutside';
import FileType from '../Column/FileType';
import FolderPath from '../Column/FolderPath';

const columnInfos = [
    { id: 'objtNm', name: 'drive.text.list.columns.objtNm', headerClass: 'files-name-cell', order: 'ASC', bodyComp: FileName },
    { id: 'objtStatChgDt', name: 'drive.text.list.columns.objtStatChgDt', headerClass: 'files-date-cell', order: 'DESC', bodyComp: ChangedDate, width: 160 },
    { id: 'fileType', name: 'drive.text.list.columns.type', headerClass: 'files-type-cell', bodyComp: FileType, width: 90 },
    { id: 'objtRegDt', name: 'drive.text.list.columns.createdDate', order: 'DESC', headerClass: 'files-createdDt-cell', bodyComp: CreatedDate, width: 102 },
    { id: 'expiredDate', name: 'drive.text.list.columns.expiredDate', headerClass: 'files-expireDt-cell', order: 'ASC', bodyComp: ExpiredDate, width: 124 },
    { id: 'onpstNm', name: 'drive.text.list.columns.filePOwnerNm', headerClass: 'files-owner-cell', bodyComp: UserName, width: 90 },
    { id: 'maxAuth', name: 'drive.text.list.columns.maxAuth', headerClass: 'files-authority-cell', bodyComp: MaxAuth, width: 70 },
    { id: 'permDeleteDueDt', name: 'drive.text.list.columns.permanentDeleteDueDt', headerClass: 'files-delDueDt-cell', order: 'ASC', bodyComp: PermanentDeleteDueDt, width: 124 },
    { id: 'fileVerSno', name: 'drive.text.list.columns.fileVerSno', headerClass: 'files-version-cell', bodyComp: FileVerSno, width: 70 },
    { id: 'fileSize', name: 'drive.text.list.columns.fileSize', headerClass: 'files-size-cell', order: 'DESC', bodyComp: Size, width: 70 },
    { id: 'expirationRequestStateCode', name: 'drive.text.list.columns.expirationRequestStateCode', headerClass: 'files-extend-cell', bodyComp: ExpirationRequestStateCode, width: 100 },
    { id: 'complianceDetectedKeywords', name: 'drive.text.list.columns.complianceDetectedKeywords', headerClass: 'files-prohibited-cell', bodyComp: ComplianceKeywords, width: 180 },
    { id: 'complianceResult', name: 'drive.text.list.columns.complianceResult', headerClass: 'files-postProcess-cell', order: 'DESC', bodyComp: ComplianceResult, width: 100 },
    { id: 'complianceRequestStateCode', name: 'drive.text.list.columns.complianceRequestState', headerClass: 'files-except-cell', bodyComp: ComplianceRequestStateCode, width: 90 },
    { id: 'complianceDetectedDate', name: 'drive.text.list.columns.complianceDetectedDate', order: 'DESC', headerClass: 'files-detectTime-cell', bodyComp: ComplianceDetectedDate, width: 100 },
    { id: 'folderPath', name: 'drive.text.list.columns.folderPath', headerClass: 'files-route-cell', bodyComp: FolderPath, width: 279, maxWidth: 800 },
    { id: 'objtDelCd', name: 'drive.text.list.columns.deletereason', headerClass: 'files-delReason-cell', bodyComp: DeleteReason, width: 110 },
    { id: 'objtDelUserNm', name: 'drive.text.list.columns.deleter', headerClass: 'files-deleteer-cell', bodyComp: DeleteUser, width: 110 },
    { id: 'objtDelDt', name: 'drive.text.list.columns.deletedDate', headerClass: 'files-delDt-cell', order: 'DESC', bodyComp: DeleteDate, width: 98 }
    // { id: 'shareTargets', name: 'drive.text.list.columns.shareTargets', headerClass: 'files-sharers-cell', bodyComp: ShareName }
];
// TODO :  설정에따라  금칙어파일 삭제일자 가져와서 해야함

const LOW_HEIGHT = 31;
const HIGH_HEIGHT = 61;
const DEFAULT_HEIGHT = LOW_HEIGHT;

export { LOW_HEIGHT, HIGH_HEIGHT };

class SettingHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.columns = [];
        this.height = DEFAULT_HEIGHT;

        this.state = {
            showSetting: false,
            columns: [],
            height: DEFAULT_HEIGHT
        };

        this.clickOutSideRefs = [React.createRef(), React.createRef()];

        const {
            context: {
                props: { pageClass }
            }
        } = this.props;
        this.pageId = pageClass.pageId;
    }

    componentDidMount() {
        this.dataLoad();
    }

    componentDidUpdate() {
        const {
            context: {
                props: { pageClass }
            }
        } = this.props;
        if (this.pageId !== pageClass.pageId) {
            this.pageId = pageClass.pageId;
            this.dataLoad();
        }
    }

    async dataLoad() {
        const {
            context: {
                props: {
                    pageClass,
                    pageClass: { pageId }
                }
            }
        } = this.props;

        const columns = (await pageClass.getColumns()) || {};
        const customColumns = configCache.getConfig(ConfigCacheKey.column, pageId);
        const customHeight = configCache.getConfig(ConfigCacheKey.rowHeight);
        const customSort = configCache.getConfig(ConfigCacheKey.sort, pageId) || {};

        // 커스텀 크기
        const customWidthById = configCache.getConfig(ConfigCacheKey.columnWidth, pageId) || {};

        const [existColumnsString = '', mandatoryColumnsString = '', defaultColumnsString = ''] = columns;
        const existColumns = existColumnsString.split(',');
        const mandatoryColumns = mandatoryColumnsString.split(',');
        const defaultColumns = defaultColumnsString.split(',');
        const mergeColumns = [...(customColumns || defaultColumns || []), ...mandatoryColumns];

        this.columns = columnInfos
            .filter(col => existColumns.includes(col.id))
            .map(col => {
                const newCol = Object.assign({}, col);
                newCol.width = _.has(customWidthById, newCol.id) ? customWidthById[newCol.id] : col.width;
                newCol.defaultWidth = col.width;
                newCol.checked = false;
                newCol.defaultChecked = false;
                newCol.disabled = false;
                if (mergeColumns.includes(newCol.id)) newCol.checked = true;
                if (defaultColumns.includes(newCol.id)) newCol.defaultChecked = true;
                if (mandatoryColumns.includes(newCol.id)) newCol.disabled = true;
                return newCol;
            });

        const sortColumnInfo = this.columns.filter(col => col.id === customSort.sortField && col.order);
        if (sortColumnInfo.length) {
            // 저장된 정렬값이 유효한 값이면 사용.
            if (!['DESC', 'ASC'].includes(customSort.sortOrder)) customSort.sortOrder = sortColumnInfo[0].order;
        } else {
            // 저장된 정렬값이 없거나, 있지만 유효하지 않으면 기본 컬럼
            const { sortField, sortOrder } = pageClass.getDefaultSortColumn();

            if (existColumns.includes(sortField)) {
                customSort.sortField = sortField;
                customSort.sortOrder = sortOrder;
                // 기본 컬럼까지 유효하지 않은 경우
            } else {
                customSort.sortField = defaultColumns[0]; // eslint-disable-line prefer-destructuring
                customSort.sortOrder = 'ASC';
            }

            /*            // 저장된 정렬값이 없거나, 유효하지 않은 상태가 되었다면, 기본컬럼중 첫번째 날짜컬럼으로 지정
            columnInfos
                .filter(col => defaultColumns.includes(col.id))
                .some(col => {
                    if (col.order && col.id.indexOf('Dt') === col.id.length - 2) {
                        customSort.sortField = col.id;
                        customSort.sortOrder = col.order;
                        return true;
                    }
                    return false;
                });

            // 기본 정렬 컬럼까지도 유효하지 않은 경우. 기본 컬럼중 첫번째 컬럼
            if (!customSort.sortField && defaultColumns.length) {
                customSort.sortField = defaultColumns[0]; // eslint-disable-line prefer-destructuring
                customSort.sortOrder = 'ASC';
            } */
        }

        // 높이 관련 보정
        if (customHeight && HIGH_HEIGHT !== customHeight && LOW_HEIGHT < customHeight) {
            this.height = LOW_HEIGHT;
            configCache.setConfig(ConfigCacheKey.rowHeight, null, this.height);
        } else {
            this.height = customHeight || DEFAULT_HEIGHT;
        }

        const {
            context: {
                actions: { setGridSettings }
            }
        } = this.props;
        setGridSettings({
            viewColumns: this.columns.filter(col => col.checked),
            viewHeight: this.height,
            sort: customSort,
            columnWidths: this.columns.filter(col => col.checked).map(col => Object.assign({}, { id: col.id, width: col.width }))
        });
    }

    handleToggleClick = force => {
        let { showSetting } = this.state;
        if (force !== undefined) {
            showSetting = force;
        } else {
            showSetting = !showSetting;
        }

        if (showSetting) {
            this.setState({
                showSetting,
                columns: this.columns.map(col => Object.assign({}, col)),
                height: this.height,
                changed: false
            });
        } else {
            this.setState({ showSetting });
        }
    };

    // 높이변경
    onSelect = height => {
        this.setState({ height, changed: true });
    };

    // 컬럼설정
    toggle = column => {
        column.checked = !column.checked; // eslint-disable-line no-param-reassign
        const { columns } = this.state;
        this.setState({ columns: Object.assign([], columns), changed: true });
    };

    setToDefault = () => {
        let { columns } = this.state;
        columns = columns.map(col => {
            col.checked = col.defaultChecked; // eslint-disable-line no-param-reassign
            col.width = col.defaultWidth; // eslint-disable-line no-param-reassign
            return col;
        });
        this.setState({
            columns,
            height: DEFAULT_HEIGHT,
            changed: true
        });
    };

    saveChanges = () => {
        const { columns, height } = this.state;
        this.columns = columns.map(col => Object.assign({}, col));
        this.height = height;
        const {
            context: {
                actions: { setGridSettings }
            }
        } = this.props;
        setGridSettings({
            viewColumns: columns.filter(col => col.checked),
            viewHeight: height,
            columnWidths: columns.filter(col => col.checked).map(col => Object.assign({}, { id: col.id, width: col.width }))
        });
        this.handleToggleClick();
    };

    render() {
        const { showSetting, columns, height, changed } = this.state;
        const {
            context: {
                state: { viewColumns }
            }
        } = this.props;
        return (
            <div className="grid-cell files-option-cell">
                <div className="has-overlay-layer right">
                    <ClickOutside onClickOutside={() => this.handleToggleClick(false)} childrenRefs={this.clickOutSideRefs}>
                        {viewColumns.length > 0 && (
                            <FormattedMessage id="drive.text.list.columnSet.title">
                                {msg => (
                                    <a ref={this.clickOutSideRefs[0]} className={showSetting ? 'btn-ic-nor active' : 'btn-ic-nor'} role="button" title={msg} onClick={() => this.handleToggleClick()}>
                                        <i className="ic-20-setting" />
                                    </a>
                                )}
                            </FormattedMessage>
                        )}
                        <div ref={this.clickOutSideRefs[1]} className={showSetting ? 'overlay-layer-wrapper show' : 'overlay-layer-wrapper'}>
                            <div className="overlay-layer">
                                <div className="layer-content layer-sm">
                                    <div className="layer-header hd-gray">
                                        <h1 className="layer-title">
                                            <FormattedMessage id="drive.text.list.columnSet.title" />
                                        </h1>
                                        <a className="btn-ic-nor" role="button" title="닫기" onClick={() => this.handleToggleClick()} id="columnSet-close">
                                            <i className="ic-20-close" />
                                        </a>
                                    </div>
                                    <div className="layer-body">
                                        <dl className="col-list">
                                            <dt>
                                                <FormattedMessage id="drive.text.list.columnSet.seleted" />
                                            </dt>
                                            <dd>
                                                <div className="form-col-2">
                                                    {columns.map(column => (
                                                        <Checky key={column.id} nameKey={column.name} checked={column.checked} disabled={column.disabled} onChange={() => this.toggle(column)} />
                                                    ))}
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl className="col-list">
                                            <dt>
                                                <FormattedMessage id="drive.text.list.columnSet.height" />{' '}
                                            </dt>
                                            <dd>
                                                <div className="form-col-2">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                onChange={() => this.onSelect(LOW_HEIGHT)}
                                                                checked={height === LOW_HEIGHT}
                                                            />
                                                            <span className="label-text">
                                                                <FormattedMessage id="drive.text.list.columnSet.height.small" />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                onChange={() => this.onSelect(HIGH_HEIGHT)}
                                                                checked={height === HIGH_HEIGHT}
                                                            />
                                                            <span className="label-text">
                                                                <FormattedMessage id="drive.text.list.columnSet.height.big" />
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="layer-footer">
                                        <div className="layer-footer-option">
                                            <a className="btn-ic-line" role="button" onClick={this.setToDefault}>
                                                <i className="ic-20-reset" />
                                                <span className="btn-text">
                                                    {' '}
                                                    <FormattedMessage id="com.reset" />
                                                </span>
                                            </a>
                                        </div>
                                        <div className="layer-footer-btns">
                                            <a className="btn btn-md btn-secondary" role="button" onClick={() => this.handleToggleClick()}>
                                                <span className="btn-text">
                                                    <FormattedMessage id="com.cancel" />
                                                </span>
                                            </a>
                                            <a className={`btn btn-md btn-primary ${changed ? '' : 'disabled'}`} role="button" onClick={this.saveChanges}>
                                                <span className="btn-text">
                                                    <FormattedMessage id="com.button.apply" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ClickOutside>
                </div>
            </div>
        );
    }
}

SettingHeader.propTypes = {
    context: PropTypes.object.isRequired
};

SettingHeader.displayName = 'SettingHeader';
export default withFilesContext(SettingHeader, ['state.viewColumns', 'props.folderId']);
