import React from 'react';
import { object } from 'prop-types';
import { Choose, When } from 'jsx-control-statements';
import DateConverter from '../../../../utils/DateConverter';
import TypeName from './TypeName';

const HistoryDefaultType = props => {
    const { history } = props;
    let className = '';
    if ((history.evtSectCd === 'CREATE' || history.evtSectCd === 'VERSIONUP' || history.evtSectCd === 'VERSIONRVT') && history.objtSectCd === 'FILE') className = 'ic-20-upload';
    if (history.evtSectCd === 'CREATE' && history.objtSectCd === 'FOLDER') className = 'ic-20-newfolder';
    if (history.evtSectCd === 'CREATE_FILE_LINK' || history.evtSectCd === 'DELETE_FILE_LINK') className = 'ic-20-link';
    if (history.evtSectCd === 'DOWNLOAD') className = 'ic-20-download';
    if (history.evtSectCd === 'OPEN_VIEWER') className = 'ic-20-open';
    if (history.evtSectCd === 'SUMMARY') className = 'ic-20-copilot';

    const views = [DateConverter.convert(history.evtOccuDt)];

    return (
        <React.Fragment>
            <dd>
                <span className="btn-ic-nor">
                    <i className={className} />
                </span>
                <TypeName type={history.evtSectCd} objtSectCd={history.objtSectCd} />
                <div className="dd-detail history-who">
                    <div className="g-inline-flex">
                        <div className="ellipsis" title={history.evtReqstrNm}>
                            {history.evtReqstrNm}
                        </div>
                        <Choose>
                            <When condition={history.objtSectCd === 'FILE'}>
                                <div className="next divider">
                                    <span className="version" title={history.fileVerSno}>
                                        v{history.fileVerSno}
                                    </span>
                                </div>
                            </When>
                        </Choose>
                        <div className="next divider">{views}</div>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    );
};

HistoryDefaultType.propTypes = {
    history: object
};

HistoryDefaultType.defaultProps = {
    history: {}
};

export default HistoryDefaultType;
