import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import PeriodButton from './PeriodButton';
import FlcmUtil from '../../../../utils/FlcmUtil';

const ExpirationDateSelector = props => {
    const { user, onSelectExpirationDate, onResetChangeByCalendar, changeByCalendar } = props;

    const [activeButtonIdx, setActiveButtonIdx] = useState(-1);

    const changeExpirationDateFromTodayTo = duration => {
        const date = moment().add(duration);
        onSelectExpirationDate(date);
    };

    // 개인 보존기한 정책에 의한 최대 만료일자
    const changeExpirationDateToMax = () => {
        const { maxExpiredDate } = FlcmUtil.getExpiredDateInfos(user);

        onSelectExpirationDate(maxExpiredDate);
    };

    const isActiveButton = buttonIdx => {
        return !changeByCalendar && activeButtonIdx === buttonIdx;
    };

    return (
        <div className="btn-group" role="group">
            <PeriodButton
                buttonLabelId="drive.text.exception.period.month.six"
                isActive={() => isActiveButton(0)}
                onClick={() => {
                    changeExpirationDateFromTodayTo({ months: 6 });
                    setActiveButtonIdx(0);
                    onResetChangeByCalendar();
                }}
            />
            <PeriodButton
                buttonLabelId="drive.text.exception.period.year.one"
                isActive={() => isActiveButton(1)}
                onClick={() => {
                    changeExpirationDateFromTodayTo({ years: 1 });
                    setActiveButtonIdx(1);
                    onResetChangeByCalendar();
                }}
            />
            <PeriodButton
                buttonLabelId="drive.text.exception.period.max"
                isActive={() => isActiveButton(2)}
                onClick={() => {
                    changeExpirationDateToMax();
                    setActiveButtonIdx(2);
                    onResetChangeByCalendar();
                }}
            />
        </div>
    );
};

ExpirationDateSelector.propTypes = {
    user: PropTypes.object.isRequired,
    onSelectExpirationDate: PropTypes.func.isRequired,
    onResetChangeByCalendar: PropTypes.func.isRequired,
    changeByCalendar: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(ExpirationDateSelector);
