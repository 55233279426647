import React from 'react';
import BaseMenu from '../BaseMenu';
import FileVersion from '../../FileVersion/FileVersion';
import { popup } from '../../../../utils/ModalService';

const executor = (props, files) => {
    popup(<FileVersion files={files} />).then(data => {
        if (data) {
            props.callback(data);
        }
    });
};

class VersionManager extends BaseMenu {
    getName() {
        return 'versionManager';
    }

    getLabel() {
        return 'drive.button.list.menu.versionManager';
    }

    isVisible() {
        return super.isVisible(false, 'viewList', 'FILE');
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-version', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), null, 'ic-20-version', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default VersionManager;
