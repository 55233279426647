import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class SelectBox extends React.Component {
    componentDidMount() {
        window.$.fn.selectpicker.Constructor.BootstrapVersion = '4';

        this.$el = window.$(this.el);
        this.$el.selectpicker();
    }

    componentDidUpdate(prevProps) {
        const { options, value, isDisabled } = this.props;
        if (prevProps.options !== options || prevProps.value !== value || prevProps.isDisabled !== isDisabled) {
            this.$el.selectpicker('refresh');
        }
    }

    componentWillUnmount() {
        this.$el.selectpicker('destroy');
    }

    handleChange = e => {
        const { onChange } = this.props;
        onChange(e.target.value);
    };

    render() {
        const { options, inputRef, value, dataWidth, dataContainer, isDisabled, type, style, title, activeStyle } = this.props;

        return (
            <span className={`select-type-${type}`} style={style}>
                <div className="dropdown bootstrap-select">
                    <select
                        className="selectpicker"
                        data-width={dataWidth}
                        data-container={dataContainer}
                        ref={el => {
                            this.el = el;
                            inputRef(el);
                        }}
                        value={value}
                        disabled={isDisabled ? 'disabled' : ''}
                        title={title}
                        onChange={this.handleChange}>
                        {options.map(item => (
                            <option key={String(item.value)} value={item.value} hidden={item.hidden} style={value === item.value ? activeStyle : {}}>
                                {item.message}
                            </option>
                        ))}
                    </select>
                </div>
            </span>
        );
    }
}

SelectBox.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    inputRef: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataContainer: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
    activeStyle: PropTypes.object
};

SelectBox.defaultProps = {
    options: [],
    onChange: _.noop,
    inputRef: _.noop,
    value: '',
    dataWidth: '',
    dataContainer: '',
    isDisabled: false,
    type: 'box',
    style: {},
    title: '',
    activeStyle: {}
};

export default SelectBox;
