import React from 'react';
import DriveBody from '../containers/DriveBody';
import DriveHeader from '../containers/DriveHeader';
import Sharing from '../../utils/DriveConfiguration/Sharing';

const SharingFiles = props => {
    const pageClass = Sharing;
    return (
        <div className="page-drv">
            <DriveHeader {...props} type="sharing" pageClass={pageClass} />
            <DriveBody {...props} pageClass={pageClass} />
        </div>
    );
};

export default SharingFiles;
