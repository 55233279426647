import { notice } from '../actions';

const initialState = {
    noticeSearched: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case notice.SEARCHED:
            return {
                noticeSearched: true
            };
        default:
            return state;
    }
};
