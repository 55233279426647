import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FavoriteItem from './FavoriteItem';

const FavoriteCard = props => {
    const { list, gridElement, isLoaded, history, reloadAfterChanged, reloadAfterShared, onClickViewMoreButton, viewMore } = props;
    const onClickViewMore = () => {
        const nextOffSetIndex = list.length;
        onClickViewMoreButton(nextOffSetIndex);
    };

    return (
        <>
            <div className="grid" ref={gridElement}>
                {list.map(folder => (
                    <FavoriteItem
                        key={`${folder.drive.objtId}_${folder.sharedCount}`}
                        folder={folder}
                        isLoaded={isLoaded}
                        history={history}
                        reloadAfterChanged={reloadAfterChanged}
                        reloadAfterShared={reloadAfterShared}
                    />
                ))}
            </div>
            {viewMore && (
                <a className="btn btn-secondary btn-block" role="button" onClick={onClickViewMore}>
                    <span className="btn-text">
                        <FormattedMessage id="com.viewmore" />
                    </span>
                </a>
            )}
        </>
    );
};

FavoriteCard.propTypes = {
    list: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    reloadAfterChanged: PropTypes.func.isRequired,
    reloadAfterShared: PropTypes.func.isRequired,
    onClickViewMoreButton: PropTypes.func.isRequired,
    gridElement: PropTypes.string.isRequired,
    viewMore: PropTypes.bool.isRequired
};

export default FavoriteCard;
