import React, { useMemo, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { If } from 'jsx-control-statements';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SummaryTooltipIcon from '../../../../../Common/Tooltip/SummaryTooltipIcon';
import {
    ERROR_RESULT_STATUS_LIST,
    REGENERATABLE_ERROR_CODES,
    REGENERATABLE_RESULT_STATUS_LIST,
    SUMMARY_GENERATION_MODE,
    SUMMARY_RESULTS_COUNT_LIMIT,
    SUMMARY_TOOLTIP_DIRECTION
} from '../../../../../Common/SummaryConstant';
import ResultNavButtons from './ResultNavButtons';
import { summaryActions } from '../../../../../../../../redux/actions/Summary';
import { OPTIONS_DIALOG_POSITION_TYPE, SUMMARY_OPTIONS } from '../../../../../OptionSelectDialog/SummaryOptionConstant';

const SummaryResultFunctionButtonGroup = ({ archived, files, summaryResult, resultTotalCount, resultSeq, onNavigate, intl }) => {
    const { resultStatus, errorCode } = summaryResult;

    const optionSelectDialogOpenButtonRef = useRef();

    const selectedLLM = useSelector(({ summary }) => summary.global.selectedLLM);

    const dispatch = useDispatch();

    const isRegenerateButtonsShowing = useMemo(() => {
        const isNotArchived = !archived;
        const isRegeneratableResultStatus = REGENERATABLE_RESULT_STATUS_LIST.includes(resultStatus);
        const isRegeneratableErrorCode = ERROR_RESULT_STATUS_LIST.includes(resultStatus) && REGENERATABLE_ERROR_CODES.includes(errorCode);

        return isNotArchived && (isRegeneratableResultStatus || isRegeneratableErrorCode);
    }, [archived, resultStatus]);

    const handleClickRegenerateButton = () => {
        dispatch(summaryActions.optionSelectDialog.close());

        if (resultTotalCount >= SUMMARY_RESULTS_COUNT_LIMIT) {
            dispatch(summaryActions.limitConfirmDialog.open());
            return;
        }

        dispatch(summaryActions.thread.regenerateAIMessage({ changedSummaryOptions: selectedLLM }));
    };

    const handleClickModifyOptionButton = () => {
        dispatch(
            summaryActions.optionSelectDialog.open({
                positionType: OPTIONS_DIALOG_POSITION_TYPE.around,
                generationMode: SUMMARY_GENERATION_MODE.conditional,
                exceptOptionList: [SUMMARY_OPTIONS.llm],
                files,
                optionSelectDialogOpenButtonRef
            })
        );
    };

    return (
        <div className="ai-set-option">
            <div className="ai-set-option-btns" style={{ visibility: isRegenerateButtonsShowing ? '' : 'hidden' }}>
                {/* <FeedbackButtons summaryResult={currentSummaryResult} summaryResultSeq={currentSummaryResultSeq} /> */}
                <div className="ai-option-reset">
                    <a className="btn-ic-radius" role="button" title={intl.formatMessage({ id: 'drive.button.copilot.summary.retry' })} onClick={handleClickRegenerateButton}>
                        <i className="ic-20-copilot-regenerate" />
                    </a>
                    <div className="has-overlay-layer bottom" ref={optionSelectDialogOpenButtonRef}>
                        <a className="btn-ic-radius" role="button" title={intl.formatMessage({ id: 'drive.button.copilot.summary.modify_options' })} onClick={handleClickModifyOptionButton}>
                            <i className="ic-20-copilot-modify" />
                        </a>
                    </div>
                </div>
            </div>
            <If condition={!archived && resultTotalCount === SUMMARY_RESULTS_COUNT_LIMIT}>
                <SummaryTooltipIcon direction={SUMMARY_TOOLTIP_DIRECTION.left} content={{ messageId: 'drive.tooltip.copilot.summary.limit' }} />
            </If>
            <ResultNavButtons summaryResult={summaryResult} summaryResultTotalCount={resultTotalCount} summaryResultSeq={resultSeq} onNavigate={onNavigate} />
        </div>
    );
};

SummaryResultFunctionButtonGroup.propTypes = {
    files: PropTypes.array.isRequired,
    archived: PropTypes.bool,
    summaryResult: PropTypes.object.isRequired,
    resultTotalCount: PropTypes.number.isRequired,
    resultSeq: PropTypes.number.isRequired,
    onNavigate: PropTypes.func.isRequired
};

SummaryResultFunctionButtonGroup.defaultProps = {
    archived: false
};

export default injectIntl(SummaryResultFunctionButtonGroup);
