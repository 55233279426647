import { $http } from 'modules/index';
import _ from 'lodash';
// import AUTH from 'constants/Auth';
// import Editor from '../components/presentationals/editor/Editor';
// import React from 'react';

export const REDIRECT_LOGIN_PAGE = '/auth/v1/login';
export const NORMALIZE_URL = '/auth/v1/auth/token/normalize';
export const GET_TOKEN_STATUS_URL = '/auth/v1/auth/token/status';
export const GET_LOGIN_INFO = '/auth/v1/auth/loginInfo';
export const DELETE_TOKEN = '/auth/v1/auth/token';
export default class AuthRest {
    static postTokenNormalize() {
        return $http.post(NORMALIZE_URL);
    }

    static getTokenStatus() {
        return $http
            .createWithoutResponseInterceptor()
            .get(GET_TOKEN_STATUS_URL)
            .then(response => response.data);
    }

    static getLoginInfo() {
        return $http.get(GET_LOGIN_INFO);
    }

    static deleteToken() {
        return $http.delete(DELETE_TOKEN);
    }

    static getToken() {
        return $http.post('/auth/v1/auth/token');
    }

    static getDomainInfo(host) {
        return $http.get(`/auth/v1/domains/auth?host=${host}`);
    }

    static getTokenBySessionFilter(langCd) {
        return $http.post('/auth/v1/auth/token/session-filter', { langCd });
    }

    static deleteHttpSession(context) {
        return $http.delete(`/${context}/v1/auth/token/session-filter`, {});
    }

    static agreeForTerm(termsData) {
        let termsInfo = [];

        termsInfo = termsData.map(column => {
            return { termsId: _.get(column, 'termsId', ''), termsTypeSectCd: _.get(column, 'termsTypeSectCd', ''), termsRegDt: _.get(column, 'termsRegDt', '') };
        });
        const input = { agreeForTerms: 'Y', termsList: termsInfo };

        console.log('rest ', input);
        return $http.post(GET_TOKEN_STATUS_URL, input);
    }

    static agreeForNewTerm() {
        const input = { agreeForNewTerms: 'Y' };
        return $http.post(GET_TOKEN_STATUS_URL, input);
    }

    static compareUserInfoWithToken(tuid) {
        return $http.post('/auth/v1/users/compare/token', { token: tuid });
    }
}
