import { $http } from 'modules/index';
import AuthService from 'utils/AuthService';

const preFix = '/drive/v1';

class FolderRest {
    static getFoldertree(folderId) {
        return $http.get(`${preFix}/foldertree/${folderId}`);
    }

    static getFolderInfo(folderId) {
        const { id } = AuthService.getLoginUser();
        if (id === folderId) return Promise.resolve({ objtId: folderId, pathVal: `/${folderId}`, pathValNm: '/내파일' });
        return $http.get(`${preFix}/folder/${folderId}`).then(({ data }) => data);
    }

    static createFolder(destFolder, objtNm) {
        const { objtId, onpstId } = destFolder;
        return $http.post(`${preFix}/folder`, { onpstFolderId: objtId, onpstId, objtNm });
    }

    static getFolderSize(objtId, onpstId) {
        const params = {
            onpstId
        };
        return $http.get(`${preFix}/folder/total-size/${objtId}`, { params });
    }

    static createFolderWorkgroupRequest(params) {
        const { approverId, requestReason, workgroupName, allocUseSize, approvers } = params;
        return $http.post(`${preFix}/folder/CREATE_WORKGROUP/request`, { approverId, reqRsn: requestReason, objtNm: workgroupName, allocUseSize, approvers });
    }

    static changeWorkgroupDateRequest(params) {
        const { approverId, onpstId, requestReason, expiredDt, objtId } = params;
        return $http.post(`${preFix}/folder/WORKGROUP_RETENTION_DATE/request`, { approverId, onpstId, objtId, reqRsn: requestReason, retentionDt: expiredDt.format('YYYYMMDD') });
    }

    static getWorkgroupRequestApprover(type) {
        return $http.get(`${preFix}/folder/${type}/request/approver`);
    }

    static getRequestSystemType(type) {
        return $http.get(`${preFix}/folder/${type}/request/system`).then(response => response, error => error);
    }

    static getRequestApproverCandidates(type) {
        return $http.get(`${preFix}/folder/${type}/request/approver/candidates`);
    }

    static getCfgWorkgroupCreatableYn() {
        const code = 'EFL_COM_WG_CREATE_USE_YN';
        return $http.get(`/common/v1/configs/cfg/${code}`);
    }

    static getCfgWorkgroupCreateApproveUseYn() {
        const code = 'EFL_COM_WG_CREATE_APPROVE_USE_YN';
        return $http.get(`/common/v1/configs/cfg/${code}`);
    }
}

export default FolderRest;
