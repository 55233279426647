import React, { memo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const RequiredLabel = memo(({ messageId, intl }) => {
    return (
        <>
            <FormattedMessage id={messageId} />
            <em className="critical-item" title={intl.formatMessage({ id: 'drive.text.search.filter.requiredItem' })} style={{ marginLeft: '0.25rem' }} />
        </>
    );
});

RequiredLabel.propTypes = {
    messageId: PropTypes.string.isRequired
};

export default injectIntl(RequiredLabel);
