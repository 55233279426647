import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MoreRow = props => {
    const { style, loadMore } = props;
    return (
        <div className="grid-row btns-row" style={style}>
            <div className="grid-cell">
                <a className="btn btn-secondary btn-block" role="button" onClick={() => loadMore()}>
                    <span className="btn-text">
                        <FormattedMessage id="drive.button.list.menu.more" />
                    </span>
                </a>
            </div>
        </div>
    );
};

MoreRow.propTypes = {
    style: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired
};

export default MoreRow;
