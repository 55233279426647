import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const SearchPath = props => {
    const { file, goToFolder } = props;

    const { extra = {} } = file;
    const { pathVals = [] } = extra;

    const viewPath = pathVals.map((path, idx) => {
        const child = (
            <a role="button" onClick={() => goToFolder(pathVals, idx, file)}>
                {path.objtNm}
            </a>
        );
        if (idx === pathVals.length - 1) {
            return (
                <li key={path.objtId} className="breadcrumb-item active" aria-current="page">
                    {child}
                </li>
            );
        }
        return (
            <li key={path.objtId} className="breadcrumb-item">
                {child}
            </li>
        );
    });

    return (
        <div className="g-inline-flex">
            <div className="title">
                <FormattedMessage id="drive.text.list.columns.path" /> :
            </div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">{viewPath.length ? viewPath : '-'}</ol>
            </nav>
        </div>
    );
};

SearchPath.propTypes = {
    file: PropTypes.object.isRequired,
    goToFolder: PropTypes.func.isRequired
};

export default SearchPath;
