import React from 'react';
import moment from 'moment/moment';
import FileExtRest from '../apis/FileExtRest';
import AuthService from './AuthService';

const IconFilterKey = {
    IconFilterExtNm: 'iconFilterConfig'
};

const IconFilterDefault = {
    filterGroup: {
        Powerpoint: ['pps', 'ppt', 'pptm', 'pot', 'ppsx', 'pptx', 'potx'],
        Excel: ['xlsm', 'xls', 'xlsx', 'xltx', 'csv', 'xlsb', 'xlt'],
        Word: ['docm', 'docx', 'dotx', 'doc', 'dot'],
        PDF: ['pdf'],
        이미지: ['jpeg', 'png', 'bmp', 'jpg', 'tif', 'tiff', 'gif', 'psd', 'ai', 'xd'],
        동영상: ['avi', 'mpg', 'mp4', 'nar', 'wmv', 'ray', 'mov', 'mpeg'],
        압축: ['7z', 'alz', 'rar', 'zip', 'apk'],
        노트패드: ['rft', 'txt'],
        한글: ['hwt', 'hml', 'hst', 'hwp'],
        웹: ['htm', 'html', 'mht'],
        기타: [
            'ala ',
            'chm ',
            'css ',
            'dcm ',
            'dd2 ',
            'ddd ',
            'dotm ',
            'drt ',
            'dsd ',
            'dwg ',
            'dxf ',
            'dxp ',
            'egg ',
            'eml ',
            'erwin ',
            'exe ',
            'gul ',
            'gwp ',
            'gz ',
            'indd ',
            'jar ',
            'js ',
            'log ',
            'mp3 ',
            'mpp ',
            'msg ',
            'mysingle ',
            'nopms ',
            'odp ',
            'odt ',
            'ppa ',
            'ppam ',
            'ppsm ',
            'pre ',
            'pst ',
            'qvf ',
            'rpm ',
            'rqd ',
            'rtf ',
            'sql ',
            'stp ',
            'svg ',
            'tmp ',
            'twdx ',
            'vsd ',
            'vsdx ',
            'wav ',
            'wma ',
            'xltm ',
            'xlx ',
            'xml ',
            'z01 ',
            'z02 ',
            'z03 '
        ]
    },
    iconGroup: {
        docm: 'WORD',
        docx: 'WORD',
        dotx: 'WORD',
        doc: 'WORD',
        dot: 'WORD',
        xlsm: 'EXCEL',
        xls: 'EXCEL',
        xlsx: 'EXCEL',
        xltx: 'EXCEL',
        csv: 'EXCEL',
        xlsb: 'EXCEL',
        xlt: 'EXCEL',
        pps: 'PPT',
        ppt: 'PPT',
        pptm: 'PPT',
        pot: 'PPT',
        ppsx: 'PPT',
        pptx: 'PPT',
        potx: 'PPT',
        pdf: 'PDF',
        jpeg: 'IMAGE',
        png: 'IMAGE',
        bmp: 'IMAGE',
        jpg: 'IMAGE',
        tif: 'IMAGE',
        tiff: 'IMAGE',
        gif: 'IMAGE',
        psd: 'GRAPHIC',
        ai: 'GRAPHIC',
        xd: 'GRAPHIC',
        avi: 'VIDEO',
        mpg: 'VIDEO',
        mp4: 'VIDEO',
        nar: 'VIDEO',
        wmv: 'VIDEO',
        ray: 'VIDEO',
        mov: 'VIDEO',
        mpeg: 'VIDEO',
        rft: 'NOTEPAD',
        txt: 'NOTEPAD',
        hwt: 'HWP',
        hml: 'HWP',
        hst: 'HWP',
        hwp: 'HWP',
        htm: 'WEB',
        html: 'WEB',
        mht: 'WEB',
        '7z': 'ZIP',
        alz: 'ZIP',
        rar: 'ZIP',
        zip: 'ZIP',
        apk: 'ZIP'
    },
    langCd: '001'
};

const EXPIRE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const FileExt = () => {
    function getExpireDate() {
        return moment()
            .utcOffset(0)
            .add(1, 'days')
            .format(EXPIRE_FORMAT);
    }

    function setFilterAndIconGroup() {
        const expireDt = getExpireDate();
        FileExtRest.getFileExtFilterAndIconGroup(true, true).then(
            result => {
                const { filterGroup, iconGroup, langCd } = result.data;
                const extGroup = { filterGroup, iconGroup, langCd, expireDt };
                localStorage.setItem(IconFilterKey.IconFilterExtNm, JSON.stringify(extGroup));
            },
            () => {
                IconFilterDefault.expireDt = expireDt;
                localStorage.setItem(IconFilterKey.IconFilterExtNm, JSON.stringify(IconFilterDefault));
            }
        );
    }

    function removeFilterAndIconGroup() {
        localStorage.removeItem(IconFilterKey.IconFilterExtNm);
    }

    function isExpired(expireDt) {
        if (!expireDt) return true;
        const expire = moment(expireDt, EXPIRE_FORMAT);
        const now = moment().utcOffset(0);
        return expire.isBefore(now);
    }

    const iconFilterGroupStorage = localStorage.getItem(IconFilterKey.IconFilterExtNm);
    if (iconFilterGroupStorage == null) {
        setFilterAndIconGroup();
    } else {
        const iconFilterGroup = JSON.parse(iconFilterGroupStorage);
        const { locale } = AuthService.getLoginUser();

        // 언어설정 바뀌었을 경우 리로드
        if (iconFilterGroup.langCd !== locale.languageCode) {
            removeFilterAndIconGroup();
            setFilterAndIconGroup();
        }

        // 하루지나면 리로드
        if (isExpired(iconFilterGroup.expireDt)) {
            removeFilterAndIconGroup();
            setFilterAndIconGroup();
        }
    }
    return <></>;
};
export default FileExt;
