import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import FileMenuAction from '../../../redux/actions/FileMenu';
import MenuMapper from '../../../utils/FileMenu/MenuMapper';

function findAncestorElm(elm, ...classNames) {
    let target = elm;
    let idx = 0;
    const lastIdx = classNames.length - 1;

    do {
        if (target.classList.contains(classNames[idx])) {
            if (lastIdx === idx) return true;
            idx += 1;
        }
        target = target.parentElement;
    } while (target);

    return false;
}

function findSiblingElm(elm, className) {
    if (!elm || !elm.parentElement) return false;
    const childrenElms = elm.parentElement.children;

    for (let idx = 0; idx < childrenElms.length; idx += 1) {
        if (childrenElms[idx].classList.contains(className)) return true;
    }
    return false;
}

function findWithChildElm(elm, elmClassName, childClassName) {
    if (!elm || !elm.children || !elm.children.length) return false;
    if (!elm.classList.contains(elmClassName)) return false;

    const childrenElms = elm.children;

    for (let idx = 0; idx < childrenElms.length; idx += 1) {
        if (childrenElms[idx].classList.contains(childClassName)) return true;
    }
    return false;
}

/**
 * openType
 *   normal : 우클릭용. 기준점 우측에 뜨고, top 0px 영역기준으로 뜸
 *   left : 우측상단 더보기용. 기준점 좌측 하단으로 뜸
 *   right : 기준점 우측 하단으로 뜸
 *   normal_left : Row 우측 더보기용. 기준점 좌측에 뜨고, top 0px 영역기준으로 뜸
 */
class FileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.menuMaxHeight = () => window.innerHeight + window.pageYOffset - 15;
        this.menuMaxWidth = () => window.innerWidth + window.pageXOffset - 15;

        // 페이지 이동시, 자동으로 닫기 처리
        const { history = {} } = this.props;
        const { location = { pathname: '' } } = history;
        this.historyListner = null;
        this.openPath = location.pathname;

        // 클릭, 우클릭, 휠 스크롤시, 닫기 처리
        this.prevStatusCallback = null;
        this.closeByClickListner = this.closeByClick.bind(this);
        this.closeByContextListner = this.closeByContext.bind(this);
        this.closeByWheelListner = this.closeByWheel.bind(this);
    }

    closeByLocation(location = { pathname: '' }) {
        const { close, isOpen } = this.props;
        if (isOpen && (this.openPath === '' || this.openPath !== location.pathname)) {
            close();
        }
    }

    closeByClick(event) {
        const { close, isOpen, statusCallback } = this.props;
        if (isOpen) {
            // 파일 행 더보기 버튼 클릭시, 이전 상태 콜백 호출
            if (findAncestorElm(event.target, 'option-more', 'files-option') && this.prevStatusCallback) {
                this.prevStatusCallback(false);
            }
            // 우측 상단 더보기 버튼 클릭시, 이전 상태 콜백 호출
            else if (findAncestorElm(event.target, 'filter-option') && this.prevStatusCallback) {
                if (findSiblingElm(event.target, 'ic-20-more') || findWithChildElm(event.target, 'btn-ic-nor', 'ic-20-more')) {
                    this.prevStatusCallback(false);
                }
            }
            // 그 외, 닫기
            else {
                statusCallback(false);
                close();
            }
        }
    }

    closeByContext(event) {
        const { close, isOpen, statusCallback } = this.props;
        if (isOpen) {
            const isContextMenu = findAncestorElm(event.target, 'comm-dropdown-menu');
            const isGridRow = findAncestorElm(event.target, 'grid-row', 'grid-row-group');

            // 파일 메뉴 오른쪽 클릭시, 무시
            if (isContextMenu) {
                event.preventDefault();
            }
            // 파일 행 오른쪽 클릭시, 이전 상태 콜백 호출
            else if (isGridRow && this.prevStatusCallback) {
                this.prevStatusCallback(false);
            }
            // 파일 목록, 파일 메뉴가 아닌 경우, 닫기
            else if (!isGridRow && !isContextMenu) {
                statusCallback(false);
                close();
            }
        }
    }

    closeByWheel() {
        const { close, isOpen, statusCallback } = this.props;
        if (isOpen) {
            statusCallback(false);
            close();
        }
    }

    componentDidMount() {
        const { close, history } = this.props;
        window.addEventListener('resize', close);
        if (!this.historyListner) {
            this.historyListner = history.listen(this.closeByLocation.bind(this));
        }
    }

    componentWillUnmount() {
        const { close } = this.props;
        window.removeEventListener('resize', close);
        if (this.historyListner) {
            this.historyListner();
        }
    }

    componentDidUpdate(prevProps) {
        const { openType = 'normal', isOpen, statusCallback, history } = this.props;
        statusCallback(isOpen);
        if (isOpen) {
            const { location = { pathname: '' } } = history;
            this.openPath = location.pathname;

            this.calcuOffset(openType);

            this.prevStatusCallback = prevProps.statusCallback;
            document.addEventListener('click', this.closeByClickListner);
            document.addEventListener('contextmenu', this.closeByContextListner);
            document.addEventListener('wheel', this.closeByWheelListner);
        } else {
            this.openPath = '';

            prevProps.statusCallback(false);

            this.prevStatusCallback = null;
            document.removeEventListener('click', this.closeByClickListner);
            document.removeEventListener('contextmenu', this.closeByContextListner);
            document.removeEventListener('wheel', this.closeByWheelListner);
        }
    }

    calcuOffset(openType) {
        if (this.menuRef) {
            const { eventObj = {} } = this.props;

            const { currentTarget } = eventObj;
            const rect = currentTarget.getBoundingClientRect();
            const win = currentTarget.ownerDocument.defaultView;
            const targetTop = rect.top + win.pageYOffset;
            const targetLeft = rect.left + win.pageXOffset;
            const targetHeight = currentTarget.offsetHeight;
            const targetWidth = currentTarget.offsetWidth;

            const pageTop = eventObj.pageY;
            const pageLeft = eventObj.pageX;

            const height = this.menuRef.offsetHeight;
            const width = this.menuRef.offsetWidth;
            const gap = 2;

            const top0Position = window.pageYOffset;
            const left0Position = window.pageXOffset;
            let top = top0Position;
            let left = left0Position;
            if (openType === 'normal') {
                top = pageTop - height - gap < top0Position ? top0Position : pageTop - height - gap;
                // this.menuRef.style.top = `${pageTop - height - gap < 0 ? 0 : pageTop - height - gap}px`;
                if (pageLeft + width + gap > window.innerWidth) {
                    // this.menuRef.style.left = `${pageLeft - width - gap}px`;
                    left = pageLeft - width - gap;
                } else {
                    // this.menuRef.style.left = `${pageLeft + gap}px`;
                    left = pageLeft + gap;
                }
            } else if (openType === 'normal_left') {
                top = pageTop - height - gap < top0Position ? top0Position : pageTop - height - gap;
                left = targetLeft - width - gap;
            } else if (openType === 'left') {
                top = targetTop + targetHeight + gap;
                left = targetLeft + targetWidth + gap - width;
            } else if (openType === 'right') {
                top = targetTop + targetHeight + gap;
                left = targetLeft - gap;
            }
            if (this.scrollRef != null && top + height > this.menuMaxHeight()) {
                const inHeight = this.menuMaxHeight() - top;
                if (inHeight < 100) {
                    top = pageTop - height - gap < top0Position ? top0Position : pageTop - height - gap;
                    left = targetLeft - width - gap;
                } else {
                    this.scrollRef.view.style.maxHeight = `${inHeight}px`;
                }
            }
            if (left + width > this.menuMaxWidth()) {
                left = this.menuMaxWidth() - width;
            }
            this.menuRef.style.top = `${top - window.pageYOffset}px`;
            this.menuRef.style.left = `${left - window.pageXOffset}px`;
            this.menuRef.style.opacity = 1;
        }
    }

    render() {
        const { isOpen = false, data = {}, close, callback: propsCallback, statusCallback, loginUser, configs: siteConfigs } = this.props;
        const { groupType = 'normal', files = [], custom = {} } = data;
        let { callback } = data;
        if (!callback) {
            callback = propsCallback;
        }
        const styleValue = { display: 'block', opacity: 0, zIndex: 5050, right: 'auto', top: 0, left: 0 };
        const { config: userConfig } = loginUser;
        custom.closeContextMenu = close;
        custom.userConfig = userConfig;
        custom.loginUser = loginUser;
        custom.getSiteConfig = id => (_.find(siteConfigs, { id }) || {}).value;

        // files로 menus생성
        let menus = [];
        if (files.length) {
            menus = new MenuMapper({ groupType, callback, custom }, files).getMenus();
        }

        // setTimeout(() => {
        //     statusCallback(isOpen, files);
        // });

        return (
            <>
                {isOpen && menus.length > 0 && (
                    <React.Fragment>
                        <div
                            className="comm-dropdown-menu"
                            style={styleValue}
                            ref={ref => {
                                this.menuRef = ref;
                            }}
                            role="button"
                            onClick={() => close({ statusCallback })}>
                            <Scrollbars
                                className="scroll"
                                autoHeight={true}
                                autoHeightMax={this.menuMaxHeight()}
                                ref={ref => {
                                    this.scrollRef = ref;
                                }}>
                                {files.length > 1 && (
                                    <h6 className="context-header">
                                        <strong>{files.length}</strong>
                                        <FormattedMessage id="drive.text.list.selected" values={{ count: '' }} />
                                    </h6>
                                )}
                                <nav>{menus}</nav>
                            </Scrollbars>
                        </div>
                    </React.Fragment>
                )}
            </>
        );
    }
}

FileMenu.defaultProps = {
    isOpen: false,
    openType: 'normal',
    eventObj: undefined,
    statusCallback: () => {},
    data: undefined,
    callback: () => {}
};

FileMenu.propTypes = {
    isOpen: bool,
    openType: string,
    eventObj: object,
    statusCallback: func,
    data: object,
    callback: func,
    close: func.isRequired,
    loginUser: object.isRequired,
    history: object.isRequired,
    configs: object.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.fileMenu.isOpen,
    openType: state.fileMenu.openType,
    eventObj: state.fileMenu.eventObj,
    statusCallback: state.fileMenu.statusCallback,
    data: state.fileMenu.data,
    loginUser: state.auth.user,
    configs: state.config.configs
});

export default connect(
    mapStateToProps,
    { close: props => ({ type: FileMenuAction.CLOSE, ...props }) }
)(FileMenu);
