import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CriticalItemDt from 'presentationals/Row/CriticalItemDt';
import SelectBox from '../../../presentationals/SelectBox';
import FileLifeCycleRest from '../../../../apis/FileLifeCycleRest';

class RequestBodyApprover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            targets: [],
            target: ''
        };
    }

    componentDidMount() {
        this.initRequestData();
    }

    initRequestData() {
        this.getApprover();
    }

    getApprover = () => {
        const { intl, searchTgt } = this.props;
        FileLifeCycleRest.getApprover(searchTgt).then(response => {
            const { resultCode, data } = response;
            let targets;
            if (resultCode !== 200) {
                targets = [{ userId: '', userNm: intl.formatMessage({ id: 'drive.placeHolder.exception.target' }), deptNm: '' }];
            } else {
                targets = data;
                targets.splice(0, 0, { userId: '', userNm: intl.formatMessage({ id: 'drive.placeHolder.exception.target' }), deptNm: '' });
            }
            this.setState({ targets }, () => {
                if (targets.length === 2) {
                    this.onChangeTarget(targets[1].userId);
                }
            });
        });
    };

    onChangeTarget = value => {
        const { changeTarget } = this.props;
        this.setState({ target: value }, changeTarget(value));
    };

    render() {
        const { intl } = this.props;
        const { targets, target } = this.state;
        const targetList = _.map(targets, user => {
            const { userId: value, userNm, deptNm } = user;
            const message = deptNm === '' ? userNm : `${userNm} / ${deptNm}`;
            return { value, message, hidden: value === '' };
        });

        return (
            <React.Fragment>
                <dl className="row-list">
                    <CriticalItemDt text={intl.formatMessage({ id: 'drive.text.exception.target' })} />
                    <dd>
                        <SelectBox options={targetList} onChange={this.onChangeTarget} value={target} type="box" isDisabled={targetList.length - 1 < 1} />
                        <div className="info-msg">
                            <i className="ic-16-info" />
                            {intl.formatMessage({ id: 'drive.guideText.exception.target' })}
                        </div>
                    </dd>
                </dl>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

RequestBodyApprover.propTypes = {
    intl: PropTypes.object,
    changeTarget: PropTypes.func,
    searchTgt: PropTypes.string
};

RequestBodyApprover.defaultProps = {
    intl: {},
    changeTarget: () => {},
    searchTgt: null
};

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RequestBodyApprover));

export default connected;
