import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import RequestPopup from '../../Popup/RequestPopup';
import Request from '../../../../constants/Request';
import FileReload from '../../../../utils/FileReload';

class ExpirationRequestStateCode extends React.Component {
    componentDidUpdate(prevProps) {
        const { file = {} } = this.props;
        const { extra = {} } = file;
        const { req: { reqStatCd = '' } = {} } = extra;

        const { file: prevFile = {} } = prevProps;
        const { extra: prevExtra = {} } = prevFile;
        const { req: { reqStatCd: prevReqStatCd = '' } = {} } = prevExtra;

        if (reqStatCd !== prevReqStatCd) {
            console.log('prevReqStatCd', prevReqStatCd, 'reqStatCd', reqStatCd);
        }
    }

    getClass = code => {
        switch (code) {
            case 'PROCESSING':
                return 'state-ing';
            case 'REJECTED':
                return 'state-reject';
            default:
                return 'state-keep';
        }
    };

    getCodeName = code => {
        const { intl } = this.props;
        switch (code) {
            case 'PROCESSING':
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.processing' });
            case 'REJECTED':
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.rejected' });
            default:
                return intl.formatMessage({ id: 'drive.text.expiration.requestState.none' });
        }
    };

    reload = () => {
        const { file } = this.props;
        FileReload.call(undefined, [file], { reloadType: 'cached', reloadReq: true });
    };

    openPopup = event => {
        event.stopPropagation();

        const {
            file: {
                extra: {
                    req: { reqId = '' }
                }
            }
        } = this.props;
        if (reqId === '') {
            return;
        }

        RequestPopup.open({
            mode: Request.MODE.VIEW,
            type: Request.TYPE.EXTENDS_EXPIRATION,
            reqId
        }).then(
            result => {
                this.reload(result);
            },
            () => {}
        );
    };

    convertReqToConfig = code => {
        switch (code) {
            case 'REJECT':
                return 'REJECTED';
            case 'PROCESSING':
                return 'PROCESSING';
            default:
                return 'NONE';
        }
    };

    render() {
        const { file = {}, intl, cellStyle } = this.props;
        const { extra = {} } = file;
        const { req: { reqStatCd = '', isByMe = false } = {} } = extra;
        const requestStatCode = this.convertReqToConfig(reqStatCd);
        const requestState = this.getCodeName(requestStatCode);

        return (
            <>
                <div className="grid-cell files-extend-cell" style={cellStyle}>
                    <em className={`${this.getClass(requestStatCode)} ellipsis`} title={requestState}>
                        {requestState}
                    </em>
                    {requestStatCode === 'PROCESSING' && isByMe && (
                        <a className="btn btn-sm btn-secondary" role="button" onClick={this.openPopup}>
                            <span className="btn-text">{intl.formatMessage({ id: 'drive.button.list.columns.inquiry' })}</span>
                        </a>
                    )}
                </div>
            </>
        );
    }
}

ExpirationRequestStateCode.defaultProps = {
    cellStyle: null
};

ExpirationRequestStateCode.propTypes = {
    file: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default injectIntl(ExpirationRequestStateCode);
