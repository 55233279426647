import React, { memo, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { SUMMARY_GENERATION_MODE } from '../../../../containers/Summary/Common/SummaryConstant';

const SingleFileSummaryMenu = memo(({ positionType, files, iconType, intl }) => {
    const optionSelectDialogOpenButtonRef = useRef();

    const dispatch = useDispatch();

    const getIconClassName = () => {
        switch (iconType) {
            case 'header':
            case 'row':
                return 'ic-20-copilot-summary';
            default:
                return 'ic-12-copilot-summary';
        }
    };

    const getMenuLabel = label => {
        if (iconType === 'row') {
            return '';
        }

        return <span className="btn-text">{label}</span>;
    };

    const handleClick = () => {
        dispatch(summaryActions.optionSelectDialog.open({ generationMode: SUMMARY_GENERATION_MODE.generation, positionType, files, optionSelectDialogOpenButtonRef }));
    };

    const renderIcon = label => {
        return (
            <a className="btn-ic-nor" role="button" ref={optionSelectDialogOpenButtonRef} onClick={handleClick} title={intl.formatMessage({ id: 'drive.button.list.menu.summary' })}>
                <i className={getIconClassName()} />
                {getMenuLabel(label)}
            </a>
        );
    };

    return (
        <FormattedMessage id="drive.button.list.menu.single_file_summary">
            {label => (
                <Choose>
                    <When condition={iconType === 'row'}>
                        <li className="nav-item">{renderIcon(label)}</li>
                    </When>
                    <Otherwise>{renderIcon(label)}</Otherwise>
                </Choose>
            )}
        </FormattedMessage>
    );
});

SingleFileSummaryMenu.propTypes = {
    positionType: PropTypes.string,
    files: PropTypes.object,
    iconType: PropTypes.string
};

SingleFileSummaryMenu.defaultProps = {
    positionType: '',
    files: [],
    iconType: null
};

export default injectIntl(SingleFileSummaryMenu);
