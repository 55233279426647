import React from 'react';
import { object, func } from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage } from 'react-intl';
import CharacterImageContainer from '../../../containers/CharacterImage/CharacterImageContainer';
import { largePopup } from '../../../../utils/ModalService';
import SharePopup from '../../../containers/Popup/Share/SharePopup';
import FileViewerRest from '../../../../apis/FileViewerRest';
import InShareRest from '../../../../apis/InShareRest';
import { DEFAULT_SHARE_AUTH_MAP } from '../../../../constants/ShareAuth';

const maxLength = 7; // 자기 자신 포함

const convertToRowForm = item => {
    return [
        {
            drive: item,
            actionPolicy: item.sharePolicy
        }
    ];
};

const ShareTarget = props => {
    const { info = { shareTgtList: [] }, reload } = props;
    const { shareTgtList } = info;
    const isMoreThanMax = shareTgtList.length > maxLength;
    const workgroupList = info.onpstSectCd === 'SYSTFOLDER' && isMoreThanMax ? shareTgtList.slice(0, maxLength) : shareTgtList.slice(0);
    const otherList = info.onpstSectCd !== 'SYSTFOLDER' && isMoreThanMax ? shareTgtList.slice(1, maxLength) : shareTgtList.slice(1);
    const list = info.onpstSectCd === 'SYSTFOLDER' ? workgroupList : otherList;

    const handleClick = async () => {
        const webViewerYn = await FileViewerRest.getViewerUseYn();
        const shareConfig = (await InShareRest.selectConfig()) || {};
        const config = Object.assign({}, DEFAULT_SHARE_AUTH_MAP, shareConfig);

        largePopup(<SharePopup files={convertToRowForm(info)} webViewerYn={webViewerYn} config={config} />).then(data => reload(data), () => false);
    };
    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.sharePeople" />
            </dt>
            <dd>
                <div className="members" role="button" onClick={handleClick}>
                    {list.length > 0 &&
                        list.map(tgt => (
                            <CharacterImageContainer
                                key={uuidv4()}
                                objtId={tgt.objtShareTgtPhotoFilePathVal ? tgt.objtShareTgtPhotoFilePathVal : ''}
                                userId={tgt.objtShareTgtId}
                                text={tgt.objtShareTgtNm}
                                size="md"
                                userType={tgt.objtShareTgtUserSectCd}
                                characterType={tgt.objtShareTgtSectCd === 'INDIVIDUAL' ? 'user' : 'team'}
                            />
                        ))}
                    {isMoreThanMax && (
                        <span className="user-thumbnail-md the-rest">
                            <i className="num">+{shareTgtList.length - maxLength}</i>
                        </span>
                    )}
                    {list.length === 0 && (
                        <a>
                            <FormattedMessage id="drive.text.noSharePeoPle" />
                        </a>
                    )}
                </div>
            </dd>
        </>
    );
};

ShareTarget.propTypes = {
    info: object,
    reload: func
};

ShareTarget.defaultProps = {
    info: {},
    reload: () => {}
};

export default ShareTarget;
