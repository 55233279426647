import React from 'react';
import PropTypes from 'prop-types';
import SizeConverter from '../../../../utils/SizeConverter';

const Size = props => {
    const {
        file: { drive, version = {} },
        cellStyle,
        pageClass: { pageId }
    } = props;

    const size = version.fileSize || drive.fileSize;
    const value = drive.objtSectCd === 'FOLDER' || !size ? '-' : SizeConverter.convert(size);
    const title = pageId === 'duplicated' ? SizeConverter.withComma(size || 0) : value;
    return (
        <div className="grid-cell files-size-cell" style={cellStyle} title={title}>
            {value}
        </div>
    );
};

Size.defaultProps = {
    cellStyle: null,
    pageClass: {}
};

Size.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any,
    pageClass: PropTypes.object
};

Size.displayName = 'Size';
export default React.memo(Size);
