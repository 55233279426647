import React, { memo, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { SUMMARY_PROMPT_TEMPLATE_TYPE } from '../../../../containers/Summary/Common/SummaryConstant';
import { getPromptTemplateAddedFileCount } from '../../../../containers/Summary/Common/Util/SummaryUtil';

const SummaryFileSelectMenu = memo(({ files, iconType, labelId, intl }) => {
    const INDEX_FIRST_FILE = 0;
    const INDEX_SECOND_FILE = 1;

    const optionSelectDialogOpenButtonRef = useRef();

    const promptInput = useSelector(({ summary }) => summary.promptInput);

    const dispatch = useDispatch();

    const getIconClassName = () => {
        switch (iconType) {
            case 'header':
            case 'row':
                return 'ic-20-copilot-summary';
            default:
                return 'ic-12-copilot-summary';
        }
    };

    const getMenuLabel = label => {
        if (iconType === 'row') {
            return '';
        }

        return <span className="btn-text">{label}</span>;
    };

    const handleClick = () => {
        const { promptTemplate } = promptInput;
        const { promptTemplateType, templateData } = promptTemplate;
        const { files: addedFiles } = templateData;

        switch (promptTemplateType) {
            case SUMMARY_PROMPT_TEMPLATE_TYPE.single:
            case SUMMARY_PROMPT_TEMPLATE_TYPE.singleWithKeyword:
                // 템플릿에 이미 추가된 파일이 1개가 존재하는 경우 -> 파일 재선택 컨펌 다이얼로그
                if (getPromptTemplateAddedFileCount(addedFiles) === 1) {
                    dispatch(summaryActions.sidePanel.open());
                    dispatch(summaryActions.fileReSelectConfirmDialog.open({ files }));
                    return;
                }

                dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files } }));
                break;
            // 프롬프트 인풋 템플릿이 다건 형태
            case SUMMARY_PROMPT_TEMPLATE_TYPE.multiple:
            case SUMMARY_PROMPT_TEMPLATE_TYPE.multipleWithKeyword:
                // 선택되는 파일이 2개를 초과하게 되는 경우
                // 다시 말하면, 템플릿에 이미 추가된 파일이 2개가 존재하거나, 추가된 파일이 1개면서 추가할 파일이 2개인 경우 -> 파일 재선택 컨펌 다이얼로그
                if (getPromptTemplateAddedFileCount(addedFiles) === 2 || (getPromptTemplateAddedFileCount(addedFiles) === 1 && files.length === 2)) {
                    dispatch(summaryActions.sidePanel.open());
                    dispatch(summaryActions.fileReSelectConfirmDialog.open({ files }));
                    return;
                }

                if (files.length === 2) {
                    // 템플릿에 추가할 파일을 2개 선택한 경우
                    dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files } }));
                } else if (addedFiles[INDEX_FIRST_FILE]) {
                    // 템플릿에 추가할 파일을 1개 선택한 경우
                    // 템플릿에는 파일 2개를 세팅할 수 있는 칸 2개가 존재
                    // 따라서 첫번째 칸에 이미 세팅된 경우, 현재 추가할 파일 1개는 두번째 칸에 세팅
                    dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files: [addedFiles[INDEX_FIRST_FILE], files[0]] } }));
                } else if (addedFiles[INDEX_SECOND_FILE]) {
                    // 반대로 두번째 칸에 이미 세팅된 경우, 첫번째 칸에 세팅
                    dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files: [files[0], addedFiles[INDEX_SECOND_FILE]] } }));
                } else {
                    // 템플릿에 추가할 파일을 1개 선택했고, 세팅된 파일이 없는 경우
                    dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files: [files[0]] } }));
                }

                break;
            default:
                break;
        }

        dispatch(summaryActions.sidePanel.open());
    };

    const renderIcon = label => {
        return (
            <a className="btn-ic-nor" role="button" ref={optionSelectDialogOpenButtonRef} onClick={handleClick} title={intl.formatMessage({ id: labelId })}>
                <i className={getIconClassName()} />
                {getMenuLabel(label)}
            </a>
        );
    };

    return (
        <>
            <FormattedMessage id={labelId}>
                {label => (
                    <Choose>
                        <When condition={iconType === 'row'}>
                            <li className="nav-item">{renderIcon(label)}</li>
                        </When>
                        <Otherwise>{renderIcon(label)}</Otherwise>
                    </Choose>
                )}
            </FormattedMessage>
        </>
    );
});

SummaryFileSelectMenu.propTypes = {
    files: PropTypes.object,
    iconType: PropTypes.string,
    labelId: PropTypes.string.isRequired
};

SummaryFileSelectMenu.defaultProps = {
    files: [],
    iconType: null
};

export default injectIntl(SummaryFileSelectMenu);
