import React from 'react';
import PropTypes from 'prop-types';
import DateConverter from 'utils/DateConverter';

const DeleteUser = props => {
    const {
        file: { drive },
        cellStyle
    } = props;

    const { objtDelDt } = drive;
    const value = DateConverter.convertDeletedDate(objtDelDt);

    return (
        <div className="grid-cell files-delDt-cell" style={cellStyle} title={value}>
            {value}
        </div>
    );
};

DeleteUser.defaultProps = {
    cellStyle: null
};

DeleteUser.propTypes = {
    file: PropTypes.object.isRequired,
    cellStyle: PropTypes.any
};

export default DeleteUser;
