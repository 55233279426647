import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { Choose, Otherwise, When } from 'jsx-control-statements';
import { injectIntl } from 'react-intl';
import { toCompactedFileInfo } from '../../Common/Util/SummaryUtil';
import { getFileIconByExtensionName } from '../../Common/Util/PromptTemplateUtil';
import { summaryActions } from '../../../../../redux/actions/Summary';

// TODO refactor
const FileItem = ({ searchedFileName, file, fileInputIndex, intl }) => {
    // TODO toCompactedFileInfo 대신 drive 기존 object convert 유틸이 있어야함
    const { name, extensionName } = toCompactedFileInfo(file);
    const { drive: { objtId, onpstId, lastModDt } = {}, users: { onpst: { userNm } = {} } = {}, extra: { authorizedPath: { pathValNm } = {} } = {} } = file;

    const timezoneDiff = useSelector(({ auth }) => auth.user.locale.timezoneDiff);
    const files = useSelector(({ summary }) => summary.promptInput.promptTemplate.templateData.files);

    const dispatch = useDispatch();

    const { fileDescription, compactedDateTime, parentFolderName, fileOwnerName } = useMemo(() => {
        return {
            fileDescription: `${name}.${extensionName}\n${intl.formatMessage({ id: 'drive.title.copilot.summary.pathLabel' })}: ${pathValNm}`,
            compactedDateTime: moment(lastModDt)
                .utcOffset(timezoneDiff)
                .format('MM-DD HH:mm'),
            parentFolderName: _.nth(_.split(pathValNm, '/'), -2),
            fileOwnerName: userNm
        };
    }, [file]);

    const handleClick = () => {
        const newFiles = [...files];
        newFiles[fileInputIndex] = {
            name,
            extensionName,
            objtId,
            onpstId
        };

        dispatch(summaryActions.promptInput.putDataToPromptTemplate({ templateData: { files: newFiles } }));
        dispatch(summaryActions.fileListBox.close());
    };

    return (
        <li onClick={handleClick}>
            <div className="item-file" title={fileDescription} role="button">
                <i className={classNames('ic-file-ml', getFileIconByExtensionName(extensionName))} />
                <div className="file">
                    <span className="file-name">
                        <Choose>
                            <When condition={_.isEmpty(searchedFileName)}>{name}</When>
                            <Otherwise>
                                <Highlighter searchWords={[searchedFileName]} autoEscape={true} textToHighlight={name} />
                            </Otherwise>
                        </Choose>
                    </span>
                    {extensionName && <span className="file-extension">{extensionName}</span>}
                </div>
                <ul className="file-detail">
                    <li>
                        <span>{fileOwnerName}</span>
                    </li>
                    <li>
                        <span>{compactedDateTime}</span>
                    </li>
                    <li>
                        <span className="item-folder-name">{parentFolderName}</span>
                    </li>
                </ul>
            </div>
        </li>
    );
};

FileItem.propTypes = {
    searchedFileName: PropTypes.string,
    file: PropTypes.object.isRequired,
    fileInputIndex: PropTypes.number.isRequired
};

FileItem.defaultProps = {
    searchedFileName: ''
};

export default injectIntl(FileItem);
