import _ from 'lodash';
import CryptoJS from 'crypto-js';
import AuthService from 'utils/AuthService';

const EXTS = {
    WRITE: {
        EDITOR: ['hwp', 'hwpx', 'hml', 'hst', 'hwt', 'doc', 'docx', 'docm', 'dotx', 'dot', 'odt'],
        VIEWER: ['txt', 'rtf']
    },
    CALC: {
        EDITOR: ['xls', 'xlsx', 'xlsb', 'xlsm', 'xlt', 'xltx', 'ods', 'cell'],
        VIEWER: ['csv']
    },
    SHOW: {
        EDITOR: ['ppt', 'pptx', 'pptm', 'ppsx', 'pps', 'potx', 'pot', 'odp', 'show'],
        VIEWER: []
    }
};

const LANGUAGE_TAG = {
    '001': 'ko',
    '002': 'en',
    '003': 'en',
    '004': 'zh'
};

const CFG_ID = {
    USE_YN: 'EFL_DRV_WEBOFFICE_USE_YN',
    URL: 'EFL_DRV_WEBOFFICE_URL'
};

const TYPE = {
    VIEWER: 'VIEWER',
    EDITOR: 'EDITOR'
};

const DEFAULT_URL = 'http://182.193.17.66/cloud-office/api/fw/';

const POLICY_KEYS_BY_TYPE = {
    VIEWER: ['openFile', 'viewFileWithViewer'],
    EDITOR: ['versionup']
};

const CONSTANTS = {
    CFG_ID,
    TYPE,
    DEFAULT_URL
};

class WebOfficeUtil {
    static getUseYn = (configs = []) => {
        const webOfficeYn = _.find(configs, ['id', CFG_ID.USE_YN]);
        return webOfficeYn === undefined ? 'N' : webOfficeYn;
    };

    static getUrlPrefix = (configs = []) => {
        const url = _.find(configs, ['id', CFG_ID.URL]);
        return url === undefined ? DEFAULT_URL : url;
    };

    static getUrl = (objtInfo = {}, loginUser = {}, appKey = undefined, reqType = 'open', prefix = DEFAULT_URL) => {
        const { objtNm } = objtInfo;
        const params = this.getParams(objtInfo, loginUser, appKey);
        return `${prefix}${objtNm}/${reqType}?${params}`;
    };

    static getParams = (objtInfo = {}, loginUser = {}, appKey = undefined) => {
        const { objtId } = objtInfo;
        const { locale = {} } = loginUser;
        const { languageCode = '002' } = locale;

        const tuid = AuthService.getAuthToken();
        const chip = AuthService.getSignatureKey();
        const signature = encodeURIComponent(CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(`${objtId}_${loginUser.id}`, chip)));
        const lang = LANGUAGE_TAG[languageCode] ? LANGUAGE_TAG[languageCode] : LANGUAGE_TAG['002'];

        const encodedUserNm = encodeURIComponent(loginUser.name);
        const encodedTuid = encodeURIComponent(tuid);

        console.log('encodedUserNm', encodedUserNm, 'encodedTuid', encodedTuid);
        console.log('tuid', tuid, 'signature', signature);

        return `app=${appKey}&user_id=${encodedUserNm}&docId=${objtId}&lang=${lang}&tuid=${encodedTuid}&signature=${signature}`;
    };

    static getAppKey = (objtInfo = {}, type = TYPE.EDITOR) => {
        const ext = this.getExtension(objtInfo.objtNm).toLowerCase();

        const types = [type];
        if (type === TYPE.VIEWER) {
            types.push(TYPE.EDITOR);
        }

        const appKey = _.findKey(EXTS, extMap => {
            return _.findIndex(types, t => extMap[t].indexOf(ext) !== -1) !== -1;
        });
        return appKey !== undefined ? `${appKey}_${type}` : undefined;
    };

    static getEditorKey = (objtInfo = {}) => this.getAppKey(objtInfo, TYPE.EDITOR);

    static getViewerKey = (objtInfo = {}) => this.getAppKey(objtInfo, TYPE.VIEWER);

    static getExtension = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(dotIndex + 1, fileName.length);
        }

        return '';
    };

    static getFileName = fileName => {
        if (_.isEmpty(fileName)) {
            return '';
        }

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex > -1) {
            return fileName.substring(0, dotIndex);
        }

        return fileName;
    };

    static isEnable = (objtInfo = {}, policy = {}, type = TYPE.EDITOR) => {
        if (!objtInfo.objtNm || objtInfo.objtSectCd === 'FOLDER') return false;

        const policyKeys = POLICY_KEYS_BY_TYPE[type];
        const hasAuth = _.every(policyKeys, policyKey => _.has(policy, policyKey) && policy[policyKey]);
        return hasAuth && this.getAppKey(objtInfo, type) !== undefined;
    };

    static isViewerEnable = (objtInfo = {}, policy = {}) => this.isEnable(objtInfo, policy, TYPE.VIEWER);

    static isEditorEnable = (objtInfo = {}, policy = {}) => this.isEnable(objtInfo, policy, TYPE.EDITOR);
}

export default WebOfficeUtil;

export { CONSTANTS as WEBOFFICE_CONSTANTS };
