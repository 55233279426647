import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FileUploader from '../../../utils/Fileuploader/FileUploader';
import { createFileFromFileItem } from '../Upload/UploadManager';
import Filters from '../../../utils/Fileuploader/Filters';
import { globalPopup } from '../../../redux/actions';

class VersionUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.uploader = null;
        this.fileInputRef = React.createRef();
        this.onFilesSelected = this.onFilesSelected.bind(this);
    }

    componentDidMount() {
        const { file, onAfterAddingFile, onChangeStateUploadItem, onSuccessUploadItem } = this.props;
        const { objtId, onpstId } = file.drive;
        const formData = { objtId, onpstId };

        const fileuploader = new FileUploader({ formData, filters: Filters.getFilters(['size', 'ext']) });

        fileuploader.onAfterAddingFile = fileItem => {
            onAfterAddingFile(createFileFromFileItem(fileItem));
        };

        fileuploader.onWhenAddingFileFailedAll = fails => {
            Filters.filterdItemsHandler(fails, fileuploader);
        };

        fileuploader.onProgressItem = fileItem => {
            onChangeStateUploadItem(createFileFromFileItem(fileItem));
        };

        fileuploader.onSuccessItem = () => {
            onSuccessUploadItem();
            this.uploader.clearQueue();
        };

        fileuploader.onErrorItem = fileItem => {
            onChangeStateUploadItem(createFileFromFileItem(fileItem));
        };

        this.uploader = fileuploader;
    }

    componentWillUnmount() {
        this.uploader.clearQueue();
    }

    onFilesSelected(evt) {
        const { openAlert } = this.props;

        if (!!evt.target.files && evt.target.files.length > 0) {
            const files = [...evt.target.files];

            const oldFileName = this.getOldFileName();
            const isAllExtensionSame = files.every(({ name: newFileName }) => this.isExtensionSame(oldFileName, newFileName));

            if (!isAllExtensionSame) {
                openAlert({ id: 'drive.alert.verHistory.invalidExt' });
                return;
            }

            this.fileInputRef.current.value = '';
            this.uploader.addToQueue(files);
        }
    }

    clearQueue = () => {
        this.uploader.clearQueue();
    };

    getOldFileName = () => {
        const { file } = this.props;
        const { drive: { objtNm = '' } = {} } = file;
        return objtNm;
    };

    getAllowedExtension = () => {
        return this.getExtensionFromFileName(this.getOldFileName());
    };

    getExtensionFromFileName = (objtNm = '') => {
        return objtNm.substring(objtNm.lastIndexOf('.')).toLowerCase();
    };

    isExtensionSame = (oldFileName, newFileName) => {
        return this.getExtensionFromFileName(oldFileName) === this.getExtensionFromFileName(newFileName);
    };

    render() {
        let disable = true;
        const { isRevert, uploading, actionPolicy } = this.props;
        if (actionPolicy.versionup && !isRevert && !uploading) {
            disable = false;
        }

        return (
            <span className={`file-upload${disable ? ' upload-ing' : ''}`}>
                <input ref={this.fileInputRef} type="file" className="upload" onChange={this.onFilesSelected} disabled={disable} accept={this.getAllowedExtension()} />
                <a className={`btn btn-secondary${disable ? ' disabled' : ''}`} role="button">
                    <span className="btn-text">
                        <FormattedMessage id="drive.button.versionUpdate" />
                    </span>
                </a>
            </span>
        );
    }
}

VersionUpdate.propTypes = {
    file: PropTypes.object,
    actionPolicy: PropTypes.object,
    isRevert: PropTypes.bool,
    uploading: PropTypes.bool,
    onAfterAddingFile: PropTypes.func,
    onSuccessUploadItem: PropTypes.func,
    onChangeStateUploadItem: PropTypes.func,
    openAlert: PropTypes.func.isRequired
};

VersionUpdate.defaultProps = {
    file: {},
    actionPolicy: {},
    isRevert: false,
    uploading: false,
    onAfterAddingFile: () => {},
    onSuccessUploadItem: () => {},
    onChangeStateUploadItem: () => {}
};

const mapDispatchToProps = {
    openAlert: globalPopup.openAlert
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(VersionUpdate)
);
