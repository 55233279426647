import { Route } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import DriveFilesHeader from '../../containers/DriveFiles/DriveFilesHeader';
import DriveFilesBody from '../../containers/DriveFiles/DriveFilesBody';
import Url from '../../../utils/Url';

const DuplicatedBody = props => {
    const { className, style, isHidden } = props;
    return (
        <div className={className} style={style}>
            {!isHidden && <DriveFilesHeader />}
            <Route path={Url.duplicated} component={DriveFilesBody} />
            <Route path={Url.similar} component={DriveFilesBody} />
        </div>
    );
};

DuplicatedBody.propTypes = {
    className: PropTypes.string,
    style: PropTypes.string,
    isHidden: PropTypes.bool
};

DuplicatedBody.defaultProps = {
    className: '',
    style: '',
    isHidden: false
};

export default DuplicatedBody;
