import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import RecycleBinRest from 'src/apis/RecycleBinRest';
import { confirm, toast } from 'src/utils/ModalService';
import BaseMenu from '../BaseMenu';

const CONSTANTS = {
    CFG_ID: 'EFL_DRV_PERMANENT_DELETE_USE_YN'
};

const executor = (props, files) => {
    function complete() {
        toast('drive.toast.permDelete.complete');
        props.callback();
    }

    function fail() {
        toast('drive.toast.permDelete.fail');
    }

    function permDeleteObjts() {
        return RecycleBinRest.deletePermanentObjtList(
            files.map(({ drive }) => {
                return { objtId: drive.objtId, onpstId: drive.onpstId, onpstFolderId: drive.onpstFolderId };
            })
        );
    }

    confirm(<FormattedMessage id="drive.confirm.permDelete" />).then(result => (result ? permDeleteObjts().then(complete, fail) : _.noop));
};

class PermanentDelete extends BaseMenu {
    getName() {
        return 'permanentDelete';
    }

    getLabel() {
        return 'drive.button.list.menu.permanentDelete';
    }

    isVisible() {
        const {
            custom: { getSiteConfig = _.noop }
        } = this.props;
        const useYn = getSiteConfig(CONSTANTS.CFG_ID);
        return useYn !== 'N';
    }

    isEnable() {
        return true;
    }

    getDom() {
        return this.getBaseMenuDom(this.getName(), 'ic-12-delete', executor);
    }

    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'option-share', 'ic-20-delete', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default PermanentDelete;
