import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SUMMARY_FEEDBACK_TYPE } from '../../Common/SummaryConstant';
import { summaryActions } from '../../../../../redux/actions/Summary';
import { showSummaryToast } from '../../Common/Util/SummaryToastUtil';

const FeedbackDialog = ({ intl }) => {
    const { isOpen, feedbackType, summaryResultSeq } = useSelector(({ summary }) => summary.feedbackDialog, shallowEqual);
    const dispatch = useDispatch();

    const handleClickCloseButton = () => {
        dispatch(summaryActions.feedbackDialog.close());
    };

    const handleClickOkButton = () => {
        dispatch(summaryActions.thread.selectFeedback({ feedbackType, summaryResultSeq }));
        dispatch(summaryActions.feedbackDialog.close());
        showSummaryToast(dispatch, { messageId: '피드백을 보냈습니다.' });
    };

    return (
        <div className={classnames('modal', 'fade', isOpen && 'show')} role="dialog" aria-hidden="true" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">피드백 보내기</h5>
                        <a className="btn-ic-nor" role="button" title={intl.formatMessage({ id: 'com.close' })} onClick={handleClickCloseButton}>
                            <i className="ic-20-copilot-close" />
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="box-text">
                            <p>
                                {feedbackType === SUMMARY_FEEDBACK_TYPE.like
                                    ? '어떤 점에서 만족했는지 설명해주세요. (선택)'
                                    : '더 나은 답변을 제공할 수 있도록 문제 상황을 가능한 자세하게 설명해 주세요. (선택)'}
                            </p>
                        </div>
                        <div className="form-group">
                            <textarea
                                className="form-control textarea-sm"
                                placeholder={
                                    feedbackType === SUMMARY_FEEDBACK_TYPE.like
                                        ? '피드백 작성 시 사적인 정보나 민감한 내용은 포함하지 않도록 주의해주세요.'
                                        : '예시) 질문 의도와 다른 답변이 제공됨, 관련없는 출처가 표시됨. 자료가 있으나 검색되지 않음 등'
                                }
                            />
                            <div className="input-remark">
                                <div className="write-count">
                                    <strong className="count-num">100</strong>/<span className="write-total">1,000</span>
                                    {/* TODO 1000 제한인지, 한글기준인지 이모지포함가능한지 등 */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-btns">
                            <a className="btn btn-md btn-secondary" role="button" data-dismiss="modal" onClick={handleClickCloseButton}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.cancel" />
                                </span>
                            </a>
                            <a className="btn btn-md btn-primary" role="button" data-dismiss="modal" onClick={handleClickOkButton}>
                                <span className="btn-text">
                                    <FormattedMessage id="com.button.ok" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FeedbackDialog.propTypes = {};

FeedbackDialog.defaultProps = {};

export default injectIntl(FeedbackDialog);
