/* eslint-disable */
import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

class MoreButton extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {

        return (
            <div className="grid-row btns-row" style={this.props.style} key={-1}>

                <div className="grid-cell">
                    <a className="btn btn-secondary btn-block" role="button"
                       onClick={(e)=>{
                           this.props.onClickMore();
                           e.stopPropagation();
                       }}
                    ><span className="btn-text">
                        <FormattedMessage id="com.viewmore" />
                    </span></a>
                </div>

            </div>
        );
    }
}

export default MoreButton;