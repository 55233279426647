import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { alert } from '../../utils/ModalService';
import FileReload from '../../utils/FileReload';
import DriveInfoRest from '../../apis/DriveInfoRest';
import { Auth, CreateDate, Location, Own, ShareTarget, Size, FolderSize, Tag, UpdateDate, Version, DueDate } from '../presentationals/DriveDetail/Info';
import { SHARED_ROOT_FOLDER_ID } from '../../constants/DriveConstant';

class DriveDetailInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { info: {} };
    }

    componentDidMount() {
        this.getObjtInfo();
    }

    componentDidUpdate(prevProps) {
        const { detailObject } = this.props;
        if (detailObject.drive !== prevProps.detailObject.drive) this.getObjtInfo();
    }

    async getObjtInfo() {
        const { detailObject } = this.props;
        const { resultCode, data } = await DriveInfoRest.getFolderFileInfo(detailObject.drive);
        if (resultCode === 200) {
            this.setState({ info: data });
        } else {
            let noAuthMsgId = 'drive.alert.incomLink.noAuthFolder';
            if (detailObject.drive.objtSectCd === 'FILE') noAuthMsgId = 'drive.alert.incomLink.noAuthFile';

            alert(noAuthMsgId).then(() => {
                FileReload.call(null, detailObject.drive, { reloadType: 'refresh', folderChanged: true });
                this.close();
            });
        }
    }

    render() {
        const { reload, detailObject, history } = this.props;
        const { info } = this.state;
        if (!info || !info.objtId) return '';
        const { objtSectCd, filePOwnerUserMap, objtRegUserMap, objtStatChgUserMap, totalInfoMap, pathValNm = '', browserPathId = '' } = info;
        const replacedPathName = pathValNm.replace('\\', '').replace(/\\/gi, '>');
        let folderPath = '';
        if (replacedPathName !== '') {
            const pathVal = replacedPathName.split('>');
            for (let i = 0; i < pathVal.length - 1; i += 1) {
                if (i !== 0) folderPath = folderPath.concat(' > ');
                folderPath = folderPath.concat(pathVal[i]);
            }
        }
        let lastAuthFolderId = '';
        let isShared = false;
        if (browserPathId !== '') {
            const browserPath = browserPathId.split('/');
            lastAuthFolderId = browserPath[browserPath.length - 2];
            isShared = browserPath[1] === SHARED_ROOT_FOLDER_ID;
        }
        return (
            <div className="tab-content file-detail-view">
                <div className="scroll-bar">
                    <div className="file-info">
                        <dl className="dl-ingroup">
                            <Own userMap={filePOwnerUserMap} />
                            <Auth authCd={info.authCd} />
                            <ShareTarget info={info} reload={reload} />
                            <Location folderNm={info.onpstFolderNm} history={history} detailObject={detailObject} pathValNm={folderPath} orgPathValNm={pathValNm} lastAuthFolderId={lastAuthFolderId} />
                            {objtRegUserMap && info.objtRegDt && <CreateDate date={info.objtRegDt} userMap={objtRegUserMap} />}
                            {objtStatChgUserMap && info.objtStatChgDt && <UpdateDate date={info.objtStatChgDt} userMap={objtStatChgUserMap} />}
                            {objtSectCd === 'FOLDER' ? (
                                <FolderSize totalInfoMap={totalInfoMap} />
                            ) : (
                                <>
                                    <Size size={info.fileSize} />
                                    <Version verSno={info.fileVerSno} objtId={info.objtId} onpstId={info.onpstId} objtNm={info.objtNm} />
                                    {info.objtExpiredDt && (
                                        <DueDate
                                            date={info.objtExpiredDt}
                                            reload={reload}
                                            expirationRequestStateCode={info.flcmRequestStateCode}
                                            objt={detailObject.drive}
                                            objtConfig={detailObject.config}
                                            isShared={isShared}
                                        />
                                    )}
                                </>
                            )}
                            <Tag info={info} reload={reload} />
                        </dl>
                    </div>
                </div>
            </div>
        );
    }
}

DriveDetailInfo.propTypes = {
    detailObject: object,
    reload: func.isRequired,
    history: object.isRequired
};

DriveDetailInfo.defaultProps = {
    detailObject: {}
};

export default DriveDetailInfo;
