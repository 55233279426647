import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Choose, When } from 'jsx-control-statements';
import { FormattedHTMLMessage } from 'react-intl';
import { summaryActions } from '../../../../../redux/actions/Summary';

const SummaryToasts = () => {
    const toasts = useSelector(({ summary }) => summary.toast.toasts);

    const dispatch = useDispatch();

    const handleClickCloseButton = id => {
        dispatch(summaryActions.toast.close({ id }));
    };

    return (
        <>
            {toasts.map(({ id, content: { messageId, text } }, index) => {
                return (
                    <div className="toast" style={{ display: 'flex', bottom: `${40 + 55 * index}px` }} key={id}>
                        <div className="toast_conts">
                            <div>
                                <Choose>
                                    <When condition={messageId}>
                                        <FormattedHTMLMessage tagName="span" id={messageId} />
                                    </When>
                                    <When condition={text}>{text}</When>
                                </Choose>
                            </div>
                            <a className="btn-ic-nor close" role="button" onClick={() => handleClickCloseButton(id)}>
                                <i className="ic-20-close" />
                            </a>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

SummaryToasts.propTypes = {};

SummaryToasts.defaultProps = {};

export default SummaryToasts;
