import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AuthService from 'utils/AuthService';
import _ from 'lodash';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { alert } from '../../utils/ModalService';
import FileReload from '../../utils/FileReload';
import DriveHeaderMenu from '../presentationals/FileMenu/DriveHeaderMenu';
import FolderTree from './FolderTree';
import Breadcrumb, { convertFolderToBreadcrumb } from '../presentationals/Breadcrumb';
import DownloadRest from '../../apis/DownloadRest';
import { ROOT_FOLDER_ID } from '../../constants/DriveConstant';
import DriveConfigRest from '../../apis/DriveConfigRest';
import DriveConfigs from '../../constants/DriveConfigs';
import FileCheckAction from '../../redux/actions/FileCheck';
import store from '../../redux/store';

const CONFIG = {
    default: {
        getRootFolderId: () => ROOT_FOLDER_ID,
        useTree: () => false,
        useBreadcrumb: () => true,
        useRefreshBtn: () => true
    },
    own: {
        getRootFolderId: () => ROOT_FOLDER_ID,
        useTree: () => false,
        useBreadcrumb: () => true,
        useRefreshBtn: () => true
    },
    shared: {
        getRootFolderId: folder => folder.browserPathId.split('/')[2],
        useTree: folder => folder.objtId !== ROOT_FOLDER_ID,
        useBreadcrumb: () => true,
        useRefreshBtn: () => true
    },
    workgroup: {
        getRootFolderId: folder => (folder.objtId === folder.onpstId ? folder.objtId : folder.browserPathId.split('/')[2]),
        useTree: folder => folder.maxAuthCd && folder.maxAuthCd !== 'NONE',
        useBreadcrumb: () => true,
        useRefreshBtn: () => true
    },
    deleted: {
        getRootFolderId: () => ROOT_FOLDER_ID,
        useTree: () => false,
        useBreadcrumb: () => true,
        useRefreshBtn: () => true
    },
    expiration: {
        getRootFolderId: () => undefined,
        useTree: () => false,
        useBreadcrumb: () => false,
        useRefreshBtn: () => true
    },
    compliance: {
        getRootFolderId: () => undefined,
        useTree: () => false,
        useBreadcrumb: () => false,
        useRefreshBtn: () => true
    },
    duplicated: {
        getRootFolderId: () => undefined,
        useTree: () => false,
        useBreadcrumb: () => false,
        useRefreshBtn: () => true
    },
    similar: {
        getRootFolderId: () => undefined,
        useTree: () => false,
        useBreadcrumb: () => false,
        useRefreshBtn: () => true
    }
};

class DriveHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFolderInfo: {},
            rootFolderId: 'initial',
            breadcrumbData: []
        };

        const { type } = this.props;
        const config = CONFIG[type] || CONFIG.default;
        this.getRootFolderId = config.getRootFolderId;
        this.useTree = config.useTree;
        this.useBreadcrumb = config.useBreadcrumb;
        this.useRefreshBtn = config.useRefreshBtn;

        this.options = [{ message: '전체', value: 'all' }, { message: '6개월', value: '6' }, { message: '12개월', value: '12' }];

        this.unsubscribe = null;
        this.preFileReload = null;
    }

    componentDidMount() {
        this.setHeader();
        if (!this.unsubscribe) {
            this.subscribe();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setHeader();
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    getPageRootFolder(type) {
        const { intl } = this.props;
        const rootNm = {
            own: intl.formatMessage({ id: 'user.gnb.myFile' }),
            shared: intl.formatMessage({ id: 'user.gnb.sharedToMe' }),
            sharing: intl.formatMessage({ id: 'user.gnb.sharedByMe' }),
            workgroup: intl.formatMessage({ id: 'user.tab.gnb.workgroup' }),
            deleted: intl.formatMessage({ id: 'user.gnb.recyleBin' }),
            expiration: intl.formatMessage({ id: 'user.tab.gnb.expiration' }),
            compliance: intl.formatMessage({ id: 'user.tab.gnb.compliance' }),
            duplicated: intl.formatMessage({ id: 'user.text.gnb.duplicated' }),
            similar: intl.formatMessage({ id: 'user.text.gnb.similar' })
        };
        return Promise.resolve({
            objtId: ROOT_FOLDER_ID,
            objtNm: rootNm[type],
            browserPathId: `/${ROOT_FOLDER_ID}`,
            explorerPathNm: `\\${rootNm[type]}`
        });
    }

    async getFolderInfo(objtId) {
        // 1. root 일 경우
        const { type, intl, push } = this.props;
        const rootFolder = await this.getPageRootFolder(type);
        if (!objtId || objtId === ROOT_FOLDER_ID) return rootFolder;

        // 2. folderId와 사용자 id가 같은 특이 케이스일 경우 ( 휴지통에서 내파일 )
        const { id } = AuthService.getLoginUser();
        if (id === objtId) {
            return {
                objtId,
                browserPathId: `/${rootFolder.objtId}/${objtId}`,
                explorerPathNm: `\\${rootFolder.objtNm}\\${intl.formatMessage({ id: 'user.gnb.myFile' })}`
            };
        }

        let folder = [];
        const response = await DownloadRest.downloadFilePathRes({ objtId });
        if (response.resultCode === 200) {
            // workgroup의 경우 하위폴더가 공유된 경우 워크그룹 자체에는 권한이 없을 수 있으므로 회피
            if (type === 'workgroup') {
                if (response.data[0].objtId !== response.data[0].onpstId && response.data[0].maxAuthCd === null) {
                    await alert('drive.alert.selectFolder.noAuth').then(() => {
                        push('/home');
                    });
                } else {
                    [folder] = response.data;
                }
            } else if (response.data[0].maxAuthCd === null) {
                await alert('drive.alert.selectFolder.noAuth').then(() => {
                    push('/home');
                });
            } else {
                [folder] = response.data;
            }
        } else {
            await alert('drive.alert.selectFolder.noAuth').then(() => {
                push('/home');
            });
        }

        // 3. 정상 폴더인 경우. ( 조회된 폴더 경로에서 root 경로 영역 교체. 휴지통 화면만 필요하지만 통일성을 위해 일괄 통합 )
        const { browserPathId, explorerPathNm } = rootFolder;
        const pathIdCutIdx = folder.browserPathId.indexOf('/', 2);
        const pathNmCutIdx = folder.explorerPathNm.indexOf('\\', 2);
        folder.browserPathId = browserPathId.concat(folder.browserPathId.substring(pathIdCutIdx));
        folder.explorerPathNm = explorerPathNm.concat(folder.explorerPathNm.substring(pathNmCutIdx));
        return folder;
    }

    async setFileCheckMaxCount() {
        const { setFileCheckMaxCount, type } = this.props;

        let cfgId = DriveConfigs.FILE_CHECK_MAX_COUNT;
        if (type === 'expiration') cfgId = DriveConfigs.FLCM_FILE_CHECK_MAX_COUNT;
        else if (type === 'compliance') cfgId = DriveConfigs.CDM_FILE_CHECK_MAX_COUNT;

        const maxCount = await DriveConfigRest.getConfig(cfgId, true);
        if (!_.isNil(maxCount) && maxCount !== '') {
            try {
                setFileCheckMaxCount(_.toNumber(maxCount));
            } catch (e) {
                console.log('Error! when convert to number', maxCount);
            }
        }
    }

    async setHeader() {
        const { match } = this.props;
        const { folderId } = match.params;
        const folder = await this.getFolderInfo(folderId);
        await this.setFileCheckMaxCount();
        this.setState({
            currentFolderInfo: folder,
            rootFolderId: this.getRootFolderId(folder),
            breadcrumbData: convertFolderToBreadcrumb(folder)
        });
    }

    refresh = (folderId, folder) => {
        FileReload.call(null, folder.drive, { reloadType: 'refresh', folderChanged: true });
    };

    move = folderId => {
        const { location, history, type } = this.props;
        const { currentFolderInfo } = this.state;

        let nextPath = location.pathname.replace(currentFolderInfo.objtId, folderId);
        if (type === 'expiration' || type === 'compliance') {
            nextPath = nextPath.concat(location.search.split('=')[0]).concat('=NONE');
        }

        const searchOnpstId = type === 'workgroup' ? currentFolderInfo.onpstId : undefined;
        history.push(nextPath, { searchOnpstId });
    };

    onSearch = params => {
        const { location, history } = this.props;

        const newParams = {};
        Object.keys(params)
            .filter(key => params[key])
            .forEach(key => {
                newParams[key] = params[key];
            });

        const nextPath = location.search.replace(location.search, `?${queryString.stringify(newParams)}`);
        history.push({ search: nextPath, preParams: newParams });
    };

    subscribe = () => {
        if (this.unsubscribe) return;

        this.unsubscribe = store.subscribe(() => {
            const { match } = this.props;
            const { folderId } = match.params;

            const storeState = store.getState();
            const { fileReload } = storeState;

            if (fileReload === this.preFileReload) return;
            this.preFileReload = fileReload;

            const { data = {} } = fileReload;
            const { changedData, reloadType } = data;

            if (reloadType === 'cached' && changedData) {
                const { objtId, objtNm } = changedData;
                if (objtId !== folderId) return;

                const { currentFolderInfo } = this.state;
                const { browserPathId, explorerPathNm } = currentFolderInfo;
                const pathIds = browserPathId.split('/');
                const pathNms = explorerPathNm.split('\\');
                const pathIdx = _.findIndex(pathIds, id => id === objtId);
                if (pathIdx === -1) return;

                const newPathNms = []
                    .concat(pathNms.slice(0, pathIdx))
                    .concat([objtNm])
                    .concat(pathNms.slice(pathIdx + 1));
                const newPathNm = newPathNms.join('\\');
                const newFolder = Object.assign({}, currentFolderInfo, { explorerPathNm: newPathNm });
                this.setState({
                    currentFolderInfo: newFolder,
                    breadcrumbData: convertFolderToBreadcrumb(newFolder)
                });
            }
        });
    };

    render() {
        const { history } = this.props;
        const { rootFolderId, breadcrumbData, currentFolderInfo } = this.state;

        const {
            pageClass,
            children,
            match: {
                params: { folderId }
            },
            location: { pathname },
            type
        } = this.props;

        if (rootFolderId === 'initial') return <div className="comm-func-bar" />;
        return (
            <div className="comm-func-bar">
                <div className="func-bar-title">
                    {this.useTree(currentFolderInfo) && (
                        <FolderTree rootFolderId={rootFolderId} currentFolderId={currentFolderInfo.objtId} move={this.move} browserPathId={currentFolderInfo.browserPathId} />
                    )}
                    {(this.useBreadcrumb() || this.useRefreshBtn) && (
                        <Breadcrumb data={breadcrumbData} move={this.move} type={type} refresh={this.refresh} onSearch={this.onSearch} history={history} />
                    )}
                    {pageClass.getTitleArea()}
                </div>
                <DriveHeaderMenu pageClass={pageClass} folderId={folderId} custom={{ folder: currentFolderInfo, move: this.move }} pathname={pathname} type={type} />
                {children}
            </div>
        );
    }
}

DriveHeader.propTypes = {
    type: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    pageClass: PropTypes.object.isRequired,
    children: PropTypes.node,
    setFileCheckMaxCount: PropTypes.func.isRequired,
    push: PropTypes.func
};

DriveHeader.defaultProps = {
    type: 'default',
    children: <></>,
    push: () => {}
};

export default connect(
    null,
    {
        push: routerPush,
        setFileCheckMaxCount: maxCount => ({ type: FileCheckAction.SET_MAX_COUNT, count: maxCount })
    }
)(injectIntl(DriveHeader));
