import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import SettingsRest from '../../apis/SettingsRest';
import { globalPopup, toast } from '../../redux/actions';
import ChangeOwnerPresentational from '../presentationals/ChangeOwner';
import { alert } from '../../utils/ModalService';

class ChangeOwner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResetMember: true,
            selectedUser: null
        };
    }

    onChangeResetMemberCheckbox = event => {
        this.setState({ isResetMember: event.target.checked });
    };

    onSelectFromSearchList = user => {
        this.setState({ selectedUser: user });
    };

    onRemoveFromSearchInput = () => {
        this.setState({ selectedUser: null });
    };

    handleCancel = () => {
        const { closeChangeOwnerPopup } = this.props;
        closeChangeOwnerPopup();
        this.setState({ isResetMember: true, selectedUser: null });
    };

    changeOwner = async () => {
        const { selectedUser, isResetMember } = this.state;
        const cleanShareYn = isResetMember ? 'Y' : 'N';
        const res = await SettingsRest.postChangeOwner(selectedUser.id, cleanShareYn);
        return res;
    };

    handleConfirm = () => {
        const { closeChangeOwnerPopup, openConfirm, showToast, intl } = this.props;
        openConfirm(intl.formatMessage({ id: 'user.confirm.LITE_POP_ChangeOwner.change' }), async result => {
            if (result) {
                const response = await this.changeOwner();
                const { resultCode, data } = response;
                if (resultCode === 200) {
                    closeChangeOwnerPopup();
                    this.setState({ isResetMember: true, selectedUser: null });
                    showToast(intl.formatMessage({ id: 'user.toast.LITE_POP_ChangeOwner.changeSuccess' }));
                } else if (resultCode === 20031) {
                    alert({ id: 'user.alert.changeOwner.useFile' });
                } else {
                    const errorMessage = intl.formatMessage({ id: data });
                    showToast(errorMessage);
                }
            }
        });
    };

    render() {
        const { isResetMember, selectedUser } = this.state;
        const { isOpen } = this.props;
        return (
            <ChangeOwnerPresentational
                isOpen={isOpen}
                isResetMember={isResetMember}
                onChangeResetMemberCheckbox={this.onChangeResetMemberCheckbox}
                selectedUser={selectedUser}
                onSelectFromSearchList={this.onSelectFromSearchList}
                onRemoveFromSearchInput={this.onRemoveFromSearchInput}
                cancel={this.handleCancel}
                confirm={this.handleConfirm}
            />
        );
    }
}

ChangeOwner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeChangeOwnerPopup: PropTypes.func.isRequired,
    openConfirm: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isOpen: state.globalPopup.changeOwner.isOpen
});

const mapDispatchToProps = {
    closeChangeOwnerPopup: globalPopup.closeChangeOwner,
    openConfirm: globalPopup.openConfirm,
    showToast: toast.showToast
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangeOwner)
);
