import React from 'react';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import configCache from 'utils/ConfigCache';
import ConfigCacheKey from 'utils/ConfigCacheKey';
import { DriveFilesProvider } from './DriveFilesContext';

class DriveFiles extends React.PureComponent {
    constructor(props) {
        super(props);
        this.value = {
            state: {
                viewColumns: [],
                viewHeight: 0,
                sort: { sortField: null, sortOrder: null },
                total: undefined,
                emptyRoot: false,
                list: undefined,
                firstApproach: false
            },
            props: this.getContextProps(),
            actions: {
                setGridSettings: this.setGridSettings
            }
        };
        this.isMount = true;
        this.state = { value: undefined }; // eslint-disable-line react/no-unused-state
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    setGridSettings = settings => {
        if (!this.isMount) return;
        this.value = Object.assign({}, this.value, { state: Object.assign({}, this.value.state, settings) });
        this.setState({ value: this.value }); // eslint-disable-line react/no-unused-state

        const { viewColumns, viewHeight, sort, columnWidths } = settings;
        const {
            pageClass: { pageId }
        } = this.props;
        if (viewColumns && viewColumns.length) configCache.setConfig(ConfigCacheKey.column, pageId, viewColumns.map(col => col.id));
        if (viewHeight) configCache.setConfig(ConfigCacheKey.rowHeight, null, viewHeight);
        if (sort && sort.sortField) configCache.setConfig(ConfigCacheKey.sort, pageId, sort);
        if (columnWidths && columnWidths.length) {
            const columnWidthById = Object.assign({}, configCache.getConfig(ConfigCacheKey.columnWidth, pageId) || {});
            columnWidths.forEach(col => {
                columnWidthById[col.id] = col.width;
            });
            configCache.setConfig(ConfigCacheKey.columnWidth, pageId, columnWidthById);
        }
    };

    getContextProps = () => {
        const { search } = this.props;
        const searchParams = QueryString.parse(search);
        return Object.assign(
            {
                setTotal: () => null,
                setDetailFile: () => null,
                closeDetailFile: () => null
            },
            this.props,
            { searchParams }
        );
    };

    render() {
        const { pageClass, search, folderId, onpstId } = this.props;
        const {
            props: { folderId: stateFolderId, onpstId: stateOnpstId, search: stateSearch }
        } = this.value;

        // props.folderId, props.onpstId, props.search의 경우, 초기화
        if (folderId !== stateFolderId || onpstId !== stateOnpstId || search !== stateSearch) {
            this.value = Object.assign({}, this.value, { props: this.getContextProps() });
            this.value.state.list = undefined;
        }

        return <DriveFilesProvider value={this.value}>{pageClass.pageBuilder(this.value)}</DriveFilesProvider>;
    }
}

DriveFiles.defaultProps = {
    search: undefined,
    folderId: undefined,
    onpstId: undefined
};

DriveFiles.propTypes = {
    search: PropTypes.string,
    folderId: PropTypes.string,
    pageClass: PropTypes.object.isRequired,
    onpstId: PropTypes.string
};

export default DriveFiles;
