import DragDropAction from '../actions/DragDrop';

const initialState = {
    action: null,
    from: null,
    items: null,

    isDragging: false,
    isDragOver: false,
    isDroppable: false,

    cursor: null,
    overItem: null
};

const dragDrop = {
    action: null,
    from: null,
    items: null,

    isDragging: false,
    isDragOver: false,
    isDroppable: false,

    cursor: null,
    overItem: null
};

export const isDragging = (fromTypes = []) => {
    // 드래그 여부
    if (!dragDrop.isDragging) return false;
    // 허용 타입 만족 여부
    if (fromTypes && fromTypes.length && dragDrop.from) {
        return fromTypes.indexOf(dragDrop.from) !== -1;
    }
    return true;
};

export const getDragDrop = () => {
    return dragDrop;
};

export const getItemCount = () => {
    if (dragDrop.items && dragDrop.items.length > 0) return dragDrop.items.length;
    return 0;
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DragDropAction.START_DRAG:
            // console.log(action.type, action.items, action.cursor);
            if (action.action && action.from && action.items && action.items.length) {
                dragDrop.isDragging = true;
                dragDrop.isDragOver = false;
                dragDrop.isDroppable = false;

                dragDrop.action = action.action;
                dragDrop.from = action.from;
                dragDrop.items = action.items;

                // 마우스 커서 정보
                if (action.cursor && action.cursor.x && action.cursor.y) {
                    dragDrop.cursor = {
                        x: action.cursor.x,
                        y: action.cursor.y
                    };
                }

                return dragDrop;
            }

            return initialState;
        case DragDropAction.END_DRAG:
            // console.log(action.type);
            if (dragDrop.isDragging && dragDrop.action && dragDrop.from && dragDrop.items.length) {
                const result = {
                    action: dragDrop.action,
                    from: dragDrop.from,
                    items: dragDrop.items
                };

                dragDrop.isDragging = false;
                dragDrop.isDragOver = false;
                dragDrop.isDroppable = false;
                dragDrop.overItem = null;
                dragDrop.cursor = null;

                dragDrop.action = null;
                dragDrop.from = null;
                dragDrop.items = null;

                return result;
            }

            return initialState;
        case DragDropAction.ENTER_DRAG:
            // console.log(action.type, action.overItem);
            dragDrop.isDragOver = action.isDragOver;
            dragDrop.isDroppable = action.isDroppable;
            dragDrop.overItem = action.overItem;

            return dragDrop;

        case DragDropAction.LEAVE_DRAG:
            // console.log(action.type);
            dragDrop.isDragOver = false;
            dragDrop.isDroppable = false;
            dragDrop.overItem = null;

            return dragDrop;
        default:
            return state;
    }
};
