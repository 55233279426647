import * as ActionTypes from '../actions/ReqMng';

const initialState = {
    reqMngList: [],
    reqMngListParam: {},
    reqMngListSelected: {
        allChecked: false,
        checkedCnt: 0,
        lastCheckedIndex: '',
        enabled: true,
        maxCount: 100
    }
};

const ReqMng = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.SET_REQ_MNG_LIST:
            return Object.assign({}, state, { reqMngList: action.reqMngList });
        case ActionTypes.SET_REQ_MNG_LIST_PARAM:
            return Object.assign({}, state, { reqMngListParam: Object.assign({}, state.reqMngListParam, action.reqMngListParam) });
        case ActionTypes.SET_REQ_MNG_LIST_SELECTED:
            return Object.assign({}, state, { reqMngListSelected: Object.assign({}, state.reqMngListSelected, action.reqMngListSelected) });
        default:
            return state;
    }
};

export default ReqMng;
