/* eslint-disable */
import React, { Component } from 'react';
import { number, object } from 'prop-types';
import { alert } from '../../utils/ModalService';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import ConfigsRest from 'apis/ConfigsRest';
import DayHistory from '../presentationals/DriveDetail/History/DayHistory';
import SelectBoxWithFilter from '../presentationals/SelectBoxWithFilter';
import HistoryRest from '../../apis/HistoryRest';
import FileReload from '../../utils/FileReload';

class DriveDetailHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            evtSectCd: '',
            isMore: false,
            dataLoad: false,
            maxSearchDays: 30
        };
    }

    // 이력조회
    componentDidMount() {
        const list = [];
        this.setState({ list, evtSectCd: 'ALL' });
        this.historyLoad('ALL');

        ConfigsRest.getCommonConfig('EFL_COM_PERMANENTLY_DELETE_DAY').then(response => {
            const { cfgVal } = response;
            this.setState({ maxSearchDays: cfgVal });
        });
    }

    componentDidUpdate(prevProps) {
        const { objt } = this.props;
        if (objt !== prevProps.objt) {
            const list = [];
            this.setState({ list, evtSectCd: 'ALL' });
            this.historyLoad('ALL');
        }
    }

    onChangeEventSectCd = evtSectCd => {
        const list = [];
        this.setState({ list, evtSectCd });
        this.historyLoad(evtSectCd);
    };

    handleClickMore = () => {
        const { list, evtSectCd } = this.state;
        this.historyLoad(evtSectCd, list[list.length - 1].searchAfter[0]);
    };

    historyLoad(evtSectCd, searchAfter) {
        this.setState({ isMore: false, dataLoad: false });

        const { objt } = this.props;

        //최초 로드와 더보기 구별하여 호출
        if (!!searchAfter) {
            HistoryRest.getFileFolderHistory(objt.objtId, objt.onpstId, evtSectCd, searchAfter).then(
                result => {
                    if (result.resultCode === 200) {
                        if (result.data.length > 0) {
                            this.setState({ list: [...this.state.list, ...result.data], dataLoad: true });
                        } else {
                            this.setState({ isMore: true, dataLoad: true });
                        }
                        return true;
                    } else {
                        let noAuthMsgId = 'drive.alert.incomLink.noAuthFolder';
                        if (objt.objtSectCd === 'FILE') noAuthMsgId = 'drive.alert.incomLink.noAuthFile';

                        alert(noAuthMsgId).then(() => {
                            FileReload.call(null, objt, { reloadType: 'refresh', folderChanged: true });
                            this.close();
                        });
                    }
                },
                () => {
                    // 실행안누르고 닫은경우. 아무 변경이 없는루트.
                    return false;
                }
            );
        } else {
            HistoryRest.getFileFolderHistory(objt.objtId, objt.onpstId, evtSectCd).then(
                result => {
                    if (result.resultCode === 200) {
                        this.setState({ list: result.data, dataLoad: true });
                        return true;
                    } else {
                        let noAuthMsgId = 'drive.alert.incomLink.noAuthFolder';
                        if (objt.objtSectCd === 'FILE') noAuthMsgId = 'drive.alert.incomLink.noAuthFile';

                        alert(noAuthMsgId).then(() => {
                            FileReload.call(null, objt, { reloadType: 'refresh', folderChanged: true });
                            this.close();
                        });
                    }
                },
                () => {
                    // 실행안누르고 닫은경우. 아무 변경이 없는루트.
                    return false;
                }
            );
        }
    }

    render() {
        const { list, evtSectCd, dataLoad, isMore, maxSearchDays } = this.state;
        const { objt, intl } = this.props;
        let types = [];

        const fileTypes = [
            { value: 'ALL', message: intl.formatMessage({ id: 'drive.filter.fileType.all' }) },
            { value: 'CREATE', message: intl.formatMessage({ id: 'drive.filter.fileType.create' }) },
            { value: 'MOVE', message: intl.formatMessage({ id: 'drive.filter.fileType.move' }) },
            { value: 'COPY', message: intl.formatMessage({ id: 'drive.filter.fileType.copy' }) },
            { value: 'RENAME_FILE', message: intl.formatMessage({ id: 'drive.filter.fileType.renameFile' }) },
            { value: 'SHAREADD', message: intl.formatMessage({ id: 'drive.filter.fileType.shareAdd' }) },
            { value: 'SHAREDEL', message: intl.formatMessage({ id: 'drive.filter.fileType.shareDel' }) },
            { value: 'SHAREMOD', message: intl.formatMessage({ id: 'drive.filter.fileType.shareMod' }) },
            { value: 'VERSIONUP', message: intl.formatMessage({ id: 'drive.filter.fileType.versionUp' }) },
            { value: 'DOWNLOAD', message: intl.formatMessage({ id: 'drive.filter.fileType.download' }) },
            { value: 'CREATE_FILE_LINK', message: intl.formatMessage({ id: 'drive.filter.fileType.createFileLink' }) },
            { value: 'DELETE_FILE_LINK', message: intl.formatMessage({ id: 'drive.filter.fileType.deleteFileLink' }) },
            { value: 'OPEN_VIEWER', message: intl.formatMessage({ id: 'drive.filter.fileType.openViewer' }) },
            { value: 'SUMMARY', message: intl.formatMessage({ id: 'drive.filter.fileType.summary' }) }
        ];

        const folderTypes = [
            { value: 'ALL', message: intl.formatMessage({ id: 'drive.filter.folderType.all' }) },
            { value: 'CREATE', message: intl.formatMessage({ id: 'drive.filter.folderType.create' }) },
            { value: 'MOVE', message: intl.formatMessage({ id: 'drive.filter.folderType.move' }) },
            { value: 'SHAREADD', message: intl.formatMessage({ id: 'drive.filter.folderType.shareAdd' }) },
            { value: 'SHAREDEL', message: intl.formatMessage({ id: 'drive.filter.folderType.shareDel' }) },
            { value: 'SHAREMOD', message: intl.formatMessage({ id: 'drive.filter.folderType.shareMod' }) },
            { value: 'RENAME_FOLDER', message: intl.formatMessage({ id: 'drive.filter.fileType.renameFile' }) }
        ];

        if (objt.objtSectCd === 'FILE') {
            types = fileTypes;
        } else if (objt.objtSectCd === 'FOLDER') {
            types = folderTypes;
        }

        if (list.length > 0) {
            return (
                <div className="tab-content file-detail-view">
                    <div className="file-detail-filter">
                        <span className="select-type-icon">
                            <span className={`btn-ic-nor ${evtSectCd !== 'ALL' ? 'on' : ''}`}>
                                <i className="ic-20-filter" />
                            </span>
                            <SelectBoxWithFilter options={types} onChange={this.onChangeEventSectCd} value={evtSectCd} />
                        </span>
                    </div>
                    {dataLoad && (
                        <div className="scroll-bar">
                            <div className="file-history">
                                <div className="info-msg">
                                    <i className="ic-16-info" />
                                    <FormattedHTMLMessage id="drive.text.detail.limit-days" tagName="div" values={{ n: maxSearchDays }} />
                                </div>
                                <dl className="dl-sepgroup">
                                    <DayHistory list={list} />
                                </dl>
                                {list.length % 50 === 0 && !isMore && (
                                    <a className="btn btn-secondary btn-block" role="button" onClick={this.handleClickMore}>
                                        <span className="btn-text">
                                            <FormattedMessage id="com.viewmore" />
                                        </span>
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div className="tab-content file-detail-view">
                <div className="file-detail-filter">
                    <span className="select-type-icon">
                        <span className={`btn-ic-nor ${evtSectCd !== 'ALL' ? 'on' : ''}`}>
                            <i className="ic-20-filter" />
                        </span>
                        <SelectBoxWithFilter options={types} onChange={this.onChangeEventSectCd} value={evtSectCd} />
                    </span>
                </div>
                {dataLoad && (
                    <div className="box-empty">
                        <div className="message">
                            <span className="ic-empty-history" />
                            <p>
                                <FormattedMessage id="drive.alert.noHistory" />
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

DriveDetailHistory.propTypes = {
    height: number,
    objt: object
};

DriveDetailHistory.defaultProps = {
    height: 743,
    objt: {}
};

export default injectIntl(DriveDetailHistory);
