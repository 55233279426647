import FoldersRest from '../../../../apis/FoldersRest';
import { toast } from '../../../../utils/ModalService';
import RemovePreferredFolder from './RemovePreferredFolder';

const executor = (props, files, e) => {
    e.stopPropagation();
    FoldersRest.removePreferFolders(files).then(({ data }) => {
        // const { drive } = files[0];
        if (data) {
            toast('drive.toast.favor.removed');
            props.callback({ type: 'remove', data, reloadPrefer: true });
        }
    });
};

class RemovePreferredFolderRow extends RemovePreferredFolder {
    getIconDom() {
        return this.getBaseIconDom(this.getName(), 'btn-ic-nor btn-func-fav on', 'ic-20-fav-add', executor);
    }

    execute() {
        executor(this.props, this.files);
    }
}

export default RemovePreferredFolderRow;
