import React from 'react';
import PropTypes from 'prop-types';
import { If } from 'jsx-control-statements';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import FileDownload from 'presentationals/Download/Download';
import { alert, popup, largePopup } from 'utils/ModalService';
import AgentDownload from './Download/AgentDownload';
import { getInfo as getGuideInfo, isLoaded as isGuideLoaded } from '../containers/QuickGuide';

const HelpAside = ({ tel, email, hours, onClickReqPartnerAccount, onClickHelpQuestion, onClickMobileAppDownloadLink, sectionCode, languageCode, configs }) => {
    function downloadMenual() {
        popup(<FileDownload type="Manual" tagInfoVal={languageCode} />);
    }
    const infoYnConfig = _.find(configs, ['id', 'EFL_COM_INFO_YN']);
    const infoYn = infoYnConfig ? infoYnConfig.value : '';

    function openAgentDownloadPopup() {
        if (window.navigator.platform.indexOf('Win') === -1) {
            alert({ id: 'user.text.helpcenter.manualDownload.unsupportedOS' });
        } else {
            largePopup(<AgentDownload isClose={true} infoYn={infoYn} />);
        }
    }

    const mobileDownloadConfig = _.find(configs, ['id', 'EFL_AUT_USE_MOBILE_APP']);
    const regPartnerAccountByUserYnConfig = _.find(configs, ['id', 'EFL_AUT_REG_PARTNER_ACCOUNT_BY_USER_YN']);
    const mobileDownloadYn = mobileDownloadConfig ? mobileDownloadConfig.value : '';
    const regPartnerAccountByUserYn = regPartnerAccountByUserYnConfig ? regPartnerAccountByUserYnConfig.value : '';

    const guideYn = getGuideInfo().enable;
    const guideId = getGuideInfo().id;

    function openQuickGuide() {
        // 가이드 사용여부 or 로딩 여부 확인
        if (!guideYn || !isGuideLoaded() || !window.WalkMeAPI) return;
        window.WalkMeAPI.startFlowById(guideId);
    }

    return (
        <aside className="help-desk">
            <If condition={infoYn === 'Y'}>
                <dl className="desk-contact">
                    <dt>
                        <i className="ic-20-customer-center" />
                        <span className="dt-text">
                            <FormattedMessage id="user.helpcenter.repPhone" />
                        </span>
                    </dt>
                    <dd>
                        <span className="phone">{tel}</span>
                    </dd>
                    <dd>
                        <ul className="li-divider-list">
                            <li>
                                <span>{hours.split('|')[0]}</span>
                            </li>
                            <li>
                                <span>{hours.split('|')[1]}</span>
                            </li>
                        </ul>
                    </dd>
                </dl>
                <dl className="desk-contact">
                    <dt>
                        <i className="ic-20-email" />
                        <span className="dt-text">
                            <FormattedMessage id="user.helpcenter.emailInquiry" />
                        </span>
                    </dt>
                    <dd>
                        <span className="email">{email}</span>
                    </dd>
                    <dd>
                        <a className="btn btn-sm btn-secondary" role="button" onClick={onClickHelpQuestion}>
                            <span className="btn-text">
                                <FormattedMessage id="user.helpcenter.oneToOneInquiry" />
                            </span>
                        </a>
                    </dd>
                </dl>
            </If>
            <If condition={infoYn === 'Y'}>
                <nav className="desk-nav" />
            </If>
            <If condition={mobileDownloadYn === 'Y'}>
                <a className="btn-ic-line btn-block" role="button" onClick={onClickMobileAppDownloadLink}>
                    <i className="ic-20-mobile" />
                    <span className="btn-text">
                        <FormattedMessage id="user.button.helpcenter.mobileApp-downloadLink" />
                    </span>
                </a>
            </If>
            <If condition={sectionCode === 'I' && regPartnerAccountByUserYn === 'Y'}>
                <a className="btn-ic-line btn-block" role="button" onClick={onClickReqPartnerAccount}>
                    <i className="ic-20-collabo" />
                    <span className="btn-text">
                        <FormattedMessage id="user.helpcenter.reqRegisterPartnerAccount" />
                    </span>
                </a>
            </If>
            <a className="btn-ic-line btn-block" role="button" onClick={downloadMenual}>
                <i className="ic-20-manual" />
                <span className="btn-text">
                    <FormattedMessage id="user.helpcenter.menualDownlaod" />
                </span>
            </a>
            <If condition={guideYn}>
                <a className="btn-ic-line btn-block" role="button" onClick={openQuickGuide}>
                    <i className="ic-20-guide" />
                    <span className="btn-text">
                        <FormattedMessage id="user.text.helpcenter.quickGuide" />
                    </span>
                </a>
            </If>
            <a className="btn-ic-line btn-block" role="button" onClick={openAgentDownloadPopup}>
                <i className="ic-20-download" />
                <span className="btn-text">
                    <FormattedMessage id="user.button.helpcenter.pcClientDownload" />
                </span>
            </a>
        </aside>
    );
};

HelpAside.propTypes = {
    tel: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    onClickReqPartnerAccount: PropTypes.func.isRequired,
    onClickHelpQuestion: PropTypes.func.isRequired,
    onClickMobileAppDownloadLink: PropTypes.func.isRequired,
    sectionCode: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
    configs: PropTypes.array.isRequired
};

export default HelpAside;
