import UploadConfigRest from '../../apis/UploadConfigRest';

class Config {
    constructor() {
        this.config = null;
    }

    getConfig = async () => {
        if (this.config == null) {
            this.config = await UploadConfigRest.getConfigs();
            if (!this.config) {
                this.config = {};
            }
            return this.config;
        }
        return this.config;
    };
}

export default new Config();
