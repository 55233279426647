import React from 'react';
import _ from 'lodash';
import RequestPopup from '../containers/Popup/RequestPopup';
import Request from '../../constants/Request';
import UserRequestRest from '../../apis/UserRequestRest';
import { alert as modalAlert } from '../../utils/ModalService';

const windowClose = result => {
    if (result) window.open('about:blank', '_self').close();
};
const openPopup = () => {
    return RequestPopup.open({
        mode: Request.MODE.REQUEST,
        type: Request.TYPE.EXCEPT_PC_CONTROL
    });
};

const AgentLockingPopup = () => {
    UserRequestRest.check('PCCONTROL')
        .then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200 || _.isNil(data) || data === false) {
                return modalAlert('user.alert.approval.exceptPcControl.alreadyReq');
            }
            return openPopup();
        })
        .then(
            result => {
                windowClose(result);
            },
            () => {
                windowClose(true);
            }
        );
    return <></>;
};

export default AgentLockingPopup;
