import React from 'react';
import { object, bool } from 'prop-types';
import FileIconStyle from '../../../../utils/FileIconStyle';

const iconSize = 'ic-file-md';

function getFileIcon(objtNm) {
    const extNm = objtNm.substring(objtNm.lastIndexOf('.') + 1).toLowerCase() || '';
    return FileIconStyle.getStyle(iconSize, extNm);
}

function getFolderIcon(isShared, onpstSectCd) {
    const folderIcon = `${iconSize} ic-file-FOLDER`;

    if (onpstSectCd === 'SYSTFOLDER') return `${folderIcon}-collabo`;
    if (isShared) return `${folderIcon}-share`;

    return folderIcon;
}

const Name = props => {
    const { objt, isShared } = props;
    const { objtNm, objtSectCd, onpstSectCd } = objt;
    const iconClass = objtSectCd === 'FOLDER' ? getFolderIcon(isShared, onpstSectCd) : getFileIcon(objtNm);
    return (
        <div className="file-detail-header">
            <div className="item-file">
                <i className={iconClass} />
                <div className="file" title={objtNm}>
                    <span className="file-name">{objtNm}</span>
                </div>
            </div>
        </div>
    );
};

Name.propTypes = {
    objt: object,
    isShared: bool
};

Name.defaultProps = {
    objt: {
        icon: 'EXCEL',
        objtNm: '테스트',
        extNm: 'docs'
    },
    isShared: false
};

export default Name;
