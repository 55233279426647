import React from 'react';
import { object, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DateConverter from '../../../../utils/DateConverter';
import UserCard from '../../../containers/NameCard/UserCard';

const UpdateDate = props => {
    const { date, userMap = {} } = props;
    const { userLangCdNm = '', userId = '' } = userMap;
    const isNameCardOpen = userLangCdNm !== '시스템' && userLangCdNm !== 'SYSTEM';
    return (
        <>
            <dt>
                <FormattedMessage id="drive.text.list.columns.objtStatChgDt" />
            </dt>
            <dd>
                <div className="g-inline-flex">
                    <div className="previous">{DateConverter.convert(date)}</div>
                    <div className="ellipsis comma">
                        <span id="updateDateUserCard" className={`name ${isNameCardOpen ? 'has-name-card' : ''}`} title={userLangCdNm}>
                            {userLangCdNm}
                        </span>
                    </div>
                </div>
            </dd>
            {isNameCardOpen && <UserCard target="updateDateUserCard" id={userId} />}
        </>
    );
};

UpdateDate.propTypes = {
    date: number,
    userMap: object
};

UpdateDate.defaultProps = {
    date: 0,
    userMap: {}
};

export default UpdateDate;
