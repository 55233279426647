import { If } from 'jsx-control-statements';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    setSelectedTab = index => {
        this.setState({ selectedTab: index });
    };

    render() {
        const { menus, children } = this.props;
        const { selectedTab } = this.state;

        return (
            <>
                <section className="help-view-panel">
                    <div className="help-nav js-for-fixed">
                        <div className="fixed-container">
                            <div className="fixed">
                                <nav>
                                    <ul className="nav nav-tabs" role="tablist">
                                        {menus.map((menu, index) => {
                                            return (
                                                <li key={String(menu)} className="nav-item">
                                                    <a
                                                        className={index === selectedTab ? 'nav-link active' : 'nav-link'}
                                                        role="tab"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => this.setSelectedTab(index)}>
                                                        {menu}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {children.map((contents, index) => {
                        return <If condition={selectedTab === index}>{contents}</If>;
                    })}
                </section>
            </>
        );
    }
}

export default Tabs;

Tabs.propTypes = {
    menus: PropTypes.array,
    children: PropTypes.node
};

Tabs.defaultProps = {
    menus: [],
    children: []
};
