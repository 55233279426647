import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import NoticeRest from 'apis/NoticeRest';
import NoticePopup from '../containers/NoticePopup';

const LIMIT_PER_PAGE = 50;

class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            notices: [],
            totalCount: 0,
            keyword: '',
            preKeyword: '',
            search: false,
            openDetails: false,
            anucId: '',
            searchInputFocus: false
        };
        this.searchedKeyword = '';
    }

    componentDidMount() {
        this.getNotices();
    }

    scrollToTop = () => window.scrollTo(0, 0);

    getNotices = isMore => {
        const { page, notices, keyword, search } = this.state;
        const params = {};
        params.page = page;
        params.limit = LIMIT_PER_PAGE;
        if (search && keyword) {
            params.keyword = keyword.trim();
            this.searchedKeyword = keyword.trim();
            this.setState({
                keyword: keyword.trim()
            });
        }
        NoticeRest.getNotices(params).then(response => {
            const { resultCode, data } = response;
            if (resultCode !== 200) return;
            const { list, total: totalCount } = data;
            const convertedNotices = isMore ? notices.concat(list) : list;
            this.setState({ notices: convertedNotices, totalCount }, isMore !== true ? this.scrollToTop : null);
        });
    };

    getMoreNotices = () => {
        this.setState(
            prevState => {
                const { page: prevPage } = prevState;
                return { page: prevPage + 1 };
            },
            () => this.getNotices(true)
        );
    };

    openNoticePopup = anucId => {
        this.setState({ openDetails: true, anucId });
    };

    closeNoticePopup = () => {
        this.setState({ openDetails: false });
    };

    createNotices = () => {
        const { notices, search } = this.state;
        return _.map(notices, notice => {
            const { anucImportanceYn, anucId, anucTitle, anucStartDt } = notice;
            const formmatedDate = moment(anucStartDt).format('YYYY-MM-DD');
            return (
                <li className={anucImportanceYn === 'Y' ? 'headline' : ''} key={anucId}>
                    <div className="notice-title">
                        <a role="button" onClick={() => this.openNoticePopup(anucId)}>
                            {search && this.searchedKeyword ? <Highlighter searchWords={[this.searchedKeyword]} autoEscape={true} textToHighlight={anucTitle} /> : <>{anucTitle}</>}
                        </a>
                    </div>
                    <em className="update">{formmatedDate}</em>
                </li>
            );
        });
    };

    onKeywordChange = ({ target }) => {
        const { value: keyword } = target;
        const { page, preKeyword } = this.state;

        let tmpPage = page;
        if (keyword !== preKeyword) {
            tmpPage = 1;
        }
        this.setState({ keyword, page: tmpPage, preKeyword: keyword });
    };

    inputKeyUpEvent = ({ key }) => {
        if (key === 'Enter') this.onSearch();
    };

    onSearch = () => {
        const { keyword } = this.state;
        if (!keyword || keyword.trim().length === 0) return;
        this.setState({ search: true }, this.getNotices);
    };

    cancelSearch = () => {
        this.setState({ keyword: '', search: false }, this.getNotices);
    };

    clearKeyword = () => {
        this.setState({ keyword: '' });
    };

    render() {
        const { intl } = this.props;
        const { notices, totalCount, keyword, search, openDetails, anucId, searchInputFocus } = this.state;
        const noticesList = this.createNotices();
        return (
            <>
                <div className="comm-title-bar js-for-fixed">
                    <div className="fixed-container">
                        <div className="fixed">
                            <div className="section-srch-result" style={{ display: search ? '' : 'none' }}>
                                <FormattedMessage id="com.tooltip.go.back">
                                    {title => (
                                        <a className="btn-ic-nor btn-func-back" role="button" title={title} onClick={this.cancelSearch}>
                                            <i className="ic-20-arrow-back" />
                                        </a>
                                    )}
                                </FormattedMessage>

                                <div className="total-count">
                                    <span className="count">
                                        <FormattedMessage id="user.helpcenter.resultCount" values={{ n: <i className="num">{totalCount}</i> }} />
                                    </span>
                                </div>
                            </div>
                            <div className="section-srch">
                                <div className={`box-input-group ${searchInputFocus ? 'is-focused' : ''}`}>
                                    <a
                                        className={_.isEmpty(keyword) ? 'btn-ic-nor btn-func-search' : 'btn-ic-nor btn-func-search on'}
                                        role="button"
                                        title={intl.formatMessage({ id: 'com.search' })}
                                        onClick={this.onSearch}>
                                        <i className="ic-16-search" />
                                    </a>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'user.helpcenter.searchAnnouncement' })}
                                        maxLength={100}
                                        value={keyword}
                                        onChange={this.onKeywordChange}
                                        onKeyUp={this.inputKeyUpEvent}
                                        onFocus={() => this.setState({ searchInputFocus: true })}
                                        onBlur={() => this.setState({ searchInputFocus: false })}
                                    />
                                    <a
                                        className="btn-ic-nor btn-func-clear"
                                        role="button"
                                        title={intl.formatMessage({ id: 'com.text.clear' })}
                                        style={{ display: keyword.length > 0 ? 'inline-flex' : 'none' }}
                                        onClick={this.clearKeyword}>
                                        <i className="ic-16-clear" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="help-view-conts">
                    <ul className="notice-list" style={{ display: notices && notices.length > 0 ? '' : 'none' }}>
                        {noticesList}
                    </ul>
                    <div className="box-empty" style={{ display: !notices || notices.length === 0 ? 'block' : 'none' }}>
                        <div className="message" style={{ display: search ? 'none' : 'block' }}>
                            <span className="ic-empty-notice" />
                            <p>{intl.formatMessage({ id: 'user.helpcenter.noAnnouncement' })}</p>
                        </div>
                        <div className="message" style={{ display: search ? 'block' : 'none' }}>
                            <span className="ic-empty-search" />
                            <p>{intl.formatMessage({ id: 'com.no-search-results' })}</p>
                        </div>
                    </div>
                    <a
                        className="btn btn-secondary btn-block"
                        role="button"
                        onClick={this.getMoreNotices}
                        style={{ display: notices && notices.length > 0 && totalCount > notices.length ? '' : 'none' }}>
                        <span className="btn-text">{intl.formatMessage({ id: 'com.viewmore' })}</span>
                    </a>
                </div>
                {openDetails && <NoticePopup show={openDetails} anucId={anucId} handleClose={this.closeNoticePopup} />}
            </>
        );
    }
}

Notice.propTypes = {
    intl: PropTypes.object.isRequired
};

export default injectIntl(Notice);
